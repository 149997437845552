import React from 'react';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';

const Container = styled.div`
  margin: 26px 30px 26px 0;

  > .dropdown {
    > .dropdown-toggle {
      background-color: #ffffff !important;
      color: ${(props) => props.theme.color.text};
      padding: 14.5px 20px 14.5px 20px;
      margin: 0;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 2px;
      border-radius: ${(props) => props.theme.border.radius};
      border-width: 1px;
      border-style: solid;
      border-color: ${(props) => props.theme.color.textGrayLight};
      display: flex;

      &:hover {
        ${(props) => props.theme.boxShadowDark};
      }

      > i {
        margin-left: 15px;
        color: ${(props) => props.theme.color.textGray};
      }

      &::after {
        display: none;
      }
    }

    .dropdown-item {
      font-size: 18px !important;
      padding: 10px 15px;

      > i {
        color: ${(props) => props.theme.color.textGray};
        margin-right: 10px;
      }
    }
  }
`;

interface Props {
  onChange: (year: number) => void;
}

/**
 * SelectFiscalYear()
 * @param props
 * @constructor
 */
export default function SelectFiscalYear(props: Props) {
  const { onChange } = props;
  const { dateFrom } = useAppSelector((state) => state.order.serviceFilter);
  const { availableYears } = useAppSelector((state) => state.order);

  const renderItems = () => {
    const years = [...availableYears];
    return years
      .sort((a, b) => (a > b ? -1 : 1))
      .map((year) => (
        <Dropdown.Item onClick={() => onChange(year)} key={year.toString()}>
          <i className="far fa-angles-right" />
          {year}
        </Dropdown.Item>
      ));
  };

  return (
    <Container>
      <Dropdown align="end">
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {moment(dateFrom).format('YYYY')}
          <i className="fas fa-sort-down" />
        </Dropdown.Toggle>

        <Dropdown.Menu>{renderItems()}</Dropdown.Menu>
      </Dropdown>
    </Container>
  );
}
