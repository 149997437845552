import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatchLinkingCreate } from '../../../Redux/Actions/LinkingAction';
import { OrderEntity, ServiceEntity } from '../../../Globals/Types/OrderTypes';
import { Report } from '../../../Globals/Types/Report';
import { CountryCode } from '../../../Globals/Types/Enums';
import DisplayText from '../../../Components/View/DisplayText';
import SelectPublicLinkValidDays from '../../../Components/PredefinedSelects/SelectPublicLinkValidDays';
import Input from '../../../Components/Inputs/Input';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import { useGetFacility } from '../../../Globals/Hooks/FacilityHooks';
import { useDispatchFacilityMailTemplateSend } from '../../../Redux/Actions/Client/Facility/MailTemplates';
import moment from 'moment';
import { MailTemplateType } from '../../../Globals/Types/Mail';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { isValidateEmail } from '../../../Globals/Functions';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import SelectLanguage from '../../../Components/PredefinedSelects/SelectLanguage';
import { LinkingPayloadPublicReport, LinkingType } from '../../../Globals/Types/Linking';

interface Props {
  visible: boolean;
  onClose: () => void;
  order: OrderEntity;
  service: ServiceEntity;
  report: Report;
}

/**
 * CreatePublicLinkModal
 * @constructor
 */
export default function CreatePublicLinkModal(props: Props) {
  const { visible, onClose, order, service, report } = props;
  const [url, setUrl] = React.useState<string>('');
  const [code, setCode] = React.useState<number>();
  const [mail, setMail] = React.useState<string>();
  const [hasMail, setHasMail] = React.useState<boolean>(false);
  const [validDays, setValidDays] = React.useState<number>(30);
  const [created, setCreated] = React.useState<boolean>(false);
  const [mailError, setMailError] = React.useState<string>(null);
  const [language, setLanguage] = React.useState<CountryCode>(null);
  const [t] = useTranslation();
  const { setLoading, loadingModal } = useLoadingModal({ headline: t('mailSending') });
  const dispatchCreateLink = useDispatchLinkingCreate();
  const getFacility = useGetFacility();
  const dispatchSendMail = useDispatchFacilityMailTemplateSend();

  const handleCreate = React.useCallback(() => {
    const payload: LinkingPayloadPublicReport = {
      orderId: order.orderId,
      serviceId: service.serviceId,
      clientId: order.ownerClientId,
      externalId: order.externalId,
    };

    return dispatchCreateLink(payload, LinkingType.publicReport, validDays).then(({ url, pin }) => {
      setUrl(url);
      setCode(pin);
      setCreated(true);
      return Promise.resolve({ url, pin });
    });
  }, [dispatchCreateLink, order.externalId, order.orderId, order.ownerClientId, service.serviceId, validDays]);

  const handleSendMail = React.useCallback(() => {
    const facility = getFacility(order.ownerFacilityId);
    setMailError(null);

    if (isValidateEmail(mail)) {
      setLoading(true);

      handleCreate().then(({ url: urlParam, pin: codeParam }) => {
        const placeholder = {
          externalId: order.externalId,
          date: moment(report.startDateTime).format('LLL'),
          facilityName: facility.name,
          url: urlParam,
          code: codeParam,
        };

        dispatchSendMail(order.ownerFacilityId, MailTemplateType.reportPublicLink, language, mail, placeholder).finally(
          () => {
            setLoading(false);
            onClose();
          },
        );
      });
    } else {
      setMailError(InternalErrorCodes.MAIL_INVALID);
    }
  }, [
    dispatchSendMail,
    getFacility,
    handleCreate,
    language,
    mail,
    onClose,
    order.externalId,
    order.ownerFacilityId,
    report.startDateTime,
    setLoading,
  ]);

  React.useEffect(() => {
    if (visible && order.customer.mail) {
      setMail(order.customer.mail);
    }
  }, [order.customer.mail, visible]);

  React.useEffect(() => {
    if (visible) {
      const facility = getFacility(order.ownerFacilityId);
      if (facility) {
        setLanguage(facility.countryCode);
        setHasMail(facility.settings && facility.settings.mailSettings && facility.settings.mailSettings.validated);
      }
    }
  }, [getFacility, order.ownerFacilityId, visible]);

  React.useEffect(() => {
    if (!visible) {
      setUrl('');
      setCode(null);
      setCreated(false);
      setValidDays(30);
      setMail('');
      setMailError(null);
    }
  }, [visible]);

  const renderLink = () => {
    if (created) {
      return (
        <Row>
          <Col xl={12}>
            <DisplayText
              caption={t('publicLink')}
              rightAction={<i className="fad fa-copy" onClick={() => navigator.clipboard.writeText(url)} />}
            >
              {url}
            </DisplayText>
          </Col>
          <Col xl={12}>
            <DisplayText
              caption={t('code')}
              rightAction={<i className="fad fa-copy" onClick={() => navigator.clipboard.writeText(code.toString())} />}
            >
              {code.toString()}
            </DisplayText>
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (!created) {
      return (
        <>
          <SelectPublicLinkValidDays onChange={setValidDays} initialValue={validDays} disabled={created} />

          <hr style={{ marginTop: 30 }} />

          <div style={{ marginTop: 30 }}>
            <ShowHideContainer visible={hasMail}>
              <SelectLanguage onChange={setLanguage} selected={language} />
              <Input onChange={setMail} value={mail} label={t('eMailAddress')} autoFocus hasError={!!mailError} />
            </ShowHideContainer>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <Modal centered show={visible} size="lg">
      <Modal.Header>
        <Modal.Title>{t('modules.report.publicLinkCreateHeadline')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('modules.report.publicLinkCreateDescription')}</p>

        {renderContent()}
        {renderLink()}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={mail && mail.length > 0 ? () => handleSendMail() : () => handleCreate()}
          disabled={created}
        >
          {mail && mail.length > 0 ? t('sendLink') : t('createLink')}
        </Button>
        <Button variant="outline-secondary" onClick={onClose}>
          {created ? t('close') : t('cancel')}
        </Button>
      </Modal.Footer>

      {loadingModal}
    </Modal>
  );
}
