import React from 'react';
import { OrderEntity, ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import Panel from './Panel';
import { useTranslation } from 'react-i18next';
import { DeliveryAndLoadingOptions } from '../../../../../Globals/Types/Enums';

interface Props {
  order: OrderEntity;
  service: ServiceEntity;
}

/**
 * DeliveryAddress()
 * @param props
 * @constructor
 */
export default function DeliveryAddress(props: Props) {
  const { service, order } = props;
  const [t] = useTranslation();

  const getCaption = () => {
    if (
      !service.deliveryAndLoadingOption ||
      service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.deliveryIsCustomer ||
      service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.deliveryIsCustomerAndLoading
    ) {
      return `${order.customer.zip} ${order.customer.city}`;
    }
    if (
      service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingAndDelivery ||
      service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingIsCustomerAndDelivery
    ) {
      return `${service.deliveryAddress.zip} ${service.deliveryAddress.city}`;
    }
  };

  return <Panel icon={'fa-duotone fa-location-dot'} label={t('deliveryAddress')} caption={getCaption()} />;
}
