import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type ContextHelpModalProps = {
  visible: boolean;
  onClose: Function;
  contextName: string;
};

/**
 * ContextHelpModal
 * @param props
 * @constructor
 */
export default function ContextHelpModal(props: ContextHelpModalProps) {
  const { visible, onClose, contextName } = props;
  const [headline, setHeadline] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [t] = useTranslation();

  React.useEffect(() => {
    setHeadline(t(`helpContext.${contextName}.headline`));
    setDescription(t(`helpContext.${contextName}.description`));
  }, [contextName, t]);

  return (
    <Modal show={visible} onHide={() => onClose()} animation={true} size="lg">
      <Modal.Header>
        <Modal.Title>
          <i className="fas fa-question-circle" style={{ marginRight: 10 }} />
          {headline}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => onClose()}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
