import React from 'react';
import ErrorMessage from '../Modals/ErrorMessage';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  onClose: () => void;
}

/**
 * FacilityDataInvalid()
 * @param props
 * @constructor
 */
export default function FacilityDataInvalid(props: Props) {
  const { visible, onClose } = props;
  const [t] = useTranslation();

  return (
    <ErrorMessage
      onClose={onClose}
      visible={visible}
      headline={t('errorMessages.facilityDataInvalid.headline')}
      description={t('errorMessages.facilityDataInvalid.description')}
    />
  );
}
