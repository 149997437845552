import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  visible: boolean;
  onClose: Function;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;

  i {
    font-size: 70px;
    color: ${(props) => props.theme.color.red};
    padding-bottom: 20px;
  }
`;

/**
 * SubscriptionExpiredMessage
 * @param props
 * @constructor
 */
export default function SubscriptionExpiredMessage(props: Props) {
  const { visible, onClose } = props;
  const [t] = useTranslation();

  return (
    <Modal show={visible} onHide={() => onClose()} animation={false} centered>
      <Modal.Body>
        <Container>
          <i className="fas fa-exclamation-circle" />
          <h4>{t('modules.settings.subscription.expiredHeadline')}</h4>
          {t('modules.settings.subscription.expiredDescription')}
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button variant="success" onClick={() => onClose()}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
