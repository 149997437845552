import React from 'react';
import PageHeader from '../../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import Panel from '../../Panel';
import Input, { InputType } from '../../Inputs/Input';
import DatePicker from '../../Inputs/DatePicker';
import SelectClientUsers from '../../SelectClientUsers';
import PositionForm from './PositionsForm';
import { initialState, reducer } from './Reducer';
import { PickedUserEntity } from '../../../Globals/Types/Types';
import moment from 'moment';
import SelectFacility from '../../PredefinedSelects/SelectFacility';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import ShowHideContainer from '../../ShowHideContainer';
import Summary from './Summary';
import { OfferEntity } from '../../../Globals/Types/OfferTypes';
import { validateOffer, ValidateOfferErrorInterface } from './ValidateOffer';

interface Props {
  offer: OfferEntity | null;
  customerId: string;
  onCreate: (offer: OfferEntity) => void;
  onUpdate: (offer: OfferEntity) => void;
  onCancel: (isEdit: boolean) => void;
}

/**
 * Create()
 * @constructor
 */
export default function Create(props: Props) {
  const { customerId, onCreate, onUpdate, onCancel, offer } = props;
  const { facilities } = useAppSelector((state) => state.client);
  const { clientId, userId } = useAppSelector((state) => state.auth);
  const [t] = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [errors, setErrors] = React.useState<ValidateOfferErrorInterface>({});

  const handleSave = React.useCallback(() => {
    const validationResult = validateOffer(state.offer);

    if (validationResult.isValid) {
      if (state.isEdit) {
        onUpdate(state.offer);
      } else {
        onCreate(state.offer);
      }
    } else {
      setErrors(validationResult.errors);
    }
  }, [onCreate, onUpdate, state.isEdit, state.offer]);

  React.useEffect(() => {
    if (clientId && userId && facilities && customerId && !offer) {
      dispatch({
        type: 'init',
        payload: {
          customerId,
          clientId,
          facilityId: facilities.length === 1 ? facilities[0].facilityId : null,
          userId,
        },
      });
    }
  }, [clientId, customerId, facilities, offer, userId]);

  React.useEffect(() => {
    if (clientId && userId && facilities && customerId && offer) {
      dispatch({
        type: 'initEdit',
        payload: offer,
      });
    }
  }, [clientId, customerId, facilities, offer, userId]);

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={handleSave}>
        {t('save')}
      </Button>
    );
  };

  const renderCancelButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => onCancel(state.isEdit)}>
        {t('cancel')}
      </Button>
    );
  };

  return (
    <>
      <PageHeader
        headline={t('offerCreate')}
        actionButtonOne={renderSaveButton()}
        actionButtonTwo={renderCancelButton()}
      />

      <Panel headline={t('basicData')}>
        <Row>
          <Col xxl={6}>
            <Input
              onChange={(value) => dispatch({ type: 'offerNumber', payload: value })}
              value={state.offer.offerNumber}
              label={t('offerNumber')}
              required
              hasError={!!errors.orderNumber}
              disabled={state.isEdit}
            />
          </Col>
          <Col xxl={6}>
            <DatePicker
              label={t('offerValidUntil')}
              onChange={(value) => dispatch({ type: 'validUntil', payload: value })}
              initialValue={moment(state.offer.validUntil).toDate()}
              required
            />
          </Col>
          <ShowHideContainer visible={!!state.offer.contactUserId}>
            <Col xxl={6}>
              <SelectClientUsers
                onChange={(value) => dispatch({ type: 'contactUserId', payload: (value as PickedUserEntity).userId })}
                initialSelected={state.offer.contactUserId}
                required
                label={t('contactPerson')}
                isMultiSelect={false}
                hasError={!!errors.contactUserId}
              />
            </Col>
          </ShowHideContainer>
          <ShowHideContainer visible={facilities && facilities.length > 1}>
            <Col xxl={6}>
              <SelectFacility
                onChange={(value) => dispatch({ type: 'facilityId', payload: value })}
                selected={state.offer.facilityId}
              />
            </Col>
          </ShowHideContainer>
        </Row>
      </Panel>

      <PositionForm
        positions={state.offer.positions}
        onChange={(value) => dispatch({ type: 'positions', payload: value })}
        hasError={!!errors.positions}
      />

      <Panel headline={t('additionalInformation')}>
        <Input
          onChange={(value) => dispatch({ type: 'additionalFooterText', payload: value })}
          value={state.offer.additionalFooterText}
          label={t('modules.offers.additionFooterInformation')}
          rows={3}
          type={InputType.textarea}
          contextHelpKey={'offerAdditionalFooterInformation'}
        />
        <Input
          onChange={(value) => dispatch({ type: 'internalInformation', payload: value })}
          value={state.offer.internalInformation}
          label={t('internalInformation')}
          rows={3}
          type={InputType.textarea}
          contextHelpKey={'offerInternalInformation'}
        />
      </Panel>

      <Summary offer={state.offer} />

      <Panel>
        <Row>
          <Col xxl={12} className="flex-row d-flex justify-content-end">
            <div style={{ marginRight: 10 }}>{renderSaveButton()}</div>
            <div>{renderCancelButton()}</div>
          </Col>
        </Row>
      </Panel>
    </>
  );
}
