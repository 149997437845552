import React from 'react';
import { AbsenceEntity } from '../../../Globals/Types/Types';
import { FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import {
  collection,
  getFirestore,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  CollectionReference,
} from 'firebase/firestore';
import {
  ABSENCES_CREATE_ERROR,
  ABSENCES_CREATE_START,
  ABSENCES_CREATE_SUCCESS,
  ABSENCES_DELETE_ERROR,
  ABSENCES_DELETE_START,
  ABSENCES_DELETE_SUCCESS,
  ABSENCES_GET_LIST_ERROR,
  ABSENCES_GET_LIST_START,
  ABSENCES_GET_LIST_SUCCESS,
  ABSENCES_UPDATE_ERROR,
  ABSENCES_UPDATE_START,
  ABSENCES_UPDATE_SUCCESS,
} from '../../ActionTypes';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import moment from 'moment';

/**
 * useDispatchAbsenceCreate()
 */
type CreateReturnType = (absence: AbsenceEntity) => Promise<AbsenceEntity>;
export const useDispatchAbsenceCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<CreateReturnType>(
    (absence) => {
      dispatch({ type: ABSENCES_CREATE_START });

      const absenceCollection = collection(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.absences,
      );

      return addDoc(absenceCollection, absence)
        .then((response) => {
          dispatch({ type: ABSENCES_CREATE_SUCCESS, payload: { ...absence, absenceId: response.id } });
          return Promise.resolve(absence);
        })
        .catch((error) => {
          dispatch({ type: ABSENCES_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchAbsenceGetList()
 */
type GetListReturnType = (startDate: Date, endDate: Date, resetState?: boolean) => Promise<Array<AbsenceEntity>>;
export const useDispatchAbsenceGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<GetListReturnType>(
    (startDate, endDate, resetState = false) => {
      dispatch({ type: ABSENCES_GET_LIST_START });

      const absenceCollection = collection(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.absences,
      ) as CollectionReference<AbsenceEntity>;
      const queryRef = query(
        absenceCollection,
        where('startDate', '>=', moment(startDate).subtract(1, 'month').toDate()),
        where('startDate', '<=', moment(endDate).add(1, 'month').toDate()),
      );

      return getDocs(queryRef)
        .then((snapShot) => {
          const absences: Array<AbsenceEntity> = [];
          if (!snapShot.empty) {
            snapShot.forEach((absence) => {
              absences.push({ ...absence.data(), absenceId: absence.id });
            });
          }
          dispatch({ type: ABSENCES_GET_LIST_SUCCESS, payload: { absences, resetState } });
          return Promise.resolve(absences);
        })
        .catch((error) => {
          dispatch({ type: ABSENCES_GET_LIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchAbsenceDelete()
 */
type DeleteReturnType = (absence: AbsenceEntity) => Promise<AbsenceEntity>;
export const useDispatchAbsenceDelete = (): DeleteReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<DeleteReturnType>(
    (absence) => {
      dispatch({ type: ABSENCES_DELETE_START });

      const absenceDoc = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.absences,
        absence.absenceId,
      );

      return deleteDoc(absenceDoc)
        .then(() => {
          dispatch({ type: ABSENCES_DELETE_SUCCESS, payload: absence });
          return Promise.resolve(absence);
        })
        .catch((error) => {
          dispatch({ type: ABSENCES_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchAbsenceUpdate()
 */
type UpdateReturnType = (absence: AbsenceEntity) => Promise<AbsenceEntity>;
export const useDispatchAbsenceUpdate = (): UpdateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<UpdateReturnType>(
    (absence) => {
      dispatch({ type: ABSENCES_UPDATE_START });

      const absenceDoc = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.absences,
        absence.absenceId,
      );

      return setDoc(absenceDoc, absence, { merge: true })
        .then(() => {
          dispatch({ type: ABSENCES_UPDATE_SUCCESS, payload: absence });
          return Promise.resolve(absence);
        })
        .catch((error) => {
          dispatch({ type: ABSENCES_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};
