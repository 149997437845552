import React from 'react';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import Select, { SelectOptions } from '../Inputs/Select';
import Lodash from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatchTourTemplateGetList } from '../../Redux/Actions/Client/TourTemplateActions';

interface Props {
  hasError?: boolean;
  initialValue?: string | string[];
  label: string;
  multiSelect?: boolean;
  onChange: (tourTemplateIds: string | string[]) => void;
}

const ALL = 'all';

/**
 * SelectTourTemplate
 * @constructor
 */
export default function SelectTourTemplate(props: Props) {
  const { hasError, initialValue, label, multiSelect, onChange } = props;
  const { tourTemplates } = useAppSelector((state) => state.client);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<SelectOptions>(null);
  const [t] = useTranslation();

  const dispatchTourTemplateGetList = useDispatchTourTemplateGetList();

  React.useEffect(() => {
    if ((!tourTemplates || tourTemplates.length === 0) && !loaded) {
      dispatchTourTemplateGetList().then(() => null);
      setLoaded(true);
    }
  }, [dispatchTourTemplateGetList, loaded, tourTemplates]);

  React.useEffect(() => {
    if (tourTemplates) {
      const optionsTemp: SelectOptions = tourTemplates.map((tourTemplate) => {
        return { label: tourTemplate.name, value: tourTemplate.tourTemplateId };
      });
      setOptions(optionsTemp);
    }
  }, [multiSelect, tourTemplates]);

  const handleSelect = (tourTemplateIds: string | string[]) => {
    if (multiSelect) {
      let tourTemplateIdsPrepared: string[] = [];
      const newest: string = Lodash.last(tourTemplateIds);
      if (newest) {
        if (newest === ALL) {
          tourTemplateIdsPrepared = Lodash.map(tourTemplates, (tourTemplate) => tourTemplate.tourTemplateId);
        } else {
          tourTemplateIdsPrepared = Lodash.filter(tourTemplateIds, (id) => id !== ALL);
        }
      }

      return onChange(tourTemplateIdsPrepared);
    }

    onChange(tourTemplateIds);
  };

  if (options) {
    return (
      <Select
        hasError={hasError}
        initialValue={initialValue}
        label={label}
        multiSelect={multiSelect}
        onChange={handleSelect}
        options={options}
        optionAll={{ label: t('selectAll'), value: 'all' }}
      />
    );
  }

  return null;
}
