import { GroupKey, TimeTrackingEntity } from '../Types/Types';
import { TimeTrackingType } from '../Types/Enums';
import moment from 'moment';
import { useAppSelector } from './Hooks';
import React from 'react';

/**
 * Calculate the overall working minutes for an order / service time tracking
 * @param timeTrackings
 */
export const timeTrackingOrderCalculateOverall = (timeTrackings: TimeTrackingEntity[]) => {
  let minutes: number = 0;
  let lastStart: TimeTrackingEntity = null;

  timeTrackings.forEach((item) => {
    if (item.type === TimeTrackingType.startOrder) {
      lastStart = item;
    } else {
      if (lastStart) {
        const diff = moment(item.timestamp.toDate()).diff(moment(lastStart.timestamp.toDate()), 'minutes');
        minutes = minutes + diff;
        lastStart = null;
      }
    }
  });

  return minutes;
};

/**
 * Check it timetracking option is mandatory is set and if the user is present then allow will be true;
 */
export const useTimeTrackingIsAllowedToUse = () => {
  const { groups } = useAppSelector((state) => state.auth.user);
  const { lastState } = useAppSelector((state) => state.user.timeTracking);
  const { mandatory, active } = useAppSelector((state) => state.auth.client.settings.timeTracking);
  const [isAllowed, setIsAllowed] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (active && mandatory) {
      if (groups.indexOf(GroupKey.ADMIN) < 0) {
        if (lastState && lastState.type) {
          if (!lastState.type || lastState.type !== TimeTrackingType.start) {
            setIsAllowed(false);
          } else {
            setIsAllowed(true);
          }
        } else {
          setIsAllowed(false);
        }
      } else {
        setIsAllowed(true);
      }
    } else {
      setIsAllowed(true);
    }
  }, [active, groups, lastState, mandatory]);

  return isAllowed;
};
