import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Panel from '../../../Components/Panel';
import { useDispatchOrderServiceRebuildIndex } from '../../../Redux/Actions/Order/ServiceAction';
import ElasticIndexFormDevButtons from './Components/ElasticIndexFormDevButtons';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { GroupKey } from '../../../Globals/Types/Types';

/**
 * ElasticIndexForm()
 * @constructor
 */
export default function ElasticIndexForm() {
  const [t] = useTranslation();
  const { groups } = useAppSelector((state) => state.auth.user);
  const { setLoading, loadingModal } = useLoadingModal({
    headline: t('modules.settings.elasticSearch.createIndices.rebuildProgress'),
  });
  const dispatchRebuildIndex = useDispatchOrderServiceRebuildIndex();

  const onClick = () => {
    setLoading(true);
    dispatchRebuildIndex().finally(() => setLoading(false));
  };

  if (groups.find((group) => group === GroupKey.ADMIN)) {
    return (
      <Panel headline={t('searchIndex')} description={t('modules.settings.searchIndex.description')}>
        <div className="d-flex flex-grow-1 flex-column justify-content-end">
          <ElasticIndexFormDevButtons />
          <Button onClick={onClick} variant="success">
            <i className="fa-brands fa-searchengin" style={{ marginRight: 8 }} />
            {t('modules.settings.searchIndex.rebuildSearchIndex')}
          </Button>
        </div>

        {loadingModal}
      </Panel>
    );
  }
  return null;
}

ElasticIndexForm.defaultProps = {
  errors: {},
  withPanel: true,
  showSkeleton: false,
};
