import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Panel from '../../../Components/Panel';
import Select from '../../../Components/Inputs/Select';
import { selectOptionActive } from '../../../Globals/Types/General';
import SelectClientUsers from '../../../Components/SelectClientUsers';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { useDispatchClientUpdate } from '../../../Redux/Actions/ClientAction';

type TimeTrackingFormProps = {
  errors: any;
  withPanel: boolean;
  showSkeleton: boolean;
};

/**
 * TimeTrackingForm()
 * @constructor
 */
export default function TimeTrackingForm(props: TimeTrackingFormProps) {
  const { showSkeleton } = props;
  const { client } = useAppSelector((state) => state.auth);
  const { active, mandatory, responsibleUser } = client.settings.timeTracking;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const dispatchUpdateClient = useDispatchClientUpdate();

  const updateState = (key: string, value: any) => {
    setLoading(true);

    dispatchUpdateClient({
      settings: {
        ...client.settings,
        timeTracking: { ...client.settings.timeTracking, [key]: value },
      },
    }).finally(() => setLoading(false));
  };

  const renderContent = () => {
    return (
      <>
        <Row>
          <Col xl={6}>
            <Select
              label={t('modules.settings.timeTracking.mandatory')}
              onChange={(value) => updateState('mandatory', value)}
              initialValue={mandatory || false}
              options={selectOptionActive}
              required
              disabled={loading || !active}
              showSkeleton={showSkeleton}
              contextHelpKey="timeTrackingMandatory"
            />
          </Col>
          <Col xl={6}>
            <SelectClientUsers
              label={t('modules.settings.timeTracking.responsibleForTemporary')}
              onChange={(value) => updateState('responsibleUser', value)}
              disabled={loading || !active}
              initialSelected={responsibleUser ? [responsibleUser] : null}
              showSkeleton={showSkeleton}
              contextHelpKey="responsibleForTemporary"
              isMultiSelect={false}
            />
          </Col>
        </Row>
      </>
    );
  };

  if (client.settings.timeTracking.active) {
    return (
      <Panel headline={t('timeTracking')} description={t('modules.settings.timeTracking.description')}>
        {renderContent()}
      </Panel>
    );
  }
  return null;
}

TimeTrackingForm.defaultProps = {
  errors: {},
  withPanel: true,
  showSkeleton: false,
};
