import React from 'react';
import Panel from '../../Components/Panel';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import Input from '../../Components/Inputs/Input';
import { useDispatchSendResetPassword } from '../../Redux/Actions/AuthAction';
import { isValidateEmail } from '../../Globals/Functions';
import { InternalErrorCodes } from '../../Globals/InternalErrorCodes';
import { useLoadingModal } from '../../Globals/Hooks/Hooks';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

/**
 * AskResetPassword()
 * @constructor
 */
export default function AskResetPassword() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = React.useState<string>(null);
  const [mail, setMail] = React.useState<string>('');
  const [resetMailSend, setResetMailSend] = React.useState<boolean>(false);
  const { setLoading, loadingModal } = useLoadingModal({ delayStart: 500 });
  const dispatchSendResetPassword = useDispatchSendResetPassword();

  const handleSend = React.useCallback(() => {
    setError(null);

    if (isValidateEmail(mail)) {
      setLoading(true);
      dispatchSendResetPassword(mail)
        .then(() => {
          setResetMailSend(true);
        })
        .catch(() => setError(InternalErrorCodes.MAIL_INVALID))
        .finally(() => setLoading(false));
    } else {
      setError(InternalErrorCodes.MAIL_INVALID);
    }
  }, [dispatchSendResetPassword, mail, setLoading]);

  const renderContent = () => {
    if (!resetMailSend) {
      return <Input onChange={setMail} value={mail} label={t('eMailAddress')} hasError={!!error} autoFocus />;
    }
    return (
      <Alert variant="success" className="text-center">
        <i className="fas fa-check-circle" style={{ fontSize: 30, marginBottom: 10 }} />
        <div>{t('modules.auth.askResetPasswordSuccess', { mail })}</div>
      </Alert>
    );
  };

  return (
    <OuterContainer>
      <div>
        <Panel
          maxWidth={800}
          headline={t('modules.auth.askResetPasswordHeadline')}
          description={t('modules.auth.askResetPasswordDescription')}
          smallHeadline={false}
        >
          <Row>
            <Col>{renderContent()}</Col>
          </Row>

          <hr />

          <Row>
            <Col className="d-flex align-items-center">
              <Button variant="outline-secondary" onClick={() => navigate('/public/login')} className="float-end">
                {t('backToLogin')}
              </Button>
            </Col>
            <Col>
              <Button
                variant="success"
                onClick={handleSend}
                className="float-end"
                disabled={!mail || mail.length <= 0 || resetMailSend}
              >
                {t('passwordReset')}
              </Button>
            </Col>
          </Row>
        </Panel>
      </div>

      {loadingModal}
    </OuterContainer>
  );
}
