import React from 'react';
import { LoadAndDeliveryAddress, OrderEntity, ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import Panel from '../../../../Panel';
import { useTranslation } from 'react-i18next';
import { DeliveryAndLoadingOptions } from '../../../../../Globals/Types/Enums';
import DisplayText from '../../../../View/DisplayText';
import { Col, Row } from 'react-bootstrap';
import EditModal from './EditModal';
import { getLocationByAddress } from '../../../../../Globals/Google';
import Text from '../../../../Text';
import { LocationPoint } from '../../../../../Globals/Types/Types';

interface Props {
  service: ServiceEntity;
  order: OrderEntity;
  onChange: (value: Partial<ServiceEntity>) => void;
  allowEdit: boolean;
}

/**
 * DeliveryAndLoadingAddress()
 * @param props
 * @constructor
 */
export default function DeliveryAndLoadingAddress(props: Props) {
  const { service, order, onChange, allowEdit } = props;
  const [t] = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const handleSave = (
    option: DeliveryAndLoadingOptions,
    loadingAddress: LoadAndDeliveryAddress,
    deliveryAddress: LoadAndDeliveryAddress,
  ) => {
    getLocationByAddress(loadingAddress, service.loadingAddress).then((newLoadingAddress) => {
      getLocationByAddress(deliveryAddress, service.deliveryAddress).then((newDeliveryAddress) => {
        setShowModal(false);
        onChange({
          deliveryAndLoadingOption: option,
          loadingAddress: { ...loadingAddress, ...newLoadingAddress },
          deliveryAddress: { ...deliveryAddress, ...newDeliveryAddress },
        });
      });
    });
  };

  const renderMapMarker = (location: LocationPoint) => {
    if (location && location.longitude && location.longitude) {
      const uri = 'https://maps.google.de/maps?q={lat},{lng}';
      const replaced = uri
        .replace('{lat}', location.latitude.toString())
        .replace('{lng}', location.longitude.toString());
      return <i className="fas fa-location-dot" onClick={() => window.open(replaced, '_blank')} />;
    }
    return null;
  };

  const renderCustomer = (caption: string) => {
    return (
      <DisplayText caption={caption} rightAction={renderMapMarker(order.customer.location)}>
        <Text>{`${order.customer.street} ${order.customer.streetNo}`}</Text>
        <Text>{`${order.customer.zip} ${order.customer.city}`}</Text>
      </DisplayText>
    );
  };

  const renderLoading = () => {
    if (
      service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.deliveryIsCustomerAndLoading ||
      service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingAndDelivery
    ) {
      return (
        <DisplayText caption={t('loadingAddress')} rightAction={renderMapMarker(service.loadingAddress.location)}>
          <Text>{`${service.loadingAddress.street} ${service.loadingAddress.streetNo}`}</Text>
          <Text>{`${service.loadingAddress.zip} ${service.loadingAddress.city}`}</Text>
          <Text visible={!!service.loadingAddress.contactPhone || !!service.loadingAddress.contactName}>
            {`${service.loadingAddress.contactName} ${
              !!service.loadingAddress.contactPhone ? `(${service.loadingAddress.contactPhone})` : ''
            }`}
          </Text>
          <Text
            light
            small
            visible={
              service.loadingAddress && service.loadingAddress.comment && service.loadingAddress.comment.length > 0
            }
          >
            {service.loadingAddress.comment}
          </Text>
        </DisplayText>
      );
    }
    if (service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingIsCustomerAndDelivery) {
      return renderCustomer(t('loadingAddress'));
    }
  };

  const renderDelivery = () => {
    if (
      service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingIsCustomerAndDelivery ||
      service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingAndDelivery
    ) {
      return (
        <DisplayText caption={t('deliveryAddress')} rightAction={renderMapMarker(service.deliveryAddress.location)}>
          <Text>{`${service.deliveryAddress.street} ${service.deliveryAddress.streetNo}`}</Text>
          <Text>{`${service.deliveryAddress.zip} ${service.deliveryAddress.city}`}</Text>
          <Text visible={!!service.deliveryAddress.contactPhone || !!service.deliveryAddress.contactName}>
            {`${service.deliveryAddress.contactName} ${
              !!service.deliveryAddress.contactPhone ? `(${service.deliveryAddress.contactPhone})` : ''
            }`}
          </Text>
          <Text
            light
            small
            visible={
              service.deliveryAddress && service.deliveryAddress.comment && service.deliveryAddress.comment.length > 0
            }
          >
            {service.deliveryAddress.comment}
          </Text>
        </DisplayText>
      );
    }
    if (service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.deliveryIsCustomerAndLoading) {
      return renderCustomer(t('deliveryAddress'));
    }
  };

  if (
    service &&
    service.deliveryAndLoadingOption &&
    service.deliveryAndLoadingOption !== DeliveryAndLoadingOptions.deliveryIsCustomer
  ) {
    return (
      <>
        <Panel
          headline={t('modules.order.loadAndDeliverHeadline')}
          onEdit={allowEdit ? () => setShowModal(true) : null}
        >
          <Row>
            <Col lg={6}>{renderLoading()}</Col>
            <Col lg={6}>{renderDelivery()}</Col>
          </Row>
        </Panel>

        <EditModal service={service} onSave={handleSave} onClose={() => setShowModal(false)} visible={showModal} />
      </>
    );
  }
  return null;
}
