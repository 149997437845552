import React from 'react';
import { TimeTrackingEntity } from '../../../Globals/Types/Types';
import { TimeTrackingType } from '../../../Globals/Types/Enums';

type Props = {
  item: TimeTrackingEntity;
};

/**
 * UserTimeTrackingIconRow()
 * @param props
 * @constructor
 */
export default function UserTimeTrackingIconRow(props: Props) {
  const { item } = props;

  const getIcon = () => {
    switch (item.type) {
      case TimeTrackingType.start:
        return <i className="fas fa-play text-success" />;
      case TimeTrackingType.pause:
        return <i className="fas fa-pause text-warning" />;
      case TimeTrackingType.stop:
        return <i className="fas fa-stop text-danger" />;
    }
  };

  return <td key={`${item.id}-IconRow`}>{getIcon()}</td>;
}
