import {
  AbsenceEntity,
  FacilityEntity,
  FacilityPartnerEntity,
  GroupKey,
  ResourceEntity,
  Subscription,
  TimeTrackingStateEntity,
  TourTemplateEntity,
  UserEntity,
} from '../../Globals/Types/Types';
import {
  ABSENCES_GET_LIST_SUCCESS,
  AUTH_LOGOUT,
  CLIENT_GET_ALL_PARTNERS_SUCCESS,
  CLIENT_INVOICE_CREATE_SUCCESS,
  CLIENT_INVOICE_GETLIST_SUCCESS,
  CLIENT_INVOICE_UPDATE_SUCCESS,
  CLIENT_SUBSCRIPTION_GETLIST_SUCCESS,
  CLIENT_TIMETRACKING_GETSTATE_SUCCESS,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_GET_SUCCESS,
  CUSTOMER_GETLIST_SUCCESS,
  CUSTOMER_INVOICES_GETLIST_SUCCESS,
  CUSTOMER_MEDIA_DELETE_SUCCESS,
  CUSTOMER_MEDIA_GETLIST_SUCCESS,
  CUSTOMER_MEDIA_UPLOAD_SUCCESS,
  CUSTOMER_NOTES_CREATE_SUCCESS,
  CUSTOMER_NOTES_DELETE_SUCCESS,
  CUSTOMER_NOTES_GETLIST_SUCCESS,
  CUSTOMER_NOTES_UPDATE_SUCCESS,
  CUSTOMER_OFFERS_GETLIST_SUCCESS,
  CUSTOMER_UPDATE_SUCCESS,
  FACILITY_GET_SUCCESS,
  FACILITY_GETLIST_SUCCESS,
  FACILITY_UPDATE_LAST_INVOICE_NUMBER_SUCCESS,
  POSITION_DELETE_SUCCESS,
  POSITION_GETLIST_SUCCESS,
  RESOURCE_GETLIST_SUCCESS,
  TOUR_TEMPLATE_GETLIST_SUCCESS,
  USER_GETLIST_SUCCESS,
} from '../ActionTypes';
import Lodash from 'lodash';
import { PositionEntity } from '../../Globals/Types/OrderTypes';
import { CustomerEntity, CustomerMedia, CustomerNoteEntity } from '../../Globals/Types/Customer';
import clientCustomerReducerHelper from './Client/CustomersReducerHelper';
import { InvoiceEntity } from '../../Globals/Types/Invoice';
import { getInvoiceSalesOverallCurrentMonth } from './Client/ClientReducerHelper';
import { OfferEntity } from '../../Globals/Types/OfferTypes';

export interface ClientReducerInterface {
  users: {
    all: UserEntity[];
    monteure: UserEntity[];
  };
  facilities: FacilityEntity[];
  allPartners: FacilityPartnerEntity[];
  absences: AbsenceEntity[];
  timeTrackingState: TimeTrackingStateEntity[];
  tourTemplates: TourTemplateEntity[];
  resources: ResourceEntity[];
  subscriptions: Subscription[];
  positions: PositionEntity[];
  invoices: {
    all: InvoiceEntity[];
    open: InvoiceEntity[];
    currentMonthSales: number;
  };
  customers: {
    all: CustomerEntity[];
    selectedCustomer: {
      customer: CustomerEntity;
      notes: CustomerNoteEntity[];
      invoices: InvoiceEntity[];
      offers: OfferEntity[];
      media: CustomerMedia[];
    };
  };
}

export type ClientActions =
  | { type: typeof USER_GETLIST_SUCCESS; payload: UserEntity[] }
  | { type: typeof FACILITY_GETLIST_SUCCESS; payload: { facilities: FacilityEntity[] } }
  | { type: typeof FACILITY_GET_SUCCESS; payload: FacilityEntity }
  | { type: typeof FACILITY_UPDATE_LAST_INVOICE_NUMBER_SUCCESS; payload: FacilityEntity }
  | { type: typeof ABSENCES_GET_LIST_SUCCESS; payload: { absences: AbsenceEntity[]; resetState: boolean } }
  | { type: typeof CLIENT_TIMETRACKING_GETSTATE_SUCCESS; payload: TimeTrackingStateEntity[] }
  | { type: typeof CLIENT_GET_ALL_PARTNERS_SUCCESS; payload: FacilityPartnerEntity[] }
  | { type: typeof TOUR_TEMPLATE_GETLIST_SUCCESS; payload: TourTemplateEntity[] }
  | { type: typeof POSITION_GETLIST_SUCCESS; payload: PositionEntity[] }
  | { type: typeof POSITION_DELETE_SUCCESS; payload: string }
  | { type: typeof RESOURCE_GETLIST_SUCCESS; payload: ResourceEntity[] }
  | { type: typeof CUSTOMER_GETLIST_SUCCESS; payload: CustomerEntity[] }
  | { type: typeof CUSTOMER_GET_SUCCESS; payload: CustomerEntity }
  | { type: typeof CUSTOMER_UPDATE_SUCCESS; payload: CustomerEntity }
  | { type: typeof CUSTOMER_DELETE_SUCCESS; payload: string }
  | { type: typeof CUSTOMER_NOTES_GETLIST_SUCCESS; payload: { customerId: string; notes: CustomerNoteEntity[] } }
  | { type: typeof CUSTOMER_NOTES_DELETE_SUCCESS; payload: { customerId: string; noteId: string } }
  | { type: typeof CUSTOMER_NOTES_CREATE_SUCCESS; payload: { customerId: string; note: CustomerNoteEntity } }
  | { type: typeof CUSTOMER_NOTES_UPDATE_SUCCESS; payload: { customerId: string; note: CustomerNoteEntity } }
  | { type: typeof CUSTOMER_INVOICES_GETLIST_SUCCESS; payload: { customerId: string; invoices: InvoiceEntity[] } }
  | { type: typeof CUSTOMER_OFFERS_GETLIST_SUCCESS; payload: { customerId: string; offers: OfferEntity[] } }
  | { type: typeof CUSTOMER_MEDIA_GETLIST_SUCCESS; payload: { customerId: string; media: CustomerMedia[] } }
  | { type: typeof CUSTOMER_MEDIA_UPLOAD_SUCCESS; payload: { customerId: string; media: CustomerMedia } }
  | { type: typeof CUSTOMER_MEDIA_DELETE_SUCCESS; payload: { customerId: string; fullPath: string } }
  | { type: typeof CLIENT_INVOICE_GETLIST_SUCCESS; payload: { invoices: InvoiceEntity[]; userId: string } }
  | { type: typeof CLIENT_INVOICE_CREATE_SUCCESS; payload: InvoiceEntity }
  | { type: typeof CLIENT_INVOICE_UPDATE_SUCCESS; payload: InvoiceEntity }
  | { type: typeof CLIENT_SUBSCRIPTION_GETLIST_SUCCESS; payload: Subscription[] }
  | { type: typeof AUTH_LOGOUT };

const defaultValues: ClientReducerInterface = {
  facilities: [],
  allPartners: null,
  users: {
    all: null,
    monteure: null,
  },
  absences: [],
  timeTrackingState: [],
  tourTemplates: [],
  resources: [],
  positions: [],
  invoices: {
    all: [],
    open: [],
    currentMonthSales: 0,
  },
  subscriptions: [],
  customers: {
    all: [],
    selectedCustomer: {
      customer: null,
      notes: null,
      invoices: null,
      offers: null,
      media: null,
    },
  },
};

export default function reduce(
  state: ClientReducerInterface = defaultValues,
  action: ClientActions,
): ClientReducerInterface {
  switch (action.type) {
    case FACILITY_UPDATE_LAST_INVOICE_NUMBER_SUCCESS:
    case FACILITY_GET_SUCCESS: {
      return {
        ...state,
        facilities: state.facilities.map((facility) =>
          facility.facilityId === action.payload.facilityId ? action.payload : facility,
        ),
      };
    }
    case USER_GETLIST_SUCCESS: {
      const monteure = action.payload.filter((user) => user.groups.indexOf(GroupKey.MONTEUR) > -1);
      return {
        ...state,
        users: {
          ...state.users,
          all: [...action.payload],
          monteure: [...monteure],
        },
      };
    }
    case FACILITY_GETLIST_SUCCESS: {
      const { facilities } = action.payload;
      return { ...state, facilities };
    }

    case ABSENCES_GET_LIST_SUCCESS: {
      if (action.payload.resetState) {
        return { ...state, absences: action.payload.absences };
      } else {
        return { ...state, absences: Lodash.unionBy(state.absences, action.payload.absences, 'absenceId') };
      }
    }

    case CLIENT_TIMETRACKING_GETSTATE_SUCCESS:
      return { ...state, timeTrackingState: action.payload };

    case CLIENT_GET_ALL_PARTNERS_SUCCESS:
      return { ...state, allPartners: action.payload };

    case RESOURCE_GETLIST_SUCCESS:
      return { ...state, resources: action.payload };

    case POSITION_GETLIST_SUCCESS:
      return { ...state, positions: action.payload };

    case POSITION_DELETE_SUCCESS:
      return { ...state, positions: state.positions.filter((item) => item.positionId !== action.payload) };

    case TOUR_TEMPLATE_GETLIST_SUCCESS:
      return { ...state, tourTemplates: action.payload };

    case CLIENT_INVOICE_GETLIST_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          all: action.payload.invoices,
          open: action.payload.invoices.filter((item) => !item.payed),
          currentMonthSales: getInvoiceSalesOverallCurrentMonth(action.payload.invoices),
        },
      };
    case CLIENT_INVOICE_CREATE_SUCCESS:
      return {
        ...state,
        invoices: {
          all: [...state.invoices.all, action.payload],
          open: [...state.invoices.open, action.payload],
          currentMonthSales: getInvoiceSalesOverallCurrentMonth([...state.invoices.all, action.payload]),
        },
      };
    case CLIENT_INVOICE_UPDATE_SUCCESS:
      let mergedCustomer = { ...state.customers.selectedCustomer };

      if (mergedCustomer && mergedCustomer.invoices) {
        mergedCustomer = {
          ...mergedCustomer,
          invoices: mergedCustomer.invoices.map((invoice) =>
            invoice.invoiceId === action.payload.invoiceId ? action.payload : invoice,
          ),
        };
      }

      return {
        ...state,
        invoices: {
          ...state.invoices,
          all: state.invoices.all.map((item) => (item.invoiceId === action.payload.invoiceId ? action.payload : item)),
        },
        customers: {
          ...state.customers,
          selectedCustomer: { ...mergedCustomer },
        },
      };

    case CLIENT_SUBSCRIPTION_GETLIST_SUCCESS:
      return { ...state, subscriptions: action.payload };

    case AUTH_LOGOUT: {
      return { ...defaultValues };
    }

    case CUSTOMER_GETLIST_SUCCESS:
    case CUSTOMER_GET_SUCCESS:
    case CUSTOMER_UPDATE_SUCCESS:
    case CUSTOMER_DELETE_SUCCESS:
    case CUSTOMER_NOTES_GETLIST_SUCCESS:
    case CUSTOMER_NOTES_DELETE_SUCCESS:
    case CUSTOMER_NOTES_CREATE_SUCCESS:
    case CUSTOMER_NOTES_UPDATE_SUCCESS:
    case CUSTOMER_INVOICES_GETLIST_SUCCESS:
    case CUSTOMER_MEDIA_GETLIST_SUCCESS:
    case CUSTOMER_MEDIA_DELETE_SUCCESS:
    case CUSTOMER_MEDIA_UPLOAD_SUCCESS:
    case CUSTOMER_OFFERS_GETLIST_SUCCESS:
      return clientCustomerReducerHelper(state, action);

    default:
      return { ...state };
  }
}
