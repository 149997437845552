import { useAppSelector } from '../Hooks/Hooks';
import React from 'react';
import { GroupKey, UserEntity } from '../Types/Types';

/**
 * useGetUser
 */
export const useGetUser = () => {
  const { all } = useAppSelector((state) => state.client.users);

  return React.useCallback(
    (userId: string) => {
      if (all) {
        return all.find((user) => user.userId === userId);
      }
    },
    [all],
  );
};

/**
 * Checks if the user is only allowed for time tracking
 * @param user
 */
export const isUserOnlyTimeTracking = (user: UserEntity): boolean => {
  return user.groups.length === 1 && user.groups.indexOf(GroupKey.TIMETRACKING) >= 0;
};
