import React from 'react';
import { useTranslation } from 'react-i18next';
import { OfferEntity } from '../../../../Globals/Types/OfferTypes';
import DropDownItem from '../../../CustomDropDown/DropDownItem';
import { buildUrlCustomersOffersUpdate } from '../../../../Globals/UrlFunctions';
import { useNavigate } from 'react-router';

interface Props {
  offer: OfferEntity;
}

/**
 * OfferEditButton()
 * @param props
 * @constructor
 */
export default function OfferEditButton(props: Props) {
  const { offer } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();

  const renderButton = () => {
    return (
      <>
        <DropDownItem
          onClick={() => navigate(buildUrlCustomersOffersUpdate(offer.customerId, offer.offerId))}
          key={'createOrder'}
        >
          <i className="far fa-pencil" />
          {t('offerEdit')}
        </DropDownItem>
      </>
    );
  };

  return <>{renderButton()}</>;
}
