import React from 'react';
import styled from 'styled-components';
import CalendarHeader from '../Components/CalendarHeader';
import { DispositionReducerByDate } from '../../../Redux/Reducer/DispositionReducer';
import moment from 'moment';
import Row from './Row';
import Headline from './Headline';
import ServiceDetailsPanel from '../ServiceDetailsPanel';
import { Tour, TourItem, TourService } from '../../../Globals/Types/Tour';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: ${(props) => props.theme.border.radius};
  ${(props) => props.theme.boxShadowDark};
  overflow: hidden;
`;

interface Props {
  tours: DispositionReducerByDate[];
  onChangeDate: (date: string) => void;
  onPressDay: (date: string) => void;
  date: string;
  selectedTour?: Tour;
  selectedTourItem?: TourItem<TourService>;
  onCloseServicePanel: () => void;
  onDeleteTourItem: (tourItem: TourItem<TourService>) => void;
  onPressService: (tour: Tour, service: TourService) => void;
}

/**
 * WeekView()
 * @param props
 * @constructor
 */
export default function WeekView(props: Props) {
  const {
    tours,
    date,
    onChangeDate,
    onPressDay,
    selectedTourItem,
    selectedTour,
    onCloseServicePanel,
    onDeleteTourItem,
    onPressService,
  } = props;
  const [startDate, setStartDate] = React.useState<string>(null);
  const [endDate, setEndDate] = React.useState<string>(null);
  const [tourNames, setTourNames] = React.useState<string[]>(null);

  React.useEffect(() => {
    setStartDate(moment(date).startOf('week').format('YYYY-MM-DD'));
    setEndDate(moment(date).endOf('week').format('YYYY-MM-DD'));
  }, [date]);

  React.useEffect(() => {
    if (startDate && endDate) {
      const filtered = tours.filter(
        (item) => moment(item.date).isSameOrAfter(moment(startDate)) && moment(item.date).isSameOrBefore(endDate),
      );
      let mergedNames: string[] = [];
      filtered.forEach((item) => {
        const names = item.data.map((tour) => tour.name);
        mergedNames = [...mergedNames, ...names];
      });

      setTourNames([...new Set(mergedNames)].sort((a, b) => a.localeCompare(b)));
    }
  }, [endDate, startDate, tours]);

  const renderTours = () => {
    if (tourNames && tourNames.length > 0) {
      return tourNames.map((name) => (
        <Row
          tourName={name}
          startDate={startDate}
          endDate={endDate}
          key={name}
          tours={tours}
          onPressService={onPressService}
          selectedService={selectedTour ? selectedTourItem.data : null}
        />
      ));
    }
    return null;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
      <Container>
        <CalendarHeader date={date} showWeekNumber onChange={onChangeDate} isWeekView={true} />
        <Headline startDate={startDate} endDate={endDate} onPress={onPressDay} />
        {renderTours()}
      </Container>

      <ServiceDetailsPanel
        visible={!!selectedTourItem}
        tour={selectedTour}
        tourItem={selectedTourItem}
        onClose={onCloseServicePanel}
        onDeleteTourItem={onDeleteTourItem}
      />
    </div>
  );
}
