import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Panel from '../../../../../Components/Panel';

type Props = {
  facilityId: string;
  visible: boolean;
};

/**
 * NoCompanyData
 * @constructor
 */
export default function NoCompanyData(props: Props) {
  const { facilityId, visible } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();

  if (visible) {
    return (
      <Panel headline={t('modules.settings.facility.noCompanyDataHeadline')} headlineCentered>
        <p style={{ textAlign: 'center' }}>{t('modules.settings.facility.noCompanyDataDescription')}</p>

        <p style={{ textAlign: 'center', marginTop: 30 }}>
          <Button variant="success" onClick={() => navigate(`/settings/facility/${facilityId}`)}>
            {t('companyDataEdit')}
          </Button>
        </p>
      </Panel>
    );
  }
  return null;
}
