import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { isValidateEmail } from '../../../../Globals/Functions';
import { FacilitySettingsEntity } from '../../../../Globals/Types/Types';
import moment from 'moment';

export type FacilitySettingsError = { [K in keyof FacilitySettingsEntity as Exclude<K, 'mailSettings'>]?: string };

export type ValidationResult = {
  isValid: boolean;
  errors: FacilitySettingsError;
  settings: FacilitySettingsEntity;
};

/**
 * isValid()
 * @param data
 */
const isValid = (data: FacilitySettingsEntity): ValidationResult => {
  let errors: FacilitySettingsError = {} as FacilitySettingsError;
  let settings: FacilitySettingsEntity = { ...data };

  const startTime = moment(data.workDayStart, 'HH:mm');
  const endTime = moment(data.workDayEnd, 'HH:mm');

  if (startTime.isSameOrAfter(endTime)) {
    errors = { ...errors, workDayEnd: InternalErrorCodes.START_AFTER_END };
  }

  if (data.invoiceMail) {
    if (!isValidateEmail(data.invoiceMail)) {
      errors = { ...errors, invoiceMail: InternalErrorCodes.MAIL_INVALID };
    }
  }

  if (data.reportMail) {
    if (!isValidateEmail(data.reportMail)) {
      errors = { ...errors, reportMail: InternalErrorCodes.MAIL_INVALID };
    }
  }

  return { isValid: Object.keys(errors).length === 0, errors, settings };
};

export default isValid;
