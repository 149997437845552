import React from 'react';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import PanelSelect, { PanelSelectOption } from '../../PanelSelect';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import { useDispatchDocumentGetDownloadUrl } from '../../../Redux/Actions/GlobalStorageActions';
import { buildDocumentRef, FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import { FacilityDocumentTypes } from '../../../Globals/Types/Enums';
import { getStorage } from 'firebase/storage';

interface Props {
  onChange: (facilityId: string) => void;
  selected: string;
  blackHeadline?: boolean;
}

type DownloadType = {
  facilityId: string;
  url: string;
};

/**
 * SelectFacility
 * @constructor
 */
export default function SelectFacility(props: Props) {
  const { onChange, selected, blackHeadline } = props;
  const [t] = useTranslation();
  const { facilities: allowedFacilities } = useAppSelector((state) => state.auth.user);
  const { clientId } = useAppSelector((state) => state.auth);
  const { facilities } = useAppSelector((state) => state.client);
  const [options, setOptions] = React.useState<Array<PanelSelectOption>>([]);
  const [imageUrls, setImageUrls] = React.useState<Array<DownloadType>>([]);
  const dispatchGetDownloadUrl = useDispatchDocumentGetDownloadUrl();

  const getImageUriOrIcon = React.useCallback<(facilityId: string) => string | null>(
    (facilityId) => {
      if (imageUrls && imageUrls.length > 0) {
        const filtered = imageUrls.filter((item) => item.facilityId === facilityId);
        if (filtered && filtered.length === 1) {
          return filtered[0].url;
        }
      }
      return null;
    },
  [imageUrls],
  );

  React.useEffect(() => {
    const promiseAll = [];
    facilities.forEach((facility) => {
      const path = buildDocumentRef(
        getStorage(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.facility,
        facility.facilityId,
        FirebasePathMappings.documents,
        FacilityDocumentTypes.logo,
      );

      promiseAll.push(
        dispatchGetDownloadUrl(path.fullPath)
          .then<DownloadType>((response) =>
            Promise.resolve({
              facilityId: facility.facilityId,
              url: response,
            }),
          )
          .catch(() => {}),
      );
    });

    Promise.all<DownloadType>(promiseAll).then((response) => {
      const returnValue = [];
      response.forEach((item) => {
        if (item !== undefined) {
          returnValue.push(item);
        }
      });
      setImageUrls(returnValue);
    });
  }, [clientId, dispatchGetDownloadUrl, facilities]);

  React.useEffect(() => {
    if (allowedFacilities.length > 1) {
      const filtered = facilities.filter((item) => allowedFacilities.indexOf(item.facilityId) > -1);

      const mapped: Array<PanelSelectOption> = filtered.map((item) => ({
        key: item.facilityId,
        caption: item.name,
        icon: getImageUriOrIcon(item.facilityId) === null ? 'fas fa-building' : null,
        imageUrl: getImageUriOrIcon(item.facilityId),
        disabled: false,
        useTranslation: false,
      }));
      setOptions(mapped);
    }
  }, [allowedFacilities, facilities, getImageUriOrIcon]);

  if (allowedFacilities.length > 1) {
    return (
      <Panel
        headline={t('facilitySelect')}
        description={t('modules.order.facilitySelectDescription')}
        headlineDarkBlack={blackHeadline || false}
      >
        <PanelSelect
          onChange={(item) => onChange(item[0])}
          options={options}
          selected={[selected]}
          multiSelect={false}
          allowUnselectAll={false}
        />
      </Panel>
    );
  }
  return null;
}
