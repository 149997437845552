import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import React from 'react';
import {
  TOUR_TEMPLATE_CREATE_ERROR,
  TOUR_TEMPLATE_CREATE_START,
  TOUR_TEMPLATE_CREATE_SUCCESS,
  TOUR_TEMPLATE_DELETE_ERROR,
  TOUR_TEMPLATE_DELETE_START,
  TOUR_TEMPLATE_DELETE_SUCCESS,
  TOUR_TEMPLATE_GET_ERROR,
  TOUR_TEMPLATE_GET_START,
  TOUR_TEMPLATE_GET_SUCCESS,
  TOUR_TEMPLATE_GETLIST_ERROR,
  TOUR_TEMPLATE_GETLIST_START,
  TOUR_TEMPLATE_GETLIST_SUCCESS,
  TOUR_TEMPLATE_UPDATE_ERROR,
  TOUR_TEMPLATE_UPDATE_START,
  TOUR_TEMPLATE_UPDATE_SUCCESS,
} from '../../ActionTypes';
import { FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { TourTemplateEntity } from '../../../Globals/Types/Types';

/**
 * buildCollection()
 */
const buildCollection = (clientId: string) => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.tourTemplate,
  ) as CollectionReference<TourTemplateEntity>;
};

/**
 * buildDoc()
 * @param clientId
 * @param tourTemplateId
 */
const buildDoc = (clientId: string, tourTemplateId: string) => {
  return doc(buildCollection(clientId), tourTemplateId) as DocumentReference<TourTemplateEntity>;
};

type CreateReturnType = (tourTemplate: TourTemplateEntity) => Promise<TourTemplateEntity>;
/**
 * useDispatchTourTemplateCreate
 */
export const useDispatchTourTemplateCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<CreateReturnType>(
    (tourTemplate) => {
      dispatch({ type: TOUR_TEMPLATE_CREATE_START, payload: tourTemplate });

      const collection = buildCollection(clientId);
      return addDoc(collection, tourTemplate)
        .then((response) => {
          const mapped: TourTemplateEntity = { ...tourTemplate, tourTemplateId: response.id };
          dispatch({ type: TOUR_TEMPLATE_CREATE_SUCCESS, payload: mapped });
          return Promise.resolve(mapped);
        })
        .catch((error) => {
          dispatch({ type: TOUR_TEMPLATE_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type DeleteReturnType = (tourTemplate: TourTemplateEntity) => Promise<TourTemplateEntity>;
/**
 * useDispatchTourTemplateDelete()
 */
export const useDispatchTourTemplateDelete = (): DeleteReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<DeleteReturnType>(
    (tourTemplate) => {
      dispatch({ type: TOUR_TEMPLATE_DELETE_START, payload: tourTemplate });

      const doc = buildDoc(clientId, tourTemplate.tourTemplateId as Required<string>);
      return deleteDoc(doc)
        .then(() => {
          dispatch({ type: TOUR_TEMPLATE_DELETE_SUCCESS, payload: tourTemplate });
          return Promise.resolve(tourTemplate);
        })
        .catch((error) => {
          dispatch({ type: TOUR_TEMPLATE_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type GetReturnType = (tourTemplateId: string) => Promise<TourTemplateEntity>;
/**
 * useDispatchTourTemplateGet
 */
export const useDispatchTourTemplateGet = (): GetReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetReturnType>(
    (tourTemplateId: string) => {
      dispatch({ type: TOUR_TEMPLATE_GET_START, payload: tourTemplateId });

      const doc = buildDoc(clientId, tourTemplateId);
      return getDoc(doc)
        .then((response) => {
          if (response.exists()) {
            const tourTemplate: TourTemplateEntity = { ...response.data(), tourTemplateId: response.id };
            dispatch({ type: TOUR_TEMPLATE_GET_SUCCESS, payload: tourTemplate });
            return Promise.resolve(tourTemplate);
          }

          dispatch({ type: TOUR_TEMPLATE_GET_ERROR, payload: `${tourTemplateId} doesn't exist` });
          return Promise.reject();
        })
        .catch((error) => {
          dispatch({ type: TOUR_TEMPLATE_GET_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type GetListReturnType = () => Promise<TourTemplateEntity[]>;
/**
 * useDispatchTourTemplateGetList()
 */
export const useDispatchTourTemplateGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListReturnType>(() => {
    dispatch({ type: TOUR_TEMPLATE_GETLIST_START, payload: clientId });

    const collection = buildCollection(clientId);
    const queryRef = query(collection, orderBy('name'));
    return getDocs(queryRef)
      .then((response) => {
        const tourTemplates: TourTemplateEntity[] = [];
        response.forEach((template) => {
          tourTemplates.push({ ...template.data(), tourTemplateId: template.id });
        });

        dispatch({ type: TOUR_TEMPLATE_GETLIST_SUCCESS, payload: tourTemplates });
        return Promise.resolve(tourTemplates);
      })
      .catch((error) => {
        dispatch({ type: TOUR_TEMPLATE_GETLIST_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [clientId, dispatch]);
};

type UpdateReturnType = (tourTemplate: TourTemplateEntity) => Promise<TourTemplateEntity>;
/**
 * useDispatchTourTemplateUpdate()
 */
export const useDispatchTourTemplateUpdate = (): UpdateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateReturnType>(
    (tourTemplate) => {
      dispatch({ type: TOUR_TEMPLATE_UPDATE_START, payload: tourTemplate });

      const doc = buildDoc(clientId, tourTemplate.tourTemplateId);
      return setDoc(doc, tourTemplate, { merge: true })
        .then(() => {
          dispatch({ type: TOUR_TEMPLATE_UPDATE_SUCCESS, payload: tourTemplate });
          return Promise.resolve(tourTemplate);
        })
        .catch((error) => {
          dispatch({ type: TOUR_TEMPLATE_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};
