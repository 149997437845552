import React from 'react';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import { ArticlePartnerEntity } from '../../../../../Globals/Types/Types';
import { useTranslation } from 'react-i18next';
import Panel from '../../../../../Components/Panel';
import { Button } from 'react-bootstrap';
import AddPartnerModal from './AddPartnerModal';
import PartnerRow from './PartnerRow';

interface Props {
  data: Array<ArticlePartnerEntity>;
  onAdd: (data: ArticlePartnerEntity) => void;
  onDelete: (data: ArticlePartnerEntity) => void;
}

/**
 * PartnerContainer
 * @param props
 * @constructor
 */
export default function PartnerContainer(props: Props) {
  const { data, onAdd, onDelete } = props;
  const [t] = useTranslation();
  const [showAdd, setShowAdd] = React.useState<boolean>(false);
  const { allPartners } = useAppSelector((state) => state.client);

  if (allPartners !== undefined && allPartners.length > 0) {
    return (
      <Panel description={t('modules.storage.partnerPriceDescription')}>
        <>
          {data.map((item) => (
            <PartnerRow onDelete={onDelete} data={item} key={item.partnerFacilityId} />
          ))}
        </>

        <div style={{ marginTop: 20, textAlign: 'right' }}>
          <Button variant="outline-secondary" onClick={() => setShowAdd(true)}>
            <i className="fas fa-plus" style={{ marginRight: 6 }} />
            {t('modules.storage.addSpecialPartnerPrice')}
          </Button>
        </div>

        <AddPartnerModal visible={showAdd} onSave={onAdd} onClose={() => setShowAdd(false)} />
      </Panel>
    );
  }
  return null;
}
