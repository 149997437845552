import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { FacilityEntity, FacilitySettingsEntity } from '../../../../Globals/Types/Types';
import GeneralForm from './GeneralForm';
import FacilityHeader from '../Components/FacilityHeader';
import { useNavigate, useParams } from 'react-router';
import { useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import { useDispatchGetFacility, useDispatchUpdateFacility } from '../../../../Redux/Actions/Client/FacilityAction';
import isValidSettings, { FacilitySettingsError } from './ValidateSettings';
import ExternalApiPanel from './ExternalApiPanel';
import { rot13Decrypt } from '../../../../Globals/Functions';
import InvoiceForm from './InvoiceForm';

export interface State {
  fields: FacilitySettingsEntity;
  errors: FacilitySettingsError;
  initialized: boolean;
  hasChanges: boolean;
}

type Action =
  | { type: 'updateGeneral'; payload: { key: string; value: string | number } }
  | { type: 'updateInvoice'; payload: { key: string; value: string | number } }
  | { type: 'setErrors'; payload: FacilitySettingsError }
  | { type: 'init'; payload: FacilitySettingsEntity }
  | { type: 'reset' };

const initialState: State = {
  fields: {
    allowExternalApi: false,
    externalApiToken: '',
    invoiceMail: '',
    reportMail: '',
    workDays: 5,
    workDayStart: '08:00',
    workDayEnd: '17:00',
    invoiceSettings: {
      lastInvoiceNumber: 0,
      lastVoucherNumber: 0,
    },
  },
  errors: {} as FacilitySettingsError,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'updateGeneral': {
      const { key, value } = action.payload;
      return { ...state, fields: { ...state.fields, [key]: value }, hasChanges: true };
    }
    case 'updateInvoice': {
      const { key, value } = action.payload;
      return {
        ...state,
        fields: { ...state.fields, invoiceSettings: { ...state.fields.invoiceSettings, [key]: value } },
        hasChanges: true,
      };
    }

    case 'init':
      return {
        ...state,
        fields: { ...action.payload },
        initialized: true,
        hasChanges: false,
        errors: {} as FacilitySettingsError,
      };

    case 'setErrors':
      return { ...state, errors: action.payload };
  }
};

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [facility, setFacility] = React.useState<FacilityEntity>();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { facilityId } = useParams();
  const dispatchGet = useDispatchGetFacility();
  const dispatchSave = useDispatchUpdateFacility();
  const { loadingModal, setLoading, isLoading } = useLoadingModal({ delayStart: 1000 });
  const { setLoading: setInitialLoading, isLoading: isInitialLoading } = useLoadingModal();

  const initialize = React.useCallback((facilityParam: FacilityEntity) => {
    let settings = { ...facilityParam.settings };
    if (facilityParam.settings && facilityParam.settings.mailSettings) {
      settings = {
        ...facilityParam.settings,
        mailSettings: {
          ...facilityParam.settings.mailSettings,
          password: rot13Decrypt(facilityParam.settings.mailSettings.password),
        },
      };
    }

    dispatch({ type: 'init', payload: settings });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    setInitialLoading(true);
    dispatchGet(facilityId)
      .then((response) => {
        initialize(response);
        setFacility(response);
        setInitialLoading(false);
        setLoading(false);
      })
      .catch(() => {
        navigate('/dashboard');
        setLoading(false);
        setInitialLoading(false);
      });
  }, [dispatchGet, facilityId, initialize, navigate, setInitialLoading, setLoading]);

  const handleResetData = () => {
    dispatch({ type: 'init', payload: facility.settings });
  };

  const handleSave = () => {
    const { isValid, errors, settings } = isValidSettings(state.fields);
    if (isValid) {
      setLoading(true);
      dispatchSave(facilityId, { settings })
        .then((response) => {
          handleSaveAfter(response);
        })
        .catch(() => setLoading(false));
    } else {
      dispatch({ type: 'setErrors', payload: errors });
    }
  };

  const handleSaveAfter = (response: FacilityEntity) => {
    setLoading(false);
    initialize(response);
    setFacility(response);
  };

  const renderSaveButton = () => {
    if (state.hasChanges) {
      return (
        <Button variant="success" onClick={handleSave} disabled={isLoading || !state.hasChanges}>
          {t('saveChanges')}
        </Button>
      );
    }
    return null;
  };

  const renderResetButton = () => {
    if (state.hasChanges) {
      return (
        <Button variant="outline-secondary" onClick={handleResetData} disabled={isLoading || !state.hasChanges}>
          <i className="fas fa-eraser" />
        </Button>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (state.initialized) {
      return (
        <>
          <GeneralForm
            disabled={isLoading}
            onChange={({ key, value }) => dispatch({ type: 'updateGeneral', payload: { key, value } })}
            data={state.fields}
            errors={state.errors}
            showSkeleton={isInitialLoading}
          />
          <InvoiceForm
            data={state.fields}
            onChange={(key, value) => dispatch({ type: 'updateInvoice', payload: { key, value } })}
          />
          <ExternalApiPanel data={state.fields} />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <FacilityHeader actionButtonOne={renderSaveButton()} actionButtonTwo={renderResetButton()} />
      {renderContent()}
      {loadingModal}
    </>
  );
}
