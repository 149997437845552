import React from 'react';
import { useCheckRebuildIndex } from './CheckRebuildIndexHook';

/**
 * IndexListener()
 * @constructor
 */
export default function IndexListener() {
  const modal = useCheckRebuildIndex();

  return <>{modal}</>;
}
