import React from 'react';
import { ContactPersonEntity } from '../../../../Globals/Types/OrderTypes';
import Panel from '../../../Panel';
import { useTranslation } from 'react-i18next';
import AddContact from './AddContact';
import ContactRow from './ContactRow';

interface Props {
  contacts: ContactPersonEntity[];
  onChange: (contacts: ContactPersonEntity[]) => void;
  description?: string;
  allowEdit: boolean;
}

/**
 * ContactPerson()
 * @param props
 * @constructor
 */
export default function ContactPerson(props: Props) {
  const { contacts, onChange, description, allowEdit } = props;
  const [t] = useTranslation();
  const [showAdd, setShowAdd] = React.useState<boolean>(false);

  const handleOnAdd = (contact: ContactPersonEntity) => {
    if (contacts) {
      const merged = [...contacts, contact];
      onChange(merged);
    } else {
      onChange([contact]);
    }
  };

  const handleOnDelete = (contact: ContactPersonEntity) => {
    const filtered = contacts.filter((item) => item.name !== contact.name && item.contact !== contact.contact);
    onChange(filtered);
  };

  const renderContacts = () => {
    if (contacts && contacts.length > 0) {
      return contacts.map((contact) => <ContactRow contact={contact} key={contact.name} onDelete={handleOnDelete} />);
    }
    return null;
  };

  const renderAddAction = () => {
    if (allowEdit) {
      return <i className="fas fa-plus-circle" style={{ fontSize: 20 }} onClick={() => setShowAdd(true)} />;
    }
    return null;
  };

  return (
    <Panel
      headline={t('contactPersons')}
      description={description || t('modules.order.contactPersonsServiceDescription')}
      customRightAction={renderAddAction()}
    >
      {renderContacts()}
      <AddContact visible={showAdd} onClose={() => setShowAdd(false)} onAdd={handleOnAdd} />
    </Panel>
  );
}
