import React from 'react';
import { OrderEntity, ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import styled, { useTheme } from 'styled-components';
import { convertMinutesToTimeString, customerDisplayName } from '../../../../../Globals/Functions';
import { useTranslation } from 'react-i18next';
import { ReportState } from '../../../../../Globals/Types/Report';
import MontageDate from './MontageDate';
import Panel from './Panel';
import PartnerAssigned from './PartnerAssigned';
import DeliveryAddress from './DeliveryAddress';
import LoadingAddress from './LoadingAddress';
import OwnerFacility from './OwnerFacility';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

interface Props {
  order: OrderEntity;
  service: ServiceEntity;
}

/**
 * HeaderPanel()
 * @param props
 * @constructor
 */
export default function HeaderPanel(props: Props) {
  const { order, service } = props;
  const [t] = useTranslation();
  const theme = useTheme();

  const renderEstimatedTime = () => {
    let caption = t('notAvailable');
    let iconClass = 'fa-beat';
    if (service.estimatedAssemblyTime && service.estimatedAssemblyTime > 0) {
      caption = `${convertMinutesToTimeString(service.estimatedAssemblyTime)}h`;
      iconClass = '';
    }
    return <Panel icon={`fa-duotone fa-stopwatch ${iconClass}`} label={t('estimatedAssemblyTime')} caption={caption} />;
  };

  const renderState = () => {
    if (service.reportState !== ReportState.none) {
      if (service.reportState === ReportState.complete) {
        return (
          <Panel
            icon="fa-duotone fa-check-circle"
            iconColor={theme.color.green}
            label={t('state')}
            caption={t('orderSuccessful')}
          />
        );
      }
      return (
        <Panel
          icon="fa-duotone fa-diamond-exclamation fa-beat"
          iconColor={theme.color.red}
          label={t('state')}
          caption={t('orderUnsuccessful')}
        />
      );
    }
    return null;
  };

  return (
    <Container>
      {renderState()}
      <OwnerFacility order={order} />
      <PartnerAssigned order={order} service={service} />
      <MontageDate service={service} />
      <Panel icon="fa-duotone fa-user" label={t('customer')} caption={customerDisplayName(order.customer)} />
      <LoadingAddress order={order} service={service} />
      <DeliveryAddress service={service} order={order} />
      {renderEstimatedTime()}
    </Container>
  );
}
