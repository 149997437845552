import React from 'react';
import { FacilityPartnerEntity } from '../../../../../Globals/Types/Types';
import styled from 'styled-components';
import DisplayText from '../../../../View/DisplayText';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import Lodash from 'lodash';
import AskDeleteModal from '../../../../Modals/AskDeleteModal';
import { useTranslation } from 'react-i18next';
import { OrderEntity } from '../../../../../Globals/Types/OrderTypes';

const Container = styled.div`
  margin-bottom: 25px;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;

  .Name {
    flex-grow: 1;
  }
`;

const DeleteIcon = styled.div`
  i {
    &:hover {
      color: ${(props) => props.theme.color.red};
    }
  }
`;

interface Props {
  order: OrderEntity;
  partnerClientId: string;
  partnerFacilityId: string;
  onDelete: (item: FacilityPartnerEntity) => void;
}

/**
 * PartnerContainer
 * @param props
 * @constructor
 */
export default function PartnerContainer(props: Props) {
  const { partnerClientId, partnerFacilityId, onDelete, order } = props;
  const [t] = useTranslation();
  const [partner, setPartner] = React.useState<FacilityPartnerEntity>(null);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const { clientId } = useAppSelector((state) => state.auth);
  const { allPartners } = useAppSelector((state) => state.client);

  React.useEffect(() => {
    const filtered = Lodash.find(
      allPartners,
      (item) => item.partnerFacilityId === partnerFacilityId && item.partnerClientId === partnerClientId,
    );
    setPartner(filtered);
  }, [allPartners, partnerClientId, partnerFacilityId]);

  const handleDelete = () => {
    setAskDelete(false);
    onDelete(partner);
  };

  const getDeleteIcon = () => (
    <DeleteIcon onClick={() => setAskDelete(true)}>
      <i className="fas fa-trash" />
    </DeleteIcon>
  );

  if (partner && partnerClientId && partnerFacilityId && order.ownerClientId === clientId) {
    return (
      <Container>
        <DisplayText caption={t('executingPartner')} rightAction={getDeleteIcon()}>
          <UserContainer>
            <div className="Name">
              <i className="fas fa-handshake" />
              {partner.partnerName}
            </div>
          </UserContainer>
        </DisplayText>

        <AskDeleteModal
          headline={t('modules.order.deleteAssignedPartner')}
          description={t('modules.order.deleteAssignedPartnerDescription')}
          onDelete={handleDelete}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
        />
      </Container>
    );
  }
  return null;
}
