import React from 'react';
import { Tour, TourItem, TourItemType, TourService } from '../../../../Globals/Types/Tour';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { DispositionListContext } from '../index';
import AskDeleteModal from '../../../Modals/AskDeleteModal';
import { useGetTourAssignedFacilitiesString } from '../../../../Globals/Hooks/DispositionHooks';
import { ReportState } from '../../../../Globals/Types/Report';

const Container = styled.div<{ width: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;
  padding: 25px;
  border-right: 1px solid ${(props) => props.theme.border.color};

  small {
    color: ${(props) => props.theme.color.textGray};
    font-size: 12px;
  }

  .Caption {
    flex-grow: 1;
  }

  i {
    padding-right: 15px;
    color: ${(props) => lighten(0.2, props.theme.color.textGray)};
    cursor: pointer;
  }

  .fa-pencil {
    &:hover {
      color: ${(props) => props.theme.color.primaryColor};
    }
  }

  .fa-trash-alt {
    &:hover {
      color: ${(props) => props.theme.color.red};
    }
  }

  .fa-lock {
    width: 26px;
    color: ${(props) => props.theme.color.red};

    &:hover {
      color: ${(props) => darken(0.1, props.theme.color.red)};
    }
  }
  .fa-info-circle {
    padding: 0;
    font-size: 20px;
    color: ${(props) => darken(0.1, props.theme.color.orange)};
  }

  .fa-check-circle {
    width: 26px;
    color: ${(props) => props.theme.color.green};

    &:hover {
      color: ${(props) => darken(0.1, props.theme.color.green)};
    }
  }
`;

interface Props {
  tour: Tour;
  onEdit: (tour: Tour) => void;
  onDelete: (tour: Tour) => void;
  onToggleClosed: (tour: Tour) => void;
}

/**
 * TourNameContainer()
 * @param props
 * @constructor
 */
export default function TourNameContainer(props: Props) {
  const { tour, onEdit, onToggleClosed, onDelete } = props;
  const context = React.useContext(DispositionListContext);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const assignedFacilities = useGetTourAssignedFacilitiesString(tour.facilityIds, true);
  const [canDelete, setCanDelete] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (tour && tour.items) {
      tour.items.forEach((item: TourItem<TourService>) => {
        if (item.type === TourItemType.service) {
          if (item.data.reportState !== ReportState.none) {
            setCanDelete(false);
          }
        }
      });
    }
  }, [tour]);

  const renderLock = () => {
    if (tour.closed) {
      return <i className="fas fa-lock" onClick={() => onToggleClosed(tour)} />;
    }
    return <i className="fas fa-check-circle" onClick={() => onToggleClosed(tour)} />;
  };

  const renderDeleteIcon = () => {
    if (canDelete) {
      return <i className="fas fa-trash-alt" style={{ paddingRight: 0 }} onClick={() => setAskDelete(true)} />;
    }
    return null;
  };

  const renderAction = () => {
    if (!tour.closed) {
      return (
        <div>
          <i className="fas fa-pencil" onClick={() => onEdit(tour)} />
          {renderDeleteIcon()}
        </div>
      );
    }
    return null;
  };

  const renderFacilities = () => {
    if (assignedFacilities) {
      return (
        <div style={{ lineHeight: 0.8 }}>
          <small>{assignedFacilities}</small>
        </div>
      );
    }
    return null;
  };

  return (
    <Container width={context.tourNameWidth}>
      <div>{renderLock()}</div>
      <div className="Caption">
        <strong>{tour.name}</strong>
        {renderFacilities()}
      </div>
      {renderAction()}

      <AskDeleteModal onDelete={() => onDelete(tour)} onClose={() => setAskDelete(false)} visible={askDelete} />
    </Container>
  );
}
