import { ClientActions, ClientReducerInterface } from '../ClientReducer';
import {
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_GET_SUCCESS,
  CUSTOMER_GETLIST_SUCCESS,
  CUSTOMER_INVOICES_GETLIST_SUCCESS,
  CUSTOMER_MEDIA_DELETE_SUCCESS,
  CUSTOMER_MEDIA_GETLIST_SUCCESS,
  CUSTOMER_MEDIA_UPLOAD_SUCCESS,
  CUSTOMER_NOTES_CREATE_SUCCESS,
  CUSTOMER_NOTES_DELETE_SUCCESS,
  CUSTOMER_NOTES_GETLIST_SUCCESS,
  CUSTOMER_NOTES_UPDATE_SUCCESS,
  CUSTOMER_OFFERS_GETLIST_SUCCESS,
  CUSTOMER_UPDATE_SUCCESS,
} from '../../ActionTypes';
import moment from 'moment';

/**
 * Helper to handle all action for client customer dispatches
 * @param state
 * @param action
 */
export default function clientCustomerReducerHelper(
  state: ClientReducerInterface,
  action: ClientActions,
): ClientReducerInterface {
  switch (action.type) {
    case CUSTOMER_GETLIST_SUCCESS: {
      return { ...state, customers: { ...state.customers, all: action.payload } };
    }

    case CUSTOMER_GET_SUCCESS:
      return {
        ...state,
        customers: {
          ...state.customers,
          selectedCustomer: {
            customer: action.payload,
            notes: null,
            invoices: null,
            offers: null,
            media: null,
          },
        },
      };

    case CUSTOMER_UPDATE_SUCCESS: {
      if (action.payload.customerId === state.customers.selectedCustomer.customer.customerId) {
        return {
          ...state,
          customers: {
            ...state.customers,
            all: state.customers.all.map((item) =>
              item.customerId === action.payload.customerId ? action.payload : item,
            ),
            selectedCustomer: { ...state.customers.selectedCustomer, customer: action.payload },
          },
        };
      }
      return {
        ...state,
        customers: {
          ...state.customers,
          all: state.customers.all.map((item) =>
            item.customerId === action.payload.customerId ? action.payload : item,
          ),
        },
      };
    }

    case CUSTOMER_DELETE_SUCCESS: {
      if (action.payload === state.customers.selectedCustomer.customer.customerId) {
        return {
          ...state,
          customers: {
            ...state.customers,
            selectedCustomer: {
              customer: null,
              notes: null,
              invoices: null,
              offers: null,
              media: null,
            },
          },
        };
      }
      return { ...state };
    }

    case CUSTOMER_NOTES_GETLIST_SUCCESS: {
      if (action.payload.customerId === state.customers.selectedCustomer.customer.customerId) {
        return {
          ...state,
          customers: {
            ...state.customers,
            selectedCustomer: { ...state.customers.selectedCustomer, notes: action.payload.notes },
          },
        };
      }
      return { ...state };
    }
    case CUSTOMER_NOTES_CREATE_SUCCESS: {
      if (action.payload.customerId === state.customers.selectedCustomer.customer.customerId) {
        return {
          ...state,
          customers: {
            ...state.customers,
            selectedCustomer: {
              ...state.customers.selectedCustomer,
              notes: [action.payload.note, ...state.customers.selectedCustomer.notes],
            },
          },
        };
      }
      return { ...state };
    }
    case CUSTOMER_NOTES_DELETE_SUCCESS: {
      if (action.payload.customerId === state.customers.selectedCustomer.customer.customerId) {
        return {
          ...state,
          customers: {
            ...state.customers,
            selectedCustomer: {
              ...state.customers.selectedCustomer,
              notes: state.customers.selectedCustomer.notes.filter((item) => item.noteId !== action.payload.noteId),
            },
          },
        };
      }
      return { ...state };
    }
    case CUSTOMER_NOTES_UPDATE_SUCCESS: {
      if (action.payload.customerId === state.customers.selectedCustomer.customer.customerId) {
        return {
          ...state,
          customers: {
            ...state.customers,
            selectedCustomer: {
              ...state.customers.selectedCustomer,
              notes: state.customers.selectedCustomer.notes.map((item) =>
                item.noteId !== action.payload.note.noteId ? item : action.payload.note,
              ),
            },
          },
        };
      }
      return { ...state };
    }

    case CUSTOMER_INVOICES_GETLIST_SUCCESS: {
      if (action.payload.customerId === state.customers.selectedCustomer.customer.customerId) {
        return {
          ...state,
          customers: {
            ...state.customers,
            selectedCustomer: {
              ...state.customers.selectedCustomer,
              invoices: action.payload.invoices,
            },
          },
        };
      }
      return { ...state };
    }

    case CUSTOMER_OFFERS_GETLIST_SUCCESS: {
      if (action.payload.customerId === state.customers.selectedCustomer.customer.customerId) {
        return {
          ...state,
          customers: {
            ...state.customers,
            selectedCustomer: {
              ...state.customers.selectedCustomer,
              offers: action.payload.offers,
            },
          },
        };
      }
      return { ...state };
    }

    case CUSTOMER_MEDIA_GETLIST_SUCCESS: {
      if (action.payload.customerId === state.customers.selectedCustomer.customer.customerId) {
        const sortedMedia = action.payload.media.sort((a, b) =>
          moment(a.timeCreated).isSameOrBefore(moment(b.timeCreated)) ? -1 : 1,
        );

        return {
          ...state,
          customers: {
            ...state.customers,
            selectedCustomer: {
              ...state.customers.selectedCustomer,
              media: sortedMedia,
            },
          },
        };
      }
      return { ...state };
    }
    case CUSTOMER_MEDIA_DELETE_SUCCESS: {
      if (action.payload.customerId === state.customers.selectedCustomer.customer.customerId) {
        return {
          ...state,
          customers: {
            ...state.customers,
            selectedCustomer: {
              ...state.customers.selectedCustomer,
              media: state.customers.selectedCustomer.media.filter((item) => item.fullPath !== action.payload.fullPath),
            },
          },
        };
      }
      return { ...state };
    }
    case CUSTOMER_MEDIA_UPLOAD_SUCCESS: {
      if (action.payload.customerId === state.customers.selectedCustomer.customer.customerId) {
        const sortedMedia = [...state.customers.selectedCustomer.media, action.payload.media].sort((a, b) =>
          moment(a.timeCreated).isSameOrBefore(moment(b.timeCreated)) ? -1 : 1,
        );

        return {
          ...state,
          customers: {
            ...state.customers,
            selectedCustomer: {
              ...state.customers.selectedCustomer,
              media: sortedMedia,
            },
          },
        };
      }
      return { ...state };
    }
  }
}
