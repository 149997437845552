import React from 'react';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import { ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  service: ServiceEntity;
}

/**
 * PartnerHint
 * @param props
 * @constructor
 */
export default function PartnerHint(props: Props) {
  const { service } = props;
  const [t] = useTranslation();
  const { clientId } = useAppSelector((state) => state.auth);

  if (
    service.assignedPartnerClientId &&
    service.assignedPartnerFacilityId &&
    service.assignedPartnerClientId === clientId
  ) {
    return (
      <Alert>
        <i className="fas fa-info-circle" style={{ marginRight: 8 }} />
        {t('modules.order.assignedPartnerHint', { typeName: service.type.caption })}
      </Alert>
    );
  }
  return null;
}
