import React from 'react';
import { ReportPositionCheckItem } from '../../../../Globals/Types/Report';
import ItemContainer from '../ItemContainer';
import { useTheme } from 'styled-components';
import { PositionEntity, ServiceEntity } from '../../../../Globals/Types/OrderTypes';
import { ReportPositionState } from '../../../../Globals/Types/Enums';
import { useTranslation } from 'react-i18next';

interface Props {
  reportPosition: ReportPositionCheckItem;
  service: ServiceEntity;
}

/**
 * Item()
 * @param props
 * @constructor
 */
export default function Item(props: Props) {
  const { reportPosition, service } = props;
  const theme = useTheme();
  const [t] = useTranslation();
  const [servicePosition, setServicePosition] = React.useState<PositionEntity>(null);

  React.useEffect(() => {
    const found = service.positions.find((item) => item.positionId === reportPosition.servicePositionId);
    setServicePosition(found);
  }, [reportPosition, service.positions]);

  const getIcon = () => {
    switch (reportPosition.state) {
      case ReportPositionState.checked:
        return 'fas fa-check-square';
      case ReportPositionState.partial:
        return 'fas fa-exclamation-square';
      case ReportPositionState.unChecked:
        return 'fas fa-times-square';
    }
  };

  const getIconColor = () => {
    switch (reportPosition.state) {
      case ReportPositionState.checked:
        return theme.color.green;
      case ReportPositionState.partial:
        return theme.color.orange;
      case ReportPositionState.unChecked:
        return theme.color.red;
    }
  };

  const getSmallDescription = () => {
    if (reportPosition.additionalInformation) {
      return `${t('remark')}: ${reportPosition.additionalInformation}`;
    }
    return null;
  };

  if (servicePosition) {
    return (
      <ItemContainer<ReportPositionCheckItem>
        item={reportPosition}
        icon={getIcon()}
        iconColor={getIconColor()}
        caption={servicePosition.caption}
        description={servicePosition.description}
        descriptionSmall={getSmallDescription()}
        images={reportPosition.images ? reportPosition.images.map((item) => item.downloadUrl) : null}
      />
    );
  }
  return null;
}
