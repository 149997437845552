import React from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import SubNavigationHeadline from './SubNavigationHeadline';
import SubNavigationItem from './SubNavigationItem';
import { GroupKey } from '../../../Globals/Types/Types';
import { useDispatchLicenseServiceDelete } from '../../../Redux/Actions/LicenseServiceAction';
import { getAuth, signOut } from 'firebase/auth';
import ChangePasswordModal from '../../User/ChangePasswordModal';
import { userDisplayName } from '../../../Globals/Functions';
import Spacer from './Spacer';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

/**
 * ProfileSubNavigation()
 * @constructor
 */
export default function ProfileSubNavigation() {
  const [t] = useTranslation();
  const [showChangePassword, setShowChangePassword] = React.useState<boolean>(false);
  const { user } = useAppSelector((state) => state.auth);
  const { timeTracking } = useAppSelector((state) => state.auth.client.settings);
  const { groups: userGroups } = useAppSelector((state) => state.auth.user);
  const dispatchDeleteLicense = useDispatchLicenseServiceDelete();

  const handleLogout = () => {
    dispatchDeleteLicense(user).then(() => {
      signOut(getAuth()).then(() => {});
    });
  };

  return (
    <Container>
      <SubNavigationHeadline headline={userDisplayName(user)} />

      <SubNavigationItem icon="far fa-user" caption={t('profile')} path="user" />
      <SubNavigationItem icon="far fa-tasks" caption={t('tasks')} path="tasks" />
      <SubNavigationItem
        icon="far fa-stopwatch"
        caption={t('timeTrackingOverview')}
        path="timeTracking"
        visible={timeTracking && timeTracking.active && userGroups.indexOf(GroupKey.TIMETRACKING) >= 0}
      />
      <SubNavigationItem icon="far fa-calendar-star" caption={t('calendar')} path="calendar" />

      <Spacer />
      <SubNavigationItem icon="far fa-key" caption={t('changePassword')} onPress={() => setShowChangePassword(true)} />
      <SubNavigationItem icon="far fa-sign-out-alt" caption={t('logout')} onPress={handleLogout} />

      <ChangePasswordModal visible={showChangePassword} onClose={() => setShowChangePassword(false)} />
    </Container>
  );
}
