import React from 'react';
import { TourTemplateEntity } from '../../../Globals/Types/Types';
import MissingAssignmentText from '../../MissingAssignmentText';

interface Props {
  item: TourTemplateEntity;
}

/**
 * TourUsersCol
 * @param props
 * @constructor
 */
export default function TourUsersCol(props: Props) {
  const { item } = props;

  const renderContent = () => {
    if (item.users && item.users.length > 0) {
      const initials: string[] = item.users.map((user) => user.initials);
      return initials.join(', ');
    }

    return <MissingAssignmentText />;
  };

  return <td key={`${item.tourTemplateId}-users`}>{renderContent()}</td>;
}
