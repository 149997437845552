import React from 'react';
import { Report, SignatureType } from '../../../Globals/Types/Report';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import { DocumentEntity } from '../../../Globals/Types/Types';
import { customerDisplayName, userDisplayName } from '../../../Globals/Functions';
import { OrderEntity } from '../../../Globals/Types/OrderTypes';
import styled from 'styled-components';

const NameContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.border.color};
  padding-top: 8px;
`;

interface Props {
  report: Report;
  order: OrderEntity;
  signature?: DocumentEntity;
  type: SignatureType;
}

/**
 * Signature()
 * @param props
 * @constructor
 */
export default function Signature(props: Props) {
  const { signature, type, report, order } = props;
  const [t] = useTranslation();

  const getHeadline = () =>
    type === SignatureType.customer
      ? t('navigationHeaders.customerSignature')
      : t('navigationHeaders.monteurSignature');

  const getName = () =>
    type === SignatureType.monteur ? userDisplayName(report.createdUser) : customerDisplayName(order.customer);

  if (signature) {
    return (
      <Panel headline={getHeadline()}>
        <img src={signature.downloadUrl} alt={signature.downloadUrl} style={{ height: 200, objectFit: 'contain' }} />
        <NameContainer>{getName()}</NameContainer>
      </Panel>
    );
  }
  return null;
}
