import { Address } from './Types/Types';
import Config from './Config';
import axios from 'axios';

/**
 * Use Google api to get geolocation for given address. Checks if old and new address differs if old address is given.
 * So we can reduce the number of requests to google.
 * getLocationByAddress()
 * @param address
 * @param oldAddress
 */
export const getLocationByAddress = (address?: Address, oldAddress?: Address): Promise<Address> => {
  if (!address) {
    return new Promise((resolve) => resolve(address));
  }

  // If address is an interface extended with Address type, extract the mandatory fields first, otherwise the extended
  // interface will be returned completely, but we only want to return the Address part at the end, enriched with
  // the location data
  const mapped: Address = {
    street: address.street,
    streetNo: address.streetNo,
    zip: address.zip,
    city: address.city,
    location: {
      latitude: address.location ? address.location.latitude : null,
      longitude: address.location ? address.location.longitude : null,
    },
  };

  let oldAddressString;
  if (oldAddress) {
    oldAddressString = `${oldAddress.street} ${oldAddress.streetNo}, ${oldAddress.zip} ${oldAddress.city}`;
  }

  const newAddressString = `${address.street} ${address.streetNo}, ${address.zip} ${address.city}`;

  const uri = encodeURI(
    Config.google.geoCodingUrl.replace('{{apiKey}}', Config.google.apiKey).replace('{{address}}', newAddressString),
  );

  if (!oldAddress || oldAddressString !== newAddressString) {
    return axios
      .get(uri)
      .then((response) => {
        if (response.data.results && response.data.results.length > 0) {
          const result = response.data.results[0];
          const point: Address = {
            ...mapped,
            location: {
              latitude: result.geometry.location.lat,
              longitude: result.geometry.location.lng,
            },
          };

          return Promise.resolve(point);
        } else {
          return Promise.resolve(mapped);
        }
      })
      .catch(() => {
        return Promise.resolve(mapped);
      });
  } else {
    return new Promise((resolve) => resolve(mapped));
  }
};
