import React from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '../../../../Components/Panel';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { SubscriptionType } from '../../../../Globals/Types/Enums';
import moment from 'moment';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;

  i {
    font-size: 20px;
    margin-right: 20px;
    color: ${(props) => props.theme.color.orange};
  }
`;

/**
 * DemoHint()
 * @constructor
 */
export default function DemoHint() {
  const [t] = useTranslation();
  const { activeSubscription } = useAppSelector((state) => state.auth);

  const getDaysBetween = () => moment(activeSubscription.endDate).diff(moment(), 'days');

  if (activeSubscription && activeSubscription.type === SubscriptionType.demo && activeSubscription.active) {
    return (
      <Panel>
        <Container>
          <i className="fas fa-exclamation-circle" />
          <div>
            {t('modules.settings.subscription.statusDemo', {
              date: moment(activeSubscription.endDate).format('LLL'),
              days: getDaysBetween(),
            })}
          </div>
        </Container>
      </Panel>
    );
  }
  return null;
}
