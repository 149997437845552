import React from 'react';
import { OrderCustomerAddress } from '../../../Globals/Types/OrderTypes';
import DisplayText from '../../View/DisplayText';
import Text from '../../Text';
import { customerDisplayName } from '../../../Globals/Functions';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';

interface Props {
  customer: OrderCustomerAddress;
}

/**
 * CustomerBox()
 * @param props
 * @constructor
 */
export default function CustomerBox(props: Props) {
  const { customer } = props;
  const [t] = useTranslation();

  return (
    <Panel headline={t('customerInformation')} containerStyle={{ flex: 1 }}>
      <DisplayText>
        <Text bold>{customerDisplayName(customer)}</Text>
        <Text>{`${customer.street} ${customer.streetNo}`}</Text>
        <Text>{`${customer.zip} ${customer.city}`}</Text>
        <Text containerStyle={{ marginBottom: 10 }}>{t(`countryCodesToName.${customer.countryCode}`)}</Text>
      </DisplayText>

      <DisplayText visible={!!customer.mobile} icon="fad fa-phone">
        {customer.mobile}
      </DisplayText>
      <DisplayText visible={!!customer.mail} icon="fad fa-envelope">
        {customer.mail}
      </DisplayText>
    </Panel>
  );
}
