import React from 'react';
import { ResourceEntity } from '../../../Globals/Types/Types';
import { useTranslation } from 'react-i18next';
import { ResourceEntityTypeIcon } from '../../../Globals/Types/Enums';

interface Props {
  item: ResourceEntity;
}

/**
 * ResourcesTypeCol
 * @constructor
 */
export default function ResourcesTypeCol(props: Props) {
  const { item } = props;
  const [t] = useTranslation();

  return (
    <td key={`${item.resourceId}-type`}>
      <i className={ResourceEntityTypeIcon[item.type.toLowerCase()]} style={{ marginRight: 8 }} />
      {t(`modules.settings.resources.type.${item.type.toLowerCase()}`)}
    </td>
  );
}
