import { ArticleEntity, DefaultValidationResult } from '../../../../Globals/Types/Types';
import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { CreateOrUpdateError } from './CreateOrUpdate';
import { isValidPrice } from '../../../../Globals/Functions';

/**
 * isValid
 * @param article
 * @param availableArticles
 */
const isValid = (
  article: ArticleEntity,
  availableArticles: Array<ArticleEntity>,
): DefaultValidationResult<CreateOrUpdateError> => {
  let errors: CreateOrUpdateError = {} as CreateOrUpdateError;

  if (!article.caption || article.caption.length === 0) {
    errors = { ...errors, caption: InternalErrorCodes.FIELD_MISSING };
  } else {
    const filtered = availableArticles.filter(
      (item) => item.caption.toLowerCase() === article.caption.toLowerCase() && item.articleId !== article.articleId,
    );
    if (filtered.length > 0) {
      errors = { ...errors, caption: InternalErrorCodes.ARTICLE_ALREADY_EXISTING };
    }
  }

  if (article.purchasePriceNetto) {
    if (!isValidPrice(article.purchasePriceNetto.toString())) {
      errors = { ...errors, purchasePriceNetto: InternalErrorCodes.PRICE_INVALID };
    }
  }
  if (article.salesPriceNetto) {
    if (!isValidPrice(article.salesPriceNetto.toString())) {
      errors = { ...errors, salesPriceNetto: InternalErrorCodes.PRICE_INVALID };
    }
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export default isValid;
