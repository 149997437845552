import React from 'react';
import Panel from '../../Components/Panel';
import { MessageEntity, PickedUserEntity, UserEntity } from '../../Globals/Types/Types';
import SelectClientUsers from '../../Components/SelectClientUsers';
import { useTranslation } from 'react-i18next';
import Input, { InputType } from '../../Components/Inputs/Input';
import ValidateCreateMessage, { ValidateCreateMessageErrorType } from './Components/ValidateCreateMessage';
import { Button } from 'react-bootstrap';
import CreateAttachments from './Components/CreateAttachments';
import { useDispatchInboxGetList, useDispatchInboxSendMessage } from '../../Redux/Actions/User/InboxAction';
import { useAppSelector, useLoadingModal } from '../../Globals/Hooks/Hooks';
import { useLocation, useNavigate } from 'react-router';

export type State = {
  message: MessageEntity;
  files: Array<File>;
  hasChanges: Boolean;
  errors: ValidateCreateMessageErrorType;
};

type Action =
  | { type: 'set'; payload: { key: string; value: string } }
  | { type: 'setReceivers'; payload: Array<PickedUserEntity> }
  | { type: 'setSender'; payload: UserEntity }
  | { type: 'addFile'; payload: File }
  | { type: 'deleteFile'; payload: File }
  | { type: 'setErrors'; payload: ValidateCreateMessageErrorType }
  | { type: 'initForward'; payload: { subject?: string; message?: string } }
  | { type: 'initAnswer'; payload: { subject?: string; message?: string; receivers: PickedUserEntity[] } }
  | { type: 'reset' };

const initialState: State = {
  message: {} as MessageEntity,
  errors: {} as ValidateCreateMessageErrorType,
  files: [],
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'set': {
      const { key, value } = action.payload;
      return { ...state, message: { ...state.message, [key]: value }, hasChanges: true };
    }
    case 'setReceivers': {
      return { ...state, message: { ...state.message, receivers: action.payload }, hasChanges: true };
    }
    case 'addFile': {
      const fileExists = state.files.filter((file) => file.name === action.payload.name);
      if (!fileExists || fileExists.length === 0) {
        return { ...state, files: [...state.files, action.payload], hasChanges: true };
      }
      return { ...state };
    }
    case 'deleteFile': {
      return { ...state, files: state.files.filter((file) => file.name !== action.payload.name), hasChanges: true };
    }
    case 'setSender': {
      const { firstName, lastName, initials, userId } = action.payload;
      return { ...state, message: { ...state.message, sender: { firstName, lastName, initials, userId } } };
    }
    case 'setErrors': {
      return { ...state, errors: action.payload };
    }
    case 'initAnswer': {
      return {
        ...state,
        message: {
          ...state.message,
          subject: action.payload.subject,
          message: action.payload.message,
          receivers: action.payload.receivers,
        } as MessageEntity,
        hasChanges: false,
        errors: {} as ValidateCreateMessageErrorType,
      };
    }
    case 'initForward': {
      return {
        ...state,
        message: {
          ...state.message,
          subject: action.payload.subject,
          message: action.payload.message,
        } as MessageEntity,
        hasChanges: false,
        errors: {} as ValidateCreateMessageErrorType,
      };
    }
    case 'reset': {
      return { ...initialState, message: { ...initialState.message, sender: state.message.sender } };
    }
  }
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Create()
 * @constructor
 */
export default function Create() {
  const { messages } = useAppSelector((state) => state.user.inbox);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const fileRef = React.useRef<HTMLInputElement>();
  const { loadingModal, setLoading } = useLoadingModal({ delayStart: 500 });
  const queryParams = useQuery();

  const dispatchSendMessage = useDispatchInboxSendMessage();
  const dispatchGetList = useDispatchInboxGetList();

  const handleSend = React.useCallback(() => {
    const { isValid, errors } = ValidateCreateMessage(state.message);

    if (isValid) {
      setLoading(true);
      dispatchSendMessage(state.message, state.files)
        .then(() => dispatchGetList())
        .catch(() => setLoading(false))
        .finally(() => {
          setLoading(false);
          navigate('/inbox');
        });
    } else {
      dispatch({ type: 'setErrors', payload: errors });
    }
  }, [dispatchGetList, dispatchSendMessage, navigate, setLoading, state.files, state.message]);

  React.useEffect(() => {
    dispatch({ type: 'setSender', payload: user });
  }, [user]);

  React.useEffect(() => {
    if (messages) {
      if (queryParams && queryParams.get('type') && queryParams.get('reference')) {
        const referenceMessage = messages.find((item) => item.messageId === queryParams.get('reference'));
        if (queryParams.get('type') === 'answer') {
          dispatch({
            type: 'initAnswer',
            payload: {
              subject: referenceMessage.subject,
              message: referenceMessage.message,
              receivers: [referenceMessage.sender],
            },
          });
        } else {
          dispatch({
            type: 'initForward',
            payload: {
              subject: referenceMessage.subject,
              message: referenceMessage.message,
            },
          });
        }
      }
    }
  }, [messages, queryParams]);

  return (
    <>
      <Panel>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <Button
              variant={'outline-secondary'}
              style={{ borderColor: 'transparent' }}
              onClick={() => navigate('/inbox')}
            >
              {t('cancel')}
            </Button>
          </div>
          <div style={{ justifyContent: 'flex-end', display: 'flex', flex: 1 }}>
            <Button variant="outline-secondary" onClick={() => fileRef.current.click()}>
              <i className="fas fa-paperclip" />
              <input
                type="file"
                ref={fileRef}
                style={{ display: 'none' }}
                onChange={(value) => dispatch({ type: 'addFile', payload: value.target.files[0] })}
              />
            </Button>
            <Button
              variant="success"
              onClick={handleSend}
              style={{ marginLeft: 10 }}
              disabled={!state.message.subject || !state.message.receivers || state.message.receivers.length <= 0}
            >
              {t('sendMessage')}
            </Button>
          </div>
        </div>
      </Panel>

      <CreateAttachments files={state.files} onDelete={(value) => dispatch({ type: 'deleteFile', payload: value })} />

      <Panel>
        <SelectClientUsers
          onChange={(value) => dispatch({ type: 'setReceivers', payload: value as Array<PickedUserEntity> })}
          label={t('receivers')}
          hasError={!!state.errors.receivers}
          initialSelected={state.message.receivers}
        />
        <Input
          label={t('subject')}
          onChange={(value) => dispatch({ type: 'set', payload: { key: 'subject', value } })}
          value={state.message.subject}
          required
          autoFocus
          hasError={!!state.errors.subject}
        />
        <Input
          onChange={(value) => dispatch({ type: 'set', payload: { key: 'message', value } })}
          value={state.message.message}
          label={t('message')}
          type={InputType.textarea}
          rows={20}
          hasError={!!state.errors.message}
        />
      </Panel>

      {loadingModal}
    </>
  );
}
