import React from 'react';
import { useTimeTrackingIsAllowedToUse } from '../../Globals/Hooks/TimeTrackingHooks';
import Panel from '../Panel';
import Text from '../Text';
import TimeTrackingIcon from '../TimeTrackingIcon';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { TimeTrackingType } from '../../Globals/Types/Enums';

/**
 * NotPresentHint()
 * @constructor
 */
export default function NotPresentHint() {
  const isAllowed = useTimeTrackingIsAllowedToUse();
  const { lastState } = useAppSelector((state) => state.user.timeTracking);
  const [t] = useTranslation();

  if (!isAllowed) {
    return (
      <div>
        <Panel>
          <div style={{ textAlign: 'center', marginBottom: 40 }}>
            <TimeTrackingIcon type={lastState ? lastState.type : TimeTrackingType.stop} size={80} />
          </div>
          <Text centered size={20}>
            {t('modules.settings.timeTracking.notPresentHintCaption')}
          </Text>
          <Text centered light size={16}>
            {t('modules.settings.timeTracking.notPresentHintSubCaption')}
          </Text>
        </Panel>
      </div>
    );
  }
  return null;
}
