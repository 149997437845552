import React from 'react';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import { ArticleStorageStockEntity, StorageAddressEntity } from '../../../../../Globals/Types/Types';
import { Col, Row } from 'react-bootstrap';
import Input, { InputType } from '../../../../../Components/Inputs/Input';
import { useDispatchStorageAddressGetList } from '../../../../../Redux/Actions/Client/Storage/AddressAction';
import { useTranslation } from 'react-i18next';

interface Props {
  data: Array<ArticleStorageStockEntity>;
  onChange: (data: Array<ArticleStorageStockEntity>) => void;
}

enum OnChangeKey {
  onStock = 'onStock',
  storageLocation = 'storageLocation',
}

/**
 * StorageAmountInput
 * @param props
 * @constructor
 */
export default function StorageAmountInput(props: Props) {
  const { data, onChange } = props;
  const [t] = useTranslation();
  const { addresses } = useAppSelector((state) => state.storage);
  const dispatchGetStorages = useDispatchStorageAddressGetList();

  React.useEffect(() => {
    if (!addresses || addresses.length === 0) {
      dispatchGetStorages().then(() => {});
    }
  }, [dispatchGetStorages, addresses]);

  const handleOnChange = (address: StorageAddressEntity, key: OnChangeKey, value: string | number) => {
    const available = data.filter((item) => item.storageId === address.storageId);

    if (available.length === 1) {
      const mapped: Array<ArticleStorageStockEntity> = data.map((item) => {
        if (item.storageId === address.storageId) {
          return { ...item, [key]: value };
        }
        return { ...item };
      });
      onChange(mapped);
    } else {
      onChange([...data, { storageId: address.storageId, [key]: value }]);
    }
  };

  const getValueAmount = (address: StorageAddressEntity) => {
    const filtered = data.filter((item) => item.storageId === address.storageId);
    if (filtered && filtered.length === 1) {
      return filtered[0].onStock;
    }
    return undefined;
  };

  const getValueLocation = (address: StorageAddressEntity) => {
    const filtered = data.filter((item) => item.storageId === address.storageId);
    if (filtered && filtered.length === 1) {
      return filtered[0].storageLocation;
    }
    return undefined;
  };

  const renderAddress = (address: StorageAddressEntity) => {
    return (
      <Row key={address.storageId}>
        <Col xl={12} style={{ marginBottom: 10 }}>
          <h6>{address.name}</h6>
        </Col>
        <Col xl={6}>
          <Input
            onChange={(value) => handleOnChange(address, OnChangeKey.onStock, value)}
            label={t('currentStockAmount')}
            value={getValueAmount(address)}
            type={InputType.number}
          />
        </Col>
        <Col xl={6} key={address.storageId}>
          <Input
            onChange={(value) => handleOnChange(address, OnChangeKey.storageLocation, value)}
            label={t('storageLocation')}
            value={getValueLocation(address)}
          />
        </Col>
      </Row>
    );
  };

  return <>{addresses.map((item) => renderAddress(item))}</>;
}
