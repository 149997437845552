import React from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import SubNavigationHeadline from './SubNavigationHeadline';
import SubNavigationItem from './SubNavigationItem';
import { useParams } from 'react-router';
import { useDispatchCustomerGet } from '../../../Redux/Actions/CustomerAction';
import { customerBuildName } from '../../../Globals/Functions/CustomerFunctions';
import Spacer from './Spacer';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

/**
 * CustomerSubNavigation()
 * @constructor
 */
export default function CustomerSubNavigation() {
  const { customer } = useAppSelector((state) => state.client.customers.selectedCustomer);
  const { client } = useAppSelector((state) => state.auth);
  const { customerId } = useParams();
  const [t] = useTranslation();
  const dispatchGet = useDispatchCustomerGet();

  React.useEffect(() => {
    dispatchGet(customerId).then(() => {});
  }, [customerId, dispatchGet]);

  const renderInvoices = () => {
    if (client.settings && client.settings.invoice && client.settings.invoice.active) {
      return <SubNavigationItem icon="far fa-euro" caption={t('invoices')} path="invoices" />;
    }
    return null;
  };

  const renderOffers = () => {
    if (client.settings && client.settings.invoice && client.settings.invoice.active) {
      return (
        <>
          <Spacer />
          <SubNavigationItem icon="far fa-bomb" caption={t('offers')} path="offers" />
        </>
      );
    }
    return null;
  };

  if (customer) {
    return (
      <Container>
        <SubNavigationHeadline headline={customerBuildName(customer, true, t)} />

        <SubNavigationItem icon="far fa-info-circle" caption={t('basicData')} path="details" />
        <SubNavigationItem icon="far fa-clipboard-list" caption={t('orders')} path="services" />
        <SubNavigationItem icon="far fa-message-captions" caption={t('notes')} path="contacts" />
        <SubNavigationItem icon="far fa-photo-film-music" caption={t('media')} path="media" />

        {renderOffers()}
        {renderInvoices()}

        <Spacer />
        <SubNavigationItem icon="far fa-angle-double-left" caption={t('back')} path="/customer/list" />
      </Container>
    );
  }
  return null;
}
