import React from 'react';
import Panel from '../../../../Components/Panel';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import CreateOrUpdateModal from './CreateOrUpdateModal';
import { useDispatchClientUpdate } from '../../../../Redux/Actions/ClientAction';
import { OrderServiceType } from '../../../../Globals/Types/OrderTypes';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { ClientSettingsType } from '../../../../Globals/Types/Types';
import ListView from './ListView';

/**
 * ServiceTypes()
 * @constructor
 */
export default function ServiceTypes() {
  const { client } = useAppSelector((state) => state.auth);
  const { settings } = client;
  const [t] = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const dispatchUpdateClient = useDispatchClientUpdate();

  const handleCreate = React.useCallback(
    (type: OrderServiceType) => {
      let merged: ClientSettingsType;
      if (settings && settings.order) {
        if (settings.order.serviceTypeDefinition && settings.order.serviceTypeDefinition.length > 0) {
          merged = {
            ...settings,
            order: {
              ...settings.order,
              serviceTypeDefinition: [...settings.order.serviceTypeDefinition, type],
            },
          };
        } else {
          merged = {
            ...settings,
            order: {
              ...settings.order,
              serviceTypeDefinition: [type],
            },
          };
        }
      } else {
        merged = {
          ...settings,
          order: {
            reportDefinition: [],
            serviceTypeDefinition: [type],
            autoExternalId: false,
            prefixFacilityInitial: false,
            enableTimeTracking: true,
          },
        };
      }

      dispatchUpdateClient({ settings: merged }).then(() => {});
    },
    [dispatchUpdateClient, settings],
  );

  return (
    <Panel
      headline={t('modules.settings.orders.serviceTypesHeadline')}
      description={t('modules.settings.orders.serviceTypesDescription')}
    >
      <ListView types={settings.order ? settings.order.serviceTypeDefinition : null} />

      <div style={{ textAlign: 'right', marginTop: 30 }}>
        <Button variant={'success'} onClick={() => setShowModal(true)}>
          {t('createService')}
        </Button>
      </div>

      <CreateOrUpdateModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        onCreate={handleCreate}
        onUpdate={() => {}}
      />
    </Panel>
  );
}
