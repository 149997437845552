import React from 'react';
import PageHeader from '../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { buildUrlCustomersDetails, UrlPatterns } from '../../Globals/UrlFunctions';
import { useNavigate } from 'react-router';
import Table, { TableFieldProps } from '../../Components/Table/Table';
import Panel from '../../Components/Panel';
import { useDispatchCustomerGetList } from '../../Redux/Actions/CustomerAction';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { CustomerEntity } from '../../Globals/Types/Customer';
import FirstnameLastnameCompanyCol from '../../Components/Table/CustomCols/FirstnameLastnameCompanyCol';
import CustomerIconCol from '../../Components/Table/CustomCols/CustomerIconCol';
import SearchFilterButton from '../../Components/SearchFilterButton';
import Text from '../../Components/Text';

const tableMappings: TableFieldProps<CustomerEntity>[] = [
  { key: 'customerId', headline: '', customColElement: CustomerIconCol, width: 80 },
  { key: 'lastName', headline: 'name', customColElement: FirstnameLastnameCompanyCol },
];

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const { all: customers } = useAppSelector((state) => state.client.customers);
  const [items, setItems] = React.useState<CustomerEntity[]>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatchGetList = useDispatchCustomerGetList();

  const handleSearch = React.useCallback(
    (value: string) => {
      if (value && value.length >= 3 && customers && customers.length > 0) {
        const filtered = customers.filter(
          (customer) =>
            (customer.firstName && customer.firstName.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
            (customer.lastName && customer.lastName.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
            (customer.companyName && customer.companyName.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
            (customer.city && customer.city.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
            (customer.street && customer.street.toLowerCase().indexOf(value.toLowerCase()) > -1),
        );
        setItems(filtered);
      } else {
        setItems(customers);
      }
    },
    [customers],
  );

  React.useEffect(() => {
    dispatchGetList()
      .then(setItems)
      .finally(() => setLoaded(true));
  }, [dispatchGetList]);

  const renderAddButton = () => {
    return (
      <Button
        variant="success"
        onClick={() => navigate(UrlPatterns.customersCreate)}
        style={{ marginLeft: 10, padding: 15 }}
      >
        <i className="fas fa-plus-circle" />
      </Button>
    );
  };

  const renderSearchButton = () => {
    return <SearchFilterButton onChange={handleSearch} onClear={() => setItems(customers)} />;
  };

  const renderContent = () => {
    if (loaded) {
      if (items && items.length > 0) {
        return (
          <Table<CustomerEntity>
            mappings={tableMappings}
            data={items}
            keyField="customerId"
            onClickRow={(item) => navigate(buildUrlCustomersDetails(item.customerId))}
          />
        );
      }
      return (
        <Text light centered>
          {t('modules.customers.noCustomersCreated')}
        </Text>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader
        withoutPadding
        containerStyle={{ paddingLeft: 40 }}
        headline={t('customers')}
        actionButtonOne={renderAddButton()}
        actionButtonTwo={renderSearchButton()}
      />

      <div>
        <Panel>{renderContent()}</Panel>
      </div>
    </>
  );
}
