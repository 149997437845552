import React, { ReactElement } from 'react';
import TableHeader from './TableHeader';
import { TableFieldProps } from './Table';
import CustomSkeleton from '../CustomSkeleton';

type LoadingProps = {
  fields: Array<TableFieldProps<any>>;
  isLoading: boolean;
};

/**
 * LoadingPreview
 * @param props
 * @constructor
 */
export default function LoadingPreview(props: LoadingProps) {
  const { fields, isLoading } = props;
  const [row, setRow] = React.useState<ReactElement>(null);

  React.useEffect(() => {
    if (fields) {
      const data = fields.map((value, index) => (
        <td key={index}>
          <CustomSkeleton height={30} />
        </td>
      ));

      setRow(<tr>{data}</tr>);
    }
  }, [fields]);

  if (isLoading && fields) {
    return (
      <>
        <TableHeader headlines={fields} />
        <tbody>
          {row}
          {row}
          {row}
          {row}
          {row}
          {row}
        </tbody>
      </>
    );
  }
  return null;
}
