import React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Input from '../../../../Components/Inputs/Input';
import {
  useDispatchPartnerCreate,
  useDispatchPartnerGetInfos,
} from '../../../../Redux/Actions/Client/Facility/PartnerAction';
import { FacilityEntity, FacilityPartnerEntity } from '../../../../Globals/Types/Types';
import { useAppSelector, useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import { getAuth, getIdToken } from 'firebase/auth';

type AddPartnerModalProps = {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  facility: FacilityEntity;
  partners: Array<FacilityPartnerEntity>;
};

/**
 * AddPartnerModal
 * @param props
 * @constructor
 */
export default function AddPartnerModal(props: AddPartnerModalProps) {
  const { visible, onClose, facility, onSuccess, partners } = props;
  const [value, setValue] = React.useState<string>('');
  const [error, setError] = React.useState<boolean>(false);
  const [alreadyRequested, setAlreadyRequested] = React.useState<boolean>(false);
  const [sameClient, setSameClient] = React.useState<boolean>(false);
  const { isLoading, setLoading } = useLoadingModal();
  const [t] = useTranslation();
  const dispatchGetPartnerInfo = useDispatchPartnerGetInfos();
  const dispatchCreatePartner = useDispatchPartnerCreate();
  const { clientId: currentClientId } = useAppSelector((state) => state.auth);

  const hasAlreadyPartnership = React.useCallback<(facilityId: string) => boolean>(
    (facilityId) => {
      const filtered = partners.filter((partner) => {
        return partner.partnerFacilityId === facilityId;
      });

      return filtered.length > 0;
    },
  [partners],
  );

  React.useEffect(() => {
    setError(false);
    setAlreadyRequested(false);
    setSameClient(false);
    setValue('');
  }, [visible]);

  const getPartnerInfos = () => {
    setError(false);
    setAlreadyRequested(false);
    setSameClient(false);

    if (value && value.length > 0 && value !== facility.facilityId) {
      setLoading(true);

      dispatchGetPartnerInfo(value)
        .then(({ clientId, facilityId, name }) => {
          if (currentClientId !== clientId) {
            if (!hasAlreadyPartnership(facilityId)) {
              return dispatchCreatePartner(facility, clientId, facilityId, name).then(() => {
                setTimeout(() => {
                  getIdToken(getAuth().currentUser, true).then(() => {
                    onSuccess();
                    setLoading(false);
                  });
                }, 2000);
              });
            } else {
              setAlreadyRequested(true);
              setLoading(false);
            }
          } else {
            setSameClient(true);
            setLoading(false);
          }
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    }
  };

  const renderErrorHint = () => {
    if (error) {
      return (
        <Alert variant="danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          {t('modules.settings.facility.partnerCreateError')}
        </Alert>
      );
    }
    return null;
  };

  const renderAlreadyRequestedHint = () => {
    if (alreadyRequested) {
      return (
        <Alert variant="danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          {t('modules.settings.facility.partnerCreateErrorAlreadyRequested')}
        </Alert>
      );
    }
    return null;
  };

  const renderSameClientHint = () => {
    if (sameClient) {
      return (
        <Alert variant="danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          {t('modules.settings.facility.partnerCreateErrorSameClient')}
        </Alert>
      );
    }
    return null;
  };

  return (
    <Modal show={visible} animation={true} size="lg">
      <Modal.Header>
        <Modal.Title>
          <i className="fas fa-handshake" style={{ marginRight: 10 }} />
          {t('requestPartner')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ marginBottom: 30 }}>{t('modules.settings.facility.partnerCreateDescription')}</p>

        {renderErrorHint()}
        {renderAlreadyRequestedHint()}
        {renderSameClientHint()}

        <Input
          onChange={setValue}
          value={value}
          label="E-Mail Adresse / Firmenschlüssel"
          autoFocus
          hasError={error}
          disabled={isLoading}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => onClose()} disabled={isLoading}>
          {t('close')}
        </Button>
        <Button variant="success" onClick={getPartnerInfos} disabled={isLoading || !value || value.length === 0}>
          {t('requestPartner')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddPartnerModal.defaultProps = {
  description: null,
};
