import React from 'react';
import styled from 'styled-components';
import screen01 from '../../Assets/Images/iphone_01.png';
import screen02 from '../../Assets/Images/iphone_02.png';
import screen03 from '../../Assets/Images/iphone_03.png';
import screen04 from '../../Assets/Images/iphone_04.png';
import { Carousel } from 'react-bootstrap';
import { AndroidView, IOSView, isMobile } from 'react-device-detect';
import apple from '../../Assets/Images/app_store.png';
import google from '../../Assets/Images/play_store.png';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    font-size: 40px;
    font-weight: bold;
    color: ${(props) => props.theme.color.primaryColor};
  }

  @media (max-width: 600px) {
    padding: 0;

    h2 {
      margin-top: 50px;
      margin-bottom: -40px;
    }
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 1200px;
  margin: 50px 0;

  img {
    height: 500px;
    width: auto;
    margin-right: 80px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 1350px) {
    width: 100%;
    flex-direction: column;
    margin-top: 0;

    img {
      margin-right: 0;
      margin-bottom: 40px;
      width: 60%;
      height: auto;
    }
  }
`;

/**
 * AppScreenshots()
 * @constructor
 */
export default function AppScreenshots() {
  const renderMobile = () => {
    if (isMobile) {
      return (
        <Carousel>
          <Carousel.Item style={{ padding: 80 }}>
            <img src={screen01} alt="iPhone Screenshot 01" className="d-block center-block w-100" />
          </Carousel.Item>
          <Carousel.Item style={{ padding: 80 }}>
            <img src={screen02} alt="iPhone Screenshot 01" className="d-block center-block w-100" />
          </Carousel.Item>
          <Carousel.Item style={{ padding: 80 }}>
            <img src={screen03} alt="iPhone Screenshot 01" className="d-block center-block w-100" />
          </Carousel.Item>
          <Carousel.Item style={{ padding: 80 }}>
            <img src={screen04} alt="iPhone Screenshot 01" className="d-block center-block w-100" />
          </Carousel.Item>
        </Carousel>
      );
    }
    return (
      <>
        <img src={screen01} alt="iPhone Screenshot 01" />
        <img src={screen02} alt="iPhone Screenshot 01" />
        <img src={screen03} alt="iPhone Screenshot 01" />
        <img src={screen04} alt="iPhone Screenshot 01" />
      </>
    );
  };

  return (
    <Container>
      <h2 className="black">Die SotoSoft-App</h2>
      <InnerContainer>{renderMobile()}</InnerContainer>

      <IOSView>
        <a href="https://apps.apple.com/es/app/sotosoft/id1660048142" target="_blank" rel="noreferrer">
          <img src={apple} style={{ width: 200, marginBottom: 30 }} alt="Apple App-Store Logo" />
        </a>
      </IOSView>
      <AndroidView>
        <a href="https://play.google.com/store/apps/details?id=app.sotosoft" target="_blank" rel="noreferrer">
          <img src={google} style={{ width: 200, marginBottom: 30 }} alt="Google Play Store Logo" />
        </a>
      </AndroidView>
    </Container>
  );
}
