import React from 'react';
import styled from 'styled-components';
import screenshot from '../../Assets/Images/appointment_image03.jpeg';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { MobileOnlyView } from 'react-device-detect';
import Config from '../../Globals/Config';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ffffff;

  @media (max-width: 1350px) {
    padding: 0 40px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 1200px;
  margin-top: 0;

  > .CreditCard {
    color: ${(props) => lighten(0.25, props.theme.color.primaryColor)};
    margin-top: 20px;
    font-weight: bold;
  }

  > div {
    display: flex;

    &.Left {
      flex-grow: 1;
      padding-right: 50px;
      display: flex;
      flex-direction: column;

      > p {
        font-size: 18px;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    font-weight: bold;

    &.blue {
      color: ${(props) => props.theme.color.primaryColor};
    }
  }

  .Circle {
    width: 400px;
    height: 400px;
    border-radius: 20px;
    overflow: hidden;
    ${(props) => props.theme.boxShadowDark};
    margin-right: 80px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 1350px) {
    width: 100%;
    flex-flow: column;
    margin: 40px 0;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;

    .Circle {
      margin-right: 0;
      width: 400px;
      height: 400px;
      border-radius: 20px;
    }

    > div {
      &.Left {
        padding-right: 0;
        margin-top: 40px;
      }
    }
  }
`;

/**
 * PresentationRow()
 * @constructor
 */
export default function PresentationRow() {
  const [t] = useTranslation();

  const open = () =>
    window.open('https://outlook.office365.com/owa/calendar/FreepresentationSotoSoft@sotosoft.app/bookings/', '_blank');

  const openWhatsApp = () => window.open(Config.whatsAppUrl, '_blank');

  return (
    <Container>
      <InnerContainer>
        <div>
          <div className="Circle">
            <img src={screenshot} alt="Screenshot" />
          </div>
        </div>
        <div className="Left">
          <h2 className="blue">{t('modules.website.presentation.header1')}</h2>
          <h2>{t('modules.website.presentation.header2')}</h2>
          <p>{t('modules.website.presentation.description')}</p>

          <div>
            <Button variant="success" style={{ padding: 15, marginTop: 30, fontSize: 20 }} onClick={open}>
              <i className="fad fa-calendar-alt" style={{ marginRight: 10 }} />
              {t('modules.website.presentation.buttonCaption')}
            </Button>
          </div>

          <MobileOnlyView>
            <Button variant="danger" style={{ padding: 15, marginTop: 20, fontSize: 20 }} onClick={openWhatsApp}>
              <i className="fab fa-square-whatsapp" style={{ marginRight: 10 }} />
              {t(t('modules.website.presentation.whatsApp'))}
            </Button>
          </MobileOnlyView>
        </div>
      </InnerContainer>
    </Container>
  );
}
