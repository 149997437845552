import React from 'react';
import Table from '../../Table/Table';
import Row from './Row';
import { TimeTrackingEntity } from '../../../Globals/Types/Types';
import TextLink from '../../View/TextLink';
import { useTranslation } from 'react-i18next';

interface Props {
  timeTrackings: TimeTrackingEntity[];
  previewMode: boolean;
}

/**
 * TimeTrackingTable()
 * @param props
 * @constructor
 */
export default function TimeTrackingTable(props: Props) {
  const { timeTrackings, previewMode } = props;
  const [t] = useTranslation();
  const [collapsed, setCollapsed] = React.useState<boolean>(!previewMode);
  const [renderItems, setRenderItems] = React.useState<TimeTrackingEntity[]>(null);

  React.useEffect(() => {
    if (timeTrackings && timeTrackings.length > 3) {
      if (previewMode && !collapsed) {
        setRenderItems(timeTrackings.slice(0, 3));
      } else {
        setRenderItems(timeTrackings);
      }
    } else {
      setRenderItems(timeTrackings);
    }
  }, [collapsed, previewMode, timeTrackings]);

  const renderContent = () =>
    renderItems ? renderItems.map((item) => <Row timeTracking={item} key={item.timestamp} />) : null;

  const renderMoreIcon = () => {
    if (timeTrackings && timeTrackings.length > 3) {
      if (previewMode && !collapsed) {
        return (
          <div style={{ textAlign: 'center' }}>
            <i className="fad fa-ellipsis-vertical" style={{ fontSize: 40, marginTop: 20, marginBottom: 20 }} />
            <TextLink onPress={() => setCollapsed(true)} centered>
              {t('showXMore', { count: timeTrackings.length - 3 })}
            </TextLink>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div>
      <Table>
        <tbody>{renderContent()}</tbody>
      </Table>
      {renderMoreIcon()}
    </div>
  );
}

TimeTrackingTable.defaultProps = {
  previewMode: false,
};
