import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../../../Globals/FirebaseGlobals';
import { MailTemplate, MailTemplateType } from '../../../../Globals/Types/Mail';
import { useAppDispatch, useAppSelector } from '../../../../Globals/Hooks/Hooks';
import React from 'react';
import {
  FACILITY_MAIL_TEMPLATE_CREATE_ERROR,
  FACILITY_MAIL_TEMPLATE_CREATE_START,
  FACILITY_MAIL_TEMPLATE_CREATE_SUCCESS,
  FACILITY_MAIL_TEMPLATE_DELETE_ERROR,
  FACILITY_MAIL_TEMPLATE_DELETE_START,
  FACILITY_MAIL_TEMPLATE_DELETE_SUCCESS,
  FACILITY_MAIL_TEMPLATE_GETLIST_ERROR,
  FACILITY_MAIL_TEMPLATE_GETLIST_START,
  FACILITY_MAIL_TEMPLATE_GETLIST_SUCCESS,
  FACILITY_MAIL_TEMPLATE_SEND_ERROR,
  FACILITY_MAIL_TEMPLATE_SEND_START,
  FACILITY_MAIL_TEMPLATE_SEND_SUCCESS,
  FACILITY_MAIL_TEMPLATE_UPDATE_ERROR,
  FACILITY_MAIL_TEMPLATE_UPDATE_START,
  FACILITY_MAIL_TEMPLATE_UPDATE_SUCCESS,
} from '../../../ActionTypes';
import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { CountryCode } from '../../../../Globals/Types/Enums';
import { getFunctions, httpsCallable } from 'firebase/functions';

const buildCollection = (clientId: string, facilityId: string): CollectionReference<MailTemplate> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.facility,
    facilityId,
    FirebasePathMappings.mailTemplates,
  ) as CollectionReference<MailTemplate>;
};

type GetListType = (facilityId: string) => Promise<MailTemplate[]>;
/**
 * useDispatchFacilityMailTemplateGetList()
 */
export const useDispatchFacilityMailTemplateGetList = (): GetListType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListType>(
    (facilityId: string) => {
      dispatch({ type: FACILITY_MAIL_TEMPLATE_GETLIST_START, payload: { clientId, facilityId } });

      return getDocs(buildCollection(clientId, facilityId))
        .then((snapShot) => {
          if (!snapShot.empty) {
            const result: MailTemplate[] = [];
            snapShot.forEach((template) => {
              result.push({ ...template.data(), templateId: template.id });
            });
            dispatch({ type: FACILITY_MAIL_TEMPLATE_GETLIST_SUCCESS, payload: result });
            return Promise.resolve(result);
          }
          return Promise.reject(new Error(InternalErrorCodes.FIRESTORE_NO_DOCUMENTS));
        })
        .catch((error) => {
          dispatch({ type: FACILITY_MAIL_TEMPLATE_GETLIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type CreateType = (facilityId: string, template: MailTemplate) => Promise<MailTemplate>;
/**
 * useDispatchFacilityMailTemplateCreate()
 */
export const useDispatchFacilityMailTemplateCreate = (): CreateType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<CreateType>(
    (facilityId, template) => {
      dispatch({ type: FACILITY_MAIL_TEMPLATE_CREATE_START, payload: { clientId, facilityId, template } });

      return addDoc(buildCollection(clientId, facilityId), { ...template })
        .then((snapShot) => {
          const result: MailTemplate = { ...template, templateId: snapShot.id };
          dispatch({ type: FACILITY_MAIL_TEMPLATE_CREATE_SUCCESS, payload: result });
          return Promise.resolve(result);
        })
        .catch((error) => {
          dispatch({ type: FACILITY_MAIL_TEMPLATE_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type UpdateType = (facilityId: string, template: MailTemplate) => Promise<MailTemplate>;
/**
 * useDispatchFacilityMailTemplateUpdate()
 */
export const useDispatchFacilityMailTemplateUpdate = (): UpdateType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateType>(
    (facilityId, template) => {
      dispatch({ type: FACILITY_MAIL_TEMPLATE_UPDATE_START, payload: { clientId, facilityId, template } });

      const collection = buildCollection(clientId, facilityId);
      const document = doc(collection, template.templateId);

      return setDoc(document, { ...template }, { merge: true })
        .then(() => {
          dispatch({ type: FACILITY_MAIL_TEMPLATE_UPDATE_SUCCESS, payload: template });
          return Promise.resolve(template);
        })
        .catch((error) => {
          dispatch({ type: FACILITY_MAIL_TEMPLATE_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type DeleteType = (facilityId: string, templateId: string) => Promise<void>;
/**
 * useDispatchFacilityMailTemplateDelete()
 */
export const useDispatchFacilityMailTemplateDelete = (): DeleteType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<DeleteType>(
    (facilityId, templateId) => {
      dispatch({ type: FACILITY_MAIL_TEMPLATE_DELETE_START, payload: { clientId, facilityId, templateId } });

      const collection = buildCollection(clientId, facilityId);
      const document = doc(collection, templateId);

      return deleteDoc(document)
        .then(() => {
          dispatch({ type: FACILITY_MAIL_TEMPLATE_DELETE_SUCCESS });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch({ type: FACILITY_MAIL_TEMPLATE_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type HttpsSendParams = {
  clientId: string;
  facilityId: string;
  template: MailTemplateType;
  language: CountryCode;
  receiver: string;
  placeholder: object;
};
type MailSendType = (
  facilityId: string,
  template: MailTemplateType,
  language: CountryCode,
  receiver: string,
  placeholder: object,
) => Promise<boolean>;
/**
 * useDispatchFacilityMailTemplateSend()
 */
export const useDispatchFacilityMailTemplateSend = (): MailSendType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<MailSendType>(
    (facilityId, template, language, receiver, placeholder) => {
      dispatch({
        type: FACILITY_MAIL_TEMPLATE_SEND_START,
        payload: { clientId, facilityId, template, language, receiver, placeholder },
      });

      const callable = httpsCallable<HttpsSendParams, boolean>(
        getFunctions(),
        FirebaseFunctionNames.sendFacilityMailTemplate,
      );

      return callable({ clientId, facilityId, template, language, receiver, placeholder })
        .then((response) => {
          dispatch({ type: FACILITY_MAIL_TEMPLATE_SEND_SUCCESS, payload: response.data });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({ type: FACILITY_MAIL_TEMPLATE_SEND_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};
