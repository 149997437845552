import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 20px;
  font-family: 'Roboto Bold', sans-serif;
  padding: 15px 10px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.border.color};

  > .LeftContainer {
    display: flex;
    align-items: center;
    flex: 1;
    color: ${(props) => props.theme.color.primaryColor};
  }

  > .RightContainer {
  }
`;

interface Props {
  headline: string;
  actionComponent?: React.ReactElement | React.ReactElement[];
}

/**
 * SubNavigationHeadline()
 * @param props
 * @constructor
 */
export default function SubNavigationHeadline(props: Props) {
  const { headline, actionComponent } = props;

  const renderActions = () => {
    if (actionComponent) {
      return <div className="RightContainer">{actionComponent}</div>;
    }
    return null;
  };

  return (
    <Container>
      <div className="LeftContainer">{headline}</div>
      {renderActions()}
    </Container>
  );
}
