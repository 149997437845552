import React, { ReactElement } from 'react';
import PageHeader from '../../../../Components/PageHeader';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';

type FacilityHeaderProps = {
  actionButtonOne?: ReactElement;
  actionButtonTwo?: ReactElement;
  subHeadline: string;
};

/**
 * Details()
 * @constructor
 */
export default function FacilityHeader(props: FacilityHeaderProps) {
  const { facilities } = useAppSelector((state) => state.client);
  const { facilityId } = useParams();
  const [name, setName] = React.useState<string>(null);

  React.useEffect(() => {
    const item = facilities.filter((item) => item.facilityId === facilityId)[0];
    setName(item.name);
  }, [facilities, facilityId]);

  return (
    <PageHeader
      headline={name}
      description={props.subHeadline}
      actionButtonOne={props.actionButtonOne}
      actionButtonTwo={props.actionButtonTwo}
    />
  );
}

FacilityHeader.defaultProps = {
  actionButtonOne: null,
  actionButtonTwo: null,
  subHeadline: '',
};
