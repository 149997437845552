import { FacilityEntity } from '../Types/Types';
import React from 'react';
import Lodash from 'lodash';
import { useAppSelector } from './Hooks';

/**
 * useGetFacility()
 */
type GetFacilityReturnType = (facilityId?: string) => FacilityEntity;
export const useGetFacility = (): GetFacilityReturnType => {
  const { facilityId: facilityIdUser } = useAppSelector((state) => state.auth);
  const { facilities } = useAppSelector((state) => state.client);

  return React.useCallback<GetFacilityReturnType>(
    (facilityId) => {
      const facilityIdUsed: string = facilityId || facilityIdUser;
      return Lodash.find(facilities, (facility) => facility.facilityId === facilityIdUsed);
    },
    [facilityIdUser, facilities],
  );
};

/**
 * useGetFacilityWorkTimes()
 */
type GetFacilityWorkTimesReturnType = (facilityId?: string) => { workDayStart: string; workDayEnd: string };
export const useGetFacilityWorkTimes = (): GetFacilityWorkTimesReturnType => {
  const getFacility = useGetFacility();

  return React.useCallback<GetFacilityWorkTimesReturnType>(
    (facilityId) => {
      const facility = getFacility(facilityId);
      const workDayStart = facility?.settings?.workDayStart || '08:00';
      const workDayEnd = facility?.settings?.workDayEnd || '17:00';
      return { workDayStart, workDayEnd };
    },
    [getFacility],
  );
};

/**
 * useGetFacilityPartnerName()
 */
type GetFacilityPartnerReturnType = (facilityId: string, partnerFacilityId: string) => string;
export const useGetFacilityPartnerName = (): GetFacilityPartnerReturnType => {
  const { allPartners } = useAppSelector((state) => state.client);

  return React.useCallback<GetFacilityPartnerReturnType>(
    (facilityId, partnerFacilityId) => {
      const found = allPartners.find((item) => item.partnerFacilityId === partnerFacilityId);
      return found ? found.partnerName : 'Already deleted';
    },
    [allPartners],
  );
};
