import React, { ReactElement } from 'react';
import styled from 'styled-components';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import LoadingPreview from './LoadingPreview';

export type TableMappings<T> = Array<TableFieldProps<T>>;

export type TableFieldProps<T> = {
  key: string;
  headline: string;
  width?: number;
  displayPattern?: string;
  content?: ReactElement;
  customColElement?: (props: { item: T }) => JSX.Element;
  textColor?: string;
};

type TableProps<T> = {
  data?: Array<T>;
  mappings?: Array<TableFieldProps<T>>;
  children?: ReactElement | Array<JSX.Element>;
  showHeadline: boolean;
  onClickRow: (item: T) => void;
  keyField?: string;
  isLoading: boolean;
};

const Container = styled.table<{ $isClickable: boolean }>`
  width: 100%;

  td,
  th {
    padding: 12px;
  }
  tbody {
    tr {
      border-bottom: ${(props) => props.theme.table.borderBottom};
      cursor: ${(props) => (props.$isClickable ? 'pointer' : 'normal')};

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: ${(props) => props.theme.table.highlight};
      }
    }
  }

  thead {
    tr {
      border-bottom: ${(props) => props.theme.table.borderBottomHead};
    }
  }
`;

/**
 * Table()
 * @param props
 * @constructor
 */
export default function Table<T>(props: TableProps<T>) {
  const { mappings, showHeadline, data, onClickRow, children, isLoading } = props;

  const renderHeadline = () => {
    if (showHeadline) {
      return <TableHeader headlines={mappings} />;
    }
    return null;
  };

  const renderContent = () => {
    if (!isLoading) {
      if (data && mappings) {
        return (
          <>
            {renderHeadline()}
            <TableBody
              data={data}
              fields={mappings}
              onClickRow={onClickRow ? onClickRow : null}
              keyField={props.keyField}
            />
          </>
        );
      }

      return children;
    }
    return null;
  };

  return (
    <Container $isClickable={onClickRow !== null}>
      {renderContent()}
      <LoadingPreview isLoading={isLoading} fields={mappings} />
    </Container>
  );
}

Table.defaultProps = {
  data: null,
  mappings: null,
  showHeadline: true,
  onClickRow: null,
  content: null,
  children: null,
  isLoading: false,
};
