import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../../Components/PageHeader';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { AvailableReportPages, ReportConfig, reportPagesSortOrder } from '../../../../Globals/Types/Report';
import Panel from '../../../../Components/Panel';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import PageContainer from './PageContainer';
import AvailablePagesContainer from './AvailablePagesContainer';
import { ClientSettingsType } from '../../../../Globals/Types/Types';
import { useDispatchClientUpdate } from '../../../../Redux/Actions/ClientAction';
import AskDeleteModal from '../../../../Components/Modals/AskDeleteModal';
import { OrderServiceType } from '../../../../Globals/Types/OrderTypes';

export type State = {
  config: ReportConfig;
  showEmptyHint: boolean;
  isEdit: boolean;
  initialized: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'serviceTypeKey'; payload: string }
  | { type: 'addPage'; payload: AvailableReportPages }
  | { type: 'deletePage'; payload: AvailableReportPages }
  | { type: 'initEmpty'; payload: string }
  | { type: 'init'; payload: ReportConfig };

const initialState: State = {
  config: {
    serviceTypeKey: null,
    pageOrder: [],
    pageData: [],
  },
  showEmptyHint: false,
  isEdit: false,
  initialized: false,
  hasChanges: false,
};

/**
 * sortPages
 * @param pages
 */
const sortPages = (pages: AvailableReportPages[]) => {
  const sortedList: AvailableReportPages[] = [];

  reportPagesSortOrder.forEach((item) => {
    if (pages.indexOf(item) > -1) {
      sortedList.push(item);
    }
  });

  return sortedList;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'serviceTypeKey':
      return { ...state, config: { ...state.config, serviceTypeKey: action.payload } };
    case 'addPage':
      return {
        ...state,
        config: { ...state.config, pageOrder: sortPages([...state.config.pageOrder, action.payload]) },
        hasChanges: true,
        showEmptyHint: false,
      };
    case 'deletePage':
      return {
        ...state,
        config: { ...state.config, pageOrder: state.config.pageOrder.filter((page) => page !== action.payload) },
        hasChanges: true,
        showEmptyHint: false,
      };
    case 'init':
      return { ...state, config: action.payload, isEdit: true, initialized: true, showEmptyHint: false };
    case 'initEmpty':
      return {
        ...state,
        config: { serviceTypeKey: action.payload, pageOrder: ['ReportSummary', 'ReportSave'] },
        showEmptyHint: true,
        isEdit: false,
        initialized: true,
      };
  }
};

/**
 * ReportConfigPage()
 * @constructor
 */
export default function ReportConfigPage() {
  const { client } = useAppSelector((state) => state.auth);
  const { settings } = client;
  const { order } = settings;
  const { reportDefinition, serviceTypeDefinition } = order;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [serviceType, setServiceType] = React.useState<OrderServiceType>(null);
  const navigate = useNavigate();
  const { serviceTypeKey } = useParams();
  const [t] = useTranslation();
  const dispatchUpdate = useDispatchClientUpdate();

  const handleSave = React.useCallback(
    (settings: ClientSettingsType) => {
      dispatchUpdate({ settings }).then(() => {
        navigate(-1);
      });
    },
    [dispatchUpdate, navigate],
  );

  const handleMergeCreate = React.useCallback(() => {
    const mergedSettings: ClientSettingsType = {
      ...settings,
      order: {
        ...settings.order,
        reportDefinition: settings.order.reportDefinition
          ? [...settings.order.reportDefinition, state.config]
          : [state.config],
      },
    };

    handleSave(mergedSettings);
  }, [handleSave, settings, state.config]);

  const handleMergeUpdate = React.useCallback(() => {
    const mergedSettings: ClientSettingsType = {
      ...settings,
      order: {
        ...settings.order,
        reportDefinition: settings.order.reportDefinition.map((item) =>
          item.serviceTypeKey === state.config.serviceTypeKey ? state.config : item,
        ),
      },
    };

    handleSave(mergedSettings);
  }, [handleSave, settings, state.config]);

  const handleMergeDelete = React.useCallback(() => {
    const mergedSettings: ClientSettingsType = {
      ...settings,
      order: {
        ...settings.order,
        reportDefinition: settings.order.reportDefinition.filter(
          (item) => item.serviceTypeKey !== state.config.serviceTypeKey,
        ),
      },
    };

    handleSave(mergedSettings);
  }, [handleSave, settings, state.config]);

  React.useEffect(() => {
    const found = serviceTypeDefinition.find((item) => item.key === serviceTypeKey);
    setServiceType(found);
  }, [serviceTypeDefinition, serviceTypeKey]);

  React.useEffect(() => {
    if (reportDefinition) {
      const found = reportDefinition.find((item) => item.serviceTypeKey === serviceTypeKey);

      if (found) {
        dispatch({ type: 'init', payload: found });
      } else {
        dispatch({ type: 'initEmpty', payload: serviceTypeKey });
      }
    } else {
      dispatch({ type: 'initEmpty', payload: serviceTypeKey });
    }
  }, [reportDefinition, serviceTypeKey]);

  const renderEmptyInfo = () => {
    if (state.showEmptyHint) {
      return <Alert variant="warning">{t('modules.settings.orders.serviceTypeNoConfigurationHint')}</Alert>;
    }
    return null;
  };

  const renderContent = () => {
    if (state.config.pageOrder && state.config.pageOrder.length > 0) {
      return state.config.pageOrder.map((page) => (
        <PageContainer
          pageName={page}
          canDelete
          key={page}
          onDelete={(page) => dispatch({ type: 'deletePage', payload: page })}
        />
      ));
    }
    return null;
  };

  const renderDeleteButton = () => {
    if (state.isEdit) {
      return (
        <Button variant="outline-danger" onClick={() => setAskDelete(true)}>
          <i className="fas fa-trash-alt" />
        </Button>
      );
    }
    return null;
  };

  const renderSaveButton = () => {
    return (
      <Button
        variant="success"
        onClick={() => (state.isEdit ? handleMergeUpdate() : handleMergeCreate())}
        disabled={!state.hasChanges || state.config.pageOrder.length <= 2}
      >
        {t('save')}
      </Button>
    );
  };

  const renderCancelButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => navigate(-1)}>
        {t('cancel')}
      </Button>
    );
  };

  if (serviceType) {
    return (
      <>
        <PageHeader
          headline={`${t('reportConfigurator')} (${serviceType.caption})`}
          actionButtonThree={renderCancelButton()}
          actionButtonTwo={renderSaveButton()}
          actionButtonOne={renderDeleteButton()}
        />
        <Panel>{t('modules.settings.orders.serviceTypeConfigurationDescription')}</Panel>

        <Row>
          <Col xxl={6}>
            <Panel headline="Ihre Konfiguration">
              {renderEmptyInfo()}
              {renderContent()}
            </Panel>
          </Col>
          <Col xxl={6}>
            <AvailablePagesContainer
              usedPages={state.config.pageOrder}
              onAdd={(page) => dispatch({ type: 'addPage', payload: page })}
            />
          </Col>
        </Row>

        <AskDeleteModal
          onDelete={handleMergeDelete}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
          headline={t('modules.settings.orders.serviceTypeConfigurationDeleteHeadline')}
          description={t('modules.settings.orders.serviceTypeConfigurationDeleteDescription')}
        />
      </>
    );
  }
  return null;
}
