import React from 'react';
import ConfirmMessage from '../Modals/ConfirmMessage';
import { InvoiceEntity } from '../../Globals/Types/Invoice';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  invoice: InvoiceEntity;
}

/**
 * AskInvoicePayedMessage()
 * @param props
 * @constructor
 */
export default function AskInvoicePayedMessage(props: Props) {
  const { visible, onClose, invoice, onSuccess } = props;
  const [t] = useTranslation();

  return (
    <ConfirmMessage
      visible={visible}
      onCancel={onClose}
      headline={t('modules.invoice.confirmPayedHeadline')}
      description={t('modules.invoice.confirmPayedDescription', { invoiceNumber: invoice.invoiceNumber })}
      confirmCaption={t('invoicePayed')}
      onSuccess={onSuccess}
    />
  );
}
