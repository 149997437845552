import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type MessageProps = {
  headline: string;
  visible: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  description?: string;
  confirmCaption?: string;
  icon?: string;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;

  i {
    font-size: 70px;
    padding-bottom: 20px;

    &.fa-question-circle {
      color: ${(props) => props.theme.color.orange};
    }

    &.fa-check-circle {
      color: ${(props) => props.theme.color.green};
    }
  }
`;

/**
 * QuestionYesNoMessage
 * @param props
 * @constructor
 */
export default function QuestionYesNoMessage(props: MessageProps) {
  const { visible, onSuccess, onCancel, headline, description, confirmCaption, icon } = props;
  const [t] = useTranslation();

  return (
    <Modal show={visible} animation={false} centered>
      <Modal.Body>
        <Container>
          <i className={icon || 'fas fa-question-circle'} />
          <h4>{headline}</h4>
          {description || null}
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button variant="success" onClick={() => onSuccess()}>
          {confirmCaption || t('yes')}
        </Button>
        <Button variant="outline-secondary" onClick={() => onCancel()}>
          {t('no')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

QuestionYesNoMessage.defaultProps = {
  description: null,
};
