import React from 'react';
import { Tour } from '../../../../Globals/Types/Tour';
import InfoWindowContainer from './InfoWindowContainer';
import styled from 'styled-components';
import { useTimeTrackingGetIcon } from '../../../../Globals/Functions/TimeTrackingFunctions';

const Container = styled.div`
  .Detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    margin-top: 6px;

    > i {
      font-size: 12px;
      margin-right: 6px;
      margin-bottom: 0;
    }
  }
`;

interface Props {
  tour: Tour;
  onPress: () => void;
  lat: number;
  lng: number;
}

/**
 * InfoWindowTour()
 * @param props
 * @constructor
 */
export default function InfoWindowTour(props: Props) {
  const { onPress, tour } = props;
  const getIcon = useTimeTrackingGetIcon(true, 20);

  const renderResources = () => {
    if (tour.resources && tour.resources.length !== 0) {
      const resourceNames = tour.resources.map((resource) => resource.name);
      return (
        <div className="Detail">
          <i className="fas fa-car" />
          <div>{resourceNames.join(' / ')}</div>
        </div>
      );
    }

    return null;
  };

  const renderUsers = () => {
    if (tour.users && tour.users.length !== 0) {
      const userInitials = tour.users.map((user) => user.initials);
      return (
        <div className="Detail">
          <i className="fas fa-user" />
          <div>{userInitials.join(' / ')}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <InfoWindowContainer onClick={onPress} width={200}>
      <Container>
        <div className="Title">
          {getIcon(tour.lastTimeTracking.state)}
          {tour.name}
        </div>
        <div className="Content">
          {renderUsers()}
          {renderResources()}
        </div>
      </Container>
    </InfoWindowContainer>
  );
}
