import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Text from '../Text';
import { useTheme } from 'styled-components';

interface Props {
  visible: boolean;
}

/**
 * ModalHint()
 * @param props
 * @constructor
 */
export default function ModalHint(props: Props) {
  const { visible } = props;
  const theme = useTheme();
  const [t] = useTranslation();

  return (
    <Modal show={visible} centered size={'lg'}>
      <Modal.Body>
        <div style={{ padding: 40, textAlign: 'center' }}>
          <i
            className="fa-kit fa-sotosoft"
            style={{ fontSize: 80, marginBottom: 40, color: theme.color.primaryColor }}
          />

          <Text bold centered size={24} containerStyle={{ marginBottom: 30 }}>
            {t('indexMustBeRebuild')}
          </Text>
          <Text centered>{t('indexMustBeRebuildDescription')}</Text>
          <Text centered light containerStyle={{ marginTop: 20 }}>
            {t('indexCreatingDescription')}
          </Text>
        </div>
      </Modal.Body>
    </Modal>
  );
}
