import React from 'react';
import Panel from '../../../Panel';
import { useTranslation } from 'react-i18next';
import { OrderEntity } from '../../../../Globals/Types/OrderTypes';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { useDispatchInvoiceGetList } from '../../../../Redux/Actions/Client/InvoiceAction';
import { InvoiceEntity } from '../../../../Globals/Types/Invoice';
import { Alert } from 'react-bootstrap';
import ListView from '../../../Invoice/ListView';
import { ReportState } from '../../../../Globals/Types/Report';

interface Props {
  order: OrderEntity;
}

/**
 * Invoices
 * @param props
 * @constructor
 */
export default function Invoices(props: Props) {
  const { order } = props;
  const { clientId } = useAppSelector((state) => state.auth);
  const { settings } = useAppSelector((state) => state.auth.client);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const { all: allInvoices } = useAppSelector((state) => state.client.invoices);
  const [invoices, setInvoices] = React.useState<InvoiceEntity[]>(null);
  const [invoicesLoaded, setInvoicesLoaded] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatchGetInvoices = useDispatchInvoiceGetList();

  React.useEffect(() => {
    const minOneServiceCompleted = order.services.filter((item) => item.reportState !== ReportState.none);
    setIsVisible(
      settings &&
        settings.invoice &&
        settings.invoice.active &&
        order.ownerClientId === clientId &&
        minOneServiceCompleted.length > 0,
    );
  }, [clientId, order.ownerClientId, order.services, settings]);

  React.useEffect(() => {
    if (isVisible) {
      if ((!allInvoices || allInvoices.length === 0) && !invoicesLoaded) {
        dispatchGetInvoices().then(() => setInvoicesLoaded(true));
      }
    }
  }, [dispatchGetInvoices, allInvoices, invoicesLoaded, isVisible]);

  React.useEffect(() => {
    if (allInvoices && allInvoices.length > 0) {
      const filtered = allInvoices.filter((invoice) => {
        if (invoice.services) {
          const result = invoice.services.filter((item) => item.orderId === order.orderId);
          return result.length > 0;
        }
        return false;
      });
      setInvoices(filtered);
    } else {
      setInvoices([]);
    }
  }, [allInvoices, order.orderId]);

  const renderPlus = () => {
    return <i className="fas fa-plus-circle" style={{ fontSize: 20 }} onClick={() => navigate('invoice')} />;
  };

  const renderContent = () => {
    if (invoices !== null) {
      if (invoices.length === 0) {
        return <Alert variant="dark">{t('modules.invoice.serviceDetailsNoInvoice')}</Alert>;
      }

      return <ListView invoices={invoices} />;
    }
    return null;
  };

  if (isVisible) {
    return (
      <Panel
        headline={t('invoicesAll')}
        description={t('modules.invoice.orderDetailsDescription')}
        customRightAction={renderPlus()}
      >
        {renderContent()}
      </Panel>
    );
  }
  return null;
}
