import React from 'react';
import Panel from '..//Panel';
import Input from '..//Inputs/Input';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FacilityEntity } from '../../Globals/Types/Types';
import SelectCountry from '../PredefinedSelects/SelectCountry';

type ClientFormProps = {
  data: FacilityEntity;
  onChange: Function;
  disabled: boolean;
  errors: FacilityEntity;
  withPanel: boolean;
  disableName: boolean;
  showSkeleton: boolean;
};

/**
 * FacilityForm()
 * @constructor
 */
export default function FacilityForm(props: ClientFormProps) {
  const { data, onChange, disabled, showSkeleton } = props;
  const [t] = useTranslation();

  const renderContent = () => {
    return (
      <>
        <Row>
          <Col xl={9}>
            <Input
              label={t('companyName')}
              onChange={(value) => onChange({ key: 'name', value })}
              value={data.name}
              required
              disabled={props.disableName || disabled}
              hasError={!!props.errors.name}
              showSkeleton={showSkeleton}
              autoFocus
            />
          </Col>
          <Col xl={3}>
            <Input
              label={t('companyNameShort')}
              onChange={(value) => onChange({ key: 'nameShort', value })}
              value={data.nameShort}
              required
              disabled={props.disableName || disabled}
              hasError={!!props.errors.name}
              showSkeleton={showSkeleton}
              autoFocus
              contextHelpKey="companyNameShort"
            />
          </Col>
          <Col xl={9}>
            <Input
              label={t('street')}
              onChange={(value) => onChange({ key: 'street', value })}
              value={data.street}
              required
              disabled={disabled}
              hasError={!!props.errors.street}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={3}>
            <Input
              label={t('streetNo')}
              onChange={(value) => onChange({ key: 'streetNo', value })}
              value={data.streetNo}
              required
              disabled={disabled}
              hasError={!!props.errors.streetNo}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={3}>
            <Input
              label={t('zip')}
              onChange={(value) => onChange({ key: 'zip', value })}
              value={data.zip}
              required
              disabled={disabled}
              hasError={!!props.errors.zip}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={9}>
            <Input
              label={t('city')}
              onChange={(value) => onChange({ key: 'city', value })}
              value={data.city}
              required
              disabled={disabled}
              hasError={!!props.errors.city}
              showSkeleton={showSkeleton}
            />
          </Col>

          <Col xl={12}>
            <SelectCountry
              onChange={(value) => onChange({ key: 'countryCode', value })}
              required
              disabled={disabled}
              selected={data.countryCode}
              showSkeleton={showSkeleton}
            />
          </Col>

          <Col xl={6}>
            <Input
              label={t('phone')}
              onChange={(value) => onChange({ key: 'phone', value })}
              value={data.phone}
              required
              disabled={disabled}
              hasError={!!props.errors.phone}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('fax')}
              onChange={(value) => onChange({ key: 'fax', value })}
              value={data.fax}
              disabled={disabled}
              hasError={!!props.errors.fax}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('eMailAddress')}
              onChange={(value) => onChange({ key: 'mail', value })}
              value={data.mail}
              required
              disabled={disabled}
              hasError={!!props.errors.mail}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('website')}
              onChange={(value) => onChange({ key: 'website', value })}
              value={data.website}
              disabled={disabled}
              hasError={!!props.errors.website}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('vat')}
              onChange={(value) => onChange({ key: 'vat', value })}
              value={data.vat}
              required
              disabled={disabled}
              hasError={!!props.errors.vat}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('devoutIdentificationNumber')}
              onChange={(value) => onChange({ key: 'devoutIdentificationNumber', value })}
              value={data.devoutIdentificationNumber}
              disabled={disabled}
              hasError={!!props.errors.devoutIdentificationNumber}
              showSkeleton={showSkeleton}
            />
          </Col>
        </Row>
      </>
    );
  };

  if (props.withPanel) {
    return (
      <Panel headline={t('companyData')} description={t('modules.settings.clientDescription')}>
        {renderContent()}
      </Panel>
    );
  } else {
    return renderContent();
  }
}

FacilityForm.defaultProps = {
  errors: {},
  withPanel: true,
  disableName: true,
  showSkeleton: false,
};
