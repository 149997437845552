import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Input, { InputType } from '../../Inputs/Input';
import File from '../../Inputs/File';
import MessageAccessSelect, { OnChangeResult } from '../Messages/MessageAccessSelect';
import { OrderEntity, OrderMessageEntity } from '../../../Globals/Types/OrderTypes';
import {
  useDispatchOrderMessageCreate,
  useDispatchOrderMessageGetList,
} from '../../../Redux/Actions/Order/MessageAction';
import { UserEntity } from '../../../Globals/Types/Types';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import moment from 'moment';
import { userEntityToPickedUserEntity } from '../../../Globals/Functions';

interface Props {
  order: OrderEntity;
  visible: boolean;
  onClose: () => void;
}

export type State = {
  message: OrderMessageEntity;
  attachment: File | null;
  hasChanges: boolean;
};

type Action =
  | { type: 'init'; payload: { user: UserEntity } }
  | { type: 'message'; payload: string }
  | { type: 'attachment'; payload: File }
  | { type: 'access'; payload: OnChangeResult }
  | { type: 'clear'; payload: { user: UserEntity } };

const initialState: State = {
  message: {
    isPublic: true,
    hasAttachment: false,
  } as OrderMessageEntity,
  attachment: null,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'message':
      return {
        ...state,
        message: { ...state.message, message: action.payload },
        hasChanges: true,
      };
    case 'attachment':
      return {
        ...state,
        attachment: action.payload,
        message: {
          ...state.message,
          hasAttachment: !!action.payload,
        },
        hasChanges: true,
      };
    case 'access':
      return {
        ...state,
        message: {
          ...state.message,
          assignedFacilities: action.payload.facilities,
          assignedUsers: action.payload.users,
          isPublic: action.payload.facilities.length === 0 && action.payload.users.length === 0,
        },
        hasChanges: true,
      };
    case 'clear':
    case 'init': {
      const { user } = action.payload;
      return {
        ...initialState,
        message: {
          ...initialState.message,
          createdUser: userEntityToPickedUserEntity(user),
        },
      };
    }
  }
};

/**
 * CreateMessageModal
 * @param props
 * @constructor
 */
export default function CreateMessageModal(props: Props) {
  const { visible, onClose, order } = props;
  const { user } = useAppSelector((state) => state.auth);
  const [error, setError] = React.useState<string>(null);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { isLoading, setLoading } = useLoadingModal();
  const [t] = useTranslation();
  const dispatchCreate = useDispatchOrderMessageCreate();
  const dispatchGetList = useDispatchOrderMessageGetList();

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear', payload: { user } });
    }
  }, [user, visible]);

  React.useEffect(() => {
    dispatch({ type: 'init', payload: { user } });
  }, [user]);

  const handleCreate = () => {
    setError(null);

    if (state.message.message && state.message.message.length > 0) {
      setLoading(true);
      const mappedMessage: OrderMessageEntity = {
        ...state.message,
        createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      };
      dispatchCreate(order, mappedMessage, state.attachment)
        .then(() => {
          return dispatchGetList(order).then(() => {
            setLoading(false);
            onClose();
          });
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setError(InternalErrorCodes.FIELD_MISSING);
    }
  };

  if (visible) {
    return (
      <Modal show={visible} animation={false} size="lg">
        <Modal.Header>
          <Modal.Title>{t('modules.order.createMessage')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MessageAccessSelect
            order={order}
            onChange={(value) => dispatch({ type: 'access', payload: value })}
            disabled={isLoading}
          />
          <Input
            onChange={(value) => dispatch({ type: 'message', payload: value })}
            value={state.message.message}
            label={t('modules.order.message')}
            type={InputType.textarea}
            rows={8}
            autoFocus
            hasError={!!error}
            disabled={isLoading}
          />
          <File
            onChange={(value) => dispatch({ type: 'attachment', payload: value })}
            label={t('modules.order.messageAttachment')}
            disabled={isLoading}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCreate} disabled={!state.hasChanges || isLoading}>
            {t('save')}
          </Button>
          <Button variant="outline-secondary" onClick={() => onClose()} disabled={isLoading}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
