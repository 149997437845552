import { CreateOrderState } from '../../../Globals/Types/OrderTypes';
import { DefaultHeyKitchenPartnerEntity } from '../../../Globals/Types/General';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';

export interface ValidateMontageDateResult {
  result: boolean;
  errors: {
    montageDate: string;
  };
}

/**
 * validateStorageOptions
 * @param data
 */
export const validateMontageDate = (data: CreateOrderState): ValidateMontageDateResult => {
  let returnValue: ValidateMontageDateResult = {
    result: true,
    errors: {
      montageDate: undefined,
    },
  };

  if (data.order.ownerClientId === DefaultHeyKitchenPartnerEntity.partnerClientId) {
    if (!data.executionDate) {
      returnValue = {
        ...returnValue,
        result: false,
        errors: { ...returnValue.errors, montageDate: InternalErrorCodes.FIELD_MISSING },
      };
    }
  }

  return returnValue;
};
