import React from 'react';
import DisplayText from '../../View/DisplayText';
import Text from '../../Text';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import { FacilityEntity } from '../../../Globals/Types/Types';

interface Props {
  facility: FacilityEntity;
}

/**
 * FacilityBox()
 * @param props
 * @constructor
 */
export default function FacilityBox(props: Props) {
  const { facility } = props;
  const [t] = useTranslation();

  return (
    <Panel headline={t('executedOrderBy')}>
      <DisplayText>
        <Text bold>{facility.name}</Text>
        <Text>{`${facility.street} ${facility.streetNo}`}</Text>
        <Text>{`${facility.zip} ${facility.city}`}</Text>
        <Text containerStyle={{ marginBottom: 10 }}>{t(`countryCodesToName.${facility.countryCode}`)}</Text>
      </DisplayText>

      <DisplayText visible={!!facility.phone} icon="fad fa-phone">
        {facility.phone}
      </DisplayText>
      <DisplayText visible={!!facility.mail} icon="fad fa-envelope">
        {facility.mail}
      </DisplayText>
      <DisplayText visible={!!facility.website} icon="fad fa-globe">
        {facility.website}
      </DisplayText>
    </Panel>
  );
}
