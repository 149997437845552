import React from 'react';
import { TimeTrackingEntity } from '../../../Globals/Types/Types';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';
import { TimeTrackingType } from '../../../Globals/Types/Enums';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
  item: TimeTrackingEntity;
};

const Container = styled.td`
  > small {
    color: ${(props) => props.theme.color.textGray};
  }
`;

/**
 * UserTimeTrackingTimeRow()
 * @param props
 * @constructor
 */
export default function UserTimeTrackingTimeRow(props: Props) {
  const { item } = props;
  const [t] = useTranslation();

  const getString = () => {
    const typedTimestamp: Timestamp = item.timestamp as Timestamp;
    const time = moment(typedTimestamp.toDate()).format('HH:mm');

    switch (item.type) {
      case TimeTrackingType.start:
        return t('modules.settings.timeTracking.userStateStart', { time });
      case TimeTrackingType.pause:
        return t('modules.settings.timeTracking.userStatePause', { time });
      case TimeTrackingType.stop:
        return t('modules.settings.timeTracking.userStateStop', { time });
    }
  };

  const renderTemporary = () => {
    if (item.temporary) {
      const typedTimestamp: Timestamp = item.temporaryTimestamp as Timestamp;

      if (typedTimestamp) {
        return (
          <small>
            <i className="fas fa-exclamation text-danger" style={{ marginRight: 10 }} />
            {t('modules.settings.timeTracking.temporary', {
              time: moment(typedTimestamp.toDate()).format('HH:mm'),
            })}
          </small>
        );
      }
    }
    return null;
  };

  const renderManualHint = () => {
    if (item.manual) {
      return <small>{t('manualCreatedBy', item.manualCreatedUser)}</small>;
    }
    return null;
  };

  return (
    <Container key={`${item.id}-TimeRow`}>
      <div>{getString()}</div>
      {renderTemporary()}
      {renderManualHint()}
    </Container>
  );
}
