import React from 'react';
import { TourItem, TourService } from '../../../Globals/Types/Tour';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGetTimeOptions } from '../../../Globals/Hooks/Hooks';
import Select from '../../Inputs/Select';
import moment from 'moment';
import { ElasticSearchServiceInterface } from '../../../Globals/Types/OrderTypes';
import DisplayText from '../../View/DisplayText';
import { convertMinutesToTimeString } from '../../../Globals/Functions';

interface Props {
  startTime: string;
  endTime: string;
  tourItem: TourItem<TourService>;
  onChangeStart: (time: string) => void;
  onChangeEnd: (time: string) => void;
  service: ElasticSearchServiceInterface;
}

/**
 * TimeHandler()
 * @param props
 * @constructor
 */
export default function TimeHandler(props: Props) {
  const { tourItem, startTime, endTime, onChangeEnd, onChangeStart, service } = props;
  const [usedAssemblyTime, setUsedAssemblyTime] = React.useState<number>(null);
  const [remainingAssemblyTime, setRemainingAssemblyTime] = React.useState<number>(null);
  const [selectedAssemblyTime, setSelectedAssemblyTime] = React.useState<number>(null);
  const [t] = useTranslation();
  const { options: minuteOptions, getTimeOptionsRange } = useGetTimeOptions(10);
  const [endMinuteOptions, setEndMinuteOptions] = React.useState(minuteOptions);

  React.useEffect(() => {
    const time = Math.abs(moment(tourItem.startTime, 'HH:mm:ss').diff(moment(tourItem.endTime, 'HH:mm:ss'), 'minute'));
    setUsedAssemblyTime(time);
  }, [tourItem.endTime, tourItem.startTime]);

  React.useEffect(() => {
    setRemainingAssemblyTime(usedAssemblyTime - selectedAssemblyTime + service.remainingAssemblyTime);
  }, [selectedAssemblyTime, service.remainingAssemblyTime, usedAssemblyTime]);

  React.useEffect(() => {
    const time = Math.abs(moment(startTime, 'HH:mm').diff(moment(endTime, 'HH:mm'), 'minute'));
    setSelectedAssemblyTime(time);
  }, [endTime, startTime]);

  React.useEffect(() => {
    const time = moment(startTime, 'HH:mm')
      .add(selectedAssemblyTime + remainingAssemblyTime, 'minute')
      .format('H:mm');
    const newEndTime = moment(time, 'H:mm').isBefore(moment(startTime, 'HH:mm:ss')) ? '23:50' : time;

    const options = getTimeOptionsRange(moment(startTime, 'HH:mm:ss').format('H:mm'), newEndTime);
    setEndMinuteOptions(options);
  }, [getTimeOptionsRange, remainingAssemblyTime, selectedAssemblyTime, startTime]);

  return (
    <Row>
      <Col xxl={6}>
        <Select
          options={minuteOptions}
          onChange={(time) => onChangeStart(moment(time, 'HH:mm').format('HH:mm:ss'))}
          label={t('timeStart')}
          initialValue={moment(startTime, 'HH:mm:ss').format('HH:mm')}
        />
      </Col>
      <Col xxl={6}>
        <Select
          options={endMinuteOptions}
          onChange={(time) => onChangeEnd(moment(time, 'HH:mm').format('HH:mm:ss'))}
          label={t('timeEnd')}
          initialValue={moment(endTime, 'HH:mm:ss').format('HH:mm')}
        />
      </Col>
      <Col xxl={12}>
        <DisplayText caption={t('modules.disposition.remainingPlanTime')} horizontal whiteBackground>
          {`${convertMinutesToTimeString(remainingAssemblyTime)}h`}
        </DisplayText>
      </Col>
    </Row>
  );
}
