import React from 'react';
import PageHeader from '../../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import {
  useDispatchCustomerMediaDelete,
  useDispatchCustomerMediaGetList,
} from '../../../Redux/Actions/Customer/MediaAction';
import { useParams } from 'react-router';
import File from '../../../Components/Inputs/File';
import UploadModal from './UploadModal';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import MediaList from '../../../Components/MediaList';
import { AbstractMedia } from '../../../Globals/Types/Types';
import Panel from '../../../Components/Panel';
import styled, { useTheme } from 'styled-components';

const HintText = styled.div`
  text-align: center;
  color: ${(props) => props.theme.color.textGray};
`;

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const { customerId } = useParams();
  const theme = useTheme();
  const [t] = useTranslation();
  const { setLoading, isLoading } = useLoadingModal();
  const { media } = useAppSelector((state) => state.client.customers.selectedCustomer);
  const dispatchGetList = useDispatchCustomerMediaGetList();
  const dispatchDelete = useDispatchCustomerMediaDelete();
  const [file, setFile] = React.useState<File>(null);
  const inputRef = React.useRef<HTMLInputElement>();

  const handleDelete = React.useCallback(
    (param: AbstractMedia) => {
      dispatchDelete(customerId, param.fullPath).then(() => {});
    },
    [customerId, dispatchDelete],
  );

  React.useEffect(() => {
    if (media === null) {
      setLoading(true);
      dispatchGetList(customerId)
        .then(() => {})
        .finally(() => setLoading(false));
    }
  }, [customerId, dispatchGetList, media, setLoading]);

  const renderAddButton = () => {
    return (
      <Button variant="success" onClick={() => inputRef.current.click()} style={{ marginLeft: 10 }}>
        <i className="fas fa-plus-circle" />
      </Button>
    );
  };

  const handleClose = () => {
    setFile(null);
  };

  const renderUploadInput = () => {
    if (!file) {
      return (
        <input ref={inputRef} type="file" onChange={(e) => setFile(e.target.files[0])} style={{ display: 'none' }} />
      );
    }
    return null;
  };

  const renderLoading = () => {
    if (isLoading) {
      return (
        <i className="fas fa-spin fa-sync" style={{ color: theme.color.textGray, fontSize: 24, marginRight: 15 }} />
      );
    }
  };

  const renderContent = () => {
    if (media !== null) {
      if (media && media.length > 0) {
        return <MediaList media={media} onDelete={handleDelete} />;
      }
    }
    return null;
  };

  const renderEmptyHint = () => {
    if (media && media.length === 0) {
      return (
        <Panel>
          <HintText>{t('modules.customers.noMediaAvailable')}</HintText>
        </Panel>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader
        headline={t('media')}
        description={t('modules.customers.mediaDescription')}
        actionButtonTwo={renderAddButton()}
        actionButtonOne={renderLoading()}
      />
      {renderContent()}
      {renderEmptyHint()}

      {renderUploadInput()}
      <UploadModal onClose={handleClose} file={file} customerId={customerId} />
    </>
  );
}
