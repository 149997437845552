import React from 'react';
import { PositionExternalData } from '../../../../Globals/Types/OrderTypes';
import styled from 'styled-components';
import Input from '../../../Inputs/Input';
import { useTranslation } from 'react-i18next';
import AskDeleteModal from '../../../Modals/AskDeleteModal';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 12px;
`;

const KeyInputContainer = styled.div`
  flex-grow: 1;
  margin-right: 20px;
`;

const ValueInputContainer = styled.div`
  flex-grow: 1;
`;

const DeleteIconContainer = styled.div`
  display: flex;
  margin-left: 10px;
`;

const IconButton = styled.div`
  display: flex;
  padding: 10px 18px;
  font-size: 18px;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
  border-radius: ${(props) => props.theme.border.radius};
  color: gray;

  &:hover {
    background-color: ${(props) => props.theme.color.red};
    color: #ffffff;
  }

  &:last-child {
    margin-right: 0;
  }
`;

interface Props {
  data: PositionExternalData;
  onDelete: (data: PositionExternalData) => void;
}

/**
 * ExternalDataRow
 * @param props
 * @constructor
 */
export default function ExternalDataRow(props: Props) {
  const { data, onDelete } = props;
  const [t] = useTranslation();
  const [showDelete, setShowDelete] = React.useState<boolean>(false);

  return (
    <Container>
      <KeyInputContainer>
        <Input onChange={() => {}} label={t('caption')} value={data.key} removeBottomMargin disabled />
      </KeyInputContainer>
      <ValueInputContainer>
        <Input onChange={() => {}} label={t('value')} value={data.value} removeBottomMargin disabled />
      </ValueInputContainer>
      <DeleteIconContainer onClick={() => setShowDelete(true)}>
        <IconButton>
          <i className="fas fa-trash-alt" />
        </IconButton>
      </DeleteIconContainer>

      <AskDeleteModal onDelete={() => onDelete(data)} onClose={() => setShowDelete(false)} visible={showDelete} />
    </Container>
  );
}
