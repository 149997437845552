import React from 'react';
import { StorageReference } from '@firebase/storage';
import DisplayText from '../../../View/DisplayText';
import { useTranslation } from 'react-i18next';
import { getFileTypeIcon } from '../../../../Globals/Functions';
import styled from 'styled-components';
import { useDispatchDocumentGetDownloadUrl } from '../../../../Redux/Actions/GlobalStorageActions';
import AskDeleteModal from '../../../Modals/AskDeleteModal';

const DownloadIcon = styled.i`
  font-size: 16px;
  margin-left: 20px;

  &:hover {
    color: ${(props) => props.theme.color.green};
  }
`;

const DeleteIcon = styled.i`
  font-size: 16px;
  margin-left: 8px;

  &:hover {
    color: ${(props) => props.theme.color.red};
  }
`;

interface Props {
  document: StorageReference;
  showDelete: boolean;
  onDelete: (item: StorageReference) => void;
}

/**
 * Documents
 * @param props
 * @constructor
 */
export function Row(props: Props) {
  const { document, showDelete, onDelete } = props;
  const [t] = useTranslation();
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const dispatchGetDownloadUrl = useDispatchDocumentGetDownloadUrl();

  const handleOnDownload = (ref: StorageReference) => {
    dispatchGetDownloadUrl(ref.fullPath).then((uri) => {
      window.open(uri);
    });
  };

  const handleOnDelete = () => {
    setAskDelete(false);
    onDelete(document);
  };

  const renderDelete = () =>
    showDelete ? <DeleteIcon className="fas fa-trash-can" onClick={() => setAskDelete(true)} /> : null;

  const renderActionIcons = (document: StorageReference) => {
    return (
      <>
        <DownloadIcon className="fas fa-cloud-download" onClick={() => handleOnDownload(document)} />
        {renderDelete()}
      </>
    );
  };

  return (
    <DisplayText
      icon={getFileTypeIcon(document.name)}
      allowLineBreak={false}
      rightAction={renderActionIcons(document)}
      key={document.fullPath}
    >
      <>
        {document.name}

        <AskDeleteModal
          onDelete={handleOnDelete}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
          headline={t('documentDelete')}
          description={t('documentDeleteDescription', { name: document.name })}
          confirmButtonText="deleteFinally"
        />
      </>
    </DisplayText>
  );
}
