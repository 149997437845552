import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Input from '../../../../Components/Inputs/Input';
import Panel from '../../../../Components/Panel';
import { FacilitySettingsEntity } from '../../../../Globals/Types/Types';
import { FacilitySettingsError } from './ValidateSettings';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';

type Props = {
  data: FacilitySettingsEntity;
  onChange: (key: string, value: string) => void;
  errors: FacilitySettingsError;
  showSkeleton: boolean;
};

/**
 * InvoiceForm()
 * @constructor
 */
export default function InvoiceForm(props: Props) {
  const { data, onChange, showSkeleton } = props;
  const { settings } = useAppSelector((state) => state.auth.client);
  const [t] = useTranslation();

  const renderContent = () => {
    return (
      <>
        <Row>
          <Col xl={6}>
            <Input
              label={t('invoiceNumberCurrent')}
              onChange={() => {}}
              value={
                data.invoiceSettings && data.invoiceSettings.lastInvoiceNumber
                  ? data.invoiceSettings.lastInvoiceNumber
                  : 'N/A'
              }
              disabled={true}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('voucherNumberCurrent')}
              onChange={() => {}}
              value={
                data.invoiceSettings && data.invoiceSettings.lastVoucherNumber
                  ? data.invoiceSettings.lastVoucherNumber
                  : 'N/A'
              }
              disabled={true}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('invoiceNumberPrefix')}
              onChange={(value) => onChange('invoiceNumberPrefix', value)}
              value={data.invoiceSettings ? data.invoiceSettings.invoiceNumberPrefix : ''}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('invoiceNumberSuffix')}
              onChange={(value) => onChange('invoiceNumberSuffix', value)}
              value={data.invoiceSettings ? data.invoiceSettings.invoiceNumberSuffix : ''}
              showSkeleton={showSkeleton}
            />
          </Col>
        </Row>
      </>
    );
  };

  if (settings.invoice && settings.invoice.active) {
    return (
      <Panel headline={t('invoices')} description={t('modules.settings.facility.invoiceSettingsDescription')}>
        {renderContent()}
      </Panel>
    );
  }
  return null;
}

InvoiceForm.defaultProps = {
  errors: {},
  withPanel: true,
  showSkeleton: false,
};
