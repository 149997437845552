import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ centered: boolean }>`
  color: ${(props) => props.theme.color.primaryColor};
  cursor: pointer;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};

  &:hover {
    text-decoration: underline;
  }
`;

interface Props {
  children: string;
  onPress: () => void;
  centered?: boolean;
  containerStyle?: object;
  visible?: boolean;
}

/**
 * TextLink()
 * @param props
 * @constructor
 */
export default function TextLink(props: Props) {
  const { children, onPress, centered, containerStyle, visible } = props;

  if (visible) {
    return (
      <Container centered={centered} style={containerStyle} onClick={onPress}>
        {children}
      </Container>
    );
  }
  return null;
}

TextLink.defaultProps = {
  centered: false,
  containerStyle: {},
  visible: true,
};
