import React from 'react';
import { InvoiceEntity } from '../../../../../Globals/Types/Invoice';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatchInvoiceSendMail } from '../../../../../Redux/Actions/Client/InvoiceAction';
import { CountryCode } from '../../../../../Globals/Types/Enums';
import { useGetFacility } from '../../../../../Globals/Hooks/FacilityHooks';
import { FacilityEntity } from '../../../../../Globals/Types/Types';
import SendMailModal from '../SendMailModal';
import DropDownItem from '../../../../../Components/CustomDropDown/DropDownItem';
import MailHistoryModal from '../MailHistoryModal';

interface Props {
  invoice: InvoiceEntity;
  onSending: (sending: boolean) => void;
  onUpdate: (invoice: InvoiceEntity) => void;
}

/**
 * InvoiceSendMailButton()
 * @param props
 * @constructor
 */
export default function InvoiceSendMailButton(props: Props) {
  const { invoice, onSending, onUpdate } = props;
  const [t] = useTranslation();
  const [facility, setFacility] = React.useState<FacilityEntity>(null);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showHistory, setShowHistory] = React.useState<boolean>(false);
  const dispatchSendMail = useDispatchInvoiceSendMail();
  const getFacility = useGetFacility();

  const sendMail = React.useCallback(
    (receiverMail: string, language: CountryCode) => {
      onSending(true);
      setShowModal(false);

      dispatchSendMail(invoice.invoiceId, receiverMail, language)
        .then(onUpdate)
        .finally(() => {
          onSending(false);
        });
    },
    [dispatchSendMail, invoice.invoiceId, onSending, onUpdate],
  );

  React.useEffect(() => {
    setFacility(getFacility(invoice.facilityId));
  }, [getFacility, invoice.facilityId]);

  const renderMailHistory = () => {
    if (invoice.mailTracking && invoice.mailTracking.length > 0) {
      return (
        <DropDownItem onClick={() => setShowHistory(true)}>
          <i className="fa-sharp fa-solid fa-clock-rotate-left" />
          {t('mailHistory')}
        </DropDownItem>
      );
    }
    return null;
  };

  const renderButton = () => {
    return (
      <React.Fragment key="sendMail">
        <Dropdown.Divider />
        <DropDownItem onClick={() => setShowModal(true)}>
          <i className="fas fa-envelope" />
          {t('sendByMail')}
        </DropDownItem>
        {renderMailHistory()}
      </React.Fragment>
    );
  };

  if (facility && facility.settings.mailSettings && facility.settings.mailSettings.validated) {
    return (
      <React.Fragment key="sendMail">
        {renderButton()}
        <SendMailModal invoice={invoice} onSend={sendMail} onClose={() => setShowModal(false)} visible={showModal} />
        <MailHistoryModal invoice={invoice} onClose={() => setShowHistory(false)} visible={showHistory} />
      </React.Fragment>
    );
  }
  return null;
}
