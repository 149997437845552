import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import PageHeader from '../../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import Panel from '../../../Components/Panel';
import { Button } from 'react-bootstrap';
import ListView from '../../../Components/Offer/ListView';
import styled, { useTheme } from 'styled-components';
import { useDispatchCustomerOffersGetList } from '../../../Redux/Actions/Customer/OffersAction';
import { buildUrlCustomersOffersDetails } from '../../../Globals/UrlFunctions';

const HintText = styled.div`
  text-align: center;
  color: ${(props) => props.theme.color.textGray};
`;

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const theme = useTheme();
  const { isLoading, setLoading } = useLoadingModal();
  const { customerId } = useParams();
  const [offersLoaded, setOffersLoaded] = React.useState<boolean>(false);
  const { offers } = useAppSelector((state) => state.client.customers.selectedCustomer);
  const dispatchGetList = useDispatchCustomerOffersGetList();

  React.useEffect(() => {
    if (!offersLoaded) {
      setLoading(true);
      dispatchGetList(customerId)
        .then(() => setOffersLoaded(true))
        .finally(() => setLoading(false));
    }
  }, [customerId, dispatchGetList, offersLoaded, setLoading]);

  const renderAddButton = () => {
    return (
      <Button variant="success" onClick={() => navigate('create')} style={{ marginLeft: 10 }}>
        {t('offerCreate')}
      </Button>
    );
  };

  const renderLoading = () => {
    if (isLoading) {
      return (
        <i className="fas fa-spin fa-sync" style={{ color: theme.color.textGray, fontSize: 24, marginRight: 15 }} />
      );
    }
  };

  const renderContent = () => {
    if (offersLoaded) {
      if (offers && offers.length > 0) {
        return (
          <Panel>
            <ListView
              offers={offers}
              onPress={(offer) => navigate(buildUrlCustomersOffersDetails(customerId, offer.offerId))}
            />
          </Panel>
        );
      }

      return (
        <Panel>
          <HintText>{t('modules.customers.noOffersAvailable')}</HintText>
        </Panel>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader
        headline={t('offers')}
        description={t('modules.customers.offerDescription')}
        actionButtonTwo={renderAddButton()}
        actionButtonOne={renderLoading()}
      />
      {renderContent()}
    </>
  );
}
