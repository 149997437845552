import { AbsenceEntity, DefaultValidationResult } from '../../../../Globals/Types/Types';
import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { AbsenceCreateErrors } from '../CreateAbsenceModal';

/**
 * isValid()
 * @param absence
 */
const isValid = (absence: AbsenceEntity): DefaultValidationResult<AbsenceCreateErrors> => {
  let errors: AbsenceCreateErrors = {};

  if (!absence.user || Object.keys(absence.user).length !== 4) {
    errors = { ...errors, user: InternalErrorCodes.FIELD_MISSING };
  }

  if (!absence.startDate) {
    errors = { ...errors, startDate: InternalErrorCodes.FIELD_MISSING };
  }
  if (!absence.endDate) {
    errors = { ...errors, endDate: InternalErrorCodes.FIELD_MISSING };
  }
  if (!absence.type) {
    errors = { ...errors, type: InternalErrorCodes.FIELD_MISSING };
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export default isValid;
