/* eslint-disable  react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface ThemeProps {
  $focused: boolean;
}

const Container = styled.div<ThemeProps>`
  position: relative;

  input {
    border-radius: ${(props) => props.theme.border.radius};
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.color.textGrayLight};
    outline: none;
    padding: 16px 18px;
    margin: 26px 15px 26px 10px;
    width: ${(props) => (props.$focused ? '400px' : '50px')};
    transition: width 0.3s;
    ${(props) => (props.$focused ? props.theme.boxShadowDark : '')};
    cursor: ${(props) => (props.$focused ? 'inherit' : 'pointer')};

    ::placeholder {
      color: #bdbdbd;
    }
  }

  i {
    position: absolute;
    right: 33px;
    top: 46px;
    cursor: pointer;
    color: ${(props) => props.theme.color.textGray};
  }

  &:hover {
    input {
      ${(props) => props.theme.boxShadowDark};
    }
  }
`;

interface Props {
  onChange: (item: string) => void;
  onClear: () => void;
}

/**
 * SearchFilterButton
 * @param props
 * @constructor
 */
export default function SearchFilterButton(props: Props) {
  const { onChange, onClear } = props;
  const [t] = useTranslation();
  const [focused, setFocused] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>('');
  const inputRef = React.useRef<HTMLInputElement>();
  const timer = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    if (value && value.length > 0) {
      timer.current = setTimeout(() => onChange(value), 800);
    } else {
      if (focused) {
        onClear();
      }
    }
    return () => clearTimeout(timer.current);
  }, [value]);

  const handleFocusOut = () => {
    if (!value || value.length === 0) {
      setFocused(false);
    }
  };

  const clear = () => {
    setValue('');
    setFocused(false);
    inputRef.current.blur();
    onClear();
  };

  const renderClearIcon = () => {
    if (value && value.length > 0) {
      return <i className="fas fa-times" onClick={clear} />;
    }
    return null;
  };

  const renderSearchIcon = () => {
    if (!value && value.length === 0) {
      return <i className="fas fa-search" onClick={() => inputRef.current.focus()} />;
    }
    return null;
  };

  return (
    <Container $focused={focused}>
      <input
        value={value}
        onChange={(e: any) => setValue(e.nativeEvent.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={handleFocusOut}
        ref={inputRef}
        placeholder={focused ? t('serviceSearchPlaceholder') : ''}
      />
      {renderClearIcon()}
      {renderSearchIcon()}
    </Container>
  );
}
