import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { ProductType } from '../../Globals/Types/Enums';

interface Props {
  onChange: (item: ProductType) => void;
  selected: ProductType;
  showSkeleton?: boolean;
}

/**
 * SelectProductType
 * @param props
 * @constructor
 */
export default function SelectProductType(props: Props) {
  const { onChange, selected, showSkeleton } = props;
  const [t] = useTranslation();
  const [options] = React.useState<SelectOptions>([
    { value: ProductType.service, label: t('productTypes.service') },
    { value: ProductType.article, label: t('productTypes.article') },
  ]);

  return (
    <Select
      label={t('type')}
      onChange={onChange}
      initialValue={selected}
      options={options}
      showSkeleton={showSkeleton}
    />
  );
}
