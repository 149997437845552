import React from 'react';
import { CustomerEntity } from '../../../Globals/Types/Customer';
import { customerGetSalutationIcon } from '../../../Globals/Functions/CustomerFunctions';

interface Props {
  item: CustomerEntity;
}

/**
 * FirstnameLastnameCompanyCol
 * @constructor
 */
export default function CustomerIconCol(props: Props) {
  const { item } = props;

  return (
    <td key={`${item.customerId}-iconCol`}>
      <i className={customerGetSalutationIcon(item)} style={{ fontSize: 26 }} />
    </td>
  );
}
