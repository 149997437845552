import React from 'react';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';
import { DragDropType } from '../../../../../Globals/Types/DispositionTypes';
import { ResourceEntity } from '../../../../../Globals/Types/Types';
import { ResourceEntityTypeIcon } from '../../../../../Globals/Types/Enums';
import { useGetTourAssignedFacilitiesString } from '../../../../../Globals/Hooks/DispositionHooks';

const Container = styled.div<{ isDragging: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin: 8px 8px 0 8px;
  padding: ${(props) => (props.isDragging ? '8px 14px' : '10px 16px')};
  background-color: ${(props) => props.theme.table.highlight};
  border-radius: 6px;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  ${(props) => (props.isDragging ? `border: 2px dashed ${props.theme.color.orange}` : null)};

  .CaptionContainer {
    display: flex;
    flex-direction: column;

    small {
      font-size: 12px;
      color: ${(props) => props.theme.color.textGray};
      line-height: 0.8;
    }
  }

  .TypeIcon {
    cursor: move;
    width: 18px;
  }

  &:hover {
    background-color: ${(props) => props.theme.color.primaryColor};
    color: #ffffff !important;
    ${(props) => props.theme.boxShadowDarkHover}

    .TypeIcon {
      color: ${(props) => props.theme.color.orange};
    }

    small {
      color: #ffffff;
    }
  }
`;

interface Props {
  resource: ResourceEntity;
}

/**
 * ResourceItem
 * @param props
 * @constructor
 */
export default function ResourceItem(props: Props) {
  const { resource } = props;
  const assignedFacilities = useGetTourAssignedFacilitiesString(resource.facilityIds, true);

  const [{ isDragging }, drag] = useDrag<ResourceEntity, any, any>(
    () => ({
      type: DragDropType.RESOURCE,
      item: resource,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [],
  );

  const renderFacilities = () => {
    if (assignedFacilities) {
      return <small>{assignedFacilities}</small>;
    }
    return null;
  };

  return (
    <Container ref={drag} isDragging={isDragging}>
      <div>
        <i className={`${ResourceEntityTypeIcon[resource.type.toLowerCase()]} TypeIcon`} style={{ marginRight: 6 }} />
      </div>
      <div className="CaptionContainer">
        {resource.name}
        {renderFacilities()}
      </div>
    </Container>
  );
}
