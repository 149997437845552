import React from 'react';
import Panel from '../../../../Panel';
import { useTranslation } from 'react-i18next';
import { ElasticSearchServiceEntity, ElasticSearchServiceInterface } from '../../../../../Globals/Types/OrderTypes';
import ServiceItem from './ServiceItem';
import { ResourceEntity, UserEntity } from '../../../../../Globals/Types/Types';
import { DragDropType } from '../../../../../Globals/Types/DispositionTypes';
import UserItem from './UserItem';
import ResourceItem from './ResourceItem';
import SearchFilter from './SearchFilter';
import { DispositionReducerByDate } from '../../../../../Redux/Reducer/DispositionReducer';
import styled from 'styled-components';
import { filterAndSortResources, filterAndSortServices, filterAndSortUsers } from './HelperFunctions';

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

const EmptyHint = styled.div`
  padding: 30px;
  color: ${(props) => props.theme.color.textGray};
  text-align: center;
  font-size: 16px;
`;

const MAX = 50;

interface Props {
  tourListByDate: DispositionReducerByDate[];
  width?: number;
  type: DragDropType;
  items: ElasticSearchServiceEntity[] | UserEntity[] | ResourceEntity[];
  onSearch: (value: string) => void;
  isLoading?: boolean;
  onReloadClick?: () => void;
  currentDate: string;
}

/**
 * RightListView
 * @param props
 * @constructor
 */
export default function RightListView(props: Props) {
  const { items, onSearch, type, width, tourListByDate, isLoading, onReloadClick, currentDate } = props;
  const [sortedItems, setSortedItems] = React.useState<ElasticSearchServiceEntity[] | UserEntity[] | ResourceEntity[]>(
    items,
  );
  const [t] = useTranslation();

  /**
   * Prepare service display order.
   * Show services with desiredDate on selected date first and sort upcoming.
   */
  React.useEffect(() => {
    if (tourListByDate) {
      const tour = tourListByDate.find((item) => item.date === currentDate);

      if (type === DragDropType.SERVICE) {
        const typesItems: ElasticSearchServiceInterface[] = items as undefined as ElasticSearchServiceInterface[];
        setSortedItems(filterAndSortServices(typesItems, currentDate));
      } else if (type === DragDropType.USER) {
        const typedItems: UserEntity[] = items as undefined as UserEntity[];
        setSortedItems(filterAndSortUsers(typedItems, tour));
      } else if (type === DragDropType.RESOURCE) {
        const typedItems: ResourceEntity[] = items as undefined as ResourceEntity[];
        setSortedItems(filterAndSortResources(typedItems, tour));
      }
    }
  }, [currentDate, items, tourListByDate, type]);

  const getSearchPlaceholder = () => {
    switch (type) {
      case DragDropType.SERVICE:
        return t('openOrdersX', { count: items ? items.length : 0 });
      case DragDropType.USER:
        return t('users');
      case DragDropType.RESOURCE:
        return t('resources');
      default:
        return '';
    }
  };

  const renderEmptyHint = () => {
    switch (type) {
      case DragDropType.SERVICE: {
        if (!items || items.length <= 0) {
          return <EmptyHint>{t('modules.disposition.noOpenServices')}</EmptyHint>;
        }
        return null;
      }
      default:
        return null;
    }
  };

  const renderItems = () => {
    if (items) {
      switch (type) {
        case DragDropType.SERVICE:
          return sortedItems
            .slice(0, MAX)
            .map((service) => (
              <ServiceItem
                service={service}
                key={service.serviceId}
                tourListByDate={tourListByDate}
                currentDate={currentDate}
              />
            ));
        case DragDropType.USER:
          return sortedItems
            .slice(0, MAX)
            .map((user) => <UserItem user={user} key={user.userId} currentData={currentDate} />);
        case DragDropType.RESOURCE:
          return sortedItems
            .slice(0, MAX)
            .map((resource) => <ResourceItem resource={resource} key={resource.resourceId} />);
        default:
          return null;
      }
    }
  };

  return (
    <Panel containerStyle={{ margin: 0, marginLeft: 20 }} withoutPadding maxWidth={width}>
      <SearchFilter
        placeholder={getSearchPlaceholder()}
        onSearch={onSearch}
        items={items}
        isLoading={isLoading}
        onReloadClick={onReloadClick}
      />
      <ItemContainer>{renderItems()}</ItemContainer>
      {renderEmptyHint()}
    </Panel>
  );
}

RightListView.defaultProps = {
  width: 400,
  isLoading: false,
};
