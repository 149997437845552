import React from 'react';
import Panel from '../../../Panel';
import { useTranslation } from 'react-i18next';
import LoadingAddressForm from '../../LoadingAddressForm';
import DeliveryAddressForm from '../../DeliveryAddressForm';
import { DeliveryAndLoadingOptions } from '../../../../Globals/Types/Enums';
import { Col, Row } from 'react-bootstrap';
import { Address } from '../../../../Globals/Types/Types';
import SelectDeliveryAndLoadingOption from '../../../PredefinedSelects/SelectDeliveryAndLoadingOption';
import { ValidateDeliveryAndLoadingOptionsResult } from '../../Validators/ValidateDeliveryAndLoadingAddress';

interface Props {
  onChangeOption: (value: DeliveryAndLoadingOptions) => void;
  onChangeDeliveryAddress: (value: Address) => void;
  onChangeLoadingAddress: (value: Address) => void;
  loadingAddress: Address;
  deliveryAddress: Address;
  option: DeliveryAndLoadingOptions;
  validationResult: ValidateDeliveryAndLoadingOptionsResult;
  blackHeadline?: boolean;
}

/**
 * DeliveryAndLoading()
 * @param props
 * @constructor
 */
export default function DeliveryAndLoading(props: Props) {
  const {
    option,
    deliveryAddress,
    loadingAddress,
    onChangeOption,
    onChangeLoadingAddress,
    onChangeDeliveryAddress,
    validationResult,
    blackHeadline,
  } = props;
  const [t] = useTranslation();

  const renderOptionTwo = () => {
    if (option === DeliveryAndLoadingOptions.deliveryIsCustomerAndLoading) {
      return (
        <LoadingAddressForm
          address={loadingAddress}
          onChange={onChangeLoadingAddress}
          validationResult={validationResult}
        />
      );
    }
    return null;
  };

  const renderOptionThree = () => {
    if (option === DeliveryAndLoadingOptions.loadingIsCustomerAndDelivery) {
      return (
        <DeliveryAddressForm
          address={deliveryAddress}
          onChange={onChangeDeliveryAddress}
          validationResult={validationResult}
        />
      );
    }
    return null;
  };

  const renderOptionFour = () => {
    if (option === DeliveryAndLoadingOptions.loadingAndDelivery) {
      return (
        <Row>
          <Col xl={12}>
            <LoadingAddressForm
              address={loadingAddress}
              onChange={onChangeLoadingAddress}
              validationResult={validationResult}
            />
          </Col>
          <Col xl={12} style={{ marginTop: 20 }}>
            <DeliveryAddressForm
              autoFocus={false}
              address={deliveryAddress}
              onChange={onChangeDeliveryAddress}
              validationResult={validationResult}
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <Panel
      headline={t('modules.order.loadAndDeliverHeadline')}
      description={t('modules.order.loadAndDeliverDescription')}
      headlineDarkBlack={blackHeadline || false}
    >
      <SelectDeliveryAndLoadingOption onChange={onChangeOption} label={t('pleaseSelect')} initialValue={option} />

      <div style={{ marginTop: 20 }}>
        {renderOptionTwo()}
        {renderOptionThree()}
        {renderOptionFour()}
      </div>
    </Panel>
  );
}
