export enum DragDropType {
  SERVICE = 'SERVICE',
  SERVICETOUR = 'SERVICETOUR',
  RESOURCE = 'RESOURCE',
  USER = 'USER',
}

export enum ViewTypes {
  daily = 'daily',
  weekly = 'weekly',
}
