import { MessageEntity } from '../Types/Types';
import { TFunction } from 'i18next';

/**
 * messageGetSubject()
 * @param message
 * @param t
 */
export const messageGetSubject = (message: MessageEntity, t: TFunction) => {
  let subject = message.subject;
  if (message.template) {
    const translationPath = `messageTemplates.${message.template}.subject`;
    subject = t(translationPath, message.parameters);
  }

  return subject;
};
