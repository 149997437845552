import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ViewTypes } from '../../../Globals/Types/DispositionTypes';

const SelectContainer = styled.div`
  border-radius: ${(props) => props.theme.border.radius};
  border: 1px solid ${(props) => props.theme.border.color};
  padding-right: 10px;

  select {
    padding: 10px;
    border-radius: ${(props) => props.theme.border.radius};
    border: none;
    outline: none;
  }
`;

interface Props {
  containerStyle?: object;
  onChange: (item: ViewTypes) => void;
  selectedType: ViewTypes;
}

/**
 * SelectView()
 * @param props
 * @constructor
 */
export default function SelectView(props: Props) {
  const { containerStyle, onChange, selectedType } = props;
  const [t] = useTranslation();

  return (
    <SelectContainer style={containerStyle}>
      <select onChange={(event) => onChange(event.target.value as ViewTypes)} value={selectedType}>
        <option value={ViewTypes.weekly}>{t('modules.disposition.viewWeekly')}</option>
        <option value={ViewTypes.daily}>{t('modules.disposition.viewDaily')}</option>
      </select>
    </SelectContainer>
  );
}
