import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  color: ${(props) => props.theme.color.textGrayLight};
  font-style: italic;
`;

/**
 * MissingAssignmentText
 * @constructor
 */
export default function MissingAssignmentText() {
  const [t] = useTranslation();

  return <Container>{t('missingAssignment')}</Container>;
}
