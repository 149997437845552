import moment from 'moment';
import { Subscription } from './Types';
import { SubscriptionPeriod, SubscriptionType } from './Enums';
import { basicPrice, userPrice } from '../Hooks/SubscriptionHooks';

export const SystemAdminUserIds = ['6P3q9wIUb9fOKGLTq0Fz'];

export const STORAGE_SELECTED_LANGUAGE = '@sotosoftSelectedLanguage';
export const STORAGE_PARTNER_ID = '@sotosoftPartnerId';

export const defaultDemoSubscription: Subscription = {
  subscriptionId: 'demo',
  invoiceNumber: 'demo',
  active: true,
  payed: false,
  period: SubscriptionPeriod.month,
  type: SubscriptionType.demo,
  createdDate: moment().format('YYYY-MM-DD'),
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().add(30, 'days').format('YYYY-MM-DD'),
  users: 10,
  isUpdateSubscription: false,
  autoRenew: false,
  overallPrice: 0,
  rebate: 0,
  basicPrice: basicPrice,
  pricePerUser: userPrice,
  invoiceAddress: {
    name: '',
    zip: '',
    city: '',
    street: '',
    streetNo: '',
    vat: '',
    mail: '',
  },
};
