import React from 'react';
import FacilityHeader from '../Components/FacilityHeader';
import { useParams } from 'react-router';
import { useDispatchCreateDocument } from '../../../../Redux/Actions/Client/FacilityAction';
import { FacilityDocumentTypes } from '../../../../Globals/Types/Enums';
import DocumentOverview from '../../../../Components/Facility/Documents/Overview';

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const { facilityId } = useParams();
  const fileRef = React.useRef<HTMLInputElement>();
  const dispatchUpload = useDispatchCreateDocument();

  const handleUpload = (event) => {
    dispatchUpload(facilityId, event.target.files[0], FacilityDocumentTypes.logo).then(() => {});
  };

  return (
    <>
      <FacilityHeader />
      <input type="file" ref={fileRef} onChange={handleUpload} style={{ display: 'none' }} />
      <DocumentOverview />
    </>
  );
}
