import { InvoiceAddressEntity, InvoiceEntity } from '../../../Globals/Types/Invoice';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { PaymentType, Salutation } from '../../../Globals/Types/Enums';

export interface ValidateInvoiceErrorInterface {
  positions?: string;
  referenceInvoiceNumber?: string;
  address?: { [K in keyof InvoiceAddressEntity]?: string };
}

export interface ValidateInvoiceResult {
  isValid: boolean;
  errors: ValidateInvoiceErrorInterface;
}

/**
 * validateInvoice()
 * @param invoice
 */
export const validateInvoice = (invoice: InvoiceEntity): ValidateInvoiceResult => {
  let result: ValidateInvoiceResult = {
    isValid: true,
    errors: {},
  };

  if (!invoice.address.firstName || invoice.address.firstName.length <= 0) {
    result = {
      ...result,
      errors: { ...result.errors, address: { ...result.errors.address, firstName: InternalErrorCodes.FIELD_MISSING } },
      isValid: false,
    };
  }
  if (!invoice.address.lastName || invoice.address.lastName.length <= 0) {
    result = {
      ...result,
      errors: { ...result.errors, address: { ...result.errors.address, lastName: InternalErrorCodes.FIELD_MISSING } },
      isValid: false,
    };
  }
  if (!invoice.address.zip || invoice.address.zip.length <= 0) {
    result = {
      ...result,
      errors: { ...result.errors, address: { ...result.errors.address, zip: InternalErrorCodes.FIELD_MISSING } },
      isValid: false,
    };
  }
  if (!invoice.address.city || invoice.address.city.length <= 0) {
    result = {
      ...result,
      errors: { ...result.errors, address: { ...result.errors.address, city: InternalErrorCodes.FIELD_MISSING } },
      isValid: false,
    };
  }
  if (!invoice.address.street || invoice.address.street.length <= 0) {
    result = {
      ...result,
      errors: { ...result.errors, address: { ...result.errors.address, street: InternalErrorCodes.FIELD_MISSING } },
      isValid: false,
    };
  }
  if (!invoice.address.streetNo || invoice.address.streetNo.length <= 0) {
    result = {
      ...result,
      errors: { ...result.errors, address: { ...result.errors.address, streetNo: InternalErrorCodes.FIELD_MISSING } },
      isValid: false,
    };
  }

  if (invoice.address.salutation === Salutation.company) {
    if (!invoice.address.taxNumber || invoice.address.taxNumber.length <= 0) {
      result = {
        ...result,
        errors: {
          ...result.errors,
          address: { ...result.errors.address, taxNumber: InternalErrorCodes.FIELD_MISSING },
        },
        isValid: false,
      };
    }
  }

  if (
    (invoice.isVoucher && invoice.paymentType === PaymentType.credit) ||
    (!invoice.isVoucher && invoice.paymentType === PaymentType.debit)
  ) {
    if (!invoice.address.iban || invoice.address.iban.length <= 14) {
      result = {
        ...result,
        errors: { ...result.errors, address: { ...result.errors.address, iban: InternalErrorCodes.FIELD_MISSING } },
        isValid: false,
      };
    }
  }

  if (invoice.isVoucher) {
    if (!invoice.referenceInvoiceNumber || invoice.referenceInvoiceNumber.length <= 0) {
      result = {
        ...result,
        errors: { ...result.errors, referenceInvoiceNumber: InternalErrorCodes.FIELD_MISSING },
        isValid: false,
      };
    }
  }

  if (!invoice.positions || invoice.positions.length <= 0) {
    result = {
      ...result,
      errors: { ...result.errors, positions: InternalErrorCodes.FIELD_MISSING },
      isValid: false,
    };
  }

  return result;
};
