import React from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { MailLanguageTemplate, MailTemplate, MailTemplateType } from '../../../../../Globals/Types/Mail';
import { useTranslation } from 'react-i18next';
import Input, { InputType } from '../../../../../Components/Inputs/Input';
import SelectLanguage from '../../../../../Components/PredefinedSelects/SelectLanguage';
import { useNavigate, useParams } from 'react-router';
import {
  useDispatchFacilityMailTemplateCreate,
  useDispatchFacilityMailTemplateDelete,
  useDispatchFacilityMailTemplateGetList,
  useDispatchFacilityMailTemplateUpdate,
} from '../../../../../Redux/Actions/Client/Facility/MailTemplates';
import Panel from '../../../../../Components/Panel';
import PageHeader from '../../../../../Components/PageHeader';
import { CountryCode } from '../../../../../Globals/Types/Enums';
import { FacilityEntity } from '../../../../../Globals/Types/Types';
import { useGetFacility } from '../../../../../Globals/Hooks/FacilityHooks';
import AskDeleteModal from '../../../../../Components/Modals/AskDeleteModal';
import PlaceholderInfos from './PlaceholderInfos';
import ErrorMessage from '../../../../../Components/Modals/ErrorMessage';
import validateMailTemplate from './ValidateMailTemplate';

export type State = {
  template: MailTemplate;
  selectedLanguage: CountryCode;
  hasChanges: boolean;
  isEdit: boolean;
  defaultLanguage: CountryCode;
  isDefaultTemplate: boolean;
};

type Action =
  | { type: 'clear' }
  | { type: 'init'; payload: MailTemplate }
  | {
      type: 'initDefault';
      payload: { subject: string; message: string; defaultLanguage: CountryCode; type: MailTemplateType };
    }
  | { type: 'subject'; payload: string }
  | { type: 'message'; payload: string }
  | { type: 'language'; payload: CountryCode };

const initialState: State = {
  template: {
    templateId: null,
    templates: [],
    type: null,
  },
  selectedLanguage: CountryCode.ES,
  hasChanges: false,
  isEdit: false,
  isDefaultTemplate: false,
  defaultLanguage: CountryCode.ES,
};

const reducer = (state: State, action: Action): State => {
  const isLanguageAvailable = () => {
    return state.template.templates.find((item) => item.countryCode === state.selectedLanguage);
  };

  switch (action.type) {
    case 'clear':
      return { ...initialState };
    case 'subject': {
      if (isLanguageAvailable()) {
        const templates = state.template.templates.map((template) => {
          if (template.countryCode === state.selectedLanguage) {
            return { ...template, subject: action.payload };
          }
          return { ...template };
        });
        return { ...state, template: { ...state.template, templates }, hasChanges: true };
      } else {
        return {
          ...state,
          template: {
            ...state.template,
            templates: [
              ...state.template.templates,
              { countryCode: state.selectedLanguage, subject: action.payload, message: '', isDefault: false },
            ],
          },
        };
      }
    }
    case 'message': {
      if (isLanguageAvailable()) {
        const templates = state.template.templates.map((template) => {
          if (template.countryCode === state.selectedLanguage) {
            return { ...template, message: action.payload };
          }
          return { ...template };
        });

        return { ...state, template: { ...state.template, templates }, hasChanges: true };
      } else {
        return {
          ...state,
          template: {
            ...state.template,
            templates: [
              ...state.template.templates,
              { countryCode: state.selectedLanguage, message: action.payload, subject: '', isDefault: false },
            ],
          },
        };
      }
    }
    case 'language':
      return { ...state, selectedLanguage: action.payload };
    case 'init': {
      const foundDefault = action.payload.templates.find((item) => item.isDefault);
      return { ...initialState, template: action.payload, isEdit: true, defaultLanguage: foundDefault.countryCode };
    }
    case 'initDefault': {
      const template: MailLanguageTemplate = {
        subject: action.payload.subject,
        message: action.payload.message,
        countryCode: action.payload.defaultLanguage,
        isDefault: true,
      };
      return {
        ...initialState,
        template: { ...initialState.template, type: action.payload.type, templates: [template] },
        defaultLanguage: action.payload.defaultLanguage,
        isDefaultTemplate: true,
      };
    }
  }
};

/**
 * EditTemplate()
 * @constructor
 */
export default function EditTemplate() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { templateName, facilityId } = useParams();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [facilityTemplates, setFacilityTemplates] = React.useState<MailTemplate[]>([]);
  const [facility, setFacility] = React.useState<FacilityEntity>();
  const dispatchGetTemplates = useDispatchFacilityMailTemplateGetList();
  const getFacility = useGetFacility();
  const dispatchCreateTemplate = useDispatchFacilityMailTemplateCreate();
  const dispatchUpdateTemplate = useDispatchFacilityMailTemplateUpdate();
  const dispatchDeleteTemplate = useDispatchFacilityMailTemplateDelete();

  const handleCreate = React.useCallback(() => {
    const validateResult = validateMailTemplate(state.template, state.defaultLanguage);

    if (validateResult.isValid) {
      dispatchCreateTemplate(facilityId, state.template).then(() => {
        navigate(-1);
      });
    } else {
      setShowError(true);
    }
  }, [dispatchCreateTemplate, facilityId, navigate, state.defaultLanguage, state.template]);

  const handleUpdate = React.useCallback(() => {
    const validateResult = validateMailTemplate(state.template, state.defaultLanguage);

    if (validateResult.isValid) {
      dispatchUpdateTemplate(facilityId, state.template).then(() => {
        navigate(-1);
      });
    } else {
      setShowError(true);
    }
  }, [dispatchUpdateTemplate, facilityId, navigate, state.defaultLanguage, state.template]);

  const handleDelete = React.useCallback(() => {
    dispatchDeleteTemplate(facilityId, state.template.templateId).then(() => {
      navigate(-1);
    });
  }, [dispatchDeleteTemplate, facilityId, navigate, state.template]);

  React.useEffect(() => {
    setFacility(getFacility(facilityId));
  }, [facilityId, getFacility]);

  React.useEffect(() => {
    dispatchGetTemplates(facilityId)
      .then(setFacilityTemplates)
      .catch(() => setFacilityTemplates([]));
  }, [dispatchGetTemplates, facilityId]);

  React.useEffect(() => {
    if (templateName && facility) {
      const found = facilityTemplates.find((item) => item.type === templateName);
      if (found) {
        dispatch({ type: 'init', payload: found });
      } else {
        dispatch({
          type: 'initDefault',
          payload: {
            message: t(`modules.settings.mailTemplates.templates.${templateName}.message`),
            subject: t(`modules.settings.mailTemplates.templates.${templateName}.subject`),
            defaultLanguage: facility.countryCode,
            type: templateName as MailTemplateType,
          },
        });
      }
    }
  }, [facility, facilityTemplates, t, templateName]);

  const handleCancel = () => {
    navigate(-1);
  };

  const getSubject = () => {
    const data = state.template.templates.find((template) => template.countryCode === state.selectedLanguage);
    if (data) {
      return data.subject;
    }
    return '';
  };

  const getMessage = () => {
    const data = state.template.templates.find((template) => template.countryCode === state.selectedLanguage);
    if (data) {
      return data.message;
    }
    return '';
  };

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={state.isEdit ? handleUpdate : handleCreate} disabled={!state.hasChanges}>
        {t('save')}
      </Button>
    );
  };

  const renderDeleteButton = () => {
    if (!state.isDefaultTemplate) {
      return (
        <Button variant="danger" onClick={() => setAskDelete(true)}>
          {t('reset')}
        </Button>
      );
    }
    return null;
  };

  const renderCancelButton = () => {
    return (
      <Button variant="outline-secondary" onClick={handleCancel}>
        {t('cancel')}
      </Button>
    );
  };

  const renderDefaultHint = () => {
    if (state.isDefaultTemplate) {
      return <Alert variant="warning">{t('modules.settings.mailTemplates.defaultTemplateHint')}</Alert>;
    }
    return null;
  };

  return (
    <>
      <PageHeader headline={t('modules.settings.mailTemplates.editTemplate')} />
      <Panel>
        <div>{t(`modules.settings.mailTemplates.description`)}</div>
        <PlaceholderInfos templateType={templateName as MailTemplateType} />
      </Panel>
      <Panel
        headline={t(`modules.settings.mailTemplates.templates.${templateName}.name`)}
        description={t(`modules.settings.mailTemplates.templates.${templateName}.description`)}
      >
        {renderDefaultHint()}
        <SelectLanguage
          onChange={(value) => dispatch({ type: 'language', payload: value })}
          selected={state.selectedLanguage}
        />
        <Input
          onChange={(value) => dispatch({ type: 'subject', payload: value })}
          label={t('subject')}
          value={getSubject()}
        />
        <Input
          onChange={(value) => dispatch({ type: 'message', payload: value })}
          value={getMessage()}
          label={t('message')}
          type={InputType.textarea}
          rows={15}
        />
      </Panel>
      <Panel>
        <Row>
          <Col xxl={4}>
            <div>{renderDeleteButton()}</div>
          </Col>
          <Col xxl={8} className="flex-row d-flex justify-content-end">
            <div style={{ marginRight: 10 }}>{renderSaveButton()}</div>
            <div>{renderCancelButton()}</div>
          </Col>
        </Row>
      </Panel>

      <AskDeleteModal
        onDelete={handleDelete}
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        confirmButtonText={t('reset')}
        headline={t('modules.settings.mailTemplates.askDeleteHeadline')}
        description={t('modules.settings.mailTemplates.askDeleteDescription')}
      />
      <ErrorMessage
        onClose={() => setShowError(false)}
        visible={showError}
        headline={t('modules.settings.mailTemplates.validateErrorHeadline')}
        description={t('modules.settings.mailTemplates.validateErrorDescription')}
      />
    </>
  );
}
