import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../Components/PageHeader';
import Panel from '../../../Components/Panel';
import Input, { InputType } from '../../../Components/Inputs/Input';
import SelectClientUsers from '../../../Components/SelectClientUsers';
import { FacilityEntity, PickedUserEntity, ResourceEntity, TourTemplateEntity } from '../../../Globals/Types/Types';
import {
  useDispatchTourTemplateCreate,
  useDispatchTourTemplateDelete,
  useDispatchTourTemplateGet,
  useDispatchTourTemplateUpdate,
} from '../../../Redux/Actions/Client/TourTemplateActions';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { UrlPatterns } from '../../../Globals/UrlFunctions';
import { useNavigate, useParams } from 'react-router';
import validateCreateOrUpdate from './ValidateCreateOrUpdate';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import BottomInternalIdContainer from '../../../Components/BottomInternalIdContainer';
import AskDeleteModal from '../../../Components/Modals/AskDeleteModal';
import ResourcesForm from '../../../Components/ResourcesForm';
import FacilitiesForm from '../../../Components/FacilitiesForm';
import { updateFacilityIdsArray } from '../../../Globals/Functions';

export type CreateOrUpdateError = { [K in keyof TourTemplateEntity]?: string };

type State = {
  errors: CreateOrUpdateError;
  isEdit: boolean;
  hasChanges: boolean;
  tourTemplate: TourTemplateEntity;
};

type Action =
  | { type: 'init'; payload: TourTemplateEntity }
  | { type: 'description'; payload: string }
  | { type: 'errors'; payload: CreateOrUpdateError }
  | { type: 'name'; payload: string }
  | { type: 'resources'; payload: ResourceEntity[] }
  | { type: 'users'; payload: PickedUserEntity[] }
  | { type: 'facilityIds'; payload: string[] };

const initialState: State = {
  errors: null,
  isEdit: false,
  hasChanges: false,
  tourTemplate: {
    description: '',
    name: '',
    facilityIds: [],
  },
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'init':
      return {
        ...state,
        tourTemplate: action.payload || ({} as TourTemplateEntity),
        errors: null,
        isEdit: !!action.payload,
        hasChanges: false,
      };
    case 'description':
      return {
        ...state,
        hasChanges: true,
        tourTemplate: {
          ...state.tourTemplate,
          description: action.payload,
        },
      };
    case 'errors':
      return { ...state, errors: action.payload };
    case 'name':
      return {
        ...state,
        hasChanges: true,
        tourTemplate: {
          ...state.tourTemplate,
          name: action.payload,
        },
      };
    case 'resources':
      return {
        ...state,
        hasChanges: true,
        tourTemplate: {
          ...state.tourTemplate,
          resources: action.payload,
        },
      };
    case 'users':
      return {
        ...state,
        hasChanges: true,
        tourTemplate: {
          ...state.tourTemplate,
          users: action.payload,
        },
      };
    case 'facilityIds':
      return {
        ...state,
        hasChanges: true,
        tourTemplate: {
          ...state.tourTemplate,
          facilityIds: action.payload,
        },
      };
  }
};

/**
 * CreateOrUpdate
 * @constructor
 */
export default function CreateOrUpdate() {
  const { tourTemplateId } = useParams();
  const { user } = useAppSelector((state) => state.auth);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { isLoading, loadingModal, setLoading } = useLoadingModal({ delayStart: 500 });
  const navigate = useNavigate();
  const [t] = useTranslation();

  const dispatchCreate = useDispatchTourTemplateCreate();
  const dispatchDelete = useDispatchTourTemplateDelete();
  const dispatchGet = useDispatchTourTemplateGet();
  const dispatchUpdate = useDispatchTourTemplateUpdate();

  React.useEffect(() => {
    if (!tourTemplateId || tourTemplateId === 'create') {
      return dispatch({ type: 'init', payload: null });
    }

    setLoading(true);
    dispatchGet(tourTemplateId)
      .then((response) => dispatch({ type: 'init', payload: response }))
      .finally(() => setLoading(false));
  }, [dispatchGet, setLoading, tourTemplateId]);

  React.useEffect(() => {
    dispatch({ type: 'facilityIds', payload: [user.facilityId] });
  }, [user.facilityId]);

  const handleDelete = () => {
    setLoading(true);
    dispatchDelete(state.tourTemplate)
      .then(() => navigate(UrlPatterns.settingsTourTemplates))
      .catch(() => setLoading(false));
  };

  const handleSave = () => {
    const { isValid, errors } = validateCreateOrUpdate(state.tourTemplate);
    if (!isValid) {
      return dispatch({ type: 'errors', payload: errors });
    }

    setLoading(true);

    const promise: Promise<TourTemplateEntity> = state.isEdit
      ? dispatchUpdate(state.tourTemplate)
      : dispatchCreate(state.tourTemplate);
    promise.then(() => navigate(UrlPatterns.settingsTourTemplates)).catch(() => setLoading(false));
  };

  const onChangeFacility = (value: boolean, facility: FacilityEntity) => {
    dispatch({
      type: 'facilityIds',
      payload: updateFacilityIdsArray(value, facility, state.tourTemplate.facilityIds),
    });
  };

  const renderCancelButton = () => {
    return (
      <Button
        variant="outline-secondary"
        onClick={() => navigate(UrlPatterns.settingsTourTemplates)}
        disabled={isLoading}
        style={{ marginRight: 10 }}
      >
        {t('cancel')}
      </Button>
    );
  };

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
        {t('save')}
      </Button>
    );
  };

  if (state.tourTemplate) {
    return (
      <>
        <PageHeader
          headline={
            state.isEdit ? t('modules.settings.tour.headlineUpdate') : t('modules.settings.tour.headlineCreate')
          }
          actionButtonOne={renderSaveButton()}
          actionButtonTwo={renderCancelButton()}
        />

        <Panel headline={t('basicData')}>
          <Input
            label={t('name')}
            value={state.tourTemplate?.name}
            onChange={(value) => dispatch({ type: 'name', payload: value })}
            hasError={!!state.errors?.name}
            disabled={isLoading}
            required
          />
          <Input
            label={t('description')}
            value={state.tourTemplate?.description}
            type={InputType.textarea}
            rows={5}
            onChange={(value) => dispatch({ type: 'description', payload: value })}
            hasError={!!state.errors?.description}
            disabled={isLoading}
          />
        </Panel>

        <Panel headline={t('employeeDefault')} description={t('modules.settings.tour.user.description')}>
          <SelectClientUsers
            initialSelected={state.tourTemplate?.users}
            onChange={(value) => dispatch({ type: 'users', payload: value as PickedUserEntity[] })}
            hasError={!!state.errors?.users}
            disabled={isLoading}
          />
        </Panel>

        <ResourcesForm
          initialValue={state.tourTemplate?.resources}
          headline={t('resourceDefault')}
          description={t('modules.settings.tour.resource.description')}
          onChange={(value) => dispatch({ type: 'resources', payload: value })}
          disabled={isLoading}
          hasError={!!state.errors?.resources}
        />

        <FacilitiesForm
          userDefaultFacility={null}
          onChange={onChangeFacility}
          description={t('modules.settings.tour.facility.description')}
          userFacilities={state.tourTemplate.facilityIds}
          hasError={!!state.errors?.facilityIds}
        />

        <ShowHideContainer visible={state.isEdit}>
          <div style={{ textAlign: 'center' }}>
            <Button
              variant="outline-danger"
              onClick={() => setAskDelete(true)}
              disabled={isLoading}
              style={{ borderColor: 'transparent' }}
            >
              {t('delete')}
            </Button>
          </div>
        </ShowHideContainer>

        <ShowHideContainer visible={state.isEdit}>
          <BottomInternalIdContainer id={tourTemplateId} />
        </ShowHideContainer>

        {loadingModal}

        <AskDeleteModal onDelete={handleDelete} onClose={() => setAskDelete(false)} visible={askDelete} />
      </>
    );
  }

  return null;
}
