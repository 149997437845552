import React from 'react';
import { Report, ReportState } from '../../../Globals/Types/Report';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import DisplayText from '../../View/DisplayText';
import ImageList from '../../ImageList';

interface Props {
  report: Report;
}

/**
 * IncompleteOverview()
 * @param props
 * @constructor
 */
export default function IncompleteOverview(props: Props) {
  const { report } = props;
  const [t] = useTranslation();

  if (report.type === ReportState.incomplete) {
    return (
      <>
        <Panel headline={t('serviceNotExecutable')} description={t('serviceUnsuccessfulDescription')}>
          <DisplayText caption={t('additionalInformation')}>{report.incomplete.additionalInformation}</DisplayText>
        </Panel>
        <Panel containerStyle={{ paddingBottom: 10 }}>
          <ImageList images={report.incomplete.images.map((item) => item.downloadUrl)} />
        </Panel>
      </>
    );
  }
  return null;
}
