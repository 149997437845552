import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import SelectNumber from '../../../../../Components/PredefinedSelects/SelectNumber';
import Select, { SelectOptions } from '../../../../../Components/Inputs/Select';
import Panel from '../../../../../Components/Panel';
import { useAppSelector, useLoadingModal } from '../../../../../Globals/Hooks/Hooks';
import { SubscriptionPeriod, SubscriptionType } from '../../../../../Globals/Types/Enums';
import DisplayText from '../../../../../Components/View/DisplayText';
import { formatCurrency } from '../../../../../Globals/Functions';
import Input from '../../../../../Components/Inputs/Input';
import { initialState, reducer } from './Reducer';
import { Subscription } from '../../../../../Globals/Types/Types';
import { SubscriptionValidationErrors, validateSubscription } from './Validate';
import QuestionYesNoMessage from '../../../../../Components/Modals/QuestionYesNoMessage';
import {
  useDispatchClientSubscriptionCreate,
  useDispatchClientSubscriptionGetList,
} from '../../../../../Redux/Actions/Client/SubscriptionAction';
import { useGetFacility } from '../../../../../Globals/Hooks/FacilityHooks';
import {
  basicPrice,
  SubscriptionPriceCalculationResult,
  useCalculatePrices,
  userPrice,
} from '../../../../../Globals/Hooks/SubscriptionHooks';
import Text from '../../../../../Components/Text';
import { useDispatchUsersGetList } from '../../../../../Redux/Actions/UserAction';

const yearlyRebatePercent = 0.1;

/**
 * CreateSubscription()
 * @constructor
 */
export default function CreateSubscription() {
  const [t] = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { setLoading, loadingModal } = useLoadingModal({
    headline: t('modules.settings.subscription.creatingSubscription'),
  });
  const { activeSubscription } = useAppSelector((state) => state.auth);
  const { users } = useAppSelector((state) => state.client);
  const [errors, setErrors] = React.useState<SubscriptionValidationErrors>({});
  const [askCreate, setAskCreate] = React.useState<boolean>(false);
  const [calculatedPrices, setCalculatedPrices] = React.useState<SubscriptionPriceCalculationResult>({
    rebate: 0,
    overallPrice: 0,
    price: 0,
    priceForAdditionalUsers: 0,
    basicPrice: basicPrice,
    pricePerUser: userPrice,
  });
  const [options] = React.useState<SelectOptions>([
    { label: t('modules.settings.subscription.periodMonth'), value: SubscriptionPeriod.month },
    {
      label: t('modules.settings.subscription.periodYear', { rebate: (yearlyRebatePercent * 100).toString() }),
      value: SubscriptionPeriod.year,
    },
  ]);

  const getFacility = useGetFacility();
  const dispatchCreate = useDispatchClientSubscriptionCreate();
  const dispatchList = useDispatchClientSubscriptionGetList();
  const calculatePrices = useCalculatePrices();
  const dispatchUsersGetList = useDispatchUsersGetList();

  React.useEffect(() => {
    dispatchUsersGetList().then(() => {});
  }, [dispatchUsersGetList]);

  const checkIsValid = React.useCallback(() => {
    setErrors({});
    const validationResult = validateSubscription(state.subscription);

    if (validationResult.isValid) {
      setAskCreate(true);
    } else {
      setErrors(validationResult.errors);
    }
  }, [state.subscription]);

  const handleCreate = React.useCallback(() => {
    setLoading(true);
    setAskCreate(false);
    dispatchCreate({
      ...state.subscription,
      overallPrice: calculatedPrices.overallPrice,
      rebate: calculatedPrices.rebate,
    })
      .then(() => {
        return dispatchList();
      })
      .finally(() => setLoading(false));
  }, [
    calculatedPrices.overallPrice,
    calculatedPrices.rebate,
    dispatchCreate,
    dispatchList,
    setLoading,
    state.subscription,
  ]);

  React.useEffect(() => {
    if (users && users.all && users.all.length > 0) {
      const facility = getFacility();
      if (!state.initialized) {
        const sub: Partial<Subscription> = {
          users: users.all.length - 2,
          period: SubscriptionPeriod.year,
          basicPrice: basicPrice,
          pricePerUser: calculatedPrices.pricePerUser,
          invoiceAddress: {
            name: facility.name,
            zip: facility.zip,
            city: facility.city,
            street: facility.street,
            streetNo: facility.streetNo,
            vat: facility.vat,
            mail: facility.mail,
          },
        };

        dispatch({ type: 'init', payload: sub });
      }
    }
  }, [calculatedPrices.pricePerUser, getFacility, state.initialized, users]);

  React.useEffect(() => {
    const result = calculatePrices(state.subscription.users, state.subscription.period);
    setCalculatedPrices(result);
  }, [calculatePrices, state.subscription.period, state.subscription.users]);

  const renderRebate = () => {
    if (state.subscription.period === SubscriptionPeriod.year && state.subscription.users > 0) {
      return (
        <DisplayText
          caption={t('modules.settings.subscription.yearlyRebate', { rebate: (yearlyRebatePercent * 100).toString() })}
          horizontal={true}
        >
          {getPrice(calculatedPrices.rebate.toString())}
        </DisplayText>
      );
    }
    return null;
  };

  const getPrice = (price: string) =>
    state.subscription.period === SubscriptionPeriod.year
      ? t('pricePerYear', { price: formatCurrency(price) })
      : t('pricePerMonth', { price: formatCurrency(price) });

  if (state.initialized && activeSubscription.type === SubscriptionType.demo) {
    return (
      <>
        <Row>
          <Col xxl={12}>
            <Panel>{t('modules.settings.subscription.createNewSubscriptionDescription')}</Panel>
          </Col>
          <Col xxl={7}>
            <Panel headline={t('subscriptionInfos')}>
              <DisplayText
                caption={t('modules.settings.subscription.basicPriceDescription')}
                horizontal={true}
                containerStyle={{ marginBottom: 15 }}
              >
                {t('pricePerMonth', { price: formatCurrency(calculatedPrices.basicPrice.toString()) })}
              </DisplayText>
              <Select
                onChange={(value) => dispatch({ type: 'period', payload: value })}
                label={t('subscriptionPeriod')}
                options={options}
                initialValue={state.subscription.period}
              />
              <SelectNumber
                max={100}
                start={0}
                onChange={(value) => dispatch({ type: 'users', payload: value })}
                initialValue={state.subscription.users}
                label={t('modules.settings.subscription.usersNeeded')}
              />
              <Input
                onChange={(value) => dispatch({ type: 'voucherCode', payload: value })}
                label={t('voucherCode')}
                value={state.subscription.voucherCode}
              />

              <DisplayText
                caption={t('modules.settings.subscription.priceSelectedUsersPerMonth', {
                  count: state.subscription.users,
                  price: formatCurrency(calculatedPrices.pricePerUser.toString()),
                })}
                horizontal={true}
                visible={state.subscription.users > 0}
              >
                {getPrice(calculatedPrices.priceForAdditionalUsers.toString())}
              </DisplayText>

              {renderRebate()}

              <DisplayText caption={`${t('overallNetto')}*`} horizontal bold={true} containerStyle={{ marginTop: 20 }}>
                {getPrice(calculatedPrices.overallPrice.toString())}
              </DisplayText>
            </Panel>
          </Col>
          <Col xxl={5}>
            <Panel headline={t('invoiceAddress')}>
              <Input
                onChange={(value) => dispatch({ type: 'name', payload: value })}
                value={state.subscription.invoiceAddress.name}
                label={t('companyName')}
                required
                hasError={!!errors.name}
              />
              <Input
                onChange={(value) => dispatch({ type: 'street', payload: value })}
                value={state.subscription.invoiceAddress.street}
                label={t('street')}
                required
                hasError={!!errors.street}
              />
              <Input
                onChange={(value) => dispatch({ type: 'streetNo', payload: value })}
                value={state.subscription.invoiceAddress.streetNo}
                label={t('streetNo')}
                required
                hasError={!!errors.streetNo}
              />
              <Input
                onChange={(value) => dispatch({ type: 'zip', payload: value })}
                value={state.subscription.invoiceAddress.zip}
                label={t('zip')}
                required
                hasError={!!errors.zip}
              />
              <Input
                onChange={(value) => dispatch({ type: 'city', payload: value })}
                value={state.subscription.invoiceAddress.city}
                label={t('city')}
                required
                hasError={!!errors.city}
              />
              <Input
                onChange={(value) => dispatch({ type: 'vat', payload: value })}
                value={state.subscription.invoiceAddress.vat}
                label={t('taxNumber')}
                hasError={!!errors.vat}
              />
              <Input
                onChange={(value) => dispatch({ type: 'mail', payload: value })}
                value={state.subscription.invoiceAddress.mail}
                label={t('invoiceMail')}
                hasError={!!errors.mail}
              />
            </Panel>
          </Col>
          <Col xxl={12}>
            <Panel>
              <div className="d-flex flex-row flex-grow-1 align-items-center">
                <Text light small>
                  {`*${t('vatHintPrice')}`}
                </Text>
                <div style={{ textAlign: 'right' }} className="flex-grow-1">
                  <Button variant="success" onClick={checkIsValid}>
                    {t('modules.settings.subscription.createNewSubscription')}
                  </Button>
                </div>
              </div>
            </Panel>
          </Col>
        </Row>

        <QuestionYesNoMessage
          icon="fas fa-check-circle"
          visible={askCreate}
          onCancel={() => setAskCreate(false)}
          headline={`${t('modules.settings.subscription.createNewSubscription')}!`}
          confirmCaption={t('modules.settings.subscription.subscribeNow')}
          onSuccess={handleCreate}
          description={t('modules.settings.subscription.approveCreateModalDescription', {
            price: getPrice(calculatedPrices.overallPrice.toString()),
          })}
        />

        {loadingModal}
      </>
    );
  }
  return null;
}
