import React from 'react';
import Panel from './Panel';
import SelectResource from './PredefinedSelects/SelectResource';
import { ResourceEntity } from '../Globals/Types/Types';
import { useAppSelector } from '../Globals/Hooks/Hooks';

interface Props {
  disabled?: boolean;
  hasError?: boolean;
  description?: string;
  headline: string;
  initialValue?: ResourceEntity[];
  onChange: (value: ResourceEntity[]) => void;
}

/**
 * ResourcesForm
 * @param props
 * @constructor
 */
export default function ResourcesForm(props: Props) {
  const { disabled, hasError, description, headline, initialValue, onChange } = props;
  const { resources } = useAppSelector((state) => state.client);

  if (resources && resources.length > 0) {
    return (
      <Panel headline={headline} description={description}>
        <SelectResource initialValue={initialValue} onChange={onChange} hasError={hasError} disabled={disabled} />
      </Panel>
    );
  }

  return null;
}
