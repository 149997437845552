import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin-bottom: 10px;

  > i {
    margin-right: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

interface Props {
  type: 'error' | 'warning' | 'success';
  textKey: string;
}

/**
 * OverviewItem()
 * @param props
 * @constructor
 */
export default function OverviewItem(props: Props) {
  const { type, textKey } = props;
  const [t] = useTranslation();
  const theme = useTheme();

  const getIcon = () => {
    switch (type) {
      case 'success':
        return 'fas fa-check-circle';
      case 'warning':
        return 'fas fa-octagon-exclamation';
      case 'error':
        return 'fas fa-square-xmark';
    }
  };

  const getIconColor = () => {
    switch (type) {
      case 'success':
        return theme.color.green;
      case 'warning':
        return theme.color.orange;
      case 'error':
        return theme.color.red;
    }
  };

  return (
    <InnerContainer>
      <i className={getIcon()} style={{ color: getIconColor() }} />
      {t(textKey)}
    </InnerContainer>
  );
}
