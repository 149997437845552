import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import ReactSelect, { OptionsOrGroups } from 'react-select';
import { useHelpContext } from '../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import CustomSkeleton from '../CustomSkeleton';
import Lodash from 'lodash';

export type SelectOptionItem = { value: string | number | boolean; label: string };
export type SelectOptions = SelectOptionItem[];

interface SelectProps {
  onChange: Function;
  label: string;
  initialValue: string | number | boolean | Array<string | number | boolean> | null;
  visible: boolean;
  options: OptionsOrGroups<any, any>;
  required: boolean;
  hasError?: boolean;
  errorMessage?: string | null;
  disabled: boolean;
  contextHelpKey: string;
  showSkeleton: boolean;
  multiSelect: boolean;
  placeholder?: string;
  closeMenuOnSelect: boolean;
  optionAll?: SelectOptionItem;
  marginBottom: number;
  containerStyle?: object;
}

interface ContainerProps {
  $focused: boolean;
  $hasError: boolean;
  $marginBottom: number;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${(props) => props.$marginBottom}px;

  .skeleton {
    border-radius: ${(props) => props.theme.border.radius};
    line-height: 56px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.border.color};
    outline: none;
  }

  .CustomSelect__control {
    outline: none;
    border-radius: ${(props) => props.theme.border.radius};
    padding-left: 18px;
    padding-right: 18px;

    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.border.color};

    &:focus {
      background-color: ${() => lighten(0.47, 'gray')};
    }
  }

  .CustomSelect__value-container {
    padding: 0;
  }

  .CustomSelect__single-value {
    margin: 0;
    padding-left: 0;
    padding-top: 14px;
  }

  .CustomSelect__input-container {
    border: none;
    margin: 0;
    height: 56px;
    padding-bottom: 6px;
    padding-top: 22px;
  }

  .CustomSelect__placeholder {
    margin: 0;
    padding-top: 14px;
  }

  .fa-question-circle {
    cursor: pointer;
    color: ${(props) => props.theme.color.help};

    &:hover {
      color: ${(props) => darken(0.1, props.theme.color.help)};
    }
  }

  .Label {
    position: absolute;
    left: 19px;
    top: ${(props) => (props.$focused ? '6px' : '6px')};
    font-size: ${(props) => (props.$focused ? '14px' : '14px')};
    color: ${(props) => (props.$hasError ? lighten(0.15, props.theme.color.red) : props.theme.color.textGray)};
    transition: top 200ms, font-size 200ms, font-weight 200ms;
  }

  .RightIconContainer {
    position: absolute;
    right: 70px;
    top: 17px;

    i {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .ErrorIcon {
      color: ${(props) => lighten(0.15, props.theme.color.red)};
    }
    .DisabledIcon {
      color: ${(props) => lighten(0.15, props.theme.color.textGray)};
    }
  }
`;

/**
 * Input()
 * @param props
 * @constructor
 */
export default function Select(props: SelectProps) {
  const {
    optionAll,
    options,
    initialValue,
    showSkeleton,
    multiSelect,
    placeholder,
    closeMenuOnSelect,
    marginBottom,
    containerStyle,
  } = props;
  const [currentValue, setCurrentValue] = React.useState(null);
  const [optionsPrepared, setOptionsPrepared] = React.useState<OptionsOrGroups<any, any>>(null);
  const [t] = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>();
  const [contextHelpElement, setContextName] = useHelpContext();

  React.useEffect(() => {
    if (multiSelect && optionAll && options) {
      return setOptionsPrepared([optionAll, ...options]);
    }

    setOptionsPrepared(options);
  }, [multiSelect, optionAll, options]);

  React.useEffect(() => {
    if (initialValue !== undefined && initialValue !== null && options) {
      let receiversParam;
      if (multiSelect) {
        const listReceivers: Array<string> = initialValue as Array<string>;
        receiversParam =
          options && options.length > 0 ? options.filter((data) => listReceivers.indexOf(data.value) > -1) : null;
        if (receiversParam.length === 0) {
          receiversParam = null;
        }
      } else {
        receiversParam =
          options && options.length > 0 ? options.filter((data) => data.value === initialValue)[0] : null;
      }

      if (receiversParam) {
        setCurrentValue(receiversParam);
      }
    }
  }, [initialValue, multiSelect, options, setCurrentValue]);

  const manualFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const renderLabel = () => {
    if (multiSelect && currentValue && currentValue.length > 0) {
      return null;
    }

    const label = `${props.label}${props.required ? '*' : ''}`;
    return (
      <div className="Label" onClick={manualFocusInput}>
        {label}
      </div>
    );
  };

  const renderErrorIcon = () => {
    if (props.hasError) {
      return <i className="fas fa-exclamation-circle ErrorIcon" />;
    }
    return null;
  };

  const renderDisabledIcon = () => {
    if (props.disabled) {
      return <i className="fas fa-lock DisabledIcon" />;
    }
    return null;
  };

  const renderContextHelp = () => {
    if (props.contextHelpKey) {
      return (
        <i className="fas fa-question-circle" onClick={() => setContextName(props.contextHelpKey)} title={t('help')} />
      );
    }
    return null;
  };

  const renderRightIcons = () => {
    const errorIcon = renderErrorIcon();
    const disabledIcon = renderDisabledIcon();
    const contextHelp = renderContextHelp();

    if (errorIcon || disabledIcon || contextHelp) {
      return (
        <div className="RightIconContainer">
          {errorIcon}
          {disabledIcon}
          {contextHelp}
        </div>
      );
    }
    return null;
  };

  const handleOnChange = (param) => {
    let currValue = param;
    if (multiSelect && optionAll) {
      if (Lodash.find(param, (p) => p.value === optionAll.value)) {
        currValue = Lodash.pullAll(options);
      }
    }
    setCurrentValue(currValue);

    if (multiSelect) {
      props.onChange(currValue.map((item) => item.value));
    } else {
      props.onChange(currValue.value);
    }
  };

  const renderContent = () => {
    if (!showSkeleton) {
      return (
        <>
          <ReactSelect
            onChange={handleOnChange}
            classNamePrefix="CustomSelect"
            placeholder={placeholder || t('pleaseSelect')}
            options={optionsPrepared}
            isDisabled={props.disabled}
            isMulti={multiSelect}
            closeMenuOnSelect={closeMenuOnSelect}
            value={currentValue}
          />
          {renderLabel()}
          {renderRightIcons()}
          {contextHelpElement}
        </>
      );
    }
    return null;
  };

  const renderSkeleton = () => {
    if (showSkeleton) {
      return <CustomSkeleton className="skeleton" />;
    }
    return null;
  };

  if (props.visible) {
    return (
      <Container
        $focused={!!props.initialValue}
        $hasError={props.hasError}
        $marginBottom={marginBottom}
        style={containerStyle}
      >
        {renderContent()}
        {renderSkeleton()}
      </Container>
    );
  }
  return null;
}

Select.defaultProps = {
  visible: true,
  initialValue: null,
  required: false,
  hasError: false,
  errorMessage: null,
  disabled: false,
  contextHelpKey: null,
  showSkeleton: false,
  multiSelect: false,
  placeholder: null,
  closeMenuOnSelect: true,
  marginBottom: 15,
};
