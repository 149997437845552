import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { MessageEntity } from '../../Globals/Types/Types';
import { useAppSelector, useGetMessageText } from '../../Globals/Hooks/Hooks';
import Panel from '../../Components/Panel';
import Text from '../../Components/Text';
import { useTranslation } from 'react-i18next';
import DetailsHeader from './Components/DetailsHeader';
import { useDispatchInboxMarkRead } from '../../Redux/Actions/User/InboxAction';
import DetailsAttachments from './Components/DetailsAttachments';

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const { messages } = useAppSelector((state) => state.user.inbox);
  const { messageId } = useParams();
  const [message, setMessage] = React.useState<MessageEntity>(null);
  const [t] = useTranslation();
  const getMessageText = useGetMessageText();
  const dispatchMarkRead = useDispatchInboxMarkRead();

  React.useEffect(() => {
    let timer;

    if (message && !message.read) {
      timer = setTimeout(() => dispatchMarkRead(message.messageId), 2000);
    }

    return () => clearTimeout(timer);
  }, [dispatchMarkRead, message]);

  useEffect(() => {
    if (messageId) {
      const found = messages.find((message) => message.messageId === messageId);
      if (found) {
        setMessage(found);
      } else {
        setMessage(null);
      }
    }
  }, [messageId, messages]);

  const renderMessage = () => {
    let messageParam = getMessageText(message);

    if (messageParam && messageParam.length > 0) {
      return <div dangerouslySetInnerHTML={{ __html: messageParam.replaceAll('\n', '<br/>') }} />;
    }
    return (
      <Text light italic>
        {t('inboxNoMessage')}
      </Text>
    );
  };

  if (message) {
    return (
      <>
        <DetailsHeader message={message} />
        <DetailsAttachments message={message} />
        <Panel>{renderMessage()}</Panel>
      </>
    );
  }
  return (
    <div style={{ marginTop: 40 }}>
      {t('inboxNoMessageSelected')
        .split('\n')
        .map((text) => (
          <Text light centered key={text}>
            {text}
          </Text>
        ))}
    </div>
  );
}
