import React from 'react';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import Panel from '../../Panel';
import Item from './Item';
import { Trans, useTranslation } from 'react-i18next';
import { ElasticSearchServiceEntity } from '../../../Globals/Types/OrderTypes';
import { buildUrlOrderServiceDetails } from '../../../Globals/UrlFunctions';
import { useNavigate } from 'react-router';
import Text from '../../Text';
import { AccessPath, useHasAccess } from '../../../Globals/Access/Groups';

/**
 * TodayList()
 * @constructor
 */
export default function TodayList() {
  const [t] = useTranslation();
  const hasAccess = useHasAccess(AccessPath.order);
  const navigate = useNavigate();
  const { today } = useAppSelector((state) => state.dashboard.services);

  const navigateDetails = (service: ElasticSearchServiceEntity) => {
    navigate(buildUrlOrderServiceDetails(service.ownerClientId, service.orderId, service.serviceId));
  };

  const renderContent = () => {
    if (today && today.length > 0) {
      return today.map((item) => <Item service={item} key={item.serviceId} onPress={navigateDetails} />);
    }
    return (
      <Text centered light>
        <Trans i18nKey={'modules.dashboard.ordersTodayEmptyDescription'} components={{ 1: <br /> }} />
      </Text>
    );
  };

  if (hasAccess) {
    return <Panel headline={t('xOrdersToday', { count: today ? today.length : 0 })}>{renderContent()}</Panel>;
  }
  return null;
}
