import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { CountryCode } from '../../Globals/Types/Enums';
import { STORAGE_SELECTED_LANGUAGE } from '../../Globals/Types/Constants';
import moment from 'moment/moment';
import i18n from 'i18next';
import { getSelectedLanguage, momentConfiguration } from '../../Globals/Functions/LocalizationFunctions';

interface Props {
  onChange: (item: CountryCode) => void;
  showSkeleton: boolean;
}

/**
 * SelectSystemLanguage
 * @param props
 * @constructor
 */
export default function SelectSystemLanguage(props: Props) {
  const { onChange, showSkeleton } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>([]);

  React.useEffect(() => {
    const optionArray = Object.keys(CountryCode).map((item) => ({
      value: CountryCode[item],
      label: t(`languageCodes.${CountryCode[item]}`),
    }));

    setOptions(optionArray);
  }, [t]);

  const handleOnChange = (value: string) => {
    localStorage.setItem(STORAGE_SELECTED_LANGUAGE, value);
    moment.locale(value);
    moment.localeData(value).set(momentConfiguration(getSelectedLanguage(true)));
    i18n.changeLanguage(value).then(() => {});
    onChange(value as CountryCode);
  };

  if (options && options.length > 0) {
    return (
      <Select
        label={t('language')}
        onChange={handleOnChange}
        initialValue={getSelectedLanguage(true)}
        options={options}
        showSkeleton={showSkeleton}
      />
    );
  }
  return null;
}

SelectSystemLanguage.defaultProps = {
  showSkeleton: false,
};
