import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import { Tour, TourItem, TourItemType, TourService } from '../../../Globals/Types/Tour';
import { PickedUserEntity, ResourceEntity, TourTemplateEntity, UserEntity } from '../../../Globals/Types/Types';
import Lodash from 'lodash';
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  where,
  writeBatch,
  WriteBatch,
  QuerySnapshot,
} from 'firebase/firestore';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import {
  TOUR_ADD_RESOURCE_ERROR,
  TOUR_ADD_RESOURCE_START,
  TOUR_ADD_RESOURCE_SUCCESS,
  TOUR_ADD_SERVICE_ERROR,
  TOUR_ADD_SERVICE_START,
  TOUR_ADD_SERVICE_SUCCESS,
  TOUR_ADD_USER_ERROR,
  TOUR_ADD_USER_START,
  TOUR_ADD_USER_SUCCESS,
  TOUR_CREATE_ERROR,
  TOUR_CREATE_FROM_TEMPLATE_ERROR,
  TOUR_CREATE_FROM_TEMPLATE_START,
  TOUR_CREATE_FROM_TEMPLATE_SUCCESS,
  TOUR_CREATE_START,
  TOUR_CREATE_SUCCESS,
  TOUR_DELETE_ERROR,
  TOUR_DELETE_RESOURCE_ERROR,
  TOUR_DELETE_RESOURCE_START,
  TOUR_DELETE_RESOURCE_SUCCESS,
  TOUR_DELETE_SERVICE_ERROR,
  TOUR_DELETE_SERVICE_START,
  TOUR_DELETE_SERVICE_SUCCESS,
  TOUR_DELETE_START,
  TOUR_DELETE_SUCCESS,
  TOUR_DELETE_USER_ERROR,
  TOUR_DELETE_USER_START,
  TOUR_DELETE_USER_SUCCESS,
  TOUR_GET_ERROR,
  TOUR_GET_FOR_ORDER_ERROR,
  TOUR_GET_FOR_ORDER_START,
  TOUR_GET_FOR_ORDER_SUCCESS,
  TOUR_GET_FOR_SERVICE_ERROR,
  TOUR_GET_FOR_SERVICE_START,
  TOUR_GET_FOR_SERVICE_SUCCESS,
  TOUR_GET_START,
  TOUR_GET_SUCCESS,
  TOUR_GETLIST_ERROR,
  TOUR_GETLIST_START,
  TOUR_GETLIST_SUCCESS,
  TOUR_TOGGLE_CLOSE_ERROR,
  TOUR_TOGGLE_CLOSE_START,
  TOUR_TOGGLE_CLOSE_SUCCESS,
  TOUR_UPDATE_ERROR,
  TOUR_UPDATE_START,
  TOUR_UPDATE_SUCCESS,
} from '../../ActionTypes';
import moment from 'moment';
import {
  ElasticSearchServiceDispositionInterface,
  ElasticSearchServiceEntity,
} from '../../../Globals/Types/OrderTypes';
import { userDisplayName, userEntityToPickedUserEntity } from '../../../Globals/Functions';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { HeyKitchenError } from '../../../Globals/HeyKitchenError';
import { getAllTourItemsForService, getServiceIdsIncludedInTour } from '../../../Globals/Functions/TourFunctions';
import { useDispatchOrderServiceGetList } from '../Order/ServiceAction';
import { ReportState } from '../../../Globals/Types/Report';
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * buildCollection
 * @param clientId
 */
const buildCollection = (clientId: string): CollectionReference<Tour> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.tour,
  ) as CollectionReference<Tour>;
};

/**
 * buildDoc()
 * @param clientId
 * @param tourId
 */
const buildDoc = (clientId: string, tourId: string): DocumentReference<Tour> => {
  return doc(buildCollection(clientId), tourId) as DocumentReference<Tour>;
};

/**
 * create()
 * @param clientId
 * @param tourList
 */
const create = (clientId: string, tourList: Tour[]): Promise<Tour[]> => {
  const tourCollection = buildCollection(clientId);

  const tourListCreated: Tour[] = [];
  let batch: WriteBatch = null;
  const promises: Array<Promise<void>> = [];
  tourList.forEach((tour, index) => {
    if (!batch) {
      batch = writeBatch(getFirestore());
    }
    const newDoc: DocumentReference<Tour> = doc(tourCollection);
    batch.set(newDoc, tour);
    if (index > 0 && index % 499 === 0) {
      promises.push(batch.commit());
      batch = null;
    }

    tourListCreated.push({ ...tour, tourId: newDoc.id });
  });

  if (batch) {
    promises.push(batch.commit());
  }

  return Promise.all(promises).then(() => Promise.resolve(tourListCreated));
};

/**
 * validateExistingToursResource()
 * @param clientId
 * @param tour
 */
const validateExistingToursResource = (clientId: string, tour: Tour): Promise<void> => {
  if (!tour.resources) {
    return Promise.resolve();
  }

  const tourCollection = buildCollection(clientId);
  const queryRef = query(
    tourCollection,
    where('date', '==', tour.date),
    where('resources', 'array-contains-any', tour.resources),
    limit(1),
  );

  return getDocs(queryRef).then((response) => {
    if (response.empty) {
      return Promise.resolve();
    }

    const tourExisting: Tour = { ...response.docs[0].data(), tourId: response.docs[0].id };
    const resource: ResourceEntity = Lodash.find(
      tourExisting.resources,
      (resource) => !!Lodash.find(tour.resources, (resource2) => resource2.resourceId === resource.resourceId),
    );

    return Promise.reject(
      new HeyKitchenError(InternalErrorCodes.DISPOSITION_ALREADY_PLANNED_RESOURCE, {
        name: resource.name,
        tourName: tourExisting.name,
        date: moment(tourExisting.date).format('LL'),
      }),
    );
  });
};

/**
 * validateExistingToursUser()
 * @param clientId
 * @param tour
 */
const validateExistingToursUser = (clientId: string, tour: Tour): Promise<void> => {
  if (!tour.users) {
    return Promise.resolve();
  }

  const tourCollection = buildCollection(clientId);
  const queryRef = query(
    tourCollection,
    where('date', '==', tour.date),
    where('users', 'array-contains-any', tour.users),
    limit(1),
  );

  return getDocs(queryRef).then((response) => {
    if (response.empty) {
      return Promise.resolve();
    }

    const tourExisting: Tour = { ...response.docs[0].data(), tourId: response.docs[0].id };
    const user: PickedUserEntity = Lodash.find(
      tourExisting.users,
      (user) => !!Lodash.find(tour.users, (user2) => user2.userId === user.userId),
    );

    return Promise.reject(
      new HeyKitchenError(InternalErrorCodes.DISPOSITION_ALREADY_PLANNED_USER, {
        name: userDisplayName(user),
        tourName: tourExisting.name,
        date: moment(tourExisting.date).format('LL'),
      }),
    );
  });
};

/**
 * useDispatchTourAddResource()
 */
type AddResourceReturnType = (tour: Tour, resource: ResourceEntity) => Promise<Tour>;
export const useDispatchTourAddResource = (): AddResourceReturnType => {
  const dispatch = useAppDispatch();
  const dispatchTourUpdate = useDispatchTourUpdate();

  return React.useCallback<AddResourceReturnType>(
    (tour, resource) => {
      dispatch({ type: TOUR_ADD_RESOURCE_START, payload: { tour, resource } });

      const resources: ResourceEntity[] = tour.resources ? [...tour.resources] : [];
      resources.push(resource);
      const tourUpdated: Tour = {
        ...tour,
        resources,
      };

      return dispatchTourUpdate(tourUpdated)
        .then(() => {
          dispatch({ type: TOUR_ADD_RESOURCE_SUCCESS, payload: tourUpdated });
          return Promise.resolve(tourUpdated);
        })
        .catch((error) => {
          dispatch({ type: TOUR_ADD_RESOURCE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, dispatchTourUpdate],
  );
};

/**
 * useDispatchTourAddService()
 */
type AddServiceReturnType = (
  tour: Tour,
  service: ElasticSearchServiceEntity,
  startTime: string,
  endTime: string,
) => Promise<Tour>;
export const useDispatchTourAddService = (): AddServiceReturnType => {
  const dispatch = useAppDispatch();
  const dispatchTourUpdate = useDispatchTourUpdate();

  return React.useCallback<AddServiceReturnType>(
    (tour, service, startTime, endTime) => {
      dispatch({ type: TOUR_ADD_SERVICE_START, payload: { tour, service } });

      const serviceItem: TourItem<TourService> = {
        type: TourItemType.service,
        startTime,
        endTime,
        data: {
          assignedClientId: service.assignedClientId || null,
          assignedFacilityId: service.assignedFacilityId || null,
          createdClientId: service.createdClientId,
          createdDate: service.createdDate,
          createdFacilityId: service.createdFacilityId,
          createdUserId: service.createdUserId,
          customer: service.customer,
          desiredDate: service.desiredDate || null,
          desiredExecutionTime: service.desiredExecutionTime || null,
          desiredWeek: service.desiredWeek || null,
          estimatedAssemblyTime: service.estimatedAssemblyTime,
          externalOrderId: service.externalOrderId || null,
          externalServiceId: service.externalServiceId || null,
          orderId: service.orderId,
          ownerClientId: service.ownerClientId,
          ownerFacilityId: service.ownerFacilityId,
          serviceId: service.serviceId,
          serviceType: service.serviceType,
          reportState: service.reportState || ReportState.none,
          isRecurring: service.isRecurring,
          deliveryAndLoadingOption: service.deliveryAndLoadingOption,
        },
      };

      const items: TourItem<any>[] = tour.items ? [...tour.items] : [];
      items.push(serviceItem);

      const tourUpdated: Tour = {
        ...tour,
        serviceIds: getServiceIdsIncludedInTour({ ...tour, items }),
        items,
      };

      return dispatchTourUpdate(tourUpdated)
        .then(() => {
          dispatch({ type: TOUR_ADD_SERVICE_SUCCESS, payload: tourUpdated });
          return Promise.resolve(tourUpdated);
        })
        .catch((error) => {
          dispatch({ type: TOUR_ADD_SERVICE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, dispatchTourUpdate],
  );
};

/**
 * useDispatchTourAddUser()
 */
type AddUserReturnType = (tour: Tour, user: UserEntity) => Promise<Tour>;
export const useDispatchTourAddUser = (): AddUserReturnType => {
  const dispatch = useAppDispatch();
  const dispatchTourUpdate = useDispatchTourUpdate();

  return React.useCallback<AddUserReturnType>(
    (tour: Tour, user: UserEntity) => {
      const userPicked: PickedUserEntity = userEntityToPickedUserEntity(user);

      dispatch({ type: TOUR_ADD_USER_START, payload: { tour, userPicked } });

      const users: PickedUserEntity[] = tour.users ? [...tour.users] : [];
      users.push(userPicked);

      const tourUpdated: Tour = {
        ...tour,
        users,
      };

      return dispatchTourUpdate(tourUpdated)
        .then(() => {
          dispatch({ type: TOUR_ADD_USER_SUCCESS, payload: tourUpdated });
          return Promise.resolve(tourUpdated);
        })
        .catch((error) => {
          dispatch({ type: TOUR_ADD_USER_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, dispatchTourUpdate],
  );
};

/**
 * useDispatchTourCreate()
 */
type CreateReturnType = (tour: Tour, dates: string[]) => Promise<Tour[]>;
export const useDispatchTourCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<CreateReturnType>(
    (tour, dates) => {
      dispatch({ type: TOUR_CREATE_START, payload: { dates, tour } });

      const tourList: Tour[] = [];
      dates.forEach((date) => {
        tourList.push({
          ...tour,
          active: true,
          closed: false,
          date,
          firebaseDate: moment(date).toDate(),
        });
      });

      return create(clientId, tourList)
        .then((tourListCreated) => {
          dispatch({ type: TOUR_CREATE_SUCCESS, payload: tourListCreated });
          return Promise.resolve(tourListCreated);
        })
        .catch((error) => {
          dispatch({ type: TOUR_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchTourCreateFromTemplate()
 */
type CreateFromTemplateReturnType = (tourTemplateIds: string[], dates: string[]) => Promise<Tour[]>;
export const useDispatchTourCreateFromTemplate = (): CreateFromTemplateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);
  const { tourTemplates } = useAppSelector((state) => state.client);

  return React.useCallback<CreateFromTemplateReturnType>(
    (tourTemplateIds, dates) => {
      dispatch({ type: TOUR_CREATE_FROM_TEMPLATE_START, payload: { dates, tourTemplateIds } });

      const tourTemplateList: TourTemplateEntity[] = Lodash.filter(tourTemplates, (template) => {
        return !!Lodash.find(tourTemplateIds, (tourId) => template.tourTemplateId === tourId);
      });

      const tourList: Tour[] = [];
      dates.forEach((date) => {
        tourTemplateList.forEach((tourTemplate) => {
          const tour: Tour = {
            active: true,
            closed: false,
            comment: tourTemplate.description || null,
            date,
            firebaseDate: moment(date).toDate(),
            facilityIds: tourTemplate.facilityIds || null,
            name: tourTemplate.name,
            resources: tourTemplate.resources || null,
            templateId: tourTemplate.tourTemplateId,
            users: tourTemplate.users || null,
            serviceIds: [],
          };
          tourList.push(tour);
        });
      });

      /**
       * Check existing tours for already planned users and resources only if tours are created for a single date.
       * Checking this for several dates could lead to alot of request, so we won't do it for now
       */
      const promises: Promise<void>[] = [];
      if (dates.length === 1) {
        tourList.forEach((tour) => {
          promises.push(validateExistingToursUser(clientId, tour));
          promises.push(validateExistingToursResource(clientId, tour));
        });
      }

      return Promise.all(promises)
        .then(() => {
          return create(clientId, tourList).then((tourListCreated) => {
            dispatch({ type: TOUR_CREATE_FROM_TEMPLATE_SUCCESS, payload: tourListCreated });
            return Promise.resolve(tourListCreated);
          });
        })
        .catch((error) => {
          dispatch({ type: TOUR_CREATE_FROM_TEMPLATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, tourTemplates],
  );
};

/**
 * useDispatchTourDelete()
 */
type DeleteReturnType = (tour: Tour) => Promise<Tour>;
export const useDispatchTourDelete = (): DeleteReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<DeleteReturnType>(
    (tour) => {
      dispatch({ type: TOUR_DELETE_START, payload: tour });

      const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.tourDelete);
      return callable({ clientId, tourId: tour.tourId })
        .then(() => {
          dispatch({ type: TOUR_DELETE_SUCCESS, payload: tour });
          return Promise.resolve(tour);
        })
        .catch((error) => {
          dispatch({ type: TOUR_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchTourDeleteResource()
 */
type DeleteResourceReturnType = (tour: Tour, resource: ResourceEntity) => Promise<Tour>;
export const useDispatchTourDeleteResource = (): DeleteResourceReturnType => {
  const dispatch = useAppDispatch();
  const dispatchTourUpdate = useDispatchTourUpdate();

  return React.useCallback<DeleteResourceReturnType>(
    (tour, resource) => {
      dispatch({ type: TOUR_DELETE_RESOURCE_START, payload: { tour, resource } });

      const resources: ResourceEntity[] = Lodash.filter(
        tour.resources,
        (item) => item.resourceId !== resource.resourceId,
      );

      const tourUpdated: Tour = {
        ...tour,
        resources: resources && resources.length !== 0 ? resources : null,
      };

      return dispatchTourUpdate(tourUpdated)
        .then(() => {
          dispatch({ type: TOUR_DELETE_RESOURCE_SUCCESS, payload: tourUpdated });
          return Promise.resolve(tourUpdated);
        })
        .catch((error) => {
          dispatch({ type: TOUR_DELETE_RESOURCE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, dispatchTourUpdate],
  );
};

/**
 * useDispatchTourDeleteService()
 */
type DeleteServiceReturnType = (tour: Tour, service: TourService) => Promise<Tour>;
export const useDispatchTourDeleteService = (): DeleteServiceReturnType => {
  const dispatch = useAppDispatch();
  const dispatchTourUpdate = useDispatchTourUpdate();

  return React.useCallback<DeleteServiceReturnType>(
    (tour, service) => {
      dispatch({ type: TOUR_DELETE_SERVICE_START, payload: { tour, service } });

      const filteredItems = tour.items.filter((item) => item.data.serviceId !== service.serviceId);
      const tourUpdated: Tour = {
        ...tour,
        serviceIds: getServiceIdsIncludedInTour({ ...tour, items: filteredItems || [] }),
        items: filteredItems && filteredItems.length !== 0 ? filteredItems : null,
      };

      return dispatchTourUpdate(tourUpdated)
        .then(() => {
          dispatch({ type: TOUR_DELETE_SERVICE_SUCCESS, payload: tourUpdated });
          return Promise.resolve(tourUpdated);
        })
        .catch((error) => {
          dispatch({ type: TOUR_DELETE_SERVICE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, dispatchTourUpdate],
  );
};

/**
 * useDispatchTourDeleteUser()
 */
type DeleteUserReturnType = (tour: Tour, user: PickedUserEntity) => Promise<Tour>;
export const useDispatchTourDeleteUser = (): DeleteUserReturnType => {
  const dispatch = useAppDispatch();
  const dispatchTourUpdate = useDispatchTourUpdate();

  return React.useCallback<DeleteUserReturnType>(
    (tour, user) => {
      dispatch({ type: TOUR_DELETE_USER_START, payload: { tour, user } });

      const users: PickedUserEntity[] = Lodash.filter(tour.users, (item) => item.userId !== user.userId);

      const tourUpdated: Tour = {
        ...tour,
        users: users && users.length !== 0 ? users : null,
      };

      return dispatchTourUpdate(tourUpdated)
        .then(() => {
          dispatch({ type: TOUR_DELETE_USER_SUCCESS, payload: tourUpdated });
          return Promise.resolve(tourUpdated);
        })
        .catch((error) => {
          dispatch({ type: TOUR_DELETE_USER_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, dispatchTourUpdate],
  );
};

/**
 * useDispatchTourGet()
 */
type TourGetReturnType = (tourId: string) => Promise<Tour>;
export const useDispatchTourGet = (): TourGetReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<TourGetReturnType>(
    (tourId) => {
      dispatch({ type: TOUR_GET_START, payload: tourId });

      const tourDoc = buildDoc(clientId, tourId);
      return getDoc(tourDoc)
        .then((tourResponse) => {
          const merged: Tour = { ...tourResponse.data(), tourId: tourResponse.id };
          dispatch({ type: TOUR_GET_SUCCESS, payload: merged });
          return Promise.resolve(merged);
        })
        .catch((error) => {
          dispatch({ type: TOUR_GET_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchTourGetList()
 */
type TourGetListReturnType = (dateStart: string, dateEnd: string) => Promise<Tour[]>;
export const useDispatchTourGetList = (): TourGetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId, user } = useAppSelector((state) => state.auth);
  const { facilities } = user;

  return React.useCallback<TourGetListReturnType>(
    (dateStart, dateEnd) => {
      dispatch({ type: TOUR_GETLIST_START, payload: { dateStart, dateEnd } });
      const tourCollection = buildCollection(clientId);

      // Max 10 entries in where 'array-contains-any' possible (facilities here)
      // https://firebase.google.com/docs/firestore/query-data/queries#in_and_array-contains-any
      // Because of this we query multiple times here, each time with 10 facilityIds
      const promises: Promise<QuerySnapshot<Tour>>[] = [];
      let facilitiesSplit: string[] = [];

      const addPromise = (facilityIds) => {
        const queryRef = query(
          tourCollection,
          where('date', '>=', dateStart),
          where('date', '<=', dateEnd),
          where('facilityIds', 'array-contains-any', facilityIds),
          orderBy('date', 'asc'),
        );
        promises.push(getDocs(queryRef));

        facilitiesSplit = [];
      };

      facilities.forEach((facilityId) => {
        facilitiesSplit.push(facilityId);

        if (facilitiesSplit.length >= 10) {
          addPromise(facilitiesSplit);
        }
      });

      if (facilitiesSplit.length !== 0) {
        addPromise(facilitiesSplit);
      }

      return Promise.all(promises)
        .then((response) => {
          const tourListTemp: Tour[] = [];
          response.forEach((snapShot) => {
            snapShot.forEach((tour) => {
              if (!tourListTemp.find((tourTemp) => tourTemp.tourId === tour.id)) {
                tourListTemp.push({ ...tour.data(), tourId: tour.id });
              }
            });
          });

          const tourList: Tour[] = Lodash.orderBy(tourListTemp, 'date', 'asc');

          dispatch({ type: TOUR_GETLIST_SUCCESS, payload: { dateStart, dateEnd, tourList } });
          return Promise.resolve(tourList);
        })
        .catch((error) => {
          dispatch({ type: TOUR_GETLIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, facilities],
  );
};

/**
 * useDispatchTourToggleClose()
 */
export const useDispatchTourToggleClose = () => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (tour) => {
      dispatch({ type: TOUR_TOGGLE_CLOSE_START, payload: tour });

      const tourUpdated: Tour = { ...tour, closed: !tour.closed };

      const tourDoc = buildDoc(clientId, tour.tourId);
      return setDoc(tourDoc, tourUpdated, { merge: true })
        .then(() => {
          dispatch({ type: TOUR_TOGGLE_CLOSE_SUCCESS, payload: tourUpdated });
          return Promise.resolve(tourUpdated);
        })
        .catch((error) => {
          dispatch({ type: TOUR_TOGGLE_CLOSE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchTourUpdate()
 */
type UpdateReturnType = (tour: Tour) => Promise<Tour>;
export const useDispatchTourUpdate = (): UpdateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateReturnType>(
    (tour) => {
      dispatch({ type: TOUR_UPDATE_START, payload: tour });

      delete tour.firebaseDate; // Never update a firebase timestamp field value. Will be destroyed to an object!
      const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.tourUpdate);

      return callable({ clientId, tourId: tour.tourId, tour })
        .then(() => {
          dispatch({ type: TOUR_UPDATE_SUCCESS, payload: tour });
          return Promise.resolve(tour);
        })
        .catch((error) => {
          dispatch({ type: TOUR_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * Return all tour items for given service
 * useDispatchTourGetForService()
 */
type GetForServiceReturnType = (serviceId: string) => Promise<ElasticSearchServiceDispositionInterface[]>;
export const useDispatchTourGetForService = (): GetForServiceReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetForServiceReturnType>(
    (serviceId: string) => {
      dispatch({ type: TOUR_GET_FOR_SERVICE_START, payload: serviceId });

      const collection = buildCollection(clientId);
      const queryRef = query(collection, where('serviceIds', 'array-contains', serviceId));
      return getDocs(queryRef)
        .then((response) => {
          const tours: Tour[] = [];

          if (!response.empty) {
            response.forEach((tourParam) => {
              tours.push({ ...tourParam.data(), tourId: tourParam.id });
            });
          }

          const responseResult = getAllTourItemsForService(tours, serviceId);
          dispatch({ type: TOUR_GET_FOR_SERVICE_SUCCESS, payload: responseResult });
          return Promise.resolve(responseResult);
        })
        .catch((error) => {
          dispatch({ type: TOUR_GET_FOR_SERVICE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * Return all tour items for all services of the given order
 * useDispatchTourGetForOrder()
 */
type GetForOrderReturnType = (clientId: string, orderId: string) => Promise<ElasticSearchServiceDispositionInterface[]>;
export const useDispatchTourGetForOrder = (): GetForOrderReturnType => {
  const dispatch = useAppDispatch();
  const dispatchGetOrderServices = useDispatchOrderServiceGetList();
  const dispatchTourGetForService = useDispatchTourGetForService();

  return React.useCallback<GetForOrderReturnType>(
    (clientId, orderId) => {
      dispatch({ type: TOUR_GET_FOR_ORDER_START, payload: { clientId, orderId } });

      return dispatchGetOrderServices(clientId, orderId)
        .then((serviceResponse) => {
          const promiseAll: Promise<ElasticSearchServiceDispositionInterface[]>[] = [];

          serviceResponse.forEach((service) => {
            promiseAll.push(dispatchTourGetForService(service.serviceId));
          });

          let result: ElasticSearchServiceDispositionInterface[] = [];
          return Promise.all(promiseAll).then((response) => {
            response.forEach((item) => {
              result = [...result, ...item];
            });

            dispatch({ type: TOUR_GET_FOR_ORDER_SUCCESS, payload: result });
            return Promise.resolve(result);
          });
        })
        .catch((error) => {
          dispatch({ type: TOUR_GET_FOR_ORDER_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, dispatchGetOrderServices, dispatchTourGetForService],
  );
};
