import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../Components/PageHeader';
import Panel from '../../../Components/Panel';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import Input, { InputType } from '../../../Components/Inputs/Input';
import { FacilityEntity, GroupType, UserEntity } from '../../../Globals/Types/Types';
import Select from '../../../Components/Inputs/Select';
import { selectOptionActive } from '../../../Globals/Types/General';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import ValidateCreateUser, { CreateClientValidationError } from './Components/ValidateCreateUser';
import { useDispatchUserMailExisting } from '../../../Redux/Actions/AuthAction';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import ErrorMessage from '../../../Components/Modals/ErrorMessage';
import { useNavigate } from 'react-router';
import { useDispatchUserCreate } from '../../../Redux/Actions/UserAction';
import GroupsForm from './Components/GroupsForm';
import PasswordStrengthBar from 'react-password-strength-bar';
import { PASSWORD_MIN_LENGTH } from '../../../Globals/Globals';
import FacilitiesForm from '../../../Components/FacilitiesForm';
import { CountryCode, UserLoginPermission } from '../../../Globals/Types/Enums';
import SelectCountry from '../../../Components/PredefinedSelects/SelectCountry';
import SelectAccessCode from '../../../Components/PredefinedSelects/SelectAccessCode';
import SelectUserLoginPermission from '../../../Components/PredefinedSelects/SelectUserLoginPermission';

export type State = {
  user: UserEntity;
  password: string;
  passwordRepeat: string;
  errors: CreateClientValidationError;
  initialized: Boolean;
  hasChanges: Boolean;
};

type Action =
  | { type: 'update'; payload: { key: keyof UserEntity; value: string | number } }
  | { type: 'facilityId'; payload: string }
  | { type: 'updateGroup'; payload: { value: boolean; groupItem: GroupType } }
  | { type: 'updateFacilities'; payload: { value: boolean; facilityEntity: FacilityEntity } }
  | { type: 'password'; payload: string }
  | { type: 'passwordRepeat'; payload: string }
  | { type: 'setErrors'; payload: CreateClientValidationError }
  | { type: 'init'; payload: UserEntity };

const initialState: State = {
  user: {
    countryCode: CountryCode.ES,
    mail: '',
    firstName: '',
    lastName: '',
    initials: '',
    clientId: '',
    facilityId: '',
    authId: '',
    devices: [],
    createdDate: null,
    active: true,
    groups: [],
    facilities: [],
    note: null,
    changePassword: true,
    accessCode: 0,
    loginPermission: UserLoginPermission.both,
  },
  password: '',
  passwordRepeat: '',
  errors: {
    user: {} as UserEntity,
  } as CreateClientValidationError,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update': {
      const { key, value } = action.payload;
      return { ...state, user: { ...state.user, [key]: value }, hasChanges: true };
    }
    case 'facilityId': {
      const isChecked = state.user.facilities.indexOf(action.payload) > -1;
      const facilities = !isChecked ? [...state.user.facilities, action.payload] : state.user.facilities;
      return {
        ...state,
        user: {
          ...state.user,
          facilityId: action.payload,
          facilities,
        },
        hasChanges: true,
      };
    }
    case 'updateGroup': {
      const { value, groupItem } = action.payload;
      if (value) {
        return { ...state, user: { ...state.user, groups: [...state.user.groups, groupItem.key] }, hasChanges: true };
      }
      return {
        ...state,
        user: {
          ...state.user,
          groups: state.user.groups.filter((param) => groupItem.key !== param),
        },
        hasChanges: true,
      };
    }
    case 'updateFacilities': {
      const { value, facilityEntity } = action.payload;
      if (value) {
        return {
          ...state,
          user: {
            ...state.user,
            facilities: [...state.user.facilities, facilityEntity.facilityId],
          },
          hasChanges: true,
        };
      }
      return {
        ...state,
        user: {
          ...state.user,
          facilities: state.user.facilities.filter((param) => facilityEntity.facilityId !== param),
        },
        hasChanges: true,
      };
    }
    case 'password':
      return { ...state, password: action.payload, hasChanges: true };
    case 'passwordRepeat':
      return { ...state, passwordRepeat: action.payload, hasChanges: true };
    case 'init': {
      return {
        ...state,
        user: action.payload,
        initialized: true,
        hasChanges: false,
        errors: {} as CreateClientValidationError,
      };
    }
    case 'setErrors':
      return { ...state, errors: action.payload };
  }
};

/**
 * Create()
 * @constructor
 */
export default function Create() {
  const { client } = useAppSelector((state) => state.auth);
  const { facilities } = useAppSelector((state) => state.client);
  const [selectOptionsFacility, setSelectOptionsFacility] =
    React.useState<Array<{ value: string; label: string }>>(null);
  const [state, dispatch] = React.useReducer(reducer, initialState, () => ({
    ...initialState,
    user: {
      ...initialState.user,
      facilityId: client.mainFacility,
      facilities: [client.mainFacility],
      changePassword: true,
    },
  }));
  const { loadingModal, setLoading, isLoading } = useLoadingModal({ delayStart: 1000 });
  const [showMailError, setShowMailError] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatchUserExists = useDispatchUserMailExisting();
  const dispatchCreate = useDispatchUserCreate();
  const [scoreWords] = React.useState([
    t('passwordStrength.weakest'),
    t('passwordStrength.weak'),
    t('passwordStrength.okay'),
    t('passwordStrength.good'),
    t('passwordStrength.veryGood'),
  ]);

  React.useEffect(() => {
    const mapped = facilities.map((item) => ({ value: item.facilityId, label: item.name }));
    setSelectOptionsFacility(mapped);
  }, [facilities]);

  const generateInitials = React.useCallback(() => {
    if (!state.user.initials) {
      if (state.user.lastName) {
        const initials = state.user.lastName.substring(0, 3).toUpperCase();
        dispatch({ type: 'update', payload: { key: 'initials', value: initials } });
      }
    }
  }, [state]);

  const handleSave = () => {
    const { isValid, errors } = ValidateCreateUser(state.user, state.password, state.passwordRepeat);

    if (isValid) {
      setLoading(true);
      dispatchUserExists(state.user.mail)
        .then((isExisting) => {
          if (isExisting) {
            setLoading(false);
            setShowMailError(true);
            // TODO: [DRA] errors can't be set here, since isValid would be false and handled before? Didn't remove it
            // because typescript error if only mail error is set. Then other fields from UserEntity are missing
            dispatch({
              type: 'setErrors',
              payload: { ...errors, user: { ...errors.user, mail: InternalErrorCodes.USER_ALREADY_EXISTING } },
            });
            return;
          }

          dispatchCreate(state.user, state.password).then(() => {
            setLoading(false);
            navigate(-1);
          });
        })
        .catch(() => setLoading(false));
    } else {
      dispatch({ type: 'setErrors', payload: errors });
    }
  };

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
        {t('save')}
      </Button>
    );
  };

  const renderCancelButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => navigate(-1)} disabled={!state.hasChanges}>
        {t('cancel')}
      </Button>
    );
  };

  const renderFacility = () => {
    if (selectOptionsFacility && facilities.length > 1) {
      return (
        <Col xl={6}>
          <Select
            options={selectOptionsFacility}
            label={t('defaultFacility')}
            onChange={(value) => dispatch({ type: 'facilityId', payload: value })}
            initialValue={state.user.facilityId}
            hasError={!!state.errors.user.facilityId}
            visible={facilities.length > 1}
            contextHelpKey="userDefaultFacility"
            disabled={isLoading}
          />
        </Col>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader
        headline={t('createUser')}
        actionButtonOne={renderSaveButton()}
        actionButtonTwo={renderCancelButton()}
      />

      <Panel>
        <Row>
          <Col xl={6}>
            <Input
              label={t('firstName')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'firstName', value } })}
              value={state.user.firstName}
              required
              hasError={!!state.errors.user.firstName}
              disabled={isLoading}
              autoFocus
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('lastName')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'lastName', value } })}
              value={state.user.lastName}
              required
              hasError={!!state.errors.user.lastName}
              onBlur={generateInitials}
              disabled={isLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('userInitials')}
              onChange={(value) =>
                dispatch({
                  type: 'update',
                  payload: { key: 'initials', value: value.toUpperCase() },
                })
              }
              value={state.user.initials}
              required
              hasError={!!state.errors.user.initials}
              maxLength={4}
              disabled={isLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('eMailAddress')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'mail', value } })}
              value={state.user.mail}
              required
              hasError={!!state.errors.user.mail}
              disabled={isLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('socialSecurityNumber')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'socialSecurityNumber', value } })}
              value={state.user.socialSecurityNumber}
              hasError={!!state.errors.user.socialSecurityNumber}
              disabled={isLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('taxNumber')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'taxNumber', value } })}
              value={state.user.taxNumber}
              hasError={!!state.errors.user.taxNumber}
              disabled={isLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('phone')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'phone', value } })}
              value={state.user.phone}
              hasError={!!state.errors.user.phone}
              disabled={isLoading}
            />
          </Col>
          <Col xl={6}>
            <SelectCountry
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'countryCode', value } })}
              selected={state.user.countryCode}
              label={t('languageOrCountry')}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('externalUserId')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'externalId', value } })}
              value={state.user.externalId}
              hasError={!!state.errors.user.externalId}
              contextHelpKey="userExternalId"
              disabled={isLoading}
            />
          </Col>
          <Col xl={6}>
            <Select
              options={selectOptionActive}
              label={t('userActive')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'active', value } })}
              initialValue={state.user.active}
              required
              hasError={!!state.errors.user.active}
              contextHelpKey="userActiveSettings"
              disabled={isLoading}
            />
          </Col>
          {renderFacility()}
          <Col xl={6}>
            <SelectUserLoginPermission
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'loginPermission', value } })}
              initialValue={state.user.loginPermission}
              contextHelpKey={'loginPermissions'}
            />
          </Col>
          <Col xl={6}>
            <SelectAccessCode
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'accessCode', value } })}
              initialValue={state.user.accessCode}
              disabled={isLoading}
              showSkeleton={false}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl={3}>
            <Input
              label={t('zip')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'zip', value } })}
              value={state.user.zip}
              hasError={!!state.errors.user.zip}
              disabled={isLoading}
            />
          </Col>
          <Col xl={9}>
            <Input
              label={t('city')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'city', value } })}
              value={state.user.city}
              hasError={!!state.errors.user.city}
              disabled={isLoading}
            />
          </Col>
          <Col xl={9}>
            <Input
              label={t('street')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'street', value } })}
              value={state.user.street}
              hasError={!!state.errors.user.street}
              disabled={isLoading}
            />
          </Col>
          <Col xl={3}>
            <Input
              label={t('streetNo')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'streetNo', value } })}
              value={state.user.streetNo}
              hasError={!!state.errors.user.streetNo}
              disabled={isLoading}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col lg={12}>
            <Alert variant="warning">
              <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
              Der Benutzer wird nach der ersten Anmeldung aufgefordert sein initiales Passwort zu ändern!
            </Alert>
          </Col>
          <Col xl={6}>
            <Input
              label={t('password')}
              onChange={(value) => dispatch({ type: 'password', payload: value })}
              value={state.password}
              required
              type={InputType.password}
              hasError={!!state.errors.password}
              disabled={isLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('passwordRepeat')}
              onChange={(value) => dispatch({ type: 'passwordRepeat', payload: value })}
              value={state.passwordRepeat}
              required
              type={InputType.password}
              hasError={!!state.errors.passwordRepeat}
              disabled={isLoading}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <PasswordStrengthBar
              password={state.password}
              minLength={PASSWORD_MIN_LENGTH}
              scoreWords={scoreWords}
              shortScoreWord={t('passwordStrength.tooShort')}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 50 }}>
          <Col lg={12}>
            <Input
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'note', value } })}
              value={state.user.note}
              label={t('remark')}
              rows={3}
              type={InputType.textarea}
            />
          </Col>
        </Row>
      </Panel>

      <FacilitiesForm
        description={t('modules.settings.userFacilitiesDescription')}
        userFacilities={state.user.facilities}
        onChange={(value, facilityEntity) => dispatch({ type: 'updateFacilities', payload: { value, facilityEntity } })}
        hasError={state.errors.user.facilities && state.errors.user.facilities.length > 0}
        userDefaultFacility={state.user.facilityId}
      />

      <Panel headline={t('userGroups')} description={t('modules.settings.userGroupsDescription')}>
        <GroupsForm
          userGroups={state.user.groups}
          onChange={(value, groupItem) => dispatch({ type: 'updateGroup', payload: { value, groupItem } })}
          hasError={state.errors.groups && state.errors.groups.length > 0}
        />
      </Panel>

      <ErrorMessage
        headline={t('modules.settings.userAlreadyExisting')}
        description={t('modules.settings.userAlreadyExistingDescription')}
        onClose={() => setShowMailError(false)}
        visible={showMailError}
      />

      {loadingModal}
    </>
  );
}
