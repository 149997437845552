import React from 'react';
import { ElasticSearchServiceInterface } from '../../../Globals/Types/OrderTypes';
import Table from '../../Table/Table';
import { useTranslation } from 'react-i18next';
import ServiceTableRow from './ServiceTableRow';
import { useClientHasFacilities, useClientHasPartners } from '../../../Globals/Hooks/ClientHooks';

interface Props {
  services: ElasticSearchServiceInterface[];
  onClick: (item: ElasticSearchServiceInterface) => void;
  hideCustomer: boolean;
  hideAddress: boolean;
  hideMontageDate: boolean;
}

/**
 * ServiceTable
 * @param props
 * @constructor
 */
export default function ServiceTable(props: Props) {
  const { onClick, services, hideCustomer, hideAddress, hideMontageDate } = props;
  const [t] = useTranslation();
  const showPartners = useClientHasPartners();
  const showFacilities = useClientHasFacilities();

  const renderFacility = () => {
    if (showFacilities) {
      return <th>{t('facility')}</th>;
    }
    return null;
  };

  const renderPartners = () => {
    if (showPartners) {
      return <th>{t('partner')}</th>;
    }
    return null;
  };

  const renderBody = () => {
    return services.map((service) => (
      <ServiceTableRow
        service={service}
        onClick={onClick}
        key={service.serviceId}
        hideCustomer={hideCustomer}
        hideAddress={hideAddress}
        hideMontageDate={hideMontageDate}
      />
    ));
  };

  const renderCustomer = () => {
    if (!hideCustomer) {
      return <th>{t('customer')}</th>;
    }
    return null;
  };

  const renderAddress = () => {
    if (!hideAddress) {
      return <th>{t('address')}</th>;
    }
    return null;
  };

  const renderMontageDate = () => {
    if (!hideMontageDate) {
      return <th>{t('montageDate')}</th>;
    }
    return null;
  };

  if (services && services.length > 0) {
    return (
      <Table onClickRow={() => {}}>
        <thead>
          <tr>
            <th style={{ width: 240 }}>{t('type')}</th>
            <th>{t('commission')}</th>
            {renderMontageDate()}
            <th>{t('assemblyTime')}</th>
            {renderCustomer()}
            {renderAddress()}
            {renderFacility()}
            {renderPartners()}
          </tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </Table>
    );
  }
  return null;
}

ServiceTable.defaultProps = {
  hideCustomer: false,
  hideAddress: false,
  hideMontageDate: false,
};
