import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { FirebasePathMappings } from '../../Globals/FirebaseGlobals';
import { useAppDispatch, useAppSelector } from '../../Globals/Hooks/Hooks';
import React from 'react';
import {
  CUSTOMER_CREATE_ERROR,
  CUSTOMER_CREATE_START,
  CUSTOMER_CREATE_SUCCESS,
  CUSTOMER_DELETE_ERROR,
  CUSTOMER_DELETE_START,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_GET_ERROR,
  CUSTOMER_GET_START,
  CUSTOMER_GET_SUCCESS,
  CUSTOMER_GETLIST_ERROR,
  CUSTOMER_GETLIST_START,
  CUSTOMER_GETLIST_SUCCESS,
  CUSTOMER_UPDATE_ERROR,
  CUSTOMER_UPDATE_START,
  CUSTOMER_UPDATE_SUCCESS,
} from '../ActionTypes';
import { InternalErrorCodes } from '../../Globals/InternalErrorCodes';
import { CustomerEntity } from '../../Globals/Types/Customer';
import moment from 'moment';

/**
 * buildCollection()
 * @param clientId
 */
const buildCollection = (clientId: string): CollectionReference<CustomerEntity> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.customer,
  ) as CollectionReference<CustomerEntity>;
};

/**
 * useDispatchCustomerGetList()
 */
type GetListReturnType = () => Promise<CustomerEntity[]>;
export const useDispatchCustomerGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListReturnType>(() => {
    dispatch({ type: CUSTOMER_GETLIST_START });

    const collection = buildCollection(clientId);

    return getDocs(query(collection, orderBy('lastName')))
      .then((response) => {
        let returnData: CustomerEntity[] = [];

        if (!response.empty) {
          response.forEach((customer) => {
            returnData.push({ ...customer.data(), customerId: customer.id });
          });
        }

        dispatch({ type: CUSTOMER_GETLIST_SUCCESS, payload: returnData });
        return Promise.resolve(returnData);
      })
      .catch((error) => {
        dispatch({ type: CUSTOMER_GETLIST_ERROR, payload: error });
        return Promise.resolve(error);
      });
  }, [clientId, dispatch]);
};

/**
 * useDispatchCustomerGet()
 */
type GetReturnType = (customerId: string) => Promise<CustomerEntity>;
export const useDispatchCustomerGet = (): GetReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetReturnType>(
    (customerId) => {
      dispatch({ type: CUSTOMER_GET_START, payload: customerId });

      const collection = buildCollection(clientId);
      const docRef = doc(collection, customerId) as DocumentReference<CustomerEntity>;

      return getDoc(docRef)
        .then((docResponse) => {
          if (docResponse.exists()) {
            const mapped: CustomerEntity = { ...docResponse.data(), customerId: docResponse.id };
            dispatch({ type: CUSTOMER_GET_SUCCESS, payload: mapped });
            return Promise.resolve(mapped);
          }

          return Promise.reject(new Error(InternalErrorCodes.ENTRY_NOT_FOUND));
        })
        .catch((error) => {
          dispatch({ type: CUSTOMER_GET_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchCustomerCreate()
 */
type CreateReturnType = (position: CustomerEntity) => Promise<CustomerEntity>;
export const useDispatchCustomerCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId, userId } = useAppSelector((state) => state.auth);

  return React.useCallback<CreateReturnType>(
    (customer) => {
      dispatch({ type: CUSTOMER_CREATE_START, payload: customer });

      const mergedCustomer: CustomerEntity = {
        ...customer,
        createdDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        createdUserId: userId,
        lastUpdatedDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        lastUpdatedUserId: userId,
      };
      const collection = buildCollection(clientId);

      return addDoc(collection, mergedCustomer)
        .then((response) => {
          const docRef = doc(getFirestore(), response.path) as DocumentReference<CustomerEntity>;
          return getDoc(docRef).then((docResponse) => {
            if (docResponse.exists()) {
              const mapped: CustomerEntity = { ...docResponse.data(), customerId: docResponse.id };

              return setDoc(docRef, mapped).then(() => {
                dispatch({ type: CUSTOMER_CREATE_SUCCESS, payload: mapped });
                return Promise.resolve(mapped);
              });
            }

            return Promise.reject(new Error(InternalErrorCodes.ENTRY_NOT_FOUND));
          });
        })
        .catch((error) => {
          dispatch({ type: CUSTOMER_CREATE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch, userId],
  );
};

/**
 * useDispatchCustomerUpdate()
 */
type UpdateReturnType = (customer: CustomerEntity) => Promise<CustomerEntity>;
export const useDispatchCustomerUpdate = (): UpdateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId, userId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateReturnType>(
    (customer) => {
      dispatch({ type: CUSTOMER_UPDATE_START, payload: customer });

      const mergedCustomer: CustomerEntity = {
        ...customer,
        lastUpdatedDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        lastUpdatedUserId: userId,
      };

      const collection = buildCollection(clientId);
      const docRef = doc(collection, customer.customerId);

      return setDoc(docRef, mergedCustomer, { merge: true })
        .then((response) => {
          dispatch({ type: CUSTOMER_UPDATE_SUCCESS, payload: customer });
          return Promise.resolve(customer);
        })
        .catch((error) => {
          dispatch({ type: CUSTOMER_UPDATE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch, userId],
  );
};

/**
 * useDispatchCustomerDelete()
 */
type DeleteReturnType = (customerId: string) => Promise<void>;
export const useDispatchCustomerDelete = (): DeleteReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<DeleteReturnType>(
    (customerId) => {
      dispatch({ type: CUSTOMER_DELETE_START, payload: customerId });

      const collection = buildCollection(clientId);
      const docRef = doc(collection, customerId);

      return deleteDoc(docRef)
        .then(() => {
          dispatch({ type: CUSTOMER_DELETE_SUCCESS, payload: customerId });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch({ type: CUSTOMER_DELETE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch],
  );
};
