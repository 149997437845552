import React from 'react';
import {
  collection,
  getFirestore,
  addDoc,
  query,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  CollectionReference,
  DocumentReference,
  orderBy,
  getDoc,
} from 'firebase/firestore';
import { ArticleEntity } from '../../../../Globals/Types/Types';
import { useAppDispatch, useAppSelector } from '../../../../Globals/Hooks/Hooks';
import {
  ARTICLE_CREATE_ERROR,
  ARTICLE_CREATE_START,
  ARTICLE_CREATE_SUCCESS,
  ARTICLE_DELETE_ERROR,
  ARTICLE_DELETE_START,
  ARTICLE_DELETE_SUCCESS,
  ARTICLE_GET_ERROR,
  ARTICLE_GET_START,
  ARTICLE_GET_SUCCESS,
  ARTICLE_GETLIST_ERROR,
  ARTICLE_GETLIST_START,
  ARTICLE_GETLIST_SUCCESS,
  ARTICLE_UPDATE_ERROR,
  ARTICLE_UPDATE_START,
  ARTICLE_UPDATE_SUCCESS,
} from '../../../ActionTypes';
import { FirebasePathMappings } from '../../../../Globals/FirebaseGlobals';

/**
 * useDispatchArticleCreate()
 */
type CreateReturnType = (article: ArticleEntity) => Promise<ArticleEntity>;
export const useDispatchArticleCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<CreateReturnType>(
    (address) => {
      dispatch({ type: ARTICLE_CREATE_START, payload: address });

      const storageCollection = collection(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.article,
      ) as CollectionReference<ArticleEntity>;

      return addDoc(storageCollection, address)
        .then((response) => {
          const mapped: ArticleEntity = { ...address, articleId: response.id };
          dispatch({ type: ARTICLE_CREATE_SUCCESS, payload: mapped });
          return Promise.resolve(mapped);
        })
        .catch((error) => {
          dispatch({ type: ARTICLE_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchArticleGetList()
 */
type GetListReturnType = () => Promise<Array<ArticleEntity>>;
export const useDispatchArticleGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<GetListReturnType>(() => {
    dispatch({ type: ARTICLE_GETLIST_START });

    const storageCollection = collection(
      getFirestore(),
      FirebasePathMappings.client,
      clientId,
      FirebasePathMappings.article,
    ) as CollectionReference<ArticleEntity>;

    const queryRef = query(storageCollection, orderBy('caption', 'asc'));

    return getDocs(queryRef)
      .then((response) => {
        let result: Array<ArticleEntity> = [];
        if (!response.empty) {
          response.forEach((article) => {
            result.push({ ...article.data(), articleId: article.id });
          });
        }
        dispatch({ type: ARTICLE_GETLIST_SUCCESS, payload: result });
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch({ type: ARTICLE_GETLIST_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [clientId, dispatch]);
};

/**
 * useDispatchArticleUpdate()
 */
type UpdateReturnType = (article: ArticleEntity) => Promise<ArticleEntity>;
export const useDispatchArticleUpdate = (): UpdateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<UpdateReturnType>(
    (article) => {
      dispatch({ type: ARTICLE_UPDATE_START, payload: article });

      const storageDoc = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.article,
        article.articleId,
      ) as DocumentReference<ArticleEntity>;

      return setDoc(storageDoc, article, { merge: true })
        .then(() => {
          dispatch({ type: ARTICLE_UPDATE_SUCCESS, payload: article });
          return Promise.resolve(article);
        })
        .catch((error) => {
          dispatch({ type: ARTICLE_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchArticleDelete()
 */
type DeleteReturnType = (article: ArticleEntity) => Promise<ArticleEntity>;
export const useDispatchArticleDelete = (): DeleteReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<DeleteReturnType>(
    (article) => {
      dispatch({ type: ARTICLE_DELETE_START, payload: article });

      const storageDoc = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.article,
        article.articleId,
      ) as DocumentReference<ArticleEntity>;

      return deleteDoc(storageDoc)
        .then(() => {
          dispatch({ type: ARTICLE_DELETE_SUCCESS, payload: article });
          return Promise.resolve(article);
        })
        .catch((error) => {
          dispatch({ type: ARTICLE_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchArticleGet()
 */
type GetReturnType = (articleId: string) => Promise<ArticleEntity>;
export const useDispatchArticleGet = (): GetReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<GetReturnType>(
    (articleId) => {
      dispatch({ type: ARTICLE_GET_START, payload: articleId });

      const storageDoc = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.article,
        articleId,
      ) as DocumentReference<ArticleEntity>;

      return getDoc(storageDoc)
        .then((snapShot) => {
          if (snapShot.exists()) {
            const mapped: ArticleEntity = { ...snapShot.data(), articleId: snapShot.id };
            dispatch({ type: ARTICLE_GET_SUCCESS, payload: mapped });
            return Promise.resolve(mapped);
          }
        })
        .catch((error) => {
          dispatch({ type: ARTICLE_GET_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};
