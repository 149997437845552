import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';

export interface MailProvider {
  name: string;
  host: string;
  port: number;
}

interface Props {
  onChange: (item: MailProvider) => void;
  selected?: string;
  required?: boolean;
  disabled?: boolean;
  showSkeleton: boolean;
}

/**
 * SelectMailProvider
 * @param props
 * @constructor
 */
export default function SelectMailProvider(props: Props) {
  const { onChange, selected, required, disabled, showSkeleton } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>([]);
  const [provider] = React.useState<MailProvider[]>([
    { name: 'GMail', host: 'smtp.gmail.com', port: 587 },
    { name: 'Web.de', host: 'smtp.web.de', port: 587 },
    { name: 'Microsoft Outlook.com', host: 'smtp-mail.outlook.com', port: 587 },
    { name: 'Microsoft Office 365', host: 'smtp.office365.com', port: 587 },
    { name: t('manualProvider'), host: '', port: null },
  ]);

  React.useEffect(() => {
    const optionArray: SelectOptions = provider.map((item) => ({
      value: item.name === t('manualProvider') ? 'manual' : item.name,
      label: item.name,
    }));

    setOptions(optionArray);
  }, [provider, t]);

  const handleOnChange = (value: string) => {
    if (value !== 'manual') {
      const find = provider.find((item) => item.name === value);
      onChange(find);
    } else {
      onChange(null);
    }
  };

  if (options && options.length > 0) {
    return (
      <Select
        label={t('mailProvider')}
        onChange={handleOnChange}
        initialValue={selected}
        options={options}
        required={required}
        disabled={disabled}
        showSkeleton={showSkeleton}
      />
    );
  }
  return null;
}

SelectMailProvider.defaultProps = {
  required: false,
  disabled: false,
  showSkeleton: false,
};
