import { useDispatch } from 'react-redux';
import React from 'react';
import { OrderEntity, OrderServiceType } from '../../../Globals/Types/OrderTypes';
import {
  ORDER_TIMETRACKING_GETLIST_ERROR,
  ORDER_TIMETRACKING_GETLIST_START,
  ORDER_TIMETRACKING_GETLIST_SUCCESS,
} from '../../ActionTypes';
import { TimeTrackingEntity } from '../../../Globals/Types/Types';
import { useDispatchOrderServiceTimeTrackingGetList } from './Service/TimeTrackingAction';
import moment from 'moment';

export interface OrderTimeTrackingResponse {
  serviceType: OrderServiceType;
  serviceId: string;
  timeTracking: TimeTrackingEntity;
  timestamp: any;
}

/**
 * useDispatchOrderServiceDocumentGetList
 * Get all time tracking items for all services inside the order
 */
type GetListReturn = (order: OrderEntity) => Promise<OrderTimeTrackingResponse[]>;
export const useDispatchOrderTimeTrackingGetList = (): GetListReturn => {
  const dispatch = useDispatch();
  const dispatchServiceTimeTrackingGetList = useDispatchOrderServiceTimeTrackingGetList();

  return React.useCallback<GetListReturn>(
    (order) => {
      dispatch({ type: ORDER_TIMETRACKING_GETLIST_START, payload: order });
      let promiseAll: Promise<OrderTimeTrackingResponse[]>[] = [];

      order.services.forEach((service) => {
        const promise = dispatchServiceTimeTrackingGetList(order, service).then((response) => {
          if (response && response.length > 0) {
            const result: OrderTimeTrackingResponse[] = response.map<OrderTimeTrackingResponse>((timeTracking) => ({
              serviceType: service.type,
              serviceId: service.serviceId,
              timeTracking,
              timestamp: moment(timeTracking.timestamp.toDate()).format('YYYY-MM-DD HH:mm:ss'),
            }));

            return Promise.resolve(result);
          }
          return Promise.resolve([]);
        });
        promiseAll.push(promise);
      });

      if (promiseAll && promiseAll.length > 0) {
        return Promise.all(promiseAll)
          .then((items) => {
            let result: OrderTimeTrackingResponse[] = [];

            items.forEach((item) => {
              result = [...result, ...item];
            });

            const sorted = result.sort((a, b) => (moment(a.timestamp).isSameOrAfter(moment(b.timestamp)) ? 1 : -1));
            dispatch({ type: ORDER_TIMETRACKING_GETLIST_SUCCESS, payload: sorted });
            return Promise.resolve(sorted);
          })
          .catch((error) => {
            dispatch({ type: ORDER_TIMETRACKING_GETLIST_ERROR, payload: error });
            return Promise.resolve(error);
          });
      }

      return new Promise((resolve) => resolve([]));
    },
    [dispatch, dispatchServiceTimeTrackingGetList],
  );
};
