import React from 'react';
import { InvoiceEntity } from '../../../../Globals/Types/Invoice';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  invoice: InvoiceEntity;
}

/**
 * AccessCodeHint()
 * @param props
 * @constructor
 */
export default function AccessCodeHint(props: Props) {
  const { invoice } = props;
  const [t] = useTranslation();

  if (invoice.accessCode > 0) {
    return <Alert variant="info">{t('invoiceAccessCodeHint', { accessCode: invoice.accessCode })}</Alert>;
  }
  return null;
}
