import React from 'react';
import { PositionEntity } from '../../../../Globals/Types/OrderTypes';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Row from './Row';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { useDispatchPositionGetList } from '../../../../Redux/Actions/Client/PositionAction';
import SearchInput from '../../../Inputs/SearchInput';
import { useNavigate } from 'react-router';
import { UrlPatterns } from '../../../../Globals/UrlFunctions';

const ScrollContainer = styled.div`
  overflow-y: scroll;
  height: 500px;
  margin: -15px;
  padding: 20px;
  border-radius: 4px;
`;

const Hint = styled.div`
  color: ${(props) => props.theme.color.textGray};
  padding: 40px;
  text-align: center;
`;

interface Props {
  onSelect: (position: PositionEntity) => void;
  visible: boolean;
  onClose: () => void;
}

/**
 * SelectPosition()
 * @param props
 * @constructor
 */
export default function SelectPosition(props: Props) {
  const { positions } = useAppSelector((state) => state.client);
  const navigate = useNavigate();
  const { onSelect, visible, onClose } = props;
  const [init, setInit] = React.useState<boolean>(false);
  const [searchString, setSearchString] = React.useState<string>('');
  const [searchResult, setSearchResult] = React.useState<PositionEntity[]>(null);
  const [t] = useTranslation();

  const dispatchGetList = useDispatchPositionGetList();

  const filter = React.useCallback(() => {
    const filtered = positions.filter((item) => item.caption.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    setSearchResult(filtered);
  }, [positions, searchString]);

  React.useEffect(() => {
    if (!init) {
      setInit(true);
      if (!positions || positions.length === 0) {
        dispatchGetList().finally(() => {});
      }
    }
  }, [dispatchGetList, init, positions]);

  React.useEffect(() => {
    if (searchString && searchString.length > 2) {
      filter();
    } else {
      setSearchResult(null);
    }
  }, [filter, searchString]);

  const renderList = () => {
    if (searchResult) {
      return searchResult.map((position) => <Row position={position} onSelect={onSelect} key={position.positionId} />);
    }

    return positions.map((position) => <Row position={position} onSelect={onSelect} key={position.positionId} />);
  };

  const renderContent = () => {
    if (init && (!positions || positions.length <= 0)) {
      return (
        <>
          <Hint>{t('modules.settings.positions.positionsSelectEmptyDescription')}</Hint>
          <div style={{ textAlign: 'center', marginBottom: 20 }}>
            <Button variant="primary" onClick={() => navigate(UrlPatterns.settingsPositions)}>
              {t('openSettings')}
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <div style={{ padding: '0 5px' }}>
          <SearchInput
            onChange={setSearchString}
            value={searchString}
            placeholder={`${t('search')}...`}
            autoFocus={true}
          />
        </div>

        <ScrollContainer>{renderList()}</ScrollContainer>
      </>
    );
  };

  return (
    <Modal show={visible} animation={true} size="xl" onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          <i className="fas fa-list-timeline" style={{ marginRight: 10 }} />
          {t('positionSelect')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderContent()}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => onClose()}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
