import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleEntity, ArticlePartnerEntity, ArticleStorageStockEntity } from '../../../../Globals/Types/Types';
import { useAppSelector, useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import { Button } from 'react-bootstrap';
import validateUpdateOrCreate from './ValidateUpdateOrCreate';
import {
  useDispatchArticleCreate,
  useDispatchArticleDelete,
  useDispatchArticleGet,
  useDispatchArticleUpdate,
} from '../../../../Redux/Actions/Client/Storage/ArticleAction';
import StorageAmountInput from './Components/StorageAmountInput';
import PageHeader from '../../../../Components/PageHeader';
import Panel from '../../../../Components/Panel';
import { useNavigate, useParams } from 'react-router';
import AskDeleteModal from '../../../../Components/Modals/AskDeleteModal';
import PartnerContainer from './Components/PartnerContainer';
import TabPanel from '../../../../Components/TabPanel';
import BasicData from './Components/BasicData';
import { useDispatchClientGetAllPartners } from '../../../../Redux/Actions/ClientAction';
import AskSaveChanges from '../../../../Components/Modals/AskSaveChanges';

export type CreateOrUpdateError = { [K in keyof ArticleEntity]?: string };

export type State = {
  article: ArticleEntity;
  isEdit: boolean;
  hasChanges: Boolean;
  errors: CreateOrUpdateError;
};

type Action =
  | { type: 'caption'; payload: string }
  | { type: 'description'; payload: string }
  | { type: 'eanCode'; payload: string }
  | { type: 'externalId'; payload: string }
  | { type: 'purchasePriceNetto'; payload: string }
  | { type: 'salesPriceNetto'; payload: string }
  | { type: 'allowReportUsage'; payload: boolean }
  | { type: 'addPartner'; payload: ArticlePartnerEntity }
  | { type: 'deletePartner'; payload: ArticlePartnerEntity }
  | { type: 'storage'; payload: Array<ArticleStorageStockEntity> }
  | { type: 'init'; payload: ArticleEntity }
  | { type: 'setErrors'; payload: CreateOrUpdateError }
  | { type: 'clear' };

const initialState: State = {
  article: {
    caption: '',
    storage: [],
    partner: [],
    allowReportUsage: false,
  } as ArticleEntity,
  errors: {} as CreateOrUpdateError,
  isEdit: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'caption':
      return {
        ...state,
        article: { ...state.article, caption: action.payload },
        hasChanges: true,
        errors: { ...state.errors, caption: '' },
      };
    case 'description':
      return {
        ...state,
        article: { ...state.article, description: action.payload },
        hasChanges: true,
        errors: { ...state.errors, description: '' },
      };
    case 'eanCode':
      return {
        ...state,
        article: { ...state.article, eanCode: action.payload },
        hasChanges: true,
        errors: { ...state.errors, eanCode: '' },
      };
    case 'externalId':
      return {
        ...state,
        article: { ...state.article, externalId: action.payload },
        hasChanges: true,
      };
    case 'purchasePriceNetto': {
      return {
        ...state,
        article: { ...state.article, purchasePriceNetto: action.payload.replace(',', '.') },
        hasChanges: true,
        errors: { ...state.errors, purchasePriceNetto: '' },
      };
    }
    case 'salesPriceNetto': {
      return {
        ...state,
        article: { ...state.article, salesPriceNetto: action.payload.replace(',', '.') },
        hasChanges: true,
        errors: { ...state.errors, salesPriceNetto: '' },
      };
    }
    case 'allowReportUsage':
      return {
        ...state,
        article: { ...state.article, allowReportUsage: action.payload },
        hasChanges: true,
      };
    case 'storage':
      return {
        ...state,
        article: { ...state.article, storage: action.payload },
        hasChanges: true,
      };
    case 'addPartner':
      return {
        ...state,
        article: { ...state.article, partner: [...state.article.partner, action.payload] },
        hasChanges: true,
      };
    case 'deletePartner':
      const partner = state.article.partner.filter(
        (item) =>
          item.partnerFacilityId !== action.payload.partnerFacilityId && item.facilityId !== action.payload.facilityId,
      );
      return {
        ...state,
        article: { ...state.article, partner },
        hasChanges: true,
      };
    case 'setErrors': {
      return { ...state, errors: action.payload };
    }
    case 'clear': {
      return { ...initialState };
    }
    case 'init': {
      return {
        ...state,
        article: { ...state.article, ...action.payload },
        isEdit: true,
        hasChanges: false,
      };
    }
  }
};

/**
 * CreateOrUpdate()
 * @constructor
 */
export default function CreateOrUpdate() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { articleId } = useParams();
  const { articles, addresses } = useAppSelector((state) => state.storage);
  const { allPartners } = useAppSelector((state) => state.client);
  const [headline, setHeadline] = React.useState<string>(t('articleCreate'));
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [askCancel, setAskCancel] = React.useState<boolean>(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { setLoading, isLoading } = useLoadingModal({ delayStart: 1000 });
  const dispatchCreate = useDispatchArticleCreate();
  const dispatchUpdate = useDispatchArticleUpdate();
  const dispatchDelete = useDispatchArticleDelete();
  const dispatchGet = useDispatchArticleGet();
  const dispatchGetAllPartners = useDispatchClientGetAllPartners();

  React.useEffect(() => {
    if (articleId) {
      dispatchGet(articleId).then((response) => {
        dispatch({ type: 'init', payload: response });
      });
    }
  }, [articleId, dispatchGet]);

  React.useEffect(() => {
    dispatchGetAllPartners().then(() => {});
  }, [dispatchGetAllPartners]);

  React.useEffect(() => {
    setHeadline(state.isEdit ? t('articleUpdate') : t('articleCreate'));
  }, [state.isEdit, t]);

  const handleCancel = () => (state.hasChanges ? setAskCancel(true) : navigate(-1));

  const handleSave = () => {
    const { isValid, errors } = validateUpdateOrCreate(state.article, articles);

    if (isValid) {
      setLoading(true);
      let promise;
      if (state.isEdit) {
        promise = dispatchUpdate(state.article);
      } else {
        promise = dispatchCreate(state.article);
      }

      promise
        .then(() => {
          setLoading(false);
          navigate(-1);
        })
        .catch(() => setLoading(false));
    } else {
      dispatch({ type: 'setErrors', payload: errors });
    }
  };

  const handleDelete = () => {
    setLoading(true);
    dispatchDelete(state.article).then(() => {
      setLoading(false);
      navigate(-1);
    });
  };

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={handleSave} disabled={isLoading || !state.hasChanges}>
        {t('save')}
      </Button>
    );
  };

  const renderCancelButton = () => {
    return (
      <Button variant="outline-secondary" onClick={handleCancel}>
        {t('cancel')}
      </Button>
    );
  };

  const renderDeleteButton = () => {
    if (state.isEdit) {
      return (
        <Button variant="outline-danger" onClick={() => setAskDelete(true)}>
          <i className="fas fa-trash" />
        </Button>
      );
    }
    return null;
  };

  const handleBasicDataChange = (key: string, value: string) => {
    dispatch({ type: key as any, payload: value });
  };

  return (
    <>
      <PageHeader
        headline={headline}
        description={state.isEdit ? state.article.caption : ''}
        actionButtonOne={renderDeleteButton()}
        actionButtonTwo={renderSaveButton()}
        actionButtonThree={renderCancelButton()}
      />

      <TabPanel initialSelected="basicData">
        <TabPanel.Tab caption="basicData" visible={true} icon="fas fa-info-circle">
          <BasicData
            article={state.article}
            onChange={handleBasicDataChange}
            isLoading={isLoading}
            errors={state.errors}
          />
        </TabPanel.Tab>
        <TabPanel.Tab
          caption="availability"
          visible={addresses !== undefined && addresses.length > 0}
          icon="fas fa-container-storage"
        >
          <Panel description={t('modules.storage.availabilityDescription')}>
            <StorageAmountInput
              data={state.article.storage}
              onChange={(value) => dispatch({ type: 'storage', payload: value })}
            />
          </Panel>
        </TabPanel.Tab>
        <TabPanel.Tab
          caption="specialPartnerPrice"
          icon="fas fa-handshake"
          visible={allPartners !== undefined && allPartners.length > 0}
        >
          <PartnerContainer
            data={state.article.partner}
            onAdd={(value) => dispatch({ type: 'addPartner', payload: value })}
            onDelete={(value) => dispatch({ type: 'deletePartner', payload: value })}
          />
        </TabPanel.Tab>
      </TabPanel>

      <AskDeleteModal
        onDelete={handleDelete}
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        headline={t('modules.storage.articleDelete')}
        description={t('modules.storage.articleDeleteDescription', state.article)}
      />

      <AskSaveChanges onLeave={() => navigate(-1)} onCancel={() => setAskCancel(false)} visible={askCancel} />
    </>
  );
}
