import { AUTH_LOGOUT, DASHBOARD_SERVICES_GET_SUCCESS } from '../ActionTypes';
import { ElasticSearchServiceEntity } from '../../Globals/Types/OrderTypes';
import moment from 'moment';
import { ReportState } from '../../Globals/Types/Report';
import { OrderServiceFilterResponseType } from '../Actions/Order/ServiceAction';

export interface AuthReducerInterface {
  services: {
    today: ElasticSearchServiceEntity[];
    tomorrow: ElasticSearchServiceEntity[];
    unterminated: ElasticSearchServiceEntity[];
  };
}

type Action =
  | { type: typeof DASHBOARD_SERVICES_GET_SUCCESS; payload: OrderServiceFilterResponseType }
  | { type: typeof AUTH_LOGOUT };

const defaultValues: AuthReducerInterface = {
  services: {
    today: null,
    tomorrow: null,
    unterminated: null,
  },
};

/**
 * Filter services by date
 * @param date
 * @param services
 */
const filterServicesByDate = (date: string, services: ElasticSearchServiceEntity[]): ElasticSearchServiceEntity[] => {
  return services.filter((service) => {
    if (service.disposition) {
      const dispositions = service.disposition.filter((item) => item.date === date);
      return dispositions.length > 0;
    }
    return false;
  });
};

/**
 * Filter services which ars not terminated
 * @param services
 */
const filterServicesUnterminated = (services: ElasticSearchServiceEntity[]): ElasticSearchServiceEntity[] => {
  return services.filter((service) => {
    return (!service.disposition || service.disposition.length === 0) && service.reportState === ReportState.none;
  });
};

/**
 * reduce()
 * @param state
 * @param action
 */
export default function reduce(state: AuthReducerInterface = defaultValues, action: Action): AuthReducerInterface {
  switch (action.type) {
    case DASHBOARD_SERVICES_GET_SUCCESS:
      return {
        ...state,
        services: {
          today: filterServicesByDate(moment().format('YYYY-MM-DD'), action.payload.services),
          tomorrow: filterServicesByDate(moment().add(1, 'day').format('YYYY-MM-DD'), action.payload.services),
          unterminated: filterServicesUnterminated(action.payload.services),
        },
      };

    case AUTH_LOGOUT: {
      return { ...defaultValues };
    }

    default:
      return { ...state };
  }
}
