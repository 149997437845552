import React from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { OrderServiceType } from '../../Globals/Types/OrderTypes';
import Lodash from 'lodash';
import PanelSelect, { PanelSelectOption } from '../PanelSelect';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  .InnerContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .RightContainer {
    display: flex;
    flex-direction: row;
  }
`;

const ArrowContainer = styled.div<{ direction: 'right' | 'left' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: ${(props) => (props.direction === 'right' ? '15px' : 0)};
  margin-right: ${(props) => (props.direction === 'left' ? '15px' : 0)};
  padding: 0 20px;
  border: 1px solid ${(props) => props.theme.color.textGrayLight};
  color: ${(props) => props.theme.color.textGrayLight};
  border-radius: ${(props) => props.theme.border.radius};
  cursor: pointer;

  &:hover {
    ${(props) => props.theme.boxShadowDark};
    color: ${(props) => props.theme.color.text};
  }
`;

interface Props {
  selected: OrderServiceType;
  onChange: (value: OrderServiceType) => void;
  setInitial: boolean; // Set the initial selected if selected is null on create
}

/**
 * SelectOrderServiceType()
 * @param props
 * @constructor
 */
export default function SelectOrderServiceType(props: Props) {
  const { selected, onChange, setInitial } = props;
  const { serviceTypeDefinition } = useAppSelector((state) => state.auth.client.settings.order);
  const [itemsPerRow] = React.useState<number>(4);
  const [visibleIndex, setVisibleIndex] = React.useState<number>(0);
  const [splittedData, setSplittedData] = React.useState<Array<OrderServiceType[]>>(null);
  const [options, setOptions] = React.useState<Array<PanelSelectOption[]>>(null);

  React.useEffect(() => {
    setSplittedData(Lodash.chunk(serviceTypeDefinition, itemsPerRow));
  }, [itemsPerRow, serviceTypeDefinition]);

  React.useEffect(() => {
    if (setInitial && !selected) {
      setTimeout(() => onChange(serviceTypeDefinition[0]), 500);
    }
  }, [onChange, serviceTypeDefinition, setInitial, selected]);

  React.useEffect(() => {
    if (splittedData) {
      const result: Array<PanelSelectOption[]> = [];

      splittedData.forEach((data) => {
        const mapped = data.map<PanelSelectOption>((item) => ({
          key: item.key,
          caption: item.caption,
          icon: item.icon,
          useTranslation: false,
          disabled: false,
        }));

        result.push(mapped);
      });

      setOptions(result);
    }
  }, [splittedData]);

  const handleOnChange = (keys: string[]) => {
    const item = serviceTypeDefinition.find((item) => keys.indexOf(item.key) > -1);
    onChange(item);
  };

  const renderRight = () => {
    if (splittedData && splittedData.length > 1 && visibleIndex < splittedData.length - 1) {
      return (
        <div className="RightContainer">
          <ArrowContainer direction={'right'} onClick={() => setVisibleIndex(visibleIndex + 1)}>
            <i className="fas fa-chevrons-right" />
          </ArrowContainer>
        </div>
      );
    }
    return null;
  };

  const renderLeft = () => {
    if (visibleIndex > 0) {
      return (
        <div className="RightContainer">
          <ArrowContainer direction={'left'} onClick={() => setVisibleIndex(visibleIndex - 1)}>
            <i className="fas fa-chevrons-left" />
          </ArrowContainer>
        </div>
      );
    }
    return null;
  };

  if (options) {
    return (
      <Container>
        {renderLeft()}
        <div className="InnerContainer">
          <PanelSelect
            options={options[visibleIndex]}
            selected={selected ? [selected.key] : []}
            onChange={handleOnChange}
            multiSelect={false}
          />
        </div>
        {renderRight()}
      </Container>
    );
  }
  return null;
}
