import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import SubNavigationHeadline from '../SubNavigationHeadline';
import ListView from './ListView';
import { useDispatchInboxGetList } from '../../../../Redux/Actions/User/InboxAction';
import Text from '../../../Text';
import ActionButtons from './ActionButtons';
import { useWindowHasFocus } from '../../../../Globals/Hooks/BrowserHooks';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

/**
 * MessageSubNavigation()
 * @constructor
 */
export default function MessageSubNavigation() {
  const theme = useTheme();
  const [t] = useTranslation();
  const dispatchGetList = useDispatchInboxGetList();
  const [loading, setLoading] = React.useState<boolean>(false);
  const focused = useWindowHasFocus();

  React.useEffect(() => {
    if (focused) {
      const timer = setTimeout(() => setLoading(true), 500);
      dispatchGetList().finally(() => {
        clearTimeout(timer);
        setLoading(false);
      });
    }
  }, [dispatchGetList, focused]);

  const renderLoading = () => {
    if (loading) {
      return (
        <Text centered containerStyle={{ marginTop: 20, marginBottom: 20 }}>
          <i className="fas fa-circle-notch fa-spin" style={{ fontSize: 22, color: theme.color.textGray }} />
        </Text>
      );
    }
    return null;
  };

  return (
    <Container>
      <SubNavigationHeadline headline={t('inbox')} actionComponent={<ActionButtons />} />

      {renderLoading()}
      <ListView />
    </Container>
  );
}
