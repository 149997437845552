import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { isValidateEmail } from '../../../../Globals/Functions';
import { MailSettings } from '../../../../Globals/Types/Types';

export type MailSettingsError = { [K in keyof MailSettings]?: string };

export type ValidationResult = {
  isValid: boolean;
  errors: MailSettingsError;
};

/**
 * isValidMailSettings()
 * @param data
 */
export const isValidMailSettings = (data: MailSettings): ValidationResult => {
  let errors: ValidationResult = {
    isValid: true,
    errors: {},
  };

  if (!data.useInternalService) {
    if (!data.mail || data.mail.length === 0) {
      errors = { ...errors, errors: { ...errors.errors, mail: InternalErrorCodes.FIELD_MISSING } };
    } else if (!isValidateEmail(data.mail)) {
      errors = { ...errors, errors: { ...errors.errors, mail: InternalErrorCodes.FIELD_MISSING } };
    }

    if (!data.user || data.user.length === 0) {
      errors = { ...errors, errors: { ...errors.errors, user: InternalErrorCodes.FIELD_MISSING } };
    }

    if (!data.password || data.password.length === 0) {
      errors = { ...errors, errors: { ...errors.errors, password: InternalErrorCodes.FIELD_MISSING } };
    }

    if (!data.host || data.host.length === 0) {
      errors = { ...errors, errors: { ...errors.errors, host: InternalErrorCodes.FIELD_MISSING } };
    }

    if (!data.port || data.port < 0) {
      errors = { ...errors, errors: { ...errors.errors, port: InternalErrorCodes.FIELD_MISSING } };
    }
  }

  return { ...errors, isValid: Object.keys(errors.errors).length <= 0 };
};
