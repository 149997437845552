import {
  AUTH_LOGOUT,
  ORDER_FILTER_LOAD_SUCCESS,
  ORDER_FILTER_SET_SUCCESS,
  ORDER_GET_START,
  ORDER_GET_SUCCESS,
  ORDER_MESSAGE_CREATE_SUCCESS,
  ORDER_MESSAGE_GETLIST_SUCCESS,
  ORDER_SERVICE_CREATE_SUCCESS,
  ORDER_SERVICE_DELETE_SUCCESS,
  ORDER_SERVICE_DOCUMENT_GETLIST_SUCCESS,
  ORDER_SERVICE_FILTER_SUCCESS,
  ORDER_SERVICE_FILTER_UNSCHEDULED_SUCCESS,
  ORDER_SERVICE_REPORT_GETLIST_SUCCESS,
  ORDER_SERVICE_SEARCH_SUCCESS,
  ORDER_SERVICE_SET_SELECTED,
  ORDER_SERVICE_TIMETRACKING_GETLIST_SUCCESS,
  ORDER_SERVICE_UPDATE_SUCCESS,
  ORDER_TIMETRACKING_GETLIST_SUCCESS,
  ORDER_UPDATE_SUCCESS,
  TOUR_GET_FOR_ORDER_SUCCESS,
  TOUR_GET_FOR_SERVICE_SUCCESS,
  USER_TASK_GETLIST_SUCCESS,
} from '../ActionTypes';
import {
  ElasticSearchServiceDispositionInterface,
  ElasticSearchServiceEntity,
  ElasticSearchServiceInterface,
  OrderEntity,
  OrderMessageEntity,
  OrderPartnerPermissionAccess,
  ServiceEntity,
} from '../../Globals/Types/OrderTypes';
import moment from 'moment';
import { ServiceFilter, TaskEntity, TimeTrackingEntity, UserEntity } from '../../Globals/Types/Types';
import Lodash from 'lodash';
import { StorageReference } from '@firebase/storage';
import { Report, ReportState } from '../../Globals/Types/Report';
import { OrderTimeTrackingResponse } from '../Actions/Order/TimeTrackingAction';
import { OrderServiceFilterResponseType } from '../Actions/Order/ServiceAction';

export interface AssignedFacilities {
  facilityId: string;
  clientId: string;
}

export interface OrderReducerInterface {
  selectedOrder: {
    allFacilities: AssignedFacilities[];
    dispositions: ElasticSearchServiceDispositionInterface[] | null; // All tour entries for all services of the order
    documents: StorageReference[];
    messages: OrderMessageEntity[];
    order: OrderEntity;
    permissions: OrderPartnerPermissionAccess; // Access for the current user/ client / facility loading the order
    timeTrackings: OrderTimeTrackingResponse[] | null;
    tasks: TaskEntity[];
    selectedService: {
      service: ServiceEntity;
      dispositions: ElasticSearchServiceDispositionInterface[] | null;
      report: Report;
      timeTracking: TimeTrackingEntity[] | null;
    };
  };
  search: {
    services: ElasticSearchServiceInterface[];
    filterResult: ElasticSearchServiceInterface[];
    lastUpdate: Date;
  };
  availableYears: number[];
  serviceFilter: ServiceFilter;
  unscheduledServices: ElasticSearchServiceEntity[];
}

const defaultPermissions: OrderPartnerPermissionAccess = {
  allowEditAll: false,
  allowEditInformation: false,
  allowEditCustomerAddress: false,
  allowEditLoadingAddress: false,
  allowEditAppointments: false,
  allowDocumentUpload: false,
  allowDocumentDelete: false,
  allowAddService: false,
  allowDeleteService: false,
  allowEditKitchenInformation: false,
  allowEditPosition: false,
};

export type OrderActions =
  | { type: typeof ORDER_GET_START; payload: string }
  | { type: typeof ORDER_GET_SUCCESS; payload: { order: OrderEntity; permissions: OrderPartnerPermissionAccess } }
  | { type: typeof ORDER_UPDATE_SUCCESS; payload: OrderEntity }
  | { type: typeof ORDER_TIMETRACKING_GETLIST_SUCCESS; payload: OrderTimeTrackingResponse[] }
  | { type: typeof ORDER_SERVICE_SET_SELECTED; payload: ServiceEntity }
  | {
      type: typeof TOUR_GET_FOR_SERVICE_SUCCESS;
      payload: ElasticSearchServiceDispositionInterface[];
    }
  | {
      type: typeof TOUR_GET_FOR_ORDER_SUCCESS;
      payload: ElasticSearchServiceDispositionInterface[];
    }
  | { type: typeof ORDER_SERVICE_UPDATE_SUCCESS; payload: { orderId: string; service: ServiceEntity } }
  | { type: typeof ORDER_SERVICE_TIMETRACKING_GETLIST_SUCCESS; payload: TimeTrackingEntity[] }
  | { type: typeof ORDER_MESSAGE_GETLIST_SUCCESS; payload: { messages: Array<OrderMessageEntity>; user: UserEntity } }
  | { type: typeof ORDER_SERVICE_FILTER_SUCCESS; payload: OrderServiceFilterResponseType }
  | { type: typeof ORDER_SERVICE_SEARCH_SUCCESS; payload: Array<ElasticSearchServiceEntity> }
  | { type: typeof ORDER_SERVICE_DOCUMENT_GETLIST_SUCCESS; payload: StorageReference[] }
  | { type: typeof USER_TASK_GETLIST_SUCCESS; payload: TaskEntity[] }
  | { type: typeof ORDER_MESSAGE_CREATE_SUCCESS; payload: OrderMessageEntity }
  | { type: typeof ORDER_SERVICE_DELETE_SUCCESS; payload: ServiceEntity }
  | { type: typeof ORDER_SERVICE_CREATE_SUCCESS; payload: ServiceEntity }
  | { type: typeof ORDER_SERVICE_REPORT_GETLIST_SUCCESS; payload: Report }
  | { type: typeof ORDER_FILTER_SET_SUCCESS; payload: ServiceFilter }
  | { type: typeof ORDER_FILTER_LOAD_SUCCESS; payload: ServiceFilter }
  | {
      type: typeof ORDER_SERVICE_FILTER_UNSCHEDULED_SUCCESS;
      payload: { services: ElasticSearchServiceEntity[]; clientId: string };
    }
  | { type: typeof AUTH_LOGOUT };

const defaultValues: OrderReducerInterface = {
  selectedOrder: {
    order: null,
    documents: null,
    messages: null,
    allFacilities: [], // All facilities assigned to the order or to a service from the order
    tasks: [],
    dispositions: null,
    permissions: defaultPermissions,
    timeTrackings: null,
    selectedService: {
      service: null,
      dispositions: null,
      report: null,
      timeTracking: null,
    },
  },
  search: {
    services: null,
    filterResult: null,
    lastUpdate: null,
  },
  availableYears: [],
  serviceFilter: {
    dateFrom: moment().startOf('year').format('YYYY-MM-DD'),
    dateTo: moment().endOf('year').format('YYYY-MM-DD'),
    serviceTypes: [],
    hideUnscheduled: false,
    hideScheduled: false,
    finishedOnly: false,
    unsuccessfulOnly: false,
    openOnly: false,
  },
  unscheduledServices: null,
};

/**
 * Filter messages by user access
 * @param messages
 * @param currentUser
 */
const checkAndFilterMessageAccessAndSort = (
  messages: Array<OrderMessageEntity>,
  currentUser: UserEntity,
): Array<OrderMessageEntity> => {
  const result = messages.filter((message) => {
    if (message.isPublic) {
      return true;
    }

    let facilityFound = false;
    currentUser.facilities.forEach((facilityId) => {
      if (message.assignedFacilities.indexOf(facilityId) > -1) {
        facilityFound = true;
      }
    });

    if (facilityFound) {
      return true;
    }

    return message.assignedUsers.indexOf(currentUser.userId) > -1;
  });

  return result.sort((a, b) => (moment(a.createdDate).isAfter(moment(b.createdDate)) ? -1 : 1));
};

/**
 * Get all assigned facilities to an order
 * @param order
 */
const getAllAssignedFacilities = (order: OrderEntity): AssignedFacilities[] => {
  const facilities: AssignedFacilities[] = [];
  facilities.push({ facilityId: order.ownerFacilityId, clientId: order.ownerClientId });
  facilities.push({ facilityId: order.createdFacilityId, clientId: order.createdClientId });

  const fromService = order.services
    .filter((item) => item.assignedPartnerFacilityId)
    .map<AssignedFacilities>((service) => ({
      facilityId: service.assignedPartnerFacilityId,
      clientId: service.assignedPartnerClientId,
    }));

  return Lodash.uniqBy(Lodash.concat(facilities, fromService), 'facilityId');
};

/**
 * Apply the filters to all found ElasticSearch entities
 * @param services
 * @param filters
 */
const applyFilter = (
  services: ElasticSearchServiceInterface[],
  filters: ServiceFilter,
): ElasticSearchServiceInterface[] => {
  if (!services) {
    return [];
  }

  let filteredServices: ElasticSearchServiceInterface[] = [];

  if (filters.serviceTypes.length > 0) {
    const filtered = services.filter((service) => filters.serviceTypes.indexOf(service.serviceType.key) > -1);
    filteredServices = [...filteredServices, ...filtered];
  } else {
    filteredServices = [...services];
  }

  if (filters.hideUnscheduled) {
    filteredServices = filteredServices.filter((service) => service.disposition);
  }
  if (filters.hideScheduled) {
    filteredServices = filteredServices.filter((service) => !service.disposition);
  }
  if (filters.finishedOnly) {
    filteredServices = filteredServices.filter(
      (service) => service.reportState && service.reportState === ReportState.complete,
    );
  }
  if (filters.unsuccessfulOnly) {
    filteredServices = filteredServices.filter(
      (service) => service.reportState && service.reportState === ReportState.incomplete,
    );
  }
  if (filters.openOnly) {
    filteredServices = filteredServices.filter(
      (service) => !service.reportState || service.reportState === ReportState.none,
    );
  }

  return filteredServices;
};

/**
 * reducer
 * @param state
 * @param action
 */
export default function reduce(
  state: OrderReducerInterface = defaultValues,
  action: OrderActions,
): OrderReducerInterface {
  switch (action.type) {
    case ORDER_GET_START: {
      if (state.selectedOrder.order && state.selectedOrder.order.orderId !== action.payload) {
        return {
          ...state,
          selectedOrder: {
            order: null,
            messages: null,
            documents: null,
            tasks: [],
            allFacilities: [],
            dispositions: null,
            permissions: defaultPermissions,
            timeTrackings: null,
            selectedService: {
              service: null,
              dispositions: null,
              report: null,
              timeTracking: null,
            },
          },
        };
      }
      return { ...state };
    }
    case ORDER_GET_SUCCESS: {
      return {
        ...state,
        selectedOrder: {
          order: action.payload.order,
          messages: null,
          documents: null,
          tasks: [],
          allFacilities: getAllAssignedFacilities(action.payload.order),
          permissions: action.payload.permissions,
          dispositions: null,
          timeTrackings: null,
          selectedService: {
            service: null,
            dispositions: null,
            report: null,
            timeTracking: null,
          },
        },
      };
    }
    case ORDER_UPDATE_SUCCESS:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          order: {
            ...action.payload,
            services: [...state.selectedOrder.order.services],
          },
        },
      };
    case ORDER_TIMETRACKING_GETLIST_SUCCESS: {
      return { ...state, selectedOrder: { ...state.selectedOrder, timeTrackings: action.payload } };
    }

    case ORDER_SERVICE_SET_SELECTED:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          selectedService: {
            service: action.payload,
            dispositions: null,
            report: null,
            timeTracking: null,
          },
        },
      };

    case ORDER_MESSAGE_GETLIST_SUCCESS: {
      const { messages, user } = action.payload;
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          messages: checkAndFilterMessageAccessAndSort(messages, user),
        },
      };
    }

    case ORDER_SERVICE_SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          services: action.payload,
          filterResult: applyFilter(action.payload, state.serviceFilter),
          lastUpdate: moment().toDate(),
        },
      };

    case ORDER_SERVICE_FILTER_SUCCESS:
      return {
        ...state,
        availableYears:
          action.payload.availableYears && action.payload.availableYears.length > 0
            ? action.payload.availableYears
            : [parseInt(moment().format('YYYY'))],
        search: {
          ...state.search,
          services: action.payload.services,
          filterResult: applyFilter(action.payload.services, state.serviceFilter),
          lastUpdate: moment().toDate(),
        },
      };

    case ORDER_SERVICE_UPDATE_SUCCESS: {
      if (state.selectedOrder.order && state.selectedOrder.order.orderId === action.payload.orderId) {
        const services = state.selectedOrder.order.services.map((item) =>
          item.serviceId !== action.payload.service.serviceId ? item : action.payload.service,
        );
        const order: OrderEntity = {
          ...state.selectedOrder.order,
          services,
        };

        return {
          ...state,
          selectedOrder: {
            ...state.selectedOrder,
            allFacilities: getAllAssignedFacilities(order),
            order,
          },
        };
      }
      return { ...state };
    }
    case ORDER_SERVICE_TIMETRACKING_GETLIST_SUCCESS:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          selectedService: { ...state.selectedOrder.selectedService, timeTracking: action.payload },
        },
      };

    case ORDER_SERVICE_DOCUMENT_GETLIST_SUCCESS:
      return { ...state, selectedOrder: { ...state.selectedOrder, documents: action.payload } };

    case ORDER_MESSAGE_CREATE_SUCCESS:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          order: {
            ...state.selectedOrder.order,
            notifyOnMessage: [
              ...new Set([...state.selectedOrder.order.notifyOnMessage, action.payload.createdUser.userId]),
            ],
          },
        },
      };

    case USER_TASK_GETLIST_SUCCESS: {
      if (state.selectedOrder.order) {
        const tasks = action.payload.filter(
          (task) => task.order && task.order.orderId && task.order.orderId === state.selectedOrder.order.orderId,
        );
        return { ...state, selectedOrder: { ...state.selectedOrder, tasks } };
      }
      return { ...state };
    }

    case ORDER_SERVICE_DELETE_SUCCESS: {
      const services = state.selectedOrder.order.services.filter(
        (service) => service.serviceId !== action.payload.serviceId,
      );
      const documents = state.selectedOrder.documents.filter(
        (document) => document.fullPath.indexOf(action.payload.serviceId) < 0,
      );

      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          order: { ...state.selectedOrder.order, services },
          documents,
        },
      };
    }

    case ORDER_SERVICE_CREATE_SUCCESS: {
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          order: {
            ...state.selectedOrder.order,
            services: [...state.selectedOrder.order.services, action.payload],
          },
        },
      };
    }

    case ORDER_FILTER_LOAD_SUCCESS:
    case ORDER_FILTER_SET_SUCCESS:
      return {
        ...state,
        serviceFilter: action.payload,
        search: {
          ...state.search,
          filterResult: [...applyFilter(state.search.services, action.payload)],
        },
      };

    case ORDER_SERVICE_FILTER_UNSCHEDULED_SUCCESS:
      return { ...state, unscheduledServices: action.payload.services };

    case TOUR_GET_FOR_SERVICE_SUCCESS: {
      if (state.selectedOrder.selectedService.service && action.payload.length > 0) {
        if (state.selectedOrder.selectedService.service.serviceId === action.payload[0].serviceId) {
          return {
            ...state,
            selectedOrder: {
              ...state.selectedOrder,
              selectedService: {
                ...state.selectedOrder.selectedService,
                dispositions: action.payload,
              },
            },
          };
        }
      }
      return { ...state };
    }

    case TOUR_GET_FOR_ORDER_SUCCESS: {
      if (state.selectedOrder && state.selectedOrder.order) {
        return {
          ...state,
          selectedOrder: {
            ...state.selectedOrder,
            dispositions: action.payload,
          },
        };
      }
      return { ...state };
    }

    case ORDER_SERVICE_REPORT_GETLIST_SUCCESS: {
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          selectedService: {
            ...state.selectedOrder.selectedService,
            report: action.payload,
          },
        },
      };
    }

    case AUTH_LOGOUT: {
      return { ...defaultValues };
    }

    default:
      return { ...state };
  }
}
