import { TourLocationTrackingDocument, TourLocationTrackingEntity } from '../../../../Globals/Types/Tour';
import { useAppDispatch, useAppSelector } from '../../../../Globals/Hooks/Hooks';
import React from 'react';
import {
  TOUR_LOCATION_GET_ERROR,
  TOUR_LOCATION_GET_START,
  TOUR_LOCATION_GET_SUCCESS,
  TOUR_LOCATION_LAST_LISTEN_SUCCESS,
  TOUR_LOCATION_LISTEN_SUCCESS,
} from '../../../ActionTypes';
import {
  collection,
  CollectionReference,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  Unsubscribe,
} from 'firebase/firestore';
import { FirebasePathMappings } from '../../../../Globals/FirebaseGlobals';

/**
 * buildCollection()
 * @param clientId
 * @param tourId
 */
const buildCollection = (clientId: string, tourId: string) => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.tour,
    tourId,
    FirebasePathMappings.locationTracking,
  ) as CollectionReference<TourLocationTrackingDocument>;
};

/**
 * useDispatchTourLocationGet()
 */
type TourGetLocationReturnType = (tourId: string) => Promise<TourLocationTrackingEntity[]>;
export const useDispatchTourLocationGet = (): TourGetLocationReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<TourGetLocationReturnType>(
    (tourId) => {
      dispatch({ type: TOUR_LOCATION_GET_START, payload: tourId });

      const collection = buildCollection(clientId, tourId);
      const queryRef = query(collection, orderBy('timestamp', 'asc'));
      return getDocs(queryRef)
        .then((response) => {
          const trackingList: TourLocationTrackingEntity[] = [];
          if (!response.empty) {
            response.forEach((tracking) => {
              trackingList.push(...tracking.data().positions);
            });
          }
          dispatch({ type: TOUR_LOCATION_GET_SUCCESS, payload: trackingList });

          return Promise.resolve(trackingList);
        })
        .catch((error) => {
          dispatch({ type: TOUR_LOCATION_GET_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchTourLocationListen()
 */
type TourLocationListenReturnType = (
  tourId: string,
  callback?: (tourId: string, trackingList: TourLocationTrackingEntity[]) => void,
) => Unsubscribe;
export const useDispatchTourLocationListen = (): TourLocationListenReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<TourLocationListenReturnType>(
    (tourId, callback) => {
      const collection = buildCollection(clientId, tourId);

      const queryRef = query(collection, orderBy('timestamp', 'asc'));

      return onSnapshot(queryRef, (snapShot) => {
        if (!snapShot.empty) {
          let trackingList: TourLocationTrackingEntity[] = [];
          snapShot.forEach((tracking) => trackingList.push(...tracking.data().positions));

          dispatch({ type: TOUR_LOCATION_LISTEN_SUCCESS, payload: { tourId, trackingList } });

          if (callback) {
            callback(tourId, trackingList);
          }
        }
      });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchTourLocationListenLast()
 */
type TourLocationListenLast = (
  tourId: string,
  callback: (tourId: string, location: TourLocationTrackingEntity) => void,
) => Unsubscribe;
export const useDispatchTourLocationListenLast = (): TourLocationListenLast => {
  const dispatch = useAppDispatch();

  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<TourLocationListenLast>(
    (tourId, callback) => {
      const collection = buildCollection(clientId, tourId);
      const queryRef = query(collection, orderBy('timestamp', 'desc'), limit(1));

      return onSnapshot(queryRef, (snapShot) => {
        if (!snapShot.empty) {
          let location: TourLocationTrackingEntity = {} as TourLocationTrackingEntity;

          snapShot.forEach((item) => {
            const data = item.data();
            location = { ...data.positions[data.positions.length - 1] };
          });

          dispatch({ type: TOUR_LOCATION_LAST_LISTEN_SUCCESS, payload: { tourId, location } });

          if (callback) {
            callback(tourId, location);
          }
        }
      });
    },
    [clientId, dispatch],
  );
};
