import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from './Row';
import Table from '../../Table/Table';
import { OfferEntity } from '../../../Globals/Types/OfferTypes';

interface Props {
  offers: OfferEntity[];
  showCustomerName?: boolean;
  onPress: (offer: OfferEntity) => void;
}

/**
 * ListView()
 * @param props
 * @constructor
 */
export default function ListView(props: Props) {
  const { offers, showCustomerName, onPress } = props;
  const [t] = useTranslation();

  const renderOffers = () =>
    offers.map((offer) => (
      <Row offer={offer} key={offer.offerId} showCustomerName={showCustomerName} onPress={onPress} />
    ));

  const renderCustomerNameHeader = () => {
    if (showCustomerName) {
      return <th>{t('customer')}</th>;
    }
    return null;
  };

  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: 50 }}></th>
          <th>{t('offerNumber')}</th>
          {renderCustomerNameHeader()}
          <th>{t('created')}</th>
          <th>{t('validUntil')}</th>
          <th>{t('offerOverallPrice')}</th>
        </tr>
      </thead>
      <tbody>{renderOffers()}</tbody>
    </Table>
  );
}
