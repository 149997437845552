import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { isValidateEmail } from '../../../../Globals/Functions';
import { UserEntity } from '../../../../Globals/Types/Types';
import { PASSWORD_MIN_LENGTH } from '../../../../Globals/Globals';

export type CreateClientValidationError = {
  user: UserEntity;
  password?: InternalErrorCodes;
  passwordRepeat?: InternalErrorCodes;
  groups?: string;
};

export type CreateClientValidationResult = {
  isValid: boolean;
  errors: CreateClientValidationError;
};

/**
 * isValid()
 * @param user
 * @param password
 * @param passwordRepeat
 */
const isValid = (user: UserEntity, password: string, passwordRepeat: string): CreateClientValidationResult => {
  let errors: CreateClientValidationError = {
    user: {} as UserEntity,
  };

  if (!user.mail || user.mail.length <= 0) {
    errors = { ...errors, user: { ...errors.user, mail: InternalErrorCodes.FIELD_MISSING } };
  } else {
    if (!isValidateEmail(user.mail)) {
      errors = { ...errors, user: { ...errors.user, mail: InternalErrorCodes.FIELD_MISSING } };
    }
  }

  if (!user.firstName || user.firstName.length <= 0) {
    errors = { ...errors, user: { ...errors.user, firstName: InternalErrorCodes.FIELD_MISSING } };
  }

  if (!user.lastName || user.lastName.length <= 0) {
    errors = { ...errors, user: { ...errors.user, lastName: InternalErrorCodes.FIELD_MISSING } };
  }

  if (!user.facilityId || user.facilityId.length <= 0) {
    errors = { ...errors, user: { ...errors.user, facilityId: InternalErrorCodes.FIELD_MISSING } };
  }

  if (!user.initials || user.initials.length <= 0) {
    errors = { ...errors, user: { ...errors.user, initials: InternalErrorCodes.FIELD_MISSING } };
  } else {
    if (user.initials.length > 4) {
      errors = { ...errors, user: { ...errors.user, initials: InternalErrorCodes.FIELD_MISSING } };
    }
  }

  if (!user.groups || user.groups.length <= 0) {
    errors = { ...errors, groups: InternalErrorCodes.FIELD_MISSING };
  }

  if (!user.facilities || user.facilities.length <= 0) {
    errors = { ...errors, user: { ...errors.user, facilities: [InternalErrorCodes.FIELD_MISSING] } };
  }

  if (!password || password.length <= 0) {
    errors = { ...errors, password: InternalErrorCodes.FIELD_MISSING };
  } else if (password.length < PASSWORD_MIN_LENGTH) {
    errors = { ...errors, password: InternalErrorCodes.PASSWORD_TO_SHORT };
  } else {
    if (password !== passwordRepeat) {
      errors = { ...errors, passwordRepeat: InternalErrorCodes.PASSWORD_NO_MATCH };
    }
  }

  if (!passwordRepeat || passwordRepeat.length <= 0) {
    errors = { ...errors, passwordRepeat: InternalErrorCodes.FIELD_MISSING };
  }

  return { isValid: Object.keys(errors.user).length === 0 && !errors.password && !errors.passwordRepeat, errors };
};

export default isValid;
