import React from 'react';
import { PositionEntity } from '../../../../Globals/Types/OrderTypes';
import styled from 'styled-components';
import { darken } from 'polished';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: ${(props) => props.theme.table.highlight};
  border-radius: ${(props) => props.theme.border.radius};
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.table.highlight)};
  }

  > .Left {
    display: flex;
    flex: 1;
    flex-direction: column;

    > .Description {
      display: flex;
      flex: 1;
      color: ${(props) => props.theme.color.textGray};
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  > .Time {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-right: 20px;
    color: ${(props) => props.theme.color.textGray};
    font-size: 14px;
  }

  > .Right {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: ${(props) => props.theme.color.textGray};
  }
`;

interface Props {
  position: PositionEntity;
  onSelect: (position: PositionEntity) => void;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { position, onSelect } = props;
  const [t] = useTranslation();

  const renderDescription = () => {
    if (position.description) {
      return <div className="Description">{position.description.substring(0, 200)}...</div>;
    }
    return null;
  };

  const renderTime = () => {
    if (position.assemblyTime) {
      return <div className="Time">{t('xMinute', { count: position.assemblyTime })}</div>;
    }
    return null;
  };

  return (
    <Container onClick={() => onSelect(position)}>
      <div className="Left">
        <div>{`${position.amount}x ${position.caption}`}</div>
        {renderDescription()}
      </div>
      {renderTime()}
      <div className="Right">
        <i className="fas fa-angle-right" />
      </div>
    </Container>
  );
}
