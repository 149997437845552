import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../../Components/PageHeader';
import SearchFilterButton from '../../../../Components/SearchFilterButton';
import styled from 'styled-components';
import SelectFiscalYear from './SelectFiscalYear';

const Icon = styled.i`
  color: ${(props) => props.theme.color.textGray};
  font-size: 24px;
  margin-right: 20px;
`;

interface Props {
  onChangeSearch: (item: string) => void;
  onClearSearch: () => void;
  onChangeYear: (year: number) => void;
  isLoading: boolean;
  count: number;
}

/**
 * Headline
 * @constructor
 */
export default function Headline(props: Props) {
  const { onChangeSearch, onClearSearch, isLoading, count, onChangeYear } = props;
  const [t] = useTranslation();

  return (
    <PageHeader
      containerStyle={{ paddingLeft: 40 }}
      headline={`${t('orderOverview')} (${count})`}
      actionButtonTwo={<SearchFilterButton onChange={onChangeSearch} onClear={onClearSearch} />}
      actionButtonThree={<SelectFiscalYear onChange={onChangeYear} />}
      actionButtonOne={isLoading ? <Icon className="fas fa-spin fa-sync" /> : null}
      withoutPadding
    />
  );
}
