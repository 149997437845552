import React from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  NamingConventionType,
  OrderEntity,
  ServiceEntity,
  ServiceSettingsEntity,
} from '../../../../../Globals/Types/OrderTypes';
import SelectYesNo from '../../../../PredefinedSelects/SelectYesNo';
import SelectRecurringDay from '../../../../PredefinedSelects/SelectRecurringDay';
import SelectNamingConvention from '../../../../PredefinedSelects/SelectNamingConvention';
import { useGetFacility } from '../../../../../Globals/Hooks/FacilityHooks';
import ShowHideContainer from '../../../../ShowHideContainer';
import SelectOrderRecurringType from '../../../../PredefinedSelects/SelectOrderRecurringType';

interface Props {
  order: OrderEntity;
  service: ServiceEntity;
  onSave: (item: ServiceSettingsEntity) => void;
  onClose: () => void;
  visible: boolean;
}

export type State = {
  data: ServiceSettingsEntity;
  initialized: Boolean;
  hasChanges: Boolean;
};

type Action =
  | { type: 'days'; payload: number }
  | { type: 'active'; payload: boolean }
  | { type: 'createNewOrder'; payload: boolean }
  | { type: 'namingConvention'; payload: NamingConventionType }
  | { type: 'needReport'; payload: boolean }
  | { type: 'sendCustomerMail'; payload: boolean }
  | { type: 'init'; payload: ServiceSettingsEntity }
  | { type: 'clear' };

const initialState: State = {
  data: {
    sendCustomerMail: true,
    recurring: {
      days: 7,
      active: false,
      namingConvention: NamingConventionType.weekName,
      currentIndex: 1,
      createNewOrder: false,
    },
    needReport: true,
  },
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'days':
      return {
        ...state,
        data: { ...state.data, recurring: { ...state.data.recurring, days: action.payload } },
        hasChanges: true,
      };
    case 'active':
      return {
        ...state,
        data: { ...state.data, recurring: { ...state.data.recurring, active: action.payload } },
        hasChanges: true,
      };
    case 'createNewOrder':
      return {
        ...state,
        data: { ...state.data, recurring: { ...state.data.recurring, createNewOrder: action.payload } },
        hasChanges: true,
      };
    case 'namingConvention':
      return {
        ...state,
        data: { ...state.data, recurring: { ...state.data.recurring, namingConvention: action.payload } },
        hasChanges: true,
      };
    case 'needReport':
      return {
        ...state,
        data: { ...state.data, needReport: action.payload },
        hasChanges: true,
      };
    case 'sendCustomerMail':
      return {
        ...state,
        data: { ...state.data, sendCustomerMail: action.payload },
        hasChanges: true,
      };
    case 'init':
      return {
        ...initialState,
        data: action.payload,
        initialized: true,
      };
    case 'clear':
      return { ...initialState };
  }
};

/**
 * EditModal
 * @param props
 * @constructor
 */
export default function EditModal(props: Props) {
  const { service, visible, onClose, onSave, order } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [mailValidated, setMailValidated] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const getFacility = useGetFacility();

  React.useEffect(() => {
    const facility = getFacility(order.createdFacilityId);
    if (facility) {
      setMailValidated(facility.settings && facility.settings.mailSettings && facility.settings.mailSettings.validated);
    }
  }, [getFacility, order.createdFacilityId]);

  React.useEffect(() => {
    if (!state.initialized && service && visible) {
      dispatch({
        type: 'init',
        payload: { ...service.settings, sendCustomerMail: !mailValidated ? false : service.settings.sendCustomerMail },
      });
    }
  }, [mailValidated, service, state.initialized, visible]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear' });
    }
  }, [visible]);

  const handleSave = () => {
    onSave(state.data);
  };

  if (visible && state.initialized) {
    return (
      <Modal show={visible} onHide={() => onClose()} size="xl">
        <Modal.Header>
          <div>
            <Modal.Title style={{ marginLeft: 25 }}>{t('settings')}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: 30 }}>
          <ShowHideContainer visible={!mailValidated && state.data.needReport}>
            <Alert variant="danger">{t('modules.order.customerReportMailHintDeactivated')}</Alert>
          </ShowHideContainer>
          <Row>
            <Col lg={6}>
              <SelectYesNo
                onChange={(value) => dispatch({ type: 'needReport', payload: value })}
                initialValue={state.data.needReport}
                label={t('needReport')}
              />
            </Col>
            <Col lg={6}>
              <SelectYesNo
                onChange={(value) => dispatch({ type: 'sendCustomerMail', payload: value })}
                initialValue={!mailValidated && state.data.needReport ? false : state.data.sendCustomerMail}
                label={t('sendReportCustomerMail')}
                disabled={!state.data.needReport || !mailValidated}
              />
            </Col>
          </Row>

          <div style={{ marginTop: 40 }}>
            <Row>
              <Col lg={6}>
                <SelectYesNo
                  initialValue={state.data.recurring.active}
                  label={t('recurringService')}
                  onChange={(value) => dispatch({ type: 'active', payload: value })}
                />
              </Col>
              <Col lg={6}>
                <SelectRecurringDay
                  initialValue={state.data.recurring.days}
                  label={t('recurringEvery')}
                  onChange={(value) => dispatch({ type: 'days', payload: value })}
                  disabled={!state.data.recurring.active}
                />
              </Col>
              <Col lg={6}>
                <SelectOrderRecurringType
                  initialValue={state.data.recurring.createNewOrder || false}
                  onChange={(value) => dispatch({ type: 'createNewOrder', payload: value })}
                  disabled={!state.data.recurring.active}
                />
              </Col>
              <Col lg={6}>
                <SelectNamingConvention
                  onChange={(value) => dispatch({ type: 'namingConvention', payload: value })}
                  label={t('recurringNamingConvention')}
                  initialValue={state.data.recurring.namingConvention}
                  disabled={!state.data.recurring.active || !state.data.recurring.createNewOrder}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
            {t('save')}
          </Button>
          <Button variant="outline-secondary" onClick={() => onClose()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
