import React from 'react';
import { AbstractMedia } from '../../Globals/Types/Types';
import styled from 'styled-components';
import Text from '../Text';
import moment from 'moment';
import { formatSize, getFileTypeIcon } from '../../Globals/Functions';
import { lighten } from 'polished';
import AskDeleteModal from '../Modals/AskDeleteModal';

const Container = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  ${(props) => props.theme.boxShadowDark};
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: ${(props) => props.theme.border.radius};
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .ImageContainer {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 15px;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => lighten(0.125, props.theme.color.textGrayLight)};
    }

    > img {
      border-top-left-radius: ${(props) => props.theme.border.radius};
      border-top-right-radius: ${(props) => props.theme.border.radius};
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    > i {
      font-size: ${(props) => props.width * 0.4}px;
      color: ${(props) => props.theme.color.textGray};
    }
  }

  .BottomContainer {
    padding: 10px;
    background-color: ${(props) => lighten(0.125, props.theme.color.textGrayLight)};
    display: flex;
    flex-direction: row;
    align-items: center;

    .Left {
      flex-grow: 1;
      overflow: hidden;
    }

    .Actions {
      width: 80px;
      i {
        padding-right: 10px;
        padding-left: 10px;
        cursor: pointer;
        color: ${(props) => props.theme.color.textGray};

        &.fa-download {
          &:hover {
            color: ${(props) => props.theme.color.primaryColor};
          }
        }
        &.fa-trash-alt {
          &:hover {
            color: ${(props) => props.theme.color.red};
          }
        }
      }
    }
  }
`;

interface Props {
  media: AbstractMedia;
  width: number;
  onDelete: (item: AbstractMedia) => void;
}

/**
 * Item()
 * @param props
 * @constructor
 */
export default function Item(props: Props) {
  const { media, width, onDelete } = props;
  const [askDelete, setAskDelete] = React.useState<boolean>(false);

  const open = () => window.open(media.downloadUrl, '_blank');

  const getContent = () => {
    if (media.contentType.indexOf('image') >= 0) {
      return <img src={media.downloadUrl} alt={media.name} />;
    } else {
      return <i className={getFileTypeIcon(media.name)} />;
    }
  };

  const renderInfos = () => {
    const date = moment(media.timeCreated).format('LL');
    const size = formatSize(media.size);
    return (
      <Text small light>
        {`${date} (${size})`}
      </Text>
    );
  };

  return (
    <Container width={width}>
      <div className="ImageContainer" onClick={open}>
        {getContent()}
      </div>
      <div className="BottomContainer">
        <div className="Left">
          <Text noWrap>{media.name}</Text>
          {renderInfos()}
        </div>
        <div className="Actions">
          <i className="fas fa-trash-alt" onClick={() => setAskDelete(true)} />
          <i className="fas fa-download" onClick={open} />
        </div>
      </div>

      <AskDeleteModal onDelete={() => onDelete(media)} onClose={() => setAskDelete(false)} visible={askDelete} />
    </Container>
  );
}
