import React from 'react';
import { PanelSelectOption } from './index';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  item: PanelSelectOption;
  onSelect: (item: PanelSelectOption) => void;
  onDeselect: (item: PanelSelectOption) => void;
  selected: boolean;
  hasError?: boolean;
  errorId?: string;
}

const Container = styled.div<{ selected: boolean; hasError: boolean }>`
  position: relative;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-right: 15px;
  border: 1px solid ${(props) => (props.selected ? props.theme.color.text : props.theme.color.textGrayLight)};
  color: ${(props) =>
    props.hasError ? props.theme.color.red : props.selected ? props.theme.color.text : props.theme.color.textGrayLight};
  border-radius: ${(props) => props.theme.border.radius};
  cursor: pointer;
  text-align: center;

  i {
    font-size: 36px;
    margin-bottom: 15px;

    &.fa-check-circle {
      position: absolute;
      font-size: 20px;
      top: 10px;
      right: 10px;
      color: ${(props) => props.theme.color.green};
    }
  }

  img {
    opacity: ${(props) => (props.selected ? 1 : 0.3)};
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    ${(props) => props.theme.boxShadowDark};
    color: ${(props) => props.theme.color.text};
  }
`;

/**
 * Box
 * @param props
 * @constructor
 */
export default function Box(props: Props) {
  const { item, onSelect, onDeselect, selected, hasError } = props;
  const [t] = useTranslation();

  const handleOnPress = () => {
    if (selected) {
      onDeselect(item);
    } else {
      onSelect(item);
    }
  };

  const renderCheckIcon = () => {
    if (selected) {
      return <i className="fas fa-check-circle" />;
    }
    return null;
  };

  const renderIconOrImage = () => {
    if (item.icon) {
      return <i className={item.icon} />;
    }
    return <img src={item.imageUrl} alt="CompanyLogo" height={56} />;
  };

  return (
    <Container onClick={handleOnPress} selected={selected} hasError={hasError}>
      {renderIconOrImage()}
      {item.useTranslation === undefined || item.useTranslation === true ? t(item.caption) : item.caption}
      {renderCheckIcon()}
    </Container>
  );
}
