import React from 'react';
import { useNavigate } from 'react-router';
import PageHeader from '../../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import { useDispatchInvoiceGet } from '../../../Redux/Actions/Client/InvoiceAction';
import { InvoiceEntity } from '../../../Globals/Types/Invoice';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { buildUrlCustomersInvoices } from '../../../Globals/UrlFunctions';
import Panel from '../../../Components/Panel';
import { getInvoiceAddressName } from '../../../Globals/Functions/InvoiceFunctions';
import { Alert, Button } from 'react-bootstrap';
import DisplayText from '../../../Components/View/DisplayText';
import { formatCurrency, formatDateString, userDisplayName } from '../../../Globals/Functions';
import PositionRows from './Components/PositionRows';
import DetailsActionButton from './Components/DetailsActionButton';
import AssignedServiceHint from './Components/AssignedServiceHint';
import Text from '../../Text';
import { useGetUser } from '../../../Globals/Functions/UserFunctions';
import AccessCodeHint from './Components/AccessCodeHint';

interface Props {
  invoiceId: string;
  customerId?: string;
}

/**
 * Details()
 * @constructor
 */
export default function Details(props: Props) {
  const { customerId, invoiceId } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { setLoading, loadingModal } = useLoadingModal({ delayStart: 500 });
  const { setLoading: setLoadingButtons, loadingModal: loadingModalButtons, setHeadline } = useLoadingModal();
  const [invoice, setInvoice] = React.useState<InvoiceEntity>(null);
  const dispatchInvoiceGet = useDispatchInvoiceGet();
  const getUser = useGetUser();

  React.useEffect(() => {
    setLoading(true);

    dispatchInvoiceGet(invoiceId)
      .then(setInvoice)
      .catch(() => navigate(buildUrlCustomersInvoices(customerId)))
      .finally(() => setLoading(false));
  }, [customerId, dispatchInvoiceGet, invoiceId, navigate, setLoading]);

  const renderBackButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => navigate(-1)}>
        <i className="fas fa-angle-double-left" style={{ marginRight: 6 }} />
        {t('back')}
      </Button>
    );
  };

  const renderPayedHint = () => {
    if (invoice.payed) {
      return <Alert variant="success">{t('invoicedPayedHint', { date: formatDateString(invoice.payedDate) })}</Alert>;
    }
    return null;
  };

  const handleDownloading = (value: boolean) => {
    setHeadline(t('creatingPdf'));
    setLoadingButtons(value);
  };

  const handleMailSending = (value: boolean) => {
    setHeadline(t('mailSending'));
    setLoadingButtons(value);
  };

  const renderDescription = () => {
    const user = getUser(invoice.createdUserId);
    const captionOne = invoice.isVoucher ? t('voucherNumber') : t('invoiceNumber');

    return (
      <>
        <Text bold>{`${captionOne}: ${invoice.invoiceNumber}`}</Text>
        <Text small light>
          {`${t('createdFrom')} ${userDisplayName(user, true)}`}
        </Text>
      </>
    );
  };

  const getHeadline = () => (invoice.isVoucher ? t('voucher') : t('invoice'));

  if (invoice) {
    return (
      <>
        <PageHeader
          headline={getHeadline()}
          description={renderDescription()}
          actionButtonTwo={
            <DetailsActionButton
              invoice={invoice}
              onDownloading={handleDownloading}
              onSendingMail={handleMailSending}
              onUpdating={setLoading}
              onUpdate={setInvoice}
              customerId={customerId}
            />
          }
          actionButtonOne={renderBackButton()}
        />

        <AssignedServiceHint invoice={invoice} />

        <Panel>
          <AccessCodeHint invoice={invoice} />
          {renderPayedHint()}
          <DisplayText caption={t('address')} containerStyle={{ marginBottom: 30 }}>
            <div>{getInvoiceAddressName(invoice.address, t)}</div>
            <div>{`${invoice.address.street} ${invoice.address.streetNo}`}</div>
            <div>{`${invoice.address.zip} ${invoice.address.city}`}</div>
            <div>{t(`countryCodesToName.${invoice.address.countryCode}`)}</div>
            <div>{invoice.address.taxNumber}</div>
          </DisplayText>

          <DisplayText horizontal caption={`${t(invoice.isVoucher ? 'voucher' : 'invoice')} Nr.`}>
            {invoice.invoiceNumber}
          </DisplayText>

          <DisplayText horizontal caption={t('date')}>
            {formatDateString(invoice.createdDate)}
          </DisplayText>

          <DisplayText horizontal caption={t('dueDate')}>
            {formatDateString(invoice.dueDate)}
          </DisplayText>

          <DisplayText horizontal caption={t('paymentType')}>
            {t(`paymentTypeOptions.${invoice.paymentType}`)}
          </DisplayText>
        </Panel>
        <Panel headline={t('positions')}>
          <div>
            <PositionRows positions={invoice.positions} />
          </div>
        </Panel>

        <Panel>
          <DisplayText horizontal caption={t('print.invoice.overallNetto')} visible={!invoice.isTaxFree}>
            {formatCurrency(invoice.priceOverall)}
          </DisplayText>

          <DisplayText
            horizontal
            caption={t('invoiceTaxCaption', { tax: invoice.taxRate })}
            visible={!invoice.isTaxFree}
          >
            {formatCurrency(invoice.tax)}
          </DisplayText>

          <DisplayText horizontal caption={t('overallPrice')} bold>
            {formatCurrency((parseFloat(invoice.priceOverall) + parseFloat(invoice.tax || '0')).toString())}
          </DisplayText>
        </Panel>

        {loadingModal}
        {loadingModalButtons}
      </>
    );
  }
  return loadingModal;
}
