import React from 'react';
import { useAppSelector, useLoadingModal } from '../../../../../Globals/Hooks/Hooks';
import { SubscriptionPeriod, SubscriptionType } from '../../../../../Globals/Types/Enums';
import Panel from '../../../../../Components/Panel';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import DisplayText from '../../../../../Components/View/DisplayText';
import moment from 'moment';
import AskDeleteModal from '../../../../../Components/Modals/AskDeleteModal';
import { useDispatchClientSubscriptionCancel } from '../../../../../Redux/Actions/Client/SubscriptionAction';
import Invoices from '../Invoices';
import { formatCurrency } from '../../../../../Globals/Functions';

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const [t] = useTranslation();
  const { setLoading, loadingModal } = useLoadingModal();
  const [askCancel, setAskCancel] = React.useState<boolean>(false);
  const { activeSubscription } = useAppSelector((state) => state.auth);
  const dispatchCancel = useDispatchClientSubscriptionCancel();

  const handleCancel = React.useCallback(() => {
    setLoading(true);
    setAskCancel(false);
    dispatchCancel().finally(() => setLoading(false));
  }, [dispatchCancel, setLoading]);

  const renderRenewHint = () => {
    if (activeSubscription.autoRenew) {
      return (
        <DisplayText horizontal caption={t('automaticRenew')}>
          {moment(activeSubscription.endDate).add(1, 'day').format('LLL')}
        </DisplayText>
      );
    }
    return null;
  };

  const getStatusText = () =>
    activeSubscription.autoRenew ? t('active') : t('modules.settings.subscription.canceled');

  const renderCancelButton = () =>
    activeSubscription.autoRenew ? (
      <div style={{ marginTop: 50, textAlign: 'center' }}>
        <Button onClick={() => setAskCancel(true)} variant="outline-danger" size="sm">
          {t('modules.settings.subscription.cancel')}
        </Button>
      </div>
    ) : null;

  if (activeSubscription.type !== SubscriptionType.demo) {
    return (
      <>
        <Row>
          <Col xxl={12}>
            <Panel headline={t('modules.settings.subscription.currentSubscription')}>
              <DisplayText horizontal caption={t('state')}>
                {getStatusText()}
              </DisplayText>
              <DisplayText horizontal caption={t('validUntil')}>
                {moment(activeSubscription.endDate).format('LLL')}
              </DisplayText>
              <DisplayText horizontal caption={t('subscriptionPeriod')}>
                {activeSubscription.period === SubscriptionPeriod.month ? t('monthly') : t('yearly')}
              </DisplayText>
              <DisplayText horizontal caption={t('price')}>
                {formatCurrency(activeSubscription.overallPrice.toString())}
              </DisplayText>
              <DisplayText horizontal caption={t('modules.settings.subscription.userCount')}>
                {activeSubscription.users.toString()}
              </DisplayText>
              {renderRenewHint()}
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col xxl={12}>
            <Invoices />
          </Col>
        </Row>

        {renderCancelButton()}

        <AskDeleteModal
          onDelete={handleCancel}
          onClose={() => setAskCancel(false)}
          visible={askCancel}
          headline={t('modules.settings.subscription.cancel')}
          confirmButtonText={t('modules.settings.subscription.cancel')}
          description={t('modules.settings.subscription.askConfirmCancel')}
        />

        {loadingModal}
      </>
    );
  }
  return null;
}
