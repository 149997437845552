import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  background-color: ${(props) => lighten(0, props.theme.color.primaryColor)};
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 60px 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1350px) {
    margin-top: 60px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 1200px;
  justify-content: center;
  align-items: center;

  @media (max-width: 1350px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  i {
    font-size: 80px;
    color: #ffffff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  }

  div {
    margin-top: 30px;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #ffffff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  }

  @media (max-width: 1350px) {
    margin-bottom: 80px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

/**
 * TeaserRow()
 * @constructor
 */
export default function TeaserRow() {
  const [t] = useTranslation();

  return (
    <Container>
      <InnerContainer>
        <Column>
          <i className="fad fa-users" />
          <div>{t('modules.website.teaser.customers')}</div>
        </Column>
        <Column>
          <i className="fad fa-clock-three" />
          <div>{t('modules.website.teaser.savesTime')}</div>
        </Column>
        <Column>
          <i className="fad fa-clouds" />
          <div>{t('modules.website.teaser.cloud')}</div>
        </Column>
        <Column>
          <i className="fad fa-globe-europe" />
          <div>{t('modules.website.teaser.multilingual')}</div>
        </Column>
      </InnerContainer>
    </Container>
  );
}
