import React from 'react';
import styled from 'styled-components';
import Icons from './Icons';

const Container = styled.div<{ hasError: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: ${(props) => props.theme.border.radius};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.hasError ? props.theme.color.red : props.theme.border.color)};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  padding: 10px;
  margin-right: 2px;
  width: 40px;
  height: 40px;
  color: ${(props) => props.theme.color.textGray};
  border-radius: ${(props) => props.theme.border.radius};

  &:hover {
    background-color: ${(props) => props.theme.color.textGray};
    color: #ffffff;
  }

  &.Selected {
    background-color: ${(props) => props.theme.color.primaryColor};
    color: #ffffff;
  }
`;

interface Props {
  onSelect: (icon: string) => void;
  selected?: string;
  hasError: boolean;
}

/**
 * SelectIcon()
 * @param props
 * @constructor
 */
export default function SelectIcon(props: Props) {
  const { onSelect, selected, hasError } = props;

  const renderIcons = () =>
    Icons.map((icon) => (
      <IconContainer className={selected === icon ? 'Selected' : ''} onClick={() => onSelect(icon)} key={icon}>
        <i className={icon} />
      </IconContainer>
    ));

  return <Container hasError={hasError}>{renderIcons()}</Container>;
}

SelectIcon.defaultProps = {
  selected: '',
  hasError: false,
};
