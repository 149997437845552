import { RecurringType } from '../../Globals/Types/Enums';
import moment, { Moment } from 'moment';
import { StateData } from './RecurringForm';

let dateEnd: string = null;
let dateStart: string = null;
let dates: string[] = [];
let maxWeekday: number = 5;

/**
 * addDate()
 * @param date
 * @param forceWeekend
 */
const addDate = (date: Moment, forceWeekend: boolean = false) => {
  const dateStr: string = date.format('YYYY-MM-DD');
  if (dateStr >= dateStart && dateStr <= dateEnd && (date.isoWeekday() <= maxWeekday || forceWeekend)) {
    dates.push(dateStr);
  }
};

/**
 * buildDatesDaily()
 * @param data
 * @param forceWeekend
 */
const buildDatesDaily = (data: StateData, forceWeekend: boolean = false) => {
  const { dailyDays } = data;

  let dateCurr: Moment = moment(dateStart);
  while (dateCurr.format('YYYY-MM-DD') <= dateEnd) {
    addDate(dateCurr, forceWeekend);
    dateCurr.add(dailyDays, 'days');
  }
};

/**
 * buildDatesMonthly()
 * @param data
 */
const buildDatesMonthly = (data: StateData) => {
  const { monthlyDay, monthlyMonths, monthlyOn, monthlyOnDay } = data;

  let dateCurr: Moment = moment(dateStart);
  if (monthlyDay) {
    const monthlyDayForDate: string = `00${monthlyDay}`.slice(-2);
    while (dateCurr.format('YYYY-MM-DD') <= dateEnd) {
      dateCurr = moment(`${dateCurr.format('YYYY-MM')}-${monthlyDayForDate}`);
      if (parseInt(dateCurr.format('DD')) === monthlyDay) {
        addDate(dateCurr);
      }

      dateCurr.add(monthlyMonths, 'months');
    }

    return;
  }

  const monthlyOnNum: number = parseInt(monthlyOn);
  const monthlyOnForDate: string = `00${monthlyOn}`.slice(-2);

  while (dateCurr.format('YYYY-MM-DD') <= dateEnd) {
    switch (monthlyOnDay) {
      case 'day':
        if (monthlyOnNum < 31) {
          dateCurr = moment(`${dateCurr.format('YYYY-MM')}-${monthlyOnForDate}`);
        } else {
          dateCurr.endOf('month');
        }
        if (dateCurr.isValid() && (monthlyOnNum === 31 || parseInt(dateCurr.format('DD')) === monthlyOnNum)) {
          addDate(dateCurr, true);
        }
        break;
      case 'weekday':
        if (monthlyOnNum < 31) {
          dateCurr.startOf('month');
          while (dateCurr.isoWeekday() > maxWeekday) {
            dateCurr.add(1, 'days');
          }
          dateCurr.add(monthlyOnNum - 1, 'days');

          addDate(dateCurr);
        } else {
          dateCurr.endOf('month');
          while (dateCurr.isoWeekday() > maxWeekday) {
            dateCurr.subtract(1, 'day');
          }

          addDate(dateCurr);
        }
        break;
    }

    dateCurr.add(monthlyMonths, 'months');
  }
};

/**
 * buildDatesWeekly()
 * @param data
 */
const buildDatesWeekly = (data: StateData) => {
  const { weeklyWeekdays, weeklyWeeks } = data;

  const dateStartOfWeek: Moment = moment(dateStart).startOf('week');

  while (dateStartOfWeek.format('YYYY-MM-DD') <= dateEnd) {
    weeklyWeekdays.forEach((item, index) => {
      if (item) {
        addDate(moment(dateStartOfWeek).add(index, 'days'), true);
      }
    });

    dateStartOfWeek.add(weeklyWeeks, 'week').startOf('week');
  }
};

/**
 * buildDates()
 * @param data
 * @param maxWeekdayParam
 */
export const buildDates = (data: StateData, maxWeekdayParam: number) => {
  dates = [];
  dateStart = data.dateStart;
  dateEnd = data.dateEnd;
  maxWeekday = maxWeekdayParam;

  switch (data.recurring) {
    case RecurringType.daily:
      buildDatesDaily(data, true);
      break;
    case RecurringType.dailyWork:
      buildDatesDaily(data);
      break;
    case RecurringType.monthly:
      buildDatesMonthly(data);
      break;
    case RecurringType.weekly:
      buildDatesWeekly(data);
      break;
  }

  return dates;
};
