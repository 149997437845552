import React from 'react';
import { ElasticSearchServiceEntity } from '../../../Globals/Types/OrderTypes';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGetTimeOptions } from '../../../Globals/Hooks/Hooks';
import Select from '../../Inputs/Select';
import moment from 'moment';
import { useDispatchOrderServiceUpdate } from '../../../Redux/Actions/Order/ServiceAction';

interface Props {
  service: ElasticSearchServiceEntity;
  visible: boolean;
  onClose: () => void;
  startTime: string;
  onSave: (service: ElasticSearchServiceEntity) => void;
}

/**
 * SetAssemblyTimeModal()
 * @param props
 * @constructor
 */
export default function SetAssemblyTimeModal(props: Props) {
  const { service, visible, onClose, startTime, onSave } = props;
  const [t] = useTranslation();
  const { options: timeOptions } = useGetTimeOptions(10);
  const [start, setStart] = React.useState<string>(startTime);
  const [end, setEnd] = React.useState<string>(startTime);

  const dispatchUpdateService = useDispatchOrderServiceUpdate();

  const updateService = React.useCallback(() => {
    if (service) {
      const assemblyTime = moment(end, 'HH:mm').diff(moment(start, 'HH:mm'), 'minute');

      if (assemblyTime > 10) {
        dispatchUpdateService(service.ownerClientId, service.orderId, service.serviceId, {
          estimatedAssemblyTime: assemblyTime,
        }).then((serviceResponse) => {
          const returnParam: ElasticSearchServiceEntity = {
            ...service,
            estimatedAssemblyTime: serviceResponse.estimatedAssemblyTime,
          };
          onSave(returnParam);
        });
      }
    }
  }, [dispatchUpdateService, end, onSave, service, start]);

  React.useEffect(() => {
    if (startTime) {
      setStart(startTime.substring(0, 5));
      setEnd(moment(startTime, 'HH:mm').add(60, 'minute').format('HH:mm'));
    }
  }, [startTime]);

  return (
    <Modal show={visible} animation={false} size="lg" centered>
      <Modal.Header>
        <Modal.Title>{t('modules.disposition.serviceAssemblyTimeModalHeadline')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('modules.disposition.serviceAssemblyTimeModalDescription')}</p>

        <Row>
          <Col lg={6}>
            <Select onChange={setStart} label={t('timeStart')} options={timeOptions} initialValue={start} />
          </Col>
          <Col lg={6}>
            <Select onChange={setEnd} label={t('timeEnd')} options={timeOptions} initialValue={end} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={updateService}>
          {t('save')}
        </Button>
        <Button variant="outline-secondary" onClick={() => onClose()}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
