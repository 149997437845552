export enum InternalErrorCodes {
  FUNCTIONS_BODY_MESSAGE_OBJECT_MISSING = 'FUNCTIONS_BODY_MESSAGE_OBJECT_MISSING',

  ARTICLE_ALREADY_EXISTING = 'ARTICLE_ALREADY_EXISTING',
  CLIENT_ALREADY_EXISTING = 'CLIENT_ALREADY_EXISTING',
  EXTENSION_NOT_ALLOWED = 'EXTENSION_NOT_ALLOWED',
  FIRESTORE_DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND',
  FIRESTORE_NO_DOCUMENTS = 'FIRESTORE_NO_DOCUMENTS',
  FIRESTORE_RESET_TOKEN_INVALID = 'FIRESTORE_RESET_TOKEN_INVALID',
  FILESIZE_TO_LARGE = 'FILESIZE_TO_LARGE',
  FIELD_MISSING = 'FIELD_MISSING',
  GLN_INVALID = 'GLN_INVALID',
  MAIL_INVALID = 'MAIL_INVALID',
  OBJECT_KEY_MISSING_PATTERN = 'OBJECT_KEY_{key}_MISSING',
  PASSWORD_TO_SHORT = 'PASSWORD_TO_SHORT',
  PASSWORD_NO_MATCH = 'PASSWORD_NO_MATCH',
  PASSWORD_INVALID_CHARS = 'PASSWORD_INVALID_CHARS',
  PRICE_INVALID = 'PRICE_INVALID',
  START_AFTER_END = 'START_AFTER_END',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  URL_INVALID = 'URL_INVALID',
  USER_ALREADY_EXISTING = 'USER_ALREADY_EXISTING',
  USER_INITIALS_TO_LONG = 'USER_INITIALS_TO_LONG',
  TIME_INVALID = 'TIME_INVALID',

  NO_LICENSE_AVAILABLE = 'NO_LICENSE_AVAILABLE',
  NO_LOGIN_PERMISSION = 'NO_LOGIN_PERMISSION',

  ENTRY_NOT_FOUND = 'ENTRY_NOT_FOUND',

  REGISTER_COMPANY_NAME_INVALID = 'REGISTER_COMPANY_NAME_INVALID',
  REGISTER_COMPANY_ALREADY_EXISTING = 'REGISTER_COMPANY_ALREADY_EXISTING',
  REGISTER_PASSWORD_NO_MATCH = 'REGISTER_PASSWORD_NO_MATCH',
  REGISTER_PASSWORD_INVALID = 'REGISTER_PASSWORD_INVALID',

  DISPOSITION_MISMATCH_FACILITY = 'DISPOSITION_MISMATCH_FACILITY',
  DISPOSITION_ALREADY_PLANNED_RESOURCE = 'DISPOSITION_ALREADY_PLANNED_RESOURCE',
  DISPOSITION_ALREADY_PLANNED_USER = 'DISPOSITION_ALREADY_PLANNED_USER',
  DISPOSITION_OVER_24H = 'DISPOSITION_OVER_24H',
  DISPOSITION_USER_NOT_ALL_FACILITIES = 'DISPOSITION_USER_NOT_ALL_FACILITIES',

  POSITION_CAPTION_ALREADY_EXISTING = 'POSITION_CAPTION_ALREADY_EXISTING',
}
