import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import InvoiceDetails from '../../Components/Invoice/Details';

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const { invoiceId } = useParams();

  return (
    <Row>
      <Col xxl={12}>
        <InvoiceDetails invoiceId={invoiceId} />
      </Col>
    </Row>
  );
}
