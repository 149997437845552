import React from 'react';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import Item from './Item';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Text from '../../../Text';

const Container = styled.div`
  overflow-y: auto;
  height: ${() => window.innerHeight - 100}px;
  margin-right: -10px;
  padding-right: 10px;
`;

/**
 * ListView()
 * @constructor
 */
export default function ListView() {
  const [t] = useTranslation();
  const { messages } = useAppSelector((state) => state.user.inbox);

  const renderItems = () => {
    return messages.map((message) => <Item message={message} key={message.messageId} />);
  };

  if (messages && messages.length > 0) {
    return <Container>{renderItems()}</Container>;
  }
  return (
    <Text centered light containerStyle={{ marginTop: 30 }}>
      {t('inboxEmpty')}
    </Text>
  );
}
