import React from 'react';
import Panel from '../../Panel';
import { OrderCustomerAddress } from '../../../Globals/Types/OrderTypes';
import { useTranslation } from 'react-i18next';
import SelectSalutation from '../../PredefinedSelects/SelectSaltutation';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import Input, { InputType } from '../../Inputs/Input';
import SelectFloor from '../../PredefinedSelects/SelectFloor';
import SelectParking from '../../PredefinedSelects/SelectParking';
import Select from '../../Inputs/Select';
import { selectOptionYesNo } from '../../../Globals/Types/General';
import ShowHideContainer from '../../ShowHideContainer';
import { ValidateCustomerAddressResult } from '../Validators/ValidateCustomerAddress';
import SelectNotifyBeforeArrival from '../../PredefinedSelects/SelectNotifyBeforeArrival';
import { CountryCode, Salutation } from '../../../Globals/Types/Enums';
import Index from '../../Customer/SelectCustomerModal';
import { CustomerEntity } from '../../../Globals/Types/Customer';
import { customerBuildName, customerToOrderCustomerAddress } from '../../../Globals/Functions/CustomerFunctions';
import styled from 'styled-components';
import { LOCALSTORAGE_CUSTOMER_CREATE_ORDER } from '../../../Globals/Globals';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import SelectLanguage from '../../PredefinedSelects/SelectLanguage';

const AlertContainer = styled(Alert)`
  display: flex;
  flex-direction: row;

  .fa-trash-alt {
    cursor: pointer;
    color: ${(props) => props.theme.color.textGray};

    &:hover {
      color: ${(props) => props.theme.color.red};
    }
  }
`;

interface Props {
  data: OrderCustomerAddress;
  onChange: (address: OrderCustomerAddress) => void;
  errors?: OrderCustomerAddress;
  validationResult: ValidateCustomerAddressResult;
  blackHeadline?: boolean;
}

/**
 * CustomerAddress
 * @param props
 * @constructor
 */
export default function CustomerAddress(props: Props) {
  const { data, onChange, validationResult, blackHeadline } = props;
  const { settings } = useAppSelector((state) => state.auth.client);
  const [showSelectCustomer, setShowSelectCustomer] = React.useState<boolean>(false);
  const [t] = useTranslation();

  React.useEffect(() => {
    const data = localStorage.getItem(LOCALSTORAGE_CUSTOMER_CREATE_ORDER);
    if (data) {
      const parsed = JSON.parse(data);
      onChange(parsed);
      localStorage.removeItem(LOCALSTORAGE_CUSTOMER_CREATE_ORDER);
    }
  }, [onChange]);

  const handleOnChange = (key: string, value: string | number) => {
    if (key === 'firstName' || key === 'lastName') {
      onChange({ ...data, customerId: null, [key]: value });
    } else {
      onChange({ ...data, [key]: value });
    }
  };

  const handleSelectCustomer = (customer: CustomerEntity) => {
    onChange(customerToOrderCustomerAddress(customer));
    setShowSelectCustomer(false);
  };

  const renderActionButton = () => {
    if (settings.customer.active) {
      return (
        <Button variant="outline-secondary" onClick={() => setShowSelectCustomer(true)}>
          <i className="fas fa-users" />
        </Button>
      );
    }
    return null;
  };

  const renderCustomerHint = () => {
    if (data.customerId) {
      return (
        <AlertContainer variant="warning">
          <div>
            <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
          </div>
          <div className="d-flex flex-grow-1">
            {t('modules.order.connectedToCustomer', {
              customerName: customerBuildName(data as unknown as CustomerEntity, true, t),
            })}
          </div>
          <div>
            <i className="fas fa-trash-alt" onClick={() => handleOnChange('customerId', null)} />
          </div>
        </AlertContainer>
      );
    }
    return null;
  };

  return (
    <Panel
      headline={t('customerAddress')}
      description={t('modules.order.customerAddressDescription')}
      headlineDarkBlack={blackHeadline || false}
      customRightAction={renderActionButton()}
    >
      {renderCustomerHint()}

      <Row>
        <Col lg={2}>
          <SelectSalutation
            onChange={(value) => handleOnChange('salutation', value)}
            selected={data.salutation || Salutation.male}
            required
          />
        </Col>
        <Col lg={data.salutation !== Salutation.company ? 5 : 10}>
          <Input
            label={data.salutation !== Salutation.company ? t('firstName') : t('companyName')}
            value={data.firstName}
            onChange={(value) => handleOnChange('firstName', value)}
            required
            hasError={validationResult ? !!validationResult.errors.firstName : false}
            errorId={validationResult ? validationResult.errors.firstName : ''}
          />
        </Col>
        <Col lg={5}>
          <Input
            label={t('lastName')}
            value={data.lastName}
            onChange={(value) => handleOnChange('lastName', value)}
            required
            hasError={validationResult ? !!validationResult.errors.lastName : false}
            errorId={validationResult ? validationResult.errors.lastName : ''}
            visible={data.salutation !== Salutation.company}
          />
        </Col>
        <Col lg={9}>
          <Input
            label={t('street')}
            value={data.street}
            onChange={(value) => handleOnChange('street', value)}
            required
            hasError={validationResult ? !!validationResult.errors.street : false}
            errorId={validationResult ? validationResult.errors.street : ''}
          />
        </Col>
        <Col lg={3}>
          <Input
            label={t('streetNo')}
            value={data.streetNo}
            onChange={(value) => handleOnChange('streetNo', value)}
            required
            hasError={validationResult ? !!validationResult.errors.streetNo : false}
            errorId={validationResult ? validationResult.errors.streetNo : ''}
          />
        </Col>
        <Col lg={3}>
          <Input
            label={t('zip')}
            value={data.zip}
            onChange={(value) => handleOnChange('zip', value)}
            required
            hasError={validationResult ? !!validationResult.errors.zip : false}
            errorId={validationResult ? validationResult.errors.zip : ''}
          />
        </Col>
        <Col lg={9}>
          <Input
            label={t('city')}
            value={data.city}
            onChange={(value) => handleOnChange('city', value)}
            required
            hasError={validationResult ? !!validationResult.errors.city : false}
            errorId={validationResult ? validationResult.errors.city : ''}
          />
        </Col>
        <Col lg={6}>
          <Input
            label={t('eMailAddress')}
            value={data.mail}
            onChange={(value) => handleOnChange('mail', value)}
            hasError={validationResult ? !!validationResult.errors.mail : false}
            errorId={validationResult ? validationResult.errors.mail : ''}
          />
        </Col>
        <Col lg={6}>
          <SelectLanguage
            onChange={(value) => handleOnChange('countryCode', value)}
            label={t('language')}
            selected={data.countryCode || CountryCode.ES}
          />
        </Col>
        <Col lg={6}>
          <Input label={t('phone')} value={data.phone} onChange={(value) => handleOnChange('phone', value)} />
        </Col>
        <Col lg={6}>
          <Input label={t('mobile')} value={data.mobile} onChange={(value) => handleOnChange('mobile', value)} />
        </Col>
        <Col lg={6}>
          <SelectFloor onChange={(value) => handleOnChange('floor', value)} selected={data.floor} />
        </Col>
        <ShowHideContainer visible={data.floor > 0}>
          <Col lg={6}>
            <Select
              label={t('elevatorAvailable')}
              initialValue={data.hasElevator}
              onChange={(value) => handleOnChange('hasElevator', value)}
              options={selectOptionYesNo}
            />
          </Col>
        </ShowHideContainer>
        <Col lg={data.floor > 0 ? 12 : 6}>
          <SelectParking onChange={(value) => handleOnChange('parking', value)} selected={0} />
        </Col>
        <Col lg={12}>
          <SelectNotifyBeforeArrival
            onChange={(value) => handleOnChange('notifyBeforeArrival', value)}
            selected={data.notifyBeforeArrival}
            translationKey="modules.order.notifyBeforeArrivalMinutes"
          />
        </Col>
        <Col lg={12}>
          <Input
            value={data.comment}
            onChange={(value) => handleOnChange('comment', value)}
            label={t('additionalInformation')}
            type={InputType.textarea}
            rows={4}
          />
        </Col>
      </Row>

      <Index
        visible={showSelectCustomer}
        onClose={() => setShowSelectCustomer(false)}
        onSelect={handleSelectCustomer}
      />
    </Panel>
  );
}
