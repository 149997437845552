import { PositionEntity } from '../../../Globals/Types/OrderTypes';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';

export type ValidatePositionError = { [K in keyof PositionEntity]?: string };
interface Result {
  isValid: boolean;
  errors: ValidatePositionError;
}

/**
 * validatePosition()
 * @param position
 * @param allPositions
 */
export const validatePosition = (position: PositionEntity, allPositions?: PositionEntity[]): Result => {
  let result: Result = {
    isValid: true,
    errors: {} as ValidatePositionError,
  };

  if (!position.caption || position.caption.length <= 0) {
    result = { ...result, isValid: false, errors: { ...result.errors, caption: InternalErrorCodes.FIELD_MISSING } };
  }

  if (allPositions) {
    allPositions.forEach((item) => {
      if (item.positionId !== position.positionId) {
        if (item.caption === position.caption) {
          result = {
            ...result,
            isValid: false,
            errors: { ...result.errors, caption: InternalErrorCodes.POSITION_CAPTION_ALREADY_EXISTING },
          };
        }
      }
    });
  }

  return result;
};
