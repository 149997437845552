import React from 'react';
import { useAppSelector, usePageTitle } from '../../../Globals/Hooks/Hooks';
import Headline from './Components/Headline';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Panel from '../../../Components/Panel';
import CreateOrUpdateModal from '../../../Components/Tasks/CreateOrUpdateModal';
import Index from '../../../Components/Tasks/ListView';
import { useDispatchUserTaskGetList, useDispatchUserTaskUpdate } from '../../../Redux/Actions/User/TaskAction';
import { TaskEntity } from '../../../Globals/Types/Types';
import { serverTimestamp } from 'firebase/firestore';

/**
 * Tasks
 * @constructor
 */
export default function Tasks() {
  const [t] = useTranslation();
  const { order, tasks } = useAppSelector((state) => state.order.selectedOrder);
  const [showCreate, setShowCreate] = React.useState<boolean>(false);
  const [selectedData, setSelectedData] = React.useState<TaskEntity | null>(null);
  const dispatchGetList = useDispatchUserTaskGetList();
  const dispatchUpdate = useDispatchUserTaskUpdate();
  usePageTitle(order ? order.externalId : '');

  const onHandleCheck = (item: TaskEntity, state: boolean) => {
    dispatchUpdate({ ...item, finished: state, finishedDateTime: serverTimestamp() }).then(() => {
      return dispatchGetList();
    });
  };

  const handleSelectTask = (item: TaskEntity) => {
    setSelectedData(item);
    setTimeout(() => setShowCreate(true), 300);
  };

  const handleCloseModal = () => {
    setSelectedData(null);
    setShowCreate(false);
  };

  const renderContent = () => {
    if (tasks.length > 0) {
      return (
        <Panel>
          <Index
            data={tasks}
            onPress={handleSelectTask}
            onCheck={(task) => onHandleCheck(task, true)}
            onUncheck={(task) => onHandleCheck(task, false)}
          />
        </Panel>
      );
    }
    return null;
  };

  return (
    <>
      <Headline order={order} headlineKey="modules.order.tasks" />

      <Panel>
        <div className="d-flex flex-row justify-content-end">
          <div>
            <Button onClick={() => setShowCreate(true)} variant="success">
              <i className="fas fa-plus-circle" style={{ marginRight: 6 }} />
              {t('taskCreate')}
            </Button>
          </div>
        </div>
      </Panel>

      {renderContent()}

      <CreateOrUpdateModal visible={showCreate} onClose={handleCloseModal} order={order} initialData={selectedData} />
    </>
  );
}
