import React from 'react';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import Headline from '../Details/Components/Headline';
import Overview from '../../../Components/Order/Report/Overview';
import Information from '../../../Components/Order/Report/Information';
import { useDispatchOrderServiceReportGetList } from '../../../Redux/Actions/Order/Service/ReportAction';
import { useDispatchOrderServiceSetSelected } from '../../../Redux/Actions/Order/ServiceAction';
import Inventory from '../../../Components/Order/Report/Inventory';
import PreDamage from '../../../Components/Order/Report/PreDamage';
import CompletedImages from '../../../Components/Order/Report/CompletedImages';
import Reclamation from '../../../Components/Order/Report/Reclamation';
import { Col, Row } from 'react-bootstrap';
import Damage from '../../../Components/Order/Report/Damage';
import AdditionalWork from '../../../Components/Order/Report/AdditionalWork';
import Returns from '../../../Components/Order/Report/Returns';
import Signature from '../../../Components/Order/Report/Signature';
import { SignatureType } from '../../../Globals/Types/Report';
import PositionCheck from '../../../Components/Order/Report/PositionCheck';
import IncompleteOverview from '../../../Components/Order/Report/IncompleteOverview';
import ActionButtons from '../../../Components/Order/Report/ActionButtons';
import PaymentBox from '../../../Components/Order/Report/PaymentBox';
import TimeTracking from '../../../Components/Order/Details/Service/TimeTracking';
import { useDispatchOrderServiceTimeTrackingGetList } from '../../../Redux/Actions/Order/Service/TimeTrackingAction';

/**
 * Report()
 * @constructor
 */
export default function Report() {
  const { clientId, orderId, serviceId } = useParams();
  const { order } = useAppSelector((state) => state.order.selectedOrder);
  const { service, report, timeTracking } = useAppSelector((state) => state.order.selectedOrder.selectedService);
  const dispatchGetReport = useDispatchOrderServiceReportGetList();
  const dispatchSetSelectedService = useDispatchOrderServiceSetSelected();
  const dispatchLoadServiceTimeTrackings = useDispatchOrderServiceTimeTrackingGetList();

  React.useEffect(() => {
    if (!service) {
      const found = order.services.find((item) => item.serviceId === serviceId);
      dispatchSetSelectedService(found);
    }
  }, [dispatchSetSelectedService, order.services, service, serviceId]);

  React.useEffect(() => {
    if (!report) {
      dispatchGetReport(clientId, orderId, serviceId).finally(() => {});
    }
  }, [clientId, dispatchGetReport, orderId, report, serviceId]);

  React.useEffect(() => {
    if (order && service) {
      dispatchLoadServiceTimeTrackings(order, service).then(() => {});
    }
  }, [dispatchLoadServiceTimeTrackings, order, service]);

  if (report && service) {
    return (
      <>
        <Headline order={order} headline={service.type.caption} />

        <ActionButtons report={report} />

        <Overview report={report} />
        <IncompleteOverview report={report} />
        <Information report={report} timeTracking={timeTracking} />
        <TimeTracking timeTracking={timeTracking} />
        <Inventory report={report} />

        <PreDamage report={report} />
        <Reclamation report={report} />
        <Damage report={report} />
        <AdditionalWork report={report} />
        <Returns report={report} />
        <PositionCheck report={report} service={service} />
        <PaymentBox report={report} />
        <CompletedImages report={report} />

        <Row>
          <Col xxl={report.customerSignature && report.monteurSignature ? 6 : 12}>
            <Signature
              signature={report.customerSignature}
              type={SignatureType.customer}
              report={report}
              order={order}
            />
          </Col>
          <Col xxl={report.customerSignature && report.monteurSignature ? 6 : 12}>
            <Signature signature={report.monteurSignature} type={SignatureType.monteur} report={report} order={order} />
          </Col>
        </Row>
      </>
    );
  }
  return null;
}
