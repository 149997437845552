import { InvoiceEntity } from '../../../Globals/Types/Invoice';
import moment from 'moment';

/**
 * getInvoiceSalesOverallCurrentMonth()
 * @param invoices
 */
export const getInvoiceSalesOverallCurrentMonth = (invoices: InvoiceEntity[]) => {
  const filtered = invoices.filter(
    (invoice) =>
      moment(invoice.createdDate).isSame(moment(), 'month') && moment(invoice.createdDate).isSame(moment(), 'year'),
  );
  let overall = 0;
  filtered.forEach((invoice) => {
    overall = overall + parseFloat(invoice.priceOverall);
  });

  return overall;
};

/**
 * getInvoiceSalesOverallByUserForCurrentMonth()
 * @param invoices
 * @param userId
 */
export const getInvoiceSalesOverallByUserForCurrentMonth = (invoices: InvoiceEntity[], userId: string) => {
  const filtered = invoices.filter(
    (invoice) =>
      moment(invoice.createdDate).isSame(moment(), 'month') &&
      moment(invoice.createdDate).isSame(moment(), 'year') &&
      userId === invoice.createdUserId,
  );
  let overall = 0;
  filtered.forEach((invoice) => {
    overall = overall + parseFloat(invoice.priceOverall);
  });

  return overall;
};
