export enum UrlPatterns {
  'dispositionWithDate' = '/disposition/{date}',
  'invoiceDetails' = '/invoices/details/{invoiceId}',
  'inboxDetails' = '/inbox/{messageId}',
  'inboxCreate' = '/inbox/create',
  'inboxAnswer' = '/inbox/create?type=answer&reference={messageId}',
  'inboxForward' = '/inbox/create?type=forward&reference={messageId}',
  'orderCreate' = '/createorder',
  'orderDetails' = '/order/c/{clientId}/o/{orderId}/details',
  'orderList' = '/order/list',
  'orderMessages' = '/order/c/{clientId}/o/{orderId}/messages',
  'orderServiceDetails' = '/order/c/{clientId}/o/{orderId}/s/{serviceId}',
  'settingsFacilityDetails' = '/settings/facility/{facilityId}/details',
  'settingsFacilityMailTemplateEdit' = '/settings/facility/{facilityId}/mail/template/{templateName}',
  'settingsResources' = '/settings/resources',
  'settingsResourcesCreateUpdate' = '/settings/resources/{resourceId}',
  'settingsStorageAddress' = '/settings/storage/address',
  'settingsStorageAddressCreateUpdate' = '/settings/storage/address/{storageId}',
  'settingsOrderReportConfig' = '/settings/orders/reportConfig/{serviceTypeKey}',
  'userTasks' = '/profile/tasks',
  'settingsTourTemplates' = '/settings/tour',
  'settingsTourTemplatesCreateOrUpdate' = '/settings/tour/{tourTemplateId}',
  'settingsPositions' = '/settings/positions',
  'settingsPositionsCreateOrUpdate' = '/settings/positions/{positionId}',
  'customers' = '/customer/list',
  'customerDetails' = '/customer/{customerId}/details',
  'customerNotes' = '/customer/{customerId}/contacts',
  'customersCreate' = '/customer/create',
  'customersUpdate' = '/customer/{customerId}/update',
  'customersInvoices' = '/customer/{customerId}/invoices',
  'customersInvoiceCreate' = '/customer/{customerId}/invoices/create',
  'customersVoucherInvoiceCreate' = '/customer/{customerId}/invoices/create/{invoiceId}',
  'customersInvoiceDetails' = '/customer/{customerId}/invoices/{invoiceId}',
  'customersOffers' = '/customer/{customerId}/offers',
  'customersOffersDetails' = '/customer/{customerId}/offers/{offerId}',
  'customersOfferUpdate' = '/customer/{customerId}/offers/update/{offerId}',
}

/**
 * buildUrlOrderDetails()
 * @param clientId
 * @param orderId
 */
export const buildUrlOrderDetails = (clientId: string, orderId: string): string => {
  return UrlPatterns.orderDetails.replace('{clientId}', clientId).replace('{orderId}', orderId);
};

/**
 * buildUrlOrderMessages()
 * @param clientId
 * @param orderId
 */
export const buildUrlOrderMessages = (clientId: string, orderId: string): string => {
  return UrlPatterns.orderMessages.replace('{clientId}', clientId).replace('{orderId}', orderId);
};

/**
 * buildUrlOrderServiceDetails()
 * @param ownerClientId
 * @param orderId
 * @param serviceId
 */
export const buildUrlOrderServiceDetails = (ownerClientId: string, orderId: string, serviceId: string): string => {
  return UrlPatterns.orderServiceDetails
    .replace('{clientId}', ownerClientId)
    .replace('{orderId}', orderId)
    .replace('{serviceId}', serviceId);
};

/**
 * buildUrlSettingsTourTemplatesCreateOrUpdate()
 * @param tourTemplateId
 */
export const buildUrlSettingsTourTemplatesCreateOrUpdate = (tourTemplateId: string) => {
  return UrlPatterns.settingsTourTemplatesCreateOrUpdate.replace('{tourTemplateId}', tourTemplateId);
};

/**
 * buildUrlSettingsResourcesCreateUpdate()
 * @param resourceId
 */
export const buildUrlSettingsResourcesCreateUpdate = (resourceId: string) => {
  return UrlPatterns.settingsResourcesCreateUpdate.replace('{resourceId}', resourceId);
};

/**
 * buildUrlSettingsStorageAddress()
 * @param storageId
 */
export const buildUrlSettingsStorageAddress = (storageId: string) => {
  return UrlPatterns.settingsStorageAddressCreateUpdate.replace('{storageId}', storageId);
};

/**
 * buildUrlDispositionWithDate()
 * @param date
 */
export const buildUrlDispositionWithDate = (date: string) => {
  return UrlPatterns.dispositionWithDate.replace('{date}', date);
};

/**
 * buildUrlSettingsPositionsCreateUpdate()
 * @param positionId
 */
export const buildUrlSettingsPositionsCreateUpdate = (positionId: string) => {
  return UrlPatterns.settingsPositionsCreateOrUpdate.replace('{positionId}', positionId);
};

/**
 * buildUrlCustomersDetails()
 * @param customerId
 */
export const buildUrlCustomersDetails = (customerId: string) => {
  return UrlPatterns.customerDetails.replace('{customerId}', customerId);
};

/**
 * buildUrlCustomersNotes()
 * @param customerId
 */
export const buildUrlCustomersNotes = (customerId: string) => {
  return UrlPatterns.customerNotes.replace('{customerId}', customerId);
};

/**
 * buildUrlCustomersUpdate()
 * @param customerId
 */
export const buildUrlCustomersUpdate = (customerId: string) => {
  return UrlPatterns.customersUpdate.replace('{customerId}', customerId);
};

/**
 * buildUrlCustomersInvoiceCreate()
 * @param customerId
 */
export const buildUrlCustomersInvoiceCreate = (customerId: string) => {
  return UrlPatterns.customersInvoiceCreate.replace('{customerId}', customerId);
};

/**
 * buildUrlCustomersVoucherInvoiceCreate()
 * @param customerId
 * @param invoiceId
 */
export const buildUrlCustomersVoucherInvoiceCreate = (customerId: string, invoiceId: string) => {
  return UrlPatterns.customersVoucherInvoiceCreate
    .replace('{customerId}', customerId)
    .replace('{invoiceId}', invoiceId);
};

/**
 * buildUrlCustomersInvoices()
 * @param customerId
 */
export const buildUrlCustomersInvoices = (customerId: string) => {
  return UrlPatterns.customersInvoices.replace('{customerId}', customerId);
};

/**
 * buildUrlCustomersInvoiceDetails()
 * @param customerId
 * @param invoiceId
 */
export const buildUrlCustomersInvoiceDetails = (customerId: string, invoiceId: string) => {
  return UrlPatterns.customersInvoiceDetails.replace('{customerId}', customerId).replace('{invoiceId}', invoiceId);
};

/**
 * buildUrlSettingsOrderReportConfig()
 * @param serviceTypeKey
 */
export const buildUrlSettingsOrderReportConfig = (serviceTypeKey: string) => {
  return UrlPatterns.settingsOrderReportConfig.replace('{serviceTypeKey}', serviceTypeKey);
};

/**
 * buildUrlSettingsFacilityDetails()
 * @param facilityId
 */
export const buildUrlSettingsFacilityDetails = (facilityId: string) => {
  return UrlPatterns.settingsFacilityDetails.replace('{facilityId}', facilityId);
};

/**
 * buildUrlSettingsFacilityMailTemplateEdit()
 * @param facilityId
 * @param templateName
 */
export const buildUrlSettingsFacilityMailTemplateEdit = (facilityId: string, templateName: string) => {
  return UrlPatterns.settingsFacilityMailTemplateEdit
    .replace('{facilityId}', facilityId)
    .replace('{templateName}', templateName);
};

/**
 * buildUrlInvoiceDetails()
 * @param invoiceId
 */
export const buildUrlInvoiceDetails = (invoiceId: string) => {
  return UrlPatterns.invoiceDetails.replace('{invoiceId}', invoiceId);
};

/**
 * buildUrlInboxDetails()
 * @param messageId
 */
export const buildUrlInboxDetails = (messageId: string) => {
  return UrlPatterns.inboxDetails.replace('{messageId}', messageId);
};

/**
 * buildUrlInboxAnswer()
 * @param messageId
 */
export const buildUrlInboxAnswer = (messageId: string) => {
  return UrlPatterns.inboxAnswer.replace('{messageId}', messageId);
};

/**
 * buildUrlInboxForward()
 * @param messageId
 */
export const buildUrlInboxForward = (messageId: string) => {
  return UrlPatterns.inboxForward.replace('{messageId}', messageId);
};

/**
 * buildUrlCustomersOffers()
 * @param customerId
 */
export const buildUrlCustomersOffers = (customerId: string) => {
  return UrlPatterns.customersOffers.replace('{customerId}', customerId);
};

/**
 * buildUrlCustomersOffersDetails()
 * @param customerId
 * @param offerId
 */
export const buildUrlCustomersOffersDetails = (customerId: string, offerId: string) => {
  return UrlPatterns.customersOffersDetails.replace('{customerId}', customerId).replace('{offerId}', offerId);
};

/**
 * buildUrlCustomersOffersUpdate()
 * @param customerId
 * @param offerId
 */
export const buildUrlCustomersOffersUpdate = (customerId: string, offerId: string) => {
  return UrlPatterns.customersOfferUpdate.replace('{customerId}', customerId).replace('{offerId}', offerId);
};
