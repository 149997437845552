import React from 'react';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import PageHeader from '../../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import Panel from '../../../Components/Panel';
import {
  useDispatchCustomerNoteDelete,
  useDispatchCustomerNotesGetList,
} from '../../../Redux/Actions/Customer/NotesAction';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import CreateOrUpdateModal from '../../../Components/Customer/Notes/CreateOrUpdateModal';
import NotesList from '../../../Components/Customer/Notes/NotesList';
import { CustomerNoteEntity } from '../../../Globals/Types/Customer';
import CustomSkeleton from '../../../Components/CustomSkeleton';

const HintText = styled.div`
  text-align: center;
  color: ${(props) => props.theme.color.textGray};
`;

/**
 * Notes()
 * @constructor
 */
export default function Notes() {
  const { customer, notes } = useAppSelector((state) => state.client.customers.selectedCustomer);
  const [t] = useTranslation();
  const { isLoading, setLoading } = useLoadingModal();
  const [showAdd, setShowAdd] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<CustomerNoteEntity>(null);
  const dispatchGetNotes = useDispatchCustomerNotesGetList();
  const dispatchDelete = useDispatchCustomerNoteDelete();

  React.useEffect(() => {
    if (customer) {
      setLoading(true);
      dispatchGetNotes(customer.customerId).finally(() => setLoading(false));
    }
  }, [customer, dispatchGetNotes, setLoading]);

  const handleDelete = (note: CustomerNoteEntity) => {
    dispatchDelete(customer.customerId, note.noteId).then(() => {});
  };

  const handleSelect = (note: CustomerNoteEntity) => {
    setSelected(note);
    setShowAdd(true);
  };

  const handleCloseAdd = () => {
    setSelected(null);
    setShowAdd(false);
  };

  const renderAddButton = () => {
    return (
      <Button variant="success" onClick={() => setShowAdd(true)} style={{ marginLeft: 10 }}>
        <i className="fas fa-plus-circle" />
      </Button>
    );
  };

  const renderContent = () => {
    if (notes !== null && notes && notes.length === 0) {
      return (
        <Panel>
          <HintText>{t('modules.customers.noNotesAvailable')}</HintText>
        </Panel>
      );
    } else {
      return <NotesList notes={notes} onEdit={handleSelect} onDelete={handleDelete} />;
    }
  };

  const renderLoading = () => {
    if (isLoading && !notes) {
      return (
        <>
          <Panel>
            <CustomSkeleton height={130} style={{ borderRadius: 8 }} />
          </Panel>
          <Panel>
            <CustomSkeleton height={130} style={{ borderRadius: 8 }} />
          </Panel>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader
        headline={t('notes')}
        description={t('modules.customers.notesDescription')}
        actionButtonOne={renderAddButton()}
      />
      {renderLoading()}
      {renderContent()}

      <CreateOrUpdateModal
        initialData={selected}
        visible={showAdd}
        onSave={() => {}}
        onClose={handleCloseAdd}
        customer={customer}
      />
    </>
  );
}
