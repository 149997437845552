import { OrderEntity, OrderMessageEntity } from '../../../Globals/Types/OrderTypes';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import React from 'react';
import {
  ORDER_MESSAGE_CREATE_ERROR,
  ORDER_MESSAGE_CREATE_START,
  ORDER_MESSAGE_CREATE_SUCCESS,
  ORDER_MESSAGE_DELETE_ERROR,
  ORDER_MESSAGE_DELETE_START,
  ORDER_MESSAGE_DELETE_SUCCESS,
  ORDER_MESSAGE_GETLIST_ERROR,
  ORDER_MESSAGE_GETLIST_START,
  ORDER_MESSAGE_GETLIST_SUCCESS,
} from '../../ActionTypes';
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDocs,
  getFirestore,
} from 'firebase/firestore';
import { FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import { useDispatchDocumentDeleteFolder, useDispatchDocumentUpload } from '../GlobalStorageActions';

type OrderMessageGetListReturnType = (order: OrderEntity) => Promise<Array<OrderMessageEntity>>;
/**
 * useDispatchOrderMessageGetList()
 */
export const useDispatchOrderMessageGetList = (): OrderMessageGetListReturnType => {
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.auth);

  return React.useCallback<OrderMessageGetListReturnType>(
    (order) => {
      dispatch({ type: ORDER_MESSAGE_GETLIST_START, payload: order });

      const collectionRef = collection(
        getFirestore(),
        FirebasePathMappings.client,
        order.ownerClientId,
        FirebasePathMappings.order,
        order.orderId,
        FirebasePathMappings.messages,
      ) as CollectionReference<OrderMessageEntity>;

      return getDocs(collectionRef)
        .then((snapShot) => {
          const messages: Array<OrderMessageEntity> = [];
          if (snapShot.size > 0) {
            snapShot.forEach((message) => {
              messages.push({ ...message.data(), messageId: message.id });
            });
          }
          dispatch({ type: ORDER_MESSAGE_GETLIST_SUCCESS, payload: { messages, user } });
          return Promise.resolve(messages);
        })
        .catch((error) => {
          dispatch({ type: ORDER_MESSAGE_GETLIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, user],
  );
};

type OrderMessageCreateReturnType = (
  order: OrderEntity,
  message: OrderMessageEntity,
  file?: File,
) => Promise<OrderMessageEntity>;
/**
 * useDispatchOrderMessageCreate()
 */
export const useDispatchOrderMessageCreate = (): OrderMessageCreateReturnType => {
  const dispatch = useDispatch();
  const dispatchDocumentUpload = useDispatchDocumentUpload();

  return React.useCallback<OrderMessageCreateReturnType>(
    (order, message, file) => {
      dispatch({ type: ORDER_MESSAGE_CREATE_START, payload: { order, message } });

      const collectionRef = collection(
        getFirestore(),
        FirebasePathMappings.client,
        order.ownerClientId,
        FirebasePathMappings.order,
        order.orderId,
        FirebasePathMappings.messages,
      ) as CollectionReference<OrderMessageEntity>;

      return addDoc(collectionRef, message)
        .then((snapShot) => {
          if (message.hasAttachment && file) {
            const path = `/client/${order.ownerClientId}/order/${order.orderId}/messages/${snapShot.id}`;
            return dispatchDocumentUpload(path, file).then(() => {
              dispatch({ type: ORDER_MESSAGE_CREATE_SUCCESS, payload: message });
              return Promise.resolve(message);
            });
          } else {
            dispatch({ type: ORDER_MESSAGE_CREATE_SUCCESS, payload: message });
            return Promise.resolve(message);
          }
        })
        .catch((error) => {
          dispatch({ type: ORDER_MESSAGE_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, dispatchDocumentUpload],
  );
};

type OrderMessageDeleteReturnType = (order: OrderEntity, message: OrderMessageEntity) => Promise<void>;
/**
 * useDispatchOrderMessageDelete()
 */
export const useDispatchOrderMessageDelete = (): OrderMessageDeleteReturnType => {
  const dispatch = useDispatch();
  const dispatchDocumentDelete = useDispatchDocumentDeleteFolder();

  return React.useCallback<OrderMessageDeleteReturnType>(
    (order, message) => {
      dispatch({ type: ORDER_MESSAGE_DELETE_START, payload: { order, message } });

      const docRef = doc(
        getFirestore(),
        FirebasePathMappings.client,
        order.ownerClientId,
        FirebasePathMappings.order,
        order.orderId,
        FirebasePathMappings.messages,
        message.messageId,
      ) as DocumentReference<OrderMessageEntity>;

      return deleteDoc(docRef)
        .then(() => {
          if (message.hasAttachment) {
            const path = `/client/${order.ownerClientId}/order/${order.orderId}/messages/${message.messageId}`;
            return dispatchDocumentDelete(path).then(() => {
              dispatch({ type: ORDER_MESSAGE_DELETE_SUCCESS, payload: message });
              return Promise.resolve();
            });
          } else {
            dispatch({ type: ORDER_MESSAGE_DELETE_SUCCESS, payload: message });
            return Promise.resolve();
          }
        })
        .catch((error) => {
          dispatch({ type: ORDER_MESSAGE_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, dispatchDocumentDelete],
  );
};
