import { Report } from '../../../../Globals/Types/Report';
import React from 'react';
import { useAppDispatch } from '../../../../Globals/Hooks/Hooks';
import {
  ORDER_SERVICE_REPORT_CREATE_PDF_ERROR,
  ORDER_SERVICE_REPORT_CREATE_PDF_START,
  ORDER_SERVICE_REPORT_CREATE_PDF_SUCCESS,
  ORDER_SERVICE_REPORT_GETLIST_ERROR,
  ORDER_SERVICE_REPORT_GETLIST_START,
  ORDER_SERVICE_REPORT_GETLIST_SUCCESS,
} from '../../../ActionTypes';
import { collection, CollectionReference, getDocs, getFirestore } from 'firebase/firestore';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../../../Globals/FirebaseGlobals';
import moment from 'moment';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { OrderEntity, ServiceEntity } from '../../../../Globals/Types/OrderTypes';
import { FacilityEntity } from '../../../../Globals/Types/Types';

/**
 * buildReportCollection()
 * @param clientId
 * @param orderId
 * @param serviceId
 */
const buildReportCollection = (clientId: string, orderId: string, serviceId: string): CollectionReference<Report> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.order,
    orderId,
    FirebasePathMappings.service,
    serviceId,
    FirebasePathMappings.report,
  ) as CollectionReference<Report>;
};

/**
 * useDispatchOrderServiceReportGetList()
 */
type ReportGetListReturnType = (clientId: string, orderId: string, serviceId: string) => Promise<Report>;
export const useDispatchOrderServiceReportGetList = (): ReportGetListReturnType => {
  const dispatch = useAppDispatch();

  return React.useCallback<ReportGetListReturnType>(
    (clientId: string, orderId: string, serviceId: string) => {
      dispatch({ type: ORDER_SERVICE_REPORT_GETLIST_START, payload: { clientId, orderId, serviceId } });

      const collectionRef = buildReportCollection(clientId, orderId, serviceId);

      return getDocs(collectionRef)
        .then((snapShot) => {
          if (snapShot.size > 0) {
            const reports: Report[] = [];
            snapShot.forEach((report) => {
              if (report.id !== 'error' && report.id !== 'errorPdf') {
                reports.push({ ...report.data(), reportId: report.id });
              }
            });

            const sortedByEndDate = reports.sort((a, b) =>
              moment(a.endDateTime).isSameOrAfter(moment(b.endDateTime)) ? 1 : -1,
            );
            dispatch({ type: ORDER_SERVICE_REPORT_GETLIST_SUCCESS, payload: sortedByEndDate[0] });
            return Promise.resolve(sortedByEndDate[0]);
          }

          return Promise.reject([]);
        })
        .catch((error) => {
          dispatch({ type: ORDER_SERVICE_REPORT_GETLIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * Just recreate the report pdf for the given service
 * useDispatchReportCreatePdf()
 */
type ReportCreatePdfReturnType = (
  clientId: string,
  orderId: string,
  serviceId: string,
  reportId: string,
) => Promise<any>;
export const useDispatchReportCreatePdf = (): ReportCreatePdfReturnType => {
  const dispatch = useAppDispatch();

  return React.useCallback<ReportCreatePdfReturnType>(
    (clientId, orderId, serviceId, reportId) => {
      dispatch({ type: ORDER_SERVICE_REPORT_CREATE_PDF_START, payload: { clientId, orderId, serviceId, reportId } });

      const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.reportCreatePdf);
      return callable({ clientId, orderId, serviceId, reportId })
        .then((response) => {
          dispatch({ type: ORDER_SERVICE_REPORT_CREATE_PDF_SUCCESS, payload: response.data });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({ type: ORDER_SERVICE_REPORT_CREATE_PDF_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchReportGetWithFunction()
 * Get the report by calling a function. Normally used to load information for unauthorized users!
 * In this case the report is loaded for the public report page
 */
type ReportGetWithFunctionType = (
  clientId: string,
  orderId: string,
  serviceId: string,
) => Promise<{ order: OrderEntity; service: ServiceEntity; report: Report; facility: FacilityEntity }>;
export const useDispatchReportGetWithFunction = (): ReportGetWithFunctionType => {
  return React.useCallback<ReportGetWithFunctionType>((clientId, orderId, serviceId) => {
    const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.reportGet);

    return callable({ clientId, orderId, serviceId })
      .then((response) => {
        return Promise.resolve({ ...(response.data as any) });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }, []);
};
