import React, { ReactElement } from 'react';
import styled from 'styled-components';

type PageHeaderProps = {
  headline: string;
  icon?: string;
  description: string | React.ReactElement;
  withoutPadding?: boolean;
  containerStyle?: object;
  actionButtonOne?: JSX.Element | null | ReactElement;
  actionButtonTwo?: JSX.Element | null | ReactElement;
  actionButtonThree?: JSX.Element | null | ReactElement;
};

const Container = styled.div<{ $withoutPadding: boolean }>`
  display: flex;
  flex-direction: row;
  border-radius: ${(props) => props.theme.border.radius};
  ${(props) => props.theme.boxShadowDark};
  padding: ${(props) => (props.$withoutPadding ? '0' : '30px 40px')};
  margin-bottom: 20px;
  background-color: #ffffff;

  p {
    margin-bottom: 0;
  }

  h1 {
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 30px;
    line-height: 40px;
    color: ${(props) => props.theme.color.primaryColor};
    font-family: 'DIN Next LT W01 Bold', sans-serif;
    -moz-text-size-adjust: auto;
    margin: 0;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: 50px;

  button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

/**
 * PageHeader()
 * @param props
 * @constructor
 */
export default function PageHeader(props: PageHeaderProps) {
  const renderHeadline = () => {
    if (props.headline) {
      return <h1>{props.headline}</h1>;
    }
    return null;
  };

  const renderDescription = () => {
    if (props.description) {
      if (React.isValidElement(props.description)) {
        return props.description;
      } else {
        return <p>{props.description}</p>;
      }
    }
    return null;
  };

  const renderActionButtons = () => {
    if (props.actionButtonOne || props.actionButtonTwo || props.actionButtonThree) {
      return (
        <RightContainer>
          {props.actionButtonOne}
          {props.actionButtonTwo}
          {props.actionButtonThree}
        </RightContainer>
      );
    }
    return null;
  };

  return (
    <Container $withoutPadding={props.withoutPadding} style={props.containerStyle}>
      <LeftContainer>
        {renderHeadline()}
        {renderDescription()}
      </LeftContainer>
      {renderActionButtons()}
    </Container>
  );
}

PageHeader.defaultProps = {
  maxWidth: null,
  icon: null,
  containerStyle: {},
  withoutPadding: false,
  headline: null,
  description: '',
  actionButtonOne: null,
  actionButtonTwo: null,
  actionButtonThree: null,
};
