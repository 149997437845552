import { TourTemplateEntity } from '../../../Globals/Types/Types';
import { CreateOrUpdateError } from './CreateOrUpdate';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';

/**
 * isValid()
 * @param tourTemplate
 */
export default function isValid(tourTemplate: TourTemplateEntity) {
  const errors: CreateOrUpdateError = {} as CreateOrUpdateError;

  if (!tourTemplate.name || tourTemplate.name.length === 0) {
    errors.name = InternalErrorCodes.FIELD_MISSING;
  }

  if (!tourTemplate.facilityIds || tourTemplate.facilityIds.length < 1) {
    errors.facilityIds = InternalErrorCodes.FIELD_MISSING;
  }

  return { errors, isValid: Object.keys(errors).length === 0 };
}
