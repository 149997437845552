import React from 'react';
import CustomSwitch from './CustomSwitch';
import { Alert, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../Globals/Hooks/Hooks';
import { FacilityEntity } from '../Globals/Types/Types';
import Panel from './Panel';

type FacilitiesFormProps = {
  description: string;
  hasError: boolean;
  onChange: (value: boolean, facilityEntity: FacilityEntity) => void;
  userDefaultFacility: string;
  userFacilities: string[];
};

/**
 * FacilitiesForm
 * @param props
 * @constructor
 */
export default function FacilitiesForm(props: FacilitiesFormProps) {
  const { description, hasError, onChange, userDefaultFacility, userFacilities } = props;
  const [t] = useTranslation();
  const { facilities } = useAppSelector((state) => state.client);

  const renderGroups = () => {
    return facilities.map((item) => (
      <Col xl={12} key={item.facilityId}>
        <CustomSwitch
          key={item.facilityId}
          onChange={(value) => onChange(value, item)}
          checked={(userFacilities && userFacilities.indexOf(item.facilityId) >= 0) || false}
          caption={item.name}
          disabled={userDefaultFacility === item.facilityId}
        />
      </Col>
    ));
  };

  const renderError = () => {
    if (hasError) {
      return (
        <Alert variant="danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          {t('modules.settings.facilityFormErrorNonSelected')}
        </Alert>
      );
    }
    return null;
  };

  if (facilities && facilities.length > 1) {
    return (
      <Panel headline={t('facilities')} description={description}>
        <>
          {renderError()}
          <Row>{renderGroups()}</Row>
        </>
      </Panel>
    );
  }
  return null;
}

FacilitiesForm.defaultProps = {
  hasError: false,
};
