import React from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '../../../../../Components/Panel';
import { FacilityPartnerEntity } from '../../../../../Globals/Types/Types';
import { Button } from 'react-bootstrap';

type Props = {
  onRequest: () => void;
  partners: Array<FacilityPartnerEntity>;
};

/**
 * NoPartnersAssigned
 * @constructor
 */
export default function NoPartnersAssigned(props: Props) {
  const { onRequest, partners } = props;
  const [t] = useTranslation();

  if (!partners || partners.length === 0) {
    return (
      <Panel headline={t('modules.settings.facility.noPartnersHeadline')} headlineCentered>
        <p style={{ textAlign: 'center' }}>{t('modules.settings.facility.noPartnersDescription')}</p>

        <p style={{ textAlign: 'center', marginTop: 30 }}>
          <Button variant="success" onClick={onRequest}>
            {t('requestPartner')}
          </Button>
        </p>
      </Panel>
    );
  }
  return null;
}
