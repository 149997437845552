/**
 * This is the configuration file for environment depending on settings for the project!
 * @type {{domain: string, apiGateway: {url: string}, env: string}}
 */
import { Environment } from './Types/Enums';

const dev = {
  env: Environment.dev,
  domain: 'http://localhost:3000',
  localhostIp: '192.168.1.52',
  google: {
    apiKey: 'AIzaSyAISzej2mBC65NK9HFynagIPmP4D9TfiOI',
    geoCodingUrl: 'https://maps.googleapis.com/maps/api/geocode/json?key={{apiKey}}&address={{address}}',
  },
  whatsAppUrl: 'https://api.whatsapp.com/message/PZMZGGEULDBPJ1?autoload=1&app_absent=0',
  firebase: {
    region: 'europe-west3',
    config: {
      apiKey: 'AIzaSyAj1U7ZZQUkeiFNJi2YVzr9DPa2ajgMfrc',
      authDomain: 'sotosoft-app.firebaseapp.com',
      projectId: 'sotosoft-app',
      storageBucket: 'sotosoft-app.appspot.com',
      messagingSenderId: '836499523428',
      appId: '1:836499523428:web:a0effc8ad749437c959c71',
      measurementId: 'G-E4LV5G8JKH',
    },
  },
};

const staging = {
  env: Environment.staging,
  domain: 'https://staging.sotosoft.app',
  localhostIp: null,
  google: {
    apiKey: 'AIzaSyAISzej2mBC65NK9HFynagIPmP4D9TfiOI',
    geoCodingUrl: 'https://maps.googleapis.com/maps/api/geocode/json?key={{apiKey}}&address={{address}}',
  },
  whatsAppUrl: 'https://api.whatsapp.com/message/PZMZGGEULDBPJ1?autoload=1&app_absent=0',
  firebase: {
    region: 'europe-west3',
    config: {
      apiKey: 'AIzaSyBOe_BNt7zGGHLCn8crTnSU9lBsRJVTRGo',
      authDomain: 'sotosoft-app-staging.firebaseapp.com',
      projectId: 'sotosoft-app-staging',
      storageBucket: 'sotosoft-app-staging.appspot.com',
      messagingSenderId: '418335248882',
      appId: '1:418335248882:web:5f731691e8c7af9e1bdb6b',
      measurementId: 'G-XMN6LZHPX5',
    },
  },
};

const production = {
  env: Environment.production,
  domain: 'https://www.sotosoft.app',
  localhostIp: null,
  google: {
    apiKey: 'AIzaSyAISzej2mBC65NK9HFynagIPmP4D9TfiOI',
    geoCodingUrl: 'https://maps.googleapis.com/maps/api/geocode/json?key={{apiKey}}&address={{address}}',
  },
  whatsAppUrl: 'https://api.whatsapp.com/message/PZMZGGEULDBPJ1?autoload=1&app_absent=0',
  firebase: {
    region: 'europe-west3',
    config: {
      apiKey: 'AIzaSyAj1U7ZZQUkeiFNJi2YVzr9DPa2ajgMfrc',
      authDomain: 'sotosoft-app.firebaseapp.com',
      projectId: 'sotosoft-app',
      storageBucket: 'sotosoft-app.appspot.com',
      messagingSenderId: '836499523428',
      appId: '1:836499523428:web:a0effc8ad749437c959c71',
      measurementId: 'G-E4LV5G8JKH',
    },
  },
};

let config = production;
if (process.env.REACT_APP_STAGE === Environment.dev) {
  config = dev;
} else if (process.env.REACT_APP_STAGE === Environment.staging) {
  config = staging;
}

export default { ...config };
