import React from 'react';
import { Salutation } from '../../../Globals/Types/Enums';
import { CustomerEntity } from '../../../Globals/Types/Customer';

interface Props {
  item: CustomerEntity;
}

/**
 * FirstnameLastnameCompanyCol
 * @constructor
 */
export default function FirstnameLastnameCompanyCol(props: Props) {
  const { item } = props;

  const renderText = () => {
    if (item.salutation === Salutation.company) {
      return item.companyName;
    } else {
      return `${item.lastName}, ${item.firstName}`;
    }
  };

  return <td key={`${item.customerId}-name`}>{renderText()}</td>;
}
