import React from 'react';
import PageHeader from '../../../Components/PageHeader';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { buildUrlSettingsPositionsCreateUpdate } from '../../../Globals/UrlFunctions';
import { useNavigate } from 'react-router';
import { useDispatchPositionGetList } from '../../../Redux/Actions/Client/PositionAction';
import Table, { TableFieldProps } from '../../../Components/Table/Table';
import Panel from '../../../Components/Panel';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { PositionEntity } from '../../../Globals/Types/OrderTypes';
import PositionCaptionCol from '../../../Components/Table/CustomCols/PositionCaptionCol';
import PositionPriceCol from '../../../Components/Table/CustomCols/PositionPriceCol';
import PositionAssemblyTimeCol from '../../../Components/Table/CustomCols/PositionAssemblyTimeCol';

const tableMappings: TableFieldProps<PositionEntity>[] = [
  { key: 'caption', headline: 'name', customColElement: PositionCaptionCol },
  { key: 'price', headline: 'priceNetto', customColElement: PositionPriceCol },
  { key: 'assemblyTime', headline: 'estimatedAssemblyTimeInMinutes', customColElement: PositionAssemblyTimeCol },
];

/**
 * Overview
 * @constructor
 */
export default function Overview() {
  const { positions } = useAppSelector((state) => state.client);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatchGetList = useDispatchPositionGetList();
  const { isLoading, setLoading } = useLoadingModal();
  const [init, setInit] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(!init);
    dispatchGetList().finally(() => {
      setInit(true);
      setLoading(false);
    });
  }, [dispatchGetList, init, setLoading]);

  const renderAddButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => navigate(buildUrlSettingsPositionsCreateUpdate('create'))}>
        <i className="fas fa-plus-circle" />
      </Button>
    );
  };

  return (
    <>
      <PageHeader headline={t('positionManagement')} actionButtonOne={renderAddButton()} />

      <Panel>
        <Table
          mappings={tableMappings}
          data={positions}
          keyField="positionId"
          isLoading={isLoading}
          onClickRow={(item) => navigate(buildUrlSettingsPositionsCreateUpdate(item.positionId))}
        />
      </Panel>
    </>
  );
}
