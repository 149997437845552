import React from 'react';
import CollapsableContainer from './CollapsableContainer';
import { DispositionListContext } from '../index';
import { PickedUserEntity, UserEntity } from '../../../../Globals/Types/Types';
import { useDrop } from 'react-dnd';
import { DragDropType } from '../../../../Globals/Types/DispositionTypes';
import Badge from './Badge';
import styled, { useTheme } from 'styled-components';
import { Tour } from '../../../../Globals/Types/Tour';
import { userDisplayName } from '../../../../Globals/Functions';
import AskDeleteModal from '../../../Modals/AskDeleteModal';
import { useTranslation } from 'react-i18next';

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  users?: PickedUserEntity[];
  children?: React.ReactElement | string;
  onAdd: (item: UserEntity) => void;
  onDelete: (item: PickedUserEntity) => void;
  tour: Tour;
  onClick?: () => void;
}

/**
 * UserColumn
 * @param props
 * @constructor
 */
export default function UserColumn(props: Props) {
  const { children, onAdd, users, onDelete, tour, onClick } = props;
  const [t] = useTranslation();
  const [toDelete, setToDelete] = React.useState<PickedUserEntity>(null);
  const context = React.useContext(DispositionListContext);
  const theme = useTheme();

  const [{ isOver, canDrop }, drop] = useDrop<UserEntity, any, any>(
    () => ({
      accept: DragDropType.USER,
      canDrop: (item, monitor) => monitor.getItemType() === DragDropType.USER,
      drop: (item) => onAdd(item),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [tour],
  );

  const handleOnDelete = () => {
    onDelete(toDelete);
    setToDelete(null);
  };

  const renderContent = () => {
    if (children) {
      return children;
    }
    if (users && users.length > 0) {
      return (
        <Inner>
          {users.map((user) => (
            <Badge
              key={user.userId}
              caption={user.initials}
              onPress={!tour.closed ? () => setToDelete(user) : () => {}}
              fontColor={theme.color.text}
            />
          ))}
        </Inner>
      );
    }
    return null;
  };

  const getUserCountIcon = () => {
    if (users) {
      switch (users.length) {
        case 0:
          return 'fas fa-circle-0 Red';
        case 1:
          return 'fas fa-circle-1 Green';
        case 2:
          return 'fas fa-circle-2 Green';
        case 3:
          return 'fas fa-circle-3 Green';
        case 4:
          return 'fas fa-circle-4 Green';
      }
    }
    return 'fas fa-circle-0 Red';
  };

  return (
    <CollapsableContainer
      forwardRef={!children && !tour.closed ? drop : null}
      width={context.resourceWidth}
      isDragOver={!children && !tour.closed ? isOver : false}
      isCanDrop={!children && !tour.closed ? canDrop : false}
      onClick={onClick}
      collapsed={context.collapsedUsers}
      collapsedIcon={children ? 'fas fa-user' : getUserCountIcon()}
    >
      <>
        {renderContent()}

        <AskDeleteModal
          onDelete={handleOnDelete}
          onClose={() => setToDelete(null)}
          visible={!!toDelete}
          headline={t('modules.disposition.delete.headline', { type: t('groupData.monteur') })}
          description={t('modules.disposition.delete.description', {
            name: toDelete ? userDisplayName(toDelete, true) : null,
            nameTour: tour ? tour.name : null,
          })}
          confirmButtonText={t('delete')}
        />
      </>
    </CollapsableContainer>
  );
}
