import React from 'react';
import Select, { SelectOptions } from './Inputs/Select';
import { useTranslation } from 'react-i18next';
import { ResourceEntityTypes } from '../Globals/Types/Types';
import Lodash from 'lodash';

interface Props {
  disabled?: boolean;
  hasError: boolean;
  initialValue: ResourceEntityTypes;
  onChange: (value: ResourceEntityTypes) => void;
  required?: boolean;
}

/**
 * SelectResourceType()
 * @constructor
 */
export default function SelectResourceType(props: Props) {
  const { disabled, hasError, initialValue, onChange, required } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>(null);

  React.useEffect(() => {
    const tempOptions: SelectOptions = [];
    Object.values(ResourceEntityTypes).forEach((item) => {
      tempOptions.push({ value: item, label: t(`modules.settings.resources.type.${item.toLowerCase()}`) });
    });
    setOptions(Lodash.sortBy(tempOptions, (option) => option.label));
  }, [setOptions, t]);

  if (options) {
    return (
      <Select
        initialValue={initialValue}
        onChange={onChange}
        label={t('type')}
        options={options}
        hasError={hasError}
        required={required}
        disabled={disabled}
      />
    );
  }

  return null;
}
