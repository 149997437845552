import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../Components/PageHeader';
import { Button } from 'react-bootstrap';
import CreateOrUpdateModal from '../../Components/Tasks/CreateOrUpdateModal';
import { useDispatchUserTaskGetList, useDispatchUserTaskUpdate } from '../../Redux/Actions/User/TaskAction';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import Panel from '../../Components/Panel';
import ListView from '../../Components/Tasks/ListView';
import { TaskEntity } from '../../Globals/Types/Types';
import { serverTimestamp } from 'firebase/firestore';
import Text from '../../Components/Text';

/**
 * Tasks()
 * @constructor
 */
export default function Tasks() {
  const [t] = useTranslation();
  const { tasks } = useAppSelector((state) => state.user);
  const [showCreate, setShowCreate] = React.useState<boolean>(false);
  const [showFinished, setShowFinished] = React.useState<boolean>(false);
  const [selectedData, setSelectedData] = React.useState<TaskEntity | null>(null);
  const [filtered, setFiltered] = React.useState<TaskEntity[]>([]);
  const dispatchGetList = useDispatchUserTaskGetList();
  const dispatchUpdate = useDispatchUserTaskUpdate();

  React.useEffect(() => {
    dispatchGetList().then(() => {});
  }, [dispatchGetList]);

  React.useEffect(() => {
    if (tasks) {
      if (!showFinished) {
        setFiltered(tasks.filter((item) => !item.finished));
      } else {
        setFiltered(tasks);
      }
    }
  }, [showFinished, tasks]);

  const onHandleCheck = (item: TaskEntity, state: boolean) => {
    dispatchUpdate({ ...item, finished: state, finishedDateTime: serverTimestamp() }).then(() => {
      return dispatchGetList();
    });
  };

  const handleSelectTask = (item: TaskEntity) => {
    setSelectedData(item);
    setTimeout(() => setShowCreate(true), 300);
  };

  const handleCloseModal = () => {
    setSelectedData(null);
    setShowCreate(false);
  };

  const renderAddButton = () => {
    return (
      <Button variant="success" onClick={() => setShowCreate(true)}>
        <i className="fas fa-plus" />
      </Button>
    );
  };

  const renderToggleFinishedButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => setShowFinished(!showFinished)}>
        <i className={showFinished ? 'fas fa-eye-slash' : 'fas fa-eye'} />
      </Button>
    );
  };

  const renderContent = () => {
    if (tasks && tasks.length > 0) {
      return (
        <ListView
          data={filtered}
          onPress={handleSelectTask}
          onCheck={(item) => onHandleCheck(item, true)}
          onUncheck={(item) => onHandleCheck(item, false)}
        />
      );
    }
    return (
      <Text light centered>
        {t('noTasksAvailable')}
      </Text>
    );
  };

  return (
    <>
      <PageHeader
        headline={t('tasks')}
        actionButtonTwo={renderAddButton()}
        actionButtonOne={renderToggleFinishedButton()}
      />

      <Panel>{renderContent()}</Panel>

      <CreateOrUpdateModal visible={showCreate} initialData={selectedData} onClose={handleCloseModal} />
    </>
  );
}
