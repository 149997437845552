import React from 'react';
import BackgroundContainer from '../../../Components/Website/BackgroundContainer';
import styled from 'styled-components';
import RegisterRow from '../../../Components/Website/RegisterRow';
import InfoBox from '../../../Components/Website/InfoBox';
import AppScreenshots from '../../../Components/Website/AppScreenshots';
import ShortInformationBoxes from '../../../Components/Website/ShortInformationBoxes';
import Price from '../../../Components/Website/Price';
import LanguageRow from '../../../Components/Website/LanguageRow';
import PresentationRow from '../../../Components/Website/PresentationRow';
import TeaserRow from '../../../Components/Website/TeaserRow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * Startpage
 * @constructor
 */
export default function Startpage() {
  return (
    <Container>
      <BackgroundContainer />
      <RegisterRow />
      <InfoBox />
      <TeaserRow />
      <ShortInformationBoxes />
      <RegisterRow />
      <PresentationRow />
      <AppScreenshots />
      <LanguageRow />
      <Price />
      <RegisterRow />
    </Container>
  );
}
