import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import {
  CUSTOMER_INVOICES_GETLIST_ERROR,
  CUSTOMER_INVOICES_GETLIST_START,
  CUSTOMER_INVOICES_GETLIST_SUCCESS,
} from '../../ActionTypes';
import { InvoiceEntity } from '../../../Globals/Types/Invoice';
import { useDispatchInvoiceGetList } from '../Client/InvoiceAction';

/**
 * useDispatchCustomerInvoicesGetList()
 */
type GetListReturnType = (customerId: string) => Promise<InvoiceEntity[]>;
export const useDispatchCustomerInvoicesGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { invoices } = useAppSelector((state) => state.client);
  const dispatchInvoicesGetList = useDispatchInvoiceGetList();

  return React.useCallback<GetListReturnType>(
    (customerId) => {
      dispatch({ type: CUSTOMER_INVOICES_GETLIST_START, payload: customerId });

      return new Promise((resolve, reject) => {
        if (!invoices.all || invoices.all.length <= 0) {
          return dispatchInvoicesGetList()
            .then((invoicesResponse) => {
              const filtered = invoicesResponse.filter((invoice) => invoice.customerId === customerId);
              dispatch({ type: CUSTOMER_INVOICES_GETLIST_SUCCESS, payload: { customerId, invoices: filtered } });
              return resolve(filtered);
            })
            .catch((error) => {
              dispatch({ type: CUSTOMER_INVOICES_GETLIST_ERROR, payload: error });
              return reject(error);
            });
        } else {
          const filtered = invoices.all.filter((invoice) => invoice.customerId === customerId);
          dispatch({ type: CUSTOMER_INVOICES_GETLIST_SUCCESS, payload: { customerId, invoices: filtered } });
          return resolve(filtered);
        }
      });
    },
    [dispatch, dispatchInvoicesGetList, invoices],
  );
};
