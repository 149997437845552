import React, { ForwardedRef, PropsWithChildren } from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import moment from 'moment';
import { getSelectedLanguage } from '../../../Globals/Functions/LocalizationFunctions';

const Container = styled.div`
  display: flex;
  font-size: 20px;
  padding: 15px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.color.primaryColor};

  &:hover {
    background-color: ${(props) => props.theme.color.primaryColor};
    color: #ffffff;
  }
`;

const CustomDateContainer = styled.div`
  cursor: pointer;

  i {
    margin-right: 10px;

    &:last-child {
      margin-left: 10px;
    }
  }
`;

interface Props {
  onChange: (date: string) => void;
  date: string;
}

/**
 * SelectDay()
 * @param props
 * @constructor
 */
export default function SelectDay(props: Props) {
  const { date, onChange } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  // eslint-disable-next-line react/prop-types
  const CustomDateInput = React.forwardRef(
    (props: PropsWithChildren<{ value: string; onClick?: () => void }>, ref: ForwardedRef<HTMLDivElement>) => (
      <CustomDateContainer onClick={props.onClick} ref={ref}>
        <i className="fas fa-calendar-alt" />
      </CustomDateContainer>
    ),
  );

  return (
    <Container onClick={() => setOpen(!open)}>
      <ReactDatePicker
        selected={moment(date).toDate()}
        onChange={(value) => onChange(moment(value).format('YYYY-MM-DD'))}
        locale={getSelectedLanguage(true)}
        showWeekNumbers
        onChangeRaw={(e) => e.preventDefault()}
        customInput={<CustomDateInput value={date} />}
      />
    </Container>
  );
}
