import React from 'react';
import styled from 'styled-components';
import { Tour, TourItem, TourService } from '../../../Globals/Types/Tour';
import { useTranslation } from 'react-i18next';
import TimeHandler from './TimeHandler';
import { Button } from 'react-bootstrap';
import { ElasticSearchServiceInterface } from '../../../Globals/Types/OrderTypes';
import DisplayText from '../../View/DisplayText';
import { useDispatchTourUpdate } from '../../../Redux/Actions/Disposition/TourActions';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';

const Container = styled.div`
  padding: 10px;

  .Headline {
    color: ${(props) => props.theme.color.primaryColor};
    margin-bottom: 20px;
  }
`;

interface Props {
  visible: boolean;
  tour: Tour;
  tourItem: TourItem<TourService>;
  onClose: () => void;
  service: ElasticSearchServiceInterface;
}

export interface State {
  startTime: string;
  endTime: string;
  lastSelectedServiceId: string;
  init: boolean;
  hasChanges: boolean;
}

type Action =
  | { type: 'startTime'; payload: string }
  | { type: 'endTime'; payload: string }
  | { type: 'reset' }
  | { type: 'init'; payload: TourItem<TourService> };

const initialState: State = {
  startTime: null,
  endTime: null,
  lastSelectedServiceId: null,
  init: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'startTime':
      return { ...state, startTime: action.payload, hasChanges: true };
    case 'endTime':
      return { ...state, endTime: action.payload, hasChanges: true };
    case 'reset':
      return { ...initialState };
    case 'init':
      return {
        startTime: action.payload.startTime,
        endTime: action.payload.endTime,
        lastSelectedServiceId: action.payload.data.serviceId,
        hasChanges: false,
        init: true,
      };
  }
};

/**
 * EditTourPanel()
 * @param props
 * @constructor
 */
export default function EditTourPanel(props: Props) {
  const { visible, tourItem, onClose, tour, service } = props;
  const [t] = useTranslation();
  const { setLoading, loadingModal } = useLoadingModal({ delayStart: 1000 });
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const dispatchTourUpdate = useDispatchTourUpdate();

  const handleOnClose = React.useCallback(() => {
    dispatch({ type: 'reset' });
    onClose();
  }, [onClose]);

  const handleUpdate = React.useCallback(() => {
    setLoading(true);
    const mergedTourItem: TourItem<TourService> = { ...tourItem, startTime: state.startTime, endTime: state.endTime };
    const mergedTour: Tour = {
      ...tour,
      items: tour.items.map((item: TourItem<TourService>) =>
        item.data.serviceId === tourItem.data.serviceId ? mergedTourItem : item,
      ),
    };
    dispatchTourUpdate(mergedTour).finally(() => {
      setLoading(false);
      handleOnClose();
    });
  }, [dispatchTourUpdate, handleOnClose, setLoading, state.endTime, state.startTime, tour, tourItem]);

  React.useEffect(() => {
    if ((!state.init && tourItem) || tourItem.data.serviceId !== state.lastSelectedServiceId) {
      dispatch({ type: 'init', payload: tourItem });
    }
  }, [state, tourItem]);

  if (visible && service) {
    return (
      <DisplayText containerStyle={{ marginTop: 40 }}>
        <Container>
          <div className="Headline">{t('modules.disposition.editTourItem')}</div>

          <TimeHandler
            tourItem={tourItem}
            service={service}
            startTime={state.startTime}
            endTime={state.endTime}
            onChangeEnd={(time) => dispatch({ type: 'endTime', payload: time })}
            onChangeStart={(time) => dispatch({ type: 'startTime', payload: time })}
          />

          <div className="float-end" style={{ marginTop: 10, marginBottom: 10 }}>
            <Button
              style={{ marginRight: 10 }}
              onClick={handleUpdate}
              variant="success"
              size="sm"
              disabled={!state.hasChanges}
            >
              {t('save')}
            </Button>
            <Button variant="outline-secondary" onClick={handleOnClose} size="sm">
              {t('cancel')}
            </Button>
          </div>
        </Container>

        {loadingModal}
      </DisplayText>
    );
  }
  return null;
}
