import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';

interface Props {
  hasError?: boolean;
  initialValue: number;
  marginBottom?: number;
  onChange: (value: number) => void;
  required: boolean;
  disabled: boolean;
}

/**
 * SelectPublicLinkValidDays
 * @param props
 * @constructor
 */
export default function SelectPublicLinkValidDays(props: Props) {
  const { hasError, initialValue, marginBottom, onChange, required, disabled } = props;
  const [options, setOptions] = React.useState<SelectOptions>(null);
  const [t] = useTranslation();

  React.useEffect(() => {
    const optionsTemp: SelectOptions = [
      { label: t('xDay', { count: 1 }), value: 1 },
      { label: t('xDay', { count: 2 }), value: 2 },
      { label: t('xDay', { count: 3 }), value: 3 },
      { label: t('xDay', { count: 4 }), value: 4 },
      { label: t('xDay', { count: 5 }), value: 5 },
      { label: t('xDay', { count: 6 }), value: 6 },
      { label: t('xWeek', { count: 1 }), value: 7 },
      { label: t('xWeek', { count: 2 }), value: 14 },
      { label: t('xWeek', { count: 3 }), value: 21 },
      { label: t('xMonth', { count: 1 }), value: 30 },
      { label: t('xMonth', { count: 2 }), value: 60 },
      { label: t('xMonth', { count: 3 }), value: 90 },
      { label: t('xMonth', { count: 4 }), value: 120 },
      { label: t('xMonth', { count: 5 }), value: 150 },
      { label: t('xMonth', { count: 6 }), value: 180 },
    ];

    setOptions(optionsTemp);
  }, [t]);

  if (options) {
    return (
      <Select
        required={required}
        label={t('publicLinkValid')}
        onChange={onChange}
        initialValue={initialValue}
        options={options}
        hasError={hasError}
        marginBottom={marginBottom}
        disabled={disabled}
      />
    );
  }

  return null;
}

SelectPublicLinkValidDays.defaultProps = {
  required: false,
  disabled: false,
};
