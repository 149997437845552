import React from 'react';
import { PositionEntity } from '../../../Globals/Types/OrderTypes';

interface Props {
  item: PositionEntity;
}

/**
 * PositionCaptionCol
 * @constructor
 */
export default function PositionCaptionCol(props: Props) {
  const { item } = props;

  return <td key={`${item.positionId}-nameCol`}>{`${item.amount}x ${item.caption}`}</td>;
}
