import { ReportState } from '../Types/Report';
import { ElasticSearchServiceEntity, PositionEntity, ServiceEntity } from '../Types/OrderTypes';
import { DefaultTheme } from 'styled-components';
import { useGetServiceTypeIcon } from '../Hooks/ServiceHooks';
import React from 'react';
import { Address } from '../Types/Types';
import Lodash from 'lodash';

type GetServiceIconByReportStateType = (service: ElasticSearchServiceEntity | ServiceEntity) => string;
/**
 * getServiceIconByReportState()
 */
export const useGetServiceIconByReportState = (): GetServiceIconByReportStateType => {
  const getServiceTypeIcon = useGetServiceTypeIcon();

  return React.useCallback<GetServiceIconByReportStateType>(
    (service: ElasticSearchServiceEntity | ServiceEntity) => {
      switch (service.reportState) {
        case undefined:
        case ReportState.none:
          return getServiceTypeIcon(service['serviceType'] || service['type']);
        case ReportState.complete:
          return 'fad fa-check-circle';
        case ReportState.incomplete:
          return 'fas fa-octagon-exclamation';
      }
    },
    [getServiceTypeIcon],
  );
};

/**
 * getServiceIconColorByReportState()
 * @param service
 * @param theme
 */
export const getServiceIconColorByReportState = (
  service: ElasticSearchServiceEntity | ServiceEntity,
  theme: DefaultTheme,
): string => {
  switch (service.reportState) {
    case ReportState.complete:
      return theme.color.green;
    case ReportState.incomplete:
      return theme.color.orange;
    default:
      return null;
  }
};

/**
 * getPredefinedDeliveryAddressFromPosition()
 * Search for special extra data fields inside the position to build a delivery address. Will be used on order create
 * to add a delivery address in a quick and easy way
 * @param position
 */
export const getPredefinedDeliveryAddressFromPosition = (position: PositionEntity): Address | null => {
  if (position.externalData && position.externalData.length > 0) {
    if (
      Lodash.findIndex(position.externalData, (item) => item.key === 'deliveryZip') > -1 &&
      Lodash.findIndex(position.externalData, (item) => item.key === 'deliveryCity') > -1 &&
      Lodash.findIndex(position.externalData, (item) => item.key === 'deliveryStreet') > -1 &&
      Lodash.findIndex(position.externalData, (item) => item.key === 'deliveryStreetNo') > -1
    ) {
      return {
        zip: Lodash.find(position.externalData, (item) => item.key === 'deliveryZip').value,
        city: Lodash.find(position.externalData, (item) => item.key === 'deliveryCity').value,
        street: Lodash.find(position.externalData, (item) => item.key === 'deliveryStreet').value,
        streetNo: Lodash.find(position.externalData, (item) => item.key === 'deliveryStreetNo').value,
      };
    }
  }
  return null;
};

/**
 * getPredefinedLoadingAddressFromPosition()
 * Search for special extra data fields inside the position to build a loading address. Will be used on order create
 * to add a delivery address in a quick and easy way
 * @param position
 */
export const getPredefinedLoadingAddressFromPosition = (position: PositionEntity): Address | null => {
  if (position.externalData && position.externalData.length > 0) {
    if (
      Lodash.findIndex(position.externalData, (item) => item.key === 'loadingZip') > -1 &&
      Lodash.findIndex(position.externalData, (item) => item.key === 'loadingCity') > -1 &&
      Lodash.findIndex(position.externalData, (item) => item.key === 'loadingStreet') > -1 &&
      Lodash.findIndex(position.externalData, (item) => item.key === 'loadingStreetNo') > -1
    ) {
      return {
        zip: Lodash.find(position.externalData, (item) => item.key === 'loadingZip').value,
        city: Lodash.find(position.externalData, (item) => item.key === 'loadingCity').value,
        street: Lodash.find(position.externalData, (item) => item.key === 'loadingStreet').value,
        streetNo: Lodash.find(position.externalData, (item) => item.key === 'loadingStreetNo').value,
      };
    }
  }
  return null;
};
