import React from 'react';
import Panel from '../../Components/Panel';

/**
 * Dashboard()
 * @constructor
 */
export default function StartTimeTracking() {
  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Panel>Bevor Sie die Webseite nutzen könne, müssen Sie Ihre Arbeitszeit starten!</Panel>
    </div>
  );
}
