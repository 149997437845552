import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PublicFooter from './PublicFooter';
import ShowHideContainer from '../ShowHideContainer';
import { useLocation } from 'react-router';

type DefaultLayoutProps = {
  navigation: any;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f2f4f7;
`;

/**
 * DefaultLayout()
 * @param props
 * @constructor
 */
export default function DefaultLayout(props: DefaultLayoutProps) {
  const { isLoggedIn } = useSelector((state: any) => state.auth);
  const location = useLocation();

  if (!isLoggedIn) {
    return (
      <Container>
        {props.navigation}
        <Outlet />

        <ShowHideContainer visible={location.pathname !== '/public/login'}>
          <PublicFooter />
        </ShowHideContainer>
      </Container>
    );
  }
  return <Navigate replace to={'/dashboard'} />;
}
