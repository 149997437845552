import { DefaultTheme } from 'styled-components';

const Theme: DefaultTheme = {
  fontSize: '16px',

  layout: {
    containerWidth: 1100,
  },

  gradient: {
    light:
      // eslint-disable-next-line max-len
      'background: repeating-linear-gradient(-50deg,rgb(255, 255, 255),rgb(255, 255, 255) 2px,rgb(237, 237, 237) 2px,rgb(237, 237, 237) 4px) !important;',
    // eslint-disable-next-line max-len
    red: 'background: repeating-linear-gradient(-50deg,rgb(255, 255, 255),rgb(255, 255, 255) 2px,rgba(231, 24, 78, 0.2) 2px,rgba(231, 24, 78, 0.2) 4px) !important;',
  },

  color: {
    primaryColor: '#2961a8',

    background: '#fff',
    hoverBackground: '#f6f6f6',
    backgroundGray: '#f8f8f8',

    text: '#444444',
    textDark: '#000000',
    textGray: '#aba5a5',
    textGrayLight: '#cac9c9',
    headlineColor: '#000000',

    help: '#17a2b8',

    red: '#e7184e',
    blue: '#00508c',
    green: '#008C1C',
    yellow: '#cccf00',
    orange: '#f9b30e',
    blueGray: '#44546a',
    purple: '#9400D3',
  },

  border: {
    radius: '12px',
    color: '#ced4da',
    width: '0.5px',
  },

  table: {
    borderBottomHead: '2px solid #cac9c9',
    borderBottom: '1px solid #cac9c9',
    borderColor: '#cac9c9',
    highlight: '#f1f1f1',
  },

  boxShadowDark:
    '-webkit-box-shadow: 0 0 3px 1px rgba(0,0,0,0.2);' +
    '-moz-box-shadow: 0 0 3px 1px rgba(0,0,0,0.2);' +
    'box-shadow: 0 0 3px 1px rgba(0,0,0,0.2);',

  boxShadowDarkHover:
    '-webkit-box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);' +
    '-moz-box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);' +
    'box-shadow: 0 0 9px 1px rgba(0,0,0,0.4);',
};

export default Theme;
