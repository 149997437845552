/**
 * Prepare the translation file from the config.
 * ATTENTION: If __DEV__ mode use the local default remote config!
 * @param allRemoteConfig
 * @returns {{}}
 */
const translation = require('./DefaultTranslations.json');
const es = require('./es_ES.json');
const en = require('./en_US.json');
const nl = require('./nl_NL.json');

const DefaultRemoteConfig = {
  language_default: translation,
  language_de_DE: translation,
  language_es_ES: es,
  language_en_US: en,
  language_nl_NL: nl,
};

const prepareTranslationConfig = (allRemoteConfig: any, isLocalDev = false) => {
  let translationConfig = {};

  // eslint-disable-next-line no-undef
  if (isLocalDev) {
    Object.keys(DefaultRemoteConfig).forEach((keyName) => {
      if (keyName.indexOf('language_') >= 0) {
        if (keyName.indexOf('language_default') < 0) {
          const newKey = keyName.replace('language_', '').replace('_', '-');
          // @ts-ignore
          const data = DefaultRemoteConfig[keyName];
          translationConfig = { ...translationConfig, [newKey]: { translation: data } };
        } else {
          const data = DefaultRemoteConfig['language_default'];
          translationConfig = { ...translationConfig, 'en-US': { translation: data } };
        }
      }
    });

    // eslint-disable-next-line no-console
    console.log('Dev-Mode: Set ".DefaultTranslations.json" to translation component for all languages!');
  } else {
    Object.keys(allRemoteConfig).forEach((keyName) => {
      if (keyName.indexOf('language_') >= 0) {
        if (keyName.indexOf('language_default') < 0) {
          const newKey = keyName.replace('language_', '').replace('_', '-');
          const data = JSON.parse(allRemoteConfig[keyName].asString());
          translationConfig = { ...translationConfig, [newKey]: { translation: data } };
        } else {
          const data = JSON.parse(allRemoteConfig['language_default'].asString());
          translationConfig = { ...translationConfig, 'en-US': { translation: data } };
        }
      }
    });
  }

  return translationConfig;
};

export default prepareTranslationConfig;
