import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';

interface Props {
  hasError?: boolean;
  initialValue: boolean;
  label: string;
  marginBottom?: number;
  onChange: (value: boolean) => void;
  contextHelpKey?: string;
  disabled: boolean;
}

/**
 * SelectYesNo
 * @param props
 * @constructor
 */
export default function SelectYesNo(props: Props) {
  const { hasError, initialValue, label, marginBottom, onChange, contextHelpKey, disabled } = props;
  const [t] = useTranslation();
  const [options] = React.useState<SelectOptions>([
    { label: t('yes'), value: true },
    { label: t('no'), value: false },
  ]);

  if (options) {
    return (
      <Select
        label={label}
        onChange={onChange}
        initialValue={initialValue}
        options={options}
        hasError={hasError}
        marginBottom={marginBottom}
        contextHelpKey={contextHelpKey}
        disabled={disabled}
      />
    );
  }

  return null;
}

SelectYesNo.defaultProps = {
  disabled: false,
};
