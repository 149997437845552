import React from 'react';
import styled from 'styled-components';
import Panel from '../../../Components/Panel';
import { fileGetIconByExtension } from '../../../Globals/Functions/FileFunctions';
import Text from '../../../Components/Text';
import { formatSize } from '../../../Globals/Functions';
import { lighten } from 'polished';

const Item = styled.div`
  border-radius: ${(props) => props.theme.border.radius};
  display: flex;
  flex-direction: row;
  padding: 14px 20px;
  margin-right: -10px;
  margin-left: -10px;
  align-content: center;

  &:last-child {
    margin-bottom: 0;
  }

  > .LeftContainer {
    display: flex;
    flex-direction: row;
    flex: 1;

    i {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  > .ActionContainer {
    cursor: pointer;

    i {
      color: #dddddd;
    }
  }

  &:hover {
    background-color: ${(props) => lighten(0.25, props.theme.color.textGray)};
  }
`;

interface Props {
  files: File[];
  onDelete: (file: File) => void;
}

/**
 * CreateAttachments()
 * @param props
 * @constructor
 */
export default function CreateAttachments(props: Props) {
  const { files, onDelete } = props;

  const renderAttachments = () =>
    files.map((item) => (
      <Item key={item.name}>
        <div className="LeftContainer">
          <i className={fileGetIconByExtension(item.name)} />
          <Text>{`${item.name} (${formatSize(item.size)})`}</Text>
        </div>
        <div className="ActionContainer">
          <i className="fas fa-trash-alt" onClick={() => onDelete(item)} />
        </div>
      </Item>
    ));

  if (files && files.length > 0) {
    return <Panel>{renderAttachments()}</Panel>;
  }
  return null;
}
