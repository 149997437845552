import React from 'react';
import { useTranslation } from 'react-i18next';
import { InvoicePositionEntity } from '../../../../Globals/Types/Invoice';
import Panel from '../../../Panel';
import PositionModal from './PositionModal';
import Position from './Position';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import SelectPosition from '../../../Order/Positions/SelectPositionModal';
import { PositionEntity } from '../../../../Globals/Types/OrderTypes';

interface Props {
  positions: InvoicePositionEntity[];
  onChange: (address: InvoicePositionEntity[]) => void;
  hasError: boolean;
}

/**
 * PositionForm()
 * @param props
 * @constructor
 */
export default function PositionForm(props: Props) {
  const { positions, onChange, hasError } = props;
  const [t] = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showSelect, setShowSelect] = React.useState<boolean>(false);

  const preparePositions = (positions: InvoicePositionEntity[]) =>
    positions.map((position, index) => ({ ...position, positionNumber: index + 1 }));

  const handleOnAdd = React.useCallback(
    (position: InvoicePositionEntity) => {
      const merged = preparePositions([...positions, position]);
      onChange(merged);
      setShowModal(false);
    },
    [onChange, positions],
  );

  const handleOnSelect = React.useCallback(
    (position: PositionEntity) => {
      const mapped: InvoicePositionEntity = {
        caption: position.caption,
        description: position.description || null,
        amount: position.amount,
        taxRate: position.taxRate || 21,
        overallPrice: position.price || '0',
        type: position.type,
        isVoucher: false,
        pricePerAmount: position.price ? (parseFloat(position.price) / position.amount).toFixed(2) : '0',
        tax: position.price ? ((parseFloat(position.price) * position.taxRate) / 100).toFixed(2) : '0',
        positionNumber: null,
      };
      const merged = preparePositions([...positions, mapped]);
      onChange(merged);
      setShowSelect(false);
    },
    [onChange, positions],
  );

  const handleOnDelete = React.useCallback(
    (position: InvoicePositionEntity) => {
      const merged = preparePositions(positions.filter((item) => item.positionNumber !== position.positionNumber));
      onChange(merged);
    },
    [onChange, positions],
  );

  const handleOnUpdate = React.useCallback(
    (position: InvoicePositionEntity) => {
      const merged = preparePositions(
        positions.map((item) => (item.positionNumber === position.positionNumber ? position : item)),
      );
      onChange(merged);
    },
    [onChange, positions],
  );

  const renderPositions = () => {
    if (positions && positions.length > 0) {
      return positions.map((position) => (
        <Position
          position={position}
          key={position.positionNumber.toString()}
          onDelete={handleOnDelete}
          onUpdate={handleOnUpdate}
        />
      ));
    }
  };

  const renderActions = () => (
    <>
      <Button variant="outline-success" onClick={() => setShowSelect(true)} style={{ marginRight: 10 }}>
        <i className="far fa-rectangle-list" style={{ marginRight: 6 }} />
        {t('selectPosition')}
      </Button>
      <Button variant="outline-success" onClick={() => setShowModal(true)}>
        <i className="far fa-plus-circle" style={{ marginRight: 6 }} />
        {t('manualPosition')}
      </Button>
    </>
  );

  const renderError = () => (hasError ? <Alert variant="danger">{t('modules.invoice.positionError')}</Alert> : null);

  return (
    <>
      <Panel headline={t('invoicePositions')}>
        {renderPositions()}
        {renderError()}

        <Row style={{ marginTop: 20 }}>
          <Col xxl={12} className="flex-row d-flex justify-content-end">
            {renderActions()}
          </Col>
        </Row>
      </Panel>

      <SelectPosition onSelect={handleOnSelect} visible={showSelect} onClose={() => setShowSelect(false)} />
      <PositionModal visible={showModal} onClose={() => setShowModal(false)} position={null} onSave={handleOnAdd} />
    </>
  );
}
