import React from 'react';
import { buildDocumentRef, FirebasePathMappings } from '../../Globals/FirebaseGlobals';
import { getDownloadURL, getStorage, uploadBytes, UploadMetadata, deleteObject, listAll } from 'firebase/storage';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { generateGuid } from '../../Globals/Functions';
import { useDispatch } from 'react-redux';
import {
  DOCUMENT_DELETE_ERROR,
  DOCUMENT_DELETE_START,
  DOCUMENT_DELETE_SUCCESS,
  DOCUMENT_FOLDER_DELETE_ERROR,
  DOCUMENT_FOLDER_DELETE_START,
  DOCUMENT_FOLDER_DELETE_SUCCESS,
  DOCUMENT_FOLDER_GET_FILES_ERROR,
  DOCUMENT_FOLDER_GET_FILES_START,
  DOCUMENT_FOLDER_GET_FILES_SUCCESS,
  DOCUMENT_UPLOAD_ERROR,
  DOCUMENT_UPLOAD_START,
  DOCUMENT_UPLOAD_SUCCESS,
} from '../ActionTypes';
import { StorageReference } from '@firebase/storage';

/**
 * useDispatchDocumentGetDownloadUrl()
 */
export const useDispatchDocumentGetDownloadUrl = () => {
  return React.useCallback((path: string) => {
    const pathRef = buildDocumentRef(getStorage(), path);
    return getDownloadURL(pathRef);
  }, []);
};

/**
 * useDispatchDocumentUpload()
 */
export const useDispatchDocumentUpload = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    (path: string, file: File) => {
      dispatch({ type: DOCUMENT_UPLOAD_START, payload: { path, file } });
      const pathRef = buildDocumentRef(getStorage(), path, file.name);

      return uploadBytes(pathRef, file)
        .then((response) => {
          dispatch({ type: DOCUMENT_UPLOAD_SUCCESS, payload: response });
          return Promise.resolve(response);
        })
        .catch((error) => {
          dispatch({ type: DOCUMENT_UPLOAD_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchDocumentDelete()
 */
export const useDispatchDocumentDelete = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    (filePath: string) => {
      dispatch({ type: DOCUMENT_DELETE_START, payload: filePath });
      const pathRef = buildDocumentRef(getStorage(), filePath);

      return deleteObject(pathRef)
        .then(() => {
          dispatch({ type: DOCUMENT_DELETE_SUCCESS });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch({ type: DOCUMENT_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchDocumentDeleteFolder()
 */
export const useDispatchDocumentDeleteFolder = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    (folderPath: string) => {
      dispatch({ type: DOCUMENT_FOLDER_DELETE_START, payload: folderPath });
      const pathRef = buildDocumentRef(getStorage(), folderPath);

      return listAll(pathRef).then((listResult) => {
        const promiseAll = [];

        listResult.items.forEach((file) => {
          promiseAll.push(deleteObject(file));
        });

        return Promise.all(promiseAll)
          .then(() => {
            dispatch({ type: DOCUMENT_FOLDER_DELETE_SUCCESS });
            return Promise.resolve();
          })
          .catch((error) => {
            dispatch({ type: DOCUMENT_FOLDER_DELETE_ERROR, payload: error });
            return Promise.reject(error);
          });
      });
    },
    [dispatch],
  );
};

/**
 * useDispatchDocumentGetFolderContent()
 */
export const useDispatchDocumentGetFolderContent = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    (folderPath: string): Promise<StorageReference[]> => {
      dispatch({ type: DOCUMENT_FOLDER_GET_FILES_START, payload: folderPath });
      const pathRef = buildDocumentRef(getStorage(), folderPath);

      return listAll(pathRef)
        .then((listResult) => {
          const result: StorageReference[] = [];

          listResult.items.forEach((file) => {
            result.push(file);
          });

          dispatch({ type: DOCUMENT_FOLDER_GET_FILES_SUCCESS, payload: result });
          return Promise.resolve(result);
        })
        .catch((error) => {
          dispatch({ type: DOCUMENT_FOLDER_GET_FILES_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchDocumentUploadToTempFolder()
 */
export const useDispatchDocumentUploadToTempFolder = () => {
  const { clientId, userId } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (file: File, customMetaData: object = {}) => {
      const guid = generateGuid();
      const metaData: UploadMetadata = {
        customMetadata: {
          ...customMetaData,
          fileName: file.name,
          userId: userId,
        },
      };

      const pathRef = buildDocumentRef(
        getStorage(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.temp,
        guid,
      );

      return uploadBytes(pathRef, file, metaData).then((response) => {
        return getDownloadURL(response.ref).then((uri) => ({ uri, storagePath: pathRef.fullPath }));
      });
    },
    [clientId, userId],
  );
};
