import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { AccessPath, useHasSettingsAccess } from '../../../Globals/Access/Groups';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { useTimeTrackingIsAllowedToUse } from '../../../Globals/Hooks/TimeTrackingHooks';
import { useSubscriptionIsValid } from '../../../Globals/Hooks/SubscriptionHooks';
import { UrlPatterns } from '../../../Globals/UrlFunctions';
import MainNavigationItem from './MainNavigationItem';
import TimeTrackingItem from './TimeTrackingItem';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 6px 6px 10px;
`;

const TopContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

const BottomContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
`;

/**
 * MainNavigation()
 * @constructor
 */
export default function MainNavigation() {
  const navigate = useNavigate();
  const hasSettingsAccess = useHasSettingsAccess();
  const { unreadCount } = useAppSelector((state) => state.user.inbox);
  const { client } = useAppSelector((state) => state.auth);
  const isTimeTrackingAllowed = useTimeTrackingIsAllowedToUse();
  const { subscriptionModal, clickHandler } = useSubscriptionIsValid();

  const renderCustomer = () => {
    if (client.settings.customer && client.settings.customer.active && isTimeTrackingAllowed) {
      return (
        <MainNavigationItem
          path={'/customer'}
          icon={'far fa-users'}
          visible={true}
          accessPath={AccessPath.customers}
          onPress={() => clickHandler(() => navigate(UrlPatterns.customers))}
        />
      );
    }
    return null;
  };

  return (
    <Container>
      <TopContainer>
        <MainNavigationItem path={'/dashboard'} icon={'far fa-home'} visible={true} />
        <MainNavigationItem
          path={'/createorder'}
          onPress={() => clickHandler(() => navigate('/createorder'))}
          icon={'far fa-plus-circle'}
          visible={isTimeTrackingAllowed}
          accessPath={AccessPath.orderCreate}
        />
        <MainNavigationItem
          path={'/order'}
          onPress={() => navigate(UrlPatterns.orderList)}
          icon={'fa-kit fa-regular-file-lines-magnifying-glass'}
          visible={isTimeTrackingAllowed}
          accessPath={AccessPath.orderList}
        />
        <MainNavigationItem
          path={'/disposition'}
          onPress={() => clickHandler(() => navigate('/disposition'))}
          icon={'fa-kit fa-regular-list-timeline-clock'}
          visible={isTimeTrackingAllowed}
          accessPath={AccessPath.disposition}
        />

        {renderCustomer()}

        <MainNavigationItem
          path={'/inbox'}
          icon={unreadCount > 0 ? 'far fa-envelope-open fa-beat-fade' : 'far fa-envelope'}
          onPress={() => clickHandler(() => navigate('/inbox'))}
          visible={true}
        />
        <TimeTrackingItem />
      </TopContainer>
      <BottomContainer>
        <MainNavigationItem
          path={'/settings'}
          icon={'far fa-cog'}
          visible={isTimeTrackingAllowed && hasSettingsAccess}
        />
        <MainNavigationItem path={'/profile'} icon={'fas fa-user-circle'} visible={true} />
      </BottomContainer>

      {subscriptionModal}
    </Container>
  );
}
