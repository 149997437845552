import React from 'react';
import { AccessPath, useHasAccess } from '../../Globals/Access/Groups';
import DashboardBox from './Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { formatCurrency } from '../../Globals/Functions';
import { GroupKey } from '../../Globals/Types/Types';

/**
 * InvoiceMonthlyPayedBox()
 * @constructor
 */
export default function InvoiceMonthlyPayedBox() {
  const { currentMonthSales: currentMonthSalesOverall } = useAppSelector((state) => state.client.invoices);
  const { currentMonthSales: currentMonthSalesByUser } = useAppSelector((state) => state.user.invoices);
  const { client, user } = useAppSelector((state) => state.auth);
  const [t] = useTranslation();
  const theme = useTheme();
  const isAllowed = useHasAccess(AccessPath.invoices);
  const [visible, setVisible] = React.useState<boolean>(null);

  React.useEffect(() => {
    setVisible(isAllowed && client.settings && client.settings.invoice && client.settings.invoice.active);
  }, [client.settings, isAllowed]);

  const renderSales = () => {
    if (user.groups.indexOf(GroupKey.ADMIN) > -1) {
      return formatCurrency(currentMonthSalesOverall ? currentMonthSalesOverall.toString(10) : '0');
    }
    return formatCurrency(currentMonthSalesByUser ? currentMonthSalesByUser.toString(10) : '0');
  };

  if (visible) {
    return (
      <DashboardBox
        headline={t('salesCurrentMonth')}
        icon="fas fa-list-check"
        bgColor={theme.color.green}
        onClick={() => {}}
        fontSize={2.5}
      >
        {renderSales()}
      </DashboardBox>
    );
  }
  return null;
}
