import React from 'react';
import styled from 'styled-components';
import { DispositionListContext } from './index';
import Timeline from './Components/Timeline';
import { DraggableTourData, Tour, TourItem, TourService } from '../../../Globals/Types/Tour';
import { ElasticSearchServiceEntity } from '../../../Globals/Types/OrderTypes';
import ResourceColumn from './Components/ResourceColumn';
import UserColumn from './Components/UserColumn';
import { PickedUserEntity, ResourceEntity, UserEntity } from '../../../Globals/Types/Types';
import TourNameContainer from './Components/TourNameContainer';
import Performance from './Components/Performance';

const Container = styled.div<{ hours: number; hourCellWidth: number; tourNameWidth: number }>`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.hours * props.hourCellWidth + props.tourNameWidth + 460}px;
  border-bottom: 1px solid ${(props) => props.theme.border.color};
  z-index: 50;

  &.Striped {
    ${(props) => props.theme.gradient.red};
  }
`;

interface Props {
  tour: Tour;
  onMoveService: (newStartTime: string, droppedData: DraggableTourData, droppedOnTour: Tour) => void;
  onAddService: (newStartTime: string, droppedData: ElasticSearchServiceEntity, droppedOnTour: Tour) => void;
  onAddUser: (droppedOnTour: Tour, user: UserEntity) => void;
  onAddResource: (droppedOnTour: Tour, resource: ResourceEntity) => void;
  onDeleteUser: (droppedOnTour: Tour, user: PickedUserEntity) => void;
  onDeleteResource: (droppedOnTour: Tour, resource: ResourceEntity) => void;
  onPressTourItem: (tour: Tour, item: TourService) => void;
  onDeleteTour: (tour: Tour) => void;
  onEditTour: (tour: Tour) => void;
  onToggleCloseTour: (tour: Tour) => void;
  selectedTourItem: TourItem<TourService> | null;
}

/**
 * TourRow
 * @param props
 * @constructor
 */
export default function TourRow(props: Props) {
  const {
    tour,
    onMoveService,
    onAddService,
    onPressTourItem,
    onAddResource,
    onAddUser,
    onDeleteResource,
    onDeleteUser,
    onToggleCloseTour,
    onDeleteTour,
    onEditTour,
    selectedTourItem,
  } = props;
  const context = React.useContext(DispositionListContext);

  return (
    <Container
      hours={context.hours}
      hourCellWidth={context.hourBoxWidth}
      tourNameWidth={context.tourNameWidth}
      className={tour.closed ? 'Striped' : null}
    >
      <TourNameContainer tour={tour} onDelete={onDeleteTour} onToggleClosed={onToggleCloseTour} onEdit={onEditTour} />
      <Performance tour={tour.items} />
      <UserColumn
        users={tour.users}
        onAdd={(user) => onAddUser(tour, user)}
        onDelete={(user) => onDeleteUser(tour, user)}
        tour={tour}
      />
      <ResourceColumn
        resources={tour.resources}
        onAdd={(resource) => onAddResource(tour, resource)}
        onDelete={(resource) => onDeleteResource(tour, resource)}
        tour={tour}
      />
      <Timeline
        showHours={false}
        tourItem={tour.items}
        tour={tour}
        onMoveService={(newStartTime, droppedData) => onMoveService(newStartTime, droppedData, tour)}
        onAddService={(newStartTime, droppedData) => onAddService(newStartTime, droppedData, tour)}
        onPressTourItem={(item) => onPressTourItem(tour, item)}
        selectedTourItem={selectedTourItem}
      />
    </Container>
  );
}
