import React from 'react';
import { OrderEntity, ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import Panel from './Panel';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';

interface Props {
  order: OrderEntity;
  service: ServiceEntity;
}

/**
 * PartnerAssigned()
 * @param props
 * @constructor
 */
export default function PartnerAssigned(props: Props) {
  const { service, order } = props;
  const [t] = useTranslation();
  const { clientId } = useAppSelector((state) => state.auth);
  const { allPartners } = useAppSelector((state) => state.client);
  const [caption, setCaption] = React.useState<string>(null);

  React.useEffect(() => {
    if (order.ownerClientId === clientId && service.assignedPartnerClientId && allPartners) {
      const found = allPartners.find((partner) => partner.partnerFacilityId === service.assignedPartnerFacilityId);
      setCaption(found ? found.partnerName : '');
    }
  }, [allPartners, clientId, order, service]);

  React.useEffect(() => {
    if (service.assignedPartnerClientId === clientId && allPartners) {
      const found = allPartners.find((partner) => partner.partnerFacilityId === order.ownerFacilityId);
      setCaption(found ? found.partnerName : '');
    }
  }, [allPartners, clientId, order, service]);

  React.useEffect(() => {
    if (order.createdClientId === clientId && order.ownerClientId !== clientId) {
      const found = allPartners.find((partner) => partner.partnerFacilityId === order.ownerFacilityId);
      setCaption(found ? found.partnerName : '');
    }
  }, [allPartners, clientId, order, service]);

  if (order.ownerClientId === clientId && service.assignedPartnerClientId) {
    return <Panel icon={'fa-duotone fa-handshake'} label={t('assignedToPartner')} caption={caption} />;
  }
  if (service.assignedPartnerClientId === clientId) {
    return <Panel icon={'fa-duotone fa-handshake'} label={t('assignedFromPartner')} caption={caption} />;
  }
  if (order.createdClientId === clientId && order.ownerClientId !== clientId) {
    return <Panel icon={'fa-duotone fa-hand-sparkles'} label={t('createdForPartner')} caption={caption} />;
  }
  return null;
}
