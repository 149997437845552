import React from 'react';
import OfferCreate from '../../../Components/Offer/Create';
import { useNavigate, useParams } from 'react-router';
import { useDispatchOfferCreate, useDispatchOfferUpdate } from '../../../Redux/Actions/Client/OffersAction';
import { OfferEntity } from '../../../Globals/Types/OfferTypes';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { buildUrlCustomersOffers, buildUrlCustomersOffersDetails } from '../../../Globals/UrlFunctions';
import { useDispatchCustomerOffersGetList } from '../../../Redux/Actions/Customer/OffersAction';

/**
 * Create()
 * @constructor
 */
export default function Create() {
  const { offers } = useAppSelector((state) => state.client.customers.selectedCustomer);
  const navigate = useNavigate();
  const { customerId, offerId } = useParams();
  const { setLoading, loadingModal } = useLoadingModal();
  const [selectedOffer, setSelectedOffer] = React.useState<OfferEntity>(null);
  const [initialized, setInitialized] = React.useState<boolean>(false);

  const dispatchCreate = useDispatchOfferCreate();
  const dispatchUpdate = useDispatchOfferUpdate();
  const dispatchGetList = useDispatchCustomerOffersGetList();

  React.useEffect(() => {
    if (offerId) {
      setInitialized(true);
      if (offers && offers.length > 0) {
        setSelectedOffer(offers.find((item) => item.offerId === offerId));
      } else {
        dispatchGetList(customerId).then(() => {});
      }
    } else {
      setInitialized(true);
    }
  }, [customerId, dispatchGetList, offerId, offers]);

  const handleCreate = React.useCallback(
    (offer: OfferEntity) => {
      setLoading(true);
      dispatchCreate(offer)
        .then(() =>
          dispatchGetList(customerId).then(() => {
            navigate(buildUrlCustomersOffers(customerId));
            return Promise.resolve();
          }),
        )
        .finally(() => setLoading(false));
    },
    [customerId, dispatchCreate, dispatchGetList, navigate, setLoading],
  );

  const handleUpdate = React.useCallback(
    (offer: OfferEntity) => {
      setLoading(true);
      dispatchUpdate(offer)
        .then(() =>
          dispatchGetList(customerId).then(() => {
            navigate(buildUrlCustomersOffersDetails(customerId, selectedOffer.offerId));
            return Promise.resolve();
          }),
        )
        .finally(() => setLoading(false));
    },
    [customerId, dispatchGetList, dispatchUpdate, navigate, selectedOffer, setLoading],
  );

  if (initialized) {
    return (
      <>
        <OfferCreate
          offer={selectedOffer}
          customerId={customerId}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onCancel={(isEdit) =>
            !isEdit
              ? navigate(buildUrlCustomersOffers(customerId))
              : navigate(buildUrlCustomersOffersDetails(customerId, selectedOffer.offerId))
          }
        />
        {loadingModal}
      </>
    );
  }
  return null;
}
