import React from 'react';
import styled from 'styled-components';
import banner from '../../Assets/Images/banner01.png';
import { useTranslation } from 'react-i18next';
import { lighten } from 'polished';

const Container = styled.div`
  background-color: ${(props) => props.theme.color.backgroundGray};
  display: flex;
  width: 100%;
  min-height: 600px;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 40px;
  margin-top: -18px;
  background-image: url(${banner});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 1200px;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1350px) {
    width: 100%;
  }
`;

const TextContainerDark = styled.div`
  background-color: ${(props) => lighten(0.2, props.theme.color.primaryColor)};
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 2.4em;
  color: #ffffff;
  border-radius: 6px;
  font-weight: bold;
  ${(props) => props.theme.boxShadowDarkHover};
  margin-bottom: 10px;

  @media (max-width: 1350px) {
    font-size: 1.4em;
  }
`;

const TextContainerLight = styled.div`
  color: ${(props) => props.theme.color.primaryColor};
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 2.4rem;
  background-color: #ffffff;
  border-radius: 6px;
  font-weight: bold;
  ${(props) => props.theme.boxShadowDarkHover};
  margin-bottom: 10px;

  @media (max-width: 1350px) {
    font-size: 1.4em;
  }
`;

/**
 * BackgroundContainer()
 * @constructor
 */
export default function BackgroundContainer() {
  const [t] = useTranslation();

  return (
    <Container>
      <InnerContainer>
        <TextContainerDark>{t('modules.website.banner.line1')}</TextContainerDark>
        <TextContainerDark>{t('modules.website.banner.line2')}</TextContainerDark>
        <TextContainerLight>{t('modules.website.banner.line3')}</TextContainerLight>
        <TextContainerLight>{t('modules.website.banner.line4')}</TextContainerLight>
      </InnerContainer>
    </Container>
  );
}
