import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { ResourceEntity } from '../../../Globals/Types/Types';
import {
  RESOURCE_CREATE_ERROR,
  RESOURCE_CREATE_START,
  RESOURCE_CREATE_SUCCESS,
  RESOURCE_DELETE_ERROR,
  RESOURCE_DELETE_START,
  RESOURCE_DELETE_SUCCESS,
  RESOURCE_GET_ERROR,
  RESOURCE_GETLIST_ERROR,
  RESOURCE_GETLIST_START,
  RESOURCE_GETLIST_SUCCESS,
  RESOURCE_GET_START,
  RESOURCE_GET_SUCCESS,
  RESOURCE_UPDATE_ERROR,
  RESOURCE_UPDATE_START,
  RESOURCE_UPDATE_SUCCESS,
} from '../../ActionTypes';

/**
 * buildCollection()
 * @param clientId
 */
const buildCollection = (clientId: string): CollectionReference<ResourceEntity> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.resource,
  ) as CollectionReference<ResourceEntity>;
};

/**
 * buildDoc()
 * @param clientId
 * @param resourceId
 */
const buildDoc = (clientId: string, resourceId: string): DocumentReference<ResourceEntity> => {
  return doc(buildCollection(clientId), resourceId) as DocumentReference<ResourceEntity>;
};

type CreateReturnType = (resource: ResourceEntity) => Promise<ResourceEntity>;
/**
 * useDispatchResourceCreate
 */
export const useDispatchResourceCreate = (): CreateReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<CreateReturnType>(
    (resource) => {
      dispatch({ type: RESOURCE_CREATE_START, payload: resource });

      const storageCollection = buildCollection(clientId);
      return addDoc(storageCollection, resource)
        .then((response) => {
          const mapped: ResourceEntity = { ...resource, resourceId: response.id };
          dispatch({ type: RESOURCE_CREATE_SUCCESS, payload: mapped });
          return Promise.resolve(mapped);
        })
        .catch((error) => {
          dispatch({ type: RESOURCE_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type DeleteReturnType = (resource: ResourceEntity) => Promise<ResourceEntity>;
/**
 * useDispatchResourceDelete
 */
export const useDispatchResourceDelete = (): DeleteReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<DeleteReturnType>(
    (resource) => {
      dispatch({ type: RESOURCE_DELETE_START, payload: resource });

      const storageDoc = buildDoc(clientId, resource.resourceId);
      return deleteDoc(storageDoc)
        .then(() => {
          dispatch({ type: RESOURCE_DELETE_SUCCESS, payload: resource });
          return Promise.resolve(resource);
        })
        .catch((error) => {
          dispatch({ type: RESOURCE_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type GetListReturnType = (clientIdParam?: string) => Promise<ResourceEntity[]>;
/**
 * useDispatchResourceGetList()
 */
export const useDispatchResourceGetList = (): GetListReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListReturnType>(
    (clientIdParam) => {
      const mappedClientId = clientIdParam || clientId;
      dispatch({ type: RESOURCE_GETLIST_START, payload: mappedClientId });

      const resourceCollection = buildCollection(mappedClientId);
      const queryRef = query(resourceCollection, orderBy('name'));
      return getDocs(queryRef)
        .then((response) => {
          const resources: ResourceEntity[] = [];
          response.forEach((resource) => {
            resources.push({ ...(resource.data() as ResourceEntity), resourceId: resource.id });
          });

          dispatch({ type: RESOURCE_GETLIST_SUCCESS, payload: resources });
          return Promise.resolve(resources);
        })
        .catch((error) => {
          dispatch({ type: RESOURCE_GETLIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type GetReturnType = (resourceId: string) => Promise<ResourceEntity>;
/**
 * useDispatchResourceGet()
 */
export const useDispatchResourceGet = (): GetReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetReturnType>(
    (resourceId) => {
      dispatch({ type: RESOURCE_GET_START, payload: resourceId });

      const storageDoc = buildDoc(clientId, resourceId);
      return getDoc(storageDoc)
        .then((response) => {
          if (response.exists()) {
            const resource = { ...response.data(), resourceId: response.id } as ResourceEntity;
            dispatch({ type: RESOURCE_GET_SUCCESS, payload: resource });
            return Promise.resolve(resource);
          }

          dispatch({ type: RESOURCE_GET_ERROR, payload: `${resourceId} doesn't exist` });
          return Promise.reject();
        })
        .catch((error) => {
          dispatch({ type: RESOURCE_GET_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type UpdateReturnType = (resource: ResourceEntity, withMerge: boolean) => Promise<ResourceEntity>;
/**
 * useDispatchResourceUpdate()
 */
export const useDispatchResourceUpdate = (): UpdateReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateReturnType>(
    (resource, withMerge = true) => {
      dispatch({ type: RESOURCE_UPDATE_START, payload: resource });

      const storageDoc = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.resource,
        resource.resourceId,
      ) as DocumentReference<ResourceEntity>;

      return setDoc(storageDoc, resource, { merge: withMerge })
        .then(() => {
          dispatch({ type: RESOURCE_UPDATE_SUCCESS, payload: resource });
          return Promise.resolve(resource);
        })
        .catch((error) => {
          dispatch({ type: RESOURCE_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};
