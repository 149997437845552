import React from 'react';
import styled from 'styled-components';
import Text from '../../../../Text';

const CustomPanel = styled.div<{ iconColor: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 15px;
  border-radius: ${(props) => props.theme.border.radius};
  ${(props) => props.theme.boxShadowDark};
  margin-right: 20px;
  background-color: #ffffff;

  i {
    font-size: 30px;
    margin-bottom: 20px;
    color: ${(props) => props.iconColor || null};
  }

  &:last-child {
    margin-right: 0;
  }
`;

interface Props {
  icon: string;
  label: string;
  caption: string;
  iconColor?: string;
}

/**
 * Panel()
 * @param props
 * @constructor
 */
export default function Panel(props: Props) {
  const { icon, label, caption, iconColor } = props;

  return (
    <CustomPanel iconColor={iconColor}>
      <i className={icon}></i>
      <Text small light centered>
        {label}
      </Text>
      <Text centered>{caption}</Text>
    </CustomPanel>
  );
}
