import React from 'react';
import { ContactPersonEntity } from '../../../../Globals/Types/OrderTypes';
import Container from '../../../Container';
import styled from 'styled-components';
import AskDeleteModal from '../../../Modals/AskDeleteModal';

const Inner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  > .Name {
    flex-grow: 1;
    padding-left: 10px;
  }

  > .Action {
    cursor: pointer;
    color: ${(props) => props.theme.color.textGray};

    &:hover {
      color: ${(props) => props.theme.color.red};
    }
  }
`;

interface Props {
  contact: ContactPersonEntity;
  onDelete: (contacts: ContactPersonEntity) => void;
}

/**
 * ContactRow()
 * @param props
 * @constructor
 */
export default function ContactRow(props: Props) {
  const { contact, onDelete } = props;
  const [showAsk, setShowAsk] = React.useState<boolean>(false);

  return (
    <Container>
      <Inner>
        <div>
          <i className="far fa-id-card-clip" />
        </div>
        <div className="Name">{`${contact.name} (${contact.contact})`}</div>
        <div className="Action">
          <i className="far fa-trash-alt" onClick={() => setShowAsk(true)} />
        </div>
      </Inner>

      <AskDeleteModal onDelete={() => onDelete(contact)} onClose={() => setShowAsk(false)} visible={showAsk} />
    </Container>
  );
}
