import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';
import SelectClientUsers from '../../../Components/SelectClientUsers';
import DatePicker from '../../../Components/Inputs/DatePicker';
import moment, { Moment } from 'moment';
import { AbsenceType } from '../../../Globals/Types/Enums';
import Select from '../../../Components/Inputs/Select';
import Input, { InputType } from '../../../Components/Inputs/Input';
import { AbsenceEntity, PickedUserEntity } from '../../../Globals/Types/Types';
import {
  useDispatchAbsenceCreate,
  useDispatchAbsenceDelete,
  useDispatchAbsenceUpdate,
} from '../../../Redux/Actions/Client/AbsenceAction';
import Validate from './Components/ValidateCreate';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import AskDeleteModal from '../../../Components/Modals/AskDeleteModal';

type CreateAbsenceModalProps = {
  onClose: () => void;
  onDeleted?: () => void;
  visible: boolean;
  initialStartDate?: Moment;
  editData?: AbsenceEntity;
  fixedUser?: PickedUserEntity;
};

export type AbsenceCreateErrors = {
  user?: string;
  startDate?: string;
  endDate?: string;
  type?: string;
};

export type State = {
  absence: AbsenceEntity;
  initialized: boolean;
  isEdit: boolean;
  hasChanges: boolean;
  errors: AbsenceCreateErrors;
};

type Action =
  | { type: 'user'; payload: PickedUserEntity }
  | { type: 'fixedUser'; payload: PickedUserEntity }
  | { type: 'startDate'; payload: string }
  | { type: 'endDate'; payload: string }
  | { type: 'type'; payload: AbsenceType }
  | { type: 'comment'; payload: string }
  | { type: 'setErrors'; payload: AbsenceCreateErrors }
  | { type: 'init'; payload: AbsenceEntity }
  | { type: 'clear' };

const initialState: State = {
  absence: {} as AbsenceEntity,
  errors: {},
  isEdit: false,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  const calcWorkingDays = (startDate, endDate): number => {
    if (startDate && endDate) {
      const amount = moment(endDate).diff(moment(startDate), 'days');

      let counter = 0;
      for (let i = 0; i <= amount; i++) {
        if (moment(startDate).add(i, 'day').weekday() < 5) {
          counter++;
        }
      }
      return counter;
    }
    return null;
  };

  const createDateArray = (startDate, endDate): Array<string> => {
    if (startDate && endDate) {
      const amount = moment(endDate).diff(moment(startDate), 'days');

      let dates: Array<string> = [];
      for (let i = 0; i <= amount; i++) {
        dates.push(moment(startDate).add(i, 'day').format('YYYY-MM-DD'));
      }
      return dates;
    }
    return [];
  };

  switch (action.type) {
    case 'user':
      return {
        ...state,
        absence: {
          ...state.absence,
          user: action.payload,
          userId: action.payload.userId,
          approved: true,
        },
        hasChanges: true,
      };
    case 'fixedUser':
      return {
        ...state,
        absence: {
          ...state.absence,
          user: action.payload,
          userId: action.payload.userId,
          approved: false,
        },
        hasChanges: true,
      };
    case 'startDate':
      return {
        ...state,
        absence: {
          ...state.absence,
          startDate: moment(action.payload).toDate(),
          workDays: calcWorkingDays(action.payload, state.absence.endDate),
          dates: createDateArray(action.payload, state.absence.endDate),
        },
        hasChanges: true,
      };
    case 'endDate':
      return {
        ...state,
        absence: {
          ...state.absence,
          endDate: moment(action.payload).toDate(),
          workDays: calcWorkingDays(state.absence.startDate, action.payload),
          dates: createDateArray(state.absence.startDate, action.payload),
        },
        hasChanges: true,
      };
    case 'type':
      return {
        ...state,
        absence: { ...state.absence, type: action.payload },
        hasChanges: true,
      };
    case 'comment':
      return {
        ...state,
        absence: { ...state.absence, comment: action.payload },
        hasChanges: true,
      };
    case 'init':
      return {
        ...state,
        absence: {
          ...action.payload,
          startDate: action.payload.startDate.toDate(),
          endDate: action.payload.endDate.toDate(),
        },
        initialized: true,
        isEdit: true,
      };
    case 'setErrors':
      return { ...state, errors: action.payload };
    case 'clear':
      return { ...initialState };
  }
};

/**
 * CreateAbsenceModal()
 * @constructor
 */
export default function CreateAbsenceModal(props: CreateAbsenceModalProps) {
  const { onClose, visible, initialStartDate, editData, onDeleted, fixedUser } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isLoading, setLoading } = useLoadingModal();
  const [t] = useTranslation();
  const [typeOptions, setTypeOptions] = React.useState<Array<{ value: string; label: string }>>([]);
  const [showAskDelete, setShowAskDelete] = React.useState<boolean>(false);
  const dispatchCreate = useDispatchAbsenceCreate();
  const dispatchDelete = useDispatchAbsenceDelete();
  const dispatchUpdate = useDispatchAbsenceUpdate();

  React.useEffect(() => {
    const options = [
      { value: AbsenceType.holiday, label: t(AbsenceType.holiday) },
      { value: AbsenceType.disease, label: t(AbsenceType.disease) },
      { value: AbsenceType.other, label: t(AbsenceType.other) },
    ];
    setTypeOptions(options);
  }, [t]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear' });
    }
  }, [visible]);

  React.useEffect(() => {
    if (editData && !state.initialized) {
      dispatch({ type: 'init', payload: editData });
    }
  }, [editData, state.initialized]);

  React.useEffect(() => {
    if (fixedUser !== null) {
      dispatch({ type: 'fixedUser', payload: fixedUser });
    }
  }, [fixedUser]);

  React.useEffect(() => {
    if (initialStartDate) {
      dispatch({ type: 'startDate', payload: initialStartDate.format('YYYY-MM-DD') });
    } else {
      dispatch({ type: 'startDate', payload: moment().format('YYYY-MM-DD') });
    }
  }, [initialStartDate]);

  const handleSave = () => {
    const { isValid, errors } = Validate(state.absence);

    if (isValid) {
      setLoading(true);
      let promise;

      if (state.isEdit) {
        promise = dispatchUpdate(state.absence);
      } else {
        promise = dispatchCreate(state.absence);
      }

      promise.then(() => {
        setLoading(false);
        onClose();
      });
    } else {
      dispatch({ type: 'setErrors', payload: errors });
    }
  };

  const getMinEndDate = (): Date => {
    if (state.absence.startDate) {
      return moment(state.absence.startDate).toDate();
    }
    return moment().toDate();
  };

  const getMaxStartDate = (): Date => {
    if (state.absence.endDate) {
      return moment(state.absence.endDate).toDate();
    }
    return null;
  };

  const handleDelete = () => {
    setShowAskDelete(false);
    setLoading(true);
    dispatchDelete(editData).then(() => {
      if (onDeleted) {
        onDeleted();
      }
      setLoading(false);
      onClose();
    });
  };

  if ((visible && !editData) || (editData && state.initialized && visible)) {
    return (
      <Modal show={visible} animation={true} size="lg">
        <Modal.Header>
          <Modal.Title>
            <i className="fas fa-calendar" style={{ marginRight: 10 }} />
            {t('absenceCreate')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <SelectClientUsers
                onChange={(value: PickedUserEntity) => dispatch({ type: 'user', payload: value })}
                isMultiSelect={false}
                disabled={isLoading || fixedUser !== null}
                required
                hasError={!!state.errors.user}
                initialSelected={state.absence.user ? [state.absence.user] : null}
              />
            </Col>
            <Col lg={6}>
              <DatePicker
                label={t('startDate')}
                onChange={(value) => dispatch({ type: 'startDate', payload: value })}
                minDate={moment().toDate()}
                disabled={isLoading}
                maxDate={getMaxStartDate()}
                required
                hasError={!!state.errors.startDate}
                initialValue={initialStartDate ? initialStartDate.toDate() : moment().toDate()}
              />
            </Col>
            <Col lg={6}>
              <DatePicker
                label={t('endDate')}
                onChange={(value) => dispatch({ type: 'endDate', payload: value })}
                disabled={isLoading}
                minDate={getMinEndDate()}
                required
                hasError={!!state.errors.endDate}
                initialValue={editData ? editData.endDate.toDate() : null}
              />
            </Col>
            <Col lg={12}>
              <Select
                onChange={(value) => dispatch({ type: 'type', payload: value })}
                label={t('absenceReason')}
                options={typeOptions}
                disabled={isLoading}
                required
                hasError={!!state.errors.type}
                initialValue={state.absence.type}
              />
            </Col>
            <Col lg={12}>
              <Input
                type={InputType.textarea}
                onChange={(value) => dispatch({ type: 'comment', payload: value })}
                label={t('additionalInformation')}
                value={state.absence.comment}
                disabled={isLoading}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ShowHideContainer visible={state.isEdit}>
            <div style={{ flexGrow: 1 }}>
              <Button variant="outline-danger" onClick={() => setShowAskDelete(true)} disabled={isLoading}>
                {t('delete')}
              </Button>
            </div>
          </ShowHideContainer>
          <div>
            <Button
              variant="outline-secondary"
              onClick={() => onClose()}
              disabled={isLoading}
              style={{ marginRight: 10 }}
            >
              {t('close')}
            </Button>
            <Button variant="success" onClick={handleSave} disabled={isLoading}>
              {t('save')}
            </Button>
          </div>
        </Modal.Footer>

        <AskDeleteModal onDelete={handleDelete} onClose={() => setShowAskDelete(false)} visible={showAskDelete} />
      </Modal>
    );
  }
  return null;
}

CreateAbsenceModal.defaultProps = {
  editData: null,
  fixedUser: null,
};
