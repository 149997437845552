import React from 'react';
import PanelSelect, { PanelSelectOption } from '../PanelSelect';
import { useTranslation } from 'react-i18next';
import { FacilityPartnerEntity } from '../../Globals/Types/Types';
import { useDispatchPartnersGetList } from '../../Redux/Actions/Client/Facility/PartnerAction';
import logo from '../../Assets/Images/logo_full.png';
import Panel from '../Panel';
import { DefaultHeyKitchenPartnerEntity } from '../../Globals/Types/General';

interface Props {
  onChange: (item: FacilityPartnerEntity | string) => void;
  currentFacilityId: string;
  selectedPartnerFacilityId: string;
  selectedPartnerClientId: string;
  showSotoSoft: boolean;
  blackHeadline?: boolean;
}

/**
 * SelectPartner
 * @param props
 * @constructor
 */
export default function SelectPartner(props: Props) {
  const {
    onChange,
    currentFacilityId,
    selectedPartnerFacilityId,
    selectedPartnerClientId,
    showSotoSoft,
    blackHeadline,
  } = props;
  const [t] = useTranslation();
  const [partners, setPartners] = React.useState<Array<FacilityPartnerEntity>>([]);
  const [options, setOptions] = React.useState<Array<PanelSelectOption>>([]);
  const [selected, setSelected] = React.useState<Array<string>>([]);
  const dispatchGetPartners = useDispatchPartnersGetList();

  type AddDefaultOptionsCallback = (item: Array<PanelSelectOption>) => Array<PanelSelectOption>;
  const addDefaultOptions = React.useCallback<AddDefaultOptionsCallback>(
    (param) => {
      const merged = [...param];

      merged.unshift({
        key: currentFacilityId || 'intern',
        caption: 'internalOrder',
        icon: 'fas fa-building',
        disabled: false,
      });

      if (showSotoSoft) {
        merged.push({
          key: 'extern',
          caption: 'SotoSoft',
          imageUrl: logo,
          disabled: false,
        });
      }

      return [...merged];
    },
    [currentFacilityId, showSotoSoft],
  );

  React.useEffect(() => {
    if (currentFacilityId) {
      const optionsParam: Array<PanelSelectOption> = [];

      dispatchGetPartners(currentFacilityId, true)
        .then((response) => {
          setPartners(response);

          if (response.length > 0) {
            response.forEach((item) => {
              if (item.partnerSettings.createOrders && item.active) {
                optionsParam.push({
                  key: item.partnerId,
                  caption: item.partnerName,
                  icon: 'fas fa-building',
                  disabled: false,
                  useTranslation: false,
                });
              }
            });

            setOptions(addDefaultOptions(optionsParam));
          } else {
            setOptions(addDefaultOptions(optionsParam));
          }
        })
        .catch(() => {
          setOptions(addDefaultOptions(optionsParam));
        });
    }
  }, [addDefaultOptions, currentFacilityId, dispatchGetPartners]);

  React.useEffect(() => {
    if (selectedPartnerFacilityId && selectedPartnerClientId) {
      if (selectedPartnerFacilityId !== currentFacilityId && selectedPartnerFacilityId !== 'extern') {
        const filtered = partners.filter(
          (item) =>
            item.partnerClientId === selectedPartnerClientId && item.partnerFacilityId === selectedPartnerFacilityId,
        )[0];
        if (filtered) {
          setSelected([filtered.partnerId]);
        }
      } else {
        setSelected([selectedPartnerFacilityId]);
      }
    }
  }, [currentFacilityId, partners, selectedPartnerClientId, selectedPartnerFacilityId]);

  const handleOnChange = (item: Array<string>) => {
    const singleItem = item[0];
    if (singleItem !== 'extern' && singleItem !== currentFacilityId) {
      const filtered = partners.filter((param) => param.partnerId === singleItem);
      if (filtered && filtered.length === 1) {
        onChange(filtered[0]);
      }
    } else {
      if (singleItem === currentFacilityId) {
        onChange(null);
      } else {
        onChange(DefaultHeyKitchenPartnerEntity);
      }
    }
  };

  if (options && options.length > 1) {
    return (
      <Panel
        headline={t('modules.order.selectPartner')}
        description={t('modules.order.selectPartnerDescription')}
        headlineDarkBlack={blackHeadline || false}
      >
        <PanelSelect onChange={handleOnChange} options={options} selected={selected} />
      </Panel>
    );
  }
  return null;
}
