import React from 'react';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { OrderEntity, ServiceEntity } from '../../../Globals/Types/OrderTypes';
import { Report as ReportEntity, SignatureType } from '../../../Globals/Types/Report';
import styled from 'styled-components';
import { useDispatchReportGetWithFunction } from '../../../Redux/Actions/Order/Service/ReportAction';
import { LinkingEntity, LinkingPayloadPublicReport } from '../../../Globals/Types/Linking';
import { Col, Container, Row } from 'react-bootstrap';
import Panel from '../../Panel';
import Overview from '../../Order/Report/Overview';
import Inventory from '../../Order/Report/Inventory';
import PreDamage from '../../Order/Report/PreDamage';
import Reclamation from '../../Order/Report/Reclamation';
import Damage from '../../Order/Report/Damage';
import AdditionalWork from '../../Order/Report/AdditionalWork';
import Returns from '../../Order/Report/Returns';
import PositionCheck from '../../Order/Report/PositionCheck';
import CompletedImages from '../../Order/Report/CompletedImages';
import Signature from '../../Order/Report/Signature';
import Text from '../../Text';
import { formatDateString } from '../../../Globals/Functions';
import { FacilityEntity } from '../../../Globals/Types/Types';
import CustomerBox from './CustomerBox';
import FacilityBox from './FacilityBox';
import PaymentBox from '../../Order/Report/PaymentBox';

const StyledContainer = styled(Container)`
  padding: 30px 15px;
`;

interface Props {
  linkingEntity: LinkingEntity<LinkingPayloadPublicReport>;
}

/**
 * Report()
 * @param props
 * @constructor
 */
export default function Report(props: Props) {
  const { linkingEntity } = props;
  const [t] = useTranslation();
  const { setLoading, loadingModal } = useLoadingModal();
  const [order, setOrder] = React.useState<OrderEntity>(null);
  const [service, setService] = React.useState<ServiceEntity>(null);
  const [report, setReport] = React.useState<ReportEntity>(null);
  const [facility, setFacility] = React.useState<FacilityEntity>(null);

  const dispatchReportGet = useDispatchReportGetWithFunction();

  React.useEffect(() => {
    if (linkingEntity) {
      setLoading(true);

      const { orderId, serviceId, clientId } = linkingEntity.payload;
      dispatchReportGet(clientId, orderId, serviceId).then((response) => {
        setOrder(response.order);
        setService(response.service);
        setReport(response.report);
        setFacility(response.facility);
        setLoading(false);
      });
    }
  }, [dispatchReportGet, linkingEntity, setLoading]);

  if (order && service && report && facility) {
    return (
      <StyledContainer>
        <Panel>
          <h4 style={{ marginBottom: 6 }}>
            {t('print.report.headline', { externalId: order.externalId, serviceType: service.type.caption })}
          </h4>
          <Text>{`${t('executionDate')}: ${formatDateString(report.startDateTime)}`}</Text>
        </Panel>

        <Row>
          <Col xl={6} style={{ display: 'flex', flex: 1 }}>
            <CustomerBox customer={order.customer} />
          </Col>
          <Col xl={6}>
            <FacilityBox facility={facility} />
          </Col>
        </Row>

        <Overview report={report} />
        <Inventory report={report} />

        <PreDamage report={report} />
        <Reclamation report={report} />
        <Damage report={report} />
        <AdditionalWork report={report} />
        <Returns report={report} />
        <PositionCheck report={report} service={service} />

        <PaymentBox report={report} />

        <CompletedImages report={report} />

        <Row>
          <Col xxl={report.customerSignature && report.monteurSignature ? 6 : 12}>
            <Signature
              signature={report.customerSignature}
              type={SignatureType.customer}
              report={report}
              order={order}
            />
          </Col>
          <Col xxl={report.customerSignature && report.monteurSignature ? 6 : 12}>
            <Signature signature={report.monteurSignature} type={SignatureType.monteur} report={report} order={order} />
          </Col>
        </Row>
      </StyledContainer>
    );
  }
  return loadingModal;
}
