import { CreateOrderState, NamingConventionType, OrderEntity, ServiceEntity } from '../../../Globals/Types/OrderTypes';
import moment from 'moment';
import { ReportState } from '../../../Globals/Types/Report';
import { DeliveryAndLoadingOptions } from '../../../Globals/Types/Enums';

/**
 * Create an order entity by create order state from create page
 * @param orderData
 */
const createOrderEntityFromCreateOrderState = (orderData: CreateOrderState): OrderEntity => {
  const desiredDate = orderData.executionDate ? moment(orderData.executionDate).format('YYYY-MM-DD') : null;
  const createdDate = moment().format('YYYY-MM-DD HH:mm:ss');
  const { deliveryAndLoadingOption, deliveryAddress, loadingAddress } = orderData.generalService;

  let order: OrderEntity = {
    ...orderData.order,
    originalExternalId: orderData.order.externalId,
    createdDate,
    executeTrigger: true,
    services: [],
  };

  const service: ServiceEntity = {
    ...orderData.generalService,
    type: orderData.orderServiceType,
    createdDate,
    createdUserId: orderData.order.createdUserId,
    desiredWeek: orderData.desiredWeek,
    desiredDate,
    desiredExecutionTime: orderData.executionTime,
    reportState: ReportState.none,
    positions: orderData.positions,
    estimatedAssemblyTime: orderData.estimatedAssemblyTime,
    deliveryAddress:
      deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingIsCustomerAndDelivery ||
      deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingAndDelivery
        ? { ...deliveryAddress }
        : null,
    loadingAddress:
      deliveryAndLoadingOption === DeliveryAndLoadingOptions.deliveryIsCustomerAndLoading ||
      deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingAndDelivery
        ? { ...loadingAddress }
        : null,
    settings: {
      sendCustomerMail: true,
      needReport: orderData.orderServiceType.needReport,
      recurring: {
        active: false,
        namingConvention: NamingConventionType.currentIndex,
        currentIndex: 1,
        days: 7,
        createNewOrder: false,
      },
    },
  };

  order = { ...order, services: [...order.services, service] };

  return { ...order, serviceCount: order.services.length };
};

export default createOrderEntityFromCreateOrderState;
