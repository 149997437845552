import React from 'react';
import styled from 'styled-components';
import { AvailableReportPages } from '../../../../Globals/Types/Report';
import { useTranslation } from 'react-i18next';

const Container = styled.div<{ leftIconAction: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.table.highlight};
  border-radius: ${(props) => props.theme.border.radius};
  padding: 15px;
  align-items: center;
  margin-bottom: 10px;

  .LeftIcon {
    font-size: 30px;
    margin-right: 15px;
    cursor: ${(props) => (props.leftIconAction ? 'pointer' : 'default')};
    color: ${(props) => props.theme.color.textGray};

    &:hover {
      color: ${(props) => (props.leftIconAction ? props.theme.color.primaryColor : props.theme.color.textGray)};
    }
  }

  .RightIcon {
    cursor: pointer;
    color: ${(props) => props.theme.color.textGray};
    padding: 10px;

    &:hover {
      color: ${(props) => props.theme.color.red};
    }
  }

  .Content {
    flex-grow: 1;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.table.highlight};
  border-radius: ${(props) => props.theme.border.radius};
  padding: 15px;
  align-items: center;
  margin: 0 0 10px 40px;
  color: ${(props) => props.theme.color.textGray};
`;

interface Props {
  pageName: AvailableReportPages;
  canDelete: boolean;
  icon?: string;
  onPressIcon?: (pageName: AvailableReportPages) => void;
  onDelete?: (pageName: AvailableReportPages) => void;
  showAdditionalInfo?: boolean;
}

/**
 * PageContainer()
 * @constructor
 */
export default function PageContainer(props: Props) {
  const { pageName, canDelete, icon, onPressIcon, onDelete, showAdditionalInfo } = props;
  const [showInfo, setShowInfo] = React.useState<boolean>(false);
  const [t] = useTranslation();

  const renderDelete = () =>
    canDelete && pageName !== 'ReportSummary' && pageName !== 'ReportSave' ? (
      <i className="fas fa-trash-alt RightIcon" onClick={() => onDelete(pageName)} key="delete" />
    ) : null;

  const renderInfos = () => {
    if (showInfo) {
      return <InfoContainer>{t(`reportPages.${pageName}.description`)}</InfoContainer>;
    }
    return null;
  };

  const renderRightIcons = () => {
    const icons = [];
    icons.push(renderDelete());

    if (showAdditionalInfo) {
      icons.push(<i className="fas fa-info-circle RightIcon" onClick={() => setShowInfo(!showInfo)} key="showInfo" />);
    }

    return icons;
  };

  return (
    <>
      <Container leftIconAction={!!onPressIcon}>
        <i className={`${icon} LeftIcon`} onClick={onPressIcon ? () => onPressIcon(pageName) : () => {}} />
        <div className="Content">{t(`reportPages.${pageName}.caption`)}</div>
        {renderRightIcons()}
      </Container>

      {renderInfos()}
    </>
  );
}

PageContainer.defaultProps = {
  icon: 'far fa-memo',
  showAdditionalInfo: false,
};
