import { useAppDispatch } from '../../../Globals/Hooks/Hooks';
import React from 'react';
import { doc, DocumentReference, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { InternalSettings } from '../../../Globals/Types/Internal/Settings';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import {
  INTERNAL_SETTINGS_GET_ERROR,
  INTERNAL_SETTINGS_GET_START,
  INTERNAL_SETTINGS_GET_SUCCESS,
  INTERNAL_SETTINGS_UPDATE_ERROR,
  INTERNAL_SETTINGS_UPDATE_START,
  INTERNAL_SETTINGS_UPDATE_SUCCESS,
} from '../../ActionTypes';

/**
 * getDocumentRef()
 */
const getDocumentRef = (): DocumentReference<InternalSettings> => {
  return doc(getFirestore(), 'sotosoft', 'settings') as DocumentReference<InternalSettings>;
};

/**
 * useDispatchInternalGetSettings()
 */
type GetSettingsReturnType = () => Promise<InternalSettings>;
export const useDispatchInternalGetSettings = (): GetSettingsReturnType => {
  const dispatch = useAppDispatch();

  return React.useCallback<GetSettingsReturnType>(() => {
    dispatch({ type: INTERNAL_SETTINGS_GET_START });

    return getDoc(getDocumentRef())
      .then((snapShot) => {
        if (snapShot.exists()) {
          const settings: InternalSettings = { ...snapShot.data() };
          dispatch({ type: INTERNAL_SETTINGS_GET_SUCCESS, payload: settings });
          return Promise.resolve(settings);
        }

        return Promise.reject(new Error(InternalErrorCodes.FIRESTORE_DOCUMENT_NOT_FOUND));
      })
      .catch((error) => {
        dispatch({ type: INTERNAL_SETTINGS_GET_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [dispatch]);
};

/**
 * useDispatchInternalUpdateSettings()
 */
type UpdateSettingsReturnType = (settings: Partial<InternalSettings>) => Promise<InternalSettings>;
export const useDispatchInternalUpdateSettings = (): UpdateSettingsReturnType => {
  const dispatch = useAppDispatch();
  const dispatchGet = useDispatchInternalGetSettings();

  return React.useCallback<UpdateSettingsReturnType>(
    (settings) => {
      dispatch({ type: INTERNAL_SETTINGS_UPDATE_START, payload: settings });

      return setDoc(getDocumentRef(), { ...settings }, { merge: true })
        .then(() => {
          return dispatchGet().then((response) => {
            dispatch({ type: INTERNAL_SETTINGS_UPDATE_SUCCESS, payload: response });
            return Promise.resolve(response);
          });
        })
        .catch((error) => {
          dispatch({ type: INTERNAL_SETTINGS_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, dispatchGet],
  );
};

/**
 * useDispatchInternalGetNextAndUpdateSubscriptionInvoiceNumber()
 * Get the next invoice number for a subscription and increase the number in internal settings
 */
type GetInvoiceNumberReturnType = () => Promise<string>;
export const useDispatchInternalGetNextAndUpdateSubscriptionInvoiceNumber = (): GetInvoiceNumberReturnType => {
  const dispatchGet = useDispatchInternalGetSettings();
  const dispatchUpdate = useDispatchInternalUpdateSettings();

  return React.useCallback<GetInvoiceNumberReturnType>(() => {
    return dispatchGet().then((settings) => {
      const newInvoiceNumber = settings.subscription.invoiceNumber + 1;
      return dispatchUpdate({ subscription: { ...settings.subscription, invoiceNumber: newInvoiceNumber } }).then(
        () => {
          const invoiceString = `SOTOSUB-${newInvoiceNumber}`;
          return Promise.resolve(invoiceString);
        },
      );
    });
  }, [dispatchGet, dispatchUpdate]);
};
