import React from 'react';
import styled from 'styled-components';
import { TourItem, TourService } from '../../../Globals/Types/Tour';
import { useTranslation } from 'react-i18next';
import DisplayText from '../../View/DisplayText';
import moment from 'moment';

const DisplayRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  line-height: 24px;

  .Caption {
    display: flex;
    align-items: center;
    flex-grow: 1;
    color: ${(props) => props.theme.color.textGray};
    font-size: 13px;
  }
`;

interface Props {
  tourItem: TourItem<TourService>;
}

/**
 * TimeBox()
 * @param props
 * @constructor
 */
export default function TimeBox(props: Props) {
  const { tourItem } = props;
  const [t] = useTranslation();

  const getAssemblyTime = () => {
    return Math.abs(moment(tourItem.endTime, 'HH:mm:ss').diff(moment(tourItem.startTime, 'HH:mm:ss'), 'minute'));
  };

  if (tourItem) {
    return (
      <DisplayText>
        <DisplayRow>
          <div className="Caption">{t('timeStart')}</div>
          <div className="Value">{t('xClock', { time: moment(tourItem.startTime, 'HH:mm:ss').format('HH:mm') })}</div>
        </DisplayRow>
        <DisplayRow>
          <div className="Caption">{t('timeEnd')}</div>
          <div className="Value">{t('xClock', { time: moment(tourItem.endTime, 'HH:mm:ss').format('HH:mm') })}</div>
        </DisplayRow>
        <DisplayRow>
          <div className="Caption">{t('assemblyTime')}</div>
          <div className="Value">{t('xMinute', { count: getAssemblyTime() })}</div>
        </DisplayRow>
      </DisplayText>
    );
  }
  return null;
}
