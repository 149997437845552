import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { Salutation } from '../../Globals/Types/Enums';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (item: Salutation) => void;
  selected: Salutation;
  required?: boolean;
  showSkeleton?: boolean;
}

/**
 * SelectSalutation
 * @param props
 * @constructor
 */
export default function SelectSalutation(props: Props) {
  const { onChange, selected, required, showSkeleton } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>([]);

  React.useEffect(() => {
    let params: SelectOptions = [];

    params.push({ value: Salutation.male, label: t('salutationType.male') });
    params.push({ value: Salutation.female, label: t('salutationType.female') });
    params.push({ value: Salutation.company, label: t('salutationType.company') });

    setOptions(params);
  }, [t]);

  if (options && options.length > 0) {
    return (
      <Select
        label={t('salutation')}
        onChange={onChange}
        initialValue={selected}
        options={options}
        required={required}
        showSkeleton={showSkeleton}
      />
    );
  }
  return null;
}

SelectSalutation.defaultProps = {
  required: false,
  showSkeleton: false,
};
