import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

const OuterContainer = styled.div<{ isPressable: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 15px;
  background-color: ${(props) => props.theme.color.backgroundGray};
  border-color: ${(props) => darken(0.05, props.theme.color.backgroundGray)};
  border-width: 0.5px;
  border-style: solid;
  border-radius: ${(props) => props.theme.border.radius};
  margin-bottom: 10px;
  cursor: ${(props) => (props.isPressable ? 'pointer' : 'default')};

  &:hover {
    background-color: ${(props) => (props.isPressable ? darken(0.1, props.theme.color.backgroundGray) : null)};
  }

  > .Main {
    flex: 1;
  }

  > .Right {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: ${(props) => props.theme.color.textGray};
  }
`;

interface Props {
  children: React.ReactElement | React.ReactElement[] | string;
  onClick?: () => void;
  rightIcon?: string;
}

/**
 * Container()
 * @param props
 * @constructor
 */
export default function Container(props: Props) {
  const { children, onClick, rightIcon } = props;

  const renderRightIcon = () => {
    if (rightIcon) {
      return (
        <div className="Right">
          <i className={rightIcon} />
        </div>
      );
    }
    return null;
  };

  return (
    <OuterContainer isPressable={!!onClick} onClick={onClick ? () => onClick() : () => {}}>
      <div className="Main">{children}</div>
      {renderRightIcon()}
    </OuterContainer>
  );
}
