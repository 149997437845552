import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { DraggableTourData, Tour, TourItem, TourService } from '../../../../Globals/Types/Tour';
import { DispositionListContext } from '../index';
import { useDrag } from 'react-dnd';
import { DragDropType } from '../../../../Globals/Types/DispositionTypes';
import { darken } from 'polished';
import { useGetServiceTypeIcon } from '../../../../Globals/Hooks/ServiceHooks';
import { ReportState } from '../../../../Globals/Types/Report';

const getBackgroundColor = ({ theme, selected, finished }) => {
  if (selected) {
    return finished ? darken(0.1, theme.color.green) : darken(0.1, theme.color.primaryColor);
  }
  return finished ? theme.color.green : theme.color.primaryColor;
};

const Container = styled.div<{
  left: number;
  width: number;
  isDragging: boolean;
  isDraggable: boolean;
  selected: boolean;
  finished: boolean;
}>`
  position: absolute;
  display: ${(props) => (props.isDragging ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  width: ${(props) => props.width}px;
  border-radius: ${(props) => props.theme.border.radius};
  left: ${(props) => props.left}px;
  background-color: ${(props) => getBackgroundColor(props)};
  top: 4px;
  bottom: 4px;
  padding: 0 15px;
  color: #ffffff;
  cursor: ${(props) => (props.isDraggable ? 'pointer' : 'default')};
  ${(props) => props.theme.boxShadowDark};

  .Icon {
    font-size: 20px;
    margin-right: 10px;
  }

  .TextContainer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .Caption {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ExternalId {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .TypeIcon {
    width: 30px;
    display: block;
  }
  .DragIcon {
    width: 30px;
    display: none;
  }

  &:hover {
    background-color: ${(props) => (props.isDraggable ? darken(0.2, props.theme.color.primaryColor) : null)};

    .TypeIcon {
      display: ${(props) => (props.isDraggable ? 'none' : 'block')};
    }
    .DragIcon {
      display: ${(props) => (props.isDraggable ? 'block' : 'none')};
      color: ${(props) => props.theme.color.orange};
    }
  }
`;

interface Props {
  tourItem: TourItem<TourService>;
  tour: Tour;
  onPress: (item: TourService) => void;
  selected: boolean;
}

/**
 * ServiceOverlay()
 * @param props
 * @constructor
 */
export default function ServiceOverlay(props: Props) {
  const { tourItem, tour, onPress, selected } = props;
  const [pixelPerMinute, setPixelPerMinute] = React.useState<number>(0);
  const [left, setLeft] = React.useState<number>();
  const [length, setLength] = React.useState<number>();
  const [isDraggable, setIsDraggable] = React.useState<boolean>(true);
  const context = React.useContext(DispositionListContext);
  const getServiceTypeIcon = useGetServiceTypeIcon();

  const [{ isDragging }, drag] = useDrag<DraggableTourData, any, { isDragging: boolean }>(
    () => ({
      type: DragDropType.SERVICETOUR,
      item: { service: tourItem.data, currentTour: tour },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [tourItem, tour],
  );

  React.useEffect(() => {
    if (tour.closed || tourItem.data.reportState !== ReportState.none) {
      setIsDraggable(false);
    } else {
      setIsDraggable(true);
    }
  }, [tour.closed, tourItem.data.reportState]);

  React.useEffect(() => {
    if (isDragging) {
      context.setDragAssemblyTime(tourItem.data.estimatedAssemblyTime);
    }
  }, [context, isDragging, tourItem.data.estimatedAssemblyTime]);

  React.useEffect(() => {
    setPixelPerMinute(context.hourBoxWidth / 60);
  }, [context.hourBoxWidth]);

  React.useEffect(() => {
    const diff = moment(tourItem.startTime, 'HH:mm').diff(moment(context.startTime, 'HH:mm'), 'minute');
    const itemMinutes = moment(tourItem.endTime, 'HH:mm:ss').diff(moment(tourItem.startTime, 'HH:mm:ss'), 'minute');
    setLeft(diff * pixelPerMinute);
    setLength(pixelPerMinute * itemMinutes);
  }, [context.startTime, pixelPerMinute, tourItem.endTime, tourItem.startTime]);

  const getTimeString = () => {
    const startShort = moment(tourItem.startTime, 'HH:mm:ss').format('HH:mm');
    const endShort = moment(tourItem.endTime, 'HH:mm:ss').format('HH:mm');

    return `${startShort}h - ${endShort}h`;
  };

  const getAddressString = () => {
    const { firstName, lastName, zip, city } = tourItem.data.customer;
    return `${firstName} ${lastName}, ${zip} ${city}`;
  };

  if (left && left > 0) {
    return (
      <Container
        left={left}
        width={length}
        isDragging={isDragging}
        ref={isDraggable ? drag : null}
        onClick={() => onPress(tourItem.data)}
        isDraggable={isDraggable}
        selected={selected}
        finished={tourItem.data.reportState !== ReportState.none}
      >
        <div className="Icon" title={tourItem.data.serviceType.caption}>
          <i className={`${getServiceTypeIcon(tourItem.data.serviceType)} TypeIcon`} />
          <i className={`fa fa-bars DragIcon`} />
        </div>
        <div className="TextContainer">
          <div className="Caption">{tourItem.data.externalOrderId}</div>
          <div className="ExternalId" title={getAddressString()}>
            {getAddressString()}
          </div>
          <div className="ExternalId" title={tourItem.data.externalOrderId}>
            {getTimeString()}
          </div>
        </div>
      </Container>
    );
  }
  return null;
}
