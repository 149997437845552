import React from 'react';
import {
  useDispatchOrderServiceFilter,
  useDispatchOrderServiceSearch,
} from '../../../Redux/Actions/Order/ServiceAction';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import Panel from '../../../Components/Panel';
import ServiceTable from '../../../Components/Order/ServiceTable';
import { ElasticSearchServiceEntity } from '../../../Globals/Types/OrderTypes';
import { useLocation, useNavigate } from 'react-router';
import ServiceFilter from '../../../Components/Order/ServiceFilter';
import Headline from './Components/Headline';
import { useDispatchOrderFilterLoad, useDispatchOrderFilterSet } from '../../../Redux/Actions/Order/FilterAction';
import { buildUrlOrderServiceDetails } from '../../../Globals/UrlFunctions';
import Text from '../../../Components/Text';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSubscriptionIsValid } from '../../../Globals/Hooks/SubscriptionHooks';

/**
 * List
 * @constructor
 */
export default function List() {
  const { filterResult } = useAppSelector((state) => state.order.search);
  const { serviceFilter } = useAppSelector((state) => state.order);
  const { isLoading, setLoading } = useLoadingModal();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const timerRef = React.useRef<NodeJS.Timeout>();
  const { search } = useLocation();
  const loadFilter = new URLSearchParams(search).get('predefined');
  const { subscriptionModal, clickHandler: subscriptionCheckClickHandler } = useSubscriptionIsValid();

  const dispatchFilter = useDispatchOrderServiceFilter(true);
  const dispatchSearch = useDispatchOrderServiceSearch();
  const dispatchLoadFilter = useDispatchOrderFilterLoad();
  const dispatchFilterSet = useDispatchOrderFilterSet(true);

  const handleChangeYear = React.useCallback(
    (year: number) => {
      if (year) {
        const startDate = moment(year, 'YYYY').startOf('year').format('YYYY-MM-DD');
        const endDate = moment(year, 'YYYY').endOf('year').format('YYYY-MM-DD');

        dispatchFilterSet({ ...serviceFilter, dateFrom: startDate, dateTo: endDate });
      }
    },
    [dispatchFilterSet, serviceFilter],
  );

  React.useEffect(() => {
    if (loadFilter === null) {
      dispatchLoadFilter();
    }
  }, [dispatchLoadFilter, loadFilter]);

  React.useEffect(() => {
    setLoading(true);
    timerRef.current = setTimeout(() => {
      dispatchFilter(serviceFilter.dateFrom, serviceFilter.dateTo).finally(() => setLoading(false));
    }, 800);

    return () => clearTimeout(timerRef.current);
  }, [dispatchFilter, serviceFilter.dateFrom, serviceFilter.dateTo, setLoading]);

  const handleSearch = (value: string) => {
    if (value && value.length > 0) {
      setLoading(true);
      dispatchSearch(value).finally(() => setLoading(false));
    }
  };

  const handleOnClearSearch = () => {
    dispatchFilter(serviceFilter.dateFrom, serviceFilter.dateTo).then(() => {});
  };

  const navigateDetails = (service: ElasticSearchServiceEntity) => {
    subscriptionCheckClickHandler(() =>
      navigate(buildUrlOrderServiceDetails(service.ownerClientId, service.orderId, service.serviceId)),
    );
  };

  const renderContent = () => {
    if (filterResult && filterResult.length > 0) {
      return <ServiceTable hideCustomer={true} services={filterResult} onClick={navigateDetails} />;
    }

    if (!isLoading) {
      return (
        <Text light centered>
          {t('modules.order.service.noServicesAvailableForFilter')}
        </Text>
      );
    } else {
      return (
        <Text light centered>
          {t('servicesLoading')}
        </Text>
      );
    }
  };

  return (
    <>
      <Headline
        onChangeSearch={handleSearch}
        onClearSearch={handleOnClearSearch}
        isLoading={isLoading}
        count={filterResult ? filterResult.length : 0}
        onChangeYear={handleChangeYear}
      />
      <div>
        <ServiceFilter />
      </div>
      <div>
        <Panel>{renderContent()}</Panel>
      </div>

      {subscriptionModal}
    </>
  );
}
