import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Panel from '../../../Components/Panel';
import CustomSwitch from '../../../Components/CustomSwitch';
import { useDispatchClientUpdate } from '../../../Redux/Actions/ClientAction';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';

/**
 * Modules()
 * @constructor
 */
export default function Modules() {
  const [t] = useTranslation();
  const [loadingCustomer, setLoadingCustomer] = React.useState<boolean>(false);
  const [loadingInvoice, setLoadingInvoice] = React.useState<boolean>(false);
  const [loadingTimeTracking, setLoadingTimeTracking] = React.useState<boolean>(false);
  const { client } = useAppSelector((state) => state.auth);
  const dispatchUpdateClient = useDispatchClientUpdate();

  const updateCustomer = (value: boolean) => {
    setLoadingCustomer(true);

    dispatchUpdateClient({
      settings: {
        ...client.settings,
        customer: { ...client.settings.customer, active: value },
      },
    }).finally(() => setLoadingCustomer(false));
  };

  const updateInvoice = (value: boolean) => {
    setLoadingInvoice(true);

    dispatchUpdateClient({
      settings: {
        ...client.settings,
        invoice: { ...client.settings.invoice, active: value },
      },
    }).finally(() => setLoadingInvoice(false));
  };

  const updateTimeTracking = (value: boolean) => {
    setLoadingTimeTracking(true);

    dispatchUpdateClient({
      settings: {
        ...client.settings,
        timeTracking: { ...client.settings.timeTracking, active: value },
      },
    }).finally(() => setLoadingTimeTracking(false));
  };

  return (
    <Panel headline={t('modules.settings.modules.headline')} description={t('modules.settings.modules.description')}>
      <Row>
        <Col xl={12}>
          <CustomSwitch
            checked={client.settings.customer ? client.settings.customer.active : false}
            onChange={updateCustomer}
            caption={t('modules.settings.modules.customerHeadline')}
            description={t('modules.settings.modules.customerDescription')}
            loading={loadingCustomer}
          />
          <CustomSwitch
            checked={client.settings.invoice.active}
            onChange={updateInvoice}
            caption={t('modules.settings.modules.invoiceHeadline')}
            description={t('modules.settings.modules.invoiceDescription')}
            loading={loadingInvoice}
          />
          <CustomSwitch
            checked={client.settings.timeTracking.active}
            onChange={updateTimeTracking}
            caption={t('modules.settings.modules.timeTrackingHeadline')}
            description={t('modules.settings.modules.timeTrackingDescription')}
            loading={loadingTimeTracking}
          />
        </Col>
      </Row>
    </Panel>
  );
}

Modules.defaultProps = {
  showSkeleton: false,
};
