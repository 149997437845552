import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { useTranslation } from 'react-i18next';
import { CustomerEntity } from '../../../Globals/Types/Customer';
import {
  customerBuildName,
  customerGetSalutationIcon,
  customerHasAddress,
} from '../../../Globals/Functions/CustomerFunctions';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: ${(props) => props.theme.table.highlight};
  border-radius: ${(props) => props.theme.border.radius};
  margin-bottom: 6px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.table.highlight)};
  }

  > .Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 20px;
  }

  > .Left {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  > .Right {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: ${(props) => props.theme.color.textGray};
  }
`;

interface Props {
  customer: CustomerEntity;
  onSelect: (customer: CustomerEntity) => void;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { customer, onSelect } = props;
  const [t] = useTranslation();

  const renderAddress = () => {
    if (customerHasAddress(customer)) {
      return `${customer.zip} ${customer.city}`;
    }
    return null;
  };

  return (
    <Container onClick={() => onSelect(customer)}>
      <div className="Icon">
        <i className={customerGetSalutationIcon(customer)} />
      </div>
      <div className="Left">
        <div>{customerBuildName(customer, false, t)}</div>
        <small className="text-muted">{renderAddress()}</small>
      </div>
      <div className="Right">
        <i className="fas fa-angle-right" />
      </div>
    </Container>
  );
}
