import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createPromise } from 'redux-promise-middleware';
import throttle from 'lodash/throttle';
import { createLogger } from 'redux-logger';
import { loadState, saveState } from './LocalStorage';
import Config from '../Globals/Config';
import { Environment } from '../Globals/Types/Enums';
import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './Reducer/AuthReducer';
import ClientReducer from './Reducer/ClientReducer';
import UserReducer from './Reducer/UserReducer';
import CalendarReducer from './Reducer/CalendarReducer';
import StorageReducer from './Reducer/StorageReducer';
import CacheReducer from './Reducer/CacheReducer';
import OrderReducer from './Reducer/OrderReducer';
import DispositionReducer from './Reducer/DispositionReducer';
import DashboardReducer from './Reducer/DashboardReducer';

let middleware;

/**
 * Add logger only on dev or staging environment.
 * On production environment no console output should be generated
 */
if (Config.env !== Environment.production) {
  const logger = createLogger({});
  middleware = applyMiddleware(createPromise(), thunk, logger);
} else {
  middleware = applyMiddleware(createPromise(), thunk);
}

const persistState = loadState();

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    cache: CacheReducer,
    calendar: CalendarReducer,
    client: ClientReducer,
    dashboard: DashboardReducer,
    disposition: DispositionReducer,
    order: OrderReducer,
    storage: StorageReducer,
    user: UserReducer,
  },
  enhancers: [middleware],
  preloadedState: persistState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable: (value: any) => true,
      },
    }),
});

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000),
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
