import React from 'react';
import { AccessPath, useHasAccess } from '../../Globals/Access/Groups';
import DashboardBox from './Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { useNavigate } from 'react-router';

/**
 * InvoiceCountBox()
 * @constructor
 */
export default function InvoiceCountBox() {
  const navigate = useNavigate();
  const { open } = useAppSelector((state) => state.client.invoices);
  const { client } = useAppSelector((state) => state.auth);
  const [t] = useTranslation();
  const theme = useTheme();
  const isAllowed = useHasAccess(AccessPath.invoices);
  const [visible, setVisible] = React.useState<boolean>(null);

  React.useEffect(() => {
    setVisible(isAllowed && client.settings && client.settings.invoice && client.settings.invoice.active);
  }, [client.settings, isAllowed]);

  if (visible) {
    return (
      <DashboardBox
        headline={t('openInvoices')}
        icon="fas fa-list-check"
        bgColor={theme.color.purple}
        onClick={() => navigate('/invoices/list')}
      >
        {open ? open.length : 0}
      </DashboardBox>
    );
  }
  return null;
}
