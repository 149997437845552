import React from 'react';
import CreateInvoice from '../../../Components/Invoice/Create';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { InvoiceEntity } from '../../../Globals/Types/Invoice';
import { useNavigate, useParams } from 'react-router';
import { buildUrlCustomersInvoiceDetails } from '../../../Globals/UrlFunctions';
import { useDispatchInvoiceGet } from '../../../Redux/Actions/Client/InvoiceAction';

/**
 * Create()
 * @constructor
 */
export default function Create() {
  const navigate = useNavigate();
  const { customerId, invoiceId } = useParams();
  const [invoice, setInvoice] = React.useState<InvoiceEntity>(null);
  const { customer } = useAppSelector((state) => state.client.customers.selectedCustomer);
  const dispatchGetInvoice = useDispatchInvoiceGet();

  React.useEffect(() => {
    if (invoiceId) {
      dispatchGetInvoice(invoiceId)
        .then(setInvoice)
        .finally(() => {});
    }
  }, [dispatchGetInvoice, invoiceId]);

  const navigateDetails = (invoice: InvoiceEntity) => {
    navigate(buildUrlCustomersInvoiceDetails(customerId, invoice.invoiceId));
  };

  if (!invoiceId) {
    return <CreateInvoice customer={customer} disableNavigateBack={true} onFinish={navigateDetails} />;
  }
  if (invoiceId && invoice) {
    return <CreateInvoice voucherInvoice={invoice} disableNavigateBack={true} onFinish={navigateDetails} />;
  }
  return null;
}
