import React from 'react';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { useDispatchCheckFacility } from '../../Redux/Actions/Client/FacilityAction';
import { Alert } from 'react-bootstrap';
import Panel from '../Panel';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { buildUrlSettingsFacilityDetails } from '../../Globals/UrlFunctions';
import { AccessPath, useHasAccess } from '../../Globals/Access/Groups';

const AlertContainer = styled(Alert)`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  .Left {
    flex: 1;
  }
  .Right {
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

/**
 * AlertUpdateFacilityData
 * @constructor
 */
export default function AlertUpdateFacilityData() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const hasAccess = useHasAccess(AccessPath.admin);
  const { facilities } = useAppSelector((state) => state.client);
  const { isFacilityValid } = useDispatchCheckFacility();

  const checkFacilities = () => {
    let data = [];

    facilities.forEach((facility) => {
      if (!isFacilityValid(facility)) {
        data.push(
          <AlertContainer
            variant="danger"
            onClick={() => navigate(buildUrlSettingsFacilityDetails(facility.facilityId))}
            key={facility.facilityId}
          >
            <div className="Left">
              <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
              {t('modules.settings.facility.errorUpdateData', { name: facility.name })}
            </div>
            <div className="Right">
              <i className="fas fa-arrow-right" />
            </div>
          </AlertContainer>,
        );
      }
    });

    if (data && data.length > 0) {
      return (
        <div>
          <Panel>{data}</Panel>
        </div>
      );
    }
    return null;
  };

  if (hasAccess) {
    return checkFacilities();
  }
  return null;
}
