import React from 'react';
import styled from 'styled-components';
import { Navigate, Outlet } from 'react-router-dom';
import MainNavigation from './NewNavigation/MainNavigation';
import { useAppSelector } from '../../Globals/Hooks/Hooks';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #f2f4f7;

  .MainNavigation {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    background-color: #ffffff;
    z-index: 101;
    ${(props) => props.theme.boxShadowDark}
  }

  > .Content {
    display: flex;
    flex: 1;
    max-width: 100%;
    flex-direction: column;
    padding: 20px 20px 20px 120px;
    background-color: #f2f4f7;
  }
`;

/**
 * AuthedLayout()
 * @constructor
 */
export default function AuthedLayout() {
  const { isLoggedIn } = useAppSelector((state) => state.auth);

  if (isLoggedIn) {
    return (
      <Container>
        <div className="MainNavigation">
          <MainNavigation />
        </div>
        <div className="Content">
          <Outlet />
        </div>
      </Container>
    );
  }
  return <Navigate replace to={'/public/startpage'} />;
}
