import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { DeliveryAndLoadingOptions as Types } from '../../Globals/Types/Enums';

interface Props {
  hasError?: boolean;
  initialValue: Types;
  label: string;
  marginBottom?: number;
  onChange: (value: Types) => void;
  disabled: boolean;
  containerStyle?: object;
}

/**
 * SelectDeliveryAndLoadingOption
 * @param props
 * @constructor
 */
export default function SelectDeliveryAndLoadingOption(props: Props) {
  const { hasError, initialValue, label, marginBottom, onChange, disabled, containerStyle } = props;
  const [t] = useTranslation();
  const [options] = React.useState<SelectOptions>([
    {
      label: t(`deliveryAndLoadingOptions.${Types.deliveryIsCustomer}`),
      value: Types.deliveryIsCustomer,
    },
    {
      label: t(`deliveryAndLoadingOptions.${Types.deliveryIsCustomerAndLoading}`),
      value: Types.deliveryIsCustomerAndLoading,
    },
    {
      label: t(`deliveryAndLoadingOptions.${Types.loadingIsCustomerAndDelivery}`),
      value: Types.loadingIsCustomerAndDelivery,
    },
    {
      label: t(`deliveryAndLoadingOptions.${Types.loadingAndDelivery}`),
      value: Types.loadingAndDelivery,
    },
  ]);

  if (options) {
    return (
      <Select
        label={label}
        onChange={onChange}
        initialValue={initialValue}
        options={options}
        hasError={hasError}
        marginBottom={marginBottom}
        disabled={disabled}
        containerStyle={containerStyle}
      />
    );
  }

  return null;
}

SelectDeliveryAndLoadingOption.defaultProps = {
  disabled: false,
};
