import React from 'react';
import PageHeader from '../../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import { initialState, reducer } from './Reducer';
import Panel from '../../../Components/Panel';
import SelectSalutation from '../../../Components/PredefinedSelects/SelectSaltutation';
import { Button, Col, Row } from 'react-bootstrap';
import Input from '../../../Components/Inputs/Input';
import { Salutation } from '../../../Globals/Types/Enums';
import SelectCountry from '../../../Components/PredefinedSelects/SelectCountry';
import SelectFloor from '../../../Components/PredefinedSelects/SelectFloor';
import { buildUrlCustomersDetails, UrlPatterns } from '../../../Globals/UrlFunctions';
import { useNavigate, useParams } from 'react-router';
import { validateCustomer } from './ValidateCustomer';
import {
  useDispatchCustomerCreate,
  useDispatchCustomerGet,
  useDispatchCustomerUpdate,
} from '../../../Redux/Actions/CustomerAction';
import { CustomerEntity } from '../../../Globals/Types/Customer';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import ErrorFormInvalidMessage from '../../../Components/Modals/ErrorFormInvalidMessage';
import SelectFacility from '../../../Components/PredefinedSelects/SelectFacility';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import SelectPaymentType from '../../../Components/PredefinedSelects/SelectPaymentType';

/**
 * CreateOrUpdate
 * @constructor
 */
export default function CreateOrUpdate() {
  const { facilities } = useAppSelector((state) => state.client);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { isLoading, setLoading } = useLoadingModal();
  const { setLoading: setSaving, loadingModal: savingModal } = useLoadingModal();
  const [showInvalid, setShowInvalid] = React.useState<boolean>(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const dispatchCreate = useDispatchCustomerCreate();
  const dispatchUpdate = useDispatchCustomerUpdate();
  const dispatchGet = useDispatchCustomerGet();

  const handleNavigateBack = React.useCallback(
    (customerIdParam?: string) => {
      if (state.isEdit) {
        navigate(buildUrlCustomersDetails(customerId));
      } else {
        if (customerIdParam) {
          navigate(buildUrlCustomersDetails(customerIdParam));
        } else {
          navigate(UrlPatterns.customers);
        }
      }
    },
    [customerId, navigate, state.isEdit],
  );

  const handleSave = React.useCallback(() => {
    const validationResult = validateCustomer(state);

    if (validationResult.isValid) {
      setSaving(true);
      let promise: Promise<CustomerEntity>;
      if (state.isEdit) {
        promise = dispatchUpdate(state.customer);
      } else {
        promise = dispatchCreate(state.customer);
      }

      promise.then((customer) => handleNavigateBack(customer.customerId)).finally(() => setSaving(false));
    } else {
      dispatch({ type: 'setErrors', payload: validationResult.errors });
      setShowInvalid(true);
    }
  }, [dispatchCreate, dispatchUpdate, handleNavigateBack, setSaving, state]);

  React.useEffect(() => {
    if (customerId) {
      setLoading(true);
      dispatchGet(customerId)
        .then((customerResponse) => {
          dispatch({ type: 'init', payload: customerResponse });
        })
        .finally(() => setLoading(false));
    } else {
      dispatch({ type: 'initEmpty', payload: facilities[0].facilityId });
    }
  }, [customerId, dispatchGet, facilities, setLoading]);

  const getHeadline = () => (state.isEdit ? t('customerUpdate') : t('customerCreate'));

  const renderCancelButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => handleNavigateBack()}>
        {t('cancel')}
      </Button>
    );
  };

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
        {t('save')}
      </Button>
    );
  };

  return (
    <>
      <PageHeader
        headline={getHeadline()}
        actionButtonTwo={renderSaveButton()}
        actionButtonThree={renderCancelButton()}
      />

      <Panel headline={t('basicData')}>
        <Row>
          <Col lg={6}>
            <SelectSalutation
              onChange={(value) => dispatch({ type: 'salutation', payload: value })}
              selected={state.customer.salutation}
              showSkeleton={isLoading}
            />
          </Col>
          <ShowHideContainer visible={state.customer.salutation !== Salutation.company}>
            <Col lg={6}>
              <Input
                onChange={(value) => dispatch({ type: 'prefix', payload: value })}
                label={t('prefix')}
                value={state.customer.prefix}
                showSkeleton={isLoading}
              />
            </Col>
          </ShowHideContainer>
          <ShowHideContainer visible={state.customer.salutation !== Salutation.company}>
            <Col lg={6}>
              <Input
                onChange={(value) => dispatch({ type: 'firstName', payload: value })}
                value={state.customer.firstName}
                label={t('firstName')}
                visible={state.customer.salutation !== Salutation.company}
                hasError={!!state.errors.firstName}
                showSkeleton={isLoading}
                autoFocus
              />
            </Col>
          </ShowHideContainer>
          <ShowHideContainer visible={state.customer.salutation !== Salutation.company}>
            <Col lg={6}>
              <Input
                onChange={(value) => dispatch({ type: 'lastName', payload: value })}
                value={state.customer.lastName}
                label={t('lastName')}
                visible={state.customer.salutation !== Salutation.company}
                hasError={!!state.errors.lastName}
                showSkeleton={isLoading}
              />
            </Col>
          </ShowHideContainer>
          <ShowHideContainer visible={state.customer.salutation === Salutation.company}>
            <Col lg={6}>
              <Input
                onChange={(value) => dispatch({ type: 'companyName', payload: value })}
                value={state.customer.companyName}
                label={t('companyName')}
                visible={state.customer.salutation === Salutation.company}
                hasError={!!state.errors.companyName}
                showSkeleton={isLoading}
              />
            </Col>
          </ShowHideContainer>
          <Col lg={6}>
            <Input
              onChange={(value) => dispatch({ type: 'taxNumber', payload: value })}
              value={state.customer.taxNumber}
              label={t('taxNumber')}
              hasError={!!state.errors.taxNumber}
              showSkeleton={isLoading}
            />
          </Col>
          <ShowHideContainer visible={state.customer.salutation !== Salutation.company}>
            <Col lg={6}>
              <Input
                label={t('jobTitle')}
                onChange={(value) => dispatch({ type: 'jobTitle', payload: value })}
                value={state.customer.jobTitle}
                visible={state.customer.salutation !== Salutation.company}
                showSkeleton={isLoading}
              />
            </Col>
          </ShowHideContainer>
          <ShowHideContainer visible={state.customer.salutation !== Salutation.company}>
            <Col lg={6}>
              <Input
                label={t('birthdate')}
                onChange={(value) => dispatch({ type: 'birthdate', payload: value })}
                value={state.birthdateHelper}
                visible={state.customer.salutation !== Salutation.company}
                showSkeleton={isLoading}
                hasError={!!state.errors.birthdate}
              />
            </Col>
          </ShowHideContainer>
          <ShowHideContainer visible={facilities.length > 1}>
            <Col lg={6}>
              <SelectFacility
                onChange={(value) => dispatch({ type: 'defaultFacilityId', payload: value })}
                selected={state.customer.defaultFacilityId}
                showSkeleton={isLoading}
                visible={facilities.length > 1}
              />
            </Col>
          </ShowHideContainer>
          <ShowHideContainer visible={state.customer.salutation === Salutation.company}>
            <Col lg={6}>
              <Input
                label={t('contactName')}
                onChange={(value) => dispatch({ type: 'contactName', payload: value })}
                value={state.customer.contactName}
                showSkeleton={isLoading}
              />
            </Col>
          </ShowHideContainer>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col lg={9}>
            <Input
              label={t('street')}
              onChange={(value) => dispatch({ type: 'street', payload: value })}
              value={state.customer.street}
              showSkeleton={isLoading}
              hasError={!!state.errors.street}
            />
          </Col>
          <Col lg={3}>
            <Input
              label={t('streetNo')}
              onChange={(value) => dispatch({ type: 'streetNo', payload: value })}
              value={state.customer.streetNo}
              showSkeleton={isLoading}
              hasError={!!state.errors.streetNo}
            />
          </Col>
          <Col lg={3}>
            <Input
              label={t('zip')}
              onChange={(value) => dispatch({ type: 'zip', payload: value })}
              value={state.customer.zip}
              showSkeleton={isLoading}
              hasError={!!state.errors.zip}
            />
          </Col>
          <Col lg={9}>
            <Input
              label={t('city')}
              onChange={(value) => dispatch({ type: 'city', payload: value })}
              value={state.customer.city}
              showSkeleton={isLoading}
              hasError={!!state.errors.city}
            />
          </Col>
          <Col lg={6}>
            <SelectFloor
              onChange={(value) => dispatch({ type: 'floor', payload: value })}
              selected={state.customer.floor}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <SelectCountry
              onChange={(value) => dispatch({ type: 'country', payload: value })}
              selected={state.customer.country}
              showSkeleton={isLoading}
            />
          </Col>
        </Row>
      </Panel>

      <Panel headline={t('contacts')}>
        <Row>
          <Col lg={6}>
            <Input
              label={t('phonePrivate')}
              onChange={(value) => dispatch({ type: 'phonePrivate', payload: value })}
              value={state.customer.phonePrivate}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label={t('phoneBusiness')}
              onChange={(value) => dispatch({ type: 'phoneBusiness', payload: value })}
              value={state.customer.phoneBusiness}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label={t('mobilePrivate')}
              onChange={(value) => dispatch({ type: 'mobilePrivate', payload: value })}
              value={state.customer.mobilePrivate}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label={t('mobileBusiness')}
              onChange={(value) => dispatch({ type: 'mobileBusiness', payload: value })}
              value={state.customer.mobileBusiness}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label={t('mailPrivate')}
              onChange={(value) => dispatch({ type: 'mailPrivate', payload: value })}
              value={state.customer.mailPrivate}
              hasError={!!state.errors.mailPrivate}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label={t('mailBusiness')}
              onChange={(value) => dispatch({ type: 'mailBusiness', payload: value })}
              value={state.customer.mailBusiness}
              hasError={!!state.errors.mailBusiness}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label={t('website')}
              onChange={(value) => dispatch({ type: 'website', payload: value })}
              value={state.customer.website}
              showSkeleton={isLoading}
              hasError={!!state.errors.website}
            />
          </Col>
        </Row>
      </Panel>

      <Panel headline={t('socialMedia')}>
        <Row>
          <Col lg={6}>
            <Input
              label="WhatsApp"
              onChange={(value) => dispatch({ type: 'whatsApp', payload: value })}
              value={state.customer.whatsApp}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label="Facebook"
              onChange={(value) => dispatch({ type: 'facebook', payload: value })}
              value={state.customer.facebook}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label="Instagram"
              onChange={(value) => dispatch({ type: 'instagram', payload: value })}
              value={state.customer.instagram}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label="TikTok"
              onChange={(value) => dispatch({ type: 'tikTok', payload: value })}
              value={state.customer.tikTok}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label="Skype"
              onChange={(value) => dispatch({ type: 'skype', payload: value })}
              value={state.customer.skype}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label="Twitter"
              onChange={(value) => dispatch({ type: 'twitter', payload: value })}
              value={state.customer.twitter}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label="Xing"
              onChange={(value) => dispatch({ type: 'xing', payload: value })}
              value={state.customer.xing}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              label="LinkedIn"
              onChange={(value) => dispatch({ type: 'linkedIn', payload: value })}
              value={state.customer.linkedIn}
              showSkeleton={isLoading}
            />
          </Col>
        </Row>
      </Panel>

      <Panel headline={t('bankInformation')}>
        <Row>
          <Col lg={6}>
            <Input
              label={t('bankName')}
              onChange={(value) => dispatch({ type: 'bankName', payload: value })}
              value={state.customer.bankName}
              showSkeleton={isLoading}
              hasError={!!state.errors.bankName}
            />
          </Col>
          <Col lg={6}>
            <Input
              label={t('bankIban')}
              onChange={(value) => dispatch({ type: 'bankIban', payload: value })}
              value={state.customer.bankIban}
              showSkeleton={isLoading}
              hasError={!!state.errors.bankIban}
            />
          </Col>
          <Col lg={6}>
            <Input
              label={t('bankBic')}
              onChange={(value) => dispatch({ type: 'bankBic', payload: value })}
              value={state.customer.bankBic}
              showSkeleton={isLoading}
              hasError={!!state.errors.bankBic}
            />
          </Col>
          <Col lg={6}>
            <SelectPaymentType
              caption={t('paymentTypeDefault')}
              showSkeleton={isLoading}
              selected={state.customer.defaultPaymentType}
              onChange={(value) => dispatch({ type: 'defaultPaymentType', payload: value })}
            />
          </Col>
        </Row>
      </Panel>

      {savingModal}

      <ErrorFormInvalidMessage onClose={() => setShowInvalid(false)} visible={showInvalid} />
    </>
  );
}
