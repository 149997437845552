import React from 'react';
import { ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import Panel from '../../../../Panel';
import DisplayText from '../../../../View/DisplayText';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import EditModal, { StateData } from './EditModal';
import { formatWeek } from '../../../../../Globals/Functions';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';

interface Props {
  service: ServiceEntity;
  onChange: (item: StateData) => void;
  allowEdit: boolean;
}

/**
 * ExecutionContainer
 * @param props
 * @constructor
 */
export default function ExecutionContainer(props: Props) {
  const { service, onChange, allowEdit } = props;
  const [t] = useTranslation();
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const { allowEditAll, allowEditAppointments } = useAppSelector((state) => state.order.selectedOrder.permissions);
  const [canEdit] = React.useState<boolean>(allowEditAll || allowEditAppointments);

  const handleOnChange = (data: StateData) => {
    setShowEdit(false);
    onChange(data);
  };

  const getNoValue = () => <i>Keine Angabe</i>;

  const getExecutionDate = () => (service.desiredDate ? moment(service.desiredDate).format('LLL') : getNoValue());

  const getExecutionTime = () =>
    service.desiredExecutionTime ? t('xClock', { time: service.desiredExecutionTime }) : getNoValue();

  const getAssemblyTime = () =>
    service.estimatedAssemblyTime ? t('xMinutes', { minute: service.estimatedAssemblyTime }) : getNoValue();

  return (
    <Panel
      headline={t('appointmentsAndTimesFor', { typeName: service.type.caption })}
      onEdit={canEdit && allowEdit ? () => setShowEdit(true) : null}
    >
      <Row>
        <Col lg={6}>
          <DisplayText caption={t('desiredDate')}>{getExecutionDate()}</DisplayText>
        </Col>
        <Col lg={6}>
          <DisplayText caption={t('desiredWeek')}>
            {service.desiredWeek ? formatWeek(service.desiredWeek) : <i>Keine Angabe</i>}
          </DisplayText>
        </Col>
        <Col lg={6}>
          <DisplayText caption={t('estimatedAssemblyTimeInMinutes')}>{getAssemblyTime()}</DisplayText>
        </Col>
        <Col lg={6}>
          <DisplayText caption={t('arrivalAtCustomer')}>{getExecutionTime()}</DisplayText>
        </Col>
        <Col lg={12}>
          <DisplayText caption={t('modules.order.montageHint')}>{service.comment || getNoValue()}</DisplayText>
        </Col>
      </Row>

      <EditModal service={service} onSave={handleOnChange} onClose={() => setShowEdit(false)} visible={showEdit} />
    </Panel>
  );
}
