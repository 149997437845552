import React from 'react';
import styled from 'styled-components';
import { TourItem, TourService } from '../../../Globals/Types/Tour';
import { useGetServiceTypeIcon } from '../../../Globals/Hooks/ServiceHooks';
import moment from 'moment';
import { ReportState } from '../../../Globals/Types/Report';
import { darken } from 'polished';

const getBackgroundColor = ({ theme, finished }, hover) => {
  if (hover) {
    return finished ? darken(0.1, theme.color.green) : darken(0.2, theme.color.primaryColor);
  }
  return finished ? theme.color.green : theme.color.primaryColor;
};

const getTextColor = ({ theme, finished }) => {
  return '#ffffff';
};

const Container = styled.div<{ finished: boolean }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  border-radius: ${(props) => props.theme.border.radius};
  padding: 6px 10px;
  ${(props) => props.theme.boxShadowDark};
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${(props) => getBackgroundColor(props, false)};
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  .Left {
    margin-right: 10px;
    color: ${(props) => getTextColor(props)};
  }

  .Right {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${(props) => getTextColor(props)};

    .Time {
      font-weight: lighter;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .Infos {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        color: ${(props) => getTextColor(props)};
      }
    }
  }

  &:hover {
    background-color: ${(props) => getBackgroundColor(props, true)};
    color: ${(props) => getTextColor(props)};

    .Left {
      color: ${(props) => props.theme.color.orange};
    }
  }

  &.Selected {
    background-color: ${(props) => getBackgroundColor(props, true)};
    color: ${(props) => getTextColor(props)};

    .Left {
      color: ${(props) => props.theme.color.orange};
    }
  }
`;

interface Props {
  data: TourItem<TourService>;
  onPress: (tourService: TourService) => void;
  selected: boolean;
}

/**
 * InfoContainer()
 * @param props
 * @constructor
 */
export default function InfoContainer(props: Props) {
  const { data, onPress, selected } = props;
  const getIcon = useGetServiceTypeIcon();

  const renderTime = () => {
    return `${moment(data.startTime, 'HH:mm:ss').format('HH:mm')} - ${moment(data.endTime, 'HH:mm:ss').format(
      'HH:mm',
    )}`;
  };

  return (
    <Container
      className={selected ? 'Selected' : null}
      onClick={() => onPress(data.data)}
      finished={data.data.reportState !== ReportState.none}
    >
      <div className="Left">
        <i className={getIcon(data.data.serviceType)} />
      </div>
      <div className="Right">
        <div className="Time">{renderTime()}</div>
        <div className="Infos">
          <strong>{data.data.externalOrderId},</strong>
          <span>{` ${data.data.customer.zip} ${data.data.customer.city}`}</span>
        </div>
      </div>
    </Container>
  );
}
