import React from 'react';
import { InvoicePositionEntity } from '../../../../Globals/Types/Invoice';
import styled from 'styled-components';
import { darken } from 'polished';
import { formatCurrency } from '../../../../Globals/Functions';
import AskDeleteModal from '../../../Modals/AskDeleteModal';
import PositionModal from './PositionModal';

const Container = styled.div<{ isVoucher: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 15px;
  background-color: ${(props) => props.theme.color.backgroundGray};
  border-radius: ${(props) => props.theme.border.radius};
  border-color: ${(props) => darken(0.05, props.theme.color.backgroundGray)};
  border-width: 0.5px;
  border-style: solid;
  margin-bottom: 10px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.color.backgroundGray)};
  }

  > .Icon {
    display: flex;
    align-items: flex-start;
    font-size: 30px;
    margin-right: 15px;
    color: ${(props) => props.theme.color.textGray};
  }

  .MiddleContainer {
    justify-content: center;
    flex: 1;

    .Caption {
    }

    .Description {
      color: ${(props) => props.theme.color.textGray};
    }
  }

  .Price {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-right: 30px;
    margin-left: 30px;
    color: ${(props) => (props.isVoucher ? props.theme.color.green : props.theme.color.text)};
  }

  .Action {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.color.textGray};

    &:hover {
      color: ${(props) => props.theme.color.red};
    }
  }
`;

interface Props {
  position: InvoicePositionEntity;
  onDelete: (position: InvoicePositionEntity) => void;
  onUpdate: (position: InvoicePositionEntity) => void;
}

/**
 * Position()
 * @param props
 * @constructor
 */
export default function Position(props: Props) {
  const { position, onDelete, onUpdate } = props;
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const handleAskDelete = (event: any) => {
    setAskDelete(true);
    event.stopPropagation();
  };

  const handleDelete = () => {
    onDelete(position);
    setAskDelete(false);
  };

  const handleUpdate = (positionParam: InvoicePositionEntity) => {
    onUpdate(positionParam);
    setShowModal(false);
  };

  const formatPrice = () => {
    if (position.isVoucher) {
      return `- ${formatCurrency(position.overallPrice)}`;
    }
    return formatCurrency(position.overallPrice);
  };

  return (
    <>
      <Container onClick={() => setShowModal(true)} isVoucher={position.isVoucher}>
        <div className="Icon">
          <i className={`far fa-circle-${position.positionNumber}`} />
        </div>
        <div className="MiddleContainer">
          <div className="Caption">
            {position.amount}x {position.caption}
          </div>
          <div className="Description">{position.description}</div>
        </div>
        <div className="Price">{formatPrice()}</div>
        <div className="Action" onClick={handleAskDelete}>
          <i className="fas fa-trash-alt" />
        </div>
      </Container>

      <AskDeleteModal onDelete={handleDelete} onClose={() => setAskDelete(false)} visible={askDelete} />
      <PositionModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        position={position}
        onSave={handleUpdate}
      />
    </>
  );
}
