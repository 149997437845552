import { MailTemplate } from '../../../../../Globals/Types/Mail';
import { CountryCode } from '../../../../../Globals/Types/Enums';

export enum TemplateErrorCodes {
  'DEFAULT_TEMPLATE_MISSING' = 'DEFAULT_TEMPLATE_MISSING',
  'DEFAULT_SUBJECT_MISSING' = 'DEFAULT_SUBJECT_MISSING',
  'DEFAULT_MESSAGE_MISSING' = 'DEFAULT_MESSAGE_MISSING',
  'LANGUAGE_SUBJECT_MISSING' = 'LANGUAGE_SUBJECT_MISSING',
  'LANGUAGE_MESSAGE_MISSING' = 'LANGUAGE_MESSAGE_MISSING',
}

interface ValidateResult {
  isValid: boolean;
  errorCode?: string;
}

/**
 * validateMailTemplate()
 * @param template
 * @param defaultLanguage
 */
const validateMailTemplate = (template: MailTemplate, defaultLanguage: CountryCode): ValidateResult => {
  const foundDefault = template.templates.find((item) => item.countryCode === defaultLanguage);
  if (!foundDefault) {
    return { isValid: false, errorCode: TemplateErrorCodes.DEFAULT_TEMPLATE_MISSING };
  } else {
    if (!foundDefault.subject || foundDefault.subject.length <= 0) {
      return { isValid: false, errorCode: TemplateErrorCodes.DEFAULT_SUBJECT_MISSING };
    }
    if (!foundDefault.message || foundDefault.message.length <= 0) {
      return { isValid: false, errorCode: TemplateErrorCodes.DEFAULT_MESSAGE_MISSING };
    }
  }

  const filtered = template.templates.filter((item) => item.countryCode !== defaultLanguage);

  let errorResult: ValidateResult = null;
  filtered.forEach((item) => {
    if ((item.subject && item.subject.length > 0) || (item.message && item.message.length > 0)) {
      if (item.subject && item.subject.length > 0 && (!item.message || item.message.length <= 0)) {
        errorResult = { isValid: false, errorCode: TemplateErrorCodes.LANGUAGE_MESSAGE_MISSING };
      }
      if (item.message && item.message.length > 0 && (!item.subject || item.subject.length <= 0)) {
        errorResult = { isValid: false, errorCode: TemplateErrorCodes.LANGUAGE_SUBJECT_MISSING };
      }
    }
  });

  if (errorResult) {
    return errorResult;
  }
  return { isValid: true };
};

export default validateMailTemplate;
