import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../Components/PageHeader';
import Panel from '../../../Components/Panel';
import { Button, Col, Row } from 'react-bootstrap';
import Input, { InputType } from '../../../Components/Inputs/Input';
import { FacilityEntity, GroupType, UserEntity } from '../../../Globals/Types/Types';
import Select from '../../../Components/Inputs/Select';
import { selectOptionActive } from '../../../Globals/Types/General';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { CreateClientValidationError } from './Components/ValidateCreateUser';
import { useNavigate, useParams } from 'react-router';
import GroupsForm from './Components/GroupsForm';
import { useDispatchUserGet, useDispatchUserUpdate } from '../../../Redux/Actions/UserAction';
import ValidateUpdateUser, { UpdateClientValidationError } from './Components/ValidateUpdateUser';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import BottomInternalIdContainer from '../../../Components/BottomInternalIdContainer';
import DeleteButton from './Components/DeleteButton';
import { userDisplayName } from '../../../Globals/Functions';
import FacilitiesForm from '../../../Components/FacilitiesForm';
import { CountryCode, UserLoginPermission } from '../../../Globals/Types/Enums';
import SelectCountry from '../../../Components/PredefinedSelects/SelectCountry';
import SelectAccessCode from '../../../Components/PredefinedSelects/SelectAccessCode';
import SelectUserLoginPermission from '../../../Components/PredefinedSelects/SelectUserLoginPermission';

export type State = {
  user: UserEntity;
  errors: UpdateClientValidationError;
  initialized: Boolean;
  hasChanges: Boolean;
};

type Action =
  | { type: 'update'; payload: { key: keyof UserEntity; value: string | number } }
  | { type: 'facilityId'; payload: string }
  | { type: 'updateGroup'; payload: { value: boolean; groupItem: GroupType } }
  | { type: 'updateFacilities'; payload: { value: boolean; facilityEntity: FacilityEntity } }
  | { type: 'setErrors'; payload: UpdateClientValidationError }
  | { type: 'init'; payload: UserEntity };

const initialState: State = {
  user: {
    countryCode: CountryCode.ES,
    mail: '',
    firstName: '',
    lastName: '',
    initials: '',
    clientId: '',
    facilityId: '',
    authId: '',
    devices: [],
    createdDate: null,
    active: true,
    groups: [],
    facilities: [],
    note: '',
    changePassword: false,
    accessCode: 0,
    loginPermission: UserLoginPermission.both,
  },
  errors: {
    user: {} as UserEntity,
  } as UpdateClientValidationError,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update': {
      const { key, value } = action.payload;
      return { ...state, user: { ...state.user, [key]: value }, hasChanges: true };
    }
    case 'facilityId': {
      const isChecked = state.user.facilities.indexOf(action.payload) > -1;
      const facilities = !isChecked ? [...state.user.facilities, action.payload] : state.user.facilities;
      return {
        ...state,
        user: {
          ...state.user,
          facilityId: action.payload,
          facilities,
        },
        hasChanges: true,
      };
    }
    case 'updateGroup': {
      const { value, groupItem } = action.payload;
      if (value) {
        return { ...state, user: { ...state.user, groups: [...state.user.groups, groupItem.key] }, hasChanges: true };
      }
      return {
        ...state,
        user: {
          ...state.user,
          groups: state.user.groups.filter((param) => groupItem.key !== param),
        },
        hasChanges: true,
      };
    }
    case 'updateFacilities': {
      const { value, facilityEntity } = action.payload;
      if (value) {
        return {
          ...state,
          user: {
            ...state.user,
            facilities: [...state.user.facilities, facilityEntity.facilityId],
          },
          hasChanges: true,
        };
      }
      return {
        ...state,
        user: {
          ...state.user,
          facilities: state.user.facilities.filter((param) => facilityEntity.facilityId !== param),
        },
        hasChanges: true,
      };
    }
    case 'init': {
      return {
        ...state,
        user: {
          ...action.payload,
          facilities: action.payload.facilities || [],
        },
        initialized: true,
        hasChanges: false,
        errors: {
          user: {},
        } as CreateClientValidationError,
      };
    }
    case 'setErrors':
      return { ...state, errors: action.payload };
  }
};

/**
 * Update()
 * @constructor
 */
export default function Update() {
  const { adminUserId } = useAppSelector((state) => state.auth.client);
  const { facilities } = useAppSelector((state) => state.client);
  const { all: users } = useAppSelector((state) => state.client.users);
  const [selectOptionsFacility, setSelectOptionsFacility] =
    React.useState<Array<{ value: string; label: string }>>(null);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { setLoading, isLoading } = useLoadingModal({ delayStart: 1000 });
  const { setLoading: setInitiallyLoading, isLoading: isInitiallyLoading } = useLoadingModal({ initialValue: true });
  const [headline, setHeadline] = React.useState<string>('&nbsp');

  const { userId } = useParams();
  const [isClientAdmin] = React.useState<boolean>(userId === adminUserId);
  const [t] = useTranslation();
  const navigate = useNavigate();

  const dispatchGetUser = useDispatchUserGet();
  const dispatchUpdateUser = useDispatchUserUpdate();

  React.useEffect(() => {
    setInitiallyLoading(true);
    dispatchGetUser(userId)
      .then((userEntity) => {
        dispatch({ type: 'init', payload: userEntity });
        setInitiallyLoading(false);
      })
      .catch(() => navigate(-1));
  }, [dispatchGetUser, navigate, setInitiallyLoading, userId]);

  React.useEffect(() => {
    const user = users.filter((item) => item.userId === userId)[0];
    if (user) {
      setHeadline(userDisplayName(user, true));
    }
  }, [users, userId]);

  React.useEffect(() => {
    const mapped = facilities.map((item) => ({ value: item.facilityId, label: item.name }));
    setSelectOptionsFacility(mapped);
  }, [facilities]);

  const handleUpdate = React.useCallback(() => {
    const { isValid, errors } = ValidateUpdateUser(state.user, facilities.length);

    if (isValid) {
      setLoading(true);
      const merged = { ...state.user };
      delete merged.createdDate;

      dispatchUpdateUser(userId, merged)
        .then(() => {
          setLoading(false);
          navigate(-1);
        })
        .catch(() => setLoading(false));
    } else {
      dispatch({ type: 'setErrors', payload: errors });
    }
  }, [dispatchUpdateUser, facilities.length, navigate, setLoading, state.user, userId]);

  const renderSaveButton = () => {
    if (state.hasChanges) {
      return (
        <Button variant="success" onClick={handleUpdate} disabled={isLoading}>
          {t('save')}
        </Button>
      );
    }
    return null;
  };

  const renderCancelButton = () => {
    if (state.hasChanges) {
      return (
        <Button variant="outline-secondary" onClick={() => navigate(-1)} disabled={isLoading}>
          {t('cancel')}
        </Button>
      );
    }
    return null;
  };

  const renderFacility = () => {
    if (selectOptionsFacility && facilities.length > 1) {
      return (
        <Col xl={6}>
          <Select
            options={selectOptionsFacility}
            label={t('defaultFacility')}
            onChange={(value) => dispatch({ type: 'facilityId', payload: value })}
            initialValue={state.user.facilityId}
            hasError={!!state.errors.user.facilityId}
            visible={facilities.length > 1}
            contextHelpKey="userDefaultFacility"
            disabled={isLoading}
            showSkeleton={isInitiallyLoading}
          />
        </Col>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader headline={headline} actionButtonOne={renderSaveButton()} actionButtonTwo={renderCancelButton()} />

      <Panel>
        <Row>
          <Col xl={6}>
            <Input
              label={t('firstName')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'firstName', value } })}
              value={state.user.firstName}
              required
              hasError={!!state.errors.user.firstName}
              disabled={isLoading}
              showSkeleton={isInitiallyLoading}
              autoFocus
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('lastName')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'lastName', value } })}
              value={state.user.lastName}
              required
              hasError={!!state.errors.user.lastName}
              disabled={isLoading}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('userInitials')}
              onChange={(value) =>
                dispatch({
                  type: 'update',
                  payload: { key: 'initials', value: value.toUpperCase() },
                })
              }
              value={state.user.initials}
              required
              hasError={!!state.errors.user.initials}
              maxLength={4}
              disabled={isLoading}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('eMailAddress')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'mail', value } })}
              value={state.user.mail}
              required
              hasError={!!state.errors.user.mail}
              disabled
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('socialSecurityNumber')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'socialSecurityNumber', value } })}
              value={state.user.socialSecurityNumber}
              hasError={!!state.errors.user.socialSecurityNumber}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('taxNumber')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'taxNumber', value } })}
              value={state.user.taxNumber}
              hasError={!!state.errors.user.taxNumber}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('phone')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'phone', value } })}
              value={state.user.phone}
              hasError={!!state.errors.user.phone}
              disabled={isLoading}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          <Col xl={6}>
            <SelectCountry
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'countryCode', value } })}
              selected={state.user.countryCode}
              label={t('languageOrCountry')}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('externalUserId')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'externalId', value } })}
              value={state.user.externalId}
              hasError={!!state.errors.user.externalId}
              contextHelpKey="userExternalId"
              disabled={isLoading}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          <Col xl={6}>
            <Select
              options={selectOptionActive}
              label={t('userActive')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'active', value } })}
              initialValue={state.user.active}
              required
              hasError={!!state.errors.user.active}
              contextHelpKey="userActiveSettings"
              disabled={isLoading || isClientAdmin}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          {renderFacility()}
          <Col xl={6}>
            <SelectUserLoginPermission
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'loginPermission', value } })}
              initialValue={state.user.loginPermission}
              disabled={isLoading}
              showSkeleton={isInitiallyLoading}
              contextHelpKey={'loginPermissions'}
            />
          </Col>
          <Col xl={6}>
            <SelectAccessCode
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'accessCode', value } })}
              initialValue={state.user.accessCode}
              disabled={isLoading}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }}>
          <Col xl={3}>
            <Input
              label={t('zip')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'zip', value } })}
              value={state.user.zip}
              hasError={!!state.errors.user.zip}
              disabled={isLoading}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          <Col xl={9}>
            <Input
              label={t('city')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'city', value } })}
              value={state.user.city}
              hasError={!!state.errors.user.city}
              disabled={isLoading}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          <Col xl={9}>
            <Input
              label={t('street')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'street', value } })}
              value={state.user.street}
              hasError={!!state.errors.user.street}
              disabled={isLoading}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
          <Col xl={3}>
            <Input
              label={t('streetNo')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'streetNo', value } })}
              value={state.user.streetNo}
              hasError={!!state.errors.user.streetNo}
              disabled={isLoading}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 50 }}>
          <Col lg={12}>
            <Input
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'note', value } })}
              value={state.user.note}
              label={t('remark')}
              rows={3}
              type={InputType.textarea}
              showSkeleton={isInitiallyLoading}
            />
          </Col>
        </Row>
      </Panel>

      <ShowHideContainer visible={!isInitiallyLoading}>
        <FacilitiesForm
          description={t('modules.settings.userFacilitiesDescription')}
          userFacilities={state.user.facilities}
          onChange={(value, facilityEntity) =>
            dispatch({ type: 'updateFacilities', payload: { value, facilityEntity } })
          }
          hasError={state.errors.user.facilities && state.errors.user.facilities.length > 0}
          userDefaultFacility={state.user.facilityId}
        />
      </ShowHideContainer>

      <ShowHideContainer visible={!isInitiallyLoading}>
        <Panel headline={t('userGroups')} description={t('modules.settings.userGroupsDescription')}>
          <GroupsForm
            userGroups={state.user.groups}
            onChange={(value, groupItem) => dispatch({ type: 'updateGroup', payload: { value, groupItem } })}
            hasError={state.errors.groups && state.errors.groups.length > 0}
            disableAdminGroup={isClientAdmin}
          />
        </Panel>
      </ShowHideContainer>

      <DeleteButton user={state.user} />

      <BottomInternalIdContainer id={userId} />
    </>
  );
}
