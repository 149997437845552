import React from 'react';
import { ElasticSearchServiceDispositionInterface, ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import Panel from './Panel';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import moment from 'moment';
import { formatTime } from '../../../../../Globals/Functions';

interface Props {
  service: ServiceEntity;
}

/**
 * MontageDate()
 * @param props
 * @constructor
 */
export default function MontageDate(props: Props) {
  const { service } = props;
  const [t] = useTranslation();
  const { dispositions } = useAppSelector((state) => state.order.selectedOrder);
  const [dispo, setDispo] = React.useState<ElasticSearchServiceDispositionInterface>(null);
  const [caption, setCaption] = React.useState<string>(null);
  const [label, setLabel] = React.useState<string>(t('montageDate'));
  const [icon, setIcon] = React.useState<string>('fa-duotone fa-stars');

  React.useEffect(() => {
    if (dispositions) {
      const found = dispositions.find((item) => item.serviceId === service.serviceId);
      setDispo(found);
    }
  }, [dispositions, service.serviceId]);

  React.useEffect(() => {
    if (dispo) {
      setLabel(t('scheduledAt'));
      setIcon('fa-duotone fa-calendar-alt');
      setCaption(`${moment(dispo.date).format('LL')} / ${formatTime(dispo.startTime)}`);
    } else {
      setLabel(t('desiredDate'));
      setIcon('fa-duotone fa-stars');

      if (service.desiredDate) {
        setCaption(moment(service.desiredDate).format('LL'));
      } else if (service.desiredWeek) {
        setCaption(t('weekNumberShort', { week: service.desiredWeek }));
      }
    }
  }, [dispo, service, t]);

  if (caption) {
    return <Panel icon={icon} label={label} caption={caption} />;
  }
  return null;
}
