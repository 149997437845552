import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import Input, { InputType } from '../../Components/Inputs/Input';
import Panel from '../../Components/Panel';
import { isValidateEmail } from '../../Globals/Functions';
import { useDispatchLogin } from '../../Redux/Actions/AuthAction';
import { useNavigate } from 'react-router';
import { useLoadingModal } from '../../Globals/Hooks/Hooks';
import TextLink from '../../Components/View/TextLink';
import { InternalErrorCodes } from '../../Globals/InternalErrorCodes';
const image = require('../../Assets/Images/login_bg.jpg');

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-image: ${() => `url(${image})`};
  background-size: cover;
  margin-top: -18px;
`;

/**
 * Login()
 * @constructor
 */
export default function Login() {
  const [t] = useTranslation();
  const { setLoading, isLoading, loadingModal } = useLoadingModal({ headline: t('loginProcessing') });
  const [mail, setMail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [errors, setErrors] = React.useState({ mail: false, password: false, noLicense: false, noPermission: false });
  const navigate = useNavigate();

  const dispatchLogin = useDispatchLogin();

  useEffect(() => {
    document.addEventListener('keydown', enterHandler);
    return () => document.removeEventListener('keydown', enterHandler);
  });

  const enterHandler = (event: KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'NumPadEnter') {
      event.preventDefault();
      handleLogin();
    }
  };

  const handleLogin = () => {
    setErrors({ mail: false, password: false, noLicense: false, noPermission: false });

    if (mail && isValidateEmail(mail) && password && password.length >= 8) {
      setLoading(true);
      dispatchLogin(mail, password)
        .then(() => {
          setLoading(false);
          navigate('/dashboard');
        })
        .catch((error) => {
          setLoading(false);

          if (error.code) {
            switch (error.code) {
              case 'auth/wrong-password': {
                setErrors({ password: error.code, mail: false, noLicense: false, noPermission: false });
                break;
              }
              case 'auth/missing-email':
              case 'auth/user-disabled':
              case 'auth/user-not-found': {
                setErrors({ mail: error.code, password: false, noLicense: false, noPermission: false });
                break;
              }
              default:
                setErrors({ mail: error.code, password: error.code, noLicense: false, noPermission: false });
            }
          } else {
            if (error === InternalErrorCodes.NO_LICENSE_AVAILABLE) {
              setErrors({ mail: false, password: false, noLicense: true, noPermission: false });
            } else if (error === InternalErrorCodes.NO_LOGIN_PERMISSION) {
              setErrors({ mail: false, password: false, noLicense: false, noPermission: true });
            }
          }
        });
    } else {
      setErrors({ mail: true, password: true, noLicense: false, noPermission: false });
    }
  };

  const renderNoLicenseAvailable = () => {
    if (errors.noLicense) {
      return (
        <Alert variant="danger" className={'d-flex flex-column align-items-center'}>
          <div>
            <i className="far fa-file-certificate" style={{ marginBottom: 15, fontSize: 24 }} />
          </div>
          <div className={'text-center'}>{t('modules.auth.noLicenseAvailable')}</div>
        </Alert>
      );
    }
    return null;
  };

  const renderNoLoginPermission = () => {
    if (errors.noPermission) {
      return (
        <Alert variant="warning" className={'d-flex flex-column align-items-center'}>
          <div>
            <i className="fas fa-globe-pointer" style={{ marginBottom: 15, fontSize: 24 }} />
          </div>
          <div className={'text-center'}>{t('modules.auth.noLoginPermissionWeb')}</div>
        </Alert>
      );
    }
    return null;
  };

  const renderPasswordWrong = () => {
    if (errors.password && !errors.mail) {
      return (
        <Alert variant="danger">
          <i className="fas fa-key" style={{ marginRight: 10 }} />
          {t('modules.auth.errorPassword')}
        </Alert>
      );
    }
    return null;
  };

  const renderMailUnknown = () => {
    if (errors.mail && !errors.password) {
      return (
        <Alert variant="danger">
          <i className="fas fa-at" style={{ marginRight: 10 }} />
          {t('modules.auth.errorMail')}
          &nbsp;
          <Alert.Link href="register">{t('registerForFreeNow')}</Alert.Link>
        </Alert>
      );
    }
    return null;
  };

  const renderDataInvalid = () => {
    if (errors.mail && errors.password) {
      return (
        <Alert variant="danger">
          <i className="fas fa-keyboard" style={{ marginRight: 10 }} />
          {t('modules.auth.errorInvalidData')}
        </Alert>
      );
    }
    return null;
  };

  return (
    <OuterContainer>
      <div>
        <Panel
          maxWidth={800}
          headline={t('modules.auth.headline')}
          description={t('modules.auth.description')}
          smallHeadline={false}
        >
          {renderPasswordWrong()}
          {renderMailUnknown()}
          {renderDataInvalid()}
          {renderNoLicenseAvailable()}
          {renderNoLoginPermission()}
          <form autoComplete="none">
            <Row>
              <Col lg={12}>
                <Input
                  label={t('eMailAddress')}
                  onChange={(value) => setMail(value.trim())}
                  value={mail}
                  type={InputType.text}
                  required
                  hasError={!!errors.mail}
                  disabled={isLoading}
                />
              </Col>
              <Col lg={12}>
                <Input
                  label={t('password')}
                  onChange={setPassword}
                  value={password}
                  type={InputType.password}
                  required
                  hasError={!!errors.password}
                  disabled={isLoading}
                />
              </Col>
            </Row>
          </form>

          <hr />

          <Row>
            <Col className="d-flex align-items-center">
              <TextLink onPress={() => navigate('/public/password/ask')}>{t('passwordForgotten')}</TextLink>
            </Col>
            <Col>
              <Button variant="success" onClick={handleLogin} className="float-end">
                {t('login')}
              </Button>
            </Col>
          </Row>
        </Panel>

        {loadingModal}
      </div>
    </OuterContainer>
  );
}
