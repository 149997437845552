import React from 'react';
import ToggleRightListViewButtons, {
  RightListViewOnChangeState,
} from '../../../Components/Disposition/DayView/Components/ToggleRightListViewButtons';
import styled from 'styled-components';
import ToggleButton from '../../../Components/ToggleButton';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

interface Props {
  onChangeFilter: (item: RightListViewOnChangeState) => void;
  onAdd: () => void;
}

/**
 * ActionButtons
 * @param props
 * @constructor
 */
export default function ActionButtons(props: Props) {
  const { onChangeFilter, onAdd } = props;

  return (
    <Container>
      <div>
        <ToggleRightListViewButtons onChange={onChangeFilter} />
      </div>
      <div style={{ marginLeft: 40 }}>
        <ToggleButton onClick={onAdd} bottomMargin={0}>
          <i className="fas fa-plus" />
        </ToggleButton>
      </div>
    </Container>
  );
}
