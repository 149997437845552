import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FacilityEntity } from '../../../../Globals/Types/Types';
import { OrderCategory, OrderEntity, OrderPartnerPermissionAccess } from '../../../../Globals/Types/OrderTypes';
import CustomSwitch from '../../../CustomSwitch';

interface Props {
  order: OrderEntity;
  partner: FacilityEntity;
  permissions: OrderPartnerPermissionAccess;
  onSave: (item: OrderPartnerPermissionAccess) => void;
  onClose: () => void;
  visible: boolean;
}

export type State = {
  data: OrderPartnerPermissionAccess;
  initialized: Boolean;
  hasChanges: Boolean;
};

type Action =
  | { type: 'allowEditAll'; payload: boolean }
  | { type: 'change'; payload: { key: string; value: boolean } }
  | { type: 'init'; payload: OrderPartnerPermissionAccess }
  | { type: 'clear' };

const initialState: State = {
  data: {
    allowEditAll: true,
    allowEditInformation: false,
    allowEditCustomerAddress: false,
    allowEditLoadingAddress: false,
    allowEditAppointments: false,
    allowDocumentUpload: false,
    allowDocumentDelete: false,
    allowAddService: false,
    allowDeleteService: false,
    allowEditKitchenInformation: false,
    allowEditPosition: false,
  },
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'allowEditAll': {
      return {
        ...state,
        data: {
          allowEditAll: action.payload,
          allowEditInformation: false,
          allowEditCustomerAddress: false,
          allowEditLoadingAddress: false,
          allowEditAppointments: false,
          allowDocumentUpload: false,
          allowDocumentDelete: false,
          allowAddService: false,
          allowDeleteService: false,
          allowEditKitchenInformation: false,
          allowEditPosition: false,
        },
        hasChanges: true,
      };
    }
    case 'change': {
      const { key, value } = action.payload;
      const data = { ...state.data, [key]: value };

      let allAllowed = true;
      Object.keys(data).forEach((key) => {
        if (!data[key] && key !== 'allowEditAll') {
          allAllowed = false;
        }
      });
      if (allAllowed) {
        return {
          ...state,
          data: {
            allowEditAll: true,
            allowEditInformation: false,
            allowEditCustomerAddress: false,
            allowEditLoadingAddress: false,
            allowEditAppointments: false,
            allowDocumentUpload: false,
            allowDocumentDelete: false,
            allowAddService: false,
            allowDeleteService: false,
            allowEditKitchenInformation: false,
            allowEditPosition: false,
          },
          hasChanges: true,
        };
      } else {
        return { ...state, data: { ...data, allowEditAll: false }, hasChanges: true };
      }
    }
    case 'init':
      return {
        ...initialState,
        data: action.payload || state.data,
        initialized: true,
      };
    case 'clear':
      return { ...initialState };
  }
};

/**
 * EditModal
 * @param props
 * @constructor
 */
export default function EditModal(props: Props) {
  const { partner, permissions, visible, onClose, onSave, order } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [t] = useTranslation();

  React.useEffect(() => {
    if (!state.initialized && visible) {
      dispatch({ type: 'init', payload: permissions });
    }
  }, [permissions, state.initialized, visible]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear' });
    }
  }, [visible]);

  const handleSave = () => {
    onSave(state.data);
  };

  const renderDependingOnOrderCategory = () => {
    const returnArray = [];
    if (order.category === OrderCategory.kitchen) {
      returnArray.push(
        <Col lg={12} key="kitchenInfos">
          <CustomSwitch
            checked={state.data.allowEditKitchenInformation}
            caption={t(`modules.order.permissions.allowEditKitchenInformation`)}
            onChange={(value) => dispatch({ type: 'change', payload: { key: 'allowEditKitchenInformation', value } })}
          />
        </Col>,
      );
    }
    return returnArray;
  };

  if (visible && state.initialized) {
    return (
      <Modal show={visible} onHide={() => onClose()} size="lg">
        <Modal.Header>
          <div>
            <Modal.Title style={{ marginLeft: 25 }}>
              {t('modules.order.permissionsFor', { name: partner.name })}
            </Modal.Title>
            <div style={{ marginLeft: 25, marginRight: 25 }}>{t('modules.order.permissionsForDescription')}</div>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: 30 }}>
          <Row>
            <Col lg={12}>
              <CustomSwitch
                checked={state.data.allowEditAll}
                caption={t(`modules.order.permissions.allowEditAll`)}
                onChange={(value) => dispatch({ type: 'allowEditAll', payload: value })}
              />
            </Col>
            <Col lg={12}>
              <CustomSwitch
                checked={state.data.allowEditCustomerAddress}
                caption={t(`modules.order.permissions.allowEditCustomerAddress`)}
                onChange={(value) => dispatch({ type: 'change', payload: { key: 'allowEditCustomerAddress', value } })}
              />
            </Col>
            <Col lg={12}>
              <CustomSwitch
                checked={state.data.allowEditLoadingAddress}
                caption={t(`modules.order.permissions.allowEditLoadingAddress`)}
                onChange={(value) => dispatch({ type: 'change', payload: { key: 'allowEditLoadingAddress', value } })}
              />
            </Col>
            <Col lg={12}>
              <CustomSwitch
                checked={state.data.allowEditAppointments}
                caption={t(`modules.order.permissions.allowEditAppointments`)}
                onChange={(value) => dispatch({ type: 'change', payload: { key: 'allowEditAppointments', value } })}
              />
            </Col>
            <Col lg={12}>
              <CustomSwitch
                checked={state.data.allowDocumentUpload}
                caption={t(`modules.order.permissions.allowDocumentUpload`)}
                onChange={(value) => dispatch({ type: 'change', payload: { key: 'allowDocumentUpload', value } })}
              />
            </Col>
            <Col lg={12}>
              <CustomSwitch
                checked={state.data.allowDocumentDelete}
                caption={t(`modules.order.permissions.allowDocumentDelete`)}
                onChange={(value) => dispatch({ type: 'change', payload: { key: 'allowDocumentDelete', value } })}
              />
            </Col>
            <Col lg={12}>
              <CustomSwitch
                checked={state.data.allowEditInformation}
                caption={t(`modules.order.permissions.allowEditInformation`)}
                onChange={(value) => dispatch({ type: 'change', payload: { key: 'allowEditInformation', value } })}
              />
            </Col>
            <Col lg={12}>
              <CustomSwitch
                checked={state.data.allowAddService}
                caption={t(`modules.order.permissions.allowAddService`)}
                onChange={(value) => dispatch({ type: 'change', payload: { key: 'allowAddService', value } })}
              />
            </Col>
            <Col lg={12}>
              <CustomSwitch
                checked={state.data.allowDeleteService}
                caption={t(`modules.order.permissions.allowDeleteService`)}
                onChange={(value) => dispatch({ type: 'change', payload: { key: 'allowDeleteService', value } })}
              />
            </Col>
            <Col lg={12}>
              <CustomSwitch
                checked={state.data.allowEditPosition}
                caption={t(`modules.order.permissions.allowEditPosition`)}
                onChange={(value) => dispatch({ type: 'change', payload: { key: 'allowEditPosition', value } })}
              />
            </Col>

            {renderDependingOnOrderCategory()}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
            {t('save')}
          </Button>
          <Button variant="outline-secondary" onClick={() => onClose()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
