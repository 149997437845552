import React from 'react';
import { Report } from '../../../Globals/Types/Report';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import ImageList from '../../ImageList';

interface Props {
  report: Report;
}

/**
 * CompletedImages()
 * @param props
 * @constructor
 */
export default function CompletedImages(props: Props) {
  const { report } = props;
  const [t] = useTranslation();

  if (report.completedImages && report.completedImages.length > 0) {
    return (
      <Panel headline={t('navigationHeaders.completedImages')} containerStyle={{ paddingBottom: 10 }}>
        <ImageList images={report.completedImages.map((item) => item.downloadUrl)} />
      </Panel>
    );
  }
  return null;
}
