import React from 'react';
import { OrderServiceType } from '../../../../Globals/Types/OrderTypes';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Input, { InputType } from '../../../../Components/Inputs/Input';
import { useTranslation } from 'react-i18next';
import SelectIcon from '../../../../Components/SelectIcon';
import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { generateGuid } from '../../../../Globals/Functions';
import SelectYesNo from '../../../../Components/PredefinedSelects/SelectYesNo';

interface Props {
  visible: boolean;
  onClose: () => void;
  onCreate: (item: OrderServiceType) => void;
  onUpdate: (item: OrderServiceType) => void;
  editData?: OrderServiceType;
}

export type State = {
  type: OrderServiceType;
  initialized: boolean;
  isEdit: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'caption'; payload: string }
  | { type: 'icon'; payload: string }
  | { type: 'description'; payload: string }
  | { type: 'needReport'; payload: boolean }
  | { type: 'init'; payload: OrderServiceType }
  | { type: 'clear' };

const initialState: State = {
  type: {
    caption: null,
    key: null,
    icon: null,
    description: null,
    needReport: true,
  },
  isEdit: false,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'caption':
      return { ...state, type: { ...state.type, caption: action.payload }, hasChanges: true };
    case 'description':
      return { ...state, type: { ...state.type, description: action.payload }, hasChanges: true };
    case 'needReport':
      return { ...state, type: { ...state.type, needReport: action.payload }, hasChanges: true };
    case 'icon':
      return { ...state, type: { ...state.type, icon: action.payload }, hasChanges: true };
    case 'init': {
      return {
        ...state,
        type: action.payload,
        hasChanges: false,
        initialized: true,
        isEdit: true,
      };
    }
    case 'clear':
      return { ...initialState };
  }
};

interface ValidateResult {
  isValid: boolean;
  errors: {
    caption?: string;
    icon?: string;
  };
}

/**
 * validate()
 * @param type
 */
const validate = (type: OrderServiceType): ValidateResult => {
  let result: ValidateResult = {
    isValid: true,
    errors: {},
  };

  if (!type.caption || type.caption.length <= 0) {
    result = { ...result, isValid: false, errors: { caption: InternalErrorCodes.FIELD_MISSING } };
  }
  if (!type.icon || type.icon.length <= 0) {
    result = { ...result, isValid: false, errors: { ...result.errors, icon: InternalErrorCodes.FIELD_MISSING } };
  }

  return result;
};

/**
 * CreateOrUpdateModal()
 * @param props
 * @constructor
 */
export default function CreateOrUpdateModal(props: Props) {
  const { visible, onClose, onCreate, onUpdate, editData } = props;
  const [t] = useTranslation();
  const [errors, setErrors] = React.useState<{ caption?: string; icon?: string }>({});
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear' });
      setErrors({});
    }
  }, [visible]);

  React.useEffect(() => {
    if (editData && !state.initialized) {
      dispatch({ type: 'init', payload: editData });
    }
  }, [editData, state.initialized, visible]);

  const handleSave = React.useCallback(() => {
    setErrors({});
    const validResult = validate(state.type);

    if (validResult.isValid) {
      if (state.isEdit) {
        onUpdate(state.type);
      } else {
        onCreate({ ...state.type, key: generateGuid() });
      }
      onClose();
    } else {
      setErrors(validResult.errors);
    }
  }, [onClose, onCreate, onUpdate, state.isEdit, state.type]);

  return (
    <Modal show={visible} animation={true} size="lg">
      <Modal.Header>
        <Modal.Title>{state.isEdit ? t('serviceTypeUpdate') : t('serviceTypeCreate')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={12}>
            <Input
              onChange={(value) => dispatch({ type: 'caption', payload: value })}
              value={state.type.caption}
              label={t('caption')}
              autoFocus
              required
              hasError={!!errors.caption}
            />
          </Col>
          <Col lg={12}>
            <Input
              onChange={(value) => dispatch({ type: 'description', payload: value })}
              value={state.type.description}
              label={t('description')}
              type={InputType.textarea}
              rows={3}
              autoFocus
              required
            />
          </Col>
          <Col xxl={12}>
            <SelectYesNo
              onChange={(value) => dispatch({ type: 'needReport', payload: value })}
              label={t('needReport')}
              initialValue={state.type.needReport}
            />
          </Col>
          <Col xxl={12}>
            <SelectIcon
              onSelect={(value) => dispatch({ type: 'icon', payload: value })}
              selected={state.type.icon}
              hasError={!!errors.icon}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
            {t('save')}
          </Button>
          <Button variant="outline-secondary" onClick={() => onClose()} style={{ marginLeft: 6 }}>
            {t('close')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
