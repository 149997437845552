import React from 'react';
import PageHeader from '../../../Components/PageHeader';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UrlPatterns } from '../../../Globals/UrlFunctions';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { PositionEntity } from '../../../Globals/Types/OrderTypes';
import Form from '../../../Components/Order/Positions/Form';
import {
  useDispatchPositionCreate,
  useDispatchPositionDelete,
  useDispatchPositionGet,
  useDispatchPositionUpdate,
} from '../../../Redux/Actions/Client/PositionAction';
import AskDeleteModal from '../../../Components/Modals/AskDeleteModal';
import { validatePosition, ValidatePositionError } from '../../../Components/Order/Positions/ValidatePosition';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import BottomInternalIdContainer from '../../../Components/BottomInternalIdContainer';

/**
 * CreateOrUpdate
 * @constructor
 */
export default function CreateOrUpdate() {
  const { positions } = useAppSelector((state) => state.client);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { positionId } = useParams();
  const { isLoading, setLoading, loadingModal } = useLoadingModal();
  const { setLoading: setSaving, loadingModal: saveModal } = useLoadingModal();
  const [position, setPosition] = React.useState<PositionEntity>(undefined);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ValidatePositionError>({});

  const dispatchGet = useDispatchPositionGet();
  const dispatchCreate = useDispatchPositionCreate();
  const dispatchUpdate = useDispatchPositionUpdate();
  const dispatchDelete = useDispatchPositionDelete();

  const handleSave = React.useCallback(() => {
    setErrors({});

    const validateResult = validatePosition(position, positions);

    if (validateResult.isValid) {
      setSaving(true);
      let promise;

      if (isEdit) {
        promise = dispatchUpdate(position);
      } else {
        promise = dispatchCreate(position).then(() => {});
      }

      promise.finally(() => {
        setSaving(false);
        navigate(UrlPatterns.settingsPositions);
      });
    } else {
      setErrors(validateResult.errors);
    }
  }, [dispatchCreate, dispatchUpdate, isEdit, navigate, position, positions, setSaving]);

  const handleDelete = React.useCallback(() => {
    setSaving(true);
    dispatchDelete(positionId).finally(() => {
      setAskDelete(false);
      setSaving(false);
      navigate(UrlPatterns.settingsPositions);
    });
  }, [dispatchDelete, navigate, positionId, setSaving]);

  React.useEffect(() => {
    if (positionId) {
      setLoading(true);
      setIsEdit(true);
      dispatchGet(positionId)
        .then(setPosition)
        .finally(() => setLoading(false));
    } else {
      setPosition(null);
    }
  }, [dispatchGet, positionId, setLoading]);

  const renderCancelButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => navigate(UrlPatterns.settingsPositions)} disabled={isLoading}>
        {t('cancel')}
      </Button>
    );
  };

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={handleSave} disabled={!hasChanges}>
        {t('save')}
      </Button>
    );
  };

  if (position !== undefined) {
    return (
      <>
        <PageHeader
          headline={isEdit ? t('positionUpdate') : t('positionCreate')}
          actionButtonTwo={renderSaveButton()}
          actionButtonThree={renderCancelButton()}
        />

        <Form
          onChange={setPosition}
          position={position}
          isLoading={isLoading}
          onHasChanges={setHasChanges}
          errors={errors}
        />

        <ShowHideContainer visible={isEdit}>
          <div style={{ textAlign: 'center' }}>
            <Button variant="outline-danger" onClick={() => setAskDelete(true)} style={{ borderColor: 'transparent' }}>
              {t('delete')}
            </Button>
          </div>
        </ShowHideContainer>

        <ShowHideContainer visible={isEdit}>
          <BottomInternalIdContainer id={position ? position.positionId : ''} />
        </ShowHideContainer>

        {saveModal}

        <AskDeleteModal onDelete={handleDelete} onClose={() => setAskDelete(false)} visible={askDelete} />
      </>
    );
  }
  return loadingModal;
}
