import React from 'react';
import styled from 'styled-components';
import { DraggableTourData, Tour } from '../../../../Globals/Types/Tour';
import { DispositionListContext } from '../index';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { DragDropType } from '../../../../Globals/Types/DispositionTypes';
import moment from 'moment';
import { ElasticSearchServiceEntity } from '../../../../Globals/Types/OrderTypes';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { lighten } from 'polished';
import { useGetFacilityWorkTimes } from '../../../../Globals/Hooks/FacilityHooks';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const HourBox = styled.div<{ width: number; isOver: boolean; borderRightStyle: 'solid' | 'dotted' }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-width: ${(props) => props.width}px;
  background-color: ${(props) => (props.isOver ? lighten(0.4, props.theme.color.primaryColor) : 'transparent')};
  border-right: 1px ${(props) => props.borderRightStyle} ${(props) => props.theme.border.color};

  &.Striped {
    ${(props) => (props.isOver ? null : props.theme.gradient.light)}
  }

  &.Closed {
    ${(props) => props.theme.gradient.red}
  }
`;

interface Props {
  hour: number;
  minute: number;
  tour: Tour;
  onMoveService: (startTime: string, droppedData: DraggableTourData) => void;
  onAddService: (startTime: string, service: ElasticSearchServiceEntity) => void;
  borderRightStyle?: 'solid' | 'dotted';
}

/**
 * DropZoneBox()
 * @param props
 * @constructor
 */
export default function DropZoneBox(props: Props) {
  const { hour, minute, tour, onMoveService, onAddService, borderRightStyle } = props;
  const { facilityId } = useAppSelector((state) => state.auth.user);
  const context = React.useContext(DispositionListContext);
  const [time] = React.useState<string>(moment(`${hour}:${minute}`, 'H:m').format('HH:mm:ss'));
  const [outOfRange, setOutOfRange] = React.useState<boolean>(false);
  const getFacilityWorkTimes = useGetFacilityWorkTimes();

  const handleOnDrop = React.useCallback(
    (droppedData: DraggableTourData | ElasticSearchServiceEntity, monitor: DropTargetMonitor) => {
      if (monitor.getItemType() === DragDropType.SERVICETOUR) {
        onMoveService(time, droppedData as DraggableTourData);
      } else {
        onAddService(time, droppedData as ElasticSearchServiceEntity);
      }
    },
    [onAddService, onMoveService, time],
  );

  const [{ isOver }, drop] = useDrop<DraggableTourData, any, any>(
    () => ({
      accept: [DragDropType.SERVICE, DragDropType.SERVICETOUR],
      canDrop: (item, monitor) =>
        monitor.getItemType() === DragDropType.SERVICE || monitor.getItemType() === DragDropType.SERVICETOUR,
      drop: handleOnDrop,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [tour],
  );

  React.useEffect(() => {
    if (isOver) {
      context.setHoverTime(time);
    }
  }, [context, isOver, time]);

  React.useEffect(() => {
    if (time) {
      const { workDayStart, workDayEnd } = getFacilityWorkTimes(facilityId);
      if (
        moment(time, 'HH:mm:ss').isSameOrAfter(moment(workDayStart, 'HH:mm:ss'), 'minute') &&
        moment(time, 'HH:mm:ss').isBefore(moment(workDayEnd, 'HH:mm:ss'), 'minute')
      ) {
        setOutOfRange(false);
      } else {
        setOutOfRange(true);
      }
    }
  }, [facilityId, getFacilityWorkTimes, time]);

  return (
    <Container>
      <HourBox
        ref={tour.closed ? null : drop}
        isOver={isOver}
        width={context.hourBoxWidth / (60 / context.hourInterval)}
        className={outOfRange && !tour.closed ? 'Striped' : ''}
        borderRightStyle={borderRightStyle}
      />
    </Container>
  );
}

DropZoneBox.defaultProps = {
  borderRightStyle: 'solid',
};
