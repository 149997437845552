import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../Components/PageHeader';
import Panel from '../../Components/Panel';
import { Button, Col, Row } from 'react-bootstrap';
import Input from '../../Components/Inputs/Input';
import { useAppSelector, useLoadingModal } from '../../Globals/Hooks/Hooks';
import { UserEntity } from '../../Globals/Types/Types';
import { useDispatchUserUpdate } from '../../Redux/Actions/UserAction';
import validateUpdateUser from './Components/ValidateUpdateUser';
import { userDisplayName } from '../../Globals/Functions';
import SelectLanguage from '../../Components/PredefinedSelects/SelectSystemLanguage';

export type State = {
  user: UserEntity;
  errors: Partial<UserEntity>;
  initialized: Boolean;
  hasChanges: Boolean;
};

type Action =
  | { type: 'update'; payload: { key: string; value: string | number } }
  | { type: 'setErrors'; payload: Partial<UserEntity> }
  | { type: 'init'; payload: UserEntity };

const initialState: State = {
  user: {} as UserEntity,
  errors: {} as Partial<UserEntity>,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update': {
      const { key, value } = action.payload;
      return { ...state, user: { ...state.user, [key]: value }, hasChanges: true };
    }
    case 'init': {
      return {
        ...state,
        user: { ...action.payload },
        initialized: true,
        hasChanges: false,
        errors: {} as Partial<UserEntity>,
      };
    }
    case 'setErrors':
      return { ...state, errors: action.payload };
  }
};

/**
 * Profile()
 * @constructor
 */
export default function Profile() {
  const { user } = useAppSelector((state) => state.auth);
  const [t] = useTranslation();
  const { isLoading, setLoading } = useLoadingModal();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const dispatchUpdateUser = useDispatchUserUpdate();

  const init = React.useCallback(() => {
    dispatch({ type: 'init', payload: user });
  }, [user]);

  React.useEffect(() => {
    init();
  }, [init, user]);

  const handleSave = () => {
    const { isValid, errors } = validateUpdateUser(state.user);
    if (isValid) {
      setLoading(true);
      const merged = { ...state.user };
      delete merged.createdDate;

      dispatchUpdateUser(user.userId, merged)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      dispatch({ type: 'setErrors', payload: errors });
    }
  };

  const renderSaveButton = () => {
    if (state.hasChanges) {
      return (
        <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
          {t('save')}
        </Button>
      );
    }
    return null;
  };

  const renderCancelButton = () => {
    if (state.hasChanges) {
      return (
        <Button variant="outline-secondary" onClick={init}>
          {t('cancel')}
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader
        headline={userDisplayName(user)}
        actionButtonOne={renderSaveButton()}
        actionButtonTwo={renderCancelButton()}
      />

      <Panel description={t('modules.profile.user.description')}>
        <Row>
          <Col lg={5}>
            <Input
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'firstName', value } })}
              value={state.user.firstName}
              label={t('firstName')}
              hasError={!!state.errors.firstName}
              disabled={isLoading}
              required
            />
          </Col>
          <Col lg={5}>
            <Input
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'lastName', value } })}
              value={state.user.lastName}
              label={t('lastName')}
              hasError={!!state.errors.lastName}
              disabled={isLoading}
              required
            />
          </Col>
          <Col lg={2}>
            <Input onChange={() => {}} value={state.user.initials} label={t('userInitials')} disabled={true} required />
          </Col>
          <Col lg={6}>
            <Input
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'phone', value } })}
              value={state.user.phone}
              label={t('phone')}
              disabled={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input onChange={() => {}} value={state.user.mail} label={t('eMailAddress')} disabled={true} required />
          </Col>
          <Col lg={6}>
            <SelectLanguage
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'countryCode', value } })}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 60 }}>
          <Col xl={9}>
            <Input
              label={t('street')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'street', value } })}
              value={state.user.street}
              hasError={!!state.errors.street}
              disabled={isLoading}
            />
          </Col>
          <Col xl={3}>
            <Input
              label={t('streetNo')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'streetNo', value } })}
              value={state.user.streetNo}
              hasError={!!state.errors.streetNo}
              disabled={isLoading}
            />
          </Col>
          <Col xl={3}>
            <Input
              label={t('zip')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'zip', value } })}
              value={state.user.zip}
              hasError={!!state.errors.zip}
              disabled={isLoading}
            />
          </Col>
          <Col xl={9}>
            <Input
              label={t('city')}
              onChange={(value) => dispatch({ type: 'update', payload: { key: 'city', value } })}
              value={state.user.city}
              hasError={!!state.errors.city}
              disabled={isLoading}
            />
          </Col>
        </Row>
      </Panel>
    </>
  );
}
