/* eslint-disable  react-hooks/exhaustive-deps */
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ElasticSearchServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import { ResourceEntity, UserEntity } from '../../../../../Globals/Types/Types';

type ContainerProps = {
  focused: boolean;
  hasReloadIcon: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 0 12px;
  background-color: ${(props) => props.theme.color.primaryColor};
  border-bottom: 1px solid ${(props) => props.theme.table.borderColor};
  border-top-left-radius: ${(props) => props.theme.border.radius};
  border-top-right-radius: ${(props) => props.theme.border.radius};
  color: #ffffff;

  input {
    flex: 1;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.color.primaryColor};
    outline: none;
    padding: 6px 8px;
    background-color: ${(props) => (props.focused ? null : props.theme.color.primaryColor)};
    cursor: ${(props) => (!props.focused ? 'pointer' : null)};
  }

  input::placeholder {
    font-weight: ${(props) => (props.focused ? '400' : 'bold')};
    color: ${(props) => (props.focused ? '#bdbdbd' : '#ffffff')};
  }

  .fa-multiply,
  .fa-search {
    position: absolute;
    right: ${(props) => (props.hasReloadIcon ? 54 : 24)}px;
    cursor: pointer;
    color: ${(props) => (props.focused ? props.theme.color.primaryColor : '#ffffff')};
  }

  .fa-rotate {
    position: absolute;
    right: 24px;
    cursor: pointer;
    color: ${(props) => (props.focused ? props.theme.color.primaryColor : '#ffffff')};
  }
`;

interface Props {
  items: ElasticSearchServiceEntity[] | UserEntity[] | ResourceEntity[];
  onSearch: (value: string) => void;
  placeholder: string;
  isLoading?: boolean;
  onReloadClick?: () => void;
}

/**
 * SearchFilter
 * @param props
 * @constructor
 */
export default function SearchFilter(props: Props) {
  const { items, onSearch, placeholder, isLoading, onReloadClick } = props;
  const theme = useTheme();
  const [searchFocused, setSearchFocused] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const inputRef = React.useRef<HTMLInputElement>();
  const timer = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    if (searchFocused) {
      timer.current = setTimeout(() => onSearch(searchValue), 500);
    }

    return () => clearTimeout(timer.current);
  }, [items, searchFocused, searchValue]); // items as dependency to search again after the list has changed

  const handleClear = () => {
    setSearchValue('');
    setSearchFocused(false);
    onSearch(null);
    inputRef.current.blur();
  };

  const handleFocusOut = () => {
    if (!searchValue || searchValue.length === 0) {
      setSearchFocused(false);
    }
  };

  const renderIconClear = () => {
    if (searchValue && searchValue.length !== 0) {
      return <i className="fas fa-multiply" onClick={handleClear} />;
    }

    return null;
  };

  const renderIconSearch = () => {
    if (!searchValue || searchValue.length === 0) {
      return <i className="far fa-search" onClick={() => inputRef.current.focus()} />;
    }

    return null;
  };

  const renderOnRefresh = () => {
    if (onReloadClick) {
      return (
        <i
          className={`fas fa-rotate ${isLoading ? 'fa-spin' : ''}`}
          style={{ color: isLoading ? theme.color.orange : '' }}
          onClick={!isLoading ? () => onReloadClick() : () => {}}
        />
      );
    }
    return null;
  };

  return (
    <Container focused={searchFocused} hasReloadIcon={!!onReloadClick}>
      <input
        value={searchValue}
        onChange={(e: any) => setSearchValue(e.nativeEvent.target.value)}
        onFocus={() => setSearchFocused(true)}
        onBlur={handleFocusOut}
        ref={inputRef}
        placeholder={placeholder}
      />
      {renderIconSearch()}
      {renderIconClear()}
      {renderOnRefresh()}
    </Container>
  );
}

SearchFilter.defaultProps = {
  isLoading: false,
};
