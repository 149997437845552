import React from 'react';
import { OrderEntity } from '../../../../../Globals/Types/OrderTypes';
import Panel from './Panel';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';

interface Props {
  order: OrderEntity;
}

/**
 * OwnerFacility()
 * @param props
 * @constructor
 */
export default function OwnerFacility(props: Props) {
  const { order } = props;
  const [t] = useTranslation();
  const { facilities } = useAppSelector((state) => state.client);
  const [name, setName] = React.useState<string>(null);

  React.useEffect(() => {
    if (facilities && facilities.length > 1) {
      const found = facilities.find((item) => item.facilityId === order.ownerFacilityId);
      if (found) {
        setName(found.name);
      }
    }
  }, [facilities, order.ownerFacilityId]);

  if (facilities && facilities.length > 1 && name) {
    return <Panel icon="fad fa-building" label={t('facility')} caption={name} />;
  }
  return null;
}
