import React from 'react';
import styled from 'styled-components';

interface ContainerProps {
  $active: boolean;
  $hasCaption: boolean;
}

const OuterContainer = styled.div<{ $bottomMargin: number }>`
  margin-right: 10px;
  margin-bottom: ${(props) => props.$bottomMargin}px;

  &:last-child {
    margin-right: 0;
  }
`;

const Container = styled.div<ContainerProps>`
  background-color: ${(props) => (props.$active ? props.theme.color.primaryColor : props.theme.table.highlight)};
  color: ${(props) => (props.$active ? '#ffffff' : props.theme.color.text)};
  border-radius: ${(props) => props.theme.border.radius};
  padding: 10px 15px;
  cursor: pointer;
  white-space: nowrap;

  i {
    margin-right: ${(props) => (props.$hasCaption ? '8px' : 0)};
    color: ${(props) => (props.$active ? '#ffffff' : props.theme.color.text)};
  }

  &:hover {
    ${(props) => props.theme.boxShadowDark};
  }
`;

interface Props {
  children?: React.ReactElement | string;
  caption?: string;
  onClick: () => void;
  active?: boolean;
  style?: object;
  icon?: string;
  bottomMargin?: number;
}

/**
 * ToggleButton
 * @param props
 * @constructor
 */
export default function ToggleButton(props: Props) {
  const { onClick, caption, children, active, style, icon, bottomMargin } = props;

  const renderIcon = () => (icon ? <i className={icon} /> : null);

  const renderCaption = () => {
    return (
      <>
        {renderIcon()}
        {caption}
      </>
    );
  };

  const renderContent = () => (children ? children : renderCaption());

  return (
    <OuterContainer $bottomMargin={bottomMargin}>
      <Container $active={active} onClick={() => onClick()} style={style} $hasCaption={!!caption}>
        {renderContent()}
      </Container>
    </OuterContainer>
  );
}

ToggleButton.defaultProps = {
  active: false,
  bottomMargin: 10,
};
