import React from 'react';
import Panel from '../../../../Components/Panel';
import { useTranslation } from 'react-i18next';
import { AvailableReportPages, reportPagesConfigurable } from '../../../../Globals/Types/Report';
import PageContainer from './PageContainer';

interface Props {
  usedPages: AvailableReportPages[];
  onAdd: (pageName: AvailableReportPages) => void;
}

/**
 * AvailablePagesContainer()
 * @param props
 * @constructor
 */
export default function AvailablePagesContainer(props: Props) {
  const { usedPages, onAdd } = props;
  const [availablePages, setAvailablePages] = React.useState<AvailableReportPages[]>([]);
  const [t] = useTranslation();

  React.useEffect(() => {
    if (usedPages) {
      const allowed = reportPagesConfigurable.filter((page) => usedPages.indexOf(page) === -1);
      setAvailablePages(allowed);
    }
  }, [usedPages]);

  const renderPages = () => {
    if (availablePages && availablePages.length > 0) {
      return availablePages.map((page) => (
        <PageContainer
          canDelete={false}
          pageName={page}
          key={page}
          icon="fas fa-circle-arrow-left"
          onPressIcon={onAdd}
          showAdditionalInfo={true}
        />
      ));
    }
    return null;
  };

  return <Panel headline={t('availableReportPages')}>{renderPages()}</Panel>;
}
