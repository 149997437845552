import { UserEntity } from '../../../Globals/Types/Types';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';

export type UpdateClientValidationResult = {
  isValid: boolean;
  errors: Partial<UserEntity>;
};

/**
 * validateUpdateUser()
 * @param user
 */
const validateUpdateUser = (user: UserEntity): UpdateClientValidationResult => {
  let errors: Partial<UserEntity> = {} as Partial<UserEntity>;

  if (!user.firstName || user.firstName.length <= 2) {
    errors = { ...errors, firstName: InternalErrorCodes.FIELD_MISSING };
  }

  if (!user.lastName || user.lastName.length <= 2) {
    errors = { ...errors, lastName: InternalErrorCodes.FIELD_MISSING };
  }

  if (user.zip || user.city || user.street || user.streetNo) {
    if (!user.zip || user.zip.length <= 0) {
      errors = { ...errors, zip: InternalErrorCodes.FIELD_MISSING };
    }
    if (!user.city || user.city.length <= 0) {
      errors = { ...errors, city: InternalErrorCodes.FIELD_MISSING };
    }
    if (!user.street || user.street.length <= 0) {
      errors = { ...errors, street: InternalErrorCodes.FIELD_MISSING };
    }
    if (!user.streetNo || user.streetNo.length <= 0) {
      errors = { ...errors, streetNo: InternalErrorCodes.FIELD_MISSING };
    }
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export default validateUpdateUser;
