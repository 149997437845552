import React from 'react';
import { OrderEntity, OrderMessageEntity } from '../../../../Globals/Types/OrderTypes';
import styled from 'styled-components';
import Panel from '../../../Panel';
import DisplayText from '../../../View/DisplayText';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { userDisplayName, getFileTypeIcon } from '../../../../Globals/Functions';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import AskDeleteModal from '../../../Modals/AskDeleteModal';
import {
  useDispatchOrderMessageDelete,
  useDispatchOrderMessageGetList,
} from '../../../../Redux/Actions/Order/MessageAction';
import ProfileCircle from '../../../ProfileCircle';
import {
  useDispatchDocumentGetDownloadUrl,
  useDispatchDocumentGetFolderContent,
} from '../../../../Redux/Actions/GlobalStorageActions';
import { StorageReference } from '@firebase/storage';
import { darken } from 'polished';

const Container = styled.div<{ position: 'left' | 'right' }>`
  display: flex;
  justify-content: ${(props) => (props.position === 'left' ? 'flex-start' : 'flex-end')};
  color: ${(props) => props.theme.color.text};

  > .DisplayName {
    display: flex;
    flex-direction: row;
  }

  .InnerContainer {
    width: 85%;

    small {
      color: ${(props) => props.theme.color.textGray};
    }

    i.fa-trash {
      cursor: pointer;

      &:hover {
        color: ${(props) => props.theme.color.red};
      }
    }
  }

  .AttachmentContainer {
    border-top: 0.5px solid ${(props) => darken(0.08, props.theme.color.backgroundGray)};
    margin-top: 20px;
    padding-top: 15px;
    cursor: pointer;
    color: ${(props) => darken(0.2, props.theme.color.backgroundGray)};

    > .File {
      display: flex;
      align-content: center;
      margin-bottom: 6px;

      i {
        padding-top: 2px;
        font-size: 22px;
        color: ${(props) => darken(0.2, props.theme.color.backgroundGray)};
      }

      &:hover {
        color: ${(props) => props.theme.color.text};

        i {
          color: ${(props) => props.theme.color.text};
        }
      }
    }
  }
`;

interface Props {
  order: OrderEntity;
  message: OrderMessageEntity;
  position: 'left' | 'right';
}

/**
 * Message
 * @param props
 * @constructor
 */
export default function Message(props: Props) {
  const { order, message, position } = props;
  const [showDelete, setShowDelete] = React.useState<boolean>(false);
  const [attachments, setAttachments] = React.useState<StorageReference[]>(null);
  const { user } = useAppSelector((state) => state.auth);
  const [t] = useTranslation();
  const dispatchDelete = useDispatchOrderMessageDelete();
  const dispatchGetList = useDispatchOrderMessageGetList();
  const dispatchGetAttachments = useDispatchDocumentGetFolderContent();
  const dispatchGetDownloadUrl = useDispatchDocumentGetDownloadUrl();

  React.useEffect(() => {
    if (message.hasAttachment && !attachments) {
      const path = `/client/${order.ownerClientId}/order/${order.orderId}/messages/${message.messageId}`;
      dispatchGetAttachments(path).then((response) => {
        setAttachments(response);
      });
    }
  }, [attachments, dispatchGetAttachments, message, order]);

  const downloadFile = (file: StorageReference) => {
    dispatchGetDownloadUrl(file.fullPath).then((url) => {
      window.open(url);
    });
  };

  const handleDelete = () => {
    dispatchDelete(order, message).then(() => {
      return dispatchGetList(order).then(() => {
        setShowDelete(false);
      });
    });
  };

  const renderDeleteAction = () =>
    message.createdUser.userId === user.userId ? (
      <i className="fas fa-trash" onClick={() => setShowDelete(true)} style={{ marginLeft: 10 }} />
    ) : null;

  const renderAttachmentAction = () => (message.hasAttachment ? <i className="fas fa-paperclip" /> : null);

  const renderAccessAction = () =>
    !message.isPublic && (message.assignedUsers.length > 1 || message.assignedFacilities.length > 0) ? (
      <i className="fas fa-user-lock text-warning" title="Nur für ausgewählte Personen sichtbar!" />
    ) : null;

  const renderPrivateAction = () => {
    if (
      !message.isPublic &&
      message.assignedUsers.length === 1 &&
      (!message.assignedFacilities || message.assignedFacilities.length === 0)
    ) {
      return <i className="fas fa-lock text-warning" title="Nur für mich sichtbar!" />;
    }
    return null;
  };

  const renderFile = (file: StorageReference) => (
    <div className="File" onClick={() => downloadFile(file)} key={file.fullPath}>
      <i className={getFileTypeIcon(file.name)} />
      {file.name}
    </div>
  );

  const renderAttachment = () => {
    if (message.hasAttachment && attachments) {
      return <div className="AttachmentContainer">{attachments.map(renderFile)}</div>;
    }
    return null;
  };

  return (
    <Container position={position}>
      <div className="InnerContainer">
        <Panel containerStyle={{ padding: 25 }}>
          <DisplayText>
            <div className="d-flex flex-row">
              <div style={{ marginRight: 10 }}>
                <ProfileCircle icon="fa-solid fa-user" />
              </div>
              <div className="flex-grow-1">
                <div>
                  {renderAttachmentAction()}
                  {userDisplayName(message.createdUser)}
                </div>
                <small>{t('createdAt', { date: moment(message.createdDate).format('LL - LT') })}</small>
              </div>
              <div className="d-flex align-items-center">
                {renderAccessAction()}
                {renderPrivateAction()}
                {renderDeleteAction()}
              </div>
            </div>
          </DisplayText>
          <DisplayText caption="Mitteilung" containerStyle={{ marginBottom: 0 }}>
            <div dangerouslySetInnerHTML={{ __html: message.message.split('\n').join('<br />') }} />
            {renderAttachment()}
          </DisplayText>
        </Panel>
      </div>

      <AskDeleteModal onDelete={handleDelete} onClose={() => setShowDelete(false)} visible={showDelete} />
    </Container>
  );
}

Message.defaultProps = {
  position: 'left',
};
