import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import AskDeleteModal from '../../../../Components/Modals/AskDeleteModal';
import { useNavigate } from 'react-router';
import { ServiceEntity } from '../../../../Globals/Types/OrderTypes';
import { useDispatchOrderServiceDelete } from '../../../../Redux/Actions/Order/ServiceAction';
import { buildUrlOrderDetails } from '../../../../Globals/UrlFunctions';
import { ReportState } from '../../../../Globals/Types/Report';

interface Props {
  service: ServiceEntity;
}

/**
 * DeleteServiceButton
 * @constructor
 */
export default function DeleteServiceButton(props: Props) {
  const { service } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const { order } = useAppSelector((state) => state.order.selectedOrder);
  const dispatchDeleteService = useDispatchOrderServiceDelete();
  const { allowDeleteService, allowEditAll } = useAppSelector((state) => state.order.selectedOrder.permissions);
  const { dispositions } = useAppSelector((state) => state.order.selectedOrder.selectedService);
  const { loadingModal, setLoading } = useLoadingModal({ headline: t('serviceDeleteLoading') });

  const handleDelete = () => {
    setAskDelete(false);
    setLoading(true);
    dispatchDeleteService(order, service)
      .then(() => {
        setLoading(false);
        navigate(buildUrlOrderDetails(order.ownerClientId, order.orderId));
      })
      .catch(() => setLoading(false));
  };

  if (dispositions || service.assignedPartnerClientId) {
    return null;
  }

  if ((allowEditAll || allowDeleteService) && service.reportState === ReportState.none) {
    return (
      <div style={{ textAlign: 'center', marginTop: 80 }}>
        <Button variant="outline-danger" onClick={() => setAskDelete(true)}>
          {t('serviceDelete')}
        </Button>

        <AskDeleteModal
          onDelete={handleDelete}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
          headline={t('modules.order.service.delete')}
          description={t('modules.order.service.deleteDescription', {
            serviceName: service.type.caption,
          })}
          confirmButtonText={t('serviceDelete')}
        />

        {loadingModal}
      </div>
    );
  }
  return null;
}
