import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import moment from 'moment';

interface Props {
  onChange: (item: string) => void;
  selected: string;
  weeksInFuture?: number;
  label: string;
}

/**
 * SelectWeek
 * @param props
 * @constructor
 */
export default function SelectWeek(props: Props) {
  const { onChange, selected, weeksInFuture, label } = props;
  const [options, setOptions] = React.useState<SelectOptions>([]);

  React.useEffect(() => {
    const params: SelectOptions = [];
    for (let i = 0; i <= weeksInFuture; i++) {
      const currentDate = moment().add(i, 'week');
      const week = currentDate.format('WW/YYYY');
      const startDay = currentDate.startOf('week').format('DD.MM');
      const endDay = currentDate.endOf('week').format('DD.MM');
      const string = `KW ${week} (${startDay} - ${endDay})`;

      params.push({ label: string, value: week });
    }

    setOptions(params);
  }, [weeksInFuture]);

  if (options && options.length > 0) {
    return <Select label={label} onChange={onChange} initialValue={selected} options={options} />;
  }
  return null;
}

SelectWeek.defaultProps = {
  weeksInFuture: 52,
};
