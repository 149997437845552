/**
 * This class is to store some reducer states to localStorage and to restore reducer state from localStorage
 * @author TRutte <thorsten.rutte@corodo.de>
 * @version 1.0
 */

const currentVersion = 1; // Increase this version when structure of redux store changed!

export interface StorageObjectState {
  auth: Object;
  client: Object;
}

export const localStorageStoreName = '@savedStoreData';
export const localStorageReduxVersion = '@reduxVersion';

/**
 * Checks if the version number has changed. If he version changed we must clear the stored data to not have
 * outdated structures eventually. Invalid structures can lead to errors. Function will be called once every first
 * page load in AppRouter.tsx
 */
export const checkVersionMatch = () => {
  const version = localStorage.getItem(localStorageReduxVersion);
  if (!version || parseInt(version) < currentVersion) {
    // eslint-disable-next-line no-console
    console.log('LocalStorage Redux Data cleared!');
    localStorage.removeItem(localStorageStoreName);
  }
};

/**
 * Save auth state to localStorage
 * @param state
 */
export function saveState(state: StorageObjectState | any) {
  try {
    const serialized = JSON.stringify({
      auth: state.auth,
      client: {
        ...state.client,
        timeTrackingState: [],
        positions: [],
        invoices: [],
        customers: {
          all: [...state.client.customers.all],
          selectedCustomer: {
            customer: null,
          },
        },
      },
    });
    localStorage.setItem(localStorageReduxVersion, currentVersion.toString());
    localStorage.setItem(localStorageStoreName, serialized);
  } catch (e) {
    // Ignore write errors
  }
}

/**
 * Load auth state from local storage
 * @returns {*}
 */
export function loadState() {
  try {
    const serializedState = localStorage.getItem(localStorageStoreName);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}
