import React from 'react';
import styled from 'styled-components';
import { TaskEntity } from '../../Globals/Types/Types';
import { TaskPriority } from '../../Globals/Types/Enums';

interface Props {
  style?: object;
  task: TaskEntity;
}

const PriorityIcon = styled.i`
  margin-right: 6px;
`;

/**
 * TaskPriorityIcon
 * @param props
 * @constructor
 */
export default function TaskPriorityIcon(props: Props) {
  const { style, task } = props;
  const { priority } = task;

  const renderPriorityIcon = (className: string) => {
    return <PriorityIcon className={className} style={style} />;
  };

  switch (priority) {
    case TaskPriority.highest:
      return renderPriorityIcon('fas fa-angle-double-up text-danger');
    case TaskPriority.higher:
      return renderPriorityIcon('fas fa-angle-up text-danger');
    case TaskPriority.high:
      return renderPriorityIcon('fas fa-exclamation text-danger');
    case TaskPriority.low:
      return renderPriorityIcon('fas fa-angle-down text-black-50');
    case TaskPriority.lower:
      return renderPriorityIcon('fas fa-angle-double-down text-black-50');
    default:
      return null;
  }
}
