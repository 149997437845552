import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import CheckRow from '../../../../Components/CheckRow';
import { useDispatchInitializeIndexService } from '../../../../Redux/Actions/Order/ServiceAction';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

type EntryType = {
  checked: boolean;
  execute: () => Promise<void>;
  text: string;
};

interface Props {
  onClose: () => void;
  visible: boolean;
}

/**
 * ElasticIndexFormDevModal()
 * @param props
 * @constructor
 */
export default function ElasticIndexFormDevModal(props: Props) {
  const { onClose: onCloseProp, visible } = props;
  const [entries, setEntries] = React.useState<EntryType[]>(null);
  const [t] = useTranslation();

  const dispatchInitializeIndexService = useDispatchInitializeIndexService();

  React.useEffect(() => {
    if (!entries) {
      const entriesTemp: Array<any> = [];
      entriesTemp.push({ checked: false, execute: dispatchInitializeIndexService, text: 'Services' });

      setEntries(entriesTemp);
    }
  }, [dispatchInitializeIndexService, entries]);

  const execute = () => {
    const promiseAll: Array<Promise<void>> = [];
    entries.forEach((entry) => {
      if (entry.checked) {
        promiseAll.push(entry.execute());
      }
    });

    Promise.all(promiseAll).then(() => onClose());
  };

  const onClick = (text) => {
    const index: number = entries.findIndex((entry) => entry.text === text);
    if (index >= 0) {
      const entriesTemp = [...entries];
      entriesTemp[index] = { ...entries[index], checked: !entries[index].checked };
      setEntries(entriesTemp);
    }
  };

  const onClose = () => {
    setEntries(null);
    onCloseProp();
  };

  const renderEntries = () => {
    if (entries) {
      return entries.map((entry) => (
        <CheckRow checked={entry.checked} key={entry.text} onClick={() => onClick(entry.text)} text={entry.text} />
      ));
    }

    return null;
  };

  return (
    <Modal show={visible} size="lg">
      <Modal.Header>
        <div>
          <Modal.Title>{t('modules.settings.elasticSearch.createIndices.headline')}</Modal.Title>
          {t('modules.settings.elasticSearch.createIndices.description')}
        </div>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <Container>{renderEntries()}</Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={execute} variant="danger">
          {t('approve')}
        </Button>
        <Button onClick={onClose} variant="outline-secondary">
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
