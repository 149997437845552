import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PositionEntity } from '../../../../Globals/Types/OrderTypes';
import { generateGuid } from '../../../../Globals/Functions';
import Form from '../../Positions/Form';
import { validatePosition, ValidatePositionError } from '../../Positions/ValidatePosition';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: (item: PositionEntity) => void;
  onEdit: (item: PositionEntity, changedAssemblyTime: boolean) => void;
  editData?: PositionEntity;
}

/**
 * CreateOrUpdatePositionModal
 * @param props
 * @constructor
 */
export default function CreateOrUpdatePositionModal(props: Props) {
  const { visible, onClose, onSave, editData, onEdit } = props;
  const [t] = useTranslation();
  const [position, setPosition] = React.useState<PositionEntity>(null);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [changedAssemblyTime, setChangedAssemblyTime] = React.useState<boolean>(false);
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ValidatePositionError>();

  React.useEffect(() => {
    if (!visible) {
      setErrors(null);
      setPosition(null);
      setIsEdit(false);
    }
  }, [visible]);

  React.useEffect(() => {
    if (editData) {
      setPosition(editData);
      setIsEdit(true);
    }
  }, [editData]);

  const handleSave = () => {
    setErrors({});

    const validationResult = validatePosition(position);

    if (validationResult.isValid) {
      if (isEdit) {
        onEdit(position, changedAssemblyTime);
      } else {
        onSave({ ...position, positionId: generateGuid() });
      }
    } else {
      setErrors(validationResult.errors);
    }
  };

  const handleOnChange = (positionItem: PositionEntity, changedAssemblyTimeItem: boolean) => {
    setPosition(positionItem);
    setChangedAssemblyTime(changedAssemblyTimeItem);
  };

  if (visible) {
    return (
      <Modal show={visible} animation={true} size="xl">
        <Modal.Header>
          <Modal.Title>
            <i className="fas fa-list-timeline" style={{ marginRight: 10 }} />
            {t('positionCreate')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onChange={handleOnChange}
            position={position}
            isLoading={false}
            onHasChanges={setHasChanges}
            errors={errors}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSave} disabled={!hasChanges}>
            {t('save')}
          </Button>
          <Button variant="outline-secondary" onClick={() => onClose()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
