import React from 'react';
import CollapsableContainer from './CollapsableContainer';
import { DispositionListContext } from '../index';
import { ResourceEntity } from '../../../../Globals/Types/Types';
import { useDrop } from 'react-dnd';
import { DragDropType } from '../../../../Globals/Types/DispositionTypes';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import styled, { useTheme } from 'styled-components';
import Badge from './Badge';
import { Tour } from '../../../../Globals/Types/Tour';

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface Props {
  resources?: ResourceEntity[];
  children?: React.ReactElement | string;
  onAdd: (item: ResourceEntity) => void;
  onDelete: (item: ResourceEntity) => void;
  tour: Tour;
  onClick?: () => void;
}

/**
 * ResourceColumn
 * @param props
 * @constructor
 */
export default function ResourceColumn(props: Props) {
  const { children, onAdd, resources, onDelete, tour, onClick } = props;
  const { resources: clientResources } = useAppSelector((state) => state.client);
  const context = React.useContext(DispositionListContext);
  const theme = useTheme();

  const [{ isOver, canDrop }, drop] = useDrop<ResourceEntity, any, any>(
    () => ({
      accept: DragDropType.RESOURCE,
      canDrop: (item, monitor) => monitor.getItemType() === DragDropType.RESOURCE,
      drop: (item) => onAdd(item),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [tour],
  );

  const getResourceCountIcon = () => {
    if (resources) {
      switch (resources.length) {
        case 0:
          return 'fas fa-circle-0 Red';
        case 1:
          return 'fas fa-circle-1 Green';
        case 2:
          return 'fas fa-circle-2 Green';
        case 3:
          return 'fas fa-circle-3 Green';
        case 4:
          return 'fas fa-circle-4 Green';
      }
    }
    return 'fas fa-circle-0 Red';
  };

  const renderContent = () => {
    if (children) {
      return children;
    }
    if (resources && resources.length > 0) {
      return (
        <Inner>
          {resources.map((resource) => (
            <Badge
              key={resource.resourceId}
              caption={resource.name}
              onPress={!tour.closed ? () => onDelete(resource) : () => {}}
              fontColor={theme.color.text}
            />
          ))}
        </Inner>
      );
    }
    return null;
  };

  if (clientResources && clientResources.length > 0) {
    return (
      <CollapsableContainer
        forwardRef={!children && !tour.closed ? drop : null}
        width={context.resourceWidth}
        isDragOver={!children && !tour.closed ? isOver : false}
        isCanDrop={!children && !tour.closed ? canDrop : false}
        collapsed={context.collapsedResources}
        onClick={onClick}
        collapsedIcon={children ? 'fas fa-car' : getResourceCountIcon()}
      >
        {renderContent()}
      </CollapsableContainer>
    );
  }
  return null;
}
