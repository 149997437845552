import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PickedUserEntity, TaskEntity, UserEntity } from '../../Globals/Types/Types';
import SelectClientUsers from '../SelectClientUsers';
import DatePicker from '../Inputs/DatePicker';
import moment from 'moment';
import { TaskPriority } from '../../Globals/Types/Enums';
import { useAppSelector, useGetTimeOptions, useLoadingModal } from '../../Globals/Hooks/Hooks';
import Input, { InputType } from '../Inputs/Input';
import Select, { SelectOptions } from '../Inputs/Select';
import {
  useDispatchUserTaskCreate,
  useDispatchUserTaskDelete,
  useDispatchUserTaskUpdate,
} from '../../Redux/Actions/User/TaskAction';
import { Timestamp } from 'firebase/firestore';
import AssignedHint from './AssignedHint';
import ShowHideContainer from '../ShowHideContainer';
import { OrderEntity } from '../../Globals/Types/OrderTypes';
import OrderHint from './OrderHint';
import { useNavigate } from 'react-router';
import { buildUrlOrderDetails } from '../../Globals/UrlFunctions';
import { userEntityToPickedUserEntity } from '../../Globals/Functions';

interface Props {
  visible: boolean;
  onClose: () => void;
  initialData?: TaskEntity;
  order?: OrderEntity;
}

export type State = {
  task: TaskEntity;
  dateHelper: string;
  timeHelper: string;
  initialized: boolean;
  isEdit: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'caption'; payload: string }
  | { type: 'description'; payload: string }
  | { type: 'date'; payload: string }
  | { type: 'time'; payload: string }
  | { type: 'priority'; payload: TaskPriority }
  | { type: 'reminderMinutes'; payload: number }
  | { type: 'assignedUser'; payload: { assignee: PickedUserEntity; currentUser: UserEntity } }
  | { type: 'init'; payload: TaskEntity }
  | { type: 'initOrder'; payload: OrderEntity }
  | { type: 'clear' };

const initialState: State = {
  task: {
    finished: false,
    priority: TaskPriority.normal,
    reminderMinutes: 10,
    assignedUser: null,
    caption: '',
    description: '',
    executeTrigger: true,
    dueDateTime: null,
    createdUser: null,
    createdDate: null,
    dueDateTimeFormatted: null,
    finishedDateTime: null,
  },
  dateHelper: moment().format('YYYY-MM-DD'),
  timeHelper: null,
  isEdit: false,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'caption':
      return { ...state, task: { ...state.task, caption: action.payload }, hasChanges: true };
    case 'description':
      return { ...state, task: { ...state.task, description: action.payload }, hasChanges: true };
    case 'date':
      return { ...state, dateHelper: action.payload, hasChanges: true };
    case 'time':
      return { ...state, timeHelper: action.payload, hasChanges: true };
    case 'priority':
      return { ...state, task: { ...state.task, priority: action.payload }, hasChanges: true };
    case 'reminderMinutes':
      return { ...state, task: { ...state.task, reminderMinutes: action.payload }, hasChanges: true };
    case 'assignedUser': {
      if (action.payload.assignee) {
        return {
          ...state,
          task: {
            ...state.task,
            assignedUser: action.payload.assignee,
            createdUser: userEntityToPickedUserEntity(action.payload.currentUser),
          },
          hasChanges: true,
        };
      }
      return {
        ...state,
        task: {
          ...state.task,
          assignedUser: null,
          createdUser: null,
        },
        hasChanges: true,
      };
    }
    case 'initOrder': {
      return {
        ...state,
        task: {
          ...state.task,
          order: {
            clientId: action.payload.ownerClientId,
            orderId: action.payload.orderId,
            externalId: action.payload.externalId,
          },
        },
      };
    }
    case 'init': {
      const typedDateTime = action.payload.dueDateTime as Timestamp;

      return {
        ...state,
        task: action.payload,
        hasChanges: false,
        initialized: true,
        isEdit: true,
        dateHelper: moment(typedDateTime.toDate()).format('YYYY-MM-DD'),
        timeHelper: moment(typedDateTime.toDate()).format('HH:mm'),
      };
    }
    case 'clear':
      return { ...initialState };
  }
};

/**
 * CreateOrUpdate
 * @param props
 * @constructor
 */
export default function CreateOrUpdateModal(props: Props) {
  const { visible, onClose, initialData, order } = props;
  const [captionError, setCaptionError] = React.useState<boolean>(false);
  const [canEdit, setCanEdit] = React.useState<boolean>(true);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [priorityOptions, setPriorityOptions] = React.useState<SelectOptions>([]);
  const [reminderMinuteOptions, setReminderMinuteOptions] = React.useState<SelectOptions>([]);
  const { user } = useAppSelector((state) => state.auth);
  const { options: timeOptions } = useGetTimeOptions(15);
  const { isLoading, setLoading } = useLoadingModal();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatchCreate = useDispatchUserTaskCreate();
  const dispatchUpdate = useDispatchUserTaskUpdate();
  const dispatchDelete = useDispatchUserTaskDelete();

  React.useEffect(() => {
    if (initialData && visible) {
      dispatch({ type: 'init', payload: initialData });
    }
  }, [initialData, visible]);

  React.useEffect(() => {
    if (!initialData && order && visible) {
      dispatch({ type: 'initOrder', payload: order });
    }
  }, [initialData, order, visible]);

  React.useEffect(() => {
    if (initialData !== null) {
      if (state.isEdit) {
        if (initialData.createdUser && initialData.createdUser.userId !== user.userId) {
          setCanEdit(false);
        }
      }
    } else {
      setCanEdit(true);
    }
  }, [initialData, state.isEdit, user.userId]);

  React.useEffect(() => {
    const options: Array<{ value: string; label: string }> = [];
    for (const item in TaskPriority) {
      options.push({
        value: item,
        label: t(`taskPriority.${TaskPriority[item]}`),
      });
    }
    setPriorityOptions(options);
  }, [t]);

  React.useEffect(() => {
    const options: SelectOptions = [
      { label: t('taskNoReminder'), value: 0 },
      { label: t('taskRemindBeforeDueDate', { minutes: 10 }), value: 10 },
      { label: t('taskRemindBeforeDueDate', { minutes: 20 }), value: 20 },
      { label: t('taskRemindBeforeDueDate', { minutes: 30 }), value: 30 },
      { label: t('taskRemindBeforeDueDate', { minutes: 40 }), value: 40 },
      { label: t('taskRemindBeforeDueDate', { minutes: 50 }), value: 50 },
      { label: t('taskRemindBeforeDueDate', { minutes: 60 }), value: 60 },
    ];

    setReminderMinuteOptions(options);
  }, [t]);

  React.useEffect(() => {
    if (!visible) {
      setCaptionError(false);
      dispatch({ type: 'clear' });
    }
  }, [visible]);

  const handleDelete = () => {
    if (state.task) {
      dispatchDelete(state.task).then(() => {
        setLoading(false);
        onClose();
      });
    }
  };

  const handleSave = () => {
    setCaptionError(false);

    if (state.task.caption) {
      setLoading(true);
      const preparedDueDateTime = `${state.dateHelper} ${state.timeHelper || '09:00'}:00`;
      let mappedTask: TaskEntity = {
        ...state.task,
        dueDateTime: moment(preparedDueDateTime).toDate(),
        dueDateTimeFormatted: moment(preparedDueDateTime).format('LL - LT'),
      };

      let promise;
      if (!state.isEdit) {
        promise = dispatchCreate(mappedTask);
      } else {
        promise = dispatchUpdate(mappedTask);
      }

      promise
        .then(() => {
          setLoading(false);
          onClose();
        })
        .catch(() => setLoading(false));
    } else {
      setCaptionError(true);
    }
  };

  const navigateToOrder = () => {
    const { clientId, orderId } = state.task.order;
    const uri = buildUrlOrderDetails(clientId, orderId);

    onClose();
    navigate(uri);
  };

  const renderToOrderButton = () => {
    if (state.task.order && state.initialized) {
      return (
        <div className="flex-grow-1">
          <Button variant="primary" onClick={navigateToOrder}>
            {t('toOrder')}
          </Button>
        </div>
      );
    }
    return null;
  };

  if (visible) {
    return (
      <Modal show={visible} animation={true} size="lg">
        <Modal.Header>
          <Modal.Title>
            <i className="fas fa-tasks" style={{ marginRight: 10 }} />
            {initialData ? t('taskUpdate') : t('taskCreate')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssignedHint item={state.task} />
          <OrderHint item={state.task} />

          <Row>
            <Col lg={12}>
              <Input
                onChange={(value) => dispatch({ type: 'caption', payload: value })}
                value={state.task.caption}
                label={t('title')}
                autoFocus
                required
                hasError={captionError}
                disabled={isLoading || !canEdit}
              />
            </Col>
            <Col lg={12}>
              <Input
                onChange={(value) => dispatch({ type: 'description', payload: value })}
                value={state.task.description}
                label={t('description')}
                type={InputType.textarea}
                rows={6}
                disabled={isLoading || !canEdit}
              />
            </Col>
            <Col lg={6}>
              <DatePicker
                label={t('dueDate')}
                onChange={(value) => dispatch({ type: 'date', payload: value })}
                minDate={moment().toDate()}
                disabled={isLoading || !canEdit}
                required
                initialValue={state.dateHelper ? moment(state.dateHelper).toDate() : moment().toDate()}
              />
            </Col>
            <Col lg={6}>
              <Select
                label={t('dueTime')}
                onChange={(value) => dispatch({ type: 'time', payload: value })}
                options={timeOptions}
                disabled={isLoading || !canEdit}
                initialValue={state.timeHelper}
              />
            </Col>
            <Col lg={6}>
              <Select
                label={t('priority')}
                onChange={(value) => dispatch({ type: 'priority', payload: value })}
                options={priorityOptions}
                initialValue={state.task.priority}
                disabled={isLoading || !canEdit}
              />
            </Col>
            <Col lg={6}>
              <Select
                label={t('reminder')}
                onChange={(value) => dispatch({ type: 'reminderMinutes', payload: value })}
                options={reminderMinuteOptions}
                initialValue={state.task.reminderMinutes}
                disabled={isLoading || !canEdit}
              />
            </Col>
            <Col lg={12}>
              <SelectClientUsers
                onChange={(value: PickedUserEntity) =>
                  dispatch({ type: 'assignedUser', payload: { assignee: value, currentUser: user } })
                }
                isMultiSelect={false}
                disabled={isLoading || !canEdit || state.isEdit}
                initialSelected={state.task.assignedUser ? [state.task.assignedUser] : null}
                showUnselectOption
                label={t('assignUser')}
                hideCurrentUser
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {renderToOrderButton()}
          <div>
            <ShowHideContainer visible={state.isEdit && canEdit}>
              <Button variant="outline-danger" onClick={handleDelete} disabled={isLoading} style={{ marginRight: 6 }}>
                <i className="fas fa-trash" />
              </Button>
            </ShowHideContainer>

            <Button variant="success" onClick={handleSave} disabled={isLoading || !state.hasChanges}>
              {t('save')}
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => onClose()}
              disabled={isLoading}
              style={{ marginLeft: 6 }}
            >
              {t('close')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
