import React from 'react';
import styled from 'styled-components';
import ToggleButton from '../../../Components/ToggleButton';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

interface Props {
  onShowMap: () => void;
  active: boolean;
  marginRight: boolean;
}

/**
 * MapButton
 * @param props
 * @constructor
 */
export default function MapButton(props: Props) {
  const { onShowMap, active, marginRight } = props;
  const [t] = useTranslation();

  const getCaption = () => (active ? t('back') : <i className="fas fa-location-dot" />);

  return (
    <Container>
      <div style={{ marginRight: marginRight ? 40 : 0 }}>
        <ToggleButton onClick={onShowMap} bottomMargin={0} active={active}>
          {getCaption()}
        </ToggleButton>
      </div>
    </Container>
  );
}
