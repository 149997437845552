import React from 'react';
import { ServiceFilter } from '../../../Globals/Types/Types';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import { ORDER_FILTER_SET_SUCCESS } from '../../ActionTypes';

const localStorageIdentifier = '@serviceFilterUser_{userId}';

type OrderFilterSetFunction = (filter: ServiceFilter) => ServiceFilter;
/**
 * useDispatchOrderFilterSet
 */
export const useDispatchOrderFilterSet = (saveState: boolean): OrderFilterSetFunction => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.auth);

  return React.useCallback<OrderFilterSetFunction>(
    (filter) => {
      if (saveState) {
        const identifier = localStorageIdentifier.replace('{userId}', userId);
        localStorage.setItem(identifier, JSON.stringify(filter));
      }
      dispatch({ type: ORDER_FILTER_SET_SUCCESS, payload: filter });
      return filter;
    },
    [dispatch, saveState, userId],
  );
};

type OrderFilterLoadFunction = () => ServiceFilter | null;
/**
 * useDispatchOrderFilterSet
 */
export const useDispatchOrderFilterLoad = (): OrderFilterLoadFunction => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.auth);

  return React.useCallback(() => {
    const identifier = localStorageIdentifier.replace('{userId}', userId);
    const data = localStorage.getItem(identifier);

    if (data) {
      const jsonObject = JSON.parse(data);
      dispatch({ type: ORDER_FILTER_SET_SUCCESS, payload: jsonObject });
      return jsonObject;
    }

    return null;
  }, [dispatch, userId]);
};
