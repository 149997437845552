import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppRouter from './Router/AppRouter';
import store from './Redux/Store';
import { Provider } from 'react-redux';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-GB';
import { registerLocale } from 'react-datepicker';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

registerLocale('de-DE', de);
registerLocale('es-ES', es);
registerLocale('en-US', en);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <AppRouter />
    </Provider>
  </DndProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
