import React from 'react';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import { DefaultHeyKitchenPartnerEntity } from '../../../Globals/Types/General';
import { Alert } from 'react-bootstrap';
import { CreateOrderState } from '../../../Globals/Types/OrderTypes';

interface Props {
  data: CreateOrderState;
  blackHeadline?: boolean;
}

/**
 * CalculationPanel
 * @param props
 * @constructor
 */
export default function CalculationPanel(props: Props) {
  const { data, blackHeadline } = props;
  const [t] = useTranslation();

  if (data.order.ownerClientId === DefaultHeyKitchenPartnerEntity.partnerClientId) {
    return (
      <Panel headline={t('modules.order.externalCalculation')} headlineDarkBlack={blackHeadline}>
        <Alert variant="danger">Berechnung noch nicht implementiert!</Alert>
      </Panel>
    );
  }
  return null;
}
