export enum LinkingType {
  publicReport = 'publicReport',
}

export interface LinkingEntity<P> {
  linkingId: string;
  type: LinkingType;
  payload: P;
  pin: number;
  createdDate: string;
  expireDate: string | null;
  errorCount: number;
}

export interface LinkingPayloadPublicReport {
  clientId: string;
  orderId: string;
  serviceId: string;
  externalId: string;
}
