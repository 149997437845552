/* eslint-disable max-len */

export const ABSENCES_CREATE_START = 'ABSENCES_CREATE_START';
export const ABSENCES_CREATE_SUCCESS = 'ABSENCES_CREATE_SUCCESS';
export const ABSENCES_CREATE_ERROR = 'ABSENCES_CREATE_ERROR';
export const ABSENCES_DELETE_START = 'ABSENCES_DELETE_START';
export const ABSENCES_DELETE_SUCCESS = 'ABSENCES_DELETE_SUCCESS';
export const ABSENCES_DELETE_ERROR = 'ABSENCES_DELETE_ERROR';
export const ABSENCES_UPDATE_START = 'ABSENCES_UPDATE_START';
export const ABSENCES_UPDATE_SUCCESS = 'ABSENCES_UPDATE_SUCCESS';
export const ABSENCES_UPDATE_ERROR = 'ABSENCES_UPDATE_ERROR';
export const ABSENCES_GET_LIST_START = 'ABSENCES_GET_LIST_START';
export const ABSENCES_GET_LIST_SUCCESS = 'ABSENCES_GET_LIST_SUCCESS';
export const ABSENCES_GET_LIST_ERROR = 'ABSENCES_GET_LIST_ERROR';

export const AUTH_REGISTER_START = 'AUTH_REGISTER_START';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR';
export const AUTH_LOGIN_START = 'AUTH_LOGIN_START';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_USER_EXISTS_START = 'AUTH_USER_EXISTS_START';
export const AUTH_USER_EXISTS_SUCCESS = 'AUTH_USER_EXISTS_SUCCESS';
export const AUTH_USER_EXISTS_ERROR = 'AUTH_USER_EXISTS_ERROR';
export const AUTH_CHANGE_PASSWORD_START = 'AUTH_CHANGE_PASSWORD_START';
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS';
export const AUTH_CHANGE_PASSWORD_ERROR = 'AUTH_CHANGE_PASSWORD_ERROR';

export const ARTICLE_GET_START = 'ARTICLE_GET_START';
export const ARTICLE_GET_SUCCESS = 'ARTICLE_GET_SUCCESS';
export const ARTICLE_GET_ERROR = 'ARTICLE_GET_ERROR';
export const ARTICLE_CREATE_START = 'ARTICLE_CREATE_START';
export const ARTICLE_CREATE_SUCCESS = 'ARTICLE_CREATE_SUCCESS';
export const ARTICLE_CREATE_ERROR = 'ARTICLE_CREATE_ERROR';
export const ARTICLE_GETLIST_START = 'ARTICLE_GETLIST_START';
export const ARTICLE_GETLIST_SUCCESS = 'ARTICLE_GETLIST_SUCCESS';
export const ARTICLE_GETLIST_ERROR = 'ARTICLE_GETLIST_ERROR';
export const ARTICLE_UPDATE_START = 'ARTICLE_UPDATE_START';
export const ARTICLE_UPDATE_SUCCESS = 'ARTICLE_UPDATE_SUCCESS';
export const ARTICLE_UPDATE_ERROR = 'ARTICLE_UPDATE_ERROR';
export const ARTICLE_DELETE_START = 'ARTICLE_DELETE_START';
export const ARTICLE_DELETE_SUCCESS = 'ARTICLE_DELETE_SUCCESS';
export const ARTICLE_DELETE_ERROR = 'ARTICLE_DELETE_ERROR';

export const CLIENT_GET_START = 'CLIENT_GET_START';
export const CLIENT_GET_SUCCESS = 'CLIENT_GET_SUCCESS';
export const CLIENT_GET_ERROR = 'CLIENT_GET_ERROR';
export const CLIENT_UPDATE_START = 'CLIENT_UPDATE_START';
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS';
export const CLIENT_UPDATE_ERROR = 'CLIENT_UPDATE_ERROR';
export const CLIENT_GET_ALL_PARTNERS_START = 'CLIENT_GET_ALL_PARTNERS_START';
export const CLIENT_GET_ALL_PARTNERS_SUCCESS = 'CLIENT_GET_ALL_PARTNERS_SUCCESS';

export const CLIENT_INVOICE_GETLIST_START = 'CLIENT_INVOICE_GETLIST_START';
export const CLIENT_INVOICE_GETLIST_SUCCESS = 'CLIENT_INVOICE_GETLIST_SUCCESS';
export const CLIENT_INVOICE_GETLIST_ERROR = 'CLIENT_INVOICE_GETLIST_ERROR';
export const CLIENT_INVOICE_GET_START = 'CLIENT_INVOICE_GET_START';
export const CLIENT_INVOICE_GET_SUCCESS = 'CLIENT_INVOICE_GET_SUCCESS';
export const CLIENT_INVOICE_GET_ERROR = 'CLIENT_INVOICE_GET_ERROR';
export const CLIENT_INVOICE_CREATE_START = 'CLIENT_INVOICE_CREATE_START';
export const CLIENT_INVOICE_CREATE_SUCCESS = 'CLIENT_INVOICE_CREATE_SUCCESS';
export const CLIENT_INVOICE_CREATE_ERROR = 'CLIENT_INVOICE_CREATE_ERROR';
export const CLIENT_INVOICE_UPDATE_START = 'CLIENT_INVOICE_UPDATE_START';
export const CLIENT_INVOICE_UPDATE_SUCCESS = 'CLIENT_INVOICE_UPDATE_SUCCESS';
export const CLIENT_INVOICE_UPDATE_ERROR = 'CLIENT_INVOICE_UPDATE_ERROR';
export const CLIENT_INVOICE_DOWNLOAD_START = 'CLIENT_INVOICE_DOWNLOAD_START';
export const CLIENT_INVOICE_DOWNLOAD_SUCCESS = 'CLIENT_INVOICE_DOWNLOAD_SUCCESS';
export const CLIENT_INVOICE_DOWNLOAD_ERROR = 'CLIENT_INVOICE_DOWNLOAD_ERROR';
export const CLIENT_INVOICE_SEND_MAIL_START = 'CLIENT_INVOICE_SEND_MAIL_START';
export const CLIENT_INVOICE_SEND_MAIL_SUCCESS = 'CLIENT_INVOICE_SEND_MAIL_SUCCESS';
export const CLIENT_INVOICE_SEND_MAIL_ERROR = 'CLIENT_INVOICE_SEND_MAIL_ERROR';

export const CLIENT_SUBSCRIPTION_CREATE_START = 'CLIENT_SUBSCRIPTION_CREATE_START';
export const CLIENT_SUBSCRIPTION_CREATE_SUCCESS = 'CLIENT_SUBSCRIPTION_CREATE_SUCCESS';
export const CLIENT_SUBSCRIPTION_CREATE_ERROR = 'CLIENT_SUBSCRIPTION_CREATE_ERROR';
export const CLIENT_SUBSCRIPTION_GET_ACTIVE_START = 'CLIENT_SUBSCRIPTION_GET_ACTIVE_START';
export const CLIENT_SUBSCRIPTION_GET_ACTIVE_SUCCESS = 'CLIENT_SUBSCRIPTION_GET_ACTIVE_SUCCESS';
export const CLIENT_SUBSCRIPTION_GET_ACTIVE_ERROR = 'CLIENT_SUBSCRIPTION_GET_ACTIVE_ERROR';
export const CLIENT_SUBSCRIPTION_GETLIST_START = 'CLIENT_SUBSCRIPTION_GETLIST_START';
export const CLIENT_SUBSCRIPTION_GETLIST_SUCCESS = 'CLIENT_SUBSCRIPTION_GETLIST_SUCCESS';
export const CLIENT_SUBSCRIPTION_GETLIST_ERROR = 'CLIENT_SUBSCRIPTION_GETLIST_ERROR';
export const CLIENT_SUBSCRIPTION_CANCEL_START = 'CLIENT_SUBSCRIPTION_CANCEL_START';
export const CLIENT_SUBSCRIPTION_CANCEL_SUCCESS = 'CLIENT_SUBSCRIPTION_CANCEL_SUCCESS';
export const CLIENT_SUBSCRIPTION_CANCEL_ERROR = 'CLIENT_SUBSCRIPTION_CANCEL_ERROR';
export const CLIENT_SUBSCRIPTION_CREATE_INVOICE_START = 'CLIENT_SUBSCRIPTION_CREATE_INVOICE_START';
export const CLIENT_SUBSCRIPTION_CREATE_INVOICE_SUCCESS = 'CLIENT_SUBSCRIPTION_CREATE_INVOICE_SUCCESS';
export const CLIENT_SUBSCRIPTION_CREATE_INVOICE_ERROR = 'CLIENT_SUBSCRIPTION_CREATE_INVOICE_ERROR';

export const CLIENT_TIMETRACKING_GETSTATE_START = 'CLIENT_TIMETRACKING_GETSTATE_START';
export const CLIENT_TIMETRACKING_GETSTATE_SUCCESS = 'CLIENT_TIMETRACKING_GETSTATE_SUCCESS';
export const CLIENT_TIMETRACKING_GETSTATE_ERROR = 'CLIENT_TIMETRACKING_GETSTATE_ERROR';
export const CLIENT_TIMETRACKING_GETBYMONTH_START = 'CLIENT_TIMETRACKING_GETBYMONTH_START';
export const CLIENT_TIMETRACKING_GETBYMONTH_SUCCESS = 'CLIENT_TIMETRACKING_GETBYMONTH_SUCCESS';
export const CLIENT_TIMETRACKING_GETBYMONTH_ERROR = 'CLIENT_TIMETRACKING_GETBYMONTH_ERROR';
export const CLIENT_TIMETRACKING_UPDATE_START = 'CLIENT_TIMETRACKING_UPDATE_START';
export const CLIENT_TIMETRACKING_UPDATE_SUCCESS = 'CLIENT_TIMETRACKING_UPDATE_SUCCESS';
export const CLIENT_TIMETRACKING_UPDATE_ERROR = 'CLIENT_TIMETRACKING_UPDATE_ERROR';
export const CLIENT_TIMETRACKING_CREATE_START = 'CLIENT_TIMETRACKING_CREATE_START';
export const CLIENT_TIMETRACKING_CREATE_SUCCESS = 'CLIENT_TIMETRACKING_CREATE_SUCCESS';
export const CLIENT_TIMETRACKING_CREATE_ERROR = 'CLIENT_TIMETRACKING_CREATE_ERROR';
export const CLIENT_TIMETRACKING_DELETE_START = 'CLIENT_TIMETRACKING_DELETE_START';
export const CLIENT_TIMETRACKING_DELETE_SUCCESS = 'CLIENT_TIMETRACKING_DELETE_SUCCESS';
export const CLIENT_TIMETRACKING_DELETE_ERROR = 'CLIENT_TIMETRACKING_DELETE_ERROR';
export const CLIENT_TIMETRACKING_CREATE_PDF_START = 'CLIENT_TIMETRACKING_CREATE_PDF_START';
export const CLIENT_TIMETRACKING_CREATE_PDF_SUCCESS = 'CLIENT_TIMETRACKING_CREATE_PDF_SUCCESS';
export const CLIENT_TIMETRACKING_CREATE_PDF_ERROR = 'CLIENT_TIMETRACKING_CREATE_PDF_ERROR';

export const CUSTOMER_GETLIST_START = 'CUSTOMER_GETLIST_START';
export const CUSTOMER_GETLIST_SUCCESS = 'CUSTOMER_GETLIST_SUCCESS';
export const CUSTOMER_GETLIST_ERROR = 'CUSTOMER_GETLIST_ERROR';
export const CUSTOMER_GET_START = 'CUSTOMER_GET_START';
export const CUSTOMER_GET_SUCCESS = 'CUSTOMER_GET_SUCCESS';
export const CUSTOMER_GET_ERROR = 'CUSTOMER_GET_ERROR';
export const CUSTOMER_CREATE_START = 'CUSTOMER_CREATE_START';
export const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER_CREATE_SUCCESS';
export const CUSTOMER_CREATE_ERROR = 'CUSTOMER_CREATE_ERROR';
export const CUSTOMER_UPDATE_START = 'CUSTOMER_UPDATE_START';
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS';
export const CUSTOMER_UPDATE_ERROR = 'CUSTOMER_UPDATE_ERROR';
export const CUSTOMER_DELETE_START = 'CUSTOMER_DELETE_START';
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS';
export const CUSTOMER_DELETE_ERROR = 'CUSTOMER_DELETE_ERROR';

export const CUSTOMER_INVOICES_GETLIST_START = 'CUSTOMER_INVOICES_GETLIST_START';
export const CUSTOMER_INVOICES_GETLIST_SUCCESS = 'CUSTOMER_INVOICES_GETLIST_SUCCESS';
export const CUSTOMER_INVOICES_GETLIST_ERROR = 'CUSTOMER_INVOICES_GETLIST_ERROR';

export const CUSTOMER_OFFERS_GETLIST_START = 'CUSTOMER_OFFERS_GETLIST_START';
export const CUSTOMER_OFFERS_GETLIST_SUCCESS = 'CUSTOMER_OFFERS_GETLIST_SUCCESS';
export const CUSTOMER_OFFERS_GETLIST_ERROR = 'CUSTOMER_OFFERS_GETLIST_ERROR';

export const CUSTOMER_NOTES_GETLIST_START = 'CUSTOMER_NOTES_GETLIST_START';
export const CUSTOMER_NOTES_GETLIST_SUCCESS = 'CUSTOMER_NOTES_GETLIST_SUCCESS';
export const CUSTOMER_NOTES_GETLIST_ERROR = 'CUSTOMER_NOTES_GETLIST_ERROR';
export const CUSTOMER_NOTES_CREATE_START = 'CUSTOMER_NOTES_CREATE_START';
export const CUSTOMER_NOTES_CREATE_SUCCESS = 'CUSTOMER_NOTES_CREATE_SUCCESS';
export const CUSTOMER_NOTES_CREATE_ERROR = 'CUSTOMER_NOTES_CREATE_ERROR';
export const CUSTOMER_NOTES_UPDATE_START = 'CUSTOMER_NOTES_UPDATE_START';
export const CUSTOMER_NOTES_UPDATE_SUCCESS = 'CUSTOMER_NOTES_UPDATE_SUCCESS';
export const CUSTOMER_NOTES_UPDATE_ERROR = 'CUSTOMER_NOTES_UPDATE_ERROR';
export const CUSTOMER_NOTES_DELETE_START = 'CUSTOMER_NOTES_DELETE_START';
export const CUSTOMER_NOTES_DELETE_SUCCESS = 'CUSTOMER_NOTES_DELETE_SUCCESS';
export const CUSTOMER_NOTES_DELETE_ERROR = 'CUSTOMER_NOTES_DELETE_ERROR';

export const CUSTOMER_MEDIA_UPLOAD_START = 'CUSTOMER_MEDIA_UPLOAD_START';
export const CUSTOMER_MEDIA_UPLOAD_SUCCESS = 'CUSTOMER_MEDIA_UPLOAD_SUCCESS';
export const CUSTOMER_MEDIA_UPLOAD_ERROR = 'CUSTOMER_MEDIA_UPLOAD_ERROR';
export const CUSTOMER_MEDIA_GETLIST_START = 'CUSTOMER_MEDIA_GETLIST_START';
export const CUSTOMER_MEDIA_GETLIST_SUCCESS = 'CUSTOMER_MEDIA_GETLIST_SUCCESS';
export const CUSTOMER_MEDIA_GETLIST_ERROR = 'CUSTOMER_MEDIA_GETLIST_ERROR';
export const CUSTOMER_MEDIA_DELETE_START = 'CUSTOMER_MEDIA_DELETE_START';
export const CUSTOMER_MEDIA_DELETE_SUCCESS = 'CUSTOMER_MEDIA_DELETE_SUCCESS';
export const CUSTOMER_MEDIA_DELETE_ERROR = 'CUSTOMER_MEDIA_DELETE_ERROR';

export const DASHBOARD_SERVICES_GET_START = 'DASHBOARD_SERVICES_GET_START';
export const DASHBOARD_SERVICES_GET_SUCCESS = 'DASHBOARD_SERVICES_GET_SUCCESS';
export const DASHBOARD_SERVICES_GET_ERROR = 'DASHBOARD_SERVICES_GET_ERROR';

export const DOCUMENT_UPLOAD_START = 'DOCUMENT_UPLOAD_START';
export const DOCUMENT_UPLOAD_SUCCESS = 'DOCUMENT_UPLOAD_SUCCESS';
export const DOCUMENT_UPLOAD_ERROR = 'DOCUMENT_UPLOAD_ERROR';
export const DOCUMENT_DELETE_START = 'DOCUMENT_DELETE_START';
export const DOCUMENT_DELETE_SUCCESS = 'DOCUMENT_DELETE_SUCCESS';
export const DOCUMENT_DELETE_ERROR = 'DOCUMENT_DELETE_ERROR';
export const DOCUMENT_FOLDER_DELETE_START = 'DOCUMENT_FOLDER_DELETE_START';
export const DOCUMENT_FOLDER_DELETE_SUCCESS = 'DOCUMENT_FOLDER_DELETE_SUCCESS';
export const DOCUMENT_FOLDER_DELETE_ERROR = 'DOCUMENT_FOLDER_DELETE_ERROR';
export const DOCUMENT_FOLDER_GET_FILES_START = 'DOCUMENT_FOLDER_GET_FILES_START';
export const DOCUMENT_FOLDER_GET_FILES_SUCCESS = 'DOCUMENT_FOLDER_GET_FILES_SUCCESS';
export const DOCUMENT_FOLDER_GET_FILES_ERROR = 'DOCUMENT_FOLDER_GET_FILES_ERROR';

export const FACILITY_CREATE_START = 'FACILITY_CREATE_START';
export const FACILITY_CREATE_SUCCESS = 'FACILITY_CREATE_SUCCESS';
export const FACILITY_CREATE_ERROR = 'FACILITY_CREATE_ERROR';
export const FACILITY_GET_START = 'FACILITY_GET_START';
export const FACILITY_GET_SUCCESS = 'FACILITY_GET_SUCCESS';
export const FACILITY_GET_ERROR = 'FACILITY_GET_ERROR';
export const FACILITY_GET_EXTERNAL_START = 'FACILITY_GET_EXTERNAL_START';
export const FACILITY_GET_EXTERNAL_SUCCESS = 'FACILITY_GET_EXTERNAL_SUCCESS';
export const FACILITY_GET_EXTERNAL_CACHE_SUCCESS = 'FACILITY_GET_EXTERNAL_CACHE_SUCCESS';
export const FACILITY_GET_EXTERNAL_ERROR = 'FACILITY_GET_EXTERNAL_ERROR';
export const FACILITY_GETLIST_START = 'FACILITY_GETLIST_START';
export const FACILITY_GETLIST_SUCCESS = 'FACILITY_GETLIST_SUCCESS';
export const FACILITY_GETLIST_ERROR = 'FACILITY_GETLIST_ERROR';
export const FACILITY_SEND_MAIL_START = 'FACILITY_SEND_MAIL_START';
export const FACILITY_SEND_MAIL_SUCCESS = 'FACILITY_SEND_MAIL_SUCCESS';
export const FACILITY_SEND_MAIL_ERROR = 'FACILITY_SEND_MAIL_ERROR';
export const FACILITY_UPDATE_START = 'FACILITY_UPDATE_START';
export const FACILITY_UPDATE_SUCCESS = 'FACILITY_UPDATE_SUCCESS';
export const FACILITY_UPDATE_ERROR = 'FACILITY_UPDATE_ERROR';
export const FACILITY_UPDATE_LAST_INVOICE_NUMBER_START = 'FACILITY_UPDATE_LAST_INVOICE_NUMBER_START';
export const FACILITY_UPDATE_LAST_INVOICE_NUMBER_SUCCESS = 'FACILITY_UPDATE_LAST_INVOICE_NUMBER_SUCCESS';
export const FACILITY_UPDATE_LAST_INVOICE_NUMBER_ERROR = 'FACILITY_UPDATE_LAST_INVOICE_NUMBER_ERROR';

export const FACILITY_DOCUMENT_CREATE_START = 'FACILITY_DOCUMENT_CREATE_START';
export const FACILITY_DOCUMENT_CREATE_SUCCESS = 'FACILITY_DOCUMENT_CREATE_SUCCESS';
export const FACILITY_DOCUMENT_CREATE_ERROR = 'FACILITY_DOCUMENT_CREATE_ERROR';
export const FACILITY_DOCUMENT_GET_START = 'FACILITY_DOCUMENT_GET_START';
export const FACILITY_DOCUMENT_GET_SUCCESS = 'FACILITY_DOCUMENT_GET_SUCCESS';
export const FACILITY_DOCUMENT_GET_ERROR = 'FACILITY_DOCUMENT_GET_ERROR';
export const FACILITY_DOCUMENT_DELETE_START = 'FACILITY_DOCUMENT_DELETE_START';
export const FACILITY_DOCUMENT_DELETE_SUCCESS = 'FACILITY_DOCUMENT_DELETE_SUCCESS';
export const FACILITY_DOCUMENT_DELETE_ERROR = 'FACILITY_DOCUMENT_DELETE_ERROR';

export const FACILITY_PARTNER_GET_INFOS_START = 'FACILITY_PARTNER_GET_INFOS_START';
export const FACILITY_PARTNER_GET_INFOS_SUCCESS = 'FACILITY_PARTNER_GET_INFOS_SUCCESS';
export const FACILITY_PARTNER_GET_INFOS_ERROR = 'FACILITY_PARTNER_GET_INFOS_ERROR';
export const FACILITY_PARTNER_CREATE_START = 'FACILITY_PARTNER_CREATE_START';
export const FACILITY_PARTNER_CREATE_SUCCESS = 'FACILITY_PARTNER_CREATE_SUCCESS';
export const FACILITY_PARTNER_CREATE_ERROR = 'FACILITY_PARTNER_CREATE_ERROR';
export const FACILITY_PARTNER_GET_LIST_START = 'FACILITY_PARTNER_GET_LIST_START';
export const FACILITY_PARTNER_GET_LIST_SUCCESS = 'FACILITY_PARTNER_GET_LIST_SUCCESS';
export const FACILITY_PARTNER_GET_LIST_FROM_CACHE_SUCCESS = 'FACILITY_PARTNER_GET_LIST_FROM_CACHE_SUCCESS';
export const FACILITY_PARTNER_GET_LIST_ERROR = 'FACILITY_PARTNER_GET_LIST_ERROR';
export const FACILITY_PARTNER_GET_START = 'FACILITY_PARTNER_GET_START';
export const FACILITY_PARTNER_GET_SUCCESS = 'FACILITY_PARTNER_GET_SUCCESS';
export const FACILITY_PARTNER_GET_ERROR = 'FACILITY_PARTNER_GET_ERROR';
export const FACILITY_PARTNER_UPDATE_START = 'FACILITY_PARTNER_UPDATE_START';
export const FACILITY_PARTNER_UPDATE_SUCCESS = 'FACILITY_PARTNER_UPDATE_SUCCESS';
export const FACILITY_PARTNER_UPDATE_ERROR = 'FACILITY_PARTNER_UPDATE_ERROR';
export const FACILITY_PARTNER_DELETE_START = 'FACILITY_PARTNER_DELETE_START';
export const FACILITY_PARTNER_DELETE_SUCCESS = 'FACILITY_PARTNER_DELETE_SUCCESS';
export const FACILITY_PARTNER_DELETE_ERROR = 'FACILITY_PARTNER_DELETE_ERROR';

export const FACILITY_MAIL_TEMPLATE_GETLIST_START = 'FACILITY_MAIL_TEMPLATE_GETLIST_START';
export const FACILITY_MAIL_TEMPLATE_GETLIST_SUCCESS = 'FACILITY_MAIL_TEMPLATE_GETLIST_SUCCESS';
export const FACILITY_MAIL_TEMPLATE_GETLIST_ERROR = 'FACILITY_MAIL_TEMPLATE_GETLIST_ERROR';
export const FACILITY_MAIL_TEMPLATE_CREATE_START = 'FACILITY_MAIL_TEMPLATE_CREATE_START';
export const FACILITY_MAIL_TEMPLATE_CREATE_SUCCESS = 'FACILITY_MAIL_TEMPLATE_CREATE_SUCCESS';
export const FACILITY_MAIL_TEMPLATE_CREATE_ERROR = 'FACILITY_MAIL_TEMPLATE_CREATE_ERROR';
export const FACILITY_MAIL_TEMPLATE_UPDATE_START = 'FACILITY_MAIL_TEMPLATE_UPDATE_START';
export const FACILITY_MAIL_TEMPLATE_UPDATE_SUCCESS = 'FACILITY_MAIL_TEMPLATE_UPDATE_SUCCESS';
export const FACILITY_MAIL_TEMPLATE_UPDATE_ERROR = 'FACILITY_MAIL_TEMPLATE_UPDATE_ERROR';
export const FACILITY_MAIL_TEMPLATE_DELETE_START = 'FACILITY_MAIL_TEMPLATE_DELETE_START';
export const FACILITY_MAIL_TEMPLATE_DELETE_SUCCESS = 'FACILITY_MAIL_TEMPLATE_DELETE_SUCCESS';
export const FACILITY_MAIL_TEMPLATE_DELETE_ERROR = 'FACILITY_MAIL_TEMPLATE_DELETE_ERROR';
export const FACILITY_MAIL_TEMPLATE_SEND_START = 'FACILITY_MAIL_TEMPLATE_SEND_START';
export const FACILITY_MAIL_TEMPLATE_SEND_SUCCESS = 'FACILITY_MAIL_TEMPLATE_SEND_SUCCESS';
export const FACILITY_MAIL_TEMPLATE_SEND_ERROR = 'FACILITY_MAIL_TEMPLATE_SEND_ERROR';

export const INTERNAL_SETTINGS_GET_START = 'INTERNAL_SETTINGS_GET_START';
export const INTERNAL_SETTINGS_GET_SUCCESS = 'INTERNAL_SETTINGS_GET_SUCCESS';
export const INTERNAL_SETTINGS_GET_ERROR = 'INTERNAL_SETTINGS_GET_ERROR';
export const INTERNAL_SETTINGS_UPDATE_START = 'INTERNAL_SETTINGS_UPDATE_START';
export const INTERNAL_SETTINGS_UPDATE_SUCCESS = 'INTERNAL_SETTINGS_UPDATE_SUCCESS';
export const INTERNAL_SETTINGS_UPDATE_ERROR = 'INTERNAL_SETTINGS_UPDATE_ERROR';

export const OFFER_CREATE_START = 'OFFER_CREATE_START';
export const OFFER_CREATE_SUCCESS = 'OFFER_CREATE_SUCCESS';
export const OFFER_CREATE_ERROR = 'OFFER_CREATE_ERROR';
export const OFFER_UPDATE_START = 'OFFER_UPDATE_START';
export const OFFER_UPDATE_SUCCESS = 'OFFER_UPDATE_SUCCESS';
export const OFFER_UPDATE_ERROR = 'OFFER_UPDATE_ERROR';
export const OFFER_CREATE_PDF_START = 'OFFER_CREATE_PDF_START';
export const OFFER_CREATE_PDF_SUCCESS = 'OFFER_CREATE_PDF_SUCCESS';
export const OFFER_CREATE_PDF_ERROR = 'OFFER_CREATE_PDF_ERROR';
export const OFFER_DELETE_START = 'OFFER_DELETE_START';
export const OFFER_DELETE_SUCCESS = 'OFFER_DELETE_SUCCESS';
export const OFFER_DELETE_ERROR = 'OFFER_DELETE_ERROR';

export const ORDER_CREATE_START = 'ORDER_CREATE_START';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_ERROR = 'ORDER_CREATE_ERROR';
export const ORDER_UPDATE_START = 'ORDER_UPDATE_START';
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS';
export const ORDER_UPDATE_ERROR = 'ORDER_UPDATE_ERROR';
export const ORDER_GET_START = 'ORDER_GET_START';
export const ORDER_GET_SUCCESS = 'ORDER_GET_SUCCESS';
export const ORDER_GET_ERROR = 'ORDER_GET_ERROR';
export const ORDER_DELETE_START = 'ORDER_DELETE_START';
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS';
export const ORDER_DELETE_ERROR = 'ORDER_DELETE_ERROR';

export const ORDER_TIMETRACKING_GETLIST_START = 'ORDER_TIMETRACKING_GETLIST_START';
export const ORDER_TIMETRACKING_GETLIST_SUCCESS = 'ORDER_TIMETRACKING_GETLIST_SUCCESS';
export const ORDER_TIMETRACKING_GETLIST_ERROR = 'ORDER_TIMETRACKING_GETLIST_ERROR';

export const ORDER_FILTER_SET_SUCCESS = 'ORDER_FILTER_SET_SUCCESS';
export const ORDER_FILTER_LOAD_SUCCESS = 'ORDER_FILTER_LOAD_SUCCESS';

export const ORDER_SERVICE_SET_SELECTED = 'ORDER_SERVICE_SET_SELECTED';
export const ORDER_SERVICE_GETLIST_START = 'ORDER_SERVICE_GETLIST_START';
export const ORDER_SERVICE_GETLIST_SUCCESS = 'ORDER_SERVICE_GETLIST_SUCCESS';
export const ORDER_SERVICE_GETLIST_ERROR = 'ORDER_SERVICE_GETLIST_ERROR';
export const ORDER_SERVICE_FILTER_START = 'ORDER_SERVICE_FILTER_START';
export const ORDER_SERVICE_FILTER_SUCCESS = 'ORDER_SERVICE_FILTER_SUCCESS';
export const ORDER_SERVICE_FILTER_ERROR = 'ORDER_SERVICE_FILTER_ERROR';
export const ORDER_SERVICE_SEARCH_START = 'ORDER_SERVICE_SEARCH_START';
export const ORDER_SERVICE_SEARCH_SUCCESS = 'ORDER_SERVICE_SEARCH_SUCCESS';
export const ORDER_SERVICE_SEARCH_ERROR = 'ORDER_SERVICE_SEARCH_ERROR';
export const ORDER_SERVICE_REBUILD_INDEX_START = 'ORDER_SERVICE_REBUILD_INDEX_START';
export const ORDER_SERVICE_REBUILD_INDEX_SUCCESS = 'ORDER_SERVICE_REBUILD_INDEX_SUCCESS';
export const ORDER_SERVICE_REBUILD_INDEX_ERROR = 'ORDER_SERVICE_REBUILD_INDEX_ERROR';
export const ORDER_SERVICE_UPDATE_START = 'ORDER_SERVICE_UPDATE_START';
export const ORDER_SERVICE_UPDATE_SUCCESS = 'ORDER_SERVICE_UPDATE_SUCCESS';
export const ORDER_SERVICE_UPDATE_ERROR = 'ORDER_SERVICE_UPDATE_ERROR';
export const ORDER_SERVICE_DELETE_START = 'ORDER_SERVICE_DELETE_START';
export const ORDER_SERVICE_DELETE_SUCCESS = 'ORDER_SERVICE_DELETE_SUCCESS';
export const ORDER_SERVICE_DELETE_ERROR = 'ORDER_SERVICE_DELETE_ERROR';
export const ORDER_SERVICE_CREATE_START = 'ORDER_SERVICE_CREATE_START';
export const ORDER_SERVICE_CREATE_SUCCESS = 'ORDER_SERVICE_CREATE_SUCCESS';
export const ORDER_SERVICE_CREATE_ERROR = 'ORDER_SERVICE_CREATE_ERROR';
export const ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_START = 'ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_START';
export const ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_SUCCESS =
  'ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_SUCCESS';
export const ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_ERROR = 'ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_ERROR';
export const ORDER_SERVICE_INITIALIZE_INDEX_START = 'ORDER_SERVICE_INITIALIZE_INDEX_START';
export const ORDER_SERVICE_INITIALIZE_INDEX_SUCCESS = 'ORDER_SERVICE_INITIALIZE_INDEX_SUCCESS';
export const ORDER_SERVICE_INITIALIZE_INDEX_ERROR = 'ORDER_SERVICE_INITIALIZE_INDEX_ERROR';

export const ORDER_SERVICE_FILTER_UNSCHEDULED_START = 'ORDER_SERVICE_FILTER_UNSCHEDULED_START';
export const ORDER_SERVICE_FILTER_UNSCHEDULED_SUCCESS = 'ORDER_SERVICE_FILTER_UNSCHEDULED_SUCCESS';
export const ORDER_SERVICE_FILTER_UNSCHEDULED_ERROR = 'ORDER_SERVICE_FILTER_UNSCHEDULED_ERROR';
export const ORDER_SERVICE_FILTER_BY_CUSTOMER_START = 'ORDER_SERVICE_FILTER_BY_CUSTOMER_START';
export const ORDER_SERVICE_FILTER_BY_CUSTOMER_SUCCESS = 'ORDER_SERVICE_FILTER_BY_CUSTOMER_SUCCESS';
export const ORDER_SERVICE_FILTER_BY_CUSTOMER_ERROR = 'ORDER_SERVICE_FILTER_BY_CUSTOMER_ERROR';
export const ORDER_SERVICE_GET_FROM_INDEX_START = 'ORDER_SERVICE_GET_FROM_INDEX_START';
export const ORDER_SERVICE_GET_FROM_INDEX_SUCCESS = 'ORDER_SERVICE_GET_FROM_INDEX_SUCCESS';
export const ORDER_SERVICE_GET_FROM_INDEX_ERROR = 'ORDER_SERVICE_GET_FROM_INDEX_ERROR';

export const ORDER_SERVICE_DOCUMENT_GETLIST_START = 'ORDER_SERVICE_DOCUMENT_GETLIST_START';
export const ORDER_SERVICE_DOCUMENT_GETLIST_SUCCESS = 'ORDER_SERVICE_DOCUMENT_GETLIST_SUCCESS';
export const ORDER_SERVICE_DOCUMENT_GETLIST_ERROR = 'ORDER_SERVICE_DOCUMENT_GETLIST_ERROR';

export const ORDER_SERVICE_REPORT_GETLIST_START = 'ORDER_SERVICE_REPORT_GETLIST_START';
export const ORDER_SERVICE_REPORT_GETLIST_SUCCESS = 'ORDER_SERVICE_REPORT_GETLIST_SUCCESS';
export const ORDER_SERVICE_REPORT_GETLIST_ERROR = 'ORDER_SERVICE_REPORT_GETLIST_ERROR';
export const ORDER_SERVICE_REPORT_CREATE_PDF_START = 'ORDER_SERVICE_REPORT_CREATE_PDF_START';
export const ORDER_SERVICE_REPORT_CREATE_PDF_SUCCESS = 'ORDER_SERVICE_REPORT_CREATE_PDF_SUCCESS';
export const ORDER_SERVICE_REPORT_CREATE_PDF_ERROR = 'ORDER_SERVICE_REPORT_CREATE_PDF_ERROR';

export const ORDER_SERVICE_TIMETRACKING_GETLIST_START = 'ORDER_SERVICE_TIMETRACKING_GETLIST_START';
export const ORDER_SERVICE_TIMETRACKING_GETLIST_SUCCESS = 'ORDER_SERVICE_TIMETRACKING_GETLIST_SUCCESS';
export const ORDER_SERVICE_TIMETRACKING_GETLIST_ERROR = 'ORDER_SERVICE_TIMETRACKING_GETLIST_ERROR';

export const ORDER_MESSAGE_GETLIST_START = 'ORDER_MESSAGE_GETLIST_START';
export const ORDER_MESSAGE_GETLIST_SUCCESS = 'ORDER_MESSAGE_GETLIST_SUCCESS';
export const ORDER_MESSAGE_GETLIST_ERROR = 'ORDER_MESSAGE_GETLIST_ERROR';
export const ORDER_MESSAGE_CREATE_START = 'ORDER_MESSAGE_CREATE_START';
export const ORDER_MESSAGE_CREATE_SUCCESS = 'ORDER_MESSAGE_CREATE_SUCCESS';
export const ORDER_MESSAGE_CREATE_ERROR = 'ORDER_MESSAGE_CREATE_ERROR';
export const ORDER_MESSAGE_DELETE_START = 'ORDER_MESSAGE_DELETE_START';
export const ORDER_MESSAGE_DELETE_SUCCESS = 'ORDER_MESSAGE_DELETE_SUCCESS';
export const ORDER_MESSAGE_DELETE_ERROR = 'ORDER_MESSAGE_DELETE_ERROR';
export const ORDER_MESSAGE_DELETE_INDEX_START = 'ORDER_MESSAGE_DELETE_INDEX_START';
export const ORDER_MESSAGE_DELETE_INDEX_SUCCESS = 'ORDER_MESSAGE_DELETE_INDEX_SUCCESS';
export const ORDER_MESSAGE_DELETE_INDEX_ERROR = 'ORDER_MESSAGE_DELETE_INDEX_ERROR';
export const ORDER_MESSAGE_INITIALIZE_INDEX_START = 'ORDER_MESSAGE_INITIALIZE_INDEX_START';
export const ORDER_MESSAGE_INITIALIZE_INDEX_SUCCESS = 'ORDER_MESSAGE_INITIALIZE_INDEX_SUCCESS';
export const ORDER_MESSAGE_INITIALIZE_INDEX_ERROR = 'ORDER_MESSAGE_INITIALIZE_INDEX_ERROR';

export const POSITION_GETLIST_START = 'POSITION_GETLIST_START';
export const POSITION_GETLIST_SUCCESS = 'POSITION_GETLIST_SUCCESS';
export const POSITION_GETLIST_ERROR = 'POSITION_GETLIST_ERROR';
export const POSITION_GET_START = 'POSITION_GET_START';
export const POSITION_GET_SUCCESS = 'POSITION_GET_SUCCESS';
export const POSITION_GET_ERROR = 'POSITION_GET_ERROR';
export const POSITION_CREATE_START = 'POSITION_CREATE_START';
export const POSITION_CREATE_SUCCESS = 'POSITION_CREATE_SUCCESS';
export const POSITION_CREATE_ERROR = 'POSITION_CREATE_ERROR';
export const POSITION_UPDATE_START = 'POSITION_UPDATE_START';
export const POSITION_UPDATE_SUCCESS = 'POSITION_UPDATE_SUCCESS';
export const POSITION_UPDATE_ERROR = 'POSITION_UPDATE_ERROR';
export const POSITION_DELETE_START = 'POSITION_DELETE_START';
export const POSITION_DELETE_SUCCESS = 'POSITION_DELETE_SUCCESS';
export const POSITION_DELETE_ERROR = 'POSITION_DELETE_ERROR';

export const RESOURCE_CREATE_START = 'RESOURCE_CREATE_START';
export const RESOURCE_CREATE_SUCCESS = 'RESOURCE_CREATE_SUCCESS';
export const RESOURCE_CREATE_ERROR = 'RESOURCE_CREATE_ERROR';
export const RESOURCE_DELETE_START = 'RESOURCE_DELETE_START';
export const RESOURCE_DELETE_SUCCESS = 'RESOURCE_DELETE_SUCCESS';
export const RESOURCE_DELETE_ERROR = 'RESOURCE_DELETE_ERROR';
export const RESOURCE_GET_START = 'RESOURCE_GET_START';
export const RESOURCE_GET_SUCCESS = 'RESOURCE_GET_SUCCESS';
export const RESOURCE_GET_ERROR = 'RESOURCE_GET_ERROR';
export const RESOURCE_GETLIST_START = 'RESOURCE_GET_LIST_START';
export const RESOURCE_GETLIST_SUCCESS = 'RESOURCE_GET_LIST_SUCCESS';
export const RESOURCE_GETLIST_ERROR = 'RESOURCE_GET_LIST_ERROR';
export const RESOURCE_UPDATE_START = 'RESOURCE_UPDATE_START';
export const RESOURCE_UPDATE_SUCCESS = 'RESOURCE_UPDATE_SUCCESS';
export const RESOURCE_UPDATE_ERROR = 'RESOURCE_UPDATE_ERROR';

export const STORAGE_ADDRESS_CREATE_START = 'STORAGE_ADDRESS_CREATE_START';
export const STORAGE_ADDRESS_CREATE_SUCCESS = 'STORAGE_ADDRESS_CREATE_SUCCESS';
export const STORAGE_ADDRESS_CREATE_ERROR = 'STORAGE_ADDRESS_CREATE_ERROR';
export const STORAGE_ADDRESS_GET_START = 'STORAGE_ADDRESS_GET_START';
export const STORAGE_ADDRESS_GET_SUCCESS = 'STORAGE_ADDRESS_GET_SUCCESS';
export const STORAGE_ADDRESS_GET_ERROR = 'STORAGE_ADDRESS_GET_ERROR';
export const STORAGE_ADDRESS_GETLIST_START = 'STORAGE_ADDRESS_GETLIST_START';
export const STORAGE_ADDRESS_GETLIST_SUCCESS = 'STORAGE_ADDRESS_GETLIST_SUCCESS';
export const STORAGE_ADDRESS_GETLIST_ERROR = 'STORAGE_ADDRESS_GETLIST_ERROR';
export const STORAGE_ADDRESS_UPDATE_START = 'STORAGE_ADDRESS_UPDATE_START';
export const STORAGE_ADDRESS_UPDATE_SUCCESS = 'STORAGE_ADDRESS_UPDATE_SUCCESS';
export const STORAGE_ADDRESS_UPDATE_ERROR = 'STORAGE_ADDRESS_UPDATE_ERROR';
export const STORAGE_ADDRESS_DELETE_START = 'STORAGE_ADDRESS_DELETE_START';
export const STORAGE_ADDRESS_DELETE_SUCCESS = 'STORAGE_ADDRESS_DELETE_SUCCESS';
export const STORAGE_ADDRESS_DELETE_ERROR = 'STORAGE_ADDRESS_DELETE_ERROR';
export const STORAGE_ADDRESS_CREATE_FROM_FACILITIES_START = 'STORAGE_ADDRESS_CREATE_FROM_FACILITIES_START';
export const STORAGE_ADDRESS_CREATE_FROM_FACILITIES_SUCCESS = 'STORAGE_ADDRESS_CREATE_FROM_FACILITIES_SUCCESS';
export const STORAGE_ADDRESS_CREATE_FROM_FACILITIES_ERROR = 'STORAGE_ADDRESS_CREATE_FROM_FACILITIES_ERROR';
export const STORAGE_ADDRESS_GET_FROM_PARTNER_START = 'STORAGE_ADDRESS_GET_FROM_PARTNER_START';
export const STORAGE_ADDRESS_GET_FROM_PARTNER_SUCCESS = 'STORAGE_ADDRESS_GET_FROM_PARTNER_SUCCESS';
export const STORAGE_ADDRESS_GET_FROM_PARTNER_ERROR = 'STORAGE_ADDRESS_GET_FROM_PARTNER_ERROR';

export const TOUR_ADD_RESOURCE_START = 'TOUR_ADD_RESOURCE_START';
export const TOUR_ADD_RESOURCE_SUCCESS = 'TOUR_ADD_RESOURCE_SUCCESS';
export const TOUR_ADD_RESOURCE_ERROR = 'TOUR_ADD_RESOURCE_ERROR';
export const TOUR_ADD_SERVICE_START = 'TOUR_ADD_SERVICE_START';
export const TOUR_ADD_SERVICE_SUCCESS = 'TOUR_ADD_SERVICE_SUCCESS';
export const TOUR_ADD_SERVICE_ERROR = 'TOUR_ADD_SERVICE_ERROR';
export const TOUR_ADD_USER_START = 'TOUR_ADD_USER_START';
export const TOUR_ADD_USER_SUCCESS = 'TOUR_ADD_USER_SUCCESS';
export const TOUR_ADD_USER_ERROR = 'TOUR_ADD_USER_ERROR';
export const TOUR_CREATE_START = 'TOUR_CREATE_START';
export const TOUR_CREATE_SUCCESS = 'TOUR_CREATE_SUCCESS';
export const TOUR_CREATE_ERROR = 'TOUR_CREATE_ERROR';
export const TOUR_CREATE_FROM_TEMPLATE_START = 'TOUR_CREATE_FROM_TEMPLATE_START';
export const TOUR_CREATE_FROM_TEMPLATE_SUCCESS = 'TOUR_CREATE_FROM_TEMPLATE_SUCCESS';
export const TOUR_CREATE_FROM_TEMPLATE_ERROR = 'TOUR_CREATE_FROM_TEMPLATE_ERROR';
export const TOUR_DELETE_START = 'TOUR_DELETE_START';
export const TOUR_DELETE_SUCCESS = 'TOUR_DELETE_SUCCESS';
export const TOUR_DELETE_ERROR = 'TOUR_DELETE_ERROR';
export const TOUR_DELETE_RESOURCE_START = 'TOUR_DELETE_RESOURCE_START';
export const TOUR_DELETE_RESOURCE_SUCCESS = 'TOUR_DELETE_RESOURCE_SUCCESS';
export const TOUR_DELETE_RESOURCE_ERROR = 'TOUR_DELETE_RESOURCE_ERROR';
export const TOUR_DELETE_SERVICE_START = 'TOUR_DELETE_SERVICE_START';
export const TOUR_DELETE_SERVICE_SUCCESS = 'TOUR_DELETE_SERVICE_SUCCESS';
export const TOUR_DELETE_SERVICE_ERROR = 'TOUR_DELETE_SERVICE_ERROR';
export const TOUR_DELETE_USER_START = 'TOUR_DELETE_USER_START';
export const TOUR_DELETE_USER_SUCCESS = 'TOUR_DELETE_USER_SUCCESS';
export const TOUR_DELETE_USER_ERROR = 'TOUR_DELETE_USER_ERROR';
export const TOUR_GET_START = 'TOUR_GET_START';
export const TOUR_GET_SUCCESS = 'TOUR_GET_SUCCESS';
export const TOUR_GET_ERROR = 'TOUR_GET_ERROR';
export const TOUR_GETLIST_START = 'TOUR_GETLIST_START';
export const TOUR_GETLIST_SUCCESS = 'TOUR_GETLIST_SUCCESS';
export const TOUR_GETLIST_ERROR = 'TOUR_GETLIST_ERROR';
export const TOUR_TOGGLE_CLOSE_START = 'TOUR_TOGGLE_CLOSE_START';
export const TOUR_TOGGLE_CLOSE_SUCCESS = 'TOUR_TOGGLE_CLOSE_SUCCESS';
export const TOUR_TOGGLE_CLOSE_ERROR = 'TOUR_TOGGLE_CLOSE_ERROR';
export const TOUR_UPDATE_START = 'TOUR_UPDATE_START';
export const TOUR_UPDATE_SUCCESS = 'TOUR_UPDATE_SUCCESS';
export const TOUR_UPDATE_ERROR = 'TOUR_UPDATE_ERROR';
export const TOUR_GET_FOR_SERVICE_START = 'TOUR_GET_FOR_SERVICE_START';
export const TOUR_GET_FOR_SERVICE_SUCCESS = 'TOUR_GET_FOR_SERVICE_SUCCESS';
export const TOUR_GET_FOR_SERVICE_ERROR = 'TOUR_GET_FOR_SERVICE_ERROR';
export const TOUR_GET_FOR_ORDER_START = 'TOUR_GET_FOR_ORDER_START';
export const TOUR_GET_FOR_ORDER_SUCCESS = 'TOUR_GET_FOR_ORDER_SUCCESS';
export const TOUR_GET_FOR_ORDER_ERROR = 'TOUR_GET_FOR_ORDER_ERROR';

export const TOUR_LOCATION_GET_START = 'TOUR_LOCATION_GET_START';
export const TOUR_LOCATION_GET_SUCCESS = 'TOUR_LOCATION_GET_SUCCESS';
export const TOUR_LOCATION_GET_ERROR = 'TOUR_LOCATION_GET_ERROR';
export const TOUR_LOCATION_LISTEN_SUCCESS = 'TOUR_LOCATION_LISTEN_SUCCESS';
export const TOUR_LOCATION_LAST_LISTEN_SUCCESS = 'TOUR_LOCATION_LAST_LISTEN_SUCCESS';

export const TOUR_TEMPLATE_CREATE_START = 'TOUR_TEMPLATE_CREATE_START';
export const TOUR_TEMPLATE_CREATE_SUCCESS = 'TOUR_TEMPLATE_CREATE_SUCCESS';
export const TOUR_TEMPLATE_CREATE_ERROR = 'TOUR_TEMPLATE_CREATE_ERROR';
export const TOUR_TEMPLATE_DELETE_START = 'TOUR_TEMPLATE_DELETE_START';
export const TOUR_TEMPLATE_DELETE_SUCCESS = 'TOUR_TEMPLATE_DELETE_SUCCESS';
export const TOUR_TEMPLATE_DELETE_ERROR = 'TOUR_TEMPLATE_DELETE_ERROR';
export const TOUR_TEMPLATE_GET_START = 'TOUR_TEMPLATE_GET_START';
export const TOUR_TEMPLATE_GET_SUCCESS = 'TOUR_TEMPLATE_GET_SUCCESS';
export const TOUR_TEMPLATE_GET_ERROR = 'TOUR_TEMPLATE_GET_ERROR';
export const TOUR_TEMPLATE_GETLIST_START = 'TOUR_TEMPLATE_GETLIST_START';
export const TOUR_TEMPLATE_GETLIST_SUCCESS = 'TOUR_TEMPLATE_GETLIST_SUCCESS';
export const TOUR_TEMPLATE_GETLIST_ERROR = 'TOUR_TEMPLATE_GETLIST_ERROR';
export const TOUR_TEMPLATE_UPDATE_START = 'TOUR_TEMPLATE_UPDATE_START';
export const TOUR_TEMPLATE_UPDATE_SUCCESS = 'TOUR_TEMPLATE_UPDATE_SUCCESS';
export const TOUR_TEMPLATE_UPDATE_ERROR = 'TOUR_TEMPLATE_UPDATE_ERROR';

export const USER_CREATE_START = 'USER_CREATE_START';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';
export const USER_DELETE_START = 'USER_DELETE_START';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';
export const USER_GETLIST_START = 'USER_GETLIST_START';
export const USER_GETLIST_SUCCESS = 'USER_GETLIST_SUCCESS';
export const USER_GETLIST_ERROR = 'USER_GETLIST_ERROR';
export const USER_GET_START = 'USER_GET_START';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_ERROR = 'USER_GET_ERROR';
export const USER_UPDATE_START = 'USER_UPDATE_START';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const USER_ABSENCES_GETLIST_START = 'USER_ABSENCES_GETLIST_START';
export const USER_ABSENCES_GETLIST_SUCCESS = 'USER_ABSENCES_GETLIST_SUCCESS';
export const USER_ABSENCES_GETLIST_ERROR = 'USER_ABSENCES_GETLIST_ERROR';

export const USER_TASK_CREATE_START = 'USER_TASK_CREATE_START';
export const USER_TASK_CREATE_SUCCESS = 'USER_TASK_CREATE_SUCCESS';
export const USER_TASK_CREATE_ERROR = 'USER_TASK_CREATE_ERROR';
export const USER_TASK_GETLIST_START = 'USER_TASK_GETLIST_START';
export const USER_TASK_GETLIST_SUCCESS = 'USER_TASK_GETLIST_SUCCESS';
export const USER_TASK_GETLIST_ERROR = 'USER_TASK_GETLIST_ERROR';
export const USER_TASK_UPDATE_START = 'USER_TASK_UPDATE_START';
export const USER_TASK_UPDATE_SUCCESS = 'USER_TASK_UPDATE_SUCCESS';
export const USER_TASK_UPDATE_ERROR = 'USER_TASK_UPDATE_ERROR';
export const USER_TASK_DELETE_START = 'USER_TASK_DELETE_START';
export const USER_TASK_DELETE_SUCCESS = 'USER_TASK_DELETE_SUCCESS';
export const USER_TASK_DELETE_ERROR = 'USER_TASK_DELETE_ERROR';

export const USER_INBOX_SEND_MESSAGE_START = 'USER_INBOX_SEND_MESSAGE_START';
export const USER_INBOX_SEND_MESSAGE_SUCCESS = 'USER_INBOX_SEND_MESSAGE_SUCCESS';
export const USER_INBOX_SEND_MESSAGE_ERROR = 'USER_INBOX_SEND_MESSAGE_ERROR';
export const USER_INBOX_GETLIST_START = 'USER_INBOX_GETLIST_START';
export const USER_INBOX_GETLIST_SUCCESS = 'USER_INBOX_GETLIST_SUCCESS';
export const USER_INBOX_GETLIST_ERROR = 'USER_INBOX_GETLIST_ERROR';
export const USER_INBOX_DELETE_START = 'USER_INBOX_DELETE_START';
export const USER_INBOX_DELETE_SUCCESS = 'USER_INBOX_DELETE_SUCCESS';
export const USER_INBOX_DELETE_ERROR = 'USER_INBOX_DELETE_ERROR';
export const USER_INBOX_UNREAD_COUNT_START = 'USER_INBOX_UNREAD_COUNT_START';
export const USER_INBOX_UNREAD_COUNT_SUCCESS = 'USER_INBOX_UNREAD_COUNT_SUCCESS';
export const USER_INBOX_UNREAD_COUNT_ERROR = 'USER_INBOX_UNREAD_COUNT_ERROR';
export const USER_INBOX_MARK_READ_START = 'USER_INBOX_MARK_READ_START';
export const USER_INBOX_MARK_READ_SUCCESS = 'USER_INBOX_MARK_READ_SUCCESS';
export const USER_INBOX_MARK_READ_ERROR = 'USER_INBOX_MARK_READ_ERROR';
export const USER_INBOX_UPDATE_START = 'USER_INBOX_UPDATE_START';
export const USER_INBOX_UPDATE_SUCCESS = 'USER_INBOX_UPDATE_SUCCESS';
export const USER_INBOX_UPDATE_ERROR = 'USER_INBOX_UPDATE_ERROR';

export const USER_TIMETRACKING_GET_LAST_START = 'USER_TIMETRACKING_GET_LAST_START';
export const USER_TIMETRACKING_GET_LAST_SUCCESS = 'USER_TIMETRACKING_GET_LAST_SUCCESS';
export const USER_TIMETRACKING_GET_LAST_ERROR = 'USER_TIMETRACKING_GET_LAST_ERROR';
export const USER_TIMETRACKING_CREATE_START = 'USER_TIMETRACKING_CREATE_START';
export const USER_TIMETRACKING_CREATE_SUCCESS = 'USER_TIMETRACKING_CREATE_SUCCESS';
export const USER_TIMETRACKING_CREATE_ERROR = 'USER_TIMETRACKING_CREATE_ERROR';
export const USER_TIMETRACKING_GETBYMONTH_START = 'USER_TIMETRACKING_GETBYMONTH_START';
export const USER_TIMETRACKING_GETBYMONTH_SUCCESS = 'USER_TIMETRACKING_GETBYMONTH_SUCCESS';
export const USER_TIMETRACKING_GETBYMONTH_ERROR = 'USER_TIMETRACKING_GETBYMONTH_ERROR';
