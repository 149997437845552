import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { PASSWORD_MIN_LENGTH } from '../../../Globals/Globals';

export type ValidationResult = {
  isValid: boolean;
  errors: {
    password?: string;
    repeat?: string;
  };
};

/**
 * validateChangePassword
 * @param password
 * @param repeat
 */
const validateChangePassword = (password: string, repeat: string): ValidationResult => {
  let errors = {};

  if (!password || password.length < PASSWORD_MIN_LENGTH) {
    errors = { ...errors, password: InternalErrorCodes.PASSWORD_TO_SHORT };
  } else if (password.indexOf(' ') > -1) {
    errors = { ...errors, password: InternalErrorCodes.PASSWORD_INVALID_CHARS };
  }

  if (password !== repeat) {
    errors = { ...errors, repeat: InternalErrorCodes.PASSWORD_NO_MATCH };
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export default validateChangePassword;
