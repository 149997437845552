import React from 'react';
import ImageViewer from 'react-simple-image-viewer';

interface Props {
  images: string[];
  visible: boolean;
  onClose: () => void;
  selectedImage?: string;
}

/**
 * ImagePreview()
 * @param props
 * @constructor
 */
export default function ImagePreview(props: Props) {
  const { visible, images, onClose, selectedImage } = props;
  const [index, setIndex] = React.useState<number>(0);

  React.useEffect(() => {
    if (selectedImage) {
      const foundIndex = images.findIndex((item) => item === selectedImage);
      if (foundIndex) {
        setIndex(foundIndex);
      }
    }
  }, [images, selectedImage]);

  if (visible) {
    return (
      <ImageViewer
        src={images}
        currentIndex={index}
        onClose={onClose}
        disableScroll={true}
        backgroundStyle={{ zIndex: 8000 }}
      />
    );
  }
  return null;
}
