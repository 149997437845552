import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';
import ToggleButton from './ToggleButton';
import { getWorkDayShortString } from '../Globals/Functions';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ErrorIcon {
    color: ${(props) => lighten(0.15, props.theme.color.red)};
  }
`;

interface Props {
  hasError?: boolean;
  initialValue?: boolean[];
  onChange: (value: boolean[]) => void;
}

/**
 * WeekdaysToggle
 * @param props
 * @constructor
 */
export default function WeekdaysToggle(props: Props) {
  const { hasError, initialValue, onChange } = props;
  const [selectedWeekdays, setSelectedWeekdays] = React.useState<boolean[]>(Array(7).fill(false));
  const [t] = useTranslation();

  React.useEffect(() => {
    if (initialValue) {
      setSelectedWeekdays(initialValue);
    }
  }, [initialValue, setSelectedWeekdays]);

  const handleSelect = (weekday: number) => {
    const weekdaysTemp: boolean[] = selectedWeekdays.map((item, index) => (index === weekday ? !item : item));
    setSelectedWeekdays(weekdaysTemp);
    onChange(weekdaysTemp);
  };

  const renderWeekdays = () => {
    const buttons: ReactElement[] = [];
    for (let i = 0; i < 7; ++i) {
      const text: string = getWorkDayShortString(i, t);
      buttons.push(
        <ToggleButton
          key={text}
          caption={text}
          active={selectedWeekdays[i]}
          onClick={() => handleSelect(i)}
          bottomMargin={0}
        />,
      );
    }

    if (hasError) {
      buttons.push(<i key="error" className="fas fa-exclamation-circle ErrorIcon" />);
    }

    return buttons;
  };

  return <Container>{renderWeekdays()}</Container>;
}
