import React from 'react';
import { useTranslation } from 'react-i18next';
import { InvoicePositionEntity } from '../../../../Globals/Types/Invoice';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Input, { InputType } from '../../../Inputs/Input';
import SelectNumber from '../../../PredefinedSelects/SelectNumber';
import { prepareStringToPrice } from '../../../../Globals/Functions';
import CustomSwitch from '../../../CustomSwitch';
import { validatePosition } from './ValidatePosition';
import { CountryCode, ProductType } from '../../../../Globals/Types/Enums';
import SelectProductType from '../../../PredefinedSelects/SelectProductType';
import SelectTax from '../../../PredefinedSelects/SelectTax';

interface Props {
  visible: boolean;
  onClose: () => void;
  position?: InvoicePositionEntity;
  onSave: (position: InvoicePositionEntity) => void;
}

export type State = {
  position: InvoicePositionEntity;
  errors: { [K in keyof InvoicePositionEntity]?: string };
  initialized: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'clear' }
  | { type: 'init'; payload: InvoicePositionEntity }
  | { type: 'caption'; payload: string }
  | { type: 'description'; payload: string }
  | { type: 'amount'; payload: number }
  | { type: 'pricePerAmount'; payload: string }
  | { type: 'overallPrice'; payload: string }
  | { type: 'setErrors'; payload: { [K in keyof InvoicePositionEntity]?: string } }
  | { type: 'isVoucher'; payload: boolean }
  | { type: 'type'; payload: ProductType }
  | { type: 'taxRate'; payload: number };

export const initialState: State = {
  position: {
    pricePerAmount: undefined,
    overallPrice: undefined,
    positionNumber: null,
    orderPositionId: null,
    amount: 1,
    caption: '',
    description: '',
    isVoucher: false,
    type: ProductType.service,
    taxRate: 21,
    tax: '0',
  },
  errors: {},
  initialized: false,
  hasChanges: false,
};

const calculateTax = (taxRate: number, overallPrice: string): string => {
  return ((parseFloat(overallPrice) * taxRate) / 100).toFixed(2);
};

const calculateOverallPrice = (pricePerAmount: string, amount: number) => {
  return pricePerAmount ? (parseFloat(pricePerAmount) * amount).toFixed(2) : '';
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'amount':
      return {
        ...state,
        position: {
          ...state.position,
          amount: action.payload,
          overallPrice: calculateOverallPrice(state.position.pricePerAmount, action.payload),
          tax: calculateTax(
            state.position.taxRate,
            calculateOverallPrice(state.position.pricePerAmount, action.payload),
          ),
        },
        hasChanges: true,
      };
    case 'caption':
      return { ...state, position: { ...state.position, caption: action.payload }, hasChanges: true };
    case 'description':
      return { ...state, position: { ...state.position, description: action.payload }, hasChanges: true };
    case 'isVoucher':
      return { ...state, position: { ...state.position, isVoucher: action.payload }, hasChanges: true };
    case 'type':
      return { ...state, position: { ...state.position, type: action.payload }, hasChanges: true };
    case 'taxRate':
      return {
        ...state,
        position: {
          ...state.position,
          taxRate: action.payload,
          tax: calculateTax(action.payload, state.position.overallPrice),
        },
        hasChanges: true,
      };
    case 'pricePerAmount':
      return {
        ...state,
        position: {
          ...state.position,
          pricePerAmount: action.payload,
          overallPrice: calculateOverallPrice(action.payload, state.position.amount),
          tax: calculateTax(state.position.taxRate, calculateOverallPrice(action.payload, state.position.amount)),
        },
        hasChanges: true,
      };
    case 'overallPrice':
      return {
        ...state,
        position: {
          ...state.position,
          overallPrice: action.payload,
          pricePerAmount: action.payload,
          tax: calculateTax(state.position.taxRate, action.payload),
        },
        hasChanges: true,
      };
    case 'setErrors':
      return { ...state, errors: action.payload };
    case 'clear':
      return { ...initialState };
    case 'init':
      return { ...initialState, position: action.payload, initialized: true };
    default:
      throw new Error('Invalid type!');
  }
};

/**
 * PositionModal()
 * @param props
 * @constructor
 */
export default function PositionModal(props: Props) {
  const { position, onSave, onClose, visible } = props;
  const [t] = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const handleSave = React.useCallback(() => {
    dispatch({ type: 'setErrors', payload: {} });
    const validationResult = validatePosition(state.position);

    if (validationResult.isValid) {
      onSave(state.position);
    } else {
      dispatch({ type: 'setErrors', payload: validationResult.errors });
    }
  }, [onSave, state.position]);

  React.useEffect(() => {
    if (position && !state.initialized && visible) {
      dispatch({ type: 'init', payload: position });
    }
  }, [position, state.initialized, visible]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear' });
    }
  }, [visible]);

  const renderPrice = () => {
    if (state.position.amount > 1) {
      return (
        <>
          <Col xxl={6}>
            <Input
              onChange={(value) => dispatch({ type: 'pricePerAmount', payload: prepareStringToPrice(value) })}
              label={t('pricePerAmount')}
              value={state.position.pricePerAmount}
              required
              hasError={!!state.errors.pricePerAmount}
            />
          </Col>
          <Col xxl={6}>
            <Input
              onChange={() => {}}
              label={t('modules.invoice.overallPriceNetto')}
              value={state.position.overallPrice}
              disabled
              required
            />
          </Col>
        </>
      );
    }
    return (
      <Col xxl={12}>
        <Input
          onChange={(value) => dispatch({ type: 'overallPrice', payload: prepareStringToPrice(value) })}
          label={t('modules.invoice.overallPriceNetto')}
          value={state.position.overallPrice}
          required
          hasError={!!state.errors.overallPrice}
        />
      </Col>
    );
  };

  return (
    <Modal show={visible} size="xl">
      <Modal.Header>
        <div>
          <Modal.Title>{t('invoicePosition')}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body style={{ padding: 30, paddingBottom: 10 }}>
        <Row>
          <Col xxl={3}>
            <SelectNumber
              onChange={(value) => dispatch({ type: 'amount', payload: value })}
              max={99}
              label={t('amount')}
              initialValue={state.position.amount}
              required
            />
          </Col>
          <Col xxl={9}>
            <Input
              onChange={(value) => dispatch({ type: 'caption', payload: value })}
              label={t('caption')}
              autoFocus
              value={state.position.caption}
              required
              hasError={!!state.errors.caption}
            />
          </Col>
          <Col xxl={12}>
            <Input
              type={InputType.textarea}
              rows={5}
              onChange={(value) => dispatch({ type: 'description', payload: value })}
              label={t('description')}
              value={state.position.description}
            />
          </Col>
          <Col xxl={6}>
            <SelectProductType
              onChange={(value) => dispatch({ type: 'type', payload: value })}
              selected={state.position.type}
            />
          </Col>
          <Col xxl={6}>
            <SelectTax
              onChange={(value) => dispatch({ type: 'taxRate', payload: value })}
              selected={state.position.taxRate}
              country={CountryCode.ES}
            />
          </Col>
          {renderPrice()}
          <Col xxl={12}>
            <CustomSwitch
              onChange={(value) => dispatch({ type: 'isVoucher', payload: value })}
              checked={state.position.isVoucher}
              caption={t('creditVoucher')}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSave}>
          {t('save')}
        </Button>
        <Button variant="outline-secondary" onClick={onClose}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
