import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import AskDeleteModal from '../../../../Components/Modals/AskDeleteModal';
import { useDispatchOrderDelete } from '../../../../Redux/Actions/OrderAction';
import { useNavigate } from 'react-router';

/**
 * DeleteOrderButton
 * @constructor
 */
export default function DeleteOrderButton() {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [reAskDelete, setReAskDelete] = React.useState<boolean>(false);
  const [deletableByService, setDeletableByService] = React.useState<boolean>(false);
  const { clientId } = useAppSelector((state) => state.auth);
  const { order, dispositions } = useAppSelector((state) => state.order.selectedOrder);
  const dispatchDeleteOrder = useDispatchOrderDelete();

  React.useEffect(() => {
    setDeletableByService(!dispositions || dispositions.length === 0);
  }, [dispositions]);

  const handleDelete = () => {
    dispatchDeleteOrder(order).then(() => {
      setReAskDelete(false);
      navigate('/dashboard');
    });
  };

  const handleShowReAsk = () => {
    setAskDelete(false);
    setTimeout(() => setReAskDelete(true), 300);
  };

  if (order.ownerClientId === clientId && order.deletable && deletableByService) {
    return (
      <div style={{ textAlign: 'center', marginTop: 80 }}>
        <Button variant="outline-danger" onClick={() => setAskDelete(true)}>
          {t('orderDelete')}
        </Button>

        <AskDeleteModal
          onDelete={handleShowReAsk}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
          headline={t('modules.order.delete')}
          description={t('modules.order.deleteDescription', { externalId: order.externalId })}
          confirmButtonText={t('modules.order.delete')}
        />

        <AskDeleteModal
          onDelete={handleDelete}
          onClose={() => setReAskDelete(false)}
          visible={reAskDelete}
          headline={t('modules.order.deleteAgain')}
          description={t('modules.order.deleteAgainDescription', { externalId: order.externalId })}
          confirmButtonText={t('modules.order.delete')}
        />
      </div>
    );
  }
  return null;
}
