import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { useHelpContext } from '../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import CustomSkeleton from '../CustomSkeleton';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { getSelectedLanguage } from '../../Globals/Functions/LocalizationFunctions';

interface DatePickerProps {
  onChange: (date: string) => void;
  label: string;
  initialValue?: Date;
  visible: boolean;
  required: boolean;
  hasError?: boolean;
  errorMessage?: string | null;
  disabled: boolean;
  contextHelpKey: string;
  showSkeleton: boolean;
  minDate: Date;
  maxDate: Date;
}

interface ContainerProps {
  $disabled: boolean;
  $focused: boolean;
  $hasError: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 15px;

  .TextContainer {
    height: 58px;
    border-radius: ${(props) => props.theme.border.radius};
    padding-left: 18px;
    padding-right: 18px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.border.color};
    background-color: ${(props) => (props.$disabled ? lighten(0.32, props.theme.color.textGray) : 'transparent')};
  }

  .skeleton {
    border-radius: 6px;
    line-height: 56px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.border.color};
    outline: none;
  }

  .fa-question-circle {
    cursor: pointer;
    color: ${(props) => props.theme.color.help};

    &:hover {
      color: ${(props) => darken(0.1, props.theme.color.help)};
    }
  }

  .Label {
    position: absolute;
    left: 19px;
    top: ${(props) => (props.$focused ? '6px' : '17px')};
    font-size: ${(props) => (props.$focused ? '14px' : '16px')};
    color: ${(props) => (props.$hasError ? lighten(0.15, props.theme.color.red) : props.theme.color.textGray)};
    transition: top 200ms, font-size 200ms, font-weight 200ms;
  }

  .RightIconContainer {
    position: absolute;
    right: 15px;
    top: 17px;

    i {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .ErrorIcon {
      color: ${(props) => lighten(0.15, props.theme.color.red)};
    }
    .DisabledIcon {
      color: ${(props) => lighten(0.15, props.theme.color.textGray)};
    }
  }

  .react-datepicker-wrapper {
    display: flex;
    z-index: -1;

    .react-datepicker__input-container {
      display: flex;
      flex: 1;
      outline: 0;
      background: transparent;

      input {
        cursor: pointer;
        outline: 0;
        display: flex;
        flex: 1;
        padding-left: 0;
        line-height: 20px;
        border: none;
        height: 56px;
        padding-bottom: 6px;
        padding-top: 22px;
        background-color: transparent;
        caret-color: transparent;
      }
    }
  }
`;

/**
 * DatePicker()
 * @param props
 * @constructor
 */
export default function DatePicker(props: DatePickerProps) {
  const { showSkeleton, minDate, maxDate, initialValue, disabled } = props;
  const [value, setValue] = React.useState<Date>(initialValue);
  const [initializedValue, setInitializedValue] = React.useState<Date>(null);
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const [contextHelpElement, setContextName] = useHelpContext();

  React.useEffect(() => {
    if (!initialized && initializedValue !== initialValue) {
      setValue(initialValue);
      setInitializedValue(initialValue);
      setInitialized(true);
    }
  }, [initialValue, initialized, initializedValue]);

  const renderLabel = () => {
    const label = `${props.label}${props.required ? '*' : ''}`;
    return <div className="Label">{label}</div>;
  };

  const renderErrorIcon = () => {
    if (props.hasError) {
      return <i className="fas fa-exclamation-circle ErrorIcon" />;
    }
    return null;
  };

  const renderDisabledIcon = () => {
    if (disabled) {
      return <i className="fas fa-lock DisabledIcon" />;
    }
    return null;
  };

  const renderContextHelp = () => {
    if (props.contextHelpKey) {
      return (
        <i className="fas fa-question-circle" onClick={() => setContextName(props.contextHelpKey)} title={t('help')} />
      );
    }
    return null;
  };

  const renderRightIcons = () => {
    const errorIcon = renderErrorIcon();
    const disabledIcon = renderDisabledIcon();
    const contextHelp = renderContextHelp();

    if (errorIcon || disabledIcon || contextHelp) {
      return (
        <div className="RightIconContainer">
          {errorIcon}
          {disabledIcon}
          {contextHelp}
        </div>
      );
    }
    return null;
  };

  const handleSetValue = (param) => {
    if (props.onChange && param) {
      setValue(param);
      const date = moment(param).format('YYYY-MM-DD');
      props.onChange(date);
    }
  };

  const renderContent = () => {
    if (!showSkeleton) {
      return (
        <div className="TextContainer">
          {renderLabel()}
          {renderRightIcons()}
          {contextHelpElement}

          <ReactDatePicker
            onChange={handleSetValue}
            locale={getSelectedLanguage(true)}
            selected={value}
            dateFormat={'iiii, dd.MM.yyyy'}
            onChangeRaw={(e) => e.preventDefault()}
            minDate={minDate}
            maxDate={maxDate}
            showWeekNumbers
            disabled={props.disabled}
            showTimeSelect={false}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
          />
        </div>
      );
    }
    return null;
  };

  const renderSkeleton = () => {
    if (showSkeleton) {
      return <CustomSkeleton className="skeleton" />;
    }
    return null;
  };

  if (props.visible) {
    return (
      <Container $focused={!!value} $hasError={props.hasError} $disabled={disabled}>
        {renderContent()}
        {renderSkeleton()}
      </Container>
    );
  }
  return null;
}

DatePicker.defaultProps = {
  visible: true,
  value: '',
  required: false,
  hasError: false,
  errorMessage: null,
  disabled: false,
  contextHelpKey: null,
  showSkeleton: false,
  minDate: null,
  maxDate: null,
  initialStartDate: null,
};
