import React from 'react';
import { useAppSelector } from './Hooks';

/**
 * useClientHasFacilities()
 * Check if the client has more than one facility!
 */
export const useClientHasFacilities = (): boolean => {
  const [value, setValue] = React.useState<boolean>(false);
  const { facilities, allPartners } = useAppSelector((state) => state.client);

  React.useEffect(() => {
    setValue(facilities && facilities.length > 1);
  }, [allPartners, facilities]);

  return value;
};

/**
 * useClientHasPartners()
 * Check if the client has at least one partner assigned to one of the facilities!
 */
export const useClientHasPartners = (): boolean => {
  const [value, setValue] = React.useState<boolean>(false);
  const { facilities, allPartners } = useAppSelector((state) => state.client);

  React.useEffect(() => {
    setValue(allPartners && allPartners.length > 0);
  }, [allPartners, facilities]);

  return value;
};

/**
 * useClientHasFacilitiesOrPartner()
 * Check if the client has more than one facility and / or has at least one partner assigned to one of the facilities!
 */
export const useClientHasFacilitiesOrPartner = (): boolean => {
  const [value, setValue] = React.useState<boolean>(false);
  const { facilities, allPartners } = useAppSelector((state) => state.client);

  React.useEffect(() => {
    setValue((facilities && facilities.length > 1) || (allPartners && allPartners.length > 0));
  }, [allPartners, facilities]);

  return value;
};
