import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ContainerBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px;

  i {
    font-size: 70px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.color.green};
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .FooterButton {
    width: 250px;
    margin-bottom: 8px;
  }
`;

interface Props {
  description?: string;
  headline: string;
  visible: boolean;
  confirmCaption?: string;
  onSuccess: () => void;
  onCancel: () => void;
}

/**
 * ConfirmMessage
 * @constructor
 */
export default function ConfirmMessage(props: Props) {
  const { description, headline, visible, confirmCaption, onCancel, onSuccess } = props;
  const [t] = useTranslation();

  return (
    <Modal centered show={visible}>
      <Modal.Body>
        <ContainerBody>
          <i className="fas fa-check-circle" />
          <h4>{headline}</h4>
          {description}
        </ContainerBody>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button variant="success" onClick={() => onSuccess()}>
          {confirmCaption || t('yes')}
        </Button>
        <Button variant="outline-secondary" onClick={() => onCancel()}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
