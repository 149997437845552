import React from 'react';
import AssignPartner from '../../../../Components/Order/Details/Service/AssignPartner';
import { OrderEntity, PositionEntity, ServiceEntity } from '../../../../Globals/Types/OrderTypes';
import { Col, Row } from 'react-bootstrap';
import ExecutionContainer from '../../../../Components/Order/Details/Service/ExecutionContainer';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { Documents } from '../../../../Components/Order/Details/Documents';
import PositionList from '../../../../Components/Order/Create/Positions/PositionList';
import DeleteServiceButton from '../Components/DeleteServiceButton';
import DispositionInformation from '../../../../Components/Order/Details/Service/DispositionInformation';
import ContactPerson from '../../../../Components/Order/Details/ContactPerson';
import Settings from '../../../../Components/Order/Details/Service/Settings';
import { ReportState } from '../../../../Globals/Types/Report';
import DeliveryAndLoadingAddress from '../../../../Components/Order/Details/Service/DeliveryAndLoadingAddress';
import ShowHideContainer from '../../../../Components/ShowHideContainer';

interface Props {
  service: ServiceEntity;
  order: OrderEntity;
  isEditable: boolean;
  onChangeService: (data: Partial<ServiceEntity>, changedPositionAssemblyTime: boolean) => void;
  hideAssignPartner: boolean;
}

/**
 * General
 * @constructor
 */
export default function General(props: Props) {
  const { service, order, onChangeService, isEditable, hideAssignPartner } = props;
  const [previewMode, setPreviewMode] = React.useState<boolean>(true);
  const { allowEditPosition, allowEditAll } = useAppSelector((state) => state.order.selectedOrder.permissions);

  const handleOnChangePositions = (positions: PositionEntity[], changedAssemblyTime: boolean) => {
    setPreviewMode(false);
    onChangeService({ positions }, changedAssemblyTime);
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <DispositionInformation />
          <PositionList
            onChangePositions={handleOnChangePositions}
            orderServiceType={service.type}
            positions={service.positions}
            validationResult={null}
            allowEdit={(allowEditPosition || allowEditAll) && service.reportState === ReportState.none && isEditable}
            previewMode={previewMode}
          />
          <DeliveryAndLoadingAddress
            service={service}
            order={order}
            onChange={(value) => onChangeService(value, false)}
            allowEdit={allowEditAll && isEditable}
          />
        </Col>

        <Col lg={12}>
          <ExecutionContainer
            service={service}
            onChange={(value) => onChangeService(value, false)}
            allowEdit={isEditable}
          />
        </Col>

        <Col lg={6} style={{ display: 'flex' }}>
          <Documents service={service} allowUpload={isEditable} />
        </Col>
        <Col lg={6} style={{ display: 'flex' }}>
          <ContactPerson
            contacts={service.contacts}
            onChange={(contacts) => onChangeService({ contacts }, false)}
            allowEdit={isEditable}
          />
        </Col>

        <Col lg={12}>
          <ShowHideContainer visible={!hideAssignPartner}>
            <AssignPartner service={service} />
          </ShowHideContainer>
          <Settings
            order={order}
            service={service}
            onChange={(settings) => onChangeService({ settings }, false)}
            isEditable={service.reportState === ReportState.none && isEditable}
          />
        </Col>
      </Row>

      <ShowHideContainer visible={isEditable}>
        <DeleteServiceButton service={service} />
      </ShowHideContainer>
    </>
  );
}
