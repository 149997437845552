import React from 'react';
import SelectFacility from '../../../Components/Order/Create/SelectFacility';
import { initialState, reducer } from './Reducer';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { Button, Col, Row } from 'react-bootstrap';
import OrderOptions from '../../../Components/Order/Create/OrderOptions';
import CustomerAddress from '../../../Components/Order/Create/CustomerAddress';
import SelectPartner from '../../../Components/PredefinedPanelSelects/SelectPartner';
import { FacilityPartnerEntity } from '../../../Globals/Types/Types';
import MontageDate from '../../../Components/Order/Create/MontageDate';
import PositionList from '../../../Components/Order/Create/Positions/PositionList';
import Panel from '../../../Components/Panel';
import { useTranslation } from 'react-i18next';
import { CreateValidationResult, validateCreateOrder } from './Validate';
import ErrorMessage from '../../../Components/Modals/ErrorMessage';
import CalculationPanel from '../../../Components/Order/Create/CalculationPanel';
import { useDispatchOrderCreate } from '../../../Redux/Actions/OrderAction';
import { useNavigate } from 'react-router';
import { buildUrlOrderDetails } from '../../../Globals/UrlFunctions';
import { useAutoBuildExternalId } from '../../../Globals/Hooks/ServiceHooks';
import DeliveryAndLoading from '../../../Components/Order/Create/DeliveryAndLoading';
import PageHeader from '../../../Components/PageHeader';
import { LOCALSTORAGE_OFFER_CREATE_ORDER } from '../../../Globals/Globals';
import { OfferEntity } from '../../../Globals/Types/OfferTypes';
import { CustomerEntity } from '../../../Globals/Types/Customer';

/**
 * Create
 * @constructor
 */
export default function Create() {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { setLoading, loadingModal } = useLoadingModal({
    headline: t('modules.order.loadingHeadline'),
    textLine1: t('modules.order.loadingDescriptionLine1'),
    textLine2: t('modules.order.loadingDescriptionLine2'),
  });
  const { clientId, userId } = useAppSelector((state) => state.auth);
  const [showError, setShowError] = React.useState<boolean>(false);
  const { user } = useAppSelector((state) => state.auth);
  const [validationResult, setValidationResult] = React.useState<CreateValidationResult>(null);

  const dispatchCreate = useDispatchOrderCreate(false, 1);
  const checkAutoBuildExternalId = useAutoBuildExternalId();

  React.useEffect(() => {
    const data = localStorage.getItem(LOCALSTORAGE_OFFER_CREATE_ORDER);
    if (data) {
      const parsed: { offer: OfferEntity; customer: CustomerEntity } = JSON.parse(data);
      dispatch({ type: 'initializeFromOffer', payload: { offer: parsed.offer, customer: parsed.customer } });
      localStorage.removeItem(LOCALSTORAGE_OFFER_CREATE_ORDER);
    }
  }, []);

  React.useEffect(() => {
    dispatch({ type: 'initOrderCreated', payload: { clientId, userId } });
    dispatch({ type: 'setCreatedFacility', payload: user.facilityId });
  }, [clientId, user.facilityId, userId]);

  React.useEffect(() => {
    dispatch({ type: 'ownerFacilityId', payload: { facilityId: user.facilityId, clientId } });
  }, [clientId, user]);

  const executeSave = () => {
    setValidationResult(null);
    const validationResult = validateCreateOrder(checkAutoBuildExternalId(state));

    if (validationResult.result) {
      setLoading(true);
      dispatchCreate(validationResult.validatedOrderEntity, state.documents).then((param) => {
        setLoading(false);
        navigate(buildUrlOrderDetails(param.ownerClientId, param.orderId));
      });
    } else {
      setShowError(true);
      setValidationResult(validationResult);
    }
  };

  const handleSelectFacility = (value: string) => {
    dispatch({ type: 'ownerFacilityId', payload: { facilityId: value, clientId } });
    dispatch({ type: 'setCreatedFacility', payload: value });
  };

  const handleSetPartner = (result: FacilityPartnerEntity | null) => {
    if (result) {
      dispatch({ type: 'setPartner', payload: result });
    } else {
      dispatch({ type: 'ownerFacilityId', payload: { facilityId: state.order.createdFacilityId, clientId } });
    }
  };

  return (
    <Row>
      <Col lg={12}>
        <PageHeader headline={t('orderCreateNew')} />

        <SelectFacility
          onChange={handleSelectFacility}
          selected={state.order.createdFacilityId}
          blackHeadline={!!validationResult}
        />

        <OrderOptions
          onChange={(value) => dispatch({ type: 'orderServiceType', payload: value })}
          onChangeExternalId={(value) => dispatch({ type: 'externalId', payload: value })}
          onChangeBalance={(value) => dispatch({ type: 'balance', payload: value })}
          selected={state.orderServiceType}
          externalId={state.order.externalId}
          balance={state.order.balance}
          validationResult={validationResult ? validationResult.validateOrderOptions : null}
          blackHeadline={!!validationResult}
        />
        <CustomerAddress
          data={state.order.customer}
          onChange={(value) => dispatch({ type: 'customerAddress', payload: value })}
          validationResult={validationResult ? validationResult.validateCustomerAddress : null}
          blackHeadline={!!validationResult}
        />
        <DeliveryAndLoading
          onChangeOption={(value) => dispatch({ type: 'deliveryAndLoadingOption', payload: value })}
          onChangeLoadingAddress={(value) => dispatch({ type: 'loadingAddress', payload: value })}
          onChangeDeliveryAddress={(value) => dispatch({ type: 'deliveryAddress', payload: value })}
          option={state.generalService.deliveryAndLoadingOption}
          loadingAddress={state.generalService.loadingAddress}
          deliveryAddress={state.generalService.deliveryAddress}
          validationResult={validationResult ? validationResult.validateDeliveryAdnLoading : null}
          blackHeadline={!!validationResult}
        />
        <PositionList
          positions={state.positions}
          orderServiceType={state.orderServiceType}
          onChangePositions={(value) => dispatch({ type: 'updatePositions', payload: value as any })}
          blackHeadline={!!validationResult}
          validationResult={validationResult ? validationResult.validatePositions : null}
          allowEdit={true}
        />
        <SelectPartner
          onChange={handleSetPartner}
          currentFacilityId={state.order.createdFacilityId}
          selectedPartnerClientId={state.order.ownerClientId}
          selectedPartnerFacilityId={state.order.ownerFacilityId}
          showSotoSoft={false}
          blackHeadline={!!validationResult}
        />
        <CalculationPanel data={state} blackHeadline={!!validationResult} />
        <MontageDate
          selectedPartner={state.order.ownerFacilityId}
          onChangeExecutionDate={(value) => dispatch({ type: 'executionDate', payload: value })}
          onChangeExecutionTime={(value) => dispatch({ type: 'executionTime', payload: value })}
          onChangeWeek={(value) => dispatch({ type: 'desiredWeek', payload: value })}
          executionDate={state.executionDate}
          executionTime={state.executionTime}
          desiredWeek={state.desiredWeek}
          blackHeadline={!!validationResult}
          validationResult={validationResult ? validationResult.validateMontageDate : null}
        />

        <Panel>
          <div style={{ textAlign: 'center' }}>
            <Button variant="success" size="lg" disabled={!state.hasChanges} onClick={executeSave}>
              {t('createOrder')}
            </Button>
          </div>
        </Panel>
      </Col>

      <ErrorMessage
        description={t('errorMessages.createOrder.description')}
        headline={t('errorMessages.createOrder.headline')}
        onClose={() => setShowError(false)}
        visible={showError}
      />

      {loadingModal}
    </Row>
  );
}
