import Lodash from 'lodash';
import moment, { Moment } from 'moment';
import { Tour } from '../../Globals/Types/Tour';
import {
  AUTH_LOGOUT,
  ORDER_SERVICE_FILTER_UNSCHEDULED_SUCCESS,
  TOUR_ADD_RESOURCE_SUCCESS,
  TOUR_ADD_SERVICE_SUCCESS,
  TOUR_ADD_USER_SUCCESS,
  TOUR_CREATE_FROM_TEMPLATE_SUCCESS,
  TOUR_CREATE_SUCCESS,
  TOUR_DELETE_RESOURCE_SUCCESS,
  TOUR_DELETE_SERVICE_SUCCESS,
  TOUR_DELETE_SUCCESS,
  TOUR_DELETE_USER_SUCCESS,
  TOUR_GETLIST_SUCCESS,
  TOUR_TOGGLE_CLOSE_SUCCESS,
  TOUR_UPDATE_SUCCESS,
} from '../ActionTypes';
import { ElasticSearchServiceInterface } from '../../Globals/Types/OrderTypes';
import { ReportState } from '../../Globals/Types/Report';

export interface DispositionReducerByDate {
  date: string;
  data: Tour[];
}

interface DispositionReducerInterface {
  tourList: Tour[];
  tourListByDate: DispositionReducerByDate[];
  unscheduledServices: ElasticSearchServiceInterface[];
}

type Action =
  | { type: typeof TOUR_ADD_RESOURCE_SUCCESS; payload: Tour }
  | { type: typeof TOUR_ADD_SERVICE_SUCCESS; payload: Tour }
  | { type: typeof TOUR_ADD_USER_SUCCESS; payload: Tour }
  | { type: typeof TOUR_CREATE_SUCCESS; payload: Tour[] }
  | { type: typeof TOUR_CREATE_FROM_TEMPLATE_SUCCESS; payload: Tour[] }
  | { type: typeof TOUR_DELETE_SUCCESS; payload: Tour }
  | { type: typeof TOUR_DELETE_RESOURCE_SUCCESS; payload: Tour }
  | { type: typeof TOUR_DELETE_SERVICE_SUCCESS; payload: Tour }
  | { type: typeof TOUR_DELETE_USER_SUCCESS; payload: Tour }
  | { type: typeof TOUR_GETLIST_SUCCESS; payload: { dateStart: string; dateEnd: string; tourList: Tour[] } }
  | { type: typeof TOUR_TOGGLE_CLOSE_SUCCESS; payload: Tour }
  | { type: typeof TOUR_UPDATE_SUCCESS; payload: Tour }
  | {
      type: typeof ORDER_SERVICE_FILTER_UNSCHEDULED_SUCCESS;
      payload: { services: ElasticSearchServiceInterface[]; clientId: string };
    }
  | { type: typeof AUTH_LOGOUT };

const defaultValues: DispositionReducerInterface = {
  tourList: [],
  tourListByDate: [],
  unscheduledServices: [],
};

export default function reduce(
  state: DispositionReducerInterface = defaultValues,
  action: Action,
): DispositionReducerInterface {
  switch (action.type) {
    case TOUR_ADD_RESOURCE_SUCCESS:
    case TOUR_ADD_SERVICE_SUCCESS:
    case TOUR_ADD_USER_SUCCESS:
    case TOUR_DELETE_RESOURCE_SUCCESS:
    case TOUR_DELETE_SERVICE_SUCCESS:
    case TOUR_DELETE_USER_SUCCESS:
    case TOUR_TOGGLE_CLOSE_SUCCESS:
    case TOUR_UPDATE_SUCCESS: {
      if (state.tourListByDate.length > 0 && state.tourList.length > 0) {
        return {
          ...state,
          tourList: updateTourList(state.tourList, [action.payload]),
          tourListByDate: updateByDateSingleTour(state.tourListByDate, action.payload),
        };
      }
      return { ...state };
    }

    case TOUR_CREATE_SUCCESS:
    case TOUR_CREATE_FROM_TEMPLATE_SUCCESS:
      return {
        ...state,
        tourList: updateTourList(state.tourList, action.payload),
        tourListByDate: filterByDateCreated(state.tourListByDate, action.payload),
      };

    case TOUR_DELETE_SUCCESS:
      return {
        ...state,
        tourList: deleteFromTourList(state.tourList, action.payload),
        tourListByDate: deleteFromByDate(state.tourListByDate, action.payload),
      };

    case TOUR_GETLIST_SUCCESS:
      return {
        ...state,
        tourList: updateTourList(state.tourList, action.payload.tourList),
        tourListByDate: filterByDate(
          state.tourListByDate,
          action.payload.dateStart,
          action.payload.dateEnd,
          action.payload.tourList,
        ),
      };

    case ORDER_SERVICE_FILTER_UNSCHEDULED_SUCCESS: {
      return {
        ...state,
        unscheduledServices: prepareUnscheduledServices(action.payload.services, action.payload.clientId),
      };
    }

    case AUTH_LOGOUT: {
      return { ...defaultValues };
    }

    default:
      return state;
  }
}

/**
 * deleteFromByDate()
 * @param tourList
 * @param tour
 */
const deleteFromByDate = (tourList: DispositionReducerByDate[], tour: Tour): DispositionReducerByDate[] => {
  const updatedTourList: DispositionReducerByDate[] = [...tourList];

  const index: number = Lodash.findIndex(updatedTourList, (item) => item.date === tour.date);
  if (index > -1) {
    const updatedData: Tour[] = Lodash.filter(updatedTourList[index].data, (item) => item.tourId !== tour.tourId);
    updatedTourList.splice(index, 1, { date: tour.date, data: updatedData });
  }

  return updatedTourList;
};

/**
 * deleteFromTourList()
 * @param tourList
 * @param tour
 */
const deleteFromTourList = (tourList: Tour[], tour: Tour): Tour[] => {
  return Lodash.filter(tourList, (item) => item.tourId !== tour.tourId);
};

/**
 * filterByDate()
 * @param currTourList
 * @param dateStart
 * @param dateEnd
 * @param tourList
 */
const filterByDate = (
  currTourList: DispositionReducerByDate[],
  dateStart: string,
  dateEnd: string,
  tourList: Tour[],
): DispositionReducerByDate[] => {
  const currDate: Moment = moment(dateStart);
  let updatedTourList: DispositionReducerByDate[] = [...currTourList];

  while (currDate.format('YYYY-MM-DD') <= dateEnd) {
    let data: Tour[] = Lodash.filter(tourList, (tour) => tour.date === currDate.format('YYYY-MM-DD'));
    data = Lodash.sortBy(data, (tour) => tour.name);
    const update: DispositionReducerByDate = {
      date: currDate.format('YYYY-MM-DD'),
      data,
    };
    const index: number = Lodash.findIndex(updatedTourList, { date: currDate.format('YYYY-MM-DD') });
    if (index > -1) {
      updatedTourList.splice(index, 1, update);
    } else {
      updatedTourList.push(update);
    }

    currDate.add(1, 'day');
  }

  return Lodash.sortBy(updatedTourList, (item) => item.date);
};

/**
 * filterByDateCreated()
 * @param currTourList
 * @param tourList
 */
const filterByDateCreated = (
  currTourList: DispositionReducerByDate[],
  tourList: Tour[],
): DispositionReducerByDate[] => {
  let updatedTourList: DispositionReducerByDate[] = [...currTourList];

  tourList.forEach((tour) => {
    // Only update present entries. If a date is not present its tours were never retrieved. If we set just the created
    // tours here all older tours will be missing, because they must be read from firestore, and won't, since the date
    // is present in the Array
    const index: number = Lodash.findIndex(updatedTourList, (item) => item.date === tour.date);
    if (index > -1) {
      const updatedData: Tour[] = [...updatedTourList[index].data, tour];
      updatedTourList.splice(index, 1, { date: tour.date, data: Lodash.sortBy(updatedData, (item) => item.name) });
    }
  });

  return updatedTourList;
};

/**
 * updateTourList()
 * @param currTourList
 * @param tourList
 */
const updateTourList = (currTourList: Tour[], tourList: Tour[]): Tour[] => {
  return Lodash.unionBy(tourList, currTourList, (tour) => tour.tourId);
};

/**
 * updateByDateSingleTour()
 * @param currTourList
 * @param tour
 */
const updateByDateSingleTour = (currTourList: DispositionReducerByDate[], tour: Tour): DispositionReducerByDate[] => {
  let updatedTourList: DispositionReducerByDate[] = [...currTourList];

  const index: number = Lodash.findIndex(updatedTourList, (item) => item.date === tour.date);
  if (index !== null && index !== undefined) {
    const updatedData: Tour[] = [...updatedTourList[index].data];
    const indexTour: number = Lodash.findIndex(updatedData, (item) => item.tourId === tour.tourId);
    if (indexTour > -1) {
      updatedData.splice(indexTour, 1, tour);
      updatedTourList.splice(index, 1, { date: tour.date, data: Lodash.sortBy(updatedData, (item) => item.name) });
    }
  }

  return updatedTourList;
};

/**
 * Remove all which has a finished report state or which are mine and assigned to a partner or which are not mine but
 * I`m assigned to
 * @param services
 * @param clientId
 */
const prepareUnscheduledServices = (
  services: ElasticSearchServiceInterface[],
  clientId: string,
): ElasticSearchServiceInterface[] => {
  return services.filter(
    (service) =>
      ((!service.reportState || service.reportState === ReportState.none) &&
        service.ownerClientId === clientId &&
        !service.assignedClientId) ||
      service.assignedClientId === clientId,
  );
};
