import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Table, { TableFieldProps } from '../../../../Components/Table/Table';
import { useAppSelector, useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import PageHeader from '../../../../Components/PageHeader';
import Panel from '../../../../Components/Panel';
import { useDispatchArticleGetList } from '../../../../Redux/Actions/Client/Storage/ArticleAction';
import { useNavigate } from 'react-router';
import { ArticleEntity } from '../../../../Globals/Types/Types';

const tableMappings: Array<TableFieldProps<ArticleEntity>> = [
  { key: 'profileIcon', headline: '', content: <i className="fas fa-boxes-stacked" />, width: 60 },
  { key: 'caption', headline: 'caption' },
];

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const { setLoading, isLoading } = useLoadingModal();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { articles } = useAppSelector((state) => state.storage);
  const dispatchGetList = useDispatchArticleGetList();

  React.useEffect(() => {
    if (!articles || articles.length === 0) {
      setLoading(true);
    }
  }, [setLoading, articles]);

  React.useEffect(() => {
    dispatchGetList()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatchGetList, setLoading]);

  const renderAddButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => navigate('create')}>
        <i className="fas fa-plus-circle" />
      </Button>
    );
  };

  const handleNavigate = (item: ArticleEntity) => {
    navigate(item.articleId);
  };

  return (
    <>
      <PageHeader headline={t('articleManagement')} actionButtonOne={renderAddButton()} />
      <Panel>
        <Table
          mappings={tableMappings}
          data={articles}
          keyField="articleId"
          isLoading={isLoading}
          onClickRow={handleNavigate}
        />
      </Panel>
    </>
  );
}
