import React from 'react';
import { TourTemplateEntity } from '../../../Globals/Types/Types';
import MissingAssignmentText from '../../MissingAssignmentText';

interface Props {
  item: TourTemplateEntity;
}

/**
 * TourResourcesCol
 * @param props
 * @constructor
 */
export default function TourResourcesCol(props: Props) {
  const { item } = props;

  const renderContent = () => {
    if (item.resources && item.resources.length > 0) {
      const resourceNames: string[] = item.resources.map((resource) => resource.name);
      return resourceNames.join(', ');
    }

    return <MissingAssignmentText />;
  };

  return <td key={`${item.tourTemplateId}-resources`}>{renderContent()}</td>;
}
