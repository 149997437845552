import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { OrderCustomerAddress } from '../../../../../Globals/Types/OrderTypes';
import Input, { InputType } from '../../../../Inputs/Input';
import Select from '../../../../Inputs/Select';
import { selectOptionYesNo } from '../../../../../Globals/Types/General';
import SelectSalutation from '../../../../PredefinedSelects/SelectSaltutation';
import SelectFloor from '../../../../PredefinedSelects/SelectFloor';
import ShowHideContainer from '../../../../ShowHideContainer';
import SelectParking from '../../../../PredefinedSelects/SelectParking';
import { validateCustomerAddress } from '../../../Validators/ValidateCustomerAddress';
import SelectNotifyBeforeArrival from '../../../../PredefinedSelects/SelectNotifyBeforeArrival';
import { Salutation } from '../../../../../Globals/Types/Enums';
import SelectLanguage from '../../../../PredefinedSelects/SelectLanguage';

interface Props {
  address: OrderCustomerAddress;
  onSave: (item: OrderCustomerAddress) => void;
  onClose: () => void;
  visible: boolean;
}

export type State = {
  address: OrderCustomerAddress;
  errors: OrderCustomerAddress;
  initialized: Boolean;
  hasChanges: Boolean;
};

type Action =
  | { type: 'change'; payload: { key: string; value: string | number } }
  | { type: 'setError'; payload: OrderCustomerAddress }
  | { type: 'init'; payload: OrderCustomerAddress }
  | { type: 'clear' };

const initialState: State = {
  address: {} as OrderCustomerAddress,
  errors: {} as OrderCustomerAddress,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'change': {
      const { key, value } = action.payload;

      return {
        ...state,
        address: {
          ...state.address,
          [key]: value,
        },
        hasChanges: true,
      };
    }
    case 'init':
      return {
        ...initialState,
        address: action.payload,
        initialized: true,
      };
    case 'setError':
      return { ...state, errors: action.payload };
    case 'clear':
      return { ...initialState };
  }
};

/**
 * EditModal
 * @param props
 * @constructor
 */
export default function EditModal(props: Props) {
  const { address, visible, onClose, onSave } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [t] = useTranslation();

  React.useEffect(() => {
    if (!state.initialized && address && visible) {
      dispatch({ type: 'init', payload: address });
    }
  }, [address, state.initialized, visible]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear' });
    }
  }, [visible]);

  const handleOnChange = (key: string, value: string | number) => {
    dispatch({ type: 'change', payload: { key, value } });
  };

  const handleSave = () => {
    const { result, errors } = validateCustomerAddress(state.address);

    if (result) {
      onSave(state.address);
    } else {
      dispatch({ type: 'setError', payload: errors as OrderCustomerAddress });
    }
  };

  if (visible && state.initialized) {
    return (
      <Modal show={visible} onHide={() => onClose()} size="xl">
        <Modal.Header>
          <div>
            <Modal.Title style={{ marginLeft: 25 }}>{t('customerAddress')}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: 30 }}>
          <Row>
            <Col lg={2}>
              <SelectSalutation
                onChange={(value) => handleOnChange('salutation', value)}
                selected={state.address.salutation || Salutation.male}
                required
              />
            </Col>
            <Col lg={state.address.salutation !== Salutation.company ? 5 : 10}>
              <Input
                label={state.address.salutation !== Salutation.company ? t('firstName') : t('companyName')}
                value={state.address.firstName}
                onChange={(value) => handleOnChange('firstName', value)}
                required
                hasError={state.errors ? !!state.errors.firstName : false}
                errorId={state.errors ? state.errors.firstName : ''}
              />
            </Col>
            <Col lg={5}>
              <Input
                label={t('lastName')}
                value={state.address.lastName}
                onChange={(value) => handleOnChange('lastName', value)}
                required
                hasError={state.errors ? !!state.errors.lastName : false}
                errorId={state.errors ? state.errors.lastName : ''}
                visible={state.address.salutation !== Salutation.company}
              />
            </Col>
            <Col lg={9}>
              <Input
                label={t('street')}
                value={state.address.street}
                onChange={(value) => handleOnChange('street', value)}
                required
                hasError={state.errors ? !!state.errors.street : false}
                errorId={state.errors ? state.errors.street : ''}
              />
            </Col>
            <Col lg={3}>
              <Input
                label={t('streetNo')}
                value={state.address.streetNo}
                onChange={(value) => handleOnChange('streetNo', value)}
                required
                hasError={state.errors ? !!state.errors.streetNo : false}
                errorId={state.errors ? state.errors.streetNo : ''}
              />
            </Col>
            <Col lg={3}>
              <Input
                label={t('zip')}
                value={state.address.zip}
                onChange={(value) => handleOnChange('zip', value)}
                required
                hasError={state.errors ? !!state.errors.zip : false}
                errorId={state.errors ? state.errors.zip : ''}
              />
            </Col>
            <Col lg={9}>
              <Input
                label={t('city')}
                value={state.address.city}
                onChange={(value) => handleOnChange('city', value)}
                required
                hasError={state.errors ? !!state.errors.city : false}
                errorId={state.errors ? state.errors.city : ''}
              />
            </Col>
            <Col lg={6}>
              <Input
                label={t('eMailAddress')}
                value={state.address.mail}
                onChange={(value) => handleOnChange('mail', value)}
                hasError={state.errors ? !!state.errors.mail : false}
                errorId={state.errors ? state.errors.mail : ''}
              />
            </Col>
            <Col lg={6}>
              <SelectLanguage
                onChange={(value) => handleOnChange('countryCode', value)}
                selected={state.address.countryCode}
              />
            </Col>
            <Col lg={6}>
              <Input
                label={t('phone')}
                value={state.address.phone}
                onChange={(value) => handleOnChange('phone', value)}
              />
            </Col>
            <Col lg={6}>
              <Input
                label={t('mobile')}
                value={state.address.mobile}
                onChange={(value) => handleOnChange('mobile', value)}
              />
            </Col>
            <Col lg={6}>
              <SelectFloor onChange={(value) => handleOnChange('floor', value)} selected={state.address.floor || 0} />
            </Col>
            <ShowHideContainer visible={state.address.floor > 0}>
              <Col lg={6}>
                <Select
                  label={t('elevatorAvailable')}
                  initialValue={state.address.hasElevator}
                  onChange={(value) => handleOnChange('hasElevator', value)}
                  options={selectOptionYesNo}
                />
              </Col>
            </ShowHideContainer>
            <Col lg={state.address.floor > 0 ? 12 : 6}>
              <SelectParking
                onChange={(value) => handleOnChange('parking', value)}
                selected={state.address.parking || 0}
              />
            </Col>
            <Col lg={12}>
              <SelectNotifyBeforeArrival
                onChange={(value) => handleOnChange('notifyBeforeArrival', value)}
                selected={state.address.notifyBeforeArrival}
                translationKey="modules.order.notifyBeforeArrivalMinutes"
              />
            </Col>
            <Col lg={12}>
              <Input
                value={state.address.comment}
                onChange={(value) => handleOnChange('comment', value)}
                label={t('additionalInformation')}
                type={InputType.textarea}
                rows={4}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
            {t('save')}
          </Button>
          <Button variant="outline-secondary" onClick={() => onClose()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
