import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../Components/PageHeader';
import Panel from '../../../Components/Panel';
import Table, { TableMappings } from '../../../Components/Table/Table';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { useDispatchTourTemplateGetList } from '../../../Redux/Actions/Client/TourTemplateActions';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { buildUrlSettingsTourTemplatesCreateOrUpdate } from '../../../Globals/UrlFunctions';
import TourUsersCol from '../../../Components/Table/CustomCols/TourUsersCol';
import TourResourcesCol from '../../../Components/Table/CustomCols/TourResourcesCol';
import { TourTemplateEntity } from '../../../Globals/Types/Types';

const tableMappingsWithoutResources: TableMappings<TourTemplateEntity> = [
  { key: 'name', headline: 'name' },
  { key: 'users', headline: 'employeeDefault', customColElement: TourUsersCol },
];

const tableMappingsWithResources: TableMappings<TourTemplateEntity> = [
  ...tableMappingsWithoutResources,
  { key: 'resources', headline: 'resourceDefault', customColElement: TourResourcesCol },
];

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const { resources, tourTemplates } = useAppSelector((state) => state.client);
  const { setLoading, isLoading } = useLoadingModal();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const dispatchTourTemplateGetList = useDispatchTourTemplateGetList();

  React.useEffect(() => {
    if (!tourTemplates || tourTemplates.length === 0) {
      setLoading(true);
    }
  }, [setLoading, tourTemplates]);

  React.useEffect(() => {
    dispatchTourTemplateGetList().finally(() => setLoading(false));
  }, [dispatchTourTemplateGetList, setLoading]);

  const renderAddButton = () => {
    return (
      <Button
        variant="outline-secondary"
        onClick={() => navigate(buildUrlSettingsTourTemplatesCreateOrUpdate('create'))}
      >
        <i className="fa fa-plus-circle" />
      </Button>
    );
  };

  return (
    <>
      <PageHeader headline={t('modules.settings.tour.headline')} actionButtonOne={renderAddButton()} />
      <Panel>{t('modules.settings.tour.description')}</Panel>
      <Panel>
        <Table
          mappings={resources && resources.length > 0 ? tableMappingsWithResources : tableMappingsWithoutResources}
          data={tourTemplates}
          keyField="tourTemplateId"
          isLoading={isLoading}
          onClickRow={(item) => navigate(buildUrlSettingsTourTemplatesCreateOrUpdate(item.tourTemplateId))}
        />
      </Panel>
    </>
  );
}
