import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type ErrorMessageProps = {
  description?: string;
  headline: string;
  onClose: Function;
  visible: boolean;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;

  i {
    font-size: 70px;
    color: ${(props) => props.theme.color.red};
    padding-bottom: 20px;
  }
`;

/**
 * ErrorMessage
 * @param props
 * @constructor
 */
export default function ErrorMessage(props: ErrorMessageProps) {
  const { description, headline, onClose, visible } = props;
  const [t] = useTranslation();

  return (
    <Modal show={visible} animation={false} centered>
      <Modal.Body>
        <Container>
          <i className="fas fa-exclamation-circle" />
          <h4>{headline}</h4>
          {description}
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button variant="outline-secondary" onClick={() => onClose()}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ErrorMessage.defaultProps = {
  description: null,
};
