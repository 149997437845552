import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import { FacilityEntity, ResourceEntity, ResourceEntityTypes } from '../../../Globals/Types/Types';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import {
  useDispatchResourceCreate,
  useDispatchResourceDelete,
  useDispatchResourceGet,
  useDispatchResourceUpdate,
} from '../../../Redux/Actions/Client/ResourceAction';
import validateCreateOrUpdate from './ValidateCreateOrUpdate';
import PageHeader from '../../../Components/PageHeader';
import Panel from '../../../Components/Panel';
import Input, { InputType } from '../../../Components/Inputs/Input';
import { UrlPatterns } from '../../../Globals/UrlFunctions';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import AskDeleteModal from '../../../Components/Modals/AskDeleteModal';
import SelectResourceType from '../../../Components/SelectResourceType';
import FacilitiesForm from '../../../Components/FacilitiesForm';
import BottomInternalIdContainer from '../../../Components/BottomInternalIdContainer';
import { updateFacilityIdsArray } from '../../../Globals/Functions';

export type CreateOrUpdateError = { [K in keyof ResourceEntity]?: string };

type State = {
  errors: CreateOrUpdateError;
  headline: string;
  isEdit: boolean;
  resource: ResourceEntity;
};

type Action =
  | { type: 'description'; payload: string }
  | { type: 'error'; payload: CreateOrUpdateError }
  | { type: 'facilityIds'; payload: string[] }
  | { type: 'init'; payload: ResourceEntity }
  | { type: 'name'; payload: string }
  | { type: 'type'; payload: ResourceEntityTypes };

const initialState: State = {
  errors: null,
  headline: null,
  isEdit: false,
  resource: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'description':
      return {
        ...state,
        resource: {
          ...state.resource,
          description: action.payload,
        },
        errors: { ...state.errors, name: null },
      };
    case 'error':
      return {
        ...state,
        errors: action.payload,
      };
    case 'facilityIds':
      return {
        ...state,
        resource: {
          ...state.resource,
          facilityIds: action.payload,
        },
      };
    case 'init':
      return {
        ...state,
        headline: action.payload ? 'modules.settings.resources.edit' : 'modules.settings.resources.create',
        isEdit: !!action.payload,
        resource: action.payload || ({} as ResourceEntity),
      };
    case 'name':
      return {
        ...state,
        resource: {
          ...state.resource,
          name: action.payload,
        },
        errors: { ...state.errors, description: null },
      };
    case 'type':
      return {
        ...state,
        resource: {
          ...state.resource,
          type: action.payload,
        },
      };
  }
};

/**
 * CreateOrUpdate()
 * @constructor
 */
export default function CreateOrUpdate() {
  const { resourceId } = useParams();
  const { facilities } = useAppSelector((state) => state.client);
  const { user } = useAppSelector((state) => state.auth);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { loadingModal, setLoading, isLoading } = useLoadingModal({ delayStart: 1000 });
  const [t] = useTranslation();
  const navigate = useNavigate();

  const dispatchCreate = useDispatchResourceCreate();
  const dispatchDelete = useDispatchResourceDelete();
  const dispatchGet = useDispatchResourceGet();
  const dispatchUpdate = useDispatchResourceUpdate();

  React.useEffect(() => {
    if (!resourceId || resourceId === 'create') {
      return dispatch({ type: 'init', payload: null });
    }

    dispatchGet(resourceId).then((response) => dispatch({ type: 'init', payload: response }));
  }, [dispatchGet, resourceId]);

  React.useEffect(() => {
    dispatch({ type: 'facilityIds', payload: [user.facilityId] });
  }, [user.facilityId]);

  const handleDelete = () => {
    setAskDelete(false);
    setLoading(true);

    dispatchDelete(state.resource)
      .then(() => navigate(UrlPatterns.settingsResources))
      .catch(() => setLoading(false));
  };

  const handleSave = () => {
    const { errors, isValid } = validateCreateOrUpdate(state.resource, facilities.length > 1);

    if (!isValid) {
      return dispatch({ type: 'error', payload: errors });
    }

    setLoading(true);
    const promise: Promise<any> = state.isEdit ? dispatchUpdate(state.resource, false) : dispatchCreate(state.resource);
    promise.then(() => navigate(UrlPatterns.settingsResources)).catch(() => setLoading(false));
  };

  const onChangeFacility = (value: boolean, facility: FacilityEntity) => {
    dispatch({ type: 'facilityIds', payload: updateFacilityIdsArray(value, facility, state.resource.facilityIds) });
  };

  const onChangeType = (type: ResourceEntityTypes) => {
    dispatch({ type: 'type', payload: type });
  };

  const renderCancelButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => navigate(UrlPatterns.settingsResources)} disabled={isLoading}>
        {t('cancel')}
      </Button>
    );
  };

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={handleSave}>
        {t('save')}
      </Button>
    );
  };

  if (state.resource) {
    return (
      <>
        <PageHeader
          headline={t(state.headline)}
          actionButtonOne={renderSaveButton()}
          actionButtonTwo={renderCancelButton()}
        />
        <Panel headline={t('basicData')}>
          <Row>
            <Col>
              <Input
                label={t('name')}
                onChange={(value) => dispatch({ type: 'name', payload: value })}
                value={state.resource.name}
                hasError={!!state.errors?.name}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <SelectResourceType
                initialValue={state.resource.type as ResourceEntityTypes}
                onChange={onChangeType}
                hasError={!!state.errors?.type}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label={t('description')}
                onChange={(value) => dispatch({ type: 'description', payload: value })}
                value={state.resource.description}
                hasError={!!state.errors?.description}
                disabled={isLoading}
                type={InputType.textarea}
                rows={5}
              />
            </Col>
          </Row>
        </Panel>

        <FacilitiesForm
          description={t('modules.settings.resources.facilitiesDescription')}
          userDefaultFacility={null}
          onChange={onChangeFacility}
          userFacilities={state.resource.facilityIds}
          hasError={!!state.errors?.facilityIds}
        />

        <ShowHideContainer visible={state.isEdit}>
          <div style={{ textAlign: 'center' }}>
            <Button
              variant="outline-danger"
              onClick={() => setAskDelete(true)}
              disabled={isLoading}
              style={{ borderColor: 'transparent' }}
            >
              {t('delete')}
            </Button>
          </div>
        </ShowHideContainer>

        <ShowHideContainer visible={state.isEdit}>
          <BottomInternalIdContainer id={resourceId} />
        </ShowHideContainer>

        {loadingModal}
        <AskDeleteModal onDelete={handleDelete} onClose={() => setAskDelete(false)} visible={askDelete} />
      </>
    );
  }

  return null;
}
