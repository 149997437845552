import { TaskEntity } from '../../../Globals/Types/Types';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import React from 'react';
import {
  USER_TASK_CREATE_ERROR,
  USER_TASK_CREATE_START,
  USER_TASK_CREATE_SUCCESS,
  USER_TASK_DELETE_ERROR,
  USER_TASK_DELETE_START,
  USER_TASK_DELETE_SUCCESS,
  USER_TASK_GETLIST_ERROR,
  USER_TASK_GETLIST_START,
  USER_TASK_GETLIST_SUCCESS,
  USER_TASK_UPDATE_ERROR,
  USER_TASK_UPDATE_START,
  USER_TASK_UPDATE_SUCCESS,
} from '../../ActionTypes';
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { FirebasePathMappings } from '../../../Globals/FirebaseGlobals';

/**
 * Firebase collection Path for user tasks
 * @param userId
 */
const buildCollection = (userId: string): CollectionReference<TaskEntity> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.user,
    userId,
    FirebasePathMappings.tasks,
  ) as CollectionReference<TaskEntity>;
};
/**
 * snapShotToTaskList()
 * @param snapShot
 */
const snapShotToTaskList = (snapShot: any) => {
  // TODO can't find import for QuerySnapshot
  let tasks: Array<TaskEntity> = [];
  if (!snapShot.empty) {
    snapShot.forEach((item) => {
      tasks.push({ ...item.data(), taskId: item.id });
    });
  }

  return tasks;
};

/**
 * useDispatchUserTaskCreate()
 */
type CreateReturnType = (task: TaskEntity) => Promise<TaskEntity>;
export const useDispatchUserTaskCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state: any) => state.auth);
  const dispatchGetList = useDispatchUserTaskGetList();

  return React.useCallback<CreateReturnType>(
    (taskEntity) => {
      dispatch({ type: USER_TASK_CREATE_START, payload: taskEntity });

      const taskCollection = collection(
        getFirestore(),
        FirebasePathMappings.user,
        userId,
        FirebasePathMappings.tasks,
      ) as CollectionReference<TaskEntity>;

      // If task is created by another user for another user execute the database trigger
      const mapped: TaskEntity = {
        ...taskEntity,
        executeTrigger: taskEntity.createdUser !== undefined,
        createdDate: serverTimestamp(),
      };

      return addDoc(taskCollection, mapped)
        .then((snapShot) => {
          return dispatchGetList().then(() => {
            const mergedEntity: TaskEntity = { ...taskEntity, taskId: snapShot.id };
            dispatch({ type: USER_TASK_CREATE_SUCCESS, payload: mergedEntity });
            return Promise.resolve(mergedEntity);
          });
        })
        .catch((error) => {
          dispatch({ type: USER_TASK_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, userId, dispatchGetList],
  );
};

/**
 * useDispatchUserTaskGetList()
 */
type GetListReturnType = () => Promise<Array<TaskEntity>>;
export const useDispatchUserTaskGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<GetListReturnType>(() => {
    dispatch({ type: USER_TASK_GETLIST_START });

    const taskCollection = buildCollection(userId);

    const queryRef = query(taskCollection, orderBy('dueDateTime', 'asc'));

    return getDocs(queryRef)
      .then((snapShot) => {
        const tasks: Array<TaskEntity> = snapShotToTaskList(snapShot);

        dispatch({ type: USER_TASK_GETLIST_SUCCESS, payload: tasks });
        return Promise.resolve(tasks);
      })
      .catch((error) => {
        dispatch({ type: USER_TASK_GETLIST_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [dispatch, userId]);
};

/**
 * useDispatchUserTaskUpdate()
 */
type UpdateReturnType = (task: TaskEntity) => Promise<TaskEntity>;
export const useDispatchUserTaskUpdate = (): UpdateReturnType => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state: any) => state.auth);
  const dispatchGetList = useDispatchUserTaskGetList();

  return React.useCallback<UpdateReturnType>(
    (task) => {
      dispatch({ type: USER_TASK_UPDATE_START });

      const taskDocument = doc(
        getFirestore(),
        FirebasePathMappings.user,
        userId,
        FirebasePathMappings.tasks,
        task.taskId,
      ) as DocumentReference<TaskEntity>;

      // If task is created by another user for another user execute the database trigger
      const mapped: TaskEntity = { ...task, executeTrigger: task.createdUser !== undefined };

      return setDoc(taskDocument, mapped, { merge: true })
        .then(() => {
          return dispatchGetList().then(() => {
            dispatch({ type: USER_TASK_UPDATE_SUCCESS, payload: task });
            return Promise.resolve(task);
          });
        })
        .catch((error) => {
          dispatch({ type: USER_TASK_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, userId, dispatchGetList],
  );
};

/**
 * useDispatchUserTaskDelete()
 */
type DeleteReturnType = (task: TaskEntity) => Promise<boolean>;
export const useDispatchUserTaskDelete = (): DeleteReturnType => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state: any) => state.auth);
  const dispatchGetList = useDispatchUserTaskGetList();

  return React.useCallback<DeleteReturnType>(
    (task) => {
      dispatch({ type: USER_TASK_DELETE_START });

      const taskDocument = doc(
        getFirestore(),
        FirebasePathMappings.user,
        userId,
        FirebasePathMappings.tasks,
        task.taskId,
      ) as DocumentReference<TaskEntity>;

      return deleteDoc(taskDocument)
        .then(() => {
          return dispatchGetList().then(() => {
            dispatch({ type: USER_TASK_DELETE_SUCCESS, payload: task });
            return Promise.resolve(true);
          });
        })
        .catch((error) => {
          dispatch({ type: USER_TASK_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, userId, dispatchGetList],
  );
};
