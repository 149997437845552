import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import { PositionEntity } from '../../../Globals/Types/OrderTypes';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import React from 'react';
import {
  POSITION_CREATE_ERROR,
  POSITION_CREATE_START,
  POSITION_CREATE_SUCCESS,
  POSITION_DELETE_ERROR,
  POSITION_DELETE_START,
  POSITION_DELETE_SUCCESS,
  POSITION_GET_ERROR,
  POSITION_GET_START,
  POSITION_GET_SUCCESS,
  POSITION_GETLIST_ERROR,
  POSITION_GETLIST_START,
  POSITION_GETLIST_SUCCESS,
  POSITION_UPDATE_ERROR,
  POSITION_UPDATE_START,
  POSITION_UPDATE_SUCCESS,
} from '../../ActionTypes';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';

/**
 * buildCollection()
 * @param clientId
 */
const buildCollection = (clientId: string): CollectionReference<PositionEntity> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.position,
  ) as CollectionReference<PositionEntity>;
};

/**
 * useDispatchPositionGetList()
 */
type GetListReturnType = () => Promise<PositionEntity[]>;
export const useDispatchPositionGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListReturnType>(() => {
    dispatch({ type: POSITION_GETLIST_START });

    const collection = buildCollection(clientId);

    return getDocs(query(collection, orderBy('caption')))
      .then((response) => {
        let returnData: PositionEntity[] = [];

        if (!response.empty) {
          response.forEach((position) => {
            returnData.push({ ...position.data(), positionId: position.id });
          });
        }

        dispatch({ type: POSITION_GETLIST_SUCCESS, payload: returnData });
        return Promise.resolve(returnData);
      })
      .catch((error) => {
        dispatch({ type: POSITION_GETLIST_ERROR, payload: error });
        return Promise.resolve(error);
      });
  }, [clientId, dispatch]);
};

/**
 * useDispatchPositionGet()
 */
type GetReturnType = (positionId: string) => Promise<PositionEntity>;
export const useDispatchPositionGet = (): GetReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetReturnType>(
    (positionId) => {
      dispatch({ type: POSITION_GET_START, payload: positionId });

      const collection = buildCollection(clientId);
      const docRef = doc(collection, positionId) as DocumentReference<PositionEntity>;

      return getDoc(docRef)
        .then((docResponse) => {
          if (docResponse.exists()) {
            const mapped: PositionEntity = { ...docResponse.data(), positionId: docResponse.id };
            dispatch({ type: POSITION_GET_SUCCESS, payload: mapped });
            return Promise.resolve(mapped);
          }

          return Promise.reject(new Error(InternalErrorCodes.ENTRY_NOT_FOUND));
        })
        .catch((error) => {
          dispatch({ type: POSITION_GET_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchPositionCreate()
 */
type CreateReturnType = (position: PositionEntity) => Promise<PositionEntity>;
export const useDispatchPositionCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<CreateReturnType>(
    (position) => {
      dispatch({ type: POSITION_CREATE_START, payload: position });

      const collection = buildCollection(clientId);

      return addDoc(collection, position)
        .then((response) => {
          const docRef = doc(getFirestore(), response.path) as DocumentReference<PositionEntity>;
          return getDoc(docRef).then((docResponse) => {
            if (docResponse.exists()) {
              const mapped: PositionEntity = { ...docResponse.data(), positionId: docResponse.id };
              dispatch({ type: POSITION_CREATE_SUCCESS, payload: mapped });
              return Promise.resolve(mapped);
            }

            return Promise.reject(new Error(InternalErrorCodes.ENTRY_NOT_FOUND));
          });
        })
        .catch((error) => {
          dispatch({ type: POSITION_CREATE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchPositionUpdate()
 */
type UpdateReturnType = (position: PositionEntity) => Promise<PositionEntity>;
export const useDispatchPositionUpdate = (): UpdateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateReturnType>(
    (position) => {
      dispatch({ type: POSITION_UPDATE_START, payload: position });

      const collection = buildCollection(clientId);
      const docRef = doc(collection, position.positionId);

      return setDoc(docRef, position, { merge: true })
        .then((response) => {
          dispatch({ type: POSITION_UPDATE_SUCCESS, payload: position });
          return Promise.resolve(position);
        })
        .catch((error) => {
          dispatch({ type: POSITION_UPDATE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchPositionUpdate()
 */
type DeleteReturnType = (positionId: string) => Promise<void>;
export const useDispatchPositionDelete = (): DeleteReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<DeleteReturnType>(
    (positionId) => {
      dispatch({ type: POSITION_DELETE_START, payload: positionId });

      const collection = buildCollection(clientId);
      const docRef = doc(collection, positionId);

      return deleteDoc(docRef)
        .then(() => {
          dispatch({ type: POSITION_DELETE_SUCCESS, payload: positionId });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch({ type: POSITION_DELETE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch],
  );
};
