import React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useDispatch } from 'react-redux';
import {
  USER_CREATE_ERROR,
  USER_CREATE_START,
  USER_CREATE_SUCCESS,
  USER_DELETE_ERROR,
  USER_DELETE_START,
  USER_DELETE_SUCCESS,
  USER_GET_ERROR,
  USER_GET_START,
  USER_GET_SUCCESS,
  USER_GETLIST_ERROR,
  USER_GETLIST_START,
  USER_GETLIST_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_START,
  USER_UPDATE_SUCCESS,
} from '../ActionTypes';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../Globals/FirebaseGlobals';
import { useAppDispatch, useAppSelector } from '../../Globals/Hooks/Hooks';
import { getFirestore, getDocs, getDoc, query, collection, where, orderBy, doc, setDoc } from 'firebase/firestore';
import { UserEntity } from '../../Globals/Types/Types';

/**
 * useDispatchUserCreate()
 */
export const useDispatchUserCreate = () => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (userData: object, password: string) => {
      const preparedData = {
        user: userData,
        password,
        clientId,
      };

      dispatch({ type: USER_CREATE_START, payload: preparedData });

      const createUser = httpsCallable(getFunctions(), FirebaseFunctionNames.createUser);
      return createUser(preparedData)
        .then((response) => {
          dispatch({ type: USER_CREATE_SUCCESS, payload: response });
          return Promise.resolve(response);
        })
        .catch((error) => {
          dispatch({ type: USER_CREATE_ERROR, payload: error });
          return Promise.reject();
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchUserDelete()
 */
export const useDispatchUserDelete = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    (userId: string) => {
      dispatch({ type: USER_DELETE_START, payload: userId });

      const createUser = httpsCallable(getFunctions(), FirebaseFunctionNames.deleteUser);
      return createUser({ userId })
        .then((response) => {
          dispatch({ type: USER_DELETE_SUCCESS, payload: response });
          return Promise.resolve(response);
        })
        .catch((error) => {
          dispatch({ type: USER_DELETE_ERROR, payload: error });
          return Promise.reject();
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchUsersGetList()
 * Get a list of all users from the current authed client
 */
type GetListFunctionAndCallbackType = () => Promise<Array<UserEntity>>;
export const useDispatchUsersGetList = (): GetListFunctionAndCallbackType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListFunctionAndCallbackType>(() => {
    dispatch({ type: USER_GETLIST_START });

    const userCollection = collection(getFirestore(), FirebasePathMappings.user);
    const queryRef = query(userCollection, where('clientId', '==', clientId), orderBy('lastName'));

    return getDocs(queryRef)
      .then((response) => {
        const userArray: Array<UserEntity> = [];
        if (!response.empty) {
          response.forEach((user) => {
            userArray.push({ ...(user.data() as UserEntity), userId: user.id });
          });
        }

        dispatch({ type: USER_GETLIST_SUCCESS, payload: userArray });
        return Promise.resolve(userArray);
      })
      .catch((error) => {
        dispatch({ type: USER_GETLIST_ERROR, payload: error });
        return Promise.reject();
      });
  }, [clientId, dispatch]);
};

/**
 * useDispatchUserGet()
 */
type GetFunctionAndCallbackType = (userId: string) => Promise<UserEntity>;
export const useDispatchUserGet = (): GetFunctionAndCallbackType => {
  const dispatch = useAppDispatch();

  return React.useCallback<GetFunctionAndCallbackType>(
    (userId: string) => {
      dispatch({ type: USER_GET_START, payload: userId });

      const userDocumentRef = doc(getFirestore(), FirebasePathMappings.user, userId);

      return getDoc(userDocumentRef)
        .then((response) => {
          if (response.exists()) {
            const user = { ...response.data(), userId: response.id } as UserEntity;
            dispatch({ type: USER_GET_SUCCESS, payload: user });
            return Promise.resolve(user);
          }

          return Promise.reject();
        })
        .catch((error) => {
          dispatch({ type: USER_GET_ERROR, payload: error });
          return Promise.reject();
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchUserUpdate()
 */
type UpdateFunctionAndCallbackType = (userId: string, data: Partial<UserEntity>) => Promise<UserEntity>;
export const useDispatchUserUpdate = (): UpdateFunctionAndCallbackType => {
  const dispatch = useAppDispatch();
  const dispatchGet = useDispatchUserGet();

  return React.useCallback<UpdateFunctionAndCallbackType>(
    (userId, data) => {
      dispatch({ type: USER_UPDATE_START, payload: data });

      const userDocumentRef = doc(getFirestore(), FirebasePathMappings.user, userId);

      return setDoc(userDocumentRef, data, { merge: true })
        .then((response) => {
          return dispatchGet(userId).then((userEntity) => {
            dispatch({ type: USER_UPDATE_SUCCESS, payload: userEntity });
            return Promise.resolve(userEntity);
          });
        })
        .catch((error) => {
          dispatch({ type: USER_UPDATE_ERROR, payload: error });
          return Promise.reject();
        });
    },
    [dispatch, dispatchGet],
  );
};
