import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Input from '../../../../Components/Inputs/Input';
import Panel from '../../../../Components/Panel';
import { FacilitySettingsEntity } from '../../../../Globals/Types/Types';
import Select from '../../../../Components/Inputs/Select';
import { timeOptions } from '../../../../Globals/Types/General';
import { FacilitySettingsError } from './ValidateSettings';

type BankFormProps = {
  data: FacilitySettingsEntity;
  onChange: Function;
  disabled: boolean;
  errors: FacilitySettingsError;
  withPanel: boolean;
  showSkeleton: boolean;
};

/**
 * GeneralForm()
 * @constructor
 */
export default function GeneralForm(props: BankFormProps) {
  const { data, onChange, showSkeleton } = props;
  const [t] = useTranslation();
  const [workDayOptions] = React.useState([
    { value: 5, label: t('fiveDayWeek') },
    { value: 6, label: t('sixDayWeek') },
  ]);

  const renderContent = () => {
    return (
      <>
        <Row>
          <Col xl={12}>
            <Select
              label={t('workDaysPerWeek')}
              onChange={(value) => onChange({ key: 'workDays', value })}
              initialValue={data.workDays}
              options={workDayOptions}
              required
              disabled={props.disabled}
              showSkeleton={showSkeleton}
              contextHelpKey="workDaysPerWeek"
            />
          </Col>
          <Col xl={6}>
            <Select
              label={t('workDayStart')}
              onChange={(value) => onChange({ key: 'workDayStart', value })}
              initialValue={data.workDayStart}
              options={timeOptions}
              required
              disabled={props.disabled}
              hasError={!!props.errors.workDayStart}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Select
              label={t('workDayEnd')}
              onChange={(value) => onChange({ key: 'workDayEnd', value })}
              initialValue={data.workDayEnd}
              options={timeOptions}
              required
              disabled={props.disabled}
              hasError={!!props.errors.workDayEnd}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={12} style={{ marginTop: 30 }}>
            <Input
              label={t('invoiceMail')}
              onChange={(value) => onChange({ key: 'invoiceMail', value })}
              value={data.invoiceMail}
              disabled={props.disabled}
              hasError={!!props.errors.invoiceMail}
              contextHelpKey="invoiceMail"
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={12}>
            <Input
              label={t('reportMail')}
              onChange={(value) => onChange({ key: 'reportMail', value })}
              value={data.reportMail}
              disabled={props.disabled}
              hasError={!!props.errors.reportMail}
              contextHelpKey="reportMail"
              showSkeleton={showSkeleton}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Panel headline={t('generalSettings')} description={t('modules.settings.generalDescription')}>
      {renderContent()}
    </Panel>
  );
}

GeneralForm.defaultProps = {
  errors: {},
  withPanel: true,
  showSkeleton: false,
};
