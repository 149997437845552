import React from 'react';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import DisplayText from '../../View/DisplayText';
import { OrderMessageEntity } from '../../../Globals/Types/OrderTypes';
import { userDisplayName } from '../../../Globals/Functions';
import moment from 'moment';

interface Props {
  messages: Array<OrderMessageEntity>;
  previewCount: number;
}

/**
 * MessagePreview
 * @param props
 * @constructor
 */
export default function MessagePreview(props: Props) {
  const { messages, previewCount } = props;
  const [t] = useTranslation();

  const getCaption = (message: OrderMessageEntity) => {
    return `${userDisplayName(message.createdUser)} (${moment(message.createdDate).format('LL - LT')}h)`;
  };

  const renderContent = () => {
    if (!messages || messages.length === 0) {
      return <DisplayText icon="fas fa-info-circle">{t('modules.order.noMessageAvailable')}</DisplayText>;
    }

    const reducedMessages = messages.filter((message, index) => index < previewCount);
    return reducedMessages.map((message) => (
      <DisplayText caption={getCaption(message)} key={message.messageId}>
        <span>{message.message.substring(0, 200)}</span>
        <span>{message.message.length > 200 ? '...' : ''}</span>
      </DisplayText>
    ));
  };

  return <Panel headline={t('lastMessages')}>{renderContent()}</Panel>;
}

MessagePreview.defaultProps = {
  previewCount: 3,
};
