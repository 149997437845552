import React from 'react';
import CustomSwitch from '../../../../Components/CustomSwitch';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GroupKey, GroupType } from '../../../../Globals/Types/Types';
import { useGroups } from '../../../../Globals/Access/Groups';

type GroupProps = {
  userGroups: Array<string>;
  onChange: (value: boolean, groupItem: GroupType) => void;
  hasError: boolean;
  disableAdminGroup?: boolean;
};

export default function GroupsForm(props: GroupProps) {
  const { userGroups, onChange, hasError, disableAdminGroup } = props;
  const [t] = useTranslation();
  const groups = useGroups();

  const renderGroups = () => {
    return groups.map((item) => (
      <CustomSwitch
        key={item.key}
        onChange={(value) => onChange(value, item)}
        checked={userGroups.indexOf(item.key) >= 0}
        caption={`${item.name} (${item.short})`}
        description={item.description}
        disabled={item.key === GroupKey.ADMIN && disableAdminGroup}
      />
    ));
  };

  const renderError = () => {
    if (hasError) {
      return (
        <Alert variant="danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          {t('modules.settings.groupFormErrorNonSelected')}
        </Alert>
      );
    }
    return null;
  };

  return (
    <>
      {renderError()}
      {renderGroups()}
    </>
  );
}

GroupsForm.defaultProps = {
  hasError: false,
  disableAdminGroup: false,
};
