import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { CreateOrderState } from '../../../Globals/Types/OrderTypes';
import { isValidPrice } from '../../../Globals/Functions';

export interface ValidateOrderOptionsResult {
  result: boolean;
  errors: {
    service: string;
    externalId: string;
    balance: string;
    removalMeter: string;
  };
}

/**
 * validateOrderOptions
 * @param data
 */
export const validateOrderOptions = (data: CreateOrderState): ValidateOrderOptionsResult => {
  let returnValue: ValidateOrderOptionsResult = {
    result: true,
    errors: {
      service: undefined,
      externalId: undefined,
      balance: undefined,
      removalMeter: undefined,
    },
  };

  if (!data.orderServiceType) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, service: InternalErrorCodes.FIELD_MISSING },
    };
  }
  if (!data.order.externalId || data.order.externalId.length === 0) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, externalId: InternalErrorCodes.FIELD_MISSING },
    };
  }
  if (data.order.balance && !isValidPrice(data.order.balance)) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, balance: InternalErrorCodes.PRICE_INVALID },
    };
  }

  return returnValue;
};
