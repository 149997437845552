import React from 'react';
import styled, { ThemeProvider, useTheme } from 'styled-components';
import Document from './Document';
import { FacilityDocumentTypes } from '../../../Globals/Types/Enums';
import { Col, Container, Row } from 'react-bootstrap';

const CustomRow = styled(Row)`
  margin: 0;
  padding: 0;
`;

const CustomCol = styled(Col)`
  padding: 0;
  margin: 0;
`;

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const theme = useTheme();

  return (
    <ThemeProvider theme={{ ...theme }}>
      <Container fluid style={{ padding: 0, margin: 0 }}>
        <CustomRow style={{ margin: 0, marginRight: -20 }}>
          <CustomCol xl={4}>
            <Document type={FacilityDocumentTypes.logo} allowedExtensions={['png', 'bmp', 'jpg', 'jpeg']} />
          </CustomCol>
          <CustomCol xl={4}>
            <Document type={FacilityDocumentTypes.certificateOfRegistration} />
          </CustomCol>
          <CustomCol xl={4}>
            <Document type={FacilityDocumentTypes.insurance} />
          </CustomCol>
          <CustomCol xl={4}>
            <Document type={FacilityDocumentTypes.tradeLicense} />
          </CustomCol>
          <CustomCol xl={4}>
            <Document type={FacilityDocumentTypes.electricCertificate} />
          </CustomCol>
          <CustomCol xl={4}>
            <Document type={FacilityDocumentTypes.waterCertificate} />
          </CustomCol>
        </CustomRow>
      </Container>
    </ThemeProvider>
  );
}

Overview.defaultProps = {};
