import { InvoicePositionEntity } from '../../../../Globals/Types/Invoice';
import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { isValidPrice } from '../../../../Globals/Functions';

export interface ValidationResult {
  errors: { [K in keyof InvoicePositionEntity]?: string };
  isValid: boolean;
}

/**
 * validatePosition()
 * @param position
 */
export const validatePosition = (position: InvoicePositionEntity): ValidationResult => {
  let result: ValidationResult = {
    errors: {},
    isValid: true,
  };

  if (!position.caption || position.caption.length <= 0) {
    result = { ...result, errors: { ...result.errors, caption: InternalErrorCodes.FIELD_MISSING }, isValid: false };
  }

  if (position.amount > 1) {
    if (!position.pricePerAmount || !isValidPrice(position.pricePerAmount) || position.pricePerAmount.length <= 0) {
      result = {
        ...result,
        errors: { ...result.errors, pricePerAmount: InternalErrorCodes.PRICE_INVALID },
        isValid: false,
      };
    }
  } else {
    if (!position.overallPrice || !isValidPrice(position.overallPrice) || position.overallPrice.length <= 0) {
      result = {
        ...result,
        errors: { ...result.errors, overallPrice: InternalErrorCodes.PRICE_INVALID },
        isValid: false,
      };
    }
  }

  return result;
};
