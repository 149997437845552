import React from 'react';
import { useAppSelector } from '../Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { GroupKey, GroupType } from '../Types/Types';

export enum AccessPath {
  'admin' = '/',
  'customers' = '/customers',
  'invoices' = '/invoices',
  'order' = '/order',
  'orderCreate' = '/order/create',
  'orderDelete' = '/order/delete',
  'orderDetails' = '/order/details',
  'orderUpdate' = '/order/update',
  'orderAssign' = '/order/assign',
  'orderList' = '/order/list',
  'orderSchedule' = '/order/schedule',
  'disposition' = '/disposition',
  'settings' = '/settings',
  'settingsFacilities' = '/settings/facilities',
  'settingsFacilityCreate' = '/settings/createFacility',
  'settingsSettings' = '/settings/settings',
  'settingsUsers' = '/settings/users',
  'settingsInvoices' = '/settings/invoices',
  'settingsTimeTracking' = '/settings/timeTracking',
  'settingsSubscription' = '/settings/subscription',
}

/**
 * Get all global groups
 */
export const useGroups = (): Array<GroupType> => {
  const [t] = useTranslation();

  return [
    {
      key: GroupKey.ADMIN,
      name: t('groupData.administrator'),
      description: t('groupData.administratorDescription'),
      short: 'ADM',
      accessNodes: [{ path: AccessPath.admin, allow: true }],
    },
    {
      key: GroupKey.MANAGEMENT,
      name: t('groupData.management'),
      description: t('groupData.managementDescription'),
      short: 'MAN',
      accessNodes: [
        { path: AccessPath.settings, allow: false },
        { path: AccessPath.orderCreate, allow: false },
        { path: AccessPath.settingsFacilities, allow: true },
        { path: AccessPath.settingsSettings, allow: true },
        { path: AccessPath.settingsInvoices, allow: true },
        { path: AccessPath.customers, allow: true },
      ],
    },
    {
      key: GroupKey.DISPO,
      name: t('groupData.disposition'),
      description: t('groupData.dispositionDescription'),
      short: 'DIS',
      accessNodes: [
        { path: AccessPath.disposition, allow: true },
        { path: AccessPath.orderList, allow: true },
        { path: AccessPath.orderCreate, allow: true },
        { path: AccessPath.orderDetails, allow: true },
        { path: AccessPath.customers, allow: true },
      ],
    },
    {
      key: GroupKey.DEALER,
      name: t('groupData.dealer'),
      description: t('groupData.dealerDescription'),
      short: 'VEK',
      accessNodes: [
        { path: AccessPath.order, allow: true },
        { path: AccessPath.invoices, allow: true },
        { path: AccessPath.orderAssign, allow: false },
        { path: AccessPath.orderSchedule, allow: false },
        { path: AccessPath.customers, allow: true },
      ],
    },
    {
      key: GroupKey.MONTEUR,
      name: t('groupData.monteur'),
      description: t('groupData.monteurDescription'),
      short: 'MON',
      accessNodes: [
        { path: AccessPath.order, allow: false },
        { path: AccessPath.orderList, allow: true },
        { path: AccessPath.orderDetails, allow: true },
        { path: AccessPath.orderCreate, allow: false },
        { path: AccessPath.settings, allow: false },
        { path: AccessPath.disposition, allow: false },
      ],
    },
    {
      key: GroupKey.DRIVER,
      name: t('groupData.driver'),
      description: t('groupData.driverDescription'),
      short: 'DRI',
      accessNodes: [
        { path: AccessPath.order, allow: false },
        { path: AccessPath.orderList, allow: true },
        { path: AccessPath.orderDetails, allow: true },
        { path: AccessPath.orderCreate, allow: false },
        { path: AccessPath.settings, allow: false },
        { path: AccessPath.disposition, allow: false },
      ],
    },
    {
      key: GroupKey.TIMETRACKING,
      name: t('groupData.timeTracking'),
      description: t('groupData.timeTrackingDescription'),
      short: 'TTR',
      accessNodes: [
        { path: AccessPath.order, allow: false },
        { path: AccessPath.orderAssign, allow: false },
        { path: AccessPath.orderSchedule, allow: false },
        { path: AccessPath.orderCreate, allow: false },
        { path: AccessPath.customers, allow: false },
      ],
    },
  ];
};

type BuildUserAccessReturnType = (userGroups: Array<string>) => Array<string>;
/**
 * Build an array with strings of all allowed access nodes for the groups of the assigned user. Only access points
 * with allow = true will be added to this array!
 */
export const useBuildUserAccessPathArray = (): BuildUserAccessReturnType => {
  const globalGroups = useGroups();

  return React.useCallback(
    (userGroups) => {
      let mergedAccessNodes = [];
      const groups = globalGroups.filter((group) => userGroups.indexOf(group.key) > -1);
      groups.forEach((item) => {
        mergedAccessNodes = [...mergedAccessNodes, ...item.accessNodes];
      });

      mergedAccessNodes = [
        ...new Set(
          mergedAccessNodes
            .sort((a, b) => a.path.localeCompare(b.path))
            .map((item) => (item.allow ? item.path : null))
            .filter((item) => item !== null),
        ),
      ];

      return mergedAccessNodes;
    },
    [globalGroups],
  );
};

/**
 * Checks if the user has at least one settings path access. This is needed to check if the menu item should be
 * shown or not! If minimum one settings route is allowed we need to show the settings menu item.
 */
export const useHasSettingsAccess = (): boolean => {
  const [isAllowed, setIsAllowed] = React.useState<boolean>(false);
  const { accessNodes } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    let isAllowedParam = false;

    if (accessNodes.indexOf('/') > -1) {
      isAllowedParam = true;
    }

    accessNodes.forEach((path) => {
      if (!isAllowedParam) {
        if (path.indexOf('/settings') === 0) {
          isAllowedParam = true;
        }
      }
    });

    setIsAllowed(isAllowedParam);
  }, [accessNodes]);

  return isAllowed;
};

/**
 * Check if current user has access to given path. Read user infos from auth reducer!
 * @param path
 */
export const useHasAccess = (path: AccessPath): boolean => {
  const [isAllowed, setIsAllowed] = React.useState<boolean>(false);
  const { accessNodes } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    if (path) {
      let isAllowedParam = false;
      let currentPath = path as string;

      while (!isAllowedParam && currentPath.length > 0) {
        if (accessNodes.indexOf(currentPath) >= 0) {
          isAllowedParam = true;
        }

        if (currentPath.lastIndexOf('/') === 0) {
          if (accessNodes.indexOf('/') >= 0) {
            isAllowedParam = true;
          }
        }
        currentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
      }

      setIsAllowed(isAllowedParam);
    }
  }, [accessNodes, path]);

  return isAllowed;
};
