import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { darken } from 'polished';
import { Col, Container, Row } from 'react-bootstrap';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 50px 40px 30px;
  background-color: ${(props) => props.theme.color.backgroundGray};

  > h1 {
    text-align: center;
    margin-bottom: 40px;
  }

  @media (max-width: 1350px) {
    padding: 50px 20px 20px;
  }
`;

const Box = styled.div`
  background-color: #ffffff;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: column;
  color: ${(props) => props.theme.color.text};
  padding: 40px;
  border-radius: 25px;
  border: 1px solid ${(props) => darken(0.15, props.theme.color.backgroundGray)};

  i {
    color: ${(props) => props.theme.color.primaryColor};
    font-size: 60px;
    margin-bottom: 30px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  }

  h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }

  h5 {
    text-align: center;
    font-size: 18px;
  }

  @media (max-width: 1350px) {
    h5 {
      font-size: 1.2em;
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 24px;
    }
    h5 {
      font-size: 16px;
    }

    i {
      font-size: 30px;
      margin-bottom: 15px;
    }
  }
`;

/**
 * ShortInformationBoxes()
 * @constructor
 */
export default function ShortInformationBoxes() {
  const [t] = useTranslation();

  return (
    <OuterContainer>
      <h1>{t('modules.website.yourAdvantages')}</h1>

      <Container>
        <Row style={{ marginBottom: 25 }}>
          <Col xxl={4} style={{ marginBottom: 25 }}>
            <Box>
              <i className="fad fa-chalkboard-user" />
              <h1>{t('modules.website.infoBox.oneHeadline')}</h1>
              <h5>{t('modules.website.infoBox.one')}</h5>
            </Box>
          </Col>
          <Col xxl={4} style={{ marginBottom: 25 }}>
            <Box>
              <i className="fa-duotone fa-list-check"></i>
              <h1>{t('modules.website.infoBox.twoHeadline')}</h1>
              <h5>{t('modules.website.infoBox.two')}</h5>
            </Box>
          </Col>
          <Col xxl={4} style={{ marginBottom: 25 }}>
            <Box>
              <i className="fad fa-stopwatch" />
              <h1>{t('modules.website.infoBox.threeHeadline')}</h1>
              <h5>{t('modules.website.infoBox.three')}</h5>
            </Box>
          </Col>
          <Col xxl={4} style={{ marginBottom: 25 }}>
            <Box>
              <i className="fad fa-file-lines" />
              <h1>{t('modules.website.infoBox.fourHeadline')}</h1>
              <h5>{t('modules.website.infoBox.four')}</h5>
            </Box>
          </Col>
          <Col xxl={4} style={{ marginBottom: 25 }}>
            <Box>
              <i className="fad fa-handshake" />
              <h1>{t('modules.website.infoBox.fiveHeadline')}</h1>
              <h5>{t('modules.website.infoBox.five')}</h5>
            </Box>
          </Col>
          <Col xxl={4} style={{ marginBottom: 25 }}>
            <Box>
              <i className="fad fa-chart-line" />
              <h1>{t('modules.website.infoBox.sixHeadline')}</h1>
              <h5>{t('modules.website.infoBox.six')}</h5>
            </Box>
          </Col>
        </Row>
      </Container>
    </OuterContainer>
  );
}
