import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import SelectWeek from '../../../../PredefinedSelects/SelectWeek';
import Input, { InputType } from '../../../../Inputs/Input';
import DatePicker from '../../../../Inputs/DatePicker';
import moment from 'moment';
import { useAppSelector, useGetTimeOptions } from '../../../../../Globals/Hooks/Hooks';
import Select from '../../../../Inputs/Select';

interface Props {
  service: ServiceEntity;
  onSave: (item: StateData) => void;
  onClose: () => void;
  visible: boolean;
}

export type StateData = Pick<
  ServiceEntity,
  'desiredWeek' | 'comment' | 'estimatedAssemblyTime' | 'desiredDate' | 'desiredExecutionTime'
>;

export type State = {
  data: StateData;
  initialized: Boolean;
  hasChanges: Boolean;
};

type Action =
  | { type: 'desiredExecutionTime'; payload: string }
  | { type: 'desiredWeek'; payload: string }
  | { type: 'comment'; payload: string }
  | { type: 'desiredDate'; payload: string }
  | { type: 'estimatedAssemblyTime'; payload: number }
  | { type: 'init'; payload: ServiceEntity }
  | { type: 'clear' };

const initialState: State = {
  data: {
    desiredExecutionTime: null,
    desiredWeek: null,
    comment: null,
    desiredDate: null,
    estimatedAssemblyTime: null,
  },
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'desiredExecutionTime':
      return { ...state, data: { ...state.data, desiredExecutionTime: action.payload }, hasChanges: true };
    case 'desiredWeek':
      return { ...state, data: { ...state.data, desiredWeek: action.payload }, hasChanges: true };
    case 'comment':
      return { ...state, data: { ...state.data, comment: action.payload }, hasChanges: true };
    case 'desiredDate':
      return {
        ...state,
        data: { ...state.data, desiredDate: action.payload },
        hasChanges: true,
      };
    case 'estimatedAssemblyTime':
      return { ...state, data: { ...state.data, estimatedAssemblyTime: action.payload || null }, hasChanges: true };
    case 'init':
      return {
        ...initialState,
        data: {
          desiredExecutionTime: action.payload.desiredExecutionTime,
          desiredWeek: action.payload.desiredWeek,
          comment: action.payload.comment,
          desiredDate: action.payload.desiredDate,
          estimatedAssemblyTime: action.payload.estimatedAssemblyTime,
        },
        initialized: true,
      };
    case 'clear':
      return { ...initialState };
  }
};

/**
 * EditModal
 * @param props
 * @constructor
 */
export default function EditModal(props: Props) {
  const { service, visible, onClose, onSave } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [t] = useTranslation();
  const { options: timeOptions } = useGetTimeOptions(15);
  const { dispositions } = useAppSelector((state) => state.order.selectedOrder.selectedService);

  React.useEffect(() => {
    if (!state.initialized && service && visible) {
      dispatch({ type: 'init', payload: service });
    }
  }, [service, state.initialized, visible]);

  const handleSave = () => {
    onSave(state.data);
  };

  if (visible && state.initialized) {
    return (
      <Modal show={visible} onHide={() => onClose()} size="xl">
        <Modal.Header>
          <div>
            <Modal.Title style={{ marginLeft: 25 }}>{t('editAppointmentsAndTimes')}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: 30 }}>
          <Row>
            <Col lg={6}>
              <DatePicker
                onChange={(value) => dispatch({ type: 'desiredDate', payload: value })}
                label={t('desiredDate')}
                initialValue={state.data.desiredDate ? moment(state.data.desiredDate).toDate() : null}
              />
            </Col>
            <Col lg={6}>
              <SelectWeek
                onChange={(value) => dispatch({ type: 'desiredWeek', payload: value })}
                selected={state.data.desiredWeek}
                label={t('desiredWeek')}
              />
            </Col>
            <Col lg={6}>
              <Input
                onChange={(value) => dispatch({ type: 'estimatedAssemblyTime', payload: parseInt(value) })}
                label={t('estimatedAssemblyTimeInMinutes')}
                type={InputType.number}
                value={state.data.estimatedAssemblyTime}
                disabled={dispositions && dispositions.length > 0}
              />
            </Col>
            <Col lg={6}>
              <Select
                onChange={(value) => dispatch({ type: 'desiredExecutionTime', payload: value })}
                label={t('arrivalAtCustomer')}
                options={timeOptions}
                initialValue={state.data.desiredExecutionTime}
              />
            </Col>
            <Col lg={12}>
              <Input
                onChange={(value) => dispatch({ type: 'comment', payload: value })}
                value={state.data.comment}
                label={t('modules.order.montageHint')}
                type={InputType.textarea}
                rows={5}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
            {t('save')}
          </Button>
          <Button variant="outline-secondary" onClick={() => onClose()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
