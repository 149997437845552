import React from 'react';
import { AccessPath, useHasAccess } from '../../../Globals/Access/Groups';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router';

const Container = styled.div<{ $iconColor?: string }>`
  display: flex;
  justify-content: center;
  margin-top: 4px;
  padding: 18px;
  border-radius: 8px;
  cursor: pointer;

  > i {
    color: ${(props) => props.$iconColor || props.theme.color.primaryColor};
    font-size: 22px;
  }

  &.active,
  &:hover {
    background-color: ${(props) => props.theme.color.primaryColor};

    > i {
      color: #ffffff;
    }
  }
`;

interface Props {
  visible: boolean;
  accessPath?: AccessPath;
  icon: string;
  iconColor?: string;
  path?: string;
  onPress?: () => void;
  disabled: boolean;
}

/**
 * MainNavigationItem()
 * @param props
 * @constructor
 */
export default function MainNavigationItem(props: Props) {
  const { visible, accessPath, icon, path, onPress, disabled, iconColor } = props;
  const isAllowed = useHasAccess(accessPath);
  const navigation = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (path) {
      setIsActive(location.pathname.lastIndexOf(path) >= 0);
    } else {
      setIsActive(false);
    }
  }, [location, path]);

  const handleNavigation = () => {
    if (path && !onPress) {
      navigation(path);
    } else {
      onPress();
    }
  };

  if (visible && (!accessPath || isAllowed)) {
    return (
      <Container
        onClick={!disabled ? handleNavigation : () => {}}
        className={isActive ? 'active' : null}
        $iconColor={iconColor}
      >
        <i className={icon} />
      </Container>
    );
  }
  return null;
}

MainNavigationItem.defaultProps = {
  disabled: false,
};
