import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { RecurringType } from '../../Globals/Types/Enums';
import { useTranslation } from 'react-i18next';
import { useGetFacility } from '../../Globals/Hooks/FacilityHooks';
import { FacilityEntity } from '../../Globals/Types/Types';
import { getWorkDayShortString } from '../../Globals/Functions';

interface Props {
  hasError?: boolean;
  initialValue: RecurringType;
  label: string;
  onChange: (value: RecurringType) => void;
}

/**
 * SelectRecurring
 * @constructor
 */
export default function SelectRecurring(props: Props) {
  const { hasError, initialValue, label, onChange } = props;
  const [recurringOptions, setRecurringOptions] = React.useState<SelectOptions>(null);

  const [t] = useTranslation();

  const getFacility = useGetFacility();

  React.useEffect(() => {
    const facility: FacilityEntity = getFacility();
    const workDays: number = facility?.settings?.workDays - 1 || 4;
    const workDaysString: string = `(${t('weekdayShort.monday')} - ${getWorkDayShortString(workDays, t)})`;

    setRecurringOptions([
      { label: t('dailyWork', { workDays: workDaysString }), value: RecurringType.dailyWork },
      { label: t('daily'), value: RecurringType.daily },
      { label: t('weekly'), value: RecurringType.weekly },
      { label: t('monthly'), value: RecurringType.monthly },
    ]);
  }, [getFacility, t]);

  if (recurringOptions) {
    return (
      <Select
        label={label}
        initialValue={initialValue}
        onChange={onChange}
        options={recurringOptions}
        hasError={hasError}
      />
    );
  }

  return null;
}
