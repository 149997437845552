import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { Parking } from '../../Globals/Types/Enums';

interface Props {
  onChange: (item: number) => void;
  selected: number;
}

/**
 * SelectParking
 * @param props
 * @constructor
 */
export default function SelectParking(props: Props) {
  const { onChange, selected } = props;
  const [t] = useTranslation();
  const [options] = React.useState<SelectOptions>([
    { value: Parking.noInfo, label: t(`parking.${Parking.noInfo}`) },
    { value: Parking.ok, label: t(`parking.${Parking.ok}`) },
    { value: Parking.smallVehicle, label: t(`parking.${Parking.smallVehicle}`) },
    { value: Parking.stoppingZone, label: t(`parking.${Parking.stoppingZone}`) },
  ]);

  return <Select label={t('parkingSituation')} onChange={onChange} initialValue={selected} options={options} />;
}
