import React from 'react';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import DatePicker from '../../Inputs/DatePicker';
import { DefaultHeyKitchenPartnerEntity } from '../../../Globals/Types/General';
import SelectWeek from '../../PredefinedSelects/SelectWeek';
import moment from 'moment';
import { ValidateMontageDateResult } from '../Validators/ValidateMontageDateOptions';
import { useGetTimeOptions } from '../../../Globals/Hooks/Hooks';
import Select from '../../Inputs/Select';

interface Props {
  onChangeExecutionDate: (item: string) => void;
  onChangeExecutionTime: (item: string) => void;
  onChangeWeek: (item: string) => void;
  executionDate: string;
  executionTime: string;
  desiredWeek: string;
  selectedPartner: string;
  blackHeadline?: boolean;
  validationResult: ValidateMontageDateResult;
}

/**
 * MontageDate
 * @param props
 * @constructor
 */
export default function MontageDate(props: Props) {
  const {
    onChangeExecutionTime,
    onChangeExecutionDate,
    onChangeWeek,
    selectedPartner,
    executionTime,
    executionDate,
    desiredWeek,
    blackHeadline,
    validationResult,
  } = props;
  const { options: timeOptions } = useGetTimeOptions(15);
  const [t] = useTranslation();

  const renderWeek = () => {
    if (selectedPartner !== DefaultHeyKitchenPartnerEntity.partnerFacilityId) {
      return (
        <Col xl={4}>
          <SelectWeek label={t('desiredWeek')} onChange={onChangeWeek} selected={desiredWeek} />
        </Col>
      );
    }
  };

  const renderExecutionTime = () => {
    return (
      <Col xl={4}>
        <Select
          onChange={onChangeExecutionTime}
          label={t('arrivalAtCustomer')}
          options={timeOptions}
          initialValue={executionTime}
        />
      </Col>
    );
  };

  const getDateColWidth = () => {
    return selectedPartner === DefaultHeyKitchenPartnerEntity.partnerFacilityId ? 12 : 4;
  };

  const getDescription = () => {
    if (selectedPartner === DefaultHeyKitchenPartnerEntity.partnerFacilityId) {
      return t('modules.order.montageDateExternDescription');
    }
    return t('modules.order.montageDateInternDescription');
  };

  return (
    <Panel
      headline={t('planedDateOrDesiredDate')}
      description={getDescription()}
      headlineDarkBlack={blackHeadline || false}
    >
      <Row>
        <Col xl={getDateColWidth()}>
          <DatePicker
            label={t('desiredDate')}
            onChange={onChangeExecutionDate}
            required={selectedPartner === DefaultHeyKitchenPartnerEntity.partnerFacilityId}
            initialValue={executionDate ? moment(executionDate).toDate() : null}
            minDate={moment().toDate()}
            hasError={validationResult ? !!validationResult.errors.montageDate : false}
          />
        </Col>
        {renderWeek()}
        {renderExecutionTime()}
      </Row>
    </Panel>
  );
}
