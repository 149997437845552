import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select, { SelectOptions } from '../Inputs/Select';

interface Props {
  hasErrorDay?: boolean;
  hasErrorOn?: boolean;
  labelDay: string;
  labelOn: string;
  onChange: (type: string, value: string) => void;
}

/**
 * SelectOnDay
 * @constructor
 */
export default function SelectOnDay(props: Props) {
  const { hasErrorDay, hasErrorOn, labelDay, labelOn, onChange } = props;
  const [t] = useTranslation();
  const [optionsDay] = React.useState<SelectOptions>([
    { label: t('workDay'), value: 'weekday' },
    { label: t('day'), value: 'day' },
  ]);
  const [optionsOn] = React.useState<SelectOptions>([
    { label: t('placings.first'), value: '1' },
    { label: t('placings.second'), value: '2' },
    { label: t('placings.third'), value: '3' },
    { label: t('placings.fourth'), value: '4' },
    { label: t('placings.fifth'), value: '5' },
    { label: t('placings.sixth'), value: '6' },
    { label: t('placings.last'), value: '31' },
  ]);

  return (
    <>
      <Row>
        <Col>
          <Select
            onChange={(value) => onChange('on', value)}
            label={labelOn}
            options={optionsOn}
            hasError={hasErrorOn}
          />
        </Col>
        <Col>
          <Select
            onChange={(value) => onChange('dayOn', value)}
            label={labelDay}
            options={optionsDay}
            hasError={hasErrorDay}
          />
        </Col>
      </Row>
    </>
  );
}
