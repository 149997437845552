import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  background-color: ${(props) => props.theme.color.primaryColor};
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 1200px;
  justify-content: center;
  align-items: center;

  > .CreditCard {
    color: ${(props) => lighten(0.25, props.theme.color.primaryColor)};
    margin-top: 20px;
    font-weight: bold;
  }

  @media (max-width: 1350px) {
    width: 100%;

    > .CreditCard {
      font-size: 11px;
    }
  }
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 20px 30px;
  font-size: 20px;
  color: ${(props) => props.theme.color.primaryColor};
  border-radius: 6px;
  font-weight: bold;
  ${(props) => props.theme.boxShadowDarkHover};
  cursor: pointer;
  transition: color, background-color 300ms;
  text-align: center;

  > i {
    font-size: 30px;
  }

  &:hover {
    background-color: ${(props) => lighten(0.1, props.theme.color.orange)};
    color: #ffffff;

    i {
      color: #ffffff;
    }
  }

  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;

/**
 * RegisterRow()
 * @constructor
 */
export default function RegisterRow() {
  const [t] = useTranslation();

  const open = () =>
    window.open('https://outlook.office365.com/owa/calendar/FreepresentationSotoSoft@sotosoft.app/bookings/', '_blank');

  return (
    <Container>
      <InnerContainer>
        <Button onClick={() => open()}>
          <div>
            <i className="fas fa-calendar-heart" style={{ marginRight: 10 }} />
          </div>
          <div>{t('modules.website.registerForFree')}</div>
        </Button>
      </InnerContainer>
    </Container>
  );
}
