import React from 'react';
import { CustomerEntity } from '../../../Globals/Types/Customer';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import SearchInput from '../../Inputs/SearchInput';
import styled from 'styled-components';
import Row from './Row';
import { useDispatchCustomerGetList } from '../../../Redux/Actions/CustomerAction';

const ScrollContainer = styled.div`
  overflow-y: scroll;
  height: 500px;
  margin: -15px;
  padding: 20px;
  border-radius: 4px;
`;

interface Props {
  visible: boolean;
  onClose: () => void;
  onSelect: (customer: CustomerEntity) => void;
}

/**
 * SelectCustomerModal()
 * @param props
 * @constructor
 */
export default function SelectCustomerModal(props: Props) {
  const { visible, onClose, onSelect } = props;
  const { all: customers } = useAppSelector((state) => state.client.customers);
  const [searchString, setSearchString] = React.useState<string>('');
  const [searchResult, setSearchResult] = React.useState<CustomerEntity[]>(null);
  const [customersFetched, setCustomersFetched] = React.useState<boolean>(false);
  const dispatchGetList = useDispatchCustomerGetList();
  const [t] = useTranslation();

  React.useEffect(() => {
    if ((!customers || customers.length === 0) && !customersFetched) {
      setCustomersFetched(true);
      dispatchGetList().then(() => {});
    }
  }, [customers, customersFetched, dispatchGetList]);

  const filter = React.useCallback(() => {
    const filtered = customers.filter(
      (item) =>
        (item.firstName && item.firstName.toLowerCase().indexOf(searchString.toLowerCase()) > -1) ||
        (item.lastName && item.lastName.toLowerCase().indexOf(searchString.toLowerCase()) > -1) ||
        (item.companyName && item.companyName.toLowerCase().indexOf(searchString.toLowerCase()) > -1),
    );
    setSearchResult(filtered);
  }, [customers, searchString]);

  React.useEffect(() => {
    if (searchString && searchString.length > 2) {
      filter();
    } else {
      setSearchResult(null);
    }
  }, [filter, searchString]);

  React.useEffect(() => {
    if (!visible) {
      setSearchResult(null);
    }
  }, [visible]);

  const renderList = () => {
    if (searchResult) {
      return searchResult.map((customer) => <Row customer={customer} onSelect={onSelect} key={customer.customerId} />);
    }

    return customers.map((customer) => <Row customer={customer} onSelect={onSelect} key={customer.customerId} />);
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ padding: '0 5px' }}>
          <SearchInput
            onChange={setSearchString}
            value={searchString}
            placeholder={`${t('search')}...`}
            autoFocus={true}
          />
        </div>

        <ScrollContainer>{renderList()}</ScrollContainer>
      </>
    );
  };

  return (
    <Modal show={visible} animation={true} size="xl" onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          <i className="fas fa-peoples" style={{ marginRight: 10 }} />
          {t('customerSelect')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderContent()}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => onClose()}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
