import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { BankEntity, FacilityEntity } from '../../../../Globals/Types/Types';
import ValidateFacility from '../Components/ValidateFacility';
import FacilityForm from '../../../../Components/Facility/FacilityForm';
import BankForm from '../../../../Components/Facility/BankForm';
import { useNavigate } from 'react-router';
import { useDispatchCreateFacility } from '../../../../Redux/Actions/Client/FacilityAction';
import { useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import PageHeader from '../../../../Components/PageHeader';
import { CountryCode } from '../../../../Globals/Types/Enums';

export type State = {
  facility: FacilityEntity;
  errors: { facility: FacilityEntity; bank: BankEntity };
  initialized: Boolean;
  hasChanges: Boolean;
};

const initialState: State = {
  facility: {
    countryCode: CountryCode.DE,
  } as FacilityEntity,
  errors: {
    facility: {} as FacilityEntity,
    bank: {} as BankEntity,
  },
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case 'updateClient': {
      const { key, value } = action.payload;
      return { ...state, facility: { ...state.facility, [key]: value }, hasChanges: true };
    }
    case 'updateBank': {
      const { key, value } = action.payload;
      return {
        ...state,
        hasChanges: true,
        facility: {
          ...state.facility,
          bank: { ...state.facility.bank, [key]: value },
        },
      };
    }
    case 'resetChanges':
      return {
        ...state,
        hasChanges: false,
        errors: {
          facility: {} as FacilityEntity,
          bank: {} as BankEntity,
        },
      };
    case 'init': {
      return {
        ...state,
        facility: action.payload,
        initialized: true,
        hasChanges: false,
        errors: {
          facility: {} as FacilityEntity,
          bank: {} as BankEntity,
        },
      };
    }
    case 'setErrors':
      const { facility, bank } = action.payload;
      return { ...state, errors: { facility, bank } };
  }
};

/**
 * Details()
 * @constructor
 */
export default function Create() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatchSave = useDispatchCreateFacility();
  const { loadingModal, setLoading, isLoading } = useLoadingModal({ delayStart: 1000 });

  const handleSave = () => {
    const { isValid, errors } = ValidateFacility(state.facility);

    if (isValid) {
      setLoading(true);

      dispatchSave(state.facility)
        .then((facilityId) => {
          setLoading(false);
          navigate(`/settings/facility/${facilityId}/details`);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      dispatch({ type: 'setErrors', payload: { facility: errors, bank: errors.bank } });
    }
  };

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={handleSave} disabled={isLoading || !state.hasChanges}>
        {t('createFacility')}
      </Button>
    );
  };

  return (
    <>
      <PageHeader headline={t('createFacility')} actionButtonOne={renderSaveButton()} />
      <FacilityForm
        data={state.facility}
        onChange={({ key, value }) => dispatch({ type: 'updateClient', payload: { key, value } })}
        disabled={isLoading}
        errors={state.errors.facility}
        disableName={false}
      />
      <BankForm
        data={state.facility.bank || ({} as BankEntity)}
        onChange={({ key, value }) => dispatch({ type: 'updateBank', payload: { key, value } })}
        disabled={isLoading}
        errors={state.errors.bank}
      />
      {loadingModal}
    </>
  );
}
