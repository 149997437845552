import React from 'react';
import Panel from '../../../Panel';
import { useTranslation } from 'react-i18next';
import TimeTrackingTable from '../../TimeTrackingTable';
import { timeTrackingOrderCalculateOverall } from '../../../../Globals/Hooks/TimeTrackingHooks';
import { convertMinutesToTimeString } from '../../../../Globals/Functions';
import { TimeTrackingEntity } from '../../../../Globals/Types/Types';

interface Props {
  timeTracking: TimeTrackingEntity[];
}

/**
 * TimeTracking()
 * @param props
 * @constructor
 */
export default function TimeTracking(props: Props) {
  const { timeTracking } = props;
  const [t] = useTranslation();

  const getDescription = () => {
    const minutes = timeTrackingOrderCalculateOverall(timeTracking);
    return `${t('overallWorkTime')}: ${t('xHours', { time: convertMinutesToTimeString(minutes) })}`;
  };

  if (timeTracking && timeTracking.length > 0) {
    return (
      <Panel headline={t('timeTracking')} description={getDescription()}>
        <TimeTrackingTable timeTrackings={timeTracking} previewMode={true} />
      </Panel>
    );
  }
  return null;
}
