import { Subscription } from '../../../../../Globals/Types/Types';
import { SubscriptionPeriod, SubscriptionType } from '../../../../../Globals/Types/Enums';
import moment from 'moment';
import { basicPrice, userPrice } from '../../../../../Globals/Hooks/SubscriptionHooks';

type Action =
  | { type: 'users'; payload: number }
  | { type: 'pricePerUser'; payload: number }
  | { type: 'voucherCode'; payload: string }
  | { type: 'overallPrice'; payload: string }
  | { type: 'name'; payload: string }
  | { type: 'zip'; payload: string }
  | { type: 'city'; payload: string }
  | { type: 'street'; payload: string }
  | { type: 'streetNo'; payload: string }
  | { type: 'vat'; payload: string }
  | { type: 'mail'; payload: string }
  | { type: 'period'; payload: SubscriptionPeriod }
  | { type: 'init'; payload: Partial<Subscription> };

interface SubscriptionState {
  subscription: Subscription;
  initialized: boolean;
  hasChanges: boolean;
}

export const initialState: SubscriptionState = {
  subscription: {
    subscriptionId: null,
    active: true,
    invoiceNumber: null,
    type: SubscriptionType.regular,
    pricePerUser: userPrice,
    basicPrice: basicPrice,
    rebate: 0,
    overallPrice: 0,
    isUpdateSubscription: false,
    autoRenew: true,
    period: SubscriptionPeriod.year,
    payed: false,
    users: 1,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'year').format('YYYY-MM-DD'),
    createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    voucherCode: '',
    invoiceAddress: {
      name: '',
      zip: '',
      city: '',
      street: '',
      streetNo: '',
      vat: '',
      mail: '',
    },
  },
  initialized: false,
  hasChanges: false,
};

const calculateEnd = (period: SubscriptionPeriod) => {
  if (period === SubscriptionPeriod.year) {
    return moment().add(1, 'year').format('YYYY-MM-DD');
  }
  return moment().add(1, 'month').format('YYYY-MM-DD');
};

export const reducer = (state: SubscriptionState, action: Action): SubscriptionState => {
  switch (action.type) {
    case 'users':
      return { ...state, subscription: { ...state.subscription, users: action.payload }, hasChanges: true };
    case 'pricePerUser':
      return { ...state, subscription: { ...state.subscription, pricePerUser: action.payload }, hasChanges: true };
    case 'overallPrice':
      return {
        ...state,
        subscription: { ...state.subscription, overallPrice: parseFloat(action.payload) },
        hasChanges: true,
      };
    case 'period':
      return {
        ...state,
        subscription: { ...state.subscription, period: action.payload, endDate: calculateEnd(action.payload) },
        hasChanges: true,
      };
    case 'voucherCode':
      return {
        ...state,
        subscription: { ...state.subscription, voucherCode: action.payload.toUpperCase() },
        hasChanges: true,
      };

    case 'name':
      return {
        ...state,
        subscription: {
          ...state.subscription,
          invoiceAddress: { ...state.subscription.invoiceAddress, name: action.payload },
        },
        hasChanges: true,
      };
    case 'zip':
      return {
        ...state,
        subscription: {
          ...state.subscription,
          invoiceAddress: { ...state.subscription.invoiceAddress, zip: action.payload },
        },
        hasChanges: true,
      };
    case 'city':
      return {
        ...state,
        subscription: {
          ...state.subscription,
          invoiceAddress: { ...state.subscription.invoiceAddress, city: action.payload },
        },
        hasChanges: true,
      };
    case 'street':
      return {
        ...state,
        subscription: {
          ...state.subscription,
          invoiceAddress: { ...state.subscription.invoiceAddress, street: action.payload },
        },
        hasChanges: true,
      };
    case 'streetNo':
      return {
        ...state,
        subscription: {
          ...state.subscription,
          invoiceAddress: { ...state.subscription.invoiceAddress, streetNo: action.payload },
        },
        hasChanges: true,
      };
    case 'vat':
      return {
        ...state,
        subscription: {
          ...state.subscription,
          invoiceAddress: { ...state.subscription.invoiceAddress, vat: action.payload },
        },
        hasChanges: true,
      };
    case 'mail':
      return {
        ...state,
        subscription: {
          ...state.subscription,
          invoiceAddress: { ...state.subscription.invoiceAddress, mail: action.payload },
        },
        hasChanges: true,
      };

    case 'init':
      return { ...state, subscription: { ...state.subscription, ...action.payload }, initialized: true };

    default:
      new Error('Type not found!');
  }
};
