import React from 'react';
import FacilityHeader from '../Components/FacilityHeader';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { FacilityPartnerEntity, GroupKey } from '../../../../Globals/Types/Types';
import {
  useDispatchPartnerDelete,
  useDispatchPartnersGet,
  useDispatchPartnerUpdate,
} from '../../../../Redux/Actions/Client/Facility/PartnerAction';
import Panel from '../../../../Components/Panel';
import Select from '../../../../Components/Inputs/Select';
import { selectOptionYesNo } from '../../../../Globals/Types/General';
import { useAppSelector, useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import AskSaveChanges from '../../../../Components/Modals/AskSaveChanges';
import BottomInternalIdContainer from '../../../../Components/BottomInternalIdContainer';
import AskDeleteModal from '../../../../Components/Modals/AskDeleteModal';
import SelectStorage from '../../../../Components/PredefinedSelects/SelectStorage';
import ShowHideContainer from '../../../../Components/ShowHideContainer';
import { useDispatchStorageAddressGetList } from '../../../../Redux/Actions/Client/Storage/AddressAction';
import { userDisplayName } from '../../../../Globals/Functions';

export type State = {
  partner: FacilityPartnerEntity;
  initialized: Boolean;
  hasChanges: Boolean;
};

type Action =
  | { type: 'active'; payload: boolean }
  | { type: 'assignOrders'; payload: boolean }
  | { type: 'createOrders'; payload: boolean }
  | { type: 'responsibleUser'; payload: string | null }
  | { type: 'allowUseStorage'; payload: boolean }
  | { type: 'defaultStorageId'; payload: string }
  | { type: 'init'; payload: FacilityPartnerEntity };

const initialState: State = {
  partner: {
    settings: {
      allowUseStorage: false,
      defaultStorageId: null,
    },
  } as FacilityPartnerEntity,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'active':
      return {
        ...state,
        hasChanges: true,
        partner: {
          ...state.partner,
          active: action.payload,
        },
      };
    case 'assignOrders':
      return {
        ...state,
        hasChanges: true,
        partner: {
          ...state.partner,
          settings: { ...state.partner.settings, assignOrders: action.payload },
        },
      };
    case 'responsibleUser':
      return {
        ...state,
        hasChanges: true,
        partner: {
          ...state.partner,
          settings: { ...state.partner.settings, responsibleUserId: action.payload },
        },
      };
    case 'createOrders':
      return {
        ...state,
        hasChanges: true,
        partner: {
          ...state.partner,
          settings: { ...state.partner.settings, createOrders: action.payload },
        },
      };
    case 'allowUseStorage':
      return {
        ...state,
        hasChanges: true,
        partner: {
          ...state.partner,
          settings: { ...state.partner.settings, allowUseStorage: action.payload },
        },
      };
    case 'defaultStorageId':
      return {
        ...state,
        hasChanges: true,
        partner: {
          ...state.partner,
          settings: { ...state.partner.settings, defaultStorageId: action.payload },
        },
      };
    case 'init': {
      return {
        ...state,
        partner: { ...state.partner, ...action.payload },
        initialized: true,
        hasChanges: false,
      };
    }
  }
};

/**
 * Details
 * @constructor
 */
export default function Details() {
  const [partner, setPartner] = React.useState<FacilityPartnerEntity>(null);
  const [askDiscard, setAskDiscard] = React.useState<boolean>(false);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { all: users } = useAppSelector((state) => state.client.users);
  const { addresses } = useAppSelector((state) => state.storage);
  const [userOptions, setUSerOptions] = React.useState<Array<{ value: string; label: string }>>(null);
  const { facilityId, partnerId } = useParams();
  const { isLoading, setLoading } = useLoadingModal();
  const [t] = useTranslation();
  const dispatchGet = useDispatchPartnersGet();
  const dispatchUpdate = useDispatchPartnerUpdate();
  const dispatchDelete = useDispatchPartnerDelete();
  const dispatchGetStorageAddresses = useDispatchStorageAddressGetList();
  const navigate = useNavigate();

  const [selectOptionState] = React.useState([
    { value: true, label: t('active') },
    { value: false, label: t('paused') },
  ]);

  const loadPartner = React.useCallback(() => {
    dispatchGet(facilityId, partnerId).then((partnerResponse) => {
      setPartner(partnerResponse);
    });
  }, [dispatchGet, facilityId, partnerId]);

  React.useEffect(() => {
    const filtered = users.filter(
      (user) =>
        user.groups.indexOf(GroupKey.ADMIN) >= 0 ||
        user.groups.indexOf(GroupKey.DEALER) >= 0 ||
        user.groups.indexOf(GroupKey.MANAGEMENT) >= 0,
    );
    const options = filtered.map((user) => ({
      value: user.userId,
      label: userDisplayName(user, true),
    }));
    options.unshift({ value: null, label: 'Kein Mitarbeiter ausgewählt' });
    setUSerOptions(options);
  }, [users]);

  React.useEffect(() => {
    if (partner) {
      dispatch({ type: 'init', payload: partner });
    }
  }, [partner]);

  React.useEffect(() => {
    loadPartner();
    dispatchGetStorageAddresses().then(() => {});
  }, [dispatchGetStorageAddresses, loadPartner]);

  const saveChanges = () => {
    setLoading(true);
    dispatchUpdate(facilityId, state.partner).then(() => {
      setLoading(false);
      navigate(-1);
    });
  };

  const handleDelete = () => {
    dispatchDelete(facilityId, partnerId).then(() => {
      navigate(-1);
    });
  };

  const handleCancel = () => {
    if (state.hasChanges) {
      setAskDiscard(true);
    } else {
      navigate(-1);
    }
  };

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={saveChanges} disabled={!state.hasChanges || isLoading}>
        {t('save')}
      </Button>
    );
  };

  const renderCancelButton = () => {
    return (
      <Button variant="outline-secondary" onClick={handleCancel} disabled={isLoading}>
        {t('cancel')}
      </Button>
    );
  };

  if (partner && state.initialized && userOptions) {
    return (
      <>
        <FacilityHeader
          actionButtonOne={renderSaveButton()}
          actionButtonTwo={renderCancelButton()}
          subHeadline={t('modules.settings.facility.partnerDetailsSubHeadline', { partnerName: partner.partnerName })}
        />

        <Panel
          headline={t('modules.settings.facility.partnerState')}
          description={t('modules.settings.facility.partnerStatusDescription')}
        >
          <Select
            label={t('state')}
            onChange={(value) => dispatch({ type: 'active', payload: value })}
            options={selectOptionState}
            initialValue={state.partner.active}
          />
        </Panel>

        <Panel
          headline={t('generalSettings')}
          description={t('modules.settings.facility.partnerGeneralSettingsDescription')}
        >
          <Select
            label={t('modules.settings.facility.partnerCanAssignOrders')}
            onChange={(value) => dispatch({ type: 'assignOrders', payload: value })}
            initialValue={state.partner.settings.assignOrders}
            options={selectOptionYesNo}
            contextHelpKey="partnerAllowAssign"
          />
          <Select
            label={t('modules.settings.facility.partnerCreateOrdersSelectCaption')}
            onChange={(value) => dispatch({ type: 'createOrders', payload: value })}
            initialValue={state.partner.settings.createOrders || false}
            options={selectOptionYesNo}
            contextHelpKey="partnerCreateOrders"
          />
        </Panel>

        <ShowHideContainer visible={addresses && addresses.length > 0}>
          <Panel
            headline={t('modules.settings.facility.storageOption')}
            description={t('modules.settings.facility.partnerAllowUseStorageDescription')}
          >
            <Select
              label={t('modules.settings.facility.partnerAllowUseStorage')}
              onChange={(value) => dispatch({ type: 'allowUseStorage', payload: value })}
              initialValue={state.partner.settings.allowUseStorage}
              options={selectOptionYesNo}
            />
            <SelectStorage
              onChange={(value) => dispatch({ type: 'defaultStorageId', payload: value.storageId })}
              disabled={!state.partner.settings.allowUseStorage}
              selected={state.partner.settings.defaultStorageId}
              onlyExternalAllowed
            />
          </Panel>
        </ShowHideContainer>

        <Panel
          headline={t('responsibleUser')}
          description={t('modules.settings.facility.partnerResponsibleUserDescription')}
        >
          <Select
            label={t('responsibleUser')}
            onChange={(value) => dispatch({ type: 'responsibleUser', payload: value })}
            initialValue={state.partner.settings.responsibleUserId}
            options={userOptions}
          />
        </Panel>

        <div style={{ textAlign: 'center', marginBottom: 30, marginTop: 30 }}>
          <Button variant="outline-danger" onClick={() => setAskDelete(true)} style={{ borderColor: 'transparent' }}>
            Partnerschaft löschen
          </Button>
        </div>

        <BottomInternalIdContainer id={partner.partnerId} />

        <AskDeleteModal
          onDelete={handleDelete}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
          headline={t('modules.settings.facility.deletePartnership')}
          description={t('modules.settings.facility.deletePartnershipDescription')}
        />

        <AskSaveChanges onLeave={() => navigate(-1)} onCancel={() => setAskDiscard(false)} visible={askDiscard} />
      </>
    );
  }
  return null;
}
