/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { useHelpContext } from '../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import CustomSkeleton from '../CustomSkeleton';
import { formatSize } from '../../Globals/Functions';
import ErrorMessage from '../Modals/ErrorMessage';
import AskDeleteModal from '../Modals/AskDeleteModal';

interface SelectProps {
  onChange: (file: File) => void;
  label: string;
  displayFileName?: string;
  visible: boolean;
  required: boolean;
  hasError?: boolean;
  errorId?: string;
  errorMessage?: string | null;
  disabled: boolean;
  contextHelpKey: string;
  showSkeleton: boolean;
  allowedTypes?: Array<string>;
  loading: boolean;
}

interface ContainerProps {
  focused: boolean;
  hasError: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 15px;

  .TextContainer {
    height: 58px;
    border-radius: 6px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: ${(props) => (props.focused ? '6px' : '16px')};
    padding-top: ${(props) => (props.focused ? '26px' : '16px')};
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.border.color};
    cursor: pointer;

    .Value {
      color: ${(props) => props.theme.color.text};
    }
  }

  .skeleton {
    border-radius: 6px;
    line-height: 56px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.border.color};
    outline: none;
  }

  .fa-folder-open {
    color: ${(props) => props.theme.color.textGray};
  }

  .fa-question-circle {
    cursor: pointer;
    color: ${(props) => props.theme.color.help};

    &:hover {
      color: ${(props) => darken(0.1, props.theme.color.help)};
    }
  }

  .Label {
    position: absolute;
    left: 19px;
    top: ${(props) => (props.focused ? '6px' : '17px')};
    font-size: ${(props) => (props.focused ? '14px' : '16px')};
    color: ${(props) => (props.hasError ? lighten(0.15, props.theme.color.red) : props.theme.color.textGray)};
    transition: top 200ms, font-size 200ms, font-weight 200ms;
  }

  .RightIconContainer {
    position: absolute;
    right: 15px;
    top: 17px;

    i {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .Main {
      font-size: 20px;
      margin-left: 15px;
    }

    .ErrorIcon {
      color: ${(props) => lighten(0.15, props.theme.color.red)};
    }
    .DisabledIcon {
      color: ${(props) => lighten(0.15, props.theme.color.textGray)};
    }
  }

  &:hover {
    .fa-folder-open {
      color: ${(props) => props.theme.color.text};
    }
    .fa-trash-can {
      color: ${(props) => props.theme.color.red};
    }
  }
`;

/**
 * File()
 * If directlyUploadToTemp ist `true` only the event `onPathChange` will be triggered with the uri to the uploaded file.
 * Otherwise, `onFileChange` will be triggered with the selected file
 * @param props
 * @constructor
 */
export default function File(props: SelectProps) {
  const { showSkeleton, onChange, displayFileName, required, label, allowedTypes, disabled, loading } = props;
  const [t] = useTranslation();
  const [selectedFile, setSelectedFile] = React.useState<File>(null);
  const [contextHelpElement, setContextName] = useHelpContext();
  const [showTypeError, setShowTypeError] = React.useState<string>(null);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const fileRef = React.useRef<HTMLInputElement>();

  const isFileTypeAllowed = React.useCallback(
    (file: File) => {
      if (allowedTypes && allowedTypes.length > 0) {
        const extension = file.name.split('.').pop().toLowerCase();
        return allowedTypes.indexOf(extension) > -1;
      }
      return true;
    },
    [allowedTypes],
  );

  React.useEffect(() => {
    if (selectedFile) {
      if (isFileTypeAllowed(selectedFile)) {
        onChange(selectedFile);
      } else {
        setSelectedFile(null);
        setShowTypeError(selectedFile.name.split('.').pop().toLowerCase());
      }
    }
  }, [isFileTypeAllowed, selectedFile]);

  const handleDeleteFile = () => {
    setAskDelete(false);
    setSelectedFile(null);
    onChange(null);
  };

  const renderLabel = () => {
    const mappedLabel = `${label}${required ? '*' : ''}`;
    return <div className="Label">{mappedLabel}</div>;
  };

  const renderErrorIcon = () => (props.hasError ? <i className="fas fa-exclamation-circle ErrorIcon" /> : null);
  const renderDisabledIcon = () => (disabled ? <i className="fas fa-lock DisabledIcon" /> : null);
  const renderSkeleton = () => (showSkeleton ? <CustomSkeleton className="skeleton" /> : null);

  const renderContextHelp = () => {
    if (props.contextHelpKey) {
      return (
        <i className="fas fa-question-circle" onClick={() => setContextName(props.contextHelpKey)} title={t('help')} />
      );
    }
    return null;
  };

  const renderUploadIcon = () =>
    loading ? (
      <i className="fas fa-spin fa-circle-notch Main" />
    ) : !selectedFile ? (
      <i className="far fa-folder-open Main" />
    ) : (
      <i className="far fa-trash-can Main" />
    );

  const renderRightIcons = () => {
    const errorIcon = renderErrorIcon();
    const disabledIcon = renderDisabledIcon();
    const contextHelp = renderContextHelp();

    return (
      <div className="RightIconContainer">
        {errorIcon}
        {disabledIcon}
        {contextHelp}

        {renderUploadIcon()}
      </div>
    );
  };

  const renderValue = () => {
    if (displayFileName) {
      return <div className="Value">{displayFileName}</div>;
    }
    if (selectedFile) {
      return <div className="Value">{`${selectedFile.name} (${formatSize(selectedFile.size)})`}</div>;
    }
    return null;
  };

  const handleFireClickEvent = () => {
    if (!loading) {
      if (!disabled && !selectedFile) {
        fileRef.current.click();
      } else {
        setAskDelete(true);
      }
    }
  };

  const renderContent = () => {
    if (!showSkeleton) {
      return (
        <div className="TextContainer" onClick={handleFireClickEvent}>
          {renderLabel()}
          {renderValue()}
          {renderRightIcons()}
          {contextHelpElement}

          <input
            type="file"
            style={{ display: 'none' }}
            ref={fileRef}
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
        </div>
      );
    }
    return null;
  };

  const renderErrorContainer = () => {
    if (allowedTypes !== undefined && allowedTypes.length > 0) {
      return (
        <ErrorMessage
          headline={t('errorMessages.invalidFileExtension.headline')}
          description={t('errorMessages.invalidFileExtension.description', {
            extension: showTypeError,
            extensions: allowedTypes.join(', '),
          })}
          onClose={() => setShowTypeError(null)}
          visible={!!showTypeError}
        />
      );
    }
    return null;
  };

  if (props.visible) {
    return (
      <Container focused={!!selectedFile || !!displayFileName} hasError={props.hasError}>
        {renderContent()}
        {renderSkeleton()}
        {renderErrorContainer()}

        <AskDeleteModal
          headline={t('errorMessages.deleteSelectedFile.headline')}
          description={t('errorMessages.deleteSelectedFile.description', {
            fileName: selectedFile ? selectedFile.name : '',
          })}
          onDelete={handleDeleteFile}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
        />
      </Container>
    );
  }
  return null;
}

File.defaultProps = {
  visible: true,
  value: '',
  required: false,
  hasError: false,
  errorMessage: null,
  disabled: false,
  contextHelpKey: null,
  showSkeleton: false,
  loading: false,
};
