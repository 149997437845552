import React from 'react';
import styled from 'styled-components';
import { OfferPositionEntity } from '../../../Globals/Types/OfferTypes';
import DisplayText from '../../View/DisplayText';
import { formatCurrency } from '../../../Globals/Functions';
import Text from '../../Text';

const RowContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 15px;

  .Left {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .Right {
    display: flex;
    margin-left: 50px;
  }
`;

interface Props {
  positions: OfferPositionEntity[];
}

/**
 * PositionRows()
 * @param props
 * @constructor
 */
export default function PositionRows(props: Props) {
  const { positions } = props;

  const renderPositions = () =>
    positions.map((position) => (
      <DisplayText key={position.caption}>
        <RowContainer>
          <div className="Left">
            <Text>{`${position.amount}x ${position.caption}`}</Text>
            <Text small light>
              {position.description}
            </Text>
          </div>
          <div className="Right">{formatCurrency(position.overallPrice)}</div>
        </RowContainer>
      </DisplayText>
    ));

  return <>{renderPositions()}</>;
}
