export enum Directions {
  vertical,
  horizontal,
}

export enum DeliveryAndLoadingOptions {
  deliveryIsCustomer = 'deliveryIsCustomer',
  deliveryIsCustomerAndLoading = 'deliveryIsCustomerAndLoading',
  loadingIsCustomerAndDelivery = 'loadingIsCustomerAndDelivery',
  loadingAndDelivery = 'loadingAndDelivery',
}

export enum Environment {
  production = 'production',
  staging = 'staging',
  dev = 'dev',
}

export enum FacilityDocumentTypes {
  logo = 'logo',
  insurance = 'insurance',
  electricCertificate = 'electricCertificate',
  waterCertificate = 'waterCertificate',
  certificateOfRegistration = 'certificateOfRegistration',
  tradeLicense = 'tradeLicense',
  others = 'others',
}

export enum SystemMessageTemplate {
  customerNoteNotification = 'customerNoteNotification',
  partnerRequest = 'partnerRequest',
  timeTrackingTemporary = 'timeTrackingTemporary',
  newTaskAssigned = 'newTaskAssigned',
  servicePartnerAssigned = 'servicePartnerAssigned',
  servicePartnerDelete = 'servicePartnerDelete',
}

export enum AbsenceType {
  holiday = 'holiday',
  disease = 'disease',
  other = 'other',
}

export enum TimeTrackingType {
  start = 'start',
  pause = 'pause',
  stop = 'stop',
  startTour = 'startTour',
  pauseTour = 'pauseTour',
  trafficJamTour = 'trafficJamTour',
  stopTour = 'stopTour',
  startOrder = 'startOrder',
  pauseOrder = 'pauseOrder',
  stopOrder = 'stopOrder',
  startDelivery = 'startDelivery',
  pauseDelivery = 'pauseDelivery',
  stopDelivery = 'stopDelivery',
}

export enum DeviceType {
  app = 'app',
  web = 'web',
}

export enum TaskPriority {
  highest = 'highest',
  higher = 'higher',
  high = 'high',
  normal = 'normal',
  low = 'low',
  lower = 'lower',
}

export enum TaskLinkType {
  order = 'order',
}

export enum Salutation {
  male = 'male',
  female = 'female',
  company = 'company',
}

export enum SubscriptionType {
  'demo' = 'demo',
  'manual' = 'manual',
  'regular' = 'regular',
}

export enum SubscriptionPeriod {
  'month' = 'month',
  'year' = 'year',
}

export enum Parking {
  noInfo = 0,
  ok = 1,
  smallVehicle = 2,
  stoppingZone = 3,
}

export enum CountryCode {
  DE = 'de-DE',
  UK = 'en-US',
  ES = 'es-ES',
  NL = 'nl-NL',
}

export const CalendarColors: Array<string> = [
  '#f2a361',
  '#a71b19',
  '#81cd76',
  '#7acbb6',
  '#843d5f',
  '#f8ef6a',
  '#9a86d4',
  '#7ba1e2',
  '#dc908f',
  '#edcc6e',
];

export enum ContentTypeToIconMapping {
  'application/pdf' = 'fas fa-file-pdf',
  'image/jpeg' = 'fas fa-file-image',
  'image/jpg' = 'fas fa-file-image',
  'image/png' = 'fas fa-file-image',
}

export enum ContentType {
  jpeg = 'image/jpeg',
  jpg = 'image/jpg',
  pdf = 'application/pdf',
  png = 'image/png',
}

export enum RecurringType {
  daily = 'daily',
  dailyWork = 'dailyWork',
  weekly = 'weekly',
  monthly = 'monthly',
}

export enum PaymentType {
  none = 'none',
  cash = 'cash',
  debit = 'debit',
  credit = 'credit',
  eccash = 'eccash',
}

export enum ReportPositionState {
  checked = 'checked',
  partial = 'partial',
  unChecked = 'unChecked',
}

export enum ResourceEntityTypeIcon {
  machine = 'fas fa-screwdriver-wrench',
  riser = 'fas fa-elevator',
  trailer = 'fas fa-trailer',
  vehicle = 'fas fa-car',
}

export enum NoteType {
  note = 'NO',
  mailIncome = 'MI',
  mailOutgoing = 'MO',
  offer = 'OF',
  phone = 'PH',
}

export enum ProductType {
  service = 'service',
  article = 'article',
}

export enum UserLoginPermission {
  web = 'web',
  app = 'app',
  both = 'both',
}
