import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { PaymentType } from '../../Globals/Types/Enums';

interface Props {
  onChange: (item: PaymentType) => void;
  selected?: string;
  required?: boolean;
  disabled?: boolean;
  showSkeleton: boolean;
  hideDebit: boolean;
  caption?: string;
}

/**
 * SelectPaymentType
 * @param props
 * @constructor
 */
export default function SelectPaymentType(props: Props) {
  const { onChange, selected, required, disabled, showSkeleton, hideDebit, caption } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>([]);

  React.useEffect(() => {
    const optionArray = Object.keys(PaymentType)
      .filter((item) => item !== PaymentType.none)
      .filter((item) => !(hideDebit && item === PaymentType.debit))
      .map((item) => ({
        value: PaymentType[item],
        label: t(`paymentTypeOptions.${PaymentType[item]}`),
      }));

    setOptions(optionArray);
  }, [hideDebit, t]);

  const handleOnChange = (value: string) => {
    onChange(value as PaymentType);
  };

  if (options && options.length > 0) {
    return (
      <Select
        label={caption || t('paymentType')}
        onChange={handleOnChange}
        initialValue={selected}
        options={options}
        required={required}
        disabled={disabled}
        showSkeleton={showSkeleton}
      />
    );
  }
  return null;
}

SelectPaymentType.defaultProps = {
  required: false,
  disabled: false,
  showSkeleton: false,
  hideDebit: false,
};
