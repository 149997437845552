import React from 'react';
import Panel from '../Panel';
import Input from '../Inputs/Input';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BankEntity } from '../../Globals/Types/Types';

type BankFormProps = {
  data: BankEntity;
  onChange: Function;
  disabled: boolean;
  errors: BankEntity;
  withPanel: boolean;
  showSkeleton: boolean;
};

/**
 * BankForm()
 * @constructor
 */
export default function BankForm(props: BankFormProps) {
  const { data, onChange, showSkeleton } = props;
  const [t] = useTranslation();

  const renderContent = () => {
    return (
      <>
        <Row>
          <Col xl={6}>
            <Input
              label={t('bankOwner')}
              onChange={(value) => onChange({ key: 'owner', value })}
              value={data.owner}
              disabled={props.disabled}
              hasError={!!props.errors.owner}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('bankIban')}
              onChange={(value) => onChange({ key: 'iban', value })}
              value={data.iban}
              disabled={props.disabled}
              hasError={!!props.errors.iban}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('bankName')}
              onChange={(value) => onChange({ key: 'name', value })}
              value={data.name}
              disabled={props.disabled}
              hasError={!!props.errors.name}
              showSkeleton={showSkeleton}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('bankBic')}
              onChange={(value) => onChange({ key: 'bic', value })}
              value={data.bic}
              disabled={props.disabled}
              hasError={!!props.errors.bic}
              showSkeleton={showSkeleton}
            />
          </Col>
        </Row>
      </>
    );
  };

  if (props.withPanel) {
    return (
      <Panel headline={t('bankInformation')} description={t('modules.settings.bankDescription')}>
        {renderContent()}
      </Panel>
    );
  } else {
    return renderContent();
  }
}

BankForm.defaultProps = {
  errors: {},
  withPanel: true,
  showSkeleton: false,
};
