import React from 'react';
import styled from 'styled-components';

type BottomPanelProps = {
  caption: string;
  onDelete: Function;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 0 6px 6px 0;
  padding: 15px;
  background-color: ${(props) => props.theme.color.backgroundGray};

  .Left {
    flex-grow: 1;
  }

  .Right {
    i {
      color: ${(props) => props.theme.color.textGray};
    }

    &:hover {
      i {
        color: ${(props) => props.theme.color.red};
      }
    }
  }
`;

/**
 * Document()
 * @param props
 * @constructor
 */
export default function BottomPanel(props: BottomPanelProps) {
  const handleOnDelete = (e) => {
    e.stopPropagation();
    props.onDelete();
  };

  return (
    <Container>
      <div className="Left">{props.caption}</div>
      <div className="Right" onClick={handleOnDelete}>
        <i className="fas fa-trash" />
      </div>
    </Container>
  );
}

BottomPanel.defaultProps = {};
