import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import SubNavigationHeadline from './SubNavigationHeadline';
import SubNavigationItem from './SubNavigationItem';
import { formatDateString } from '../../../Globals/Functions';
import {
  getServiceIconColorByReportState,
  useGetServiceIconByReportState,
} from '../../../Globals/Functions/ServiceFunctions';
import { ServiceEntity } from '../../../Globals/Types/OrderTypes';
import { Navigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatchOrderGet, useDispatchOrderHasAccess } from '../../../Redux/Actions/OrderAction';
import { useDispatchOrderMessageGetList } from '../../../Redux/Actions/Order/MessageAction';
import { useDispatchClientGetAllPartners } from '../../../Redux/Actions/ClientAction';
import { useDispatchOrderServiceDocumentGetList } from '../../../Redux/Actions/Order/Service/DocumentAction';
import { useDispatchUserTaskGetList } from '../../../Redux/Actions/User/TaskAction';
import { useDispatchTourGetForOrder } from '../../../Redux/Actions/Disposition/TourActions';
import CreateServiceModal from '../../Order/Details/CreateServiceModal';
import { buildUrlOrderServiceDetails, UrlPatterns } from '../../../Globals/UrlFunctions';
import Spacer from './Spacer';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

/**
 * OrderSubNavigation()
 * @constructor
 */
export default function OrderSubNavigation() {
  const [t] = useTranslation();
  const theme = useTheme();
  const { allowEditAll, allowAddService } = useAppSelector((state) => state.order.selectedOrder.permissions);
  const { dispositions } = useAppSelector((state) => state.order.selectedOrder);
  const { clientId } = useAppSelector((state) => state.auth);
  const { enableTimeTracking } = useAppSelector((state) => state.auth.client.settings.order);
  const [canAddService, setCanAddService] = React.useState<boolean>(allowEditAll || allowAddService);
  const getServiceIconByReportState = useGetServiceIconByReportState();
  const { orderId, clientId: orderClientId } = useParams();
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const { order } = useAppSelector((state) => state.order.selectedOrder);
  const [showCreateService, setShowCreateService] = React.useState<boolean>(false);
  const { setLoading, loadingModal } = useLoadingModal({ headline: t('loadingOrder') });
  const dispatchGet = useDispatchOrderGet();
  const dispatchGetMessages = useDispatchOrderMessageGetList();
  const dispatchLoadAllPartners = useDispatchClientGetAllPartners();
  const dispatchGetDocuments = useDispatchOrderServiceDocumentGetList();
  const dispatchTasksGetList = useDispatchUserTaskGetList();
  const dispatchTourGetForOrder = useDispatchTourGetForOrder();
  const hasAccess = useDispatchOrderHasAccess();

  React.useEffect(() => {
    if (orderId) {
      setLoading(true);

      dispatchGet(orderClientId, orderId)
        .then((orderResponse) => {
          return dispatchTourGetForOrder(orderResponse.ownerClientId, orderId).then(() => {
            return dispatchGetMessages(orderResponse).then(() => {
              return dispatchLoadAllPartners().then(() => {
                return dispatchGetDocuments(orderResponse).then(() => {
                  return dispatchTasksGetList().then(() => {});
                });
              });
            });
          });
        })
        .finally(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [
    dispatchGet,
    dispatchGetDocuments,
    dispatchGetMessages,
    dispatchLoadAllPartners,
    dispatchTasksGetList,
    dispatchTourGetForOrder,
    orderClientId,
    orderId,
    setLoading,
  ]);

  const getSubCaption = React.useCallback(
    (service: ServiceEntity) => {
      if (dispositions && order) {
        if (
          (order.ownerClientId === clientId && !service.assignedPartnerClientId) ||
          service.assignedPartnerClientId === clientId
        ) {
          const disposition = dispositions.find((item) => item.serviceId === service.serviceId);
          if (disposition) {
            return formatDateString(disposition.date);
          }
          return `${t('noDisposition')}...`;
        } else {
          return <i>{t('dispositionByPartner')}</i>;
        }
      }
    },
    [clientId, dispositions, order, t],
  );

  React.useEffect(() => {
    setCanAddService(allowEditAll || allowAddService);
  }, [allowAddService, allowEditAll]);

  const renderServices = () => {
    let filteredServices = [...order.services];
    if (order.ownerClientId !== clientId && order.createdClientId !== clientId) {
      filteredServices = order.services.filter((service) => service.assignedPartnerClientId === clientId);
    }

    return filteredServices.map((service) => {
      return (
        <SubNavigationItem
          key={service.serviceId}
          icon={getServiceIconByReportState(service)}
          caption={service.type.caption}
          path={buildUrlOrderServiceDetails(order.ownerClientId, order.orderId, service.serviceId)}
          iconColor={getServiceIconColorByReportState(service, theme)}
          subCaption={getSubCaption(service)}
        />
      );
    });
  };

  const renderAddService = () => {
    if (canAddService) {
      return (
        <>
          <SubNavigationItem
            icon="far fa-plus-circle"
            caption={t('createService')}
            onPress={() => setShowCreateService(true)}
          />
        </>
      );
    }
    return null;
  };

  const renderTimeTracking = () => {
    if (enableTimeTracking) {
      return <SubNavigationItem icon="far fa-stopwatch" caption={t('timeTracking')} path="timetracking" />;
    }
    return null;
  };

  const renderMessages = () => {
    return <SubNavigationItem icon="far fa-envelope" caption={t('modules.order.messages')} path="messages" />;
  };

  const renderTasks = () => {
    return <SubNavigationItem icon="far fa-list" caption={t('tasks')} path="tasks" />;
  };

  if (isLoggedIn) {
    if (order && hasAccess) {
      return (
        <Container>
          <SubNavigationHeadline headline={t('order')} />

          <SubNavigationItem icon="far fa-info-circle" caption={t('overview')} path="details" />
          {renderMessages()}
          {renderTasks()}
          {renderTimeTracking()}

          <Spacer />
          {renderServices()}
          <Spacer />

          {renderAddService()}
          <SubNavigationItem
            icon="far fa-angle-double-left"
            caption={t('orderOverview')}
            path={UrlPatterns.orderList}
          />

          <CreateServiceModal order={order} visible={showCreateService} onClose={() => setShowCreateService(false)} />
          {loadingModal}
        </Container>
      );
    }
    if (!hasAccess) {
      return <Navigate replace to={UrlPatterns.orderList} />;
    }
    return null;
  }
  return <Navigate replace to={'/public/login'} />;
}
