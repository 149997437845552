import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { darken, lighten } from 'polished';
import styled from 'styled-components';
import moment from 'moment';
import Lodash from 'lodash';
import { useAppSelector, useHelpContext, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import RecurringForm from '../../../Components/RecurringForm/RecurringForm';
import {
  useDispatchTourCreate,
  useDispatchTourCreateFromTemplate,
} from '../../../Redux/Actions/Disposition/TourActions';
import SelectTourTemplate from '../../../Components/PredefinedSelects/SelectTourTemplate';
import CustomSwitch from '../../../Components/CustomSwitch';
import Input, { InputType } from '../../../Components/Inputs/Input';
import { FacilityEntity } from '../../../Globals/Types/Types';
import { Tour } from '../../../Globals/Types/Tour';
import validate from './ValidateCreate';
import Panel from '../../../Components/Panel';
import FacilitiesForm from '../../../Components/FacilitiesForm';
import { updateFacilityIdsArray } from '../../../Globals/Functions';
import DatePicker from '../../../Components/Inputs/DatePicker';
import Select, { SelectOptions } from '../../../Components/Inputs/Select';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { DispositionValidationError } from '../ValidateDragDrop';
import { HeyKitchenError } from '../../../Globals/HeyKitchenError';

const Container = styled.div`
  .Descriptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;

    &.Centered {
      justify-content: center;
      text-align: center;
    }
  }

  .ErrorIcon {
    color: ${(props) => lighten(0.15, props.theme.color.red)};
    margin-left: 8px;
  }

  .Tab {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
  }

  .Title {
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      margin-right: 8px;
    }
  }

  .fa-question-circle {
    cursor: pointer;
    color: ${(props) => props.theme.color.help};

    &:hover {
      color: ${(props) => darken(0.1, props.theme.color.help)};
    }
  }
`;

interface Errors extends Tour {
  dates: string;
  tourTemplateIds: string;
}

export type ValidateErrors = { [K in keyof Errors]?: string };

export type State = {
  dates: string[];
  errors: ValidateErrors;
  fromTemplate: boolean;
  recurring: boolean;
  tour: Tour;
  tourTemplateIds: string[];
};

type Action =
  | { type: 'clear' }
  | { type: 'comment'; payload: string }
  | { type: 'dates'; payload: string[] }
  | { type: 'errors'; payload: ValidateErrors }
  | { type: 'fromTemplate'; payload: boolean }
  | { type: 'recurring'; payload: boolean }
  | { type: 'tourTemplateIds'; payload: string[] }
  | { type: 'name'; payload: string }
  | { type: 'description'; payload: string }
  | { type: 'facilityIds'; payload: string[] };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'clear':
      return { ...initialState };
    case 'comment':
      return { ...state, tour: { ...state.tour, comment: action.payload }, errors: { ...state.errors, comment: null } };
    case 'dates':
      return {
        ...state,
        dates: action.payload,
        errors: { ...state.errors, dates: action.payload.length === 0 ? state.errors?.dates : null },
      };
    case 'errors':
      return { ...state, errors: action.payload };
    case 'fromTemplate':
      return { ...state, fromTemplate: action.payload };
    case 'recurring':
      return { ...state, recurring: action.payload };
    case 'tourTemplateIds':
      return {
        ...state,
        tourTemplateIds: action.payload,
        errors: {
          ...state.errors,
          tourTemplateIds: action.payload?.length === 0 ? state.errors?.tourTemplateIds : null,
        },
      };
    case 'name':
      return { ...state, tour: { ...state.tour, name: action.payload }, errors: { ...state.errors, name: null } };
    case 'facilityIds':
      return {
        ...state,
        tour: { ...state.tour, facilityIds: action.payload },
        errors: { ...state.errors, facilityIds: null },
      };
  }
};

const initialState: State = {
  dates: null,
  errors: null,
  fromTemplate: false,
  recurring: false,
  tour: {
    active: true,
    closed: false,
    facilityIds: null,
    name: undefined,
    serviceIds: [],
  },
  tourTemplateIds: null,
};

interface Props {
  initialDate: string;
  onClose: (date?: string) => void;
  onError: (error: DispositionValidationError) => void;
  visible: boolean;
}

/**
 * CreateTourModal
 * @param props
 * @constructor
 */
export default function CreateTourModal(props: Props) {
  const { initialDate, onClose, onError, visible } = props;
  const { user } = useAppSelector((state) => state.auth);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [t] = useTranslation();
  const { isLoading, loadingModal, setLoading } = useLoadingModal({
    headline: t('modules.disposition.create.loading'),
  });
  const [tourOptions] = React.useState<SelectOptions>([
    { label: t('modules.disposition.create.tourManual'), value: 'manual' },
    { label: t('modules.disposition.create.tourTemplate'), value: 'template' },
  ]);
  const [contextHelpElement, setContextName] = useHelpContext();

  const dispatchTourCreate = useDispatchTourCreate();
  const dispatchTourCreateFromTemplate = useDispatchTourCreateFromTemplate();

  React.useEffect(() => {
    dispatch({ type: 'dates', payload: [initialDate] });
  }, [initialDate]);

  React.useEffect(() => {
    if (!state.tour.facilityIds && user.facilityId) {
      dispatch({ type: 'facilityIds', payload: [user.facilityId] });
    }
  }, [user.facilityId, state.tour.facilityIds]);

  const handleClose = (created: boolean) => {
    let newDate: string = undefined;
    if (created) {
      if (state.recurring) {
        if (Lodash.find(state.dates, (date) => date === initialDate)) {
          newDate = initialDate;
        }
      } else {
        newDate = state.dates[0];
      }
    }
    dispatch({ type: 'clear' });
    setTimeout(() => dispatch({ type: 'dates', payload: [newDate || initialDate] }), 200);

    onClose(newDate);
    setLoading(false);
  };

  const handleSave = () => {
    const { errors, isValid } = validate(state);
    if (!isValid) {
      dispatch({ type: 'errors', payload: errors });
      return;
    }

    let promise: Promise<Tour[]>;
    if (state.fromTemplate) {
      promise = dispatchTourCreateFromTemplate(state.tourTemplateIds, state.dates);
    } else {
      promise = dispatchTourCreate(state.tour, state.dates);
    }

    setLoading(true);
    promise
      .then(() => handleClose(true))
      .catch((error) => {
        setLoading(false);

        if (error instanceof HeyKitchenError) {
          onError({ code: error.message as InternalErrorCodes, details: error.details });
        }
      });
  };

  const onChangeFacility = (value: boolean, facility: FacilityEntity) => {
    dispatch({
      type: 'facilityIds',
      payload: updateFacilityIdsArray(value, facility, state.tour.facilityIds),
    });
  };

  const onChangeRecurring = (value) => {
    dispatch({ type: 'recurring', payload: value });

    if (value) {
      dispatch({ type: 'dates', payload: [] });
    } else {
      dispatch({ type: 'dates', payload: [initialDate] });
    }
  };

  const onChangeTourTemplate = (tourTemplateIds: string[]) => {
    dispatch({ type: 'tourTemplateIds', payload: tourTemplateIds });
  };

  const renderDates = () => {
    if (state.recurring) {
      return (
        <Panel
          headline={t('timeFrame')}
          description={t('modules.disposition.create.descriptionDates')}
          customRightAction={renderDatesHelpIcon()}
        >
          <RecurringForm
            onChange={(value) => dispatch({ type: 'dates', payload: value })}
            showErrors={!!state.errors?.dates}
          />
        </Panel>
      );
    }
  };

  const renderDatesHelpIcon = () => {
    return (
      <i className="fas fa-question-circle" onClick={() => setContextName('dispositionDates')} title={t('help')} />
    );
  };

  const renderSingleDate = () => {
    if (!state.recurring) {
      return (
        <DatePicker
          onChange={(value) => dispatch({ type: 'dates', payload: [value] })}
          label={t('date')}
          initialValue={moment(initialDate).toDate()}
          hasError={!!state.errors?.dates}
        />
      );
    }
    return null;
  };

  const renderTours = () => {
    if (state.fromTemplate) {
      return (
        <SelectTourTemplate
          label={t('modules.disposition.create.descriptionTour')}
          initialValue={state.tourTemplateIds}
          onChange={onChangeTourTemplate}
          hasError={!!state.errors?.tourTemplateIds}
          multiSelect
        />
      );
    }

    return (
      <>
        <Input
          label={t('name')}
          value={state.tour.name}
          onChange={(value) => dispatch({ type: 'name', payload: value })}
          hasError={!!state.errors?.name}
          disabled={isLoading}
          required
          autoFocus
        />
        <Input
          label={t('description')}
          value={state.tour.comment}
          onChange={(value) => dispatch({ type: 'comment', payload: value })}
          hasError={!!state.errors?.comment}
          disabled={isLoading}
          type={InputType.textarea}
          rows={5}
        />
      </>
    );
  };

  return (
    <Modal show={visible} size="xl">
      <Container>
        <Modal.Header>
          <div>
            <Modal.Title>
              <div className="Title">
                <i className="fas fa-road" />
                <div>{t('modules.disposition.create.headline')}</div>
              </div>
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: 30, paddingBottom: 10 }}>
          <Panel headline={t('basicData')} description={t('modules.disposition.create.description')}>
            <Select
              label={t('type')}
              initialValue={'manual'}
              onChange={(value: string) => dispatch({ type: 'fromTemplate', payload: value !== 'manual' })}
              options={tourOptions}
            />
            {renderTours()}
            <CustomSwitch
              caption={t('modules.disposition.create.recurring')}
              onChange={onChangeRecurring}
              checked={state.recurring}
              disabled={isLoading}
            />
            {renderSingleDate()}
          </Panel>

          {renderDates()}

          <ShowHideContainer visible={!state.fromTemplate}>
            <FacilitiesForm
              userDefaultFacility={null}
              onChange={onChangeFacility}
              description={t('modules.disposition.create.descriptionFacilities')}
              userFacilities={state.tour.facilityIds}
              hasError={!!state.errors?.facilityIds}
            />
          </ShowHideContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSave} disabled={isLoading}>
            {t('save')}
          </Button>
          <Button variant="outline-secondary" onClick={() => handleClose(false)} disabled={isLoading}>
            {t('cancel')}
          </Button>
        </Modal.Footer>

        {loadingModal}
        {contextHelpElement}
      </Container>
    </Modal>
  );
}
