import { useDispatch } from 'react-redux';
import React from 'react';
import { OrderEntity, ServiceEntity } from '../../../../Globals/Types/OrderTypes';
import {
  ORDER_SERVICE_TIMETRACKING_GETLIST_ERROR,
  ORDER_SERVICE_TIMETRACKING_GETLIST_START,
  ORDER_SERVICE_TIMETRACKING_GETLIST_SUCCESS,
} from '../../../ActionTypes';
import { TimeTrackingEntity } from '../../../../Globals/Types/Types';
import { collection, CollectionReference, getDocs, getFirestore } from 'firebase/firestore';
import { FirebasePathMappings } from '../../../../Globals/FirebaseGlobals';
import moment from 'moment/moment';

/**
 * useDispatchOrderServiceDocumentGetList
 */
type GetListReturn = (order: OrderEntity, service: ServiceEntity) => Promise<TimeTrackingEntity[]>;
export const useDispatchOrderServiceTimeTrackingGetList = (): GetListReturn => {
  const dispatch = useDispatch();

  return React.useCallback<GetListReturn>(
    (order, service) => {
      dispatch({ type: ORDER_SERVICE_TIMETRACKING_GETLIST_START, payload: { order, service } });

      const collectionRef = collection(
        getFirestore(),
        FirebasePathMappings.client,
        order.ownerClientId,
        FirebasePathMappings.order,
        order.orderId,
        FirebasePathMappings.service,
        service.serviceId,
        FirebasePathMappings.timeTracking,
      ) as CollectionReference<TimeTrackingEntity>;

      return getDocs(collectionRef)
        .then((response) => {
          if (!response.empty) {
            const result: TimeTrackingEntity[] = [];
            response.forEach((item) => {
              result.push({ ...item.data(), id: item.id });
            });

            const sorted = result.sort((a, b) =>
              moment(a.timestamp.toDate()).isSameOrAfter(moment(b.timestamp.toDate())) ? 1 : -1,
            );

            dispatch({ type: ORDER_SERVICE_TIMETRACKING_GETLIST_SUCCESS, payload: sorted });
            return Promise.resolve(sorted);
          }
        })
        .catch((error) => {
          dispatch({ type: ORDER_SERVICE_TIMETRACKING_GETLIST_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [dispatch],
  );
};
