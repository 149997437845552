import React from 'react';
import { useTranslation } from 'react-i18next';
import { OfferEntity } from '../../../../Globals/Types/OfferTypes';
import DropDownItem from '../../../CustomDropDown/DropDownItem';
import { useNavigate } from 'react-router';
import AskDeleteModal from '../../../Modals/AskDeleteModal';
import { useDispatchOfferDelete } from '../../../../Redux/Actions/Client/OffersAction';
import { useDispatchCustomerOffersGetList } from '../../../../Redux/Actions/Customer/OffersAction';

interface Props {
  offer: OfferEntity;
}

/**
 * OfferDeleteButton()
 * @param props
 * @constructor
 */
export default function OfferDeleteButton(props: Props) {
  const { offer } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const dispatchDelete = useDispatchOfferDelete();
  const dispatchGetList = useDispatchCustomerOffersGetList();

  const handleDelete = () => {
    dispatchDelete(offer)
      .then(() => dispatchGetList(offer.customerId))
      .finally(() => navigate(-1));
  };

  const renderButton = () => {
    return (
      <>
        <DropDownItem onClick={() => setAskDelete(true)} key={'createOrder'}>
          <i className="far fa-trash-alt" />
          {t('delete')}
        </DropDownItem>

        <AskDeleteModal
          onDelete={handleDelete}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
          headline={t('alerts.offerDelete.headline')}
          description={t('alerts.offerDelete.description')}
        />
      </>
    );
  };

  return <>{renderButton()}</>;
}
