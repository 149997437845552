/* eslint-disable  react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';
import { TourItem, TourService } from '../../../Globals/Types/Tour';
import { useGetServiceTypeIcon } from '../../../Globals/Hooks/ServiceHooks';
import { buildUrlOrderServiceDetails } from '../../../Globals/UrlFunctions';
import { ReportState } from '../../../Globals/Types/Report';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 60px;
  padding: 0 10px 0 20px;
  background-color: ${(props) => props.theme.color.primaryColor};
  border-bottom: 1px solid ${(props) => props.theme.table.borderColor};
  border-top-left-radius: ${(props) => props.theme.border.radius};
  border-top-right-radius: ${(props) => props.theme.border.radius};
  color: #ffffff;
  font-weight: bold;

  > div {
    flex-grow: 1;

    > i {
      margin-right: 10px;
    }
  }

  > i {
    padding: 10px;
    cursor: pointer;

    &.fa-times {
      font-size: 18px;
    }

    &:hover {
      color: ${(props) => props.theme.color.orange};
    }

    &.fa-trash-alt {
      &:hover {
        color: ${(props) => props.theme.color.red};
      }
    }
  }
`;

interface Props {
  tourItem: TourItem<TourService>;
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
}

/**
 * Headline
 * @param props
 * @constructor
 */
export default function Headline(props: Props) {
  const { tourItem, onClose, onDelete, isLoading } = props;
  const getIcon = useGetServiceTypeIcon();

  const handleDetailsService = () => {
    window.open(
      buildUrlOrderServiceDetails(tourItem.data.ownerClientId, tourItem.data.orderId, tourItem.data.serviceId),
    );
  };

  const renderLoading = () => {
    if (isLoading) {
      return <i className="fas fa-spin fa-rotate" />;
    }
    return null;
  };

  const renderDelete = () => {
    if (tourItem.data.reportState === ReportState.none) {
      return <i className="fas fa-trash-alt" onClick={onDelete} />;
    }
    return null;
  };

  return (
    <Container>
      <div>
        <i className={getIcon(tourItem.data.serviceType)} />
        {tourItem.data.externalOrderId}
      </div>
      {renderLoading()}
      <i className="fas fa-link" onClick={handleDetailsService} />
      {renderDelete()}
      <i className="fas fa-times" onClick={onClose} />
    </Container>
  );
}
