import React from 'react';
import Panel from '../../Components/Panel';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import Input, { InputType } from '../../Components/Inputs/Input';
import { InternalErrorCodes } from '../../Globals/InternalErrorCodes';
import { useLoadingModal } from '../../Globals/Hooks/Hooks';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import Text from '../../Components/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

/**
 * ResetPassword()
 * @constructor
 */
export default function ResetPassword() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = React.useState<string>(null);
  const [password, setPassword] = React.useState<string>('');
  const [repeat, setRepeat] = React.useState<string>('');
  const [helperCounter, setHelperCounter] = React.useState<number>(10);
  const [resetPasswordSuccess, setResetPasswordSuccess] = React.useState<boolean>(false);
  const { setLoading, loadingModal } = useLoadingModal({ delayStart: 500 });
  const intervalRef = React.useRef<NodeJS.Timer>();
  const counterRef = React.useRef<number>();
  const { search } = useLocation();
  const oobCode = new URLSearchParams(search).get('oobCode');

  React.useEffect(
    () => () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    },
    [],
  );

  React.useEffect(() => {
    if (resetPasswordSuccess && counterRef.current >= 10) {
      intervalRef.current = setInterval(() => {
        counterRef.current = counterRef.current - 1;
        setHelperCounter(counterRef.current);

        if (counterRef.current <= 0) {
          clearInterval(intervalRef.current);
          navigate('/public/login');
        }
      }, 1000);
    } else {
      counterRef.current = 10;
    }
  }, [navigate, resetPasswordSuccess]);

  const handleSend = React.useCallback(() => {
    setError(null);

    if (password.length >= 8 && password === repeat) {
      setLoading(true);

      verifyPasswordResetCode(getAuth(), oobCode)
        .then(() => {
          confirmPasswordReset(getAuth(), oobCode, password)
            .then(() => {
              setResetPasswordSuccess(true);
            })
            .catch(() => {
              setError(InternalErrorCodes.PASSWORD_TO_SHORT);
            })
            .finally(() => setLoading(false));
        })
        .catch(() => {
          setError(InternalErrorCodes.FIRESTORE_RESET_TOKEN_INVALID);
        })
        .finally(() => setLoading(false));
    } else {
      if (password.length < 8) {
        setError(InternalErrorCodes.PASSWORD_TO_SHORT);
      } else if (password !== repeat) {
        setError(InternalErrorCodes.PASSWORD_NO_MATCH);
      }
    }
  }, [oobCode, password, repeat, setLoading]);

  const renderContent = () => {
    if (!resetPasswordSuccess) {
      return (
        <>
          <Input type={InputType.password} onChange={setPassword} value={password} label={t('password')} autoFocus />
          <Input type={InputType.password} onChange={setRepeat} value={repeat} label={t('passwordRepeat')} />
        </>
      );
    }
    return (
      <Alert variant="success" className="text-center">
        <i className="fas fa-check-circle" style={{ fontSize: 30, marginBottom: 10 }} />
        <div>{t('modules.auth.resetPasswordSuccessful')}</div>
        <Text small containerStyle={{ marginTop: 20 }} centered>
          {t('redirectingInSeconds', { seconds: helperCounter })}
        </Text>
      </Alert>
    );
  };

  const renderError = () => {
    if (error) {
      let text: string;

      switch (error) {
        case InternalErrorCodes.PASSWORD_TO_SHORT:
          text = t('modules.auth.resetPasswordMessages.short');
          break;
        case InternalErrorCodes.PASSWORD_NO_MATCH:
          text = t('modules.auth.resetPasswordMessages.mismatch');
          break;
        case InternalErrorCodes.FIRESTORE_RESET_TOKEN_INVALID:
          text = t('modules.auth.resetPasswordMessages.tokenExpired');
          break;
      }

      return <Alert variant="danger">{text}</Alert>;
    }
    return null;
  };

  return (
    <OuterContainer>
      <div>
        <Panel
          maxWidth={800}
          headline={t('modules.auth.resetPasswordHeadline')}
          description={t('modules.auth.resetPasswordDescription')}
          smallHeadline={false}
        >
          {renderError()}

          <Row>
            <Col>{renderContent()}</Col>
          </Row>

          <hr />

          <Row>
            <Col className="d-flex align-items-center">
              <Button variant="outline-secondary" onClick={() => navigate('/public/login')} className="float-end">
                {t('backToLogin')}
              </Button>
            </Col>
            <Col>
              <Button
                variant="success"
                onClick={handleSend}
                className="float-end"
                disabled={!password || password.length <= 0 || resetPasswordSuccess}
              >
                {t('changePassword')}
              </Button>
            </Col>
          </Row>
        </Panel>
      </div>

      {loadingModal}
    </OuterContainer>
  );
}
