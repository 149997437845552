import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../Components/PageHeader';
import { Button, Col, Row } from 'react-bootstrap';
import { buildUrlCustomersUpdate, UrlPatterns } from '../../../Globals/UrlFunctions';
import { useNavigate, useParams } from 'react-router';
import { useDispatchCustomerDelete } from '../../../Redux/Actions/CustomerAction';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import Panel from '../../../Components/Panel';
import DisplayText from '../../../Components/View/DisplayText';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import {
  customerBuildName,
  customerHasAddress,
  customerHasBankData,
  customerHasContacts,
  customerHasSocialMedia,
} from '../../../Globals/Functions/CustomerFunctions';
import { formatBirthdate, formatDateTime, userDisplayName } from '../../../Globals/Functions';
import BottomInternalIdContainer from '../../../Components/BottomInternalIdContainer';
import AskDeleteModal from '../../../Components/Modals/AskDeleteModal';
import moment from 'moment';
import { useGetUser } from '../../../Globals/Functions/UserFunctions';

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const { customer } = useAppSelector((state) => state.client.customers.selectedCustomer);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { isLoading, setLoading } = useLoadingModal();
  const dispatchDelete = useDispatchCustomerDelete();
  const getUser = useGetUser();

  const handleDelete = React.useCallback(() => {
    setLoading(true);
    dispatchDelete(customerId)
      .then(() => {
        navigate(UrlPatterns.customers);
      })
      .finally(() => setLoading(false));
  }, [customerId, dispatchDelete, navigate, setLoading]);

  const renderEditButton = () => {
    return (
      <Button
        variant="outline-secondary"
        onClick={() => navigate(buildUrlCustomersUpdate(customerId))}
        style={{ border: 'transparent' }}
      >
        {t('edit')}
      </Button>
    );
  };

  const renderAddress = () => {
    if (customerHasAddress(customer)) {
      return (
        <DisplayText caption={t('address')}>
          <div>{`${customer.street} ${customer.streetNo}`}</div>
          <div>{`${customer.zip} ${customer.city}`}</div>
          <div>{t(`countryCodesToName.${customer.country}`)}</div>
          <small className="text-muted">{t('floorDescriptionWithoutElevator', { count: customer.floor })}</small>
        </DisplayText>
      );
    }
    return null;
  };

  const renderSocialMedia = () => {
    if (customerHasSocialMedia(customer)) {
      return (
        <Panel headline={t('socialMedia')}>
          <Row>
            <ShowHideContainer visible={!!customer.whatsApp}>
              <Col lg={6}>
                <DisplayText caption="WhatsApp" icon="fab fa-whatsapp">
                  {customer.whatsApp}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.facebook}>
              <Col lg={6}>
                <DisplayText caption="Facebook" icon="fab fa-facebook">
                  {customer.facebook}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.twitter}>
              <Col lg={6}>
                <DisplayText caption="Twitter" icon="fab fa-twitter">
                  {customer.twitter}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.instagram}>
              <Col lg={6}>
                <DisplayText caption="Instagram" icon="fab fa-instagram">
                  {customer.instagram}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.tikTok}>
              <Col lg={6}>
                <DisplayText caption="TikTok" icon="fab fa-tiktok">
                  {customer.tikTok}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.xing}>
              <Col lg={6}>
                <DisplayText caption="Xing" icon="fab fa-xing">
                  {customer.xing}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.linkedIn}>
              <Col lg={6}>
                <DisplayText caption="LinkedIn" icon="fab fa-linkedin">
                  {customer.linkedIn}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.skype}>
              <Col lg={6}>
                <DisplayText caption="Skype" icon="fab fa-skype">
                  {customer.skype}
                </DisplayText>
              </Col>
            </ShowHideContainer>
          </Row>
        </Panel>
      );
    }
    return null;
  };

  const renderContacts = () => {
    if (customerHasContacts(customer)) {
      return (
        <Panel headline={t('contact')}>
          <Row>
            <ShowHideContainer visible={!!customer.phonePrivate}>
              <Col lg={6}>
                <DisplayText caption={t('phonePrivate')} icon="fas fa-phone">
                  {customer.phonePrivate}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.phoneBusiness}>
              <Col lg={6}>
                <DisplayText caption={t('phoneBusiness')} icon="fas fa-phone-office">
                  {customer.phoneBusiness}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.mobilePrivate}>
              <Col lg={6}>
                <DisplayText caption={t('mobilePrivate')} icon="fad fa-mobile">
                  {customer.mobilePrivate}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.mobileBusiness}>
              <Col lg={6}>
                <DisplayText caption={t('mobileBusiness')} icon="fas fa-phone-office">
                  {customer.mobileBusiness}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.mailPrivate}>
              <Col lg={6}>
                <DisplayText caption={t('mailPrivate')} icon="fas fa-at">
                  {customer.mailPrivate}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.mailBusiness}>
              <Col lg={6}>
                <DisplayText caption={t('mailBusiness')} icon="fas fa-at">
                  {customer.mailBusiness}
                </DisplayText>
              </Col>
            </ShowHideContainer>
            <ShowHideContainer visible={!!customer.website}>
              <Col lg={6}>
                <DisplayText caption={t('website')} icon="fas fa-globe">
                  {customer.website}
                </DisplayText>
              </Col>
            </ShowHideContainer>
          </Row>
        </Panel>
      );
    }
    return null;
  };

  const renderBankData = () => {
    if (customerHasBankData(customer)) {
      return (
        <Panel headline={t('bankInformation')}>
          <Row>
            <Col lg={12}>
              <DisplayText caption={t('bankName')}>{customer.bankName}</DisplayText>
            </Col>
            <Col lg={6}>
              <DisplayText caption={t('bankIban')}>{customer.bankIban}</DisplayText>
            </Col>
            <Col lg={6}>
              <DisplayText caption={t('bankBic')}>{customer.bankBic}</DisplayText>
            </Col>
          </Row>
        </Panel>
      );
    }
    return null;
  };

  if (customer) {
    return (
      <>
        <PageHeader
          headline={t('basicData')}
          description={t('modules.customers.basicDataDescription')}
          actionButtonOne={renderEditButton()}
        />

        <Panel>
          <DisplayText caption={t('name')}>
            <strong>{customerBuildName(customer, false, t)}</strong>
          </DisplayText>
          <Row>
            <Col lg={!!customer.birthdate ? 6 : 12}>
              <DisplayText caption={t('jobTitle')} visible={!!customer.jobTitle}>
                {customer.jobTitle}
              </DisplayText>
            </Col>
            <Col lg={!!customer.jobTitle ? 6 : 12}>
              <DisplayText caption={t('birthdate')} visible={!!customer.birthdate}>
                {formatBirthdate(customer.birthdate)}
              </DisplayText>
            </Col>
            <Col lg={12}>
              <DisplayText caption={t('taxNumber')} visible={!!customer.taxNumber}>
                {customer.taxNumber}
              </DisplayText>
            </Col>
            <Col lg={12}>
              <DisplayText caption={t('contactName')} visible={!!customer.contactName}>
                {customer.contactName}
              </DisplayText>
            </Col>
          </Row>

          {renderAddress()}
        </Panel>

        {renderContacts()}
        {renderSocialMedia()}
        {renderBankData()}

        <Panel>
          <Row>
            <Col lg={6}>
              <DisplayText caption={t('created')}>
                {formatDateTime(moment(customer.createdDateTime).toDate())}
              </DisplayText>
            </Col>
            <Col lg={6}>
              <DisplayText caption={t('createdFrom')}>{userDisplayName(getUser(customer.createdUserId))}</DisplayText>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <DisplayText caption={t('lastUpdated')}>
                {formatDateTime(moment(customer.lastUpdatedDateTime).toDate())}
              </DisplayText>
            </Col>
            <Col lg={6}>
              <DisplayText caption={t('lastUpdatedFrom')}>
                {userDisplayName(getUser(customer.createdUserId))}
              </DisplayText>
            </Col>
          </Row>
        </Panel>

        <div style={{ textAlign: 'center', marginTop: 40 }}>
          <Button
            variant="outline-danger"
            onClick={() => setAskDelete(true)}
            disabled={isLoading}
            style={{ borderColor: 'transparent' }}
          >
            {t('delete')}
          </Button>
        </div>

        <BottomInternalIdContainer id={customer.customerId} />

        <AskDeleteModal
          onDelete={handleDelete}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
          headline={t('errorMessages.customerDelete.headline')}
          description={t('errorMessages.customerDelete.description')}
        />
      </>
    );
  }
  return null;
}
