import React from 'react';
import { useTranslation } from 'react-i18next';
import { OfferEntity } from '../../../../Globals/Types/OfferTypes';
import { useGetFacility } from '../../../../Globals/Hooks/FacilityHooks';
import { FacilityEntity } from '../../../../Globals/Types/Types';
import { CountryCode } from '../../../../Globals/Types/Enums';
import { useDispatchOfferCreatePdf } from '../../../../Redux/Actions/Client/OffersAction';
import { useDispatchCheckFacility } from '../../../../Redux/Actions/Client/FacilityAction';
import { downloadBase64Pdf } from '../../../../Globals/Functions';
import DropDownItem from '../../../CustomDropDown/DropDownItem';
import FacilityDataInvalid from '../../../Facility/FacilityDataInvalidMessage';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';

interface Props {
  offer: OfferEntity;
  onDownloading: (loading: boolean) => void;
}

/**
 * OfferDownloadButton()
 * @param props
 * @constructor
 */
export default function OfferDownloadButton(props: Props) {
  const [t] = useTranslation();
  const { offer, onDownloading } = props;
  const { selectedCustomer } = useAppSelector((state) => state.client.customers);
  const [showFacilityError, setShowFacilityError] = React.useState<boolean>(false);
  const [facility, setFacility] = React.useState<FacilityEntity>();
  const [languages, setLanguages] = React.useState<CountryCode[]>([]);
  const getFacility = useGetFacility();

  const dispatchDownload = useDispatchOfferCreatePdf();
  const { isFacilityValid } = useDispatchCheckFacility();

  const download = React.useCallback(
    (language: CountryCode) => {
      if (isFacilityValid(facility)) {
        onDownloading(true);

        dispatchDownload(offer.offerId, language)
          .then((response) => {
            downloadBase64Pdf(response, `${offer.offerNumber}_${language.substring(0, 2).toUpperCase()}.pdf`);

            onDownloading(false);
          })
          .catch(() => {
            onDownloading(false);
          });
      } else {
        setShowFacilityError(true);
      }
    },
    [dispatchDownload, facility, offer, isFacilityValid, onDownloading],
  );

  React.useEffect(() => {
    const found = getFacility(offer.facilityId);
    setFacility(found);
  }, [getFacility, offer]);

  React.useEffect(() => {
    if (facility) {
      if (facility.countryCode !== selectedCustomer.customer.country) {
        setLanguages([selectedCustomer.customer.country, facility.countryCode]);
      } else {
        setLanguages([facility.countryCode]);
      }
    }
  }, [facility, selectedCustomer]);

  const renderSingleLanguage = () => {
    if (languages && languages.length === 1) {
      return (
        <DropDownItem onClick={() => download(facility.countryCode)} key={facility.countryCode}>
          <i className="fas fa-cloud-arrow-down" />
          {`${t('download')} (${t(`languageCodes.${facility.countryCode}`)})`}
        </DropDownItem>
      );
    }
    return null;
  };

  const renderMultiLanguage = () => {
    if (languages && languages.length > 1) {
      return languages.map((lngCode) => (
        <DropDownItem onClick={() => download(lngCode)} key={lngCode}>
          <i className="fas fa-cloud-arrow-down" />
          {`${t('download')} (${t(`languageCodes.${lngCode}`)})`}
        </DropDownItem>
      ));
    }
    return null;
  };

  if (languages) {
    return (
      <>
        {renderSingleLanguage()}
        {renderMultiLanguage()}

        <FacilityDataInvalid visible={showFacilityError} onClose={() => setShowFacilityError(false)} />
      </>
    );
  }
  return null;
}
