import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { UserEntity } from '../../../../Globals/Types/Types';

export type UpdateClientValidationError = {
  user: UserEntity;
  groups?: string;
};

export type UpdateClientValidationResult = {
  isValid: boolean;
  errors: UpdateClientValidationError;
};

/**
 * isValid()
 * @param user
 * @param facilityCount
 */
const isValid = (user: UserEntity, facilityCount: number): UpdateClientValidationResult => {
  let errors: UpdateClientValidationError = {
    user: {} as UserEntity,
  };

  if (!user.firstName || user.firstName.length <= 0) {
    errors = { ...errors, user: { ...errors.user, firstName: InternalErrorCodes.FIELD_MISSING } };
  }

  if (!user.lastName || user.lastName.length <= 0) {
    errors = { ...errors, user: { ...errors.user, lastName: InternalErrorCodes.FIELD_MISSING } };
  }

  if (!user.facilityId || user.facilityId.length <= 0) {
    errors = { ...errors, user: { ...errors.user, facilityId: InternalErrorCodes.FIELD_MISSING } };
  }

  if (!user.initials || user.initials.length <= 0) {
    errors = { ...errors, user: { ...errors.user, initials: InternalErrorCodes.FIELD_MISSING } };
  } else {
    if (user.initials.length > 4) {
      errors = { ...errors, user: { ...errors.user, initials: InternalErrorCodes.FIELD_MISSING } };
    }
  }

  if (!user.groups || user.groups.length <= 0) {
    errors = { ...errors, groups: InternalErrorCodes.FIELD_MISSING };
  }

  if (facilityCount > 1) {
    if (!user.facilities || user.facilities.length <= 0) {
      errors = { ...errors, user: { ...errors.user, facilities: [InternalErrorCodes.FIELD_MISSING] } };
    }
  }

  return { isValid: Object.keys(errors.user).length === 0, errors };
};

export default isValid;
