import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';
import de from '../../Assets/Images/flags/de-DE.png';
import nl from '../../Assets/Images/flags/nl-NL.png';
import es from '../../Assets/Images/flags/es-ES.png';
import en from '../../Assets/Images/flags/en-US.png';
import fr from '../../Assets/Images/flags/fr-FR.png';

const Container = styled.div`
  background-color: ${(props) => lighten(0.4, props.theme.color.primaryColor)};
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 60px 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 1200px;
  justify-content: center;
  align-items: center;

  .ImageContainer {
    display: flex;
    flex-direction: row;
    flex: 1;

    > div {
      display: flex;
      flex-direction: row;
      flex: 1;
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }

      > img {
        width: 140px;
        height: 140px;
      }
    }
  }

  @media (max-width: 1350px) {
    width: 100%;

    h2 {
      text-align: center;
    }

    .ImageContainer {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;

      > div {
        margin-right: 10px;

        > img {
          width: 55px;
          height: 55px;
        }
      }
    }
  }
`;

/**
 * LanguageRow()
 * @constructor
 */
export default function LanguageRow() {
  const [t] = useTranslation();

  return (
    <Container>
      <InnerContainer>
        <h2 style={{ marginBottom: 50 }}>{t('modules.website.availableLanguages')}</h2>
        <div className="ImageContainer">
          <div>
            <img src={es} alt="ES" />
          </div>
          <div>
            <img src={en} alt="EN" />
          </div>
          <div>
            <img src={fr} alt="FR" />
          </div>
          <div>
            <img src={de} alt="DE" />
          </div>
          <div>
            <img src={nl} alt="NL" />
          </div>
        </div>
      </InnerContainer>
    </Container>
  );
}
