import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { TimeTrackingEntity, UserEntity } from '../../../Globals/Types/Types';
import moment from 'moment';
import DatePicker from '../../../Components/Inputs/DatePicker';
import Input from '../../../Components/Inputs/Input';
import {
  useDispatchClientTimeTrackingCreate,
  useDispatchClientTimeTrackingDelete,
  useDispatchClientTimeTrackingUpdate,
} from '../../../Redux/Actions/Client/TimeTrackingAction';
import { useParams } from 'react-router';
import SelectClientUsers from '../../../Components/SelectClientUsers';
import Select, { SelectOptions } from '../../../Components/Inputs/Select';
import { DeviceType, TimeTrackingType } from '../../../Globals/Types/Enums';
import { Timestamp } from 'firebase/firestore';
import Lodash from 'lodash';
import validateManual from './Components/ValidateManual';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import AskDeleteModal from '../../../Components/Modals/AskDeleteModal';

type ModalProps = {
  timeTracking?: TimeTrackingEntity | null;
  onClose: (reload: boolean) => void;
  visible: boolean;
};

export type ManualTimeTrackingError = {
  date?: string;
  time?: string;
  type?: string;
};

export type State = {
  timeTracking: TimeTrackingEntity;
  dateHelper: Date;
  timeHelper: string;
  isEdit: boolean;
  hasChanges: boolean;
  errors: ManualTimeTrackingError;
};

type Action =
  | { type: 'date'; payload: string }
  | { type: 'time'; payload: string }
  | { type: 'type'; payload: TimeTrackingType }
  | { type: 'setErrors'; payload: ManualTimeTrackingError }
  | { type: 'init'; payload: TimeTrackingEntity }
  | { type: 'clear' };

const initialState: State = {
  timeTracking: {} as TimeTrackingEntity,
  dateHelper: moment().toDate(),
  timeHelper: '',
  errors: {},
  isEdit: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'date':
      return {
        ...state,
        dateHelper: moment(action.payload).toDate(),
        hasChanges: true,
        errors: { ...state.errors, date: null },
      };
    case 'time':
      return {
        ...state,
        timeHelper: action.payload,
        hasChanges: true,
        errors: { ...state.errors, time: null },
      };
    case 'type':
      return {
        ...state,
        timeTracking: { ...state.timeTracking, type: action.payload },
        hasChanges: true,
        errors: { ...state.errors, type: null },
      };
    case 'init': {
      const timeStamp = action.payload.timestamp as Timestamp;
      return {
        ...state,
        timeTracking: action.payload,
        dateHelper: timeStamp.toDate(),
        timeHelper: moment(timeStamp.toDate()).format('HH:mm'),
        hasChanges: false,
        isEdit: true,
      };
    }
    case 'setErrors': {
      return { ...state, errors: action.payload };
    }
    case 'clear':
      return { ...initialState };
    default:
      return { ...state };
  }
};

/**
 * TimeTrackingManualModal()
 * @constructor
 */
export default function TimeTrackingManualModal(props: ModalProps) {
  const { onClose, timeTracking, visible } = props;
  const [t] = useTranslation();
  const { userId } = useParams();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [user, setUser] = React.useState<UserEntity>(null);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [headline, setHeadline] = React.useState<string>(t('timeTrackingCreate'));
  const [typeOptions, setTypeOptions] = React.useState<SelectOptions>([]);
  const { loadingModal, isLoading, setLoading } = useLoadingModal({ delayStart: 1000 });
  const { all: users } = useAppSelector((state) => state.client.users);
  const { user: currentUser } = useAppSelector((state) => state.auth);
  const dispatchUpdate = useDispatchClientTimeTrackingUpdate();
  const dispatchCreate = useDispatchClientTimeTrackingCreate();
  const dispatchDelete = useDispatchClientTimeTrackingDelete();

  React.useEffect(() => {
    const types: SelectOptions = [
      { value: TimeTrackingType.start, label: t(`timeTrackingTypes.${TimeTrackingType.start}`) },
      { value: TimeTrackingType.pause, label: t(`timeTrackingTypes.${TimeTrackingType.pause}`) },
      { value: TimeTrackingType.stop, label: t(`timeTrackingTypes.${TimeTrackingType.stop}`) },
    ];
    setTypeOptions(types);
  }, [t]);

  React.useEffect(() => {
    if (users) {
      const filtered = users.filter((item) => item.userId === userId);
      if (filtered.length === 1) {
        setUser(filtered[0]);
      }
    }
  }, [users, userId]);

  React.useEffect(() => {
    if (timeTracking) {
      dispatch({ type: 'init', payload: timeTracking });
      setHeadline(t('timeTrackingEdit'));
    }
  }, [t, timeTracking]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear' });
    }
  }, [visible]);

  const handleDelete = () => {
    setLoading(true);
    setAskDelete(false);
    dispatchDelete(userId, timeTracking.id).then(() => {
      setLoading(false);
      onClose(true);
    });
  };

  const handleSave = () => {
    const { isValid, errors } = validateManual(state.dateHelper, state.timeHelper, state.timeTracking.type);
    if (!isValid) {
      dispatch({ type: 'setErrors', payload: errors });
    } else {
      setLoading(true);

      const timestamp = `${moment(state.dateHelper).format('YYYY-MM-DD')} ${state.timeHelper}`;
      const mapped: TimeTrackingEntity = {
        ...state.timeTracking,
        timestamp: moment(timestamp).toDate(),
        manual: true,
        manualCreatedUser: Lodash.pick(currentUser, 'firstName', 'lastName', 'userId', 'initials'),
      };

      let promise;
      if (state.isEdit) {
        promise = dispatchUpdate(userId, mapped);
      } else {
        promise = dispatchCreate(userId, { ...mapped, device: DeviceType.web });
      }

      promise.then(() => {
        setLoading(false);
        onClose(true);
      });
    }
  };

  if (visible && user) {
    return (
      <>
        <Modal show={true} animation={true} size="lg" onHide={() => onClose(false)}>
          <Modal.Header>
            <Modal.Title>
              <i className="fas da-clock" style={{ marginRight: 10 }} />
              {headline}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: 20 }}>
            <Row>
              <Col lg={6}>
                <SelectClientUsers
                  label={t('employee')}
                  onChange={() => {}}
                  initialSelected={[user]}
                  isMultiSelect={false}
                  required
                  disabled
                />
              </Col>
              <Col lg={6}>
                <Select
                  label={t('type')}
                  onChange={(value) => dispatch({ type: 'type', payload: value })}
                  options={typeOptions}
                  initialValue={state.timeTracking.type}
                  hasError={!!state.errors.type}
                />
              </Col>
              <Col lg={6}>
                <DatePicker
                  label={t('date')}
                  onChange={(value) => dispatch({ type: 'date', payload: value })}
                  initialValue={state.dateHelper || moment().toDate()}
                  required
                  disabled={isLoading}
                  hasError={!!state.errors.date}
                />
              </Col>
              <Col lg={6}>
                <Input
                  onChange={(value) => dispatch({ type: 'time', payload: value })}
                  label={t('modules.settings.timeTracking.timeWithFormatHint')}
                  value={state.timeHelper}
                  required
                  disabled={isLoading}
                  hasError={!!state.errors.time}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleSave} disabled={isLoading || !state.hasChanges}>
              {t('save')}
            </Button>
            <ShowHideContainer visible={state.isEdit}>
              <Button variant="outline-danger" onClick={() => setAskDelete(true)} disabled={isLoading}>
                <i className="fas fa-trash" />
              </Button>
            </ShowHideContainer>
            <Button variant="outline-secondary" onClick={() => onClose(false)} disabled={isLoading}>
              {t('close')}
            </Button>
          </Modal.Footer>
        </Modal>

        {loadingModal}

        <AskDeleteModal onDelete={handleDelete} onClose={() => setAskDelete(false)} visible={askDelete} />
      </>
    );
  }
  return null;
}

TimeTrackingManualModal.defaultProps = {
  timeTracking: null,
};
