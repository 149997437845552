import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Tab from './Tab';

type Props = {
  containerStyle?: object;
  withoutPadding?: boolean;
  children: ReactElement | ReactElement[];
  initialSelected: string;
};

interface ContainerProps {
  withoutPadding: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  border-radius: ${(props) => props.theme.border.radius};
  ${(props) => props.theme.boxShadowDark};
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
`;

interface DummyInterface {
  caption: string;
  children: ReactElement | ReactElement[] | string;
  icon?: string;
  visible?: boolean;
}
const DummyTab = (props: DummyInterface) => null;

/**
 * TabPanel()
 * @param props
 * @constructor
 */
function TabPanel(props: Props) {
  const { containerStyle, withoutPadding, children, initialSelected } = props;
  const [selectedTab, setSelectedTab] = React.useState<string>(null);
  const [tabComponents, setTabComponents] = React.useState([]);

  React.useEffect(() => {
    if (initialSelected) {
      setSelectedTab(initialSelected);
    }
  }, [initialSelected]);

  React.useEffect(() => {
    setTabComponents(React.Children.map(children, (child) => child));
  }, [children]);

  const renderSelectedTabChildren = () => {
    if (selectedTab) {
      const filtered = tabComponents.filter((item) => item.props.caption === selectedTab)[0];
      if (filtered) {
        return filtered.props.children;
      }
    }
    return null;
  };

  return (
    <>
      <Container style={containerStyle} withoutPadding={withoutPadding}>
        {tabComponents.map((component) => (
          <Tab
            caption={component.props.caption}
            key={component.props.caption}
            selected={selectedTab && component.props.caption === selectedTab}
            onSelect={setSelectedTab}
            icon={component.props.icon}
            visible={component.props.visible !== undefined ? component.props.visible : true}
          />
        ))}
      </Container>

      {renderSelectedTabChildren()}
    </>
  );
}

TabPanel.Tab = DummyTab;

export default TabPanel;
