import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { CountryCode } from '../../Globals/Types/Enums';

interface Props {
  onChange: (item: number) => void;
  selected: number;
  showSkeleton?: boolean;
  country: CountryCode;
}

const optionsES: SelectOptions = [
  { value: 21, label: '21%' },
  { value: 10, label: '10%' },
  { value: 4, label: '4%' },
  { value: 0, label: '0%' },
];

const optionsDE: SelectOptions = [
  { value: 19, label: '19%' },
  { value: 7, label: '7%' },
  { value: 0, label: '0%' },
];

/**
 * SelectTax
 * @param props
 * @constructor
 */
export default function SelectTax(props: Props) {
  const { onChange, selected, showSkeleton, country } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>(null);

  React.useEffect(() => {
    switch (country) {
      case CountryCode.DE:
        setOptions(optionsDE);
        break;
      default:
        setOptions(optionsES);
    }
  }, [country]);

  if (options) {
    return (
      <Select
        label={t('taxRate')}
        onChange={onChange}
        initialValue={selected}
        options={options}
        showSkeleton={showSkeleton}
      />
    );
  }
  return null;
}
