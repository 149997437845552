import React from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '../../../../../Components/Panel';
import { FacilityEntity, FacilityPartnerEntity } from '../../../../../Globals/Types/Types';
import styled from 'styled-components';
import defaultCompanyLogo from '../../../../../Assets/Images/default_company_logo.png';
import { useDispatchGetExternalFacility } from '../../../../../Redux/Actions/Client/FacilityAction';
import { useNavigate } from 'react-router';
import { Badge, Button } from 'react-bootstrap';
import AskDeleteModal from '../../../../../Components/Modals/AskDeleteModal';
import { useDispatchDocumentGetDownloadUrl } from '../../../../../Redux/Actions/GlobalStorageActions';
import { buildDocumentRef } from '../../../../../Globals/FirebaseGlobals';
import { getStorage } from 'firebase/storage';

type PartnerContainerProps = {
  partner: FacilityPartnerEntity;
  facilityId: string;
  onDelete: (partner: FacilityPartnerEntity) => void;
  onApprove: (partner: FacilityPartnerEntity) => void;
};

const Container = styled.div<{ logoUrl: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  > .Image {
    margin-right: 30px;

    img {
      width: 200px;
      height: 150px;
      border-radius: 6px;
      object-fit: ${(props) => (props.logoUrl ? 'contain' : 'cover')};
    }
  }

  > .Content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > .Name {
      font-weight: bold;
      font-size: 18px;
    }

    .badge {
      margin-bottom: 10px;
    }

    > small {
      font-size: 12px;
      color: lightgray;
    }
  }

  > .Options {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

/**
 * PartnerContainer
 * @constructor
 */
export default function PartnerContainer(props: PartnerContainerProps) {
  const { partner, facilityId, onDelete, onApprove } = props;
  const [facility, setFacility] = React.useState<FacilityEntity>(null);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [logoUrl, setLogoUrl] = React.useState<string>(null);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatchGetExternalFacility = useDispatchGetExternalFacility();
  const dispatchGetLogo = useDispatchDocumentGetDownloadUrl();

  React.useEffect(() => {
    dispatchGetExternalFacility(partner.partnerClientId, partner.partnerFacilityId).then((facilityResponse) => {
      setFacility(facilityResponse);
    });
  }, [dispatchGetExternalFacility, partner.partnerClientId, partner.partnerFacilityId]);

  React.useEffect(() => {
    if (partner) {
      const path = buildDocumentRef(
        getStorage(),
        'client',
        partner.partnerClientId,
        'facility',
        partner.partnerFacilityId,
        'documents',
        'logo',
      );
      dispatchGetLogo(path.fullPath)
        .then((uri) => setLogoUrl(uri))
        .catch(() => {});
    }
  }, [dispatchGetLogo, partner]);

  const handleOnClick = () => {
    if (partner.approved) {
      navigate(partner.partnerId);
    }
  };

  const renderApproveButton = () => {
    if (!partner.approved) {
      if (partner.invitedFacilityId !== facilityId) {
        return (
          <>
            <Button variant="success" style={{ marginRight: 10 }} onClick={() => onApprove(partner)}>
              <i className="fas fa-check-circle" style={{ marginRight: 10 }} />
              {t('approve')}
            </Button>
            <Button variant="outline-danger" onClick={() => setAskDelete(true)}>
              <i className="fas fa-times-circle" />
            </Button>
          </>
        );
      } else {
        return (
          <Badge pill bg="warning" style={{ padding: '10px 15px' }}>
            {t('waitingApprove')}!
          </Badge>
        );
      }
    }
    return (
      <Button variant="outline-secondary">
        <i className="fas fa-cogs" />
      </Button>
    );
  };

  const renderStatus = () => {
    if (!partner.approved) {
      return (
        <Badge bg="secondary">
          <i className="fas fa-ellipsis-h" style={{ marginRight: 10 }} />
          {t('waitingApprove')}
        </Badge>
      );
    }
    if (partner.active) {
      return (
        <Badge bg="success">
          <i className="fas fa-check" style={{ marginRight: 10 }} />
          {t('active')}
        </Badge>
      );
    } else {
      return (
        <Badge bg="warning">
          <i className="fas fa-pause" style={{ marginRight: 10 }} />
          {t('paused')}
        </Badge>
      );
    }
  };

  if (facility) {
    return (
      <Panel>
        <Container onClick={handleOnClick} logoUrl={!!logoUrl}>
          <div className="Image">
            <img alt="CompanyLogo" src={logoUrl || defaultCompanyLogo} />
          </div>
          <div className="Content">
            <div className="Name">{facility.name}</div>
            <div>{renderStatus()}</div>

            <address>
              <div>{`${facility.street} ${facility.streetNo}`}</div>
              <div>{`${facility.zip} ${facility.city}`}</div>
            </address>

            <div>{`${facility.mail} / ${facility.phone}`}</div>
          </div>
          <div className="Options">{renderApproveButton()}</div>
        </Container>

        <AskDeleteModal
          onDelete={() => onDelete(partner)}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
          headline={t('modules.settings.facility.declinePartnership')}
          description={t('modules.settings.facility.declinePartnershipDescription')}
        />
      </Panel>
    );
  }
  return null;
}
