import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackupRestoreForm from './BackupRestoreForm';
import ElasticIndexForm from './ElasticIndexForm';
import PageHeader from '../../../Components/PageHeader';
import TimeTrackingForm from './TimeTrackingForm';
import Modules from './Modules';

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const [t] = useTranslation();

  const renderContent = () => {
    return (
      <>
        <Modules />
        <TimeTrackingForm />
        <Row>
          <Col xxl={6} className={'d-flex'}>
            <ElasticIndexForm />
          </Col>
          <Col xxl={6} className={'d-flex'}>
            <BackupRestoreForm />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <PageHeader headline={t('generalSettings')} />
      {renderContent()}
    </>
  );
}
