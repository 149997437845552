import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';

interface Props {
  hasError?: boolean;
  initialValue: number;
  label: string;
  marginBottom?: number;
  onChange: (value: number) => void;
  disabled: boolean;
}

/**
 * SelectRecurringDay
 * @param props
 * @constructor
 */
export default function SelectRecurringDay(props: Props) {
  const { hasError, initialValue, label, marginBottom, onChange, disabled } = props;
  const [t] = useTranslation();
  const [options] = React.useState<SelectOptions>([
    { label: t('recurringDay.day', { count: 1 }), value: 1 },
    { label: t('recurringDay.day', { count: 2 }), value: 2 },
    { label: t('recurringDay.day', { count: 3 }), value: 3 },
    { label: t('recurringDay.day', { count: 4 }), value: 4 },
    { label: t('recurringDay.day', { count: 5 }), value: 5 },
    { label: t('recurringDay.day', { count: 6 }), value: 6 },
    { label: t('recurringDay.week', { count: 1 }), value: 7 },
    { label: t('recurringDay.week', { count: 2 }), value: 14 },
    { label: t('recurringDay.week', { count: 3 }), value: 21 },
    { label: t('recurringDay.week', { count: 4 }), value: 28 },
  ]);

  if (options) {
    return (
      <Select
        label={label}
        onChange={onChange}
        initialValue={initialValue}
        options={options}
        hasError={hasError}
        marginBottom={marginBottom}
        disabled={disabled}
      />
    );
  }

  return null;
}

SelectRecurringDay.defaultProps = {
  disabled: false,
};
