import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { NamingConventionType, OrderEntity, OrderServiceType, ServiceEntity } from '../../../Globals/Types/OrderTypes';
import { useDispatchOrderServiceCreate } from '../../../Redux/Actions/Order/ServiceAction';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { buildUrlOrderServiceDetails } from '../../../Globals/UrlFunctions';
import { ReportState } from '../../../Globals/Types/Report';
import SelectOrderServiceType from '../../PredefinedPanelSelects/SelectOrderServiceType';
import { DeliveryAndLoadingOptions } from '../../../Globals/Types/Enums';

interface Props {
  order: OrderEntity;
  visible: boolean;
  onClose: () => void;
}

/**
 * CreateServiceModal
 * @param props
 * @constructor
 */
export default function CreateServiceModal(props: Props) {
  const { visible, onClose, order } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { isLoading, setLoading, loadingModal } = useLoadingModal({ headline: t('modules.order.serviceAdd') });
  const { userId } = useAppSelector((state) => state.auth);
  const dispatchCreateService = useDispatchOrderServiceCreate();

  const handleCreate = (serviceType: OrderServiceType) => {
    const service: ServiceEntity = {
      type: serviceType,
      createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      createdUserId: userId,
      reportState: ReportState.none,
      contacts: order.contacts || [],
      deliveryAndLoadingOption: DeliveryAndLoadingOptions.deliveryIsCustomer,
      settings: {
        sendCustomerMail: true,
        needReport: serviceType.needReport,
        recurring: {
          active: false,
          namingConvention: NamingConventionType.currentIndex,
          currentIndex: 1,
          days: 7,
          createNewOrder: false,
        },
      },
    };

    setLoading(true);
    dispatchCreateService(order, service)
      .then((serviceResponse) => {
        setLoading(false);
        onClose();
        navigate(buildUrlOrderServiceDetails(order.ownerClientId, order.orderId, serviceResponse.serviceId));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Modal show={visible} animation={false} size="lg">
      <Modal.Header>
        <Modal.Title>
          <i className="fas fa-plus-circle" style={{ marginRight: 8 }} />
          {t('modules.order.createService')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('modules.order.serviceCreateDescription')}</p>
        <div>
          <SelectOrderServiceType selected={null} onChange={handleCreate} setInitial={false} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => onClose()} disabled={isLoading}>
          {t('cancel')}
        </Button>
      </Modal.Footer>

      {loadingModal}
    </Modal>
  );
}
