import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import {
  CUSTOMER_OFFERS_GETLIST_ERROR,
  CUSTOMER_OFFERS_GETLIST_START,
  CUSTOMER_OFFERS_GETLIST_SUCCESS,
} from '../../ActionTypes';
import { collection, CollectionReference, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { OfferEntity } from '../../../Globals/Types/OfferTypes';
import { FirebasePathMappings } from '../../../Globals/FirebaseGlobals';

/**
 * buildCollection()
 * @param clientId
 * @param customerId
 */
const buildCollection = (clientId: string, customerId: string): CollectionReference<OfferEntity> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.offers,
  ) as CollectionReference<OfferEntity>;
};

/**
 * useDispatchCustomerOffersGetList()
 */
type GetListReturnType = (customerId: string) => Promise<OfferEntity[]>;
export const useDispatchCustomerOffersGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListReturnType>(
    (customerId) => {
      dispatch({ type: CUSTOMER_OFFERS_GETLIST_START, payload: customerId });

      const offerCollection = buildCollection(clientId, customerId);
      const queryRef = query(
        offerCollection,
        where('customerId', '==', customerId),
        orderBy('firebaseCreatedDateTime'),
      );

      return getDocs(queryRef)
        .then((response) => {
          const offers: OfferEntity[] = [];
          response.forEach((offer) => {
            const offerData: OfferEntity = { ...offer.data(), offerId: offer.id };

            offers.push(offerData);
          });
          dispatch({ type: CUSTOMER_OFFERS_GETLIST_SUCCESS, payload: { offers, customerId } });
          return Promise.resolve(offers);
        })
        .catch((error) => {
          dispatch({ type: CUSTOMER_OFFERS_GETLIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};
