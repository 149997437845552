import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (item: number) => void;
  selected: number;
  showSkeleton?: boolean;
}

/**
 * SelectFloor
 * @param props
 * @constructor
 */
export default function SelectFloor(props: Props) {
  const { onChange, selected, showSkeleton } = props;
  const [t] = useTranslation();
  const [options] = React.useState<SelectOptions>([
    { value: 0, label: t('floorZero') },
    { value: 1, label: t('floorNumber', { number: 1 }) },
    { value: 2, label: t('floorNumber', { number: 2 }) },
    { value: 3, label: t('floorNumber', { number: 3 }) },
    { value: 4, label: t('floorNumber', { number: 4 }) },
    { value: 5, label: t('floorNumber', { number: 5 }) },
    { value: 6, label: t('floorNumber', { number: 6 }) },
  ]);

  return (
    <Select
      label={t('floor')}
      onChange={onChange}
      initialValue={selected}
      options={options}
      showSkeleton={showSkeleton}
    />
  );
}
