import {
  CreateOrderState,
  NamingConventionType,
  OrderCategory,
  OrderCustomerAddress,
  OrderDocument,
  OrderServiceType,
  PositionEntity,
  ServiceEntity,
} from '../../../Globals/Types/OrderTypes';
import { DeliveryAndLoadingOptions, Environment, Parking, Salutation } from '../../../Globals/Types/Enums';
import { Address, FacilityPartnerEntity } from '../../../Globals/Types/Types';
import moment from 'moment';
import { DefaultHeyKitchenPartnerEntity } from '../../../Globals/Types/General';
import { calculateAssemblyTimeFromPositions, generateGuid } from '../../../Globals/Functions';
import { ReportState } from '../../../Globals/Types/Report';
import {
  getPredefinedDeliveryAddressFromPosition,
  getPredefinedLoadingAddressFromPosition,
} from '../../../Globals/Functions/ServiceFunctions';
import { OfferEntity } from '../../../Globals/Types/OfferTypes';
import { CustomerEntity } from '../../../Globals/Types/Customer';

type Action =
  | { type: 'initOrderCreated'; payload: { clientId: string; userId: string } }
  | { type: 'setCreatedFacility'; payload: string }
  | { type: 'ownerFacilityId'; payload: { facilityId: string; clientId: string } }
  | { type: 'orderServiceType'; payload: OrderServiceType }
  | { type: 'customerAddress'; payload: OrderCustomerAddress }
  | { type: 'generalService'; payload: ServiceEntity }
  | { type: 'externalId'; payload: string }
  | { type: 'balance'; payload: string }
  | { type: 'removalMeter'; payload: number }
  | { type: 'setPartner'; payload: FacilityPartnerEntity }
  | { type: 'toggleStorage'; payload: boolean }
  | { type: 'executionDate'; payload: string }
  | { type: 'executionTime'; payload: string }
  | { type: 'desiredWeek'; payload: string }
  | { type: 'montageSingleDocument'; payload: boolean }
  | { type: 'documentAdd'; payload: OrderDocument }
  | { type: 'documentDelete'; payload: OrderDocument }
  | { type: 'updatePositions'; payload: PositionEntity[] }
  | { type: 'deliveryAddress'; payload: Address }
  | { type: 'loadingAddress'; payload: Address }
  | { type: 'deliveryAndLoadingOption'; payload: DeliveryAndLoadingOptions }
  | { type: 'initializeFromOffer'; payload: { offer: OfferEntity; customer: CustomerEntity } };

const getDevCustomer = (): OrderCustomerAddress => {
  return {
    salutation: Salutation.male,
    parking: Parking.noInfo,
    floor: 0,
    notifyBeforeArrival: 0,
    hasElevator: false,
  } as OrderCustomerAddress;
};

const getDevExternalId = () => {
  if (process.env.REACT_APP_STAGE === Environment.dev) {
    return `DEV-${(10000 + Math.random() * 10000).toFixed(2)}`;
  }
  return null;
};

const checkPositionsForDeliveryOrLoadingAddress = (
  positions: PositionEntity[],
  currentState: CreateOrderState,
): CreateOrderState => {
  if (positions && positions.length > 0) {
    if (currentState.generalService.deliveryAndLoadingOption === DeliveryAndLoadingOptions.deliveryIsCustomer) {
      let deliveryAddress;
      let loadingAddress;

      positions.forEach((position) => {
        if (!deliveryAddress) {
          deliveryAddress = getPredefinedDeliveryAddressFromPosition(position);
        }
        if (!loadingAddress) {
          loadingAddress = getPredefinedLoadingAddressFromPosition(position);
        }
      });

      if (deliveryAddress) {
        return {
          ...currentState,
          positions,
          generalService: {
            ...currentState.generalService,
            deliveryAndLoadingOption: DeliveryAndLoadingOptions.loadingIsCustomerAndDelivery,
            deliveryAddress,
          },
        };
      } else if (loadingAddress) {
        return {
          ...currentState,
          positions,
          generalService: {
            ...currentState.generalService,
            deliveryAndLoadingOption: DeliveryAndLoadingOptions.deliveryIsCustomerAndLoading,
            loadingAddress,
          },
        };
      }
    }
  }
  return { ...currentState, positions };
};

const createOrderFromOffer = (
  offer: OfferEntity,
  customer: CustomerEntity,
  currentState: CreateOrderState,
): CreateOrderState => {
  const preparedPositions: PositionEntity[] = offer.positions.map((position) => ({
    type: position.type,
    amount: position.amount,
    assemblyTime: position.estimatedTime || 0,
    caption: position.caption,
    description: position.description,
    price: position.overallPrice,
    taxRate: position.taxRate,
    invoiced: false,
    externalData: [],
    positionId: generateGuid(),
  }));

  const address: OrderCustomerAddress = {
    firstName: customer.salutation === Salutation.company ? customer.companyName : customer.firstName,
    lastName: customer.lastName,
    zip: customer.zip,
    city: customer.city,
    street: customer.street,
    streetNo: customer.streetNo,
    parking: Parking.noInfo,
    countryCode: customer.country,
    phone: customer.phonePrivate || customer.phoneBusiness,
    mobile: customer.mobilePrivate || customer.mobileBusiness,
    notifyBeforeArrival: 15,
    floor: customer.floor,
    mail: customer.mailPrivate || customer.mailBusiness,
    hasElevator: customer.hasElevator,
    customerId: customer.customerId,
    salutation: customer.salutation,
    comment: offer.internalInformation,
  };

  return { ...currentState, positions: preparedPositions, order: { ...currentState.order, customer: address } };
};

export const initialState: CreateOrderState = {
  order: {
    customer: getDevCustomer(),
    executeTrigger: true,
    externalId: getDevExternalId(),
    originalExternalId: '',
    isExternal: false,
    category: OrderCategory.kitchen,
    ownerClientId: null,
    ownerFacilityId: null,
    createdUserId: null,
    createdClientId: null,
    createdFacilityId: null,
    balance: null,
    invoiceAddress: null,
    createdDate: null,
    services: null,
    deletable: true,
    serviceCount: 0,
  },
  generalService: {
    type: null,
    deliveryAndLoadingOption: DeliveryAndLoadingOptions.deliveryIsCustomer,
    createdDate: null,
    createdUserId: null,
    reportState: ReportState.none,
    positions: [],
    settings: {
      needReport: true,
      sendCustomerMail: true,
      recurring: {
        active: false,
        days: 7,
        currentIndex: 1,
        namingConvention: NamingConventionType.currentIndex,
        createNewOrder: false,
      },
    },
  },
  orderServiceType: null,
  positions: [],
  executionDate: null,
  executionTime: null,
  desiredWeek: null,
  documents: [],
  montageSingleDocument: true,
  estimatedAssemblyTime: 0,
  errors: {},
  isEdit: false,
  hasChanges: false,
};

export const reducer = (state: CreateOrderState, action: Action): CreateOrderState => {
  switch (action.type) {
    case 'initOrderCreated': {
      return {
        ...state,
        order: {
          ...state.order,
          createdClientId: action.payload.clientId,
          createdUserId: action.payload.userId,
          createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      };
    }
    case 'setCreatedFacility':
      return { ...state, order: { ...state.order, createdFacilityId: action.payload }, hasChanges: true };
    case 'ownerFacilityId': {
      return {
        ...state,
        order: {
          ...state.order,
          ownerFacilityId: action.payload.facilityId,
          ownerClientId: action.payload.clientId,
          isExternal: false,
        },
        hasChanges: true,
      };
    }
    case 'orderServiceType':
      return { ...state, orderServiceType: action.payload, hasChanges: true };
    case 'executionDate':
      return { ...state, executionDate: action.payload, hasChanges: true };
    case 'executionTime':
      return { ...state, executionTime: action.payload, hasChanges: true };
    case 'desiredWeek':
      return { ...state, desiredWeek: action.payload, hasChanges: true };
    case 'customerAddress':
      return { ...state, order: { ...state.order, customer: action.payload }, hasChanges: true };
    case 'externalId':
      return { ...state, order: { ...state.order, externalId: action.payload }, hasChanges: true };
    case 'balance':
      return { ...state, order: { ...state.order, balance: action.payload.replace(',', '.') }, hasChanges: true };
    case 'generalService':
      return { ...state, generalService: { ...state.generalService, ...action.payload }, hasChanges: true };
    case 'setPartner':
      const partner = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          ownerFacilityId: partner.partnerFacilityId,
          ownerClientId: partner.partnerClientId,
          isExternal: partner.partnerClientId === DefaultHeyKitchenPartnerEntity.partnerClientId,
        },
        hasChanges: true,
      };
    case 'documentAdd':
      return { ...state, documents: [...state.documents, action.payload] };
    case 'documentDelete':
      return { ...state, documents: state.documents.filter((item) => item.uri !== action.payload.uri) };
    case 'montageSingleDocument':
      return { ...state, montageSingleDocument: action.payload };
    case 'updatePositions': {
      const estimatedAssemblyTime = calculateAssemblyTimeFromPositions(action.payload);
      const updatedState = checkPositionsForDeliveryOrLoadingAddress(action.payload, state);
      return {
        ...updatedState,
        estimatedAssemblyTime,
      };
    }
    case 'deliveryAndLoadingOption': {
      return {
        ...state,
        generalService: { ...state.generalService, deliveryAndLoadingOption: action.payload },
        hasChanges: true,
      };
    }
    case 'deliveryAddress': {
      return {
        ...state,
        generalService: { ...state.generalService, deliveryAddress: action.payload },
        hasChanges: true,
      };
    }
    case 'loadingAddress': {
      return {
        ...state,
        generalService: { ...state.generalService, loadingAddress: action.payload },
        hasChanges: true,
      };
    }

    case 'initializeFromOffer':
      return { ...createOrderFromOffer(action.payload.offer, action.payload.customer, state) };

    default:
      new Error('Type not found!');
  }
};
