import React from 'react';
import Panel from '../../../../Panel';
import { useTranslation } from 'react-i18next';
import Row from './Row';
import { buildUrlDispositionWithDate } from '../../../../../Globals/UrlFunctions';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import { ElasticSearchServiceDispositionInterface } from '../../../../../Globals/Types/OrderTypes';

/**
 * DispositionInformation
 * @constructor
 */
export default function DispositionInformation() {
  const [t] = useTranslation();
  const { dispositions } = useAppSelector((state) => state.order.selectedOrder.selectedService);

  const navigateDisposition = (disposition: ElasticSearchServiceDispositionInterface) => {
    const uri = buildUrlDispositionWithDate(disposition.date);
    window.open(uri);
  };

  if (dispositions && dispositions.length > 0) {
    return (
      <Panel headline={t('disposition')} description={t('modules.order.dispositionDescription')}>
        {dispositions.map((disposition) => (
          <Row disposition={disposition} key={disposition.tourId} onPress={navigateDisposition} />
        ))}
      </Panel>
    );
  }
  return null;
}
