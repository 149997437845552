import React from 'react';
import FacilityHeader from '../Components/FacilityHeader';
import { useParams } from 'react-router';
import EmailForm from './EmailForm';
import SettingsPanel from './Templates/SettingsPanel';

/**
 * Mail()
 * @constructor
 */
export default function Mail() {
  const { facilityId } = useParams();

  return (
    <>
      <FacilityHeader />
      <EmailForm facilityId={facilityId} />
      <SettingsPanel />
    </>
  );
}
