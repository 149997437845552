import React from 'react';
import { PreDamageItem, Report } from '../../../Globals/Types/Report';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import ItemContainer from './ItemContainer';
import { useTheme } from 'styled-components';

interface Props {
  report: Report;
}

/**
 * PreDamage()
 * @param props
 * @constructor
 */
export default function PreDamage(props: Props) {
  const { report } = props;
  const theme = useTheme();
  const [t] = useTranslation();

  const renderItems = () =>
    report.preDamage.map((preDamage) => (
      <ItemContainer<PreDamageItem>
        item={preDamage}
        icon={'fas fa-octagon-exclamation'}
        iconColor={theme.color.orange}
        caption={preDamage.caption}
        description={preDamage.additionalInformation}
        images={preDamage.images.map((item) => item.downloadUrl)}
        key={preDamage.id}
      />
    ));

  if (report.preDamage && report.preDamage.length > 0) {
    return <Panel headline={t('modules.report.summary.preDamage')}>{renderItems()}</Panel>;
  }
  return null;
}
