import { InternalErrorCodes } from '../../Globals/InternalErrorCodes';
import { RecurringType } from '../../Globals/Types/Enums';
import { StateData } from './RecurringForm';

export type ValidateErrors = { [K in keyof StateData]?: string };

/**
 * isValid()
 * @param data
 */
const isValid = (data: StateData): { errors: ValidateErrors; isValid: boolean } => {
  let errors = {} as ValidateErrors;

  if (!data.dateStart || data.dateStart.length === 0) {
    errors.dateStart = InternalErrorCodes.FIELD_MISSING;
  }

  if (!data.dateEnd || data.dateEnd.length === 0) {
    errors.dateEnd = InternalErrorCodes.FIELD_MISSING;
  }

  if (data.dateEnd < data.dateStart) {
    errors.dateStart = InternalErrorCodes.FIELD_MISSING;
    errors.dateEnd = InternalErrorCodes.FIELD_MISSING;
  }

  switch (data.recurring) {
    case RecurringType.daily:
    case RecurringType.dailyWork:
      errors = { ...errors, ...isValidDaily(data) };
      break;
    case RecurringType.monthly:
      errors = { ...errors, ...isValidMonthly(data) };
      break;
    case RecurringType.weekly:
      errors = { ...errors, ...isValidWeekly(data) };
      break;
    default:
      errors.recurring = InternalErrorCodes.FIELD_MISSING;
  }

  return { errors, isValid: Object.keys(errors).length === 0 };
};

export default isValid;

/**
 * isValidDaily()
 * @param data
 */
const isValidDaily = (data: StateData): ValidateErrors => {
  const errors = {} as ValidateErrors;

  if (!data.dailyDays || data.dailyDays < 0 || data.dailyDays > 99) {
    errors.dailyDays = InternalErrorCodes.FIELD_MISSING;
  }

  return errors;
};

/**
 * isValidMonthly()
 * @param data
 */
const isValidMonthly = (data: StateData): ValidateErrors => {
  const errors = {} as ValidateErrors;

  if (!data.monthlyMonths || data.monthlyMonths < 0 || data.monthlyMonths > 99) {
    errors.monthlyMonths = InternalErrorCodes.FIELD_MISSING;
  }

  if (!data.monthlyOnDay || data.monthlyOnDay.length === 0) {
    errors.monthlyOnDay = InternalErrorCodes.FIELD_MISSING;
  }

  if (!data.monthlyOn || data.monthlyOn.length === 0) {
    errors.monthlyOn = InternalErrorCodes.FIELD_MISSING;
  }

  if (
    errors.monthlyOnDay === InternalErrorCodes.FIELD_MISSING &&
    errors.monthlyOn === InternalErrorCodes.FIELD_MISSING
  ) {
    if (!data.monthlyDay || data.monthlyDay < 0 || data.monthlyDay > 31) {
      errors.monthlyDay = InternalErrorCodes.FIELD_MISSING;
    } else {
      delete errors.monthlyOnDay;
      delete errors.monthlyOn;
    }
  }

  return errors;
};

/**
 * isValidWeekly()
 * @param data
 */
const isValidWeekly = (data: StateData): ValidateErrors => {
  const errors = {} as ValidateErrors;

  if (!data.weeklyWeekdays.find((item) => !!item)) {
    errors.weeklyWeekdays = InternalErrorCodes.FIELD_MISSING;
  }

  if (!data.weeklyWeeks || data.weeklyWeeks < 0 || data.weeklyWeeks > 99) {
    errors.weeklyWeeks = InternalErrorCodes.FIELD_MISSING;
  }

  return errors;
};
