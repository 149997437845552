import React, { useEffect } from 'react';
import { MessageEntity } from '../../../../Globals/Types/Types';
import styled from 'styled-components';
import Text from '../../../Text';
import { userDisplayName } from '../../../../Globals/Functions';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { lighten } from 'polished';
import { useNavigate, useParams } from 'react-router';
import { buildUrlInboxDetails } from '../../../../Globals/UrlFunctions';
import { messageGetSubject } from '../../../../Globals/Functions/MessageFunctions';

const Container = styled.div<{ $isUnread: boolean; $initials: string }>`
  display: flex;
  flex-direction: row;
  padding: 15px;
  cursor: pointer;
  align-items: flex-start;
  margin-bottom: 4px;

  > .LeftContainer {
    margin-right: 15px;

    > .Circle {
      display: flex;
      height: 50px;
      width: 50px;
      border-radius: 25px;
      background-color: ${(props) => lighten(0.25, props.theme.color.textGray)};
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: ${(props) => (props.$initials.length === 2 ? '16px' : props.$initials.length === 3 ? '14px' : '12px')};
    }
  }

  > .RightContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    > .Headline {
      display: flex;
      flex: 1;
      align-items: center;

      > .Sender {
        flex: 1;
        font-weight: ${(props) => (props.$isUnread ? '600' : '400')};
      }
    }

    > .Subject {
      display: flex;
      flex: 1;
      max-width: 360px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.active,
  &:hover {
    border-bottom-width: 0;
    border-radius: ${(props) => props.theme.border.radius};
    background-color: ${(props) => lighten(0.25, props.theme.color.textGray)};

    > .LeftContainer {
      > .Circle {
        background-color: #ffffff;
      }
    }
  }
`;

interface Props {
  message: MessageEntity;
}

/**
 * Item()
 * @param props
 * @constructor
 */
export default function Item(props: Props) {
  const { message } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { messageId } = useParams();
  const [active, setActive] = React.useState<boolean>(false);

  useEffect(() => {
    if (messageId) {
      setActive(message.messageId === messageId);
    } else {
      setActive(false);
    }
  }, [message.messageId, messageId]);

  const getDateString = () => {
    if (moment(message.createdDate.toDate()).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      return t('today');
    } else if (
      moment(message.createdDate.toDate()).format('YYYY-MM-DD') === moment().subtract(1, 'd').format('YYYY-MM-DD')
    ) {
      return t('yesterday');
    } else {
      return moment(message.createdDate.toDate()).format('LL');
    }
  };

  const renderAttachmentIcon = () => {
    if (message && message.attachments && message.attachments.length > 0) {
      return <i className="fal fa-paperclip" style={{ marginRight: 6, fontSize: 14, color: 'gray' }} />;
    }
    return null;
  };

  return (
    <Container
      $isUnread={!message.read}
      $initials={message.sender.initials}
      className={active ? 'active' : ''}
      onClick={() => navigate(buildUrlInboxDetails(message.messageId))}
    >
      <div className={'LeftContainer'}>
        <div className="Circle">{message.sender.initials}</div>
      </div>
      <div className={'RightContainer'}>
        <div className="Headline">
          <div className="Sender">
            {renderAttachmentIcon()}
            {userDisplayName(message.sender, true)}
          </div>
          <div>
            <Text light small>
              {getDateString()}
            </Text>
          </div>
        </div>

        <div className="Subject">
          <Text light>{messageGetSubject(message, t)}</Text>
        </div>
      </div>
    </Container>
  );
}
