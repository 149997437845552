import React from 'react';
import { SubscriptionPeriod } from '../Types/Enums';
import { useAppSelector } from './Hooks';
import moment from 'moment';
import SubscriptionExpiredMessage from '../../Components/Modals/SubscriptionExpiredMessage';

export const basicPrice = 49.9;
export const userPrice = 19.9;
export const yearlyRebatePercent = 0.1;

/**
 * useCalculatePrices()
 * Calculates the price for a subscription and returns all necessary values
 */
export interface SubscriptionPriceCalculationResult {
  basicPrice: number;
  pricePerUser: number;
  priceForAdditionalUsers: number;
  overallPrice: number;
  price: number;
  rebate: number;
}
type CalculationResult = (users: number, period: SubscriptionPeriod) => SubscriptionPriceCalculationResult;

export const useCalculatePrices = (): CalculationResult => {
  const calculateRebate = (users: number, pricePerUser: number, period: SubscriptionPeriod): number => {
    const calculated = period === SubscriptionPeriod.year ? users * pricePerUser * 12 : users * pricePerUser;

    if (period === SubscriptionPeriod.year) {
      return -calculated * yearlyRebatePercent;
    } else {
      return 0;
    }
  };

  const calculatePriceForAdditionalUsers = (
    users: number,
    pricePerUser: number,
    period: SubscriptionPeriod,
  ): number => {
    return period === SubscriptionPeriod.year ? users * pricePerUser * 12 : users * pricePerUser;
  };

  const calculatePrice = (users: number, priceForAdditionalUsers: number, period: SubscriptionPeriod): number => {
    return period === SubscriptionPeriod.year
      ? basicPrice * 12 + priceForAdditionalUsers
      : basicPrice + priceForAdditionalUsers;
  };

  return React.useCallback<CalculationResult>((users: number, period: SubscriptionPeriod) => {
    const pricePerUser = userPrice;
    const priceForAdditionalUsers = calculatePriceForAdditionalUsers(users, pricePerUser, period);
    const rebate = calculateRebate(users, pricePerUser, period);
    const price = calculatePrice(users, priceForAdditionalUsers, period);
    const overallPrice = price + rebate;

    return {
      basicPrice: basicPrice,
      pricePerUser: pricePerUser,
      priceForAdditionalUsers,
      rebate,
      price,
      overallPrice,
    };
  }, []);
};

/**
 * useSubscriptionIsValid()
 */
type IsValidReturnType = {
  subscriptionValid: boolean;
  clickHandler: (callback) => void;
  subscriptionModal: JSX.Element;
};
export const useSubscriptionIsValid = (): IsValidReturnType => {
  const { activeSubscription } = useAppSelector((state) => state.auth);
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [clicked, setClicked] = React.useState<boolean>(false);
  const [modal, setModal] = React.useState<JSX.Element>();

  React.useEffect(() => {
    if (clicked && !modal) {
      setModal(<SubscriptionExpiredMessage visible={true} onClose={() => setModal(null)} />);
      setClicked(false);
    }
  }, [clicked, modal]);

  React.useEffect(() => {
    if (activeSubscription) {
      if (moment().isBefore(moment(activeSubscription.endDate))) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [activeSubscription]);

  const clickHandler = React.useCallback(
    (callback: () => void) => {
      if (!clicked) {
        if (!isValid) {
          setClicked(true);
          return null;
        } else {
          return callback();
        }
      }
    },
    [clicked, isValid],
  );

  return { subscriptionValid: isValid, clickHandler, subscriptionModal: modal };
};
