import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FacilityEntity, MailEntity } from '../../../Globals/Types/Types';
import { buildDocumentRef, FirebaseFunctionNames, FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import {
  collection,
  CollectionReference,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { getStorage, uploadBytes, getMetadata, deleteObject } from 'firebase/storage';
import {
  FACILITY_CREATE_ERROR,
  FACILITY_CREATE_START,
  FACILITY_CREATE_SUCCESS,
  FACILITY_DOCUMENT_CREATE_ERROR,
  FACILITY_DOCUMENT_CREATE_START,
  FACILITY_DOCUMENT_CREATE_SUCCESS,
  FACILITY_DOCUMENT_DELETE_ERROR,
  FACILITY_DOCUMENT_DELETE_START,
  FACILITY_DOCUMENT_DELETE_SUCCESS,
  FACILITY_DOCUMENT_GET_ERROR,
  FACILITY_DOCUMENT_GET_START,
  FACILITY_DOCUMENT_GET_SUCCESS,
  FACILITY_GET_ERROR,
  FACILITY_GET_EXTERNAL_CACHE_SUCCESS,
  FACILITY_GET_EXTERNAL_ERROR,
  FACILITY_GET_EXTERNAL_START,
  FACILITY_GET_EXTERNAL_SUCCESS,
  FACILITY_GET_START,
  FACILITY_GET_SUCCESS,
  FACILITY_GETLIST_ERROR,
  FACILITY_GETLIST_START,
  FACILITY_GETLIST_SUCCESS,
  FACILITY_SEND_MAIL_ERROR,
  FACILITY_SEND_MAIL_START,
  FACILITY_SEND_MAIL_SUCCESS,
  FACILITY_UPDATE_ERROR,
  FACILITY_UPDATE_LAST_INVOICE_NUMBER_ERROR,
  FACILITY_UPDATE_LAST_INVOICE_NUMBER_START,
  FACILITY_UPDATE_LAST_INVOICE_NUMBER_SUCCESS,
  FACILITY_UPDATE_START,
  FACILITY_UPDATE_SUCCESS,
} from '../../ActionTypes';
import { FacilityDocumentTypes } from '../../../Globals/Types/Enums';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import Lodash from 'lodash';
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * buildCollection()
 * @param clientId
 */
const buildCollection = (clientId: string): CollectionReference<FacilityEntity> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.facility,
  ) as CollectionReference<FacilityEntity>;
};

type GetListReturnType = (clientIdParam: string | null) => Promise<Array<FacilityEntity>>;
type GetListCallbackType = (arg0: string | null) => Promise<Array<FacilityEntity>>;
/**
 * useDispatchGetFacilities()
 */
export const useDispatchGetFacilities = (): GetListReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useSelector((state: any) => state.auth);

  return React.useCallback<GetListCallbackType>(
    (clientIdParam: string = null) => {
      const mappedClientId = clientIdParam || clientId;

      dispatch({ type: FACILITY_GETLIST_START, payload: mappedClientId });

      const facilityCollection = buildCollection(mappedClientId);
      const queryRef = query(facilityCollection, orderBy('name'));

      return getDocs(queryRef)
        .then((response) => {
          const facilities: Array<FacilityEntity> = [];
          response.forEach((facility) => {
            facilities.push({ ...(facility.data() as FacilityEntity), facilityId: facility.id });
          });
          dispatch({ type: FACILITY_GETLIST_SUCCESS, payload: { facilities } });
          return Promise.resolve(facilities);
        })
        .catch((error) => {
          dispatch({ type: FACILITY_GETLIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type GetFacilityReturnType = (facilityId: string) => Promise<FacilityEntity>;
type GetFacilityCallback = (arg0: string) => Promise<FacilityEntity>;
/**
 * useDispatchGetFacility()
 */
export const useDispatchGetFacility = (): GetFacilityReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useSelector((state: any) => state.auth);

  return React.useCallback<GetFacilityCallback>(
    (facilityId: string) => {
      dispatch({ type: FACILITY_GET_START, payload: clientId });

      const collectionRef = buildCollection(clientId);
      const docRef = doc(collectionRef, facilityId);

      return getDoc(docRef)
        .then((response) => {
          if (response.exists()) {
            const result: FacilityEntity = { ...response.data(), facilityId: response.id } as FacilityEntity;
            dispatch({ type: FACILITY_GET_SUCCESS, payload: result });
            return Promise.resolve(result);
          }
          return Promise.reject();
        })
        .catch((error) => {
          dispatch({ type: FACILITY_GET_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch],
  );
};

type UpdateFacilityReturnType = (facilityId: string, data: Partial<FacilityEntity>) => Promise<FacilityEntity>;
/**
 * useDispatchUpdateFacility()
 */
export const useDispatchUpdateFacility = (): UpdateFacilityReturnType => {
  const dispatch = useDispatch();
  const dispatchGet = useDispatchGetFacility();
  const { clientId } = useSelector((state: any) => state.auth);

  return React.useCallback<UpdateFacilityReturnType>(
    (facilityId, data) => {
      dispatch({ type: FACILITY_UPDATE_START, payload: { data } });

      const collectionRef = buildCollection(clientId);
      const docRef = doc(collectionRef, facilityId);

      return setDoc(docRef, data, { merge: true })
        .then(() => {
          return dispatchGet(facilityId).then((facility) => {
            dispatch({ type: FACILITY_UPDATE_SUCCESS, payload: facility });
            return Promise.resolve(facility);
          });
        })
        .catch((error) => {
          dispatch({ type: FACILITY_UPDATE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch, dispatchGet],
  );
};

type CreateFacilityReturnType = (facilityData: FacilityEntity) => Promise<string>;
type CreateFacilityCallbackType = (arg1: FacilityEntity) => Promise<string>;
/**
 * useDispatchCreateFacility()
 */
export const useDispatchCreateFacility = (): CreateFacilityReturnType => {
  const dispatch = useDispatch();
  const dispatchGetList = useDispatchGetFacilities();
  const { clientId } = useSelector((state: any) => state.auth);

  return React.useCallback<CreateFacilityCallbackType>(
    (facilityData: FacilityEntity) => {
      dispatch({ type: FACILITY_CREATE_START, payload: facilityData });

      const collectionRef = buildCollection(clientId);

      const facilityId = doc(collectionRef).id;
      const docRef = doc(collectionRef, facilityId);
      const mapped = { ...facilityData, facilityId };

      return setDoc(docRef, mapped)
        .then(() => {
          return dispatchGetList(clientId).then(() => {
            dispatch({ type: FACILITY_CREATE_SUCCESS, payload: mapped.facilityId });
            return Promise.resolve(mapped.facilityId);
          });
        })
        .catch((error) => {
          dispatch({ type: FACILITY_CREATE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch, dispatchGetList],
  );
};

type CreateFacilityDocumentReturnType = (
  facilityId: string,
  file: File,
  documentType: FacilityDocumentTypes,
) => Promise<Object>;
type CreateFacilityDocumentCallbackType = (
  facilityId: string,
  file: File,
  documentType: FacilityDocumentTypes,
) => Promise<Object>;
/**
 * useDispatchCreateDocument()
 */
export const useDispatchCreateDocument = (): CreateFacilityDocumentReturnType => {
  const dispatch = useDispatch();
  const { clientId, userId } = useSelector((state: any) => state.auth);

  return React.useCallback<CreateFacilityDocumentCallbackType>(
    (facilityId, file, documentType) => {
      dispatch({ type: FACILITY_DOCUMENT_CREATE_START, payload: { facilityId, file, documentType } });
      const fileName = documentType !== FacilityDocumentTypes.others ? `${documentType}` : file.name;

      const path = buildDocumentRef(
        getStorage(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.facility,
        facilityId,
        FirebasePathMappings.documents,
        fileName,
      );
      const customMetadata = {
        type: documentType,
        uploadFileName: file.name,
        uploadUserId: userId,
      };

      return uploadBytes(path, file, { customMetadata })
        .then((response) => {
          dispatch({ type: FACILITY_DOCUMENT_CREATE_SUCCESS, payload: response });
          return Promise.resolve(response);
        })
        .catch((error) => {
          dispatch({ type: FACILITY_DOCUMENT_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, userId],
  );
};

/**
 * useDispatchGetDocumentByName()
 */
export const useDispatchGetDocumentByName = () => {
  const dispatch = useDispatch();
  const { clientId } = useSelector((state: any) => state.auth);

  return React.useCallback(
    (facilityId: string, name: string) => {
      dispatch({ type: FACILITY_DOCUMENT_GET_START });

      const path = buildDocumentRef(
        getStorage(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.facility,
        facilityId,
        FirebasePathMappings.documents,
        name,
      );

      return getMetadata(path)
        .then((response) => {
          dispatch({ type: FACILITY_DOCUMENT_GET_SUCCESS, payload: response });
          return Promise.resolve(response);
        })
        .catch((error) => {
          dispatch({ type: FACILITY_DOCUMENT_GET_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchDeleteDocument()
 */
export const useDispatchDeleteDocument = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    (path: string) => {
      dispatch({ type: FACILITY_DOCUMENT_DELETE_START });

      const pathRef = buildDocumentRef(getStorage(), path);

      return deleteObject(pathRef)
        .then((response) => {
          dispatch({ type: FACILITY_DOCUMENT_DELETE_SUCCESS, payload: response });
          return Promise.resolve(response);
        })
        .catch((error) => {
          dispatch({ type: FACILITY_DOCUMENT_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

type ExternalFacilityReturnType = (clientId: string, facilityId: string) => Promise<FacilityEntity>;
/**
 * useDispatchGetFacility()
 * Get an external facility data from another client. Is only allowed if the facilities are partners
 */
export const useDispatchGetExternalFacility = (): ExternalFacilityReturnType => {
  const dispatch = useDispatch();
  const { facilities } = useAppSelector((state) => state.cache);

  return React.useCallback<ExternalFacilityReturnType>(
    (clientId, facilityId) => {
      const foundInCache = Lodash.find(facilities, (item) => item.facilityId === facilityId);
      if (foundInCache) {
        dispatch({ type: FACILITY_GET_EXTERNAL_CACHE_SUCCESS, payload: foundInCache });
        return Promise.resolve(foundInCache);
      }

      dispatch({ type: FACILITY_GET_EXTERNAL_START, payload: { clientId, facilityId } });

      const collectionRef = buildCollection(clientId);
      const docRef = doc(collectionRef, facilityId);

      return getDoc(docRef)
        .then((response) => {
          if (response.exists()) {
            const facility = { ...response.data(), facilityId: response.id } as FacilityEntity;
            dispatch({ type: FACILITY_GET_EXTERNAL_SUCCESS, payload: facility });
            return Promise.resolve(facility);
          }
          return Promise.reject();
        })
        .catch((error) => {
          dispatch({ type: FACILITY_GET_EXTERNAL_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [dispatch, facilities],
  );
};

/**
 * useDispatchCheckFacility
 * Checks a facilities company data for missing values
 */
type FacilityCheckResult = {
  isValid: boolean;
  setFacility: (facility: FacilityEntity) => void;
  isFacilityValid: (facility: FacilityEntity) => boolean;
};

export const useDispatchCheckFacility = (): FacilityCheckResult => {
  const [isValid, setIsValid] = React.useState(false);
  const [facilityCurr, setFacilityCurr] = React.useState<FacilityEntity>(null);

  React.useEffect(() => {
    const { bank, facilityId, name, street, streetNo, zip, city, vat, mail, phone } = facilityCurr || {};

    const { name: bankName, owner, iban, bic } = bank || {};

    const valid =
      facilityId &&
      facilityId.length > 0 &&
      name &&
      name.length > 0 &&
      street &&
      street.length > 0 &&
      streetNo &&
      streetNo.length > 0 &&
      zip &&
      zip.length > 0 &&
      city &&
      city.length > 0 &&
      vat &&
      vat.length > 0 &&
      mail &&
      mail.length > 0 &&
      phone &&
      phone.length > 0 &&
      bank &&
      bankName &&
      bankName.length > 0 &&
      owner &&
      owner.length > 0 &&
      iban &&
      iban.length > 0 &&
      bic &&
      bic.length > 0;

    setIsValid(valid);
  }, [facilityCurr]);

  const isFacilityValid = React.useCallback((facility: FacilityEntity) => {
    const { bank, facilityId, name, street, streetNo, zip, city, vat, mail, phone } = facility || {};
    const { name: bankName, owner, iban, bic } = bank || {};

    return (
      facilityId &&
      facilityId.length > 0 &&
      name &&
      name.length > 0 &&
      street &&
      street.length > 0 &&
      streetNo &&
      streetNo.length > 0 &&
      zip &&
      zip.length > 0 &&
      city &&
      city.length > 0 &&
      vat &&
      vat.length > 0 &&
      mail &&
      mail.length > 0 &&
      phone &&
      phone.length > 0 &&
      bank &&
      bankName &&
      bankName.length > 0 &&
      owner &&
      owner.length > 0 &&
      iban &&
      iban.length > 0 &&
      bic &&
      bic.length > 0
    );
  }, []);

  const setFacility = React.useCallback((facility: FacilityEntity) => setFacilityCurr(facility), []);

  return { isValid, setFacility, isFacilityValid };
};

/**
 * useDispatchSendMail()
 */
type SendMailReturnType = (
  facilityId: string,
  to: string,
  subject: string,
  text: string,
  isValidation?: boolean,
) => Promise<void>;
export const useDispatchSendMail = (): SendMailReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<SendMailReturnType>(
    (facilityId, to, subject, text, isValidation) => {
      const mail: MailEntity = { to, subject, text };

      dispatch({ type: FACILITY_SEND_MAIL_START, payload: { mail, isValidation } });

      const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.sendMail);
      return callable({ clientId, facilityId, mail, isValidation })
        .then(() => {
          dispatch({ type: FACILITY_SEND_MAIL_SUCCESS });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch({ type: FACILITY_SEND_MAIL_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useUpdateFacilityInvoiceNumber()
 * Update only the lastInvoiceNumber of facility settings
 */
type UpdateLastInvoiceNumberReturn = (facilityId: string, lastInvoiceNumber: number) => Promise<FacilityEntity>;
export const useDispatchFacilityUpdateLastInvoiceNumber = (): UpdateLastInvoiceNumberReturn => {
  const dispatch = useAppDispatch();
  const dispatchGet = useDispatchGetFacility();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateLastInvoiceNumberReturn>(
    (facilityId, lastInvoiceNumber) => {
      dispatch({ type: FACILITY_UPDATE_LAST_INVOICE_NUMBER_START, payload: { facilityId, lastInvoiceNumber } });

      return dispatchGet(facilityId)
        .then((facility) => {
          let preparedSettings;

          if (facility.settings) {
            preparedSettings = {
              ...facility.settings,
              invoiceSettings: { ...facility.settings.invoiceSettings, lastInvoiceNumber },
            };
          } else {
            preparedSettings = {
              invoiceSettings: { lastInvoiceNumber },
            };
          }

          const collectionRef = buildCollection(clientId);
          const docRef = doc(collectionRef, facility.facilityId);
          const preparedFacility = { ...facility, settings: preparedSettings };

          return setDoc(docRef, preparedFacility, { merge: true }).then(() => {
            dispatch({ type: FACILITY_UPDATE_LAST_INVOICE_NUMBER_SUCCESS, payload: preparedFacility });
            return Promise.resolve(preparedFacility);
          });
        })
        .catch((error) => {
          dispatch({ type: FACILITY_UPDATE_LAST_INVOICE_NUMBER_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, dispatchGet],
  );
};

/**
 * useDispatchFacilityUpdateLastVoucherNumber()
 * Update only the lastVoucherNumber of facility settings
 */
type UpdateLastVoucherNumberReturn = (facilityId: string, lastVoucherNumber: number) => Promise<FacilityEntity>;
export const useDispatchFacilityUpdateLastVoucherNumber = (): UpdateLastVoucherNumberReturn => {
  const dispatch = useAppDispatch();
  const dispatchGet = useDispatchGetFacility();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateLastVoucherNumberReturn>(
    (facilityId, lastVoucherNumber) => {
      dispatch({ type: FACILITY_UPDATE_LAST_INVOICE_NUMBER_START, payload: { facilityId, lastVoucherNumber } });

      return dispatchGet(facilityId)
        .then((facility) => {
          let preparedSettings;
          if (facility.settings) {
            preparedSettings = {
              ...facility.settings,
              invoiceSettings: { ...facility.settings.invoiceSettings, lastVoucherNumber },
            };
          } else {
            preparedSettings = {
              invoiceSettings: { lastVoucherNumber },
            };
          }

          const collectionRef = buildCollection(clientId);
          const docRef = doc(collectionRef, facility.facilityId);
          const preparedFacility = { ...facility, settings: preparedSettings };

          return setDoc(docRef, preparedFacility, { merge: true }).then(() => {
            dispatch({ type: FACILITY_UPDATE_LAST_INVOICE_NUMBER_SUCCESS, payload: preparedFacility });
            return Promise.resolve(preparedFacility);
          });
        })
        .catch((error) => {
          dispatch({ type: FACILITY_UPDATE_LAST_INVOICE_NUMBER_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, dispatchGet],
  );
};
