import React from 'react';
import { OrderEntity, ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import Panel from './Panel';
import { useTranslation } from 'react-i18next';
import { DeliveryAndLoadingOptions } from '../../../../../Globals/Types/Enums';

interface Props {
  order: OrderEntity;
  service: ServiceEntity;
}

/**
 * LoadingAddress()
 * @param props
 * @constructor
 */
export default function LoadingAddress(props: Props) {
  const { service, order } = props;
  const [t] = useTranslation();

  const getCaption = () => {
    if (service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingIsCustomerAndDelivery) {
      return `${order.customer.zip} ${order.customer.city}`;
    }
    if (
      service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.deliveryIsCustomerAndLoading ||
      service.deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingAndDelivery
    ) {
      return `${service.loadingAddress.zip} ${service.loadingAddress.city}`;
    }
  };

  if (
    service.deliveryAndLoadingOption &&
    service.deliveryAndLoadingOption !== DeliveryAndLoadingOptions.deliveryIsCustomer
  ) {
    return <Panel icon={'fa-duotone fa-truck-ramp-box'} label={t('loadingAddress')} caption={getCaption()} />;
  }
  return null;
}
