import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';

interface Props {
  hasError?: boolean;
  initialValue: number;
  label: string;
  marginBottom?: number;
  max: number;
  onChange: (value: number) => void;
  start?: number;
  required: boolean;
}

/**
 * SelectNumber
 * @param props
 * @constructor
 */
export default function SelectNumber(props: Props) {
  const { hasError, initialValue, label, marginBottom, max, onChange, start, required } = props;
  const [options, setOptions] = React.useState<SelectOptions>(null);

  React.useEffect(() => {
    const optionsTemp: SelectOptions = [];
    for (let i = start; i <= max; ++i) {
      optionsTemp.push({ label: `${i}`, value: i });
    }

    setOptions(optionsTemp);
  }, [max, setOptions, start]);

  if (options) {
    return (
      <Select
        required={required}
        label={label}
        onChange={onChange}
        initialValue={initialValue}
        options={options}
        hasError={hasError}
        marginBottom={marginBottom}
      />
    );
  }

  return null;
}

SelectNumber.defaultProps = {
  start: 1,
  required: false,
};
