import {
  AbsenceType,
  CountryCode,
  DeviceType,
  SubscriptionPeriod,
  SubscriptionType,
  SystemMessageTemplate,
  TaskLinkType,
  TaskPriority,
  TimeTrackingType,
  UserLoginPermission,
} from './Enums';
import { Timestamp, FieldValue } from 'firebase/firestore';
import { AccessPath } from '../Access/Groups';
import { OrderServiceType } from './OrderTypes';
import { ReportConfig } from './Report';
import { FullMetadata } from '@firebase/storage';

export interface LocationPoint {
  latitude?: number;
  longitude?: number;
}

export interface Address {
  zip: string;
  city: string;
  street: string;
  streetNo: string;
  location?: LocationPoint;
}

export interface LicenseEntity extends PickedUserEntity {
  id?: string;
  type: 'web' | 'app';
  datetime: string;
  deviceName: string;
}

export interface UserDevice {
  id: string;
  fcmToken: string;
  lastUpdateDate: string;
  os: string;
  osVersion: string;
  model: string;
}

export interface UserEntity extends Partial<Address> {
  userId?: string;
  clientId: string;
  facilityId: string;
  authId: string;
  externalId?: string;
  firstName: string;
  lastName: string;
  initials: string;
  mail: string;
  phone?: string;
  taxNumber?: string;
  socialSecurityNumber?: string;
  note?: string;
  countryCode: string;
  devices: UserDevice[];
  active: boolean;
  groups: Array<GroupKey>;
  facilities: Array<string>;
  createdDate: Timestamp;
  changePassword: boolean;
  accessCode: number;
  loginPermission: UserLoginPermission;
}

export type PickedUserEntity = Pick<UserEntity, 'firstName' | 'lastName' | 'userId' | 'initials'>;

export interface FacilityEntity extends Partial<Address> {
  facilityId?: string;
  name: string;
  nameShort: string;
  countryCode?: CountryCode;
  mail?: string;
  website?: string;
  phone?: string;
  fax?: string;
  vat?: string;
  devoutIdentificationNumber?: string;
  bank?: BankEntity;
  settings?: FacilitySettingsEntity;
  createdDate?: Timestamp;
  active?: boolean;
}

export interface FacilityPartnerEntity {
  partnerId?: string;
  partnerClientId: string;
  partnerFacilityId: string;
  partnerName: string;
  partnerSettings: FacilityPartnerSettingsEntity;
  active: boolean;
  approved: boolean;
  approvedBy?: string;
  approvedDate?: Timestamp;
  invitedUserId: string;
  invitedClientId: string;
  invitedFacilityId: string;
  settings: FacilityPartnerSettingsEntity;
  createdDate: Timestamp;
  executeTrigger: boolean; // Defines if the create trigger function should be called if an entry is added
}

export interface FacilityPartnerSettingsEntity {
  assignOrders: boolean;
  createOrders: boolean;
  responsibleUserId: string;
  allowUseStorage: boolean;
  defaultStorageId: string;
}

export interface DocumentEntity {
  fileName: string;
  downloadUrl: string;
  path: string;
  size: number;
  created?: string;
  updated?: string;
}

export interface BankEntity {
  owner: string;
  name: string;
  iban: string;
  bic: string;
}

export interface FacilityInvoiceSettings {
  lastInvoiceNumber: number;
  lastVoucherNumber: number;
  invoiceNumberPrefix?: string;
  invoiceNumberSuffix?: string;
}

export interface FacilitySettingsEntity {
  workDayStart: string;
  workDayEnd: string;
  workDays: number;
  invoiceMail: string;
  reportMail: string;
  allowExternalApi: boolean;
  externalApiToken: string;
  mailSettings?: MailSettings;
  invoiceSettings: FacilityInvoiceSettings;
}

export interface ClientSettingsTimeTrackingType {
  active: boolean;
  mandatory: boolean;
  responsibleUser?: PickedUserEntity;
}

export interface ClientInvoiceSettings {
  active: boolean;
}

export interface ClientCustomerSettings {
  active: boolean;
}

export interface ClientOrderSettings {
  serviceTypeDefinition: OrderServiceType[];
  reportDefinition: ReportConfig[];
  autoExternalId: boolean;
  // prefixFacilityInitial: If true we add the facility initial as prefix to the auto generated commission number
  prefixFacilityInitial: boolean;
  enableTimeTracking: boolean;
}

export interface ClientSettingsType {
  forceRebuildIndex: boolean;
  allowUseFacilities: boolean;
  invoice: ClientInvoiceSettings;
  customer: ClientCustomerSettings;
  timeTracking: ClientSettingsTimeTrackingType;
  order: ClientOrderSettings;
}

export interface Subscription {
  subscriptionId: string;
  invoiceNumber: string;
  type: SubscriptionType;
  period: SubscriptionPeriod;
  autoRenew: boolean;
  basicPrice: number;
  users: number;
  pricePerUser: number;
  startDate: string;
  endDate: string;
  createdDate: string;
  active: boolean;
  payed: boolean;
  rebate: number;
  overallPrice: number;
  isUpdateSubscription: boolean;
  voucherCode?: string;
  invoiceAddress: {
    name: string;
    zip: string;
    city: string;
    street: string;
    streetNo: string;
    vat: string;
    mail: string;
  };
}

export interface ClientEntity {
  clientId?: string;
  mainFacility: string;
  adminUserId: string;
  createdDate?: Timestamp;
  registerDate?: Timestamp;
  partnerId?: string;
  settings: ClientSettingsType;
  active: boolean;
}

export interface ArticleStorageStockEntity {
  storageId: string;
  onStock?: number;
  storageLocation?: string;
}

export interface ArticlePartnerEntity {
  partnerClientId: string;
  partnerFacilityId: string;
  facilityId?: string;
  salesPriceNetto: string;
}

export interface ArticleEntity {
  articleId?: string;
  caption: string;
  description?: string;
  storage?: ArticleStorageStockEntity[];
  partner?: ArticlePartnerEntity[];
  eanCode?: string;
  externalId?: string;
  purchasePriceNetto?: string;
  salesPriceNetto?: string;
  allowReportUsage: boolean;
}

export interface DefaultValidationResult<T> {
  isValid: boolean;
  errors: T;
}

/**
 * Type for message added to a user inbox
 */
export interface MessageEntity {
  messageId?: string;
  subject: string;
  message: string;
  link?: string;
  linkCaptionKey?: string; // Translation key for the button caption to be displayed
  read: boolean;
  readDate?: Timestamp;
  mailSend: boolean;
  mailSendDate?: Timestamp;
  isSystemMessage: boolean;
  sender: PickedUserEntity;
  receivers: Array<PickedUserEntity>;
  previousMessage?: MessageEntity;
  attachments?: Array<DocumentEntity>;
  parameters?: object;
  template?: SystemMessageTemplate;
  createdDate: Timestamp;
}

export interface AbsenceEntity {
  absenceId?: string;
  userId: string;
  user: PickedUserEntity;
  startDate: any;
  endDate: any;
  dates: Array<string>;
  workDays: number;
  type: AbsenceType;
  comment?: string;
  approved: boolean;
  color?: string;
  approvedUserId?: string;
  createdDate: Timestamp;
  createdUserId: string;
}

export interface TaskEntity {
  taskId?: string;
  createdUser?: PickedUserEntity; // Only needed if task is created for another user
  assignedUser: PickedUserEntity;
  caption: string;
  description?: string;
  order?: {
    clientId: string;
    orderId: string;
    externalId: string;
  };
  dueDateTime: Timestamp | Date;
  reminderMinutes: number;
  dueDateTimeFormatted: string; // Just a helper to display the datetime in a translation
  priority: TaskPriority;
  finished: boolean;
  finishedDateTime: Timestamp | Date | FieldValue;
  linkType?: TaskLinkType;
  linkId?: string;
  createdDate: Timestamp | Date | FieldValue;
  executeTrigger: boolean;
}

export interface TimeTrackingEntity {
  id?: string;
  timestamp: any;
  temporary: boolean;
  temporaryTimestamp?: Date | Timestamp;
  temporaryComment?: string;
  type: TimeTrackingType;
  device: DeviceType;
  manual?: boolean;
  manualCreatedUser?: PickedUserEntity;
  location?: LocationPoint;
}

export interface GroupedTimeTracking {
  date: string;
  minutes: number;
  data: Array<TimeTrackingEntity>;
}

export interface TimeTrackingStateEntity extends TimeTrackingEntity {
  user: PickedUserEntity;
}

export interface GroupType {
  key: GroupKey;
  name: string;
  description: string;
  short: string;
  accessNodes: Array<{ path: AccessPath; allow: boolean }>;
}

export interface StorageAddressEntity extends Address {
  storageId?: string;
  name: string;
  contactPhone?: string;
  contactName?: string;
  gln?: string;
  comment?: string;
  allowExternalStorage: boolean;
}

export interface ServiceFilter {
  dateFrom: string;
  dateTo: string;
  serviceTypes: string[]; // The key value of the OrderServiceType!
  hideUnscheduled: boolean;
  hideScheduled: boolean;
  finishedOnly: boolean;
  unsuccessfulOnly: boolean;
  openOnly: boolean;
}

export enum GroupKey {
  MONTEUR = 'MONTEUR',
  ADMIN = 'ADMIN',
  DISPO = 'DISPOSITION',
  MANAGEMENT = 'MANAGEMENT',
  DEALER = 'DEALER',
  DRIVER = 'DRIVER',
  TIMETRACKING = 'TIMETRACKING',
}

export const defaultAllowedDocumentExtensions = ['pdf', 'png', 'jpg', 'jpeg', 'bmp', 'doc', 'docx'];

export interface TourTemplateEntity {
  tourTemplateId?: string;
  description: string;
  name: string;
  resources?: ResourceEntity[];
  users?: PickedUserEntity[];
  facilityIds: string[];
}

export enum ResourceEntityTypes {
  machine = 'MACHINE',
  riser = 'RISER',
  trailer = 'TRAILER',
  vehicle = 'VEHICLE',
}

export interface ResourceEntity {
  description: string;
  facilityIds: string[];
  name: string;
  resourceId?: string;
  type: ResourceEntityTypes;
  externalId?: string;
}

export interface MailSettings {
  useInternalService: boolean; // If true internal SendGrid will be used to send mail
  host: string;
  port: number;
  mail: string;
  user: string;
  password: string;
  validated: boolean;
  displayName?: string;
}

export interface MailEntity {
  to: string;
  subject: string;
  text: string;
  html?: string;
  attachments?: DocumentEntity[];
}

export interface AbstractMedia extends FullMetadata {
  downloadUrl: string;
}
