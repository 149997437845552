import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { STORAGE_PARTNER_ID } from '../../../Globals/Types/Constants';

/**
 * LandingPagePartnerRedirect()
 * @constructor
 */
export default function LandingPagePartnerRedirect() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirect = new URLSearchParams(search).get('redirect');

  React.useEffect(() => {
    localStorage.setItem(STORAGE_PARTNER_ID, id);

    if (redirect) {
      if (redirect === 'register') {
        navigate('/public/register');
      } else {
        navigate('/public/startpage');
      }
    } else {
      navigate('/public/startpage');
    }
  }, [id, navigate, redirect]);

  return null;
}
