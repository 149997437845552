import React from 'react';
import Panel from '../../../Components/Panel';
import styled from 'styled-components';
import Input, { InputType } from '../../../Components/Inputs/Input';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import validateRegister, { RegisterValidateErrors } from './Validator';
import { UserEntity } from '../../../Globals/Types/Types';
import { RegisterClientFacilityType, useDispatchLogin, useDispatchRegister } from '../../../Redux/Actions/AuthAction';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';
import PasswordStrengthBar from 'react-password-strength-bar';
import { PASSWORD_MIN_LENGTH } from '../../../Globals/Globals';
import { CountryCode } from '../../../Globals/Types/Enums';
import SelectCountry from '../../../Components/PredefinedSelects/SelectCountry';
import SelectCompanyType from '../../../Components/PredefinedSelects/SelectCompanyType';
import SelectYesNo from '../../../Components/PredefinedSelects/SelectYesNo';
import { TemplateTypes } from '../../../Globals/Hooks/ServiceTypeTemplates';
import { STORAGE_PARTNER_ID } from '../../../Globals/Types/Constants';
import { isMobile } from 'react-device-detect';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import RegisterMobileHint from './RegisterMobileHint';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-top: 40px;

  .InnerContainer {
    width: 800px;
  }

  @media (max-width: 1350px) {
    .InnerContainer {
      width: 100%;
    }
  }
`;

export interface State {
  name: string;
  countryCode: CountryCode;
  companyType: TemplateTypes;
  hasFacilities: boolean;
  nameShort: string;
  firstname: string;
  lastname: string;
  mail: string;
  password: string;
  repeat: string;
  errors: RegisterValidateErrors;
}

type Action =
  | { type: 'set'; payload: { key: string; value: string | boolean } }
  | { type: 'code'; payload: string }
  | { type: 'resetErrors' }
  | { type: 'setErrors'; payload: Object };

const initialState: State = {
  name: '',
  countryCode: CountryCode.DE,
  hasFacilities: false,
  companyType: null,
  nameShort: '',
  firstname: '',
  lastname: '',
  mail: '',
  password: '',
  repeat: '',
  errors: {},
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'set': {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    case 'resetErrors':
      return { ...state, errors: {} };
    case 'setErrors':
      return { ...state, errors: action.payload };
  }
};

/**
 * Register()
 * @constructor
 */
export default function Register() {
  const [success, setSuccess] = React.useState<boolean>(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [t] = useTranslation();
  const { isLoading, setLoading, loadingModal } = useLoadingModal({ delayStart: 200 });
  const [scoreWords] = React.useState([
    t('passwordStrength.weakest'),
    t('passwordStrength.weak'),
    t('passwordStrength.okay'),
    t('passwordStrength.good'),
    t('passwordStrength.veryGood'),
  ]);

  const dispatchRegister = useDispatchRegister();
  const dispatchLogin = useDispatchLogin();

  const handleSave = () => {
    const validateResult = validateRegister(state);

    if (validateResult.isValid) {
      const client: RegisterClientFacilityType = {
        name: state.name,
        nameShort: state.name.substring(0, 4).toUpperCase(),
        countryCode: state.countryCode,
        partnerId: localStorage.getItem(STORAGE_PARTNER_ID) || null,
      };
      const user: UserEntity = {
        firstName: state.firstname,
        lastName: state.lastname,
        mail: state.mail,
        countryCode: state.countryCode,
      } as UserEntity;

      setLoading(true);
      return dispatchRegister(client, user, state.password, state.hasFacilities, state.companyType)
        .then((response: any) => {
          const { status, errorCode } = response.data;
          setLoading(false);

          if (status) {
            if (!isMobile) {
              return dispatchLogin(user.mail, state.password);
            } else {
              setSuccess(true);
            }
          } else {
            if (errorCode === InternalErrorCodes.USER_ALREADY_EXISTING) {
              dispatch({ type: 'setErrors', payload: { mail: errorCode } });
            } else if (errorCode === InternalErrorCodes.CLIENT_ALREADY_EXISTING) {
              dispatch({ type: 'setErrors', payload: { name: errorCode } });
            }
          }
        })
        .finally(() => setLoading(false));
    } else {
      dispatch({ type: 'setErrors', payload: validateResult.errors });
    }
  };

  return (
    <OuterContainer>
      <div className="InnerContainer">
        <RegisterMobileHint visible={success} />

        <ShowHideContainer visible={!success}>
          <Panel
            smallHeadline={false}
            headline={t('modules.register.headline')}
            description={t('modules.register.description')}
          >
            <form autoComplete="none">
              <Row>
                <Col lg={12}>
                  <Input
                    label={t('companyName')}
                    onChange={(value: string) => dispatch({ type: 'set', payload: { key: 'name', value } })}
                    value={state.name}
                    type={InputType.text}
                    required
                    hasError={!!state.errors.name}
                    disabled={isLoading}
                  />
                </Col>
                <Col xxl={12}>
                  <SelectCompanyType
                    onChange={(value: string) => dispatch({ type: 'set', payload: { key: 'companyType', value } })}
                    selected={state.companyType}
                    hasError={!!state.errors.companyType}
                  />
                </Col>
                <Col xxl={12}>
                  <SelectYesNo
                    label={t('modules.register.hasFacilities')}
                    onChange={(value) => dispatch({ type: 'set', payload: { key: 'hasFacilities', value } })}
                    initialValue={state.hasFacilities}
                    contextHelpKey={'registerFacilities'}
                  />
                </Col>
                <Col lg={12}>
                  <SelectCountry
                    onChange={(value: string) => dispatch({ type: 'set', payload: { key: 'countryCode', value } })}
                    selected={state.countryCode}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <Col lg={12}>
                  <Input
                    label={t('firstName')}
                    onChange={(value: string) => dispatch({ type: 'set', payload: { key: 'firstname', value } })}
                    value={state.firstname}
                    type={InputType.text}
                    required
                    hasError={!!state.errors.firstname}
                    disabled={isLoading}
                  />
                </Col>
                <Col lg={12}>
                  <Input
                    label={t('lastName')}
                    onChange={(value: string) => dispatch({ type: 'set', payload: { key: 'lastname', value } })}
                    value={state.lastname}
                    type={InputType.text}
                    required
                    hasError={!!state.errors.lastname}
                    disabled={isLoading}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Input
                    label={t('eMailAddress')}
                    onChange={(value: string) =>
                      dispatch({ type: 'set', payload: { key: 'mail', value: value.trim() } })
                    }
                    value={state.mail}
                    type={InputType.text}
                    required
                    hasError={!!state.errors.mail}
                    disabled={isLoading}
                  />
                </Col>
                <Col lg={12} style={{ marginTop: 30 }}>
                  <Input
                    label={t('password')}
                    onChange={(value: string) =>
                      dispatch({ type: 'set', payload: { key: 'password', value: value.trim() } })
                    }
                    value={state.password}
                    type={InputType.password}
                    required
                    hasError={!!state.errors.password}
                    disabled={isLoading}
                  />
                </Col>
                <Col lg={12}>
                  <Input
                    label={t('passwordRepeat')}
                    onChange={(value: string) =>
                      dispatch({ type: 'set', payload: { key: 'repeat', value: value.trim() } })
                    }
                    value={state.repeat}
                    type={InputType.password}
                    required
                    hasError={!!state.errors.repeat}
                    disabled={isLoading}
                  />
                </Col>
                <Col lg={12}>
                  <PasswordStrengthBar
                    password={state.password}
                    minLength={PASSWORD_MIN_LENGTH}
                    scoreWords={scoreWords}
                    shortScoreWord={t('passwordStrength.tooShort')}
                  />
                </Col>
              </Row>
            </form>

            <hr />

            <Row>
              <Col>
                <Button variant="success" onClick={handleSave} className="float-end" disabled={isLoading}>
                  {t('registerForFree')}
                </Button>
              </Col>
            </Row>
          </Panel>

          {loadingModal}
        </ShowHideContainer>
      </div>
    </OuterContainer>
  );
}
