import React from 'react';
import Table from '../../../../../Components/Table/Table';
import { useTranslation } from 'react-i18next';
import { OrderServiceType } from '../../../../../Globals/Types/OrderTypes';
import Row from './Row';

interface Props {
  types: OrderServiceType[];
}

/**
 * ListView()
 * @param props
 * @constructor
 */
export default function ListView(props: Props) {
  const { types } = props;
  const [t] = useTranslation();

  const renderContent = () => {
    if (types && types.length > 0) {
      const copiedTypes = [...types];
      return copiedTypes
        .sort((a, b) => (a.caption.toLowerCase().localeCompare(b.caption.toLowerCase()) ? -1 : 1))
        .map((type) => <Row type={type} key={type.key} isDeletable={types.length > 1} />);
    }
    return null;
  };

  if (types && types.length > 0) {
    return (
      <Table>
        <thead>
          <tr>
            <th style={{ width: 40 }} />
            <th>{t('caption')}</th>
            <th style={{ width: 100 }}></th>
          </tr>
        </thead>
        <tbody>{renderContent()}</tbody>
      </Table>
    );
  }
  return null;
}
