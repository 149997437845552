import React from 'react';
import {
  ElasticSearchServiceDispositionInterface,
  ElasticSearchServiceInterface,
} from '../../../Globals/Types/OrderTypes';
import styled, { useTheme } from 'styled-components';
import Text from '../../Text';
import { customerDisplayName, formatTime } from '../../../Globals/Functions';
import {
  getServiceIconColorByReportState,
  useGetServiceIconByReportState,
} from '../../../Globals/Functions/ServiceFunctions';
import moment from 'moment';
import { darken } from 'polished';

const Container = styled.div<{ $iconColor: string }>`
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: ${(props) => props.theme.border.radius};
  margin-bottom: 10px;
  padding: 15px;
  background-color: ${(props) => darken(0.03, props.theme.color.backgroundGray)};
  align-items: center;

  &:hover {
    ${(props) => props.theme.boxShadowDark};
  }

  > .First {
    flex: 1 1 0;
  }

  > .Middle {
    flex: 1 1 0;
  }

  > .Right {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  i {
    font-size: 30px;
    margin-right: 20px;
    margin-left: 10px;
    color: ${(props) => props.$iconColor};
  }
`;

interface Props {
  service: ElasticSearchServiceInterface;
  onPress: (service: ElasticSearchServiceInterface) => void;
}

/**
 * Item()
 * @param props
 * @constructor
 */
export default function Item(props: Props) {
  const { service, onPress } = props;
  const [tour, setTour] = React.useState<ElasticSearchServiceDispositionInterface>(null);
  const theme = useTheme();
  const getIcon = useGetServiceIconByReportState();

  React.useEffect(() => {
    const found = service.disposition.find((item) => moment(item.date).isSame(moment(), 'date'));
    setTour(found);
  }, [service.disposition]);

  const getTourName = () => (tour ? tour.tourName : null);

  const getTourUsers = () => (tour && tour.users ? tour.users.map((user) => user.initials).join(', ') : '-');

  const getTourTimes = () => {
    if (tour) {
      return `${formatTime(tour.startTime)} - ${formatTime(tour.endTime)}`;
    }
    return null;
  };

  return (
    <Container $iconColor={getServiceIconColorByReportState(service, theme)} onClick={() => onPress(service)}>
      <i className={getIcon(service)} />

      <div className="First">
        <Text noWrap bold size={15}>
          {service.externalOrderId}
        </Text>
        <Text light>{service.serviceType.caption}</Text>
      </div>
      <div className="Middle">
        <Text>{customerDisplayName(service.customer)}</Text>
        <Text light>{`${service.customer.zip} ${service.customer.city}`}</Text>
      </div>
      <div className="Right">
        <Text>{`${getTourName()} (${getTourUsers()})`}</Text>
        <Text light>{getTourTimes()}</Text>
      </div>
    </Container>
  );
}
