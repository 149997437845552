import { CountryCode } from './Enums';

export enum MailTemplateType {
  customerInvoice = 'customerInvoice',
  report = 'report',
  reportPublicLink = 'reportPublicLink',
}

export interface MailTemplate {
  templateId: string;
  type: MailTemplateType;
  templates: MailLanguageTemplate[];
}

export interface MailLanguageTemplate {
  countryCode: CountryCode;
  subject: string;
  message: string;
  isDefault: boolean;
}

export const TemplatePlaceholders = {
  [MailTemplateType.customerInvoice]: ['name', 'invoiceNumber', 'facilityName'],
  [MailTemplateType.report]: ['name', 'externalId', 'date', 'facilityName', 'url', 'code'],
  [MailTemplateType.reportPublicLink]: ['externalId', 'date', 'facilityName', 'url', 'code'],
};
