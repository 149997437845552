import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { OfferEntity } from '../../../../Globals/Types/OfferTypes';
import OfferDownloadButton from './OfferDownloadButton';
import OfferCreateOrderButton from './OfferCreateOrderButton';
import OfferDeleteButton from './OfferDeleteButton';
import OfferEditButton from './OfferEditButton';

interface Props {
  offer: OfferEntity;
  onDownloading: (loading: boolean) => void;
}

/**
 * DetailsActionButton()
 * @param props
 * @constructor
 */
export default function DetailsActionButton(props: Props) {
  const { offer, onDownloading } = props;
  const [t] = useTranslation();

  return (
    <Dropdown align="end">
      <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ margin: 0 }}>
        {t('offer')}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <OfferDownloadButton offer={offer} onDownloading={onDownloading} />
        <OfferCreateOrderButton offer={offer} />
        <Dropdown.Divider />
        <OfferEditButton offer={offer} />
        <OfferDeleteButton offer={offer} />
      </Dropdown.Menu>
    </Dropdown>
  );
}
