import React from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '../../../../Components/Panel';
import { FacilitySettingsEntity } from '../../../../Globals/Types/Types';
import Input, { InputType } from '../../../../Components/Inputs/Input';

type ExternalApiPanelProps = {
  data: FacilitySettingsEntity;
};

/**
 * ExternalApiPanel()
 * @constructor
 */
export default function ExternalApiPanel(props: ExternalApiPanelProps) {
  const { data } = props;
  const [t] = useTranslation();

  if (data.allowExternalApi) {
    return (
      <Panel headline={t('externalApi')} description={t('modules.settings.externalApiDescription')}>
        <Input
          type={InputType.textarea}
          onChange={() => {}}
          disabled
          label={t('securityKey')}
          value={data.externalApiToken}
          rows={2}
        />
      </Panel>
    );
  }
  return null;
}

ExternalApiPanel.defaultProps = {
  errors: {},
  withPanel: true,
};
