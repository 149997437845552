import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import DisplayText from '../../../Components/View/DisplayText';
import { formatSize } from '../../../Globals/Functions';
import Input, { InputType } from '../../../Components/Inputs/Input';
import { useDispatchCustomerMediaUpload } from '../../../Redux/Actions/Customer/MediaAction';
import { CustomerMediaMetaData } from '../../../Globals/Types/Customer';

interface Props {
  customerId: string;
  onClose: () => void;
  file?: File;
}

const MAX_FILE_SIZE = 5242880;

/**
 * UploadModal()
 * @constructor
 */
export default function UploadModal(props: Props) {
  const { file, onClose, customerId } = props;
  const [t] = useTranslation();
  const { userId } = useAppSelector((state) => state.auth);
  const { isLoading, setLoading, loadingModal, setTextLine1 } = useLoadingModal({
    headline: t('uploadingFile'),
    textLine1: '0%',
  });
  const [fileName, setFileName] = React.useState<string>(null);
  const [size, setSize] = React.useState<number>(null);
  const [comment, setComment] = React.useState<string>('');
  const dispatchUpload = useDispatchCustomerMediaUpload();

  const handelUpload = React.useCallback(() => {
    const meta: CustomerMediaMetaData = {
      createdUserId: userId,
      comment,
      isReference: 'false',
    };

    setLoading(true);
    dispatchUpload(customerId, file, file.name, meta, (progress) => setTextLine1(`${progress.toFixed(0)}%`)).then(
      () => {
        onClose();
        setLoading(false);
      },
    );
  }, [comment, customerId, dispatchUpload, file, onClose, setLoading, setTextLine1, userId]);

  React.useEffect(() => {
    if (file) {
      setFileName(file.name);
      setSize(file.size);
    }
  }, [file]);

  const renderFileSizeHint = () => {
    if (file.size > MAX_FILE_SIZE) {
      return (
        <Alert variant="danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 8 }} />
          {t('modules.customers.uploadMaxFileSizeHint', { sizeInMb: 5 })}
        </Alert>
      );
    }
    return null;
  };

  if (file !== undefined && file !== null) {
    return (
      <Modal show={true} size="lg">
        <Modal.Header>
          <Modal.Title>{t('mediaUpload')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderFileSizeHint()}
          <Row>
            <Col lg={12}>
              <DisplayText horizontal caption={t('fileName')}>
                {fileName}
              </DisplayText>
            </Col>
            <Col lg={12}>
              <DisplayText horizontal caption={t('fileSize')}>
                {formatSize(size)}
              </DisplayText>
            </Col>
            <Col lg={12}>
              <Input
                onChange={setComment}
                value={comment}
                type={InputType.textarea}
                rows={3}
                label={t('additionalInformation')}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handelUpload} disabled={isLoading || file.size > MAX_FILE_SIZE}>
            {t('upload')}
          </Button>
          <Button variant="outline-secondary" onClick={onClose}>
            {t('cancel')}
          </Button>
        </Modal.Footer>

        {loadingModal}
      </Modal>
    );
  }
  return null;
}
