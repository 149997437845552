import React from 'react';
import {
  collection,
  CollectionReference,
  getFirestore,
  addDoc,
  serverTimestamp,
  deleteDoc,
  doc,
  DocumentReference,
  setDoc,
} from 'firebase/firestore';
import { OfferEntity } from '../../../Globals/Types/OfferTypes';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import {
  OFFER_CREATE_ERROR,
  OFFER_CREATE_PDF_ERROR,
  OFFER_CREATE_PDF_START,
  OFFER_CREATE_PDF_SUCCESS,
  OFFER_CREATE_START,
  OFFER_CREATE_SUCCESS,
  OFFER_DELETE_ERROR,
  OFFER_DELETE_START,
  OFFER_DELETE_SUCCESS,
  OFFER_UPDATE_ERROR,
  OFFER_UPDATE_START,
  OFFER_UPDATE_SUCCESS,
} from '../../ActionTypes';
import { CountryCode } from '../../../Globals/Types/Enums';
import { useDispatch } from 'react-redux';
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * buildCollection()
 * @param clientId
 */
const buildCollection = (clientId: string): CollectionReference<OfferEntity> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.offers,
  ) as CollectionReference<OfferEntity>;
};

/**
 * useDispatchOfferCreate()
 */
type GetListReturnType = (offer: OfferEntity) => Promise<OfferEntity>;
export const useDispatchOfferCreate = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListReturnType>(
    (offer: OfferEntity) => {
      const mergedOffer: OfferEntity = { ...offer, firebaseCreatedDateTime: serverTimestamp() };

      dispatch({ type: OFFER_CREATE_START, payload: { offer: mergedOffer } });

      const offerCollection = buildCollection(clientId);

      return addDoc(offerCollection, mergedOffer)
        .then((response) => {
          dispatch({ type: OFFER_CREATE_SUCCESS, payload: mergedOffer });
          return Promise.resolve(mergedOffer);
        })
        .catch((error) => {
          dispatch({ type: OFFER_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchOfferUpdate()
 */
type UpdateReturnType = (offer: OfferEntity) => Promise<OfferEntity>;
export const useDispatchOfferUpdate = (): UpdateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateReturnType>(
    (offer: OfferEntity) => {
      const mergedOffer: OfferEntity = { ...offer, firebaseCreatedDateTime: serverTimestamp() };

      dispatch({ type: OFFER_UPDATE_START, payload: { offer: mergedOffer } });

      const document = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.offers,
        offer.offerId,
      ) as DocumentReference<OfferEntity>;

      return setDoc(document, mergedOffer)
        .then((response) => {
          dispatch({ type: OFFER_UPDATE_SUCCESS, payload: mergedOffer });
          return Promise.resolve(mergedOffer);
        })
        .catch((error) => {
          dispatch({ type: OFFER_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type DownloadReturnType = (offerId: string, language: CountryCode) => Promise<string>;
/**
 * useDispatchOfferCreatePdf()
 */
export const useDispatchOfferCreatePdf = (): DownloadReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<DownloadReturnType>(
    (offerId, language) => {
      dispatch({ type: OFFER_CREATE_PDF_START, payload: offerId });

      const callback = httpsCallable<{ clientId: string; offerId: string; language: CountryCode }, string>(
        getFunctions(),
        FirebaseFunctionNames.offerCreatePdf,
      );
      return callback({ clientId, offerId, language })
        .then((response) => {
          dispatch({ type: OFFER_CREATE_PDF_SUCCESS, payload: response.data });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({ type: OFFER_CREATE_PDF_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type DeleteReturnType = (offer: OfferEntity) => Promise<OfferEntity>;
/**
 * useDispatchOfferDelete()
 */
export const useDispatchOfferDelete = (): DeleteReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<DeleteReturnType>(
    (offer: OfferEntity) => {
      dispatch({ type: OFFER_DELETE_START, payload: offer });

      const document = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.offers,
        offer.offerId,
      ) as DocumentReference<OfferEntity>;

      return deleteDoc(document)
        .then(() => {
          dispatch({ type: OFFER_DELETE_SUCCESS, payload: offer });
          return Promise.resolve(offer);
        })
        .catch((error) => {
          dispatch({ type: OFFER_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};
