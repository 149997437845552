import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { NoteType } from '../../Globals/Types/Enums';

interface Props {
  onChange: (item: NoteType) => void;
  selected: NoteType;
}

/**
 * SelectNoteType
 * @param props
 * @constructor
 */
export default function SelectNoteType(props: Props) {
  const { onChange, selected } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>(null);

  React.useEffect(() => {
    const optionsParam: SelectOptions = [];
    Object.keys(NoteType).forEach((item) => {
      optionsParam.push({
        label: `${t(`noteTypes.${NoteType[item]}`)} (${NoteType[item]})`,
        value: NoteType[item],
      });
    });

    setOptions(optionsParam);
  }, [t]);

  return <Select label={t('type')} onChange={onChange} initialValue={selected} options={options} />;
}
