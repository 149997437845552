import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useAppSelector, useGetTimeOptions, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import {
  useDispatchTimeTrackingCreate,
  useDispatchTimeTrackingGetLast,
} from '../../../Redux/Actions/User/TimeTrackingAction';
import { TimeTrackingType } from '../../../Globals/Types/Enums';
import styled from 'styled-components';
import { TimeTrackingEntity } from '../../../Globals/Types/Types';
import CustomSwitch from '../../../Components/CustomSwitch';
import moment from 'moment';
import Select from '../../../Components/Inputs/Select';
import DatePicker from '../../../Components/Inputs/DatePicker';
import Input from '../../../Components/Inputs/Input';
import CurrentStateHint from './Components/CurrentStateHint';

type ModalProps = {
  visible: boolean;
  onClose: () => void;
};

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  > .Left {
    flex-grow: 1;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  > .Row {
    display: flex;
    flex-direction: row;
    flex: 1;
    border-radius: 10px;
    ${(props) => props.theme.boxShadowDark};
    cursor: pointer;

    > .Content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-left: 20px;
      justify-content: center;

      > h6 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }

      > p {
        font-size: 14px;
        margin: 0;
        color: ${(props) => props.theme.color.textGray};
      }
    }

    > .Button {
      width: 100px;
      height: 100px;
      border-radius: 10px 0 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      > i {
        font-size: 30px;
        color: #fff;
      }

      &.Start {
        background-color: ${(props) => props.theme.color.green};
      }

      &.Stop {
        background-color: ${(props) => props.theme.color.red};
      }

      &.Pause {
        background-color: ${(props) => props.theme.color.orange};
      }
    }
  }
`;

/**
 * TimeTrackingModal()
 * @constructor
 */
export default function TimeTrackingModal(props: ModalProps) {
  const { onClose, visible } = props;
  const [t] = useTranslation();
  const [temporary, setTemporary] = React.useState<boolean>(false);
  const [temporaryComment, setTemporaryComment] = React.useState<string>('');
  const [temporaryDate, setTemporaryDate] = React.useState<string>(moment().format('YYYY-MM-DD'));
  const [temporaryTime, setTemporaryTime] = React.useState<string>('');
  const [temporaryTimeError, setTemporaryTimeError] = React.useState<boolean>(false);
  const [time, setTime] = React.useState<string>(moment().format('HH:mm'));
  const { isLoading, setLoading } = useLoadingModal();
  const { options: timeOptions } = useGetTimeOptions(10);
  const intervalRef = React.useRef<NodeJS.Timer>();
  const { lastState } = useAppSelector((state) => state.user.timeTracking);
  const dispatchGetLastEntry = useDispatchTimeTrackingGetLast();
  const dispatchCreate = useDispatchTimeTrackingCreate();

  React.useEffect(() => {
    if (visible) {
      intervalRef.current = setInterval(() => setTime(moment().format('HH:mm')), 20000);
    } else {
      clearInterval(intervalRef.current);
    }
  }, [visible]);

  React.useEffect(() => {
    if (visible) {
      dispatchGetLastEntry().then(() => {});
    }
  }, [dispatchGetLastEntry, visible]);

  React.useEffect(() => {
    if (!visible) {
      setTemporary(false);
      setTemporaryComment('');
      setTemporaryTime(null);
      setTemporaryDate(moment().format('YYYY-MM-DD'));
      setTemporaryTimeError(false);
    }
  }, [visible]);

  const create = (type: TimeTrackingType) => {
    setTemporaryTimeError(false);
    if (temporary && (!temporaryTime || temporaryTime.length === 0)) {
      setTemporaryTimeError(true);
      return;
    }

    if (!isLoading) {
      let temporaryTimestamp = null;
      if (temporary) {
        const dateString = `${temporaryDate} - ${temporaryTime}`;
        temporaryTimestamp = moment(dateString, 'YYYY-MM-DD H:mm').toDate();
      }

      const timeTracking: TimeTrackingEntity = {
        type: type,
        timestamp: null,
        temporary,
        temporaryComment,
        temporaryTimestamp,
        device: null,
      };

      setLoading(true);
      dispatchCreate(timeTracking)
        .then(() => {
          return dispatchGetLastEntry().then(() => {
            setLoading(false);
            onClose();
          });
        })
        .catch(() => setLoading(false));
    }
  };

  const renderStopOrPause = () => {
    if (lastState && lastState.type === TimeTrackingType.start) {
      return (
        <ButtonContainer>
          <div className="Row" onClick={() => create(TimeTrackingType.pause)}>
            <div className="Button Pause">
              <i className="fas fa-pause" />
            </div>
            <div className="Content">
              <h6>{t('modules.settings.timeTracking.pauseTracking')}</h6>
              <p>{t('modules.settings.timeTracking.pauseTrackingDescription')}</p>
            </div>
          </div>
          <div className="Row" style={{ marginTop: 20 }} onClick={() => create(TimeTrackingType.stop)}>
            <div className="Button Stop">
              <i className="fas fa-stop" />
            </div>
            <div className="Content">
              <h6>{t('modules.settings.timeTracking.stopTracking')}</h6>
              <p>{t('modules.settings.timeTracking.stopTrackingDescription')}</p>
            </div>
          </div>
        </ButtonContainer>
      );
    }
    return null;
  };

  const renderStart = () => {
    if (!lastState || lastState.type !== TimeTrackingType.start) {
      return (
        <ButtonContainer>
          <div className="Row" onClick={() => create(TimeTrackingType.start)}>
            <div className="Button Start">
              <i className="fas fa-play" />
            </div>
            <div className="Content">
              <h6>{t('modules.settings.timeTracking.startTracking')}</h6>
              <p>{t('modules.settings.timeTracking.startTrackingDescription')}</p>
            </div>
          </div>
        </ButtonContainer>
      );
    }
    return null;
  };

  const renderTemporary = () => {
    if (temporary) {
      return (
        <Row>
          <Col lg={6}>
            <DatePicker label={t('date')} onChange={setTemporaryDate} initialValue={moment().toDate()} required />
          </Col>
          <Col lg={6}>
            <Select
              label={t('time')}
              onChange={setTemporaryTime}
              options={timeOptions}
              required
              hasError={!!temporaryTimeError}
            />
          </Col>
          <Col lg={12}>
            <Input onChange={setTemporaryComment} label={t('remark')} value={temporaryComment} />
          </Col>
        </Row>
      );
    }
    return null;
  };

  if (visible) {
    return (
      <Modal show={visible} animation={true} size="lg" onHide={onClose}>
        <Modal.Header>
          <Modal.Title style={{ flex: 1 }}>
            <HeadlineContainer>
              <div className="Left">
                <i className="fas da-clock" style={{ marginRight: 10 }} />
                {t('timeTracking')}
              </div>
              <div style={{ marginRight: 10 }}>{`${time} Uhr`}</div>
            </HeadlineContainer>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 30 }}>
          <div style={{ marginBottom: 30 }}>
            <CurrentStateHint />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CustomSwitch
              onChange={setTemporary}
              checked={temporary}
              caption={t('modules.settings.timeTracking.temporaryStamping')}
              description={t('modules.settings.timeTracking.temporaryStampingDescription')}
            />
            {renderTemporary()}
          </div>

          {renderStopOrPause()}
          {renderStart()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onClose} disabled={isLoading}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
