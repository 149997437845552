import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import Panel from '../../../../../Components/Panel';
import Input, { InputType } from '../../../../../Components/Inputs/Input';
import Select from '../../../../../Components/Inputs/Select';
import { selectOptionYesNo } from '../../../../../Globals/Types/General';
import { ArticleEntity } from '../../../../../Globals/Types/Types';
import { CreateOrUpdateError } from '../CreateOrUpdate';

interface Props {
  article: ArticleEntity;
  onChange: (key: string, value: string) => void;
  isLoading: boolean;
  errors: CreateOrUpdateError;
}

/**
 * BasicData()
 * @constructor
 */
export default function BasicData(props: Props) {
  const { article, onChange, isLoading, errors } = props;
  const [t] = useTranslation();

  return (
    <Panel>
      <Row>
        <Col xl={12}>
          <Input
            label={t('caption')}
            onChange={(value) => onChange('caption', value)}
            value={article.caption}
            required
            disabled={isLoading}
            autoFocus
            hasError={!!errors.caption}
          />
        </Col>
        <Col xl={12}>
          <Input
            label={t('description')}
            onChange={(value) => onChange('description', value)}
            value={article.description}
            disabled={isLoading}
            type={InputType.textarea}
            rows={4}
          />
        </Col>
        <Col xl={6}>
          <Input
            label={t('purchasePriceNetto')}
            onChange={(value) => onChange('purchasePriceNetto', value)}
            value={article.purchasePriceNetto}
            disabled={isLoading}
            hasError={!!errors.purchasePriceNetto}
          />
        </Col>
        <Col xl={6}>
          <Input
            label={t('salesPriceNetto')}
            onChange={(value) => onChange('salesPriceNetto', value)}
            value={article.salesPriceNetto}
            disabled={isLoading}
            hasError={!!errors.salesPriceNetto}
          />
        </Col>
        <Col xl={6}>
          <Input
            label={t('eanCode')}
            onChange={(value) => onChange('eanCode', value)}
            value={article.eanCode}
            disabled={isLoading}
            hasError={!!errors.eanCode}
          />
        </Col>
        <Col xl={6}>
          <Input
            label={t('externalId')}
            onChange={(value) => onChange('externalId', value)}
            value={article.externalId}
            disabled={isLoading}
            contextHelpKey="externalId"
          />
        </Col>
        <Col xl={6}>
          <Select
            label={t('allowReportUsage')}
            onChange={(value) => onChange('allowReportUsage', value)}
            options={selectOptionYesNo}
            initialValue={article.allowReportUsage}
            disabled={isLoading}
            contextHelpKey="allowExternalUsage"
          />
        </Col>
      </Row>
    </Panel>
  );
}
