/**
 * IMPORTANT!
 * If new icons will be added they also must be added in the app in function
 * "./src/Library/Functions/WebIconsToAppIcons.ts". Otherwise, the app can not display the icon correctly!
 */

export default [
  'fas fa-tools',
  'fas fa-person-dolly',
  'fas fa-boxes-stacked',
  'fas fa-trash-undo',
  'fas fa-bomb',
  'fas fa-list-check',
  'fas fa-briefcase',
  'fas fa-star',
  'fas fa-truck-fast',
  'fas fa-grid-2',
  'fas fa-truck-front',
  'fas fa-gear',
  'fas fa-hammer',
  'fas fa-trees',
  'fas fa-sprinkler',
  'fas fa-soap',
  'fas fa-globe',
  'fas fa-taxi',
  'fas fa-plane-departure',
  'fas fa-plane-arrival',
  'fas fa-bus',
  'fas fa-van-shuttle',
  'fas fa-car',
  'fas fa-train',
  'fas fa-exclamation-triangle',
  'fas fa-check-circle',
  'fas fa-bolt-lightning',
  'fas fa-truck-flatbed',
];
