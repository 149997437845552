import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { lighten } from 'polished';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.table.highlight};

  .Caption {
    display: flex;
    align-items: center;
    width: 20%;
    min-width: 200px;
    font-size: 16px;
    border-right: 6px solid ${(props) => props.theme.border.color};
    border-bottom: 1px solid ${(props) => props.theme.border.color};
    padding-left: 15px;
  }
`;

const Row = styled.div<{ isToday: boolean }>`
  cursor: pointer;
  display: flex;
  flex: 1;
  width: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-right: 1px solid ${(props) => props.theme.border.color};
  border-bottom: 1px solid ${(props) => props.theme.border.color};
  padding: 10px;
  min-height: 56px;
  font-weight: ${(props) => (props.isToday ? 'bold' : 'normal')};

  background-color: ${(props) => (props.isToday ? lighten(0.05, props.theme.color.primaryColor) : null)};
  color: ${(props) => (props.isToday ? '#ffffff' : null)};

  &:hover {
    font-weight: bold;
    text-decoration: underline;
    color: ${(props) => (props.isToday ? null : props.theme.color.primaryColor)};
  }

  &:last-child {
    border-right: none;
  }
`;

interface Props {
  startDate: string;
  endDate: string;
  onPress: (date: string) => void;
}

/**
 * Headline()
 * @param props
 * @constructor
 */
export default function Headline(props: Props) {
  const { startDate, endDate, onPress } = props;

  const renderDays = () => {
    let currentDate = startDate;
    let dayStrings: string[] = [];

    while (moment(currentDate).isSameOrBefore(moment(endDate))) {
      dayStrings.push(currentDate);
      currentDate = moment(currentDate).add(1, 'day').format('YYYY-MM-DD');
    }

    return dayStrings.map((dayString) => (
      <Row key={dayString} onClick={() => onPress(dayString)} isToday={moment(dayString).isSame(moment(), 'date')}>
        {moment(dayString).format('dd. DD')}
      </Row>
    ));
  };

  return (
    <Container>
      <div className="Caption"></div>
      {renderDays()}
    </Container>
  );
}
