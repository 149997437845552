import React from 'react';
import styled from 'styled-components';
import ToggleButton from '../../../ToggleButton';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export interface RightListViewOnChangeState {
  users: boolean;
  services: boolean;
  resources: boolean;
}

interface Props {
  onChange: (item: RightListViewOnChangeState) => void;
}

/**
 * ToggleRightListViewButtons
 * @param props
 * @constructor
 */
export default function ToggleRightListViewButtons(props: Props) {
  const { onChange } = props;
  const { resources: clientResources } = useAppSelector((state) => state.client);
  const [users, setUsers] = React.useState<boolean>(false);
  const [resources, setResources] = React.useState<boolean>(false);
  const [services, setServices] = React.useState<boolean>(true);

  React.useEffect(() => {
    onChange({ users, resources, services });
  }, [onChange, resources, services, users]);

  const renderResource = () => {
    if (clientResources && clientResources.length > 0) {
      return (
        <ToggleButton bottomMargin={0} onClick={() => setResources(!resources)} icon="fas fa-car" active={resources} />
      );
    }
    return null;
  };

  return (
    <Container>
      <ToggleButton
        bottomMargin={0}
        onClick={() => setServices(!services)}
        icon="fas fa-clipboard-list"
        active={services}
      />
      <ToggleButton bottomMargin={0} onClick={() => setUsers(!users)} icon="fas fa-users" active={users} />
      {renderResource()}
    </Container>
  );
}
