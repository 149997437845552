import React from 'react';
import { OrderEntity } from '../../../../Globals/Types/OrderTypes';
import Panel from '../../../../Components/Panel';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  h3,
  h4,
  h5 {
    margin: 0;
    line-height: 40px;
  }

  small {
    font-size: 16px;
  }

  > .Left {
    flex-grow: 1;
  }

  > .Right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
  }

  > .ActionButton {
    margin-left: 25px;
  }
`;

interface Props {
  order: OrderEntity;
  headlineKey?: string;
  headline?: string;
  actionButton?: React.ReactElement;
}

/**
 * Headline
 * @constructor
 */
export default function Headline(props: Props) {
  const { order, headlineKey, headline, actionButton } = props;
  const [t] = useTranslation();

  const renderButton = () => {
    if (actionButton) {
      return <div className="Right ActionButton">{actionButton}</div>;
    }
    return null;
  };

  if (order) {
    return (
      <Panel>
        <Container>
          <div className="Left">
            <h3>{headline || t(headlineKey)}</h3>
            <small>{t('orderCreatedAt', { date: moment(order.createdDate).format('LLL - LT') })}</small>
          </div>
          <div className="Right">
            <small>{t('commissionNumber')}</small>
            <h3>{order.externalId}</h3>
          </div>
          {renderButton()}
        </Container>
      </Panel>
    );
  }
  return null;
}
