import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../Components/PageHeader';
import Panel from '../../Components/Panel';
import { useDispatchUserTimeTrackingGetByMonth } from '../../Redux/Actions/User/TimeTrackingAction';
import moment from 'moment';
import { GroupedTimeTracking, TimeTrackingEntity } from '../../Globals/Types/Types';
import { parseInt } from 'lodash';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import Table, { TableMappings } from '../../Components/Table/Table';
import UserTimeTrackingTimeRow from '../../Components/Table/CustomCols/UserTimeTrackingTimeRow';
import UserTimeTrackingIconRow from '../../Components/Table/CustomCols/UserTimeTrackingIconRow';
import { convertMinutesToTimeString } from '../../Globals/Functions';
import { Col, Row } from 'react-bootstrap';

const mappings: TableMappings<TimeTrackingEntity> = [
  { key: 'icon', headline: 'state', width: 100, customColElement: UserTimeTrackingIconRow },
  { key: 'time', headline: 'time', customColElement: UserTimeTrackingTimeRow },
];

/**
 * TimeTracking()
 * @constructor
 */
export default function TimeTracking() {
  const [t] = useTranslation();
  const { groupedByDate, lastState } = useAppSelector((state) => state.user.timeTracking);
  const dispatchGetList = useDispatchUserTimeTrackingGetByMonth();
  const [overallMinutes, setOverallMinutes] = React.useState<number>();

  const getData = React.useCallback(() => {
    const month = parseInt(moment().format('M'));
    const year = parseInt(moment().format('YYYY'));

    dispatchGetList(month, year).then(() => {});
  }, [dispatchGetList]);

  React.useEffect(() => {
    if (lastState) {
      getData();
    }
  }, [getData, lastState]);

  React.useEffect(() => {
    let minutes: number = 0;
    if (groupedByDate) {
      groupedByDate.forEach((item) => {
        minutes += item.minutes;
      });
    }
    setOverallMinutes(minutes);
  }, [groupedByDate]);

  const renderDates = (param: GroupedTimeTracking) => {
    return (
      <Panel
        headline={moment(param.date).format('LLL')}
        description={t('modules.settings.timeTracking.subHeadlineUserTimeTracking', {
          time: convertMinutesToTimeString(param.minutes),
        })}
        key={param.date}
      >
        <Table data={param.data} mappings={mappings} keyField={'id'} />
      </Panel>
    );
  };

  const renderOverall = () => {
    if (groupedByDate && overallMinutes) {
      return (
        <Panel>
          <Row>
            <Col lg={8}>
              <h4 style={{ margin: 0 }}>{t('modules.settings.timeTracking.overallWorkTime')}</h4>
            </Col>
            <Col lg={4}>
              <h4 style={{ margin: 0, textAlign: 'right' }}>
                {t('xHours', { time: convertMinutesToTimeString(overallMinutes) })}
              </h4>
            </Col>
          </Row>
        </Panel>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader headline={t('timeTracking')} />
      {renderOverall()}
      {groupedByDate.map((item) => renderDates(item))}
    </>
  );
}
