import { FirebaseStorage, StorageReference, getStorage, ref } from '@firebase/storage';

/**
 * Path mappings for all collections. To build a path for a sub collection, use the defined hooks!
 */
export enum FirebasePathMappings {
  absences = 'absences',
  address = 'address',
  article = 'article',
  attachments = 'attachments',
  client = 'client',
  customer = 'customer',
  documents = 'documents',
  facility = 'facility',
  history = 'history',
  inbox = 'inbox',
  invoice = 'invoice',
  license = 'license',
  locationTracking = 'locationTracking',
  mailTemplates = 'mailTemplates',
  material = 'material',
  media = 'media',
  messages = 'messages',
  notes = 'notes',
  order = 'order',
  offers = 'offers',
  partners = 'partners',
  position = 'position',
  report = 'report',
  resource = 'resource',
  service = 'service',
  storage = 'storage',
  subscription = 'subscription',
  tasks = 'tasks',
  temp = 'temp',
  timeTracking = 'timeTracking',
  timeTrackingState = 'timeTrackingState',
  tour = 'tour',
  tourTemplate = 'tourTemplate',
  user = 'user',
}

export const OrderSubCollections = {
  [FirebasePathMappings.messages]: FirebasePathMappings.messages,
  [FirebasePathMappings.service]: FirebasePathMappings.service,
};

export enum FirebaseFunctionNames {
  clientTimeTrackingCreatePdf = 'internal-clientTimeTrackingCreatePdf',
  createUser = 'internal-createUser',
  deleteUser = 'internal-deleteUser',
  linkingGet = 'internal-linkingGet',
  linkingCreate = 'internal-linkingCreate',
  register = 'internal-register',
  sendMessage = 'internal-sendMessage',
  getPartnerInfos = 'internal-getPartnerInfos',
  createOrder = 'internal-orderCreate',
  updateOrder = 'internal-orderUpdate',
  deleteOrder = 'internal-orderDelete',
  invoiceCreatePdf = 'internal-invoiceCreatePdf',
  invoiceSendMail = 'internal-invoiceSendMail',
  offerCreatePdf = 'internal-offerCreatePdf',
  orderCreateTestData = 'internal-orderCreateTestData',
  serviceRebuildIndex = 'internal-serviceRebuildIndex',
  serviceFilter = 'internal-serviceFilter',
  serviceFilterUnscheduled = 'internal-serviceFilterUnscheduled',
  serviceFilterByCustomer = 'internal-serviceFilterByCustomer',
  serviceGetFromIndex = 'internal-serviceGetFromIndex',
  serviceSearch = 'internal-serviceSearch',
  serviceCreate = 'internal-serviceCreate',
  serviceUpdate = 'internal-serviceUpdate',
  serviceDelete = 'internal-serviceDelete',
  serviceInitializeIndex = 'internal-serviceInitializeIndex',
  subscriptionCreateInvoice = 'internal-subscriptionCreateInvoice',
  tourUpdate = 'internal-tourUpdate',
  tourDelete = 'internal-tourDelete',
  backup = 'internal-backup',
  restore = 'internal-restore',
  sendMail = 'internal-sendMail',
  sendFacilityMailTemplate = 'internal-sendFacilityMailTemplate',
  reportCreatePdf = 'internal-reportCreatePdf',
  reportGet = 'internal-reportGet',
}

/**
 * buildDocumentRef()
 * @param storage
 * @param args
 */
type BuildStoragePathFunction = (storage: FirebaseStorage, ...args: any[]) => StorageReference;
export const buildDocumentRef: BuildStoragePathFunction = (storage, ...args) => {
  let pathBuild = '';
  args.forEach((item) => {
    if (pathBuild.length === 0) {
      pathBuild += item;
    } else {
      pathBuild += `/${item}`;
    }
  });

  return ref(getStorage(), pathBuild);
};
