import React from 'react';
import { Subscription } from '../../../Globals/Types/Types';
import {
  CLIENT_SUBSCRIPTION_CANCEL_ERROR,
  CLIENT_SUBSCRIPTION_CANCEL_START,
  CLIENT_SUBSCRIPTION_CANCEL_SUCCESS,
  CLIENT_SUBSCRIPTION_CREATE_ERROR,
  CLIENT_SUBSCRIPTION_CREATE_INVOICE_ERROR,
  CLIENT_SUBSCRIPTION_CREATE_INVOICE_START,
  CLIENT_SUBSCRIPTION_CREATE_INVOICE_SUCCESS,
  CLIENT_SUBSCRIPTION_CREATE_START,
  CLIENT_SUBSCRIPTION_CREATE_SUCCESS,
  CLIENT_SUBSCRIPTION_GET_ACTIVE_ERROR,
  CLIENT_SUBSCRIPTION_GET_ACTIVE_START,
  CLIENT_SUBSCRIPTION_GET_ACTIVE_SUCCESS,
  CLIENT_SUBSCRIPTION_GETLIST_ERROR,
  CLIENT_SUBSCRIPTION_GETLIST_START,
  CLIENT_SUBSCRIPTION_GETLIST_SUCCESS,
} from '../../ActionTypes';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import { useDispatchInternalGetNextAndUpdateSubscriptionInvoiceNumber } from '../Internal/SettingsAction';
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDocs,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';

/**
 * useDispatchClientSubscriptionCreate()
 */
type CreateReturnType = (subscription: Subscription) => Promise<Subscription>;
export const useDispatchClientSubscriptionCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);
  const dispatchGetInvoiceNumber = useDispatchInternalGetNextAndUpdateSubscriptionInvoiceNumber();

  return React.useCallback<CreateReturnType>(
    (subscription) => {
      dispatch({ type: CLIENT_SUBSCRIPTION_CREATE_START, payload: subscription });

      return dispatchGetInvoiceNumber()
        .then((invoiceNumber) => {
          const mergedSubscription: Subscription = { ...subscription, invoiceNumber };

          const collectionRef = collection(
            getFirestore(),
            FirebasePathMappings.client,
            clientId,
            FirebasePathMappings.subscription,
          );

          return addDoc(collectionRef, mergedSubscription).then(() => {
            dispatch({ type: CLIENT_SUBSCRIPTION_CREATE_SUCCESS, payload: mergedSubscription });
            return Promise.resolve(mergedSubscription);
          });
        })
        .catch((error) => {
          dispatch({ type: CLIENT_SUBSCRIPTION_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, dispatchGetInvoiceNumber],
  );
};

/**
 * useDispatchClientSubscriptionGetActive()
 */
type GetActiveReturnType = (fncClientId?: string) => Promise<Subscription>;
export const useDispatchClientSubscriptionGetActive = (): GetActiveReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);
  const dispatchGetList = useDispatchClientSubscriptionGetList();

  return React.useCallback<GetActiveReturnType>(
    (fncClientId: string = null) => {
      const usedClientId = fncClientId || clientId;
      dispatch({ type: CLIENT_SUBSCRIPTION_GET_ACTIVE_START, payload: usedClientId });

      return dispatchGetList(usedClientId)
        .then((subscriptions) => {
          if (subscriptions) {
            const active = subscriptions.find((item) => item.active);
            dispatch({ type: CLIENT_SUBSCRIPTION_GET_ACTIVE_SUCCESS, payload: active });
            return Promise.resolve(active);
          }

          dispatch({ type: CLIENT_SUBSCRIPTION_GET_ACTIVE_SUCCESS, payload: null });
          return Promise.resolve(null);
        })
        .catch((error) => {
          dispatch({ type: CLIENT_SUBSCRIPTION_GET_ACTIVE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, dispatchGetList],
  );
};

/**
 * useDispatchClientSubscriptionGetList()
 */
type GetListReturnType = (fncClientId?: string) => Promise<Subscription[]>;
export const useDispatchClientSubscriptionGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListReturnType>(
    (fncClientId: string = null) => {
      const usedClientId = fncClientId || clientId;
      dispatch({ type: CLIENT_SUBSCRIPTION_GETLIST_START, payload: usedClientId });

      const collectionRef = collection(
        getFirestore(),
        FirebasePathMappings.client,
        usedClientId,
        FirebasePathMappings.subscription,
      ) as CollectionReference<Subscription>;

      return getDocs(collectionRef)
        .then((snapshot) => {
          if (!snapshot.empty) {
            const items: Subscription[] = [];

            snapshot.forEach((item) => {
              items.push({ ...item.data(), subscriptionId: item.id });
            });

            dispatch({ type: CLIENT_SUBSCRIPTION_GETLIST_SUCCESS, payload: items });
            return Promise.resolve(items);
          }
          dispatch({ type: CLIENT_SUBSCRIPTION_GETLIST_SUCCESS, payload: null });
          return Promise.resolve(null);
        })
        .catch((error) => {
          dispatch({ type: CLIENT_SUBSCRIPTION_GETLIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchClientSubscriptionCancel()
 * Stop auto-renew of the current active subscription
 */
type CancelReturnType = () => Promise<Subscription>;
export const useDispatchClientSubscriptionCancel = (): CancelReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state: any) => state.auth);
  const dispatchGetActive = useDispatchClientSubscriptionGetActive();

  return React.useCallback<CancelReturnType>(() => {
    dispatch({ type: CLIENT_SUBSCRIPTION_CANCEL_START });

    return dispatchGetActive()
      .then((subscription) => {
        const docRef = doc(
          getFirestore(),
          FirebasePathMappings.client,
          clientId,
          FirebasePathMappings.subscription,
          subscription.subscriptionId,
        ) as DocumentReference<Subscription>;

        return updateDoc(docRef, { autoRenew: false }).then(() => {
          const merged: Subscription = { ...subscription, autoRenew: false };
          dispatch({ type: CLIENT_SUBSCRIPTION_CANCEL_SUCCESS, payload: merged });
          return Promise.resolve(merged);
        });
      })
      .catch((error) => {
        dispatch({ type: CLIENT_SUBSCRIPTION_CANCEL_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [clientId, dispatch, dispatchGetActive]);
};

/**
 * useDispatchClientSubscriptionCreateInvoice()
 */
type CreateInvoiceReturnType = (subscription: Subscription) => Promise<string>;
export const useDispatchClientSubscriptionCreateInvoice = (): CreateInvoiceReturnType => {
  const dispatch = useAppDispatch();

  return React.useCallback<CreateInvoiceReturnType>(
    (subscription) => {
      dispatch({ type: CLIENT_SUBSCRIPTION_CREATE_INVOICE_START, payload: subscription });

      const callback = httpsCallable<Subscription, string>(
        getFunctions(),
        FirebaseFunctionNames.subscriptionCreateInvoice,
      );

      return callback(subscription)
        .then((response) => {
          dispatch({ type: CLIENT_SUBSCRIPTION_CREATE_INVOICE_SUCCESS, payload: response.data });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({ type: CLIENT_SUBSCRIPTION_CREATE_INVOICE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};
