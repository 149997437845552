import React from 'react';
import { ElasticSearchServiceInterface } from '../../../Globals/Types/OrderTypes';
import { convertMinutesToTimeString, customerDisplayName, formatDateWithDiff } from '../../../Globals/Functions';
import { useTranslation } from 'react-i18next';
import { useGetServiceFacilityName } from '../../../Globals/Hooks/ServiceHooks';
import styled, { useTheme } from 'styled-components';
import { ReportState } from '../../../Globals/Types/Report';
import {
  getServiceIconColorByReportState,
  useGetServiceIconByReportState,
} from '../../../Globals/Functions/ServiceFunctions';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import Text from '../../Text';
import { useClientHasFacilities, useClientHasPartners } from '../../../Globals/Hooks/ClientHooks';
import { useGetFacilityPartnerName } from '../../../Globals/Hooks/FacilityHooks';

const TableRow = styled.tr<{ $isFinished: boolean; $textColor?: string }>`
  color: ${(props) => props.$textColor || 'inherit'};
`;

const Icon = styled.i<{ $iconColor: string }>`
  color: ${(props) => props.$iconColor || 'inherit'};
  margin-right: 12px;
`;

interface Props {
  service: ElasticSearchServiceInterface;
  onClick: (service: ElasticSearchServiceInterface) => void;
  hideCustomer: boolean;
  hideAddress: boolean;
  hideMontageDate: boolean;
}

/**
 * ServiceTableRow()
 * @param props
 * @constructor
 */
export default function ServiceTableRow(props: Props) {
  const { service, onClick, hideCustomer, hideAddress, hideMontageDate } = props;
  const { clientId } = useAppSelector((state) => state.auth);
  const [t] = useTranslation();
  const theme = useTheme();
  const showFacilities = useClientHasFacilities();
  const showPartners = useClientHasPartners();
  const facilityName = useGetServiceFacilityName(service);
  const getFacilityPartnerName = useGetFacilityPartnerName();
  const getServiceIconByReportState = useGetServiceIconByReportState();

  const getExecutionDate = () => {
    if (service.disposition && service.disposition.length > 0) {
      const formattedDate = `${service.disposition[0].date} 23:59:59`;
      return formatDateWithDiff(formattedDate, true, t);
    } else {
      if (service.desiredDate) {
        return (
          <Text light small italic>
            {formatDateWithDiff(service.desiredDate, true, t)}
          </Text>
        );
      } else if (service.desiredWeek) {
        return (
          <Text light small italic>
            {t('weekNumberShort', { week: service.desiredWeek })}
          </Text>
        );
      }
    }
    return null;
  };

  const renderExecutionDate = () => {
    if (!hideMontageDate) {
      return <td>{getExecutionDate()}</td>;
    }
    return null;
  };

  const renderFacility = () => {
    if (showFacilities) {
      return <td>{facilityName}</td>;
    }
    return null;
  };

  const renderPartner = () => {
    if (showPartners) {
      if (service.createdClientId === clientId && service.ownerClientId !== clientId) {
        return (
          <td>
            <i className="fa-regular fa-arrow-up-from-square" style={{ marginRight: 10 }} />
            {getFacilityPartnerName(service.createdFacilityId, service.ownerFacilityId)}
          </td>
        );
      }
      if (service.ownerClientId === clientId && service.assignedClientId) {
        return (
          <td>
            <i className="fa-regular fa-arrow-up-from-square" style={{ marginRight: 10 }} />
            {getFacilityPartnerName(service.ownerFacilityId, service.assignedFacilityId)}
          </td>
        );
      }
      if (service.createdClientId !== clientId && service.ownerClientId === clientId) {
        return (
          <td>
            <i className="fa-regular fa-arrow-down-to-square" style={{ marginRight: 10 }} />
            {getFacilityPartnerName(service.ownerFacilityId, service.createdFacilityId)}
          </td>
        );
      }
      if (service.assignedClientId === clientId) {
        return (
          <td>
            <i className="fa-regular fa-arrow-down-to-square" style={{ marginRight: 10 }} />
            {getFacilityPartnerName(service.assignedFacilityId, service.ownerFacilityId)}
          </td>
        );
      }
      return <td />;
    }
    return null;
  };

  const renderCustomer = () => {
    if (!hideCustomer) {
      return <td>{customerDisplayName(service.customer)}</td>;
    }
    return null;
  };

  const renderAddress = () => {
    if (!hideAddress) {
      return <td>{`${service.customer.zip} ${service.customer.city}`}</td>;
    }
    return null;
  };

  const renderExternalId = () => {
    if (service.isRecurring) {
      return (
        <div style={{ alignItems: 'center', display: 'flex' }}>
          {service.externalOrderId} <i className="fas fa-arrows-rotate" style={{ fontSize: 10, marginLeft: 8 }} />
        </div>
      );
    }
    return service.externalOrderId;
  };

  const renderAssemblyTime = () => {
    if (service.estimatedAssemblyTime && service.estimatedAssemblyTime > 0) {
      return `${convertMinutesToTimeString(service.estimatedAssemblyTime)}h`;
    }
    return null;
  };

  const getRowColor = () => {
    if (
      (service.reportState === ReportState.none &&
        service.ownerClientId !== clientId &&
        service.createdClientId === clientId) ||
      (service.reportState === ReportState.none && service.assignedClientId && service.assignedClientId !== clientId)
    ) {
      return theme.color.textGray;
    }
    return null;
  };

  return (
    <TableRow
      key={service.serviceId}
      onClick={() => onClick(service)}
      $isFinished={service.reportState === ReportState.complete}
      $textColor={getRowColor()}
    >
      <td>
        <Icon
          className={getServiceIconByReportState(service)}
          $iconColor={getServiceIconColorByReportState(service, theme)}
        />
        {service.serviceType.caption}
      </td>
      <td>{renderExternalId()}</td>
      {renderExecutionDate()}
      <td>{renderAssemblyTime()}</td>
      {renderCustomer()}
      {renderAddress()}
      {renderFacility()}
      {renderPartner()}
    </TableRow>
  );
}
