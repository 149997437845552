import { CustomerEntity, CustomerNoteEntity } from '../Types/Customer';
import { NoteType, Parking, Salutation } from '../Types/Enums';
import { TFunction } from 'i18next';
import { OrderCustomerAddress } from '../Types/OrderTypes';
import { useTheme } from 'styled-components';
import React from 'react';
import { darken, lighten } from 'polished';

/**
 * Checks if a customer has social media entries available
 * @param customer
 */
export const customerHasSocialMedia = (customer: CustomerEntity): boolean => {
  return !!(
    customer &&
    (customer.facebook ||
      customer.twitter ||
      customer.linkedIn ||
      customer.xing ||
      customer.instagram ||
      customer.skype ||
      customer.whatsApp ||
      customer.tikTok)
  );
};

/**
 * Checks if a customer has contacts available
 * @param customer
 */
export const customerHasContacts = (customer: CustomerEntity): boolean => {
  return !!(
    customer &&
    (customer.phonePrivate ||
      customer.phoneBusiness ||
      customer.mobileBusiness ||
      customer.mobilePrivate ||
      customer.mailBusiness ||
      customer.mailPrivate ||
      customer.website)
  );
};

/**
 * Checks if a customer has bank data available
 * @param customer
 */
export const customerHasBankData = (customer: CustomerEntity): boolean => {
  return !!(customer && (customer.bankBic || customer.bankIban || customer.bankName));
};

/**
 * Check if customer has address data
 * @param customer
 */
export const customerHasAddress = (customer: CustomerEntity) => {
  return customer && customer.zip && customer.city && customer.street;
};

/**
 * customerBuildName()
 * @param customer
 * @param nameOnly
 * @param t
 */
export const customerBuildName = (customer: CustomerEntity, nameOnly: boolean, t: TFunction): string => {
  if (customer) {
    if (customer.salutation === Salutation.company) {
      // If companyName is not available use lastName. companyName is normally also written to key lastName
      return customer.companyName || customer.lastName;
    } else {
      if (nameOnly) {
        return `${customer.firstName} ${customer.lastName}`;
      } else {
        const salutationString = t(`salutationType.${customer.salutation}`);
        const prefix = customer.prefix || '';
        return `${salutationString} ${prefix} ${customer.firstName} ${customer.lastName}`;
      }
    }
  }
  return null;
};

/**
 * Return the icon string by salutation of customer
 * @param customer
 */
export const customerGetSalutationIcon = (customer: CustomerEntity): string => {
  switch (customer.salutation) {
    case Salutation.company:
      return 'far fa-building';
    case Salutation.female:
      return 'far fa-person-dress';
    case Salutation.male:
      return 'far fa-person';
  }
};

/**
 * Convert CustomerEntity to OrderCustomerAddress
 * @param customer
 */
export const customerToOrderCustomerAddress = (customer: CustomerEntity): OrderCustomerAddress => {
  return {
    customerId: customer.customerId,
    salutation: customer.salutation,
    firstName: customer.salutation === Salutation.company ? customer.companyName : customer.firstName,
    lastName: customer.salutation === Salutation.company ? '' : customer.lastName,
    mail: customer.mailPrivate || customer.mailBusiness,
    phone: customer.phonePrivate || customer.phoneBusiness,
    mobile: customer.mobilePrivate || customer.mobileBusiness,
    zip: customer.zip,
    city: customer.city,
    street: customer.street,
    streetNo: customer.streetNo,
    comment: '',
    floor: customer.floor,
    hasElevator: false,
    parking: Parking.noInfo,
    notifyBeforeArrival: 15,
    countryCode: customer.country,
  };
};

/**
 * useGetNoteTypeColor()
 */
export const useGetNoteTypeColor = () => {
  const theme = useTheme();
  const [colors] = React.useState<{ type: NoteType; color: string }[]>([
    { type: NoteType.note, color: theme.color.blue },
    { type: NoteType.mailIncome, color: theme.color.orange },
    { type: NoteType.mailOutgoing, color: darken(0.2, theme.color.orange) },
    { type: NoteType.offer, color: theme.color.red },
    { type: NoteType.phone, color: lighten(0.3, theme.color.blue) },
  ]);

  return React.useCallback(
    (type: NoteType) => {
      if (colors) {
        const found = colors.find((item) => item.type === type);
        if (found) {
          return found.color;
        }
      }
      return theme.color.textGray;
    },
    [colors, theme.color.textGray],
  );
};

/**
 * filterCustomerNoteUserAccess()
 * Filters all customer notes by checking if the current user has access to the note
 * @param notes
 * @param currentUserId
 */
export const filterCustomerNoteUserAccess = (
  notes: CustomerNoteEntity[],
  currentUserId: string,
): CustomerNoteEntity[] => {
  return notes.filter((note) =>
    note.accessUsers && note.accessUsers.length > 0 ? note.accessUsers.indexOf(currentUserId) >= 0 : note,
  );
};
