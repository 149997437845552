import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { FirebasePathMappings } from '../../Globals/FirebaseGlobals';
import { LicenseEntity, UserEntity } from '../../Globals/Types/Types';
import React from 'react';
import { browserName, fullBrowserVersion } from 'react-device-detect';
import moment from 'moment';
import { generateGuid } from '../../Globals/Functions';
import { useAppSelector } from '../../Globals/Hooks/Hooks';

const storageName = '@DeviceLicenseKey';

/**
 * localStorageSetUniqueIdentifier()
 * For every browser a unique identifier will be set to handle and identify each browser for licence checks.
 * The identifier should never change, if this happens the device can not be logged out from license storage
 */
export const licenseServiceSetUniqueIdentifier = () => {
  const id = localStorage.getItem(storageName);

  if (!id) {
    localStorage.setItem(storageName, generateGuid());
  }
};

/**
 * licenseServiceGetIdentifier()
 */
export const licenseServiceGetIdentifier = () => {
  return localStorage.getItem(storageName);
};

/**
 * useDispatchLicenseServiceSet()
 * Add or update a license
 */
export const useDispatchLicenseServiceSet = () => {
  return React.useCallback((user: UserEntity) => {
    const collectionRef = doc(
      getFirestore(),
      FirebasePathMappings.client,
      user.clientId,
      FirebasePathMappings.license,
      licenseServiceGetIdentifier(),
    ) as DocumentReference<LicenseEntity>;

    const data: LicenseEntity = {
      userId: user.userId,
      firstName: user.firstName,
      lastName: user.lastName,
      initials: user.initials,
      type: 'web',
      datetime: moment().format('YYYY-MM-DD HH:mm:ss'),
      deviceName: `${browserName} (${fullBrowserVersion})`,
    };

    return setDoc(collectionRef, data, { merge: true });
  }, []);
};

/**
 * useDispatchLicenseServiceDelete()
 * Delete a license by the id
 */
export const useDispatchLicenseServiceDelete = () => {
  return React.useCallback((user: UserEntity) => {
    const collectionRef = doc(
      getFirestore(),
      FirebasePathMappings.client,
      user.clientId,
      FirebasePathMappings.license,
      licenseServiceGetIdentifier(),
    ) as DocumentReference<LicenseEntity>;

    return deleteDoc(collectionRef);
  }, []);
};

/**
 * useDispatchLicenseServiceDeleteByIdentifier()
 * Delete a license manually by its identifier
 */
export const useDispatchLicenseServiceDeleteByIdentifier = () => {
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (identifier: string) => {
      const collectionRef = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.license,
        identifier,
      ) as DocumentReference<LicenseEntity>;

      return deleteDoc(collectionRef);
    },
    [clientId],
  );
};

/**
 * useDispatchLicenseServiceGetList()
 * Get a list of all used licenses of the current client
 */
export const useDispatchLicenseServiceGetList = () => {
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback(() => {
    const collectionRef = collection(
      getFirestore(),
      FirebasePathMappings.client,
      clientId,
      FirebasePathMappings.license,
    ) as CollectionReference<LicenseEntity>;

    return getDocs(collectionRef).then((snapShot) => {
      if (!snapShot.empty) {
        const licenses: LicenseEntity[] = [];
        snapShot.forEach((item) => {
          licenses.push({ ...item.data(), id: item.id });
        });

        return Promise.resolve(licenses);
      }

      return Promise.resolve([]);
    });
  }, [clientId]);
};

/**
 * useDispatchLicenseServiceGetListByUser()
 * Get a list of currently used licenses by userId
 */
export const useDispatchLicenseServiceGetListByUser = () => {
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (userId: string) => {
      const collectionRef = collection(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.license,
      ) as CollectionReference<LicenseEntity>;

      const queryRef = query(collectionRef, where('userId', '==', userId));

      return getDocs(queryRef).then((snapShot) => {
        if (!snapShot.empty) {
          const licenses: LicenseEntity[] = [];
          snapShot.forEach((item) => {
            licenses.push({ ...item.data(), id: item.id });
          });

          return Promise.resolve(licenses);
        }

        return Promise.resolve([]);
      });
    },
    [clientId],
  );
};

/**
 * useDispatchLicenseServiceIsAvailable()
 * Checks if licenses are available. Count must be lowered given count
 */
export const useDispatchLicenseServiceIsAvailable = () => {
  return React.useCallback((clientId: string, maxCount: number) => {
    const identifier = licenseServiceGetIdentifier();

    const collectionRef = collection(
      getFirestore(),
      FirebasePathMappings.client,
      clientId,
      FirebasePathMappings.license,
    ) as CollectionReference<LicenseEntity>;

    return getDocs(collectionRef).then((snapshot) => {
      if (!snapshot.empty) {
        // If a license is already present with the license identifier, the user can log in!
        const found = snapshot.docs.find((item) => item.id === identifier);
        if (!found) {
          return Promise.resolve(snapshot.size < maxCount);
        } else {
          return Promise.resolve(true);
        }
      }
      return Promise.resolve(true);
    });
  }, []);
};
