import { ElasticSearchServiceInterface } from '../../../../../Globals/Types/OrderTypes';
import moment from 'moment/moment';
import { ResourceEntity, UserEntity } from '../../../../../Globals/Types/Types';
import { DispositionReducerByDate } from '../../../../../Redux/Reducer/DispositionReducer';

/**
 * Prepare service display order.
 * Show services with desiredDate on selected date first and sort upcoming next.
 * @param services
 * @param currentDate
 */
export const filterAndSortServices = (
  services: ElasticSearchServiceInterface[],
  currentDate: string,
): ElasticSearchServiceInterface[] => {
  const todayItems = services.filter((item: ElasticSearchServiceInterface) => {
    if (item.desiredDate) {
      return moment(item.desiredDate).isSame(moment(currentDate), 'date');
    }
    return false;
  });
  const otherItems = services
    .filter((item: ElasticSearchServiceInterface) => {
      if (item.desiredDate) {
        return !moment(item.desiredDate).isSame(moment(currentDate), 'date');
      }
      return item;
    })
    .sort((a, b) => {
      if (a.desiredDate && b.desiredDate) {
        return moment(a.desiredDate).isSameOrAfter(moment(b.desiredDate), 'date') ? 1 : -1;
      }
      return -1;
    });

  return [...todayItems, ...otherItems];
};

/**
 * Sort users and remove already planned users for the selected date
 * @param users
 * @param tour
 */
export const filterAndSortUsers = (users: UserEntity[], tour: DispositionReducerByDate): UserEntity[] => {
  if (tour) {
    const userIds = tour.data
      .map((item) => {
        if (item.users) {
          return item.users.map((user) => user.userId);
        }
        return null;
      })
      .flat()
      .filter((item) => item);

    return users.filter((user) => userIds.indexOf(user.userId) < 0 && user.active);
  }

  return users;
};

/**
 * Sort users and remove already planned users for the selected date
 * @param resources
 * @param tour
 */
export const filterAndSortResources = (
  resources: ResourceEntity[],
  tour: DispositionReducerByDate,
): ResourceEntity[] => {
  if (tour) {
    const resourceIds = tour.data
      .map((item) => {
        if (item.resources) {
          return item.resources.map((resource) => resource.resourceId);
        }
        return null;
      })
      .flat()
      .filter((item) => item);

    return resources.filter((resource) => resourceIds.indexOf(resource.resourceId) < 0);
  }

  return resources;
};
