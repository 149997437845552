import React from 'react';
import { Report, ReportState } from '../../../Globals/Types/Report';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import OverviewItem from './OverviewItem';
import Container from '../../Container';

interface Props {
  report: Report;
}

/**
 * Overview()
 * @param props
 * @constructor
 */
export default function Overview(props: Props) {
  const { report } = props;
  const [t] = useTranslation();

  const renderReclamations = () => {
    if (!report.reclamation || report.reclamation.length === 0) {
      return <OverviewItem type="success" textKey={'modules.report.summary.noReclamationsPresent'} />;
    } else {
      return <OverviewItem type="warning" textKey={'modules.report.summary.reclamationPresent'} />;
    }
  };

  const renderDamage = () => {
    if (!report.damage || report.damage.length === 0) {
      return <OverviewItem type="success" textKey={'modules.report.summary.noCustomerDamage'} />;
    } else {
      return <OverviewItem type="warning" textKey={'modules.report.summary.customerDamage'} />;
    }
  };

  const renderPreDamage = () => {
    if (report.preDamage && report.damage && report.damage.length > 0) {
      return <OverviewItem type="warning" textKey={'modules.report.summary.preDamage'} />;
    }
    return null;
  };

  const renderServiceSuccess = () => {
    if (
      (!report.reclamation || report.reclamation.length === 0) &&
      (!report.damage || report.damage.length === 0) &&
      report.customerSignature
    ) {
      return <OverviewItem type="success" textKey={'modules.report.summary.confirmExecution'} />;
    }
    return null;
  };

  if (report.type === ReportState.complete) {
    return (
      <Panel headline={t('overview')}>
        <Container>
          {renderPreDamage()}
          {renderReclamations()}
          {renderDamage()}
          {renderServiceSuccess()}
        </Container>
      </Panel>
    );
  }
  return null;
}
