import React from 'react';
import { TableFieldProps } from './Table';
import { useTranslation } from 'react-i18next';

type TableHeaderProps = {
  headlines: Array<TableFieldProps<any>>;
};

/**
 * Table()
 * @param props
 * @constructor
 */
export default function TableHeader(props: TableHeaderProps) {
  const { headlines } = props;
  const [t] = useTranslation();

  const renderHeader = (item) => {
    return (
      <th key={item.key} style={{ width: item.width || 'auto' }}>
        {item.headline && item.headline.length > 0 ? t(item.headline) : ''}
      </th>
    );
  };

  return (
    <thead>
      <tr>{headlines.map((item) => renderHeader(item))}</tr>
    </thead>
  );
}

TableHeader.defaultProps = {};
