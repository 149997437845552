import { InvoiceAddressEntity, InvoiceEntity, InvoicePositionEntity } from '../../../Globals/Types/Invoice';
import { CountryCode, PaymentType, ProductType, Salutation } from '../../../Globals/Types/Enums';
import {
  ElasticSearchServiceDispositionInterface,
  OrderEntity,
  ServiceEntity,
} from '../../../Globals/Types/OrderTypes';
import moment from 'moment';
import { CustomerEntity } from '../../../Globals/Types/Customer';
import { ReportState } from '../../../Globals/Types/Report';
import { formatDateString } from '../../../Globals/Functions';

export type InvoiceReducerState = {
  invoice: InvoiceEntity;
  isEdit: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'clear' }
  | { type: 'init'; payload: InvoiceEntity }
  | { type: 'invoiceNumber'; payload: string }
  | { type: 'referenceInvoiceNumber'; payload: string }
  | { type: 'facilityId'; payload: string }
  | { type: 'dueDate'; payload: string }
  | { type: 'executionDate'; payload: string }
  | { type: 'accessCode'; payload: number }
  | { type: 'clientId'; payload: string }
  | { type: 'paymentType'; payload: PaymentType }
  | { type: 'address'; payload: InvoiceAddressEntity }
  | { type: 'positions'; payload: InvoicePositionEntity[] }
  | { type: 'isTaxFree'; payload: boolean }
  | { type: 'taxRate'; payload: number }
  | { type: 'initCustomer'; payload: CustomerEntity }
  | { type: 'initService'; payload: { order: OrderEntity; service: ServiceEntity } }
  | { type: 'initOrder'; payload: { order: OrderEntity; dispositions: ElasticSearchServiceDispositionInterface[] } }
  | { type: 'initVoucherInvoice'; payload: InvoiceEntity };

export const initialState: InvoiceReducerState = {
  invoice: {
    invoiceId: null,
    invoiceNumber: null,
    dueDate: moment().add(7, 'd').format('YYYY-MM-DD'),
    executionDate: moment().format('YYYY-MM-DD'),
    clientId: null,
    paymentType: PaymentType.credit,
    address: {
      salutation: Salutation.male,
      firstName: null,
      lastName: null,
      zip: null,
      city: null,
      street: null,
      streetNo: null,
      taxNumber: null,
      countryCode: null,
    },
    positions: [],
    isTaxFree: false,
    taxRate: 21,
    tax: '0',
    priceOverall: '0',
    isVoucher: false,
    createdDate: moment().add(7, 'd').format('YYYY-MM-DD'),
    createdUserId: null,
    payed: false,
    closed: false,
    accessCode: 0,
  },
  isEdit: false,
  hasChanges: false,
};

const calculateTax = (positions: InvoicePositionEntity[]): string => {
  let taxAmount = 0.0;

  positions.forEach((position) => {
    if (position.tax && parseFloat(position.tax) > 0) {
      if (!position.isVoucher) {
        taxAmount = taxAmount + (position.tax ? parseFloat(position.tax) : 0.0);
      } else {
        taxAmount = taxAmount - (position.tax ? parseFloat(position.tax) : 0.0);
      }
    }
  });
  return taxAmount.toFixed(2);
};

/**
 * calculateOverallPrice()
 * @param positions
 */
const calculateOverallPrice = (positions: InvoicePositionEntity[]): string => {
  let price: number = 0;

  positions.forEach((position) => {
    if (position.overallPrice && parseFloat(position.overallPrice) > 0) {
      const formattedOverallPrice = position.overallPrice.replace(',', '.');
      if (parseFloat(formattedOverallPrice) > 0) {
        if (position.isVoucher) {
          price = price - parseFloat(formattedOverallPrice);
        } else {
          price = price + parseFloat(formattedOverallPrice);
        }
      }
    }
  });

  return price.toFixed(2);
};

/**
 * buildPositionsFromService()
 * @param service
 */
const buildPositionsFromService = (service: ServiceEntity): InvoicePositionEntity[] => {
  if (service.positions) {
    return service.positions.map((position, index) => ({
      amount: position.amount,
      caption: position.caption,
      description: position.description || '',
      orderPositionId: position.positionId,
      pricePerAmount: (parseFloat(position.price) / position.amount).toFixed(2),
      overallPrice: position.price ? position.price.replace(',', '.') : '0',
      positionNumber: index + 1,
      isVoucher: false,
      type: position.type || ProductType.service,
      taxRate: position.taxRate || 21,
      tax: ((parseFloat(position.price) * (position.taxRate || 21)) / 100).toFixed(2),
    }));
  }
  return [];
};

/**
 * buildPositionsFromOrder()
 * @param order
 * @param disposition
 */
const buildPositionsFromOrder = (
  order: OrderEntity,
  disposition: ElasticSearchServiceDispositionInterface[],
): InvoicePositionEntity[] => {
  const services = order.services.filter(
    (service) => service.reportState === ReportState.complete && service.positions && service.positions.length > 0,
  );

  return services.map((service, index) => {
    const dispo = disposition.find((dispo) => dispo.serviceId === service.serviceId);
    const positions = buildPositionsFromService(service);
    const taxRate = positions[0].taxRate || 21;
    const overall = calculateOverallPrice(positions);

    return {
      amount: 1,
      caption: `${service.type.caption} ${dispo ? `(${formatDateString(dispo.date)})` : ''}`,
      description: '',
      orderPositionId: service.serviceId,
      pricePerAmount: overall,
      overallPrice: overall,
      positionNumber: index + 1,
      isVoucher: false,
      type: ProductType.service,
      taxRate: taxRate,
      tax: ((parseFloat(overall) * taxRate) / 100).toFixed(2),
    };
  });
};

/**
 * initializeInvoiceByService()
 * @param order
 * @param service
 */
const initializeInvoiceByService = (order: OrderEntity, service: ServiceEntity): InvoiceEntity => {
  const positions: InvoicePositionEntity[] = buildPositionsFromService(service);

  return {
    invoiceId: null,
    invoiceNumber: null,
    customerId: order.customer.customerId,
    clientId: order.ownerClientId,
    facilityId: order.ownerFacilityId,
    services: [
      {
        ownerClientId: order.ownerClientId,
        orderId: order.orderId,
        serviceId: service.serviceId,
        externalId: order.externalId,
      },
    ],
    dueDate: moment().add(7, 'd').format('YYYY-MM-DD'),
    executionDate: moment().format('YYYY-MM-DD'),
    paymentType: PaymentType.credit,
    positions,
    address: {
      salutation: order.customer.salutation,
      firstName: order.customer.firstName,
      lastName: order.customer.salutation === Salutation.company ? order.customer.firstName : order.customer.lastName,
      zip: order.customer.zip,
      city: order.customer.city,
      street: order.customer.street,
      streetNo: order.customer.streetNo,
      countryCode: order.customer.countryCode || CountryCode.ES,
    },
    isTaxFree: false,
    taxRate: 21,
    tax: calculateTax(positions),
    priceOverall: calculateOverallPrice(positions),
    isVoucher: false,
    createdDate: moment().format('YYYY-MM-DD'),
    createdUserId: null,
    payed: false,
    closed: false,
    accessCode: 0,
  };
};

/**
 * initializeInvoiceByOrder()
 * @param order
 * @param dispositions
 */
const initializeInvoiceByOrder = (
  order: OrderEntity,
  dispositions: ElasticSearchServiceDispositionInterface[],
): InvoiceEntity => {
  const services = order.services.filter(
    (service) => service.reportState === ReportState.complete && service.positions && service.positions.length > 0,
  );
  const positions: InvoicePositionEntity[] = buildPositionsFromOrder(order, dispositions);

  return {
    invoiceId: null,
    invoiceNumber: null,
    customerId: order.customer.customerId,
    clientId: order.ownerClientId,
    facilityId: order.ownerFacilityId,
    services: services.map((service) => ({
      ownerClientId: order.ownerClientId,
      orderId: order.orderId,
      serviceId: service.serviceId,
      externalId: order.externalId,
    })),
    dueDate: moment().add(7, 'd').format('YYYY-MM-DD'),
    executionDate: moment().format('YYYY-MM-DD'),
    paymentType: PaymentType.credit,
    positions,
    address: {
      salutation: order.customer.salutation,
      firstName: order.customer.firstName,
      lastName: order.customer.salutation === Salutation.company ? order.customer.firstName : order.customer.lastName,
      zip: order.customer.zip,
      city: order.customer.city,
      street: order.customer.street,
      streetNo: order.customer.streetNo,
      countryCode: order.customer.countryCode || CountryCode.ES,
    },
    isTaxFree: false,
    taxRate: 21,
    tax: calculateTax(positions),
    priceOverall: calculateOverallPrice(positions),
    isVoucher: false,
    createdDate: moment().format('YYYY-MM-DD'),
    createdUserId: null,
    payed: false,
    closed: false,
    accessCode: 0,
  };
};

/**
 * initializeInvoiceByCustomer()
 * @param customer
 */
const initializeInvoiceByCustomer = (customer: CustomerEntity): InvoiceEntity => {
  return {
    invoiceId: null,
    invoiceNumber: null,
    customerId: customer.customerId,
    clientId: null,
    facilityId: customer.defaultFacilityId,
    dueDate: moment().add(7, 'd').format('YYYY-MM-DD'),
    executionDate: moment().format('YYYY-MM-DD'),
    paymentType: PaymentType.credit,
    positions: [],
    address: {
      salutation: customer.salutation,
      firstName: customer.salutation !== Salutation.company ? customer.firstName : customer.companyName,
      lastName: customer.lastName,
      zip: customer.zip,
      city: customer.city,
      street: customer.street,
      streetNo: customer.streetNo,
      taxNumber: customer.taxNumber || null,
      countryCode: customer.country,
      iban: customer.bankIban || null,
    },
    isTaxFree: false,
    taxRate: 21,
    tax: '0',
    priceOverall: '0',
    isVoucher: false,
    createdDate: moment().format('YYYY-MM-DD'),
    createdUserId: null,
    payed: false,
    closed: false,
    accessCode: 0,
  };
};

const initializeByVoucherInvoice = (invoice: InvoiceEntity): InvoiceEntity => {
  const merged: InvoiceEntity = {
    ...invoice,
    invoiceId: null,
    invoiceNumber: null,
    positions: invoice.positions.map((item) => ({ ...item, isVoucher: true })),
    referenceInvoiceNumber: invoice.invoiceNumber,
    isVoucher: true,
  };

  return {
    ...merged,
    tax: calculateTax(merged.positions),
    priceOverall: calculateOverallPrice(merged.positions),
    createdDate: moment().format('YYYY-MM-DD'),
    createdUserId: null,
    payed: false,
    closed: false,
    dueDate: moment().add(7, 'd').format('YYYY-MM-DD'),
    executionDate: moment().format('YYYY-MM-DD'),
  };
};

/**
 * reducer()
 * @param state
 * @param action
 */
export const reducer = (state: InvoiceReducerState, action: Action): InvoiceReducerState => {
  switch (action.type) {
    case 'invoiceNumber':
      return { ...state, invoice: { ...state.invoice, invoiceNumber: action.payload }, hasChanges: true };
    case 'referenceInvoiceNumber':
      return { ...state, invoice: { ...state.invoice, referenceInvoiceNumber: action.payload }, hasChanges: true };
    case 'facilityId':
      return { ...state, invoice: { ...state.invoice, facilityId: action.payload } };
    case 'dueDate':
      return { ...state, invoice: { ...state.invoice, dueDate: action.payload }, hasChanges: true };
    case 'executionDate':
      return { ...state, invoice: { ...state.invoice, executionDate: action.payload }, hasChanges: true };
    case 'paymentType':
      return { ...state, invoice: { ...state.invoice, paymentType: action.payload }, hasChanges: true };
    case 'address':
      return { ...state, invoice: { ...state.invoice, address: action.payload }, hasChanges: true };
    case 'positions': {
      const overallPrice = calculateOverallPrice(action.payload);

      return {
        ...state,
        invoice: {
          ...state.invoice,
          positions: action.payload,
          priceOverall: overallPrice,
          isVoucher: parseFloat(overallPrice) < 0,
          tax: !state.invoice.isTaxFree ? calculateTax(action.payload) : '0',
        },
        hasChanges: true,
      };
    }
    case 'isTaxFree':
      return {
        ...state,
        invoice: {
          ...state.invoice,
          isTaxFree: action.payload,
          tax: action.payload ? '0' : calculateTax(state.invoice.positions),
        },
        hasChanges: true,
      };
    case 'taxRate':
      return { ...state, invoice: { ...state.invoice, taxRate: action.payload }, hasChanges: true };
    case 'init':
      return { ...initialState, invoice: { ...action.payload }, isEdit: true };
    case 'initOrder':
      return {
        ...state,
        invoice: { ...initializeInvoiceByOrder(action.payload.order, action.payload.dispositions) },
        hasChanges: true,
      };
    case 'initService':
      return {
        ...state,
        invoice: { ...initializeInvoiceByService(action.payload.order, action.payload.service) },
        hasChanges: true,
      };
    case 'initCustomer':
      return { ...state, invoice: { ...initializeInvoiceByCustomer(action.payload) } };
    case 'initVoucherInvoice':
      return { ...state, invoice: { ...initializeByVoucherInvoice(action.payload) }, hasChanges: true };
    case 'accessCode':
      return { ...state, invoice: { ...state.invoice, accessCode: action.payload }, hasChanges: true };
    case 'clear':
      return { ...state };

    default:
      throw new Error('Action unknown!');
  }
};
