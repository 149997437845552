import React from 'react';
import { Report } from '../../../../Globals/Types/Report';
import Panel from '../../../Panel';
import { useTranslation } from 'react-i18next';
import DisplayText from '../../../View/DisplayText';
import { convertMinutesToTimeString, formatDateTime, userDisplayName } from '../../../../Globals/Functions';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { TimeTrackingEntity } from '../../../../Globals/Types/Types';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { timeTrackingOrderCalculateOverall } from '../../../../Globals/Hooks/TimeTrackingHooks';

interface Props {
  report: Report;
  timeTracking?: TimeTrackingEntity[];
}

/**
 * Information()
 * @param props
 * @constructor
 */
export default function Information(props: Props) {
  const { report, timeTracking } = props;
  const { enableTimeTracking } = useAppSelector((state) => state.auth.client.settings.order);
  const [start, setStart] = React.useState<string>(null);
  const [end, setEnd] = React.useState<string>(null);
  const [minutes, setMinutes] = React.useState<number>(null);
  const [t] = useTranslation();

  React.useEffect(() => {
    if (enableTimeTracking) {
      if (timeTracking && timeTracking.length > 0) {
        setStart(moment(timeTracking[0].timestamp.toDate()).format('LL - LT'));
        setEnd(moment(timeTracking[timeTracking.length - 1].timestamp.toDate()).format('LL - LT'));
        setMinutes(timeTrackingOrderCalculateOverall(timeTracking));
      }
    }
  }, [enableTimeTracking, timeTracking]);

  const getDuration = () => {
    const minutes = moment(report.endDateTime).diff(moment(report.startDateTime), 'minutes');
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;

    if (hours > 0 && minutesLeft > 0) {
      return `${t('xHours', { count: hours })} ${t('xMinute', { count: minutesLeft })}`;
    } else if (hours > 0 && minutesLeft === 0) {
      return t('xHours', { count: hours });
    } else {
      return t('xMinute', { count: minutesLeft });
    }
  };

  const renderStart = () => {
    if (enableTimeTracking && start) {
      return (
        <Col xl={6} xxl={4}>
          <DisplayText caption={t('workStarted')} icon="fas fa-play-circle">
            {start}
          </DisplayText>
        </Col>
      );
    }
    return (
      <Col xl={6} xxl={4}>
        <DisplayText caption={t('reportStarted')} icon="fas fa-play-circle">
          {moment(report.startDateTime).format('LL - LT')}
        </DisplayText>
      </Col>
    );
  };

  const renderEnd = () => {
    if (enableTimeTracking && end) {
      return (
        <Col xl={6} xxl={4}>
          <DisplayText caption={t('workFinished')} icon="fas fa-stop-circle">
            {end}
          </DisplayText>
        </Col>
      );
    }
    return (
      <Col xl={6} xxl={4}>
        <DisplayText caption={t('reportFinished')} icon="fas fa-stop-circle">
          {moment(report.endDateTime).format('LL - LT')}
        </DisplayText>
      </Col>
    );
  };

  const renderDuration = () => {
    if (enableTimeTracking && minutes) {
      return (
        <Col xl={6} xxl={4}>
          <DisplayText caption={t('reportDuration')} icon="fas fa-stop-circle">
            <>{t('xHours', { time: convertMinutesToTimeString(minutes) })}</>
          </DisplayText>
        </Col>
      );
    }
    return (
      <Col xl={6} xxl={4}>
        <DisplayText caption={t('reportDuration')} icon="fas fa-stopwatch">
          {getDuration()}
        </DisplayText>
      </Col>
    );
  };

  return (
    <Panel headline={t('information')}>
      <Row>
        {renderStart()}
        {renderEnd()}
        {renderDuration()}
        <Col xl={6} xxl={6}>
          <DisplayText caption={t('reportUploaded')} icon="fas fa-cloud-upload">
            {formatDateTime(report.uploadedDateTime)}
          </DisplayText>
        </Col>
        <Col xl={6} xxl={6}>
          <DisplayText caption={t('uploadedFrom')} icon="fas fa-user">
            {userDisplayName(report.createdUser, true)}
          </DisplayText>
        </Col>
      </Row>
    </Panel>
  );
}
