import React from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '../../../../../Components/Panel';
import Table from '../../../../../Components/Table/Table';
import { MailTemplateType } from '../../../../../Globals/Types/Mail';
import { useNavigate, useParams } from 'react-router';
import { buildUrlSettingsFacilityMailTemplateEdit } from '../../../../../Globals/UrlFunctions';

const translationTemplateCaptionKey = 'modules.settings.mailTemplates.templates.{templateName}.name';

/**
 * SettingsPanel()
 * @constructor
 */
export default function SettingsPanel() {
  const [t] = useTranslation();
  const { facilityId } = useParams();
  const navigate = useNavigate();

  const renderRow = () => {
    return Object.keys(MailTemplateType).map((key) => {
      const templateName: MailTemplateType = MailTemplateType[key];

      return (
        <tr
          key={templateName}
          onClick={() => navigate(buildUrlSettingsFacilityMailTemplateEdit(facilityId, templateName))}
        >
          <td>{t(translationTemplateCaptionKey.replace('{templateName}', templateName))}</td>
          <td width={30}>
            <i className="fas fa-pencil" />
          </td>
        </tr>
      );
    });
  };

  return (
    <Panel
      headline={t('modules.settings.mailTemplates.settingsHeadline')}
      description={t('modules.settings.mailTemplates.settingsDescription')}
    >
      <Table onClickRow={() => {}}>
        <thead>
          <tr>
            <th>{t('caption')}</th>
            <th />
          </tr>
        </thead>
        <tbody>{renderRow()}</tbody>
      </Table>
    </Panel>
  );
}
