import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ContactPersonEntity } from '../../../../Globals/Types/OrderTypes';
import Input from '../../../Inputs/Input';
import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import SelectClientUsers from '../../../SelectClientUsers';
import { PickedUserEntity } from '../../../../Globals/Types/Types';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { userDisplayName } from '../../../../Globals/Functions';

interface Error {
  name: string;
  contact: string;
}

interface Props {
  visible: boolean;
  onClose: () => void;
  onAdd: (contact: ContactPersonEntity) => void;
}

export type State = {
  contact: ContactPersonEntity;
  selectedUser: PickedUserEntity;
  disableInputs: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'selectUser'; payload: PickedUserEntity }
  | { type: 'name'; payload: string }
  | { type: 'contact'; payload: string }
  | { type: 'clear' };

const initialState: State = {
  contact: {
    name: '',
    contact: '',
  },
  selectedUser: null,
  disableInputs: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'selectUser':
      return { ...state, selectedUser: action.payload, disableInputs: action.payload !== undefined, hasChanges: true };
    case 'name':
      return { ...state, contact: { ...state.contact, name: action.payload }, hasChanges: true };
    case 'contact':
      return { ...state, contact: { ...state.contact, contact: action.payload }, hasChanges: true };
    case 'clear':
      return { ...initialState };
  }
};

/**
 * validate()
 * @param state
 */
const validate = (state: State): { isValid: boolean; errors: Error } => {
  let errorObject: Error = {} as Error;

  if (!state.selectedUser) {
    if (!state.contact.name || state.contact.name.length <= 0) {
      errorObject = { ...errorObject, name: InternalErrorCodes.FIELD_MISSING };
    }
    if (!state.contact.contact || state.contact.contact.length <= 0) {
      errorObject = { ...errorObject, contact: InternalErrorCodes.FIELD_MISSING };
    }
  }

  return {
    isValid: Object.keys(errorObject).length === 0,
    errors: errorObject,
  };
};

/**
 * AddContact
 * @param props
 * @constructor
 */
export default function AddContact(props: Props) {
  const { visible, onClose, onAdd } = props;
  const [t] = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [errors, setErrors] = React.useState<Error>({} as Error);
  const { all } = useAppSelector((state) => state.client.users);

  React.useEffect(() => {
    if (visible) {
      dispatch({ type: 'clear' });
    }
  }, [visible]);

  const handleSave = () => {
    const { isValid, errors } = validate(state);

    if (isValid) {
      let contactEntry: ContactPersonEntity = null;
      if (state.selectedUser) {
        const user = all.find((item) => item.userId === state.selectedUser.userId);
        if (user) {
          contactEntry = {
            name: userDisplayName(user),
            contact: user.phone || user.mail,
            userId: user.userId,
          };
        }
      } else {
        contactEntry = { ...state.contact };
      }
      onAdd(contactEntry);
      onClose();
    } else {
      setErrors(errors);
    }
  };

  return (
    <Modal show={visible} size="lg">
      <Modal.Header>
        <div>
          <Modal.Title>{t('addContactPerson')}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body style={{ padding: 30 }}>
        <Row>
          <Col lg={12}>
            <SelectClientUsers
              onChange={(value: PickedUserEntity) => dispatch({ type: 'selectUser', payload: value })}
              isMultiSelect={false}
              showUnselectOption={true}
            />
          </Col>
          <Col lg={12}>
            <Input
              onChange={(value) => dispatch({ type: 'name', payload: value })}
              label={t('name')}
              value={state.contact.name}
              autoFocus
              hasError={!!errors.name}
              disabled={state.disableInputs}
            />
          </Col>
          <Col lg={12}>
            <Input
              onChange={(value) => dispatch({ type: 'contact', payload: value })}
              label={t('phoneOrMail')}
              value={state.contact.contact}
              hasError={!!errors.contact}
              disabled={state.disableInputs}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
          {t('save')}
        </Button>
        <Button variant="outline-secondary" onClick={() => onClose()}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
