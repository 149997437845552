import { OrderCustomerAddress } from './OrderTypes';
import { DocumentEntity, LocationPoint, PickedUserEntity } from './Types';
import { PaymentType, ReportPositionState } from './Enums';

export type AvailableReportPages =
  | 'ReportInventory'
  | 'ReportIncomplete'
  | 'ReportPreDamage'
  | 'ReportCustomerData'
  | 'ReportPositionCheck'
  | 'ReportDevices'
  | 'ReportDamageAndReclamation'
  | 'ReportAdditionalWorkAndReturn'
  | 'ReportCompletedImages'
  | 'ReportAdditionalInformation'
  | 'ReportPayment'
  | 'ReportSummary'
  | 'ReportMonteurSignature'
  | 'ReportCustomerSignature'
  | 'ReportSave';

export const reportPagesConfigurable: AvailableReportPages[] = [
  'ReportInventory',
  'ReportPreDamage',
  'ReportCustomerData',
  'ReportPositionCheck',
  'ReportDevices',
  'ReportDamageAndReclamation',
  'ReportAdditionalWorkAndReturn',
  'ReportPayment',
  'ReportAdditionalInformation',
  'ReportCompletedImages',
  'ReportMonteurSignature',
  'ReportCustomerSignature',
];

export const reportPagesSortOrder: AvailableReportPages[] = [
  'ReportInventory',
  'ReportPreDamage',
  'ReportCustomerData',
  'ReportPositionCheck',
  'ReportDevices',
  'ReportDamageAndReclamation',
  'ReportAdditionalWorkAndReturn',
  'ReportPayment',
  'ReportAdditionalInformation',
  'ReportCompletedImages',
  'ReportSummary',
  'ReportMonteurSignature',
  'ReportCustomerSignature',
  'ReportSave',
];

export interface ReportConfig {
  serviceTypeKey: string | 'default' | 'incomplete';
  pageOrder: AvailableReportPages[];
  pageData?: Array<{ key: string; value: string }>;
}

export interface Report {
  reportId?: string;
  createdUser: PickedUserEntity;
  createdClientId: string;
  type: ReportState;
  startDateTime: string;
  startLocation: LocationPoint;
  endDateTime: string;
  endLocation: LocationPoint;
  uploadedDateTime: string;
  uploadedLocation: LocationPoint;
  inventory?: DocumentEntity[];
  preDamage?: PreDamageItem[];
  customerData?: ReportCustomerData;
  pluggedItems?: DefaultItem[];
  unpluggedItems?: DefaultItem[];
  reclamation?: ReclamationItem[];
  damage?: DamageItem[];
  reclamationInfo?: ReclamationInfo;
  additionalWork?: AdditionalWorkItem[];
  returns?: ReturnItem[];
  completedImages?: DocumentEntity[];
  payment?: PaymentInfos;
  positionCheck?: ReportPositionCheckItem[];
  incomplete?: IncompleteInfo;
  additionalInformation?: string;
  monteurSignature?: DocumentEntity;
  customerSignature?: DocumentEntity;
}

export enum ReportState {
  none = 'none',
  incomplete = 'incomplete',
  complete = 'complete',
}

export type ReportCustomerData = Pick<OrderCustomerAddress, 'mail' | 'phone' | 'mobile' | 'comment'>;

export interface PreDamageItem {
  id: string;
  caption: string;
  additionalInformation: string;
  images: DocumentEntity[];
}

export interface DefaultItem {
  id: string;
  caption: string;
  externalId: string; // Gerätenummer / Möbelnummer / EAN ...
  additionalInformation: string;
  isCustomerDevice: boolean;
  images: DocumentEntity[];
  servicePositionId?: string; // PositionId from the service position if position selected from service
}

export interface ReclamationItem {
  id: string;
  caption: string;
  externalId: string;
  additionalInformation: string;
  workOptionElectric: boolean;
  workOptionWater: boolean;
  originator: number; // Enum
  images: DocumentEntity[];
  servicePositionId?: string; // PositionId from the service position if position selected from service
}

export interface DamageItem {
  id: string;
  caption: string;
  additionalInformation: string;
  images: DocumentEntity[];
}

export interface ReclamationInfo {
  reclamationTime: number;
  reclamationMonteurCount: number;
}

export interface AdditionalWorkItem {
  id: string;
  caption: string;
  additionalInformation: string;
  assemblyTime: number;
  workOptionElectric: boolean;
  workOptionWater: boolean;
  images: DocumentEntity[];
  articleNumber?: string;
}

export interface ReturnItem {
  id: string;
  caption: string;
  additionalInformation: string;
  images: DocumentEntity[];
  servicePositionId?: string; // PositionId from the service position if position selected from service
}

export interface PaymentInfos {
  paymentType: PaymentType;
  customerPaidValue: number;
  additionalInformation: string;
  bankOwner: string;
  bankName: string;
  bankBic: string;
  bankIban: string;
}

export enum SignatureType {
  monteur = 'monteur',
  customer = 'customer',
}

export interface IncompleteInfo {
  additionalInformation: string;
  images: DocumentEntity[];
}

export interface ReportPositionCheckItem {
  id: string;
  servicePositionId: string;
  state: ReportPositionState;
  additionalInformation?: string;
  images?: DocumentEntity[];
}
