import { useDispatch } from 'react-redux';
import React from 'react';
import { OrderDocument, OrderEntity, OrderPartnerPermissionAccess } from '../../Globals/Types/OrderTypes';
import {
  ORDER_CREATE_ERROR,
  ORDER_CREATE_START,
  ORDER_CREATE_SUCCESS,
  ORDER_DELETE_ERROR,
  ORDER_DELETE_START,
  ORDER_DELETE_SUCCESS,
  ORDER_GET_ERROR,
  ORDER_GET_START,
  ORDER_GET_SUCCESS,
  ORDER_UPDATE_ERROR,
  ORDER_UPDATE_START,
  ORDER_UPDATE_SUCCESS,
} from '../ActionTypes';
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../Globals/FirebaseGlobals';
import { doc, DocumentReference, getDoc, getFirestore } from 'firebase/firestore';
import { InternalErrorCodes } from '../../Globals/InternalErrorCodes';
import { useDispatchOrderServiceGetList } from './Order/ServiceAction';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { GroupKey, UserEntity } from '../../Globals/Types/Types';
import Lodash from 'lodash';

/**
 * useDispatchOrderCreate
 * @param createTestData
 * @param amount | Max amount 500
 */
type OrderCreateReturnType = (order: OrderEntity, documents: Array<OrderDocument>) => Promise<OrderEntity>;

export const useDispatchOrderCreate = (createTestData: boolean, amount: number): OrderCreateReturnType => {
  const dispatch = useDispatch();

  return React.useCallback<OrderCreateReturnType>(
    (order: OrderEntity, documents: Array<OrderDocument>) => {
      dispatch({ type: ORDER_CREATE_START, payload: order });

      let createOrderCallable;
      if (createTestData) {
        createOrderCallable = httpsCallable(getFunctions(), FirebaseFunctionNames.orderCreateTestData);
      } else {
        createOrderCallable = httpsCallable(getFunctions(), FirebaseFunctionNames.createOrder);
      }

      return createOrderCallable({ order, documents, amount: amount > 500 ? 500 : amount })
        .then((response) => {
          dispatch({ type: ORDER_CREATE_SUCCESS, payload: response });
          return Promise.resolve(response.data as OrderEntity);
        })
        .catch((error) => {
          dispatch({ type: ORDER_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [amount, createTestData, dispatch],
  );
};

/**
 * useDispatchOrderUpdate
 */
type OrderUpdateReturnType = (
  clientId: string,
  orderId: string,
  changedData: Partial<OrderEntity>,
) => Promise<OrderEntity>;

export const useDispatchOrderUpdate = (): OrderUpdateReturnType => {
  const dispatch = useDispatch();

  return React.useCallback<OrderUpdateReturnType>(
    (clientId, orderId, data) => {
      dispatch({ type: ORDER_UPDATE_START, payload: { clientId, orderId, data } });

      const updateOrderCallable = httpsCallable(getFunctions(), FirebaseFunctionNames.updateOrder);
      return updateOrderCallable({ clientId, orderId, data })
        .then((response: HttpsCallableResult<OrderEntity>) => {
          dispatch({ type: ORDER_UPDATE_SUCCESS, payload: response.data });
          return Promise.resolve(response.data as OrderEntity);
        })
        .catch((error) => {
          dispatch({ type: ORDER_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchOrderGet()
 */
type OrderGetReturnType = (clientId: string, orderId: string) => Promise<OrderEntity>;

export const useDispatchOrderGet = (): OrderGetReturnType => {
  const dispatch = useDispatch();
  const dispatchGetServices = useDispatchOrderServiceGetList();
  const { user } = useAppSelector((state) => state.auth);

  return React.useCallback<OrderGetReturnType>(
    (clientId: string, orderId: string) => {
      dispatch({ type: ORDER_GET_START, payload: { clientId, orderId } });

      const docRef = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.order,
        orderId,
      ) as DocumentReference<OrderEntity>;

      return getDoc(docRef)
        .then((snapShot) => {
          if (snapShot.exists()) {
            const order = { ...snapShot.data(), orderId: snapShot.id };

            return dispatchGetServices(order.ownerClientId, orderId).then((services) => {
              const mappedOrder = { ...order, services: [...services] };
              const permissions = prepareOrderPermissions(mappedOrder, user);
              dispatch({ type: ORDER_GET_SUCCESS, payload: { order: mappedOrder, permissions } });
              return Promise.resolve(mappedOrder);
            });
          }

          return Promise.reject(new Error(InternalErrorCodes.FIRESTORE_DOCUMENT_NOT_FOUND));
        })
        .catch((error) => {
          dispatch({ type: ORDER_GET_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, dispatchGetServices, user],
  );
};

/**
 * useDispatchOrderDelete
 */
export const useDispatchOrderDelete = () => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (order: OrderEntity) => {
      dispatch({ type: ORDER_DELETE_START, payload: order });

      if (clientId === order.ownerClientId) {
        const deleteCallable = httpsCallable(getFunctions(), FirebaseFunctionNames.deleteOrder);
        return deleteCallable({ clientId: order.ownerClientId, orderId: order.orderId })
          .then((response) => {
            dispatch({ type: ORDER_DELETE_SUCCESS, payload: response });
            return Promise.resolve(response);
          })
          .catch((error) => {
            dispatch({ type: ORDER_DELETE_ERROR, payload: error });
            return Promise.reject(error);
          });
      } else {
        return new Promise((resolve) => {
          return resolve(false);
        });
      }
    },
    [clientId, dispatch],
  );
};

/**
 * Prepare and get the order partner permissions for the current user
 * @param order
 * @param user
 */
const prepareOrderPermissions = (order: OrderEntity, user: UserEntity): OrderPartnerPermissionAccess => {
  // Check is only monteur or driver than no edit is granted
  if (
    user.groups.indexOf(GroupKey.ADMIN) < 0 &&
    user.groups.indexOf(GroupKey.MANAGEMENT) < 0 &&
    user.groups.indexOf(GroupKey.DISPO) < 0 &&
    user.groups.indexOf(GroupKey.DEALER) < 0
  ) {
    return {
      allowEditAll: false,
      allowEditInformation: false,
      allowEditCustomerAddress: false,
      allowEditLoadingAddress: false,
      allowEditAppointments: false,
      allowDocumentUpload: false,
      allowDocumentDelete: false,
      allowAddService: false,
      allowDeleteService: false,
      allowEditKitchenInformation: false,
      allowEditPosition: false,
    };
  }

  // Check if partner permission is available. If not it will be the same as allowEditAll permission.
  // If clientId === user.clientId it is also allowEditAll, besides it is only a monteur or drive as filtered above.
  if (order.partnerPermissions && order.partnerPermissions.length > 0) {
    const permission = Lodash.find(order.partnerPermissions, (item) => item.clientId === user.clientId);
    if (permission) {
      return permission.permissions;
    }
  }

  return {
    allowEditAll: true,
    allowEditInformation: false,
    allowEditCustomerAddress: false,
    allowEditLoadingAddress: false,
    allowEditAppointments: false,
    allowDocumentUpload: false,
    allowDocumentDelete: false,
    allowAddService: false,
    allowDeleteService: false,
    allowEditKitchenInformation: false,
    allowEditPosition: false,
  };
};

/**
 * useDispatchOrderHasAccess
 */
export const useDispatchOrderHasAccess = () => {
  const [hasAccess, setHasAccess] = React.useState<boolean>(true);
  const { user } = useAppSelector((state) => state.auth);
  const { order } = useAppSelector((state) => state.order.selectedOrder);
  const { dispositions } = useAppSelector((state) => state.order.selectedOrder);

  React.useEffect(() => {
    if (order) {
      setHasAccess(false);

      if (order.ownerClientId === user.clientId || order.createdClientId === user.clientId) {
        setHasAccess(true);
      }

      order.services.forEach((service) => {
        if (service.assignedPartnerClientId === user.clientId) {
          setHasAccess(true);
        }
      });

      if (dispositions) {
        dispositions.forEach((tour) => {
          if (tour.users) {
            tour.users.forEach((tourUser) => {
              if (user.userId === tourUser.userId) {
                setHasAccess(true);
              }
            });
          }
        });
      }
    }
  }, [dispositions, order, user]);

  return hasAccess;
};
