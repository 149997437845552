import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SelectNumber from '../PredefinedSelects/SelectNumber';
import SelectOnDay from '../PredefinedSelects/SelectOnDay';
import WeekdaysToggle from '../WeekdaysToggle';
import DatePicker from '../Inputs/DatePicker';
import { RecurringType } from '../../Globals/Types/Enums';
import validate, { ValidateErrors } from './Validate';
import SelectRecurring from '../PredefinedSelects/SelectRecurring';
import { buildDates } from './Functions';
import { useGetFacility } from '../../Globals/Hooks/FacilityHooks';
import { FacilityEntity } from '../../Globals/Types/Types';

export interface StateData {
  dateStart: string;
  dateEnd: string;
  recurring: RecurringType;
  dailyDays: number;
  monthlyDay: number;
  monthlyMonths: number;
  monthlyOn: string;
  monthlyOnDay: string;
  weeklyWeeks: number;
  weeklyWeekdays: boolean[];
}

interface State {
  errors: ValidateErrors;
  data: StateData;
}

type Action =
  | { type: 'dateStart'; payload: string }
  | { type: 'dateEnd'; payload: string }
  | { type: 'errors'; payload: ValidateErrors }
  | { type: 'recurring'; payload: RecurringType }
  | { type: 'dailyDays'; payload: number }
  | { type: 'monthlyDay'; payload: number }
  | { type: 'monthlyMonths'; payload: number }
  | { type: 'monthlyOnDay'; payload: string }
  | { type: 'monthlyOn'; payload: string }
  | { type: 'weeklyWeeks'; payload: number }
  | { type: 'weeklyWeekdays'; payload: boolean[] };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'dateStart':
      return {
        ...state,
        data: { ...state.data, dateStart: action.payload },
        errors: { ...state.errors, dateStart: null },
      };
    case 'dateEnd':
      return { ...state, data: { ...state.data, dateEnd: action.payload }, errors: { ...state.errors, dateEnd: null } };
    case 'errors':
      return { ...state, errors: action.payload };
    case 'recurring':
      return {
        ...state,
        data: { ...state.data, recurring: action.payload },
        errors: { ...state.errors, recurring: null },
      };
    case 'dailyDays':
      return {
        ...state,
        data: { ...state.data, dailyDays: action.payload },
        errors: { ...state.errors, dailyDays: null },
      };
    case 'monthlyDay':
      return {
        ...state,
        data: { ...state.data, monthlyDay: action.payload, monthlyOn: null, monthlyOnDay: null },
        errors: { ...state.errors, monthlyDay: null, monthlyOn: null, monthlyOnDay: null },
      };
    case 'monthlyMonths':
      return {
        ...state,
        data: { ...state.data, monthlyMonths: action.payload },
        errors: { ...state.errors, monthlyMonths: null },
      };
    case 'monthlyOn':
      return {
        ...state,
        data: { ...state.data, monthlyOn: action.payload, monthlyDay: null },
        errors: { ...state.errors, monthlyOn: null, monthlyDay: null },
      };
    case 'monthlyOnDay':
      return {
        ...state,
        data: { ...state.data, monthlyOnDay: action.payload, monthlyDay: null },
        errors: { ...state.errors, monthlyOnDay: null, monthlyDay: null },
      };
    case 'weeklyWeeks':
      return {
        ...state,
        data: { ...state.data, weeklyWeeks: action.payload },
        errors: { ...state.errors, weeklyWeeks: null },
      };
    case 'weeklyWeekdays':
      return {
        ...state,
        data: { ...state.data, weeklyWeekdays: action.payload },
        errors: { ...state.errors, weeklyWeekdays: null },
      };
    default:
      return state;
  }
};

const initialState: State = {
  errors: null,
  data: {
    dateEnd: moment().endOf('month').format('YYYY-MM-DD'),
    dateStart: moment().format('YYYY-MM-DD'),
    recurring: RecurringType.dailyWork,
    dailyDays: 1,
    monthlyDay: parseInt(moment().format('DD')),
    monthlyMonths: 1,
    monthlyOn: null,
    monthlyOnDay: null,
    weeklyWeekdays: Array(7).fill(false),
    weeklyWeeks: 1,
  },
};

const MAX_WEEKDAY = 6;

interface Props {
  onChange: (dates: string[]) => void;
  showErrors?: boolean;
}

/**
 * RecurringForm()
 * @constructor
 */
export default function RecurringForm(props: Props) {
  const { onChange, showErrors } = props;
  const [maxDateEnd] = React.useState(moment().add(3, 'years').endOf('year').toDate());
  const [maxWeekday, setMaxWeekday] = React.useState<number>(MAX_WEEKDAY);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [t] = useTranslation();

  const getFacility = useGetFacility();

  React.useEffect(() => {
    const found: FacilityEntity = getFacility();
    setMaxWeekday(found?.settings?.workDays || MAX_WEEKDAY);
  }, [getFacility, setMaxWeekday]);

  React.useEffect(() => {
    const { errors, isValid } = validate(state.data);
    if (!isValid) {
      dispatch({ type: 'errors', payload: errors });
      return onChange([]);
    }

    dispatch({ type: 'errors', payload: null });
    const dates: string[] = buildDates(state.data, maxWeekday);
    onChange(dates);
  }, [maxWeekday, onChange, state.data]);

  const renderDependantOptions = () => {
    switch (state.data.recurring) {
      case RecurringType.daily:
      case RecurringType.dailyWork:
        return (
          <Row>
            <Col>
              <SelectNumber
                label={t('everyXDays')}
                initialValue={state.data.dailyDays}
                onChange={(value) => dispatch({ type: 'dailyDays', payload: value })}
                max={31}
                hasError={showErrors && !!state.errors?.dailyDays}
              />
            </Col>
          </Row>
        );

      case RecurringType.monthly:
        // TODO: Clear 'Jeweils am' or 'Tag' / 'an jedem' if the other is selected. Select component can't be cleared
        // atm
        return (
          <>
            <Row>
              <Col>
                <SelectNumber
                  label={t('everyXMonths')}
                  initialValue={state.data.monthlyMonths}
                  max={12}
                  onChange={(value) => dispatch({ type: 'monthlyMonths', payload: value })}
                  hasError={showErrors && !!state.errors?.monthlyMonths}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectNumber
                  label={t('eachOn')}
                  initialValue={state.data.monthlyDay}
                  max={31}
                  onChange={(value) => dispatch({ type: 'monthlyDay', payload: value })}
                  hasError={showErrors && !!state.errors?.monthlyDay}
                />
              </Col>
              <Col>
                <SelectOnDay
                  labelDay={t('day')}
                  labelOn={t('eachOn')}
                  onChange={(type, value) => {
                    if (type === 'dayOn') {
                      dispatch({ type: 'monthlyOnDay', payload: value });
                    } else {
                      dispatch({ type: 'monthlyOn', payload: value });
                    }
                  }}
                  hasErrorDay={showErrors && !!state.errors?.monthlyOnDay}
                  hasErrorOn={showErrors && !!state.errors?.monthlyOn}
                />
              </Col>
            </Row>
          </>
        );

      case RecurringType.weekly:
        return (
          <Row style={{ alignItems: 'center' }}>
            <Col>
              <SelectNumber
                label={t('everyXWeeks')}
                initialValue={state.data.weeklyWeeks}
                max={4}
                onChange={(value) => dispatch({ type: 'weeklyWeeks', payload: value })}
                hasError={showErrors && !!state.errors?.weeklyWeeks}
                marginBottom={0}
              />
            </Col>
            <Col>
              <WeekdaysToggle
                initialValue={state.data.weeklyWeekdays}
                onChange={(value) => dispatch({ type: 'weeklyWeekdays', payload: value })}
                hasError={showErrors && !!state.errors?.weeklyWeekdays}
              />
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Row>
        <Col>
          <DatePicker
            label={t('startDate')}
            initialValue={moment(state.data.dateStart).toDate()}
            onChange={(value) => dispatch({ type: 'dateStart', payload: value })}
            minDate={moment().toDate()}
            hasError={showErrors && !!state.errors?.dateStart}
          />
        </Col>
        <Col>
          <DatePicker
            label={t('endDate')}
            initialValue={moment(state.data.dateEnd).toDate()}
            onChange={(value) => dispatch({ type: 'dateEnd', payload: value })}
            hasError={showErrors && !!state.errors?.dateEnd}
            minDate={moment().toDate()}
            maxDate={maxDateEnd}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectRecurring
            label={t('repeat')}
            initialValue={state.data.recurring}
            onChange={(value) => dispatch({ type: 'recurring', payload: value })}
            hasError={showErrors && !!state.errors?.recurring}
          />
        </Col>
      </Row>
      {renderDependantOptions()}
    </>
  );
}
