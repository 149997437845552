import React from 'react';
import { InvoiceEntity } from '../../../Globals/Types/Invoice';
import { useTranslation } from 'react-i18next';
import Row from './Row';
import Table from '../../Table/Table';

interface Props {
  invoices: InvoiceEntity[];
  showCustomerName?: boolean;
}

/**
 * ListView()
 * @param props
 * @constructor
 */
export default function ListView(props: Props) {
  const { invoices, showCustomerName } = props;
  const [t] = useTranslation();

  const renderInvoices = () =>
    invoices.map((invoice) => <Row invoice={invoice} key={invoice.invoiceId} showCustomerName={showCustomerName} />);

  const renderCustomerNameHeader = () => {
    if (showCustomerName) {
      return <th>{t('customer')}</th>;
    }
    return null;
  };

  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: 50 }}></th>
          <th>{t('invoiceNumber')}</th>
          {renderCustomerNameHeader()}
          <th>{t('dueDate')}</th>
          <th>{t('overallPrice')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{renderInvoices()}</tbody>
    </Table>
  );
}
