/* eslint-disable no-console */

import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { getApps, initializeApp } from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/remote-config';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth, onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { fetchAndActivate, getAll, getRemoteConfig } from 'firebase/remote-config';
import { Environment } from '../Globals/Types/Enums';
import prepareTranslationConfig from '../Globals/Translations/PrepareTranslationConfig';
import { initializeTranslations } from '../Globals/Translations/i18n';
import LightTheme from '../Globals/Theme/LightTheme';
import { ThemeProvider } from 'styled-components';
import { useDispatchLogoutWithoutRequest } from '../Redux/Actions/AuthAction';
import Login from '../Modules/Public/Login';
import AskResetPassword from '../Modules/Public/AskResetPassword';
import ResetPassword from '../Modules/Public/ResetPassword';
import FirebaseModeRedirect from '../Modules/Public/FirebaseModeRedirect';
import PublicReport from '../Modules/Public/Linking';
import Register from '../Modules/Public/Register/Register';
import DefaultLayout from '../Components/Layout/DefaultLayout';
import PublicNavigation from '../Components/Layout/Navigation/PublicNavigation';
import Dashboard from '../Modules/Dashboard/Dashboard';
import SettingsFacilityDetails from '../Modules/Settings/Facility/Details/Details';
import SettingsFacilitySettings from '../Modules/Settings/Facility/Settings/Details';
import SettingsFacilityDocuments from '../Modules/Settings/Facility/Documents/Overview';
import SettingsOrders from '../Modules/Settings/Order';
import SettingsOrderReportConfig from '../Modules/Settings/Order/ReportConfig';
import Create from '../Modules/Settings/Facility/CreateFacility/Create';
import UsersOverview from '../Modules/Settings/Users/Overview';
import UserCreate from '../Modules/Settings/Users/Create';
import ClientSettings from '../Modules/Settings/Settings/Overview';
import UserUpdate from '../Modules/Settings/Users/Update';
import HolidayOverview from '../Modules/Settings/Absence/Overview';
import PartnerOverview from '../Modules/Settings/Facility/Partners/Overview';
import PartnerDetails from '../Modules/Settings/Facility/Partners/Details';
import FacilityMailSettings from '../Modules/Settings/Facility/Mail';
import FacilityEditTemplate from '../Modules/Settings/Facility/Mail/Templates/EditTemplate';
import StorageAddressOverview from '../Modules/Settings/Storage/Address/Overview';
import StorageAddressCreateOrUpdate from '../Modules/Settings/Storage/Address/CreateOrUpdate';
import StorageArticleOverview from '../Modules/Settings/Storage/Article/Overview';
import StorageArticleCreateOrUpdate from '../Modules/Settings/Storage/Article/CreateOrUpdate';
import TimeTrackingOverview from '../Modules/Settings/TimeTracking/Overview';
import TimeTrackingUserDetails from '../Modules/Settings/TimeTracking/UserDetails';
import UserCalender from '../Modules/Profile/Calendar';
import UserTimeTracking from '../Modules/Profile/TimeTracking';
import UserTasks from '../Modules/Profile/Tasks';
import UserProfile from '../Modules/Profile/Profile';
import OrderCreate from '../Modules/Order/Create';
import OrderList from '../Modules/Order/List';
import OrderOverview from '../Modules/Order/Details/Overview';
import OrderCreateInvoice from '../Modules/Order/Details/CreateInvoice';
import OrderMessages from '../Modules/Order/Details/Messages';
import OrderService from '../Modules/Order/Details/Service';
import OrderTasks from '../Modules/Order/Details/Tasks';
import OrderTimeTracking from '../Modules/Order/Details/TimeTracking';
import ServiceCreateInvoice from '../Modules/Order/Details/Services/CreateInvoice';
import CustomerOverview from '../Modules/Customer/Overview';
import CustomerDetails from '../Modules/Customer/Details';
import CustomerNotes from '../Modules/Customer/Notes';
import CustomerServices from '../Modules/Customer/Services';
import CustomerInvoices from '../Modules/Customer/Invoices/Overview';
import CustomerMedia from '../Modules/Customer/Media/Overview';
import CustomerInvoiceCreate from '../Modules/Customer/Invoices/Create';
import CustomerInvoiceDetails from '../Modules/Customer/Invoices/Details';
import CustomerCreateOrUpdate from '../Modules/Customer/CreateOrUpdate';
import CustomerOffers from '../Modules/Customer/Offer/Overview';
import CustomerOffersCreate from '../Modules/Customer/Offer/Create';
import CustomerOffersDetails from '../Modules/Customer/Offer/Details';
import Inbox from '../Modules/Inbox/Inbox';
import InboxDetails from '../Modules/Inbox/Details';
import InboxCreate from '../Modules/Inbox/Create';
import { useDispatchInboxGetList } from '../Redux/Actions/User/InboxAction';
import { useAppSelector } from '../Globals/Hooks/Hooks';
import moment from 'moment';
import StartTimeTracking from '../Modules/Settings/StartTimeTracking';
import Config from '../Globals/Config';
import TourOverview from '../Modules/Settings/Tour/Overview';
import TourCreateOrUpdate from '../Modules/Settings/Tour/CreateOrUpdate';
import ResourceOverview from '../Modules/Settings/Resources/Overview';
import ResourceCreateOrUpdate from '../Modules/Settings/Resources/CreateOrUpdate';
import Disposition from '../Modules/Disposition/Disposition';
import PositionsOverview from '../Modules/Settings/Positions/Overview';
import PositionsCreateOrUpdate from '../Modules/Settings/Positions/CreateOrUpdate';
import Report from '../Modules/Order/Report';
import Subscription from '../Modules/Settings/Subscription';
import Startpage from '../Modules/Public/Website/Startpage';
import { getSelectedLanguage, momentConfiguration } from '../Globals/Functions/LocalizationFunctions';
import LinkingLayout from '../Components/Layout/LinkingLayout';
import LandingPagePartnerRedirect from '../Modules/Public/Linking/LandingPagePartnerRedirect';
import InvoiceOverview from '../Modules/Invoice/Overview';
import InvoiceDetails from '../Modules/Invoice/Details';
import { checkVersionMatch } from '../Redux/LocalStorage';
import EnvironmentHint from '../Components/EnvironmentHint';
import { licenseServiceSetUniqueIdentifier } from '../Redux/Actions/LicenseServiceAction';
import AuthedLayout from '../Components/Layout/AuthedLayout';
import SubNavigation from '../Components/Layout/NewNavigation/SubNavigation';
import SettingsSubNavigation from '../Components/Layout/NewNavigation/SettingsSubNavigation';
import ProfileSubNavigation from '../Components/Layout/NewNavigation/ProfileSubNavigation';
import CustomerSubNavigation from '../Components/Layout/NewNavigation/CustomerSubNavigation';
import FacilitySubNavigation from '../Components/Layout/NewNavigation/FacilitySubNavigation';
import OrderSubNavigation from '../Components/Layout/NewNavigation/OrderSubNavigation';
import MessageSubNavigation from '../Components/Layout/NewNavigation/MessageSubNavigation';
import StorageSubNavigation from '../Components/Layout/NewNavigation/StorageSubNavigation';
import IndexListener from '../Components/RebuildIndexListener/IndexListener';

/**
 * AppRouter()
 * @returns {*}
 * @constructor
 */
export default function AppRouter() {
  const [fbInitialized, setFbInitialized] = React.useState<boolean>(false);
  const [configInitialized, setConfigInitialized] = React.useState<boolean>(false);
  const [firebaseInitialized, setFirebaseInitialized] = React.useState<boolean>(false);
  const [remoteTranslations, setRemoteTranslations] = React.useState(null);
  const [initializedTranslations, setInitializedTranslations] = React.useState(false);
  const [isLocalDev] = React.useState(process.env.REACT_APP_STAGE === Environment.dev);
  const { isLoggedIn } = useAppSelector((state) => state.auth);

  const dispatchLoadInbox = useDispatchInboxGetList();
  const dispatchLogoutWithoutRequest = useDispatchLogoutWithoutRequest();

  const handleAuthStateChange = React.useCallback(
    (state) => {
      if (!state) {
        dispatchLogoutWithoutRequest();
      } else {
        console.log('User already authed!');
      }
    },
    [dispatchLogoutWithoutRequest],
  );

  React.useEffect(() => {
    licenseServiceSetUniqueIdentifier();
    checkVersionMatch();
  }, []);

  React.useEffect(() => {
    try {
      moment.locale('de', {
        week: {
          dow: 1, // Monday is the first day of the week.
        },
      });
    } catch (e) {}

    if (getApps().length === 0) {
      initializeApp(Config.firebase.config);
      getFunctions().region = Config.firebase.region;
      setFirebaseInitialized(true);
    }
  }, []);

  React.useEffect(() => {
    let interval;

    if (isLoggedIn && firebaseInitialized) {
      setTimeout(() => dispatchLoadInbox().then(() => {}), 2000);

      interval = setInterval(() => {
        dispatchLoadInbox().then(() => {});
      }, 300000);
    }

    return () => clearInterval(interval);
  }, [dispatchLoadInbox, firebaseInitialized, isLoggedIn]);

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    onAuthStateChanged(getAuth(), handleAuthStateChange);
    onIdTokenChanged(getAuth(), (data) => console.log('AUTH ID-TOKEN CHANGED', data));
  }, [handleAuthStateChange]);

  React.useEffect(() => {
    if (!configInitialized) {
      getRemoteConfig().settings.minimumFetchIntervalMillis = isLocalDev ? 18000 : 600000;
      fetchAndActivate(getRemoteConfig()).then(() => {
        const allConfigValues = getAll(getRemoteConfig());
        const translationConfig = prepareTranslationConfig(allConfigValues, isLocalDev);
        setConfigInitialized(true);

        console.log('Remote translations loaded:', { ...translationConfig });
        setRemoteTranslations(translationConfig);
      });
    }
  }, [configInitialized, isLocalDev]);

  React.useEffect(() => {
    if (isLocalDev && !fbInitialized) {
      connectFirestoreEmulator(getFirestore(), Config.localhostIp, 9012);
      connectAuthEmulator(getAuth(), `http://${Config.localhostIp}:9099`, { disableWarnings: true });
      connectStorageEmulator(getStorage(), Config.localhostIp, 9013);
      connectFunctionsEmulator(getFunctions(), Config.localhostIp, 5001);
      setFbInitialized(true);
      console.log('Firebase emulators setUp!');
    }
  }, [fbInitialized, isLocalDev]);

  React.useEffect(() => {
    if (remoteTranslations && !initializedTranslations) {
      moment.locale(getSelectedLanguage(false));
      moment.localeData(getSelectedLanguage(false)).set(momentConfiguration(getSelectedLanguage(true)));
      initializeTranslations(remoteTranslations, getSelectedLanguage(true));
      setInitializedTranslations(true);
    }
  }, [initializedTranslations, remoteTranslations]);

  React.useEffect(() => {
    if (Config.env !== Environment.dev) {
      // Initialize Analytics and get a reference to the service
      getAnalytics();
    }
  }, []);

  const renderProfileRoutes = () => {
    return (
      <Route path="/profile" element={<SubNavigation subNavigation={<ProfileSubNavigation />} defaultRoute="user" />}>
        <Route path="calendar" element={<UserCalender />} />
        <Route path="timeTracking" element={<UserTimeTracking />} />
        <Route path="tasks" element={<UserTasks />} />
        <Route path="user" element={<UserProfile />} />
      </Route>
    );
  };

  const renderPublicRoutes = () => {
    return (
      <Route path="/public/" element={<DefaultLayout navigation={<PublicNavigation />} />}>
        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="redirect" element={<FirebaseModeRedirect />} />
        <Route path="password/ask" element={<AskResetPassword />} />
        <Route path="password/reset" element={<ResetPassword />} />
        <Route path="report/:hash" element={<PublicReport />} />
        <Route path="*" element={<Startpage />} />
      </Route>
    );
  };

  const renderPublicReportRoute = () => {
    return (
      <>
        <Route path="/l/" element={<LinkingLayout navigation={null} />}>
          <Route path=":linkingId" element={<PublicReport />} />
          <Route path="*" element={<Startpage />} />
        </Route>
        <Route path="/lp/" element={<LinkingLayout navigation={null} />}>
          <Route path=":id" element={<LandingPagePartnerRedirect />} />
          <Route path="*" element={<Startpage />} />
        </Route>
      </>
    );
  };

  const renderRoutes = () => {
    return (
      <Routes>
        <Route element={<AuthedLayout />}>
          <Route
            path="/settings/facility/:facilityId"
            element={<SubNavigation subNavigation={<FacilitySubNavigation />} defaultRoute={'details'} />}
          >
            <Route path="create" element={<SettingsFacilityDetails />} />
            <Route path="details" element={<SettingsFacilityDetails />} />
            <Route path="advanced" element={<SettingsFacilitySettings />} />
            <Route path="documents" element={<SettingsFacilityDocuments />} />
            <Route path="mail" element={<FacilityMailSettings />} />
            <Route path="mail/template/:templateName" element={<FacilityEditTemplate />} />
            <Route path="partners" element={<PartnerOverview />} />
            <Route path="partners/:partnerId" element={<PartnerDetails />} />
            <Route path="*" element={null} />
          </Route>

          <Route
            path="/settings/storage"
            element={<SubNavigation subNavigation={<StorageSubNavigation />} defaultRoute={'address'} />}
          >
            <Route path="address" element={<StorageAddressOverview />} />
            <Route path="address/:storageId" element={<StorageAddressCreateOrUpdate />} />
            <Route path="article" element={<StorageArticleOverview />} />
            <Route path="article/create" element={<StorageArticleCreateOrUpdate />} />
            <Route path="article/:articleId" element={<StorageArticleCreateOrUpdate />} />
            <Route path="*" element={null} />
          </Route>

          <Route
            path="/settings"
            element={<SubNavigation subNavigation={<SettingsSubNavigation />} defaultRoute="advanced" />}
          >
            <Route path="createFacility" element={<Create />} />
            <Route path="users" element={<UsersOverview />} />
            <Route path="users/:userId" element={<UserUpdate />} />
            <Route path="userCreate" element={<UserCreate />} />
            <Route path="absence" element={<HolidayOverview />} />
            <Route path="tour" element={<TourOverview />} />
            <Route path="tour/:tourTemplateId" element={<TourCreateOrUpdate />} />
            <Route path="timeTracking" element={<TimeTrackingOverview />} />
            <Route path="timeTracking/:userId" element={<TimeTrackingUserDetails />} />
            <Route path="advanced" element={<ClientSettings />} />
            <Route path="resources" element={<ResourceOverview />} />
            <Route path="resources/:resourceId" element={<ResourceCreateOrUpdate />} />
            <Route path="positions" element={<PositionsOverview />} />
            <Route path="positions/create" element={<PositionsCreateOrUpdate />} />
            <Route path="positions/:positionId" element={<PositionsCreateOrUpdate />} />
            <Route path="orders" element={<SettingsOrders />} />
            <Route path="orders/reportConfig/:serviceTypeKey" element={<SettingsOrderReportConfig />} />
            <Route path="subscription" element={<Subscription />} />
            <Route path="*" element={null} />
          </Route>

          <Route path="createorder" element={<OrderCreate />} />

          <Route path="/order">
            <Route path="list" element={<OrderList />} />
            <Route
              path="c/:clientId/o/:orderId"
              element={<SubNavigation subNavigation={<OrderSubNavigation />} defaultRoute="details" />}
            >
              <Route path="details" element={<OrderOverview />} />
              <Route path="details/invoice" element={<OrderCreateInvoice />} />
              <Route path="messages" element={<OrderMessages />} />
              <Route path="tasks" element={<OrderTasks />} />
              <Route path="timetracking" element={<OrderTimeTracking />} />
              <Route path="s/:serviceId" element={<OrderService />} />
              <Route path="s/:serviceId/report" element={<Report />} />
              <Route path="s/:serviceId/invoice" element={<ServiceCreateInvoice />} />
            </Route>
          </Route>

          <Route path="/customer">
            <Route path="list" element={<CustomerOverview />} />
            <Route path="create" element={<CustomerCreateOrUpdate />} />

            <Route
              path=":customerId"
              element={<SubNavigation subNavigation={<CustomerSubNavigation />} defaultRoute={'details'} />}
            >
              <Route path="update" element={<CustomerCreateOrUpdate />} />
              <Route path="details" element={<CustomerDetails />} />
              <Route path="contacts" element={<CustomerNotes />} />
              <Route path="services" element={<CustomerServices />} />
              <Route path="media" element={<CustomerMedia />} />
              <Route path="invoices" element={<CustomerInvoices />} />
              <Route path="invoices/create" element={<CustomerInvoiceCreate />} />
              <Route path="invoices/create/:invoiceId" element={<CustomerInvoiceCreate />} />
              <Route path="invoices/:invoiceId" element={<CustomerInvoiceDetails />} />
              <Route path="offers" element={<CustomerOffers />} />
              <Route path="offers/create" element={<CustomerOffersCreate />} />
              <Route path="offers/:offerId" element={<CustomerOffersDetails />} />
              <Route path="offers/update/:offerId" element={<CustomerOffersCreate />} />
            </Route>
          </Route>

          <Route path="/invoices">
            <Route path="details/:invoiceId" element={<InvoiceDetails />} />
            <Route path="list" element={<InvoiceOverview />} />
          </Route>

          {renderProfileRoutes()}

          <Route path="startTimeTrackingFirst" element={<StartTimeTracking />} />
          <Route path="dashboard" element={<Dashboard />} />

          <Route
            element={<SubNavigation width={500} subNavigation={<MessageSubNavigation />} defaultRoute={'inbox'} />}
          >
            <Route path="inbox" element={<Inbox />} />
            <Route path="inbox/create" element={<InboxCreate />} />
            <Route path="inbox/:messageId" element={<InboxDetails />} />
          </Route>

          <Route path="disposition" element={<Disposition />} />
          <Route path="disposition/:date" element={<Disposition />} />
          <Route path="*" element={<Navigate replace to="/public/startpage" />} />
        </Route>
        {renderPublicReportRoute()}
        {renderPublicRoutes()}
      </Routes>
    );
  };

  if (configInitialized && remoteTranslations && initializedTranslations) {
    return (
      <ThemeProvider theme={LightTheme}>
        <IndexListener />
        <BrowserRouter>{renderRoutes()}</BrowserRouter>
        <EnvironmentHint />
      </ThemeProvider>
    );
  }
  return <></>;
}
