import { DefaultValidationResult, StorageAddressEntity } from '../../../../Globals/Types/Types';
import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { CreateOrUpdateError } from './CreateOrUpdate';

/**
 * isValid()
 * @param address
 */
const isValid = (address: StorageAddressEntity): DefaultValidationResult<CreateOrUpdateError> => {
  let errors: CreateOrUpdateError = {} as CreateOrUpdateError;

  if (!address.name || address.name.length === 0) {
    errors = { ...errors, name: InternalErrorCodes.FIELD_MISSING };
  }
  if (!address.zip || address.zip.length === 0) {
    errors = { ...errors, zip: InternalErrorCodes.FIELD_MISSING };
  }
  if (!address.city || address.city.length === 0) {
    errors = { ...errors, city: InternalErrorCodes.FIELD_MISSING };
  }
  if (!address.street || address.street.length === 0) {
    errors = { ...errors, street: InternalErrorCodes.FIELD_MISSING };
  }
  if (!address.streetNo || address.streetNo.length === 0) {
    errors = { ...errors, streetNo: InternalErrorCodes.FIELD_MISSING };
  }
  if (address.gln && address.gln.length !== 13) {
    errors = { ...errors, gln: InternalErrorCodes.GLN_INVALID };
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export default isValid;
