import React from 'react';
import { TimeTrackingType } from '../../../Globals/Types/Enums';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import TimeTrackingIcon from '../../TimeTrackingIcon';
import Text from '../../Text';
import { userDisplayName } from '../../../Globals/Functions';
import { TimeTrackingEntity } from '../../../Globals/Types/Types';

interface Props {
  timeTracking: TimeTrackingEntity;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { timeTracking } = props;
  const [t] = useTranslation();
  const getText = () => {
    switch (timeTracking.type) {
      case TimeTrackingType.startOrder:
        return t('modules.order.timeTrackingStatusStart');
      case TimeTrackingType.pauseOrder:
        return t('modules.order.timeTrackingStatusPause');
      case TimeTrackingType.stopOrder:
        return t('modules.order.timeTrackingStatusStop');
    }
  };

  return (
    <tr>
      <td width={40}>
        <TimeTrackingIcon type={timeTracking.type} size={24} />
      </td>
      <td>
        <Text>{getText()}</Text>
        <Text small light>
          {`${userDisplayName(timeTracking.manualCreatedUser, true)}`}
        </Text>
      </td>
      <td width={300} style={{ textAlign: 'right' }}>
        {moment(timeTracking.timestamp.toDate()).format('LL - LTS')}
      </td>
    </tr>
  );
}
