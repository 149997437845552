import React from 'react';
import styled from 'styled-components';
import Timeline from './Timeline';
import { DispositionListContext } from '../index';
import ResourceColumn from './ResourceColumn';
import UserColumn from './UserColumn';
import { useTranslation } from 'react-i18next';

const HeaderContainer = styled.div<{ hours: number; hourCellWidth: number; tourNameWidth: number }>`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.hours * props.hourCellWidth + props.tourNameWidth + 460}px;
  background-color: ${(props) => props.theme.color.backgroundGray};
  border-top-left-radius: ${(props) => props.theme.border.radius};
  border-top-right-radius: ${(props) => props.theme.border.radius};
  border-bottom: 1px solid ${(props) => props.theme.border.color};

  .Date {
    min-width: ${(props) => props.tourNameWidth}px;
    padding: 25px;
    font-weight: bold;
    border-right: 1px solid ${(props) => props.theme.border.color};
  }

  .Performance {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    font-size: 20px;
    border-right: 1px solid ${(props) => props.theme.border.color};
  }
`;

/**
 * Header
 * @constructor
 */
export default function Header() {
  const [t] = useTranslation();
  const context = React.useContext(DispositionListContext);

  return (
    <HeaderContainer hours={context.hours} hourCellWidth={context.hourBoxWidth} tourNameWidth={context.tourNameWidth}>
      <div className="Date">{t('tour')}</div>
      <div className="Performance">
        <i className="fas fa-badge-percent" />
      </div>
      <UserColumn
        onAdd={() => {}}
        onDelete={() => {}}
        tour={null}
        onClick={() => context.setCollapsedUsers(!context.collapsedUsers)}
      >
        {t('users')}
      </UserColumn>
      <ResourceColumn
        onAdd={() => {}}
        onDelete={() => {}}
        tour={null}
        onClick={() => context.setCollapsedResources(!context.collapsedResources)}
      >
        {t('resources')}
      </ResourceColumn>
      <Timeline
        showHours={true}
        tour={null}
        onMoveService={() => {}}
        onPressTourItem={() => {}}
        onAddService={() => {}}
        selectedTourItem={null}
      />
    </HeaderContainer>
  );
}
