import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import Select, { SelectOptions } from '../../../../../Components/Inputs/Select';
import Input from '../../../../../Components/Inputs/Input';
import Lodash from 'lodash';
import { ArticlePartnerEntity, FacilityPartnerEntity } from '../../../../../Globals/Types/Types';
import { isValidPrice } from '../../../../../Globals/Functions';
import { InternalErrorCodes } from '../../../../../Globals/InternalErrorCodes';

interface Props {
  visible: boolean;
  onSave: (item: ArticlePartnerEntity) => void;
  onClose: () => void;
}

/**
 * AddPartnerModal
 * @param props
 * @constructor
 */
export default function AddPartnerModal(props: Props) {
  const { visible, onSave, onClose } = props;
  const { allPartners, facilities } = useAppSelector((state) => state.client);
  const [facilityOptions, setFacilityOptions] = React.useState<SelectOptions>([]);
  const [partnerOptions, setPartnerOptions] = React.useState<SelectOptions>([]);
  const [selectedFacility, setSelectedFacility] = React.useState<string>(null);
  const [selectedPartner, setSelectedPartner] = React.useState<string>(null);
  const [salesPriceNetto, setSalesPriceNetto] = React.useState<string>('');
  const [salesPriceNettoError, setSalesPriceNettoError] = React.useState<string>(null);
  const [selectedPartnerError, setSelectedPartnerError] = React.useState<string>(null);
  const [t] = useTranslation();

  React.useEffect(() => {
    setSelectedPartner(null);
  }, [selectedFacility]);

  React.useEffect(() => {
    if (!visible) {
      setSalesPriceNettoError(null);
      setSalesPriceNetto('');
      setSelectedPartnerError(null);
      setSelectedPartner(null);
    }
  }, [visible]);

  React.useEffect(() => {
    const options: SelectOptions = facilities.map((facility) => ({
      label: facility.name,
      value: facility.facilityId,
    }));

    options.unshift({ label: 'Alle Betriebsstätten', value: null });
    setFacilityOptions(options);
  }, [facilities]);

  React.useEffect(() => {
    const options: Array<FacilityPartnerEntity> = allPartners.filter((item) => {
      if (selectedFacility !== null) {
        return item.invitedFacilityId === selectedFacility;
      }
      return item;
    });

    const unique = Lodash.unionBy(options, 'partnerFacilityId');
    const uniqueOptions: SelectOptions = unique.map((partner) => ({
      label: partner.partnerName,
      value: partner.partnerFacilityId,
    }));

    setPartnerOptions(uniqueOptions);
  }, [allPartners, selectedFacility]);

  const save = () => {
    setSalesPriceNettoError(null);
    setSelectedPartnerError(null);
    const priceValid: boolean = isValidPrice(salesPriceNetto);

    if (priceValid && salesPriceNetto && salesPriceNetto.length > 0) {
      if (selectedPartner !== null) {
        const filtered = allPartners.find((item) => item.partnerFacilityId === selectedPartner);

        onSave({
          facilityId: selectedFacility,
          partnerFacilityId: selectedPartner,
          partnerClientId: filtered.partnerClientId,
          salesPriceNetto: salesPriceNetto.replace(',', '.'),
        });
      } else {
        setSelectedPartnerError(InternalErrorCodes.FIELD_MISSING);
      }
    } else {
      setSalesPriceNettoError(InternalErrorCodes.PRICE_INVALID);
    }
  };

  return (
    <Modal show={visible} animation={false} size="lg">
      <Modal.Header>
        <Modal.Title>{t('modules.storage.addPartnerPrice')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ padding: '0 10px' }}>{t('modules.storage.addPartnerPriceModalDescription')}</p>

        <Select
          label={t('facility')}
          onChange={setSelectedFacility}
          options={facilityOptions}
          initialValue={selectedFacility}
        />
        <Select
          label={t('partner')}
          onChange={setSelectedPartner}
          options={partnerOptions}
          hasError={!!selectedPartnerError}
        />
        <Input
          onChange={(value) => setSalesPriceNetto(value.replace(',', '.'))}
          value={salesPriceNetto}
          label={t('salesPriceNetto')}
          hasError={!!salesPriceNettoError}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={save}>
          {t('save')}
        </Button>
        <Button variant="outline-secondary" onClick={onClose}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
