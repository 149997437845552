import React from 'react';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import { useDispatchOrderServiceReportGetList } from '../../../Redux/Actions/Order/Service/ReportAction';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { ReportState } from '../../../Globals/Types/Report';
import Container from '../../Container';
import { userDisplayName } from '../../../Globals/Functions';
import styled from 'styled-components';
import moment from 'moment';
import { useNavigate } from 'react-router';

const InnerContainer = styled.div<{ success: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    color: #000000;
  }

  > .Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 30px;
    padding-right: 15px;
    color: ${(props) => (props.success ? props.theme.color.green : props.theme.color.orange)};
  }

  > .Text {
    line-height: 20px;

    small {
      color: ${(props) => props.theme.color.textGray};
    }
  }
`;

/**
 * ReportInformation
 * @constructor
 */
export default function ReportInformation() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { order } = useAppSelector((state) => state.order.selectedOrder);
  const { service, report } = useAppSelector((state) => state.order.selectedOrder.selectedService);
  const dispatchGetReports = useDispatchOrderServiceReportGetList();

  React.useEffect(() => {
    if (!report && service.reportState !== ReportState.none) {
      dispatchGetReports(order.ownerClientId, order.orderId, service.serviceId).then(() => {});
    }
  }, [dispatchGetReports, order.orderId, order.ownerClientId, report, service.reportState, service.serviceId]);

  const renderWithoutReportInfos = () => {
    if (service.reportState === ReportState.complete) {
      return (
        <Container>
          <InnerContainer success={true}>
            <div className="Icon">
              <i className="fas fa-check-circle" />
            </div>
            <div className="Text">
              <div>{t('modules.order.serviceFinishedSuccessfulWithoutReport')}</div>
            </div>
          </InnerContainer>
        </Container>
      );
    } else {
      return (
        <Container>
          <InnerContainer success={false}>
            <div className="Icon">
              <i className="fas fa-octagon-exclamation fa-fade" />
            </div>
            <div className="Text">
              <div>{t('modules.order.serviceFinishedUnsuccessfulWithoutReport')}</div>
            </div>
          </InnerContainer>
        </Container>
      );
    }
  };

  const renderWithReportInfos = () => (
    <Container rightIcon="fas fa-chevron-right" onClick={() => navigate('report')}>
      <InnerContainer success={report.type === ReportState.complete}>
        <div className="Icon">
          <i
            className={`fas ${
              report.type === ReportState.complete ? 'fa-check-circle' : 'fa-octagon-exclamation fa-fade'
            }`}
          />
        </div>
        <div className="Text">
          <div>
            {t(report.type === ReportState.complete ? 'reportCompletedDate' : 'reportUncompletedDate', {
              date: moment(report.endDateTime).format('LLL - LT'),
            })}
          </div>
          <div>
            <small>{`${t('createdFrom')}: ${userDisplayName(report.createdUser, true)}`}</small>
          </div>
        </div>
      </InnerContainer>
    </Container>
  );

  if (service.reportState !== ReportState.none) {
    if (report) {
      return <Panel headline={t('reportInformation')}>{renderWithReportInfos()}</Panel>;
    } else {
      return <Panel headline={t('reportInformation')}>{renderWithoutReportInfos()}</Panel>;
    }
  }
  return null;
}
