import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{
  $light: boolean;
  $centered: boolean;
  $small: boolean;
  $italic: boolean;
  $bold: boolean;
  $size: number;
  $noWrap: boolean;
  $color: string;
}>`
  color: ${(props) => (props.$light ? props.theme.color.textGray : props.$color || props.theme.color.text)};
  text-align: ${(props) => (props.$centered ? 'center' : 'left')};
  font-size: ${(props) => (props.$small ? 14 : props.$size)}px;
  font-style: ${(props) => (props.$italic ? 'italic' : 'normal')};
  font-weight: ${(props) => (props.$bold ? 'bold' : 'normal')};
  ${(props) => (props.$noWrap ? 'overflow: hidden;  text-overflow: ellipsis; white-space: nowrap;' : null)}
`;

interface Props {
  children: string | React.ReactElement;
  light: boolean;
  centered: boolean;
  small: boolean;
  containerStyle: object;
  italic: boolean;
  bold: boolean;
  visible: boolean;
  size: number;
  noWrap: boolean;
  color?: string;
}

/**
 * Text()
 * @param props
 * @constructor
 */
export default function Text(props: Props) {
  const { children, light, centered, small, containerStyle, italic, bold, visible, size, noWrap, color } = props;

  if (visible) {
    return (
      <Container
        $light={light}
        $centered={centered}
        $small={small}
        style={containerStyle}
        $italic={italic}
        $bold={bold}
        $size={size}
        $noWrap={noWrap}
        $color={color}
      >
        {children}
      </Container>
    );
  }
  return null;
}

Text.defaultProps = {
  light: false,
  centered: false,
  small: false,
  containerStyle: {},
  italic: false,
  bold: false,
  visible: true,
  size: 16,
  noWrap: false,
  color: null,
};
