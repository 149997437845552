import React from 'react';
import { Button } from 'react-bootstrap';
import Config from '../../../../Globals/Config';
import { Environment } from '../../../../Globals/Types/Enums';
import ElasticIndexFormDevModal from './ElasticIndexFormDevModal';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { SystemAdminUserIds } from '../../../../Globals/Types/Constants';

/**
 * ElasticIndexFormDev
 * @constructor
 */
export default function ElasticIndexFormDevButtons() {
  const { userId } = useAppSelector((state) => state.auth);
  const [visible, setVisible] = React.useState(false);
  const [t] = useTranslation();

  if (
    Config.env === Environment.dev ||
    Config.env === Environment.staging ||
    (Config.env === Environment.production && SystemAdminUserIds.indexOf(userId) >= 0)
  ) {
    return (
      <>
        <Button onClick={() => setVisible(true)} variant="danger" style={{ marginBottom: 10 }}>
          <i className="fa-brands fa-searchengin" style={{ marginRight: 8 }} />
          {t('modules.settings.elasticSearch.createIndices.headline')}
        </Button>
        <ElasticIndexFormDevModal onClose={() => setVisible(false)} visible={visible} />
      </>
    );
  }

  return null;
}
