import React from 'react';
import { FacilityEntity, MailSettings } from '../../../../Globals/Types/Types';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import Input, { InputType } from '../../../../Components/Inputs/Input';
import { useTranslation } from 'react-i18next';
import Panel from '../../../../Components/Panel';
import EmailTestModal from './EmailValidationModal';
import AskDeleteModal from '../../../../Components/Modals/AskDeleteModal';
import { isValidMailSettings, MailSettingsError } from './ValidateMailSettings';
import { useDispatchUpdateFacility } from '../../../../Redux/Actions/Client/FacilityAction';
import { useAppSelector, useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import { useNavigate } from 'react-router';
import { rot13Crypt, rot13Decrypt } from '../../../../Globals/Functions';
import SelectMailProvider, { MailProvider } from '../../../../Components/PredefinedSelects/SelectMailProvider';
import ShowHideContainer from '../../../../Components/ShowHideContainer';
import SelectMailService from '../../../../Components/PredefinedSelects/SelectMailService';

interface Props {
  facilityId: string;
}

export interface State {
  mail: MailSettings;
  initialized: boolean;
  hasChanges: boolean;
  saved: boolean;
}

type Action =
  | { type: 'mail'; payload: string }
  | { type: 'host'; payload: string }
  | { type: 'port'; payload: number }
  | { type: 'user'; payload: string }
  | { type: 'password'; payload: string }
  | { type: 'displayName'; payload: string }
  | { type: 'useInternalService'; payload: boolean }
  | { type: 'setProvider'; payload: MailProvider }
  | { type: 'init'; payload: MailSettings }
  | { type: 'reset' }
  | { type: 'setValidated' }
  | { type: 'unsetValidated' }
  | { type: 'setSaved'; payload: boolean };

const initialState: State = {
  mail: {
    useInternalService: false,
    mail: '',
    host: '',
    port: 21,
    user: '',
    password: '',
    displayName: '',
    validated: false,
  },
  initialized: false,
  hasChanges: false,
  saved: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'useInternalService':
      return {
        ...state,
        mail: { ...state.mail, useInternalService: action.payload },
        hasChanges: true,
        saved: false,
      };
    case 'mail':
      return { ...state, mail: { ...state.mail, mail: action.payload }, hasChanges: true, saved: false };
    case 'host':
      return { ...state, mail: { ...state.mail, host: action.payload }, hasChanges: true, saved: false };
    case 'port':
      return { ...state, mail: { ...state.mail, port: action.payload }, hasChanges: true, saved: false };
    case 'user':
      return { ...state, mail: { ...state.mail, user: action.payload }, hasChanges: true, saved: false };
    case 'password':
      return { ...state, mail: { ...state.mail, password: action.payload }, hasChanges: true, saved: false };
    case 'displayName':
      return { ...state, mail: { ...state.mail, displayName: action.payload }, hasChanges: true, saved: false };
    case 'setProvider':
      return {
        ...state,
        mail: { ...state.mail, host: action.payload.host, port: action.payload.port },
        hasChanges: true,
        saved: false,
      };
    case 'setValidated':
      return { ...state, mail: { ...state.mail, validated: true } };
    case 'unsetValidated':
      return { ...state, mail: { ...state.mail, validated: false }, saved: false };
    case 'setSaved':
      return { ...state, saved: action.payload };
    case 'init':
      return {
        ...initialState,
        mail: { ...action.payload },
        initialized: true,
        saved: !!action.payload,
      };
  }
};

/**
 * EmailForm
 * @param props
 * @constructor
 */
export default function EmailForm(props: Props) {
  const { facilityId } = props;
  const navigate = useNavigate();
  const { facilities } = useAppSelector((state) => state.client);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [showValidationModal, setShowValidationModal] = React.useState<boolean>(false);
  const [askReset, setAskReset] = React.useState<boolean>(false);
  const [providerSelected, setProviderSelected] = React.useState<boolean>(false);
  const [facility, setFacility] = React.useState<FacilityEntity>();
  const [errors, setErrors] = React.useState<MailSettingsError>({});
  const [t] = useTranslation();
  const { setLoading, loadingModal } = useLoadingModal({ delayStart: 500, headline: t('savingChanges') });

  const dispatchUpdate = useDispatchUpdateFacility();

  React.useEffect(() => {
    if (facilities) {
      const filtered = facilities.find((item) => item.facilityId === facilityId);
      if (filtered) {
        setFacility(filtered);
        if (filtered.settings && filtered.settings.mailSettings) {
          dispatch({ type: 'init', payload: filtered.settings.mailSettings });
        }
      } else {
        navigate('/dashboard');
      }
    }
  }, [facilities, facilityId, navigate]);

  const handelOnSave = () => {
    const validationResult = isValidMailSettings(state.mail);

    if (validationResult.isValid) {
      setLoading(true);
      dispatchUpdate(facilityId, {
        settings: { ...facility.settings, mailSettings: { ...state.mail, validated: state.mail.useInternalService } },
      })
        .then(() => {
          dispatch({ type: 'setSaved', payload: true });
          if (!state.mail.useInternalService) {
            setShowValidationModal(true);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setErrors(validationResult.errors);
    }
  };

  const handleToggleValidation = (stateParam: boolean) => {
    if (facility.settings.mailSettings.validated !== stateParam) {
      setLoading(true);
      dispatchUpdate(facilityId, {
        settings: { ...facility.settings, mailSettings: { ...state.mail, validated: stateParam } },
      }).finally(() => setLoading(false));

      if (stateParam) {
        dispatch({ type: 'setValidated' });
      } else {
        dispatch({ type: 'unsetValidated' });
      }
    }
    setAskReset(false);
    setShowValidationModal(false);
  };

  const handleToggleUseInternal = (value: boolean) => {
    dispatchUpdate(facilityId, {
      settings: { ...facility.settings, mailSettings: { ...state.mail, useInternalService: value, validated: value } },
    })
      .then((response) => {
        dispatch({ type: 'init', payload: response.settings.mailSettings });
      })
      .finally(() => setLoading(false));
  };

  const handleSelectProvider = (provider: MailProvider) => {
    if (provider) {
      setProviderSelected(true);
      dispatch({ type: 'setProvider', payload: provider });
    } else {
      setProviderSelected(false);
    }
  };

  const renderValidation = () => {
    if (!state.hasChanges && state.mail) {
      if (state.mail.validated) {
        return (
          <Alert variant="success">
            <i className="fas fa-check-circle" style={{ marginRight: 10 }} />
            {t('modules.settings.facility.emailValidated')}
          </Alert>
        );
      }

      if (state.saved) {
        return (
          <Alert variant="danger">
            <div className="ContainerAlert">{t('modules.settings.facility.emailValidationDescription')}</div>
          </Alert>
        );
      }
    }

    return null;
  };

  const renderActionButtons = () => {
    if (state.mail.useInternalService) {
      return null;
    }

    if (!state.saved && !state.mail.validated) {
      return (
        <Button onClick={handelOnSave} variant="success" disabled={!state.hasChanges}>
          {t('save')}
        </Button>
      );
    }
    if (state.saved && !state.mail.validated) {
      return (
        <Button onClick={() => setShowValidationModal(true)} variant="success">
          {t('validateNow')}
        </Button>
      );
    }
    if (state.saved && state.mail.validated) {
      return (
        <Button onClick={() => setAskReset(true)} variant="danger">
          {t('validateReset')}
        </Button>
      );
    }
  };

  return (
    <Panel headline={t('emailSettings')} description={t('modules.settings.facility.emailSettings')}>
      <Row>
        <Col lg={12}>
          <SelectMailService onChange={handleToggleUseInternal} selected={state.mail.useInternalService} />
        </Col>
      </Row>

      <ShowHideContainer visible={!state.mail.validated && !state.mail.useInternalService}>
        <Row style={{ marginTop: 30 }}>
          <Col lg={12}>{renderValidation()}</Col>
          <Col lg={6}>
            <SelectMailProvider onChange={handleSelectProvider} selected={'manual'} />
          </Col>
        </Row>
      </ShowHideContainer>

      <ShowHideContainer visible={!state.mail.useInternalService}>
        <Row>
          <Col lg={6}>
            <Input
              label={t('eMailAddress')}
              value={state.mail.mail}
              onChange={(value) => dispatch({ type: 'mail', payload: value })}
              disabled={state.mail.validated}
              hasError={!!errors.mail}
              required
            />
          </Col>
          <Col lg={6}>
            <Input
              label={t('displayName')}
              value={state.mail.displayName}
              onChange={(value) => dispatch({ type: 'displayName', payload: value })}
              hasError={!!errors.displayName}
            />
          </Col>

          <Col lg={6}>
            <Input
              label={t('username')}
              value={state.mail.user}
              onChange={(value) => dispatch({ type: 'user', payload: value })}
              disabled={state.mail.validated}
              hasError={!!errors.user}
              required
            />
          </Col>
          <Col lg={6}>
            <Input
              label={t('password')}
              value={rot13Decrypt(state.mail.password)}
              onChange={(value) => dispatch({ type: 'password', payload: rot13Crypt(value) })}
              type={InputType.password}
              disabled={state.mail.validated}
              hasError={!!errors.password}
              required
            />
          </Col>

          <Col lg={6}>
            <Input
              label={t('host')}
              value={state.mail.host}
              onChange={(value) => dispatch({ type: 'host', payload: value })}
              disabled={state.mail.validated || providerSelected}
              hasError={!!errors.host}
              required
            />
          </Col>
          <Col lg={6}>
            <Input
              label={t('port')}
              value={state.mail.port}
              onChange={(value) => dispatch({ type: 'port', payload: parseInt(value) })}
              disabled={state.mail.validated || providerSelected}
              hasError={!!errors.port}
              type={InputType.number}
              required
            />
          </Col>
        </Row>
      </ShowHideContainer>

      <Row>
        <Col lg={12} className="d-flex justify-content-end">
          {renderActionButtons()}
        </Col>
      </Row>

      <EmailTestModal
        facilityId={facilityId}
        initialEmail={state.mail.mail}
        onClose={handleToggleValidation}
        visible={showValidationModal}
      />
      <AskDeleteModal
        onDelete={() => handleToggleValidation(false)}
        onClose={() => setAskReset(false)}
        visible={askReset}
        headline={t('validateReset')}
        description={t('modules.settings.facility.emailValidationResetDescription')}
        confirmButtonText={t('validateReset')}
      />

      {loadingModal}
    </Panel>
  );
}
