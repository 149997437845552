import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { BankEntity, FacilityEntity } from '../../../../Globals/Types/Types';
import ValidateFacility from '../Components/ValidateFacility';
import FacilityForm from '../../../../Components/Facility/FacilityForm';
import BankForm from '../../../../Components/Facility/BankForm';
import { useNavigate, useParams } from 'react-router';
import { useDispatchGetFacility, useDispatchUpdateFacility } from '../../../../Redux/Actions/Client/FacilityAction';
import FacilityHeader from '../Components/FacilityHeader';
import { useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import BottomInternalIdContainer from '../../../../Components/BottomInternalIdContainer';

export type State = {
  facility: FacilityEntity;
  errors: { facility: FacilityEntity; bank: BankEntity };
  initialized: Boolean;
  hasChanges: Boolean;
};

const initialState: State = {
  facility: {} as FacilityEntity,
  errors: {
    facility: {} as FacilityEntity,
    bank: {} as BankEntity,
  },
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case 'updateClient': {
      const { key, value } = action.payload;
      return { ...state, facility: { ...state.facility, [key]: value }, hasChanges: true };
    }
    case 'updateBank': {
      const { key, value } = action.payload;
      return {
        ...state,
        hasChanges: true,
        facility: {
          ...state.facility,
          bank: { ...state.facility.bank, [key]: value },
        },
      };
    }
    case 'resetChanges':
      return {
        ...state,
        hasChanges: false,
        errors: {
          facility: {} as FacilityEntity,
          bank: {} as BankEntity,
        },
      };
    case 'init': {
      return {
        ...state,
        facility: action.payload,
        initialized: true,
        hasChanges: false,
        errors: {
          facility: {} as FacilityEntity,
          bank: {} as BankEntity,
        },
      };
    }
    case 'setErrors':
      const { facility, bank } = action.payload;
      return { ...state, errors: { facility, bank } };
  }
};

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatchGet = useDispatchGetFacility();
  const dispatchSave = useDispatchUpdateFacility();
  const { facilityId } = useParams();
  const { loadingModal, setLoading, isLoading } = useLoadingModal({ delayStart: 1000 });
  const { setLoading: setInitLoading, isLoading: initiallyIsLoading } = useLoadingModal();

  const initialize = React.useCallback((data) => {
    dispatch({ type: 'init', payload: data });
  }, []);

  React.useEffect(() => {
    setInitLoading(true);
    setLoading(true);
    dispatchGet(facilityId)
      .then((facility) => {
        initialize(facility);
        setInitLoading(false);
        setLoading(false);
      })
      .catch(() => {
        navigate('/dashboard');
        setInitLoading(false);
        setLoading(false);
      });
  }, [dispatchGet, facilityId, initialize, setInitLoading, navigate, setLoading]);

  const handleSave = () => {
    const { isValid, errors } = ValidateFacility(state.facility);

    if (isValid) {
      setLoading(true);

      dispatchSave(facilityId, state.facility)
        .then((facility) => {
          initialize(facility);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      dispatch({ type: 'setErrors', payload: { facility: errors, bank: errors.bank } });
    }
  };

  const handleResetData = () => {
    dispatchGet(facilityId).then((facility) => {
      initialize(facility);
    });
  };

  const renderSaveButton = () => {
    if (state.hasChanges) {
      return (
        <Button variant="success" onClick={handleSave} disabled={isLoading}>
          {t('saveChanges')}
        </Button>
      );
    }
    return null;
  };

  const renderResetButton = () => {
    if (state.hasChanges) {
      return (
        <Button variant="outline-secondary" onClick={handleResetData} disabled={isLoading}>
          <i className="fas fa-eraser" />
        </Button>
      );
    }
    return null;
  };

  if (state.initialized) {
    return (
      <>
        <FacilityHeader actionButtonOne={renderSaveButton()} actionButtonTwo={renderResetButton()} />
        <FacilityForm
          data={state.facility}
          onChange={({ key, value }) => dispatch({ type: 'updateClient', payload: { key, value } })}
          disabled={isLoading}
          errors={state.errors.facility}
          showSkeleton={initiallyIsLoading}
          disableName={false}
        />
        <BankForm
          data={state.facility.bank || ({} as BankEntity)}
          onChange={({ key, value }) => dispatch({ type: 'updateBank', payload: { key, value } })}
          disabled={isLoading}
          errors={state.errors.bank}
          showSkeleton={initiallyIsLoading}
        />
        {loadingModal}

        <BottomInternalIdContainer id={facilityId} />
      </>
    );
  }
  return null;
}
