import React from 'react';
import { useDispatch } from 'react-redux';
import {
  FACILITY_PARTNER_CREATE_ERROR,
  FACILITY_PARTNER_CREATE_START,
  FACILITY_PARTNER_CREATE_SUCCESS,
  FACILITY_PARTNER_DELETE_ERROR,
  FACILITY_PARTNER_DELETE_START,
  FACILITY_PARTNER_DELETE_SUCCESS,
  FACILITY_PARTNER_GET_ERROR,
  FACILITY_PARTNER_GET_INFOS_ERROR,
  FACILITY_PARTNER_GET_INFOS_START,
  FACILITY_PARTNER_GET_INFOS_SUCCESS,
  FACILITY_PARTNER_GET_LIST_ERROR,
  FACILITY_PARTNER_GET_LIST_FROM_CACHE_SUCCESS,
  FACILITY_PARTNER_GET_LIST_START,
  FACILITY_PARTNER_GET_LIST_SUCCESS,
  FACILITY_PARTNER_GET_START,
  FACILITY_PARTNER_GET_SUCCESS,
  FACILITY_PARTNER_UPDATE_ERROR,
  FACILITY_PARTNER_UPDATE_START,
  FACILITY_PARTNER_UPDATE_SUCCESS,
} from '../../../ActionTypes';
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../../../Globals/FirebaseGlobals';
import { FacilityEntity, FacilityPartnerEntity } from '../../../../Globals/Types/Types';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  serverTimestamp,
  Timestamp,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { useDispatchGetFacility } from '../FacilityAction';
import Lodash from 'lodash';

/**
 * useDispatchUserCreate()
 */
export const useDispatchPartnerGetInfos = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    (mailOrId?: string) => {
      const data = {
        partnerMail: mailOrId.indexOf('@') > 0 ? mailOrId : null,
        facilityId: mailOrId.indexOf('@') < 0 ? mailOrId : null,
      };

      dispatch({ type: FACILITY_PARTNER_GET_INFOS_START, payload: mailOrId });

      const getInfos = httpsCallable(getFunctions(), FirebaseFunctionNames.getPartnerInfos);
      return getInfos(data)
        .then((response: HttpsCallableResult<any>) => {
          if (response.data.result) {
            dispatch({ type: FACILITY_PARTNER_GET_INFOS_SUCCESS, payload: response.data });
            if (response.data.data) {
              return Promise.resolve({
                clientId: response.data.data.clientId,
                facilityId: response.data.data.facilityId,
                name: response.data.data.name,
              });
            }
          }
          return Promise.reject(response.data);
        })
        .catch((error) => {
          dispatch({ type: FACILITY_PARTNER_GET_INFOS_ERROR, payload: error });
          return Promise.reject();
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchPartnerCreate()
 */
type CreatePartnersReturnType = (
  facility: FacilityEntity,
  partnerClientId: string,
  partnerFacilityId: string,
  partnerName: string,
) => Promise<FacilityPartnerEntity>;

export const useDispatchPartnerCreate = (): CreatePartnersReturnType => {
  const dispatch = useDispatch();
  const dispatchGetFacility = useDispatchGetFacility();
  const dispatchGetList = useDispatchPartnersGetList();
  const { clientId, userId } = useAppSelector((state) => state.auth);

  return React.useCallback<CreatePartnersReturnType>(
    (facility, partnerClientId, partnerFacilityId, partnerName) => {
      dispatch({ type: FACILITY_PARTNER_CREATE_START, payload: { facility, partnerClientId, partnerFacilityId } });

      const collectionRef = collection(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.facility,
        facility.facilityId,
        FirebasePathMappings.partners,
      );

      const partnerEntity: FacilityPartnerEntity = {
        partnerClientId: partnerClientId,
        partnerFacilityId: partnerFacilityId,
        partnerName,
        active: false,
        approved: false,
        invitedFacilityId: facility.facilityId,
        invitedClientId: clientId,
        invitedUserId: userId,
        createdDate: serverTimestamp() as Timestamp,
        executeTrigger: true,
        settings: {
          assignOrders: true,
          createOrders: true,
          responsibleUserId: null,
          allowUseStorage: false,
          defaultStorageId: null,
        },
        partnerSettings: {
          assignOrders: true,
          createOrders: true,
          responsibleUserId: null,
          allowUseStorage: false,
          defaultStorageId: null,
        },
      };

      return addDoc(collectionRef, partnerEntity)
        .then(() => {
          return dispatchGetFacility(facility.facilityId).then(() => {
            return dispatchGetList(facility.facilityId, false).then(() => {
              dispatch({ type: FACILITY_PARTNER_CREATE_SUCCESS, payload: partnerEntity });
              return Promise.resolve(partnerEntity);
            });
          });
        })
        .catch((error) => {
          dispatch({ type: FACILITY_PARTNER_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, dispatchGetFacility, dispatchGetList, userId],
  );
};

/**
 * useDispatchPartnersGetList()
 */
type GetListPartnersReturnType = (facilityId: string, fromCache: boolean) => Promise<Array<FacilityPartnerEntity>>;

export const useDispatchPartnersGetList = (dispatchChanges: boolean = true): GetListPartnersReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);
  const { facilityPartners } = useAppSelector((state) => state.cache);

  return React.useCallback<GetListPartnersReturnType>(
    (facilityId, fromCache = true) => {
      const cacheIndex = Lodash.findIndex(facilityPartners, (item) => item.facilityId === facilityId);

      if (cacheIndex > -1 && fromCache) {
        const data = facilityPartners[cacheIndex].partners;
        dispatch({ type: FACILITY_PARTNER_GET_LIST_FROM_CACHE_SUCCESS, payload: data });
        return Promise.resolve(data);
      } else {
        dispatch({ type: FACILITY_PARTNER_GET_LIST_START, payload: facilityId });

        const collectionRef = collection(
          getFirestore(),
          FirebasePathMappings.client,
          clientId,
          FirebasePathMappings.facility,
          facilityId,
          FirebasePathMappings.partners,
        );
        const queryRef = query(collectionRef);

        return getDocs(queryRef)
          .then((snapShot) => {
            const data: Array<FacilityPartnerEntity> = [];

            if (!snapShot.empty) {
              snapShot.forEach((item) => {
                data.push({ ...(item.data() as FacilityPartnerEntity), partnerId: item.id });
              });
            }

            if (dispatchChanges) {
              dispatch({ type: FACILITY_PARTNER_GET_LIST_SUCCESS, payload: { facilityId, partners: data } });
            } else {
              dispatch({
                type: 'FACILITY_PARTNER_GET_LIST_SUCCESS_DEACTIVATED_DISPATCH',
                payload: { facilityId, partners: data },
              });
            }
            return Promise.resolve(data);
          })
          .catch((error) => {
            dispatch({ type: FACILITY_PARTNER_GET_LIST_ERROR, payload: error });
            return Promise.reject(error);
          });
      }
    },
    [clientId, dispatch, dispatchChanges, facilityPartners],
  );
};

/**
 * useDispatchPartnerUpdate()
 */
type UpdatePartnersReturnType = (
  facilityId: string,
  partner: FacilityPartnerEntity,
) => Promise<Array<FacilityPartnerEntity>>;

export const useDispatchPartnerUpdate = (): UpdatePartnersReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);
  const dispatchGetList = useDispatchPartnersGetList();

  return React.useCallback<UpdatePartnersReturnType>(
    (facilityId, partner) => {
      dispatch({ type: FACILITY_PARTNER_UPDATE_START, payload: facilityId });

      const docRef = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.facility,
        facilityId,
        FirebasePathMappings.partners,
        partner.partnerId,
      );

      const mapped: FacilityPartnerEntity = { ...partner, executeTrigger: true };

      return setDoc(docRef, mapped, { merge: true })
        .then(() => {
          return dispatchGetList(facilityId, false).then((response) => {
            dispatch({ type: FACILITY_PARTNER_UPDATE_SUCCESS, payload: response });
            return Promise.resolve(response);
          });
        })
        .catch((error) => {
          dispatch({ type: FACILITY_PARTNER_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, dispatchGetList],
  );
};

/**
 * useDispatchPartnersGetList()
 */
type GetPartnersReturnCallbackType = (facilityId: string, partnerId: string) => Promise<FacilityPartnerEntity>;

export const useDispatchPartnersGet = (): GetPartnersReturnCallbackType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetPartnersReturnCallbackType>(
    (facilityId, partnerId) => {
      dispatch({ type: FACILITY_PARTNER_GET_START, payload: { facilityId, partnerId } });

      const docRef = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.facility,
        facilityId,
        FirebasePathMappings.partners,
        partnerId,
      );

      return getDoc(docRef)
        .then((snapShot) => {
          if (snapShot.exists) {
            const entity = { ...snapShot.data(), partnerId: snapShot.id };
            dispatch({ type: FACILITY_PARTNER_GET_SUCCESS, payload: entity });
            return Promise.resolve(entity as FacilityPartnerEntity);
          }

          return Promise.reject();
        })
        .catch((error) => {
          dispatch({ type: FACILITY_PARTNER_GET_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchPartnersGetList()
 */
type DeletePartnersReturnCallbackType = (
  facilityId: string,
  partnerId: string,
) => Promise<Array<FacilityPartnerEntity>>;

export const useDispatchPartnerDelete = (): DeletePartnersReturnCallbackType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);
  const dispatchGetList = useDispatchPartnersGetList();

  return React.useCallback<DeletePartnersReturnCallbackType>(
    (facilityId, partnerId) => {
      dispatch({ type: FACILITY_PARTNER_DELETE_START, payload: { facilityId, partnerId } });

      const docRef = doc(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.facility,
        facilityId,
        FirebasePathMappings.partners,
        partnerId,
      );

      return deleteDoc(docRef)
        .then(() => {
          return dispatchGetList(facilityId, false).then((response) => {
            dispatch({ type: FACILITY_PARTNER_DELETE_SUCCESS, payload: response });
            return Promise.resolve(response);
          });
        })
        .catch((error) => {
          dispatch({ type: FACILITY_PARTNER_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, dispatchGetList],
  );
};
