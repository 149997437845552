import React from 'react';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { useDispatchTimeTrackingGetLast } from '../../../Redux/Actions/User/TimeTrackingAction';
import TimeTrackingModal from '../../../Modules/Settings/TimeTracking/TimeTrackingModal';
import { TimeTrackingType } from '../../../Globals/Types/Enums';
import { useTheme } from 'styled-components';
import { GroupKey } from '../../../Globals/Types/Types';
import { useSubscriptionIsValid } from '../../../Globals/Hooks/SubscriptionHooks';
import MainNavigationItem from './MainNavigationItem';
import { getTimeTrackingIconColorByType } from '../../../Globals/Functions/TimeTrackingFunctions';

/**
 * TimeTrackingItem()
 * @constructor
 */
export default function TimeTrackingItem() {
  const theme = useTheme() as any;
  const [show, setShow] = React.useState<boolean>(false);
  const { lastState } = useAppSelector((state) => state.user.timeTracking);
  const { timeTracking } = useAppSelector((state) => state.auth.client.settings);
  const { groups: userGroups } = useAppSelector((state) => state.auth.user);
  const { subscriptionModal, clickHandler } = useSubscriptionIsValid();
  const dispatchGetLastEntry = useDispatchTimeTrackingGetLast();

  React.useEffect(() => {
    if (timeTracking.active) {
      dispatchGetLastEntry().then(() => {});
    }
  }, [dispatchGetLastEntry, timeTracking.active]);

  const getTimeTrackingIcon = () => {
    if (lastState) {
      switch (lastState.type) {
        case TimeTrackingType.startTour:
        case TimeTrackingType.start:
          return 'fa-kit fa-regular-circle-play-clock';
        case TimeTrackingType.stopTour:
        case TimeTrackingType.stop:
          return 'fa-kit fa-sharp-regular-circle-stop-clock';
        case TimeTrackingType.pauseTour:
        case TimeTrackingType.pause:
          return 'fa-kit fa-regular-circle-pause-clock';
        case TimeTrackingType.trafficJamTour:
          return 'fas fa-truck-clock';
        default:
          return 'fa-kit fa-sharp-regular-circle-stop-clock';
      }
    }
    return 'fa-kit fa-sharp-regular-circle-stop-clock';
  };

  if (timeTracking.active && userGroups.indexOf(GroupKey.TIMETRACKING) >= 0) {
    return (
      <>
        <MainNavigationItem
          icon={getTimeTrackingIcon()}
          onPress={() => clickHandler(() => setShow(true))}
          iconColor={getTimeTrackingIconColorByType(lastState ? lastState.type : TimeTrackingType.stop, theme)}
          visible={true}
        />

        <TimeTrackingModal visible={show} onClose={() => setShow(false)} />
        {subscriptionModal}
      </>
    );
  }
  return null;
}
