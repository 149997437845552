import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import AskDeleteModal from '../../../../Components/Modals/AskDeleteModal';
import { useNavigate } from 'react-router';
import { useDispatchUserDelete } from '../../../../Redux/Actions/UserAction';
import { GroupKey, UserEntity } from '../../../../Globals/Types/Types';

interface Props {
  user: UserEntity;
}

/**
 * DeleteButton
 * @constructor
 */
export default function DeleteButton(props: Props) {
  const { user } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const { userId, user: currentUser, client } = useAppSelector((state) => state.auth);
  const dispatchDeleteUser = useDispatchUserDelete();
  const [isClientAdmin] = React.useState<boolean>(client.adminUserId === user.userId);

  const handleDelete = () => {
    dispatchDeleteUser(user.userId).then(() => {
      setAskDelete(false);
      navigate(-1);
    });
  };

  if (currentUser.groups.indexOf(GroupKey.ADMIN) > -1 && userId !== user.userId && !isClientAdmin) {
    return (
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <Button variant="outline-danger" onClick={() => setAskDelete(true)} style={{ border: 0 }}>
          {t('userDelete')}
        </Button>

        <AskDeleteModal
          onDelete={handleDelete}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
          headline={t('userDelete')}
          description={t('modules.settings.userDeleteDescription', { ...user })}
          confirmButtonText={t('userDelete')}
        />
      </div>
    );
  }
  return null;
}
