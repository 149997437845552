import React from 'react';
import styled from 'styled-components';

type LoadingModalProps = {
  visible: boolean;
  headline: string;
  line1: string;
  line2: string;
  progress: number;
};

const Container = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9) center center no-repeat;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .Container {
    text-align: center;

    h4,
    .Progress {
      color: #fff;
    }

    i {
      --fa-animation-duration: 1s;
      --fa-fade-opacity: 0.1;
      font-size: 50px;
      color: #ffffff;
      margin: 20px;
    }

    .Text {
      color: #fff;
      font-weight: 200;
    }
  }
`;

/**
 * LoadingModal
 * @param props
 * @constructor
 */
export default function LoadingModal(props: LoadingModalProps) {
  const { visible, headline, line1, line2, progress } = props;

  const renderHeadline = () => {
    if (headline) {
      return <h4>{headline}</h4>;
    }
    return null;
  };

  const renderProgress = () => {
    if (progress > 0) {
      return <div className="Progress">{`${progress}%`}</div>;
    }
    return null;
  };

  const renderText = () => {
    if (line1) {
      return (
        // eslint-disable-next-line react/no-danger
        <div className="Text" dangerouslySetInnerHTML={{ __html: line1 }} />
      );
    }
    return null;
  };

  const renderText2 = () => {
    if (line2) {
      return (
        // eslint-disable-next-line react/no-danger
        <div className="Text" dangerouslySetInnerHTML={{ __html: line2 }} />
      );
    }
    return null;
  };

  if (visible) {
    return (
      <Container>
        <div className="Container">
          {renderHeadline()}
          {renderText()}
          {renderText2()}
          <div>
            <i className="fak fa-beat-fade fa-sotosoft" />
          </div>
          {renderProgress()}
        </div>
      </Container>
    );
  }
  return null;
}

LoadingModal.defaultProps = {
  headline: null,
  text: null,
  progress: 0,
};
