import { InternalErrorCodes } from './InternalErrorCodes';
import moment, { Moment } from 'moment';
import { FacilityEntity, GroupedTimeTracking, PickedUserEntity, TimeTrackingEntity, UserEntity } from './Types/Types';
import { Timestamp } from 'firebase/firestore';
import { Salutation, TimeTrackingType } from './Types/Enums';
import { OrderCustomerAddress, PositionEntity } from './Types/OrderTypes';
import Lodash from 'lodash';
import { CustomerEntity } from './Types/Customer';
import { InvoiceAddressEntity } from './Types/Invoice';

/**
 * Formats the customer names for display
 * @param customer
 */
export const customerDisplayName = (customer: OrderCustomerAddress | CustomerEntity | InvoiceAddressEntity): string => {
  if (customer) {
    if (customer.salutation !== Salutation.company) {
      return `${customer.lastName}, ${customer.firstName}`;
    }
    return customer.firstName;
  }

  return '';
};

/**
 * Formats the given Timestamp to show date and time and also the days difference between the date and now
 * @param date
 * @param onlyInFuture
 * @param t
 */
export const formatDateWithDiff = (date: string, onlyInFuture: boolean, t: Function) => {
  if (date) {
    const todayDate = moment().format('YYYY-MM-DD');
    const formattedDate = moment(date).format('YYYY-MM-DD');

    const daysBetween = moment(formattedDate).diff(moment(todayDate), 'day');
    if (daysBetween === -1) {
      return `${moment(date).format('LLL')} (${t('yesterday')})`;
    }

    if (daysBetween < -1 && onlyInFuture) {
      return moment(date).format('LLL');
    } else {
      return `${moment(date).format('LLL')} (${t('xDay', { count: daysBetween })})`;
    }
  }
  return null;
};

/**
 * Get date and time in formatted from given Date or Timestamp
 * @param dateTime
 */
export const formatDateTime = (dateTime: Timestamp | Date | undefined | null | string): string => {
  if (dateTime) {
    let preparedDateTime: Date;
    if (dateTime instanceof Timestamp) {
      const timestamp = dateTime as Timestamp;
      preparedDateTime = timestamp.toDate();
    } else if (dateTime instanceof String) {
      preparedDateTime = moment(dateTime).toDate();
    } else {
      preparedDateTime = dateTime as Date;
    }

    return `${moment(preparedDateTime).format('LLL - LT')} Uhr`;
  }

  return null;
};

/**
 * formatTime()
 * @param time
 * @param outputFormat
 * @param inputFormat
 */
export const formatTime = (time: string, outputFormat: string = 'LT', inputFormat: string = 'HH:mm:ss'): string => {
  return `${moment(time, inputFormat).format(outputFormat)}h`;
};

/**
 * generateGuid()
 */
export const generateGuid = (): string => {
  function s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

/**
 * isValidEmail()
 * @param email
 * @returns {boolean}
 */
export function isValidateEmail(email: string): boolean {
  const re =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(email).toLowerCase())) {
    if (
      email.indexOf('à') > -1 ||
      email.indexOf('è') > -1 ||
      email.indexOf('ù') > -1 ||
      email.indexOf('ò') > -1 ||
      email.indexOf('ì') > -1 ||
      email.toLowerCase().indexOf('ü') > -1 ||
      email.toLowerCase().indexOf('ä') > -1 ||
      email.toLowerCase().indexOf('ö') > -1 ||
      email.toLowerCase().indexOf('ß') > -1
    ) {
      return false;
    }
    return true;
  }
  return false;
}

/**
 * Checks if a given file matches the extensions and the max file size
 * @param file
 * @param allowedExtensions
 * @param maxFileSize
 */
export const isFileValid = (file: File, allowedExtensions: Array<string>, maxFileSize?: number): string | true => {
  const extension = file.name.split('.').pop();
  if (allowedExtensions && allowedExtensions.length > 0) {
    if (allowedExtensions.indexOf(extension) < 0) {
      return InternalErrorCodes.EXTENSION_NOT_ALLOWED;
    }
  }
  if (maxFileSize && maxFileSize > 0 && file.size > maxFileSize) {
    return InternalErrorCodes.FILESIZE_TO_LARGE;
  }
  return true;
};

/**
 * Format bytes to kB or MB or GB
 * @param bytes
 */
export const formatSize = (bytes: number): string => {
  if (bytes < 1024) {
    return `${bytes} Byte`;
  }
  if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} kB`;
  }
  return `${(bytes / 1204 / 1024).toFixed(2)} MB`;
};

/**
 * getDaysOfMonth()
 * @param month
 */
export const getDaysOfMonth = (month: Moment): Array<Moment> => {
  let daysInMonth = month.daysInMonth();
  const arrDays = [];

  while (daysInMonth) {
    const current = moment(month).date(daysInMonth);
    arrDays.push(current);
    daysInMonth -= 1;
  }

  arrDays.reverse();
  const weekEnd = moment(arrDays[arrDays.length - 1]);

  let startDateOfWeek = moment(arrDays[0]).startOf('week');

  const data: Array<Moment> = [];
  do {
    for (let j = 0; j < 7; j += 1) {
      data.push(moment(startDateOfWeek).weekday(j));
    }
    startDateOfWeek = moment(startDateOfWeek).add(1, 'weeks').startOf('week');
  } while (startDateOfWeek.isSameOrBefore(weekEnd, 'week'));

  return data;
};

/**
 * Calculates the working minutes from the given time tracking array
 * @param data
 */
export const calculateMinutesForTimeTrackings = (data: Array<TimeTrackingEntity>): number => {
  let minutes: number = 0;
  let lastStart;

  data.forEach((item) => {
    if (item.type === TimeTrackingType.start) {
      lastStart = { ...item };
    } else {
      if (lastStart) {
        const typedStartTime: Timestamp = lastStart.timestamp;
        const typedCurrentTime: Timestamp = item.timestamp as Timestamp;

        minutes += moment(typedCurrentTime.toDate()).diff(moment(typedStartTime.toDate()), 'minutes');
      }
    }
  });

  return minutes;
};

/**
 * Group the array of TimeTracking entities by date
 * @param data
 * @param orderDirection
 */
export const groupTimeTrackingByDate = (
  data: Array<TimeTrackingEntity>,
  orderDirection: 'asc' | 'desc' = 'asc',
): Array<GroupedTimeTracking> => {
  if (!data || data.length === 0) {
    return [];
  }

  const dates = data.map((item) => {
    const typedTimestamp: Timestamp = item.timestamp as Timestamp;
    return moment(typedTimestamp.toDate()).format('YYYY-MM-DD');
  });
  const uniqueDates = [...new Set(dates)].sort((a, b) => {
    if (orderDirection === 'asc') {
      return a.localeCompare(b);
    }
    return b.localeCompare(a);
  });

  let result: Array<GroupedTimeTracking> = [];

  uniqueDates.forEach((date) => {
    const filteredData = data.filter((item) => {
      const typedTimestamp: Timestamp = item.timestamp as Timestamp;
      return moment(typedTimestamp.toDate()).isSame(moment(date), 'day');
    });

    result = [...result, { date, data: filteredData, minutes: calculateMinutesForTimeTrackings(filteredData) }];
  });

  return result;
};

/**
 * Convert minutes to readable time string with hh:mm
 * @param minutesParam
 * @returns {string}
 */
export const convertMinutesToTimeString = (minutesParam: number): string => {
  const hours = minutesParam / 60;
  const rHours = Math.floor(hours);
  const minutes = (hours - rHours) * 60;
  const rMinutes = Math.round(minutes) < 10 ? `0${Math.round(minutes)}` : Math.round(minutes);

  return `${rHours}:${rMinutes}`;
};

/**
 * isValidPrice
 * @param price
 */
export const isValidPrice = (price: string): boolean => {
  const res = price.match(/^(\d{1,9})?(\.\d{2})?$/);
  return res !== null;
};

/**
 * Format currency
 * TODO: Add locale currency to be used in different countries
 * @param price
 * @param nullIfEmpty
 * @param currency
 */
export const formatCurrency = (price: string, nullIfEmpty: boolean = false, currency: string = 'EUR'): string => {
  let currencySign = '€';
  if (currency !== 'EUR') {
    currencySign = currency.toUpperCase();
  }

  if (price !== undefined && price != null) {
    const formatted = parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&.');
    return `${
      formatted.substring(0, formatted.length - 3) + ',' + formatted.substring(formatted.length - 2, formatted.length)
    } ${currencySign}`;
  }
  if (nullIfEmpty) {
    return null;
  }
  return `0.00 ${currencySign}`;
};

/**
 * Formats users names and initials to display as <lastName>, <firstName> (<initals>)
 * @param user
 * @param withInitials
 * @param useShortName
 */
export const userDisplayName = (
  user: PickedUserEntity | UserEntity,
  withInitials: boolean = false,
  useShortName: boolean = false,
): string => {
  let displayName: string = 'Unknown User Entity';
  if (user) {
    displayName = useShortName
      ? `${user.firstName.substring(0, 1).toUpperCase()}. ${user.lastName}`
      : `${user.lastName}, ${user.firstName}`;

    if (withInitials) {
      displayName += ` (${user.initials})`;
    }
  }
  return displayName;
};

/**
 *
 * @param facility
 * @param shortOnly
 */
export const facilityDisplayName = (facility: FacilityEntity, shortOnly: boolean = false) => {
  if (facility) {
    const short = facility.nameShort || facility.name.substring(0, 4).toUpperCase();

    if (shortOnly) {
      return short;
    }
    return `${facility.name} (${short})`;
  }
  return 'Betriebsstätte unbekannt!';
};

/**
 * Get an FA icon based on file extension
 * @param fileName
 */
export const getFileTypeIcon = (fileName: string) => {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'pdf':
      return 'fas fa-file-pdf';
    case 'doc':
    case 'docx':
      return 'fas fa-file-word';
    case 'xls':
    case 'xlsx':
      return 'fas fa-file-excel';
    case 'mp4':
    case 'avi':
      return 'fas fa-camera-movie';
    case 'csv':
      return 'fas fa-file-csv';
    default:
      return 'fas fa-file-image';
  }
};

/**
 * Get date in formatted
 * @param date
 * @param format
 */
export const formatDateString = (date: string | null | undefined, format: string = 'LLL'): string => {
  if (date) {
    return moment(date).format(format);
  }
  return null;
};

/**
 * formatWeek
 * @param week
 */
export const formatWeek = (week: string) => {
  const startDate = moment(week, 'WW/YYYY').startOf('week').format('DD.MM');
  const endDate = moment(week, 'WW/YYYY').endOf('week').format('DD.MM');
  return `KW ${week} (${startDate} - ${endDate})`;
};

/**
 * updateFacilityIdsArray()
 * @param value
 * @param facility
 * @param currFacilityIds
 */
export const updateFacilityIdsArray = (value: boolean, facility: FacilityEntity, currFacilityIds: string[]) => {
  const facilityIds: string[] = currFacilityIds ? [...currFacilityIds] : [];
  if (value) {
    facilityIds.push(facility.facilityId);
    return facilityIds;
  }

  return Lodash.remove(facilityIds, (facilityId) => facilityId !== facility.facilityId);
};

/**
 * getWorkDayShortString()
 * @param day
 * @param t
 */
export const getWorkDayShortString = (day: number, t: Function): string => {
  switch (day) {
    case 0:
      return t('weekdayShort.monday');
    case 1:
      return t('weekdayShort.tuesday');
    case 2:
      return t('weekdayShort.wednesday');
    case 3:
      return t('weekdayShort.thursday');
    case 4:
      return t('weekdayShort.friday');
    case 5:
      return t('weekdayShort.saturday');
    case 6:
      return t('weekdayShort.sunday');
  }
};

/**
 * userEntityToPickedUserEntity()
 * @param user
 */
export const userEntityToPickedUserEntity = (user: UserEntity): PickedUserEntity => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    initials: user.initials,
    userId: user.userId,
  };
};

/**
 * calculateAssemblyTimeFromPositions
 * @param positions
 */
export const calculateAssemblyTimeFromPositions = (positions: PositionEntity[]): number => {
  let time: number = 0;

  if (positions && positions.length > 0) {
    positions.forEach((position) => {
      if (position.assemblyTime) {
        time += position.assemblyTime;
      }
    });
  }
  return time;
};

/**
 * rot13()
 * @param str
 * @param input
 * @param output
 */
const rot13 = (str: string, input: string[], output: string[]) => {
  const lookup: any = input.reduce((m, k, i) => Object.assign(m, { [k]: output[i] }), {});
  return str
    .split('')
    .map((x) => lookup[x] || x)
    .join('');
};

/**
 * rot13Crypt()
 * @param str
 */
export const rot13Crypt = (str: string): string => {
  const input: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const output: string[] = 'IJKLMNOPQRSTUVWXYZABCDEFGHijklmnopqrstuvwxyzabcdefgh'.split('');
  return rot13(str, input, output);
};

/**
 * rot13Decrypt()
 * @param str
 */
export const rot13Decrypt = (str: string): string => {
  if (str && str.length > 0) {
    const input: string[] = 'IJKLMNOPQRSTUVWXYZABCDEFGHijklmnopqrstuvwxyzabcdefgh'.split('');
    const output: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    return rot13(str, input, output);
  }
  return '';
};

/**
 * isUrlValid()
 * @param str
 */
export const isUrlValid = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

/**
 * Check if a given string is a valid date in format DD.MM.YYYY
 * @param date
 */
export const isValidDateString = (date: string) => {
  const regex = /^\d{2}.\d{2}.\d{4}$/;
  return !(date.match(regex) === null);
};

/**
 * formatBirthdate()
 * @param date
 */
export const formatBirthdate = (date: string) => {
  if (date) {
    const years = moment().diff(moment(date), 'year');
    return `${moment(date).format('LL')} (${years})`;
  }
  return null;
};

/**
 * prepareStringToPrice()
 * @param value
 */
export const prepareStringToPrice = (value: string): string => {
  if (value && value.length > 0) {
    return value.replace(',', '.');
  }
  return '';
};

/**
 * openBase64PdfInBrowser()
 * @param base64
 */
export const openBase64PdfInBrowser = (base64: string): void => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: 'application/pdf;base64' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

/**
 * Download base64 PDF
 * @param base64
 * @param fileName
 */
export const downloadBase64Pdf = (base64: string, fileName: string): void => {
  let linkSource = base64;
  if (base64.indexOf('data:application/pdf;base64') < 0) {
    linkSource = `data:application/pdf;base64,${base64}`;
  }

  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};
