import React from 'react';
import { OrderEntity, ServiceEntity } from '../../Globals/Types/OrderTypes';
import General from '../../Modules/Order/Details/Services/General';
import { Button, Modal } from 'react-bootstrap';
import Headline from '../../Modules/Order/Details/Components/Headline';
import HeaderPanel from './Details/Service/HeaderPanel';
import { useTranslation } from 'react-i18next';
import Panel from '../Panel';
import { buildUrlCustomersDetails, buildUrlOrderServiceDetails } from '../../Globals/UrlFunctions';
import ShowHideContainer from '../ShowHideContainer';

interface Props {
  service: ServiceEntity;
  order: OrderEntity;
  visible: boolean;
  onClose: (clear: boolean) => void;
}

/**
 * ServiceOverlay()
 * @param props
 * @constructor
 */
export default function ServiceOverlay(props: Props) {
  const { service, order, visible, onClose } = props;
  const [t] = useTranslation();

  const openCustomer = () => {
    onClose(false);
    window.open(buildUrlCustomersDetails(order.customer.customerId));
  };

  const openOrder = () => {
    onClose(true);
    window.open(buildUrlOrderServiceDetails(order.ownerClientId, order.orderId, service.serviceId));
  };

  const renderContent = () => {
    if (service && order) {
      return (
        <>
          <Headline order={order} headline={service.type.caption} />
          <HeaderPanel order={order} service={service} />
          <General
            service={service}
            order={order}
            onChangeService={() => {}}
            isEditable={false}
            hideAssignPartner={true}
          />

          <Panel containerStyle={{ marginBottom: 0 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Button variant={'outline-success'} onClick={openOrder} style={{ marginRight: 10 }}>
                  {t('orderEdit')}
                  <i className="fa-regular fa-arrow-up-right-from-square" style={{ marginLeft: 10 }} />
                </Button>
                <ShowHideContainer visible={!!order.customer.customerId}>
                  <Button variant={'outline-success'} onClick={openCustomer}>
                    {t('customerOpen')}
                    <i className="fa-regular fa-arrow-up-right-from-square" style={{ marginLeft: 10 }} />
                  </Button>
                </ShowHideContainer>
              </div>
              <div>
                <Button variant={'secondary'} onClick={() => onClose(false)}>
                  {t('close')}
                </Button>
              </div>
            </div>
          </Panel>
        </>
      );
    }
    return null;
  };

  const renderLoading = () => {
    if (!service || !order) {
      return (
        <div style={{ textAlign: 'center', fontSize: 22 }}>
          <div>{t('serviceLoading')}</div>
          <div>
            <i className="fa fa-spin fa-spinner" />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Modal show={visible} centered size={'xl'} onHide={() => onClose(false)}>
      <div style={{ padding: 40 }}>
        {renderContent()}
        {renderLoading()}
      </div>
    </Modal>
  );
}
