import { OrderServiceType } from '../Types/OrderTypes';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useAppSelector } from './Hooks';

export type TemplateTypes = 'craft' | 'kitchen' | 'gardening' | 'pool' | 'furniture' | 'other' | 'transport';

const craft: OrderServiceType[] = [
  { key: '1', caption: 'constructionWork', icon: 'fas fa-tools', needReport: true },
  { key: '2', caption: 'maintenance', icon: 'fas fa-boxes-stacked', needReport: true },
  { key: '3', caption: 'demolition', icon: 'fas fa-trash-undo', needReport: true },
  { key: '4', caption: 'delivery', icon: 'fas fa-truck-fast', needReport: true },
  { key: '5', caption: 'other', icon: 'fas fa-star', needReport: true },
];

const kitchen: OrderServiceType[] = [
  { key: '1', caption: 'demontage', icon: 'fas fa-trash-undo', needReport: true },
  { key: '2', caption: 'delivery', icon: 'fas fa-person-dolly', needReport: true },
  { key: '3', caption: 'montage', icon: 'fas fa-tools', needReport: true },
  { key: '4', caption: 'reclamation', icon: 'fas fa-bomb', needReport: true },
  { key: '5', caption: 'other', icon: 'fas fa-star', needReport: true },
];

const gardening: OrderServiceType[] = [
  { key: '1', caption: 'maintenance', icon: 'fas fa-sprinkler', needReport: true },
  { key: '2', caption: 'service', icon: 'fas fa-tools', needReport: true },
  { key: '3', caption: 'other', icon: 'fas fa-star', needReport: true },
];

const pool: OrderServiceType[] = [
  { key: '1', caption: 'cleaning', icon: 'fas fa-soap', needReport: true },
  { key: '2', caption: 'maintenance', icon: 'fas fa-tools', needReport: true },
  { key: '3', caption: 'other', icon: 'fas fa-star', needReport: true },
];

const furniture: OrderServiceType[] = [
  { key: '1', caption: 'demontage', icon: 'fas fa-trash-undo', needReport: true },
  { key: '2', caption: 'delivery', icon: 'fas fa-person-dolly', needReport: true },
  { key: '3', caption: 'montage', icon: 'fas fa-tools', needReport: true },
  { key: '4', caption: 'reclamation', icon: 'fas fa-bomb', needReport: true },
  { key: '5', caption: 'other', icon: 'fas fa-star', needReport: true },
];

const other: OrderServiceType[] = [
  { key: '1', caption: 'work', icon: 'fas fa-briefcase', needReport: true },
  { key: '2', caption: 'maintenance', icon: 'fas fa-person-dolly', needReport: true },
  { key: '3', caption: 'other', icon: 'fas fa-star', needReport: true },
];

const transport: OrderServiceType[] = [
  { key: '1', caption: 'transfer', icon: 'fas fa-taxi', needReport: false },
  { key: '2', caption: 'transferEmpty', icon: 'fas fa-truck-flatbed', needReport: false },
  { key: '3', caption: 'other', icon: 'fas fa-star', needReport: false },
];

/**
 * useGetServiceTypeTemplates()
 */
export const useGetServiceTypeTemplates = () => {
  const [t] = useTranslation();

  return React.useCallback(
    (type: TemplateTypes): OrderServiceType[] => {
      switch (type) {
        case 'kitchen':
          return kitchen.map((item) => ({ ...item, caption: t(item.caption) }));
        case 'craft':
          return craft.map((item) => ({ ...item, caption: t(item.caption) }));
        case 'gardening':
          return gardening.map((item) => ({ ...item, caption: t(item.caption) }));
        case 'pool':
          return pool.map((item) => ({ ...item, caption: t(item.caption) }));
        case 'furniture':
          return furniture.map((item) => ({ ...item, caption: t(item.caption) }));
        case 'other':
          return other.map((item) => ({ ...item, caption: t(item.caption) }));
        case 'transport':
          return transport.map((item) => ({ ...item, caption: t(item.caption) }));
      }
    },
    [t],
  );
};

/**
 * useGetServiceTypeHasReportConfig()
 */
type GetServiceTypeHasReportConfigReturn = (type: string) => boolean;
export const useGetServiceTypeHasReportConfig = (): GetServiceTypeHasReportConfigReturn => {
  const { client } = useAppSelector((state) => state.auth);
  const { settings } = client;
  const { order } = settings;
  const { reportDefinition } = order;

  return React.useCallback<GetServiceTypeHasReportConfigReturn>(
    (serviceTypeKey: string) => {
      const found = reportDefinition.find((item) => item.serviceTypeKey === serviceTypeKey);
      return !!found;
    },
    [reportDefinition],
  );
};
