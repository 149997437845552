import React from 'react';
import Panel from '../../../../Panel';
import { useTranslation } from 'react-i18next';
import { OrderEntity } from '../../../../../Globals/Types/OrderTypes';
import { useDispatchUserGet } from '../../../../../Redux/Actions/UserAction';
import { FacilityEntity, UserEntity } from '../../../../../Globals/Types/Types';
import DisplayText from '../../../../View/DisplayText';
import moment from 'moment';
import { useDispatchGetExternalFacility } from '../../../../../Redux/Actions/Client/FacilityAction';
import { facilityDisplayName, formatCurrency, userDisplayName } from '../../../../../Globals/Functions';
import EditModal from './EditModal';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';

interface Props {
  order: OrderEntity;
  onChange: (item: Partial<OrderEntity>) => void;
}

/**
 * MetaData
 * @param props
 * @constructor
 */
export default function MetaData(props: Props) {
  const { order, onChange } = props;
  const [t] = useTranslation();
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<UserEntity>(null);
  const [ownerFacility, setOwnerFacility] = React.useState<FacilityEntity>(null);
  const [createdFacility, setCreatedFacility] = React.useState<FacilityEntity>(null);
  const { allowEditAll, allowEditInformation } = useAppSelector((state) => state.order.selectedOrder.permissions);
  const [canEdit] = React.useState<boolean>(allowEditAll || allowEditInformation);

  const dispatchGetUser = useDispatchUserGet();
  const dispatchGetFacility = useDispatchGetExternalFacility();

  React.useEffect(() => {
    dispatchGetUser(order.createdUserId).then(setUser);
  }, [dispatchGetUser, order.createdUserId]);

  React.useEffect(() => {
    dispatchGetFacility(order.ownerClientId, order.ownerFacilityId).then(setOwnerFacility);
    if (order.ownerClientId !== order.createdClientId) {
      dispatchGetFacility(order.createdClientId, order.createdFacilityId).then(setCreatedFacility);
    }
  }, [dispatchGetFacility, order.createdClientId, order.createdFacilityId, order.ownerClientId, order.ownerFacilityId]);

  const renderOwnerFacility = () => {
    return (
      <DisplayText showSkeleton={!ownerFacility} caption={t('orderOwner')} contextHelpKey="orderOwner">
        {facilityDisplayName(ownerFacility)}
      </DisplayText>
    );
  };

  const renderCreateFacility = () => {
    if (createdFacility) {
      return (
        <DisplayText showSkeleton={!createdFacility} caption={t('orderCreated')} contextHelpKey="orderCreator">
          {facilityDisplayName(createdFacility)}
        </DisplayText>
      );
    }
  };

  const renderBalance = () =>
    order.balance ? (
      <DisplayText caption={t('modules.order.balance')} containerStyle={{ marginTop: 25 }}>
        {formatCurrency(order.balance)}
      </DisplayText>
    ) : null;

  const handleOnChange = (data: Partial<OrderEntity>) => {
    setShowEdit(false);
    onChange(data);
  };

  return (
    <Panel headline={t('information')} onEdit={canEdit ? () => setShowEdit(true) : null} containerStyle={{ flex: 1 }}>
      <DisplayText caption={t('commissionNumber')}>{order.externalId}</DisplayText>
      <DisplayText caption={t('created')}>{moment(order.createdDate).format('LLL - LT')}</DisplayText>
      <DisplayText showSkeleton={!user} caption={t('createdFrom')}>
        {userDisplayName(user)}
      </DisplayText>
      {renderCreateFacility()}
      {renderOwnerFacility()}

      {renderBalance()}

      <EditModal order={order} onSave={handleOnChange} onClose={() => setShowEdit(false)} visible={showEdit} />
    </Panel>
  );
}
