import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SubNavigationHeadline from './SubNavigationHeadline';
import SubNavigationItem from './SubNavigationItem';
import Spacer from './Spacer';
import { useNavigate } from 'react-router';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

/**
 * StorageSubNavigation()
 * @constructor
 */
export default function StorageSubNavigation() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <SubNavigationHeadline headline={t('storageManagement')} />

      <SubNavigationItem icon="far fa-container-storage" caption={t('storageAddresses')} path="address" />
      <SubNavigationItem icon="far fa-boxes-stacked" caption={t('articleManagement')} path="article" />

      <Spacer />

      <SubNavigationItem icon="far fa-angle-double-left" caption={t('back')} onPress={() => navigate('/settings')} />
    </Container>
  );
}
