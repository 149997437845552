import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Input, { InputType } from '../../Inputs/Input';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { useDispatchAuthChangePassword } from '../../../Redux/Actions/AuthAction';
import validateChangePassword from './ValidateChangePassword';
import PasswordStrengthBar from 'react-password-strength-bar';
import { PASSWORD_MIN_LENGTH } from '../../../Globals/Globals';
import SuccessMessage from '../../Modals/SuccessMessage';
import { useDispatchUserUpdate } from '../../../Redux/Actions/UserAction';
import Text from '../../Text';
import { rgba } from 'polished';
import { useTheme } from 'styled-components';

interface Props {
  visible: boolean;
  onClose: () => void;
  forceChange?: boolean;
}

/**
 * ChangePasswordModal()
 * @constructor
 */
export default function ChangePasswordModal(props: Props) {
  const { visible, onClose, forceChange } = props;
  const [t] = useTranslation();
  const theme = useTheme();
  const { isLoading, setLoading } = useLoadingModal();
  const { userId } = useAppSelector((state) => state.auth);
  const [errors, setErrors] = React.useState<{ password?: string; repeat?: string }>({});
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = React.useState<string>('');
  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
  const [scoreWords] = React.useState([
    t('passwordStrength.weakest'),
    t('passwordStrength.weak'),
    t('passwordStrength.okay'),
    t('passwordStrength.good'),
    t('passwordStrength.veryGood'),
  ]);

  const dispatchChangePassword = useDispatchAuthChangePassword();
  const dispatchUpdateUser = useDispatchUserUpdate();

  React.useEffect(() => {
    if (!visible) {
      setShowSuccess(false);
      setErrors({});
    }
  }, [visible]);

  const handleSave = () => {
    const { isValid, errors } = validateChangePassword(newPassword, passwordRepeat);
    if (isValid) {
      setLoading(true);
      dispatchChangePassword(newPassword).then(() => {
        return dispatchUpdateUser(userId, { changePassword: false }).then(() => {
          setLoading(false);
          setShowSuccess(true);
        });
      });
    } else {
      setErrors(errors);
    }
  };

  const renderCancel = () =>
    !forceChange ? (
      <Button variant="outline-secondary" onClick={onClose}>
        {t('cancel')}
      </Button>
    ) : null;

  const renderForceDescription = () =>
    forceChange ? (
      <Text bold size={18} containerStyle={{ marginBottom: 10 }} color={theme.color.red}>
        {t('modules.profile.changePassword.forceChangePasswordDescription')}
      </Text>
    ) : null;

  if (visible) {
    if (!showSuccess) {
      return (
        <Modal show={visible} size="lg" animation={true} centered style={{ backgroundColor: rgba(0, 0, 0, 0.7) }}>
          <Modal.Header>
            <Modal.Title>{t('changePassword')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {renderForceDescription()}
            <Text containerStyle={{ marginBottom: 30 }}>{t('modules.profile.changePassword.description')}</Text>

            <Row>
              <Col lg={12}>
                <Input
                  onChange={setNewPassword}
                  value={newPassword}
                  label={t('newPassword')}
                  hasError={!!errors.password}
                  disabled={isLoading}
                  required
                  type={InputType.password}
                  autoFocus
                />
              </Col>
              <Col lg={12}>
                <Input
                  onChange={setPasswordRepeat}
                  value={passwordRepeat}
                  label={t('passwordRepeat')}
                  hasError={!!errors.repeat}
                  disabled={isLoading}
                  required
                  type={InputType.password}
                />
              </Col>
              <Col lg={12} style={{ marginBottom: 30 }}>
                <PasswordStrengthBar
                  password={newPassword}
                  minLength={PASSWORD_MIN_LENGTH}
                  scoreWords={scoreWords}
                  shortScoreWord={t('passwordStrength.tooShort')}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleSave} disabled={isLoading}>
              {t('changePassword')}
            </Button>
            {renderCancel()}
          </Modal.Footer>
        </Modal>
      );
    }

    return (
      <SuccessMessage
        onClose={onClose}
        visible={showSuccess}
        headline={t('modules.profile.changePassword.successHeadline')}
        description={t('modules.profile.changePassword.successDescription')}
      />
    );
  }
  return null;
}

ChangePasswordModal.defaultProps = {
  forceChange: false,
};
