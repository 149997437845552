import React from 'react';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import PageHeader from '../../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import Panel from '../../../Components/Panel';
import { useDispatchOrderServiceFilterByCustomer } from '../../../Redux/Actions/Order/Service/Filter';
import ServiceTable from '../../../Components/Order/ServiceTable';
import { ElasticSearchServiceInterface } from '../../../Globals/Types/OrderTypes';
import { useNavigate } from 'react-router';
import { buildUrlOrderServiceDetails, UrlPatterns } from '../../../Globals/UrlFunctions';
import styled, { useTheme } from 'styled-components';
import { Button } from 'react-bootstrap';
import { customerToOrderCustomerAddress } from '../../../Globals/Functions/CustomerFunctions';
import { LOCALSTORAGE_CUSTOMER_CREATE_ORDER } from '../../../Globals/Globals';

const HintText = styled.div`
  text-align: center;
  color: ${(props) => props.theme.color.textGray};
`;

/**
 * Services()
 * @constructor
 */
export default function Services() {
  const { customer } = useAppSelector((state) => state.client.customers.selectedCustomer);
  const navigate = useNavigate();
  const theme = useTheme();
  const [t] = useTranslation();
  const dispatchGetServices = useDispatchOrderServiceFilterByCustomer();
  const [services, setServices] = React.useState<ElasticSearchServiceInterface[]>(null);
  const { isLoading, setLoading } = useLoadingModal();

  React.useEffect(() => {
    if (customer) {
      setLoading(true);
      dispatchGetServices(customer.customerId)
        .then(setServices)
        .finally(() => setLoading(false));
    }
  }, [customer, dispatchGetServices, setLoading]);

  const navigateDetails = (service: ElasticSearchServiceInterface) => {
    navigate(buildUrlOrderServiceDetails(service.ownerClientId, service.orderId, service.serviceId));
  };

  const handleCreateOrder = () => {
    const mapped = customerToOrderCustomerAddress(customer);
    localStorage.setItem(LOCALSTORAGE_CUSTOMER_CREATE_ORDER, JSON.stringify(mapped));
    navigate(UrlPatterns.orderCreate);
  };

  const renderCreateButton = () => {
    return (
      <Button variant="success" onClick={handleCreateOrder}>
        {t('newOrder')}
      </Button>
    );
  };

  const renderLoading = () => {
    if (isLoading) {
      return (
        <i className="fas fa-spin fa-sync" style={{ color: theme.color.textGray, fontSize: 24, marginRight: 15 }} />
      );
    }
  };

  const renderContent = () => {
    if (services !== null && services.length === 0) {
      return (
        <>
          <HintText>{t('modules.customers.noServicesAvailable')}</HintText>
        </>
      );
    } else {
      return <ServiceTable services={services} onClick={navigateDetails} hideCustomer={true} hideAddress={true} />;
    }
  };

  return (
    <>
      <PageHeader
        headline={t('orders')}
        description={t('modules.customers.orderDescription')}
        actionButtonTwo={renderCreateButton()}
        actionButtonOne={renderLoading()}
      />
      <Panel>{renderContent()}</Panel>
    </>
  );
}
