import React from 'react';
import { InvoiceEntity } from '../../../Globals/Types/Invoice';
import moment from 'moment';
import { customerDisplayName, downloadBase64Pdf, formatCurrency } from '../../../Globals/Functions';
import styled, { useTheme } from 'styled-components';
import { useDispatchInvoiceDownload, useDispatchInvoiceUpdate } from '../../../Redux/Actions/Client/InvoiceAction';
import { useTranslation } from 'react-i18next';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { useGetFacility } from '../../../Globals/Hooks/FacilityHooks';
import { useDispatchCheckFacility } from '../../../Redux/Actions/Client/FacilityAction';
import { useNavigate } from 'react-router';
import { buildUrlCustomersInvoiceDetails, buildUrlInvoiceDetails } from '../../../Globals/UrlFunctions';
import AskInvoicePayedMessage from '../AskInvoicePayedMessage';
import FacilityDataInvalid from '../../Facility/FacilityDataInvalidMessage';

const Icon = styled.i`
  font-size: 20px;

  &.fa-square-plus {
    color: ${(props) => props.theme.color.green};
  }
  &.fa-square-minus {
    color: ${(props) => props.theme.color.red};
  }
  &.fa-check-circle {
    color: ${(props) => props.theme.color.textGray};
  }
`;

const ActionContainer = styled.div`
  i {
    cursor: pointer;
    padding: 0 10px;
    color: ${(props) => props.theme.color.textGray};

    &.fa-check-circle {
      &:hover {
        color: ${(props) => props.theme.color.green};
      }
    }
    &.fa-cloud-download {
      &:hover {
        color: ${(props) => props.theme.color.primaryColor};
      }
    }
  }
`;

const ActionCol = styled.td`
  text-align: right;
  width: 200px;
`;

interface Props {
  invoice: InvoiceEntity;
  showCustomerName?: boolean;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { invoice, showCustomerName } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();
  const theme = useTheme();
  const { setLoading, loadingModal, setHeadline } = useLoadingModal();
  const [askPayed, setAskPayed] = React.useState<boolean>(false);
  const [showFacilityError, setShowFacilityError] = React.useState<boolean>(false);
  const [overall] = React.useState<string>((parseFloat(invoice.priceOverall) + parseFloat(invoice.tax)).toFixed(2));
  const dispatchDownload = useDispatchInvoiceDownload();
  const dispatchUpdate = useDispatchInvoiceUpdate();
  const { isFacilityValid } = useDispatchCheckFacility();
  const getFacility = useGetFacility();

  const download = React.useCallback(() => {
    const facility = getFacility(invoice.facilityId);

    if (isFacilityValid(facility)) {
      setHeadline(t('creatingPdf'));
      setLoading(true);

      dispatchDownload(invoice.invoiceId, facility.countryCode)
        .then((response) => {
          downloadBase64Pdf(response, `${invoice.invoiceNumber}.pdf`);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      setShowFacilityError(true);
    }
  }, [
    dispatchDownload,
    getFacility,
    invoice.facilityId,
    invoice.invoiceId,
    invoice.invoiceNumber,
    isFacilityValid,
    setHeadline,
    setLoading,
    t,
  ]);

  const handleSetPayed = React.useCallback(() => {
    setLoading(true);
    setAskPayed(false);
    dispatchUpdate({ ...invoice, payed: true, payedDate: moment().format('YYYY-MM-DD') }).finally(() =>
      setLoading(false),
    );
  }, [dispatchUpdate, invoice, setLoading]);

  const getIcon = () =>
    invoice.payed ? 'fas fa-check-circle' : invoice.isVoucher ? 'fas fa-square-minus' : 'fas fa-square-plus';

  const navigateDetails = () => {
    if (invoice.customerId) {
      navigate(buildUrlCustomersInvoiceDetails(invoice.customerId, invoice.invoiceId));
    } else {
      navigate(buildUrlInvoiceDetails(invoice.invoiceId));
    }
  };

  const renderActions = () => {
    const actions: React.ReactElement[] = [];

    if (!invoice.payed) {
      actions.push(<i className="fas fa-check-circle" key="payed" onClick={() => setAskPayed(true)} />);
    }

    actions.push(<i className="fas fa-cloud-download" key="download" onClick={download} />);
    return <ActionContainer>{actions}</ActionContainer>;
  };

  const getInvoiceNumber = () => {
    if (invoice.isVoucher) {
      return (
        <>
          {invoice.invoiceNumber}
          <small style={{ marginLeft: 10, color: theme.color.textGray }}>({invoice.referenceInvoiceNumber})</small>
        </>
      );
    }
    return invoice.invoiceNumber;
  };

  const renderCustomerName = () => {
    if (showCustomerName) {
      return (
        <td onClick={navigateDetails}>
          {customerDisplayName(invoice.address)}
          {invoice.customerId ? '*' : null}
        </td>
      );
    }
    return null;
  };

  return (
    <tr style={{ cursor: 'pointer', color: invoice.payed ? theme.color.textGray : '' }}>
      <td onClick={navigateDetails}>
        <Icon className={getIcon()} />
      </td>
      <td onClick={navigateDetails}>{getInvoiceNumber()}</td>
      {renderCustomerName()}
      <td onClick={navigateDetails}>{moment(invoice.dueDate).format('LL')}</td>
      <td onClick={navigateDetails}>{formatCurrency(overall)}</td>
      <ActionCol>
        {renderActions()}

        <AskInvoicePayedMessage
          visible={askPayed}
          onClose={() => setAskPayed(false)}
          onSuccess={handleSetPayed}
          invoice={invoice}
        />

        <FacilityDataInvalid visible={showFacilityError} onClose={() => setShowFacilityError(false)} />

        {loadingModal}
      </ActionCol>
    </tr>
  );
}
