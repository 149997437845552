import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { CountryCode } from '../../Globals/Types/Enums';

interface Props {
  onChange: (item: CountryCode) => void;
  selected?: string;
  required?: boolean;
  disabled?: boolean;
  showSkeleton: boolean;
  label?: string;
}

/**
 * SelectCountry
 * @param props
 * @constructor
 */
export default function SelectCountry(props: Props) {
  const { onChange, selected, required, disabled, showSkeleton, label } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>([]);

  React.useEffect(() => {
    const optionArray = Object.keys(CountryCode).map((item) => ({
      value: CountryCode[item],
      label: t(`countryCodesToName.${CountryCode[item]}`),
    }));

    setOptions(optionArray);
  }, [t]);

  const handleOnChange = (value: string) => {
    onChange(value as CountryCode);
  };

  if (options && options.length > 0) {
    return (
      <Select
        label={label || t('country')}
        onChange={handleOnChange}
        initialValue={selected}
        options={options}
        required={required}
        disabled={disabled}
        showSkeleton={showSkeleton}
      />
    );
  }
  return null;
}

SelectCountry.defaultProps = {
  required: false,
  disabled: false,
  showSkeleton: false,
};
