import React from 'react';
import Panel from '../../../../Components/Panel';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import moment from 'moment';
import { SubscriptionType } from '../../../../Globals/Types/Enums';
import DisplayText from '../../../../Components/View/DisplayText';
import { formatCurrency } from '../../../../Globals/Functions';
import { useDispatchClientSubscriptionCreateInvoice } from '../../../../Redux/Actions/Client/SubscriptionAction';
import { Subscription } from '../../../../Globals/Types/Types';

/**
 * Invoices()
 * @constructor
 */
export default function Invoices() {
  const [t] = useTranslation();
  const { setLoading, loadingModal } = useLoadingModal();
  const { subscriptions } = useAppSelector((state) => state.client);
  const [hasSubscription, setHasSubscription] = React.useState<boolean>(
    subscriptions ? subscriptions.filter((item) => item.type !== SubscriptionType.demo).length > 0 : false,
  );
  const dispatchInvoice = useDispatchClientSubscriptionCreateInvoice();

  React.useEffect(() => {
    setHasSubscription(
      subscriptions ? subscriptions.filter((item) => item.type !== SubscriptionType.demo).length > 0 : false,
    );
  }, [subscriptions]);

  const handleDownloadInvoice = React.useCallback(
    (subscriptionParam: Subscription) => {
      setLoading(true);
      dispatchInvoice(subscriptionParam)
        .then((response) => {
          const a = document.createElement('a');
          a.href = response;
          a.download = `${subscriptionParam.invoiceNumber}.pdf`;
          a.click();
          setLoading(false);
        })
        .catch(() => setLoading(false));
    },
    [dispatchInvoice, setLoading],
  );

  const renderRows = () => {
    const sorted = subscriptions.sort((a, b) => (moment(a.startDate).isAfter(moment(b.startDate)) ? 1 : -1));

    return sorted
      .filter((item) => item.type !== SubscriptionType.demo)
      .map((item) => (
        <DisplayText
          caption={`${item.invoiceNumber} (${moment(item.createdDate).format('LL')})`}
          key={item.invoiceNumber}
          rightAction={<i className="fas fa-cloud-download" onClick={() => handleDownloadInvoice(item)} />}
        >
          {formatCurrency(item.overallPrice.toString())}
        </DisplayText>
      ));
  };

  if (hasSubscription) {
    return (
      <>
        <Panel headline={t('invoices')}>{renderRows()}</Panel>
        {loadingModal}
      </>
    );
  }
  return null;
}
