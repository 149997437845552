import React from 'react';
import { InvoiceEntity } from '../../../../../Globals/Types/Invoice';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InvoiceDownloadButton from './InvoiceDownloadButton';
import InvoicePayedButton from './InvoicePayedButton';
import InvoiceSendMailButton from './InvoiceSendMailButton';
import InvoiceVoucherButton from './InvoiceVoucherButton';

interface Props {
  invoice: InvoiceEntity;
  customerId: string;
  onSendingMail: (sending: boolean) => void;
  onDownloading: (loading: boolean) => void;
  onUpdating: (loading: boolean) => void;
  onUpdate: (invoice: InvoiceEntity) => void;
}

/**
 * DetailsActionButton()
 * @param props
 * @constructor
 */
export default function DetailsActionButton(props: Props) {
  const { invoice, onSendingMail, onDownloading, onUpdate, onUpdating, customerId } = props;
  const [t] = useTranslation();

  const renderVoucher = () => {
    if (customerId && !invoice.isVoucher) {
      return <InvoiceVoucherButton invoice={invoice} customerId={customerId} />;
    }
    return null;
  };

  return (
    <Dropdown align="end">
      <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ margin: 0 }}>
        {invoice.isVoucher ? t('voucher') : t('invoice')}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <InvoiceDownloadButton invoice={invoice} onDownloading={onDownloading} />
        <InvoiceSendMailButton invoice={invoice} onSending={onSendingMail} onUpdate={onUpdate} />
        <InvoicePayedButton invoice={invoice} onUpdate={onUpdate} onLoading={onUpdating} />
        {renderVoucher()}
      </Dropdown.Menu>
    </Dropdown>
  );
}
