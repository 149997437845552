import { STORAGE_SELECTED_LANGUAGE } from '../Types/Constants';

/**
 * Map the browser language to the SotoSoft language codes in i18n
 */
export const getMappedLanguageFromBrowser = (): string => {
  if (navigator.language && navigator.language.length > 0) {
    const language = navigator.language.substring(0, 2).toLowerCase();
    switch (language) {
      case 'de':
        return 'de-DE';
      case 'nl':
        return 'nl-NL';
      case 'en':
        return 'en-US';
      default:
        return 'es-ES';
    }
  }
  return 'de-DE';
};

/**
 * Get the current selected language
 * @param long
 */
export const getSelectedLanguage = (long: boolean) => {
  const lng = localStorage.getItem(STORAGE_SELECTED_LANGUAGE);

  if (lng) {
    return long ? lng : lng.substring(0, 2);
  } else {
    const browserLng = getMappedLanguageFromBrowser();
    return long ? browserLng : browserLng.substring(0, 2);
  }
};

/**
 * Get the moment i18n configuration for displaying the format depending on the selected language
 * @param languageCode
 */
export const momentConfiguration = (languageCode: string) => {
  switch (languageCode) {
    case 'nl-NL':
      return {
        weekdays: 'Zondag_Maandag_Dinsdag_Woensdag_Donderdag_Vrijdag_Zaterdag'.split('_'),
        months: 'Januari_Februari_Maart_April_Mei_Juni_Juli_Augustus_September_Oktober_November_December'.split('_'),
        longDateFormat: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD-MM',
          LL: 'DD-MM-YYYY',
          LLL: 'dddd, DD-MM-YYYY',
          LLLL: 'dddd, DD-MM-YYYY',
        },
      };
    case 'en-US':
      return {
        longDateFormat: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD/MM',
          LL: 'DD/MM/YYYY',
          LLL: 'dddd, DD/MM/YYYY',
          LLLL: 'dddd, DD/MM/YYYY',
        },
      };
    default:
      return {
        longDateFormat: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD.MM',
          LL: 'DD.MM.YYYY',
          LLL: 'dddd, DD.MM.YYYY',
          LLLL: 'dddd, DD.MM.YYYY',
        },
      };
  }
};
