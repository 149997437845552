import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ size: number; bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: ${(props) => props.size * 0.35}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  background-color: ${(props) => props.bgColor || props.theme.color.textGray};
  color: #ffffff;
`;

interface Props {
  text: string;
  size: number;
  bgColor?: string;
}

/**
 * Circle
 * @param props
 * @constructor
 */
export default function Circle(props: Props) {
  const { size, text, bgColor } = props;

  return (
    <Container size={size} bgColor={bgColor}>
      {text}
    </Container>
  );
}
