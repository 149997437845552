import { ValidateErrors } from './UpdateTourModal';
import { Tour } from '../../../Globals/Types/Tour';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';

/**
 * isValid
 * @param tour
 */
const isValid = (tour: Tour): { errors: ValidateErrors; isValid: boolean } => {
  const errors = {} as ValidateErrors;

  if (!tour.name || tour.name.length === 0) {
    errors.name = InternalErrorCodes.FIELD_MISSING;
  }

  if (!tour.facilityIds || tour.facilityIds.length === 0) {
    errors.facilityIds = InternalErrorCodes.FIELD_MISSING;
  }

  return { errors, isValid: Object.keys(errors).length === 0 };
};

export default isValid;
