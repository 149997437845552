import React from 'react';
import { useAppSelector } from '../Hooks/Hooks';
import moment from 'moment';
import { InvoiceAddressEntity } from '../Types/Invoice';
import { Salutation } from '../Types/Enums';
import { TFunction } from 'i18next';

type BuildInvoiceNumberReturn = (
  facilityId: string,
  isVoucher: boolean,
) => { nextInvoiceNumber: number; preparedInvoiceNumber: string } | null;
/**
 * Build the next upcoming invoice number for the given facility id
 * Returns the invoiceNumber with prefix and suffix and the new number to be saved in facility settings
 */
export const useBuildInvoiceNumber = (): BuildInvoiceNumberReturn => {
  const { facilities } = useAppSelector((state) => state.client);

  return React.useCallback<BuildInvoiceNumberReturn>(
    (facilityId: string, isVoucher: boolean) => {
      const facility = facilities.find((item) => item.facilityId === facilityId);

      if (facility) {
        const { settings } = facility;
        let invoiceNumber = moment().format('YY');
        let nextInvoiceNumber;

        if (isVoucher) {
          nextInvoiceNumber =
            settings && settings.invoiceSettings && settings.invoiceSettings.lastVoucherNumber
              ? settings.invoiceSettings.lastVoucherNumber + 1
              : 1;
        } else {
          nextInvoiceNumber =
            settings && settings.invoiceSettings && settings.invoiceSettings.lastInvoiceNumber
              ? settings.invoiceSettings.lastInvoiceNumber + 1
              : 1;
        }

        invoiceNumber = invoiceNumber + '-' + nextInvoiceNumber;
        invoiceNumber = isVoucher ? 'V-' + invoiceNumber : 'I-' + invoiceNumber;

        if (
          settings &&
          settings.invoiceSettings &&
          settings.invoiceSettings.invoiceNumberPrefix &&
          settings.invoiceSettings.invoiceNumberPrefix.length > 0
        ) {
          invoiceNumber = settings.invoiceSettings.invoiceNumberPrefix + invoiceNumber;
        }

        if (
          settings &&
          settings.invoiceSettings &&
          settings.invoiceSettings.invoiceNumberSuffix &&
          settings.invoiceSettings.invoiceNumberSuffix.length > 0
        ) {
          invoiceNumber = invoiceNumber + settings.invoiceSettings.invoiceNumberSuffix;
        }

        return {
          nextInvoiceNumber,
          preparedInvoiceNumber: invoiceNumber,
        };
      }

      return {
        nextInvoiceNumber: null,
        preparedInvoiceNumber: '',
      };
    },
    [facilities],
  );
};

/**
 * getAddressName()
 * Get a formatted string of the invoice address / receiver
 * @param address
 * @param t
 */
export const getInvoiceAddressName = (address: InvoiceAddressEntity, t: TFunction): string => {
  if (address.salutation === Salutation.company) {
    return `${t(`salutationType.${Salutation.company}`)} ${address.firstName}`;
  }
  return `${t(`salutationType.${address.salutation}`)} ${address.firstName} ${address.lastName}`;
};
