import React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';

type SwitchProps = {
  checked: boolean;
  onChange: (value: boolean) => void;
  caption: string;
  description?: string;
  containerStyle?: Object;
  disabled?: boolean;
  loading?: boolean;
};

const Container = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  flex-direction: row;
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 20px;

  .Switch {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .Caption {
    color: ${(props) => (props.disabled ? props.theme.color.textGray : 'inherited')};
    justify-content: center;
  }

  .TextContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-left: 20px;
  }

  small {
    color: ${(props) => props.theme.color.textGray};
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

/**
 * CustomSwitch()
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CustomSwitch(props: SwitchProps) {
  const { checked, onChange, caption, containerStyle, disabled, description, loading } = props;

  const handleOnChange = () => {
    if (!disabled) {
      onChange(!checked);
    }
  };

  const renderDescription = () => {
    if (description) {
      return <small>{description}</small>;
    }
    return null;
  };

  const renderLoading = () => {
    if (loading) {
      return (
        <SpinnerContainer>
          <i className="fas fa-spin fa-spinner" />
        </SpinnerContainer>
      );
    }
    return null;
  };

  return (
    <Container
      className="d-flex flex-row"
      style={containerStyle}
      onClick={disabled || loading ? () => {} : () => handleOnChange()}
      role="button"
      tabIndex={-1}
      onKeyPress={() => {}}
      disabled={disabled || loading}
    >
      <div className="Switch">
        <Switch
          checked={checked}
          onChange={() => {}}
          disabled={disabled || loading}
          uncheckedHandleIcon={renderLoading()}
          checkedHandleIcon={renderLoading()}
        />
      </div>
      <div className="TextContainer">
        <div className="Caption">{caption}</div>
        {renderDescription()}
      </div>
    </Container>
  );
}

CustomSwitch.defaultProps = {
  caption: '',
  containerStyle: null,
  disabled: false,
  description: null,
  loading: false,
};
