import React from 'react';
import Select from '../Inputs/Select';
import { useGetNumberOptions } from '../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  initialValue: number;
  onChange: (value: number) => void;
  disabled: boolean;
  showSkeleton: boolean;
}

/**
 * SelectAccessCode()
 * @param props
 * @constructor
 */
export default function SelectAccessCode(props: Props) {
  const { onChange, disabled, showSkeleton, initialValue } = props;
  const [t] = useTranslation();
  const accessCodeOptions = useGetNumberOptions(0, 99);

  return (
    <Select
      options={accessCodeOptions}
      label={t('accessCode')}
      onChange={onChange}
      initialValue={initialValue}
      disabled={disabled}
      contextHelpKey={'accessCode'}
      showSkeleton={showSkeleton}
    />
  );
}
