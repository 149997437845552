import React from 'react';
import Panel from '../../Panel';
import { Tour, TourItem, TourService } from '../../../Globals/Types/Tour';
import Headline from './Headline';
import styled from 'styled-components';
import DisplayText from '../../View/DisplayText';
import { useTranslation } from 'react-i18next';
import AskDeleteModal from '../../Modals/AskDeleteModal';
import TimeBox from './TimeBox';
import TextLink from '../../View/TextLink';
import EditTourPanel from './EditTourPanel';
import Text from '../../Text';
import { ElasticSearchServiceInterface } from '../../../Globals/Types/OrderTypes';
import { useDispatchOrderServiceGetFromIndex } from '../../../Redux/Actions/Order/Service/Filter';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { Alert } from 'react-bootstrap';
import ShowHideContainer from '../../ShowHideContainer';
import { ReportState } from '../../../Globals/Types/Report';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`;

interface Props {
  width?: number;
  visible: boolean;
  tour: Tour;
  tourItem: TourItem<TourService>;
  onClose: () => void;
  onDeleteTourItem: (tourItem: TourItem<TourService>) => void;
}

/**
 * ServiceDetailsPanel()
 * @param props
 * @constructor
 */
export default function ServiceDetailsPanel(props: Props) {
  const { width, visible, tourItem, onClose, onDeleteTourItem, tour } = props;
  const [t] = useTranslation();
  const { setLoading, isLoading } = useLoadingModal();
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [service, setService] = React.useState<ElasticSearchServiceInterface>(null);

  const dispatchGetService = useDispatchOrderServiceGetFromIndex();

  React.useEffect(() => {
    if (tourItem && visible) {
      setLoading(true);
      dispatchGetService(tourItem.data.serviceId)
        .then((response) => {
          setService(response);
        })
        .finally(() => setLoading(false));
    }
  }, [dispatchGetService, setLoading, tourItem, visible]);

  const handleDelete = () => {
    setAskDelete(false);
    onDeleteTourItem(tourItem);
  };

  const handleOnClose = () => {
    setEdit(false);
    setService(null);
    onClose();
  };

  const renderRemainingMinutes = () => {
    if (service && service.remainingAssemblyTime && service.remainingAssemblyTime > 0) {
      return (
        <Alert variant="danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          {t('modules.disposition.hintRemainingMinutes', { minutes: service.remainingAssemblyTime })}
        </Alert>
      );
    }
    return null;
  };

  const renderFinished = () => {
    if (tourItem.data.reportState !== ReportState.none) {
      return (
        <Alert variant="success">
          <i className="fa-sharp fa-regular fa-circle-check" style={{ marginRight: 6 }} />
          {t('reportFinished')}
        </Alert>
      );
    }
    return null;
  };

  if (visible && tourItem && tour) {
    return (
      <Panel containerStyle={{ margin: 0, marginLeft: 20 }} withoutPadding maxWidth={width}>
        <Headline
          tourItem={tourItem}
          onClose={handleOnClose}
          onDelete={() => setAskDelete(true)}
          isLoading={isLoading}
        />

        <Container>
          {renderFinished()}
          {renderRemainingMinutes()}

          <DisplayText caption={t('commissionNumber')} horizontal>
            {tourItem.data.externalOrderId}
          </DisplayText>

          <DisplayText caption={t('customerAddress')}>
            <div style={{ marginTop: 10 }}>
              <div>
                <strong>
                  {tourItem.data.customer.firstName} {tourItem.data.customer.lastName}
                </strong>
              </div>
              <div>
                {tourItem.data.customer.street} {tourItem.data.customer.streetNo}
              </div>
              <div>
                {tourItem.data.customer.zip} {tourItem.data.customer.city}
              </div>
            </div>
          </DisplayText>

          <TimeBox tourItem={tourItem} />

          <ShowHideContainer visible={tourItem.data.reportState === ReportState.none}>
            <TextLink onPress={() => setEdit(true)} centered containerStyle={{ marginTop: 20 }} visible={!edit}>
              {t('edit')}
            </TextLink>
          </ShowHideContainer>

          <EditTourPanel
            visible={edit}
            tourItem={tourItem}
            onClose={() => setEdit(false)}
            tour={tour}
            service={service}
          />
        </Container>

        <Text light small centered containerStyle={{ marginBottom: 15 }}>
          {tour.tourId}
        </Text>

        <AskDeleteModal
          onDelete={handleDelete}
          onClose={() => setAskDelete(false)}
          visible={askDelete}
          headline={t('modules.disposition.deleteServiceFromTour.headline')}
          description={t('modules.disposition.deleteServiceFromTour.description', {
            externalId: tourItem.data.externalOrderId,
            tourName: tour.name,
          })}
        />
      </Panel>
    );
  }
  return null;
}

ServiceDetailsPanel.defaultProps = {
  width: 600,
};
