import React from 'react';
import { useAppSelector } from '../Globals/Hooks/Hooks';
import { useDispatchUsersGetList } from '../Redux/Actions/UserAction';
import { PickedUserEntity, UserEntity } from '../Globals/Types/Types';
import Select from './Inputs/Select';
import { useTranslation } from 'react-i18next';
import { userDisplayName, userEntityToPickedUserEntity } from '../Globals/Functions';

type SelectClientUsersProps = {
  onChange: (users: Array<PickedUserEntity> | PickedUserEntity) => void;
  groupsFilter?: Array<string>;
  initialSelected?: Array<PickedUserEntity> | string;
  label?: string;
  hasError: boolean;
  disabled: boolean;
  isMultiSelect: boolean;
  required: boolean;
  showSkeleton: boolean;
  contextHelpKey?: string;
  showUnselectOption: boolean;
  hideCurrentUser: boolean;
  placeholder?: string;
  marginBottom?: number;
};

/**
 * SelectClientUsers
 * @param props
 * @constructor
 */
export default function SelectClientUsers(props: SelectClientUsersProps) {
  const {
    onChange,
    groupsFilter,
    initialSelected,
    label,
    hasError,
    disabled,
    isMultiSelect,
    required,
    showSkeleton,
    contextHelpKey,
    showUnselectOption,
    hideCurrentUser,
    placeholder,
    marginBottom,
  } = props;
  const { userId } = useAppSelector((state) => state.auth);
  const { all: users } = useAppSelector((state) => state.client.users);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = React.useState<Array<UserEntity>>(null);
  const [initial, setInitial] = React.useState<Array<string> | string>(null);
  const [options, setOptions] = React.useState<Array<{ label: string; value: string }>>([]);
  const [t] = useTranslation();
  const dispatchGetList = useDispatchUsersGetList();

  const removeCurrentUser = React.useCallback(
    (users: UserEntity[]): UserEntity[] => {
      if (hideCurrentUser) {
        return users.filter((user) => user.userId !== userId);
      } else {
        return users;
      }
    },
    [hideCurrentUser, userId],
  );

  React.useEffect(() => {
    if (!users || users.length === 0) {
      dispatchGetList().then(() => {});
    }
  }, [dispatchGetList, users]);

  React.useEffect(() => {
    if (isMultiSelect) {
      if (Array.isArray(initialSelected)) {
        if (initialSelected && initialSelected.length > 0) {
          setInitial(initialSelected.map((item) => item.userId));
        }
      }
    } else {
      if (Array.isArray(initialSelected)) {
        if (initialSelected && initialSelected.length > 0) {
          setInitial(initialSelected.map((item) => item.userId)[0]);
        }
      } else {
        setInitial(initialSelected);
      }
    }
    setLoaded(true);
  }, [initialSelected, isMultiSelect]);

  React.useEffect(() => {
    if (users) {
      if (groupsFilter && groupsFilter.length > 0) {
        const filtered = users.filter((user) => {
          let found = false;
          user.groups.forEach((groupKey) => {
            if (groupsFilter.indexOf(groupKey) >= 0) {
              found = true;
            }
          });
          return found;
        });
        setFilteredUsers(removeCurrentUser(filtered));
      } else {
        setFilteredUsers(removeCurrentUser(users));
      }
    }
  }, [groupsFilter, removeCurrentUser, users]);

  React.useEffect(() => {
    if (filteredUsers) {
      const mapped = filteredUsers.map((user) => ({
        label: userDisplayName(user, true),
        value: user.userId,
      }));

      if (showUnselectOption) {
        mapped.unshift({ value: null, label: 'Keine Auswahl' });
      }
      setOptions(mapped);
    }
  }, [filteredUsers, showUnselectOption]);

  const handleOnChange = (value) => {
    if (isMultiSelect) {
      const result = [];
      value.forEach((userId) => {
        const mapped = filteredUsers
          .filter((user) => user.userId === userId)
          .map((user) => userEntityToPickedUserEntity(user));
        result.push(mapped[0]);
      });
      onChange(result);
    } else {
      const mapped = filteredUsers
        .filter((user) => user.userId === value)
        .map((user) => userEntityToPickedUserEntity(user));
      onChange(mapped[0]);
    }
  };

  if (loaded && options && options.length > 0) {
    return (
      <Select
        label={label || t('users')}
        options={options}
        onChange={handleOnChange}
        initialValue={initial}
        multiSelect={isMultiSelect}
        hasError={hasError}
        disabled={disabled}
        required={required}
        showSkeleton={showSkeleton}
        contextHelpKey={contextHelpKey}
        placeholder={placeholder}
        marginBottom={marginBottom}
      />
    );
  }
  return null;
}

SelectClientUsers.defaultProps = {
  groupFilter: null,
  initialSelected: null,
  hasError: false,
  disabled: false,
  isMultiSelect: true,
  required: false,
  showSkeleton: false,
  contextHelpKey: null,
  showUnselectOption: false,
  hideCurrentUser: false,
};
