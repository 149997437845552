import { LocationPoint, PickedUserEntity, ResourceEntity } from './Types';
import { OrderCustomerAddress, OrderServiceType } from './OrderTypes';
import { FieldValue } from 'firebase/firestore';
import { DeliveryAndLoadingOptions, TimeTrackingType } from './Enums';
import { ReportState } from './Report';

export enum TourItemType {
  service = 'service',
  travelTime = 'travelTime',
}

export interface TourItem<T> {
  type: TourItemType;
  startTime: string;
  endTime: string;
  displayColor?: string;
  displayName?: string;
  data: T;
}

export interface TourService {
  assignedClientId?: string | null;
  assignedFacilityId?: string | null;
  createdClientId: string;
  createdDate: string;
  createdFacilityId: string;
  createdUserId: string;
  customer: OrderCustomerAddress;
  desiredDate?: string | null;
  desiredExecutionTime?: string | null;
  desiredWeek?: string | null;
  estimatedAssemblyTime: number; // The time in minutes calculated to execute the whole service
  externalOrderId: string | null;
  externalServiceId?: string | null;
  orderId: string;
  ownerClientId: string;
  ownerFacilityId: string;
  serviceId: string;
  serviceType: OrderServiceType;
  reportState: ReportState;
  isRecurring: boolean;
  deliveryAndLoadingOption: DeliveryAndLoadingOptions;
}

export interface TourLocationPoint extends LocationPoint {
  timestamp: number;
}

export interface Tour {
  tourId?: string;
  templateId?: string;
  name: string;
  date?: string;
  firebaseDate?: Date | FieldValue;
  closed: boolean;
  active: boolean;
  comment?: string | null;
  serviceIds: string[];
  resources?: ResourceEntity[];
  users?: PickedUserEntity[];
  items?: TourItem<any>[];
  locations?: TourLocationPoint[];
  facilityIds: string[];
  externalId?: string | null;
  lastTimeTracking?: TourTimeTrackingEntity;
}

export interface TourLocationTrackingDocument {
  timestamp: any;
  positions: TourLocationTrackingEntity[];
}

export interface TourLocationTrackingEntity {
  position: LocationPoint;
  speed: number;
  altitude: number;
  timestamp: any;
  user: PickedUserEntity;
}

export interface TourTimeTrackingEntity {
  state: TimeTrackingType;
  timestamp: any;
  location: LocationPoint;
  createdUser: PickedUserEntity;
}

export interface DraggableTourData {
  service: TourService;
  currentTour?: Tour;
}
