import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
  caption: string;
  onSelect: (tab: string) => void;
  selected: boolean;
  icon?: string;
  visible: boolean;
};

const Container = styled.div`
  padding: 12px 20px;
  border-radius: ${(props) => props.theme.border.radius};
  cursor: pointer;
  margin-right: 10px;
  background-color: ${(props) => props.theme.color.hoverBackground};
  color: ${(props) => props.theme.color.text};

  &:hover {
    background-color: ${(props) => props.theme.color.primaryColor};
    color: #ffffff;
    transition: background-color 300ms, color 300ms;
  }

  &.Active {
    background-color: ${(props) => props.theme.color.primaryColor};
    color: #ffffff;
  }
`;

/**
 * Tab()
 * @param props
 * @constructor
 */
export default function Tab(props: Props) {
  const { onSelect, caption, selected, icon, visible } = props;
  const [t] = useTranslation();

  const renderIcon = () => {
    if (icon) {
      return <i className={icon} style={{ marginRight: 10 }} />;
    }
    return null;
  };

  if (visible) {
    return (
      <Container className={selected ? 'Active' : null} onClick={() => onSelect(caption)}>
        {renderIcon()}
        {t(caption)}
      </Container>
    );
  }
  return null;
}

Tab.defaultProps = {
  containerStyle: {},
  withoutPadding: false,
  icon: null,
};
