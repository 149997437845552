import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../Globals/Functions';
import Text from '../Text';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ffffff;
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 18px;

  p {
    text-align: center;
  }

  h2 {
    text-transform: uppercase;
    color: ${(props) => props.theme.color.primaryColor};
    font-weight: bold;
  }

  .singleUserLicense {
    font-size: 18px;
    text-align: center;
    margin: 20px 200px 0;
    color: ${(props) => props.theme.color.textGray};
    font-weight: bold;
  }

  .payment {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 26px;

    div {
      margin-right: 20px;

      &.blue {
        color: ${(props) => props.theme.color.primaryColor};
      }

      &.small {
        font-size: 16px;
        color: ${(props) => props.theme.color.textGray};
      }
    }
  }

  @media (max-width: 1350px) {
    width: 100%;
    margin: 0;

    .singleUserLicense {
      margin: 20px 0;
    }

    h2 {
      text-align: center;
    }
  }
`;

/**
 * Price()
 * @constructor
 */
export default function Price() {
  const [t] = useTranslation();

  return (
    <Container>
      <InnerContainer>
        <h2>{t('modules.website.priceHeadline')}</h2>
        <h4>{t('modules.website.quitMonthly')}</h4>
        <p>{t('modules.website.priceDescription')}</p>

        <div className="payment" style={{ marginTop: 50 }}>
          <div className="blue">{t('modules.website.basicPrice')}</div>
          <div>{t('modules.website.pricePerMonth', { price: formatCurrency('49.90') })}</div>
          <div className="small">{t('modules.website.hintUsersIncluded')}</div>
        </div>

        <div className="payment" style={{ marginTop: 30 }}>
          <div className="blue">{t('modules.website.pricePerUserHeadline')}</div>
          <div>{t('modules.website.pricePerMonth', { price: formatCurrency('19.90') })}</div>
        </div>

        <div className="payment" style={{ marginTop: 50 }}>
          <div className="small">{t('modules.website.annualRebateHint')}</div>
        </div>

        <div className="singleUserLicense">
          <div className="small">{t('modules.website.priceSingleUserDescription')}</div>
        </div>

        <div style={{ marginTop: 20 }}>
          <Text small light>
            {`*${t('vatHintPrice')}`}
          </Text>
        </div>
      </InnerContainer>
    </Container>
  );
}
