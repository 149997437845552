import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { isValidateEmail } from '../../../Globals/Functions';
import { OrderCustomerAddress } from '../../../Globals/Types/OrderTypes';
import { Salutation } from '../../../Globals/Types/Enums';

export interface ValidateCustomerAddressResult {
  result: boolean;
  errors: {
    firstName: string;
    lastName: string;
    zip: string;
    city: string;
    street: string;
    streetNo: string;
    mail: string;
  };
}

/**
 * validateCustomerAddress
 * @param data
 */
export const validateCustomerAddress = (data: OrderCustomerAddress): ValidateCustomerAddressResult => {
  let returnValue: ValidateCustomerAddressResult = {
    result: true,
    errors: {
      firstName: undefined,
      lastName: undefined,
      zip: undefined,
      city: undefined,
      street: undefined,
      streetNo: undefined,
      mail: undefined,
    },
  };

  if (!data.firstName || data.firstName.length === 0) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, firstName: InternalErrorCodes.FIELD_MISSING },
    };
  }
  if (data.salutation !== Salutation.company) {
    if (!data.lastName || data.lastName.length === 0) {
      returnValue = {
        ...returnValue,
        result: false,
        errors: { ...returnValue.errors, lastName: InternalErrorCodes.FIELD_MISSING },
      };
    }
  }
  if (!data.zip || data.zip.length === 0) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, zip: InternalErrorCodes.FIELD_MISSING },
    };
  }
  if (!data.city || data.city.length === 0) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, city: InternalErrorCodes.FIELD_MISSING },
    };
  }
  if (!data.street || data.street.length === 0) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, street: InternalErrorCodes.FIELD_MISSING },
    };
  }
  if (!data.streetNo || data.streetNo.length === 0) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, streetNo: InternalErrorCodes.FIELD_MISSING },
    };
  }

  if (data.mail && !isValidateEmail(data.mail)) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, mail: InternalErrorCodes.MAIL_INVALID },
    };
  }

  return returnValue;
};
