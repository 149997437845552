import React from 'react';
import { TimeTrackingType } from '../Globals/Types/Enums';
import { useTheme } from 'styled-components';

interface Props {
  type: TimeTrackingType;
  circle: boolean;
  size: number;
}

/**
 * TimeTrackingIcon()
 * @param props
 * @constructor
 */
export default function TimeTrackingIcon(props: Props) {
  const { type, circle, size } = props;
  const theme = useTheme();

  const getIcon = () => {
    switch (type) {
      case TimeTrackingType.startOrder:
      case TimeTrackingType.start:
      case TimeTrackingType.startTour:
        return circle ? 'fas fa-circle-play' : 'fas fa-play';
      case TimeTrackingType.stopOrder:
      case TimeTrackingType.stop:
      case TimeTrackingType.stopTour:
        return circle ? 'fas fa-circle-stop' : 'fas fa-stop';
      case TimeTrackingType.pauseOrder:
      case TimeTrackingType.pause:
      case TimeTrackingType.pauseTour:
        return circle ? 'fas fa-circle-pause' : 'fas fa-pause';
    }
  };

  const getColor = () => {
    switch (type) {
      case TimeTrackingType.startOrder:
      case TimeTrackingType.start:
      case TimeTrackingType.startTour:
        return theme.color.green;
      case TimeTrackingType.stopOrder:
      case TimeTrackingType.stop:
      case TimeTrackingType.stopTour:
        return theme.color.red;
      case TimeTrackingType.pauseOrder:
      case TimeTrackingType.pause:
      case TimeTrackingType.pauseTour:
        return theme.color.orange;
    }
  };

  return <i className={getIcon()} style={{ color: getColor(), fontSize: size }} />;
}

TimeTrackingIcon.defaultProps = {
  circle: true,
  size: 14,
};
