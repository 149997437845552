import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { UrlPatterns } from '../../../../Globals/UrlFunctions';

const Container = styled.div`
  display: flex;
  flex-direction: row;

  > i {
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.color.primaryColor};
      color: #ffffff;
    }
  }
`;

/**
 * ActionButtons()
 * @constructor
 */
export default function ActionButtons() {
  const navigate = useNavigate();

  return (
    <Container>
      <i className="fal fa-plus-circle" onClick={() => navigate(UrlPatterns.inboxCreate)} />
    </Container>
  );
}
