import React from 'react';
import { useParams } from 'react-router';
import PinInput from '../../../Components/Linking/PinInput';
import styled from 'styled-components';
import { useDispatchLinkingValidatePin } from '../../../Redux/Actions/LinkingAction';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';
import ErrorMessage from '../../../Components/Modals/ErrorMessage';
import { LinkingEntity, LinkingPayloadPublicReport, LinkingType } from '../../../Globals/Types/Linking';
import Report from '../../../Components/Linking/Report';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

/**
 * LinkingLandingpage()
 * @constructor
 */
export default function LinkingLandingpage() {
  const [t] = useTranslation();
  const { linkingId } = useParams();
  const [validated, setValidated] = React.useState<boolean>(false);
  const [linkingEntity, setLinkingEntity] = React.useState<LinkingEntity<LinkingPayloadPublicReport>>(null);
  const [showError, setShowError] = React.useState<boolean>(false);
  const { setLoading, isLoading } = useLoadingModal();
  const dispatchValidatePin = useDispatchLinkingValidatePin();

  const validatePin = React.useCallback(
    (pin) => {
      setLoading(true);
      dispatchValidatePin(linkingId, pin)
        .then((response) => {
          setLinkingEntity(response);
          setValidated(true);
        })
        .catch((error) => {
          setShowError(true);
        })
        .finally(() => setLoading(false));
    },
    [dispatchValidatePin, linkingId, setLoading],
  );

  const renderContent = () => {
    if (linkingEntity) {
      switch (linkingEntity.type) {
        case LinkingType.publicReport:
          return <Report linkingEntity={linkingEntity} />;
      }
    }
    return null;
  };

  if (!validated) {
    return (
      <Container style={{ padding: 0 }}>
        <PinInput onReady={validatePin} isLoading={isLoading} />

        <ErrorMessage
          onClose={() => setShowError(false)}
          visible={showError}
          headline={t('errorMessages.linkingCodeError.headline')}
          description={t('errorMessages.linkingCodeError.description')}
        />
      </Container>
    );
  }
  return renderContent();
}
