import { Salutation } from '../../../Globals/Types/Enums';
import { CreateOrUpdateCustomerState, CustomerCreateOrUpdateError } from './Reducer';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { isUrlValid, isValidateEmail, isValidDateString } from '../../../Globals/Functions';

interface CustomerValidationResult {
  isValid: boolean;
  errors: CustomerCreateOrUpdateError;
}

/**
 * validateCustomer()
 * @param state
 */
export const validateCustomer = (state: CreateOrUpdateCustomerState): CustomerValidationResult => {
  let result: CustomerValidationResult = {
    isValid: true,
    errors: {},
  };

  const { customer } = state;

  if (customer.salutation === Salutation.company) {
    if (!customer.companyName || customer.companyName.length <= 0) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, companyName: InternalErrorCodes.FIELD_MISSING },
      };
    }
  } else {
    if (!customer.firstName || customer.firstName.length <= 0) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, firstName: InternalErrorCodes.FIELD_MISSING },
      };
    }
    if (!customer.lastName || customer.lastName.length <= 0) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, lastName: InternalErrorCodes.FIELD_MISSING },
      };
    }
  }

  if (customer.mailPrivate && customer.mailPrivate.length > 0) {
    if (!isValidateEmail(customer.mailPrivate)) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, mailPrivate: InternalErrorCodes.MAIL_INVALID },
      };
    }
  }
  if (customer.mailBusiness && customer.mailBusiness.length > 0) {
    if (!isValidateEmail(customer.mailBusiness)) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, mailBusiness: InternalErrorCodes.MAIL_INVALID },
      };
    }
  }

  if (customer.website && customer.website.length > 0) {
    if (!isUrlValid(customer.website)) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, website: InternalErrorCodes.URL_INVALID },
      };
    }
  }

  if (state.birthdateHelper && state.birthdateHelper.length > 0) {
    if (!isValidDateString(state.birthdateHelper)) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, birthdate: InternalErrorCodes.URL_INVALID },
      };
    }
  }

  if (
    (customer.zip && customer.zip.length > 0) ||
    (customer.city && customer.city.length > 0) ||
    (customer.street && customer.street.length > 0)
  ) {
    if (!customer.zip || customer.zip.length <= 0) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, zip: InternalErrorCodes.FIELD_MISSING },
      };
    }
    if (!customer.city || customer.city.length <= 0) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, city: InternalErrorCodes.FIELD_MISSING },
      };
    }
    if (!customer.street || customer.street.length <= 0) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, street: InternalErrorCodes.FIELD_MISSING },
      };
    }
  }

  if (
    (customer.bankBic && customer.bankBic.length > 0) ||
    (customer.bankName && customer.bankName.length > 0) ||
    (customer.bankIban && customer.bankIban.length > 0)
  ) {
    if (!customer.bankBic || customer.bankBic.length <= 0) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, bankBic: InternalErrorCodes.FIELD_MISSING },
      };
    }
    if (!customer.bankName || customer.bankName.length <= 0) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, bankName: InternalErrorCodes.FIELD_MISSING },
      };
    }
    if (!customer.bankIban || customer.bankIban.length <= 0) {
      result = {
        ...result,
        isValid: false,
        errors: { ...result.errors, bankIban: InternalErrorCodes.FIELD_MISSING },
      };
    }
  }

  return result;
};
