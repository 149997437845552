/* eslint-disable indent */
import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';

const Container = styled.div<{
  width: number;
  hover: boolean;
  canDrop: boolean;
  collapsed: boolean;
  isCollapsable: boolean;
}>`
  cursor: ${(props) => (props.isCollapsable ? 'pointer' : 'default')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  width: ${(props) => (props.collapsed ? 50 : props.width)}px;
  border-right: 1px solid ${(props) => props.theme.border.color};
  text-align: center;
  background-color: ${(props) =>
    props.hover
      ? lighten(0.4, props.theme.color.primaryColor)
      : props.canDrop
      ? lighten(0.55, props.theme.color.primaryColor)
      : null};

  i {
    font-size: 20px;
  }

  .Green {
    color: ${(props) => props.theme.color.green};
  }

  .Red {
    color: ${(props) => props.theme.color.red};
  }
`;

interface Props {
  children: React.ReactElement | React.ReactElement[] | string;
  collapsedIcon?: string;
  width: number;
  forwardRef?: React.Ref<HTMLDivElement>;
  isDragOver: boolean;
  isCanDrop: boolean;
  collapsed: boolean;
  onClick?: () => void;
}

/**
 * CollapsableContainer
 * @param props
 * @constructor
 */
export default function CollapsableContainer(props: Props) {
  const { children, width, forwardRef, isDragOver, isCanDrop, collapsed, onClick, collapsedIcon } = props;

  const renderContent = () => {
    if (collapsed) {
      return <i className={collapsedIcon} />;
    }
    return children;
  };

  return (
    <Container
      ref={forwardRef}
      width={width}
      hover={isDragOver}
      canDrop={isCanDrop}
      collapsed={collapsed}
      onClick={onClick ? () => onClick() : () => {}}
      isCollapsable={!!onClick}
    >
      {renderContent()}
    </Container>
  );
}

CollapsableContainer.defaultProps = {
  collapsed: false,
};
