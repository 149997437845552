import React from 'react';
import { PositionEntity } from '../../../Globals/Types/OrderTypes';
import { formatCurrency } from '../../../Globals/Functions';

interface Props {
  item: PositionEntity;
}

/**
 * PositionPriceCol
 * @constructor
 */
export default function PositionPriceCol(props: Props) {
  const { item } = props;

  const renderPrice = () => {
    if (item.price && item.price.length > 0) {
      return formatCurrency(item.price);
    }
    return null;
  };

  return <td key={`${item.positionId}-price`}>{renderPrice()}</td>;
}
