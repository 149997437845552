import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { OfferEntity } from '../../../Globals/Types/OfferTypes';

export interface ValidateOfferErrorInterface {
  positions?: string;
  referenceInvoiceNumber?: string;
  orderNumber?: string;
  contactUserId?: string;
}

export interface ValidateOfferResult {
  isValid: boolean;
  errors: ValidateOfferErrorInterface;
}

/**
 * validateInvoice()
 * @param offer
 */
export const validateOffer = (offer: OfferEntity): ValidateOfferResult => {
  let result: ValidateOfferResult = {
    isValid: true,
    errors: {},
  };

  if (!offer.offerNumber) {
    result = {
      ...result,
      errors: { ...result.errors, orderNumber: InternalErrorCodes.FIELD_MISSING },
      isValid: false,
    };
  }

  if (!offer.contactUserId) {
    result = {
      ...result,
      errors: { ...result.errors, contactUserId: InternalErrorCodes.FIELD_MISSING },
      isValid: false,
    };
  }

  if (!offer.positions || offer.positions.length <= 0) {
    result = {
      ...result,
      errors: { ...result.errors, positions: InternalErrorCodes.FIELD_MISSING },
      isValid: false,
    };
  }

  return result;
};
