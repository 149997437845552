import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import styled from 'styled-components';
import { FacilityPartnerEntity } from '../../Globals/Types/Types';
import { OrderEntity } from '../../Globals/Types/OrderTypes';

const Container = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 25px;
  border-bottom: 0.5px solid #dee2e6;
  cursor: pointer;
  align-items: center;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${(props) => props.theme.table.highlight};
  }

  > i {
    margin-right: 15px;
    font-size: 22px;
  }
`;

interface Props {
  order: OrderEntity;
  onSave: (item: FacilityPartnerEntity) => void;
  onClose: () => void;
  visible: boolean;
}

/**
 * AssignPartnerModal
 * @param props
 * @constructor
 */
export default function AssignPartnerModal(props: Props) {
  const { order, visible, onClose, onSave } = props;
  const [partners, setPartners] = React.useState<FacilityPartnerEntity[]>(null);
  const [t] = useTranslation();
  const { allPartners } = useAppSelector((state) => state.client);

  React.useEffect(() => {
    const filtered = allPartners.filter(
      (partner) =>
        partner.active &&
        partner.invitedFacilityId === order.ownerFacilityId &&
        partner.partnerSettings &&
        partner.partnerSettings.assignOrders,
    );
    setPartners(filtered);
  }, [allPartners, order]);

  const handleOnSave = (partner: FacilityPartnerEntity) => {
    onSave(partner);
  };

  const renderRow = () => {
    if (partners && partners.length > 0) {
      return partners.map((partner) => (
        <Row onClick={() => handleOnSave(partner)} key={partner.partnerId}>
          <i className="fas fa-handshake" />
          {partner.partnerName}
        </Row>
      ));
    }
    return null;
  };

  return (
    <Modal show={visible} onHide={() => onClose()} size="lg">
      <Modal.Header>
        <div>
          <Modal.Title>{t('assignPartner')}</Modal.Title>
          <small>Bitte wählen Sie den Partner für die Durchführung des Auftrags</small>
        </div>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <Container>{renderRow()}</Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => onClose()}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
