import React from 'react';
import styled, { useTheme } from 'styled-components';

const Container = styled.div<{ fontColor: string }>`
  display: flex;
  cursor: pointer;
  background-color: ${(props) => props.theme.table.highlight};
  padding: 4px 12px;
  border-radius: 10px;
  color: ${(props) => props.fontColor};
  margin-right: 12px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 14px;

  > i {
    font-size: 11px;
    padding-right: 4px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${(props) => props.theme.table.highlight};
    background-color: #a9a9a9;
  }
`;

interface Props {
  caption: string;
  onPress: () => void;
  fontColor?: string;
}

/**
 * Badge
 * @param props
 * @constructor
 */
export default function Badge(props: Props) {
  const { caption, onPress, fontColor } = props;
  const theme = useTheme();

  return (
    <Container onClick={onPress} fontColor={fontColor || theme.table.highlight}>
      {caption}
    </Container>
  );
}
