import React from 'react';
import styled from 'styled-components';
import { ElasticSearchServiceEntity, OrderEntity, ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { DragDropType } from '../../../../../Globals/Types/DispositionTypes';
import { formatDateString } from '../../../../../Globals/Functions';
import { DispositionListContext } from '../../index';
import { DispositionReducerByDate } from '../../../../../Redux/Reducer/DispositionReducer';
import { useGetServiceTypeIcon } from '../../../../../Globals/Hooks/ServiceHooks';
import moment from 'moment';
import ServiceOverlay from '../../../../Order/ServiceOverlay';
import { useDispatchOrderGet } from '../../../../../Redux/Actions/OrderAction';

const Container = styled.div<{ isDragging: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin: 8px 8px 0 8px;
  padding: ${(props) => (props.isDragging ? '8px 14px' : '10px 16px')};
  background-color: ${(props) => props.theme.table.highlight};
  border-radius: 6px;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  ${(props) => (props.isDragging ? `border: 2px dashed ${props.theme.color.orange}` : null)};

  .small {
    font-size: 12px;
    color: #adadad;
    line-height: 16px;
  }

  .isToday {
    font-weight: bold;
    color: ${(props) => props.theme.color.green};
  }

  .isBefore {
    font-weight: bold;
    color: ${(props) => props.theme.color.red};

    i {
      margin-right: 4px;
    }
  }

  .Left {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 24px;
    padding: 0 6px;
  }

  .Right {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .TypeIcon {
    width: 32px;
    display: block;
  }

  &:hover {
    background-color: ${(props) => props.theme.color.primaryColor};
    color: #ffffff !important;
    ${(props) => props.theme.boxShadowDarkHover}

    .small {
      color: #ffffff;
    }

    .TypeIcon {
      cursor: move;
      color: ${(props) => props.theme.color.orange};
    }
  }
`;

interface Props {
  service: ElasticSearchServiceEntity;
  tourListByDate: DispositionReducerByDate[];
  onPress?: (service: ElasticSearchServiceEntity) => void;
  currentDate: string;
}

/**
 * ServiceItem
 * @param props
 * @constructor
 */
export default function ServiceItem(props: Props) {
  const { service, tourListByDate, currentDate } = props;
  const [t] = useTranslation();
  const context = React.useContext(DispositionListContext);
  const getServiceTypeIcon = useGetServiceTypeIcon();
  const [isSelectedDate, setIsSelectedDate] = React.useState<boolean>(false);
  const [isBeforeToday, setIsBeforeToday] = React.useState<boolean>(false);

  const [serviceEntity, setServiceEntity] = React.useState<ServiceEntity>(null);
  const [orderEntity, setOrderEntity] = React.useState<OrderEntity>(null);
  const [showOverlay, setShowOverlay] = React.useState<boolean>(false);
  const dispatchGetOrder = useDispatchOrderGet();

  const loadService = React.useCallback(() => {
    setShowOverlay(true);

    if (!serviceEntity || !orderEntity) {
      dispatchGetOrder(service.ownerClientId, service.orderId).then((order) => {
        const found = order.services.find((item) => item.serviceId === service.serviceId);
        setServiceEntity(found);
        setOrderEntity(order);
      });
    }
  }, [dispatchGetOrder, orderEntity, service.orderId, service.ownerClientId, service.serviceId, serviceEntity]);

  const [{ isDragging }, drag] = useDrag<ElasticSearchServiceEntity, any, any>(
    () => ({
      type: DragDropType.SERVICE,
      item: service,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [service, tourListByDate],
  );

  React.useEffect(() => {
    if (service) {
      if (service.desiredDate) {
        setIsBeforeToday(moment(service.desiredDate).isBefore(moment(currentDate), 'date'));
      } else if (service.desiredWeek) {
        setIsBeforeToday(moment(service.desiredWeek, 'WW/YYYY').startOf('week').isBefore(moment(currentDate), 'week'));
      } else {
        setIsBeforeToday(false);
      }
    }
  }, [currentDate, service]);

  React.useEffect(() => {
    if (service) {
      if (service.desiredDate) {
        setIsSelectedDate(moment(service.desiredDate).isSame(moment(currentDate), 'date'));
      } else if (service.desiredWeek) {
        setIsSelectedDate(moment(service.desiredWeek, 'WW/YYYY').startOf('week').isSame(moment(currentDate), 'week'));
      } else {
        setIsSelectedDate(false);
      }
    }
  }, [currentDate, service]);

  React.useEffect(() => {
    if (isDragging) {
      context.setDragAssemblyTime(service.estimatedAssemblyTime);
    }
  }, [context, isDragging, service.estimatedAssemblyTime]);

  const renderAssemblyTime = () => {
    const { estimatedAssemblyTime, remainingAssemblyTime } = service;
    if (estimatedAssemblyTime) {
      return <div className="small">{`Planbare Zeit: ${remainingAssemblyTime}/${estimatedAssemblyTime} Minuten`}</div>;
    }
    return null;
  };

  const getDesiredTimeString = () => {
    if (service.desiredExecutionTime) {
      return `(${service.desiredExecutionTime})`;
    }
    return '';
  };

  const renderDesiredWeek = () => {
    if (service.desiredWeek) {
      const string = `${t('desiredWeek')}: ${service.desiredWeek} ${getDesiredTimeString()}`;

      if (isBeforeToday) {
        return (
          <div className="small isBefore">
            <i className="fas fa-exclamation-triangle" />
            {string}
          </div>
        );
      } else if (isSelectedDate) {
        return <div className="small isToday">{string}</div>;
      } else {
        return <div className="small">{string}</div>;
      }
    }
    return null;
  };

  const renderDesiredDate = () => {
    const string = `${t('desiredDate')}: ${formatDateString(service.desiredDate, 'LL')} ${getDesiredTimeString()}`;

    if (service.desiredDate) {
      if (isBeforeToday) {
        return (
          <div className="small isBefore">
            <i className="fas fa-exclamation-triangle" />
            {string}
          </div>
        );
      } else if (isSelectedDate) {
        return <div className="small isToday">{string}</div>;
      } else {
        return <div className="small">{string}</div>;
      }
    }
    return null;
  };

  return (
    <>
      <Container ref={drag} isDragging={isDragging} onClick={loadService}>
        <div className="Left">
          <i className={`${getServiceTypeIcon(service.serviceType)} TypeIcon`} />
        </div>
        <div className="Right">
          {service.externalOrderId}
          <div className="small">{`${service.customer.zip} ${service.customer.city}`}</div>
          {renderAssemblyTime()}
          {renderDesiredDate()}
          {renderDesiredWeek()}
        </div>
      </Container>
      <ServiceOverlay
        service={serviceEntity}
        order={orderEntity}
        visible={showOverlay}
        onClose={(clear) => {
          if (clear) {
            setServiceEntity(null);
            setOrderEntity(null);
          }
          setShowOverlay(false);
        }}
      />
    </>
  );
}
