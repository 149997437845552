/* eslint-disable max-len */
import {
  AbsenceEntity,
  GroupedTimeTracking,
  MessageEntity,
  TaskEntity,
  TimeTrackingEntity,
} from '../../Globals/Types/Types';
import {
  AUTH_LOGOUT,
  CLIENT_INVOICE_GETLIST_SUCCESS,
  USER_ABSENCES_GETLIST_SUCCESS,
  USER_INBOX_DELETE_SUCCESS,
  USER_INBOX_GETLIST_SUCCESS,
  USER_INBOX_MARK_READ_SUCCESS,
  USER_INBOX_UNREAD_COUNT_SUCCESS,
  USER_INBOX_UPDATE_SUCCESS,
  USER_TASK_DELETE_SUCCESS,
  USER_TASK_GETLIST_SUCCESS,
  USER_TIMETRACKING_GET_LAST_SUCCESS,
  USER_TIMETRACKING_GETBYMONTH_SUCCESS,
} from '../ActionTypes';
import { groupTimeTrackingByDate } from '../../Globals/Functions';
import { InvoiceEntity } from '../../Globals/Types/Invoice';
import { getInvoiceSalesOverallByUserForCurrentMonth } from './Client/ClientReducerHelper';

type InboxType = {
  messages: Array<MessageEntity>;
  unreadCount: number;
  initiallyLoaded: boolean;
};

export interface UserReducerInterface {
  absences: Array<AbsenceEntity>;
  inbox: InboxType;
  tasks: Array<TaskEntity>;
  invoices: {
    currentMonthSales: number;
  };
  timeTracking: {
    lastState: TimeTrackingEntity;
    currentMonth: Array<TimeTrackingEntity>;
    groupedByDate: Array<GroupedTimeTracking>;
  };
}

export type UserActions =
  | { type: typeof USER_INBOX_GETLIST_SUCCESS; payload: Array<MessageEntity> }
  | { type: typeof USER_INBOX_UNREAD_COUNT_SUCCESS; payload: number }
  | { type: typeof USER_INBOX_DELETE_SUCCESS; payload: string }
  | { type: typeof USER_INBOX_MARK_READ_SUCCESS; payload: string }
  | { type: typeof USER_INBOX_UPDATE_SUCCESS; payload: MessageEntity }
  | { type: typeof USER_ABSENCES_GETLIST_SUCCESS; payload: Array<AbsenceEntity> }
  | { type: typeof USER_TIMETRACKING_GET_LAST_SUCCESS; payload: TimeTrackingEntity }
  | {
      type: typeof USER_TIMETRACKING_GETBYMONTH_SUCCESS;
      payload: { month: number; year: number; data: Array<TimeTrackingEntity> };
    }
  | { type: typeof USER_TASK_GETLIST_SUCCESS; payload: Array<TaskEntity> }
  | { type: typeof USER_TASK_DELETE_SUCCESS; payload: TaskEntity }
  | { type: typeof CLIENT_INVOICE_GETLIST_SUCCESS; payload: { invoices: InvoiceEntity[]; userId: string } }
  | { type: typeof AUTH_LOGOUT };

const defaultValues: UserReducerInterface = {
  absences: [],
  inbox: {
    messages: [],
    unreadCount: 0,
    initiallyLoaded: false,
  },
  invoices: {
    currentMonthSales: 0,
  },
  tasks: [],
  timeTracking: {
    lastState: null,
    currentMonth: [],
    groupedByDate: [],
  },
};

export default function reduce(state: UserReducerInterface = defaultValues, action: UserActions): UserReducerInterface {
  switch (action.type) {
    case USER_INBOX_GETLIST_SUCCESS: {
      const unreadCount = action.payload.filter((message) => !message.read).length;
      return { ...state, inbox: { ...state.inbox, messages: action.payload, initiallyLoaded: true, unreadCount } };
    }
    case USER_INBOX_UNREAD_COUNT_SUCCESS: {
      return { ...state, inbox: { ...state.inbox, unreadCount: action.payload } };
    }
    case USER_INBOX_DELETE_SUCCESS: {
      const messages = state.inbox.messages.filter((item) => item.messageId !== action.payload);
      const unreadCount = messages.filter((message) => !message.read).length;
      return {
        ...state,
        inbox: {
          ...state.inbox,
          messages,
          unreadCount,
        },
      };
    }
    case USER_INBOX_MARK_READ_SUCCESS: {
      const messages = state.inbox.messages.map((message) =>
        message.messageId === action.payload ? { ...message, read: true } : message,
      );
      const unreadCount = messages.filter((message) => !message.read).length;
      return { ...state, inbox: { ...state.inbox, messages, unreadCount } };
    }
    case USER_INBOX_UPDATE_SUCCESS: {
      const messages = state.inbox.messages.map((item) =>
        item.messageId === action.payload.messageId ? action.payload : item,
      );
      const unreadCount = messages.filter((message) => !message.read).length;
      return { ...state, inbox: { ...state.inbox, messages, unreadCount } };
    }
    case USER_ABSENCES_GETLIST_SUCCESS: {
      return { ...state, absences: action.payload };
    }
    case USER_TIMETRACKING_GET_LAST_SUCCESS: {
      return { ...state, timeTracking: { ...state.timeTracking, lastState: action.payload } };
    }
    case USER_TIMETRACKING_GETBYMONTH_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        timeTracking: {
          ...state.timeTracking,
          groupedByDate: groupTimeTrackingByDate(data, 'desc'),
          currentMonth: data,
        },
      };
    }

    case USER_TASK_GETLIST_SUCCESS: {
      return { ...state, tasks: action.payload };
    }
    case USER_TASK_DELETE_SUCCESS: {
      return { ...state, tasks: state.tasks.filter((item) => item.taskId !== action.payload.taskId) };
    }

    case CLIENT_INVOICE_GETLIST_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          currentMonthSales: getInvoiceSalesOverallByUserForCurrentMonth(
            action.payload.invoices,
            action.payload.userId,
          ),
        },
      };

    case AUTH_LOGOUT: {
      return { ...defaultValues };
    }

    default:
      return { ...state };
  }
}
