import React from 'react';
import { AbstractMedia } from '../../Globals/Types/Types';
import styled from 'styled-components';
import Item from './Item';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -15px;
`;

interface Props {
  media: AbstractMedia[];
  onDelete: (item: AbstractMedia) => void;
}

const ITEM_MARGIN = 15;

/**
 * MediaList()
 * @param props
 * @constructor
 */
export default function MediaList(props: Props) {
  const { media, onDelete } = props;
  const ref = React.useRef<HTMLDivElement>();
  const [itemWidth, setItemWidth] = React.useState<number>(0);

  const handleResize = React.useCallback(() => {
    if (ref.current) {
      const windowWidth = ref.current.offsetWidth;
      let itemsPerRow = parseInt((windowWidth / 350).toFixed(0), 10);

      const itemWidthParam = (windowWidth - ITEM_MARGIN * itemsPerRow) / itemsPerRow;
      if (itemWidth !== itemWidthParam) {
        setItemWidth(itemWidthParam);
      }
    }
  }, [itemWidth]);

  React.useEffect(() => {
    if (ref) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const renderContent = () => {
    if (media) {
      return media.map((item) => <Item media={item} width={itemWidth} key={item.fullPath} onDelete={onDelete} />);
    }
    return null;
  };

  return <Container ref={ref}>{renderContent()}</Container>;
}
