import React from 'react';
import styled from 'styled-components';
import { Tour, TourItem, TourService } from '../../../Globals/Types/Tour';
import InfoContainer from './InfoContainer';

const Row = styled.div<{ isPlanned: boolean }>`
  display: flex;
  flex: 1;
  width: 50px;
  flex-direction: column;
  border-right: 1px solid ${(props) => props.theme.border.color};
  border-bottom: 1px solid ${(props) => props.theme.border.color};
  padding: 10px;
  min-height: 56px;
  ${(props) => (!props.isPlanned ? props.theme.gradient.light : null)};

  &:last-child {
    border-right: none;
  }
`;

interface Props {
  name: string;
  tours: Tour[];
  onPress: (tourService: TourService) => void;
  selectedService: TourService;
}

/**
 * DayContainer()
 * @param props
 * @constructor
 */
export default function DayContainer(props: Props) {
  const { name, tours, onPress, selectedService } = props;
  const [data, setData] = React.useState<TourItem<TourService>[]>(null);

  React.useEffect(() => {
    const found = tours.find((tour) => tour.name === name);
    if (found) {
      if (found.items) {
        setData(found.items);
      } else {
        setData([]);
      }
    }
  }, [name, tours]);

  const renderContent = () => {
    if (data) {
      return data.map((item) => (
        <InfoContainer
          key={item.startTime}
          data={item}
          onPress={onPress}
          selected={selectedService && selectedService.serviceId === item.data.serviceId}
        />
      ));
    }
    return null;
  };

  return <Row isPlanned={!!data}>{renderContent()}</Row>;
}
