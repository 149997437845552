import React from 'react';
import { useAppSelector, useLoadingModal, usePageTitle } from '../../../Globals/Hooks/Hooks';
import { Col, Row } from 'react-bootstrap';
import CustomerAddress from '../../../Components/Order/Details/Service/CustomerAddress';
import Headline from './Components/Headline';
import MessagePreview from '../../../Components/Order/Details/MessagePreview';
import MetaData from '../../../Components/Order/Details/Service/MetaData';
import { useTranslation } from 'react-i18next';
import ExecutionOverview from '../../../Components/Order/Details/ExecutionOverview';
import { Documents } from '../../../Components/Order/Details/Documents';
import { OrderEntity } from '../../../Globals/Types/OrderTypes';
import { useDispatchOrderUpdate } from '../../../Redux/Actions/OrderAction';
import PartnerPermissions from '../../../Components/Order/Details/PartnerPermissions';
import DeleteOrderButton from './Components/DeleteOrderButton';
import ContactPerson from '../../../Components/Order/Details/ContactPerson';
import Invoices from '../../../Components/Order/Details/Invoices';

/**
 * Overview
 * @constructor
 */
export default function Overview() {
  const [t] = useTranslation();
  const { setLoading, loadingModal } = useLoadingModal({ headline: t('updatingService') });
  const { order, messages } = useAppSelector((state) => state.order.selectedOrder);
  const dispatchUpdateOrder = useDispatchOrderUpdate();
  usePageTitle(order ? order.externalId : '');

  const handleUpdateOrder = (data: Partial<OrderEntity>) => {
    setLoading(true);
    dispatchUpdateOrder(order.ownerClientId, order.orderId, data).then(() => {
      setLoading(false);
    });
  };

  if (order) {
    return (
      <>
        <Headline order={order} headlineKey="modules.order.overview" />

        <Row>
          <Col xl={6} lg={12} style={{ display: 'flex' }}>
            <MetaData order={order} onChange={handleUpdateOrder} />
          </Col>

          <Col xl={6} lg={12} style={{ display: 'flex' }}>
            <CustomerAddress
              customer={order.customer}
              onChange={(data) => handleUpdateOrder({ customer: data })}
              headline={t('customerAddress')}
            />
          </Col>

          <Col xl={6} lg={12} style={{ display: 'flex' }}>
            <ExecutionOverview order={order} />
          </Col>
          <Col xl={6} lg={12} style={{ display: 'flex' }}>
            <MessagePreview messages={messages} />
          </Col>
          <Col xl={6} lg={12} style={{ display: 'flex' }}>
            <Documents showDelete={false} />
          </Col>
          <Col xl={6} lg={12} style={{ display: 'flex' }}>
            <ContactPerson
              contacts={order.contacts}
              onChange={(contacts) => handleUpdateOrder({ contacts })}
              description={t('modules.order.contactPersonsOrderDescription')}
              allowEdit={true}
            />
          </Col>
          <Col xl={12}>
            <Invoices order={order} />
          </Col>
          <Col xl={12}>
            <PartnerPermissions order={order} onChange={handleUpdateOrder} />
          </Col>
        </Row>

        <DeleteOrderButton />
        {loadingModal}
      </>
    );
  }
  return null;
}
