import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';
import React from 'react';
import moment from 'moment';
import { FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import { CustomerNoteEntity } from '../../../Globals/Types/Customer';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import {
  CUSTOMER_NOTES_CREATE_ERROR,
  CUSTOMER_NOTES_CREATE_START,
  CUSTOMER_NOTES_CREATE_SUCCESS,
  CUSTOMER_NOTES_DELETE_ERROR,
  CUSTOMER_NOTES_DELETE_START,
  CUSTOMER_NOTES_DELETE_SUCCESS,
  CUSTOMER_NOTES_GETLIST_ERROR,
  CUSTOMER_NOTES_GETLIST_START,
  CUSTOMER_NOTES_GETLIST_SUCCESS,
  CUSTOMER_NOTES_UPDATE_ERROR,
  CUSTOMER_NOTES_UPDATE_START,
  CUSTOMER_NOTES_UPDATE_SUCCESS,
} from '../../ActionTypes';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { filterCustomerNoteUserAccess } from '../../../Globals/Functions/CustomerFunctions';

/**
 * buildCollection()
 * @param clientId
 * @param customerId
 */
const buildCollection = (clientId: string, customerId: string): CollectionReference<CustomerNoteEntity> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.customer,
    customerId,
    FirebasePathMappings.notes,
  ) as CollectionReference<CustomerNoteEntity>;
};

/**
 * useDispatchCustomerNotesGetList()
 */
type GetListReturnType = (customerId: string) => Promise<CustomerNoteEntity[]>;
export const useDispatchCustomerNotesGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId, userId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListReturnType>(
    (customerId) => {
      dispatch({ type: CUSTOMER_NOTES_GETLIST_START, payload: customerId });

      const collection = buildCollection(clientId, customerId);

      return getDocs(query(collection, orderBy('firebaseUpdatedDateTime', 'desc')))
        .then((response) => {
          let returnData: CustomerNoteEntity[] = [];

          if (!response.empty) {
            response.forEach((note) => {
              returnData.push({ ...note.data(), noteId: note.id });
            });
          }

          const filteredAccess = filterCustomerNoteUserAccess(returnData, userId);
          dispatch({ type: CUSTOMER_NOTES_GETLIST_SUCCESS, payload: { customerId, notes: filteredAccess } });
          return Promise.resolve(filteredAccess);
        })
        .catch((error) => {
          dispatch({ type: CUSTOMER_NOTES_GETLIST_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch, userId],
  );
};

/**
 * useDispatchCustomerNoteCreate()
 */
type CreateReturnType = (customerId: string, note: CustomerNoteEntity) => Promise<CustomerNoteEntity>;
export const useDispatchCustomerNoteCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId, userId } = useAppSelector((state) => state.auth);

  return React.useCallback<CreateReturnType>(
    (customerId, note) => {
      dispatch({ type: CUSTOMER_NOTES_CREATE_START, payload: { customerId, note } });

      const mergedCustomer: CustomerNoteEntity = {
        ...note,
        createdDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        createdUserId: userId,
        updatedDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        updatedUserId: userId,
        firebaseCreatedDateTime: serverTimestamp(),
        firebaseUpdatedDateTime: serverTimestamp(),
      };
      const collection = buildCollection(clientId, customerId);

      return addDoc(collection, mergedCustomer)
        .then((response) => {
          const docRef = doc(getFirestore(), response.path) as DocumentReference<CustomerNoteEntity>;
          return getDoc(docRef).then((docResponse) => {
            if (docResponse.exists()) {
              const mapped: CustomerNoteEntity = { ...docResponse.data(), noteId: docResponse.id };

              return setDoc(docRef, mapped).then(() => {
                dispatch({ type: CUSTOMER_NOTES_CREATE_SUCCESS, payload: { customerId, note: mapped } });
                return Promise.resolve(mapped);
              });
            }

            return Promise.reject(new Error(InternalErrorCodes.ENTRY_NOT_FOUND));
          });
        })
        .catch((error) => {
          dispatch({ type: CUSTOMER_NOTES_CREATE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch, userId],
  );
};

/**
 * useDispatchCustomerNoteUpdate()
 */
type UpdateReturnType = (customerId: string, note: CustomerNoteEntity) => Promise<CustomerNoteEntity>;
export const useDispatchCustomerNoteUpdate = (): UpdateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId, userId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateReturnType>(
    (customerId, note) => {
      dispatch({ type: CUSTOMER_NOTES_UPDATE_START, payload: { customerId, note } });

      const mergedNote: CustomerNoteEntity = {
        ...note,
        updatedDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        updatedUserId: userId,
        firebaseUpdatedDateTime: serverTimestamp(),
      };

      const collection = buildCollection(clientId, customerId);
      const docRef = doc(collection, note.noteId);

      return setDoc(docRef, mergedNote, { merge: true })
        .then(() => {
          dispatch({ type: CUSTOMER_NOTES_UPDATE_SUCCESS, payload: { customerId, note: mergedNote } });
          return Promise.resolve(mergedNote);
        })
        .catch((error) => {
          dispatch({ type: CUSTOMER_NOTES_UPDATE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch, userId],
  );
};

/**
 * useDispatchCustomerNoteDelete()
 */
type DeleteReturnType = (customerId: string, noteId: string) => Promise<void>;
export const useDispatchCustomerNoteDelete = (): DeleteReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<DeleteReturnType>(
    (customerId, noteId) => {
      dispatch({ type: CUSTOMER_NOTES_DELETE_START, payload: { customerId, noteId } });

      const collection = buildCollection(clientId, customerId);
      const docRef = doc(collection, noteId);

      return deleteDoc(docRef)
        .then(() => {
          dispatch({ type: CUSTOMER_NOTES_DELETE_SUCCESS, payload: { customerId, noteId } });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch({ type: CUSTOMER_NOTES_DELETE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch],
  );
};
