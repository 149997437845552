import React from 'react';
import Lodash from 'lodash';
import moment from 'moment';
import PageHeader from '../../Components/PageHeader';
import { useAppSelector, useLoadingModal, usePageTitle } from '../../Globals/Hooks/Hooks';
import { RightListViewOnChangeState } from '../../Components/Disposition/DayView/Components/ToggleRightListViewButtons';
import { useDispatchOrderServiceFilterUnscheduled } from '../../Redux/Actions/Order/Service/Filter';
import ActionButtons from './Components/ActionButtons';
import {
  useDispatchTourAddResource,
  useDispatchTourAddService,
  useDispatchTourAddUser,
  useDispatchTourDelete,
  useDispatchTourDeleteResource,
  useDispatchTourDeleteService,
  useDispatchTourDeleteUser,
  useDispatchTourGetList,
  useDispatchTourToggleClose,
} from '../../Redux/Actions/Disposition/TourActions';
import CreateTourModal from './Create/CreateTourModal';
import { Tour, TourItem, TourService } from '../../Globals/Types/Tour';
import { ElasticSearchServiceEntity } from '../../Globals/Types/OrderTypes';
import UpdateTourModal from './Update/UpdateTourModal';
import { PickedUserEntity, ResourceEntity, UserEntity } from '../../Globals/Types/Types';
import { useValidateResource, useValidateService, useValidateUser } from './ValidateDragDrop';
import ErrorMessage from '../../Components/Modals/ErrorMessage';
import {
  DispositionErrorMessage,
  searchMonteure,
  searchResources,
  searchServices,
  tourServiceToElasticSearchService,
  useGetErrorMessage,
} from './Functions';
import { useParams } from 'react-router';
import DispositionList from '../../Components/Disposition/DayView';
import { useTranslation } from 'react-i18next';
import { useDispatchUsersGetList } from '../../Redux/Actions/UserAction';
import { useDispatchResourceGetList } from '../../Redux/Actions/Client/ResourceAction';
import { formatDateString } from '../../Globals/Functions';
import { useWindowHasFocus } from '../../Globals/Hooks/BrowserHooks';
import SelectView from '../../Components/Disposition/Components/SelectView';
import { ViewTypes } from '../../Globals/Types/DispositionTypes';
import WeekView from '../../Components/Disposition/WeekView';
import { useGetFacilityWorkTimes } from '../../Globals/Hooks/FacilityHooks';
import MapButton from './Components/MapButton';
import Map from './Map';
import { useDispatchAbsenceGetList } from '../../Redux/Actions/Client/AbsenceAction';
import SetAssemblyTimeModal from '../../Components/Disposition/Components/SetAssemblyTimeModal';

/**
 * Disposition
 * @constructor
 */
export default function Disposition() {
  const { date: initialDate } = useParams();
  const [t] = useTranslation();
  const { all: allUsers } = useAppSelector((state) => state.client.users);
  const { resources } = useAppSelector((state) => state.client);
  const { tourListByDate, tourList, unscheduledServices } = useAppSelector((state) => state.disposition);
  const [showMap, setShowMap] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<string>(moment().format('YYYY-MM-DD'));
  const [error, setError] = React.useState<DispositionErrorMessage>(null);
  const [selectedView, setSelectedView] = React.useState<ViewTypes>(ViewTypes.daily);
  const [lastFilterState, setLastFilterState] = React.useState<RightListViewOnChangeState>(null);
  const [filterState, setFilterState] = React.useState<RightListViewOnChangeState>({
    users: false,
    resources: false,
    services: true,
  });
  const [selectedService, setSelectedService] = React.useState<ElasticSearchServiceEntity>(null);
  const [selectedStartTime, setSelectedStartTime] = React.useState<string>(null);
  const [showSetAssemblyTime, setShowSetAssemblyTime] = React.useState<boolean>(false);

  const [selectedTourItem, setSelectedTourItem] = React.useState<TourItem<TourService>>(null);
  const [showCreate, setShowCreate] = React.useState<boolean>(false);
  const [showUpdate, setShowUpdate] = React.useState<boolean>(false);
  const [selectedTour, setSelectedTour] = React.useState<Tour>(null);
  const [monteurePrepared, setMonteurePrepared] = React.useState<UserEntity[]>(null);
  const [isSearch, setIsSearch] = React.useState<boolean>(false);
  const [searchResults, setSearchResults] = React.useState<ElasticSearchServiceEntity[]>(null);
  const [resourcesPrepared, setResourcesPrepared] = React.useState<ResourceEntity[]>(null);
  const { isLoading: isLoadingUnscheduled, setLoading: setLoadingUnscheduled } = useLoadingModal();
  const windowHasFocus = useWindowHasFocus();

  const dispatchTourAddResource = useDispatchTourAddResource();
  const dispatchTourAddService = useDispatchTourAddService();
  const dispatchTourAddUser = useDispatchTourAddUser();
  const dispatchTourDelete = useDispatchTourDelete();
  const dispatchTourDeleteResource = useDispatchTourDeleteResource();
  const dispatchTourDeleteService = useDispatchTourDeleteService();
  const dispatchTourDeleteUser = useDispatchTourDeleteUser();
  const dispatchTourGetList = useDispatchTourGetList();
  const dispatchTourToggleClose = useDispatchTourToggleClose();
  const dispatchGetUnscheduled = useDispatchOrderServiceFilterUnscheduled();
  const dispatchGetUsers = useDispatchUsersGetList();
  const dispatchGetResources = useDispatchResourceGetList();
  const getFacilityWorkTimes = useGetFacilityWorkTimes();
  const dispatchGetAbsence = useDispatchAbsenceGetList();

  const getErrorMessage = useGetErrorMessage();

  const validateResource = useValidateResource();
  const validateService = useValidateService();
  const validateUser = useValidateUser();

  usePageTitle(`Disposition ${formatDateString(date, 'DD.MM.YY')}`);

  const refreshUnscheduledByTimeout = React.useCallback(
    (withTimeout: boolean = true) => {
      setLoadingUnscheduled(true);
      setTimeout(
        () => {
          dispatchGetUnscheduled().finally(() => setLoadingUnscheduled(false));
        },
        withTimeout ? 0 : 0,
      );
    },
    [dispatchGetUnscheduled, setLoadingUnscheduled],
  );

  React.useEffect(() => {
    if (selectedTourItem) {
      tourList.forEach((tour) => {
        if (tour.items) {
          const found = tour.items.find(
            (item: TourItem<TourService>) => item.data.serviceId === selectedTourItem.data.serviceId,
          );
          if (found) {
            setSelectedTourItem(found);
            return;
          }
        }
      });
    }
  }, [selectedTourItem, tourList]);

  React.useEffect(() => {
    if (windowHasFocus) {
      refreshUnscheduledByTimeout(false);
    }
  }, [refreshUnscheduledByTimeout, windowHasFocus]);

  React.useEffect(() => {
    if (!monteurePrepared) {
      setMonteurePrepared(allUsers);
    }
  }, [allUsers, monteurePrepared, setMonteurePrepared]);

  React.useEffect(() => {
    if (initialDate) {
      setDate(initialDate);
    }
  }, [initialDate]);

  React.useEffect(() => {
    if (!resourcesPrepared) {
      setResourcesPrepared(resources);
    }
  }, [resources, resourcesPrepared, setResourcesPrepared]);

  React.useEffect(() => {
    dispatchGetUsers().then(() => dispatchGetResources());
  }, [dispatchGetResources, dispatchGetUsers]);

  React.useEffect(() => {
    if (!Lodash.find(tourListByDate, (item) => item.date === date)) {
      const dateStart: string = moment(date).subtract(9, 'month').startOf('month').format('YYYY-MM-DD');
      const dateEnd: string = moment(date).add(3, 'month').endOf('month').format('YYYY-MM-DD');
      dispatchTourGetList(dateStart, dateEnd).then(() => null);
      dispatchGetAbsence(moment(dateStart).toDate(), moment(dateEnd).toDate()).then(() => null);
    }
  }, [date, dispatchTourGetList, tourListByDate, dispatchGetAbsence]);

  const handleSetDate = (date: string) => {
    if (lastFilterState) {
      setFilterState(lastFilterState);
    }
    setDate(date);
    setSelectedTourItem(null);
    setSelectedTour(null);
  };

  const handleCloseCreate = (newDate?: string) => {
    // Can be the same date, don't trigger useEffects
    if (newDate && newDate !== date) {
      setDate(newDate);
    }
    setShowCreate(false);
  };

  const handleCloseUpdate = () => {
    setSelectedTour(null);
    setShowUpdate(false);
  };

  const handleCloseSetAssemblyTime = () => {
    setSelectedStartTime(null);
    setSelectedService(null);
    setSelectedTour(null);
    setShowSetAssemblyTime(false);
  };

  const handleOnSaveSetAssemblyTime = (service: ElasticSearchServiceEntity) => {
    handleAddService(selectedStartTime, selectedTour, service).then(() => {
      handleCloseSetAssemblyTime();
    });
  };

  const handleDeleteResource = (tour: Tour, resource: ResourceEntity) => {
    dispatchTourDeleteResource(tour, resource).then(() => null);
  };

  const handleDeleteTourItem = (tourItem: TourItem<TourService>) => {
    dispatchTourDeleteService(selectedTour, tourItem.data).then(() => {
      setSelectedTour(null);
      setSelectedTourItem(null);
      setFilterState(lastFilterState);
      refreshUnscheduledByTimeout();
    });
  };

  const handleDeleteTour = (tour: Tour) => {
    dispatchTourDelete(tour).then(() => {
      refreshUnscheduledByTimeout();
    });
  };

  const handleDropResource = (tour: Tour, resource: ResourceEntity) => {
    const { error, isValid } = validateResource(tour, resource);
    if (!isValid) {
      return setError(getErrorMessage(error));
    }

    dispatchTourAddResource(tour, resource).then(() => null);
  };

  const handleCheckAddService = (newStartTime: string, tour: Tour, service: ElasticSearchServiceEntity) => {
    if (service.estimatedAssemblyTime && service.estimatedAssemblyTime > 0) {
      return handleAddService(newStartTime, tour, service);
    } else {
      setSelectedService(service);
      setSelectedStartTime(newStartTime);
      setSelectedTour(tour);
      setTimeout(() => setShowSetAssemblyTime(true), 500);
    }
  };

  const handleAddService = (newStartTime: string, tour: Tour, service: ElasticSearchServiceEntity) => {
    const workTimes = getFacilityWorkTimes(service.ownerFacilityId);
    const { error, isValid, endTime } = validateService(tour, service, newStartTime, workTimes.workDayEnd);

    if (!isValid) {
      setError(getErrorMessage(error));
      return Promise.reject();
    }

    return dispatchTourAddService(tour, service, newStartTime, endTime).then(() => {
      refreshUnscheduledByTimeout();
      return Promise.resolve();
    });
  };

  const handleMoveService = (newStartTime: string, tour: Tour, service: TourService, tourOld: Tour) => {
    const servicePrepared = tourServiceToElasticSearchService(service);

    const tourItem: TourItem<TourService> = tourOld.items.find(
      (item: TourItem<TourService>) => item.data.serviceId === service.serviceId,
    );
    const time = Math.abs(moment(tourItem.startTime, 'HH:mm:ss').diff(moment(tourItem.endTime, 'HH:mm:ss'), 'minute'));
    const newEndTime = moment(newStartTime, 'HH:mm:ss').add(time, 'minute').format('HH:mm:ss');

    dispatchTourDeleteService(tourOld, service).then((updatedTour) => {
      // Check if is update on same tour, then take response as tour to add the service, the tour was modified and a
      // service was deleted, so we must use the updated tour to avoid adding outdated data again. Otherwise, take the
      // new tour
      const tourParam = updatedTour.tourId === tour.tourId ? updatedTour : tour;

      dispatchTourAddService(tourParam, servicePrepared, newStartTime, newEndTime).then(() => {
        refreshUnscheduledByTimeout();
      });
    });
  };

  const handleDropUser = (tour: Tour, user: UserEntity) => {
    const { error, isValid } = validateUser(tour, user);

    if (!isValid) {
      return setError(getErrorMessage(error));
    }

    dispatchTourAddUser(tour, user).then(() => null);
  };

  const handleEditTour = (tour: Tour) => {
    setSelectedTour(tour);
    setShowUpdate(true);
  };

  const handlePressService = (tour: Tour, service: TourService) => {
    const item = tour.items.find((item: TourItem<TourService>) => item.data.serviceId === service.serviceId);
    setSelectedTourItem(item);
    setSelectedTour(tour);

    setLastFilterState(filterState);
    setFilterState({
      services: false,
      resources: false,
      users: false,
    });
  };

  const handleCloseServicePanel = () => {
    setSelectedTourItem(null);
    setSelectedTour(null);
    setFilterState(lastFilterState);
    refreshUnscheduledByTimeout(false);
  };

  const handleSearch = (value: string) => {
    if (value && value.length > 0) {
      setIsSearch(true);
      setSearchResults(searchServices(unscheduledServices, value));
    } else {
      setIsSearch(false);
      setSearchResults(null);
    }
  };

  const onClickDayInWeekView = (dateParam: string) => {
    setDate(dateParam);
    setSelectedView(ViewTypes.daily);
  };

  const getMapButton = () => {
    if (tourListByDate) {
      const found = tourListByDate.find((item) => item.date === moment().format('YYYY-MM-DD'));
      if (found && found.data && found.data.length > 0) {
        return <MapButton onShowMap={() => setShowMap(!showMap)} active={showMap} marginRight={!showMap} />;
      }
      return null;
    }
    return null;
  };

  const renderView = () => {
    if (!showMap) {
      if (selectedView === ViewTypes.daily) {
        return (
          <DispositionList
            date={date}
            onChangeDate={handleSetDate}
            onMoveService={handleMoveService}
            onAddService={handleCheckAddService}
            onDropUser={handleDropUser}
            onDropResource={handleDropResource}
            onDeleteTour={handleDeleteTour}
            onEditTour={handleEditTour}
            onToggleCloseTour={(tour: Tour) => dispatchTourToggleClose(tour).then(() => {})}
            showService={filterState.services}
            showResources={filterState.resources}
            showUsers={filterState.users}
            users={monteurePrepared}
            resources={resourcesPrepared}
            services={!isSearch ? unscheduledServices : searchResults}
            onSearchMonteure={(value: string) => setMonteurePrepared(searchMonteure(allUsers, value))}
            onSearchResources={(value: string) => setResourcesPrepared(searchResources(resources, value))}
            onSearchServices={handleSearch}
            onDeleteResource={handleDeleteResource}
            onDeleteUser={(tour: Tour, user: PickedUserEntity) => dispatchTourDeleteUser(tour, user).then(() => {})}
            onPressService={handlePressService}
            tourListByDate={tourListByDate}
            isLoadingServices={isLoadingUnscheduled}
            onReloadServiceClick={() => refreshUnscheduledByTimeout(false)}
            selectedTour={selectedTour}
            selectedTourItem={selectedTourItem}
            onCloseServicePanel={handleCloseServicePanel}
            onDeleteTourItem={handleDeleteTourItem}
          />
        );
      }
      return (
        <WeekView
          date={date}
          onChangeDate={setDate}
          tours={tourListByDate}
          onPressDay={onClickDayInWeekView}
          selectedTour={selectedTour}
          selectedTourItem={selectedTourItem}
          onCloseServicePanel={handleCloseServicePanel}
          onDeleteTourItem={handleDeleteTourItem}
          onPressService={handlePressService}
        />
      );
    }
    return <Map />;
  };

  return (
    <>
      <PageHeader
        headline={`${t('disposition')}`}
        actionButtonThree={
          !showMap ? <ActionButtons onChangeFilter={setFilterState} onAdd={() => setShowCreate(true)} /> : null
        }
        actionButtonTwo={
          !showMap ? (
            <SelectView containerStyle={{ marginRight: 30 }} onChange={setSelectedView} selectedType={selectedView} />
          ) : null
        }
        actionButtonOne={getMapButton()}
      />

      {renderView()}

      <CreateTourModal
        initialDate={date}
        onClose={handleCloseCreate}
        onError={(error) => setError(getErrorMessage(error))}
        visible={showCreate}
      />

      <UpdateTourModal onClose={handleCloseUpdate} tour={selectedTour} visible={showUpdate} />

      <ErrorMessage
        onClose={() => setError(null)}
        visible={!!error}
        headline={error?.headline}
        description={error?.description}
      />

      <SetAssemblyTimeModal
        service={selectedService}
        visible={showSetAssemblyTime}
        onClose={handleCloseSetAssemblyTime}
        startTime={selectedStartTime}
        onSave={handleOnSaveSetAssemblyTime}
      />
    </>
  );
}
