import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface DayNamesHeaderProps {
  $showSundays: boolean;
  $badgeMargin: number;
}

const Container = styled.div<DayNamesHeaderProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% * (1 / ${(props) => (props.$showSundays ? 7 : 6)}) - ${(props) => props.$badgeMargin * 2}px);
  margin: ${(props) => props.$badgeMargin}px;
  cursor: pointer;
  perspective: 1500px;
  padding-left: 6px;
`;

/**
 * DayNamesHeader()
 * @returns {*}
 * @constructor
 */
export default function DayNamesHeader(props: DayNamesHeaderProps) {
  const { $showSundays, $badgeMargin } = props;
  const [t] = useTranslation();

  const renderSunday = () => {
    if ($showSundays) {
      return (
        <Container $showSundays={$showSundays} $badgeMargin={$badgeMargin}>
          {t('sunday')}
        </Container>
      );
    }
    return null;
  };

  return (
    <>
      <Container $showSundays={$showSundays} $badgeMargin={$badgeMargin}>
        {t('weekday.monday')}
      </Container>
      <Container $showSundays={$showSundays} $badgeMargin={$badgeMargin}>
        {t('weekday.tuesday')}
      </Container>
      <Container $showSundays={$showSundays} $badgeMargin={$badgeMargin}>
        {t('weekday.wednesday')}
      </Container>
      <Container $showSundays={$showSundays} $badgeMargin={$badgeMargin}>
        {t('weekday.thursday')}
      </Container>
      <Container $showSundays={$showSundays} $badgeMargin={$badgeMargin}>
        {t('weekday.friday')}
      </Container>
      <Container $showSundays={$showSundays} $badgeMargin={$badgeMargin}>
        {t('weekday.saturday')}
      </Container>
      {renderSunday()}
    </>
  );
}
