import React from 'react';
import { UserEntity } from '../../../Globals/Types/Types';
import { useGroups } from '../../../Globals/Access/Groups';

interface Props {
  item: UserEntity;
}

/**
 * UserGroupsCol
 * @constructor
 */
export default function UserGroupsCol(props: Props) {
  const { item } = props;
  const groups = useGroups();

  const renderGroups = () =>
    item.groups
      .map((name) => {
        const found = groups.find((item) => item.key === name);
        return found.short;
      })
      .join(', ');

  return <td key={`${item.userId}-group`}>{renderGroups()}</td>;
}
