import React from 'react';
import styled from 'styled-components';
import { DefaultBadgeProps } from './CalendarTypes';
import moment from 'moment';

interface ContainerProps {
  $showSundays: boolean;
  $badgeMargin: number;
  $isSameMonth: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% * (1 / ${(props) => (props.$showSundays ? 7 : 6)}) - ${(props) => props.$badgeMargin * 2}px);
  height: 200px;
  margin: ${(props) => props.$badgeMargin}px;
  cursor: pointer;
  perspective: 1500px;
  border: 1px solid ${(props) => props.theme.border.color};
  border-radius: ${(props) => props.theme.border.radius};
  opacity: ${(props) => (props.$isSameMonth ? 1 : 0.3)};

  .DayNumber {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    > span {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding: 10px 15px;
    }
  }

  .Content {
    display: flex;
    flex-grow: 1;
    background-color: #ffffff;
  }

  .WeekNumber {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 12px;
    padding: 10px 15px;
    color: ${(props) => props.theme.color.textGray};
  }

  &.Current {
    border: 2px solid ${(props) => props.theme.color.primaryColor};

    .DayNumber {
      > span {
        background-color: ${(props) => props.theme.color.primaryColor};
        color: #fff;
        border-bottom-left-radius: ${(props) => props.theme.border.radius};
      }
    }
  }
`;

/**
 * DefaultBadge
 * @param props
 * @constructor
 */
export default function DefaultBadge<T>(props: DefaultBadgeProps<T>) {
  const { selectedDay, showSundays, onPressDay, currentMonth, badgeMargin } = props;
  const [show, setShow] = React.useState<boolean>(false);

  React.useEffect(() => {
    const dayNumber = selectedDay.weekday();
    if (dayNumber === 6 && !showSundays) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [selectedDay, showSundays]);

  if (show) {
    return (
      <Container
        $showSundays={showSundays}
        $badgeMargin={badgeMargin}
        $isSameMonth={moment(selectedDay).isSame(currentMonth, 'month')}
        className={moment().isSame(selectedDay, 'day') ? 'Current' : ''}
        onClick={() => onPressDay(selectedDay)}
      >
        <div className="DayNumber">
          <span className="">{selectedDay.format('D')}</span>
        </div>
        <div className="Content"></div>
        <div className="WeekNumber">{`KW ${selectedDay.format('WW')}`}</div>
      </Container>
    );
  }
  return null;
}
