import styled from 'styled-components';

const InfoWindowContainer = styled.div<{
  focused?: boolean;
  inactive?: boolean;
  withoutPinOffset?: boolean;
  onClick?: () => void;
  width: number;
}>`
  position: absolute;
  display: flex;
  width: ${(props) => props.width}px;
  transform: ${(props) => (props.withoutPinOffset ? 'translate(-50%,-0%)' : 'translate(-50%,-135%)')};
  border: ${(props) => (props.focused ? `2px solid ${props.theme.color.primaryColor}` : undefined)};
  border-radius: ${(props) => props.theme.border.radius};
  flex-direction: column;
  background-color: ${(props) => props.theme.color.backgroundGray};
  font-size: 14px;
  z-index: ${(props) => (props.focused ? 2000 : 0)};
  opacity: ${(props) => (props.inactive ? 0.6 : 1)};
  cursor: ${(props) => (props.onClick ? 'pointer' : undefined)};
  ${(props) => props.theme.boxShadowDark};

  .Title {
    padding: 8px 10px;
    background-color: ${(props) => props.theme.color.primaryColor};
    border-radius: ${(props) => props.theme.border.radius} ${(props) => props.theme.border.radius} 0 0;
    display: flex;
    align-items: center;
    align-content: center;
    font-weight: bolder;
    color: #ffffff;

    > i {
      margin-right: 10px;
    }
  }

  .Content {
    padding: 10px;
  }

  &:after {
    position: absolute;
    bottom: -19px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 20px;
    border-color: ${(props) => props.theme.color.backgroundGray} transparent transparent transparent;
    left: ${(props) => props.width / 2 - 20}px;
    content: '';
  }
`;

export default InfoWindowContainer;
