import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import Input, { InputType } from '../Inputs/Input';
import Text from '../Text';
import { ValidateDeliveryAndLoadingOptionsResult } from './Validators/ValidateDeliveryAndLoadingAddress';
import { LoadAndDeliveryAddress } from '../../Globals/Types/OrderTypes';
import SelectStorage from '../PredefinedSelects/SelectStorage';

interface Props {
  address: LoadAndDeliveryAddress;
  onChange: (value: LoadAndDeliveryAddress) => void;
  autoFocus: boolean;
  validationResult: ValidateDeliveryAndLoadingOptionsResult;
}

/**
 * DeliveryAddressForm()
 * @param props
 * @constructor
 */
export default function DeliveryAddressForm(props: Props) {
  const { address, onChange, autoFocus, validationResult } = props;
  const [t] = useTranslation();

  const handleOnChange = (key: string, value: string) => {
    onChange({ ...address, [key]: value });
  };

  return (
    <>
      <Row>
        <Col xl={12} style={{ marginBottom: 15 }}>
          <Text bold>{t('deliveryAddress')}</Text>
        </Col>
        <Col xl={12}>
          <SelectStorage onChange={(address) => onChange(address)} />
        </Col>
        <Col xl={9}>
          <Input
            value={address ? address.street : ''}
            onChange={(value) => handleOnChange('street', value)}
            label={t('street')}
            autoFocus={autoFocus}
            hasError={validationResult ? !!validationResult.errors.deliveryAddress.street : false}
            required
          />
        </Col>
        <Col xl={3}>
          <Input
            value={address ? address.streetNo : ''}
            onChange={(value) => handleOnChange('streetNo', value)}
            label={t('streetNo')}
            hasError={validationResult ? !!validationResult.errors.deliveryAddress.streetNo : false}
            required
          />
        </Col>
        <Col xl={3}>
          <Input
            value={address ? address.zip : ''}
            onChange={(value) => handleOnChange('zip', value)}
            label={t('zip')}
            hasError={validationResult ? !!validationResult.errors.deliveryAddress.zip : false}
            required
          />
        </Col>
        <Col xl={9}>
          <Input
            value={address ? address.city : ''}
            onChange={(value) => handleOnChange('city', value)}
            label={t('city')}
            hasError={validationResult ? !!validationResult.errors.deliveryAddress.city : false}
            required
          />
        </Col>
        <Col xl={6}>
          <Input
            value={address ? address.contactName : ''}
            onChange={(value) => handleOnChange('contactName', value)}
            label={t('contactPerson')}
          />
        </Col>
        <Col xl={6}>
          <Input
            value={address ? address.contactPhone : ''}
            onChange={(value) => handleOnChange('contactPhone', value)}
            label={t('contactPhone')}
          />
        </Col>
        <Col xl={12}>
          <Input
            type={InputType.textarea}
            rows={2}
            value={address ? address.comment : ''}
            onChange={(value) => handleOnChange('comment', value)}
            label={t('hint')}
          />
        </Col>
      </Row>
    </>
  );
}

DeliveryAddressForm.defaultProps = {
  autoFocus: true,
  address: {},
};
