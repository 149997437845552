import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { DraggableTourData, Tour, TourItem, TourService } from '../../../../Globals/Types/Tour';
import { DispositionListContext } from '../index';
import ServiceOverlay from './ServiceOverlay';
import DropZoneBox from './DropZoneBox';
import { ElasticSearchServiceEntity } from '../../../../Globals/Types/OrderTypes';

const BoxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const HourBox = styled.div<{ width: number }>`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: auto;
  min-width: ${(props) => props.width}px;
  border-right: 1px solid ${(props) => props.theme.border.color};
`;

interface Props {
  showHours: boolean;
  tourItem?: TourItem<any>[];
  tour: Tour;
  onMoveService: (newStartTime: string, droppedData: DraggableTourData) => void;
  onAddService: (newStartTime: string, droppedData: ElasticSearchServiceEntity) => void;
  onPressTourItem: (item: TourService) => void;
  selectedTourItem: TourItem<TourService> | null;
}

/**
 * Timeline()
 * @param props
 * @constructor
 */
export default function Timeline(props: Props) {
  const { showHours, tourItem, tour, onMoveService, onPressTourItem, onAddService, selectedTourItem } = props;
  const context = React.useContext(DispositionListContext);

  const renderBoxes = () => {
    if (showHours) {
      let result = [];
      for (let i = 0; i < context.hours; i++) {
        result.push(
          <HourBox width={context.hourBoxWidth} key={i}>
            {moment(context.startTime, 'HH:mm').add(i, 'h').format('H')}
          </HourBox>,
        );
      }
      return result;
    }
    return null;
  };

  const renderDropZones = () => {
    if (!showHours) {
      let result = [];
      for (let i = 0; i < context.hours; i++) {
        for (let j = 0; j < 60; j += context.hourInterval) {
          result.push(
            <DropZoneBox
              hour={parseInt(moment(context.startTime, 'HH:mm').add(i, 'h').format('H'))}
              minute={j}
              tour={tour}
              onMoveService={onMoveService}
              onAddService={onAddService}
              key={`${i}-${j}`}
              borderRightStyle={j < 45 ? 'dotted' : 'solid'}
            />,
          );
        }
      }
      return result;
    }
    return null;
  };

  const renderServices = () => {
    if (tourItem) {
      return tourItem.map((item) => (
        <ServiceOverlay
          tourItem={item}
          tour={tour}
          onPress={onPressTourItem}
          key={item.startTime}
          selected={selectedTourItem && selectedTourItem.data.serviceId === item.data.serviceId}
        />
      ));
    }
    return null;
  };

  return (
    <BoxContainer>
      {renderBoxes()}
      {renderDropZones()}
      {renderServices()}
    </BoxContainer>
  );
}

Timeline.defaultProps = {
  showHours: false,
};
