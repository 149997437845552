import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PageHeader from '../../../Components/PageHeader';
import Panel from '../../../Components/Panel';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { useDispatchResourceGetList } from '../../../Redux/Actions/Client/ResourceAction';
import Table, { TableFieldProps } from '../../../Components/Table/Table';
import ResourcesFacilitiesCol from '../../../Components/Table/CustomCols/ResourcesFacilitiesCol';
import { buildUrlSettingsResourcesCreateUpdate } from '../../../Globals/UrlFunctions';
import ResourcesTypeCol from '../../../Components/Table/CustomCols/ResourcesTypeCol';
import { ResourceEntity } from '../../../Globals/Types/Types';

const tableMappingsWithoutFacilities: TableFieldProps<ResourceEntity>[] = [
  { key: 'name', headline: 'name' },
  { key: 'type', headline: 'type', customColElement: ResourcesTypeCol },
];

const tableMappingsWithFacilities: TableFieldProps<ResourceEntity>[] = [
  ...tableMappingsWithoutFacilities,
  { key: 'facilityIds', headline: 'facilities', customColElement: ResourcesFacilitiesCol },
];

/**
 * Overview
 * @constructor
 */
export default function Overview() {
  const { setLoading, isLoading } = useLoadingModal();
  const { facilities, resources } = useAppSelector((state) => state.client);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatchResourceGetList = useDispatchResourceGetList();

  React.useEffect(() => {
    if (!resources || resources.length === 0) {
      setLoading(true);
    }
  }, [resources, setLoading]);

  React.useEffect(() => {
    dispatchResourceGetList().finally(() => setLoading(false));
  }, [dispatchResourceGetList, setLoading]);

  const renderAddButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => navigate(buildUrlSettingsResourcesCreateUpdate('create'))}>
        <i className="fas fa-plus-circle" />
      </Button>
    );
  };

  return (
    <>
      <PageHeader headline={t('modules.settings.resources.management')} actionButtonOne={renderAddButton()} />
      <Panel>
        <Table
          mappings={facilities.length > 1 ? tableMappingsWithFacilities : tableMappingsWithoutFacilities}
          data={resources}
          keyField="resourceId"
          isLoading={isLoading}
          onClickRow={(item) => navigate(buildUrlSettingsResourcesCreateUpdate(item.resourceId))}
        />
      </Panel>
    </>
  );
}
