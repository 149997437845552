import React from 'react';
import {
  ElasticSearchServiceDispositionInterface,
  OrderEntity,
  ServiceEntity,
} from '../../../Globals/Types/OrderTypes';
import { initialState, reducer } from './Reducer';
import PageHeader from '../../PageHeader';
import { useTranslation } from 'react-i18next';
import Panel from '../../Panel';
import AddressForm from './AddressForm';
import { Button, Col, Row } from 'react-bootstrap';
import DatePicker from '../../Inputs/DatePicker';
import moment from 'moment';
import SelectPaymentType from '../../PredefinedSelects/SelectPaymentType';
import PositionForm from './PositionsForm';
import Summary from './Summary';
import SelectFacility from '../../PredefinedSelects/SelectFacility';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { useBuildInvoiceNumber } from '../../../Globals/Functions/InvoiceFunctions';
import Input from '../../Inputs/Input';
import { useDispatchInvoiceCreate } from '../../../Redux/Actions/Client/InvoiceAction';
import { validateInvoice, ValidateInvoiceErrorInterface } from './ValidateInvoice';
import { useNavigate } from 'react-router';
import QuestionYesNoMessage from '../../Modals/QuestionYesNoMessage';
import { CustomerEntity } from '../../../Globals/Types/Customer';
import AskSaveChanges from '../../Modals/AskSaveChanges';
import SelectYesNo from '../../PredefinedSelects/SelectYesNo';
import { FacilityEntity, GroupKey } from '../../../Globals/Types/Types';
import { Salutation } from '../../../Globals/Types/Enums';
import { InvoiceEntity } from '../../../Globals/Types/Invoice';
import SelectAccessCode from '../../PredefinedSelects/SelectAccessCode';
import ShowHideContainer from '../../ShowHideContainer';

interface Props {
  customer?: CustomerEntity;
  service?: ServiceEntity;
  order?: OrderEntity;
  onFinish?: (invoice: InvoiceEntity) => void;
  disableNavigateBack?: boolean;
  voucherInvoice?: InvoiceEntity;
  dispositions?: ElasticSearchServiceDispositionInterface[];
}

/**
 * CreateInvoice
 * @param props
 * @constructor
 */
export default function CreateInvoice(props: Props) {
  const { customer, service, order, disableNavigateBack, onFinish, voucherInvoice, dispositions } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { setLoading, loadingModal } = useLoadingModal();
  const [t] = useTranslation();
  const { facilities } = useAppSelector((state) => state.client);
  const { groups: userGroups } = useAppSelector((state) => state.auth.user);
  const [invoiceNumber, setInvoiceNumber] = React.useState<string>(null);
  const [askSave, setAskSave] = React.useState<boolean>(false);
  const [askCancel, setAskCancel] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ValidateInvoiceErrorInterface>({});
  const buildInvoiceNumber = useBuildInvoiceNumber();
  const { user } = useAppSelector((state) => state.auth);
  const [facility, setFacility] = React.useState<FacilityEntity>(null);
  const dispatchCreate = useDispatchInvoiceCreate();

  const handleCreate = React.useCallback(() => {
    setAskSave(false);
    setErrors({});
    const validationResult = validateInvoice(state.invoice);

    if (validationResult.isValid) {
      const { nextInvoiceNumber, preparedInvoiceNumber } = buildInvoiceNumber(
        state.invoice.facilityId,
        parseFloat(state.invoice.priceOverall) < 0,
      );
      setLoading(true);
      dispatchCreate({ ...state.invoice, invoiceNumber: preparedInvoiceNumber }, nextInvoiceNumber)
        .then((createdResponse) => {
          if (onFinish) {
            onFinish(createdResponse);
          }
          if (!disableNavigateBack) {
            navigate(-1);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setErrors(validationResult.errors);
    }
  }, [buildInvoiceNumber, disableNavigateBack, dispatchCreate, navigate, onFinish, setLoading, state.invoice]);

  const handleAskSave = React.useCallback(() => {
    setErrors({});
    const validationResult = validateInvoice(state.invoice);

    if (validationResult.isValid) {
      setAskSave(true);
    } else {
      setErrors(validationResult.errors);
    }
  }, [state.invoice]);

  const handleCancel = React.useCallback(() => {
    if (state.hasChanges) {
      setAskCancel(true);
    } else {
      navigate(-1);
    }
  }, [navigate, state.hasChanges]);

  React.useEffect(() => {
    if (state.invoice.facilityId) {
      const found = facilities.find((item) => item.facilityId === state.invoice.facilityId);
      setFacility(found);
    }
  }, [facilities, state.invoice.facilityId]);

  React.useEffect(() => {
    const { preparedInvoiceNumber } = buildInvoiceNumber(
      state.invoice.facilityId,
      parseFloat(state.invoice.priceOverall) < 0,
    );
    setInvoiceNumber(preparedInvoiceNumber);
  }, [buildInvoiceNumber, state.invoice.facilityId, state.invoice.priceOverall]);

  React.useEffect(() => {
    if (customer) {
      dispatch({ type: 'initCustomer', payload: customer });
    } else if (order && service) {
      dispatch({ type: 'initService', payload: { order, service } });
    } else if (order && !service) {
      dispatch({ type: 'initOrder', payload: { order, dispositions } });
    } else if (voucherInvoice) {
      dispatch({ type: 'initVoucherInvoice', payload: voucherInvoice });
    } else {
      dispatch({ type: 'facilityId', payload: user.facilityId });
    }
  }, [customer, dispositions, order, service, user.facilityId, voucherInvoice]);

  const renderFacilities = () => {
    if (facilities.length > 1) {
      return (
        <Col xxl={6}>
          <SelectFacility
            onChange={(value) => dispatch({ type: 'facilityId', payload: value })}
            selected={state.invoice.facilityId}
          />
        </Col>
      );
    }
    return null;
  };

  const renderTaxFree = () => {
    if (
      facility &&
      facility.countryCode !== state.invoice.address.countryCode &&
      state.invoice.address.salutation === Salutation.company
    ) {
      return (
        <Col xxl={6}>
          <SelectYesNo
            label={t('modules.invoice.isTaxFree')}
            onChange={(value) => dispatch({ type: 'isTaxFree', payload: value })}
            initialValue={state.invoice.isTaxFree}
          />
        </Col>
      );
    }

    return null;
  };

  const renderReferenceInvoiceNumber = () => {
    if (state.invoice.isVoucher) {
      return (
        <Col xxl={6}>
          <Input
            label={t('modules.invoice.referenceInvoiceNumber')}
            value={state.invoice.referenceInvoiceNumber}
            onChange={(value) => dispatch({ type: 'referenceInvoiceNumber', payload: value })}
            required
            hasError={errors && !!errors.referenceInvoiceNumber}
          />
        </Col>
      );
    }

    return null;
  };

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={handleAskSave} disabled={!state.hasChanges}>
        {t('save')}
      </Button>
    );
  };

  const renderCancelButton = () => {
    return (
      <Button variant="outline-secondary" onClick={handleCancel}>
        {t('cancel')}
      </Button>
    );
  };

  return (
    <>
      <PageHeader
        headline={t('invoiceCreate')}
        description={`${t('invoiceNumber')}: ${invoiceNumber}`}
        actionButtonOne={renderSaveButton()}
        actionButtonTwo={renderCancelButton()}
      />

      <Panel headline={t('invoiceHeader')}>
        <Row style={{ marginBottom: 40 }}>
          <Col xxl={12}>
            <Input onChange={() => {}} label={t('invoiceNumber')} value={invoiceNumber} disabled />
          </Col>
        </Row>

        <AddressForm
          state={state}
          onChange={(value) => dispatch({ type: 'address', payload: value })}
          errors={errors.address}
        />

        <Row style={{ marginTop: 40 }}>
          <Col xxl={6}>
            <DatePicker
              onChange={(value) => dispatch({ type: 'executionDate', payload: value })}
              label={t('invoiceExecutionDate')}
              initialValue={moment(state.invoice.executionDate).toDate()}
            />
          </Col>
          <Col xxl={6}>
            <DatePicker
              onChange={(value) => dispatch({ type: 'dueDate', payload: value })}
              label={t('dueDate')}
              initialValue={moment(state.invoice.dueDate).toDate()}
            />
          </Col>
          <Col xxl={6}>
            <SelectPaymentType
              onChange={(value) => dispatch({ type: 'paymentType', payload: value })}
              selected={state.invoice.paymentType}
              hideDebit={state.invoice.isVoucher}
            />
          </Col>
          {renderFacilities()}
          {renderTaxFree()}
          {renderReferenceInvoiceNumber()}

          <ShowHideContainer visible={userGroups.indexOf(GroupKey.ADMIN) > -1}>
            <Col xxl={6}>
              <SelectAccessCode
                initialValue={state.invoice.accessCode}
                onChange={(value) => dispatch({ type: 'accessCode', payload: value })}
                disabled={false}
                showSkeleton={false}
              />
            </Col>
          </ShowHideContainer>
        </Row>
      </Panel>

      <PositionForm
        positions={state.invoice.positions}
        onChange={(value) => dispatch({ type: 'positions', payload: value })}
        hasError={!!errors.positions}
      />

      <Summary invoice={state.invoice} />

      <Panel>
        <Row>
          <Col xxl={12} className="flex-row d-flex justify-content-end">
            <div style={{ marginRight: 10 }}>{renderSaveButton()}</div>
            <div>{renderCancelButton()}</div>
          </Col>
        </Row>
      </Panel>

      <QuestionYesNoMessage
        headline={t('modules.invoice.askCreateHeadline')}
        description={t('modules.invoice.askCreateDescription')}
        confirmCaption={t('invoiceCreate')}
        visible={askSave}
        onCancel={() => setAskSave(false)}
        onSuccess={handleCreate}
      />

      <AskSaveChanges visible={askCancel} onCancel={() => setAskCancel(false)} onLeave={() => navigate(-1)} />

      {loadingModal}
    </>
  );
}

CreateInvoice.defaultProps = {
  disableNavigateBack: false,
};
