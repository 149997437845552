import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.theme.border.radius};
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 20px;
  ${(props) => props.theme.boxShadowDark};
  cursor: pointer;

  &:hover {
    ${(props) => props.theme.boxShadowDarkHover};
  }

  img {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    padding: 10px;
    object-fit: cover;
    overflow: hidden;
  }
`;

interface Props {
  image: string;
  size: number;
  onClick: (image: string) => void;
}

/**
 * Item()
 * @param props
 * @constructor
 */
export default function Item(props: Props) {
  const { size, image, onClick } = props;

  return (
    <Container size={size} onClick={() => onClick(image)}>
      <img src={image} alt={image} />
    </Container>
  );
}
