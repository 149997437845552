import { AbsenceEntity } from '../../../Globals/Types/Types';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import React from 'react';
import {
  USER_ABSENCES_GETLIST_ERROR,
  USER_ABSENCES_GETLIST_START,
  USER_ABSENCES_GETLIST_SUCCESS,
} from '../../ActionTypes';
import { collection, CollectionReference, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { FirebasePathMappings } from '../../../Globals/FirebaseGlobals';

/**
 * useDispatchUserAbsenceGetList()
 */
type GetListReturnType = (startDate: Date, endDate: Date) => Promise<Array<AbsenceEntity>>;
export const useDispatchUserAbsenceGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId, userId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<GetListReturnType>(
    (startDate, endDate) => {
      dispatch({ type: USER_ABSENCES_GETLIST_START });

      const absenceCollection = collection(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.absences,
      ) as CollectionReference<AbsenceEntity>;
      const queryRef = query(
        absenceCollection,
        where('startDate', '>=', startDate),
        where('startDate', '<=', endDate),
        where('userId', '==', userId),
      );

      return getDocs(queryRef)
        .then((snapShot) => {
          const absences: Array<AbsenceEntity> = [];
          if (!snapShot.empty) {
            snapShot.forEach((absence) => {
              absences.push({ ...absence.data(), absenceId: absence.id });
            });
          }
          dispatch({ type: USER_ABSENCES_GETLIST_SUCCESS, payload: absences });
          return Promise.resolve(absences);
        })
        .catch((error) => {
          dispatch({ type: USER_ABSENCES_GETLIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, userId],
  );
};
