import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Input, { InputType } from '../../../../Components/Inputs/Input';
import { useTranslation } from 'react-i18next';
import { useDispatchSendMail } from '../../../../Redux/Actions/Client/FacilityAction';
import { isValidateEmail } from '../../../../Globals/Functions';
import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import ErrorMessage from '../../../../Components/Modals/ErrorMessage';
import styled from 'styled-components';
import { useLoadingModal } from '../../../../Globals/Hooks/Hooks';

const ContainerEmail = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

const ContainerPin = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .IconSpin {
    padding-left: 10px;
    font-size: 28px;
    margin-left: 8px;
  }

  .IconSuccess {
    padding-left: 10px;
    font-size: 28px;
    color: ${(props) => props.theme.color.green};
  }
`;

interface State {
  name: string;
  domain: string;
  errorEmail: InternalErrorCodes;
  errorPin: boolean;
  errorSending: Error;
  pinEntered: string;
  pinGenerated: string;
  success: boolean;
}

type Action =
  | { type: 'init'; payload: { domain: string; name: string } }
  | { type: 'name'; payload: string }
  | { type: 'errorEmail'; payload: InternalErrorCodes }
  | { type: 'errorPin'; payload: boolean }
  | { type: 'errorSending'; payload: Error }
  | { type: 'pinEntered'; payload: string }
  | { type: 'pinGenerated'; payload: string }
  | { type: 'success' }
  | { type: 'clear' };

const initialState: State = {
  domain: '',
  name: '',
  errorEmail: null,
  errorPin: false,
  errorSending: null,
  pinEntered: '',
  pinGenerated: null,
  success: false,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'clear':
      return {
        ...initialState,
        domain: state.domain,
        name: state.name,
      };

    case 'init':
      return {
        ...state,
        domain: action.payload.domain,
        name: action.payload.name,
      };

    case 'name':
      return {
        ...state,
        name: action.payload,
      };

    case 'pinEntered':
      return {
        ...state,
        pinEntered: action.payload,
      };

    case 'pinGenerated':
      return {
        ...state,
        pinGenerated: action.payload,
      };

    case 'errorEmail':
      return {
        ...state,
        errorEmail: action.payload,
      };

    case 'errorPin':
      return {
        ...state,
        errorPin: action.payload,
      };

    case 'errorSending':
      return {
        ...state,
        errorSending: action.payload,
      };

    case 'success':
      return {
        ...state,
        success: true,
      };
  }
};

interface Props {
  initialEmail: string;
  onClose: (success: boolean) => void;
  visible: boolean;
  facilityId: string;
}

/**
 * EmailTestModal
 * @param props
 * @constructor
 */
export default function EmailValidationModal(props: Props) {
  const { initialEmail, onClose, visible, facilityId } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [t] = useTranslation();
  const { setLoading, isLoading, loadingModal } = useLoadingModal({ headline: t('sendingValidationMail') });

  const dispatchSendMail = useDispatchSendMail();

  React.useEffect(() => {
    if (initialEmail) {
      const emailSplit: string[] = initialEmail.split('@');
      dispatch({ type: 'init', payload: { name: emailSplit[0], domain: `@${emailSplit[1]}` } });
    }
  }, [initialEmail, dispatch]);

  const handleClose = (success: boolean) => {
    dispatch({ type: 'clear' });
    onClose(success);
  };

  const onPressSend = () => {
    const email = `${state.name}${state.domain}`;

    if (!state.name || state.name.length === 0) {
      return dispatch({ type: 'errorEmail', payload: InternalErrorCodes.FIELD_MISSING });
    } else if (!isValidateEmail(email)) {
      return dispatch({ type: 'errorEmail', payload: InternalErrorCodes.MAIL_INVALID });
    }

    setLoading(true);

    const pinTemp = Math.floor(Math.random() * 1000000).toString();
    dispatchSendMail(
      facilityId,
      email,
      t('modules.settings.facility.emailValidationSubject'),
      t('modules.settings.facility.emailValidationText', { pin: pinTemp }),
      true,
    )
      .then(() => {
        dispatch({ type: 'pinGenerated', payload: pinTemp });
      })
      .catch((error) => dispatch({ type: 'errorSending', payload: error }))
      .finally(() => setLoading(false));
  };

  const onPressValidate = () => {
    if (state.pinEntered !== state.pinGenerated) {
      return dispatch({ type: 'errorPin', payload: true });
    }

    dispatch({ type: 'success' });
    setTimeout(() => handleClose(true), 100);
  };

  const renderOkButton = () => {
    if (state.pinGenerated) {
      return (
        <Button variant="success" onClick={onPressValidate} disabled={isLoading}>
          {t('validate')}
        </Button>
      );
    }

    return (
      <Button variant="success" onClick={onPressSend} disabled={isLoading}>
        {t('send')}
      </Button>
    );
  };

  const renderPin = () => {
    if (state.pinGenerated) {
      let iconClassName = 'far fa-input-numeric fa-beat IconSpin';
      if (state.success) {
        iconClassName = 'fas fa-check IconSuccess';
      }

      return (
        <>
          <div style={{ marginBottom: 8 }}>{t('modules.settings.facility.emailValidationPinDescription')}</div>
          <ContainerPin>
            <Input
              label={t('validateCode')}
              value={state.pinEntered}
              type={InputType.number}
              onChange={(value) => dispatch({ type: 'pinEntered', payload: value })}
              hasError={state.errorPin}
              disabled={isLoading}
              autoFocus
              removeBottomMargin
            />
            <i className={iconClassName} />
          </ContainerPin>
        </>
      );
    }

    return null;
  };

  return (
    <Modal show={visible} size="lg" animation>
      <Modal.Header>
        <div>
          <Modal.Title>
            <i className="fas fa-check-circle" style={{ marginRight: 10 }} />
            {t('modules.settings.facility.emailValidationHeadline')}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body style={{ padding: 20 }}>
        <div>{t('modules.settings.facility.emailValidationDescription')}</div>
        <br />
        <div>{t('modules.settings.facility.emailValidationReceiverDescription')}</div>
        <br />
        <ContainerEmail>
          <Input
            label={t('name')}
            value={state.name}
            onChange={(value) => dispatch({ type: 'name', payload: value })}
            hasError={!!state.errorEmail}
            disabled={!!state.pinGenerated || isLoading}
            autoFocus
          />
          <Input label={t('domain')} value={state.domain} onChange={() => {}} style={{ marginLeft: 8 }} disabled />
        </ContainerEmail>
        {renderPin()}
      </Modal.Body>
      <Modal.Footer>
        {renderOkButton()}
        <Button variant="outline-secondary" onClick={() => handleClose(false)} disabled={isLoading}>
          {t('cancel')}
        </Button>
      </Modal.Footer>

      <ErrorMessage
        onClose={() => dispatch({ type: 'errorSending', payload: null })}
        visible={!!state.errorSending}
        headline={t('modules.settings.facility.emailValidationErrorHeadline')}
        description={t('modules.settings.facility.emailValidationErrorDescription', {
          message: decodeURI(state.errorSending?.message),
        })}
      />
      {loadingModal}
    </Modal>
  );
}
