import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

interface StyledProps {
  size: number;
  bgColor?: string;
}

const Container = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size / 2}px;
  background-color: ${(props) => props.bgColor || props.theme.color.background};
  border: 1px solid ${(props) => darken(0.04, props.theme.color.backgroundGray)};

  > i {
    font-size: ${(props) => props.size * 0.5}px;
    margin-left: 4px;
  }
`;

interface Props {
  size?: number;
  text?: string;
  icon?: string;
  imageUri?: string;
  bgColor?: string;
}

/**
 * ProfileCircle
 * @param props
 * @constructor
 */
export default function ProfileCircle(props: Props) {
  const { size, text, icon } = props;

  const renderIcon = () => {
    if (icon) {
      return <i className={icon} />;
    }
    return null;
  };

  const renderText = () => {
    if (text) {
      return <span>{text}</span>;
    }
    return null;
  };

  return (
    <Container size={size}>
      {renderIcon()}
      {renderText()}
    </Container>
  );
}

ProfileCircle.defaultProps = {
  size: 50,
};
