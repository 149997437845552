import { DefaultValidationResult, MessageEntity } from '../../../Globals/Types/Types';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';

export type ValidateCreateMessageErrorType = {
  subject?: string;
  message?: string;
  receivers?: string;
};

/**
 * isValid()
 * @param message
 */
const isValid = (message: MessageEntity): DefaultValidationResult<ValidateCreateMessageErrorType> => {
  let errors: ValidateCreateMessageErrorType = {};

  if (!message.subject || message.subject.length <= 0) {
    errors = { ...errors, subject: InternalErrorCodes.FIELD_MISSING };
  }
  if (!message.receivers || message.receivers.length <= 0) {
    errors = { ...errors, receivers: InternalErrorCodes.FIELD_MISSING };
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export default isValid;
