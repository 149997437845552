/**
 * HeyKitchenError
 */
export class HeyKitchenError extends Error {
  details: string | Object | undefined = undefined;

  /**
   * constructor()
   * @param message
   * @param details
   */
  constructor(message: string, details: string | Object) {
    super(message);
    this.name = this.constructor.name;
    this.details = details;
  }
}
