import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import DayContainer from './DayContainer';
import { DispositionReducerByDate } from '../../../Redux/Reducer/DispositionReducer';
import { lighten } from 'polished';
import { Tour, TourService } from '../../../Globals/Types/Tour';

const Container = styled.div`
  display: flex;
  flex-direction: row;

  .Caption {
    display: flex;
    align-items: center;
    width: 20%;
    font-size: 16px;
    min-width: 200px;
    border-right: 6px solid ${(props) => props.theme.border.color};
    border-bottom: 1px solid ${(props) => props.theme.border.color};
    padding-left: 15px;
    background-color: ${(props) => lighten(0.04, props.theme.table.highlight)};
  }
`;

interface Props {
  tourName: string;
  startDate: string;
  endDate: string;
  tours: DispositionReducerByDate[];
  onPressService: (tour: Tour, tourService: TourService) => void;
  selectedService: TourService;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { tourName, startDate, endDate, tours, onPressService, selectedService } = props;

  const getData = (date: string): Tour[] => {
    const found = tours.find((item) => item.date === date);
    return found ? found.data : [];
  };

  const renderDays = () => {
    const days: React.ReactElement[] = [];
    let currentDate = startDate;

    while (moment(currentDate).isSameOrBefore(moment(endDate))) {
      const toursFiltered: Tour[] = getData(currentDate);
      const tour: Tour = toursFiltered.find((item) => item.name === tourName);
      days.push(
        <DayContainer
          tours={toursFiltered}
          name={tourName}
          key={`${tourName}-${currentDate}`}
          onPress={(tourService) => onPressService(tour, tourService)}
          selectedService={selectedService}
        />,
      );
      currentDate = moment(currentDate).add(1, 'day').format('YYYY-MM-DD');
    }

    return days;
  };

  return (
    <Container>
      <div className="Caption">{tourName}</div>
      {renderDays()}
    </Container>
  );
}
