import { ServiceFilter } from '../../Globals/Types/Types';
import moment from 'moment';
import React from 'react';
import { useDispatchOrderFilterSet } from '../../Redux/Actions/Order/FilterAction';

export enum PredefinedFilters {
  ordersToday,
  ordersTomorrow,
  openOrders,
}

/**
 * useSetPredefinedFilter()
 * Set a predefined filter for dashboard panels
 */
export const useSetPredefinedFilter = () => {
  const setFilter = useDispatchOrderFilterSet(false);

  return React.useCallback(
    (type: PredefinedFilters) => {
      switch (type) {
        case PredefinedFilters.ordersToday:
          setFilter(buildTodayOrders());
          break;
        case PredefinedFilters.ordersTomorrow:
          setFilter(buildTomorrowOrders());
          break;
        case PredefinedFilters.openOrders:
          setFilter(buildOpenOrders());
          break;
      }
    },
    [setFilter],
  );
};

/**
 * buildTodayOrders()
 */
const buildTodayOrders = (): ServiceFilter => {
  return {
    dateFrom: moment().format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
    serviceTypes: [],
    hideUnscheduled: true,
    finishedOnly: false,
    openOnly: false,
    hideScheduled: false,
    unsuccessfulOnly: false,
  };
};

/**
 * buildTomorrowOrders()
 */
const buildTomorrowOrders = (): ServiceFilter => {
  return {
    dateFrom: moment().add(1, 'd').format('YYYY-MM-DD'),
    dateTo: moment().add(1, 'd').format('YYYY-MM-DD'),
    serviceTypes: [],
    hideUnscheduled: true,
    finishedOnly: false,
    openOnly: false,
    hideScheduled: false,
    unsuccessfulOnly: false,
  };
};

/**
 * buildTomorrowOrders()
 */
const buildOpenOrders = (): ServiceFilter => {
  return {
    dateFrom: moment().subtract(90, 'd').format('YYYY-MM-DD'),
    dateTo: moment().add(90, 'd').format('YYYY-MM-DD'),
    serviceTypes: [],
    hideUnscheduled: false,
    hideScheduled: true,
    finishedOnly: false,
    openOnly: true,
    unsuccessfulOnly: false,
  };
};
