import React from 'react';
import { useTranslation } from 'react-i18next';
import { StorageAddressEntity } from '../../../../Globals/Types/Types';
import { useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import { Button, Col, Row } from 'react-bootstrap';
import Input, { InputType } from '../../../../Components/Inputs/Input';
import ShowHideContainer from '../../../../Components/ShowHideContainer';
import AskDeleteModal from '../../../../Components/Modals/AskDeleteModal';
import validateUpdateOrCreate from './ValidateUpdateOrCreate';
import {
  useDispatchStorageAddressCreate,
  useDispatchStorageAddressDelete,
  useDispatchStorageAddressGet,
  useDispatchStorageAddressUpdate,
} from '../../../../Redux/Actions/Client/Storage/AddressAction';
import Select from '../../../../Components/Inputs/Select';
import { selectOptionYesNo } from '../../../../Globals/Types/General';
import { useNavigate, useParams } from 'react-router';
import PageHeader from '../../../../Components/PageHeader';
import Panel from '../../../../Components/Panel';
import BottomInternalIdContainer from '../../../../Components/BottomInternalIdContainer';
import { UrlPatterns } from '../../../../Globals/UrlFunctions';

export type CreateOrUpdateError = StorageAddressEntity;

export type State = {
  address: StorageAddressEntity;
  isEdit: boolean;
  hasChanges: Boolean;
  errors: CreateOrUpdateError;
};

type Action =
  | { type: 'name'; payload: string }
  | { type: 'zip'; payload: string }
  | { type: 'city'; payload: string }
  | { type: 'street'; payload: string }
  | { type: 'streetNo'; payload: string }
  | { type: 'comment'; payload: string }
  | { type: 'contactPhone'; payload: string }
  | { type: 'contactName'; payload: string }
  | { type: 'gln'; payload: string }
  | { type: 'allowExternalStorage'; payload: boolean }
  | { type: 'init'; payload: StorageAddressEntity }
  | { type: 'setErrors'; payload: CreateOrUpdateError }
  | { type: 'clear' };

const initialState: State = {
  address: {
    allowExternalStorage: false,
  } as StorageAddressEntity,
  errors: {} as CreateOrUpdateError,
  isEdit: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'name':
      return {
        ...state,
        address: { ...state.address, name: action.payload },
        hasChanges: true,
        errors: { ...state.errors, name: '' },
      };
    case 'zip':
      return {
        ...state,
        address: { ...state.address, zip: action.payload },
        hasChanges: true,
        errors: { ...state.errors, zip: '' },
      };
    case 'city':
      return {
        ...state,
        address: { ...state.address, city: action.payload },
        hasChanges: true,
        errors: { ...state.errors, city: '' },
      };
    case 'street':
      return {
        ...state,
        address: { ...state.address, street: action.payload },
        hasChanges: true,
        errors: { ...state.errors, street: '' },
      };
    case 'streetNo':
      return {
        ...state,
        address: { ...state.address, streetNo: action.payload },
        hasChanges: true,
        errors: { ...state.errors, streetNo: '' },
      };
    case 'comment':
      return { ...state, address: { ...state.address, comment: action.payload }, hasChanges: true };
    case 'contactPhone':
      return { ...state, address: { ...state.address, contactPhone: action.payload }, hasChanges: true };
    case 'contactName':
      return { ...state, address: { ...state.address, contactName: action.payload }, hasChanges: true };
    case 'gln':
      return { ...state, address: { ...state.address, gln: action.payload }, hasChanges: true };
    case 'allowExternalStorage':
      return { ...state, address: { ...state.address, allowExternalStorage: action.payload }, hasChanges: true };

    case 'setErrors': {
      return { ...state, errors: action.payload };
    }
    case 'clear': {
      return { ...initialState };
    }
    case 'init': {
      return {
        ...state,
        address: { ...state.address, ...action.payload },
        isEdit: true,
        hasChanges: false,
      };
    }
  }
};

/**
 * CreateOrUpdate()
 * @constructor
 */
export default function CreateOrUpdateModal() {
  const { storageId } = useParams();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [headline, setHeadline] = React.useState<string>(null);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { loadingModal, setLoading, isLoading } = useLoadingModal({ delayStart: 1000 });
  const dispatchCreate = useDispatchStorageAddressCreate();
  const dispatchDelete = useDispatchStorageAddressDelete();
  const dispatchGet = useDispatchStorageAddressGet();
  const dispatchUpdate = useDispatchStorageAddressUpdate();

  React.useEffect(() => {
    setHeadline(
      state.isEdit ? t('modules.settings.storage.address.update') : t('modules.settings.storage.address.create'),
    );
  }, [state.isEdit, t]);

  React.useEffect(() => {
    if (storageId !== 'create') {
      setLoading(true);

      dispatchGet(storageId)
        .then((response) => {
          dispatch({ type: 'init', payload: response });
        })
        .finally(() => setLoading(false));
    }
  }, [dispatchGet, setLoading, storageId]);

  const handleSave = () => {
    const { isValid, errors } = validateUpdateOrCreate(state.address);

    if (isValid) {
      setLoading(true);
      let promise;
      if (state.isEdit) {
        promise = dispatchUpdate(state.address);
      } else {
        promise = dispatchCreate(state.address);
      }

      promise
        .then(() => {
          navigate(UrlPatterns.settingsStorageAddress);
        })
        .catch(() => setLoading(false));
    } else {
      dispatch({ type: 'setErrors', payload: errors });
    }
  };

  const handleDelete = () => {
    setLoading(true);
    setAskDelete(false);
    dispatchDelete(state.address)
      .then(() => {
        navigate(UrlPatterns.settingsStorageAddress);
      })
      .catch(() => setLoading(false));
  };

  const renderCancelButton = () => {
    return (
      <Button
        variant="outline-secondary"
        onClick={() => navigate(UrlPatterns.settingsStorageAddress)}
        disabled={isLoading}
        style={{ marginRight: 10 }}
      >
        {t('cancel')}
      </Button>
    );
  };

  const renderSaveButton = () => {
    return (
      <Button variant="success" onClick={handleSave} disabled={isLoading || !state.hasChanges}>
        {t('save')}
      </Button>
    );
  };

  return (
    <>
      <PageHeader headline={headline} actionButtonOne={renderSaveButton()} actionButtonTwo={renderCancelButton()} />
      <Panel>
        <Row>
          <Col xl={12}>
            <Input
              label={t('name')}
              onChange={(value) => dispatch({ type: 'name', payload: value })}
              value={state.address.name}
              required
              disabled={isLoading}
              autoFocus
              hasError={!!state.errors.name}
            />
          </Col>
          <Col xl={9}>
            <Input
              label={t('street')}
              onChange={(value) => dispatch({ type: 'street', payload: value })}
              value={state.address.street}
              required
              disabled={isLoading}
              hasError={!!state.errors.street}
            />
          </Col>
          <Col xl={3}>
            <Input
              label={t('streetNo')}
              onChange={(value) => dispatch({ type: 'streetNo', payload: value })}
              value={state.address.streetNo}
              required
              disabled={isLoading}
              hasError={!!state.errors.streetNo}
            />
          </Col>
          <Col xl={3}>
            <Input
              label={t('zip')}
              onChange={(value) => dispatch({ type: 'zip', payload: value })}
              value={state.address.zip}
              required
              disabled={isLoading}
              hasError={!!state.errors.zip}
            />
          </Col>
          <Col xl={9}>
            <Input
              label={t('city')}
              onChange={(value) => dispatch({ type: 'city', payload: value })}
              value={state.address.city}
              required
              disabled={isLoading}
              hasError={!!state.errors.city}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('contactName')}
              onChange={(value) => dispatch({ type: 'contactName', payload: value })}
              value={state.address.contactName}
              disabled={isLoading}
              hasError={!!state.errors.contactName}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('contactPhone')}
              onChange={(value) => dispatch({ type: 'contactPhone', payload: value })}
              value={state.address.contactPhone}
              disabled={isLoading}
              hasError={!!state.errors.contactPhone}
            />
          </Col>
          <Col xl={6}>
            <Input
              label={t('gln')}
              onChange={(value) => dispatch({ type: 'gln', payload: value })}
              value={state.address.gln}
              disabled={isLoading}
              hasError={!!state.errors.gln}
            />
          </Col>
          <Col xl={6}>
            <Select
              options={selectOptionYesNo}
              label={t('allowExternalStorage')}
              onChange={(value) => dispatch({ type: 'allowExternalStorage', payload: value })}
              initialValue={state.address.allowExternalStorage}
              disabled={isLoading}
              contextHelpKey="allowExternalStorage"
            />
          </Col>
          <Col xl={12}>
            <Input
              label={t('additionalInformation')}
              onChange={(value) => dispatch({ type: 'comment', payload: value })}
              value={state.address.comment}
              disabled={isLoading}
              type={InputType.textarea}
              rows={4}
            />
          </Col>
        </Row>
      </Panel>

      <ShowHideContainer visible={state.isEdit}>
        <div style={{ textAlign: 'center', marginBottom: 30, marginTop: 30 }}>
          <Button variant="outline-danger" onClick={() => setAskDelete(true)} style={{ borderColor: 'transparent' }}>
            {t('delete')}
          </Button>
        </div>
      </ShowHideContainer>

      <ShowHideContainer visible={state.isEdit}>
        <BottomInternalIdContainer id={storageId} />
      </ShowHideContainer>

      {loadingModal}

      <AskDeleteModal onDelete={handleDelete} onClose={() => setAskDelete(false)} visible={askDelete} />
    </>
  );
}
