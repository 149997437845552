import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { CalendarDataType, DefaultBadgeProps } from '../CalendarTypes';
import { AbsenceEntity } from '../../../Globals/Types/Types';
import { useTranslation } from 'react-i18next';

interface ContainerProps {
  $showSundays: boolean;
  $isSameMonth: boolean;
  $badgeMargin: number;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% * (1 / ${(props) => (props.$showSundays ? 7 : 6)}) - ${(props) => props.$badgeMargin * 2}px);
  min-height: 200px;
  margin: ${(props) => props.$badgeMargin}px;
  cursor: pointer;
  perspective: 1500px;
  border: 1px solid ${(props) => props.theme.border.color};
  border-radius: ${(props) => props.theme.border.radius};
  opacity: ${(props) => (props.$isSameMonth ? 1 : 0.3)};

  .DayNumber {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;

    > span {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding: 10px 15px;
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #ffffff !important;

    .UserRow {
      padding: 4px 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: ${(props) => props.theme.color.primaryColor};
      margin-bottom: 2px;
      color: #fff;
      font-size: 15px;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .WeekNumber {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 12px;
    padding: 10px 15px;
    color: ${(props) => props.theme.color.textGray};
  }

  &.Current {
    border: 2px solid ${(props) => props.theme.color.primaryColor};

    .DayNumber {
      > span {
        background-color: ${(props) => props.theme.color.primaryColor};
        color: #fff;
        border-bottom-left-radius: ${(props) => props.theme.border.radius};
      }
    }
  }
`;

/**
 * UserAbsenceBadge
 * @param props
 * @constructor
 */
export default function UserAbsenceBadge<T>(props: DefaultBadgeProps<T>) {
  const { selectedDay, showSundays, onPressDay, currentMonth, data, onPressEntry, badgeMargin } = props;
  const [selectedData, setSelectedData] = React.useState<CalendarDataType<AbsenceEntity>>(null);
  const [show, setShow] = React.useState<boolean>(false);
  const [t] = useTranslation();

  React.useEffect(() => {
    const filtered = data.filter((item) => item.date.isSame(selectedDay, 'day'));
    if (filtered.length > 0) {
      setSelectedData(filtered[0] as unknown as CalendarDataType<AbsenceEntity>);
    } else {
      setSelectedData(null);
    }
  }, [data, selectedDay]);

  React.useEffect(() => {
    const dayNumber = selectedDay.weekday();
    if (dayNumber === 6 && !showSundays) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [selectedDay, showSundays]);

  const handleDetailsClick = (e: MouseEvent, item: AbsenceEntity) => {
    e.stopPropagation();
    onPressEntry(item as undefined);
  };

  const renderContent = () => {
    if (selectedData) {
      return selectedData.data.map((item) => {
        return (
          <div className="UserRow" key={item.id} onClick={(e: any) => handleDetailsClick(e, item.data)}>
            {t(item.data.type)}
          </div>
        );
      });
    }
    return null;
  };

  if (show) {
    return (
      <Container
        $showSundays={showSundays}
        $badgeMargin={badgeMargin}
        $isSameMonth={moment(selectedDay).isSame(currentMonth, 'month')}
        className={moment().isSame(selectedDay, 'day') ? 'Current' : ''}
        onClick={() => onPressDay(selectedDay)}
      >
        <div className="DayNumber">
          <span className="">{selectedDay.format('D')}</span>
        </div>
        <div className="Content">{renderContent()}</div>
        <div className="WeekNumber">{`KW ${selectedDay.format('WW')}`}</div>
      </Container>
    );
  }
  return null;
}
