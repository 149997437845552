import React from 'react';
import { InvoiceEntity } from '../../../../../Globals/Types/Invoice';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatchInvoiceUpdate } from '../../../../../Redux/Actions/Client/InvoiceAction';
import AskInvoicePayedMessage from '../../../../../Components/Invoice/AskInvoicePayedMessage';
import moment from 'moment';
import DropDownItem from '../../../../../Components/CustomDropDown/DropDownItem';

interface Props {
  invoice: InvoiceEntity;
  onUpdate: (invoice: InvoiceEntity) => void;
  onLoading: (value: boolean) => void;
}

/**
 * InvoicePayedButton()
 * @param props
 * @constructor
 */
export default function InvoicePayedButton(props: Props) {
  const { invoice, onUpdate, onLoading } = props;
  const [t] = useTranslation();
  const [askPayed, setAskPayed] = React.useState<boolean>(false);
  const dispatchUpdate = useDispatchInvoiceUpdate();

  const setPayed = React.useCallback(() => {
    onLoading(true);
    setAskPayed(false);

    dispatchUpdate({ ...invoice, payed: true, payedDate: moment().format('YYYY-MM-DD') })
      .then((response) => {
        onLoading(false);
        onUpdate(response);
      })
      .catch(() => onLoading(false));
  }, [dispatchUpdate, invoice, onLoading, onUpdate]);

  const renderButton = () => {
    return (
      <>
        <Dropdown.Divider />
        <DropDownItem onClick={() => setAskPayed(true)} key={'markPayed'}>
          <i className="fas fa-check-circle text-success" />
          {t('markPayed')}
        </DropDownItem>
      </>
    );
  };

  if (!invoice.payed) {
    return (
      <>
        {renderButton()}

        <AskInvoicePayedMessage
          visible={askPayed}
          onClose={() => setAskPayed(false)}
          onSuccess={setPayed}
          invoice={invoice}
        />
      </>
    );
  }
  return null;
}
