import React from 'react';
import { useAppSelector } from './Hooks';
import { CreateOrderState, OrderCustomerAddress, OrderServiceType } from '../Types/OrderTypes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Salutation } from '../Types/Enums';

/**
 * useGetServiceFacilityName()
 * Get the facility assigned to a service. Check if is partner or internal and return the facility
 * @param service
 */
export const useGetServiceFacilityName = (service): string => {
  const [facility, setFacility] = React.useState<string>(null);
  const { facilities, allPartners } = useAppSelector((state) => state.client);

  React.useEffect(() => {
    const foundInFacilities = facilities.find((item) => item.facilityId === service.ownerFacilityId);
    if (!foundInFacilities) {
      const foundInPartners = allPartners.find((item) => item.partnerFacilityId === service.ownerFacilityId);
      if (foundInPartners) {
        setFacility(`${foundInPartners.partnerName} (Partner)`);
      }
    } else {
      setFacility(foundInFacilities.name);
    }
  }, [allPartners, facilities, service.ownerFacilityId]);

  return facility;
};

/**
 * Get the service type icon for the client settings. If not available any more use the icon from the data in service
 * type saved
 * useGetServiceTypeIcon()
 */
export const useGetServiceTypeIcon = (): ((type: OrderServiceType) => string) => {
  const { serviceTypeDefinition } = useAppSelector((state) => state.auth.client.settings.order);

  return React.useCallback(
    (type: OrderServiceType) => {
      const find = serviceTypeDefinition.find((item) => item.key === type.key);
      return find.icon || type.icon;
    },
    [serviceTypeDefinition],
  );
};

/**
 * Generate externalId automatically if setting is set. If not return the state as it is
 */
type AutoBuildReturnType = (createState: CreateOrderState) => CreateOrderState;
export const useAutoBuildExternalId = (): AutoBuildReturnType => {
  const [t] = useTranslation();
  const { autoExternalId, prefixFacilityInitial } = useAppSelector((state) => state.auth.client.settings.order);
  const { facilities } = useAppSelector((state) => state.client);

  const buildName = (customer: OrderCustomerAddress) => {
    const { firstName, lastName } = customer;

    if (customer.salutation === Salutation.company) {
      return firstName;
    }
    return `${firstName.substring(0, 1).toUpperCase()}${lastName}`;
  };

  return React.useCallback<AutoBuildReturnType>(
    (createState: CreateOrderState) => {
      if (autoExternalId && !createState.order.externalId) {
        const week = t('weekNumberShort', { week: moment().format('WW') }).replace(' ', '');
        let generatedId = `${buildName(createState.order.customer)} ${week}`;

        if (prefixFacilityInitial) {
          const found = facilities.find((item) => item.facilityId === createState.order.createdFacilityId);
          if (found) {
            generatedId = found.nameShort + ' ' + generatedId;
          }
        }

        return { ...createState, order: { ...createState.order, externalId: generatedId } };
      }
      return createState;
    },
    [autoExternalId, facilities, prefixFacilityInitial, t],
  );
};
