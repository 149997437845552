import React from 'react';
import { TableFieldProps } from './Table';
import styled from 'styled-components';

type TableBodyProps = {
  data: Array<Object>;
  fields: Array<TableFieldProps<any>>;
  onClickRow: Function;
  keyField: string;
};

const CustomTd = styled.td<{ textColor: string }>`
  color: ${(props) => (props.textColor ? props.textColor : 'inherit')};
`;

/**
 * TableBody()
 * @param props
 * @constructor
 */
export default function TableBody(props: TableBodyProps) {
  const { data, fields, keyField, onClickRow } = props;

  const getFieldCaption = (fieldData: TableFieldProps<any>, item: Object) => {
    if (!fieldData.content) {
      const seperated = fieldData.key.split(',');
      if (seperated.length > 1) {
        let pattern = fieldData.displayPattern;

        seperated.forEach((key) => {
          pattern = pattern.replace('{' + key + '}', item[key] || '');
        });
        return pattern;
      } else {
        return item[fieldData.key];
      }
    } else {
      return fieldData.content;
    }
  };

  const prepareRow = (param: TableFieldProps<any>, item) => {
    if (param.customColElement) {
      const Element = param.customColElement;
      return <Element item={item} key={item[param.key]} />;
    } else {
      return (
        <CustomTd key={param.key} width={param.width} textColor={param.textColor}>
          {getFieldCaption(param, item)}
        </CustomTd>
      );
    }
  };

  const renderRow = (item) => {
    return <>{fields.map((param) => prepareRow(param, item))}</>;
  };

  return (
    <tbody>
      {data.map((item) => (
        <tr key={item[keyField]} onClick={() => (onClickRow ? onClickRow(item) : () => {})}>
          {renderRow(item)}
        </tr>
      ))}
    </tbody>
  );
}
