import React from 'react';
import { ArticlePartnerEntity, FacilityEntity, FacilityPartnerEntity } from '../../../../../Globals/Types/Types';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import styled from 'styled-components';
import { formatCurrency } from '../../../../../Globals/Functions';
import { darken } from 'polished';

interface Props {
  onDelete: (item: ArticlePartnerEntity) => void;
  data: ArticlePartnerEntity;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  background-color: ${(props) => props.theme.color.backgroundGray};
  border-radius: ${(props) => props.theme.border.radius};
  margin-bottom: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  align-content: center;

  .Name {
    flex-grow: 1;
  }

  .Delete {
    display: flex;
    width: 30px;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-end;
    opacity: 0;
  }

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.color.backgroundGray)};

    .Delete {
      opacity: 1;
    }
  }
`;

/**
 * PartnerRow
 * @param props
 * @constructor
 */
export default function PartnerRow(props: Props) {
  const { onDelete, data } = props;
  const { allPartners, facilities } = useAppSelector((state) => state.client);
  const [partner, setPartner] = React.useState<FacilityPartnerEntity>(null);
  const [facility, setFacility] = React.useState<FacilityEntity>(null);

  React.useEffect(() => {
    const partnerParam = allPartners.find((item) => item.partnerFacilityId === data.partnerFacilityId);
    setPartner(partnerParam);
  }, [allPartners, data]);

  React.useEffect(() => {
    if (data.facilityId) {
      const facilityParam = facilities.find((item) => item.facilityId === data.facilityId);
      setFacility(facilityParam);
    }
  }, [facilities, data]);

  const getCaption = () => {
    if (facility) {
      return (
        <>
          {partner.partnerName}
          <br />
          <small className="text-muted">Als Partner von Betriebsstätte "{facility.name}"</small>
        </>
      );
    }
    return partner.partnerName;
  };

  if (partner) {
    return (
      <Container onClick={() => onDelete(data)}>
        <div className="Name">{getCaption()}</div>
        <div className="Price">{formatCurrency(data.salesPriceNetto)}</div>
        <div className="Delete">
          <i className="fas fa-trash" />
        </div>
      </Container>
    );
  }
  return null;
}
