import React from 'react';
import {
  collection,
  getFirestore,
  addDoc,
  query,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  CollectionReference,
  DocumentReference,
  where,
  documentId,
  getDoc,
} from 'firebase/firestore';
import { FacilityPartnerEntity, StorageAddressEntity } from '../../../../Globals/Types/Types';
import { useAppDispatch, useAppSelector } from '../../../../Globals/Hooks/Hooks';
import {
  STORAGE_ADDRESS_CREATE_ERROR,
  STORAGE_ADDRESS_CREATE_FROM_FACILITIES_ERROR,
  STORAGE_ADDRESS_CREATE_FROM_FACILITIES_START,
  STORAGE_ADDRESS_CREATE_FROM_FACILITIES_SUCCESS,
  STORAGE_ADDRESS_CREATE_START,
  STORAGE_ADDRESS_CREATE_SUCCESS,
  STORAGE_ADDRESS_DELETE_ERROR,
  STORAGE_ADDRESS_DELETE_START,
  STORAGE_ADDRESS_DELETE_SUCCESS,
  STORAGE_ADDRESS_GET_ERROR,
  STORAGE_ADDRESS_GET_FROM_PARTNER_ERROR,
  STORAGE_ADDRESS_GET_FROM_PARTNER_START,
  STORAGE_ADDRESS_GET_FROM_PARTNER_SUCCESS,
  STORAGE_ADDRESS_GET_START,
  STORAGE_ADDRESS_GET_SUCCESS,
  STORAGE_ADDRESS_GETLIST_ERROR,
  STORAGE_ADDRESS_GETLIST_START,
  STORAGE_ADDRESS_GETLIST_SUCCESS,
  STORAGE_ADDRESS_UPDATE_ERROR,
  STORAGE_ADDRESS_UPDATE_START,
  STORAGE_ADDRESS_UPDATE_SUCCESS,
} from '../../../ActionTypes';
import { FirebasePathMappings } from '../../../../Globals/FirebaseGlobals';

/**
 * buildCollection()
 * @param clientId
 */
const buildCollection = (clientId: string) => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.storage,
  ) as CollectionReference<StorageAddressEntity>;
};

/**
 * buildDoc()
 * @param clientId
 * @param storageId
 */
const buildDoc = (clientId: string, storageId: string) => {
  return doc(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.storage,
    storageId,
  ) as DocumentReference<StorageAddressEntity>;
};

/**
 * useDispatchStorageAddressCreate()
 */
type CreateReturnType = (address: StorageAddressEntity) => Promise<StorageAddressEntity>;
export const useDispatchStorageAddressCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<CreateReturnType>(
    (address) => {
      dispatch({ type: STORAGE_ADDRESS_CREATE_START, payload: address });

      const storageCollection = buildCollection(clientId);

      return addDoc(storageCollection, address)
        .then((response) => {
          const mapped: StorageAddressEntity = { ...address, storageId: response.id };
          dispatch({ type: STORAGE_ADDRESS_CREATE_SUCCESS, payload: mapped });
          return Promise.resolve(mapped);
        })
        .catch((error) => {
          dispatch({ type: STORAGE_ADDRESS_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchStorageAddressGet()
 */
type GetReturnType = (storageId: string) => Promise<StorageAddressEntity>;
export const useDispatchStorageAddressGet = (): GetReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetReturnType>(
    (storageId) => {
      dispatch({ type: STORAGE_ADDRESS_GET_START, payload: storageId });

      const docRef = buildDoc(clientId, storageId);

      return getDoc(docRef)
        .then((response) => {
          if (response.exists()) {
            const storage = { ...response.data(), storageId: response.id } as StorageAddressEntity;
            dispatch({ type: STORAGE_ADDRESS_GET_SUCCESS, payload: storage });
            return Promise.resolve(storage);
          }

          dispatch({ type: STORAGE_ADDRESS_GET_ERROR, payload: `${storageId} doesn't exist` });

          return Promise.reject();
        })
        .catch((error) => {
          dispatch({ type: STORAGE_ADDRESS_GET_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchStorageAddressGetList()
 */
type GetListReturnType = () => Promise<Array<StorageAddressEntity>>;
export const useDispatchStorageAddressGetList = (): GetListReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListReturnType>(() => {
    dispatch({ type: STORAGE_ADDRESS_GETLIST_START });

    const storageCollection = buildCollection(clientId);

    const queryRef = query(storageCollection, where(documentId(), '!=', 'settings'));

    return getDocs(queryRef)
      .then((response) => {
        let result: Array<StorageAddressEntity> = [];
        if (!response.empty) {
          response.forEach((address) => {
            result.push({ ...address.data(), storageId: address.id });
          });
        }
        const sorted = result.sort((a, b) => a.name.localeCompare(b.name));
        dispatch({ type: STORAGE_ADDRESS_GETLIST_SUCCESS, payload: sorted });
        return Promise.resolve(sorted);
      })
      .catch((error) => {
        dispatch({ type: STORAGE_ADDRESS_GETLIST_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [clientId, dispatch]);
};

/**
 * useDispatchStorageAddressUpdate()
 */
type UpdateReturnType = (address: StorageAddressEntity) => Promise<StorageAddressEntity>;
export const useDispatchStorageAddressUpdate = (): UpdateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateReturnType>(
    (address) => {
      dispatch({ type: STORAGE_ADDRESS_UPDATE_START, payload: address });

      const storageDoc = buildDoc(clientId, address.storageId);

      return setDoc(storageDoc, address, { merge: true })
        .then(() => {
          dispatch({ type: STORAGE_ADDRESS_UPDATE_SUCCESS, payload: address });
          return Promise.resolve(address);
        })
        .catch((error) => {
          dispatch({ type: STORAGE_ADDRESS_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchStorageAddressDelete()
 */
type DeleteReturnType = (address: StorageAddressEntity) => Promise<StorageAddressEntity>;
export const useDispatchStorageAddressDelete = (): DeleteReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<DeleteReturnType>(
    (address) => {
      dispatch({ type: STORAGE_ADDRESS_DELETE_START, payload: address });

      const storageDoc = buildDoc(clientId, address.storageId);

      return deleteDoc(storageDoc)
        .then(() => {
          dispatch({ type: STORAGE_ADDRESS_DELETE_SUCCESS, payload: address });
          return Promise.resolve(address);
        })
        .catch((error) => {
          dispatch({ type: STORAGE_ADDRESS_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchStorageAddressCreateFromFacilities()
 */
type CreateFacilitiesReturnType = () => Promise<any>;
export const useDispatchStorageAddressCreateFromFacilities = (): CreateFacilitiesReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);
  const { facilities } = useAppSelector((state) => state.client);

  return React.useCallback<CreateFacilitiesReturnType>(() => {
    dispatch({ type: STORAGE_ADDRESS_CREATE_FROM_FACILITIES_START, payload: facilities });

    const storageRef = buildCollection(clientId);

    let promiseAll: Array<Promise<any>> = [];
    facilities.forEach((facility) => {
      if (facility.zip && facility.city && facility.street && facility.streetNo) {
        const storageEntity: StorageAddressEntity = {
          name: facility.name,
          zip: facility.zip,
          city: facility.city,
          street: facility.street,
          streetNo: facility.streetNo,
          allowExternalStorage: false,
        };

        promiseAll.push(addDoc(storageRef, storageEntity));
      }
    });

    return Promise.all(promiseAll)
      .then((data) => {
        dispatch({ type: STORAGE_ADDRESS_CREATE_FROM_FACILITIES_SUCCESS });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({ type: STORAGE_ADDRESS_CREATE_FROM_FACILITIES_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [clientId, dispatch, facilities]);
};

/**
 * useDispatchStorageAddressGetFromPartner()
 */
type GetFromPartnerReturnType = (partner: FacilityPartnerEntity) => Promise<StorageAddressEntity>;
export const useDispatchStorageAddressGetFromPartner = (): GetFromPartnerReturnType => {
  const dispatch = useAppDispatch();

  return React.useCallback<GetFromPartnerReturnType>(
    (partner) => {
      dispatch({ type: STORAGE_ADDRESS_GET_FROM_PARTNER_START, payload: { partner } });

      const docRef = buildDoc(partner.partnerClientId, partner.partnerSettings.defaultStorageId);

      return getDoc(docRef)
        .then((snapShot) => {
          if (snapShot.exists()) {
            const mapped: StorageAddressEntity = { ...snapShot.data(), storageId: snapShot.id };
            dispatch({ type: STORAGE_ADDRESS_GET_FROM_PARTNER_SUCCESS, payload: mapped });
            return Promise.resolve(mapped);
          }
          return Promise.reject();
        })
        .catch((error) => {
          dispatch({ type: STORAGE_ADDRESS_GET_FROM_PARTNER_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};
