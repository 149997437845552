import React from 'react';
import { useAppSelector, useLoadingModal, usePageTitle } from '../../../Globals/Hooks/Hooks';
import { useParams } from 'react-router';
import { ServiceEntity } from '../../../Globals/Types/OrderTypes';
import Headline from './Components/Headline';
import {
  useDispatchOrderServiceSetSelected,
  useDispatchOrderServiceUpdate,
} from '../../../Redux/Actions/Order/ServiceAction';
import { useTranslation } from 'react-i18next';
import General from './Services/General';
import { useDispatchClientGetAllPartners } from '../../../Redux/Actions/ClientAction';
import AskUpdateEstimatedAssemblyTimeModal from './Components/AskUpdateEstimatedAssemblyTimeModal';
import { calculateAssemblyTimeFromPositions } from '../../../Globals/Functions';
import ReportInformation from '../../../Components/Order/Details/ReportInformation';
import { useDispatchTourGetForService } from '../../../Redux/Actions/Disposition/TourActions';
import { ReportState } from '../../../Globals/Types/Report';
import Invoices from '../../../Components/Order/Details/Service/Invoices';
import HeaderPanel from '../../../Components/Order/Details/Service/HeaderPanel';

/**
 * Service
 * @constructor
 */
export default function Service() {
  const { orderId, serviceId } = useParams();
  const [isEditable, setIsEditable] = React.useState<boolean>(true);
  const [askUpdateAssemblyTime, setAskUpdateAssemblyTime] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const { order } = useAppSelector((state) => state.order.selectedOrder);
  const { service, dispositions } = useAppSelector((state) => state.order.selectedOrder.selectedService);
  const { setLoading, loadingModal } = useLoadingModal({ headline: t('updatingService') });
  const dispatchUpdateService = useDispatchOrderServiceUpdate();
  const dispatchGetAllPartners = useDispatchClientGetAllPartners();
  const dispatchSetSelectedService = useDispatchOrderServiceSetSelected();
  const dispatchTourGetForService = useDispatchTourGetForService();
  usePageTitle(order ? order.externalId : '');

  const handleUpdateService = React.useCallback(
    (data: Partial<ServiceEntity>, changedAssemblyTime: boolean) => {
      if (order) {
        setLoading(true);
        dispatchUpdateService(order.ownerClientId, order.orderId, serviceId, data)
          .then(() => {
            if (changedAssemblyTime && (!dispositions || dispositions.length === 0)) {
              setTimeout(() => setAskUpdateAssemblyTime(true), 800);
            }
          })
          .finally(() => setLoading(false));
      }
    },
    [dispatchUpdateService, dispositions, order, serviceId, setLoading],
  );

  const recalculateAssemblyTime = React.useCallback(() => {
    if (service.positions && service.positions.length > 0) {
      setAskUpdateAssemblyTime(false);
      const estimatedAssemblyTime = calculateAssemblyTimeFromPositions(service.positions);
      handleUpdateService({ estimatedAssemblyTime }, false);
    }
  }, [handleUpdateService, service]);

  React.useEffect(() => {
    if (service) {
      setIsEditable((!dispositions || dispositions.length <= 0) && service.reportState === ReportState.none);
    }
  }, [dispositions, service]);

  React.useEffect(() => {
    dispatchGetAllPartners().then(() => {});
  }, [dispatchGetAllPartners]);

  React.useEffect(() => {
    if (order && order.orderId === orderId) {
      const filtered = order.services.find((item) => item.serviceId === serviceId);
      dispatchSetSelectedService(filtered);
    }
  }, [dispatchSetSelectedService, order, orderId, serviceId]);

  React.useEffect(() => {
    if (service) {
      dispatchTourGetForService(service.serviceId).then(() => {});
    }
  }, [dispatchTourGetForService, service]);

  if (service && order) {
    return (
      <>
        <Headline order={order} headline={service.type.caption} />
        <HeaderPanel order={order} service={service} />
        <ReportInformation />
        <General
          service={service}
          order={order}
          onChangeService={handleUpdateService}
          isEditable={isEditable}
          hideAssignPartner={false}
        />
        <Invoices order={order} service={service} />
        {loadingModal}
        <AskUpdateEstimatedAssemblyTimeModal
          visible={askUpdateAssemblyTime}
          onClose={() => setAskUpdateAssemblyTime(false)}
          onRecalculate={recalculateAssemblyTime}
        />
      </>
    );
  }
  return null;
}
