import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type MessageProps = {
  visible: boolean;
  onLeave: () => void;
  onCancel: () => void;
  description?: string;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;

  i {
    font-size: 70px;
    color: ${(props) => props.theme.color.red};
    padding-bottom: 20px;
  }
`;

/**
 * AskSaveChanges
 * @param props
 * @constructor
 */
export default function AskSaveChanges(props: MessageProps) {
  const { visible, onLeave, onCancel } = props;
  const [t] = useTranslation();

  return (
    <Modal show={visible} animation={false} centered>
      <Modal.Body>
        <Container>
          <i className="fas fa-question-circle" />
          <h4>{t('discardChanges')}?</h4>
          {t('discardChangesDescription')}
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button variant="danger" onClick={() => onLeave()}>
          {t('discardChanges')}
        </Button>
        <Button variant="outline-secondary" onClick={() => onCancel()}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AskSaveChanges.defaultProps = {
  description: null,
};
