import React from 'react';
import { MailTemplateType, TemplatePlaceholders } from '../../../../../Globals/Types/Mail';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Placeholder = styled.td`
  font-weight: bold;
`;

interface Props {
  templateType: MailTemplateType;
}

/**
 * PlaceholderInfos()
 * @param props
 * @constructor
 */
export default function PlaceholderInfos(props: Props) {
  const { templateType } = props;
  const [t] = useTranslation();

  const renderContent = () => {
    return TemplatePlaceholders[templateType].map((item) => (
      <tr>
        <Placeholder width={140}>{`{{${item}}}`}</Placeholder>
        <td>{t(`modules.settings.mailTemplates.templates.${templateType}.placeholder.${item}`)}</td>
      </tr>
    ));
  };

  return (
    <Container>
      <table>{renderContent()}</table>
    </Container>
  );
}
