import React from 'react';
import { ResourceEntity } from '../../../Globals/Types/Types';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import Lodash from 'lodash';
import { useTranslation } from 'react-i18next';

interface Props {
  item: ResourceEntity;
}

/**
 * ResourcesFacilitiesCol()
 * @param props
 * @constructor
 */
export default function ResourcesFacilitiesCol(props: Props) {
  const { item } = props;
  const { facilities } = useAppSelector((state) => state.client);
  const [names, setNames] = React.useState<string>(null);
  const [t] = useTranslation();

  React.useEffect(() => {
    const namesArr: string[] = Lodash.map(item.facilityIds, (facilityId) => {
      const facility = Lodash.find(facilities, (facility) => facility.facilityId === facilityId);
      if (facility) {
        return facility.name;
      }

      return t('unknown');
    });

    if (namesArr.length > 0) {
      setNames(namesArr.join(', '));
    }
  }, [facilities, item, t]);

  return (
    <td key={`${item.resourceId}-facilities`}>
      <small>{names}</small>
    </td>
  );
}
