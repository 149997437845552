import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (item: string) => void;
  selected: string;
  hasError: boolean;
}

/**
 * SelectCompanyType
 * @param props
 * @constructor
 */
export default function SelectCompanyType(props: Props) {
  const { onChange, selected, hasError } = props;
  const [t] = useTranslation();
  const [options] = React.useState<SelectOptions>([
    { value: 'gardening', label: t(`companyTypes.gardening`) },
    { value: 'furniture', label: t(`companyTypes.furniture`) },
    { value: 'kitchen', label: t(`companyTypes.kitchen`) },
    { value: 'craft', label: t(`companyTypes.craft`) },
    { value: 'pool', label: t(`companyTypes.pool`) },
    { value: 'transport', label: t(`companyTypes.transfer`) },
    { value: 'other', label: t(`companyTypes.other`) },
  ]);

  return (
    <Select
      hasError={hasError}
      label={t('companyType')}
      onChange={onChange}
      initialValue={selected}
      options={options}
      contextHelpKey={'registerCompanyType'}
    />
  );
}
