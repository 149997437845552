import React from 'react';
import { OrderEntity, ServiceEntity } from '../../../Globals/Types/OrderTypes';
import Panel from '../../Panel';
import DisplayText from '../../View/DisplayText';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { formatDateString, formatWeek } from '../../../Globals/Functions';
import moment from 'moment';
import { useGetServiceTypeIcon } from '../../../Globals/Hooks/ServiceHooks';

const Container = styled.div`
  display: flex;
  flex-direction: row;

  .Caption {
    flex-grow: 1;
  }
`;

interface Props {
  order: OrderEntity;
}

/**
 * ExecutionOverview
 * @param props
 * @constructor
 */
export default function ExecutionOverview(props: Props) {
  const { order } = props;
  const [t] = useTranslation();
  const getServiceTypeIcon = useGetServiceTypeIcon();

  const renderRow = (service: ServiceEntity) => {
    let formattedString;

    if (service.desiredDate && moment(service.desiredDate).isSameOrAfter(moment(), 'day')) {
      formattedString = formatDateString(service.desiredDate);
    } else if (service.desiredWeek) {
      formattedString = formatWeek(service.desiredWeek);
    } else {
      formattedString = <i>{t('noAppointment')}</i>;
    }

    if (formattedString) {
      return (
        <DisplayText key={service.serviceId} icon={getServiceTypeIcon(service.type)}>
          <Container>
            <div className="Caption">{service.type.caption}</div>
            <div>{formattedString}</div>
          </Container>
        </DisplayText>
      );
    }

    return null;
  };

  return <Panel headline={t('appointmentOverview')}>{order.services.map(renderRow)}</Panel>;
}
