import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import de from '../../../Assets/Images/flags/de-DE.png';
import us from '../../../Assets/Images/flags/en-US.png';
import nl from '../../../Assets/Images/flags/nl-NL.png';
import es from '../../../Assets/Images/flags/es-ES.png';
import { useTranslation } from 'react-i18next';
import { CountryCode } from '../../../Globals/Types/Enums';
import { STORAGE_SELECTED_LANGUAGE } from '../../../Globals/Types/Constants';
import moment from 'moment';
import i18n from 'i18next';
import { getSelectedLanguage, momentConfiguration } from '../../../Globals/Functions/LocalizationFunctions';

const Container = styled(Dropdown)`
  margin-left: 2px;

  > button {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 22px 20px;
    border: none;
    outline: 0;
    border-radius: ${(props) => props.theme.border.radius};

    .btn-primary.dropdown-toggle {
      background-color: ${(props) => props.theme.color.primaryColor} !important;
    }

    :after {
      border: none;
    }

    &:hover,
    &:active {
      background-color: ${(props) => props.theme.color.primaryColor} !important;
      outline: 0;
    }

    &:focus {
      background-color: #ffffff;
      outline: 0;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
`;

/**
 * SelectLanguage()
 * @constructor
 */
export default function SelectLanguage() {
  const [t] = useTranslation();

  const getImage = () => {
    const lng = getSelectedLanguage(true);

    if (lng) {
      switch (lng as CountryCode) {
        case CountryCode.UK:
          return us;
        case CountryCode.ES:
          return es;
        case CountryCode.DE:
          return de;
        case CountryCode.NL:
          return nl;
      }
    } else {
      return es;
    }
  };

  const handleSetLanguage = (lng: CountryCode) => {
    localStorage.setItem(STORAGE_SELECTED_LANGUAGE, lng);
    moment.locale(lng);
    moment.localeData(lng).set(momentConfiguration(lng));
    i18n.changeLanguage(lng).then(() => {});
  };

  return (
    <Container>
      <Dropdown.Toggle id="dropdown-custom-1">
        <img src={getImage()} width={29} height={29} alt="SelectLanguage" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="super-colors">
        <Dropdown.Item eventKey="1" onClick={() => handleSetLanguage(CountryCode.DE)}>
          {t('languageCodes.de-DE')}
        </Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={() => handleSetLanguage(CountryCode.ES)}>
          {t('languageCodes.es-ES')}
        </Dropdown.Item>
        <Dropdown.Item eventKey="3" onClick={() => handleSetLanguage(CountryCode.UK)}>
          {t('languageCodes.en-US')}
        </Dropdown.Item>
        <Dropdown.Item eventKey="3" onClick={() => handleSetLanguage(CountryCode.NL)}>
          {t('languageCodes.nl-NL')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Container>
  );
}
