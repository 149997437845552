/* eslint-disable max-len */
import { ClientEntity, FacilityEntity, Subscription, UserEntity } from '../../Globals/Types/Types';
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  CLIENT_GET_SUCCESS,
  CLIENT_SUBSCRIPTION_CANCEL_SUCCESS,
  CLIENT_SUBSCRIPTION_CREATE_SUCCESS,
  CLIENT_SUBSCRIPTION_GET_ACTIVE_SUCCESS,
  CLIENT_UPDATE_SUCCESS,
  USER_UPDATE_SUCCESS,
} from '../ActionTypes';
import { defaultDemoSubscription } from '../../Globals/Types/Constants';
import { UserLoginPermission } from '../../Globals/Types/Enums';

export interface AuthReducerInterface {
  client: ClientEntity;
  activeSubscription: Subscription;
  clientId: string | null;
  facilityId: string | null;
  isLoggedIn: boolean;
  user: UserEntity;
  userId: string | null;
  accessNodes: Array<string>;
}

type Action =
  | {
      type: typeof AUTH_LOGIN_SUCCESS;
      payload: {
        client: ClientEntity;
        user: UserEntity;
        userId: string;
        facilities: Array<FacilityEntity>;
        accessNodes: Array<string>;
      };
    }
  | { type: typeof CLIENT_GET_SUCCESS; payload: ClientEntity }
  | { type: typeof CLIENT_UPDATE_SUCCESS; payload: ClientEntity }
  | { type: typeof USER_UPDATE_SUCCESS; payload: UserEntity }
  | { type: typeof CLIENT_SUBSCRIPTION_GET_ACTIVE_SUCCESS; payload: Subscription }
  | { type: typeof CLIENT_SUBSCRIPTION_CANCEL_SUCCESS; payload: Subscription }
  | { type: typeof CLIENT_SUBSCRIPTION_CREATE_SUCCESS; payload: Subscription }
  | { type: typeof AUTH_LOGOUT };

const defaultValues: AuthReducerInterface = {
  client: {
    mainFacility: null,
    adminUserId: null,
    active: true,
    settings: {
      forceRebuildIndex: false,
      allowUseFacilities: false,
      invoice: {
        active: false,
      },
      customer: {
        active: false,
      },
      timeTracking: {
        active: false,
        mandatory: false,
      },
      order: {
        serviceTypeDefinition: [],
        reportDefinition: [],
        autoExternalId: false,
        prefixFacilityInitial: false,
        enableTimeTracking: true,
      },
    },
  },
  activeSubscription: defaultDemoSubscription,
  clientId: null,
  facilityId: null,
  user: {
    userId: null,
    active: true,
    initials: null,
    clientId: null,
    authId: null,
    facilityId: null,
    firstName: '',
    lastName: '',
    mail: '',
    countryCode: '',
    devices: [],
    createdDate: null,
    groups: [],
    facilities: [],
    changePassword: false,
    accessCode: 99,
    loginPermission: UserLoginPermission.both,
  },
  userId: null,
  accessNodes: [],
  isLoggedIn: false,
};

export default function reduce(state: AuthReducerInterface = defaultValues, action: Action): AuthReducerInterface {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS: {
      const { client, user, userId, accessNodes } = action.payload;
      return {
        ...state,
        client: { ...state.client, ...client },
        user,
        clientId: user.clientId,
        userId,
        facilityId: user.facilityId,
        isLoggedIn: true,
        accessNodes,
      };
    }

    case CLIENT_UPDATE_SUCCESS:
    case CLIENT_GET_SUCCESS: {
      if (state.clientId === action.payload.clientId) {
        return { ...state, client: action.payload };
      }
      return { ...state };
    }

    case USER_UPDATE_SUCCESS: {
      if (action.payload.userId === state.userId) {
        return { ...state, user: action.payload };
      }
      return { ...state };
    }

    case CLIENT_SUBSCRIPTION_CANCEL_SUCCESS:
    case CLIENT_SUBSCRIPTION_CREATE_SUCCESS:
    case CLIENT_SUBSCRIPTION_GET_ACTIVE_SUCCESS:
      return { ...state, activeSubscription: action.payload || defaultDemoSubscription };

    case AUTH_LOGOUT: {
      return { ...defaultValues };
    }

    default:
      return { ...state };
  }
}
