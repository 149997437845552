/* eslint-disable max-len */
import {
  validateOrderOptions,
  ValidateOrderOptionsResult,
} from '../../../Components/Order/Validators/ValidateOrderOptions';
import {
  validateCustomerAddress,
  ValidateCustomerAddressResult,
} from '../../../Components/Order/Validators/ValidateCustomerAddress';
import {
  validateMontageDate,
  ValidateMontageDateResult,
} from '../../../Components/Order/Validators/ValidateMontageDateOptions';
import { validatePositions, ValidatePositionsResult } from '../../../Components/Order/Validators/ValidatePositions';
import { CreateOrderState, OrderEntity } from '../../../Globals/Types/OrderTypes';
import createOrderEntityFromCreateOrderState from './CreateOrderEntity';
import {
  validateDeliveryAndLoadingAddress,
  ValidateDeliveryAndLoadingOptionsResult,
} from '../../../Components/Order/Validators/ValidateDeliveryAndLoadingAddress';

export interface CreateValidationResult {
  result: boolean;
  validateOrderOptions: ValidateOrderOptionsResult;
  validateCustomerAddress: ValidateCustomerAddressResult;
  validateMontageDate: ValidateMontageDateResult;
  validatePositions: ValidatePositionsResult;
  validateDeliveryAdnLoading: ValidateDeliveryAndLoadingOptionsResult;
  validatedOrderEntity: OrderEntity | null;
}

/**
 * validateCreateOrder()
 * @param data
 */
export const validateCreateOrder = (data: CreateOrderState): CreateValidationResult => {
  let returnValue: CreateValidationResult = {
    result: true,
    validateOrderOptions: validateOrderOptions(data),
    validateCustomerAddress: validateCustomerAddress(data.order.customer),
    validateMontageDate: validateMontageDate(data),
    validatePositions: validatePositions(data),
    validateDeliveryAdnLoading: validateDeliveryAndLoadingAddress(
      data.generalService.deliveryAndLoadingOption,
      data.generalService.loadingAddress,
      data.generalService.deliveryAddress,
    ),
    validatedOrderEntity: null,
  };

  Object.keys(returnValue).forEach((key) => {
    if (key !== 'result' && key !== 'validatedOrderEntity') {
      if (returnValue[key].result === false) {
        returnValue = { ...returnValue, result: false };
      }
    }
  });

  // If validation is OK, create an order entity and add to validation result
  if (returnValue.result) {
    returnValue = {
      ...returnValue,
      validatedOrderEntity: createOrderEntityFromCreateOrderState(data),
    };
  }

  return returnValue;
};
