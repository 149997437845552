import React from 'react';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';

/**
 * Will be used by firebase auth templates. All mail templates call this route and the function redirects to the
 * correct pages with all query params
 * @constructor
 */
export default function FirebaseModeRedirect() {
  const { search } = useLocation();
  const mode = new URLSearchParams(search).get('mode');

  const getUrl = () => {
    switch (mode) {
      case 'resetPassword':
        return `/public/password/reset${search}`;
      default:
        return '/public/login';
    }
  };

  return <Navigate to={getUrl()} />;
}
