import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Config from '../Config';
import { Environment } from '../Types/Enums';
import { getMappedLanguageFromBrowser } from '../Functions/LocalizationFunctions';

/**
 * initializeTranslations()
 * @param languages
 * @param selectedLng
 */
export const initializeTranslations = (languages, selectedLng) => {
  i18n
    .use(initReactI18next)
    .init({
      debug: Config.env !== Environment.production,
      interpolation: {
        escapeValue: false,
      },

      fallbackLng: 'en-US',
      lng: selectedLng || getMappedLanguageFromBrowser(),
      resources: {
        ...languages,
      },
      react: {
        suspend: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
      },
    })
    .then(() => {});
};
