import React from 'react';
import moment, { Moment } from 'moment';
import Lodash from 'lodash';
import { ResourceEntity, UserEntity } from '../../Globals/Types/Types';
import { Tour } from '../../Globals/Types/Tour';
import { ElasticSearchServiceEntity } from '../../Globals/Types/OrderTypes';
import { InternalErrorCodes } from '../../Globals/InternalErrorCodes';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { userDisplayName } from '../../Globals/Functions';

export type DispositionValidationError = {
  code: InternalErrorCodes;
  details?: Object;
};

type DispositionValidateResult = {
  error?: DispositionValidationError;
  isValid: boolean;
};

/**
 * validateFacility()
 * @param facilityIdsTour
 * @param facilityIdsItem
 */
const validateFacility = (facilityIdsTour: string[], facilityIdsItem: string[]): DispositionValidateResult => {
  if (!Lodash.find(facilityIdsTour, (id) => Lodash.find(facilityIdsItem, (id2) => id2 === id))) {
    return { isValid: false, error: { code: InternalErrorCodes.DISPOSITION_MISMATCH_FACILITY } };
  }

  return { isValid: true };
};

/**
 * Users MUST have all facilities which are assigned to the tour. Otherwise, there could be services
 * inside the tour where user or resource has no access to
 * validateFacility()
 * @param tourFacilityIds
 * @param itemFacilityIds
 */
const validateUserFacility = (tourFacilityIds: string[], itemFacilityIds: string[]): DispositionValidateResult => {
  let found: boolean = true;

  tourFacilityIds.forEach((tourId) => {
    if (found) {
      const foundItem = itemFacilityIds.find((itemId) => itemId === tourId);
      if (!foundItem) {
        found = false;
      }
    }
  });

  return { isValid: found, error: { code: InternalErrorCodes.DISPOSITION_USER_NOT_ALL_FACILITIES } };
};

type ResourceReturnType = (tour: Tour, resource: ResourceEntity) => DispositionValidateResult;
/**
 * useValidateResource()
 */
export const useValidateResource = (): ResourceReturnType => {
  const { tourListByDate } = useAppSelector((state) => state.disposition);

  return React.useCallback<ResourceReturnType>(
    (tour, resource) => {
      const { date } = tour;
      const tourList: Tour[] = Lodash.find(tourListByDate, (item) => item.date === date)?.data;

      const alreadyPlanned: Tour = Lodash.find(
        tourList,
        (item) => !!Lodash.find(item.resources, (item2) => item2.resourceId === resource.resourceId),
      );

      if (alreadyPlanned) {
        if (alreadyPlanned.tourId === tour.tourId) {
          return { isValid: false };
        }

        return {
          isValid: false,
          error: {
            code: InternalErrorCodes.DISPOSITION_ALREADY_PLANNED_RESOURCE,
            details: { name: resource.name, tourName: tour.name, date: moment(tour.date).format('LL') },
          },
        };
      }

      return validateFacility(tour.facilityIds, resource.facilityIds);
    },
    [tourListByDate],
  );
};

interface ValidateResultService extends DispositionValidateResult {
  startTime?: string;
  endTime?: string;
}
type ServiceReturnType = (
  tour: Tour,
  service: ElasticSearchServiceEntity,
  startTime: string,
  workDayEnd: string,
) => ValidateResultService;
/**
 * useCalculateServiceTime()
 */
const useCalculateServiceTime = (): ServiceReturnType => {
  return React.useCallback<ServiceReturnType>((tour, service, startTime, workDayEnd) => {
    // Only used if tour is full for the day to let users add services anyway when they want
    const remainingDayMinutes: number = moment(`${tour.date} ${workDayEnd}`).diff(
      moment(`${tour.date} ${startTime}`),
      'minutes',
    );

    let usedAssemblyTime: number = service.estimatedAssemblyTime;
    if (service.estimatedAssemblyTime && service.estimatedAssemblyTime > 0) {
      if (service.remainingAssemblyTime) {
        if (service.remainingAssemblyTime > 0) {
          if (service.remainingAssemblyTime > remainingDayMinutes) {
            usedAssemblyTime = remainingDayMinutes;
          } else {
            usedAssemblyTime = service.remainingAssemblyTime;
          }
        }
      } else {
        if (service.estimatedAssemblyTime > remainingDayMinutes) {
          usedAssemblyTime = remainingDayMinutes;
        } else {
          usedAssemblyTime = service.estimatedAssemblyTime;
        }
      }
    } else {
      usedAssemblyTime = 8 * 60;
    }

    if (usedAssemblyTime <= 0) {
      return { isValid: false, error: { code: InternalErrorCodes.DISPOSITION_OVER_24H } };
    }

    const startMoment: Moment = moment(`${tour.date} ${startTime}`);
    const endMoment: Moment = moment(startMoment).add(usedAssemblyTime, 'minutes');

    if (startMoment.format('YYYY-MM-DD') !== endMoment.format('YYYY-MM-DD')) {
      return { isValid: false, error: { code: InternalErrorCodes.DISPOSITION_OVER_24H } };
    }

    return {
      isValid: true,
      startTime: startMoment.format('HH:mm:ss'),
      endTime: endMoment.format('HH:mm:ss'),
    };
  }, []);
};

/**
 * validateService()
 */
export const useValidateService = (): ServiceReturnType => {
  const calculateServiceTime = useCalculateServiceTime();

  return React.useCallback<ServiceReturnType>(
    (tour, service, startTime, workDayEnd) => {
      const facilityResult: DispositionValidateResult = validateFacility(tour.facilityIds, [
        service.ownerFacilityId,
        service.assignedFacilityId,
      ]);

      if (!facilityResult.isValid) {
        return facilityResult;
      }

      return calculateServiceTime(tour, service, startTime, workDayEnd);
    },
    [calculateServiceTime],
  );
};

type UserReturnType = (tour: Tour, user: UserEntity) => DispositionValidateResult;
/**
 * validateUser()
 */
export const useValidateUser = (): UserReturnType => {
  const { tourListByDate } = useAppSelector((state) => state.disposition);

  return React.useCallback(
    (tour, user) => {
      const { date } = tour;
      const tourList: Tour[] = Lodash.find(tourListByDate, (item) => item.date === date)?.data;

      const alreadyPlanned: Tour = Lodash.find(tourList, (item) => {
        return !!Lodash.find(item.users, (item2) => item2.userId === user.userId);
      });

      if (alreadyPlanned) {
        if (alreadyPlanned.tourId === tour.tourId) {
          return { isValid: false };
        }

        return {
          isValid: false,
          error: {
            code: InternalErrorCodes.DISPOSITION_ALREADY_PLANNED_USER,
            details: { name: userDisplayName(user), tourName: tour.name, date: moment(tour.date).format('LL') },
          },
        };
      }

      return validateUserFacility(tour.facilityIds, user.facilities);
    },
    [tourListByDate],
  );
};
