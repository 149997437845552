import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { formatDateString, userDisplayName } from '../../../../../Globals/Functions';
import moment from 'moment';
import { ElasticSearchServiceDispositionInterface } from '../../../../../Globals/Types/OrderTypes';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 15px;
  background-color: ${(props) => props.theme.color.backgroundGray};
  border-color: ${(props) => darken(0.05, props.theme.color.backgroundGray)};
  border-width: 0.5px;
  border-style: solid;
  border-radius: ${(props) => props.theme.border.radius};
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    color: #000000;

    .Right {
      color: ${(props) => props.theme.color.primaryColor};
    }
  }

  > .Left {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  > .Right {
  }
`;

const Spacer = styled.div`
  padding: 0 10px;
  font-weight: bold;
`;

interface Props {
  disposition: ElasticSearchServiceDispositionInterface;
  onPress: (tour: ElasticSearchServiceDispositionInterface) => void;
}

/**
 * DispositionInformation
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { disposition, onPress } = props;
  const [t] = useTranslation();

  const renderDate = () => <div>{formatDateString(disposition.date)}</div>;

  const renderTime = () => {
    const startTime = moment(disposition.startTime, 'HH:mm:ss').format('HH:mm');
    const endTime = moment(disposition.endTime, 'HH:mm:ss').format('HH:mm');
    return <div>{`${startTime}h - ${endTime}h (${t('xMinute', { count: disposition.assemblyTime })})`}</div>;
  };

  const renderUsers = () => {
    if (disposition.users && disposition.users.length > 0) {
      const usersString = disposition.users.map((user) => userDisplayName(user, true)).join(' - ');
      return (
        <>
          <Spacer>/</Spacer>
          {usersString}
        </>
      );
    }
    return null;
  };

  const renderResource = () => {
    if (disposition.resources && disposition.resources.length > 0) {
      const resString = disposition.resources.map((resource) => resource.name).join(' - ');
      return (
        <>
          <Spacer>/</Spacer>
          {resString}
        </>
      );
    }
    return null;
  };

  return (
    <Container onClick={() => onPress(disposition)}>
      <div className="Left">
        {renderDate()}
        <Spacer>/</Spacer>
        {renderTime()}
        {renderUsers()}
        {renderResource()}
      </div>
      <div className="Right">
        <i className="fas fa-circle-arrow-right" />
      </div>
    </Container>
  );
}
