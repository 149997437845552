import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TourItem, TourService } from '../../../../Globals/Types/Tour';
import moment from 'moment';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { useGetFacilityWorkTimes } from '../../../../Globals/Hooks/FacilityHooks';

const Container = styled.div<{ bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  width: 60px;
  border-right: 1px solid ${(props) => props.theme.border.color};
  text-align: center;
  color: ${(props) => props.bgColor};
  font-weight: bolder;
`;

interface Props {
  tour: TourItem<TourService>[];
}

/**
 * Performance()
 * @param props
 * @constructor
 */
export default function Performance(props: Props) {
  const { tour } = props;
  const theme = useTheme();
  const { facilityId } = useAppSelector((state) => state.auth.user);
  const [overallPlanned, setOverallPlanned] = React.useState<number>(0);
  const [overallAvailable, setOverallAvailable] = React.useState<number>(0);
  const [workLoad, setWorkLoad] = React.useState<number>(0);
  const getFacilityWorkTime = useGetFacilityWorkTimes();

  React.useEffect(() => {
    setWorkLoad((overallPlanned * 100) / overallAvailable);
  }, [overallAvailable, overallPlanned]);

  React.useEffect(() => {
    const { workDayStart, workDayEnd } = getFacilityWorkTime(facilityId);
    if (workDayEnd && workDayStart) {
      const diff = moment(workDayEnd, 'HH:mm:ss').diff(moment(workDayStart, 'HH:mm:ss'), 'minute');
      setOverallAvailable(diff);
    } else {
      // Default 8 * 60 minutes
      setOverallAvailable(480);
    }
  }, [facilityId, getFacilityWorkTime]);

  React.useEffect(() => {
    if (tour) {
      let overall = 0;
      tour.forEach((item) => {
        const diff = moment(item.endTime, 'HH:mm:ss').diff(moment(item.startTime, 'HH:mm:ss'), 'minute');
        overall = overall + diff;
      });
      setOverallPlanned(overall);
    } else {
      setOverallPlanned(0);
    }
  }, [tour]);

  const getColor = () => (workLoad < 50 ? theme.color.red : workLoad < 80 ? theme.color.orange : theme.color.green);

  return <Container bgColor={getColor()}>{workLoad.toFixed(0)}%</Container>;
}
