import { useDispatch } from 'react-redux';
import React from 'react';
import {
  DASHBOARD_SERVICES_GET_ERROR,
  DASHBOARD_SERVICES_GET_START,
  DASHBOARD_SERVICES_GET_SUCCESS,
} from '../ActionTypes';
import { OrderServiceFilterResponseType, useDispatchOrderServiceFilter } from './Order/ServiceAction';
import moment from 'moment';

/**
 * useDispatchDashboardGetServices()
 * Geta ll upcoming services for today and tomorrow
 */
export const useDispatchDashboardGetServices = (): (() => Promise<OrderServiceFilterResponseType>) => {
  const dispatch = useDispatch();
  const dispatchServicesFilter = useDispatchOrderServiceFilter(false);

  return React.useCallback<() => Promise<OrderServiceFilterResponseType>>(() => {
    dispatch({ type: DASHBOARD_SERVICES_GET_START });
    const today = moment().format('YYYY-MM-DD');
    const tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');

    return dispatchServicesFilter(today, tomorrow)
      .then((services) => {
        dispatch({ type: DASHBOARD_SERVICES_GET_SUCCESS, payload: services });
        return Promise.resolve(services);
      })
      .catch((error) => {
        dispatch({ type: DASHBOARD_SERVICES_GET_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [dispatch, dispatchServicesFilter]);
};
