import React from 'react';
import { TaskEntity } from '../../../Globals/Types/Types';
import Table from '../../Table/Table';
import { useTranslation } from 'react-i18next';
import Item from './Item';

interface Props {
  data: Array<TaskEntity>;
  onPress: (task: TaskEntity) => void;
  onCheck: (task: TaskEntity) => void;
  onUncheck: (task: TaskEntity) => void;
}

/**
 * ListView
 * @param props
 * @constructor
 */
export default function ListView(props: Props) {
  const { data, onPress, onCheck, onUncheck } = props;
  const [t] = useTranslation();

  const renderRows = () => {
    return data.map((item) => (
      <Item task={item} onPress={onPress} onCheck={onCheck} onUncheck={onUncheck} key={item.taskId} />
    ));
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th style={{ width: 50 }} />
            <th>{t('title')}</th>
            <th style={{ textAlign: 'right' }}>{t('dueDate')}</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </Table>
    </>
  );
}
