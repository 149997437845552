import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 12px 25px;
  border-bottom: 0.5px solid #dee2e6;
  cursor: pointer;
  align-items: center;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${(props) => props.theme.table.highlight};
  }

  > i {
    margin-right: 15px;
    font-size: 22px;
  }
`;

interface Props {
  checked: boolean;
  key: string;
  onClick: () => void;
  text: string;
}

/**
 * CheckRow()
 * @param props
 * @constructor
 */
export default function CheckRow(props: Props) {
  const { checked, onClick, text } = props;

  const renderCheckIcon = () => {
    if (checked) {
      return <i className="far fa-check-square" />;
    }
    return <i className="fal fa-square" />;
  };

  return (
    <Row onClick={onClick}>
      {renderCheckIcon()}
      {text}
    </Row>
  );
}
