import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { darken } from 'polished';

export enum InputType {
  text = 'text',
  password = 'password',
  textarea = 'textarea',
  number = 'number',
}

type InputProps = {
  onChange: (value: string) => void;
  placeholder: string;
  value: string | number;
  visible: boolean;
  type?: InputType;
  required: boolean;
  hasError?: boolean;
  disabled: boolean;
  style: Object;
  autoFocus: boolean;
  removeBottomMargin: boolean;
};

interface ContainerProps {
  $noMarginBottom: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${(props) => (props.$noMarginBottom ? '0px' : '15px')};

  input {
    border-radius: ${(props) => props.theme.border.radius};
    padding: 16px 18px 16px 50px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.border.color};
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .fa-question-circle {
    cursor: pointer;
    color: ${(props) => props.theme.color.help};

    &:hover {
      color: ${(props) => darken(0.1, props.theme.color.help)};
    }
  }

  .fa-search {
    position: absolute;
    left: 15px;
    top: 19px;
    color: ${(props) => props.theme.color.textGray};
    font-size: 20px;
  }

  .fa-times-circle {
    position: absolute;
    right: 15px;
    top: 19px;
    color: ${(props) => props.theme.color.textGray};
    font-size: 20px;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.color.text};
    }
  }
`;

/**
 * SearchInput()
 * @param props
 * @constructor
 */
export default function SearchInput(props: InputProps) {
  const inputRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (props.autoFocus) {
      setTimeout(() => inputRef.current.focus(), 200);
    }
  }, [props.autoFocus]);

  const renderSearchIcon = () => {
    return <i className="fas fa-search" />;
  };

  const renderClearIcon = () => {
    if (props.value) {
      return <i className="fas fa-times-circle" onClick={() => props.onChange('')} />;
    }
    return null;
  };

  const renderInputElement = () => (
    <input
      type={InputType.text}
      value={props.value}
      onChange={(e: any) => props.onChange(e.nativeEvent.target.value)}
      disabled={props.disabled}
      ref={inputRef}
      autoFocus={props.autoFocus}
      placeholder={props.placeholder}
    />
  );

  if (props.visible) {
    return (
      <Container style={props.style} $noMarginBottom={props.removeBottomMargin}>
        {renderSearchIcon()}
        {renderInputElement()}
        {renderClearIcon()}
      </Container>
    );
  }
  return null;
}

SearchInput.defaultProps = {
  visible: true,
  value: '',
  required: false,
  disabled: false,
  style: {},
  autoFocus: false,
  removeBottomMargin: false,
};
