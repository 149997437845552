import React from 'react';
import { TaskEntity } from '../../Globals/Types/Types';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  item: TaskEntity;
}

/**
 * AssignedHint
 * @param props
 * @constructor
 */
export default function AssignedHint(props: Props) {
  const { item } = props;
  const [t] = useTranslation();
  const { userId } = useAppSelector((state) => state.auth);

  if (item.createdUser && item.createdUser.userId !== userId) {
    return (
      <Alert variant="info">
        <i className="fas fa-info-circle" style={{ marginRight: 10 }} />
        {t('modules.profile.tasks.assignedHint', { ...item.createdUser })}
      </Alert>
    );
  }
  return null;
}
