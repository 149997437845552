import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { useDispatchStorageAddressGetList } from '../../Redux/Actions/Client/Storage/AddressAction';
import { StorageAddressEntity } from '../../Globals/Types/Types';

interface Props {
  onChange: (item: StorageAddressEntity) => void;
  selected?: string;
  required?: boolean;
  disabled?: boolean;
  onlyExternalAllowed?: boolean;
}

/**
 * SelectStorage
 * @param props
 * @constructor
 */
export default function SelectStorage(props: Props) {
  const { onChange, selected, required, disabled, onlyExternalAllowed } = props;
  const [t] = useTranslation();
  const { addresses } = useAppSelector((state) => state.storage);
  const [options, setOptions] = React.useState<SelectOptions>([]);
  const dispatchGetAddresses = useDispatchStorageAddressGetList();

  React.useEffect(() => {
    dispatchGetAddresses().then(() => {});
  }, [dispatchGetAddresses]);

  React.useEffect(() => {
    if (addresses && addresses.length > 0) {
      const param: SelectOptions = addresses
        .filter((item) => {
          if (onlyExternalAllowed && item.allowExternalStorage) {
            return true;
          } else return !onlyExternalAllowed;
        })
        .map((item) => ({
          value: item.storageId,
          label: item.name,
        }));
      setOptions(param);
    }
  }, [addresses, onlyExternalAllowed]);

  const handleOnChange = (param: string) => {
    const filtered = addresses.filter((item) => item.storageId === param);
    if (filtered.length === 1) {
      onChange(filtered[0]);
    }
  };

  if (addresses && addresses.length > 0 && options && options.length > 0) {
    return (
      <Select
        label={t('storageAddress')}
        onChange={handleOnChange}
        initialValue={selected}
        options={options}
        required={required}
        disabled={disabled}
      />
    );
  }
  return null;
}

SelectStorage.defaultProps = {
  required: false,
  disabled: false,
  onlyExternalAllowed: false,
};
