import React from 'react';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { useNavigate, useParams } from 'react-router';
import { OfferEntity } from '../../../Globals/Types/OfferTypes';
import PageHeader from '../../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import { useDispatchCustomerOffersGetList } from '../../../Redux/Actions/Customer/OffersAction';
import Text from '../../../Components/Text';
import { formatCurrency, userDisplayName } from '../../../Globals/Functions';
import { useGetUser } from '../../../Globals/Functions/UserFunctions';
import { Button } from 'react-bootstrap';
import Panel from '../../../Components/Panel';
import DisplayText from '../../../Components/View/DisplayText';
import moment from 'moment';
import { UserEntity } from '../../../Globals/Types/Types';
import PositionRows from '../../../Components/Offer/Details/PositionRows';
import DetailsActionButton from '../../../Components/Offer/Details/DetailsActionButton';
import { buildUrlCustomersOffers } from '../../../Globals/UrlFunctions';

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { offerId, customerId } = useParams();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const { offers } = useAppSelector((state) => state.client.customers.selectedCustomer);
  const [offer, setOffer] = React.useState<OfferEntity>(null);
  const [user, setUser] = React.useState<UserEntity>(null);
  const { setLoading, loadingModal } = useLoadingModal({ headline: t('creatingPdf') });

  const getUser = useGetUser();
  const dispatchGetList = useDispatchCustomerOffersGetList();

  React.useEffect(() => {
    if (!offers && !loaded) {
      dispatchGetList(customerId).then(() => setLoaded(true));
    }
  }, [customerId, dispatchGetList, loaded, offers]);

  React.useEffect(() => {
    if (offerId && offers) {
      setOffer(offers.find((item) => item.offerId === offerId));
    }
  }, [offerId, offers]);

  React.useEffect(() => {
    if (offer) {
      setUser(getUser(offer.contactUserId));
    }
  }, [getUser, offer]);

  const renderDescription = () => {
    const user = getUser(offer.createdUserId);

    return (
      <>
        <Text bold>{`${t('offerNumber')}: ${offer.offerNumber}`}</Text>
        <Text small light>
          {`${t('createdFrom')} ${userDisplayName(user, true)}`}
        </Text>
      </>
    );
  };

  const renderBackButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => navigate(buildUrlCustomersOffers(customerId))}>
        <i className="fas fa-angle-double-left" style={{ marginRight: 6 }} />
        {t('back')}
      </Button>
    );
  };

  const renderAdditionalInformation = () => {
    if (offer.additionalFooterText || offer.internalInformation) {
      return (
        <Panel headline={t('additionalInformation')}>
          <DisplayText
            horizontal={false}
            caption={t('modules.offers.additionFooterInformation')}
            visible={!!offer.additionalFooterText}
          >
            {offer.additionalFooterText}
          </DisplayText>
          <DisplayText horizontal={false} caption={t('internalInformation')} visible={!!offer.internalInformation}>
            {offer.internalInformation}
          </DisplayText>
        </Panel>
      );
    }
    return null;
  };

  if (offer) {
    return (
      <>
        <PageHeader
          headline={t('offer')}
          description={renderDescription()}
          actionButtonOne={renderBackButton()}
          actionButtonTwo={<DetailsActionButton offer={offer} onDownloading={setLoading} />}
        />

        <Panel>
          <DisplayText caption={t('offerNumber')} horizontal={true}>
            {offer.offerNumber}
          </DisplayText>
          <DisplayText caption={t('created')} horizontal={true}>
            {moment(offer.createdDate).format('LLL')}
          </DisplayText>
          <DisplayText caption={t('createdFrom')} horizontal={true}>
            {userDisplayName(getUser(offer.createdUserId), true)}
          </DisplayText>
          <DisplayText caption={t('validUntil')} horizontal={true}>
            {moment(offer.validUntil).format('LLL')}
          </DisplayText>
          <DisplayText caption={t('contactPerson')} horizontal={true}>
            {userDisplayName(user)}
          </DisplayText>
        </Panel>

        <Panel headline={t('positions')}>
          <PositionRows positions={offer.positions} />
        </Panel>

        {renderAdditionalInformation()}

        <Panel>
          <DisplayText horizontal caption={t('print.invoice.overallNetto')}>
            {formatCurrency(offer.priceOverall)}
          </DisplayText>

          <DisplayText horizontal caption={t('invoiceTaxCaption')}>
            {formatCurrency(offer.tax)}
          </DisplayText>

          <DisplayText horizontal caption={t('offerOverallPrice')} bold>
            {formatCurrency((parseFloat(offer.priceOverall) + parseFloat(offer.tax || '0')).toString())}
          </DisplayText>
        </Panel>

        {loadingModal}
      </>
    );
  }
  return null;
}
