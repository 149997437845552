import React from 'react';
import { AccessPath, useHasAccess } from '../../../Globals/Access/Groups';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useLocation, useNavigate } from 'react-router';
import Text from '../../Text';

const Container = styled.div<{ iconColor?: string; disabled: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  padding: 10px;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) => (props.disabled ? 'opacity: 0.5;' : null)};

  > i {
    color: ${(props) => props.iconColor || '#606060'};
    font-size: 16px;
    margin-right: 10px;
  }

  &.active,
  &:hover {
    background-color: ${(props) => (!props.disabled ? lighten(0.25, props.theme.color.textGray) : null)};
  }
`;

interface Props {
  visible: boolean;
  accessPath?: AccessPath;
  icon: string;
  caption: string;
  path?: string;
  onPress?: () => void;
  disabled: boolean;
  iconColor?: string;
  subCaption?: string | React.ReactElement;
}

/**
 * SubNavigationItem()
 * @param props
 * @constructor
 */
export default function SubNavigationItem(props: Props) {
  const { visible, accessPath, icon, path, onPress, disabled, caption, iconColor, subCaption } = props;
  const isAllowed = useHasAccess(accessPath);
  const navigation = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (path) {
      setIsActive(location.pathname.lastIndexOf(path) >= 0);
    } else {
      setIsActive(false);
    }
  }, [location, path]);

  const handleNavigation = () => {
    if (path && !onPress) {
      navigation(path);
    } else {
      onPress();
    }
  };

  const renderSubCaption = () => {
    if (subCaption) {
      return (
        <Text small light containerStyle={{ marginTop: -4 }}>
          {subCaption}
        </Text>
      );
    }
    return null;
  };

  if (visible && (!accessPath || isAllowed)) {
    return (
      <Container
        onClick={!disabled ? handleNavigation : () => {}}
        className={isActive ? 'active' : null}
        iconColor={iconColor}
        disabled={disabled}
      >
        <i className={icon} />
        <div>
          {caption}
          {renderSubCaption()}
        </div>
      </Container>
    );
  }
  return null;
}

SubNavigationItem.defaultProps = {
  disabled: false,
  visible: true,
};
