import React from 'react';
import Panel from '../../../../Panel';
import { OrderCustomerAddress } from '../../../../../Globals/Types/OrderTypes';
import { useTranslation } from 'react-i18next';
import DisplayText from '../../../../View/DisplayText';
import { Col, Row } from 'react-bootstrap';
import EditModal from './EditModal';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import { customerDisplayName } from '../../../../../Globals/Functions';

interface Props {
  customer: OrderCustomerAddress;
  onChange: (item: OrderCustomerAddress) => void;
  headline?: string;
}

export default function CustomerAddress(props: Props) {
  const { customer, headline, onChange } = props;
  const { allowEditAll, allowEditCustomerAddress } = useAppSelector((state) => state.order.selectedOrder.permissions);
  const [canEdit] = React.useState<boolean>(allowEditAll || allowEditCustomerAddress);
  const [t] = useTranslation();
  const [showEdit, setShowEdit] = React.useState<boolean>(false);

  const renderMail = () =>
    customer.mail ? (
      <Col xl={6}>
        <DisplayText icon="fas fa-envelope">{customer.mail}</DisplayText>
      </Col>
    ) : null;
  const renderPhone = () =>
    customer.phone ? (
      <Col xl={6}>
        <DisplayText icon="fas fa-phone">{customer.phone}</DisplayText>
      </Col>
    ) : null;
  const renderMobile = () =>
    customer.mobile ? (
      <Col xl={6}>
        <DisplayText icon="far fa-mobile">{customer.mobile}</DisplayText>
      </Col>
    ) : null;
  const renderComment = () =>
    customer.comment ? (
      <DisplayText caption={t('additionalInformation')} marginTop>
        {customer.comment}
      </DisplayText>
    ) : null;
  const renderParkingInfo = () => <DisplayText icon="fas fa-car">{t(`parking.${customer.parking}`)}</DisplayText>;

  const renderFloor = () => {
    switch (customer.floor) {
      case 0:
        return t('floorZero');
      default: {
        if (customer.hasElevator) {
          return t('floorDescriptionWithElevator', { number: customer.floor });
        }
        return t('floorDescriptionWithoutElevator', { number: customer.floor });
      }
    }
  };

  const renderNotifyBeforeArrival = () => {
    if (customer.notifyBeforeArrival) {
      return (
        <DisplayText marginTop icon="fa fa-bell-on">
          {t('modules.order.notifyBeforeArrivalMinutes_other', { count: customer.notifyBeforeArrival })}
        </DisplayText>
      );
    }

    return (
      <DisplayText marginTop icon="fa fa-bell-slash">
        {t('modules.order.notifyBeforeArrivalMinutes_zero')}
      </DisplayText>
    );
  };

  const handleOnChange = (data: OrderCustomerAddress) => {
    setShowEdit(false);
    onChange(data);
  };

  return (
    <Panel headline={headline || t('deliveryAddress')} onEdit={canEdit ? () => setShowEdit(true) : null}>
      <address>
        <DisplayText caption={t('address')}>
          <strong>{customerDisplayName(customer)}</strong>
          <div>{`${customer.street} ${customer.streetNo}`}</div>
          <div>{`${customer.zip} ${customer.city}`}</div>
          <div>{`${t('language')}: ${t(`languageCodes.${customer.countryCode}`)}`}</div>
        </DisplayText>

        <DisplayText marginTop>{renderFloor()}</DisplayText>
        {renderParkingInfo()}
        {renderNotifyBeforeArrival()}

        <Row>
          {renderMail()}
          {renderMobile()}
          {renderPhone()}
        </Row>

        {renderComment()}
      </address>

      <EditModal address={customer} onSave={handleOnChange} onClose={() => setShowEdit(false)} visible={showEdit} />
    </Panel>
  );
}
