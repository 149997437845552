import React from 'react';
import { Report } from '../../../Globals/Types/Report';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import DisplayText from '../../View/DisplayText';
import { formatCurrency } from '../../../Globals/Functions';
import { Col, Row } from 'react-bootstrap';
import { PaymentType } from '../../../Globals/Types/Enums';

interface Props {
  report: Report;
}

/**
 * PaymentBox()
 * @param props
 * @constructor
 */
export default function PaymentBox(props: Props) {
  const { report } = props;
  const [t] = useTranslation();

  const renderBank = () => {
    if (report.payment.paymentType === PaymentType.debit) {
      return (
        <>
          <Col xl={6}>
            <DisplayText caption={t('bankOwner')}>{report.payment.bankOwner}</DisplayText>
          </Col>
          <Col xl={6}>
            <DisplayText caption={t('bankName')}>{report.payment.bankName}</DisplayText>
          </Col>
          <Col xl={6}>
            <DisplayText caption={t('bankIban')}>{report.payment.bankIban}</DisplayText>
          </Col>
          <Col xl={6}>
            <DisplayText caption={t('bankBic')}>{report.payment.bankBic}</DisplayText>
          </Col>
        </>
      );
    }
    return null;
  };

  if (report) {
    if (report.payment && report.payment.customerPaidValue > 0) {
      return (
        <Panel headline={t('payment')} description={t('modules.report.payment.payedValueDescription')}>
          <Row>
            <Col xl={6}>
              <DisplayText caption={t('paymentType')}>
                {t(`paymentTypeOptions.${report.payment.paymentType}`)}
              </DisplayText>
            </Col>
            <Col xl={6}>
              <DisplayText caption={t('payedValue')}>
                {formatCurrency(report.payment.customerPaidValue.toString())}
              </DisplayText>
            </Col>
            {renderBank()}
          </Row>
        </Panel>
      );
    }
  }
  return null;
}
