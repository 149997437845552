import { State, ValidateErrors } from './CreateTourModal';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';

/**
 * isValid()
 * @param data
 */
const isValid = (data: State) => {
  const { dates, fromTemplate, tour, tourTemplateIds } = data;
  const errors = {} as ValidateErrors;

  if (!dates || dates.length === 0) {
    errors.dates = InternalErrorCodes.FIELD_MISSING;
  }

  if (fromTemplate) {
    if (!tourTemplateIds || tourTemplateIds.length === 0) {
      errors.tourTemplateIds = InternalErrorCodes.FIELD_MISSING;
    }
  } else {
    if (!tour.name || tour.name.length === 0) {
      errors.name = InternalErrorCodes.FIELD_MISSING;
    }

    if (!tour.facilityIds || tour.facilityIds.length === 0) {
      errors.facilityIds = InternalErrorCodes.FIELD_MISSING;
    }
  }

  return { errors, isValid: Object.keys(errors).length === 0 };
};

export default isValid;
