import React from 'react';
import styled from 'styled-components';
import { formatDateString } from '../../../Globals/Functions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SelectDay from './SelectDay';
import ShowHideContainer from '../../ShowHideContainer';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.border.color};
  z-index: 1000;
  background-color: #ffffff;

  .Right {
    display: flex;
    flex-direction: row;
  }

  .Left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-grow: 1;

    .Date {
      font-size: 20px;
      font-weight: bolder;
      padding-left: 6px;
      color: ${(props) => props.theme.color.primaryColor};
    }

    .DateString {
      display: flex;
      align-items: center;
      flex-direction: row;

      > .Light {
        font-weight: lighter;
        font-size: 16px;
      }

      > .Spacer {
        margin: 0 10px;
      }
    }
  }

  .Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    padding: 15px 20px;
    cursor: pointer;
    color: ${(props) => props.theme.color.primaryColor};

    &.Small {
      font-size: 14px;
      padding: 10px 15px;
    }

    &:hover {
      background-color: ${(props) => props.theme.color.primaryColor};
      color: #ffffff;
    }
  }
`;

interface Props {
  date: string;
  onChange: (date: string) => void;
  showWeekNumber: boolean;
  isWeekView: boolean;
}

/**
 * CalendarHeader
 * @param props
 * @constructor
 */
export default function CalendarHeader(props: Props) {
  const { date, onChange, showWeekNumber, isWeekView } = props;
  const [t] = useTranslation();

  const onNext = (amount: number) => {
    onChange(moment(date).add(amount, 'day').format('YYYY-MM-DD'));
  };

  const onPrev = (amount: number) => {
    onChange(moment(date).subtract(amount, 'day').format('YYYY-MM-DD'));
  };

  const renderToday = () => {
    if (!moment(date).isSame(moment(), 'date')) {
      if (!showWeekNumber) {
        return (
          <div className="Icon" style={{ marginLeft: 40 }} onClick={() => onChange(moment().format('YYYY-MM-DD'))}>
            <i className="far fa-calendar-day" style={{ marginRight: 6 }} />
            {t('today')}
          </div>
        );
      }
    }
    return null;
  };

  const renderDateString = () => {
    if (showWeekNumber) {
      return (
        <div className="DateString">
          <div>{t('weekNumberShort', { week: moment(date).format('WW') })}</div>
          <div className="Spacer">|</div>
          <div className="Light">{moment(date).format('MMM YYYY')}</div>
        </div>
      );
    }
    return (
      <div className="DateString">
        <div>{t('weekNumberShort', { week: moment(date).format('WW') })}</div>
        <div className="Spacer">|</div>
        <div className="Light">{formatDateString(date)}</div>
      </div>
    );
  };

  return (
    <Container>
      <div className="Left">
        <SelectDay date={date} onChange={onChange} />
        <div className="Date">{renderDateString()}</div>
      </div>
      <div className="Right">
        {renderToday()}
        <ShowHideContainer visible={!isWeekView}>
          <div className="Icon" onClick={() => onPrev(1)}>
            <i className="fas fa-angle-left" />
          </div>
        </ShowHideContainer>
        <div className="Icon" onClick={() => onPrev(7)}>
          <i className="fas fa-angles-left" />
        </div>
        <div className="Icon" onClick={() => onNext(7)}>
          <i className="fas fa-angles-right" />
        </div>
        <ShowHideContainer visible={!isWeekView}>
          <div className="Icon" onClick={() => onNext(1)}>
            <i className="fas fa-angle-right" />
          </div>
        </ShowHideContainer>
      </div>
    </Container>
  );
}

CalendarHeader.defaultProps = {
  showWeekNumber: false,
};
