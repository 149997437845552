import { OrderEntity } from '../../../../../Globals/Types/OrderTypes';
import { InternalErrorCodes } from '../../../../../Globals/InternalErrorCodes';
import { isValidPrice } from '../../../../../Globals/Functions';

export interface ValidateOrderOptionsResult {
  result: boolean;
  errors: {
    externalId: string;
    balance: string;
  };
}

/**
 * validateOrderOptions
 * @param data
 */
export const validateEditData = (data: Partial<OrderEntity>): ValidateOrderOptionsResult => {
  let returnValue: ValidateOrderOptionsResult = {
    result: true,
    errors: {
      externalId: undefined,
      balance: undefined,
    },
  };

  if (!data.externalId || data.externalId.length === 0) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, externalId: InternalErrorCodes.FIELD_MISSING },
    };
  }
  if (data.balance && !isValidPrice(data.balance)) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, balance: InternalErrorCodes.PRICE_INVALID },
    };
  }

  return returnValue;
};
