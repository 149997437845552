import React from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { useDispatchGetFacilities } from '../../../Redux/Actions/Client/FacilityAction';
import { useSubscriptionIsValid } from '../../../Globals/Hooks/SubscriptionHooks';
import SubNavigationHeadline from './SubNavigationHeadline';
import SubNavigationItem from './SubNavigationItem';
import { AccessPath } from '../../../Globals/Access/Groups';
import { FacilityEntity } from '../../../Globals/Types/Types';
import Spacer from './Spacer';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

/**
 * SettingsSubNavigation()
 * @constructor
 */
export default function SettingsSubNavigation() {
  const { facilities } = useAppSelector((state) => state.client);
  const { clientId } = useAppSelector((state) => state.auth);
  const { allowUseFacilities, timeTracking } = useAppSelector((state) => state.auth.client.settings);
  const [t] = useTranslation();
  const dispatchGetListFacilities = useDispatchGetFacilities();
  const { subscriptionValid } = useSubscriptionIsValid();

  React.useEffect(() => {
    dispatchGetListFacilities(clientId).then(() => {});
  }, [clientId, dispatchGetListFacilities]);

  const renderFacilities = () => {
    if (facilities) {
      return facilities.map((item: FacilityEntity) => (
        <SubNavigationItem
          icon="far fa-building"
          caption={item.name}
          path={`/settings/facility/${item.facilityId}`}
          key={Math.random()}
          disabled={!subscriptionValid}
        />
      ));
    }
    return null;
  };

  const renderAddFacilities = () => {
    if (allowUseFacilities) {
      return (
        <SubNavigationItem
          icon="far fa-plus-circle"
          caption={t('createFacility')}
          path="createFacility"
          accessPath={AccessPath.settingsFacilityCreate}
          disabled={!subscriptionValid}
        />
      );
    }
    return null;
  };

  return (
    <Container>
      <SubNavigationHeadline headline={t('settings')} />

      {renderFacilities()}
      {renderAddFacilities()}

      <Spacer />

      <SubNavigationItem icon="far fa-cog" caption={t('general')} path="advanced" visible={true} />
      <SubNavigationItem
        icon="far fa-user"
        caption={t('userManagement')}
        path="users"
        accessPath={AccessPath.settingsUsers}
        disabled={!subscriptionValid}
        visible={true}
      />
      <SubNavigationItem
        icon="far fa-screwdriver-wrench"
        caption={t('orders')}
        path="orders"
        disabled={!subscriptionValid}
      />

      <SubNavigationItem
        icon="far fa-calendar-circle-user"
        caption={t('absenceCalendar')}
        path="absence"
        disabled={!subscriptionValid}
      />
      <SubNavigationItem
        icon="far fa-warehouse"
        caption={t('storageManagement')}
        path="storage"
        disabled={!subscriptionValid}
      />
      <SubNavigationItem
        icon="far fa-layer-group"
        caption={t('positions')}
        path="positions"
        disabled={!subscriptionValid}
      />
      <SubNavigationItem
        icon="far fa-truck"
        caption={t('modules.settings.resources.management')}
        path="resources"
        disabled={!subscriptionValid}
      />
      <SubNavigationItem
        icon="far fa-road"
        caption={t('modules.settings.tour.headline')}
        path="tour"
        disabled={!subscriptionValid}
      />
      <SubNavigationItem
        icon="far fa-stopwatch"
        caption={t('timeTracking')}
        path="timeTracking"
        visible={timeTracking && timeTracking.active}
        disabled={!subscriptionValid}
        accessPath={AccessPath.settingsTimeTracking}
      />
      <SubNavigationItem
        icon="far fa-badge-dollar"
        caption={t('subscription')}
        path="subscription"
        accessPath={AccessPath.settingsSubscription}
      />
    </Container>
  );
}
