import React from 'react';
import { OrderEntity, OrderPartnerPermission } from '../../../../Globals/Types/OrderTypes';
import Panel from '../../../Panel';
import { useTranslation } from 'react-i18next';
import PartnerContainer from './PartnerContainer';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { AssignedFacilities } from '../../../../Redux/Reducer/OrderReducer';
import Lodash from 'lodash';

interface Props {
  order: OrderEntity;
  onChange: (item: Partial<OrderEntity>) => void;
}

/**
 * PartnerPermissions
 * @param props
 * @constructor
 */
export default function PartnerPermissions(props: Props) {
  const { order, onChange } = props;
  const { user } = useAppSelector((state) => state.auth);
  const [externalFacilities, setExternalFacilities] = React.useState<AssignedFacilities[]>(null);
  const { allFacilities } = useAppSelector((state) => state.order.selectedOrder);
  const [t] = useTranslation();

  React.useEffect(() => {
    if (allFacilities) {
      const filtered = allFacilities.filter((item) => item.clientId !== user.clientId);
      setExternalFacilities(filtered);
    }
  }, [allFacilities, user.clientId]);

  const handleOnChange = (newAccess: OrderPartnerPermission) => {
    if (order.partnerPermissions) {
      const found = Lodash.find(order.partnerPermissions, (item) => item.facilityId === newAccess.facilityId);
      if (found) {
        const mapped: OrderPartnerPermission[] = order.partnerPermissions.map((item) =>
          item.facilityId === newAccess.facilityId ? newAccess : item,
        );
        onChange({ partnerPermissions: mapped });
      } else {
        onChange({ partnerPermissions: [...order.partnerPermissions, newAccess] });
      }
    } else {
      onChange({ partnerPermissions: [newAccess] });
    }
  };

  const renderContent = () => {
    return externalFacilities.map((item) => (
      <PartnerContainer
        permissions={order.partnerPermissions}
        facility={item}
        order={order}
        onChange={handleOnChange}
        key={item.facilityId}
      />
    ));
  };

  if (user.clientId === order.ownerClientId && externalFacilities && externalFacilities.length > 0) {
    return <Panel headline={t('modules.order.partnerPermissions')}>{renderContent()}</Panel>;
  }
  return null;
}
