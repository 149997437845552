import React from 'react';
import Panel from '../../Panel';
import { InvoiceEntity } from '../../../Globals/Types/Invoice';
import styled from 'styled-components';
import { darken } from 'polished';
import { formatCurrency } from '../../../Globals/Functions';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  align-content: center;
  padding: 15px;
  background-color: ${(props) => props.theme.color.backgroundGray};
  border-radius: ${(props) => props.theme.border.radius};
  border-color: ${(props) => darken(0.05, props.theme.color.backgroundGray)};
  border-width: 0.5px;
  border-style: solid;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .Left {
    flex-grow: 1;
  }

  &.Summary {
    font-size: 24px;
    font-weight: bold;
  }
`;

interface Props {
  invoice: InvoiceEntity;
}

/**
 * Summary()
 * @param props
 * @constructor
 */
export default function Summary(props: Props) {
  const { invoice } = props;
  const [t] = useTranslation();

  const renderTax = () => {
    if (!invoice.isTaxFree) {
      return (
        <div className="d-flex flex-row">
          <div className="flex-grow-1">{`${t('invoiceTaxCaption')}`}</div>
          <div>{formatCurrency(invoice.tax)}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <Panel>
      <Container>
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-row">
            <div className="flex-grow-1">{`${t('invoiceNettoOverall')}`}</div>
            <div>{formatCurrency(invoice.priceOverall)}</div>
          </div>
          {renderTax()}
        </div>
      </Container>
      <Container className="Summary">
        <div className="flex-grow-1">{t('invoiceOverallPrice')}</div>
        <div>{formatCurrency((parseFloat(invoice.tax) + parseFloat(invoice.priceOverall)).toFixed(2))}</div>
      </Container>
    </Panel>
  );
}
