import React from 'react';
import { useAppSelector, usePageTitle } from '../../../Globals/Hooks/Hooks';
import Headline from './Components/Headline';
import Panel from '../../../Components/Panel';
import { useTranslation } from 'react-i18next';
import { useDispatchOrderTimeTrackingGetList } from '../../../Redux/Actions/Order/TimeTrackingAction';
import TimeTrackingTable from '../../../Components/Order/TimeTrackingTable';
import { Alert } from 'react-bootstrap';
import { timeTrackingOrderCalculateOverall } from '../../../Globals/Hooks/TimeTrackingHooks';
import { convertMinutesToTimeString } from '../../../Globals/Functions';

/**
 * TimeTracking
 * @constructor
 */
export default function TimeTracking() {
  const [t] = useTranslation();
  const { order, timeTrackings } = useAppSelector((state) => state.order.selectedOrder);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const dispatchGetList = useDispatchOrderTimeTrackingGetList();
  usePageTitle(order ? order.externalId : '');

  React.useEffect(() => {
    if ((!timeTrackings || timeTrackings.length <= 0) && !loaded && order) {
      dispatchGetList(order).then(() => setLoaded(true));
    }
  }, [dispatchGetList, loaded, order, timeTrackings]);

  const renderContent = () => {
    if (timeTrackings && timeTrackings.length > 0) {
      return (
        <Panel>
          <TimeTrackingTable timeTrackings={timeTrackings.map((item) => item.timeTracking)} />
        </Panel>
      );
    }
    return null;
  };

  const renderOverallTimeHint = () => {
    if (timeTrackings && timeTrackings.length > 0) {
      const overall = timeTrackingOrderCalculateOverall(timeTrackings.map((item) => item.timeTracking));

      return (
        <Panel>
          <div className="d-flex flex-row justify-center">
            <div className="flex-grow-1">
              <h4 style={{ margin: 0 }}>{`${t('overallWorkTime')}:`}</h4>
            </div>
            <div>
              <h4 style={{ margin: 0 }}>{`${t('xHours', { time: convertMinutesToTimeString(overall) })}`}</h4>
            </div>
          </div>
        </Panel>
      );
    }

    return null;
  };

  const renderEmpty = () => {
    if (loaded && (!timeTrackings || timeTrackings.length === 0)) {
      return (
        <Panel>
          <Alert variant={'warning'} style={{ margin: 0 }}>
            <i className="fas fa-info-circle" style={{ marginRight: 6 }} />
            {t('modules.order.timeTrackingNotAvailable')}
          </Alert>
        </Panel>
      );
    }
    return null;
  };

  return (
    <>
      <Headline order={order} headlineKey="timeTracking" />

      <Panel>{t('modules.order.timeTrackingOverallDescription')}</Panel>
      {renderOverallTimeHint()}
      {renderContent()}
      {renderEmpty()}
    </>
  );
}
