import React from "react";

/**
 * Check if the current window has focus
 */
export const useWindowHasFocus = () => {
  const [focused, setFocused] = React.useState<boolean>(true);

  React.useEffect(() => {
    window.addEventListener('focus', () => setFocused(true));
    window.addEventListener('blur', () => setFocused(false));

    return () => {
      window.removeEventListener('focus', () => setFocused(true));
      window.removeEventListener('blur', () => setFocused(false));
    }

  }, []);

  return focused;
}
