import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (value: boolean) => void;
  selected?: boolean;
  required?: boolean;
  disabled?: boolean;
  showSkeleton: boolean;
}

/**
 * SelectMailService
 * @param props
 * @constructor
 */
export default function SelectMailService(props: Props) {
  const { onChange, selected, required, disabled, showSkeleton } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>([]);

  React.useEffect(() => {
    setOptions([
      { label: t('mailServiceInternal'), value: true },
      { label: t('mailServiceManual'), value: false },
    ]);
  }, [t]);

  const handleOnChange = (value: boolean) => {
    onChange(value);
  };

  if (options && options.length > 0) {
    return (
      <Select
        label={t('mailService')}
        onChange={handleOnChange}
        initialValue={selected}
        options={options}
        required={required}
        disabled={disabled}
        showSkeleton={showSkeleton}
      />
    );
  }
  return null;
}

SelectMailService.defaultProps = {
  required: false,
  disabled: false,
  showSkeleton: false,
};
