import { Address, PickedUserEntity, ResourceEntity } from './Types';
import { CountryCode, DeliveryAndLoadingOptions, ProductType, Salutation } from './Enums';
import { PaymentInfos, ReportState } from './Report';

export interface CreateOrderState {
  order: OrderEntity;
  orderServiceType: OrderServiceType;
  generalService: ServiceEntity;
  positions?: PositionEntity[];
  executionDate: string;
  executionTime: string;
  desiredWeek: string;
  documents: Array<OrderDocument>;
  montageSingleDocument: boolean;
  estimatedAssemblyTime: number;
  isEdit: boolean;
  hasChanges: boolean;
  errors: any;
}

export enum OrderCategory {
  kitchen = 'kitchen',
  furniture = 'furniture',
}

export enum NamingConventionType {
  currentIndex,
  weekName,
}

export interface OrderServiceType {
  key: string;
  icon: string;
  caption: string;
  description?: string;
  needReport: boolean;
}

export interface OrderPartnerPermissionAccess {
  allowEditAll: boolean;
  allowEditInformation: boolean;
  allowEditCustomerAddress: boolean;
  allowEditLoadingAddress: boolean;
  allowEditAppointments: boolean;
  allowEditKitchenInformation: boolean;
  allowDocumentUpload: boolean;
  allowDocumentDelete: boolean;
  allowAddService: boolean;
  allowDeleteService: boolean;
  allowEditPosition: boolean;
}

export interface OrderPartnerPermission {
  clientId: string;
  facilityId: string;
  permissions: OrderPartnerPermissionAccess;
}

export interface PositionExternalData {
  key: string;
  value: string;
}

export interface PositionEntity {
  positionId: string;
  externalId?: string;
  articleId?: string; // id of an internal storage article if selected
  caption: string;
  description?: string;
  amount: number;
  assemblyTime?: number;
  externalData: PositionExternalData[];
  price?: string;
  invoiced: boolean;
  invoicedDate?: string;
  type: ProductType;
  taxRate: number;
}

export interface RecurringServiceInformation {
  active: boolean;
  currentIndex: number;
  days: number;
  namingConvention: NamingConventionType;
  createNewOrder: boolean;
}

export interface ServiceSettingsEntity {
  sendCustomerMail: boolean;
  needReport: boolean;
  recurring: RecurringServiceInformation;
}

export interface LoadAndDeliveryAddress extends Address {
  comment?: string;
  contactPhone?: string;
  contactName?: string;
}

export interface ServiceEntity {
  serviceId?: string;
  type: OrderServiceType;
  externalId?: string | null;
  comment?: string;
  desiredWeek?: string;
  desiredDate?: string;
  desiredExecutionTime?: string;
  estimatedAssemblyTime?: number;
  positions?: Array<PositionEntity>;
  deliveryAndLoadingOption: DeliveryAndLoadingOptions;
  loadingAddress?: LoadAndDeliveryAddress;
  deliveryAddress?: LoadAndDeliveryAddress;
  createdDate: string;
  createdUserId: string;
  assignedPartnerClientId?: string | null;
  assignedPartnerFacilityId?: string | null;
  lastUpdatedUser?: PickedUserEntity;
  reportState: ReportState;
  contacts?: ContactPersonEntity[];
  settings: ServiceSettingsEntity;
}

export interface OrderEntity {
  orderId?: string;
  externalId: string;
  originalExternalId: string; // Needed if externalId will be changed by recurring order / service
  groupId?: string;
  category: OrderCategory;
  ownerClientId: string;
  ownerFacilityId: string;
  createdClientId: string;
  createdFacilityId: string;
  createdUserId: string;
  balance: string;
  customer: OrderCustomerAddress;
  invoiceAddress: OrderInvoiceAddress;
  services?: Array<ServiceEntity>;
  notifyOnMessage?: Array<string>; // Array of userId's to be notified on new messages for the order
  createdDate: string;
  executeTrigger: boolean;
  isExternal: boolean;
  partnerPermissions?: OrderPartnerPermission[];
  deletable: boolean;
  serviceCount: number;
  lastUpdatedUser?: PickedUserEntity;
  balanceHistory?: OrderBalanceHistory[];
  contacts?: ContactPersonEntity[];
}

export interface OrderLoadingAddress extends Address {
  name: string;
  contactName?: string;
  contactPhone?: string;
  comment?: string;
}

export interface OrderInvoiceAddress extends Address {
  firstName: string;
  lastName: string;
}

export interface OrderCustomerAddress extends Address {
  customerId?: string;
  salutation?: Salutation;
  firstName: string;
  lastName: string;
  countryCode: CountryCode;
  mail?: string;
  phone?: string;
  mobile?: string;
  floor: number;
  hasElevator?: boolean;
  parking: number;
  notifyBeforeArrival?: number;
  comment?: string;
}

export enum OrderDocumentType {
  orderConfirmation = 'orderConfirmation',
  installPlan = 'installPlan',
  orderConfirmationAndInstallPlan = 'orderConfirmationAndInstallPlan',
  other = 'other',
}

export interface OrderDocument {
  serviceType: OrderServiceType | null;
  type: OrderDocumentType;
  fileName: string;
  uri: string;
}

export interface OrderMessageEntity {
  messageId: string;
  message: string;
  isPublic: boolean;
  hasAttachment: boolean;
  assignedFacilities: Array<string>;
  assignedUsers: Array<string>;
  createdDate: string;
  createdUser: PickedUserEntity;
}

export interface ElasticSearchServiceDispositionInterface {
  serviceId?: string;
  tourId: string;
  tourName: string;
  date: string;
  startTime: string;
  endTime: string;
  assemblyTime: number;
  users?: PickedUserEntity[];
  resources?: ResourceEntity[];
}

export interface ElasticSearchServiceInterface {
  createdDate: string;
  createdUserId: string;
  createdFacilityId: string;
  createdClientId: string;
  customer: OrderCustomerAddress;
  externalOrderId: string | null;
  externalServiceId?: string | null;
  orderId: string;
  serviceId: string;
  ownerClientId: string;
  ownerFacilityId: string;
  assignedClientId?: string | null;
  assignedFacilityId?: string | null;
  serviceType: OrderServiceType;
  estimatedAssemblyTime?: number;
  remainingAssemblyTime: number | null;
  desiredDate?: string | null;
  desiredWeek?: string | null;
  desiredExecutionTime?: string | null;
  reportState: ReportState;
  deliveryAndLoadingOption: DeliveryAndLoadingOptions;
  loadingAddress?: LoadAndDeliveryAddress | null;
  deliveryAddress?: LoadAndDeliveryAddress | null;
  disposition: ElasticSearchServiceDispositionInterface[] | null;
  isRecurring: boolean;
}

export interface ElasticSearchServiceEntity extends ElasticSearchServiceInterface {}

export interface OrderBalanceHistory {
  balancePrevious: string;
  balanceNew: string;
  dateTime: string;
  source: OrderBalanceHistorySource;
  user: PickedUserEntity;
  paymentInfos?: PaymentInfos;
  reportId?: string;
}

export enum OrderBalanceHistorySource {
  manual = 'MANUAL',
  report = 'REPORT',
}

export interface ContactPersonEntity {
  name: string;
  contact: string;
  userId?: string; // If is set the contact person is a selected user account
}
