import React from 'react';
import Panel from '../../../../Panel';
import { useTranslation } from 'react-i18next';
import {
  NamingConventionType,
  OrderEntity,
  ServiceEntity,
  ServiceSettingsEntity,
} from '../../../../../Globals/Types/OrderTypes';
import { Col, Row } from 'react-bootstrap';
import DisplayText from '../../../../View/DisplayText';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import EditModal from './EditModal';

interface Props {
  order: OrderEntity;
  service: ServiceEntity;
  onChange: (settings: ServiceSettingsEntity) => void;
  isEditable: boolean;
}

/**
 * Settings
 * @param props
 * @constructor
 */
export default function Settings(props: Props) {
  const { service, onChange, isEditable, order } = props;
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const { allowEditAll, allowEditInformation } = useAppSelector((state) => state.order.selectedOrder.permissions);
  const [canEdit, setCanEdit] = React.useState<boolean>((allowEditAll || allowEditInformation) && isEditable);

  React.useEffect(() => {
    setCanEdit((allowEditAll || allowEditInformation) && isEditable);
  }, [allowEditAll, allowEditInformation, isEditable]);

  const handleOnChange = (settings: ServiceSettingsEntity) => {
    setShowEdit(false);
    onChange(settings);
  };

  const getDaysString = () => {
    switch (service.settings.recurring.days) {
      case 7:
        return t('recurringDay.week', { count: 1 });
      case 14:
        return t('recurringDay.week', { count: 2 });
      case 21:
        return t('recurringDay.week', { count: 3 });
      case 28:
        return t('recurringDay.week', { count: 4 });
      default:
        return t('recurringDay.day', { count: service.settings.recurring.days });
    }
  };

  const getNamingString = () => {
    switch (service.settings.recurring.namingConvention) {
      case NamingConventionType.weekName:
        return t('namingConvention.week');
      case NamingConventionType.currentIndex:
        return t('namingConvention.currentIndex');
    }
  };

  const renderSendCustomerMail = () => {
    if (service.settings.needReport) {
      return (
        <Col lg={6}>
          <DisplayText caption={t('sendReportCustomerMail')} contextHelpKey="sendReportCustomerMail">
            {service.settings.sendCustomerMail ? t('yes') : t('no')}
          </DisplayText>
        </Col>
      );
    }
    return null;
  };

  return (
    <Panel headline={t('settings')} onEdit={canEdit ? () => setShowEdit(true) : null}>
      <Row>
        <Col lg={service.settings.needReport ? 6 : 12}>
          <DisplayText caption={t('needReport')} contextHelpKey="serviceNeedReport">
            {service.settings.needReport ? t('yes') : t('no')}
          </DisplayText>
        </Col>
        {renderSendCustomerMail()}
      </Row>
      <Row style={{ marginTop: service.settings.recurring.active ? 40 : 0 }}>
        <Col lg={12}>
          <DisplayText caption={t('recurringService')} contextHelpKey="serviceRecurring">
            {service.settings.recurring.active ? t('yes') : t('no')}
          </DisplayText>
        </Col>
        <Col lg={6}>
          <DisplayText visible={service.settings.recurring.active} caption={t('recurringEvery')}>
            {getDaysString()}
          </DisplayText>
        </Col>
        <Col lg={6}>
          <DisplayText visible={service.settings.recurring.active} caption={t('createCopyAs')}>
            {service.settings.recurring.createNewOrder
              ? t('recurringCopyOrderOption')
              : t('recurringCopyServiceOption')}
          </DisplayText>
        </Col>
        <Col lg={6}>
          <DisplayText
            visible={service.settings.recurring.active && service.settings.recurring.createNewOrder}
            caption={t('recurringNamingConvention')}
          >
            {getNamingString()}
          </DisplayText>
        </Col>
      </Row>

      <EditModal
        service={service}
        order={order}
        onSave={handleOnChange}
        onClose={() => setShowEdit(false)}
        visible={showEdit}
      />
    </Panel>
  );
}
