import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Table, { TableFieldProps } from '../../../../Components/Table/Table';
import { useAppSelector, useLoadingModal } from '../../../../Globals/Hooks/Hooks';
import PageHeader from '../../../../Components/PageHeader';
import Panel from '../../../../Components/Panel';
import {
  useDispatchStorageAddressCreateFromFacilities,
  useDispatchStorageAddressGetList,
} from '../../../../Redux/Actions/Client/Storage/AddressAction';
import { StorageAddressEntity } from '../../../../Globals/Types/Types';
import { useNavigate } from 'react-router';
import { buildUrlSettingsStorageAddress } from '../../../../Globals/UrlFunctions';

const tableMappings: Array<TableFieldProps<StorageAddressEntity>> = [
  { key: 'profileIcon', headline: '', content: <i className="fas fa-warehouse" />, width: 60 },
  { key: 'name', headline: 'caption' },
  { key: 'zip,city,street,streetNo', headline: 'address', displayPattern: '{street} {streetNo}, {zip} {city}' },
];

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const { setLoading, isLoading } = useLoadingModal();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const { addresses } = useAppSelector((state) => state.storage);
  const dispatchGetList = useDispatchStorageAddressGetList();
  const dispatchCreateFromFacilities = useDispatchStorageAddressCreateFromFacilities();

  React.useEffect(() => {
    if ((!addresses || addresses.length === 0) && !loaded && !isLoading) {
      setLoading(true);
    }
  }, [setLoading, addresses, loaded, isLoading]);

  React.useEffect(() => {
    dispatchGetList()
      .then(() => {
        setLoading(false);
        setLoaded(true);
      })
      .catch(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [dispatchGetList, setLoading]);

  const renderAddButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => navigate(buildUrlSettingsStorageAddress('create'))}>
        <i className="fas fa-plus-circle" />
      </Button>
    );
  };

  const handleCreateFromFacilities = () => {
    setLoaded(false);
    setLoading(true);

    dispatchCreateFromFacilities()
      .then(() => {
        return dispatchGetList().then(() => {
          setLoading(false);
          setLoaded(true);
        });
      })
      .catch(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const renderContent = () => {
    if (loaded && (!addresses || addresses.length === 0)) {
      return (
        <Panel>
          <div className="d-flex flex-column align-items-center">
            <div>{t('modules.settings.storage.address.missing1')}</div>
            <div>{t('modules.settings.storage.address.missing2')}</div>

            <Button variant="success" style={{ marginTop: 40 }} onClick={handleCreateFromFacilities}>
              <i className="fas fa-plus-circle" style={{ marginRight: 10 }} />
              {t('modules.settings.storage.address.createFromFacility')}
            </Button>
          </div>
        </Panel>
      );
    }
    return (
      <Panel>
        <Table
          mappings={tableMappings}
          data={addresses}
          keyField="storageId"
          isLoading={isLoading}
          onClickRow={(item: StorageAddressEntity) => navigate(buildUrlSettingsStorageAddress(item.storageId))}
        />
      </Panel>
    );
  };

  return (
    <>
      <PageHeader headline={t('storageAddress')} actionButtonOne={renderAddButton()} />
      {renderContent()}
    </>
  );
}
