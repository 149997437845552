import React from 'react';
import { useDispatchCustomerInvoicesGetList } from '../../../Redux/Actions/Customer/InvoicesAction';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import PageHeader from '../../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import Panel from '../../../Components/Panel';
import { Button } from 'react-bootstrap';
import { buildUrlCustomersInvoiceCreate } from '../../../Globals/UrlFunctions';
import ListView from '../../../Components/Invoice/ListView';
import styled, { useTheme } from 'styled-components';

const HintText = styled.div`
  text-align: center;
  color: ${(props) => props.theme.color.textGray};
`;

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const theme = useTheme();
  const { isLoading, setLoading } = useLoadingModal();
  const { customerId } = useParams();
  const [invoicesLoaded, setInvoicesLoaded] = React.useState<boolean>(false);
  const { invoices } = useAppSelector((state) => state.client.customers.selectedCustomer);
  const dispatchGetList = useDispatchCustomerInvoicesGetList();

  React.useEffect(() => {
    if (!invoicesLoaded) {
      setLoading(true);
      dispatchGetList(customerId)
        .then(() => setInvoicesLoaded(true))
        .finally(() => setLoading(false));
    }
  }, [customerId, dispatchGetList, invoicesLoaded, setLoading]);

  const renderAddButton = () => {
    return (
      <Button
        variant="success"
        onClick={() => navigate(buildUrlCustomersInvoiceCreate(customerId))}
        style={{ marginLeft: 10 }}
      >
        {t('invoiceCreate')}
      </Button>
    );
  };

  const renderLoading = () => {
    if (isLoading) {
      return (
        <i className="fas fa-spin fa-sync" style={{ color: theme.color.textGray, fontSize: 24, marginRight: 15 }} />
      );
    }
  };

  const renderContent = () => {
    if (invoicesLoaded) {
      if (invoices && invoices.length > 0) {
        return (
          <Panel>
            <ListView invoices={invoices} />
          </Panel>
        );
      }

      return (
        <Panel>
          <HintText>{t('modules.customers.noInvoicesAvailable')}</HintText>
        </Panel>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader
        headline={t('invoices')}
        description={t('modules.customers.invoiceDescription')}
        actionButtonTwo={renderAddButton()}
        actionButtonOne={renderLoading()}
      />
      {renderContent()}
    </>
  );
}
