import React from 'react';
import {
  OrderEntity,
  OrderPartnerPermission,
  OrderPartnerPermissionAccess,
} from '../../../../Globals/Types/OrderTypes';
import DisplayText from '../../../View/DisplayText';
import { useTranslation } from 'react-i18next';
import { useDispatchGetExternalFacility } from '../../../../Redux/Actions/Client/FacilityAction';
import { FacilityEntity } from '../../../../Globals/Types/Types';
import { AssignedFacilities } from '../../../../Redux/Reducer/OrderReducer';
import Lodash from 'lodash';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import EditModal from './EditModal';

interface Props {
  order: OrderEntity;
  facility: AssignedFacilities;
  permissions: OrderPartnerPermission[];
  onChange: (item: OrderPartnerPermission) => void;
}

/**
 * PartnerContainer
 * @param props
 * @constructor
 */
export default function PartnerContainer(props: Props) {
  const { permissions, facility, onChange, order } = props;
  const [t] = useTranslation();
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const { clientId } = useAppSelector((state) => state.auth);
  const [facilityEntity, setFacilityEntity] = React.useState<FacilityEntity>(null);
  const [permissionEntity, setPermissionEntity] = React.useState<OrderPartnerPermission>(null);
  const dispatchGetPartner = useDispatchGetExternalFacility();

  React.useEffect(() => {
    if (facility.clientId !== clientId) {
      dispatchGetPartner(facility.clientId, facility.facilityId).then(setFacilityEntity);
    }
  }, [clientId, dispatchGetPartner, facility.clientId, facility.facilityId]);

  React.useEffect(() => {
    if (permissions && facilityEntity) {
      const found = Lodash.find(permissions, (item) => item.facilityId === facilityEntity.facilityId);
      setPermissionEntity(found);
    }
  }, [facilityEntity, permissions]);

  const buildPermissionString = () => {
    if (permissionEntity) {
      const { permissions: intPermissions } = permissionEntity;

      let stringArray = [];
      if (intPermissions.allowEditAll) {
        return t('modules.order.permissions.allowEditAll');
      }

      stringArray.push(
        intPermissions.allowEditAppointments ? t('modules.order.permissions.allowEditAppointments') : null,
      );
      stringArray.push(
        intPermissions.allowEditCustomerAddress ? t('modules.order.permissions.allowEditCustomerAddress') : null,
      );
      stringArray.push(intPermissions.allowDocumentUpload ? t('modules.order.permissions.allowDocumentUpload') : null);
      stringArray.push(intPermissions.allowDocumentDelete ? t('modules.order.permissions.allowDocumentDelete') : null);
      stringArray.push(
        intPermissions.allowEditLoadingAddress ? t('modules.order.permissions.allowEditLoadingAddress') : null,
      );
      stringArray.push(
        intPermissions.allowEditInformation ? t('modules.order.permissions.allowEditInformation') : null,
      );
      stringArray.push(intPermissions.allowAddService ? t('modules.order.permissions.allowAddService') : null);
      stringArray.push(
        intPermissions.allowEditKitchenInformation ? t('modules.order.permissions.allowEditKitchenInformation') : null,
      );
      stringArray.push(intPermissions.allowEditPosition ? t('modules.order.permissions.allowEditPosition') : null);
      stringArray.push(intPermissions.allowDeleteService ? t('modules.order.permissions.allowDeleteService') : null);

      stringArray = stringArray.filter((item) => item !== null);
      if (stringArray.length > 0) {
        return stringArray.join(' *** ');
      }
      return t('modules.order.permissions.disallowAll');
    }
    return t('modules.order.permissions.allowEditAll');
  };

  const renderEditIcon = () => {
    return <i className="fas fa-pencil" style={{ marginLeft: 20 }} onClick={() => setShowEdit(true)} />;
  };

  const handleOnChange = (data: OrderPartnerPermissionAccess) => {
    setShowEdit(false);

    if (permissions) {
      const mapped: OrderPartnerPermission = {
        clientId: facility.clientId,
        facilityId: facility.facilityId,
        permissions: data,
      };
      onChange(mapped);
    } else {
      onChange({
        clientId: facility.clientId,
        facilityId: facility.facilityId,
        permissions: data,
      });
    }
  };

  if (facilityEntity) {
    return (
      <>
        <DisplayText caption={facilityEntity.name} rightAction={renderEditIcon()}>
          {buildPermissionString()}
        </DisplayText>

        <EditModal
          order={order}
          partner={facilityEntity}
          permissions={permissionEntity ? permissionEntity.permissions : null}
          onSave={handleOnChange}
          onClose={() => setShowEdit(false)}
          visible={showEdit}
        />
      </>
    );
  }
  return null;
}
