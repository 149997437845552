import React from 'react';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';
import DisplayText from '../../../../Components/View/DisplayText';
import { useTimeTrackingGetIcon } from '../../../../Globals/Functions/TimeTrackingFunctions';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  justify-content: center;

  > i {
    margin-right: 8px;
  }

  .Header {
    color: ${(props) => props.theme.color.textGray};
  }

  .State {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
  }
`;

/**
 * CurrentStateHint()
 * @constructor
 */
export default function CurrentStateHint() {
  const [t] = useTranslation();
  const { lastState } = useAppSelector((state) => state.user.timeTracking);
  const getIcon = useTimeTrackingGetIcon(true, 20);

  const getText = () => {
    const lastTimeStamp = lastState.timestamp as Timestamp;

    const date = moment(lastTimeStamp.toDate()).format('LL');
    const time = moment(lastTimeStamp.toDate()).format('LT');

    const today = moment().format('YYYY-MM-DD');
    const lastDate = moment(lastTimeStamp.toDate()).format('YYYY-MM-DD');

    const count = moment(today).diff(lastDate, 'day');

    return t(`timeTrackingStatus.${lastState.type}`, { date, time, count });
  };

  if (lastState) {
    return (
      <DisplayText>
        <Container>
          <div className="Header">{t('currentState')}</div>
          <div className="State">
            {getIcon(lastState.type)}
            {getText()}
          </div>
        </Container>
      </DisplayText>
    );
  }
  return null;
}
