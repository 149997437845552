import { State } from './Register';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { isValidateEmail } from '../../../Globals/Functions';
import { PASSWORD_MIN_LENGTH } from '../../../Globals/Globals';

export type RegisterValidateErrors = {
  name?: string;
  companyType?: string;
  firstname?: string;
  lastname?: string;
  mail?: string;
  password?: string;
  repeat?: string;
  code?: string;
};

export type ValidationResult = {
  isValid: boolean;
  errors: Object;
};

/**
 * Validate register form inputs
 * @param data
 */
const validateRegister = (data: State): ValidationResult => {
  let errors: RegisterValidateErrors = {};

  if (!data.name || data.name.length <= 0) {
    errors = { ...errors, name: InternalErrorCodes.REGISTER_COMPANY_NAME_INVALID };
  }

  if (!data.firstname || data.firstname.length <= 0) {
    errors = { ...errors, firstname: InternalErrorCodes.FIELD_MISSING };
  }

  if (!data.lastname || data.lastname.length <= 0) {
    errors = { ...errors, lastname: InternalErrorCodes.FIELD_MISSING };
  }

  if (!data.mail || data.mail.length <= 0) {
    errors = { ...errors, mail: InternalErrorCodes.FIELD_MISSING };
  } else {
    if (!isValidateEmail(data.mail)) {
      errors = { ...errors, mail: InternalErrorCodes.MAIL_INVALID };
    }
  }

  if (!data.password || data.password.length <= 0) {
    errors = { ...errors, password: InternalErrorCodes.FIELD_MISSING };
  } else {
    if (data.password.length < PASSWORD_MIN_LENGTH) {
      errors = { ...errors, password: InternalErrorCodes.REGISTER_PASSWORD_INVALID };
    }
  }

  if (!data.repeat || data.repeat.length <= 0) {
    errors = { ...errors, repeat: InternalErrorCodes.FIELD_MISSING };
  }

  if (data.password && data.repeat) {
    if (data.password !== data.repeat) {
      errors = { ...errors, repeat: InternalErrorCodes.REGISTER_PASSWORD_NO_MATCH };
    }
  }

  if (!data.companyType) {
    errors = { ...errors, companyType: InternalErrorCodes.FIELD_MISSING };
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export default validateRegister;
