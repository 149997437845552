import React, { MouseEvent } from 'react';
import { PositionEntity } from '../../../../Globals/Types/OrderTypes';
import styled from 'styled-components';
import { darken } from 'polished';
import AskDeleteModal from '../../../Modals/AskDeleteModal';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../../Globals/Functions';
import { ProductType } from '../../../../Globals/Types/Enums';
import ShowHideContainer from '../../../ShowHideContainer';

interface Props {
  position: PositionEntity;
  onDelete: (item: PositionEntity) => void;
  onClick: (item: PositionEntity) => void;
  index: number;
  allowEdit: boolean;
  allowDelete: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 15px;
  background-color: ${(props) => props.theme.color.backgroundGray};
  border-color: ${(props) => darken(0.05, props.theme.color.backgroundGray)};
  border-width: 0.5px;
  border-style: solid;
  border-radius: ${(props) => props.theme.border.radius};
  margin-bottom: 10px;
  cursor: pointer;

  .Left {
    display: flex;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-weight: bold;
    font-size: 22px;
    color: ${(props) => props.theme.color.backgroundGray};
    background-color: ${(props) => darken(0.15, props.theme.color.backgroundGray)};
    min-width: 50px;
    height: 50px;
    border-radius: 25px;
  }

  .Right {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;

    .Caption {
      font-weight: 500;
    }
    .Description,
    .AssemblyTime {
      display: flex;
      font-size: 14px;
      color: ${(props) => props.theme.color.textGray};

      > .Spacer {
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }

  .Action {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: 15px;
    padding-left: 15px;
    color: ${(props) => props.theme.color.textGrayLight};
    border-left: 1px solid ${(props) => darken(0.1, props.theme.color.backgroundGray)};

    &:hover {
      color: ${(props) => props.theme.color.red};
    }
  }

  &:hover {
    .Left {
      color: #ffffff;
      background-color: ${(props) => darken(0.7, props.theme.color.backgroundGray)};
    }
    .Right {
      .Caption {
        color: ${(props) => props.theme.color.textDark};
      }
    }
  }
`;

/**
 * DefaultPositionRow
 * @param props
 * @constructor
 */
export default function DefaultPositionRow(props: Props) {
  const { position, onDelete, index, onClick, allowEdit, allowDelete } = props;
  const [t] = useTranslation();
  const [askDelete, setAskDelete] = React.useState<boolean>(false);

  const renderDescription = () =>
    position.description ? (
      <div className="Description" style={{ marginBottom: 10 }}>
        {position.description}
      </div>
    ) : null;

  const renderPrice = () => {
    if (position.price) {
      return (
        <div className="AssemblyTime">
          <div>{t(`productTypes.${position.type}`)}</div>
          <ShowHideContainer visible={position.type === ProductType.service}>
            <div className="Spacer">/</div>
            <div>
              <i className="fas fa-stopwatch" style={{ marginRight: 6 }} />
              {t('estimatedAssemblyTimeWithMinutes', { minute: position.assemblyTime })}
            </div>
          </ShowHideContainer>
          <div className="Spacer">/</div>
          <div>
            <i className="fas fa-circle-euro" style={{ marginRight: 6 }} />
            {formatCurrency(position.price)}
          </div>
        </div>
      );
    }
    return null;
  };

  const handleOnDelete = (e: MouseEvent<HTMLElement>) => {
    setAskDelete(true);
  };

  const finalDelete = () => {
    setAskDelete(false);
    onDelete(position);
  };

  const renderDeleteIcon = () =>
    allowEdit && allowDelete ? (
      <div className="Action" onClick={handleOnDelete}>
        <i className="fa-sharp fa-light fa-trash-can" />
      </div>
    ) : null;

  return (
    <Container>
      <div className="Left" onClick={() => onClick(position)}>
        {`P${index}`}
      </div>
      <div className="Right" onClick={allowEdit ? () => onClick(position) : () => {}}>
        <div className="Caption">
          {`${position.amount}x `}
          {position.caption}
        </div>
        {renderDescription()}
        {renderPrice()}
      </div>
      {renderDeleteIcon()}

      <AskDeleteModal onDelete={finalDelete} onClose={() => setAskDelete(false)} visible={askDelete} />
    </Container>
  );
}
