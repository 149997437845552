import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../Components/PageHeader';
import { useDispatchClientTimeTrackingGetState } from '../../../Redux/Actions/Client/TimeTrackingAction';
import Panel from '../../../Components/Panel';
import Table, { TableFieldProps } from '../../../Components/Table/Table';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { TimeTrackingEntity, TimeTrackingStateEntity } from '../../../Globals/Types/Types';
import OverviewTableRow from './Components/OverviewTableRow';
import { useNavigate } from 'react-router';

const dummyTableMappings: Array<TableFieldProps<TimeTrackingEntity>> = [
  { key: 'profileIcon', headline: '', width: 80 },
  { key: 'sender', headline: 'employee' },
  { key: 'subject', headline: 'state' },
];

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { timeTrackingState } = useAppSelector((state) => state.client);
  const { isLoading, setLoading } = useLoadingModal();
  const dispatchGetStateList = useDispatchClientTimeTrackingGetState();

  React.useEffect(() => {
    if (!timeTrackingState || timeTrackingState.length === 0) {
      setLoading(true);
    }
  }, [setLoading, timeTrackingState]);

  React.useEffect(() => {
    dispatchGetStateList().finally(() => setLoading(false));
  }, [dispatchGetStateList, setLoading]);

  const handleOnRowClick = (item: TimeTrackingStateEntity) => {
    navigate(item.user.userId);
  };

  const renderBody = (entry: TimeTrackingStateEntity) => (
    <OverviewTableRow item={entry} key={entry.id} onClick={handleOnRowClick} />
  );

  return (
    <>
      <PageHeader headline={t('modules.settings.timeTracking.currentState')} />

      <Panel>
        <Table isLoading={isLoading} mappings={dummyTableMappings} onClickRow={() => {}}>
          <thead>
            <tr>
              <th style={{ width: 80 }} />
              <th>{t('employee')}</th>
              <th style={{ textAlign: 'right' }}>{t('state')}</th>
            </tr>
          </thead>
          <tbody>{timeTrackingState.map(renderBody)}</tbody>
        </Table>
      </Panel>
    </>
  );
}
