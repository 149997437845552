import React from 'react';
import { Report } from '../../../../Globals/Types/Report';
import Panel from '../../../Panel';
import { useTranslation } from 'react-i18next';
import { ServiceEntity } from '../../../../Globals/Types/OrderTypes';
import Item from './Item';

interface Props {
  report: Report;
  service: ServiceEntity;
}

/**
 * PositionCheck()
 * @param props
 * @constructor
 */
export default function PositionCheck(props: Props) {
  const { report, service } = props;
  const [t] = useTranslation();

  const renderItems = () =>
    report.positionCheck.map((positionItem) => (
      <Item reportPosition={positionItem} service={service} key={positionItem.id} />
    ));

  if (report.positionCheck && report.positionCheck.length > 0) {
    return <Panel headline={t('modules.report.summary.positionCheck')}>{renderItems()}</Panel>;
  }
  return null;
}
