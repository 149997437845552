import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import React from 'react';
import { CustomerMedia, CustomerMediaMetaData } from '../../../Globals/Types/Customer';
import {
  getStorage,
  updateMetadata,
  uploadBytesResumable,
  listAll,
  getMetadata,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { buildDocumentRef, FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import {
  CUSTOMER_MEDIA_DELETE_ERROR,
  CUSTOMER_MEDIA_DELETE_START,
  CUSTOMER_MEDIA_DELETE_SUCCESS,
  CUSTOMER_MEDIA_GETLIST_ERROR,
  CUSTOMER_MEDIA_GETLIST_START,
  CUSTOMER_MEDIA_GETLIST_SUCCESS,
  CUSTOMER_MEDIA_UPLOAD_ERROR,
  CUSTOMER_MEDIA_UPLOAD_START,
  CUSTOMER_MEDIA_UPLOAD_SUCCESS,
} from '../../ActionTypes';

/**
 * useDispatchCustomerMediaUpload()
 */
type UploadType = (
  customerId: string,
  file: File,
  fileName: string,
  customMetadata: CustomerMediaMetaData,
  onProgress?: (percent: number) => void,
) => Promise<CustomerMedia>;
export const useDispatchCustomerMediaUpload = (): UploadType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<UploadType>(
    (customerId, file, fileName, customMetadata, onProgress) => {
      const path = buildDocumentRef(
        getStorage(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.customer,
        customerId,
        FirebasePathMappings.media,
        file.name,
      );
      dispatch({ type: CUSTOMER_MEDIA_UPLOAD_START, payload: { customerId, file, fileName, customMetadata, path } });

      const uploadTask = uploadBytesResumable(path, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapShot) => {
            const progress = (snapShot.bytesTransferred / snapShot.totalBytes) * 100;
            if (onProgress) {
              onProgress(progress);
            }
          },
          (error) => {
            dispatch({ type: CUSTOMER_MEDIA_UPLOAD_ERROR, payload: error });
            reject(error);
          },
          () => {
            updateMetadata(uploadTask.snapshot.ref, { customMetadata: customMetadata as any }).finally(() => {
              getMetadata(uploadTask.snapshot.ref).then((metaData) => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
                  const result: CustomerMedia = {
                    ...metaData,
                    downloadUrl,
                  };

                  dispatch({ type: CUSTOMER_MEDIA_UPLOAD_SUCCESS, payload: { customerId, media: result } });
                  resolve(result);
                });
              });
            });
          },
        );
      });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchCustomerMediaGetList()
 */
type GetListType = (customerId: string) => Promise<CustomerMedia[]>;
export const useDispatchCustomerMediaGetList = (): GetListType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListType>(
    (customerId) => {
      dispatch({ type: CUSTOMER_MEDIA_GETLIST_START, payload: customerId });

      const path = buildDocumentRef(
        getStorage(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.customer,
        customerId,
        FirebasePathMappings.media,
      );

      return listAll(path)
        .then((result) => {
          if (result.items.length > 0) {
            let promiseAll: Promise<CustomerMedia>[] = [];

            result.items.forEach((fileRef) => {
              promiseAll.push(
                getMetadata(fileRef).then((metaData) => {
                  return getDownloadURL(metaData.ref).then((url) => {
                    const teste: CustomerMedia = {
                      ...metaData,
                      downloadUrl: url,
                    };
                    return teste;
                  });
                }),
              );
            });

            if (promiseAll && promiseAll.length > 0) {
              return Promise.all(promiseAll).then((allResult) => {
                dispatch({ type: CUSTOMER_MEDIA_GETLIST_SUCCESS, payload: { customerId, media: allResult } });
                return allResult;
              });
            }
          }
          dispatch({ type: CUSTOMER_MEDIA_GETLIST_SUCCESS, payload: { customerId, media: [] } });
          return Promise.resolve([]);
        })
        .catch((error) => {
          dispatch({ type: CUSTOMER_MEDIA_GETLIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

/**
 * useDispatchCustomerMediaDelete()
 */
type DeleteType = (customerId: string, fullPath: string) => Promise<void>;
export const useDispatchCustomerMediaDelete = (): DeleteType => {
  const dispatch = useAppDispatch();

  return React.useCallback<DeleteType>(
    (customerId, fullPath) => {
      dispatch({ type: CUSTOMER_MEDIA_DELETE_START, payload: { customerId, fullPath } });

      const ref = buildDocumentRef(getStorage(), fullPath);

      return deleteObject(ref)
        .then(() => {
          dispatch({ type: CUSTOMER_MEDIA_DELETE_SUCCESS, payload: { customerId, fullPath } });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch({ type: CUSTOMER_MEDIA_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};
