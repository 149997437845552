import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { NamingConventionType } from '../../Globals/Types/OrderTypes';

interface Props {
  hasError?: boolean;
  initialValue: number;
  label: string;
  marginBottom?: number;
  onChange: (value: number) => void;
  disabled: boolean;
}

/**
 * SelectNamingConvention
 * @param props
 * @constructor
 */
export default function SelectNamingConvention(props: Props) {
  const { hasError, initialValue, label, marginBottom, onChange, disabled } = props;
  const [t] = useTranslation();
  const [options] = React.useState<SelectOptions>([
    { label: t('namingConvention.week'), value: NamingConventionType.weekName },
    { label: t('namingConvention.currentIndex'), value: NamingConventionType.currentIndex },
  ]);

  if (options) {
    return (
      <Select
        label={label}
        onChange={onChange}
        initialValue={initialValue}
        options={options}
        hasError={hasError}
        marginBottom={marginBottom}
        disabled={disabled}
      />
    );
  }

  return null;
}

SelectNamingConvention.defaultProps = {
  disabled: false,
};
