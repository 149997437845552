import React from 'react';
import styled from 'styled-components';
import TaskPriorityIcon from '../TaskPriorityIcon';
import { formatDateTime, formatTime } from '../../../Globals/Functions';
import { useTranslation } from 'react-i18next';
import { TaskEntity } from '../../../Globals/Types/Types';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import Text from '../../Text';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';

const Icon = styled.i`
  font-size: 20px;
  cursor: pointer;
`;

const Container = styled.tr<{ overDueDateTime: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.overDueDateTime ? props.theme.color.red : null)};

  td {
    small {
      color: ${(props) => props.theme.color.textGray};
    }
  }
`;

interface Props {
  task: TaskEntity;
  onPress: (task: TaskEntity) => void;
  onCheck: (task: TaskEntity) => void;
  onUncheck: (task: TaskEntity) => void;
}

/**
 * Item()
 * @param props
 * @constructor
 */
export default function Item(props: Props) {
  const { task, onUncheck, onCheck, onPress } = props;
  const [t] = useTranslation();
  const { userId } = useAppSelector((state) => state.auth);
  const dueDateTime: Timestamp = task.dueDateTime as unknown as Timestamp;

  const renderIcon = () => {
    if (!task.createdUser || task.createdUser.userId !== userId) {
      if (task.finished) {
        return <Icon className="fas fa-check-square text-success" onClick={() => onUncheck(task)} />;
      }
      return <Icon className="far fa-square" onClick={() => onCheck(task)} />;
    }
    return (
      <Icon
        className="fas fa-user-circle"
        title={t('modules.profile.tasks.assignedToHint', { ...task.assignedUser })}
      />
    );
  };

  const renderAssignee = () => {
    if (task.createdUser !== undefined && task.createdUser !== null) {
      if (task.createdUser.userId === userId && task.assignedUser) {
        return (
          <Text light small>
            {t('assignedTo', { ...task.assignedUser })}
          </Text>
        );
      }
      return (
        <Text light small>
          {t('assignedFrom', { ...task.createdUser })}
        </Text>
      );
    }
    return null;
  };

  const getDueDateString = () => {
    if (moment(dueDateTime.toDate()).isSame(moment(), 'date')) {
      return `${t('xDay', { count: 0 })} - ${formatTime(moment(dueDateTime.toDate()).format('HH:mm:ss'))}`;
    }
    if (moment(dueDateTime.toDate()).isSame(moment().add(1, 'day'), 'date')) {
      return `${t('xDay', { count: 1 })} - ${formatTime(moment(dueDateTime.toDate()).format('HH:mm:ss'))}`;
    }
    return formatDateTime(task.dueDateTime);
  };

  return (
    <Container
      key={task.taskId}
      overDueDateTime={moment(dueDateTime.toDate()).isBefore(moment(), 'date') && !task.finished}
    >
      <td>{renderIcon()}</td>
      <td onClick={() => onPress(task)}>
        <div>
          <TaskPriorityIcon task={task} />
          {task.caption}
        </div>
        {renderAssignee()}
      </td>
      <td style={{ textAlign: 'right' }} onClick={() => onPress(task)}>
        {getDueDateString()}
      </td>
    </Container>
  );
}
