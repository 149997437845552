import React from 'react';
import { OrderServiceType } from '../../../../../Globals/Types/OrderTypes';
import styled from 'styled-components';
import CreateOrUpdateModal from '../CreateOrUpdateModal';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import { useDispatchClientUpdate } from '../../../../../Redux/Actions/ClientAction';
import { ClientSettingsType } from '../../../../../Globals/Types/Types';
import AskDeleteModal from '../../../../../Components/Modals/AskDeleteModal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { buildUrlSettingsOrderReportConfig } from '../../../../../Globals/UrlFunctions';
import { useGetServiceTypeHasReportConfig } from '../../../../../Globals/Hooks/ServiceTypeTemplates';

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.color.textGray};

  i {
    cursor: pointer;
    padding: 5px 10px;

    &.fa-trash-alt {
      &:hover {
        color: ${(props) => props.theme.color.red};
      }
    }
    &.fa-pencil {
      &:hover {
        color: ${(props) => props.theme.color.primaryColor};
      }
    }
    &.fa-clipboard-check {
      color: ${(props) => props.theme.color.green};

      &:hover {
        color: ${(props) => props.theme.color.primaryColor};
      }
    }
  }
`;

const Small = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.color.textGray};
`;

interface Props {
  type: OrderServiceType;
  isDeletable: boolean;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { type, isDeletable } = props;
  const { client } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { settings } = client;
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const dispatchUpdateClient = useDispatchClientUpdate();
  const getServiceTypeHasReportConfig = useGetServiceTypeHasReportConfig();

  const handleUpdate = React.useCallback(
    (type: OrderServiceType) => {
      const merged: ClientSettingsType = {
        ...settings,
        order: {
          ...settings.order,
          serviceTypeDefinition: settings.order.serviceTypeDefinition.map((item) =>
            item.key === type.key ? type : item,
          ),
        },
      };
      dispatchUpdateClient({ settings: merged }).then(() => {});
      setShowModal(false);
    },
    [dispatchUpdateClient, settings],
  );

  const handleDelete = React.useCallback(() => {
    const merged: ClientSettingsType = {
      ...settings,
      order: {
        ...settings.order,
        serviceTypeDefinition: settings.order.serviceTypeDefinition.filter((item) => item.key !== type.key),
      },
    };
    dispatchUpdateClient({ settings: merged }).then(() => {});
    setAskDelete(false);
  }, [dispatchUpdateClient, settings, type.key]);

  const renderDeleteIcon = () =>
    isDeletable ? <i className="fas fa-trash-alt" onClick={() => setAskDelete(true)} /> : null;

  return (
    <>
      <tr>
        <td>
          <i className={type.icon} />
        </td>
        <td>
          <div>{type.caption}</div>
          <Small>{type.description}</Small>
        </td>
        <td>
          <ActionContainer>
            <i
              className={getServiceTypeHasReportConfig(type.key) ? 'fas fa-clipboard-check' : 'far fa-clipboard'}
              onClick={() => navigate(buildUrlSettingsOrderReportConfig(type.key))}
            />
            <i className="fas fa-pencil" onClick={() => setShowModal(true)} />
            {renderDeleteIcon()}
          </ActionContainer>
        </td>
      </tr>

      <AskDeleteModal
        onDelete={handleDelete}
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        description={t('modules.settings.orders.serviceTypeAskDeleteDescription', { type: type.caption })}
      />

      <CreateOrUpdateModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        editData={type}
        onCreate={() => {}}
        onUpdate={handleUpdate}
      />
    </>
  );
}
