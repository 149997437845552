import React, { ReactElement } from 'react';
import styled from 'styled-components';

type PanelProps = {
  children: any;
  maxWidth: number | null;
  containerStyle: object;
  withoutPadding: boolean;
  headline?: string;
  customRightAction?: ReactElement | ReactElement[] | string;
  description?: string;
  paddingHorizontal: number;
  smallHeadline: boolean;
  headlineCentered: boolean;
  headlineDarkBlack: boolean;
  onEdit?: () => void;
};

interface ContainerProps {
  $maxWidth: number | null;
  $withoutPadding: boolean;
  $paddingHorizontal: number;
  $hasDescription: boolean;
  $headlineCentered: boolean;
  $headlineDarkBlack: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: ${(props) => props.theme.border.radius};
  ${(props) => props.theme.boxShadowDark};
  ${(props) => (props.$maxWidth ? `min-width: ${props.$maxWidth}px` : null)};
  ${(props) => (props.$maxWidth ? `max-width: ${props.$maxWidth}px` : null)};
  padding: ${(props) => (props.$withoutPadding ? 0 : `30px ${props.$paddingHorizontal}px`)};
  margin-bottom: 20px;
  background-color: #ffffff;

  @media (max-width: 600px) {
    padding: 20px;
  }

  p {
    margin-bottom: 30px;
  }

  > .Headline {
    display: flex;
    flex-direction: row;

    > .Left {
      flex-grow: 1;
    }

    > .Right {
      cursor: pointer;

      &.Edit {
        &:hover {
          color: ${(props) => props.theme.color.blue};
        }
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => (props.$headlineDarkBlack ? props.theme.color.textDark : props.theme.color.primaryColor)};
    font-weight: ${(props) => (props.$headlineDarkBlack ? 600 : 400)};
    font-family: 'DIN Next LT W01', sans-serif;
    -moz-text-size-adjust: auto;
    margin-top: 0;
    margin-bottom: ${(props) => (props.$hasDescription ? '4px' : '30px')};
    text-align: ${(props) => (props.$headlineCentered ? 'center' : 'left')};
  }
`;

/**
 * Panel()
 * @param props
 * @constructor
 */
export default function Panel(props: PanelProps) {
  const {
    maxWidth,
    containerStyle,
    withoutPadding,
    paddingHorizontal,
    headlineCentered,
    customRightAction,
    headlineDarkBlack,
    onEdit,
  } = props;

  const renderHeadline = () => {
    if (props.headline) {
      if (props.smallHeadline) {
        return <h5>{props.headline}</h5>;
      } else {
        return <h3>{props.headline}</h3>;
      }
    }
    return null;
  };

  const renderRightAction = () => {
    if (customRightAction) {
      return <div className="Right">{customRightAction}</div>;
    }
    if (onEdit) {
      return (
        <div className="Right Edit" onClick={onEdit}>
          <i className="fas fa-pencil" style={{ paddingLeft: 20 }} />
        </div>
      );
    }
    return null;
  };

  const renderDescription = () => {
    if (props.description) {
      return <p>{props.description}</p>;
    }
    return null;
  };

  return (
    <Container
      style={containerStyle}
      $maxWidth={maxWidth}
      $withoutPadding={withoutPadding}
      $hasDescription={props.description.length > 0}
      $paddingHorizontal={paddingHorizontal}
      $headlineCentered={headlineCentered}
      $headlineDarkBlack={headlineDarkBlack}
    >
      <div className="Headline">
        <div className="Left">{renderHeadline()}</div>
        {renderRightAction()}
      </div>
      {renderDescription()}
      {props.children}
    </Container>
  );
}

Panel.defaultProps = {
  maxWidth: null,
  containerStyle: {},
  withoutPadding: false,
  headline: null,
  description: '',
  paddingHorizontal: 40,
  smallHeadline: true,
  headlineCentered: false,
  headlineRight: null,
  headlineDarkBlack: false,
};
