import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { ClientEntity, FacilityPartnerEntity } from '../../Globals/Types/Types';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { FirebasePathMappings } from '../../Globals/FirebaseGlobals';
import {
  CLIENT_GET_ALL_PARTNERS_START,
  CLIENT_GET_ALL_PARTNERS_SUCCESS,
  CLIENT_GET_ERROR,
  CLIENT_GET_START,
  CLIENT_GET_SUCCESS,
  CLIENT_UPDATE_ERROR,
  CLIENT_UPDATE_START,
  CLIENT_UPDATE_SUCCESS,
} from '../ActionTypes';
import { useDispatchPartnersGetList } from './Client/Facility/PartnerAction';
import { useAppSelector } from '../../Globals/Hooks/Hooks';

type GetCallback = (clientId: string) => Promise<ClientEntity>;
/**
 * useDispatchClientGet()
 */
export const useDispatchClientGet = (): GetCallback => {
  const dispatch = useDispatch();

  return React.useCallback<GetCallback>(
    (clientId: string) => {
      dispatch({ type: CLIENT_GET_START, payload: clientId });

      const docRef = doc(getFirestore(), FirebasePathMappings.client, clientId);
      return getDoc(docRef)
        .then((response) => {
          const result = { ...response.data(), clientId: response.id } as ClientEntity;
          dispatch({ type: CLIENT_GET_SUCCESS, payload: result });
          return Promise.resolve<ClientEntity>(result);
        })
        .catch((error) => {
          dispatch({ type: CLIENT_GET_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [dispatch],
  );
};

type UpdateCallback = (client: Partial<ClientEntity>) => Promise<ClientEntity>;
/**
 * useDispatchClientUpdate()
 */
export const useDispatchClientUpdate = (): UpdateCallback => {
  const dispatch = useDispatch();
  const dispatchGet = useDispatchClientGet();
  const { clientId } = useSelector((state: any) => state.auth);

  return React.useCallback<UpdateCallback>(
    (client) => {
      dispatch({ type: CLIENT_UPDATE_START, payload: client });

      const docRef = doc(getFirestore(), FirebasePathMappings.client, clientId);
      return setDoc(docRef, client, { merge: true })
        .then((response) => {
          return dispatchGet(clientId).then((responseClient) => {
            dispatch({ type: CLIENT_UPDATE_SUCCESS, payload: responseClient });
            return Promise.resolve<ClientEntity>(responseClient);
          });
        })
        .catch((error) => {
          dispatch({ type: CLIENT_UPDATE_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [clientId, dispatch, dispatchGet],
  );
};

type GetAllPartnersCallback = () => Promise<Array<FacilityPartnerEntity>>;
/**
 * useDispatchClientUpdate()
 */
export const useDispatchClientGetAllPartners = (): GetAllPartnersCallback => {
  const dispatch = useDispatch();
  const { facilities } = useAppSelector((state) => state.client);
  const dispatchGetPartners = useDispatchPartnersGetList(false);

  return React.useCallback<GetAllPartnersCallback>(() => {
    dispatch({ type: CLIENT_GET_ALL_PARTNERS_START });

    if (facilities) {
      let promiseAll: Array<Promise<Array<FacilityPartnerEntity>>> = [];
      facilities.forEach((facility) => {
        promiseAll.push(dispatchGetPartners(facility.facilityId, false));
      });

      Promise.all(promiseAll).then((data) => {
        let partnersArray: Array<FacilityPartnerEntity> = [];

        data.forEach((item) => {
          item.forEach((partner) => {
            partnersArray.push(partner);
          });
        });

        dispatch({ type: CLIENT_GET_ALL_PARTNERS_SUCCESS, payload: partnersArray });
        return Promise.resolve(partnersArray);
      });
    }

    return Promise.resolve([]);
  }, [dispatch, dispatchGetPartners, facilities]);
};
