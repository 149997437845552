import { useDispatch } from 'react-redux';
import React from 'react';
import { ClientEntity, UserEntity } from '../../Globals/Types/Types';
import { collection, doc, DocumentReference, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore';
import {
  browserLocalPersistence,
  getAuth,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../Globals/FirebaseGlobals';
import {
  AUTH_CHANGE_PASSWORD_ERROR,
  AUTH_CHANGE_PASSWORD_START,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_START,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_REGISTER_ERROR,
  AUTH_REGISTER_START,
  AUTH_REGISTER_SUCCESS,
  AUTH_USER_EXISTS_ERROR,
  AUTH_USER_EXISTS_START,
  AUTH_USER_EXISTS_SUCCESS,
} from '../ActionTypes';
import { useDispatchGetFacilities } from './Client/FacilityAction';
import { useAppDispatch } from '../../Globals/Hooks/Hooks';
import { useBuildUserAccessPathArray } from '../../Globals/Access/Groups';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { TemplateTypes, useGetServiceTypeTemplates } from '../../Globals/Hooks/ServiceTypeTemplates';
import { CountryCode, UserLoginPermission } from '../../Globals/Types/Enums';
import { useDispatchLicenseServiceIsAvailable, useDispatchLicenseServiceSet } from './LicenseServiceAction';
import { useDispatchClientSubscriptionGetActive } from './Client/SubscriptionAction';
import { InternalErrorCodes } from '../../Globals/InternalErrorCodes';

export interface RegisterClientFacilityType {
  name: string;
  nameShort: string;
  countryCode: CountryCode;
  partnerId?: string;
}

/**
 * useDispatchUserCreate()
 */
export const useDispatchRegister = () => {
  const dispatch = useDispatch();
  const getOrderServiceTypeTemplate = useGetServiceTypeTemplates();

  return React.useCallback(
    (
      client: RegisterClientFacilityType,
      user: UserEntity,
      password: string,
      hasFacilities: boolean,
      companyType: TemplateTypes,
    ) => {
      const serviceTypeTemplate = getOrderServiceTypeTemplate(companyType);
      const preparedData = { user, password, client, hasFacilities, orderServiceTypeTemplate: serviceTypeTemplate };

      dispatch({ type: AUTH_REGISTER_START, payload: preparedData });

      const register = httpsCallable(getFunctions(), FirebaseFunctionNames.register);
      return register(preparedData)
        .then((response) => {
          dispatch({ type: AUTH_REGISTER_SUCCESS, payload: response });
          return Promise.resolve(response);
        })
        .catch((error) => {
          dispatch({ type: AUTH_REGISTER_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, getOrderServiceTypeTemplate],
  );
};

/**
 * useDispatchUserMailExisting()
 * Checks if a given mail is already existing at a user
 */
export const useDispatchUserMailExisting = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    (mail) => {
      dispatch({ type: AUTH_USER_EXISTS_START, payload: mail });

      const userCollection = collection(getFirestore(), FirebasePathMappings.user);
      const queryRef = query(userCollection, where('mail', '==', mail.toLowerCase()));

      return getDocs(queryRef)
        .then((docRefs) => {
          dispatch({ type: AUTH_USER_EXISTS_SUCCESS, payload: !docRefs.empty });
          return Promise.resolve(!docRefs.empty);
        })
        .catch((error) => {
          dispatch({ type: AUTH_USER_EXISTS_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchLogin()
 */
export const useDispatchLogin = () => {
  const dispatch = useAppDispatch();
  const dispatchGetFacilities = useDispatchGetFacilities();
  const buildAccessNodes = useBuildUserAccessPathArray();
  const dispatchSetLicense = useDispatchLicenseServiceSet();
  const dispatchLicenseAvailable = useDispatchLicenseServiceIsAvailable();
  const dispatchGetActiveSubscription = useDispatchClientSubscriptionGetActive();

  return React.useCallback(
    (mail: string, password: string) => {
      dispatch({ type: AUTH_LOGIN_START, payload: { mail, password } });

      return setPersistence(getAuth(), browserLocalPersistence).then(() => {
        return signInWithEmailAndPassword(getAuth(), mail, password).then((authResponse) => {
          const userCollection = collection(getFirestore(), FirebasePathMappings.user);
          const queryRef = query(userCollection, where('authId', '==', authResponse.user.uid));

          return getDocs(queryRef)
            .then((userDocs) => {
              if (userDocs.docs.length === 1) {
                const user: UserEntity = {
                  ...userDocs.docs[0].data(),
                } as UserEntity;

                if (user.loginPermission !== UserLoginPermission.app) {
                  const docReference = doc(
                    getFirestore(),
                    FirebasePathMappings.client,
                    user.clientId,
                  ) as DocumentReference<ClientEntity>;
                  return getDoc(docReference).then((clientDoc) => {
                    const client = clientDoc.data();

                    return dispatchGetFacilities(user.clientId).then((facilities) => {
                      return dispatchGetActiveSubscription(user.clientId).then((subscription) => {
                        if (subscription) {
                          return dispatchLicenseAvailable(user.clientId, subscription.users).then((result) => {
                            if (result) {
                              dispatch({
                                type: AUTH_LOGIN_SUCCESS,
                                payload: {
                                  facilities,
                                  client,
                                  user,
                                  userId: userDocs.docs[0].id,
                                  accessNodes: buildAccessNodes(user.groups),
                                },
                              });
                              dispatchSetLicense(user).then(() => {});
                              return Promise.resolve({ client, user });
                            } else {
                              return Promise.reject(InternalErrorCodes.NO_LICENSE_AVAILABLE);
                            }
                          });
                        }
                        return Promise.reject(InternalErrorCodes.NO_LICENSE_AVAILABLE);
                      });
                    });
                  });
                } else {
                  return Promise.reject(InternalErrorCodes.NO_LOGIN_PERMISSION);
                }
              } else {
                return Promise.reject();
              }
            })
            .catch((error) => {
              dispatch({ type: AUTH_LOGIN_ERROR, payload: error });
              return Promise.reject(error);
            });
        });
      });
    },
    [
      buildAccessNodes,
      dispatch,
      dispatchGetActiveSubscription,
      dispatchGetFacilities,
      dispatchLicenseAvailable,
      dispatchSetLicense,
    ],
  );
};

/**
 * useDispatchLogout()
 * Just send the dispatch for logout. Executes no logout on firebase auth
 */
export const useDispatchLogoutWithoutRequest = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(() => {
    dispatch({ type: AUTH_LOGOUT });
  }, [dispatch]);
};

/**
 * useDispatchAuthChangePassword()
 */
export const useDispatchAuthChangePassword = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    (newPassword: string) => {
      dispatch({ type: AUTH_CHANGE_PASSWORD_START, payload: newPassword });

      return updatePassword(getAuth().currentUser, newPassword)
        .then(() => {
          dispatch({ type: AUTH_CHANGE_PASSWORD_SUCCESS, payload: true });
          return Promise.resolve(true);
        })
        .catch((error) => {
          dispatch({ type: AUTH_CHANGE_PASSWORD_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

type SendResetPasswordType = (mail: string) => Promise<boolean>;
/**
 * useDispatchSendResetPassword()
 */
export const useDispatchSendResetPassword = (): SendResetPasswordType => {
  return React.useCallback<SendResetPasswordType>((mail) => {
    return sendPasswordResetEmail(getAuth(), mail)
      .then(() => Promise.resolve(true))
      .catch(() => Promise.reject());
  }, []);
};
