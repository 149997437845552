import React from 'react';
import { MessageEntity } from '../../../Globals/Types/Types';
import Text from '../../../Components/Text';
import { userDisplayName } from '../../../Globals/Functions';
import { messageGetSubject } from '../../../Globals/Functions/MessageFunctions';
import moment from 'moment';
import Panel from '../../../Components/Panel';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDispatchInboxDelete } from '../../../Redux/Actions/User/InboxAction';
import AskDeleteModal from '../../../Components/Modals/AskDeleteModal';
import { buildUrlInboxAnswer, buildUrlInboxForward } from '../../../Globals/UrlFunctions';

const HeaderContainer = styled.div<{ $initials: string }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;

  > .LeftContainer {
    margin-right: 30px;

    > .Circle {
      display: flex;
      height: 80px;
      width: 80px;
      border-radius: 40px;
      background-color: ${(props) => lighten(0.25, props.theme.color.textGray)};
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: ${(props) => (props.$initials.length === 2 ? '30px' : props.$initials.length === 3 ? '24px' : '20px')};
    }
  }

  > .RightContainer {
    flex-grow: 1;
  }

  > .ButtonContainer {
    > i {
      padding: 12px;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        background-color: ${(props) => props.theme.color.primaryColor};
        color: #ffffff;
      }
    }
  }
`;

interface Props {
  message: MessageEntity;
}

/**
 * DetailsHeader()
 * @param props
 * @constructor
 */
export default function DetailsHeader(props: Props) {
  const { message } = props;
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const dispatchDelete = useDispatchInboxDelete();

  const handleDelete = React.useCallback(() => {
    dispatchDelete(message.messageId).then(() => {});
  }, [dispatchDelete, message.messageId]);

  const renderNavigateLinkButton = () => {
    if (message.link) {
      return (
        <i
          className="fa-regular fa-arrow-up-right-from-square"
          title={message.linkCaptionKey ? t(message.linkCaptionKey) : t('openLink')}
          onClick={() => navigate(message.link)}
        />
      );
    }
    return null;
  };

  return (
    <Panel>
      <HeaderContainer $initials={message.sender.initials}>
        <div className="LeftContainer">
          <div className="Circle">{message.sender.initials}</div>
        </div>
        <div className="RightContainer">
          <Text bold size={18}>
            {userDisplayName(message.sender, true)}
          </Text>
          <Text>{messageGetSubject(message, t)}</Text>
          <Text light small>
            {moment(message.createdDate.toDate()).format('LLLL - LT')}
          </Text>
          <Text small light>
            <>{message.receivers.map((item) => userDisplayName(item, true)).join(', ')}</>
          </Text>
        </div>
        <div className={'ButtonContainer'}>
          {renderNavigateLinkButton()}
          <i
            className="far fa-arrow-turn-left"
            title={t('answer')}
            onClick={() => navigate(buildUrlInboxAnswer(message.messageId))}
          />
          <i
            className="far fa-arrows-turn-right"
            title={t('forward')}
            onClick={() => navigate(buildUrlInboxForward(message.messageId))}
          />
          <i className="fal fa-trash-can" title={t('delete')} onClick={() => setAskDelete(true)} />
        </div>
      </HeaderContainer>

      <AskDeleteModal
        onDelete={handleDelete}
        onClose={() => setAskDelete(false)}
        visible={askDelete}
        headline={t('modules.inbox.askDeleteHeadline')}
        description={t('modules.inbox.askDeleteDescription')}
      />
    </Panel>
  );
}
