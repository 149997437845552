import React from 'react';
import FacilityHeader from '../Components/FacilityHeader';
import Panel from '../../../../Components/Panel';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import NoPartnersAssigned from './Components/NoPartnersAssigned';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { FacilityEntity, FacilityPartnerEntity } from '../../../../Globals/Types/Types';
import ShowHideContainer from '../../../../Components/ShowHideContainer';
import PartnerContainer from './Components/PartnerContainer';
import AddPartnerModal from './AddPartnerModal';
import {
  useDispatchPartnerDelete,
  useDispatchPartnersGetList,
  useDispatchPartnerUpdate,
} from '../../../../Redux/Actions/Client/Facility/PartnerAction';
import SuccessMessage from '../../../../Components/Modals/SuccessMessage';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import NoCompanyData from './Components/NoCompanyData';
import { useDispatchCheckFacility } from '../../../../Redux/Actions/Client/FacilityAction';

const InfoContainer = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme.color.backgroundGray};
  border: 1px solid ${(props) => props.theme.border.color};
  padding: 10px;
  border-radius: ${(props) => props.theme.border.radius};
  color: #000;
  margin-top: 10px;
`;

/**
 * Overview
 * @constructor
 */
export default function Overview() {
  const [facility, setFacility] = React.useState<FacilityEntity>(null);
  const [showAdd, setShowAdd] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [partners, setPartners] = React.useState<Array<FacilityPartnerEntity>>(null);
  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
  const { facilityId } = useParams();
  const { facilities } = useAppSelector((state) => state.client);
  const { userId } = useAppSelector((state) => state.auth);
  const [t] = useTranslation();
  const { isValid: facilityValid, setFacility: setFacilityForCheck } = useDispatchCheckFacility();
  const dispatchGetList = useDispatchPartnersGetList();
  const dispatchDelete = useDispatchPartnerDelete();
  const dispatchUpdate = useDispatchPartnerUpdate();

  const loadPartners = React.useCallback(() => {
    if (!loaded) {
      dispatchGetList(facilityId, false).then((partnersResponse) => {
        setPartners(partnersResponse);
        setLoaded(true);
      });
    }
  }, [dispatchGetList, facilityId, loaded]);

  React.useEffect(() => {
    loadPartners();
  }, [loadPartners]);

  React.useEffect(() => {
    const filtered = facilities.filter((item) => item.facilityId === facilityId)[0];
    setFacility(filtered);
    setFacilityForCheck(filtered);
  }, [facilityId, facilities, setFacilityForCheck]);

  const renderAddButton = () => {
    return (
      <Button variant="success" onClick={() => setShowAdd(true)}>
        {t('requestPartner')}
      </Button>
    );
  };

  const handleOnSuccess = () => {
    setLoaded(false);
    setShowAdd(false);
    setTimeout(() => setShowSuccess(true), 400);
    loadPartners();
  };

  const handelDelete = (partner: FacilityPartnerEntity) => {
    dispatchDelete(facilityId, partner.partnerId).then((response) => {
      setPartners(response);
    });
  };

  const handleOnApprove = (partner: FacilityPartnerEntity) => {
    const merged: FacilityPartnerEntity = {
      ...partner,
      active: true,
      approved: true,
      approvedBy: userId,
      approvedDate: serverTimestamp() as Timestamp,
    };

    dispatchUpdate(facilityId, merged).then((response) => {
      setPartners(response);
    });
  };

  const renderPartners = () => {
    if (partners && partners.length > 0) {
      return partners.map((partner) => (
        <PartnerContainer
          partner={partner}
          facilityId={facilityId}
          key={partner.partnerClientId}
          onDelete={handelDelete}
          onApprove={handleOnApprove}
        />
      ));
    }
    return null;
  };

  if (facilityId && facility) {
    return (
      <>
        <FacilityHeader actionButtonOne={renderAddButton()} subHeadline={t('partnerships')} />
        <Panel>
          <>
            {t('modules.settings.facility.partnersDescription')}

            <ShowHideContainer visible={facilityValid}>
              <InfoContainer>{t('modules.settings.facility.yourInternalKey', { key: facilityId })}</InfoContainer>
              <InfoContainer>{t('modules.settings.facility.yourRegisterMail', { mail: facility.mail })}</InfoContainer>
            </ShowHideContainer>
          </>
        </Panel>
        <ShowHideContainer visible={facilityValid}>
          <NoPartnersAssigned onRequest={() => setShowAdd(true)} partners={partners} />

          {renderPartners()}

          <AddPartnerModal
            onClose={() => setShowAdd(false)}
            onSuccess={handleOnSuccess}
            visible={showAdd}
            facility={facility}
            partners={partners}
          />
        </ShowHideContainer>

        <NoCompanyData visible={!facilityValid} facilityId={facilityId} />

        <SuccessMessage
          headline={t('modules.settings.facility.partnerRequestSuccessHeadline')}
          description={t('modules.settings.facility.partnerRequestSuccessDescription')}
          onClose={() => setShowSuccess(false)}
          visible={showSuccess}
        />
      </>
    );
  }
  return null;
}
