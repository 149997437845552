/* eslint-disable max-len */
import { FacilityEntity, FacilityPartnerEntity } from '../../Globals/Types/Types';
import Lodash from 'lodash';
import {
  AUTH_LOGOUT,
  FACILITY_GET_EXTERNAL_SUCCESS,
  FACILITY_GET_SUCCESS,
  FACILITY_PARTNER_GET_LIST_SUCCESS,
} from '../ActionTypes';

export interface CacheReducerInterface {
  facilityPartners: Array<{ facilityId: string; partners: Array<FacilityPartnerEntity> }>;
  facilities: FacilityEntity[];
}

type Action =
  | {
      type: typeof FACILITY_PARTNER_GET_LIST_SUCCESS;
      payload: { facilityId: string; partners: Array<FacilityPartnerEntity> };
    }
  | { type: typeof FACILITY_GET_SUCCESS; payload: FacilityEntity }
  | { type: typeof FACILITY_GET_EXTERNAL_SUCCESS; payload: FacilityEntity }
  | { type: typeof AUTH_LOGOUT };

const defaultValues: CacheReducerInterface = {
  facilityPartners: [],
  facilities: [],
};

export default function reduce(state: CacheReducerInterface = defaultValues, action: Action): CacheReducerInterface {
  switch (action.type) {
    case FACILITY_PARTNER_GET_LIST_SUCCESS: {
      const found = Lodash.findIndex(state.facilityPartners, (item) => item.facilityId === action.payload.facilityId);
      if (found > -1) {
        const facilityPartners = state.facilityPartners.map((item) => {
          if (item.facilityId === action.payload.facilityId) {
            return { ...action.payload };
          }
          return { ...item };
        });
        return { ...state, facilityPartners };
      } else {
        return { ...state, facilityPartners: [...state.facilityPartners, action.payload] };
      }
    }

    case FACILITY_GET_EXTERNAL_SUCCESS:
    case FACILITY_GET_SUCCESS: {
      const hasEntry = Lodash.find((item) => item.facilities === action.payload.facilityId);
      let facilities: FacilityEntity[] = [];
      if (hasEntry) {
        const mapped = this.state.facilities.map((item) =>
          item.facilityId === action.payload ? action.payload : item,
        );
        facilities = [...mapped];
      } else {
        facilities = [...state.facilities, action.payload];
      }
      return { ...state, facilities };
    }

    case AUTH_LOGOUT:
      return { ...defaultValues };

    default:
      return { ...state };
  }
}
