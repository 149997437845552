import React from 'react';
import { ServiceEntity } from '../../../../Globals/Types/OrderTypes';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';
import { StorageReference } from '@firebase/storage';
import Panel from '../../../Panel';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatchDocumentDelete, useDispatchDocumentUpload } from '../../../../Redux/Actions/GlobalStorageActions';
import { useDispatchOrderServiceDocumentGetList } from '../../../../Redux/Actions/Order/Service/DocumentAction';
import { Alert } from 'react-bootstrap';
import { Row } from './Row';

const UploadButton = styled.i`
  font-size: 20px;
  &:hover {
    color: ${(props) => props.theme.color.green};
  }
`;

interface Props {
  service?: ServiceEntity;
  allowUpload?: boolean;
  showDelete?: boolean;
}

/**
 * Documents
 * @param props
 * @constructor
 */
export function Documents(props: Props) {
  const { service, allowUpload, showDelete } = props;
  const [t] = useTranslation();
  const [selectedDocs, setSelectedDocs] = React.useState<StorageReference[]>(null);
  const { documents, order } = useAppSelector((state) => state.order.selectedOrder);
  const { allowEditAll, allowDocumentUpload, allowDocumentDelete } = useAppSelector(
    (state) => state.order.selectedOrder.permissions,
  );
  const [canUpload] = React.useState<boolean>(allowEditAll || allowDocumentUpload);
  const [canDelete] = React.useState<boolean>(allowEditAll || allowDocumentDelete);
  const fileInputRef = React.useRef<HTMLInputElement>();
  const dispatchUploadFile = useDispatchDocumentUpload();
  const dispatchGetAllDocuments = useDispatchOrderServiceDocumentGetList();
  const dispatchDelete = useDispatchDocumentDelete();

  React.useEffect(() => {
    if (service && documents) {
      const filtered = documents.filter((document) => document.fullPath.indexOf(service.serviceId) > -1);
      setSelectedDocs(filtered);
    } else {
      setSelectedDocs(documents);
    }
  }, [documents, service]);

  const handleDelete = (document: StorageReference) => {
    dispatchDelete(document.fullPath).then(() => {
      return dispatchGetAllDocuments(order).then(() => {});
    });
  };

  const handleUpload = (file: File) => {
    if (service) {
      const path = `/client/${order.ownerClientId}/order/${order.orderId}/service/${service.serviceId}`;
      dispatchUploadFile(path, file).then(() => {
        return dispatchGetAllDocuments(order).then(() => {});
      });
    }
  };

  const render = () => {
    if (selectedDocs) {
      return selectedDocs.map((document) => (
        <Row document={document} showDelete={showDelete && canDelete} onDelete={handleDelete} key={document.fullPath} />
      ));
    }
    return null;
  };

  const renderUploadButton = () => {
    if (allowUpload && allowUpload) {
      return <UploadButton className="fas fa-plus-circle" onClick={() => fileInputRef.current.click()} />;
    }
    return null;
  };

  const renderEmptyHint = () =>
    !selectedDocs || selectedDocs.length === 0 ? (
      <Alert variant="dark">
        <i className="fas fa-info-circle" style={{ marginRight: 8 }} />
        {t('noDocumentsAvailable')}
      </Alert>
    ) : null;

  return (
    <Panel headline={t('documents')} customRightAction={canUpload ? renderUploadButton() : null}>
      {render()}
      {renderEmptyHint()}

      <input
        style={{ display: 'none' }}
        ref={fileInputRef}
        type="file"
        onChange={(e) => handleUpload(e.target.files[0])}
      />
    </Panel>
  );
}

Documents.defaultProps = {
  allowUpload: false,
  showDelete: true,
  onDelete: () => {},
};
