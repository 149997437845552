import React from 'react';
import styled from 'styled-components';
import Panel from '../../Components/Panel';
import Config from '../../Globals/Config';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { Tour, TourLocationTrackingEntity } from '../../Globals/Types/Tour';
import moment from 'moment';
import { Unsubscribe } from 'firebase/firestore';
import { useDispatchTourLocationListenLast } from '../../Redux/Actions/Disposition/Tour/LocationActions';
import GoogleMapReact, { Coords } from 'google-map-react';
import InfoWindowTour from '../../Components/Disposition/Components/InfoWindow/InfoWindowTour';
import { useTranslation } from 'react-i18next';
import Text from '../../Components/Text';

interface TourMarker {
  tour: Tour;
  position: Coords;
  tourId: string;
}

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 6px;
`;

/**
 * Map()
 * @constructor
 */
export default function Map() {
  const [t] = useTranslation();
  const { tourListByDate } = useAppSelector((state) => state.disposition);
  const [tours, setTours] = React.useState<Tour[]>(null);
  const [mapKey, setMapKey] = React.useState(null);
  const [defaultCenter, setDefaultCenter] = React.useState<Coords>(null);
  const [markers, setMarkers] = React.useState<TourMarker[]>(null);

  const dispatchTourLocationListenLast = useDispatchTourLocationListenLast();

  const updateTour = React.useCallback(
    (tourId: string, position: TourLocationTrackingEntity) => {
      const pos: Coords = { lat: position.position.latitude, lng: position.position.longitude };

      if (!defaultCenter) {
        setDefaultCenter(pos);
      }

      setMarkers((localMarkers) => {
        if (localMarkers && localMarkers.length > 0) {
          return localMarkers.map((marker) => ({
            ...marker,
            position:
              tourId === marker.tourId
                ? { lat: position.position.latitude, lng: position.position.longitude }
                : marker.position,
          }));
        }
      });
    },
    [defaultCenter],
  );

  React.useEffect(() => setMapKey(Math.random()), []);

  React.useEffect(() => {
    const dayData = tourListByDate.find((item) => item.date === moment().format('YYYY-MM-DD'));
    setTours(dayData.data);
  }, [tourListByDate]);

  React.useEffect(() => {
    const unsubscribeList: Unsubscribe[] = [];
    tours?.forEach((tour) => {
      unsubscribeList.push(dispatchTourLocationListenLast(tour.tourId, updateTour));
    });

    return () => unsubscribeList.forEach((item) => item());
  }, [dispatchTourLocationListenLast, tours, updateTour]);

  React.useEffect(() => {
    if (tours) {
      const markerTmp: TourMarker[] = tours.map((tour) => ({
        tour: tour,
        position: { lat: 0, lng: 0 },
        tourId: tour.tourId,
      }));

      setMarkers(markerTmp);
    }
  }, [tours]);

  const renderInfoWindows = () => {
    if (markers) {
      return markers.map((marker) => (
        <InfoWindowTour
          key={marker.tourId}
          tour={marker.tour}
          onPress={() => {}}
          lat={marker.position.lat}
          lng={marker.position.lng}
        />
      ));
    }
    return null;
  };

  if (tours && markers && defaultCenter) {
    return (
      <Panel containerStyle={{ flex: 1 }} withoutPadding>
        <MapContainer>
          <GoogleMapReact
            key={mapKey}
            bootstrapURLKeys={{ key: Config.google.apiKey }}
            defaultCenter={defaultCenter}
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals
          >
            {renderInfoWindows()}
          </GoogleMapReact>
        </MapContainer>
      </Panel>
    );
  }
  return (
    <Panel>
      <Text light centered>
        {t('modules.disposition.tourMapNoData')}
      </Text>
      <Text light centered>
        {t('modules.disposition.tourMapNoDataHint')}
      </Text>
    </Panel>
  );
}
