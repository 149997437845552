import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoadAndDeliveryAddress, ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import { DeliveryAndLoadingOptions } from '../../../../../Globals/Types/Enums';
import SelectDeliveryAndLoadingOption from '../../../../PredefinedSelects/SelectDeliveryAndLoadingOption';
import LoadingAddressForm from '../../../LoadingAddressForm';
import DeliveryAddressForm from '../../../DeliveryAddressForm';
import Panel from '../../../../Panel';
import {
  validateDeliveryAndLoadingAddress,
  ValidateDeliveryAndLoadingOptionsResult,
} from '../../../Validators/ValidateDeliveryAndLoadingAddress';

interface Props {
  service: ServiceEntity;
  onSave: (
    option: DeliveryAndLoadingOptions,
    loadingAddress: LoadAndDeliveryAddress | null,
    deliveryAddress: LoadAndDeliveryAddress | null,
  ) => void;
  onClose: () => void;
  visible: boolean;
}

export type State = {
  data: {
    option: DeliveryAndLoadingOptions;
    loadingAddress: LoadAndDeliveryAddress;
    deliveryAddress: LoadAndDeliveryAddress;
  };
  errors: Partial<ServiceEntity>;
  initialized: Boolean;
  hasChanges: Boolean;
};

type Action =
  | { type: 'option'; payload: DeliveryAndLoadingOptions }
  | { type: 'delivery'; payload: LoadAndDeliveryAddress }
  | { type: 'loading'; payload: LoadAndDeliveryAddress }
  | { type: 'init'; payload: ServiceEntity }
  | { type: 'setErrors'; payload: Partial<ServiceEntity> }
  | { type: 'clear' };

const initialState: State = {
  data: {
    option: null,
    loadingAddress: null,
    deliveryAddress: null,
  },
  errors: {} as Partial<ServiceEntity>,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'option':
      return { ...state, data: { ...state.data, option: action.payload }, hasChanges: true };
    case 'loading':
      return { ...state, data: { ...state.data, loadingAddress: action.payload }, hasChanges: true };
    case 'delivery':
      return { ...state, data: { ...state.data, deliveryAddress: action.payload }, hasChanges: true };
    case 'setErrors':
      return { ...state, errors: action.payload };
    case 'init':
      return {
        ...initialState,
        initialized: true,
        data: {
          option: action.payload.deliveryAndLoadingOption,
          loadingAddress: action.payload.loadingAddress,
          deliveryAddress: action.payload.deliveryAddress,
        },
      };
    case 'clear':
      return { ...initialState };
  }
};

/**
 * EditModal
 * @param props
 * @constructor
 */
export default function EditModal(props: Props) {
  const { service, visible, onClose, onSave } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [errors, setErrors] = React.useState<ValidateDeliveryAndLoadingOptionsResult>(null);
  const [t] = useTranslation();

  React.useEffect(() => {
    if (!state.initialized && service && visible) {
      dispatch({ type: 'init', payload: service });
    }
  }, [service, state.initialized, visible]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear' });
      setErrors(null);
    }
  }, [visible]);

  const handleSave = () => {
    let saveData = { ...state.data };
    if (state.data.option === DeliveryAndLoadingOptions.loadingIsCustomerAndDelivery) {
      saveData = { ...saveData, loadingAddress: null };
    }
    if (state.data.option === DeliveryAndLoadingOptions.deliveryIsCustomerAndLoading) {
      saveData = { ...saveData, deliveryAddress: null };
    }
    if (state.data.option === DeliveryAndLoadingOptions.deliveryIsCustomer) {
      saveData = { ...saveData, deliveryAddress: null, loadingAddress: null };
    }

    setErrors(null);
    const validationResult = validateDeliveryAndLoadingAddress(
      state.data.option,
      state.data.loadingAddress,
      state.data.deliveryAddress,
    );

    if (validationResult.result) {
      onSave(saveData.option, saveData.loadingAddress, saveData.deliveryAddress);
    } else {
      setErrors(validationResult);
    }
  };

  const renderLoading = () => {
    if (
      state.data.option === DeliveryAndLoadingOptions.loadingAndDelivery ||
      state.data.option === DeliveryAndLoadingOptions.deliveryIsCustomerAndLoading
    ) {
      return (
        <Col lg={12}>
          <Panel>
            <LoadingAddressForm
              address={state.data.loadingAddress}
              onChange={(value) => dispatch({ type: 'loading', payload: value })}
              validationResult={errors}
            />
          </Panel>
        </Col>
      );
    }
    return null;
  };

  const renderDelivery = () => {
    if (
      state.data.option === DeliveryAndLoadingOptions.loadingAndDelivery ||
      state.data.option === DeliveryAndLoadingOptions.loadingIsCustomerAndDelivery
    ) {
      return (
        <Col lg={12}>
          <Panel>
            <DeliveryAddressForm
              address={state.data.deliveryAddress}
              onChange={(value) => dispatch({ type: 'delivery', payload: value })}
              validationResult={errors}
            />
          </Panel>
        </Col>
      );
    }
    return null;
  };

  if (visible && state.initialized) {
    return (
      <Modal show={visible} onHide={() => onClose()} size="lg">
        <Modal.Header>
          <div>
            <Modal.Title style={{ marginLeft: 25 }}>{t('modules.order.loadAndDeliverHeadline')}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: 30 }}>
          <Row>
            <Col lg={12}>
              <Panel>
                <SelectDeliveryAndLoadingOption
                  onChange={(value) => dispatch({ type: 'option', payload: value })}
                  initialValue={state.data.option}
                  label={t('pleaseSelect')}
                  containerStyle={{ margin: 0 }}
                />
              </Panel>
            </Col>
            {renderLoading()}
            {renderDelivery()}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
            {t('save')}
          </Button>
          <Button variant="outline-secondary" onClick={() => onClose()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
