import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  visible: boolean;
  onClose: Function;
  onRecalculate: () => void;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;

  i {
    font-size: 70px;
    color: ${(props) => props.theme.color.red};
    padding-bottom: 20px;
  }
`;

/**
 * AskUpdateEstimatedAssemblyTimeModal
 * @param props
 * @constructor
 */
export default function AskUpdateEstimatedAssemblyTimeModal(props: Props) {
  const { visible, onClose, onRecalculate } = props;
  const [t] = useTranslation();

  const getDescription = () => t('modules.order.askUpdateAssemblyTimeDescription');

  return (
    <Modal show={visible} animation={false} centered>
      <Modal.Body>
        <Container>
          <i className="far fa-stopwatch text-primary" />
          <h4>{t('modules.order.askUpdateAssemblyTimeHeadline')}</h4>
          {getDescription()}
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button variant="primary" onClick={onRecalculate}>
          {t('recalculate')}
        </Button>
        <Button variant="outline-secondary" onClick={() => onClose()}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
