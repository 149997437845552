import React from 'react';
import { CustomerEntity, CustomerNoteEntity } from '../../../Globals/Types/Customer';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Input, { InputType } from '../../Inputs/Input';
import {
  useDispatchCustomerNoteCreate,
  useDispatchCustomerNoteUpdate,
} from '../../../Redux/Actions/Customer/NotesAction';
import { customerBuildName } from '../../../Globals/Functions/CustomerFunctions';
import { NoteType, SystemMessageTemplate } from '../../../Globals/Types/Enums';
import SelectNoteType from '../../PredefinedSelects/SelectNoteType';
import SelectClientUsers from '../../SelectClientUsers';
import { MessageEntity, PickedUserEntity } from '../../../Globals/Types/Types';
import { useDispatchInboxSendMessage } from '../../../Redux/Actions/User/InboxAction';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { buildUrlCustomersDetails } from '../../../Globals/UrlFunctions';
import { userEntityToPickedUserEntity } from '../../../Globals/Functions';

interface Props {
  initialData: CustomerNoteEntity;
  customer: CustomerEntity;
  visible: boolean;
  onSave: (note: CustomerNoteEntity) => void;
  onClose: () => void;
}

export type State = {
  note: CustomerNoteEntity;
  notifyUsers: PickedUserEntity[];
  initialized: boolean;
  isEdit: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'note'; payload: string }
  | { type: 'type'; payload: NoteType }
  | { type: 'accessUsers'; payload: PickedUserEntity[] }
  | { type: 'notifyUsers'; payload: PickedUserEntity[] }
  | { type: 'init'; payload: CustomerNoteEntity }
  | { type: 'clear' };

const initialState: State = {
  note: {
    noteId: null,
    note: '',
    type: NoteType.note,
    accessUsers: [],
    fixed: false,
    isEditable: true,
    createdDateTime: null,
    createdUserId: null,
    updatedDateTime: null,
    updatedUserId: null,
    firebaseCreatedDateTime: null,
    firebaseUpdatedDateTime: null,
  },
  notifyUsers: [],
  isEdit: false,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'note': {
      return { ...state, note: { ...state.note, note: action.payload }, hasChanges: true };
    }
    case 'type': {
      return { ...state, note: { ...state.note, type: action.payload }, hasChanges: true };
    }
    case 'accessUsers': {
      return {
        ...state,
        note: { ...state.note, accessUsers: action.payload.map((item) => item.userId) },
        hasChanges: true,
      };
    }
    case 'notifyUsers': {
      return {
        ...state,
        notifyUsers: action.payload,
        hasChanges: true,
      };
    }
    case 'init':
      return {
        ...state,
        note: action.payload,
        initialized: true,
        isEdit: true,
      };
    case 'clear':
      return { ...initialState };
  }
};

/**
 * CreateOrUpdateModal()
 * @param props
 * @constructor
 */
export default function CreateOrUpdateModal(props: Props) {
  const { onSave, onClose, visible, customer, initialData } = props;
  const { user } = useAppSelector((globalState) => globalState.auth);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [t] = useTranslation();
  const dispatchCreate = useDispatchCustomerNoteCreate();
  const dispatchUpdate = useDispatchCustomerNoteUpdate();
  const dispatchSendMail = useDispatchInboxSendMessage();

  const handleSave = React.useCallback(() => {
    setHasError(false);

    if (state.note.note && state.note.note.length > 0) {
      let promise: Promise<CustomerNoteEntity>;
      if (state.isEdit) {
        promise = dispatchUpdate(customer.customerId, state.note);
      } else {
        promise = dispatchCreate(customer.customerId, state.note);
      }

      promise.then((response) => {
        if (state.notifyUsers && state.notifyUsers.length > 0) {
          const message: MessageEntity = {
            template: SystemMessageTemplate.customerNoteNotification,
            message: 'SYSTEM MESSAGE',
            subject: 'SYSTEM MESSAGE',
            receivers: state.notifyUsers,
            isSystemMessage: false,
            parameters: {
              firstName: customer.firstName,
              lastName: customer.lastName,
              note: state.note.note,
            },
            sender: userEntityToPickedUserEntity(user),
            link: buildUrlCustomersDetails(customer.customerId),
            linkCaptionKey: t('toCustomer'),
            read: false,
            mailSend: false,
            createdDate: null,
          };

          dispatchSendMail(message).then(() => {});
        }

        onSave(response);
        onClose();
      });
    } else {
      setHasError(true);
    }
  }, [
    customer.customerId,
    customer.firstName,
    customer.lastName,
    dispatchCreate,
    dispatchSendMail,
    dispatchUpdate,
    onClose,
    onSave,
    state.isEdit,
    state.note,
    state.notifyUsers,
    t,
    user,
  ]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear' });
      setHasError(false);
    }
  }, [visible]);

  React.useEffect(() => {
    if (initialData && !state.initialized && visible) {
      dispatch({ type: 'init', payload: initialData });
    }
  }, [initialData, state.initialized, visible]);

  const getHeadline = () =>
    state.isEdit ? t('editNote') : t('newNoteFor', { name: customerBuildName(customer, true, t) });

  const getSelectedUsers = () =>
    state.note.accessUsers
      ? state.note.accessUsers.map((item) => ({ userId: item, firstName: '', lastName: '', initials: '' }))
      : [];

  if (visible) {
    return (
      <Modal show={visible} animation={false} size="xl">
        <Modal.Header>
          <Modal.Title>{getHeadline()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectNoteType onChange={(value) => dispatch({ type: 'type', payload: value })} selected={state.note.type} />
          <SelectClientUsers
            onChange={(value: PickedUserEntity[]) => dispatch({ type: 'accessUsers', payload: value })}
            label={t('noteVisibleFor')}
            initialSelected={getSelectedUsers()}
            placeholder={t('forAllUsers')}
          />
          <SelectClientUsers
            onChange={(value: PickedUserEntity[]) => dispatch({ type: 'notifyUsers', payload: value })}
            label={t('modules.customers.noteNotifyUsers')}
            initialSelected={state.notifyUsers}
            placeholder={t('modules.customers.noteNoNotification')}
          />
          <Input
            onChange={(value) => dispatch({ type: 'note', payload: value })}
            value={state.note.note}
            label={t('note')}
            type={InputType.textarea}
            rows={10}
            hasError={hasError}
            autoFocus
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
            {t('save')}
          </Button>
          <Button variant="outline-secondary" onClick={() => onClose()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
