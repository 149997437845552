import React from 'react';
import Panel from '../../../../Components/Panel';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import { GroupedTimeTracking } from '../../../../Globals/Types/Types';
import { useTranslation } from 'react-i18next';
import { convertMinutesToTimeString } from '../../../../Globals/Functions';

interface Props {
  onNext: () => void;
  onPrev: () => void;
  selectedMonth: string;
  data: Array<GroupedTimeTracking>;
}

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  align-content: center;

  > .Headline {
    font-size: 18px;
    font-weight: 600;
  }
  > .Time {
    font-size: 13px;
    color: ${(props) => props.theme.color.textGray};
  }
`;

/**
 * MonthSelector
 * @param props
 * @constructor
 */
export default function MonthSelector(props: Props) {
  const { selectedMonth, onNext, onPrev, data } = props;
  const [t] = useTranslation();
  const [overall, setOverall] = React.useState<number>(0);

  React.useEffect(() => {
    let minutes: number = 0;
    if (data) {
      data.forEach((item) => {
        minutes += item.minutes;
      });
    }
    setOverall(minutes);
  }, [data]);

  const getMonthString = () => {
    return moment(selectedMonth).format('MMMM YYYY');
  };

  return (
    <Panel withoutPadding containerStyle={{ flexDirection: 'row', padding: 20 }}>
      <Button variant="outline-secondary" onClick={onPrev}>
        <i className="fas fa-angle-double-left" />
      </Button>
      <MiddleContainer>
        <div className="Headline">{getMonthString()}</div>
        <div className="Time">{t('xHours', { time: convertMinutesToTimeString(overall) })}</div>
      </MiddleContainer>
      <Button variant="outline-secondary" onClick={onNext}>
        <i className="fas fa-angle-double-right" />
      </Button>
    </Panel>
  );
}
