import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { InvoiceAddressEntity } from '../../../Globals/Types/Invoice';
import SelectSalutation from '../../PredefinedSelects/SelectSaltutation';
import { PaymentType, Salutation } from '../../../Globals/Types/Enums';
import Input from '../../Inputs/Input';
import SelectCountry from '../../PredefinedSelects/SelectCountry';
import { InvoiceReducerState } from './Reducer';

interface Props {
  state: InvoiceReducerState;
  onChange: (address: InvoiceAddressEntity) => void;
  errors?: { [K in keyof InvoiceAddressEntity]?: string };
}

/**
 * AddressForm()
 * @param props
 * @constructor
 */
export default function AddressForm(props: Props) {
  const { state, onChange, errors } = props;
  const { address } = state.invoice;
  const [t] = useTranslation();

  const handleOnChange = React.useCallback(
    (field: string, value: string | Salutation) => {
      onChange({ ...address, [field]: value });
    },
    [address, onChange],
  );

  const renderIban = () => {
    if (
      (state.invoice.isVoucher && state.invoice.paymentType === PaymentType.credit) ||
      (!state.invoice.isVoucher && state.invoice.paymentType === PaymentType.debit)
    ) {
      return (
        <Col lg={6}>
          <Input
            label={'IBAN'}
            value={address.iban}
            onChange={(value) => handleOnChange('iban', value)}
            required
            hasError={errors && !!errors.iban}
          />
        </Col>
      );
    }
    return null;
  };

  return (
    <Row>
      <Col lg={2}>
        <SelectSalutation
          onChange={(value) => handleOnChange('salutation', value)}
          selected={address.salutation || Salutation.male}
          required
        />
      </Col>
      <Col lg={address.salutation !== Salutation.company ? 5 : 10}>
        <Input
          label={address.salutation !== Salutation.company ? t('firstName') : t('companyName')}
          value={address.firstName}
          onChange={(value) => handleOnChange('firstName', value)}
          hasError={errors && !!errors.firstName}
          required
          autoFocus
        />
      </Col>
      <Col lg={5}>
        <Input
          label={t('lastName')}
          value={address.lastName}
          onChange={(value) => handleOnChange('lastName', value)}
          required
          visible={address.salutation !== Salutation.company}
          hasError={errors && !!errors.lastName}
        />
      </Col>
      <Col lg={9}>
        <Input
          label={t('street')}
          value={address.street}
          onChange={(value) => handleOnChange('street', value)}
          required
          hasError={errors && !!errors.street}
        />
      </Col>
      <Col lg={3}>
        <Input
          label={t('streetNo')}
          value={address.streetNo}
          onChange={(value) => handleOnChange('streetNo', value)}
          required
          hasError={errors && !!errors.streetNo}
        />
      </Col>
      <Col lg={3}>
        <Input
          label={t('zip')}
          value={address.zip}
          onChange={(value) => handleOnChange('zip', value)}
          required
          hasError={errors && !!errors.zip}
        />
      </Col>
      <Col lg={9}>
        <Input
          label={t('city')}
          value={address.city}
          onChange={(value) => handleOnChange('city', value)}
          required
          hasError={errors && !!errors.city}
        />
      </Col>
      <Col lg={6}>
        <SelectCountry
          selected={address.countryCode}
          onChange={(value) => handleOnChange('countryCode', value)}
          required
        />
      </Col>
      <Col lg={6}>
        <Input
          label={t('taxNumber')}
          value={address.taxNumber}
          onChange={(value) => handleOnChange('taxNumber', value)}
          required={address.salutation === Salutation.company}
          hasError={errors && !!errors.taxNumber}
        />
      </Col>

      {renderIban()}
    </Row>
  );
}
