import React from 'react';
import { CountryCode } from '../../../../Globals/Types/Enums';
import { InvoiceEntity } from '../../../../Globals/Types/Invoice';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatchCustomerGet } from '../../../../Redux/Actions/CustomerAction';
import { CustomerEntity } from '../../../../Globals/Types/Customer';
import SelectLanguage from '../../../../Components/PredefinedSelects/SelectLanguage';
import Input from '../../../../Components/Inputs/Input';
import { isValidateEmail } from '../../../../Globals/Functions';

interface Props {
  invoice: InvoiceEntity;
  onSend: (receiverMail: string, language: CountryCode) => void;
  onClose: () => void;
  visible: boolean;
}

/**
 * SendMailModal()
 * @param props
 * @constructor
 */
export default function SendMailModal(props: Props) {
  const { onSend, invoice, onClose, visible } = props;
  const [t] = useTranslation();
  const [customer, setCustomer] = React.useState<CustomerEntity>(null);
  const [language, setLanguage] = React.useState<CountryCode>(invoice.address.countryCode);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [receiverMail, setReceiverMail] = React.useState<string>('');
  const dispatchGetCustomer = useDispatchCustomerGet();

  const handleSend = React.useCallback(() => {
    setHasError(false);
    if (receiverMail && isValidateEmail(receiverMail)) {
      onSend(receiverMail, language);
    } else {
      setHasError(true);
    }
  }, [language, onSend, receiverMail]);

  React.useEffect(() => {
    if (invoice.customerId) {
      if ((invoice && visible && !customer) || (customer && customer.customerId !== invoice.customerId)) {
        dispatchGetCustomer(invoice.customerId).then(setCustomer);
      }
    }
  }, [customer, dispatchGetCustomer, invoice.customerId, visible, invoice]);

  React.useEffect(() => {
    if ((customer && customer.mailPrivate) || (customer && customer.mailBusiness)) {
      setReceiverMail(customer.mailPrivate || customer.mailBusiness);
    }
    if (customer && customer.country) {
      setLanguage(customer.country);
    }
  }, [customer]);

  return (
    <Modal show={visible} centered size="lg">
      <Modal.Header>
        <Modal.Title>{t('sendByMail')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('modules.customers.invoiceSendMailDescription')}</p>
        <SelectLanguage onChange={setLanguage} selected={language} />
        <Input
          onChange={setReceiverMail}
          label={t('eMailAddress')}
          value={receiverMail}
          hasError={hasError}
          autoFocus
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSend}>
          {t('sendMail')}
        </Button>
        <Button variant="outline-secondary" onClick={() => onClose()}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
