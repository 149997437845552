import React from 'react';
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { FirebaseFunctionNames } from '../../../Globals/FirebaseGlobals';
import { useDispatchDocumentGetDownloadUrl } from '../GlobalStorageActions';

export type BackupResponse = {
  result: boolean;
  error?: Error;
  name?: string;
  filename?: string;
};

export type RestoreResponse = {
  result: boolean;
  error?: Error;
};

/**
 * useDispatchBackup()
 */
type BackupReturnType = (clientId: string) => Promise<{ uri: string; filename: string }>;
export const useDispatchBackup = (): BackupReturnType => {
  const dispatchGetDownloadUrl = useDispatchDocumentGetDownloadUrl();

  return React.useCallback<BackupReturnType>(
    (clientId) => {
      const backup = httpsCallable(getFunctions(), FirebaseFunctionNames.backup);
      return backup({ clientId }).then((response: HttpsCallableResult<BackupResponse>) => {
        const { error, result, name, filename } = response.data;
        if (!result) {
          return Promise.reject(error);
        }

        return dispatchGetDownloadUrl(name).then((uri) => Promise.resolve({ uri, filename }));
      });
    },
    [dispatchGetDownloadUrl],
  );
};

/**
 * useDispatchRestore()
 */
type RestoreReturnType = (storagePath: string) => Promise<void>;
export const useDispatchRestore = (): RestoreReturnType => {
  return React.useCallback<RestoreReturnType>((storagePath) => {
    const restore = httpsCallable(getFunctions(), FirebaseFunctionNames.restore);
    return restore({ storagePath }).then((response: HttpsCallableResult<RestoreResponse>) => {
      const { error, result } = response.data;
      if (!result) {
        return Promise.reject(error);
      }

      return Promise.resolve();
    });
  }, []);
};
