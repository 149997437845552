import React from 'react';
import { PositionExternalData } from '../../../../Globals/Types/OrderTypes';
import styled from 'styled-components';
import Input from '../../../Inputs/Input';
import { useTranslation } from 'react-i18next';
import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 12px;
`;

const KeyInputContainer = styled.div`
  flex-grow: 1;
  margin-right: 20px;
`;

const ValueInputContainer = styled.div`
  flex-grow: 1;
`;

const DeleteIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`;

const IconButton = styled.div`
  display: flex;
  padding: 10px 15px;
  font-size: 22px;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
  border-radius: ${(props) => props.theme.border.radius};

  &.check {
    color: ${(props) => props.theme.color.green};
  }
  &.delete {
    color: ${(props) => props.theme.color.red};
  }

  &:hover {
    &.check {
      background-color: ${(props) => props.theme.color.green};
      color: #ffffff;
    }
    &.delete {
      background-color: ${(props) => props.theme.color.red};
      color: #ffffff;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;

interface Props {
  onSave: (data: PositionExternalData) => void;
  onCancel: () => void;
}

/**
 * ExternalDataRowAdd
 * @param props
 * @constructor
 */
export default function ExternalDataRowAdd(props: Props) {
  const { onSave, onCancel } = props;
  const [t] = useTranslation();
  const [key, setKey] = React.useState<string>(undefined);
  const [value, setValue] = React.useState<string>(undefined);
  const [keyError, setKeyError] = React.useState<string>(undefined);
  const [valueError, setValueError] = React.useState<string>(undefined);

  const handleOnSave = () => {
    setKeyError(undefined);
    setValueError(undefined);

    if (key && key.length > 1 && value && value.length >= 1) {
      onSave({ key, value });
    } else {
      if (!key) {
        setKeyError(InternalErrorCodes.FIELD_MISSING);
      }
      if (!value) {
        setValueError(InternalErrorCodes.FIELD_MISSING);
      }
    }
  };

  return (
    <Container>
      <KeyInputContainer>
        <Input onChange={setKey} label={t('caption')} value={key} removeBottomMargin autoFocus hasError={!!keyError} />
      </KeyInputContainer>
      <ValueInputContainer>
        <Input onChange={setValue} label={t('value')} value={value} removeBottomMargin hasError={!!valueError} />
      </ValueInputContainer>
      <DeleteIconContainer>
        <IconButton className="check" onClick={handleOnSave}>
          <i className="fas fa-check-circle" />
        </IconButton>
        <IconButton onClick={onCancel} className="delete">
          <i className="fas fa-times-circle" />
        </IconButton>
      </DeleteIconContainer>
    </Container>
  );
}
