import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { UserLoginPermission } from '../../Globals/Types/Enums';

interface Props {
  hasError?: boolean;
  initialValue: UserLoginPermission;
  marginBottom?: number;
  onChange: (value: UserLoginPermission) => void;
  contextHelpKey?: string;
  disabled: boolean;
  showSkeleton: boolean;
}

/**
 * SelectUserLoginPermission
 * @param props
 * @constructor
 */
export default function SelectUserLoginPermission(props: Props) {
  const { hasError, initialValue, marginBottom, onChange, contextHelpKey, disabled, showSkeleton } = props;
  const [t] = useTranslation();
  const [options] = React.useState<SelectOptions>([
    { label: t('loginPermission.both'), value: UserLoginPermission.both },
    { label: t('loginPermission.web'), value: UserLoginPermission.web },
    { label: t('loginPermission.app'), value: UserLoginPermission.app },
  ]);

  if (options) {
    return (
      <Select
        label={t('allowLogin')}
        onChange={onChange}
        initialValue={initialValue}
        options={options}
        hasError={hasError}
        marginBottom={marginBottom}
        contextHelpKey={contextHelpKey}
        disabled={disabled}
        showSkeleton={showSkeleton}
      />
    );
  }

  return null;
}

SelectUserLoginPermission.defaultProps = {
  disabled: false,
  showSkeleton: false,
};
