import { AbsenceEntity } from '../../Globals/Types/Types';
import { ABSENCES_GET_LIST_SUCCESS, AUTH_LOGOUT, USER_ABSENCES_GETLIST_SUCCESS } from '../ActionTypes';
import { CalendarDataType } from '../../Components/Calendar/CalendarTypes';
import moment from 'moment';

export interface CalendarReducerInterface {
  absences: Array<CalendarDataType<AbsenceEntity>>;
  currentUserData: Array<CalendarDataType<any>>;
}

export type ClientActions =
  | { type: typeof ABSENCES_GET_LIST_SUCCESS; payload: { absences: AbsenceEntity[]; resetState: boolean } }
  | { type: typeof USER_ABSENCES_GETLIST_SUCCESS; payload: Array<AbsenceEntity> }
  | { type: typeof AUTH_LOGOUT };

const defaultValues: CalendarReducerInterface = {
  absences: [],
  currentUserData: [],
};

export default function reduce(
  state: CalendarReducerInterface = defaultValues,
  action: ClientActions,
): CalendarReducerInterface {
  switch (action.type) {
    case ABSENCES_GET_LIST_SUCCESS: {
      const absences = [];
      const dates = [...new Set(action.payload.absences.flatMap((item) => item.dates))];

      dates.forEach((date) => {
        const items = action.payload.absences.filter((item) => item.dates.indexOf(date) >= 0);
        const newEntry: CalendarDataType<AbsenceEntity> = {
          date: moment(date),
          data: items.map((item) => ({
            id: item.absenceId,
            caption: date,
            data: item,
          })),
        };
        absences.push(newEntry);
      });
      return { ...state, absences: [...absences] };
    }

    case USER_ABSENCES_GETLIST_SUCCESS: {
      const absences = [];
      const dates = [...new Set(action.payload.flatMap((item) => item.dates))];

      dates.forEach((date) => {
        const items = action.payload.filter((item) => item.dates.indexOf(date) >= 0);
        const newEntry: CalendarDataType<AbsenceEntity> = {
          date: moment(date),
          data: items.map((item) => ({
            id: item.absenceId,
            caption: date,
            data: item,
          })),
        };
        absences.push(newEntry);
      });
      return { ...state, currentUserData: absences };
    }

    case AUTH_LOGOUT: {
      return { ...defaultValues };
    }

    default:
      return { ...state };
  }
}
