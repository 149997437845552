import React from 'react';
import styled from 'styled-components';
import screenshot from '../../Assets/Images/dispo_screenshot_mac_01.png';
import { lighten } from 'polished';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 50px 0 100px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ffffff;

  @media (max-width: 1350px) {
    padding-bottom: 0;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 1200px;
  margin-top: 50px;

  > .CreditCard {
    color: ${(props) => lighten(0.25, props.theme.color.primaryColor)};
    margin-top: 20px;
    font-weight: bold;
  }

  > div {
    display: flex;
    flex: 1;

    &.Right {
      padding-left: 50px;
      display: flex;
      flex-direction: column;

      > p {
        font-size: 18px;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    font-weight: bold;

    &.blue {
      color: ${(props) => props.theme.color.primaryColor};
    }
  }

  img {
    width: 100%;
  }

  @media (max-width: 1350px) {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;

    > div {
      &.Right {
        padding-left: 0;
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        text-align: center;
      }

      p {
        margin: 0;
      }
    }
  }
`;

/**
 * InfoBox()
 * @constructor
 */
export default function InfoBox() {
  const [t] = useTranslation();

  return (
    <Container>
      <InnerContainer>
        <div>
          <img src={screenshot} alt="Screenshot" />
        </div>
        <div className="Right">
          <h2 className="blue">SotoSoft - Simplify your work!</h2>
          <h2>{t('modules.website.banner.line1')}</h2>
          <p>{t('modules.website.infoText')}</p>
        </div>
      </InnerContainer>
    </Container>
  );
}
