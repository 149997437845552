import React from 'react';
import { PositionEntity, PositionExternalData } from '../../../../Globals/Types/OrderTypes';
import ExternalDataRow from './ExternalDataRow';
import { Button } from 'react-bootstrap';
import ExternalDataRowAdd from './ExternalDataRowAdd';
import { useTranslation } from 'react-i18next';
import Panel from '../../../Panel';

interface Props {
  position: PositionEntity;
  onChange: (position: PositionExternalData[]) => void;
}

/**
 * ExternalDataContainer
 * @param props
 * @constructor
 */
export default function ExternalDataContainer(props: Props) {
  const { position, onChange } = props;
  const [t] = useTranslation();
  const [showAdd, setShowAdd] = React.useState<boolean>(false);

  const renderData = () => {
    if (position.externalData) {
      return position.externalData.map((data) => (
        <ExternalDataRow data={data} onDelete={handleOnDelete} key={data.key} />
      ));
    }
    return null;
  };

  const handleOnAddPosition = (data: PositionExternalData) => {
    setShowAdd(false);
    const merged: PositionExternalData[] = position.externalData ? [...position.externalData, data] : [data];
    onChange(merged);
  };

  const handleOnDelete = (data: PositionExternalData) => {
    const filtered = position.externalData.filter((param) => param.key !== data.key);
    onChange(filtered);
  };

  const renderAdd = () => {
    if (showAdd) {
      return <ExternalDataRowAdd onSave={handleOnAddPosition} onCancel={() => setShowAdd(false)} />;
    }
    return null;
  };

  const renderAddButton = () => {
    if (!showAdd) {
      return (
        <div style={{ textAlign: 'left', marginTop: 30 }}>
          <Button variant="success" onClick={() => setShowAdd(true)}>
            {t('informationAdd')}
          </Button>
        </div>
      );
    }
    return null;
  };

  return (
    <Panel
      headline={t('modules.order.positionAddAdditionalDataHeadline')}
      description={t('modules.order.positionAddAdditionalDataDescription')}
    >
      {renderData()}
      {renderAdd()}
      {renderAddButton()}
    </Panel>
  );
}
