import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type AskDeleteModalProps = {
  visible: boolean;
  onClose: Function;
  onDelete: Function;
  headline: string;
  description?: string;
  confirmButtonText?: string;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;

  i {
    font-size: 70px;
    color: ${(props) => props.theme.color.red};
    padding-bottom: 20px;
  }
`;

/**
 * AskDeleteModalProps
 * @param props
 * @constructor
 */
export default function AskDeleteModal(props: AskDeleteModalProps) {
  const { visible, onClose, description, onDelete, headline, confirmButtonText } = props;
  const [t] = useTranslation();

  const getDescription = () => description || t('deleteDefaultDescription');

  return (
    <Modal show={visible} animation={false} centered>
      <Modal.Body>
        <Container>
          <i className="fas fa-trash-alt text-danger" />
          <h4>{headline || t('deleteSelectedEntry')}</h4>
          {getDescription()}
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button variant="danger" onClick={() => onDelete()}>
          {confirmButtonText || t('delete')}
        </Button>
        <Button variant="outline-secondary" onClick={() => onClose()}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AskDeleteModal.defaultProps = {
  headline: null,
  description: null,
};
