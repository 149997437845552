import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { isValidateEmail } from '../../../../Globals/Functions';
import { FacilityEntity } from '../../../../Globals/Types/Types';

export type ValidationResult = {
  isValid: boolean;
  errors: FacilityEntity;
};

/**
 * isValid()
 * @param client
 */
const isValid = (client: FacilityEntity): ValidationResult => {
  let errors: FacilityEntity = {} as FacilityEntity;

  if (!client.zip || client.zip.length <= 0) {
    errors = { ...errors, zip: InternalErrorCodes.FIELD_MISSING };
  }

  if (!client.city || client.city.length <= 0) {
    errors = { ...errors, city: InternalErrorCodes.FIELD_MISSING };
  }

  if (!client.street || client.street.length <= 0) {
    errors = { ...errors, street: InternalErrorCodes.FIELD_MISSING };
  }

  if (!client.streetNo || client.streetNo.length <= 0) {
    errors = { ...errors, streetNo: InternalErrorCodes.FIELD_MISSING };
  }

  if (!client.phone || client.phone.length <= 0) {
    errors = { ...errors, phone: InternalErrorCodes.FIELD_MISSING };
  }

  if (!client.mail || client.mail.length <= 0) {
    errors = { ...errors, mail: InternalErrorCodes.FIELD_MISSING };
  } else {
    if (!isValidateEmail(client.mail)) {
      errors = { ...errors, mail: InternalErrorCodes.MAIL_INVALID };
    }
  }

  if (!client.vat || client.vat.length <= 0) {
    errors = { ...errors, vat: InternalErrorCodes.FIELD_MISSING };
  }

  /**
   * Validate client bank data
   */
  if (client.bank) {
    if ((client.bank.iban && client.bank.iban.length > 0) || (client.bank.bic && client.bank.bic.length > 0)) {
      if (!client.bank.name || client.bank.name.length <= 0) {
        errors = { ...errors, bank: { ...errors.bank, name: InternalErrorCodes.FIELD_MISSING } };
      }

      if (!client.bank.iban || client.bank.iban.length <= 0) {
        errors = { ...errors, bank: { ...errors.bank, iban: InternalErrorCodes.FIELD_MISSING } };
      }

      if (!client.bank.owner || client.bank.owner.length <= 0) {
        errors = { ...errors, bank: { ...errors.bank, owner: InternalErrorCodes.FIELD_MISSING } };
      }

      if (!client.bank.bic || client.bank.bic.length <= 0) {
        errors = { ...errors, bank: { ...errors.bank, bic: InternalErrorCodes.FIELD_MISSING } };
      }
    }
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export default isValid;
