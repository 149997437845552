import React from 'react';
import { MessageEntity } from '../../../Globals/Types/Types';
import styled from 'styled-components';
import Panel from '../../../Components/Panel';
import { fileGetIconByExtension } from '../../../Globals/Functions/FileFunctions';
import Text from '../../../Components/Text';
import { formatSize } from '../../../Globals/Functions';
import { lighten } from 'polished';

const Item = styled.div`
  border-radius: ${(props) => props.theme.border.radius};
  display: flex;
  flex-direction: row;
  padding: 14px 20px;
  margin-right: -10px;
  margin-left: -10px;
  align-content: center;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  i {
    font-size: 20px;
    margin-right: 10px;
  }

  &:hover {
    background-color: ${(props) => lighten(0.25, props.theme.color.textGray)};
  }
`;

interface Props {
  message: MessageEntity;
}

/**
 * DetailsAttachments()
 * @param props
 * @constructor
 */
export default function DetailsAttachments(props: Props) {
  const { message } = props;

  const renderAttachments = () =>
    message.attachments.map((item) => (
      <Item onClick={() => window.open(item.downloadUrl)} key={item.path}>
        <i className={fileGetIconByExtension(item.fileName)} />
        <Text>{`${item.fileName} (${formatSize(item.size)})`}</Text>
      </Item>
    ));

  if (message.attachments && message.attachments.length > 0) {
    return <Panel>{renderAttachments()}</Panel>;
  }
  return null;
}
