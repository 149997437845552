import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../Components/PageHeader';
import ServiceTypes from './ServiceTypes';
import Panel from '../../../Components/Panel';
import CustomSwitch from '../../../Components/CustomSwitch';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { useDispatchClientUpdate } from '../../../Redux/Actions/ClientAction';
import { ClientOrderSettings } from '../../../Globals/Types/Types';
import { useClientHasFacilities } from '../../../Globals/Hooks/ClientHooks';
import TimeTracking from './TimeTracking';
import { Row } from 'react-bootstrap';

interface Props {}

/**
 * Order()
 * @param props
 * @constructor
 */
export default function Order(props: Props) {
  const { client } = useAppSelector((state) => state.auth);
  const { setLoading, isLoading } = useLoadingModal();
  const { setLoading: setLoadingInitials, isLoading: isLoadingInitials } = useLoadingModal();
  const { autoExternalId, prefixFacilityInitial } = client.settings.order;
  const [t] = useTranslation();
  const hasFacilities = useClientHasFacilities();

  const dispatchUpdateClient = useDispatchClientUpdate();

  const handleUpdateOrderSettings = (data: ClientOrderSettings) => {
    return dispatchUpdateClient({ settings: { ...client.settings, order: data } });
  };

  const handleUpdateAutoExternalId = (value: boolean) => {
    setLoading(true);
    handleUpdateOrderSettings({ ...client.settings.order, autoExternalId: value }).finally(() => setLoading(false));
  };

  const handleUpdateInitials = (value: boolean) => {
    setLoadingInitials(true);
    handleUpdateOrderSettings({ ...client.settings.order, prefixFacilityInitial: value }).finally(() =>
      setLoadingInitials(false),
    );
  };

  const renderPrefixFacility = () => {
    if (hasFacilities) {
      return (
        <CustomSwitch
          onChange={handleUpdateInitials}
          checked={prefixFacilityInitial}
          caption={t('modules.settings.orders.prefixFacilityInitialsHeadline')}
          description={t('modules.settings.orders.prefixFacilityInitialsDescription')}
          loading={isLoadingInitials}
          disabled={!autoExternalId}
        />
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader headline={t('orders')} />
      <ServiceTypes />

      <Row>
        <div className="col-6" style={{ display: 'flex' }}>
          <Panel
            headline={t('modules.settings.orders.autoExternalIdHeadline')}
            description={t('modules.settings.orders.autoExternalIdDescription')}
          >
            <CustomSwitch
              onChange={handleUpdateAutoExternalId}
              checked={autoExternalId}
              caption={t('modules.settings.orders.autoExternalIdHeadline')}
              loading={isLoading}
            />
            {renderPrefixFacility()}
          </Panel>
        </div>

        <div className="col-6" style={{ display: 'flex' }}>
          <TimeTracking />
        </div>
      </Row>
    </>
  );
}
