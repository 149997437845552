import React from 'react';
import styled from 'styled-components';
import Container from '../../Container';
import { useTranslation } from 'react-i18next';
import ImagePreview from '../../ImagePreview';

const InnerContainer = styled.div<{ iconColor: string }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  cursor: pointer;

  i {
    color: ${(props) => props.iconColor};
    font-size: 36px;
    margin-right: 15px;
  }

  .Text {
    .CaptionContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      .Caption {
        font-weight: bold;
      }
    }
    .Description {
      color: ${(props) => props.theme.color.textGray};
    }
    .DescriptionSmall {
      color: ${(props) => props.theme.color.textGray};
    }
    .Images {
      color: ${(props) => props.theme.color.textGray};
      font-size: 13px;
      margin-left: 10px;
    }
  }
`;

interface Props<T> {
  item: T;
  icon: string;
  iconColor: string;
  caption: string;
  description: string;
  descriptionSmall?: string;
  images?: string[];
}

/**
 * ItemContainer()
 * @param props
 * @constructor
 */
export default function ItemContainer<T>(props: Props<T>) {
  const { icon, iconColor, caption, description, images, descriptionSmall } = props;
  const [t] = useTranslation();
  const [showImages, setShowImages] = React.useState<boolean>(false);

  const renderImageCount = () => {
    if (images && images.length > 0) {
      return <div className="Images">({t('image', { count: images.length })})</div>;
    }
    return null;
  };

  const renderDescriptionSmall = () => {
    if (descriptionSmall) {
      return <div className="DescriptionSmall">{descriptionSmall}</div>;
    }
    return null;
  };

  return (
    <>
      <Container onClick={images && images.length > 0 ? () => setShowImages(true) : () => {}}>
        <InnerContainer iconColor={iconColor}>
          <div className="Icon">
            <i className={icon} />
          </div>
          <div className="Text">
            <div className="CaptionContainer">
              <div className="Caption">{caption}</div>
              {renderImageCount()}
            </div>
            <div className="Description">{description}</div>
            {renderDescriptionSmall()}
          </div>
        </InnerContainer>
      </Container>

      <ImagePreview images={images} visible={showImages} onClose={() => setShowImages(false)} />
    </>
  );
}
