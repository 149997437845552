import React from 'react';
import { InvoiceEntity } from '../../../../../Globals/Types/Invoice';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DropDownItem from '../../../../../Components/CustomDropDown/DropDownItem';
import { useNavigate } from 'react-router';
import { buildUrlCustomersVoucherInvoiceCreate } from '../../../../../Globals/UrlFunctions';

interface Props {
  invoice: InvoiceEntity;
  customerId: string;
}

/**
 * InvoiceVoucherButton()
 * @param props
 * @constructor
 */
export default function InvoiceVoucherButton(props: Props) {
  const { invoice, customerId } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Dropdown.Divider />
      <DropDownItem
        onClick={() => navigate(buildUrlCustomersVoucherInvoiceCreate(customerId, invoice.invoiceId))}
        key={'markPayed'}
      >
        <i className="fa-light fa-money-bill-transfer" />
        {t('invoiceCreateVoucher')}
      </DropDownItem>
    </>
  );
}
