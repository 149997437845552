import React from 'react';
import { CustomerNoteEntity } from '../../../../Globals/Types/Customer';
import Note from './Note';

interface Props {
  notes: CustomerNoteEntity[];
  onEdit: (note: CustomerNoteEntity) => void;
  onDelete: (note: CustomerNoteEntity) => void;
}

/**
 * NotesList
 * @param props
 * @constructor
 */
export default function NotesList(props: Props) {
  const { notes, onEdit, onDelete } = props;

  if (notes) {
    return (
      <>
        {notes.map((note) => (
          <Note note={note} onEdit={onEdit} onDelete={onDelete} key={note.noteId} />
        ))}
      </>
    );
  }
  return null;
}
