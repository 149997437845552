import React from 'react';
import { Report, ReportState } from '../../../Globals/Types/Report';
import Panel from '../../Panel';
import { Button } from 'react-bootstrap';
import { useDispatchReportCreatePdf } from '../../../Redux/Actions/Order/Service/ReportAction';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import CreatePublicLinkModal from '../../../Modules/Order/Report/CreatePublicLinkModal';
import { openBase64PdfInBrowser } from '../../../Globals/Functions';

interface Props {
  report: Report;
}

/**
 * ActionButtons()
 * @param props
 * @constructor
 */
export default function ActionButtons(props: Props) {
  const { report } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [showLinking, setShowLinking] = React.useState<boolean>(false);
  const { order } = useAppSelector((state) => state.order.selectedOrder);
  const { service } = useAppSelector((state) => state.order.selectedOrder.selectedService);
  const { setLoading, loadingModal } = useLoadingModal({ headline: t('generatingPdf') });
  const dispatchCreateReport = useDispatchReportCreatePdf();

  const handleOnCreate = () => {
    setLoading(true);
    dispatchCreateReport(order.ownerClientId, order.orderId, service.serviceId, report.reportId)
      .then((response) => {
        if (response.internalBase64Report.length > 0) {
          openBase64PdfInBrowser(response.internalBase64Report);
        }
      })
      .finally(() => setLoading(false));
  };

  if (report.type === ReportState.complete) {
    return (
      <>
        <Panel>
          <div className="d-flex flex-lg-row">
            <div>
              <Button variant="outline-secondary" onClick={() => navigate(-1)}>
                <i className="fas fa-angles-left" style={{ marginRight: 8 }} />
                {t('back')}
              </Button>
            </div>
            <div style={{ justifyContent: 'flex-end', display: 'flex', flex: 1 }}>
              <Button variant="success" onClick={() => setShowLinking(true)} style={{ marginRight: 8 }}>
                <i className="fas fa-globe" style={{ marginRight: 8 }} />
                {t('publicLink')}
              </Button>
              <Button variant="success" onClick={() => handleOnCreate()}>
                <i className="fas fa-cloud-download" style={{ marginRight: 8 }} />
                {t('downloadReport')}
              </Button>
            </div>
          </div>
        </Panel>

        <CreatePublicLinkModal
          visible={showLinking}
          onClose={() => setShowLinking(false)}
          order={order}
          service={service}
          report={report}
        />

        {loadingModal}
      </>
    );
  }
  return null;
}
