import React from 'react';
import PageHeader from '../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import DashboardBox from '../../Components/Dashboard/Box';
import styled, { useTheme } from 'styled-components';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import moment from 'moment';
import AlertUpdateFacilityData from '../../Components/Facility/AlertUpdateFacilityData';
import { PredefinedFilters, useSetPredefinedFilter } from './SetPredefinedFilters';
import { useNavigate } from 'react-router';
import { buildUrlDispositionWithDate, UrlPatterns } from '../../Globals/UrlFunctions';
import { useTimeTrackingIsAllowedToUse } from '../../Globals/Hooks/TimeTrackingHooks';
import NotPresentHint from '../../Components/TimeTracking/NotPresentHint';
import ChangePasswordModal from '../../Components/User/ChangePasswordModal';
import { Col, Row } from 'react-bootstrap';
import TodayList from '../../Components/Dashboard/TodayList';
import InvoiceCountBox from '../../Components/Dashboard/InvoiceCountBox';
import InvoiceMonthlyPayedBox from '../../Components/Dashboard/InvoiceMonthlyPayedBox';
import { useReloadDashboard } from './DashboardLoadingFunction';
import { AccessPath, useHasAccess } from '../../Globals/Access/Groups';

const DateStyle = styled.div`
  font-size: 20px;
  color: ${(props) => props.theme.color.primaryColor};
`;

const TimeStyle = styled.div`
  font-size: 20px;
  color: ${(props) => props.theme.color.primaryColor};
  text-align: right;
`;

/**
 * Dashboard()
 * @constructor
 */
export default function Dashboard() {
  const { tomorrow, unterminated } = useAppSelector((state) => state.dashboard.services);
  const { user } = useAppSelector((state) => state.auth);
  const { tasks } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const theme = useTheme();
  const [time, setTime] = React.useState<string>(t('xClock', { time: moment().format('HH:mm') }));
  const [changePassword, setChangePassword] = React.useState<boolean>(false);
  const [openTaskCount, setOpenTaskCount] = React.useState<number>(0);
  const setPredefinedFilter = useSetPredefinedFilter();
  const isAllowedByTimeTracking = useTimeTrackingIsAllowedToUse();
  const hasOrderAccess = useHasAccess(AccessPath.order);
  const hasDispoAccess = useHasAccess(AccessPath.disposition);

  useReloadDashboard();

  React.useEffect(() => {
    if (user && user.changePassword) {
      setTimeout(() => setChangePassword(true), 500);
    }
  }, [user]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTime(t('xClock', { time: moment().format('HH:mm') }));
    }, 10000);

    return () => clearInterval(interval);
  }, [t]);

  React.useEffect(() => {
    if (tasks && tasks.length > 0) {
      setOpenTaskCount(tasks.filter((task) => !task.finished).length);
    } else {
      setOpenTaskCount(0);
    }
  }, [tasks]);

  const renderDateAndTime = () => {
    return (
      <div>
        <DateStyle>{moment().format('LLL')}</DateStyle>
        <TimeStyle>{time}</TimeStyle>
      </div>
    );
  };

  const handlePanelClick = (type: PredefinedFilters) => {
    setPredefinedFilter(type);
    navigate(`${UrlPatterns.orderList}?predefined`);
  };

  const renderContent = () => {
    if (isAllowedByTimeTracking) {
      return (
        <Row>
          <Col xxl={12} xl={12} md={12}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginRight: -15 }}>
              <DashboardBox
                headline={t('ordersTomorrow')}
                icon="fas fa-home"
                bgColor={theme.color.blue}
                onClick={hasOrderAccess ? () => handlePanelClick(PredefinedFilters.ordersTomorrow) : () => {}}
              >
                {tomorrow ? tomorrow.length : 0}
              </DashboardBox>
              <DashboardBox
                headline={t('ordersWithoutAppointment')}
                icon="fas fa-home"
                bgColor={theme.color.orange}
                onClick={hasOrderAccess ? () => handlePanelClick(PredefinedFilters.openOrders) : () => {}}
              >
                {unterminated ? unterminated.length : 0}
              </DashboardBox>
              <DashboardBox
                headline={t('tourToday')}
                icon="fas fa-home"
                bgColor={theme.color.green}
                onClick={
                  hasDispoAccess ? () => navigate(buildUrlDispositionWithDate(moment().format('YYYY-MM-DD'))) : () => {}
                }
              >
                0
              </DashboardBox>
              <DashboardBox
                headline={t('openTasks')}
                icon="fas fa-list-check"
                bgColor={theme.color.yellow}
                onClick={() => navigate('/profile/tasks')}
              >
                {openTaskCount}
              </DashboardBox>
              <InvoiceCountBox />
              <InvoiceMonthlyPayedBox />
            </div>
          </Col>
          <Col xxl={12} xl={12} md={12}>
            <TodayList />
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <>
      <PageHeader headline={t('startPage')} actionButtonOne={renderDateAndTime()} />

      <AlertUpdateFacilityData />
      <NotPresentHint />

      {renderContent()}

      <ChangePasswordModal onClose={() => setChangePassword(false)} visible={changePassword} forceChange />
    </>
  );
}
