import React from 'react';
import { useTranslation } from 'react-i18next';
import Lodash from 'lodash';
import { InternalErrorCodes } from '../../Globals/InternalErrorCodes';
import { ResourceEntity, UserEntity } from '../../Globals/Types/Types';
import { ElasticSearchServiceEntity } from '../../Globals/Types/OrderTypes';
import { DispositionValidationError } from './ValidateDragDrop';
import { TourService } from '../../Globals/Types/Tour';
import { ReportState } from '../../Globals/Types/Report';

/**
 * searchMonteure()
 * @param monteure
 * @param value
 */
export const searchMonteure = (monteure: UserEntity[], value: string): UserEntity[] => {
  if (!value || value.length === 0) {
    return monteure;
  }

  const valueLower: string = value?.toLowerCase();
  return Lodash.filter(monteure, (monteur) => {
    return (
      Lodash.includes(monteur.firstName.toLowerCase(), valueLower) ||
      Lodash.includes(monteur.lastName.toLowerCase(), valueLower) ||
      Lodash.includes(monteur.initials.toLowerCase(), valueLower)
    );
  });
};

/**
 * searchResources()
 * @param resources
 * @param value
 */
export const searchResources = (resources: ResourceEntity[], value: string): ResourceEntity[] => {
  if (!value || value.length === 0) {
    return resources;
  }

  const valueLower: string = value.toLowerCase();
  return Lodash.filter(resources, (resource) => {
    return Lodash.includes(resource.name.toLowerCase(), valueLower);
  });
};

/**
 * searchServices()
 * @param unscheduledServices
 * @param value
 */
export const searchServices = (
  unscheduledServices: ElasticSearchServiceEntity[],
  value: string,
): ElasticSearchServiceEntity[] => {
  if (!value || value.length === 0) {
    return unscheduledServices;
  }

  const valueLower: string = value.toLowerCase();
  return Lodash.filter(unscheduledServices, (service) => {
    return (
      Lodash.includes(service.customer.firstName.toLowerCase(), valueLower) ||
      Lodash.includes(service.customer.lastName.toLowerCase(), valueLower) ||
      Lodash.includes(service.customer.street.toLowerCase(), valueLower) ||
      Lodash.includes(service.customer.city.toLowerCase(), valueLower) ||
      Lodash.includes(service.customer.zip.toLowerCase(), valueLower) ||
      Lodash.includes(service.customer.comment ? service.customer.comment.toLowerCase() : '', valueLower) ||
      Lodash.includes(service.customer.mail ? service.customer.mail.toLowerCase() : '', valueLower) ||
      Lodash.includes(service.externalOrderId.toLowerCase(), valueLower)
    );
  });
};

export type DispositionErrorMessage = { headline: string; description: string } | null;
type ErrorMessageReturnType = (error: DispositionValidationError) => DispositionErrorMessage;
/**
 * useGetErrorMessage()
 */
export const useGetErrorMessage = (): ErrorMessageReturnType => {
  const [t] = useTranslation();

  return React.useCallback<ErrorMessageReturnType>(
    (error) => {
      switch (error.code) {
        case InternalErrorCodes.DISPOSITION_MISMATCH_FACILITY:
          return {
            headline: t('errorMessages.dispositionMismatchFacility.headline'),
            description: t('errorMessages.dispositionMismatchFacility.description'),
          };
        case InternalErrorCodes.DISPOSITION_ALREADY_PLANNED_RESOURCE:
          return {
            headline: t('errorMessages.dispositionAlreadyPlannedResource.headline'),
            description: t('errorMessages.dispositionAlreadyPlannedResource.description', { ...error.details }),
          };
        case InternalErrorCodes.DISPOSITION_ALREADY_PLANNED_USER:
          return {
            headline: t('errorMessages.dispositionAlreadyPlannedUser.headline'),
            description: t('errorMessages.dispositionAlreadyPlannedUser.description', { ...error.details }),
          };

        case InternalErrorCodes.DISPOSITION_OVER_24H:
          return {
            headline: t('errorMessages.dispositionOver24h.headline'),
            description: t('errorMessages.dispositionOver24h.description'),
          };

        case InternalErrorCodes.DISPOSITION_USER_NOT_ALL_FACILITIES:
          return {
            headline: t('errorMessages.dispositionUserMissingFacility.headline'),
            description: t('errorMessages.dispositionUserMissingFacility.description'),
          };

        default:
          return null;
      }
    },
    [t],
  );
};

/**
 * tourServiceToElasticSearchService()
 * @param service
 */
export const tourServiceToElasticSearchService = (service: TourService): ElasticSearchServiceEntity => {
  return {
    ownerClientId: service.ownerClientId,
    ownerFacilityId: service.ownerFacilityId,
    createdClientId: service.createdClientId,
    createdFacilityId: service.createdFacilityId,
    orderId: service.orderId,
    serviceId: service.serviceId,
    serviceType: (service as TourService).serviceType,
    externalOrderId: service.externalOrderId,
    externalServiceId: service.externalServiceId,
    estimatedAssemblyTime: (service as TourService).estimatedAssemblyTime,
    customer: service.customer,
    createdDate: null,
    createdUserId: null,
    reportState: ReportState.none,
    disposition: null,
    remainingAssemblyTime: null,
    isRecurring: service.isRecurring,
    deliveryAndLoadingOption: service.deliveryAndLoadingOption,
  };
};
