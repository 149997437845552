import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import { lighten } from 'polished';

type DefaultLayoutProps = {
  navigation: any;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Footer = styled.div`
  padding: 40px;
  text-align: center;
  color: ${(props) => lighten(0.2, props.theme.color.textGray)};

  .normal {
    line-height: 30px;
    font-weight: bolder;
  }

  .small {
    line-height: 22px !important;

    > span {
      margin: 0 10px;
    }
  }

  a {
    color: ${(props) => lighten(0.2, props.theme.color.textGray)};
  }
`;

/**
 * LinkingLayout()
 * @param props
 * @constructor
 */
export default function LinkingLayout(props: DefaultLayoutProps) {
  const { isLoggedIn } = useSelector((state: any) => state.auth);

  if (!isLoggedIn) {
    return (
      <Container>
        {props.navigation}
        <Outlet />

        <Footer>
          <BrowserView>
            <div className="normal">© 2023 Sotosoft S.L. / Sotogrande / NIF: B72570732</div>
          </BrowserView>
          <MobileOnlyView>
            <div className="normal">© 2023 Sotosoft S.L. / Sotogrande</div>
          </MobileOnlyView>

          <div className="small">
            <span>
              <a href="https://www.sotosoft.app">https://www.sotosoft.app</a>
            </span>
          </div>
        </Footer>
      </Container>
    );
  }
  return <Navigate replace to={'/dashboard'} />;
}
