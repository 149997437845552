import React from 'react';
import { useAppSelector } from './Hooks';

/**
 * Get a formatted string of the facilities given in the array
 * @param facilityIds
 * @param short
 */
export const useGetTourAssignedFacilitiesString = (facilityIds: String[], short: boolean = false): string => {
  const { facilities } = useAppSelector((state) => state.client);
  const [hasFacilities] = React.useState<boolean>(facilities && facilities.length > 1);
  const [value, setValue] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (hasFacilities) {
      let names: String[] = [];
      facilityIds.forEach((facilityId) => {
        const found = facilities.find((item) => item.facilityId === facilityId);
        if (found) {
          if (short) {
            names.push(found.nameShort);
          } else {
            names.push(found.name);
          }
        }
      });
      setValue(names.join(', '));
    }
  }, [facilities, hasFacilities, short, facilityIds]);

  return value;
};
