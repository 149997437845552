import { ElasticSearchServiceEntity, OrderEntity, ServiceEntity } from '../../../Globals/Types/OrderTypes';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import React from 'react';
import {
  ORDER_SERVICE_CREATE_ERROR,
  ORDER_SERVICE_CREATE_START,
  ORDER_SERVICE_CREATE_SUCCESS,
  ORDER_SERVICE_DELETE_ERROR,
  ORDER_SERVICE_DELETE_START,
  ORDER_SERVICE_DELETE_SUCCESS,
  ORDER_SERVICE_FILTER_ERROR,
  ORDER_SERVICE_FILTER_START,
  ORDER_SERVICE_FILTER_SUCCESS,
  ORDER_SERVICE_GETLIST_ERROR,
  ORDER_SERVICE_GETLIST_START,
  ORDER_SERVICE_GETLIST_SUCCESS,
  ORDER_SERVICE_INITIALIZE_INDEX_ERROR,
  ORDER_SERVICE_INITIALIZE_INDEX_START,
  ORDER_SERVICE_INITIALIZE_INDEX_SUCCESS,
  ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_ERROR,
  ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_START,
  ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_SUCCESS,
  ORDER_SERVICE_REBUILD_INDEX_ERROR,
  ORDER_SERVICE_REBUILD_INDEX_START,
  ORDER_SERVICE_REBUILD_INDEX_SUCCESS,
  ORDER_SERVICE_SEARCH_ERROR,
  ORDER_SERVICE_SEARCH_START,
  ORDER_SERVICE_SEARCH_SUCCESS,
  ORDER_SERVICE_SET_SELECTED,
  ORDER_SERVICE_UPDATE_ERROR,
  ORDER_SERVICE_UPDATE_START,
  ORDER_SERVICE_UPDATE_SUCCESS,
} from '../../ActionTypes';
import { collection, CollectionReference, getDocs, getFirestore } from 'firebase/firestore';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import Lodash from 'lodash';
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { FacilityPartnerEntity, MessageEntity } from '../../../Globals/Types/Types';
import { SystemMessageTemplate } from '../../../Globals/Types/Enums';
import { useDispatchUserGet } from '../UserAction';
import { messageSystemSender } from '../../../Globals/Types/General';
import { buildUrlOrderServiceDetails } from '../../../Globals/UrlFunctions';
import { userEntityToPickedUserEntity } from '../../../Globals/Functions';

type OrderServiceGetListReturnType = (clientId: string, orderId: string) => Promise<Array<ServiceEntity>>;
/**
 * useDispatchOrderGet()
 */
export const useDispatchOrderServiceGetList = (): OrderServiceGetListReturnType => {
  const dispatch = useDispatch();

  return React.useCallback<OrderServiceGetListReturnType>(
    (clientId: string, orderId: string) => {
      dispatch({ type: ORDER_SERVICE_GETLIST_START, payload: { clientId, orderId } });

      const collectionRef = collection(
        getFirestore(),
        FirebasePathMappings.client,
        clientId,
        FirebasePathMappings.order,
        orderId,
        FirebasePathMappings.service,
      ) as CollectionReference<ServiceEntity>;

      return getDocs(collectionRef)
        .then((snapShot) => {
          if (snapShot.size > 0) {
            const services: Array<ServiceEntity> = [];
            snapShot.forEach((service) => {
              services.push({ ...service.data(), serviceId: service.id });
            });
            dispatch({ type: ORDER_SERVICE_GETLIST_SUCCESS, payload: services });
            return Promise.resolve(services);
          }

          return Promise.reject(new Error(InternalErrorCodes.FIRESTORE_DOCUMENT_NOT_FOUND));
        })
        .catch((error) => {
          dispatch({ type: ORDER_SERVICE_GETLIST_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

export interface OrderServiceFilterResponseType {
  services: ElasticSearchServiceEntity[];
  availableYears: number[];
}
type OrderServiceFilterReturnType = (startDate: string, endDate: string) => Promise<OrderServiceFilterResponseType>;
/**
 * useDispatchOrderServiceFilter
 */
export const useDispatchOrderServiceFilter = (dispatchEvents: boolean): OrderServiceFilterReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);
  const { facilities: userFacilityIds } = useAppSelector((state) => state.auth.user);

  const internalDispatch = React.useCallback(
    (type: string, payload: any) => {
      if (dispatchEvents) {
        dispatch({ type, payload });
      }
    },
    [dispatch, dispatchEvents],
  );

  return React.useCallback<OrderServiceFilterReturnType>(
    (startDate, endDate) => {
      internalDispatch(ORDER_SERVICE_FILTER_START, clientId);

      const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.serviceFilter);
      return callable({ clientId, startDate, endDate, userFacilityIds })
        .then((response: HttpsCallableResult<OrderServiceFilterResponseType>) => {
          internalDispatch(ORDER_SERVICE_FILTER_SUCCESS, response.data);
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          internalDispatch(ORDER_SERVICE_FILTER_ERROR, error);
          return Promise.reject(new Error(error));
        });
    },
    [clientId, internalDispatch, userFacilityIds],
  );
};

type OrderServiceSearchReturnType = (value: string) => Promise<Array<ElasticSearchServiceEntity>>;
/**
 * useDispatchOrderServiceFilter
 */
export const useDispatchOrderServiceSearch = (): OrderServiceSearchReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);
  const { facilities: userFacilityIds } = useAppSelector((state) => state.auth.user);

  return React.useCallback<OrderServiceSearchReturnType>(
    (value) => {
      dispatch({ type: ORDER_SERVICE_SEARCH_START, payload: { clientId, value } });

      const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.serviceSearch);
      return callable({ clientId, value, userFacilityIds })
        .then((response: HttpsCallableResult<Array<ElasticSearchServiceEntity>>) => {
          dispatch({ type: ORDER_SERVICE_SEARCH_SUCCESS, payload: response.data });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({ type: ORDER_SERVICE_SEARCH_ERROR, payload: error });
          return Promise.reject(new Error(error));
        });
    },
    [clientId, dispatch, userFacilityIds],
  );
};

type RebuildIndexReturnType = () => Promise<number>;
/**
 * useDispatchOrderServiceRebuildIndex
 */
export const useDispatchOrderServiceRebuildIndex = (): RebuildIndexReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<RebuildIndexReturnType>(() => {
    dispatch({ type: ORDER_SERVICE_REBUILD_INDEX_START, payload: clientId });

    const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.serviceRebuildIndex);
    return callable({ clientId })
      .then((response: HttpsCallableResult<number>) => {
        dispatch({ type: ORDER_SERVICE_REBUILD_INDEX_SUCCESS, payload: response.data });
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_SERVICE_REBUILD_INDEX_ERROR, payload: error });
        return Promise.reject(new Error(error));
      });
  }, [clientId, dispatch]);
};

/**
 * useDispatchOrderServiceUpdate
 */
type OrderServiceUpdateReturnType = (
  clientId: string,
  orderId: string,
  serviceId: string,
  data: Partial<ServiceEntity>,
) => Promise<ServiceEntity>;

export const useDispatchOrderServiceUpdate = (): OrderServiceUpdateReturnType => {
  const dispatch = useDispatch();

  return React.useCallback<OrderServiceUpdateReturnType>(
    (clientId, orderId, serviceId, data) => {
      dispatch({ type: ORDER_SERVICE_UPDATE_START, payload: { clientId, orderId, serviceId, data } });

      const updateCallable = httpsCallable(getFunctions(), FirebaseFunctionNames.serviceUpdate);
      return updateCallable({ clientId, orderId, serviceId, data })
        .then((response: HttpsCallableResult<ServiceEntity>) => {
          dispatch({ type: ORDER_SERVICE_UPDATE_SUCCESS, payload: { orderId, service: response.data } });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({ type: ORDER_SERVICE_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchOrderServiceSendAssignPartnerNotification
 */
export const useDispatchOrderServiceSendAssignPartnerNotification = () => {
  const dispatch = useDispatch();
  const { facilities } = useAppSelector((state) => state.client);
  const dispatchGetUser = useDispatchUserGet();

  return React.useCallback(
    (order: OrderEntity, service: ServiceEntity, partner: FacilityPartnerEntity) => {
      dispatch({ type: ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_START, payload: { order, service, partner } });

      if (partner.partnerSettings.responsibleUserId && partner.partnerSettings.responsibleUserId.length > 0) {
        const facility = Lodash.find(facilities, (item) => item.facilityId === order.ownerFacilityId);

        return dispatchGetUser(partner.partnerSettings.responsibleUserId).then((userResponse) => {
          const linkPath = buildUrlOrderServiceDetails(order.ownerClientId, order.orderId, service.serviceId);

          const message: MessageEntity = {
            subject: 'TEMPLATE MESSAGE',
            message: 'TEMPLATE MESSAGE',
            read: false,
            mailSend: false,
            createdDate: null,
            isSystemMessage: true,
            template: SystemMessageTemplate.servicePartnerAssigned,
            parameters: {
              externalId: order.externalId,
              typeName: service.type.caption,
              partnerName: facility.name,
            },
            sender: messageSystemSender,
            receivers: [userEntityToPickedUserEntity(userResponse)],
            link: linkPath,
            linkCaptionKey: 'toOrder',
          };

          const sendMessageCallable = httpsCallable<MessageEntity>(getFunctions(), FirebaseFunctionNames.sendMessage);
          return sendMessageCallable(message)
            .then((response) => {
              dispatch({ type: ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_SUCCESS, payload: response });
              return Promise.resolve();
            })
            .catch((error) => {
              dispatch({ type: ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_ERROR, payload: error });
              return Promise.reject(error);
            });
        });
      } else {
        dispatch({
          type: ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_ERROR,
          payload: new Error('No responsible user assigned!'),
        });
        return Promise.reject();
      }
    },
    [dispatch, dispatchGetUser, facilities],
  );
};

/**
 * useDispatchOrderServiceSendDeletePartnerNotification
 */
export const useDispatchOrderServiceSendDeletePartnerNotification = () => {
  const dispatch = useDispatch();
  const { facilities } = useAppSelector((state) => state.client);
  const dispatchGetUser = useDispatchUserGet();

  return React.useCallback(
    (order: OrderEntity, service: ServiceEntity, partner: FacilityPartnerEntity) => {
      dispatch({ type: ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_START, payload: { order, service, partner } });

      if (partner.partnerSettings.responsibleUserId && partner.partnerSettings.responsibleUserId.length > 0) {
        const facility = Lodash.find(facilities, (item) => item.facilityId === order.ownerFacilityId);

        return dispatchGetUser(partner.partnerSettings.responsibleUserId).then((userResponse) => {
          const message: MessageEntity = {
            subject: 'TEMPLATE MESSAGE',
            message: 'TEMPLATE MESSAGE',
            read: false,
            mailSend: false,
            createdDate: null,
            isSystemMessage: true,
            template: SystemMessageTemplate.servicePartnerDelete,
            parameters: {
              externalId: order.externalId,
              partnerName: facility.name,
            },
            sender: messageSystemSender,
            receivers: [
              {
                userId: userResponse.userId,
                firstName: userResponse.firstName,
                lastName: userResponse.lastName,
                initials: userResponse.initials,
              },
            ],
          };

          const sendMessageCallable = httpsCallable<MessageEntity>(getFunctions(), FirebaseFunctionNames.sendMessage);
          return sendMessageCallable(message)
            .then((response) => {
              dispatch({ type: ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_SUCCESS, payload: response });
              return Promise.resolve();
            })
            .catch((error) => {
              dispatch({ type: ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_ERROR, payload: error });
              return Promise.reject(error);
            });
        });
      } else {
        dispatch({
          type: ORDER_SERVICE_PARTNER_ASSIGNED_NOTIFICATION_ERROR,
          payload: new Error('No responsible user assigned!'),
        });
        return Promise.reject();
      }
    },
    [dispatch, dispatchGetUser, facilities],
  );
};

/**
 * useDispatchOrderServiceDelete
 */
type OrderServiceDeleteReturnType = (order: OrderEntity, service: ServiceEntity) => Promise<boolean>;
export const useDispatchOrderServiceDelete = (): OrderServiceDeleteReturnType => {
  const dispatch = useDispatch();

  return React.useCallback(
    (order, service) => {
      dispatch({ type: ORDER_SERVICE_DELETE_START, payload: { order, service } });

      const postData = {
        clientId: order.ownerClientId,
        orderId: order.orderId,
        serviceId: service.serviceId,
      };

      const deleteCallable = httpsCallable(getFunctions(), FirebaseFunctionNames.serviceDelete);
      return deleteCallable(postData)
        .then(() => {
          dispatch({ type: ORDER_SERVICE_DELETE_SUCCESS, payload: service });
          return Promise.resolve(true);
        })
        .catch((error) => {
          dispatch({ type: ORDER_SERVICE_DELETE_ERROR, payload: error });
          return Promise.reject(false);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchOrderServiceCreate
 */
type OrderServiceCreateReturnType = (order: OrderEntity, service: ServiceEntity) => Promise<ServiceEntity>;
export const useDispatchOrderServiceCreate = (): OrderServiceCreateReturnType => {
  const dispatch = useDispatch();

  return React.useCallback(
    (order, service) => {
      dispatch({ type: ORDER_SERVICE_CREATE_START, payload: { order, service } });

      const postData = {
        clientId: order.ownerClientId,
        orderId: order.orderId,
        service: service,
      };

      const deleteCallable = httpsCallable(getFunctions(), FirebaseFunctionNames.serviceCreate);
      return deleteCallable(postData)
        .then((response: HttpsCallableResult<ServiceEntity>) => {
          dispatch({ type: ORDER_SERVICE_CREATE_SUCCESS, payload: response.data });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({ type: ORDER_SERVICE_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchInitializeIndexService()
 */
export const useDispatchInitializeIndexService = (): (() => Promise<void>) => {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch({ type: ORDER_SERVICE_INITIALIZE_INDEX_START });
    const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.serviceInitializeIndex);
    return callable()
      .then(() => {
        dispatch({ type: ORDER_SERVICE_INITIALIZE_INDEX_SUCCESS });
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch({ type: ORDER_SERVICE_INITIALIZE_INDEX_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [dispatch]);
};

/**
 * useDispatchOrderServiceSetSelected()
 * Set the current selected service for service / order details page
 */
export const useDispatchOrderServiceSetSelected = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    (service: ServiceEntity) => {
      dispatch({ type: ORDER_SERVICE_SET_SELECTED, payload: service });
    },
    [dispatch],
  );
};
