import React from 'react';
import { TimeTrackingEntity, TimeTrackingStateEntity } from '../../../Globals/Types/Types';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import {
  CLIENT_TIMETRACKING_CREATE_ERROR,
  CLIENT_TIMETRACKING_CREATE_PDF_ERROR,
  CLIENT_TIMETRACKING_CREATE_PDF_START,
  CLIENT_TIMETRACKING_CREATE_PDF_SUCCESS,
  CLIENT_TIMETRACKING_CREATE_START,
  CLIENT_TIMETRACKING_CREATE_SUCCESS,
  CLIENT_TIMETRACKING_DELETE_ERROR,
  CLIENT_TIMETRACKING_DELETE_START,
  CLIENT_TIMETRACKING_DELETE_SUCCESS,
  CLIENT_TIMETRACKING_GETBYMONTH_ERROR,
  CLIENT_TIMETRACKING_GETBYMONTH_START,
  CLIENT_TIMETRACKING_GETBYMONTH_SUCCESS,
  CLIENT_TIMETRACKING_GETSTATE_ERROR,
  CLIENT_TIMETRACKING_GETSTATE_START,
  CLIENT_TIMETRACKING_GETSTATE_SUCCESS,
  CLIENT_TIMETRACKING_UPDATE_ERROR,
  CLIENT_TIMETRACKING_UPDATE_START,
  CLIENT_TIMETRACKING_UPDATE_SUCCESS,
} from '../../ActionTypes';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import moment from 'moment';
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * useDispatchClientTimeTrackingGetState()
 * Get the list of the last states of time trackings. Only users having last time trackings are listed here!
 */
type GetStateReturnType = () => Promise<TimeTrackingStateEntity[]>;
export const useDispatchClientTimeTrackingGetState = (): GetStateReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetStateReturnType>(() => {
    dispatch({ type: CLIENT_TIMETRACKING_GETSTATE_START, payload: clientId });

    const collectionRef = collection(
      getFirestore(),
      FirebasePathMappings.client,
      clientId,
      FirebasePathMappings.timeTrackingState,
    ) as CollectionReference<TimeTrackingStateEntity>;
    const queryRef = query(collectionRef, orderBy('user.lastName', 'asc'));

    return getDocs(queryRef)
      .then((response) => {
        const result: Array<TimeTrackingStateEntity> = [];
        if (!response.empty) {
          response.forEach((doc) => {
            result.push({ ...doc.data(), id: doc.id });
          });

          dispatch({ type: CLIENT_TIMETRACKING_GETSTATE_SUCCESS, payload: result });
          return Promise.resolve(result);
        }
        return Promise.reject();
      })
      .catch((error) => {
        dispatch({ type: CLIENT_TIMETRACKING_GETSTATE_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [dispatch, clientId]);
};

/**
 * useDispatchClientTimeTrackingGetByMonth()
 */
type GetByMonthReturnType = (userId: string, month: number, year: number) => Promise<TimeTrackingEntity[]>;
export const useDispatchClientTimeTrackingGetByMonth = (): GetByMonthReturnType => {
  const dispatch = useAppDispatch();

  return React.useCallback<GetByMonthReturnType>(
    (userId, month, year) => {
      dispatch({ type: CLIENT_TIMETRACKING_GETBYMONTH_START, payload: { month, year } });
      const startDate = moment(`01-${month}-${year}`, 'DD-M-YYYY').startOf('month');
      const endDate = moment(`01-${month}-${year}`, 'DD-M-YYYY').endOf('month');

      const collectionRef = collection(
        getFirestore(),
        FirebasePathMappings.user,
        userId,
        FirebasePathMappings.timeTracking,
      ) as CollectionReference<TimeTrackingEntity>;

      const queryRef = query(
        collectionRef,
        where('timestamp', '>=', startDate.toDate()),
        where('timestamp', '<=', endDate.toDate()),
        orderBy('timestamp', 'asc'),
      );

      return getDocs(queryRef)
        .then((documents) => {
          const result: Array<TimeTrackingEntity> = [];
          if (!documents.empty) {
            documents.forEach((item) => {
              result.push({ ...item.data(), id: item.id });
            });
          }
          dispatch({ type: CLIENT_TIMETRACKING_GETBYMONTH_SUCCESS, payload: result });
          return Promise.resolve(result);
        })
        .catch((error) => {
          dispatch({ type: CLIENT_TIMETRACKING_GETBYMONTH_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchClientTimeTrackingUpdate()
 */
type UpdateReturnType = (userId: string, timeTracking: TimeTrackingEntity) => Promise<TimeTrackingEntity>;
export const useDispatchClientTimeTrackingUpdate = (): UpdateReturnType => {
  const dispatch = useAppDispatch();

  return React.useCallback<UpdateReturnType>(
    (userId, timeTracking) => {
      dispatch({ type: CLIENT_TIMETRACKING_UPDATE_START, payload: { userId, timeTracking } });

      const docRef = doc(
        getFirestore(),
        FirebasePathMappings.user,
        userId,
        FirebasePathMappings.timeTracking,
        timeTracking.id,
      ) as any;

      return setDoc(docRef, timeTracking, { merge: true })
        .then(() => {
          dispatch({ type: CLIENT_TIMETRACKING_UPDATE_SUCCESS, payload: timeTracking });
          return Promise.resolve(timeTracking);
        })
        .catch((error) => {
          dispatch({ type: CLIENT_TIMETRACKING_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchClientTimeTrackingCreate()
 */
type CreateReturnType = (userId: string, timeTracking: TimeTrackingEntity) => Promise<TimeTrackingEntity>;
export const useDispatchClientTimeTrackingCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();

  return React.useCallback<CreateReturnType>(
    (userId, timeTracking) => {
      dispatch({ type: CLIENT_TIMETRACKING_CREATE_START, payload: { userId, timeTracking } });

      const docRef = collection(
        getFirestore(),
        FirebasePathMappings.user,
        userId,
        FirebasePathMappings.timeTracking,
      ) as any;

      return addDoc(docRef, timeTracking)
        .then((snap) => {
          const mapped: TimeTrackingEntity = { ...timeTracking, id: snap.id };
          dispatch({ type: CLIENT_TIMETRACKING_CREATE_SUCCESS, payload: mapped });
          return Promise.resolve(mapped);
        })
        .catch((error) => {
          dispatch({ type: CLIENT_TIMETRACKING_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchClientTimeTrackingDelete()
 */
type DeleteReturnType = (userId: string, timeTrackingId: string) => Promise<string>;
export const useDispatchClientTimeTrackingDelete = (): DeleteReturnType => {
  const dispatch = useAppDispatch();

  return React.useCallback<DeleteReturnType>(
    (userId, timeTrackingId) => {
      dispatch({ type: CLIENT_TIMETRACKING_DELETE_START, payload: { userId, timeTrackingId } });

      const docRef = doc(
        getFirestore(),
        FirebasePathMappings.user,
        userId,
        FirebasePathMappings.timeTracking,
        timeTrackingId,
      );

      return deleteDoc(docRef)
        .then(() => {
          dispatch({ type: CLIENT_TIMETRACKING_DELETE_SUCCESS, payload: timeTrackingId });
          return Promise.resolve(timeTrackingId);
        })
        .catch((error) => {
          dispatch({ type: CLIENT_TIMETRACKING_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchClientTimeTrackingCreateUserPdf()
 */
interface CreateUserPdfHttpsParams {
  clientId: string;
  userId: string;
  month: number;
  year: number;
}
export const useDispatchClientTimeTrackingCreateUserPdf = () => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (userId, month, year) => {
      dispatch({ type: CLIENT_TIMETRACKING_CREATE_PDF_START, payload: userId, month, year });

      const callable = httpsCallable<CreateUserPdfHttpsParams, string>(
        getFunctions(),
        FirebaseFunctionNames.clientTimeTrackingCreatePdf,
      );

      return callable({ clientId, userId, month, year })
        .then((response) => {
          dispatch({ type: CLIENT_TIMETRACKING_CREATE_PDF_SUCCESS, payload: response });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({ type: CLIENT_TIMETRACKING_CREATE_PDF_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};
