import React from 'react';
import Select, { SelectOptions } from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { CountryCode } from '../../Globals/Types/Enums';
import { useAppSelector } from '../../Globals/Hooks/Hooks';

interface Props {
  onChange: (item: CountryCode) => void;
  selected?: string;
  required?: boolean;
  disabled?: boolean;
  showSkeleton: boolean;
  visible: boolean;
}

/**
 * SelectFacility
 * @param props
 * @constructor
 */
export default function SelectFacility(props: Props) {
  const { onChange, selected, required, disabled, showSkeleton, visible } = props;
  const { facilities } = useAppSelector((state) => state.client);
  const { facilities: userFacilities } = useAppSelector((state) => state.auth.user);
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>([]);

  React.useEffect(() => {
    const optionArray = facilities
      .filter((item) => userFacilities.indexOf(item.facilityId) >= 0)
      .map((item) => ({
        value: item.facilityId,
        label: item.name,
      }));

    setOptions(optionArray);
  }, [facilities, userFacilities]);

  const handleOnChange = (value: string) => {
    onChange(value as CountryCode);
  };

  if (options && options.length > 0 && visible) {
    return (
      <Select
        label={t('selectFacility')}
        onChange={handleOnChange}
        initialValue={selected}
        options={options}
        required={required}
        disabled={disabled}
        showSkeleton={showSkeleton}
      />
    );
  }
  return null;
}

SelectFacility.defaultProps = {
  required: false,
  disabled: false,
  showSkeleton: false,
  visible: true,
};
