import { ElasticSearchServiceEntity } from '../../../../Globals/Types/OrderTypes';
import { useAppDispatch, useAppSelector } from '../../../../Globals/Hooks/Hooks';
import React from 'react';
import {
  ORDER_SERVICE_FILTER_BY_CUSTOMER_ERROR,
  ORDER_SERVICE_FILTER_BY_CUSTOMER_START,
  ORDER_SERVICE_FILTER_BY_CUSTOMER_SUCCESS,
  ORDER_SERVICE_FILTER_UNSCHEDULED_ERROR,
  ORDER_SERVICE_FILTER_UNSCHEDULED_START,
  ORDER_SERVICE_FILTER_UNSCHEDULED_SUCCESS,
  ORDER_SERVICE_GET_FROM_INDEX_ERROR,
  ORDER_SERVICE_GET_FROM_INDEX_START,
  ORDER_SERVICE_GET_FROM_INDEX_SUCCESS,
} from '../../../ActionTypes';
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { FirebaseFunctionNames } from '../../../../Globals/FirebaseGlobals';

/**
 * useDispatchOrderServiceFilter
 */
type OrderServiceFilterUnscheduledReturnType = () => Promise<ElasticSearchServiceEntity[]>;
export const useDispatchOrderServiceFilterUnscheduled = (): OrderServiceFilterUnscheduledReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);
  const { facilities: userFacilityIds } = useAppSelector((state) => state.auth.user);

  return React.useCallback<OrderServiceFilterUnscheduledReturnType>(() => {
    dispatch({ type: ORDER_SERVICE_FILTER_UNSCHEDULED_START, payload: { clientId } });

    const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.serviceFilterUnscheduled);
    return callable({ clientId, userFacilityIds })
      .then((response: HttpsCallableResult<Array<ElasticSearchServiceEntity>>) => {
        dispatch({ type: ORDER_SERVICE_FILTER_UNSCHEDULED_SUCCESS, payload: { services: response.data, clientId } });
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_SERVICE_FILTER_UNSCHEDULED_ERROR, payload: error });
        return Promise.reject(new Error(error));
      });
  }, [clientId, dispatch, userFacilityIds]);
};

/**
 * useDispatchOrderServiceFilterByCustomer
 */
type OrderServiceFilterByCustomerReturnType = (customerId: string) => Promise<ElasticSearchServiceEntity[]>;
export const useDispatchOrderServiceFilterByCustomer = (): OrderServiceFilterByCustomerReturnType => {
  const dispatch = useAppDispatch();

  return React.useCallback<OrderServiceFilterByCustomerReturnType>(
    (customerId) => {
      dispatch({ type: ORDER_SERVICE_FILTER_BY_CUSTOMER_START, payload: { customerId } });

      const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.serviceFilterByCustomer);
      return callable({ customerId })
        .then((response: HttpsCallableResult<Array<ElasticSearchServiceEntity>>) => {
          dispatch({ type: ORDER_SERVICE_FILTER_BY_CUSTOMER_SUCCESS, payload: response.data });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({ type: ORDER_SERVICE_FILTER_BY_CUSTOMER_ERROR, payload: error });
          return Promise.reject(new Error(error));
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchOrderServiceGetFromIndex()
 */
type OrderServiceGetFromIndexReturnType = (serviceId: string) => Promise<ElasticSearchServiceEntity>;
export const useDispatchOrderServiceGetFromIndex = (): OrderServiceGetFromIndexReturnType => {
  const dispatch = useAppDispatch();

  return React.useCallback<OrderServiceGetFromIndexReturnType>(
    (serviceId: string) => {
      dispatch({ type: ORDER_SERVICE_GET_FROM_INDEX_START, payload: serviceId });

      const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.serviceGetFromIndex);
      return callable({ serviceId })
        .then((response: HttpsCallableResult<ElasticSearchServiceEntity>) => {
          dispatch({ type: ORDER_SERVICE_GET_FROM_INDEX_SUCCESS, payload: response.data });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({ type: ORDER_SERVICE_GET_FROM_INDEX_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};
