import { ArticleEntity, StorageAddressEntity } from '../../Globals/Types/Types';
import {
  ARTICLE_DELETE_SUCCESS,
  ARTICLE_GETLIST_SUCCESS,
  ARTICLE_UPDATE_SUCCESS,
  AUTH_LOGOUT,
  STORAGE_ADDRESS_DELETE_SUCCESS,
  STORAGE_ADDRESS_GETLIST_SUCCESS,
  STORAGE_ADDRESS_UPDATE_SUCCESS,
} from '../ActionTypes';

export interface StorageReducerInterface {
  addresses: Array<StorageAddressEntity>;
  articles: Array<ArticleEntity>;
}

export type UserActions =
  | { type: typeof STORAGE_ADDRESS_GETLIST_SUCCESS; payload: Array<StorageAddressEntity> }
  | { type: typeof STORAGE_ADDRESS_UPDATE_SUCCESS; payload: StorageAddressEntity }
  | { type: typeof STORAGE_ADDRESS_DELETE_SUCCESS; payload: StorageAddressEntity }
  | { type: typeof ARTICLE_GETLIST_SUCCESS; payload: Array<ArticleEntity> }
  | { type: typeof ARTICLE_UPDATE_SUCCESS; payload: ArticleEntity }
  | { type: typeof ARTICLE_DELETE_SUCCESS; payload: ArticleEntity }
  | { type: typeof AUTH_LOGOUT };

const defaultValues: StorageReducerInterface = {
  addresses: [],
  articles: [],
};

export default function reduce(
  state: StorageReducerInterface = defaultValues,
  action: UserActions,
): StorageReducerInterface {
  switch (action.type) {
    case STORAGE_ADDRESS_GETLIST_SUCCESS: {
      return { ...state, addresses: action.payload };
    }
    case STORAGE_ADDRESS_UPDATE_SUCCESS: {
      const addresses = state.addresses.map((item) =>
        item.storageId === action.payload.storageId ? action.payload : item,
      );
      return { ...state, addresses };
    }
    case STORAGE_ADDRESS_DELETE_SUCCESS: {
      const addresses = state.addresses.filter((item) => item.storageId !== action.payload.storageId);
      return { ...state, addresses };
    }

    case ARTICLE_GETLIST_SUCCESS: {
      return { ...state, articles: action.payload };
    }
    case ARTICLE_UPDATE_SUCCESS: {
      const articles = state.articles.map((item) =>
        item.articleId === action.payload.articleId ? action.payload : item,
      );
      return { ...state, articles };
    }
    case ARTICLE_DELETE_SUCCESS: {
      const articles = state.articles.filter((item) => item.articleId !== action.payload.articleId);
      return { ...state, articles };
    }

    case AUTH_LOGOUT: {
      return { ...defaultValues };
    }

    default:
      return { ...state };
  }
}
