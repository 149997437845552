import { useDispatchClientGetAllPartners } from '../../Redux/Actions/ClientAction';
import { useDispatchOrderFilterLoad } from '../../Redux/Actions/Order/FilterAction';
import { useDispatchDashboardGetServices } from '../../Redux/Actions/DashboardAction';
import { useDispatchUserTaskGetList } from '../../Redux/Actions/User/TaskAction';
import { useDispatchClientSubscriptionGetActive } from '../../Redux/Actions/Client/SubscriptionAction';
import React from 'react';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { useDispatchInvoiceGetList } from '../../Redux/Actions/Client/InvoiceAction';

/**
 * useReloadDashboard()
 */
export const useReloadDashboard = () => {
  const { activeSubscription } = useAppSelector((state) => state.auth);
  const { client } = useAppSelector((state) => state.auth);

  const dispatchGetAllPartners = useDispatchClientGetAllPartners();
  const dispatchOrderFiltersLoad = useDispatchOrderFilterLoad();
  const dispatchLoadServices = useDispatchDashboardGetServices();
  const dispatchUserTaskGetList = useDispatchUserTaskGetList();
  const dispatchLoadActiveSubscription = useDispatchClientSubscriptionGetActive();
  const dispatchGetOpenInvoices = useDispatchInvoiceGetList();

  React.useEffect(() => {
    dispatchGetAllPartners().then(() => {});
    dispatchOrderFiltersLoad();
    dispatchUserTaskGetList().then(() => {});
    dispatchLoadServices().then(() => {});
  }, [dispatchGetAllPartners, dispatchLoadServices, dispatchOrderFiltersLoad, dispatchUserTaskGetList]);

  React.useEffect(() => {
    if (!activeSubscription) {
      dispatchLoadActiveSubscription().then(() => {});
    }
  }, [activeSubscription, dispatchLoadActiveSubscription]);

  React.useEffect(() => {
    if (client.settings.invoice.active) {
      dispatchGetOpenInvoices().then(() => {});
    }
  }, [client.settings.invoice.active, dispatchGetOpenInvoices]);
};
