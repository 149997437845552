import React from 'react';
import InvoiceCreate from '../../../Components/Invoice/Create';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';

/**
 * CreateInvoice()
 * @constructor
 */
export default function CreateInvoice() {
  const { order, dispositions } = useAppSelector((state) => state.order.selectedOrder);
  return <InvoiceCreate order={order} dispositions={dispositions} />;
}
