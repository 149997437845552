import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { OfferEntity } from '../../../../Globals/Types/OfferTypes';
import DropDownItem from '../../../CustomDropDown/DropDownItem';
import { LOCALSTORAGE_OFFER_CREATE_ORDER } from '../../../../Globals/Globals';
import { UrlPatterns } from '../../../../Globals/UrlFunctions';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';

interface Props {
  offer: OfferEntity;
}

/**
 * OfferCreateOrderButton()
 * @param props
 * @constructor
 */
export default function OfferCreateOrderButton(props: Props) {
  const { offer } = props;
  const { customer } = useAppSelector((state) => state.client.customers.selectedCustomer);
  const navigate = useNavigate();
  const [t] = useTranslation();

  const navigateCreateOrder = () => {
    localStorage.setItem(LOCALSTORAGE_OFFER_CREATE_ORDER, JSON.stringify({ offer, customer }));
    navigate(UrlPatterns.orderCreate);
  };

  const renderButton = () => {
    return (
      <>
        <Dropdown.Divider />
        <DropDownItem onClick={navigateCreateOrder} key={'createOrder'}>
          <i className="fa-kit fa-regular-file-lines-circle-plus" />
          {t('createOrder')}
        </DropDownItem>
      </>
    );
  };

  return <>{renderButton()}</>;
}
