import React from 'react';
import { useTranslation } from 'react-i18next';
import { AndroidView, IOSView } from 'react-device-detect';
import apple from '../../../Assets/Images/app_store.png';
import google from '../../../Assets/Images/play_store.png';
import { useTheme } from 'styled-components';
import Text from '../../../Components/Text';

interface Props {
  visible: boolean;
}

/**
 * RegisterMobileHint()
 * @constructor
 */
export default function RegisterMobileHint(props: Props) {
  const { visible } = props;
  const theme = useTheme();
  const [t] = useTranslation();

  if (visible) {
    return (
      <div style={{ textAlign: 'center', padding: 15 }}>
        <i className="fas fa-check-circle" style={{ fontSize: 50, marginBottom: 20, color: theme.color.green }} />
        <Text bold size={20} centered>
          {t('modules.register.successMobileHeadline')}
        </Text>
        <div style={{ marginBottom: 40 }}>{t('modules.register.successMobileDescription')}</div>

        <br />
        <h5>SotoSoft App</h5>
        <br />
        <IOSView>
          <a href="https://apps.apple.com/es/app/sotosoft/id1660048142">
            <img src={apple} style={{ width: 200, marginBottom: 0 }} alt="Apple App-Store Logo" />
          </a>
        </IOSView>
        <AndroidView>
          <a href="https://play.google.com/store/apps/details?id=app.sotosoft">
            <img src={google} style={{ width: 200 }} alt="Google Play Store Logo" />
          </a>
        </AndroidView>
      </div>
    );
  }
  return null;
}
