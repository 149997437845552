import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SubNavigationItem from './SubNavigationItem';
import { useNavigate, useParams } from 'react-router';
import Spacer from './Spacer';
import SubNavigationHeadline from './SubNavigationHeadline';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

/**
 * FacilitySubNavigation()
 * @constructor
 */
export default function FacilitySubNavigation() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { facilityId } = useParams();
  const { facilities } = useAppSelector((state) => state.client);
  const [name, setName] = React.useState<string>(null);

  React.useEffect(() => {
    const item = facilities.filter((item) => item.facilityId === facilityId)[0];
    setName(item.name);
  }, [facilities, facilityId]);

  return (
    <Container>
      <SubNavigationHeadline headline={name} />

      <SubNavigationItem icon="far fa-building" caption={t('companyData')} path="details" />
      <SubNavigationItem icon="far fa-cogs" caption={t('settings')} path="advanced" />
      <SubNavigationItem icon="far fa-envelope" caption={t('mailSettings')} path="mail" />
      <SubNavigationItem icon="far fa-file-alt" caption={t('documents')} path="documents" />
      <SubNavigationItem icon="far fa-handshake" caption={t('partnerships')} path="partners" />

      <Spacer />

      <SubNavigationItem icon="far fa-angle-double-left" caption={t('back')} onPress={() => navigate('/settings')} />
    </Container>
  );
}
