import { Subscription } from '../../../../../Globals/Types/Types';
import { InternalErrorCodes } from '../../../../../Globals/InternalErrorCodes';
import { isValidateEmail } from '../../../../../Globals/Functions';

export interface SubscriptionValidationErrors {
  name?: string;
  zip?: string;
  city?: string;
  street?: string;
  streetNo?: string;
  vat?: string;
  mail?: string;
}

export interface SubscriptionValidationResult {
  errors: SubscriptionValidationErrors;
  isValid: boolean;
}

export const validateSubscription = (subscription: Subscription): SubscriptionValidationResult => {
  let result: SubscriptionValidationResult = {
    errors: {},
    isValid: true,
  };

  if (!subscription.invoiceAddress.name || subscription.invoiceAddress.name.length <= 0) {
    result = { ...result, errors: { ...result.errors, name: InternalErrorCodes.FIELD_MISSING }, isValid: false };
  }
  if (!subscription.invoiceAddress.zip || subscription.invoiceAddress.zip.length <= 0) {
    result = { ...result, errors: { ...result.errors, zip: InternalErrorCodes.FIELD_MISSING }, isValid: false };
  }
  if (!subscription.invoiceAddress.city || subscription.invoiceAddress.city.length <= 0) {
    result = { ...result, errors: { ...result.errors, city: InternalErrorCodes.FIELD_MISSING }, isValid: false };
  }
  if (!subscription.invoiceAddress.street || subscription.invoiceAddress.street.length <= 0) {
    result = { ...result, errors: { ...result.errors, street: InternalErrorCodes.FIELD_MISSING }, isValid: false };
  }
  if (!subscription.invoiceAddress.streetNo || subscription.invoiceAddress.streetNo.length <= 0) {
    result = { ...result, errors: { ...result.errors, streetNo: InternalErrorCodes.FIELD_MISSING }, isValid: false };
  }
  if (!subscription.invoiceAddress.vat || subscription.invoiceAddress.vat.length <= 0) {
    result = { ...result, errors: { ...result.errors, vat: InternalErrorCodes.FIELD_MISSING }, isValid: false };
  }

  if (subscription.invoiceAddress.mail) {
    if (!isValidateEmail(subscription.invoiceAddress.mail)) {
      result = { ...result, errors: { ...result.errors, mail: InternalErrorCodes.MAIL_INVALID }, isValid: false };
    }
  }

  return result;
};
