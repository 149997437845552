import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Panel from '../../../Panel';
import Input, { InputType } from '../../../Inputs/Input';
import Select from '../../../Inputs/Select';
import ExternalDataContainer from './ExternalDataContainer';
import { PositionEntity, PositionExternalData } from '../../../../Globals/Types/OrderTypes';
import { ValidatePositionError } from '../ValidatePosition';
import { CountryCode, ProductType } from '../../../../Globals/Types/Enums';
import SelectProductType from '../../../PredefinedSelects/SelectProductType';
import SelectTax from '../../../PredefinedSelects/SelectTax';
import SelectNumber from '../../../PredefinedSelects/SelectNumber';
import { useGetAssemblyTimeOptions } from '../../../../Globals/Hooks/Hooks';

interface Props {
  position?: PositionEntity;
  onChange: (position: PositionEntity, changedAssemblyTime: boolean) => void;
  onHasChanges?: (value: boolean) => void;
  isLoading: boolean;
  errors?: ValidatePositionError;
}

export type State = {
  position: PositionEntity;
  changedAssemblyTime: boolean;
  isEdit: boolean;
  hasChanges: boolean;
  initialized: boolean;
};

type Action =
  | { type: 'setField'; payload: { key: string; value: string | number } }
  | { type: 'assemblyTime'; payload: number }
  | { type: 'setExtraData'; payload: PositionExternalData[] }
  | { type: 'init'; payload?: PositionEntity }
  | { type: 'clear' };

const initialState: State = {
  position: {
    positionId: null,
    caption: '',
    amount: 1,
    assemblyTime: 0,
    externalData: null,
    price: null,
    invoiced: false,
    type: ProductType.service,
    taxRate: 21,
  },
  changedAssemblyTime: false,
  isEdit: false,
  hasChanges: false,
  initialized: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setField': {
      const { key, value } = action.payload;
      return { ...state, position: { ...state.position, [key]: value }, hasChanges: true };
    }
    case 'assemblyTime': {
      return {
        ...state,
        position: { ...state.position, assemblyTime: action.payload },
        changedAssemblyTime: true,
        hasChanges: true,
      };
    }
    case 'setExtraData': {
      return { ...state, position: { ...state.position, externalData: action.payload }, hasChanges: true };
    }
    case 'init':
      return {
        ...state,
        position: action.payload || initialState.position,
        hasChanges: false,
        isEdit: true,
        initialized: true,
      };
    case 'clear':
      return { ...initialState };
  }
};

/**
 * Form
 * @constructor
 */
export default function Form(props: Props) {
  const { position, onChange, isLoading, onHasChanges, errors } = props;
  const [t] = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const assemblyTimeOptions = useGetAssemblyTimeOptions();

  React.useEffect(() => {
    if (!state.initialized) {
      dispatch({ type: 'init', payload: position });
    }
  }, [position, state.initialized]);

  React.useEffect(() => {
    if (state.initialized) {
      onChange(state.position, state.changedAssemblyTime);
    }
  }, [onChange, state.changedAssemblyTime, state.initialized, state.position]);

  React.useEffect(() => {
    if (onHasChanges) {
      onHasChanges(state.hasChanges);
    }
  }, [onHasChanges, state.hasChanges]);

  return (
    <>
      <Panel headline={t('basicData')}>
        <Row>
          <Col lg={12}>
            <Input
              onChange={(value) => dispatch({ type: 'setField', payload: { key: 'caption', value } })}
              value={state.position.caption}
              label={t('caption')}
              required
              autoFocus
              showSkeleton={isLoading}
              hasError={errors && !!errors.caption}
            />
          </Col>
          <Col lg={12}>
            <Input
              onChange={(value) => dispatch({ type: 'setField', payload: { key: 'description', value } })}
              value={state.position.description}
              label={t('additionalInformation')}
              type={InputType.textarea}
              rows={5}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <SelectNumber
              onChange={(value) => dispatch({ type: 'setField', payload: { key: 'amount', value } })}
              max={99}
              label={t('amount')}
              initialValue={state.position.amount || 1}
            />
          </Col>
          <Col lg={6}>
            <Input
              onChange={(value) => dispatch({ type: 'setField', payload: { key: 'price', value } })}
              value={state.position.price}
              label={t('priceNetto')}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Input
              onChange={(value) => dispatch({ type: 'setField', payload: { key: 'externalId', value } })}
              value={state.position.externalId}
              label={t('externalId')}
              contextHelpKey="externalId"
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <Select
              label={t('estimatedAssemblyTimeInMinutes')}
              onChange={(value) => dispatch({ type: 'assemblyTime', payload: value })}
              initialValue={state.position.assemblyTime}
              options={assemblyTimeOptions}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <SelectProductType
              onChange={(value) => dispatch({ type: 'setField', payload: { key: 'type', value } })}
              selected={state.position.type}
              showSkeleton={isLoading}
            />
          </Col>
          <Col lg={6}>
            <SelectTax
              onChange={(value) => dispatch({ type: 'setField', payload: { key: 'taxRate', value } })}
              selected={state.position.taxRate}
              showSkeleton={isLoading}
              country={CountryCode.ES}
            />
          </Col>
        </Row>
      </Panel>

      <ExternalDataContainer
        position={state.position}
        onChange={(value) => dispatch({ type: 'setExtraData', payload: value })}
      />
    </>
  );
}
