import React from 'react';
import styled from 'styled-components';
import { DispositionListContext } from '../index';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Container = styled.div<{ x: number; y: number }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.primaryColor};
  height: 30px;
  left: ${(props) => props.x}px;
  top: ${(props) => props.y - 70}px;
  z-index: 5000;
  color: #ffffff;
  border-radius: ${(props) => props.theme.border.radius};
  ${(props) => props.theme.boxShadowDark};
  padding: 4px 10px;
`;

interface Props {
  time: string;
  assemblyTime: number;
}

/**
 * TimeOverlay
 * @param props
 * @constructor
 */
export default function TimeOverlay(props: Props) {
  const { time, assemblyTime } = props;
  const [t] = useTranslation();
  const [globalCoords, setGlobalCoords] = React.useState({ x: 0, y: 0 });
  const context = React.useContext(DispositionListContext);

  const handleSetCoords = React.useCallback((event: MouseEvent) => {
    setGlobalCoords({
      x: event.clientX,
      y: event.clientY,
    });
  }, []);

  React.useEffect(() => {
    window.addEventListener('drag', handleSetCoords);
    window.addEventListener('dragend', () => context.setHoverTime(null));
    return () => {
      window.removeEventListener('drag', handleSetCoords);
      window.removeEventListener('dragend', () => context.setHoverTime(null));
    };
  }, [context, handleSetCoords]);

  const getText = () => {
    const startTime = moment(time, 'HH:mm:ss').format('HH:mm');
    const endTime = moment(time, 'HH:mm:ss').add(assemblyTime, 'minute').format('HH:mm');

    return `${startTime} - ${endTime} (${t('xMinute', { count: assemblyTime })})`;
  };

  if (time) {
    return (
      <Container x={globalCoords.x} y={globalCoords.y}>
        <div>{getText()}</div>
      </Container>
    );
  }
  return null;
}
