import React from 'react';
import PageHeader from '../../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import DemoHint from './Components/DemoHint';
import CreateSubscription from './Components/Create/CreateSubscription';
import Details from './Components/Details';

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const [t] = useTranslation();

  return (
    <>
      <PageHeader headline={t('subscription')} />

      <DemoHint />

      <CreateSubscription />
      <Details />
    </>
  );
}
