import React from 'react';
import Select from '../Inputs/Select';
import { useTranslation } from 'react-i18next';
import { useGetMinuteOptions } from '../../Globals/Hooks/Hooks';

interface Props {
  onChange: (item: number) => void;
  selected: number;
  translationKey: string;
  hours?: number;
}

/**
 * SelectNotifyBeforeArrival
 * @param props
 * @constructor
 */
export default function SelectNotifyBeforeArrival(props: Props) {
  const { onChange, selected, translationKey, hours } = props;
  const [t] = useTranslation();
  const options = useGetMinuteOptions(15, hours, translationKey);

  if (options) {
    return (
      <Select
        label={t('modules.order.notifyBeforeArrival')}
        onChange={onChange}
        initialValue={selected}
        options={options}
      />
    );
  }
  return null;
}

SelectNotifyBeforeArrival.defaultProps = {
  hours: 1,
};
