import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.border.color};
  margin-top: 15px;
  margin-bottom: 15px;
`;

/**
 * Spacer()
 * @constructor
 */
export default function Spacer() {
  return <Container />;
}
