import React from 'react';
import styled from 'styled-components';
import { Navigate, Outlet, useOutlet } from 'react-router-dom';

const DEFAULT_NAVIGATION_WIDTH = '280px';

const Container = styled.div``;

const Navigation = styled.div<{ width: number }>`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100px;
  width: ${(props) => (props.width ? `${props.width}px` : DEFAULT_NAVIGATION_WIDTH)};
  z-index: 100;
  overflow: hidden;
  ${(props) => props.theme.boxShadowDark}
`;

const RightContainer = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: ${(props) => (props.width ? `${props.width}px` : DEFAULT_NAVIGATION_WIDTH)};
`;

interface Props {
  defaultRoute?: string;
  subNavigation: React.ReactElement;
  width?: number;
}

/**
 * SubNavigation()
 * @param props
 * @constructor
 */
export default function SubNavigation(props: Props) {
  const { defaultRoute, subNavigation, width } = props;
  const outlet = useOutlet();

  const renderOutlet = () => {
    if (outlet) {
      return <Outlet />;
    } else {
      return <Navigate to={defaultRoute} />;
    }
  };

  return (
    <Container>
      <Navigation width={width}>{subNavigation}</Navigation>
      <RightContainer width={width}>{renderOutlet()}</RightContainer>
    </Container>
  );
}
