import React from 'react';
import Panel from '../../../../Panel';
import { ServiceEntity } from '../../../../../Globals/Types/OrderTypes';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useAppSelector, useLoadingModal } from '../../../../../Globals/Hooks/Hooks';
import {
  useDispatchOrderServiceSendAssignPartnerNotification,
  useDispatchOrderServiceSendDeletePartnerNotification,
  useDispatchOrderServiceUpdate,
} from '../../../../../Redux/Actions/Order/ServiceAction';
import { FacilityPartnerEntity } from '../../../../../Globals/Types/Types';
import AssignPartnerModal from '../../../AssignPartnerModal';
import PartnerContainer from './PartnerContainer';
import PartnerHint from './PartnerHint';

interface Props {
  service: ServiceEntity;
}

/**
 * AssignPartner
 * @param props
 * @constructor
 */
export default function AssignPartner(props: Props) {
  const { service } = props;
  const [t] = useTranslation();
  const { allPartners } = useAppSelector((state) => state.client);
  const { order } = useAppSelector((state) => state.order.selectedOrder);
  const { dispositions } = useAppSelector((state) => state.order.selectedOrder.selectedService);
  const { clientId } = useAppSelector((state) => state.auth);
  const [showAddPartner, setShowAddPartner] = React.useState<boolean>(false);
  const [hasPartner, setHasPartner] = React.useState<boolean>(false);
  const { setLoading, loadingModal } = useLoadingModal({
    headline: t('updatingService'),
  });
  const dispatchUpdateService = useDispatchOrderServiceUpdate();
  const dispatchNotifyPartnerAdd = useDispatchOrderServiceSendAssignPartnerNotification();
  const dispatchNotifyPartnerDelete = useDispatchOrderServiceSendDeletePartnerNotification();

  React.useEffect(() => {
    if (order.ownerClientId === clientId) {
      const filtered = allPartners.filter(
        (partner) =>
          partner.active && partner.invitedFacilityId === order.ownerFacilityId && partner.partnerSettings.assignOrders,
      );
      setHasPartner(filtered.length > 0);
    }
  }, [order, allPartners, clientId]);

  const getDescription = () =>
    allPartners && allPartners.length > 0
      ? t('modules.order.whoShouldExecuteDescriptionWithPartner')
      : t('modules.order.whoShouldExecuteDescriptionWithoutPartner');

  const handleUpdateAssignedPartner = (partner: FacilityPartnerEntity) => {
    setShowAddPartner(false);
    setLoading(true);

    const data: Partial<ServiceEntity> = {
      assignedPartnerClientId: partner.partnerClientId,
      assignedPartnerFacilityId: partner.partnerFacilityId,
    };
    dispatchUpdateService(order.ownerClientId, order.orderId, service.serviceId, data)
      .then(() => {
        return dispatchNotifyPartnerAdd(order, service, partner);
      })
      .finally(() => setLoading(false));
  };

  const handleDeletePartner = (partner: FacilityPartnerEntity) => {
    setShowAddPartner(false);

    const data: Partial<ServiceEntity> = {
      assignedPartnerClientId: null,
      assignedPartnerFacilityId: null,
    };
    dispatchUpdateService(order.ownerClientId, order.orderId, service.serviceId, data)
      .then(() => {
        return dispatchNotifyPartnerDelete(order, service, partner);
      })
      .finally(() => setLoading(false));
  };

  const renderAssignButton = () => {
    if (!service.assignedPartnerClientId) {
      return (
        <div>
          <Button variant="success" onClick={() => setShowAddPartner(true)}>
            <i className="fas fa-plus-circle" style={{ marginRight: 8 }} />
            {t('assignPartner')}
          </Button>
        </div>
      );
    }
    return null;
  };

  if (order.ownerClientId === clientId && hasPartner && (!dispositions || dispositions.length === 0)) {
    return (
      <Panel
        headline={t('modules.order.whoShouldExecute', { typeName: service.type.caption })}
        description={getDescription()}
      >
        <PartnerContainer
          partnerClientId={service.assignedPartnerClientId}
          partnerFacilityId={service.assignedPartnerFacilityId}
          onDelete={handleDeletePartner}
          order={order}
        />
        <PartnerHint service={service} />

        {renderAssignButton()}

        <AssignPartnerModal
          order={order}
          onSave={handleUpdateAssignedPartner}
          onClose={() => setShowAddPartner(false)}
          visible={showAddPartner}
        />

        {loadingModal}
      </Panel>
    );
  }
  return null;
}
