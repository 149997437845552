import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { OrderEntity } from '../../../../../Globals/Types/OrderTypes';
import Input from '../../../../Inputs/Input';
import { validateEditData } from './ValidateEditData';

interface Props {
  order: Partial<OrderEntity>;
  onSave: (item: Partial<OrderEntity>) => void;
  onClose: () => void;
  visible: boolean;
}

export type State = {
  data: Partial<OrderEntity>;
  errors: Partial<OrderEntity>;
  initialized: Boolean;
  hasChanges: Boolean;
};

type Action =
  | { type: 'externalId'; payload: string }
  | { type: 'balance'; payload: string }
  | { type: 'init'; payload: Partial<OrderEntity> }
  | { type: 'setErrors'; payload: Partial<OrderEntity> }
  | { type: 'clear' };

const initialState: State = {
  data: {
    externalId: '',
    balance: '',
  } as Partial<OrderEntity>,
  errors: {} as Partial<OrderEntity>,
  initialized: false,
  hasChanges: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'externalId':
      return { ...state, data: { ...state.data, externalId: action.payload }, hasChanges: true };
    case 'balance':
      return { ...state, data: { ...state.data, balance: action.payload }, hasChanges: true };
    case 'setErrors':
      return { ...state, errors: action.payload };
    case 'init':
      return {
        ...initialState,
        data: {
          externalId: action.payload.externalId,
          balance: action.payload.balance,
        },
        initialized: true,
      };
    case 'clear':
      return { ...initialState };
  }
};

/**
 * EditModal
 * @param props
 * @constructor
 */
export default function EditModal(props: Props) {
  const { order, visible, onClose, onSave } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [t] = useTranslation();

  React.useEffect(() => {
    if (!state.initialized && order && visible) {
      dispatch({ type: 'init', payload: order });
    }
  }, [order, state.initialized, visible]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear' });
    }
  }, [visible]);

  const handleSave = () => {
    const { result, errors } = validateEditData(state.data);
    if (result) {
      onSave(state.data);
    } else {
      dispatch({ type: 'setErrors', payload: errors });
    }
  };

  if (visible && state.initialized) {
    return (
      <Modal show={visible} onHide={() => onClose()} size="lg">
        <Modal.Header>
          <div>
            <Modal.Title style={{ marginLeft: 25 }}>{t('information')}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body style={{ padding: 30 }}>
          <Row>
            <Col lg={12}>
              <Input
                onChange={(value) => dispatch({ type: 'externalId', payload: value })}
                label={t('commissionNumber')}
                value={state.data.externalId}
                hasError={!!state.errors.externalId}
              />
            </Col>
            <Col lg={12}>
              <Input
                onChange={(value) => dispatch({ type: 'balance', payload: value.replace(',', '.') })}
                label={t('modules.order.balance')}
                value={state.data.balance}
                hasError={!!state.errors.balance}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSave} disabled={!state.hasChanges}>
            {t('save')}
          </Button>
          <Button variant="outline-secondary" onClick={() => onClose()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
