import React from 'react';
import styled from 'styled-components';
import Panel from '../Panel';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';

const Input = styled.input`
  border-radius: ${(props) => props.theme.border.radius};
  padding: 20px 6px;
  width: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.border.color};
  outline: none;
  text-align: center;
  font-size: 20px;
  margin-right: 6px;
`;

interface Props {
  onReady: (pin: string) => void;
  isLoading: boolean;
}

const autoFocusKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

/**
 * PinInput()
 * @param props
 * @constructor
 */
export default function PinInput(props: Props) {
  const { onReady, isLoading } = props;
  const [t] = useTranslation();
  const [one, setOne] = React.useState<string>('');
  const [two, setTwo] = React.useState<string>('');
  const [three, setThree] = React.useState<string>('');
  const [four, setFour] = React.useState<string>('');
  const [five, setFive] = React.useState<string>('');
  const [six, setSix] = React.useState<string>('');

  React.useEffect(() => {
    const value = one + two + three + four + five + six;
    if (value && value.length === 6) {
      onReady(value);
      setTimeout(() => clearAll(), 1000);
    }
  }, [five, four, onReady, one, six, three, two]);

  const handleNext = (event) => {
    if (autoFocusKeys.indexOf(event.nativeEvent.key) >= 0) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form[index + 1].select();
      event.preventDefault();
    }
    if (event.nativeEvent.keyCode === 8) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (index > 0) {
        form[index - 1].select();
        event.preventDefault();
      }
    }
  };

  const clearAll = () => {
    setOne('');
    setTwo('');
    setThree('');
    setFour('');
    setFive('');
    setSix('');
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Panel
          headline={t('linking.pinHeadline')}
          description={t('linking.pinDescription')}
          maxWidth={500}
          containerStyle={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <form>
            <Input
              maxLength={1}
              autoFocus={true}
              onKeyUp={handleNext}
              value={one}
              onChange={(e: any) =>
                setOne(autoFocusKeys.indexOf(e.nativeEvent.target.value) >= 0 ? e.nativeEvent.target.value : '')
              }
              disabled={isLoading}
              inputMode="numeric"
            />
            <Input
              maxLength={1}
              onKeyUp={handleNext}
              value={two}
              onChange={(e: any) =>
                setTwo(autoFocusKeys.indexOf(e.nativeEvent.target.value) >= 0 ? e.nativeEvent.target.value : '')
              }
              disabled={isLoading}
              inputMode="numeric"
            />
            <Input
              maxLength={1}
              onKeyUp={handleNext}
              value={three}
              onChange={(e: any) =>
                setThree(autoFocusKeys.indexOf(e.nativeEvent.target.value) >= 0 ? e.nativeEvent.target.value : '')
              }
              disabled={isLoading}
              inputMode="numeric"
            />
            <Input
              maxLength={1}
              onKeyUp={handleNext}
              value={four}
              onChange={(e: any) =>
                setFour(autoFocusKeys.indexOf(e.nativeEvent.target.value) >= 0 ? e.nativeEvent.target.value : '')
              }
              disabled={isLoading}
              inputMode="numeric"
            />
            <Input
              maxLength={1}
              onKeyUp={handleNext}
              value={five}
              onChange={(e: any) =>
                setFive(autoFocusKeys.indexOf(e.nativeEvent.target.value) >= 0 ? e.nativeEvent.target.value : '')
              }
              disabled={isLoading}
              inputMode="numeric"
            />
            <Input
              maxLength={1}
              value={six}
              onChange={(e: any) =>
                setSix(autoFocusKeys.indexOf(e.nativeEvent.target.value) >= 0 ? e.nativeEvent.target.value : '')
              }
              disabled={isLoading}
              inputMode="numeric"
              onKeyUp={handleNext}
            />
          </form>
        </Panel>
      </Row>
    </Container>
  );
}
