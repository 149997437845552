import React from 'react';
import { useAppSelector, usePageTitle } from '../../../Globals/Hooks/Hooks';
import Headline from './Components/Headline';
import { useDispatchOrderMessageGetList } from '../../../Redux/Actions/Order/MessageAction';
import Message from '../../../Components/Order/Details/Messages/Message';
import CreateMessageModal from '../../../Components/Order/Details/CreateMessageModal';
import CustomSwitch from '../../../Components/CustomSwitch';
import Panel from '../../../Components/Panel';
import { useDispatchOrderUpdate } from '../../../Redux/Actions/OrderAction';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

/**
 * Messages
 * @constructor
 */
export default function Messages() {
  const [t] = useTranslation();
  const { userId } = useAppSelector((state) => state.auth);
  const { messages, order } = useAppSelector((state) => state.order.selectedOrder);
  const [showCreate, setShowCreate] = React.useState<boolean>(false);
  const [notify, setNotify] = React.useState<boolean>(false);
  const dispatchGetMessages = useDispatchOrderMessageGetList();
  const dispatchUpdateOrder = useDispatchOrderUpdate();
  usePageTitle(order ? order.externalId : '');

  React.useEffect(() => {
    if (order && order.notifyOnMessage && order.notifyOnMessage.length > 0) {
      setNotify(order.notifyOnMessage.indexOf(userId) > -1);
    } else {
      setNotify(false);
    }
  }, [order, userId]);

  React.useEffect(() => {
    if (order) {
      dispatchGetMessages(order).then(() => {});
    }
  }, [dispatchGetMessages, order]);

  const toggleNotify = () => {
    let newNotify;
    if (notify) {
      newNotify = order.notifyOnMessage.filter((item) => item !== userId);
    } else {
      newNotify = [...new Set([...order.notifyOnMessage, userId])];
    }

    dispatchUpdateOrder(order.ownerClientId, order.orderId, { notifyOnMessage: newNotify }).then(() => {});
  };

  const renderContent = () => {
    if (messages) {
      return messages.map((message, index) => (
        <Message
          message={message}
          order={order}
          position={index % 2 === 0 ? 'left' : 'right'}
          key={message.messageId}
        />
      ));
    }
    return null;
  };

  if (order) {
    return (
      <>
        <Headline order={order} headlineKey="modules.order.messages" />

        <Panel>
          <div className="d-flex flex-row">
            <div className="d-flex flex-grow-1 align-items-center">
              <CustomSwitch
                containerStyle={{ marginBottom: 0 }}
                onChange={toggleNotify}
                checked={notify}
                caption={t('modules.order.notifyUserOnMessages')}
              />
            </div>
            <div>
              <Button onClick={() => setShowCreate(true)} variant="success">
                <i className="fas fa-plus-circle" style={{ marginRight: 6 }} />
                {t('newMessage')}
              </Button>
            </div>
          </div>
        </Panel>

        {renderContent()}

        <CreateMessageModal order={order} visible={showCreate} onClose={() => setShowCreate(false)} />
      </>
    );
  }
  return null;
}
