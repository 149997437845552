import React from 'react';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';
import { DragDropType } from '../../../../../Globals/Types/DispositionTypes';
import { UserEntity } from '../../../../../Globals/Types/Types';
import { userDisplayName } from '../../../../../Globals/Functions';
import { useGetTourAssignedFacilitiesString } from '../../../../../Globals/Hooks/DispositionHooks';
import { useAppSelector } from '../../../../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';

const Container = styled.div<{ isDragging: boolean; hasAbsence: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: ${(props) => (props.hasAbsence ? 'default' : 'pointer')};
  margin: 8px 8px 0 8px;
  padding: ${(props) => (props.isDragging ? '8px 14px' : '10px 16px')};
  background-color: ${(props) => props.theme.table.highlight};
  border-radius: 6px;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  ${(props) => (props.isDragging ? `border: 2px dashed ${props.theme.color.orange}` : null)};
  color: ${(props) => (!props.hasAbsence ? 'inherit' : props.theme.color.textGray)};

  .CaptionContainer {
    display: flex;
    flex-direction: column;

    small {
      font-size: 12px;
      color: ${(props) => props.theme.color.textGray};
      line-height: 0.8;
    }
  }

  .TypeIcon {
    cursor: move;
    width: 18px;
  }

  &:hover {
    background-color: ${(props) => (!props.hasAbsence ? props.theme.color.primaryColor : null)};
    color: ${(props) => (!props.hasAbsence ? '#ffffff !important' : props.theme.color.textGray)};
    ${(props) => (!props.hasAbsence ? props.theme.boxShadowDarkHover : null)}

    .TypeIcon {
      color: ${(props) => (!props.hasAbsence ? props.theme.color.orange : null)};
    }
  }
`;

interface Props {
  user: UserEntity;
  currentData: string;
}

/**
 * UserItem
 * @param props
 * @constructor
 */
export default function UserItem(props: Props) {
  const { user, currentData } = props;
  const [t] = useTranslation();
  const absence = useAppSelector((state) =>
    state.client.absences.filter((item) => item.userId === user.userId && item.dates.indexOf(currentData) >= 0),
  );
  const assignedFacilities = useGetTourAssignedFacilitiesString(user.facilities, true);

  React.useEffect(() => {}, []);

  const [{ isDragging }, drag] = useDrag<UserEntity, any, any>(() => ({
    type: DragDropType.USER,
    item: user,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const renderFacilities = () => {
    if (assignedFacilities && (!absence || absence.length === 0)) {
      return <small>{assignedFacilities}</small>;
    }
    return null;
  };

  const renderAbsence = () => {
    if (absence.length > 0) {
      return <small>{`${t('absent')}: ${t(absence[0].type)}`}</small>;
    }
    return null;
  };

  return (
    <Container ref={absence.length > 0 ? null : drag} isDragging={isDragging} hasAbsence={absence.length > 0}>
      <div>
        <i className="fas fa-user TypeIcon" style={{ marginRight: 6 }} />
      </div>
      <div className="CaptionContainer">
        {userDisplayName(user, true)}
        {renderFacilities()}
        {renderAbsence()}
      </div>
    </Container>
  );
}
