import React from 'react';
import moment from 'moment';
import { formatCurrency } from '../../../Globals/Functions';
import { OfferEntity } from '../../../Globals/Types/OfferTypes';

interface Props {
  offer: OfferEntity;
  onPress: (offer: OfferEntity) => void;
  showCustomerName?: boolean;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { offer, showCustomerName, onPress } = props;
  const [overall] = React.useState<string>((parseFloat(offer.priceOverall) + parseFloat(offer.tax)).toFixed(2));

  const renderCustomerName = () => {
    if (showCustomerName) {
      return <td onClick={() => onPress(offer)}>noNAME</td>;
    }
    return null;
  };

  return (
    <tr style={{ cursor: 'pointer' }}>
      <td onClick={() => onPress(offer)}>
        <i className={'far fa-bomb'} />
      </td>
      <td onClick={() => onPress(offer)}>{offer.offerNumber}</td>
      {renderCustomerName()}
      <td onClick={() => onPress(offer)}>{moment(offer.createdDate).format('LL')}</td>
      <td onClick={() => onPress(offer)}>{moment(offer.validUntil).format('LL')}</td>
      <td onClick={() => onPress(offer)}>{formatCurrency(overall)}</td>
    </tr>
  );
}
