import React from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '../../../Components/Panel';
import CustomSwitch from '../../../Components/CustomSwitch';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { useDispatchClientUpdate } from '../../../Redux/Actions/ClientAction';
import { ClientOrderSettings } from '../../../Globals/Types/Types';

interface Props {}

/**
 * TimeTracking()
 * @param props
 * @constructor
 */
export default function TimeTracking(props: Props) {
  const { client } = useAppSelector((state) => state.auth);
  const { setLoading, isLoading } = useLoadingModal();
  const { enableTimeTracking } = client.settings.order;
  const [t] = useTranslation();

  const dispatchUpdateClient = useDispatchClientUpdate();

  const handleUpdateOrderSettings = (data: ClientOrderSettings) => {
    return dispatchUpdateClient({ settings: { ...client.settings, order: data } });
  };

  const handleUpdate = (value: boolean) => {
    setLoading(true);
    handleUpdateOrderSettings({ ...client.settings.order, enableTimeTracking: value }).finally(() => setLoading(false));
  };

  return (
    <Panel headline={t('timeTracking')} description={t('modules.settings.orders.timeTrackingDescription')}>
      <CustomSwitch
        onChange={handleUpdate}
        checked={enableTimeTracking}
        caption={t('modules.settings.orders.enableTimeTracking')}
        loading={isLoading}
      />
    </Panel>
  );
}
