import React from 'react';
import { Tour, TourItem, TourService } from '../../../Globals/Types/Tour';
import { ElasticSearchServiceEntity } from '../../../Globals/Types/OrderTypes';
import { ResourceEntity, UserEntity } from '../../../Globals/Types/Types';
import { DispositionReducerByDate } from '../../../Redux/Reducer/DispositionReducer';
import styled from 'styled-components';
import Header from './Components/Header';
import TourRow from './TourRow';
import CalendarHeader from '../Components/CalendarHeader';
import { DragDropType } from '../../../Globals/Types/DispositionTypes';
import RightListView from './Components/RightListView';
import { useTranslation } from 'react-i18next';
import TimeOverlay from './Components/TimeOverlay';
import ServiceDetailsPanel from '../ServiceDetailsPanel';

const StorageSettingsKey = '@DispositionSettings';

interface DispositionSettingsInterface {
  collapsedUsers: boolean;
  collapsedResources: boolean;
}

const ScrollContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: scroll;
  background-color: #ffffff;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: ${(props) => props.theme.border.radius};
  ${(props) => props.theme.boxShadowDark};
  overflow: scroll;
`;

const EmptyHint = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 50px;
  font-size: 16px;
  padding: 0 40px;
  text-align: center;
  color: ${(props) => props.theme.color.textGray};
`;

interface Props {
  onMoveService: (startTime: string, tour: Tour, service: TourService, tourOld: Tour) => void;
  onAddService: (startTime: string, tour: Tour, service: ElasticSearchServiceEntity) => void;
  onDropUser: (tour: Tour, user: UserEntity) => void;
  onDropResource: (tour: Tour, resource: ResourceEntity) => void;
  onDeleteTour: (tour: Tour) => void;
  onEditTour: (tour: Tour) => void;
  onToggleCloseTour: (tour: Tour) => void;
  services: ElasticSearchServiceEntity[];
  resources: ResourceEntity[];
  users: UserEntity[];
  showService: boolean;
  showUsers: boolean;
  showResources: boolean;
  date: string;
  onChangeDate: (date: string) => void;
  onSearchMonteure: (value: string) => void;
  onSearchResources: (value: string) => void;
  onSearchServices: (value: string) => void;
  onDeleteResource: (tour: Tour, resource: ResourceEntity) => void;
  onDeleteUser: (tour: Tour, user: UserEntity) => void;
  onDeleteTourItem: (tourItem: TourItem<TourService>) => void;
  onPressService: (tour: Tour, service: TourService) => void;
  tourListByDate: DispositionReducerByDate[];
  isLoadingServices: boolean;
  onReloadServiceClick?: () => void;
  selectedTour?: Tour;
  selectedTourItem?: TourItem<TourService>;
  onCloseServicePanel: () => void;
}

interface Context {
  setCollapsedResources: React.Dispatch<React.SetStateAction<boolean>>;
  collapsedResources: boolean;
  collapsedUsers: boolean;
  setCollapsedUsers: React.Dispatch<React.SetStateAction<boolean>>;
  setHoverTime: React.Dispatch<React.SetStateAction<string>>;
  setDragAssemblyTime: React.Dispatch<React.SetStateAction<number>>;
  userWidth: number;
  resourceWidth: number;
  hourBoxWidth: number;
  startTime: string;
  hours: number;
  tourNameWidth: number;
  hourInterval: number;
}

export const DispositionListContext = React.createContext<Context>(null);

/**
 * DayView()
 * @param props
 * @constructor
 */
export default function DayView(props: Props) {
  const {
    date,
    tourListByDate,
    onMoveService,
    onPressService,
    onAddService,
    onChangeDate,
    showService,
    showUsers,
    showResources,
    services,
    selectedTour,
    selectedTourItem,
    resources,
    users,
    onSearchServices,
    onSearchMonteure,
    onSearchResources,
    onDropResource,
    onDropUser,
    onDeleteResource,
    onDeleteUser,
    onDeleteTourItem,
    onToggleCloseTour,
    onDeleteTour,
    onEditTour,
    onCloseServicePanel,
    isLoadingServices,
    onReloadServiceClick,
  } = props;
  const [t] = useTranslation();
  const [collapsedUsers, setCollapsedUsers] = React.useState<boolean>(false);
  const [collapsedResources, setCollapsedResources] = React.useState<boolean>(false);
  const [hoverTime, setHoverTime] = React.useState<string>(null);
  const [dragAssemblyTime, setDragAssemblyTime] = React.useState<number>(null);
  const [disposition, setDisposition] = React.useState<DispositionReducerByDate>(null);

  React.useEffect(() => {
    const settings = localStorage.getItem(StorageSettingsKey);
    if (settings) {
      const parsed = JSON.parse(settings) as DispositionSettingsInterface;
      setCollapsedResources(parsed.collapsedResources);
      setCollapsedUsers(parsed.collapsedUsers);
    }
  }, []);

  React.useEffect(() => {
    const value = tourListByDate.find((item) => item.date === date);
    setDisposition(value);
  }, [date, tourListByDate]);

  React.useEffect(() => {
    const localStorageEntry: DispositionSettingsInterface = {
      collapsedUsers,
      collapsedResources,
    };

    localStorage.setItem(StorageSettingsKey, JSON.stringify(localStorageEntry));
  }, [collapsedResources, collapsedUsers]);

  const renderTourRows = () => {
    if (disposition) {
      return disposition.data.map((tour) => (
        <TourRow
          tour={tour}
          key={tour.tourId}
          onAddUser={onDropUser}
          onAddResource={onDropResource}
          onDeleteResource={onDeleteResource}
          onDeleteUser={onDeleteUser}
          onToggleCloseTour={onToggleCloseTour}
          onDeleteTour={onDeleteTour}
          onEditTour={onEditTour}
          onMoveService={(newStartTime, droppedData, droppedOnTour) =>
            onMoveService(newStartTime, droppedOnTour, droppedData.service, droppedData.currentTour)
          }
          onAddService={(newStartTime, droppedData, droppedOnTour) =>
            onAddService(newStartTime, droppedOnTour, droppedData)
          }
          onPressTourItem={onPressService}
          selectedTourItem={selectedTourItem}
        />
      ));
    }
    return null;
  };

  const renderEmptyHint = () => {
    if (!disposition || disposition.data.length <= 0) {
      return (
        <EmptyHint>
          <strong>{t('modules.disposition.noToursPlannedToday')}</strong>
          <div>{t('modules.disposition.addTourHint')}</div>
        </EmptyHint>
      );
    }
    return null;
  };

  const renderServices = () =>
    showService ? (
      <RightListView
        items={services}
        type={DragDropType.SERVICE}
        onSearch={onSearchServices}
        width={400}
        tourListByDate={tourListByDate}
        isLoading={isLoadingServices}
        onReloadClick={onReloadServiceClick}
        currentDate={date}
      />
    ) : null;
  const renderResources = () =>
    showResources ? (
      <RightListView
        items={resources}
        type={DragDropType.RESOURCE}
        onSearch={onSearchResources}
        width={300}
        tourListByDate={tourListByDate}
        currentDate={date}
      />
    ) : null;
  const renderUsers = () =>
    showUsers ? (
      <RightListView
        items={users}
        type={DragDropType.USER}
        onSearch={onSearchMonteure}
        width={300}
        tourListByDate={tourListByDate}
        currentDate={date}
      />
    ) : null;

  return (
    <DispositionListContext.Provider
      value={{
        setCollapsedResources,
        collapsedResources,
        collapsedUsers,
        setCollapsedUsers,
        setDragAssemblyTime,
        setHoverTime,
        userWidth: 200,
        resourceWidth: 200,
        hourBoxWidth: 120,
        startTime: '06:00',
        hours: 18,
        tourNameWidth: 350,
        hourInterval: 10,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <Container>
          <CalendarHeader date={date} onChange={onChangeDate} isWeekView={false} />
          <ScrollContainer>
            <Header />
            {renderTourRows()}
            {renderEmptyHint()}
          </ScrollContainer>
        </Container>

        {renderServices()}
        {renderUsers()}
        {renderResources()}

        <ServiceDetailsPanel
          visible={!!selectedTourItem}
          tour={selectedTour}
          tourItem={selectedTourItem}
          onClose={onCloseServicePanel}
          onDeleteTourItem={onDeleteTourItem}
        />

        <TimeOverlay time={hoverTime} assemblyTime={dragAssemblyTime} />
      </div>
    </DispositionListContext.Provider>
  );
}
