import { Tour, TourItemType } from '../Types/Tour';
import { ElasticSearchServiceDispositionInterface } from '../Types/OrderTypes';
import moment from 'moment';

/**
 * Get all serviceIds assigned to a tour
 * @param tour
 */
export const getServiceIdsIncludedInTour = (tour: Tour) => {
  const serviceIds: string[] = [];

  tour.items.forEach((tourItem) => {
    if (tourItem.type === TourItemType.service) {
      serviceIds.push(tourItem.data.serviceId);
    }
  });

  return serviceIds;
};

/**
 * Get all tour items from given tours for the given serviceId
 * @param tourParam
 * @param serviceId
 */
export const getAllTourItemsForService = (
  tourParam: Tour[],
  serviceId: string,
): ElasticSearchServiceDispositionInterface[] => {
  const result: ElasticSearchServiceDispositionInterface[] = [];

  tourParam.forEach((tour) => {
    tour.items.forEach((tourItem) => {
      if (tourItem.type === TourItemType.service && tourItem.data.serviceId === serviceId) {
        result.push({
          serviceId,
          date: tour.date,
          tourId: tour.tourId,
          endTime: tourItem.endTime,
          tourName: tour.name,
          startTime: tourItem.startTime,
          users: tour.users,
          resources: tour.resources,
          assemblyTime: moment(tourItem.endTime, 'HH:mm:ss').diff(moment(tourItem.startTime, 'HH:mm:ss'), 'minute'),
        });
      }
    });
  });

  return result;
};
