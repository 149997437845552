import React from 'react';
import { TimeTrackingEntity } from '../../../Globals/Types/Types';
import { FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  serverTimestamp,
  where,
} from 'firebase/firestore';
import {
  USER_TIMETRACKING_CREATE_ERROR,
  USER_TIMETRACKING_CREATE_START,
  USER_TIMETRACKING_CREATE_SUCCESS,
  USER_TIMETRACKING_GET_LAST_ERROR,
  USER_TIMETRACKING_GET_LAST_START,
  USER_TIMETRACKING_GET_LAST_SUCCESS,
  USER_TIMETRACKING_GETBYMONTH_START,
  USER_TIMETRACKING_GETBYMONTH_SUCCESS,
} from '../../ActionTypes';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import { DeviceType } from '../../../Globals/Types/Enums';
import moment from 'moment';

/**
 * useDispatchTimeTrackingGetLast()
 */
type GetLastByUserReturnType = () => Promise<TimeTrackingEntity>;
export const useDispatchTimeTrackingGetLast = (): GetLastByUserReturnType => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<() => Promise<TimeTrackingEntity>>(() => {
    dispatch({ type: USER_TIMETRACKING_GET_LAST_START, payload: userId });

    const collectionRef = collection(
      getFirestore(),
      FirebasePathMappings.user,
      userId,
      FirebasePathMappings.timeTracking,
    ) as CollectionReference<TimeTrackingEntity>;

    const queryRef = query(collectionRef, orderBy('timestamp', 'desc'), limit(1));

    return getDocs(queryRef)
      .then((response) => {
        if (!response.empty && response.size === 1) {
          const doc = response.docs[0];
          const entity: TimeTrackingEntity = { ...doc.data(), id: doc.id };
          dispatch({ type: USER_TIMETRACKING_GET_LAST_SUCCESS, payload: entity });
          return Promise.resolve(entity);
        }
        return Promise.reject();
      })
      .catch((error) => {
        dispatch({ type: USER_TIMETRACKING_GET_LAST_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [dispatch, userId]);
};

/**
 * useDispatchTimeTrackingCreate()
 */
type CreateReturnType = (timeTracking: TimeTrackingEntity) => Promise<TimeTrackingEntity>;
type CallbackCreateType = (arg0: TimeTrackingEntity) => Promise<TimeTrackingEntity>;
export const useDispatchTimeTrackingCreate = (): CreateReturnType => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<CallbackCreateType>(
    (timeTracking) => {
      const preparedTimeTracking: TimeTrackingEntity = {
        ...timeTracking,
        timestamp: serverTimestamp(),
        device: DeviceType.web,
      };

      dispatch({ type: USER_TIMETRACKING_CREATE_START, payload: preparedTimeTracking });

      const collectionRef = collection(
        getFirestore(),
        FirebasePathMappings.user,
        userId,
        FirebasePathMappings.timeTracking,
      );

      return addDoc(collectionRef, preparedTimeTracking)
        .then((response) => {
          return getDoc(doc(getFirestore(), response.path)).then((document) => {
            const entity = { ...document.data(), id: document.id } as TimeTrackingEntity;
            dispatch({ type: USER_TIMETRACKING_CREATE_SUCCESS, payload: entity });
            return Promise.resolve(entity);
          });
        })
        .catch((error) => {
          dispatch({ type: USER_TIMETRACKING_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, userId],
  );
};

/**
 * useDispatchUserTimeTrackingGetByMonth()
 */
type PromiseReturn = Promise<{ month: number; year: number; data: Array<TimeTrackingEntity> }>;
type GetByMonthReturnType = (month: number, year: number) => PromiseReturn;
export const useDispatchUserTimeTrackingGetByMonth = (): GetByMonthReturnType => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state: any) => state.auth);

  return React.useCallback<GetByMonthReturnType>(
    (month: number, year: number) => {
      dispatch({ type: USER_TIMETRACKING_GETBYMONTH_START, payload: { month, year } });
      const startDate = moment(`01-${month}-${year}`, 'DD-M-YYYY').startOf('month');
      const endDate = moment(`01-${month}-${year}`, 'DD-M-YYYY').endOf('month');

      const collectionRef = collection(
        getFirestore(),
        FirebasePathMappings.user,
        userId,
        FirebasePathMappings.timeTracking,
      ) as CollectionReference<TimeTrackingEntity>;

      const queryRef = query(
        collectionRef,
        where('timestamp', '>=', startDate.toDate()),
        where('timestamp', '<=', endDate.toDate()),
        orderBy('timestamp', 'asc'),
      );

      return getDocs(queryRef)
        .then((documents) => {
          const result: Array<TimeTrackingEntity> = [];
          if (!documents.empty) {
            documents.forEach((item) => {
              result.push({ ...item.data(), id: item.id });
            });
          }
          const payload = { year, month, data: result };
          dispatch({ type: USER_TIMETRACKING_GETBYMONTH_SUCCESS, payload });
          return Promise.resolve(payload);
        })
        .catch((error) => {
          dispatch({ type: USER_TIMETRACKING_GETBYMONTH_SUCCESS, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, userId],
  );
};
