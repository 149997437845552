import { useDispatch } from 'react-redux';
import React from 'react';
import {
  USER_INBOX_DELETE_ERROR,
  USER_INBOX_DELETE_START,
  USER_INBOX_DELETE_SUCCESS,
  USER_INBOX_GETLIST_ERROR,
  USER_INBOX_GETLIST_START,
  USER_INBOX_GETLIST_SUCCESS,
  USER_INBOX_MARK_READ_ERROR,
  USER_INBOX_MARK_READ_START,
  USER_INBOX_MARK_READ_SUCCESS,
  USER_INBOX_SEND_MESSAGE_ERROR,
  USER_INBOX_SEND_MESSAGE_START,
  USER_INBOX_SEND_MESSAGE_SUCCESS,
  USER_INBOX_UNREAD_COUNT_ERROR,
  USER_INBOX_UNREAD_COUNT_START,
  USER_INBOX_UNREAD_COUNT_SUCCESS,
  USER_INBOX_UPDATE_ERROR,
  USER_INBOX_UPDATE_START,
  USER_INBOX_UPDATE_SUCCESS,
} from '../../ActionTypes';
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { buildDocumentRef, FirebaseFunctionNames, FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import { collection, doc, getDocs, getFirestore, orderBy, query, where, deleteDoc, setDoc } from 'firebase/firestore';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { DocumentEntity, MessageEntity } from '../../../Globals/Types/Types';
import { getDownloadURL, getStorage, uploadBytes } from 'firebase/storage';
import { generateGuid } from '../../../Globals/Functions';

/**
 * useDispatchMessageSend()
 */
export const useDispatchInboxSendMessage = () => {
  const dispatch = useDispatch();
  const uploadAttachment = useDispatchUploadAttachment();

  return React.useCallback(
    (message: MessageEntity, attachments: Array<File> = null) => {
      dispatch({ type: USER_INBOX_SEND_MESSAGE_START, payload: message });

      let promiseAll = [];
      if (attachments && attachments.length > 0) {
        attachments.forEach((file) => {
          promiseAll.push(uploadAttachment(file));
        });
      }

      return Promise.all(promiseAll).then((values) => {
        const preparedMessage = {
          ...message,
          attachments: [...values],
        };

        const sendMessage = httpsCallable(getFunctions(), FirebaseFunctionNames.sendMessage);
        return sendMessage(preparedMessage)
          .then((response: HttpsCallableResult<{ result: boolean; data: Object }>) => {
            const { result, data } = response.data;
            if (result) {
              dispatch({ type: USER_INBOX_SEND_MESSAGE_SUCCESS, payload: response.data });
              return Promise.resolve();
            }
            return Promise.reject(data);
          })
          .catch((error) => {
            dispatch({ type: USER_INBOX_SEND_MESSAGE_ERROR, payload: error });
            return Promise.reject(error);
          });
      });
    },
    [dispatch, uploadAttachment],
  );
};

/**
 * useDispatchInboxGetList()
 */
export const useDispatchInboxGetList = (): (() => Promise<Array<MessageEntity>>) => {
  const dispatch = useDispatch();
  const { userId } = useAppSelector((state) => state.auth);

  return React.useCallback(() => {
    dispatch({ type: USER_INBOX_GETLIST_START });

    const userCollection = collection(getFirestore(), FirebasePathMappings.user, userId, FirebasePathMappings.inbox);
    const queryRef = query(userCollection, orderBy('createdDate', 'desc'));

    return getDocs(queryRef)
      .then((docRefs) => {
        const result: Array<MessageEntity> = [];

        docRefs.forEach((message) => {
          result.push({ ...message.data(), messageId: message.id } as MessageEntity);
        });
        dispatch({ type: USER_INBOX_GETLIST_SUCCESS, payload: result });
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch({ type: USER_INBOX_GETLIST_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [dispatch, userId]);
};

/**
 * useDispatchInboxMarkRead()
 */
export const useDispatchInboxMarkRead = (): ((messageId: string) => Promise<string>) => {
  const dispatch = useDispatch();
  const { userId } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (messageId: string) => {
      dispatch({ type: USER_INBOX_MARK_READ_START, payload: messageId });

      const docRef = doc(getFirestore(), FirebasePathMappings.user, userId, FirebasePathMappings.inbox, messageId);

      return setDoc(docRef, { read: true }, { merge: true })
        .then(() => {
          dispatch({ type: USER_INBOX_MARK_READ_SUCCESS, payload: messageId });
          return Promise.resolve(messageId);
        })
        .catch((error) => {
          dispatch({ type: USER_INBOX_MARK_READ_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, userId],
  );
};

/**
 * useDispatchInboxGetUnreadCount()
 */
export const useDispatchInboxGetUnreadCount = (): (() => Promise<number>) => {
  const dispatch = useDispatch();
  const { userId } = useAppSelector((state) => state.auth);

  return React.useCallback(() => {
    dispatch({ type: USER_INBOX_UNREAD_COUNT_START });

    const userCollection = collection(getFirestore(), FirebasePathMappings.user, userId, FirebasePathMappings.inbox);
    const queryRef = query(userCollection, where('read', '==', false));

    return getDocs(queryRef)
      .then((docRefs) => {
        dispatch({ type: USER_INBOX_UNREAD_COUNT_SUCCESS, payload: docRefs.docs.length });
        return Promise.resolve(docRefs.docs.length);
      })
      .catch((error) => {
        dispatch({ type: USER_INBOX_UNREAD_COUNT_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [dispatch, userId]);
};

/**
 * useDispatchInboxDelete()
 */
export const useDispatchInboxDelete = (): ((messageId: string) => Promise<void>) => {
  const dispatch = useDispatch();
  const { userId } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (messageId) => {
      dispatch({ type: USER_INBOX_DELETE_START });

      const docRef = doc(getFirestore(), FirebasePathMappings.user, userId, FirebasePathMappings.inbox, messageId);

      return deleteDoc(docRef)
        .then(() => {
          dispatch({ type: USER_INBOX_DELETE_SUCCESS, payload: messageId });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch({ type: USER_INBOX_DELETE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, userId],
  );
};

/**
 * useDispatchInboxUpdate()
 */
export const useDispatchInboxUpdate = (): ((message: MessageEntity) => Promise<MessageEntity>) => {
  const dispatch = useDispatch();
  const { userId } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (message) => {
      dispatch({ type: USER_INBOX_UPDATE_START });

      const docRef = doc(
        getFirestore(),
        FirebasePathMappings.user,
        userId,
        FirebasePathMappings.inbox,
        message.messageId,
      );

      return setDoc(docRef, message, { merge: true })
        .then(() => {
          dispatch({ type: USER_INBOX_UPDATE_SUCCESS, payload: message });
          return Promise.resolve(message);
        })
        .catch((error) => {
          dispatch({ type: USER_INBOX_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [dispatch, userId],
  );
};

type UploadAttachmentType = (file: File) => Promise<DocumentEntity>;
/**
 * useDispatchUploadAttachment()
 * Uploads all attachments for a message wich should be sent. The attachments will be stored in a special path
 * and the information to the file will be added to the message object after upload. All attachments will be deleted
 * after 100 days!
 */
const useDispatchUploadAttachment = (): UploadAttachmentType => {
  const { userId } = useAppSelector((state) => state.auth);

  return React.useCallback<UploadAttachmentType>(
    (file) => {
      const guid = generateGuid();
      const pathRef = buildDocumentRef(getStorage(), FirebasePathMappings.attachments, guid);

      const customMetadata = {
        uploadFileName: file.name,
        uploadUserId: userId,
      };

      return uploadBytes(pathRef, file, { customMetadata }).then((uploadResult) => {
        return getDownloadURL(uploadResult.ref).then((url) => {
          const result = {
            downloadUrl: url,
            path: uploadResult.ref.fullPath,
            fileName: file.name,
            size: file.size,
          };
          return Promise.resolve(result);
        });
      });
    },
    [userId],
  );
};
