import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NavigationItem from './NavigationItem';
import logo from '../../../Assets/Images/logo2.png';
import SelectLanguage from './SelectLanguage';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  ${(props) => props.theme.boxShadowDark};
  margin-bottom: 20px;
  background-color: #ffffff;
`;

const InnerContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  padding: 10px;

  @media (max-width: 1350px) {
    width: 100%;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
`;

const Image = styled.img`
  height: 60px;
  margin-right: 50px;

  @media (max-width: 1350px) {
    margin-right: 0;
  }
`;

/**
 * Panel()
 * @constructor
 */
export default function PublicNavigation() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <InnerContainer>
        <LeftContainer>
          <Image data-testid="image" src={logo} onClick={() => navigate('/public/startpage')} />

          <NavigationItem
            testId="login"
            icon="fas fa-home"
            caption={t('startPage')}
            onPress={() => {}}
            path="startpage"
            visible={!isMobile}
          />
        </LeftContainer>
        <RightContainer>
          <NavigationItem
            testId="login"
            icon="fas fa-sign-in-alt"
            caption={t('login')}
            onPress={() => {}}
            path="login"
            visible={!isMobile}
          />
          <NavigationItem
            testId="register"
            icon="fas fa-check-circle"
            caption={t('registerForFree')}
            onPress={() => {}}
            path="register"
            visible={!isMobile}
          />
          <SelectLanguage />
        </RightContainer>
      </InnerContainer>
    </Container>
  );
}
