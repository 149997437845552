import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { lighten } from 'polished';

type Props = {
  height?: number;
  style?: Object;
  className?: string;
};

/**
 * CustomSkeleton
 * @param props
 * @constructor
 */
export default function CustomSkeleton(props: Props) {
  return <Skeleton {...props} baseColor={lighten(0.02, '#ededed')} highlightColor="#ffffff" />;
}
