import React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router';
import { Directions } from '../../../Globals/Types/Enums';
import { AccessPath, useHasAccess } from '../../../Globals/Access/Groups';

type NavigationItemProps = {
  icon: string;
  caption: string;
  subCaption?: string | React.ReactElement;
  onPress: Function | null;
  testId: string;
  path?: string | null;
  direction: Directions;
  visible: boolean;
  showRightArrow: boolean;
  rightIcon?: string;
  rightIconSize?: number;
  disabled: boolean;
  accessPath: AccessPath;
  textBold: boolean;
  iconColor?: string;
};

interface ContainerProps {
  direction: Directions;
  iconColor: string;
  textBold: boolean;
  rightIconSize: number;
}

const Container = styled.a<ContainerProps>`
  display: flex;
  ${(props) => (props.direction === Directions.vertical ? '' : 'width: 100%')};
  flex-direction: ${(props) => (props.direction === Directions.vertical ? 'column' : 'row')};
  justify-content: ${(props) => (props.direction === Directions.vertical ? 'center' : 'flex-start')};
  align-content: ${(props) => (props.direction === Directions.vertical ? 'center' : 'flex-start')};
  align-items: ${(props) => (props.direction === Directions.vertical ? 'center' : 'center')};
  text-align: ${(props) => (props.direction === Directions.vertical ? 'center' : 'flex-start')};
  padding: ${(props) => (props.direction === Directions.vertical ? '15px 20px 10px 20px' : '15px 20px')};
  cursor: pointer;
  border-radius: ${(props) => props.theme.border.radius};
  margin-left: 2px;
  text-decoration: none;
  margin-bottom: ${(props) => (props.direction === Directions.vertical ? '0' : '2px')};

  > .LeftIcon {
    font-size: 20px;
    margin-bottom: ${(props) => (props.direction === Directions.vertical ? '6px' : '0')};
    width: ${(props) => (props.direction === Directions.vertical ? 'auto' : '40px')};
    min-width: ${(props) => (props.direction === Directions.vertical ? 'auto' : '40px')};
    max-width: ${(props) => (props.direction === Directions.vertical ? 'auto' : '40px')};
    color: ${(props) => (props.iconColor ? props.iconColor : props.theme.color.primaryColor)};
  }

  > .RightIcon {
    color: ${(props) => props.theme.color.textGrayLight};
    font-size: ${(props) => props.rightIconSize}px;
    margin-left: 15px;
  }

  .CenterContainer {
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .Caption {
    color: ${(props) => props.theme.color.text};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: ${(props) => (props.textBold ? 'bold' : 'normal')};
  }

  .SubCaption {
    color: ${(props) => props.theme.color.textGray};
    font-size: 13px;
    margin-top: -3px;
  }

  &:hover {
    background-color: ${(props) => props.theme.color.primaryColor};

    .Caption,
    .LeftIcon,
    .RightIcon,
    .SubCaption {
      color: #fff;
    }
  }

  &.active {
    background-color: ${(props) => props.theme.color.primaryColor};

    .LeftIcon,
    .SubCaption,
    .RightIcon,
    .Caption {
      color: #fff;
    }
  }

  &.disabled {
    cursor: default;

    .LeftIcon,
    .Caption {
      color: ${(props) => props.theme.color.textGray};
    }

    &:hover {
      background-color: transparent;

      .Caption {
        color: ${(props) => props.theme.color.textGray};
      }
      .LeftIcon {
        color: ${(props) => props.theme.color.textGray};
      }
    }
  }
`;

/**
 * Panel()
 * @param props
 * @constructor
 */
export default function NavigationItem(props: NavigationItemProps) {
  const { disabled, accessPath, textBold, iconColor, subCaption, rightIcon, rightIconSize } = props;
  const isAllowed = useHasAccess(accessPath);
  const navigation = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.path) {
      setIsActive(location.pathname.lastIndexOf(props.path) >= 0);
    } else {
      setIsActive(false);
    }
  }, [location, props.path]);

  const handleNavigation = () => {
    if (props.path) {
      navigation(props.path);
    } else {
      props.onPress();
    }
  };

  const renderRightArrow = () => {
    if (props.showRightArrow && props.direction === Directions.horizontal) {
      return <i className="fas fa-angle-right RightIcon" />;
    }
    return null;
  };

  const renderRightIcon = () => {
    if (!props.showRightArrow && props.direction === Directions.horizontal && rightIcon) {
      return <i className={`${rightIcon} RightIcon`} />;
    }
    return null;
  };

  const renderSubCaption = () => {
    if (subCaption) {
      return <div className="SubCaption">{subCaption}</div>;
    }
    return null;
  };

  if (!accessPath || isAllowed) {
    if (props.visible) {
      return (
        <Container
          data-testid={props.testId}
          onClick={!disabled ? handleNavigation : () => {}}
          title={props.caption}
          className={`${isActive ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
          direction={props.direction}
          textBold={textBold}
          iconColor={iconColor}
          rightIconSize={rightIconSize}
        >
          <i className={`${props.icon} LeftIcon`} />
          <div className="CenterContainer">
            <div className="Caption">{props.caption}</div>
            {renderSubCaption()}
          </div>
          {renderRightArrow()}
          {renderRightIcon()}
        </Container>
      );
    }
  }
  return null;
}

NavigationItem.defaultProps = {
  testId: null,
  direction: Directions.vertical,
  visible: true,
  onPress: null,
  showRightArrow: false,
  disabled: false,
  accessPath: '',
  textBold: false,
  iconColor: null,
  rightIconSize: 20,
};
