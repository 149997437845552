import React from 'react';
import whatsAppQrCode from '../../Assets/Images/whatsAppQrCodeGray.png';
import { BrowserView, CustomView, isBrowser, isTablet, MobileOnlyView } from 'react-device-detect';
import styled from 'styled-components';
import Config from '../../Globals/Config';

const Footer = styled.div`
  padding: 40px;
  text-align: center;
  color: ${(props) => props.theme.color.textGray};

  .normal {
    line-height: 40px;
    font-weight: bolder;
  }

  .small {
    line-height: 22px !important;

    > span {
      margin: 0 10px;
    }
  }

  a {
    color: ${(props) => props.theme.color.textGray};
  }
`;

export default function PublicFooter() {
  return (
    <Footer>
      <CustomView condition={isBrowser || isTablet}>
        <div>
          <img src={whatsAppQrCode} style={{ height: 200, marginBottom: 20 }} alt="WhatsApp QR-Code" />
        </div>
      </CustomView>

      <BrowserView>
        <div className="normal">© 2023 Sotosoft S.L. / Sotogrande / NIF: B72570732</div>
      </BrowserView>
      <MobileOnlyView>
        <div className="normal">© 2023 Sotosoft S.L. / Sotogrande</div>
        <div className="normal" style={{ marginTop: -20 }}>
          NIF: B72570732
        </div>
      </MobileOnlyView>

      <div className="small">Urb. Senda Chica, La Reserva 6 Bajo B, 11310 Sotogrande</div>
      <div className="small">
        <span>
          <i className="fas fa-envelope" /> <a href="mailto:info@sotosoft.app">info@sotosoft.app</a>
        </span>
        <span>
          <i className="fab fa-whatsapp" />{' '}
          <a href={Config.whatsAppUrl} target="_blank" rel="noreferrer">
            (+34) 676 886 931
          </a>
        </span>
      </div>
    </Footer>
  );
}
