import React from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import { TimeTrackingType } from '../Types/Enums';

/**
 * getTimeTrackingIconNameByType()
 * @param type
 * @param circleIcons
 */
export const getTimeTrackingIconNameByType = (type: TimeTrackingType, circleIcons: boolean) => {
  switch (type) {
    case TimeTrackingType.startTour:
    case TimeTrackingType.start:
      return circleIcons ? 'fas fa-play-circle' : 'fas fa-play';
    case TimeTrackingType.stopTour:
    case TimeTrackingType.stop:
      return circleIcons ? 'fas fa-stop-circle' : 'fas fa-stop';
    case TimeTrackingType.pauseTour:
    case TimeTrackingType.pause:
      return circleIcons ? 'fas fa-pause-circle' : 'fas fa-pause';
    case TimeTrackingType.trafficJamTour:
      return 'fas fa-truck-clock';
    default:
      return circleIcons ? 'fas fa-stop-circle' : 'fas fa-stop';
  }
};

/**
 * getTimeTrackingIconColorByType()
 * @param type
 * @param theme
 */
export const getTimeTrackingIconColorByType = (type: TimeTrackingType, theme: DefaultTheme) => {
  switch (type) {
    case TimeTrackingType.startTour:
    case TimeTrackingType.start:
      return theme.color.green;
    case TimeTrackingType.stopTour:
    case TimeTrackingType.stop:
      return theme.color.red;
    case TimeTrackingType.pauseTour:
    case TimeTrackingType.pause:
      return theme.color.orange;
    case TimeTrackingType.trafficJamTour:
      return theme.color.blue;
    default:
      return theme.color.blue;
  }
};

/**
 * useTimeTrackingGetIcon()
 * @param circleIcons
 * @param size
 */
export const useTimeTrackingGetIcon = (circleIcons: boolean, size: number) => {
  const theme = useTheme();

  return React.useCallback(
    (type: TimeTrackingType) => {
      return (
        <i
          className={getTimeTrackingIconNameByType(type, circleIcons)}
          style={{ color: getTimeTrackingIconColorByType(type, theme), fontSize: size }}
        />
      );
    },
    [circleIcons, size, theme],
  );
};
