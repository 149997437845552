import { useAppSelector } from '../../Globals/Hooks/Hooks';
import React from 'react';
import { onSnapshot, getFirestore, doc, DocumentReference } from 'firebase/firestore';
import { FirebasePathMappings } from '../../Globals/FirebaseGlobals';
import { ClientEntity } from '../../Globals/Types/Types';
import { useDispatchOrderServiceRebuildIndex } from '../../Redux/Actions/Order/ServiceAction';
import LoadingModal from '../LoadingModal';
import { useDispatchClientGet } from '../../Redux/Actions/ClientAction';
import { useTranslation } from 'react-i18next';
import ModalHint from './ModalHint';

/**
 * useCheckRebuildIndex()
 * Check if index must be rebuilt!
 */
export const useCheckRebuildIndex = () => {
  const { clientId, userId, client } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isClientAdmin, setIsClientAdmin] = React.useState<boolean>(false);
  const [showHint, setShowHint] = React.useState<boolean>(false);

  const [t] = useTranslation();
  const dispatchRebuildIndex = useDispatchOrderServiceRebuildIndex();
  const dispatchClientGet = useDispatchClientGet();

  React.useEffect(() => {
    if (client) {
      if (client.adminUserId === userId) {
        setIsClientAdmin(true);
      }
    }
  }, [client, userId]);

  React.useEffect(() => {
    let unsubscribe = null;
    if (clientId) {
      const documentRef = doc(getFirestore(), FirebasePathMappings.client, clientId) as DocumentReference<ClientEntity>;

      unsubscribe = onSnapshot(documentRef, (snapShot) => {
        if (snapShot.data().settings.forceRebuildIndex === true) {
          if (isClientAdmin) {
            setTimeout(() => {
              setLoading(true);
              dispatchRebuildIndex()
                .then(() => dispatchClientGet(clientId))
                .finally(() => setLoading(false));
            }, 1000);
          } else {
            setShowHint(true);
          }
        } else {
          setShowHint(false);
        }
      });
    }
    return () => (unsubscribe ? unsubscribe() : null);
  }, [clientId, dispatchClientGet, dispatchRebuildIndex, isClientAdmin]);

  if (loading) {
    if (isClientAdmin) {
      return (
        <LoadingModal
          headline={t('indexCreating')}
          line1={t('indexCreatingPleaseWait')}
          visible={loading}
          line2={t('indexCreatingDescription')}
        />
      );
    }
  }
  if (showHint) {
    return <ModalHint visible={showHint} />;
  }
  return null;
};
