import { DefaultValidationResult } from '../../../../Globals/Types/Types';
import { InternalErrorCodes } from '../../../../Globals/InternalErrorCodes';
import { TimeTrackingType } from '../../../../Globals/Types/Enums';
import { ManualTimeTrackingError } from '../TimeTrackingManualModal';

/**
 * isValid
 * @param date
 * @param time
 * @param type
 */
const isValid = (
  date: Date,
  time: string,
  type: TimeTrackingType,
): DefaultValidationResult<ManualTimeTrackingError> => {
  let errors: ManualTimeTrackingError = {};

  if (!date) {
    errors = { ...errors, date: InternalErrorCodes.FIELD_MISSING };
  }

  if (!time) {
    errors = { ...errors, time: InternalErrorCodes.FIELD_MISSING };
  } else {
    if (!time.match(/^$|^(([1-9])|([01][0-9])|(2[0-3])):[0-5][0-9]$/)) {
      errors = { ...errors, time: InternalErrorCodes.TIME_INVALID };
    }
  }

  if (!type) {
    errors = { ...errors, type: InternalErrorCodes.FIELD_MISSING };
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export default isValid;
