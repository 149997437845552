import { useDispatch } from 'react-redux';
import React from 'react';
import { useDispatchDocumentGetFolderContent } from '../../GlobalStorageActions';
import { OrderEntity } from '../../../../Globals/Types/OrderTypes';
import { StorageReference } from '@firebase/storage';
import {
  ORDER_SERVICE_DOCUMENT_GETLIST_ERROR,
  ORDER_SERVICE_DOCUMENT_GETLIST_START,
  ORDER_SERVICE_DOCUMENT_GETLIST_SUCCESS,
} from '../../../ActionTypes';

/**
 * useDispatchOrderServiceDocumentGetList
 */
type GetListReturn = (order: OrderEntity) => Promise<StorageReference[]>;

export const useDispatchOrderServiceDocumentGetList = (): GetListReturn => {
  const dispatch = useDispatch();
  const dispatchGetList = useDispatchDocumentGetFolderContent();

  return React.useCallback<GetListReturn>(
    (order) => {
      dispatch({ type: ORDER_SERVICE_DOCUMENT_GETLIST_START, payload: order });
      const promiseAll: Array<Promise<StorageReference[]>> = [];

      order.services.forEach((service) => {
        const folderPath = `/client/${order.ownerClientId}/order/${order.orderId}/service/${service.serviceId}`;
        promiseAll.push(dispatchGetList(folderPath));
      });

      return Promise.all(promiseAll)
        .then((response) => {
          const result: StorageReference[] = [];

          response.forEach((item) => {
            item.forEach((fileRef) => {
              result.push(fileRef);
            });
          });

          dispatch({ type: ORDER_SERVICE_DOCUMENT_GETLIST_SUCCESS, payload: result });
          return Promise.resolve(result);
        })
        .catch((error) => {
          dispatch({ type: ORDER_SERVICE_DOCUMENT_GETLIST_ERROR, payload: error });
          return Promise.resolve(error);
        });
    },
    [dispatch, dispatchGetList],
  );
};
