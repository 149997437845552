import React from 'react';
import { OrderEntity } from '../../../Globals/Types/OrderTypes';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import Select, { SelectOptions } from '../../Inputs/Select';
import { GroupBase } from 'react-select';
import { userDisplayName } from '../../../Globals/Functions';
import { useTranslation } from 'react-i18next';
import Lodash from 'lodash';

export interface OnChangeResult {
  isPrivate: boolean;
  users: Array<string>;
  facilities: Array<string>;
}

interface Props {
  order: OrderEntity;
  disabled?: boolean;
  onChange?: (item: OnChangeResult) => void;
}

/**
 * MessageAccessSelect
 * @param props
 * @constructor
 */
export default function MessageAccessSelect(props: Props) {
  const { order, onChange, disabled } = props;
  const [t] = useTranslation();
  const [ids, setIds] = React.useState<Array<string>>(null);
  const [options, setOptions] = React.useState<Array<GroupBase<SelectOptions>>>([]);
  const { allPartners, facilities } = useAppSelector((state) => state.client);
  const { all: users } = useAppSelector((state) => state.client.users);
  const { userId, user } = useAppSelector((state) => state.auth);
  const { allFacilities } = useAppSelector((state) => state.order.selectedOrder);

  const userOptions = React.useCallback(() => {
    if (users) {
      return users
        .filter((userParam) => userParam.userId !== userId)
        .map((user) => ({ value: `user-${user.userId}`, label: userDisplayName(user) }));
    }
    return [];
  }, [userId, users]);

  const privateMessageOptions = React.useCallback(() => {
    const facilitiesFiltered = user.facilities
      .map((item) => {
        const partner = Lodash.find(facilities, (partnerItem) => partnerItem.facilityId === item);
        return {
          value: `facility-${partner.facilityId}`,
          label: t('onlyForFacility', { name: partner.name }),
        };
      })
      .filter((item) => Lodash.find(allFacilities, (all) => all.facilityId === item.value.replace('facility-', '')));

    return [{ value: `private-${userId}`, label: t('onlyForMe') }, ...facilitiesFiltered];
  }, [allFacilities, facilities, t, user.facilities, userId]);

  const partnerOptions = React.useCallback(() => {
    const params = [];

    if (allPartners) {
      ids.forEach((id) => {
        const partner = allPartners.filter((item) => item.partnerFacilityId === id)[0];
        if (partner) {
          params.push({ value: `partner-${id}`, label: partner.partnerName });
        }
      });
    }

    return params;
  }, [ids, allPartners]);

  React.useEffect(() => {
    const mappedIds = order.services.map((service) => service.assignedPartnerFacilityId);
    mappedIds.push(order.ownerFacilityId);
    mappedIds.push(order.createdFacilityId);
    setIds([...new Set(mappedIds.filter((item) => item))]);
  }, [order]);

  React.useEffect(() => {
    if (ids) {
      const mappedOptions: Array<GroupBase<any>> = [
        { label: t('privateMessage'), options: privateMessageOptions() },
        { label: t('partner'), options: partnerOptions() },
        { label: t('employee'), options: userOptions() },
      ];

      setOptions(mappedOptions);
    }
  }, [ids, partnerOptions, privateMessageOptions, t, userId, userOptions]);

  const handleOnChange = (data: Array<string>) => {
    let result: OnChangeResult = {
      isPrivate: false,
      users: [],
      facilities: [],
    };

    data.forEach((id) => {
      if (id.indexOf('private') > -1) {
        result = { ...result, isPrivate: true };
      } else if (id.indexOf('facility') > -1) {
        result = { ...result, facilities: [...result.facilities, id.replace('facility-', '')] };
      } else {
        if (id.indexOf('user') > -1) {
          result = { ...result, users: [...result.users, id.replace('user-', '')] };
        } else {
          result = { ...result, facilities: [...result.facilities, id.replace('partner-', '')] };
        }
      }
    });

    // Ad the current user to access array, if access is restricted to someone. Created user sees always
    // his own messages
    if (result.users.length > 0 || result.facilities.length > 0 || result.isPrivate) {
      result = { ...result, users: [...result.users, userId] };
    }

    onChange(result);
  };

  return (
    <Select
      onChange={handleOnChange}
      placeholder="Alle können diese Mitteilung sehen"
      label={'Wer darf die Mitteilung sehen?'}
      options={options}
      multiSelect
      disabled={disabled}
      contextHelpKey="messageAccess"
    />
  );
}

MessageAccessSelect.defaultProps = {
  disabled: false,
};
