import React from 'react';
import InvoiceCreate from '../../../../Components/Invoice/Create';
import { useAppSelector } from '../../../../Globals/Hooks/Hooks';

/**
 * CreateInvoice()
 * @constructor
 */
export default function CreateInvoice() {
  const { order } = useAppSelector((state) => state.order.selectedOrder);
  const { service } = useAppSelector((state) => state.order.selectedOrder.selectedService);
  return <InvoiceCreate order={order} service={service} />;
}
