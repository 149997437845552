import React from 'react';
import { InvoiceEntity, InvoiceServices } from '../../../../Globals/Types/Invoice';
import Panel from '../../../Panel';
import DisplayText from '../../../View/DisplayText';
import { useTranslation } from 'react-i18next';
import { buildUrlOrderServiceDetails } from '../../../../Globals/UrlFunctions';
import { useNavigate } from 'react-router';

interface Props {
  invoice: InvoiceEntity;
}

/**
 * AssignedServiceHint()
 * @param props
 * @constructor
 */
export default function AssignedServiceHint(props: Props) {
  const { invoice } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();

  const navigateService = (service: InvoiceServices) => {
    if (service.ownerClientId && service.orderId && service.serviceId) {
      navigate(buildUrlOrderServiceDetails(service.ownerClientId, service.orderId, service.serviceId));
    }
  };

  const renderServices = () => {
    return invoice.services.map((service) => {
      return (
        <DisplayText
          rightAction={<i className="fas fa-circle-arrow-right" onClick={() => navigateService(service)} />}
          caption={t('invoiceForOrder')}
        >
          {service.externalId}
        </DisplayText>
      );
    });
  };

  if (invoice.services && invoice.services.length > 0) {
    return <Panel>{renderServices()}</Panel>;
  }
  return null;
}
