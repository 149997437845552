import React, { ReactElement } from 'react';
import LoadingModal from '../../Components/LoadingModal';
import ContextHelpModal from '../../Components/ContextHelpModal';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../../Redux/Store';
import Config from '../Config';
import { Environment } from '../Types/Enums';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MessageEntity } from '../Types/Types';
import { SelectOptions } from '../../Components/Inputs/Select';

type LoadingModalOptions = {
  headline?: string;
  textLine1?: string;
  textLine2?: string;
  progress?: number;
  delayStart?: number;
  delayEnd?: number;
  initialValue?: boolean;
};
type LoadingModalResult = {
  loadingModal: ReactElement;
  setLoading: (value: boolean) => void;
  isLoading: boolean;
  setHeadline: (headline: string) => void;
  setTextLine1: (line: string) => void;
};
type LoadingModalFn = (options?: LoadingModalOptions) => LoadingModalResult;
/**
 * Generate a Loading Component and returns it if loading is set. The returned loadingElement has to be placed into
 * the Code and if loading is set the default loading modal will appear!
 * useLoadingModal()
 * @param options
 */
export const useLoadingModal: LoadingModalFn = (
  options: LoadingModalOptions = { initialValue: false },
): LoadingModalResult => {
  const [loading, setLoading] = React.useState<boolean>(options.initialValue);
  const [delayedLoading, setDelayedLoading] = React.useState<boolean>(false);
  const [loadingElement, setLoadingElement] = React.useState<ReactElement | null>(null);
  const [headline, setHeadline] = React.useState<string>(options.headline);
  const [textLine1, setTextLine1] = React.useState<string>(options.textLine1);
  const timerRef = React.useRef<any>();

  const setLoadingFnc = React.useCallback(
    (value: boolean) => {
      clearTimeout(timerRef.current);
      if (!value) {
        if (Config.env === Environment.dev) {
          setTimeout(() => {
            setLoading(false);
          }, Math.random() * 3000);
        } else {
          setLoading(false);
        }
        timerRef.current = setTimeout(() => setDelayedLoading(false), options.delayEnd || 0);
      } else {
        setLoading(true);
        timerRef.current = setTimeout(() => setDelayedLoading(true), options.delayStart || 0);
      }
    },
    [options.delayEnd, options.delayStart],
  );

  React.useEffect(() => {
    if (delayedLoading) {
      setLoadingElement(
        <LoadingModal
          visible={true}
          headline={headline}
          line1={textLine1}
          line2={options.textLine2}
          progress={options.progress}
        />,
      );
    } else {
      if (Config.env === Environment.dev) {
        setTimeout(() => {
          setLoadingElement(null);
        }, Math.random() * 4000);
      } else {
        setLoadingElement(null);
      }
    }
  }, [delayedLoading, headline, options.progress, options.textLine2, textLine1]);

  return { loadingModal: loadingElement, setLoading: setLoadingFnc, isLoading: loading, setHeadline, setTextLine1 };
};

type HelpContextResult = [ReactElement, Function];
type HelpContextFn = () => HelpContextResult;
/**
 * useHelpContext()
 */
export const useHelpContext: HelpContextFn = (): HelpContextResult => {
  const [contextName, setContextName] = React.useState<string>(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [modal, setModal] = React.useState<ReactElement>(null);

  const fnc = React.useCallback((contextName) => {
    setContextName(contextName);
    setVisible(true);
  }, []);

  React.useEffect(() => {
    if (contextName && visible) {
      setModal(<ContextHelpModal visible={true} onClose={() => setVisible(false)} contextName={contextName} />);
    } else {
      setModal(null);
    }
  }, [contextName, visible]);

  return [modal, fnc];
};

/**
 * Get an array of time options
 */
type ReturnGetTimeOptions = {
  options: SelectOptions;
  getTimeOptionsRange: (start: string, end: string) => SelectOptions;
};
export const useGetTimeOptions = (intervalInMinutes: number): ReturnGetTimeOptions => {
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>(null);

  const getTimeOptions = React.useCallback(
    (start: string, end: string): SelectOptions => {
      const params: SelectOptions = [];
      let currentDate = moment(start, 'H:mm');
      while (!currentDate.isAfter(moment(end, 'H:mm'), 'minute')) {
        params.push({
          value: currentDate.format('HH:mm'),
          label: t('xClock', { time: currentDate.format('HH:mm') }),
        });
        currentDate = currentDate.add(intervalInMinutes, 'minute');

        if (currentDate.format('HH:mm') === '00:00') {
          break;
        }
      }
      return params;
    },
    [intervalInMinutes, t],
  );

  React.useEffect(() => {
    setOptions(getTimeOptions('6:00', '23:50'));
  }, [getTimeOptions, intervalInMinutes]);

  return { options, getTimeOptionsRange: getTimeOptions };
};

/**
 * Get an array of minutes options
 * @param intervalInMinutes
 * @param hours
 * @param translationKey
 */
export const useGetMinuteOptions = (
  intervalInMinutes: number,
  hours: number = 1,
  translationKey: string,
): SelectOptions => {
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<SelectOptions>(null);

  React.useEffect(() => {
    const amount: number = parseInt(((hours * 60) / intervalInMinutes).toFixed(0));
    const result = [];

    for (let i = 0; i <= amount; i++) {
      const value = i * intervalInMinutes;
      result.push({ value, label: t(translationKey, { count: value }) });
    }

    setOptions(result);
  }, [hours, intervalInMinutes, t, translationKey]);

  return options;
};

/**
 * useGetAssemblyTimeOptions()
 */
export const useGetAssemblyTimeOptions = () => {
  const [t] = useTranslation();

  return [
    { label: t('xMinutes', { count: 0 }), value: 0 },
    { label: t('xMinute', { count: 15 }), value: 15 },
    { label: t('xMinute', { count: 30 }), value: 30 },
    { label: t('xMinute', { count: 45 }), value: 45 },
    { label: t('xHours', { count: 1 }), value: 60 },
    { label: t('xHours', { count: 1.25 }), value: 75 },
    { label: t('xHours', { count: 1.5 }), value: 90 },
    { label: t('xHours', { count: 1.75 }), value: 105 },
    { label: t('xHours', { count: 2 }), value: 120 },
    { label: t('xHours', { count: 2.5 }), value: 150 },
    { label: t('xHours', { count: 3 }), value: 180 },
    { label: t('xHours', { count: 3.5 }), value: 210 },
    { label: t('xHours', { count: 4 }), value: 240 },
    { label: t('xHours', { count: 4.5 }), value: 270 },
    { label: t('xHours', { count: 5 }), value: 300 },
    { label: t('xHours', { count: 5.5 }), value: 330 },
    { label: t('xHours', { count: 6 }), value: 360 },
    { label: t('xHours', { count: 6.5 }), value: 390 },
    { label: t('xHours', { count: 7 }), value: 420 },
    { label: t('xHours', { count: 7.5 }), value: 450 },
    { label: t('xHours', { count: 8 }), value: 480 },
  ];
};

/**
 * Get an array of numbers in range of start and end
 * @param start
 * @param end
 */
export const useGetNumberOptions = (start: number, end: number): SelectOptions => {
  const [options, setOptions] = React.useState<SelectOptions>(null);

  React.useEffect(() => {
    const result = [];

    for (let i = start; i <= end; i++) {
      const value = i;
      result.push({ value, label: value });
    }

    setOptions(result);
  }, [end, start]);

  return options;
};

/**
 * useGetMessageSubject()
 */
type GetMessageSubjectReturnType = (message: MessageEntity) => string;
export const useGetMessageSubject = (): GetMessageSubjectReturnType => {
  const [t] = useTranslation();

  return React.useCallback<GetMessageSubjectReturnType>(
    (message) => {
      if (message.template) {
        const translationPath = `messageTemplates.${message.template}.subject`;
        return t(translationPath, message.parameters);
      }
      return message.subject;
    },
    [t],
  );
};

/**
 * useGetMessageText()
 */
type GetMessageTextReturnType = (message: MessageEntity) => string;
export const useGetMessageText = (): GetMessageTextReturnType => {
  const [t] = useTranslation();

  return React.useCallback<GetMessageTextReturnType>(
    (message) => {
      if (message.template) {
        const translationPath = `messageTemplates.${message.template}.message`;
        return t(translationPath, message.parameters);
      }

      return message.message;
    },
    [t],
  );
};

/**
 * usePageTitle()
 * Set page title and reset to default if page will be destroyed!
 * @param title
 */
export const usePageTitle = (title: string) => {
  React.useEffect(() => {
    document.title = `${title} - SotoSoft`;
    return () => {
      document.title = `SotoSoft - Process your work!`;
    };
  }, [title]);
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
