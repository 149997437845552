import React from 'react';
import styled, { useTheme } from 'styled-components';
import Config from '../Globals/Config';
import { Environment } from '../Globals/Types/Enums';
import Text from './Text';

const Container = styled.div<{ $bgColor: string }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: ${(props) => props.$bgColor};
  text-align: center;
  color: #ffffff;
`;

/**
 * EnvironmentHint()
 * @constructor
 */
export default function EnvironmentHint() {
  const theme = useTheme();

  const getBgColor = () => {
    if (Config.env === Environment.dev) {
      return theme.color.red;
    }
    if (Config.env === Environment.staging) {
      return theme.color.orange;
    }

    return '#ffffff';
  };

  if (Config.env !== Environment.production) {
    return (
      <Container $bgColor={getBgColor()}>
        <Text bold centered color={'#ffffff'}>
          {Config.env === Environment.dev ? 'Development' : 'Staging'}
        </Text>
      </Container>
    );
  }
  return null;
}
