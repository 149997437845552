import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import CustomSkeleton from '../CustomSkeleton';
import { useHelpContext } from '../../Globals/Hooks/Hooks';

interface StyledInterface {
  $marginTop: boolean;
  $marginBottom: boolean;
  $allowLineBreak: boolean;
  $bold: boolean;
  $whiteBackground: boolean;
}

const defaultMargins = '25px';

const SkeletonContainer = styled(CustomSkeleton)<StyledInterface>`
  margin-top: ${(props) => (props.$marginTop ? defaultMargins : 0)};
  margin-bottom: ${(props) => (props.$marginBottom ? defaultMargins : '6px')};
  border: 0.5px solid ${(props) => darken(0.04, props.theme.color.backgroundGray)};
`;

const Container = styled.div<StyledInterface>`
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  background-color: ${(props) => (props.$whiteBackground ? '#ffffff' : props.theme.color.backgroundGray)};
  border: 0.5px solid ${(props) => darken(0.04, props.theme.color.backgroundGray)};
  border-radius: 6px;
  margin-top: ${(props) => (props.$marginTop ? defaultMargins : 0)};
  margin-bottom: ${(props) => (props.$marginBottom ? defaultMargins : '6px')};
  font-weight: ${(props) => (props.$bold ? 'bold' : 'normal')};

  .Left {
    flex-grow: 1;
    ${(props) => (!props.$allowLineBreak ? 'overflow: hidden;' : null)};
    ${(props) => (!props.$allowLineBreak ? 'text-overflow: ellipsis;' : null)};
    ${(props) => (!props.$allowLineBreak ? 'white-space: nowrap;' : null)};

    > .Caption {
      font-size: 14px;
      color: ${(props) => props.theme.color.textGray};
    }

    .WithIcon {
      align-items: center;
      align-content: center;
      display: flex;
      flex-direction: row;

      .WithIconContent {
        flex-grow: 1;
      }
    }
  }

  .Right {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;

    .fa-question-circle {
      &:hover {
        color: ${(props) => props.theme.color.blue};
      }
    }
  }

  i {
    width: 26px;
    color: ${(props) => props.theme.color.textGray};
  }

  .HorizontalInnerContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 8px 0;

    .Left {
      display: flex;
      flex: 1;
      align-items: center;
    }
  }
`;

interface Props {
  caption?: string;
  icon?: string;
  horizontal?: boolean;
  children?: string | React.ReactElement[] | React.ReactElement;
  containerStyle?: object;
  marginTop?: boolean;
  marginBottom?: boolean;
  showSkeleton: boolean;
  rightAction?: React.ReactElement;
  allowLineBreak?: boolean;
  contextHelpKey?: string;
  className?: string;
  visible: boolean;
  bold: boolean;
  whiteBackground?: boolean;
}

/**
 * DisplayText
 * @param props
 * @constructor
 */
export default function DisplayText(props: Props) {
  const {
    children,
    caption,
    containerStyle,
    icon,
    marginBottom,
    marginTop,
    showSkeleton,
    rightAction,
    allowLineBreak,
    contextHelpKey,
    className,
    visible,
    horizontal,
    bold,
    whiteBackground,
  } = props;
  const [help, setHelp] = useHelpContext();

  const renderCaption = () => (caption ? <div className="Caption">{caption}</div> : null);

  const renderWithIcon = () => {
    if (icon) {
      return (
        <div className="WithIcon">
          <i className={icon} />
          <div className="WithIconContent">{children}</div>
        </div>
      );
    }
    return null;
  };

  const renderNormal = () => (!icon ? children : null);
  const renderAction = () => (rightAction ? <div className="Right">{rightAction}</div> : null);
  const renderHelpIcon = () =>
    contextHelpKey ? (
      <div className="Right" style={{ marginLeft: 10 }} onClick={() => setHelp(contextHelpKey)}>
        <i className="fas fa-question-circle" />
      </div>
    ) : null;

  const renderAllActions = () => {
    return (
      <>
        {renderAction()}
        {renderHelpIcon()}
      </>
    );
  };

  const renderContent = () => {
    if (!horizontal) {
      return (
        <>
          <div className="Left">
            {renderCaption()}
            {renderWithIcon()}
            {renderNormal()}
          </div>
          {renderAllActions()}

          {help}
        </>
      );
    } else {
      return (
        <div className="HorizontalInnerContainer">
          <div className="Left">{renderCaption()}</div>
          <div className="Right">{children}</div>
        </div>
      );
    }
  };

  if (visible) {
    if (!showSkeleton) {
      return (
        <Container
          $whiteBackground={whiteBackground}
          style={containerStyle}
          $marginBottom={marginBottom}
          $marginTop={marginTop}
          $allowLineBreak={allowLineBreak}
          className={className}
          $bold={bold}
        >
          {renderContent()}
        </Container>
      );
    }
    return (
      <SkeletonContainer
        $whiteBackground={whiteBackground}
        height={caption ? 61.5 : 42}
        $marginBottom={marginBottom}
        $marginTop={marginTop}
        $allowLineBreak={allowLineBreak}
        $bold={false}
      />
    );
  }
  return null;
}

DisplayText.defaultProps = {
  horizontal: false,
  containerStyle: {},
  marginTop: false,
  marginBottom: false,
  showSkeleton: false,
  allowLineBreak: true,
  visible: true,
  bold: false,
  whiteBackground: false,
};
