import React from 'react';
import { useAppSelector } from '../Globals/Hooks/Hooks';

type Props = {
  id: string;
  idOnly?: boolean;
  smallMargin?: boolean;
};

/**
 * BottomInternalIdContainer
 * @param props
 * @constructor
 */
export default function BottomInternalIdContainer(props: Props) {
  const { id, idOnly, smallMargin } = props;
  const { clientId, userId } = useAppSelector((state) => state.auth);

  const render = () => {
    if (idOnly) {
      return id;
    }
    return `C-${clientId} / U-${userId} / ${id}`;
  };

  return (
    <div
      style={{
        color: '#b7b7b7',
        textAlign: 'center',
        marginTop: smallMargin ? 20 : 40,
        marginBottom: smallMargin ? 20 : 40,
      }}
    >
      <small>{render()}</small>
    </div>
  );
}

BottomInternalIdContainer.efaultProps = {
  idOnly: false,
  smallMargin: false,
};
