import React, { ReactElement } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';

type MonthSelectorProps = {
  currentMonth: Moment;
  onNext: (next: Moment) => void;
  onPrev: (prev: Moment) => void;
  leftContainer?: ReactElement | JSX.Element[];
  rightContainer?: ReactElement | JSX.Element[];
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;

  > .Container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;

    > .Caption {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      height: 40px;
      width: 180px;
      margin: 0 40px;
      font-family: 'DIN Next LT W01 Bold', sans-serif;
      font-size: 20px;
    }

    > .Arrow {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border: 1px solid ${(props) => props.theme.border.color};
      cursor: pointer;
      border-radius: ${(props) => props.theme.border.radius};

      &:hover {
        background-color: ${(props) => props.theme.color.primaryColor};
        color: #fff;
      }
    }
  }
`;

/**
 * MonthSelector()
 * @param props
 * @returns {*|null}
 * @constructor
 */
export default function MonthSelector(props: MonthSelectorProps) {
  const { currentMonth, onNext, onPrev, leftContainer, rightContainer } = props;

  const renderLeftContainer = () => {
    if (leftContainer) {
      return leftContainer;
    }
    return null;
  };

  const renderRightContainer = () => {
    if (rightContainer) {
      return rightContainer;
    }
    return null;
  };

  if (currentMonth) {
    return (
      <Container>
        <div style={{ width: 200 }}>{renderLeftContainer()}</div>
        <div className="Container">
          <div
            className="Left Arrow"
            onClick={() => onPrev(moment(currentMonth).subtract(1, 'months'))}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
          >
            <i className="fas fa-angle-left" />
          </div>
          <div className="Caption">{moment(currentMonth).format('MMMM YYYY')}</div>
          <div
            className="Right Arrow"
            onClick={() => onNext(moment(currentMonth).add(1, 'months'))}
            tabIndex={-1}
            role="button"
            onKeyPress={() => {}}
          >
            <i className="fas fa-angle-right" />
          </div>
        </div>
        <div style={{ width: 200, textAlign: 'right' }}>{renderRightContainer()}</div>
      </Container>
    );
  }
  return null;
}

MonthSelector.defaultProps = {
  leftContainer: null,
  rightContainer: null,
};
