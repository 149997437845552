import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeTrackingStateEntity } from '../../../../Globals/Types/Types';
import { TimeTrackingType } from '../../../../Globals/Types/Enums';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';

type RowProps = {
  item: TimeTrackingStateEntity;
  onClick: (item: TimeTrackingStateEntity) => void;
};

/**
 * OverviewTableRow()
 * @constructor
 */
export default function OverviewTableRow(props: RowProps) {
  const { item, onClick } = props;
  const [t] = useTranslation();

  const getIcon = () => {
    switch (item.type) {
      case TimeTrackingType.start:
        return <i className="fas fa-play text-success" />;
      case TimeTrackingType.pause:
        return <i className="fas fa-pause text-warning" />;
      case TimeTrackingType.stop:
        return <i className="fas fa-stop text-danger" />;
    }
  };

  const getDateString = (): string => {
    const typedTimestamp: Timestamp = item.timestamp as Timestamp;
    const date = moment(typedTimestamp.toDate());

    if (date.isSame(moment(), 'day')) {
      return t('today');
    }
    return date.format('LL');
  };

  const getStateString = () => {
    const typedTimestamp: Timestamp = item.timestamp as Timestamp;
    const time = moment(typedTimestamp.toDate()).format('HH:mm');

    switch (item.type) {
      case TimeTrackingType.start:
        return t('modules.settings.timeTracking.stateHintStart', { time, date: getDateString() });
      case TimeTrackingType.pause:
        return t('modules.settings.timeTracking.stateHintPause', { time, date: getDateString() });
      case TimeTrackingType.stop:
        return t('modules.settings.timeTracking.stateHintStop', { time, date: getDateString() });
    }
  };

  if (item) {
    return (
      <tr key={item.id} onClick={() => onClick(item)}>
        <td>{getIcon()}</td>
        <td>{`${item.user.lastName}, ${item.user.firstName} (${item.user.initials})`}</td>
        <td style={{ textAlign: 'right' }}>{getStateString()}</td>
      </tr>
    );
  }
  return null;
}
