import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { TimeTrackingEntity } from '../../../Globals/Types/Types';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import DatePicker from '../../../Components/Inputs/DatePicker';
import Input from '../../../Components/Inputs/Input';
import { useDispatchClientTimeTrackingUpdate } from '../../../Redux/Actions/Client/TimeTrackingAction';
import { useParams } from 'react-router';

type ModalProps = {
  timeTracking?: TimeTrackingEntity | null;
  onClose: (reload: boolean) => void;
  visible: boolean;
};

/**
 * TimeTrackingApproveTemporaryModal()
 * @constructor
 */
export default function TimeTrackingApproveTemporaryModal(props: ModalProps) {
  const { onClose, timeTracking, visible } = props;
  const { userId } = useParams();
  const [t] = useTranslation();
  const [time, setTime] = React.useState<string>();
  const { loadingModal, isLoading, setLoading } = useLoadingModal({ delayStart: 1000 });
  const dispatchUpdate = useDispatchClientTimeTrackingUpdate();

  React.useEffect(() => {
    if (timeTracking && visible) {
      const typedTimestamp = timeTracking.temporaryTimestamp as Timestamp;
      setTime(moment(typedTimestamp.toDate()).format('HH:mm'));
    }
  }, [timeTracking, visible]);

  const renderTemporaryHint = () => {
    if (timeTracking.temporary) {
      const typedTimestamp: Timestamp = timeTracking.temporaryTimestamp as Timestamp;
      const datetime = moment(typedTimestamp.toDate()).format('LLL - LT');

      return <Alert>{t('modules.settings.timeTracking.editTemporaryHint', { datetime })}</Alert>;
    }
    return null;
  };

  const handleApproveTemporary = () => {
    const mapped: TimeTrackingEntity = {
      ...timeTracking,
      temporary: false,
      timestamp: timeTracking.temporaryTimestamp,
    };

    setLoading(true);
    dispatchUpdate(userId, mapped)
      .then(() => {
        setLoading(false);
        onClose(true);
      })
      .catch(() => setLoading(false));
  };

  const renderApproveButton = () => {
    if (timeTracking.temporary) {
      return (
        <Button variant="success" onClick={handleApproveTemporary}>
          {t('modules.settings.timeTracking.transferTemporary')}
        </Button>
      );
    }
    return null;
  };

  const getInitialDate = (): Date => {
    // Only needed to convert value to Timestamp
    const typedTimestamp = timeTracking.temporaryTimestamp as Timestamp;
    return typedTimestamp.toDate();
  };

  if (timeTracking && visible) {
    // TODO: Why time input as input here and not as select?
    return (
      <>
        <Modal show={true} animation={true} size="lg" onHide={() => onClose(false)}>
          <Modal.Header>
            <Modal.Title>
              <i className="fas da-clock" style={{ marginRight: 10 }} />
              {t('timeTracking')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: 20 }}>
            {renderTemporaryHint()}

            <Row>
              <Col lg={6}>
                <DatePicker label={t('date')} onChange={() => {}} initialValue={getInitialDate()} required disabled />
              </Col>
              <Col lg={6}>
                <Input
                  onChange={setTime}
                  label={t('modules.settings.timeTracking.timeWithFormatHint')}
                  value={time}
                  disabled={isLoading}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {renderApproveButton()}
            <Button variant="outline-secondary" onClick={() => onClose(false)} disabled={isLoading}>
              {t('close')}
            </Button>
          </Modal.Footer>
        </Modal>

        {loadingModal}
      </>
    );
  }
  return null;
}

TimeTrackingApproveTemporaryModal.defaultProps = {
  timeTracking: null,
};
