import React from 'react';
import { CustomerNoteEntity } from '../../../../Globals/Types/Customer';
import Panel from '../../../Panel';
import styled from 'styled-components';
import { useGetUser } from '../../../../Globals/Functions/UserFunctions';
import { formatDateTime, userDisplayName } from '../../../../Globals/Functions';
import moment from 'moment';
import AskDeleteModal from '../../../Modals/AskDeleteModal';
import { useTranslation } from 'react-i18next';
import Circle from '../../../Circle';
import { useGetNoteTypeColor } from '../../../../Globals/Functions/CustomerFunctions';

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  > .Upper {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${(props) => props.theme.border.color};
    padding-bottom: 20px;

    .Circle {
      margin-right: 20px;
    }

    .fa-user-circle {
      font-size: 50px;
      color: ${(props) => props.theme.color.textGray};
      margin-right: 20px;
    }

    .TextContainer {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;

      .Date {
        color: ${(props) => props.theme.color.textGray};
      }

      .User {
        font-weight: bold;
        font-size: 17px;
      }
    }

    .ActionContainer {
      i {
        cursor: pointer;
        padding: 10px;
        color: ${(props) => props.theme.color.textGray};

        &:hover {
          &.fa-trash-alt {
            color: ${(props) => props.theme.color.red};
          }
          &.fa-pencil {
            color: ${(props) => props.theme.color.primaryColor};
          }
        }
      }
    }
  }

  > .Lower {
    padding-top: 20px;
  }
`;

interface Props {
  note: CustomerNoteEntity;
  onEdit: (note: CustomerNoteEntity) => void;
  onDelete: (note: CustomerNoteEntity) => void;
}

/**
 * Note()
 * @param props
 * @constructor
 */
export default function Note(props: Props) {
  const { note, onEdit, onDelete } = props;
  const [t] = useTranslation();
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const getTypeColor = useGetNoteTypeColor();
  const getUser = useGetUser();

  return (
    <Panel>
      <InnerContainer>
        <div className="Upper">
          <div className="Circle">
            <Circle text={note.type.toUpperCase()} size={70} bgColor={getTypeColor(note.type)} />
          </div>
          <div className="TextContainer">
            <div className="User">{userDisplayName(getUser(note.createdUserId), true)}</div>
            <div className="Date">{formatDateTime(moment(note.updatedDateTime).toDate())}</div>
            <div className="Date">{t(`noteTypes.${note.type}`)}</div>
          </div>
          <div className="ActionContainer">
            <i className="fas fa-pencil" onClick={() => onEdit(note)} />
            <i className="fas fa-trash-alt" onClick={() => setAskDelete(true)} />
          </div>
        </div>
        <div className="Lower" dangerouslySetInnerHTML={{ __html: note.note.replaceAll('\n', '<br />') }} />
      </InnerContainer>

      <AskDeleteModal onDelete={() => onDelete(note)} onClose={() => setAskDelete(false)} visible={askDelete} />
    </Panel>
  );
}
