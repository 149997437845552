import React from 'react';
import { DamageItem, Report } from '../../../Globals/Types/Report';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import ItemContainer from './ItemContainer';
import { useTheme } from 'styled-components';

interface Props {
  report: Report;
}

/**
 * Damage()
 * @param props
 * @constructor
 */
export default function Damage(props: Props) {
  const { report } = props;
  const theme = useTheme();
  const [t] = useTranslation();

  const renderItems = () =>
    report.damage.map((preDamage) => (
      <ItemContainer<DamageItem>
        item={preDamage}
        icon={'far fa-house-chimney-crack'}
        iconColor={theme.color.red}
        caption={preDamage.caption}
        description={preDamage.additionalInformation}
        images={preDamage.images.map((item) => item.downloadUrl)}
        key={preDamage.id}
      />
    ));

  if (report.damage && report.damage.length > 0) {
    return <Panel headline={t('modules.report.summary.customerDamage')}>{renderItems()}</Panel>;
  }
  return null;
}
