import React from 'react';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import { OrderServiceType } from '../../../Globals/Types/OrderTypes';
import Input from '../../Inputs/Input';
import { Col, Row } from 'react-bootstrap';
import { ValidateOrderOptionsResult } from '../Validators/ValidateOrderOptions';
import SelectOrderServiceType from '../../PredefinedPanelSelects/SelectOrderServiceType';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';

interface Props {
  onChange: (value: OrderServiceType) => void;
  onChangeExternalId: (item: string) => void;
  onChangeBalance: (item: string) => void;
  selected: OrderServiceType;
  externalId: string;
  balance: string;
  validationResult: ValidateOrderOptionsResult;
  blackHeadline?: boolean;
}

/**
 * OrderOptions
 * @constructor
 */
export default function OrderOptions(props: Props) {
  const {
    onChange,
    onChangeExternalId,
    selected,
    externalId,
    balance,
    onChangeBalance,
    validationResult,
    blackHeadline,
  } = props;
  const [t] = useTranslation();
  const { autoExternalId } = useAppSelector((state) => state.auth.client.settings.order);

  const getAutoExternalIdIcon = () => {
    if (autoExternalId) {
      return <i className="fas fa-wand-sparkles" title={t('modules.settings.orders.autoExternalIdHint')} />;
    }
    return null;
  };

  return (
    <Panel
      headline={t('services')}
      description={t('modules.order.serviceDescription')}
      headlineDarkBlack={blackHeadline || false}
    >
      <Row>
        <Col lg={12} style={{ marginBottom: 15 }}>
          <SelectOrderServiceType selected={selected} onChange={(value) => onChange(value)} setInitial={true} />
        </Col>
        <Col lg={6}>
          <Input
            value={externalId}
            onChange={onChangeExternalId}
            label={t('commissionNumber')}
            required
            autoFocus
            hasError={validationResult ? !!validationResult.errors.externalId : false}
            errorId={validationResult ? validationResult.errors.externalId : ''}
            rightIcon={getAutoExternalIdIcon()}
          />
        </Col>
        <Col lg={6}>
          <Input
            value={balance}
            onChange={onChangeBalance}
            label={t('modules.order.balance')}
            contextHelpKey="orderBalance"
            hasError={validationResult ? !!validationResult.errors.balance : false}
            errorId={validationResult ? validationResult.errors.balance : ''}
          />
        </Col>
      </Row>
    </Panel>
  );
}
