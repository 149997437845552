/**
 * fileGetIconByExtension()
 * @param filePathOrExtension
 */
export const fileGetIconByExtension = (filePathOrExtension: string): string => {
  const extension = filePathOrExtension.split('.').pop().toLowerCase();

  switch (extension) {
    case 'jpeg':
    case 'jpg':
    case 'png':
      return 'fal fa-image';
    case 'pdf':
      return 'fal fa-file-pdf';
    case 'doc':
    case 'docx':
      return 'fal fa-file-word';
    case 'xls':
    case 'xlsx':
      return 'fal fa-file-excel';
    case 'zip':
      return 'fal fa-file-zip';
    case 'xml':
      return 'fal fa-file-xml';
    case 'csv':
      return 'fal fa-file-csv';
    case 'mov':
    case 'mp4':
      return 'fal fa-file-video';
    default:
      return 'fal fa-paperclip';
  }
};
