import React from 'react';
import PageHeader from '../../Components/PageHeader';
import { useTranslation } from 'react-i18next';
import SearchFilterButton from '../../Components/SearchFilterButton';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import ListView from '../../Components/Invoice/ListView';
import Panel from '../../Components/Panel';
import { InvoiceEntity } from '../../Globals/Types/Invoice';
import Text from '../../Components/Text';
import { useDispatchInvoiceGetList } from '../../Redux/Actions/Client/InvoiceAction';

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const [t] = useTranslation();
  const { open, all } = useAppSelector((state) => state.client.invoices);
  const [filtered, setFiltered] = React.useState<InvoiceEntity[]>(open || []);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [headline, setHeadline] = React.useState<string>(t('openInvoices'));
  const dispatchGetOpenInvoices = useDispatchInvoiceGetList();

  React.useEffect(() => {
    if (!all || all.length === 0) {
      dispatchGetOpenInvoices().then(() => {});
    }
  }, [all, dispatchGetOpenInvoices]);

  React.useEffect(() => {
    if (open) {
      setFiltered(open);
    }
  }, [open]);

  const filter = React.useCallback(() => {
    const data = all.filter((invoice) => {
      return (
        invoice.invoiceNumber.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
        invoice.address.firstName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
        invoice.address.lastName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
      );
    });
    setFiltered(data);
    setHeadline(t('invoices'));
  }, [all, searchValue, t]);

  const handleClear = React.useCallback(() => {
    setFiltered(open);
    setHeadline(t('openInvoices'));
  }, [open, t]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (searchValue.length >= 2) {
      timer = setTimeout(() => filter(), 300);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [filter, searchValue]);

  const renderContent = () => {
    if (searchValue && searchValue.length > 0 && filtered.length === 0) {
      return (
        <Text centered light>
          {t('invoicesFilterNoFoundHint', { value: searchValue })}
        </Text>
      );
    }
    if ((!searchValue || searchValue.length === 0) && filtered.length === 0) {
      return (
        <Text centered light>
          {t('invoicesNoOpenHint')}
        </Text>
      );
    }

    return <ListView invoices={filtered} showCustomerName />;
  };

  return (
    <>
      <PageHeader
        containerStyle={{ paddingLeft: 40 }}
        headline={headline}
        actionButtonOne={<SearchFilterButton onChange={setSearchValue} onClear={handleClear} />}
        withoutPadding
      />

      <div>
        <Panel>{renderContent()}</Panel>
      </div>
    </>
  );
}
