import moment from 'moment';
import { CustomerEntity } from '../../../Globals/Types/Customer';
import { OfferEntity, OfferPositionEntity } from '../../../Globals/Types/OfferTypes';

export type InvoiceReducerState = {
  offer: OfferEntity;
  initialized: boolean;
  isEdit: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'clear' }
  | { type: 'initEdit'; payload: OfferEntity }
  | { type: 'init'; payload: { clientId: string; facilityId: string; customerId: string; userId: string } }
  | { type: 'offerNumber'; payload: string }
  | { type: 'customerId'; payload: string }
  | { type: 'contactUserId'; payload: string }
  | { type: 'facilityId'; payload: string }
  | { type: 'clientId'; payload: string }
  | { type: 'validUntil'; payload: string }
  | { type: 'additionalFooterText'; payload: string }
  | { type: 'internalInformation'; payload: string }
  | { type: 'positions'; payload: OfferPositionEntity[] }
  | { type: 'initCustomer'; payload: CustomerEntity };

export const initialState: InvoiceReducerState = {
  offer: {
    offerId: null,
    offerNumber: moment().format('HHmm-MMYYYY'),
    approved: false,
    contactUserId: null,
    validUntil: moment().add(30, 'd').format('YYYY-MM-DD'),
    clientId: null,
    positions: [],
    priceOverall: '0',
    tax: '0',
    createdDate: moment().add(7, 'd').format('YYYY-MM-DD'),
    createdUserId: null,
    facilityId: null,
    customerId: null,
    firebaseCreatedDateTime: null,
    approvedDate: null,
  },
  initialized: false,
  isEdit: false,
  hasChanges: false,
};

/**
 * calculateTax()
 * @param positions
 */
const calculateTax = (positions: OfferPositionEntity[]): string => {
  let taxAmount = 0.0;

  positions.forEach((position) => {
    if (position.tax && parseFloat(position.tax) > 0) {
      taxAmount = taxAmount + (position.tax ? parseFloat(position.tax) : 0.0);
    }
  });
  return taxAmount.toFixed(2);
};

/**
 * calculateOverallPrice()
 * @param positions
 */
const calculateOverallPrice = (positions: OfferPositionEntity[]): string => {
  let price: number = 0;

  positions.forEach((position) => {
    if (position.overallPrice && parseFloat(position.overallPrice) > 0) {
      const formattedOverallPrice = position.overallPrice.replace(',', '.');
      if (parseFloat(formattedOverallPrice) > 0) {
        price = price + parseFloat(formattedOverallPrice);
      }
    }
  });

  return price.toFixed(2);
};

/**
 * reducer()
 * @param state
 * @param action
 */
export const reducer = (state: InvoiceReducerState, action: Action): InvoiceReducerState => {
  switch (action.type) {
    case 'facilityId':
      return { ...state, offer: { ...state.offer, facilityId: action.payload } };
    case 'validUntil':
      return { ...state, offer: { ...state.offer, validUntil: action.payload }, hasChanges: true };
    case 'contactUserId':
      return { ...state, offer: { ...state.offer, contactUserId: action.payload }, hasChanges: true };
    case 'offerNumber':
      return { ...state, offer: { ...state.offer, offerNumber: action.payload }, hasChanges: true };
    case 'additionalFooterText':
      return { ...state, offer: { ...state.offer, additionalFooterText: action.payload }, hasChanges: true };
    case 'internalInformation':
      return { ...state, offer: { ...state.offer, internalInformation: action.payload }, hasChanges: true };
    case 'positions': {
      const overallPrice = calculateOverallPrice(action.payload);

      return {
        ...state,
        offer: {
          ...state.offer,
          positions: action.payload,
          priceOverall: overallPrice,
          tax: calculateTax(action.payload),
        },
        hasChanges: true,
      };
    }
    case 'initEdit':
      return { ...initialState, offer: { ...action.payload }, isEdit: true, initialized: true };
    case 'init':
      return {
        ...state,
        offer: {
          ...state.offer,
          clientId: action.payload.clientId,
          facilityId: action.payload.facilityId,
          customerId: action.payload.customerId,
          createdUserId: action.payload.userId,
          contactUserId: action.payload.userId,
        },
        initialized: true,
      };
    case 'clear':
      return { ...state };

    default:
      throw new Error('Action unknown!');
  }
};
