import React from 'react';
import styled from 'styled-components';
import Item from './Item';
import ImagePreview from '../ImagePreview';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

interface Props {
  imageViewSize?: number;
  images: string[];
}

/**
 * ImageList()
 * @param props
 * @constructor
 */
export default function ImageList(props: Props) {
  const { images, imageViewSize } = props;
  const [show, setShow] = React.useState<boolean>(false);
  const [clickedImage, setClickedImage] = React.useState<string>(null);

  const handleClickedImage = (data: string) => {
    setClickedImage(data);
    setShow(true);
  };

  const renderImages = () =>
    images.map((image) => <Item image={image} size={imageViewSize} key={image} onClick={handleClickedImage} />);

  if (images && images.length > 0) {
    return (
      <Container>
        {renderImages()}
        <ImagePreview images={images} visible={show} onClose={() => setShow(false)} selectedImage={clickedImage} />
      </Container>
    );
  }
  return null;
}

ImageList.defaultProps = {
  imageViewSize: 200,
};
