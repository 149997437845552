import React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseFunctionNames } from '../../Globals/FirebaseGlobals';
import { LinkingEntity, LinkingPayloadPublicReport, LinkingType } from '../../Globals/Types/Linking';
import { AxiosError } from 'axios';

/**
 * useDispatchLinkingValidatePin()
 */
type ValidateLinkingPinType = (linkingId: string, pin: number) => Promise<LinkingEntity<LinkingPayloadPublicReport>>;
export const useDispatchLinkingValidatePin = (): ValidateLinkingPinType => {
  return React.useCallback<ValidateLinkingPinType>((linkingId, pin) => {
    const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.linkingGet);

    return callable({ linkingId, pin })
      .then((response) => {
        return Promise.resolve(response.data as LinkingEntity<any>);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }, []);
};

/**
 * useDispatchLinkingCreate()
 */
type LinkingCreateType = (
  payload: LinkingPayloadPublicReport,
  type: LinkingType,
  validDays: number,
) => Promise<{ url: string; pin: number }>;
export const useDispatchLinkingCreate = (): LinkingCreateType => {
  return React.useCallback<LinkingCreateType>((payload, type, validDays) => {
    const callable = httpsCallable(getFunctions(), FirebaseFunctionNames.linkingCreate);

    return callable({ payload, type, validDays })
      .then((response) => {
        return Promise.resolve(response.data as any);
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error.response.data);
      });
  }, []);
};
