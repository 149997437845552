import React, { useEffect } from 'react';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import Text from '../../Components/Text';
import { useNavigate } from 'react-router';
import { buildUrlInboxDetails } from '../../Globals/UrlFunctions';
import { useTranslation } from 'react-i18next';

/**
 * Inbox
 * @constructor
 */
export default function Inbox() {
  const { messages } = useAppSelector((state) => state.user.inbox);
  const navigate = useNavigate();
  const [t] = useTranslation();

  useEffect(() => {
    if (messages && messages.length > 0) {
      const lastMessage = messages[0];
      navigate(buildUrlInboxDetails(lastMessage.messageId));
    }
  }, [messages, navigate]);

  return (
    <div style={{ marginTop: 40 }}>
      {t('inboxNoMessageSelected')
        .split('\n')
        .map((text) => (
          <Text light centered key={text}>
            {text}
          </Text>
        ))}
    </div>
  );
}
