import React from 'react';
import Panel from '../../Panel';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import { useDispatchOrderFilterSet } from '../../../Redux/Actions/Order/FilterAction';
import ToggleButton from '../../ToggleButton';
import { ServiceFilter as ServiceFilterType } from '../../../Globals/Types/Types';
import { useGetServiceTypeIcon } from '../../../Globals/Hooks/ServiceHooks';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: -10px;
`;

const BlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

/**
 * ServiceFilter
 * @constructor
 */
export default function ServiceFilter() {
  const [t] = useTranslation();
  const { serviceFilter } = useAppSelector((state) => state.order);
  const { serviceTypeDefinition } = useAppSelector((state) => state.auth.client.settings.order);
  const dispatchFilterSet = useDispatchOrderFilterSet(true);
  const getServiceTypeIcon = useGetServiceTypeIcon();

  const toggleServices = (serviceTypeKey: string) => {
    let mergedFilter: ServiceFilterType;
    if (serviceFilter.serviceTypes.indexOf(serviceTypeKey) > -1) {
      mergedFilter = {
        ...serviceFilter,
        serviceTypes: serviceFilter.serviceTypes.filter((item) => item !== serviceTypeKey),
      };
    } else {
      mergedFilter = { ...serviceFilter, serviceTypes: [...serviceFilter.serviceTypes, serviceTypeKey] };
    }

    dispatchFilterSet(mergedFilter);
  };

  const toggleHideUnterminated = () => {
    dispatchFilterSet({
      ...serviceFilter,
      hideUnscheduled: !serviceFilter.hideUnscheduled,
      hideScheduled: !serviceFilter.hideUnscheduled === true ? false : serviceFilter.hideScheduled,
    });
  };

  const toggleHideScheduled = () => {
    dispatchFilterSet({
      ...serviceFilter,
      hideScheduled: !serviceFilter.hideScheduled,
      hideUnscheduled: !serviceFilter.hideScheduled === true ? false : serviceFilter.hideUnscheduled,
    });
  };

  const toggleFinishedOnly = () => {
    dispatchFilterSet({
      ...serviceFilter,
      finishedOnly: !serviceFilter.finishedOnly,
      unsuccessfulOnly: false,
      openOnly: false,
    });
  };

  const toggleUnsuccessfulOnly = () => {
    dispatchFilterSet({
      ...serviceFilter,
      unsuccessfulOnly: !serviceFilter.unsuccessfulOnly,
      finishedOnly: false,
      openOnly: false,
    });
  };

  const toggleOpenOnly = () => {
    dispatchFilterSet({
      ...serviceFilter,
      openOnly: !serviceFilter.openOnly,
      finishedOnly: false,
      unsuccessfulOnly: false,
    });
  };

  return (
    <Panel>
      <Container>
        <BlockContainer>
          {serviceTypeDefinition.map((item) => (
            <ToggleButton
              caption={item.caption}
              onClick={() => toggleServices(item.key)}
              active={serviceFilter.serviceTypes.indexOf(item.key) > -1}
              icon={getServiceTypeIcon(item)}
              key={item.key}
            />
          ))}
          <ToggleButton
            onClick={toggleHideUnterminated}
            caption={t('hideUnscheduled')}
            icon="fas fa-calendar-circle-minus"
            active={serviceFilter.hideUnscheduled}
          />
          <ToggleButton
            onClick={toggleHideScheduled}
            caption={t('hideScheduled')}
            icon="fas fa-calendar-circle-plus"
            active={serviceFilter.hideScheduled}
          />
          <ToggleButton
            onClick={toggleFinishedOnly}
            caption={t('finishedOnly')}
            icon="fas fa-check-circle"
            active={serviceFilter.finishedOnly}
          />
          <ToggleButton
            onClick={toggleUnsuccessfulOnly}
            caption={t('unsuccessfulOnly')}
            icon="fas fa-octagon-exclamation"
            active={serviceFilter.unsuccessfulOnly}
          />
          <ToggleButton
            onClick={toggleOpenOnly}
            caption={t('openOnly')}
            icon="fas fa-folder-open"
            active={serviceFilter.openOnly}
          />
        </BlockContainer>
      </Container>
    </Panel>
  );
}
