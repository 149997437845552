import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Panel from '../../../Components/Panel';
import FacilitiesForm from '../../../Components/FacilitiesForm';
import { useTranslation } from 'react-i18next';
import Input, { InputType } from '../../../Components/Inputs/Input';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { Tour } from '../../../Globals/Types/Tour';
import { FacilityEntity } from '../../../Globals/Types/Types';
import { updateFacilityIdsArray } from '../../../Globals/Functions';
import validate from './ValidateUpdate';
import { useDispatchTourUpdate } from '../../../Redux/Actions/Disposition/TourActions';
import styled from 'styled-components';
import Text from '../../../Components/Text';

const Container = styled.div`
  .Title {
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      margin-right: 8px;
    }
  }
`;

export type ValidateErrors = { [K in keyof Tour]?: string };

type State = {
  errors: ValidateErrors;
  tour: Tour;
};

type Action =
  | { type: 'clear' }
  | { type: 'comment'; payload: string }
  | { type: 'errors'; payload: ValidateErrors }
  | { type: 'facilityIds'; payload: string[] }
  | { type: 'init'; payload: Tour }
  | { type: 'name'; payload: string };

const initialState: State = {
  errors: null,
  tour: null,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'clear':
      return { ...initialState };
    case 'comment':
      return { ...state, tour: { ...state.tour, comment: action.payload }, errors: { ...state.errors, comment: null } };
    case 'errors':
      return { ...state, errors: action.payload };
    case 'facilityIds':
      return {
        ...state,
        tour: { ...state.tour, facilityIds: action.payload },
        errors: { ...state.errors, facilityIds: null },
      };
    case 'init':
      return { ...state, tour: action.payload, errors: null };
    case 'name':
      return { ...state, tour: { ...state.tour, name: action.payload }, errors: { ...state.errors, name: null } };
  }
};

interface Props {
  onClose: () => void;
  tour: Tour;
  visible: boolean;
}

/**
 * UpdateTourModal
 * @param props
 * @constructor
 */
export default function UpdateTourModal(props: Props) {
  const { onClose, tour, visible } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [t] = useTranslation();
  const { isLoading, loadingModal, setLoading } = useLoadingModal({
    headline: t('modules.disposition.update.loading'),
  });

  const dispatchTourUpdate = useDispatchTourUpdate();

  React.useEffect(() => {
    if (!state.tour) {
      dispatch({ type: 'init', payload: tour });
    }
  }, [state.tour, tour]);

  const handleClose = () => {
    dispatch({ type: 'clear' });
    setLoading(false);
    onClose();
  };

  const handleSave = () => {
    const { errors, isValid } = validate(state.tour);
    if (!isValid) {
      return dispatch({ type: 'errors', payload: errors });
    }

    setLoading(true);
    dispatchTourUpdate(state.tour)
      .then(handleClose)
      .catch(() => setLoading(false));
  };

  const onChangeFacility = (value: boolean, facility: FacilityEntity) => {
    dispatch({
      type: 'facilityIds',
      payload: updateFacilityIdsArray(value, facility, state.tour.facilityIds),
    });
  };

  if (state.tour) {
    return (
      <Modal show={visible} size="xl">
        <Container>
          <Modal.Header>
            <div>
              <Modal.Title>
                <div className="Title">
                  <i className="fas fa-road" />
                  <div>{t('modules.disposition.update.headline')}</div>
                </div>
              </Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body style={{ padding: 30, paddingBottom: 10 }}>
            <Panel headline={t('basicData')}>
              <Input
                label={t('name')}
                value={state.tour.name}
                onChange={(value) => dispatch({ type: 'name', payload: value })}
                hasError={!!state.errors?.name}
                disabled={isLoading}
                required
                autoFocus
              />
              <Input
                label={t('description')}
                value={state.tour.comment}
                onChange={(value) => dispatch({ type: 'comment', payload: value })}
                hasError={!!state.errors?.comment}
                disabled={isLoading}
                type={InputType.textarea}
                rows={5}
              />
            </Panel>

            <FacilitiesForm
              userDefaultFacility={null}
              onChange={onChangeFacility}
              description={t('modules.disposition.create.descriptionFacilities')}
              userFacilities={state.tour.facilityIds}
              hasError={!!state.errors?.facilityIds}
            />
          </Modal.Body>
          <Modal.Footer>
            <div style={{ flexGrow: 1, marginLeft: 20 }}>
              <Text small light>
                {tour ? tour.tourId : ''}
              </Text>
            </div>
            <Button variant="success" onClick={handleSave} disabled={isLoading}>
              {t('save')}
            </Button>
            <Button variant="outline-secondary" onClick={handleClose} disabled={isLoading}>
              {t('cancel')}
            </Button>
          </Modal.Footer>

          {loadingModal}
        </Container>
      </Modal>
    );
  }

  return null;
}
