import { DefaultValidationResult, ResourceEntity } from '../../../Globals/Types/Types';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { CreateOrUpdateError } from './CreateOrUpdate';

/**
 * isValid()
 * @param resource
 * @param facilityRequired
 */
const isValid = (resource: ResourceEntity, facilityRequired: boolean): DefaultValidationResult<CreateOrUpdateError> => {
  let errors: CreateOrUpdateError = {} as CreateOrUpdateError;

  if (!resource.type || resource.type.length === 0) {
    errors = { ...errors, type: InternalErrorCodes.FIELD_MISSING };
  }
  if (!resource.name || resource.name.length === 0) {
    errors = { ...errors, name: InternalErrorCodes.FIELD_MISSING };
  }
  if (facilityRequired && (!resource.facilityIds || resource.facilityIds.length === 0)) {
    errors = { ...errors, facilityIds: InternalErrorCodes.FIELD_MISSING };
  }

  return { isValid: Object.keys(errors).length === 0, errors };
};

export default isValid;
