import React from 'react';
import { useDispatch } from 'react-redux';
import { FirebaseFunctionNames, FirebasePathMappings } from '../../../Globals/FirebaseGlobals';
import {
  addDoc,
  collection,
  CollectionReference,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  doc,
} from 'firebase/firestore';
import {
  CLIENT_INVOICE_CREATE_ERROR,
  CLIENT_INVOICE_CREATE_START,
  CLIENT_INVOICE_CREATE_SUCCESS,
  CLIENT_INVOICE_DOWNLOAD_ERROR,
  CLIENT_INVOICE_DOWNLOAD_START,
  CLIENT_INVOICE_DOWNLOAD_SUCCESS,
  CLIENT_INVOICE_GET_ERROR,
  CLIENT_INVOICE_GET_START,
  CLIENT_INVOICE_GET_SUCCESS,
  CLIENT_INVOICE_GETLIST_ERROR,
  CLIENT_INVOICE_GETLIST_START,
  CLIENT_INVOICE_GETLIST_SUCCESS,
  CLIENT_INVOICE_SEND_MAIL_ERROR,
  CLIENT_INVOICE_SEND_MAIL_START,
  CLIENT_INVOICE_SEND_MAIL_SUCCESS,
  CLIENT_INVOICE_UPDATE_ERROR,
  CLIENT_INVOICE_UPDATE_START,
  CLIENT_INVOICE_UPDATE_SUCCESS,
} from '../../ActionTypes';
import { InvoiceEntity, InvoiceMailTrackingEntity } from '../../../Globals/Types/Invoice';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import {
  useDispatchFacilityUpdateLastInvoiceNumber,
  useDispatchFacilityUpdateLastVoucherNumber,
} from './FacilityAction';
import { useAppDispatch, useAppSelector } from '../../../Globals/Hooks/Hooks';
import { FacilityEntity } from '../../../Globals/Types/Types';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { CountryCode } from '../../../Globals/Types/Enums';
import moment from 'moment';

/**
 * buildCollection()
 * @param clientId
 */
const buildCollection = (clientId: string): CollectionReference<InvoiceEntity> => {
  return collection(
    getFirestore(),
    FirebasePathMappings.client,
    clientId,
    FirebasePathMappings.invoice,
  ) as CollectionReference<InvoiceEntity>;
};

type GetListReturnType = () => Promise<InvoiceEntity[]>;
/**
 * useDispatchInvoiceGetList()
 */
export const useDispatchInvoiceGetList = (): GetListReturnType => {
  const dispatch = useDispatch();
  const { clientId, user } = useAppSelector((state) => state.auth);

  return React.useCallback<GetListReturnType>(() => {
    dispatch({ type: CLIENT_INVOICE_GETLIST_START });

    const invoiceCollection = buildCollection(clientId);
    const queryRef = query(invoiceCollection, orderBy('firebaseCreatedDateTime'));

    return getDocs(queryRef)
      .then((response) => {
        const invoices: InvoiceEntity[] = [];
        response.forEach((invoice) => {
          const invoiceData: InvoiceEntity = { ...invoice.data(), invoiceId: invoice.id };

          if (invoiceData.accessCode > 0) {
            if (user.accessCode >= invoiceData.accessCode) {
              invoices.push(invoiceData);
            }
          } else {
            invoices.push(invoiceData);
          }
        });
        dispatch({ type: CLIENT_INVOICE_GETLIST_SUCCESS, payload: { invoices, userId: user.userId } });
        return Promise.resolve(invoices);
      })
      .catch((error) => {
        dispatch({ type: CLIENT_INVOICE_GETLIST_ERROR, payload: error });
        return Promise.reject(error);
      });
  }, [clientId, dispatch, user]);
};

type UpdateReturnType = (invoice: InvoiceEntity) => Promise<InvoiceEntity>;
/**
 * useDispatchInvoiceUpdate()
 */
export const useDispatchInvoiceUpdate = (): UpdateReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<UpdateReturnType>(
    (invoice) => {
      dispatch({ type: CLIENT_INVOICE_UPDATE_START, payload: invoice });

      const invoiceCollection = buildCollection(clientId);
      const invoiceDocument = doc(invoiceCollection, invoice.invoiceId);

      return setDoc(invoiceDocument, invoice, { merge: true })
        .then(() => {
          dispatch({ type: CLIENT_INVOICE_UPDATE_SUCCESS, payload: invoice });
          return Promise.resolve(invoice);
        })
        .catch((error) => {
          dispatch({ type: CLIENT_INVOICE_UPDATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type GetReturnType = (invoiceId: string) => Promise<InvoiceEntity>;
/**
 * useDispatchInvoiceGet()
 */
export const useDispatchInvoiceGet = (): GetReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<GetReturnType>(
    (invoiceId) => {
      dispatch({ type: CLIENT_INVOICE_GET_START, payload: invoiceId });

      const invoiceCollection = buildCollection(clientId);
      const invoiceDocument = doc(invoiceCollection, invoiceId);

      return getDoc(invoiceDocument)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const invoice: InvoiceEntity = { ...snapshot.data(), invoiceId: snapshot.id };
            dispatch({ type: CLIENT_INVOICE_GET_SUCCESS, payload: invoice });
            return Promise.resolve(invoice);
          }
          return Promise.reject(new Error(InternalErrorCodes.FIRESTORE_DOCUMENT_NOT_FOUND));
        })
        .catch((error) => {
          dispatch({ type: CLIENT_INVOICE_GET_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type CreateReturnType = (invoice: InvoiceEntity, invoiceNumber: number) => Promise<InvoiceEntity>;
/**
 * useDispatchInvoiceCreate()
 */
export const useDispatchInvoiceCreate = (): CreateReturnType => {
  const dispatch = useDispatch();
  const { clientId, userId } = useAppSelector((state) => state.auth);
  const dispatchUpdateLastInvoiceNumber = useDispatchFacilityUpdateLastInvoiceNumber();
  const dispatchUpdateLastVoucherNumber = useDispatchFacilityUpdateLastVoucherNumber();

  return React.useCallback<CreateReturnType>(
    (invoice, invoiceNumber) => {
      dispatch({ type: CLIENT_INVOICE_CREATE_START, payload: { invoice, invoiceNumber } });

      const preparedInvoice: InvoiceEntity = {
        ...invoice,
        clientId,
        firebaseCreatedDateTime: serverTimestamp(),
        createdUserId: userId,
      };
      const invoiceCollection = buildCollection(clientId);

      return addDoc(invoiceCollection, preparedInvoice)
        .then((response) => {
          return setDoc(response, { invoiceId: response.id }, { merge: true }).then(() => {
            return getDoc(response).then((getResponse) => {
              if (getResponse.exists()) {
                const invoiceResponse: InvoiceEntity = getResponse.data();
                let promise: Promise<FacilityEntity>;

                if (parseFloat(invoiceResponse.priceOverall) < 0) {
                  promise = dispatchUpdateLastVoucherNumber(invoiceResponse.facilityId, invoiceNumber);
                } else {
                  promise = dispatchUpdateLastInvoiceNumber(invoiceResponse.facilityId, invoiceNumber);
                }

                return promise.then(() => {
                  dispatch({ type: CLIENT_INVOICE_CREATE_SUCCESS, payload: invoiceResponse });
                  return Promise.resolve(invoiceResponse);
                });
              }
              return Promise.reject(new Error(InternalErrorCodes.FIRESTORE_DOCUMENT_NOT_FOUND));
            });
          });
        })
        .catch((error) => {
          dispatch({ type: CLIENT_INVOICE_CREATE_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, dispatchUpdateLastInvoiceNumber, dispatchUpdateLastVoucherNumber, userId],
  );
};

type DownloadReturnType = (invoiceId: string, language: CountryCode) => Promise<string>;
/**
 *
 */
export const useDispatchInvoiceDownload = (): DownloadReturnType => {
  const dispatch = useDispatch();
  const { clientId } = useAppSelector((state) => state.auth);

  return React.useCallback<DownloadReturnType>(
    (invoiceId, language) => {
      dispatch({ type: CLIENT_INVOICE_DOWNLOAD_START, payload: invoiceId });

      const callback = httpsCallable<{ clientId: string; invoiceId: string; language: CountryCode }, string>(
        getFunctions(),
        FirebaseFunctionNames.invoiceCreatePdf,
      );
      return callback({ clientId, invoiceId, language })
        .then((response) => {
          dispatch({ type: CLIENT_INVOICE_DOWNLOAD_SUCCESS, payload: response.data });
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch({ type: CLIENT_INVOICE_DOWNLOAD_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch],
  );
};

type SendMailReturnType = (invoiceId: string, receiverMail: string, language: CountryCode) => Promise<InvoiceEntity>;
/**
 * useDispatchInvoiceSendMail()
 */
export const useDispatchInvoiceSendMail = (): SendMailReturnType => {
  const dispatch = useAppDispatch();
  const { clientId } = useAppSelector((state) => state.auth);
  const dispatchGetInvoice = useDispatchInvoiceGet();
  const dispatchUpdateInvoice = useDispatchInvoiceUpdate();

  return React.useCallback<SendMailReturnType>(
    (invoiceId, receiverMail, language) => {
      dispatch({
        type: CLIENT_INVOICE_SEND_MAIL_START,
        payload: { clientId, invoiceId, receiverMail, language },
      });

      const callable = httpsCallable<{
        clientId: string;
        language: CountryCode;
        invoiceId: string;
        receiverMail: string;
      }>(getFunctions(), FirebaseFunctionNames.invoiceSendMail);

      return callable({ clientId, language, receiverMail, invoiceId })
        .then(() => {
          return dispatchGetInvoice(invoiceId).then((invoice) => {
            const newTracking: InvoiceMailTrackingEntity = {
              mail: receiverMail,
              date: moment().format('YYYY-MM-DD HH:mm:ss'),
            };

            const merged: InvoiceEntity = {
              ...invoice,
              mailTracking: invoice.mailTracking ? [...invoice.mailTracking, newTracking] : [newTracking],
            };

            return dispatchUpdateInvoice(merged).then((updateResponse) => {
              dispatch({ type: CLIENT_INVOICE_SEND_MAIL_SUCCESS, payload: updateResponse });
              return Promise.resolve(updateResponse);
            });
          });
        })
        .catch((error) => {
          dispatch({ type: CLIENT_INVOICE_SEND_MAIL_ERROR, payload: error });
          return Promise.reject(error);
        });
    },
    [clientId, dispatch, dispatchGetInvoice, dispatchUpdateInvoice],
  );
};
