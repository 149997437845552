import React from 'react';
import { useTranslation } from 'react-i18next';
import Lodash from 'lodash';
import Select, { SelectOptions } from '../Inputs/Select';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { ResourceEntity } from '../../Globals/Types/Types';
import { useDispatchResourceGetList } from '../../Redux/Actions/Client/ResourceAction';

interface Props {
  disabled?: boolean;
  hasError?: boolean;
  initialValue?: ResourceEntity[];
  label?: string;
  multiSelect?: boolean;
  onChange: (value: ResourceEntity[]) => void;
  required?: boolean;
}

/**
 * SelectResource
 * @param props
 * @constructor
 */
export default function SelectResource(props: Props) {
  const { disabled, hasError, initialValue, label, multiSelect, onChange, required } = props;
  const { resources } = useAppSelector((state) => state.client);
  const [initial, setInitial] = React.useState<string[]>(null);
  const [options, setOptions] = React.useState<SelectOptions>(null);
  const [t] = useTranslation();

  const dispatchResourceGetList = useDispatchResourceGetList();

  React.useEffect(() => {
    if (!resources || resources.length === 0) {
      dispatchResourceGetList().then(() => null);
    }
  }, [dispatchResourceGetList, resources]);

  React.useEffect(() => {
    const initialIds: string[] = initialValue?.map((resource) => resource.resourceId) || [];
    setInitial(initialIds);
  }, [initialValue]);

  React.useEffect(() => {
    if (resources && resources.length !== 0) {
      const optionsTemp: SelectOptions = resources?.map((resource) => ({
        value: resource.resourceId,
        label: resource.name,
      }));

      setOptions(optionsTemp);
    }
  }, [resources]);

  const handleChange = (values: string[]) => {
    const found: ResourceEntity[] = [];
    values.forEach((value) => {
      found.push(Lodash.find(resources, (resource) => resource.resourceId === value));
    });

    onChange(found);
  };

  if (initial && options) {
    return (
      <Select
        disabled={disabled}
        hasError={hasError}
        initialValue={initial}
        label={label || t('resource')}
        multiSelect={multiSelect}
        onChange={handleChange}
        options={options}
        required={required}
      />
    );
  }

  return null;
}

SelectResource.defaultProps = {
  multiSelect: true,
};
