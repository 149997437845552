import React from 'react';
import { PositionEntity } from '../../../Globals/Types/OrderTypes';
import { useTranslation } from 'react-i18next';

interface Props {
  item: PositionEntity;
}

/**
 * PositionAssemblyTimeCol
 * @constructor
 */
export default function PositionAssemblyTimeCol(props: Props) {
  const { item } = props;
  const [t] = useTranslation();

  const renderAssemblyTime = () => {
    if (item.assemblyTime && item.assemblyTime > 0) {
      return t('xMinute', { count: item.assemblyTime });
    }
    return null;
  };

  return <td key={`${item.positionId}-assemblyTime`}>{renderAssemblyTime()}</td>;
}
