import { CountryCode, PaymentType, Salutation } from '../../../Globals/Types/Enums';
import { CustomerEntity } from '../../../Globals/Types/Customer';
import { isValidDateString } from '../../../Globals/Functions';
import moment from 'moment';

export type CustomerCreateOrUpdateError = { [K in keyof CustomerEntity]?: string };

export interface CreateOrUpdateCustomerState {
  customer: CustomerEntity;
  birthdateHelper: string;
  hasChanges: boolean;
  errors: CustomerCreateOrUpdateError;
  isEdit: boolean;
}

type Action =
  | { type: 'salutation'; payload: Salutation }
  | { type: 'prefix'; payload: string }
  | { type: 'firstName'; payload: string }
  | { type: 'lastName'; payload: string }
  | { type: 'companyName'; payload: string }
  | { type: 'taxNumber'; payload: string }
  | { type: 'jobTitle'; payload: string }
  | { type: 'defaultFacilityId'; payload: string }
  | { type: 'birthdate'; payload: string }
  | { type: 'street'; payload: string }
  | { type: 'streetNo'; payload: string }
  | { type: 'zip'; payload: string }
  | { type: 'city'; payload: string }
  | { type: 'floor'; payload: number }
  | { type: 'country'; payload: CountryCode }
  | { type: 'mailPrivate'; payload: string }
  | { type: 'mailBusiness'; payload: string }
  | { type: 'phonePrivate'; payload: string }
  | { type: 'phoneBusiness'; payload: string }
  | { type: 'mobilePrivate'; payload: string }
  | { type: 'mobileBusiness'; payload: string }
  | { type: 'whatsApp'; payload: string }
  | { type: 'facebook'; payload: string }
  | { type: 'instagram'; payload: string }
  | { type: 'tikTok'; payload: string }
  | { type: 'skype'; payload: string }
  | { type: 'twitter'; payload: string }
  | { type: 'xing'; payload: string }
  | { type: 'linkedIn'; payload: string }
  | { type: 'website'; payload: string }
  | { type: 'contactName'; payload: string }
  | { type: 'bankIban'; payload: string }
  | { type: 'bankBic'; payload: string }
  | { type: 'bankName'; payload: string }
  | { type: 'defaultPaymentType'; payload: PaymentType }
  | { type: 'comment'; payload: string }
  | { type: 'init'; payload: CustomerEntity }
  | { type: 'initEmpty'; payload: string }
  | { type: 'setErrors'; payload: CustomerCreateOrUpdateError };

export const initialState: CreateOrUpdateCustomerState = {
  customer: {
    customerId: null,
    defaultFacilityId: null,
    salutation: Salutation.male,
    country: CountryCode.DE,
    createdDateTime: null,
    createdUserId: null,
    lastUpdatedDateTime: null,
    lastUpdatedUserId: null,
    floor: 0,
  },
  birthdateHelper: null,
  errors: {},
  isEdit: false,
  hasChanges: false,
};

export const reducer = (state: CreateOrUpdateCustomerState, action: Action): CreateOrUpdateCustomerState => {
  switch (action.type) {
    case 'salutation':
      return { ...state, customer: { ...state.customer, salutation: action.payload }, hasChanges: true };
    case 'prefix':
      return {
        ...state,
        customer: { ...state.customer, prefix: action.payload },
        hasChanges: true,
      };
    case 'firstName':
      return {
        ...state,
        customer: {
          ...state.customer,
          firstName: action.payload,
          companyName: null,
        },
        errors: { ...state.errors, firstName: null },
        hasChanges: true,
      };
    case 'lastName':
      return {
        ...state,
        customer: { ...state.customer, lastName: action.payload, companyName: null },
        errors: { ...state.errors, lastName: null },
        hasChanges: true,
      };
    case 'companyName':
      return {
        ...state,
        customer: {
          ...state.customer,
          companyName: action.payload,
          lastName: action.payload,
          firstName: null,
        },
        errors: { ...state.errors, companyName: null },
        hasChanges: true,
      };
    case 'taxNumber':
      return { ...state, customer: { ...state.customer, taxNumber: action.payload }, hasChanges: true };
    case 'jobTitle':
      return { ...state, customer: { ...state.customer, jobTitle: action.payload }, hasChanges: true };
    case 'defaultFacilityId':
      return { ...state, customer: { ...state.customer, defaultFacilityId: action.payload }, hasChanges: true };
    case 'street':
      return { ...state, customer: { ...state.customer, street: action.payload }, hasChanges: true };
    case 'streetNo':
      return { ...state, customer: { ...state.customer, streetNo: action.payload }, hasChanges: true };
    case 'zip':
      return { ...state, customer: { ...state.customer, zip: action.payload }, hasChanges: true };
    case 'city':
      return { ...state, customer: { ...state.customer, city: action.payload }, hasChanges: true };
    case 'floor':
      return { ...state, customer: { ...state.customer, floor: action.payload }, hasChanges: true };
    case 'country':
      return { ...state, customer: { ...state.customer, country: action.payload }, hasChanges: true };
    case 'mailPrivate':
      return {
        ...state,
        customer: { ...state.customer, mailPrivate: action.payload },
        errors: { ...state.errors, mailPrivate: null },
        hasChanges: true,
      };
    case 'mailBusiness':
      return {
        ...state,
        customer: { ...state.customer, mailBusiness: action.payload },
        errors: { ...state.errors, mailBusiness: null },
        hasChanges: true,
      };
    case 'phonePrivate':
      return { ...state, customer: { ...state.customer, phonePrivate: action.payload }, hasChanges: true };
    case 'phoneBusiness':
      return { ...state, customer: { ...state.customer, phoneBusiness: action.payload }, hasChanges: true };
    case 'mobilePrivate':
      return { ...state, customer: { ...state.customer, mobilePrivate: action.payload }, hasChanges: true };
    case 'mobileBusiness':
      return { ...state, customer: { ...state.customer, mobileBusiness: action.payload }, hasChanges: true };
    case 'whatsApp':
      return { ...state, customer: { ...state.customer, whatsApp: action.payload }, hasChanges: true };
    case 'facebook':
      return { ...state, customer: { ...state.customer, facebook: action.payload }, hasChanges: true };
    case 'instagram':
      return { ...state, customer: { ...state.customer, instagram: action.payload }, hasChanges: true };
    case 'tikTok':
      return { ...state, customer: { ...state.customer, tikTok: action.payload }, hasChanges: true };
    case 'skype':
      return { ...state, customer: { ...state.customer, skype: action.payload }, hasChanges: true };
    case 'twitter':
      return { ...state, customer: { ...state.customer, twitter: action.payload }, hasChanges: true };
    case 'xing':
      return { ...state, customer: { ...state.customer, xing: action.payload }, hasChanges: true };
    case 'linkedIn':
      return { ...state, customer: { ...state.customer, linkedIn: action.payload }, hasChanges: true };
    case 'contactName':
      return { ...state, customer: { ...state.customer, contactName: action.payload }, hasChanges: true };
    case 'website':
      return {
        ...state,
        customer: { ...state.customer, website: action.payload },
        errors: { ...state.errors, website: null },
        hasChanges: true,
      };
    case 'birthdate': {
      if (isValidDateString(action.payload)) {
        return {
          ...state,
          birthdateHelper: action.payload,
          customer: { ...state.customer, birthdate: moment(action.payload, 'LL').format('YYYY-MM-DD') },
          hasChanges: true,
        };
      } else {
        return { ...state, birthdateHelper: action.payload, hasChanges: true };
      }
    }
    case 'comment':
      return { ...state, customer: { ...state.customer, comment: action.payload }, hasChanges: true };
    case 'bankIban':
      return { ...state, customer: { ...state.customer, bankIban: action.payload }, hasChanges: true };
    case 'bankBic':
      return { ...state, customer: { ...state.customer, bankBic: action.payload }, hasChanges: true };
    case 'bankName':
      return { ...state, customer: { ...state.customer, bankName: action.payload }, hasChanges: true };
    case 'defaultPaymentType':
      return { ...state, customer: { ...state.customer, defaultPaymentType: action.payload }, hasChanges: true };
    case 'init':
      return {
        ...state,
        customer: action.payload,
        birthdateHelper: moment(action.payload.birthdate).format('LL'),
        isEdit: true,
      };
    case 'initEmpty':
      return {
        ...state,
        customer: {
          ...state.customer,
          defaultFacilityId: action.payload,
        },
      };
    case 'setErrors':
      return { ...state, errors: action.payload };
    default:
      new Error('Type not found!');
  }
};
