import React from 'react';
import { useParams } from 'react-router';
import InvoiceDetails from '../../../Components/Invoice/Details';

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const { invoiceId, customerId } = useParams();

  return <InvoiceDetails invoiceId={invoiceId} customerId={customerId} />;
}
