import React from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  useDispatchClientTimeTrackingCreateUserPdf,
  useDispatchClientTimeTrackingGetByMonth,
} from '../../../Redux/Actions/Client/TimeTrackingAction';
import { parseInt } from 'lodash';
import moment from 'moment';
import { GroupedTimeTracking, PickedUserEntity, TimeTrackingEntity } from '../../../Globals/Types/Types';
import {
  convertMinutesToTimeString,
  downloadBase64Pdf,
  groupTimeTrackingByDate,
  userDisplayName,
} from '../../../Globals/Functions';
import Panel from '../../../Components/Panel';
import Table, { TableMappings } from '../../../Components/Table/Table';
import { useTranslation } from 'react-i18next';
import UserTimeTrackingIconRow from '../../../Components/Table/CustomCols/UserTimeTrackingIconRow';
import UserTimeTrackingTimeRow from '../../../Components/Table/CustomCols/UserTimeTrackingTimeRow';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';
import PageHeader from '../../../Components/PageHeader';
import { useDispatchUserGet } from '../../../Redux/Actions/UserAction';
import { Button } from 'react-bootstrap';
import TimeTrackingApproveTemporaryModal from './TimeTrackingApproveTemporaryModal';
import MonthSelector from './Components/MonthSelector';
import TimeTrackingManualModal from './TimeTrackingManualModal';

const mappings: TableMappings<TimeTrackingEntity> = [
  { key: 'icon', headline: 'state', width: 100, customColElement: UserTimeTrackingIconRow },
  { key: 'time', headline: 'time', customColElement: UserTimeTrackingTimeRow },
];

/**
 * UserDetails
 * @constructor
 */
export default function UserDetails() {
  const { userId } = useParams();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [selectedEntry, setSelectedEntry] = React.useState<TimeTrackingEntity>(null);
  const [selectedMonth, setSelectedMonth] = React.useState<string>(moment().format('YYYY-MM-DD'));
  const { loadingModal, setLoading } = useLoadingModal();
  const [data, setData] = React.useState<Array<GroupedTimeTracking>>([]);
  const [user, setUser] = React.useState<PickedUserEntity>(null);
  const [showManual, setShowManual] = React.useState<boolean>(false);
  const [showApprove, setShowApprove] = React.useState<boolean>(false);
  const dispatchGetByMonth = useDispatchClientTimeTrackingGetByMonth();
  const dispatchDownloadReport = useDispatchClientTimeTrackingCreateUserPdf();
  const dispatchGetUser = useDispatchUserGet();

  const getData = React.useCallback(
    (momentMonth) => {
      const month = parseInt(moment(momentMonth).format('M'));
      const year = parseInt(moment(momentMonth).format('YYYY'));

      setLoading(true);
      dispatchGetByMonth(userId, month, year).then((response) => {
        const grouped = groupTimeTrackingByDate(response, 'desc');
        setData(grouped);
        setLoading(false);
      });
    },
    [dispatchGetByMonth, setLoading, userId],
  );

  const handleCreatePdf = React.useCallback(() => {
    setLoading(true);
    dispatchDownloadReport(
      userId,
      parseInt(moment(selectedMonth).format('M')),
      parseInt(moment(selectedMonth).format('YYYY')),
    )
      .then((base64String) => {
        const monthString = moment(selectedMonth).format('MM-YYYY');
        const fielName = `${monthString} - ${userDisplayName(user).replace(', ', '')}.pdf`;
        downloadBase64Pdf(base64String, fielName);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatchDownloadReport, selectedMonth, setLoading, user, userId]);

  React.useEffect(() => {
    getData(selectedMonth);
  }, [getData, selectedMonth]);

  React.useEffect(() => {
    dispatchGetUser(userId).then((user) => {
      setUser(user);
    });
  }, [dispatchGetUser, userId]);

  const handleCloseEdit = (reload: boolean) => {
    if (reload) {
      getData(selectedMonth);
    }
    setSelectedEntry(null);
    setShowApprove(false);
    setShowManual(false);
  };

  const renderCancelButton = () => {
    return (
      <Button variant="outline-secondary" onClick={() => navigate(-1)}>
        <i className="fas fa-angle-double-left" style={{ marginRight: 8 }} />
        {t('back')}
      </Button>
    );
  };

  const renderAddButton = () => {
    return (
      <Button variant="success" onClick={() => setShowManual(true)}>
        <i className="fas fa-plus" />
      </Button>
    );
  };

  const renderDownloadButton = () => {
    if (
      moment(selectedMonth).isBefore(moment(), 'month') ||
      (moment(selectedMonth).isSame(moment(), 'month') && moment(selectedMonth).endOf('month').isSame(moment(), 'day'))
    ) {
      return (
        <Button variant="outline-secondary" onClick={handleCreatePdf}>
          <i className="fas fa-cloud-arrow-down" style={{ marginRight: 8 }} />
          {t('modules.settings.timeTracking.downloadReport')}
        </Button>
      );
    }
    return null;
  };

  const handleSelectEntry = (item: TimeTrackingEntity) => {
    setSelectedEntry(item);

    setTimeout(() => {
      if (item.temporary) {
        setShowApprove(true);
      } else {
        setShowManual(true);
      }
    }, 300);
  };

  const renderDates = (param: GroupedTimeTracking) => {
    return (
      <Panel
        key={param.date}
        headline={moment(param.date).format('LLL')}
        description={t('modules.settings.timeTracking.subHeadlineUserTimeTracking', {
          time: convertMinutesToTimeString(param.minutes),
        })}
      >
        <Table data={param.data} mappings={mappings} key={'id'} onClickRow={handleSelectEntry} />
      </Panel>
    );
  };

  return (
    <>
      <PageHeader
        headline={t('modules.settings.timeTracking.userOverview')}
        description={user ? userDisplayName(user, true) : ''}
        actionButtonOne={renderCancelButton()}
        actionButtonTwo={renderDownloadButton()}
        actionButtonThree={renderAddButton()}
      />
      <MonthSelector
        onNext={() => setSelectedMonth(moment(selectedMonth).add(1, 'month').format('YYYY-MM-DD'))}
        onPrev={() => setSelectedMonth(moment(selectedMonth).subtract(1, 'month').format('YYYY-MM-DD'))}
        selectedMonth={selectedMonth}
        data={data}
      />

      {data.map((item) => renderDates(item))}

      <TimeTrackingApproveTemporaryModal onClose={handleCloseEdit} timeTracking={selectedEntry} visible={showApprove} />
      <TimeTrackingManualModal onClose={handleCloseEdit} visible={showManual} timeTracking={selectedEntry} />

      {loadingModal}
    </>
  );
}
