import React from 'react';
import { OrderServiceType, PositionEntity } from '../../../../Globals/Types/OrderTypes';
import Panel from '../../../Panel';
import { useTranslation } from 'react-i18next';
import CreateOrUpdatePositionModal from './CreateOrUpdatePositionModal';
import { Alert, Button } from 'react-bootstrap';
import DefaultPositionRow from './DefaultPositionRow';
import { ValidatePositionsResult } from '../../Validators/ValidatePositions';
import styled from 'styled-components';
import SelectPosition from '../../Positions/SelectPositionModal';

const PreviewIndicatorContainer = styled.div`
  text-align: center;
  margin-top: 20px;

  i {
    font-size: 40px;
    margin-bottom: 15px;
  }

  .Link {
    cursor: pointer;
    color: ${(props) => props.theme.color.red};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

interface Props {
  orderServiceType: OrderServiceType;
  positions: PositionEntity[];
  blackHeadline?: boolean;
  validationResult: ValidatePositionsResult;
  allowEdit: boolean;
  onChangePositions?: (item: PositionEntity[], changedAssemblyTime: boolean) => void;
  previewMode?: boolean;
  previewCount?: number;
}

/**
 * PositionList
 * @param props
 * @constructor
 */
export default function PositionList(props: Props) {
  const {
    positions,
    orderServiceType,
    blackHeadline,
    validationResult,
    allowEdit,
    onChangePositions,
    previewMode,
    previewCount,
  } = props;
  const [filteredPositions, setFilteredPositions] = React.useState<PositionEntity[]>(null);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showSelect, setShowSelect] = React.useState<boolean>(false);
  const [internalPreviewMode, setInternalPreviewMode] = React.useState<boolean>(previewMode);
  const [selected, setSelected] = React.useState<PositionEntity>(null);
  const [t] = useTranslation();

  React.useEffect(() => {
    if (positions) {
      if (internalPreviewMode && positions.length > previewCount) {
        setFilteredPositions(positions.slice(0, previewCount));
      } else {
        setFilteredPositions(positions);
      }
    } else {
      setFilteredPositions([]);
    }
  }, [previewCount, internalPreviewMode, positions]);

  React.useEffect(() => {
    setInternalPreviewMode(previewMode);
  }, [previewMode]);

  const handleOnAdd = (item: PositionEntity) => {
    setShowModal(false);
    setShowSelect(false);
    const mergedPositions = positions ? [...positions, item] : [item];

    if (onChangePositions) {
      onChangePositions(mergedPositions, item.assemblyTime && item.assemblyTime > 0);
    }
  };

  const handleOnEdit = (item: PositionEntity, changedAssemblyTime: boolean) => {
    setShowModal(false);
    const mergedPositions = positions.map((param) => (param.positionId === item.positionId ? item : param));

    if (onChangePositions) {
      onChangePositions(mergedPositions, changedAssemblyTime);
    }
  };

  const handleDeletePosition = (item: PositionEntity) => {
    setShowModal(false);
    const mergedPositions = positions.filter((param) => item.positionId !== param.positionId);

    if (onChangePositions) {
      onChangePositions(mergedPositions, true);
    }
  };

  const handleSelect = (item: PositionEntity) => {
    setSelected(item);
    setTimeout(() => setShowModal(true), 300);
  };

  const renderPositions = () => {
    if (filteredPositions) {
      return filteredPositions.map((item, index) => (
        <DefaultPositionRow
          position={item}
          onDelete={handleDeletePosition}
          index={index + 1}
          key={index}
          onClick={handleSelect}
          allowEdit={allowEdit}
          allowDelete={filteredPositions.length > 1}
        />
      ));
    }
    return null;
  };

  const renderError = () => {
    if (validationResult && !!validationResult.errors.hasPosition) {
      return (
        <Alert variant="danger">
          <i className="fas fa-exclamation-circle" style={{ marginRight: 10 }} />
          {t('modules.order.createNoPosition')}
        </Alert>
      );
    }
    return null;
  };

  const renderAddButton = () =>
    allowEdit ? (
      <div style={{ marginTop: 20, textAlign: 'right' }}>
        <Button variant="outline-success" onClick={() => setShowSelect(true)} style={{ marginRight: 10 }}>
          <i className="far fa-rectangle-list" style={{ marginRight: 6 }} />
          {t('selectPosition')}
        </Button>
        <Button variant="outline-success" onClick={() => setShowModal(true)}>
          <i className="far fa-plus-circle" style={{ marginRight: 6 }} />
          {t('manualPosition')}
        </Button>
      </div>
    ) : null;

  const renderPreviewIndicator = () => {
    if (internalPreviewMode && positions && positions.length > previewCount) {
      return (
        <PreviewIndicatorContainer>
          <div>
            <i className="fa-solid fa-ellipsis-vertical" />
          </div>
          <div className="Link" onClick={() => setInternalPreviewMode(false)}>
            {t('modules.order.positionsShowMoore', { count: positions.length - previewCount })}
          </div>
        </PreviewIndicatorContainer>
      );
    }
    return null;
  };

  const handleOnCloseCreateOrUpdateModal = () => {
    setSelected(null);
    setShowModal(false);
  };

  if (orderServiceType) {
    return (
      <Panel
        headline={t('modules.order.positionsFor', {
          count: positions ? positions.length : 0,
          type: orderServiceType.caption,
        })}
        description={t('modules.order.positionsDescription', { type: orderServiceType.caption })}
        headlineDarkBlack={blackHeadline || false}
      >
        {renderError()}
        {renderPositions()}
        {renderPreviewIndicator()}

        {renderAddButton()}

        <CreateOrUpdatePositionModal
          visible={showModal}
          onClose={handleOnCloseCreateOrUpdateModal}
          onSave={handleOnAdd}
          onEdit={handleOnEdit}
          editData={selected}
        />

        <SelectPosition onSelect={handleOnAdd} visible={showSelect} onClose={() => setShowSelect(false)} />
      </Panel>
    );
  }
  return null;
}

PositionList.defaultProps = {
  previewMode: false,
  previewCount: 3,
};
