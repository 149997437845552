import React, { ReactElement } from 'react';
import { CalendarDataType, DefaultBadgeProps } from './CalendarTypes';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { getDaysOfMonth } from '../../Globals/Functions';
import DefaultBadge from './DefaultBadge';
import DayNamesHeader from './DayNamesHeader';
import MonthSelector from './MonthSelector';

type CalendarProps<T> = {
  showSundays: boolean;
  initialMonth?: Moment;
  data: Array<CalendarDataType<T>>;
  BadgeComponent?: <T>(props: DefaultBadgeProps<T>) => ReactElement;
  badgeMargin?: number;
  onMonthChange?: (month: Moment) => void;
  onPressDay: (day: Moment) => void;
  onPressEntry: (item: T) => void;
};

interface ContainerProps {
  $badgeMargin: number;
}
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  margin-right: ${(props) => `-${props.$badgeMargin}px`};
`;

/**
 * Calendar
 * @param props
 * @constructor
 */
export default function Calendar<T>(props: CalendarProps<T>) {
  const { showSundays, initialMonth, BadgeComponent, data, badgeMargin, onMonthChange, onPressDay, onPressEntry } =
    props;
  const [currentMonth, setCurrentMonth] = React.useState<Moment>(null);
  const [lastMonth, setLastMonth] = React.useState<Moment>(null);

  React.useEffect(() => {
    if (initialMonth) {
      setCurrentMonth(initialMonth);
    } else {
      setCurrentMonth(moment());
    }
  }, [initialMonth]);

  React.useEffect(() => {
    if (currentMonth && currentMonth !== lastMonth) {
      setLastMonth(currentMonth);
      onMonthChange(currentMonth);
    }
  }, [currentMonth, lastMonth, onMonthChange]);

  if (currentMonth) {
    return (
      <>
        <MonthSelector currentMonth={currentMonth} onPrev={setCurrentMonth} onNext={setCurrentMonth} />
        <Container $badgeMargin={badgeMargin}>
          <DayNamesHeader $showSundays={showSundays} $badgeMargin={badgeMargin} />
          {getDaysOfMonth(currentMonth).map((day) => (
            <BadgeComponent<T>
              key={day.format('DD.MM')}
              selectedDay={day}
              showSundays={showSundays}
              onPressDay={onPressDay}
              onPressEntry={onPressEntry}
              data={data}
              currentMonth={currentMonth}
              badgeMargin={badgeMargin}
            />
          ))}
        </Container>
      </>
    );
  }
  return null;
}

Calendar.defaultProps = {
  showSundays: false,
  initialMonth: null,
  BadgeComponent: DefaultBadge,
  badgeMargin: 6,
  onMonthChange: () => {},
};
