import React from 'react';
import { InvoiceEntity } from '../../../../Globals/Types/Invoice';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DisplayText from '../../../../Components/View/DisplayText';

interface Props {
  invoice: InvoiceEntity;
  onClose: () => void;
  visible: boolean;
}

/**
 * MailHistoryModal()
 * @param props
 * @constructor
 */
export default function MailHistoryModal(props: Props) {
  const { invoice, onClose, visible } = props;
  const [t] = useTranslation();

  const renderHistory = () => {
    return invoice.mailTracking
      .sort((a, b) => (moment(a.date).isSameOrAfter(moment(b.date)) ? -1 : 1))
      .map((item) => (
        <DisplayText horizontal caption={item.mail} key={item.date}>
          <>{moment(item.date).format('LLL - LT')}h</>
        </DisplayText>
      ));
  };

  if (visible && invoice && invoice.mailTracking) {
    return (
      <Modal show={visible} centered size="lg">
        <Modal.Header>
          <Modal.Title>{t('mailHistory')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderHistory()}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => onClose()}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
}
