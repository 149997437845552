import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';
import { DeliveryAndLoadingOptions } from '../../../Globals/Types/Enums';
import { Address } from '../../../Globals/Types/Types';

export interface ValidateDeliveryAndLoadingOptionsResult {
  result: boolean;
  errors: {
    loadingAddress: {
      zip: string;
      city: string;
      street: string;
      streetNo: string;
    };
    deliveryAddress: {
      zip: string;
      city: string;
      street: string;
      streetNo: string;
    };
  };
}

/**
 * validateDeliveryAndLoadingAddress()
 * @param deliveryAndLoadingOption
 * @param loadingAddress
 * @param deliveryAddress
 */
export const validateDeliveryAndLoadingAddress = (
  deliveryAndLoadingOption: DeliveryAndLoadingOptions,
  loadingAddress: Address | null,
  deliveryAddress: Address | null,
): ValidateDeliveryAndLoadingOptionsResult => {
  let returnValue: ValidateDeliveryAndLoadingOptionsResult = {
    result: true,
    errors: {
      loadingAddress: {
        zip: undefined,
        city: undefined,
        street: undefined,
        streetNo: undefined,
      },
      deliveryAddress: {
        zip: undefined,
        city: undefined,
        street: undefined,
        streetNo: undefined,
      },
    },
  };

  if (
    deliveryAndLoadingOption === DeliveryAndLoadingOptions.deliveryIsCustomerAndLoading ||
    deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingAndDelivery
  ) {
    if (!loadingAddress || !loadingAddress.zip || loadingAddress.zip.length === 0) {
      returnValue = {
        ...returnValue,
        result: false,
        errors: {
          ...returnValue.errors,
          loadingAddress: { ...returnValue.errors.loadingAddress, zip: InternalErrorCodes.FIELD_MISSING },
        },
      };
    }
    if (!loadingAddress || !loadingAddress.city || loadingAddress.city.length === 0) {
      returnValue = {
        ...returnValue,
        result: false,
        errors: {
          ...returnValue.errors,
          loadingAddress: { ...returnValue.errors.loadingAddress, city: InternalErrorCodes.FIELD_MISSING },
        },
      };
    }
    if (!loadingAddress || !loadingAddress.street || loadingAddress.street.length === 0) {
      returnValue = {
        ...returnValue,
        result: false,
        errors: {
          ...returnValue.errors,
          loadingAddress: { ...returnValue.errors.loadingAddress, street: InternalErrorCodes.FIELD_MISSING },
        },
      };
    }
    if (!loadingAddress || !loadingAddress.streetNo || loadingAddress.streetNo.length === 0) {
      returnValue = {
        ...returnValue,
        result: false,
        errors: {
          ...returnValue.errors,
          loadingAddress: { ...returnValue.errors.loadingAddress, streetNo: InternalErrorCodes.FIELD_MISSING },
        },
      };
    }
  }

  if (
    deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingIsCustomerAndDelivery ||
    deliveryAndLoadingOption === DeliveryAndLoadingOptions.loadingAndDelivery
  ) {
    if (!deliveryAddress || !deliveryAddress.zip || deliveryAddress.zip.length === 0) {
      returnValue = {
        ...returnValue,
        result: false,
        errors: {
          ...returnValue.errors,
          deliveryAddress: { ...returnValue.errors.deliveryAddress, zip: InternalErrorCodes.FIELD_MISSING },
        },
      };
    }
    if (!deliveryAddress || !deliveryAddress.city || deliveryAddress.city.length === 0) {
      returnValue = {
        ...returnValue,
        result: false,
        errors: {
          ...returnValue.errors,
          deliveryAddress: { ...returnValue.errors.deliveryAddress, city: InternalErrorCodes.FIELD_MISSING },
        },
      };
    }
    if (!deliveryAddress || !deliveryAddress.street || deliveryAddress.street.length === 0) {
      returnValue = {
        ...returnValue,
        result: false,
        errors: {
          ...returnValue.errors,
          deliveryAddress: { ...returnValue.errors.deliveryAddress, street: InternalErrorCodes.FIELD_MISSING },
        },
      };
    }
    if (!deliveryAddress || !deliveryAddress.streetNo || deliveryAddress.streetNo.length === 0) {
      returnValue = {
        ...returnValue,
        result: false,
        errors: {
          ...returnValue.errors,
          deliveryAddress: { ...returnValue.errors.deliveryAddress, streetNo: InternalErrorCodes.FIELD_MISSING },
        },
      };
    }
  }

  return returnValue;
};
