import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../Components/PageHeader';
import Panel from '../../../Components/Panel';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useDispatchUsersGetList } from '../../../Redux/Actions/UserAction';
import Table from '../../../Components/Table/Table';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import { UserEntity } from '../../../Globals/Types/Types';
import UserGroupsCol from '../../../Components/Table/CustomCols/UserGroupsCol';
import { userDisplayName } from '../../../Globals/Functions';
import styled from 'styled-components';

const TableRow = styled.tr<{ disabled: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.disabled ? props.theme.color.textGray : props.theme.color.text)};
`;

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const { setLoading, isLoading } = useLoadingModal();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { all: users } = useAppSelector((state) => state.client.users);
  const dispatchGetList = useDispatchUsersGetList();

  React.useEffect(() => {
    if (!users || users.length === 0) {
      setLoading(true);
    }
  }, [setLoading, users]);

  React.useEffect(() => {
    dispatchGetList()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatchGetList, setLoading]);

  const checkNavigateAddUser = () => {
    navigate('/settings/userCreate');
  };

  const renderAddButton = () => {
    return (
      <Button variant="success" onClick={checkNavigateAddUser}>
        <i className="fas fa-plus-circle" />
      </Button>
    );
  };

  const navigateDetails = (user: UserEntity) => {
    navigate(`/settings/users/${user.userId}`);
  };

  const renderUserRows = () => {
    if (users && users.length > 0) {
      return users.map((user) => (
        <TableRow disabled={!user.active} onClick={() => navigateDetails(user)} key={user.userId}>
          <td width={60}>
            <i className={user.active ? 'fas fa-user' : 'fas fa-user-slash'} />
          </td>
          <td>{userDisplayName(user, true)}</td>
          <td>{user.mail}</td>
          <UserGroupsCol item={user} />
        </TableRow>
      ));
    }
    return null;
  };

  return (
    <>
      <PageHeader headline={t('userManagement')} actionButtonOne={renderAddButton()} />
      <Panel>
        <Table isLoading={isLoading}>
          <thead>
            <tr>
              <th />
              <th>{t('name')}</th>
              <th>{t('eMailAddress')}</th>
              <th>{t('groups')}</th>
            </tr>
          </thead>
          <tbody>{renderUserRows()}</tbody>
        </Table>
      </Panel>
    </>
  );
}
