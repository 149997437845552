import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

const Item = styled(Dropdown.Item)`
  padding: 8px 13px;

  > i {
    margin-right: 10px;
    color: ${(props) => props.theme.color.textGray};
  }

  &:hover {
    background-color: ${(props) => props.theme.color.primaryColor};
    color: #ffffff;

    > i {
      color: #ffffff !important;
    }
  }
`;

interface Props {
  children: any;
  onClick?: () => void;
}

/**
 * DropDownItem()
 * @param props
 * @constructor
 */
export default function DropDownItem(props: Props) {
  const { children, onClick } = props;

  return <Item onClick={onClick ? () => onClick() : () => {}}>{children}</Item>;
}
