import React from 'react';
import PageHeader from '../../../Components/PageHeader';
import Calendar from '../../../Components/Calendar/Calendar';
import Panel from '../../../Components/Panel';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import CreateAbsenceModal from './CreateAbsenceModal';
import { useDispatchAbsenceGetList } from '../../../Redux/Actions/Client/AbsenceAction';
import { Moment } from 'moment';
import { AbsenceEntity } from '../../../Globals/Types/Types';
import { useAppSelector } from '../../../Globals/Hooks/Hooks';
import AbsenceBadge from '../../../Components/Calendar/Badges/AbsenceBadge';

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const [showCreate, setShowCreate] = React.useState<boolean>(false);
  const [selectedStartDate, setSelectedStartDate] = React.useState<Moment>(null);
  const [selectedMonth, setSelectedMonth] = React.useState<Moment>(null);
  const [selectedAbsence, setSelectedAbsence] = React.useState<AbsenceEntity>(null);
  const { absences } = useAppSelector((state) => state.calendar);
  const [t] = useTranslation();
  const dispatchGetAbsence = useDispatchAbsenceGetList();

  const fetchData = React.useCallback(
    (month) => {
      const startDate = month.startOf('month').toDate();
      const endDate = month.endOf('month').toDate();
      dispatchGetAbsence(startDate, endDate, true).then(() => {});
    },
    [dispatchGetAbsence],
  );

  const handleMonthChange = (month: Moment) => {
    setSelectedMonth(month);
    fetchData(month);
  };

  const renderAddButton = () => {
    return (
      <Button variant="success" onClick={() => setShowCreate(true)}>
        <i className="fas fa-plus-circle" />
      </Button>
    );
  };

  const handleClickDay = (day: Moment) => {
    setSelectedStartDate(day);
    setTimeout(() => setShowCreate(true), 500);
  };

  const handleCloseCreate = () => {
    fetchData(selectedMonth);
    setShowCreate(false);
    setSelectedStartDate(null);
    setSelectedAbsence(null);
  };

  const handlePressAbsence = (absence: AbsenceEntity) => {
    setSelectedAbsence(absence);
    setShowCreate(true);
  };

  return (
    <>
      <PageHeader headline={t('absenceCalendar')} actionButtonOne={renderAddButton()} />
      <Panel>
        <Calendar<AbsenceEntity>
          data={absences}
          showSundays={true}
          onMonthChange={handleMonthChange}
          onPressEntry={handlePressAbsence}
          onPressDay={handleClickDay}
          BadgeComponent={AbsenceBadge}
        />
      </Panel>

      <CreateAbsenceModal
        onClose={handleCloseCreate}
        visible={showCreate}
        initialStartDate={selectedStartDate}
        editData={selectedAbsence}
      />
    </>
  );
}
