import React from 'react';
import styled from 'styled-components';
import Box from './Box';

export interface PanelSelectOption {
  key: string;
  caption: string;
  icon?: string;
  imageUrl?: string;
  disabled: boolean;
  useTranslation?: boolean;
}

interface Props {
  onChange: (key: Array<string>) => void;
  options: Array<PanelSelectOption>;
  multiSelect?: boolean;
  selected: Array<string>;
  allowUnselectAll?: boolean;
  hasError?: boolean;
  errorId?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

/**
 * PanelSelect
 * @param props
 * @constructor
 */
export default function PanelSelect(props: Props) {
  const { options, selected, onChange, multiSelect, allowUnselectAll, hasError, errorId } = props;

  const handleOnSelect = (item: PanelSelectOption) => {
    if (multiSelect) {
      const merged = [...selected, item.key];
      onChange(merged);
    } else {
      onChange([item.key]);
    }
  };

  const handleOnDeselect = (item: PanelSelectOption) => {
    if (multiSelect) {
      if (allowUnselectAll) {
        const filtered = selected.filter((param) => param !== item.key);
        onChange(filtered);
      } else {
        if (selected.length > 1) {
          const filtered = selected.filter((param) => param !== item.key);
          onChange(filtered);
        }
      }
    } else {
      if (allowUnselectAll) {
        onChange([]);
      }
    }
  };

  return (
    <Container>
      {options.map((item) => (
        <Box
          item={item}
          onSelect={handleOnSelect}
          onDeselect={handleOnDeselect}
          selected={selected.indexOf(item.key) > -1}
          key={item.key}
          hasError={hasError}
          errorId={errorId}
        />
      ))}
    </Container>
  );
}

PanelSelect.defaultProps = {
  multiSelect: false,
  allowUnselectAll: true,
};
