import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { useDispatchBackup, useDispatchRestore } from '../../../Redux/Actions/Client/BackupRestoreAction';
import { useAppSelector, useLoadingModal } from '../../../Globals/Hooks/Hooks';
import Config from '../../../Globals/Config';
import { Environment } from '../../../Globals/Types/Enums';
import ErrorMessage from '../../../Components/Modals/ErrorMessage';
import { GroupKey } from '../../../Globals/Types/Types';
import { isFileValid } from '../../../Globals/Functions';
import Panel from '../../../Components/Panel';
import { useDispatchDocumentUploadToTempFolder } from '../../../Redux/Actions/GlobalStorageActions';

type ErrorType = {
  title: string;
  description?: string;
};

/**
 * BackupRestoreForm
 * @constructor
 */
export default function BackupRestoreForm() {
  const [t] = useTranslation();
  const { clientId, user } = useAppSelector((state) => state.auth);
  const { groups } = user;
  const { setLoading: setLoadingBackup, loadingModal: loadingModalBackup } = useLoadingModal({
    textLine1: t('modules.settings.backup.loading1'),
    textLine2: t('modules.settings.backup.loading2'),
  });
  const { setLoading: setLoadingRestore, loadingModal: loadingModalRestore } = useLoadingModal({
    textLine1: t('modules.settings.backup.restoreLoading1'),
    textLine2: t('modules.settings.backup.restoreLoading2'),
  });

  const restoreFile = React.useRef<HTMLInputElement | null>(null);
  const [canRestore] = React.useState(Config.env === Environment.dev);
  const [errorObject, setErrorObject] = React.useState<ErrorType | null>(null);
  const dispatchBackup = useDispatchBackup();
  const dispatchRestore = useDispatchRestore();
  const dispatchUploadTemp = useDispatchDocumentUploadToTempFolder();

  const handleBackup = () => {
    setLoadingBackup(true);
    dispatchBackup(clientId)
      .then((result) => {
        setTimeout(() => saveAs(result.uri, result.filename), 1000);
      })
      .catch(() => setErrorObject({ title: t('modules.settings.backup.downloadFailed') }))
      .finally(() => setLoadingBackup(false));
  };

  const handleRestore = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];

      if (!isFileValid(file, ['zip'])) {
        return setErrorObject({ title: t('fileFormatInvalid'), description: t('fileFormatInvalidDescription') });
      }

      setLoadingRestore(true);

      dispatchUploadTemp(file)
        .then(({ storagePath }) => dispatchRestore(storagePath))
        .catch(() => setErrorObject({ title: t('modules.settings.backup.restoreFailed') }))
        .finally(() => setLoadingRestore(false));
    }
  };

  const renderRestore = () => {
    if (canRestore) {
      return (
        <>
          <input type="file" ref={restoreFile} style={{ display: 'none' }} onChange={handleRestore} />
          <Button onClick={() => restoreFile.current.click()} variant="danger" style={{ marginBottom: 10 }}>
            <i className="fas fa-upload" style={{ marginRight: 8 }} />
            {t('modules.settings.backup.restore')}
          </Button>
        </>
      );
    }
  };

  if (groups.find((group) => group === GroupKey.ADMIN)) {
    return (
      <Panel headline={t('modules.settings.backup.headline')} description={t('modules.settings.backup.description')}>
        <div className="d-flex flex-grow-1 flex-column justify-content-end">
          {renderRestore()}
          <Button onClick={() => handleBackup()} variant="success">
            <i className="fas fa-download" style={{ marginRight: 8 }} />
            {t('modules.settings.backup.download')}
          </Button>
        </div>
        <ErrorMessage
          headline={errorObject?.title}
          description={errorObject?.description}
          onClose={() => setErrorObject(null)}
          visible={!!errorObject}
        />
        {loadingModalBackup}
        {loadingModalRestore}
      </Panel>
    );
  }
  return null;
}
