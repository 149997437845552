import React from 'react';
import styled from 'styled-components';
import { lighten, saturate } from 'polished';

type Size = 'small' | 'medium' | 'large';

const Container = styled.div<{ $size: Size; $bgColor: string; $fontSize: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: ${(props) => props.theme.border.radius};
  margin-bottom: 15px;
  margin-right: 15px;
  min-width: ${(props) => (props.$size === 'small' ? '400px' : props.$size === 'medium' ? '50%' : '100%')};
  ${(props) => props.theme.boxShadowDark};
  color: #ffffff;
  cursor: pointer;

  .Header {
    border-radius: ${(props) => props.theme.border.radius} ${(props) => props.theme.border.radius} 0 0;
    color: ${(props) => props.theme.color.blue};
    padding: 15px 20px 0;
    margin-bottom: 0;
  }

  .Inner {
    border-radius: ${(props) => props.theme.border.radius};
    margin: 15px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: ${(props) => props.$fontSize || '4'}em;
    line-height: 2em;
    font-weight: bold;
    background-color: ${(props) => saturate(0.1, props.$bgColor)};
  }

  &:hover {
    background-color: ${(props) => saturate(0.1, props.$bgColor)};

    .Header {
      color: #ffffff;
    }

    .Inner {
      background-color: ${(props) => lighten(0.45, props.$bgColor)};
      color: ${(props) => saturate(0.1, props.$bgColor)};
    }
  }
`;

interface Props {
  icon: string;
  headline: string;
  children: React.ReactElement | React.ReactElement[] | string | number;
  size: Size;
  bgColor: string;
  onClick: () => void;
  fontSize?: number;
}

/**
 * DashboardBox
 * @param props
 * @constructor
 */
export default function DashboardBox(props: Props) {
  const { headline, children, size, bgColor, onClick, fontSize } = props;

  return (
    <Container $size={size} $bgColor={bgColor} onClick={onClick} $fontSize={fontSize}>
      <h5 className="Header">{headline}</h5>
      <div className="Inner">{children}</div>
    </Container>
  );
}

DashboardBox.defaultProps = {
  size: 'small',
};
