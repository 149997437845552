import { CreateOrderState } from '../../../Globals/Types/OrderTypes';
import { InternalErrorCodes } from '../../../Globals/InternalErrorCodes';

export interface ValidatePositionsResult {
  result: boolean;
  errors: {
    hasPosition: string;
  };
}

/**
 * validateStorageOptions
 * @param data
 */
export const validatePositions = (data: CreateOrderState): ValidatePositionsResult => {
  let returnValue: ValidatePositionsResult = {
    result: true,
    errors: {
      hasPosition: undefined,
    },
  };

  if (!data.positions || data.positions.length === 0) {
    returnValue = {
      ...returnValue,
      result: false,
      errors: { ...returnValue.errors, hasPosition: InternalErrorCodes.FIELD_MISSING },
    };
  }

  return returnValue;
};
