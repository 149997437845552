import React from 'react';
import { useAppSelector } from '../../Globals/Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { Button } from 'react-bootstrap';
import PageHeader from '../../Components/PageHeader';
import Panel from '../../Components/Panel';
import { AbsenceEntity } from '../../Globals/Types/Types';
import CreateAbsenceModal from '../Settings/Absence/CreateAbsenceModal';
import UserCalendar from '../../Components/Calendar/Calendar';
import { useDispatchUserAbsenceGetList } from '../../Redux/Actions/User/AbsenceAction';
import UserAbsenceBadge from '../../Components/Calendar/Badges/UserAbsenceBadge';
import { userEntityToPickedUserEntity } from '../../Globals/Functions';

/**
 * Calendar()
 * @constructor
 */
export default function Calendar() {
  const [showCreate, setShowCreate] = React.useState<boolean>(false);
  const [selectedStartDate, setSelectedStartDate] = React.useState<Moment>(null);
  const [selectedMonth, setSelectedMonth] = React.useState<Moment>(null);
  const { currentUserData } = useAppSelector((state) => state.calendar);
  const { user } = useAppSelector((state) => state.auth);
  const [t] = useTranslation();
  const dispatchGetAbsence = useDispatchUserAbsenceGetList();

  const fetchData = React.useCallback(
    (month) => {
      const startDate = month.startOf('month').toDate();
      const endDate = month.endOf('month').toDate();
      dispatchGetAbsence(startDate, endDate).then(() => {});
    },
    [dispatchGetAbsence],
  );

  const handleMonthChange = (month: Moment) => {
    setSelectedMonth(month);
    fetchData(month);
  };

  const renderAddButton = () => {
    return (
      <Button variant="success" onClick={() => setShowCreate(true)}>
        <i className="fas fa-plus-circle" />
      </Button>
    );
  };

  const handleClickDay = (day: Moment) => {
    setSelectedStartDate(day);
    setShowCreate(true);
  };

  const handleCloseCreate = () => {
    fetchData(selectedMonth);
    setShowCreate(false);
    setSelectedStartDate(null);
  };

  return (
    <>
      <PageHeader headline={t('calendar')} actionButtonOne={renderAddButton()} />
      <Panel>
        <UserCalendar<AbsenceEntity>
          data={currentUserData}
          showSundays={true}
          onMonthChange={handleMonthChange}
          onPressEntry={(item) => {}}
          onPressDay={handleClickDay}
          BadgeComponent={UserAbsenceBadge}
        />
      </Panel>

      <CreateAbsenceModal
        onClose={handleCloseCreate}
        visible={showCreate}
        initialStartDate={selectedStartDate}
        fixedUser={userEntityToPickedUserEntity(user)}
      />
    </>
  );
}
