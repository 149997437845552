import React from 'react';
import styled from 'styled-components';
import { ContentTypeToIconMapping, FacilityDocumentTypes } from '../../../Globals/Types/Enums';
import { useTranslation } from 'react-i18next';
import {
  useDispatchCreateDocument,
  useDispatchDeleteDocument,
  useDispatchGetDocumentByName,
} from '../../../Redux/Actions/Client/FacilityAction';
import { useParams } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FullMetadata } from '@firebase/storage';
import BottomPanel from './BottomPanel';
import AskDeleteModal from '../../Modals/AskDeleteModal';
import { useDispatchDocumentGetDownloadUrl } from '../../../Redux/Actions/GlobalStorageActions';
import { isFileValid } from '../../../Globals/Functions';
import ErrorMessage from '../../Modals/ErrorMessage';
import { defaultAllowedDocumentExtensions } from '../../../Globals/Types/Types';
import { useLoadingModal } from '../../../Globals/Hooks/Hooks';

type DocumentProps = {
  type: FacilityDocumentTypes;
  allowedExtensions?: Array<string>;
  description?: string;
};

const Container = styled.div<{ loaded: boolean; loading: boolean }>`
  display: ${(props) => (props.loaded ? 'flex' : 'normal')};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 300px;
  border-radius: ${(props) => props.theme.border.radius};
  ${(props) => props.theme.boxShadowDark};
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
  line-height: 1;
  overflow: hidden;
  background-color: #ffffff;

  .EmptyOne {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-family: 'DIN Next LT W01 Bold', sans-serif;
    overflow: hidden;

    > i {
      margin-bottom: 10px;
      font-size: 60px;
      color: ${(props) => (props.loading ? props.theme.color.primaryColor : '#ededed')};
    }

    small {
      font-family: 'DIN Next LT W01 Light', sans-serif;
      color: ${(props) => props.theme.color.textGray};
    }

    &:hover {
      background-color: ${(props) => props.theme.color.hoverBackground};

      > i {
        color: ${(props) => props.theme.color.primaryColor};
      }
    }
  }
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;

  .Top {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    width: 100%;
    overflow: hidden;

    img {
      padding: 15px;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    i {
      font-size: 120px;
    }
  }

  .Bottom {
    display: flex;
    width: 100%;
    border-radius: 0 6px 6px 0;
    padding: 15px;
    background-color: ${(props) => props.theme.color.backgroundGray};
  }
`;

/**
 * Document()
 * @param props
 * @constructor
 */
export default function Document(props: DocumentProps) {
  const { setLoading, isLoading } = useLoadingModal();
  const [document, setDocument] = React.useState<FullMetadata | null>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [showAskDelete, setShowAskDelete] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [url, setUrl] = React.useState<string>(null);
  const [t] = useTranslation();
  const { facilityId } = useParams();
  const fileRef = React.useRef<HTMLInputElement>();
  const dispatchUpload = useDispatchCreateDocument();
  const dispatchGetDocument = useDispatchGetDocumentByName();
  const dispatchDelete = useDispatchDeleteDocument();
  const dispatchGetDownloadUrl = useDispatchDocumentGetDownloadUrl();

  const loadDocument = React.useCallback(() => {
    return dispatchGetDocument(facilityId, props.type)
      .then((response) => {
        setLoaded(true);
        setDocument(response);
        if (response.fullPath) {
          return dispatchGetDownloadUrl(response.fullPath).then((url) => {
            setUrl(url);
          });
        } else {
          return Promise.resolve();
        }
      })
      .catch(() => {
        setLoaded(true);
      });
  }, [dispatchGetDocument, dispatchGetDownloadUrl, facilityId, props.type]);

  React.useEffect(() => {
    loadDocument().then(() => {});
  }, [dispatchGetDocument, facilityId, loadDocument, loaded, props.type]);

  const handleUpload = (event) => {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const isValid = isFileValid(file, props.allowedExtensions);

      if (isValid === true) {
        setLoading(true);
        dispatchUpload(facilityId, event.target.files[0], props.type)
          .then(() => {
            return loadDocument().then(() => {
              setLoading(false);
            });
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setShowError(true);
      }
    }
  };

  const handleDelete = () => {
    dispatchDelete(document.fullPath).then(() => {
      setDocument(null);
      setShowAskDelete(false);
    });
  };

  const handleDownload = () => {
    dispatchGetDownloadUrl(document.fullPath).then((url) => {
      window.open(url);
    });
  };

  const getIconName = () => (isLoading ? 'fas fa-spin fa-circle-notch' : 'fas fa-cloud-upload-alt');

  const renderEmptyHint = () => {
    if (!document && loaded) {
      return (
        <div className="EmptyOne" onClick={() => fileRef.current.click()}>
          <i className={getIconName()} />
          <h5>{t(`facilityDocumentTypes.${props.type}`)}</h5>
          <small>({props.allowedExtensions.join(', ')})</small>
        </div>
      );
    }
    if (!loaded) {
      return <Skeleton height={300} baseColor={'#fff'} highlightColor={'#e0e0e0'} />;
    }
    return null;
  };

  const renderIconOrImage = () => {
    if (url && document.contentType.toLowerCase().indexOf('image') === 0) {
      return <img src={url} alt={document.name} height={56} />;
    }
    return <i className={ContentTypeToIconMapping[document.contentType]} />;
  };

  const renderDocument = () => {
    if (document && loaded) {
      return (
        <DocumentContainer>
          <div className="Top" onClick={handleDownload}>
            {renderIconOrImage()}
          </div>
          <BottomPanel caption={t(`facilityDocumentTypes.${props.type}`)} onDelete={() => setShowAskDelete(true)} />
        </DocumentContainer>
      );
    }
    return null;
  };

  return (
    <Container loading={isLoading} loaded={loaded}>
      {renderEmptyHint()}
      {renderDocument()}

      <AskDeleteModal visible={showAskDelete} onClose={() => setShowAskDelete(false)} onDelete={handleDelete} />
      <ErrorMessage
        headline={t('errorMessages.invalidFileFormat.headline')}
        description={t('errorMessages.invalidFileFormat.description')}
        onClose={() => setShowError(false)}
        visible={showError}
      />

      <input type="file" ref={fileRef} onChange={handleUpload} style={{ display: 'none' }} />
    </Container>
  );
}

Document.defaultProps = {
  allowedExtensions: defaultAllowedDocumentExtensions,
  description: null,
};
